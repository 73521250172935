import config from "../../config";

export const GetJSONFields = async () => {
  return await Promise.all([
    fetch(`${config.json.URL}/fields.json`, {
      //   method: "POST",
      //   body: JSON.stringify({ shopId, shopType }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(([data]) => {
      return data; // {data,results}
    })
    .catch((err) =>
      console.log("Error fetching JSON fields from s3 bucket", err)
    );
};

export const BillingAPI = async (input) => {
  return await Promise.all([
    fetch(`${config.ApiGateway}/billingDashboardById`, {
      method: "POST",
      body: JSON.stringify(input),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return await getDataFromExternalAPI(data);
    })
    .catch((err) =>
      console.log("Error fetching JSON fields from s3 bucket", err)
    );
};
export const AppointmentAPI = async (input) => {
  return await Promise.all([
    fetch(`${config.ApiGateway}/appointmentDashboardById`, {
      method: "POST",
      body: JSON.stringify(input),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return await getDataFromExternalAPI(data);
    })
    .catch((err) =>
      console.log("Error fetching JSON fields from s3 bucket", err)
    );
};

export const DeliveryAPI = async (input) => {
  return await Promise.all([
    fetch(`${config.ApiGateway}/orderDashboardById`, {
      method: "POST",
      body: JSON.stringify(input),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return await getDataFromExternalAPI(data);
    })
    .catch((err) =>
      console.log("Error fetching JSON fields from s3 bucket", err)
    );
};

export const ProductAndServiceAPI = async ({ shopId, productStatus }) => {
  return await Promise.all([
    fetch(`${config.ApiGateway}/shopInventoryProducts`, {
      method: "POST",
      body: JSON.stringify({
        // "0a29da5a-5e9e-42c7-83ac-dc85a3be783b"

        shopId: shopId,
        productStatus: productStatus,
      }),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return await getDataFromExternalAPI(data);
    })
    .catch((err) =>
      console.log("Error fetching JSON fields from s3 bucket", err)
    );
};
//

export const CouponById = async (input) => {
  // Invoice by shopId
  return await Promise.all([
    fetch(`${config.ApiGateway}/editcoupon`, {
      method: "POST",
      body: JSON.stringify({
        shopId: "0a29da5a-5e9e-42c7-83ac-dc85a3be783b",
      }),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return await getDataFromExternalAPI(data);
    })
    .catch((err) =>
      console.log("Error fetching JSON fields from s3 bucket", err)
    );
};

export const NotificationAPI = async ({ shopId }) => {
  // Invoice by shopId
  return await Promise.all([
    fetch(`${config.ApiGateway}/notificationDashboardById`, {
      method: "POST",
      body: JSON.stringify({
        // "0a29da5a-5e9e-42c7-83ac-dc85a3be783b"

        shopId: "0a29da5a-5e9e-42c7-83ac-dc85a3be783b",
      }),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return await getDataFromExternalAPI(data);
    })
    .catch((err) =>
      console.log("Error fetching JSON fields from s3 bucket", err)
    );
};

export const InvoiceAPI = async ({ shopId }) => {
  // Invoice by shopId
  return await Promise.all([
    fetch(`${config.ApiGateway}/getShopAndInvoice`, {
      method: "POST",
      body: JSON.stringify({
        // "0a29da5a-5e9e-42c7-83ac-dc85a3be783b"

        shopId,
      }),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return await getDataFromExternalAPI(data);
    })
    .catch((err) =>
      console.log("Error fetching JSON fields from s3 bucket", err)
    );
};
export const GenerateInvoiceAPI = async (input) => {
  // Invoice by shopId
  return await Promise.all([
    fetch(`${config.ApiGateway}/invoiceGeneration`, {
      method: "POST",
      body: JSON.stringify(input),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return data;
    })
    .catch((err) => console.log("Error fetching invoice", err));
};

export const AddEmployeeAPI = async (secondaryUserId, primaryUserId) => {
  // Invoice by shopId
  return await Promise.all([
    fetch(`${config.ApiGateway}/addEmployee`, {
      method: "POST",
      body: JSON.stringify({
        userId: secondaryUserId, // sub employee shopId
        ownerShopId: primaryUserId, // to get sub shop
      }),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return data;
    })
    .catch((err) =>
      console.log("Error fetching JSON fields from s3 bucket", err)
    );
};

export const CreateOfferAPI = async (input) => {
  // Invoice by shopId
  return await Promise.all([
    fetch(`${config.ApiGateway}/createoffer`, {
      method: "POST",
      body: JSON.stringify(input),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return data;
    })
    .catch((err) =>
      console.log("Error fetching JSON fields from s3 bucket", err)
    );
};

export const UpdateOfferAPI = async (input) => {
  // Invoice by shopId
  return await Promise.all([
    fetch(`${config.ApiGateway}/updateoffer`, {
      method: "POST",
      body: JSON.stringify(input),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return data;
    })
    .catch((err) =>
      console.log("Error fetching JSON fields from s3 bucket", err)
    );
};

export const B2BPackageAPI = async (input) => {
  // Manages to update package available for b2b -- // currently supported loyalty,website, billing, appointment and delievry

  return await await Promise.all([
    fetch(`${config.ApiGateway}/b2bPackageActivation`, {
      method: "POST",
      body: JSON.stringify(input),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return data;
    })
    .catch((err) => console.log("error in updating package", err));
};

export const loyaltyPointsAPI = async (input) => {
  // Manages to update package available for b2b -- // currently supported loyalty,website, billing, appointment and delievry

  return await Promise.all([
    fetch(`${config.ApiGateway}/updateloyaltyratio`, {
      method: "POST",
      body: JSON.stringify(input),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return data;
    })
    .catch((err) => console.log("error in updating package", err));
};

export const UpdateProfileAPI = async (editProfile) => {
  return await Promise.all([
    fetch(`${config.ApiGateway}/editProfile`, {
      method: "POST",
      body: JSON.stringify(editProfile),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return data;
    })
    .catch((err) => console.log(err));
};

// SHop Inventory Products

export const AddInventoryItemAPI = async (input) => {
  return await Promise.all([
    fetch(`${config.ApiGateway}/addInventoryItem`, {
      method: "POST",
      body: JSON.stringify({
        productList: input,
      }),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return data;
    })
    .catch((err) => console.log(err));
};

export const UpdateInventoryItemAPI = async (input) => {
  return await Promise.all([
    fetch(`${config.ApiGateway}/shopEditProducts`, {
      method: "POST",
      body: JSON.stringify(input),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return data;
    })
    .catch((err) => console.log(err));
};

export const DeleteInventoryItemAPI = async (input) => {
  return await Promise.all([
    fetch(`${config.ApiGateway}/deleteMasterProducts`, {
      method: "POST",
      body: JSON.stringify(input),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return data;
    })
    .catch((err) => console.log(err));
};
const getDataFromExternalAPI = async (data) => {
  if (data.status) {
    if (data.payloadResponseExceededLimit) {
      try {
        let uploadData = await (await fetch(data.data)).json();

        return {
          status: true,
          data: uploadData,
        };
      } catch (err) {
        return {
          status: false,
          data: [],
        };
      }
    } else {
      return {
        status: true,
        data: data.data || data,
      };
      // setTellcallingRecords(data.data || []);
    }

    // setTableLoading(false);
  } else {
    return {
      status: false,
      data: [],
    };
  }
};
