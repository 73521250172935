import React, { useState, useEffect } from "react";
import { Container, Col, Button, Row, Form, Modal } from "react-bootstrap";
import MaterialTable from "material-table";
import config from "../../config";
import Limit from "../../limit";
import { Link } from "react-router-dom";
import { ClickAwayListener } from "@material-ui/core";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default function OffersCreated() {
  let currentDate = new Date();
  const [state, setState] = useState({
    startDate: new Date(currentDate.setDate(currentDate.getDate() - 5))
      .toISOString()
      .substring(0, 10),
    endDate: new Date().toISOString().substring(0, 10),
  });

  const [offerType, setOffertype] = useState("offer");
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const [viewOffer, setviewOffer] = useState({});
  const [offerList, setOfferList] = useState([]);
  useEffect(() => {
    getOffers("offer");
  }, []);
  const getOffers = async (value) => {
    return await Promise.all([
      fetch(`${config.Elasticsearch.URL}/offers/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                {
                  match: {
                    offerType: value,
                  },
                },

                {
                  range: {
                    offerAddedDate: {
                      gte: state.startDate,
                      lte: state.endDate,
                      boost: 2.0,
                    },
                  },
                },
              ],
              must_not: [{ term: { pincode: Limit.size.ghoshakInternal } }],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        let tempdata = data.hits.hits.map((element) => element._source);
        let uniqueShopDetails = [];
        tempdata.forEach((elements) => {
          uniqueShopDetails.push(elements.shopId);
        });
        uniqueShopDetails = [...new Set(uniqueShopDetails)];
        let shopList = await getShop(uniqueShopDetails);
        let consolidatedList = await mergeShopAndOffer(tempdata, shopList);
        setOfferList(consolidatedList);
      })
      .catch((err) => console.log(err));
  };
  const getShop = async (shopId) => {
    return await Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              should: [
                {
                  terms: {
                    userId: shopId,
                  },
                },
              ],
              must_not: [{ term: { pincode: Limit.size.ghoshakInternal } }],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let tempdata = data.hits.hits.map((element) => element._source);

        return tempdata;
      })
      .catch((err) => console.log(err));
  };
  const mergeShopAndOffer = async (offers, shops) => {
    let consolidatedOffers = [];
    offers.map((elements) => {
      let getCustomerArr = shops.find(
        (items) => elements.shopId === items.userId
      );
      if (getCustomerArr !== undefined) {
        let appendObject = Object.assign({}, elements, getCustomerArr);

        consolidatedOffers.push(appendObject);
      }
    });
    return await consolidatedOffers;
  };
  const readOffer = (items) => {
    setviewOffer(items);
    setModalShow(true);
  };
  const exportPdf = () => {
    var doc = new jsPDF();
    let header = [
      "offer Image",

      "shop Name",
      "Address",
      "pincode",

      "offer details",
      "Created on",
      "Expiry on",
    ];

    // var columns = ["Bina ID", "Bina Adı", "Özellik"];
    // var rows = [
    //   [1, "Shaw", "Tanzania"],
    //   [2, "Nelson", "Kazakhstan"],
    //   [3, "Garcia", "Madagascar"],
    // ];
    let data = [];
    offerList.map((elements) => {
      data.push([
        elements["offerImageLocation"],
        elements["shopName"],
        elements["shopAddress"],
        elements["shopPincode"],

        elements["offerDetails"],
        elements["offerAddedDate"],
        elements["offerEndDate"],
      ]);
    });
    console.log(data);
    doc.autoTable(header, data, {
      bodyStyles: {
        minCellHeight: 25,
        minCellWidth: 25,
        overflow: "linebreak",
      },
      didDrawCell: (data) => {
        if (data.section === "body" && data.column.index === 0) {
          // const img = new Image();

          // img.src = ;

          var img = new Image();

          img.src = data.row.raw[0];

          console.log(data.row.raw[0]);

          doc.addImage(img, "JPEG", data.cell.x + 2, data.cell.y + 2, 25, 25);

          // Create an Image object

          // var img = new Image();
          // img.src = data.row.raw[0];
          // Add CORS approval to prevent a tainted canvas

          // Load the image

          // img.onload = (data) => console.log(data);

          // doc.addImage(img, "JPEG", data.cell.x + 2, data.cell.y + 2, 25, 25);

          // Usage:

          // "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/images/offers/default_offer_image.png";
          //  data.row.raw[0];
          // typeof offerList[data.row.index] == "undefined"
          //   ? "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/images/offers/default_offer_image.png"
          //   : offerList[data.row.index].offerImageLocation;

          // console.log(offerList[32]);
          // typeof offerList[data.row.index] == "undefined"
          //   ? doc.addImage(img, "PNG", data.cell.x + 2, data.cell.y + 2, 25, 25)

          // console.log(img);

          // doc.addImage(img, "JPG", data.cell.x + 2, data.cell.y + 2, 25, 25);
        }
      },
    });
    // doc.save("table.pdf");
  };

  return (
    <Container fluid>
      <div style={{ width: "100%", height: "50px" }}></div>
      <div className="orderHeader">
        <h2 className="orderHeaderText">Offer Creation </h2>
      </div>
      <Row
        style={{
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <Col sm={6}>
          <Form.Row style={{ marginTop: 1 + "%" }}>
            <Col sm={2}>
              <strong>Offer Type</strong>
            </Col>

            <Col sm={4}>
              <Form.Check
                label="Offer"
                type="radio"
                name="offerType"
                onChange={(e) => {
                  setOffertype(e.target.value);
                  getOffers(e.target.value);
                }}
                value="offer"
              />
            </Col>
            <Col sm={4}>
              <Form.Check
                label="Collection"
                type="radio"
                name="offerType"
                onChange={(e) => {
                  setOffertype(e.target.value);
                  getOffers(e.target.value);
                }}
                value="item"
              />
            </Col>
          </Form.Row>
        </Col>
        <Col sm={6}>
          <Form.Row>
            <Col>
              <strong>Offer Created Range </strong>
            </Col>
            <Col>
              <Form.Control
                type="date"
                name="startDate"
                value={state.startDate}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                  getOffers(offerType);
                }}
              />
            </Col>

            <Col>
              <Form.Control
                type="date"
                name="endDate"
                value={state.endDate}
                min={state.startDate}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                  getOffers(offerType);
                }}
              />
            </Col>
          </Form.Row>
        </Col>
    
    
    
    
    
    
    
      </Row>
      <div style={{ width: "100%", height: "30px" }}></div>
      <Row>
        <Col sm={6}>
          <Form.Group>
            <Col sm={12}>
              <Form.Label>Approved Status</Form.Label>
            </Col>
            <Col sm={6}>
              <Form.Control as="select">
                <option>Yes</option>
                <option>No</option>
              </Form.Control>
            </Col>
          </Form.Group>
        </Col>
        <Col style={{ textAlign: "right" }} sm={6}>
          <Form.Group>
            <Col sm={12}>
              <Form.Label>Export as Pdf</Form.Label>
            </Col>
            <Col sm={12}>
              <Button onClick={() => exportPdf()}>Download</Button>
            </Col>
          </Form.Group>
        </Col>

        <Col sm={12}>
          <MaterialTable
            title="Offers"
            columns={[
              {
                title: "Offer Imge",
                field: "offerImageLocation",
                render: (rowData) => (
                  <img
                    // src={JSON.parse(item.offerImageLocation)}
                    src={rowData.offerImageLocation}
                    alt={rowData.offerImageLocation}
                    width="200"
                    height="200"
                  ></img>
                ),
                editComponent: (props) => (
                  <img
                    // src={JSON.parse(item.offerImageLocation)}
                    src={props.offerImageLocation}
                    alt={props.offerImageLocation}
                    width="200"
                    height="200"
                  ></img>
                ),
              },
              {
                title: "Shop Id",
                field: "userId10Digit",
                render: (rowData) => (
                  <Link
                    to={{
                      pathname: "/merchant",
                      search: `?userid=${rowData.userId10Digit}`,
                    }}
                  >
                    {rowData.userId10Digit}
                  </Link>
                ),
              },

              {
                title: "Shop Name",
                field: "shopName",
              },
              {
                title: "Phone Number",
                field: "userPhoneNumber",
              },
              {
                title: "Offer type",
                field: "offerType",
              },
              {
                title: "Details",
                field: "offerDetails",
              },

              {
                title: "Review ",
                field: "review",
              },
              {
                title: "Pincode",
                field: "shopPincode",
              },
              {
                title: "Status",
                field: "offerStatus",
              },
              {
                title: "Created on ",
                field: "offerAddedDate",
              },
              {
                title: "Expiry Date",
                field: "offerEndDate",
              },
              {
                title: "Reviewed on ",
                field: "reviewedOn",
              },
              {
                title: "Reviewed By",
                field: "reviewedBy",
              },
              {
                title: "Action",
                field: "view",
                render: (rowData) => (
                  <Button
                    onClick={() => {
                      readOffer(rowData);
                    }}
                  >
                    View Shop
                  </Button>
                ),
              },
            ]}
            data={offerList}
            options={{
              exportButton: true,

              pageSize: 30,
              pageSizeOptions: [5, 10, 20, 100],
              grouping: false,
              filtering: false,
            }}
          />
        </Col>
      </Row>
      <Modal className="viewCartDetails" show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              OFFER DATE
            </Form.Label>
            <Col>
              <Form.Control
                plaintext
                type="date"
                name="offerStartDate"
                value={viewOffer.offerStartDate}
              ></Form.Control>
            </Col>

            <Col>
              <Form.Control
                plaintext
                type="date"
                name="offerEndDate"
                value={viewOffer.offerEndDate}
              ></Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              OFFER TIMING
            </Form.Label>
            <Col>
              <Form.Control
                plaintext
                type="time"
                name="offerStartTime"
                value={viewOffer.offerStartTime}
              ></Form.Control>
            </Col>
            <Col>
              <Form.Control
                plaintext
                type="time"
                name="offerEndTime"
                value={viewOffer.offerEndTime}
              ></Form.Control>
            </Col>
          </Form.Group>
          {!(
            viewOffer.offerType === "item" && viewOffer.shopType === "Mobile"
          ) && (
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                OFFER DESCRIPTION
              </Form.Label>
              <Col>
                <Form.Control
                  plaintext
                  as="textarea"
                  rows="4"
                  name="offerDetails"
                  value={viewOffer.offerDetails}
                ></Form.Control>
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              OFFER ADDED ON
            </Form.Label>
            <Col>
              <Form.Control
                plaintext
                type="text"
                name="offerAddedDate"
                value={viewOffer.offerAddedDate}
              ></Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              OFFER ORIGINAL PRICE
            </Form.Label>
            <Col>
              <Form.Control
                plaintext
                type="text"
                pattern="[0-9]{1,10}"
                name="offerOriginalPrice"
                value={viewOffer.offerOriginalPrice}
              ></Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="4">
              OFFER NEW PRICE
            </Form.Label>
            <Col>
              <Form.Control
                plaintext
                type="text"
                pattern="[0-9]{1,10}"
                name="offerNewPrice"
                value={viewOffer.offerNewPrice}
              ></Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              OFFER DISCOUNT
            </Form.Label>
            <Col>
              <Form.Control
                plaintext
                type="text"
                name="offerDiscount"
                //

                value={viewOffer.offerDiscount}
              ></Form.Control>
            </Col>
          </Form.Group>
          {viewOffer.offerType === "item" && viewOffer.shopType === "Textile" && (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  OFFER CATEGORY
                </Form.Label>
                <Col>
                  <Form.Control
                    plaintext
                    type="text"
                    name="offerCategory"
                    value={viewOffer.offerCategory}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  SUB CATEGORY
                </Form.Label>
                <Col>
                  <Form.Control
                    plaintext
                    type="text"
                    name="offerSubCategory"
                    value={viewOffer.offerSubCategory}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  OFFER TAG
                </Form.Label>
                <Col>
                  <Form.Control
                    plaintext
                    type="text"
                    name="offerTag"
                    value={viewOffer.offerTag}
                  ></Form.Control>
                </Col>
              </Form.Group>
            </>
          )}

          {viewOffer.offerType === "item" && viewOffer.shopType === "Mobile" && (
            <>
              <Form.Group as={Row}>
                <Form.Label className="asterik" column sm="4">
                  Category
                </Form.Label>
                <Col>
                  <Form.Control
                    plaintext
                    name="offerCategory"
                    value={viewOffer.offerCategory}
                    required
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label className="asterik" column sm="4">
                  Brand
                </Form.Label>
                <Col>
                  <Form.Control
                    plaintext
                    name="mobileBrand"
                    value={viewOffer.mobileBrand}
                    required
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label className="asterik" column sm="4">
                  Mobile Model
                </Form.Label>
                <Col>
                  <Form.Control
                    plaintext
                    type="text"
                    name="mobileModel"
                    value={viewOffer.mobileModel}
                    required
                  ></Form.Control>
                </Col>
              </Form.Group>
              {viewOffer.offerCategory === "mobile" && (
                <>
                  <Form.Group as={Row}>
                    <Form.Label className="asterik" column sm="4">
                      Memory
                    </Form.Label>
                    <Col>
                      <Form.Control
                        plaintext
                        type="text"
                        name="mobileMemory"
                        value={viewOffer.mobileMemory}
                        required
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Memory Extendable
                    </Form.Label>
                    <Col>
                      <Form.Control
                        plaintext
                        type="text"
                        name="mobileMemoryExtendable"
                        value={viewOffer.mobileMemoryExtendable}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Dimension
                    </Form.Label>
                    <Col>
                      <Form.Control
                        plaintext
                        type="text"
                        name="mobileDimensions"
                        placeholder="15.7 * 0.9"
                        value={viewOffer.mobileDimensions}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label className="asterik" column sm="4">
                      RAM
                    </Form.Label>
                    <Col>
                      <Form.Control
                        plaintext
                        type="text"
                        name="mobileRAM"
                        value={viewOffer.mobileRAM}
                        required
                      ></Form.Control>
                    </Col>
                    <Form.Label className="asterik" column sm="4">
                      OS
                    </Form.Label>
                    <Col>
                      <Form.Control
                        plaintext
                        name="mobileOS"
                        value={viewOffer.mobileOS}
                        required
                      >
                        <option value="">{"Select OS"}</option>
                        {this.state.OsList}
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Front Camera Pixel
                    </Form.Label>
                    <Col>
                      <Form.Control
                        plaintext
                        type="text"
                        pattern="[0-9]{1,10}"
                        name="mobileFrontFacingCamera"
                        value={viewOffer.mobileFrontFacingCamera}
                        placeholder="Mega Pixel"
                      ></Form.Control>
                    </Col>
                    <Form.Label column sm="4">
                      Rear Camera pixel
                    </Form.Label>
                    <Col>
                      <Form.Control
                        plaintext
                        type="text"
                        pattern="[0-9]{1,10}"
                        name="mobileRearFacingCamera"
                        value={viewOffer.mobileRearFacingCamera}
                        placeholder="Mega Pixel"
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                </>
              )}

              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Mobile Color
                </Form.Label>
                <Col>
                  <Form.Control
                    plaintext
                    type="text"
                    name="mobileColor"
                    value={viewOffer.mobileColor}
                  ></Form.Control>
                </Col>
                <Form.Label column sm="4">
                  Mobile Weight
                </Form.Label>
                <Col>
                  <Form.Control
                    plaintext
                    type="text"
                    pattern="[0-9]{1,10}"
                    name="mobileWeight"
                    value={viewOffer.mobileWeight}
                    placeholder="In Gms"
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Other Details
                </Form.Label>
                <Col>
                  <Form.Control
                    plaintext
                    as="textarea"
                    rows="6"
                    name="mobileOtherDetails"
                    value={viewOffer.mobileOtherDetails}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Service Details
                </Form.Label>
                <Col>
                  <Form.Control
                    plaintext
                    as="textarea"
                    rows="6"
                    name="mobileServiceDetails"
                    value={viewOffer.mobileServiceDetails}
                  ></Form.Control>
                </Col>
              </Form.Group>
            </>
          )}

          <Form.Group as={Row}>
            <Form.Label column sm="4">
              OFFER STATUS
            </Form.Label>
            <Col>
              <Form.Control
                plaintext
                name="offerStatus"
                value={viewOffer.offerStatus}
                required
              ></Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              OFFER TYPE
            </Form.Label>
            <Col>
              <Form.Control
                plaintext
                type="text"
                name="offerType"
                value={viewOffer.offerType}
              ></Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="4">
              REVIEW COMMENT
            </Form.Label>
            <Col>
              <Form.Control
                plaintext
                as="textarea"
                rows="4"
                name="reviewComment"
                value={viewOffer.reviewComment}
              ></Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="4">
              REVIEW COMMENT
            </Form.Label>
            <Col>
              <Form.Control
                plaintext
                as="textarea"
                rows="4"
                name="reviewComment"
                value={viewOffer.reviewComment}
              ></Form.Control>
            </Col>
          </Form.Group>
        </Modal.Body>
      </Modal>
    
    
    
    
    
    
    
    
    
    
      <div style={{ width: "100%", height: "50px" }}></div>
    </Container>
  );
}
