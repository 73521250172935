// Import Dependencies

import MaterialTable from "material-table";
import React, { useEffect, useState, useContext } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import config from "../config";
import { useHistory } from "react-router-dom";
import { store } from "../provider";

function Gamification(props) {
  let history = useHistory();
  const context = useContext(store);

  const [gameClaims, setGameClaims] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [transactionModalOpen, setTransactionModalOpen] = useState(false);
  const [tempstatus, setTempStatus] = useState("");
  const [reason, setReason] = useState("");

  const [transcationId, setTranscationId] = useState("");
  const [transcationDate, setTranscationDate] = useState("");
  const [transcationNotes, setTranscationNotes] = useState("");

  const [tempRowData, setTempRowData] = useState("");
  const [filteredGameClaims, setFilteredGameClaims] = useState([]);

  const [currentTab, setCurrentTab] = useState("unpaid");
  useEffect(() => {
    fetchAllRequest();
  }, []);

  const fetchAllRequest = async () => {
    let response = await fetch(`${config.ApiGateway}/getGamificationClaims`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
    let sortedArray = response.Items.sort(
      (a, b) => parseInt(b.createdAt) - parseInt(a.createdAt)
    );

    setFilteredGameClaims(sortedArray.filter((item) => item.status != "paid"));

    setGameClaims(sortedArray);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleonSubmit = (e) => {
    e.preventDefault();
    if (tempstatus === "rejected" && reason === "")
      return alert("Enter reason for rejection");
    if (tempstatus === "paid" && transcationId === "")
      return alert("Enter payment id");
    updateClaimStaus();
  };

  const updateClaimStaus = async () => {
    await fetch(`${config.ApiGateway}/updateGamificationClaim`, {
      method: "POST",
      body: JSON.stringify({
        transactionId: tempRowData.transactionId,
        status: tempstatus,
        reason: reason,
        paymentId: transcationId,
        shopId: tempRowData.shopId,
        points: tempRowData.points,
        updatedBy: context.state.currentUser,
        transcationDate,
        transcationNotes,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    fetchAllRequest();
    setModalOpen(false);
    setTransactionModalOpen(false);
  };

  const filterGameClaims = (paid) => {
    if (paid) {
      setCurrentTab("paid");
      setFilteredGameClaims(
        gameClaims.filter((item) => item.status === "paid")
      );
    } else {
      setCurrentTab("unpaid");
      setFilteredGameClaims(gameClaims.filter((item) => item.status != "paid"));
    }
  };

  return (
    <>
      <Container fluid>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Gamification</h2>
        </div>
        <div style={{ margin: 10 }}>
          <Button
            style={{ margin: 10 }}
            onClick={() => filterGameClaims(false)}
          >
            {" "}
            Unpaid{" "}
          </Button>
          <Button style={{ margin: 10 }} onClick={() => filterGameClaims(true)}>
            {" "}
            Paid{" "}
          </Button>
        </div>
        <MaterialTable
          actions={[
            (rowData) => ({
              icon: "save",
              tooltip: "Change status",
              disabled: rowData.status === "paid",
              hidden: rowData.status === "paid",
              onClick: (event, rowData) => {
                console.log(context.state.currentUser);

                setTempStatus(rowData.status);
                setTempRowData(rowData);
                setModalOpen(true);
              },
            }),
            (rowData) => ({
              icon: "payment",
              tooltip: "Transaction",
              disabled: rowData.status != "accepted",
              hidden: rowData.status != "accepted",
              onClick: (event, rowData) => {
                setTempStatus("paid");
                setTempRowData(rowData);
                setTransactionModalOpen(true);
              },
            }),
            (rowData) => ({
              icon: "details",
              tooltip: "more details",
              onClick: (event, rowData) => {
                history.push({
                  pathname: "gamificationHistory",
                  state: rowData.shopId,
                });
              },
            }),
          ]}
          columns={[
            { title: "shop name", field: "shopName" },
            { title: "shop id", field: "userId10Digit" },
            { title: "date", field: "transactionDate" },
            { title: "claimed points", field: "points" },
            { title: "status", field: "status" },
            { title: "updated by", field: "updatedBy" },
            { title: "paid by", field: "paidBy" },
            { title: "Paid Date", field: "paidDate" },
            { title: "paymentId", field: "paymentId" },
          ]}
          data={filteredGameClaims}
          title={currentTab + " claims"}
          options={{
            actionsColumnIndex: -1,
            search: true,
            exportButton: true,
          }}
        />
        <Modal
          show={modalOpen}
          style={{ width: 500, margin: "auto" }}
          onHide={handleClose}
        >
          <>
            <Modal.Header closeButton>
              <Modal.Title>Change status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form style={{ background: "#FFF" }} onSubmit={handleonSubmit}>
                <>
                  <Form.Label>Set Status </Form.Label>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="radio"
                      label="Accept"
                      name="status"
                      value="accepted"
                      checked={tempstatus === "accepted"}
                      onChange={() => setTempStatus("accepted")}
                    />
                    <Form.Check
                      type="radio"
                      label="Reject"
                      name="status"
                      value="reject"
                      checked={tempstatus === "rejected"}
                      onChange={() => setTempStatus("rejected")}
                    />
                  </Form.Group>
                  {tempstatus === "rejected" && (
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Reason for rejection </Form.Label>
                      <Form.Control
                        placeholder="Reason"
                        defaultValue={tempRowData.reason || ""}
                        onChange={(input) => setReason(input.target.value)}
                      />
                    </Form.Group>
                  )}
                </>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Modal.Body>
          </>
        </Modal>

        <Modal
          show={transactionModalOpen}
          style={{ width: 500, margin: "auto" }}
          onHide={() => setTransactionModalOpen(false)}
        >
          <>
            <Modal.Header closeButton>
              <Modal.Title>Transaction</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form style={{ background: "#FFF" }} onSubmit={handleonSubmit}>
                <>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Label>Transaction Id</Form.Label>
                    <Form.Control
                      placeholder="transaction Id"
                      onChange={(input) => setTranscationId(input.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Label>Transaction Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={transcationDate}
                      name="paidDate"
                      required
                      onChange={(e) => setTranscationDate(e.target.value)}
                    ></Form.Control>
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                        placeholder="Notes"
                        onChange={(input) =>
                          setTranscationNotes(input.target.value)
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Form.Group>
                </>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Modal.Body>
          </>
        </Modal>
      </Container>
    </>
  );
}

export default Gamification;
