import React, { useEffect, useState, useContext, useMemo } from "react";
import { Container, Col, Button, Row, Form } from "react-bootstrap";
import config from "../config";
import moment from "moment";
import Limit from "../limit";
import GetAppIcon from "@material-ui/icons/GetApp";
import PeopleIcon from "@material-ui/icons/People";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Link, useHistory } from "react-router-dom";
import { store } from "../provider";
import Skeleton from "@material-ui/lab/Skeleton";
export default function CustomerManagement() {
  var initialDate = new Date();
  initialDate.setDate(initialDate.getDate() - 15);
  const [accquiredList, setAccquiredList] = useState([]);
  const [refferalCount, setRefferalCount] = useState("0");
  const [gcustomers, setGcustomers] = useState([]);
  const history = useHistory();
  const [activate, setActivate] = useState([]);
  const [engage, setEngage] = useState([]);
  const [retain, setRetain] = useState([]);
  const [referals, setReferals] = useState([]);
  const [activeCustomers, setActiveCustomers] = useState([]);
  const context = useContext(store);
  const [days, setDays] = useState("15");
  const [isLoading, setIsLoading] = useState(true);
  const [selectMonthLifeCycle, setSelectMonthLifeCycle] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: initialDate.toISOString().substring(0, 10),
    endDate: new Date().toISOString().substring(0, 10),
  });
  const [monthLifeCycleValue, setMonthLifeCycleValue] = useState("");
  const [month, setMonth] = useState([
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUNE",
    "JULY",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ]);
  const calculateBillByNumber = async (customerDetails, bills) => {
    let billedCustomer = {};
    bills.map((elements) => {
      let getCustomerArr = customerDetails.find(
        (items) => elements.customerPhoneNumber === items.userPhoneNumber
      );
      if (getCustomerArr !== undefined) {
        if (billedCustomer[getCustomerArr.userId] === undefined) {
          billedCustomer[getCustomerArr.userId] = {
            bill: 0,
            userName: "",
            userPhoneNumber: "",
            userPincode: "",
            userId10Digit: "",
          };
        }

        billedCustomer[getCustomerArr.userId].bill += 1;
        billedCustomer[getCustomerArr.userId].userName = elements.customerName;
        billedCustomer[getCustomerArr.userId].userPhoneNumber =
          elements.customerPhoneNumber;
        billedCustomer[getCustomerArr.userId].userId10Digit =
          getCustomerArr.userId10Digit;
        billedCustomer[getCustomerArr.userId].userPincode =
          getCustomerArr.userPincode;
      }
    });
    return billedCustomer;
  };

  const splitCustByActivity = async (billedCustomer, activity) => {
    let result = await Promise.all(
      activity.map((elements) => {
        let mergeBilled = Object.entries(billedCustomer).find(
          ([key, value]) => elements.userId == key
        );
        if (mergeBilled !== undefined) {
          elements.bill = mergeBilled[1].bill;
          elements.active =
            parseInt(mergeBilled[1].bill) + parseInt(elements.active);
          return elements;
        } else {
          return elements;
        }
      })
    );

    return result;
  };

  useEffect(() => {
    var customerDetails = [];

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          _source: {
            includes: [
              "userPhoneNumber",
              "userId",
              "userType",
              "userPincode",
              "userName",
              "userId10Digit",
            ],
          },
          query: {
            bool: {
              must: [{ match: { userType: "Customer" } }],
              must_not: {
                bool: {
                  must: [{ term: { userPincode: "111111" } }],
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        // get first and last day for all month

        let initialDate = new Date();
        let currentYear = initialDate.getFullYear();
        let monthLifeCycle = [];
        for (let i = 0; i < 12; i++) {
          let firstDay = new Date(currentYear, i, 1);
          let lastDay = new Date(currentYear, i + 1, 0);

          monthLifeCycle.push(
            <option
              value={[
                [moment(firstDay).format("YYYY-MM-DD")],
                [moment(lastDay).format("YYYY-MM-DD")],
              ]}
            >
              {month[0 + i]}
            </option>
          );
        }

        let tempdata = data.hits.hits.map((element) => element._source);

        tempdata.forEach((elements) => {
          customerDetails.push({
            userId: elements.userId,

            userId10Digit: elements.userId10Digit,
            userName: elements.userName,
            userPhoneNumber: elements.userPhoneNumber,
            userPincode: elements.userPincode,
          });
        });
        setSelectMonthLifeCycle(monthLifeCycle);
        setGcustomers(customerDetails);
      })

      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    findCustomerActivity();
  }, [gcustomers, dateRange]);
  const findCustomerActivity = async () => {
    console.log(days, "triggered");
    await Promise.all([
      fetch(`${config.ApiGateway}/customerManagement`, {
        method: "POST",
        body: JSON.stringify({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        setReferals(data.refferals);
        setRefferalCount(data.successfulRefferalCount);
        setAccquiredList(data.accquiredList);
        setActiveCustomers(data.activityDone);
        let billCount = await calculateBillByNumber(gcustomers, data.billList);
        let consolidatedList = await splitCustByActivity(
          billCount,
          data.activityDone
        );

        let activateList = [];
        let engageList = [];
        let retainList = [];

        consolidatedList.forEach((elements) => {
          if (parseInt(elements.active) <= Limit.activateLimit)
            activateList.push(elements);
          if (
            parseInt(elements.active) >= Limit.engageLimit &&
            parseInt(elements.active) <= Limit.retainLimit - 1
          )
            engageList.push(elements);
          if (parseInt(elements.active) >= Limit.retainLimit)
            retainList.push(elements);
        });

        setActivate(activateList);
        setEngage(engageList);
        setRetain(retainList);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const handleClick = async (value) => {
    let lastActivityDays = value;
    let currentDate = new Date();
    let date1 = new Date(
      currentDate.setDate(currentDate.getDate() - lastActivityDays)
    )
      .toISOString()
      .substring(0, 10);
    const date2 = new Date().toISOString().substring(0, 10);
    setDateRange((prevState) => ({
      ...prevState,
      startDate: date1,
      endDate: date2,
    }));
    setIsLoading(true);
    setDays(value);
  };

  return (
    <>
      <Container>
        <div style={{ width: "100%", height: "100px" }}></div>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Customer Management</h2>
        </div>
        <div className="flexCardHeader">
          <label className="flexCardHeaderlabel">
            <Form.Row>
              <Form.Label>Filter By Month</Form.Label>
              <Form.Control
                as="select"
                value={monthLifeCycleValue}
                onChange={(e) => {
                  let value = e.target.value;

                  setDateRange((prevState) => ({
                    ...prevState,
                    startDate: value.substring(0, 10),
                    endDate: value.substring(11, 21),
                  }));
                  setMonthLifeCycleValue(value);
                  setDays("custom");
                  setIsLoading(true);
                }}
              >
                {selectMonthLifeCycle}
              </Form.Control>
            </Form.Row>
          </label>

          <nav class="site-nav">
            <ul className="flexCardUl">
              <li
                className={days === "15" ? "active" : ""}
                onClick={() => handleClick("15")}
              >
                <a href="#">15</a>
              </li>
              <li
                className={days === "30" ? "active" : ""}
                onClick={() => handleClick("30")}
              >
                <a href="#">30</a>
              </li>
              <li
                className={days === "60" ? "active" : ""}
                onClick={() => handleClick("60")}
              >
                <a href="#">60</a>
              </li>
              <li
                className={days === "90" ? "active" : ""}
                onClick={() => handleClick("90")}
              >
                <a href="#">90</a>
              </li>
              <li
                className={days === "182" ? "active" : ""}
                onClick={() => handleClick("182")}
              >
                <a href="#">182</a>
              </li>
              <li
                className={days === "365" ? "active" : ""}
                onClick={() => handleClick("365")}
              >
                <a href="#">365</a>
              </li>
              <li className={days === "custom" ? "active" : ""}>
                <a href="#">Custom</a>
              </li>
            </ul>
          </nav>
        </div>

        <div style={{ width: "100%", height: "10px" }}></div>
        <div className="orderHeader">
          <h2 className="ordersubHeaderText">Acquired Customers</h2>
          <button
            onClick={async () => {
              await context.dispatch({
                type: "Acquired",
                customerDetails: accquiredList,
              });
              history.push(`/customerDetails`);
            }}
            className="orderDashboardViewallBtn"
          >
            View all
          </button>
        </div>
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton animation="wave" width={340} height={138} />
          </div>
        ) : (
          <Row className="subContentRow">
            <Col sm={4} className="splitCol">
              <div className="cardBodyRow">
                <Row>
                  <Col className="cardBodyDelivery">
                    <p className="tabRowContentSubHeading">Total Downloads</p>
                    <h3>{accquiredList.length}</h3>
                  </Col>
                  <Col
                    style={{ justifyContent: "center" }}
                    className="cardBodyDeliveryIcon"
                  >
                    <GetAppIcon fontSize="large" />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
        <div style={{ width: "100%", height: "10px" }}></div>
        <div className="orderHeader">
          <h2 className="ordersubHeaderText">Refferals </h2>
          <button
            onClick={async () => {
              await context.dispatch({
                type: "Referals",
                customerDetails: referals,
              });
              history.push(`/customerDetails`);
            }}
            className="orderDashboardViewallBtn"
          >
            View all
          </button>
        </div>
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton animation="wave" width={340} height={138} />
          </div>
        ) : (
          <Row className="subContentRow">
            <Col sm={4} className="splitCol">
              <div className="cardBodyRow">
                <Row>
                  <Col className="cardBodyDelivery">
                    <p className="tabRowContentSubHeading">
                      Successful Referrals
                    </p>
                    <h3>{refferalCount}</h3>
                  </Col>
                  <Col
                    style={{ justifyContent: "center" }}
                    className="cardBodyDeliveryIcon"
                  >
                    <PeopleIcon fontSize="large" />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}

        <div style={{ width: "100%", height: "10px" }}></div>
        <div className="orderHeader">
          <h2 className="ordersubHeaderText">Activate </h2>
          <button
            onClick={async () => {
              await context.dispatch({
                type: "Activate",
                customerDetails: activate,
              });
              history.push(`/customerDetails`);
            }}
            className="orderDashboardViewallBtn"
          >
            View all
          </button>
        </div>
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton animation="wave" width={340} height={138} />
          </div>
        ) : (
          <Row className="subContentRow">
            <Col sm={4} className="splitCol">
              <div className="cardBodyRow">
                <Row>
                  <Col className="cardBodyDelivery">
                    <p className="tabRowContentSubHeading">Total Customers</p>
                    <h3>{activate.length}</h3>
                  </Col>
                  <Col
                    style={{ justifyContent: "center" }}
                    className="cardBodyDeliveryIcon"
                  >
                    <PeopleIcon fontSize="large" />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
        <div style={{ width: "100%", height: "10px" }}></div>

        <div className="orderHeader">
          <h2 className="ordersubHeaderText">Engage </h2>
          <button
            onClick={async () => {
              await context.dispatch({
                type: "Engage",
                customerDetails: engage,
              });
              history.push(`/customerDetails`);
            }}
            className="orderDashboardViewallBtn"
          >
            View all
          </button>
        </div>
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton animation="wave" width={340} height={138} />
          </div>
        ) : (
          <Row className="subContentRow">
            <Col sm={4} className="splitCol">
              <div className="cardBodyRow">
                <Row>
                  <Col className="cardBodyDelivery">
                    <p className="tabRowContentSubHeading">Total Customers</p>
                    <h3>{engage.length}</h3>
                  </Col>
                  <Col
                    style={{ justifyContent: "center" }}
                    className="cardBodyDeliveryIcon"
                  >
                    <PeopleIcon fontSize="large" />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
        <div style={{ width: "100%", height: "10px" }}></div>
        <div className="orderHeader">
          <h2 className="ordersubHeaderText">Retain </h2>
          <button
            onClick={async () => {
              await context.dispatch({
                type: "Retain",
                customerDetails: retain,
              });
              history.push(`/customerDetails`);
            }}
            className="orderDashboardViewallBtn"
          >
            View all
          </button>
        </div>

        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton animation="wave" width={340} height={138} />
          </div>
        ) : (
          <Row className="subContentRow">
            <Col sm={4} className="splitCol">
              <div className="cardBodyRow">
                <Row>
                  <Col className="cardBodyDelivery">
                    <p className="tabRowContentSubHeading">Total Customers</p>
                    <h3>{retain.length}</h3>
                  </Col>
                  <Col
                    style={{ justifyContent: "center" }}
                    className="cardBodyDeliveryIcon"
                  >
                    <PeopleIcon fontSize="large" />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}

        <div style={{ width: "100%", height: "10px" }}></div>
        <div className="orderHeader">
          <h2 className="ordersubHeaderText">Inactive Users </h2>
        </div>

        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton animation="wave" width={340} height={138} />
          </div>
        ) : (
          <Row className="subContentRow">
            <Col sm={4} className="splitCol">
              <div className="cardBodyRow">
                <Row>
                  <Col className="cardBodyDelivery">
                    <p className="tabRowContentSubHeading">Total Customers</p>
                    <h3>
                      {parseInt(gcustomers.length) -
                        parseInt(activeCustomers.length)}
                    </h3>
                  </Col>
                  <Col
                    style={{ justifyContent: "center" }}
                    className="cardBodyDeliveryIcon"
                  >
                    <PeopleIcon fontSize="large" />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={4} className="splitCol">
              <div className="cardBodyRow">
                <Row>
                  <Col className="cardBodyDelivery">
                    <p className="tabRowContentSubHeading">In percentage</p>
                    <h3>
                      {(
                        ((gcustomers.length - activeCustomers.length) /
                          gcustomers.length) *
                        100
                      ).toFixed(2)}{" "}
                      {" %"}
                    </h3>
                  </Col>
                  <Col
                    style={{ justifyContent: "center" }}
                    className="cardBodyDeliveryIcon"
                  >
                    <ArrowDropDownIcon
                      style={{ color: "red" }}
                      fontSize="large"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
