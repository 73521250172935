import React, { Component } from "react";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { Auth } from "aws-amplify";
import config from "../config";

export default class ShopAddedDelivery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId10Digit: "",
      userInformation: {},
      getShopDetails: true,
      productShopType: "",
      masterOptions: [],
    };
    this.reset = this.state;
    this.getShop = this.getShop.bind(this);
    this.onChange = this.onChange.bind(this);
    this.addProductToShops = this.addProductToShops.bind(this);
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  addProductToShops = (e) => {
    e.preventDefault();

    if (this.state.productShopType == "") {
      return alert("Select Product Shop Type");
    }
    Promise.all([
      fetch(
        `${config.ApiGateway}/shopAddedDelivery`,

        {
          method: "POST",

          body: JSON.stringify({
            shopId: this.state.shopId,
            productShopType: this.state.productShopType,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert("products added to shops successfully");
        this.setState(this.reset);
      })
      .catch((err) => console.log(err));
  };
  getShop(e) {
    e.preventDefault();

    Promise.all([
      fetch(
        `${config.ApiGateway}/getCouponLimit`,

        {
          method: "POST",

          body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        if (Object.keys(data).length > 0) {
          this.setState({
            userInformation: data,
            getShopDetails: false,
            shopId: data.shopId,
          });
        } else {
          alert("Entered shop id not found");
        }
      })
      .catch((err) => console.log(err));
  }
  componentDidMount = async () => {
    try {
      const masterFields = await fetch(`${config.json.URL}/masterList.json`);
      var MasterJSON = await masterFields.json();
    } catch (error) {
      console.log(error);
    }
    let getMasterType = [];
    MasterJSON.masterList.forEach((elements) => {
      getMasterType.push(
        <option value={elements.type}>{elements.type}</option>
      );
    });
    this.setState({
      masterOptions: getMasterType,
    });
  };
  render() {
    console.log(this.state.shopId);
    return (
      <Container>
        <Row>
          <div style={{ width: "100%", height: "40px" }}></div>
          <div className="orderHeader">
            <h2 className="orderHeaderText">Port Products to Shop</h2>
          </div>

          <div className="vertical-space-30"></div>
          <Col>
            {this.state.getShopDetails ? (
              <>
                <Form onSubmit={this.getShop}>
                  <Row style={{ marginTop: 10 + "%" }}>
                    <Col md={{ span: 7, offset: 2 }}>
                      <Form.Label>Enter Shop Id</Form.Label>
                      <Form.Control
                        pattern="^[0-9]{7,13}"
                        name="userId10Digit"
                        onChange={this.onChange}
                        autoFocus={true}
                        autoComplete={true}
                        value={this.state.userId10Digit}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "15px" }}>
                    <Col
                      md={{ span: 7, offset: 2 }}
                      style={{ textAlign: "right" }}
                    >
                      <Button type="submit">Submit</Button>
                    </Col>
                  </Row>
                </Form>
              </>
            ) : (
              <>
                <Form onSubmit={this.addProductToShops}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      SHOP ID
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        type="text"
                        onChange={this.offerOnchange}
                        name="shopId"
                        disabled={true}
                        value={this.state.userInformation["userId10Digit"]}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      USERNAME
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        type="text"
                        onChange={this.offerOnchange}
                        name="shopId"
                        disabled={true}
                        value={this.state.userInformation["userName"]}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      PHONE NUMBER
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        type="text"
                        onChange={this.offerOnchange}
                        name="shopId"
                        disabled={true}
                        value={this.state.userInformation["userPhoneNumber"]}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      SHOP NAME
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        type="text"
                        onChange={this.offerOnchange}
                        name="shopId"
                        disabled={true}
                        value={this.state.userInformation["shopName"]}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      SHOP ADDRESS
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        type="text"
                        onChange={this.offerOnchange}
                        name="shopId"
                        disabled={true}
                        value={this.state.userInformation["shopAddress"]}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      SHOP TYPE
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        type="text"
                        onChange={this.offerOnchange}
                        name="shopType"
                        disabled={true}
                        value={this.state.userInformation["shopType"]}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      SHOP PINCODE
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        type="text"
                        onChange={this.offerOnchange}
                        name="shopPincode"
                        disabled={true}
                        value={this.state.userInformation["userPincode"]}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Product Shop Type
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        onChange={this.onChange}
                        name="productShopType"
                        as="select"
                      >
                        <option value="">Select Product Shop Type</option>
                        {this.state.masterOptions}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Button style={{ textAlign: "right" }} type="submit">
                    Submit
                  </Button>
                </Form>
              </>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
