import React, { Component } from "react";
import { Container, Form, Button, Row, Col, Modal } from "react-bootstrap";
import { Auth } from "aws-amplify";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import config from "../config";
import MaterialTable from "material-table";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { ListItemSecondaryAction } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 4,
  },
});
class ViewOnlineCoupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponObject: {},
      viewCoupons: [],
      viewCouponDetails: false,
      editForm: false,
      indexValue: -1,
      addCouponValue: 0,
      userId10Digit: "",
      showAddShops: true,
      shopList: [],
      showRedeemedUsers: false,
      redeemedUserList: [],
    };
    this.viewCouponsById = this.viewCouponsById.bind(this);
    this.addCouponOnchange = this.addCouponOnchange.bind(this);
    this.updateCoupon = this.updateCoupon.bind(this);
    this.getShop = this.getShop.bind(this);
    this.onChange = this.onChange.bind(this);
    this.addCoupons = this.addCoupons.bind(this);
    this.getReedemedUsers = this.getReedemedUsers.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.updateExpiry = this.updateExpiry.bind(this);
  }
  getReedemedUsers = (item) => {
    console.log("______Redeemed Users _______________");
    Promise.all([
      fetch(`${config.ApiGateway}/couponRedemption`, {
        method: "POST",

        body: JSON.stringify({
          couponId: item.couponId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        this.setState({
          showRedeemedUsers: true,
          redeemedUserList: data,
        });
      })
      .catch((err) => console.log("Error in getting Shop" + err));
  };
  addCoupons = (e) => {
    e.preventDefault();
    let input = {};
    let couponValue = this.state.viewCoupons[0];
    Object.keys(couponValue).forEach((elements) => {
      input[elements] = couponValue[elements];
    });
    let addShopDetails = this.state.shopList[0];
    input.shopName = addShopDetails.shopName;
    input.userId10Digit = addShopDetails.userId10Digit;
    input.shopId = addShopDetails.shopId;
    input.pincode = addShopDetails.pincode;
    input.totalCouponCount = this.state.addCouponValue;
    input.remainingCouponCount = parseInt(this.state.addCouponValue) || 0;
    input.editType = "add";
    Promise.all([
      fetch(`${config.ApiGateway}/updateOrderCoupon`, {
        method: "POST",

        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert("coupon added sucessfully");
        let updatedCouponList = Object.entries(data.couponList).map(
          ([index, value]) => value.coupons
        );

        this.setState({
          shopList: [],
          viewCoupons: updatedCouponList[0],
          editForm: false,
          showAddShops: true,
        });
      })
      .catch((err) => console.log("Error in getting Shop" + err));
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  addCouponOnchange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  getShop = (e) => {
    e.preventDefault();
    Promise.all([
      fetch(`${config.ApiGateway}/getCouponLimit`, {
        method: "POST",

        body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        Object.keys(data).length <= 0
          ? alert("Entered Shop id is not found")
          : this.setState({
              showAddShops: false,
              shopList: [
                {
                  shopId: data.shopId,
                  userId10Digit: data.userId10Digit,
                  pincode: data.userPincode,
                  shopName: data.shopName,
                  userPhoneNumber: data.userPhoneNumber,
                  userName: data.userName,
                  shopAddress: data.shopAddress,
                  totalCoupons: 10,
                },
              ],

              confirmShop: true,
            });
      })
      .catch((err) => console.log("Error in getting Shop" + err));
  };
  updateCoupon = (item) => {
    const regNumber = /^[1-9][0-9]*$/;
    if (!regNumber.test(this.state.addCouponValue))
      return alert("Enter valid coupon Count");
    if (parseInt(this.state.addCouponValue) <= 0) {
      return alert("Coupon count must have atleast 1 count");
    }

    Promise.all([
      fetch(`${config.ApiGateway}/updateOrderCoupon`, {
        method: "POST",

        body: JSON.stringify({
          couponId: item.couponId,
          additionalCoupon: this.state.addCouponValue,
          totalCouponCount: item.totalCouponCount,
          remainingCouponCount: item.remainingCouponCount,
          couponReferenceId: item.couponReferenceId,
          editType: "edit",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let updatedCouponList = Object.entries(data.couponList).map(
          ([index, value]) => value.coupons
        );

        this.setState({
          viewCoupons: updatedCouponList[0],
          addCouponValue: 0,
        });
      })
      .catch((err) => console.log("Error in getting Shop" + err));
  };
  viewCouponsById = (couponList) => {
    let sortByShop = couponList.sort((a, b) =>
      a.shopName.localeCompare(b.shopName)
    );

    this.setState({
      viewCoupons: sortByShop,
      viewCouponDetails: true,
    });
  };
  componentWillUnmount = () => {
    this._isMounted = false;
  };
  componentDidMount = async () => {
    this._mounted = true;
    Promise.all([
      fetch(`${config.ApiGateway}/viewOrderCoupon`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        // Object.entries(data).map(([id, value], index) => {
        //   console.log(value.coupons);
        //   // Object.entries(value.coupons.condition).map(([elements, value1]) => {
        //   //   console.log(elements, value1);
        //   // });
        // });

        this.setState({
          couponObject: data,
        });
      })
      .catch((err) => console.log("Error in getting coupons" + err));
  };
  handleClose = () => {
    this.setState({
      showRedeemedUsers: false,
    });
  };
  updateExpiry = (e) => {
    e.preventDefault();
    let couponValue = this.state.viewCoupons[0];

    Promise.all([
      fetch(`${config.ApiGateway}/updateOrderCoupon`, {
        method: "POST",

        body: JSON.stringify({
          updateExpiryDate: this.state.updateExpiryDate,

          couponReferenceId: couponValue.couponReferenceId,
          editType: "extendExpiry",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert("coupon Expiry Date Updated");
        let updatedCouponList = Object.entries(data.couponList).map(
          ([index, value]) => value.coupons
        );

        this.setState({
          viewCoupons: updatedCouponList[0],
          addCouponValue: 0,
        });
      })
      .catch((err) => console.log("Error in getting Shop" + err));
  };
  render() {
    const { classes } = this.props;
    const {
      viewCouponDetails,
      viewCoupons,
      editForm,
      showAddShops,
      indexValue,
      showRedeemedUsers,
      redeemedUserList,
      shopList,
    } = this.state;

    let couponInformation = viewCoupons[0];
    return (
      <Container>
        <div className="vertical-space-20"></div>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Online Coupons List</h2>
        </div>
        {viewCouponDetails ? (
          <>
            <Row
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Col
                style={{ maxWidth: "45%", padding: "15px" }}
                className="flexCard"
                sm={6}
              >
                <div className="shopLabeltext">
                  <label style={{ color: "#89959b", width: "30%" }}>
                    Coupon Given By :{" "}
                  </label>
                  <p style={{ width: "40%" }}>
                    {couponInformation.couponGivenBy}
                  </p>
                </div>
                <div className="shopLabeltext">
                  <label style={{ color: "#89959b", width: "30%" }}>
                    Coupon Code :{" "}
                  </label>
                  <p style={{ width: "40%" }}>{couponInformation.promocode}</p>
                </div>
                <div className="shopLabeltext">
                  <label style={{ color: "#89959b", width: "30%" }}>
                    Discount Type :{" "}
                  </label>
                  <p style={{ width: "40%" }}>
                    {couponInformation.discountType}
                  </p>
                </div>
                <div className="shopLabeltext">
                  <label style={{ color: "#89959b", width: "30%" }}>
                    Maximum Discount Amount:{"₹ "}
                  </label>
                  <p style={{ width: "40%" }}>
                    {couponInformation.maximumDiscount || "NA"}
                  </p>
                </div>
                <div className="shopLabeltext">
                  <label style={{ color: "#89959b", width: "30%" }}>
                    Discount Amount :{" "}
                  </label>
                  <p style={{ width: "40%" }}>
                    {"₹ "}
                    {couponInformation.discountAmount}
                  </p>
                </div>
                <div className="shopLabeltext">
                  <label style={{ color: "#89959b", width: "30%" }}>
                    Hidden:{" "}
                  </label>
                  <p style={{ width: "40%" }}>{couponInformation.hidden}</p>
                </div>

                <div className="shopLabeltext">
                  <label style={{ color: "#89959b", width: "30%" }}>
                    Expiry Date :{" "}
                  </label>
                  <p style={{ width: "40%" }}>{couponInformation.expiryDate}</p>
                </div>
                <Form style={{ width: "100%" }} onSubmit={this.updateExpiry}>
                  <Form.Row>
                    <Form.Label
                      column
                      sm="5"
                      style={{ color: "#89959b", width: "30%" }}
                    >
                      Update Expiry Date :{" "}
                    </Form.Label>

                    <Col sm={"4"}>
                      <Form.Control
                        name="updateExpiryDate"
                        onChange={this.onChange}
                        value={this.state.updateExpiryDate}
                        min={new Date().toISOString().substring(0, 10)}
                        type="date"
                      ></Form.Control>
                    </Col>
                    <Col sm={"2"}>
                      <Button type="submit">Update</Button>
                    </Col>
                  </Form.Row>
                </Form>
              </Col>
              <Col className="flexCard" sm={6}>
                {showAddShops ? (
                  <>
                    <Form
                      style={{ width: "100%", padding: "15px" }}
                      onSubmit={this.getShop}
                    >
                      <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="4">
                          Shop Id
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            name="userId10Digit"
                            onChange={this.onChange}
                            value={this.state.userId10Digit}
                            required
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <div style={{ width: "100%", height: "5px" }}></div>
                      <Button style={{ float: "right" }} type="submit">
                        Get Shop
                      </Button>
                    </Form>
                  </>
                ) : (
                  <>
                    <Form
                      onSubmit={this.addCoupons}
                      style={{ width: "100%", padding: "15px" }}
                    >
                      <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="4">
                          Shop Name
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            plaintext
                            value={this.state.shopList[0].shopName || null}
                            name="shopName"
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="4">
                          Name
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            plaintext
                            name="shopName"
                            value={this.state.shopList[0].userName || null}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="4">
                          Phone Number
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            plaintext
                            name="shopName"
                            value={
                              this.state.shopList[0].userPhoneNumber || null
                            }
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="4">
                          Address
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            plaintext
                            value={this.state.shopList[0].shopAddress || null}
                            name="shopName"
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="4">
                          Pincode
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            plaintext
                            name="shopName"
                            value={this.state.shopList[0].pincode || null}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="4">
                          Total Coupons
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            name="addCouponValue"
                            onChange={this.onChange}
                            value={this.state.addCouponValue}
                            type="number"
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <div style={{ textAlign: "right" }}>
                        <Button
                          style={{ marginRight: "5px" }}
                          onClick={() => {
                            this.setState({
                              showAddShops: true,
                              shopList: [],
                            });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button type="submit"> Add Shops</Button>
                      </div>
                    </Form>
                  </>
                )}
              </Col>
            </Row>

            {viewCoupons.map((value, index) => {
              var item = value;

              return (
                <>
                  <Row style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                    <Col className="flexCard" sm={12}>
                      <div
                        style={{
                          justifyContent: "space-between",
                          display: "flex",
                          padding: "20px",
                        }}
                      >
                        <h1 className="shopProfile">{item.shopName}</h1>
                        {this.state.editForm ? (
                          <>
                            <span
                              onClick={() => {
                                this.setState({
                                  editForm: false,
                                  indexValue: -1,
                                });
                              }}
                            >
                              {index === indexValue ? (
                                <CloseIcon />
                              ) : (
                                <>
                                  {" "}
                                  <EditIcon />
                                </>
                              )}
                            </span>
                          </>
                        ) : (
                          <>
                            {" "}
                            <span
                              onClick={() => {
                                this.setState({
                                  editForm: true,
                                  indexValue: index,
                                });
                              }}
                            >
                              <EditIcon />
                            </span>
                          </>
                        )}
                      </div>
                      <div className="shopLabeltext">
                        <label style={{ color: "#89959b" }}>Shop Id: </label>
                        <p>{item.userId10Digit}</p>
                      </div>
                      <div style={{ padding: "0px 30px" }}>
                        <Row>
                          <Col sm={4}>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                              <Form.Label
                                sm="6"
                                style={{
                                  color: "#89959b",
                                  textAlign: "left",
                                  padding: "0",
                                }}
                              >
                                Minimum Price
                              </Form.Label>
                              <Col className="formBoxLabel" sm="6">
                                <p>{"₹" + item.condition.minPrice}</p>
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col sm={4}>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                              <Form.Label
                                sm="6"
                                style={{
                                  color: "#89959b",
                                  textAlign: "left",
                                  padding: "0",
                                }}
                              >
                                M th Time
                              </Form.Label>
                              <Col className="formBoxLabel" sm="6">
                                <p>{item.condition.mTh}</p>
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col sm={4}>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                              <Form.Label
                                sm="6"
                                style={{
                                  color: "#89959b",
                                  textAlign: "left",
                                  padding: "0",
                                }}
                              >
                                N th Time
                              </Form.Label>
                              <Col className="formBoxLabel" sm="6">
                                <p>{item.condition.nTh}</p>
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={4}>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                              <Form.Label
                                sm="6"
                                style={{
                                  color: "#89959b",
                                  textAlign: "left",
                                  padding: "0",
                                }}
                              >
                                Total Coupons
                              </Form.Label>
                              <Col className="formBoxLabel" sm="6">
                                <p>{item.totalCouponCount}</p>
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col sm={4}>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                              <Form.Label
                                sm="6"
                                style={{
                                  color: "#89959b",
                                  textAlign: "left",
                                  paddingTop: "0",
                                }}
                              >
                                Remaining Coupons
                              </Form.Label>
                              <Col className="formBoxLabel" sm="6">
                                <p>{item.remainingCouponCount}</p>
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col sm={4}>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                              <Form.Label
                                sm="6"
                                style={{
                                  color: "#89959b",
                                  textAlign: "left",
                                  padding: "0",
                                }}
                              >
                                Add Coupons
                              </Form.Label>
                              <Col className="formBoxLabel" sm="6">
                                <Form.Control
                                  value={item.addCoupons}
                                  name="addCouponValue"
                                  value={this.state.addCouponValue}
                                  pattern="[0-9]{1,}"
                                  onChange={this.addCouponOnchange}
                                  plaintext={index !== indexValue}
                                />
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>

                        <div
                          style={{
                            width: "50%",
                            float: "right",
                            marginBottom: "30px",
                          }}
                        >
                          <Row
                            style={{
                              width: "100%",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <>
                              <Button
                                onClick={() => this.getReedemedUsers(item)}
                                style={{ marginRight: "10px" }}
                              >
                                View Redeemed Customers
                              </Button>

                              {index === indexValue && (
                                <>
                                  <Button
                                    onClick={() => this.updateCoupon(item)}
                                    style={{ marginRight: "10px" }}
                                  >
                                    Save
                                  </Button>
                                  <Button style={{ marginRight: "10px" }}>
                                    Delete
                                  </Button>
                                </>
                              )}
                            </>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              );
            })}
          </>
        ) : (
          <>
            <Table
              style={{
                border: 0.5 + "px",
                borderColor: "#ced4da",
                borderStyle: "solid",
              }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>S NO</TableCell>
                  <TableCell className={classes.tableCell}>
                    Coupon Code
                  </TableCell>
                  <TableCell className={classes.tableCell}>Type</TableCell>
                  <TableCell className={classes.tableCell}>
                    Total Shop
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    expiry date
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Created on
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    View Coupon
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(this.state.couponObject).map(
                  ([id, value], index) => (
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        {index + 1}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {value.coupons[0].promocode}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {value.coupons[0].couponGivenBy}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {value.coupons.length}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {value.coupons[0].expiryDate}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {value.coupons[0].addedDate}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Button
                          onClick={() => this.viewCouponsById(value.coupons)}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </>
        )}
        <div>
          <Modal
            className="modal-order-coupon"
            show={showRedeemedUsers}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Redeemed Users</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table
                style={{
                  border: 0.5 + "px",
                  borderColor: "#ced4da",
                  borderStyle: "solid",
                }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>S NO</TableCell>
                    <TableCell className={classes.tableCell}>
                      Customer ID
                    </TableCell>
                    <TableCell className={classes.tableCell}>Name</TableCell>
                    <TableCell className={classes.tableCell}>
                      Phone Number
                    </TableCell>
                    <TableCell className={classes.tableCell}>Pincode</TableCell>
                    <TableCell className={classes.tableCell}>Status</TableCell>
                    <TableCell className={classes.tableCell}>
                      Discount Type
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Discount Amount
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Redeemed On
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {redeemedUserList.map((items, index) => (
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        {index + 1}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Link
                          to={{
                            pathname: "/customer",
                            search: `?userid=${items.userId10Digit}`,
                          }}
                        >
                          {items.userId10Digit}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {items.userName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {items.userPhoneNumber}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {items.userPincode}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {items.couponStatus}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {items.discountType}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {"₹ "} {items.discountAmount}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {items.redeemedDate}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Modal.Body>
          </Modal>
        </div>
      </Container>
    );
  }
}

ViewOnlineCoupons.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewOnlineCoupons);
