import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";
import MaterialTable from "material-table";

import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import config from "../config";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import CircularProgress from "@mui/material/CircularProgress";
import { stringToObject } from "rsuite/esm/utils";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class BillingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId10Digit: "",
      billingResponse: {},
      employeeList: [],
      showData: false,
      productPerPage: 10,
      productPage: 0,
      billPerPage: 10,
      billPage: 0,
      billByMonthPerPage: 10,
      billByMonthPage: 0,
      sortAmount: true, // By default in desc (Bill list by Month)
      billsperMonth: [],
      highestGrossing: [],
      exportData: [],
      headers: [],
      billAmount: 0,
      orderAmount: 0,
    };

    this.getBillingByShopId = this.getBillingByShopId.bind(this);
    this.handleProductChangePerRow = this.handleProductChangePerRow.bind(this);
    this.productPage = this.productPage.bind(this);
    this.handleBillChangePerRow = this.handleBillChangePerRow.bind(this);
    this.BillPage = this.BillPage.bind(this);
    this.handleBillByMonthChangePerRow =
      this.handleBillByMonthChangePerRow.bind(this);
    this.BillByMonthPage = this.BillByMonthPage.bind(this);
    this.sortByAmount = this.sortByAmount.bind(this);
    this.csvLink = React.createRef();
  }
  export() {
    // Export data to csv format
    let tempArr = [];
    this.state.billingResponse.billList.map((elements) =>
      tempArr.push(elements)
    );

    const headers = [
      { label: "Invoice No", key: "invoiceNumber" },
      { label: "Cust Name", key: "customerName" },
      { label: "Phone", key: "customerPhoneNumber" },
      { label: "Payment", key: "paymentMethod" },
      { label: "Bill Amount", key: "amountPaid" },
      { label: "Billed on", key: "billingDate" },
      { label: "Bill Generated By", key: "employee" },
      { label: "view PDF", key: "bills3Location" },
    ];

    this.setState(
      {
        headers: headers,
        exportData: tempArr,
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  sortByAmount() {
    // Sort bill amount
    let tempArr = this.state.billsperMonth;

    tempArr.sort((a, b) =>
      this.state.sortAmount
        ? parseInt(a.amountPaid) - parseInt(b.amountPaid)
        : parseInt(b.amountPaid) - parseInt(a.amountPaid)
    );
    this.setState({
      billsperMonth: tempArr,
      sortAmount: !this.state.sortAmount,
    });
  }
  handleProductChangePerRow = (event) => {
    this.setState({ productPerPage: event.target.value });
  };
  productPage = (event, page) => {
    this.setState({ productPage: page });
  };

  handleBillChangePerRow = (event) => {
    this.setState({ billPerPage: event.target.value });
  };
  BillPage = (event, page) => {
    this.setState({ billPage: page });
  };
  handleBillByMonthChangePerRow = (event) => {
    this.setState({ billByMonthPerPage: event.target.value });
  };
  BillByMonthPage = (event, page) => {
    this.setState({ billByMonthPage: page });
  };

  async getDatasss(event) {
    const datakey = JSON.parse(localStorage.getItem("userData"));
    console.log("datakey", datakey);

    const key = `${datakey.username.slice(3) + 1234}`;

    let value = `https://prod-ghoshak.s3.ap-south-1.amazonaws.com/temp_report_json/%2B91${key}.JSON`;
    console.log("value", value);

    try {
      let datas = await fetch(
        `https://prod-ghoshak.s3.ap-south-1.amazonaws.com/temp_report_json/%2B91${key}.JSON`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let response = await datas.json();

      console.log("response", response);

      // let datas = await s3.getObject(getDataFromSSS).promise();
      const data = {
        data: response,
      };

      if (data.data.status === "true") {
        // Response Data structure
        // data - {billList : TotalBills,BillsByMonth : Bill per month,EmployeeList  : Total Employee,ProductList : Product list pe shop,TaxList : cgst,sgst,userInfo : Shop Profile}
        let totalBillAmount = 0; // initial bill amount
        let totalOrderAmount = 0; // initial order amount
        let findHighest = 0;
        if (event === "order") {
          data.data.billList.forEach((elements) => {
            totalOrderAmount += parseFloat(elements.totalAmount) || 0; // sum bill for each bill amount
          });
        } else {
          data.data.billList.forEach((elements) => {
            totalBillAmount += parseFloat(elements.amountPaid) || 0;
          });

          let highestGrossing = data.data.billListByMonth
            .sort((a, b) => parseInt(b.amountPaid) - a.amountPaid)
            .slice(0, 1); // to get highest bills generated by month
          findHighest =
            highestGrossing[0] === undefined
              ? { amountPaid: "-", date: "-" }
              : highestGrossing[0]; // amount paid
        }

        this.setState({
          billingResponse: data.data, // billing data
          showData: true, // loading state
          billsperMonth: data.data.billListByMonth, // bills by month
          highestGrossing: findHighest, // highest amount paid
          billAmount: totalBillAmount, // Total Bill Amount
          orderAmount: totalOrderAmount,
        });
      }
    } catch (error) {
      console.log("error-->", error);
    }
  }

  getBillingByShopId(userId) {
    // get bill details by shop Id
    //`${config.ApiGateway}/billingInfo`,  for testing
    //https://vkbmrh8wc3.execute-api.ap-south-1.amazonaws.com/dev/billingInfo
    const datakey = JSON.parse(localStorage.getItem("userData"));
    const key = `${datakey.username + 1234}.JSON`;
    const keybilllist = `${datakey.username + 2345}.JSON`;

    const daterange = JSON.parse(localStorage.getItem("daterange"));
    const startDate = `${daterange.startDate}`;
    const endDate = `${daterange.endDate}`;
    const dd = JSON.parse(userId);
    fetch(`${config.ApiGateway}/billingInfo`, {
      method: "POST",
      body: JSON.stringify({
        userId10Digit: dd.userId || this.state.userId10Digit,
        key: key, // Ghoshak ShopId
        keybilllist: keybilllist,
        startDate: dd.startDate,
        endDate: dd.endDate,
        type: dd.type,
        indicate: dd.indicate,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (
          data.errorMessage ===
          "Response payload size exceeded maximum allowed payload size (6291556 bytes)."
        ) {
          setTimeout(() => {
            this.getDatasss(dd.type);
          }, 3000);
        }
        if (data.payloadResponseExceededLimit === true) {
          let totalBillAmount = 0; // initial bill amount
          let totalOrderAmount = 0; // initial order amount
          let findHighest = 0;
          if (dd.type === "order") {
            data.data.billList.forEach((elements) => {
              totalOrderAmount += parseFloat(elements.totalAmount) || 0;
            });
          } else {
            data.data.billList.forEach((elements) => {
              totalBillAmount += parseFloat(elements.amountPaid) || 0;
            });

            let highestGrossing = data.data.billListByMonth
              .sort((a, b) => parseInt(b.amountPaid) - a.amountPaid)
              .slice(0, 1); // to get highest bills generated by month
            findHighest =
              highestGrossing[0] === undefined
                ? { amountPaid: "-", date: "-" }
                : highestGrossing[0]; // amount paid
          }

          this.setState({
            billingResponse: data.data, // billing data
            showData: true, // loading state
            billsperMonth: data.data.billListByMonth, // bills by month
            highestGrossing: findHighest, // highest amount paid
            billAmount: totalBillAmount, // Total Bill Amount
            orderAmount: totalOrderAmount,
          });
        }

        if (data.status === "true") {
          let totalBillAmount = 0; // initial bill amount
          let totalOrderAmount = 0; // initial order amount
          let findHighest = 0;

          if (dd.type === "order") {
            data.billList.forEach((elements) => {
              totalOrderAmount += parseFloat(elements.totalAmount) || 0; // sum bill for each bill amount
            });
          } else {
            data.billList.forEach((elements) => {
              totalBillAmount += parseFloat(elements.amountPaid) || 0; // sum bill for each bill amount
            });

            let highestGrossing = data.billListByMonth
              .sort((a, b) => parseInt(b.amountPaid) - a.amountPaid)
              .slice(0, 1); // to get highest bills generated by month
            findHighest =
              highestGrossing[0] === undefined
                ? { amountPaid: "-", date: "-" }
                : highestGrossing[0]; // amount paid
          }

          this.setState({
            billingResponse: data, // billing data
            showData: true, // loading state
            billsperMonth: data.billListByMonth, // bills by month
            highestGrossing: findHighest, // highest amount paid
            billAmount: totalBillAmount, // Total Bill Amount
            orderAmount: totalOrderAmount,
          });
        }
      })
      .catch((err) => {
        console.log("what is the err", err);
        setTimeout(() => {
          this.getDatasss(dd.type);
        }, 30000);
        // alert("Wainting 30 second");
        this.setState({
          message: "NO SHOPS FOUND",
        });
      });
  }

  async componentDidMount() {
    let searchParams = new URLSearchParams(window.location.search);
    if (
      searchParams.get("userid") !== null &&
      searchParams.get("userid") !== undefined &&
      searchParams.get("userid") !== ""
    ) {
      await this.getBillingByShopId(searchParams.get("userid")); // Pass Shopid
    }
  }

  render() {
    const { classes } = this.props;
    const normalise = (value) => ((value - 100) * 100) / (2000 - 100);
    const value = 0.66;
    return (
      <Container fluid>
        <Row className="rowClass">
          <b style={{ fontSize: 32 }}>Billing Info</b>
        </Row>
        {this.state.showData ? (
          <>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col className="division" sm={{ span: 11 }}>
                <div
                  className="headerName"
                  style={{
                    backgroundColor: "#fafafa",
                    width: 100 + "%",
                    margin: 1 + "%",
                  }}
                >
                  <h3 style={{ textAlign: "center" }}> Billing Stats</h3>
                </div>
                <Row
                  style={{
                    marginBottom: 20 + "px",
                    marginTop: 20 + "px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Col className="verticalRightBorder" sm={{ span: 2 }}>
                    <label style={{ textAlign: "center" }}>
                      Total Employee
                    </label>
                    <h3 style={{ textAlign: "center" }}>
                      <b>{this.state.billingResponse.employeeList.length}</b>
                    </h3>
                  </Col>
                  <Col className="verticalRightBorder" sm={{ span: 2 }}>
                    <label style={{ textAlign: "center" }}>Tax List</label>
                    <h3 style={{ textAlign: "center" }}>
                      <b> {this.state.billingResponse.taxList.length}</b>
                    </h3>
                  </Col>
                  <Col className="verticalRightBorder" sm={{ span: 2 }}>
                    <label style={{ textAlign: "center" }}>
                      Product Added List
                    </label>
                    <h3 style={{ textAlign: "center" }}>
                      <b> {this.state.billingResponse.productList.length}</b>
                    </h3>
                  </Col>

                  <Col className="verticalRightBorder" sm={{ span: 2 }}>
                    <label style={{ textAlign: "center" }}>
                      {this.state.billingResponse.type === "order"
                        ? "Total Orders"
                        : "Total Bills"}{" "}
                    </label>
                    <h3 style={{ textAlign: "center" }}>
                      <b> {this.state.billingResponse.billList.length}</b>
                    </h3>
                  </Col>
                  <Col className="verticalRightBorder" sm={{ span: 2 }}>
                    <label style={{ textAlign: "center" }}>
                      {this.state.billingResponse.type === "order"
                        ? "Total Order Amount"
                        : " Total Bill Amount"}
                    </label>
                    <h3 style={{ textAlign: "center" }}>
                      {console.log(
                        "orderAmount",
                        this.state.orderAmount.toFixed(2)
                      )}
                      <b>
                        {"₹ "}
                        {this.state.billingResponse.type === "order"
                          ? this.state.orderAmount.toFixed(2)
                          : this.state.billAmount.toFixed(2)}
                      </b>
                    </h3>
                  </Col>
                  <Col
                    className="verticalRightBorder"
                    style={{ borderRightColor: "transparent" }}
                    sm={{ span: 2 }}
                  >
                    <label style={{ textAlign: "center" }}>
                      Highest Grossing Bill By Month
                    </label>
                    <h3 style={{ textAlign: "center" }}>
                      <b>
                        {"₹ "}
                        {this.state.billingResponse.type === "order"
                          ? "monthly not available"
                          : this.state.highestGrossing.amountPaid === "-"
                          ? 0
                          : this.state.highestGrossing.amountPaid.toFixed(2)}
                      </b>
                    </h3>
                    <p style={{ textAlign: "center" }}>
                      {this.state.highestGrossing.billingDate}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col className="division" sm={{ span: 11 }}>
                <div
                  className="headerName"
                  style={{
                    backgroundColor: "#fafafa",
                    width: 100 + "%",
                    margin: 1 + "%",
                  }}
                >
                  <h3 style={{ textAlign: "center" }}> Shop Details</h3>
                </div>

                <Row
                  style={{
                    marginBottom: 20 + "px",
                    marginTop: 20 + "px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {[
                    "shopName",
                    "userName",
                    "userPhoneNumber",
                    "shopType",
                    "userPincode",
                    "shopAddress",
                  ].map((items) => (
                    <Col className="verticalRightBorder" sm={{ span: 2 }}>
                      <label
                        style={{
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {items}
                      </label>
                      <h4 style={{ textAlign: "center" }}>
                        <b>{this.state.billingResponse.userInfo[0][items]}</b>
                      </h4>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "space-around" }}>
              <Col className="division" sm={5}>
                <Row
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <Col sm={12}>
                    <div className="headerCenter">
                      <h4 className="subHeader">Product List</h4>
                    </div>

                    <Table size="small" className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            S No
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Product Name
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Version
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Qty
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Price(₹)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.billingResponse.productList
                          .slice(
                            this.state.productPage * this.state.productPerPage,
                            this.state.productPage * this.state.productPerPage +
                              this.state.productPerPage
                          )
                          .map((value, index) => (
                            <>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  {index + 1}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {value.productName}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {value.versionId}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {value.productQty}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {"₹ "} {value.productPrice}
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      count={this.state.billingResponse.productList.length}
                      rowsPerPage={this.state.productPerPage}
                      page={this.state.productPage}
                      onChangePage={this.productPage}
                      rowsPerPageOptions={[5, 10, 25]}
                      onChangeRowsPerPage={this.handleProductChangePerRow}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={5}>
                <Row
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Col className="division">
                    <div className="headerCenter">
                      <h4 className="subHeader">Generated Bills by Month</h4>
                    </div>

                    <Table size="small" className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            S No
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Date
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <TableSortLabel
                              active={true}
                              direction={this.state.sortAmount ? "desc" : "asc"}
                              onClick={this.sortByAmount}
                            ></TableSortLabel>
                            Amount Paid
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.billsperMonth

                          .slice(
                            this.state.billByMonthPage *
                              this.state.billByMonthPerPage,
                            this.state.billByMonthPage *
                              this.state.billByMonthPerPage +
                              this.state.billByMonthPerPage
                          )
                          .map((value, index) => (
                            <>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  {index + 1}
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  {value.billingDate}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {value.amountPaid}
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      count={this.state.billsperMonth.length}
                      rowsPerPage={this.state.billByMonthPage}
                      page={this.state.billByMonthPage}
                      onChangePage={this.BillByMonthPage}
                      rowsPerPageOptions={[5, 10, 25]}
                      onChangeRowsPerPage={this.handleBillByMonthChangePerRow}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col className="division" sm={{ span: 5 }}>
                <Row
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Col>
                    <div className="headerCenter">
                      <h4 className="subHeader">Employee</h4>
                    </div>
                    <Table size="small" className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            S No
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Employee
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.billingResponse.employeeList.map(
                          (value, index) => (
                            <>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  {index + 1}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {value}
                                </TableCell>
                              </TableRow>
                            </>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </Col>
                </Row>
              </Col>
              <Col className="division" xs={6}>
                <Row
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Col>
                    <div className="headerCenter">
                      <h4 className="subHeader">Tax</h4>
                      <Table size="small" className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              S No
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Tax Name
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Tax Rate
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.billingResponse.taxList.map(
                            (value, index) => (
                              <>
                                <TableRow>
                                  <TableCell className={classes.tableCell}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {value.taxname}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {value.taxRate}
                                  </TableCell>
                                </TableRow>
                              </>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ display: "flex", justifyContent: "center" }}>
              {/* <Col className="division" sm={{ span: 11 }}> */}
              {/* <Row
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Col>
                    <div className="headerCenter">
                      <h4 className="subHeader">Bill History</h4>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingBottom: 20 + "px",
                      }}
                    >
                      <Form.Label style={{ paddingRight: 20 + "px" }}>
                        Export as CSV
                      </Form.Label>
                      <Button
                        onClick={() => {
                          this.export();
                        }}
                        size="sm"
                      >
                        Download
                      </Button>
                      <CSVLink
                        enclosingCharacter={`'`}
                        data={this.state.exportData}
                        headers={this.state.headers}
                        ref={this.csvLink}
                        target="_blank"
                      />
                    </div>

                    <Table size="small" className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            S No
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Invoice No
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Customer Name
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Phone
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {this.state.billingResponse.type === "order"
                              ? "Order Amount"
                              : "Bill Amount"}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            Payment
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Billed on
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Bill Generated By
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.billingResponse.billList

                          .slice(
                            this.state.billPage * this.state.billPerPage,
                            this.state.billPage * this.state.billPerPage +
                              this.state.billPerPage
                          )
                          .map((value, index) => (
                            <>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  {index + 1}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {value.invoiceNumber}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {value.customerName}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {value.customerPhoneNumber}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {"₹ "}{" "}
                                  {this.state.billingResponse.type === "order"
                                    ? value.totalAmount
                                    : value.amountPaid}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {this.state.billingResponse.type === "order"
                                    ? "0.00"
                                    : value.paymentMethod[0].method}
                                  {}
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  {value.billingDate}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {value.employee}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Button
                                    onClick={() =>
                                      window.open(value.bills3Location)
                                    }
                                  >
                                    View PDF
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      count={this.state.billingResponse.billList.length}
                      rowsPerPage={this.state.billPerPage}
                      page={this.state.billPage}
                      onChangePage={this.BillPage}
                      rowsPerPageOptions={[5, 10, 25]}
                      onChangeRowsPerPage={this.handleBillChangePerRow}
                    />
                  </Col>
                </Row> */}
              {/* </Col> */}
              <div className="vertical-space-20"></div>
              <div className="flexBoxRow">
                <div id="displayTable" className="flexBoxColumn">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingBottom: 20 + "px",
                      marginLeft: "85%",
                    }}
                  >
                    <Form.Label style={{ paddingRight: 20 + "px" }}>
                      Export as CSV
                    </Form.Label>
                    <Button
                      onClick={() => {
                        this.export();
                      }}
                      size="sm"
                    >
                      Download
                    </Button>
                    <CSVLink
                      enclosingCharacter={`'`}
                      data={this.state.exportData}
                      headers={this.state.headers}
                      ref={this.csvLink}
                      target="_blank"
                    />
                  </div>

                  <MaterialTable
                    title={
                      this.state.billingResponse.type === "order"
                        ? "Order History "
                        : "Bill History "
                    }
                    columns={[
                      {
                        title: "S NO",
                        field: "tableData.id",
                        render: (rowData) => {
                          return <p>{rowData.tableData.id + 1}</p>;
                        },
                      },
                      {
                        title:
                          this.state.billingResponse.type === "order"
                            ? "Order Id"
                            : "Invoice No",
                        field: "invoiceNumber",
                        render: (rowData) => {
                          if (this.state.billingResponse.type === "order") {
                            return rowData.orderId;
                          } else {
                            return rowData.invoiceNumber;
                          }
                        },
                      },

                      {
                        title: "Customer Name",
                        field: "customerName",
                      },
                      {
                        title: "Phone",
                        field: "customerPhoneNumber",
                      },
                      {
                        title:
                          this.state.billingResponse.type === "order"
                            ? "Order Amount"
                            : "Bill Amount",
                        field: "amountPaid",

                        render: (rowData) => {
                          if (this.state.billingResponse.type === "order") {
                            return `₹ ${rowData.totalAmount} /-`;
                          } else {
                            return `₹ ${rowData.amountPaid} /-`;
                          }
                        },
                      },
                      {
                        title: "Payment Method",
                        field: "paymentMethod",

                        render: (rowData) => {
                          if (this.state.billingResponse.type === "order") {
                            return rowData.paymentStatus;
                          } else if (
                            this.state.billingResponse.type === "bill"
                          ) {
                            if (
                              JSON.stringify(rowData.paymentMethod).length > 15
                            ) {
                              return rowData.paymentMethod[0].method;
                            } else {
                              return rowData.paymentMethod;
                            }
                          }
                        },
                      },
                      {
                        title: "Billed on",
                        field: "billingDate",
                      },
                      {
                        title:
                          this.state.billingResponse.type === "order"
                            ? "ShopType"
                            : "Bill Generated By", //
                        field:
                          this.state.billingResponse.type === "order"
                            ? "shopType"
                            : "employee",
                      },

                      {
                        title:
                          this.state.billingResponse.type === "bill"
                            ? "Action"
                            : "Cart Total",
                        field:
                          this.state.billingResponse.type === "bill"
                            ? "action"
                            : "cartTotal",
                        render:
                          this.state.billingResponse.type === "bill"
                            ? (rowData) => (
                                <Button
                                  onClick={() =>
                                    window.open(rowData.bills3Location)
                                  }
                                >
                                  View PDF
                                </Button>
                              )
                            : "",
                      },
                    ]}
                    data={this.state.billingResponse.billList}
                    style={{ width: "100%" }}
                    options={{
                      exportButton: true,
                      pageSize: 10,
                      //maxBodyHeight: 437,
                      pageSizeOptions: [5, 10, 20, 100, 500, 1000],
                      grouping: false,
                      filtering: false,
                      tableLayout: "auto",
                      paging: true,
                    }}
                  />
                </div>
              </div>
            </Row>
          </>
        ) : (
          <>
            <div
              style={{
                margin: "auto",
                marginLeft: "50%",
                marginRight: "50%",
                marginTop: "10%",
              }}
            >
              <p>Loading ...........</p>
              <CircularProgress disableShrink />
            </div>
          </>
        )}
      </Container>
    );
  }
}
BillingInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BillingInfo);
