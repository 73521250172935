import React from "react";
import { Container, Row, Col } from "react-bootstrap";
const PartnerProfile = ({ userProfile }) => {
  return (
    <div className="profileCardContainer">
      <div className="vertical-space-40"></div>
      <div className="profileCard">
        <Row>
          <Col sm="6" xs="7">
            <ul className="profileul">
              <li className="address">
                <b>Company Name</b> :&nbsp; {userProfile.companyName}
              </li>
              <li className="address">
                <b>Address</b> :&nbsp;{userProfile.userAddress}
              </li>
              <li className="address">
                <b>PAN</b> :&nbsp;{userProfile.panNo}
              </li>
              <li className="address">
                <b>GSTN </b> :&nbsp;{userProfile.gstNo}
              </li>
              <li className="address">
                <b>Refferal Code </b> :&nbsp;
                {userProfile.referralCode}
              </li>
              <li className="address">
                <b>Location </b> :&nbsp;{userProfile.companyLocation}
              </li>
            </ul>
          </Col>
          <Col sm="6" xs="5">
            <ul id="right" className="profileul">
              <li className="address">{userProfile.userName}</li>
              <li className="address">{userProfile.emailAddress}</li>
              <li className="address">{userProfile.userPhoneNumber}</li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PartnerProfile;
