import React, { useEffect, useState } from "react";
import { Container, Col, Button, Row, Form } from "react-bootstrap";
import moment from "moment";
import config from "../config";

export default function ActiveUsers() {
  const [month, setMonth] = useState([
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUNE",
    "JULY",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ]);
  const [selectMonthLifeCycle, setSelectMonthLifeCycle] = useState([]);
  const [monthLifeCycleValue, setMonthLifeCycleValue] = useState("");

  const [days, setDays] = useState("1");
  const [daysInaMonth, setDaysInaMonth] = useState([]);
  useEffect(() => {
    var getShopDetails = [];

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          _source: {
            includes: [
              "userPhoneNumber",
              "userId",
              "userType",
              "userPincode",
              "userName",
              "userId10Digit",
              "pin",
            ],
          },
          query: {
            bool: {
              must: [{ match: { userType: "Shop" } }],
              must_not: {
                bool: {
                  must: [{ term: { userPincode: "111111" } }],
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let initialDate = new Date();
        let currentYear = initialDate.getFullYear();
        let monthLifeCycle = [];
        for (let i = 0; i < 12; i++) {
          let firstDay = new Date(currentYear, i, 1);
          let lastDay = new Date(currentYear, i + 1, 0);

          monthLifeCycle.push(
            <option value={moment(firstDay).format("YYYY-MM-DD")}>
              {month[0 + i]}
            </option>
          );
        }

        let tempdata = data.hits.hits.map((element) => element._source);

        tempdata.forEach((elements) => {
          getShopDetails.push({
            userId: elements.userId,

            userId10Digit: elements.userId10Digit,
            userName: elements.userName,
            userPhoneNumber: elements.userPhoneNumber,
            userPincode: elements.userPincode,
          });
        });
        setSelectMonthLifeCycle(monthLifeCycle);
        // setGhoshakShops(getShopDetails);
      })

      .catch((err) => console.log(err));
  }, []);

  const handleClick = (value) => {
    setDays(value);
  };

  return (
    <Container fluid>
      <div style={{ width: "100%", height: "40px" }}></div>
      <div className="orderHeader">
        <h2 className="orderHeaderText">B2B Active Users</h2>
      </div>
      <div className="flexCardHeader">
        <label className="flexCardHeaderlabel">
          <Form.Row>
            <Form.Label>Filter By Month</Form.Label>
            <Form.Control
              onChange={(e) => {
                let value = e.target.value;
                const daysInMonth = moment(value.substring(0, 7)).daysInMonth();

                let daysArray = Array.from(
                  { length: daysInMonth },
                  (v, k) => k + 1
                );
                setDaysInaMonth(daysArray);
              }}
              as="select"
              value={monthLifeCycleValue}
            >
              {selectMonthLifeCycle}
            </Form.Control>
          </Form.Row>
        </label>
        <div className="vertical-space-5"></div>
      </div>

      <div style={{ fontSize: "12px", width: "100%", overflowX: "auto" }}>
        <nav class="site-nav">
          <ul className="b2bActiveuser">
            {daysInaMonth.map((items) => (
              <li
                className={days === items ? "active" : ""}
                onClick={() => handleClick(items)}
              >
                <a href="#">{items}</a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </Container>
  );
}
