// import dependencies

import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  InputGroup,
  DropdownButton,
  FormControl,
  Dropdown,
} from "react-bootstrap";
import SearchIcon from "@material-ui/icons/Search";
import config from "../config";
export default function BannerByLocation() {
  const [bannerLocation, setBannerLocation] = useState([]);
  const [getBannerHistory, setGetBannerHistory] = useState("");
  const [bookedBanners, setBookedBanners] = useState([]);
  useEffect(() => {
    Promise.all([
      fetch(`${config.json.URL}/bannerDropdown.json`, {
        // method: "POST"
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let banner = data.types.filter((item) => item.BANNER); //get banner array

        let bannerPlaces = banner.map((elements) => elements.BANNER); // get slots

        setBannerLocation(bannerPlaces[0] || []);
      })
      .catch((err) => console.log("Error in getting banner location", err));
  }, []);
  const getBanner = (bannerValue) => {
    // To get banner History by pincode
    Promise.all([
      fetch(`${config.ApiGateway}/bannerLocation`, {
        method: "POST",
        body: JSON.stringify({
          bannerlocation: bannerValue,
          startDate: "2020-06-20",
          endDate: "2020-12-12",
          type: "getBanner",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setBookedBanners(data);
      })
      .catch((err) =>
        console.log("Error getting in banner location slot", err)
      );
  };
  return (
    <Container>
      <div style={{ width: "100%", height: "40px" }}></div>
      <div className="orderHeader">
        <h2 className="orderHeaderText">Banner Location</h2>
      </div>
      <Row>
        <Col>
          <Form.Row>
            <Form.Label column sm="4">
              Get Active Banner Location
            </Form.Label>
            <Col sm="8">
              <Form.Control
                onChange={(e) => {
                  getBanner(e.target.value);
                  setGetBannerHistory(e.target.value);
                }}
                as="select"
              >
                <option>Choose Banner Location</option>
                {bannerLocation.map((elements) => (
                  <>
                    <option value={elements}> {elements}</option>
                  </>
                ))}
              </Form.Control>
            </Col>
          </Form.Row>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <div className="appendSearchIcon">
            <input></input>
            <button type="submit">
              <SearchIcon />
            </button>
          </div>
        </Col>
        <Col sm="6"></Col>
      </Row>

      {bookedBanners.map((elements) => (
        <>
          <div className="vertical-space-30"></div>
          <Row className="bannerCard">
            <Col sm="4">
              <img src={elements.bannerImageUrl}></img>
            </Col>
            <Col sm="8">
              <div className="bannerInfo">
                <Row>
                  <Col sm="4">
                    <label>Booked Date :</label>
                  </Col>
                  <Col sm="8">
                    <p>
                      {elements.startDate} - {elements.endDate}
                    </p>
                  </Col>
                </Row>
              </div>
              <div className="bannerInfo">
                <Row>
                  <Col sm="4">
                    <label>Pincode :</label>
                  </Col>
                  <Col sm="8">
                    <p>{elements.pincode}</p>
                  </Col>
                </Row>
              </div>
              <div className="bannerInfo">
                <Row>
                  <Col sm="4">
                    <label>Created on:</label>
                  </Col>
                  <Col sm="8">
                    <p>{elements.createdOn}</p>
                  </Col>
                </Row>
              </div>

              <div className="bannerInfo">
                <Row>
                  <Col sm="4">
                    <label>Created By:</label>
                  </Col>
                  <Col sm="8">
                    <p>{elements.reviewedBy}</p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col style={{ textAlign: "right" }} sm="12">
              <Button variant="danger">Delete</Button>
            </Col>
          </Row>
        </>
      ))}
    </Container>
  );
}
