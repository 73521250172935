import MaterialTable from "material-table";
import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});
export default function MasterSheetTable({
  data,
  validKeys,
  title,
  isSearchable,
  isExpandable,
  expandableType,
}) {
  return (
    <MaterialTable
      title={title}
      data={data}
      options={{
        search: isSearchable,
        headerStyle: { position: "sticky", top: 0 },
        maxBodyHeight: "600px",
        exportButton: {
          pdf: false,
        },
      }}
      detailPanel={
        isExpandable
          ? [
              {
                render: (rowData) => {
                  switch (expandableType) {
                    case "orders":
                      return <OrderDetailExpansion rowData={rowData} />;
                    case "customerLogs":
                      return <CustomerOrderLogs rowData={rowData} />;
                    default:
                      return [];
                  }

                  // return (

                  // );
                },
              },
            ]
          : []
      }
      columns={[
        {
          title: "Customer ID",
          field: "customerId",
          hidden: !validKeys.includes("customerId"),
        },
        {
          title: "Customer Name",
          field: "customerName",
          hidden: !validKeys.includes("customerName"),
        },
        {
          title: "Customer Ph No",
          field: "customerPhoneNumber",
          hidden: !validKeys.includes("customerPhoneNumber"),
        },
        {
          title: "Successful Orders",
          field: "visit",
          hidden: !validKeys.includes("visit"),
        },
        {
          title: "Billing Date",
          field: "billingDate",
          hidden: !validKeys.includes("billingDate"),
        },
        {
          title: "Duration",
          field: "duration",
          hidden: !validKeys.includes("duration"),
        },
        {
          title: "Employee Name",
          field: "employeeName",
          hidden: !validKeys.includes("employeeName"),
        },
        {
          title: "Amount Paid ₹",
          field: "amountPaid",
          hidden: !validKeys.includes("amountPaid"),
        },

        {
          title: "Employee",
          field: "employee",
          hidden: !validKeys.includes("employee"),
          render: (rowData) => {
            return typeof rowData.otherDetails === "object"
              ? rowData.otherDetails["employee"] || "NA"
              : "NA";
          },
        },
        {
          title: "Total Appointment",
          field: "totalAppointment",
          hidden: !validKeys.includes("totalAppointment"),
        },
        {
          title: "Bill",
          field: "bills3Location",
          render: (rowData) => (
            <button
              onClick={() =>
                window.open(rowData.bills3Location || rowData.tinyUrl)
              }
            >
              View PDF
            </button>
          ),
          hidden: !validKeys.includes("bills3Location"),
        },

        {
          title: "Order ID",
          field: "orderId",
          hidden: !validKeys.includes("orderId"),
        },
        {
          title: "Billed Date",
          field: "billingDate",
          hidden: !validKeys.includes("billingDate"),
        },
        {
          title: "Delivery Method",
          field: "deliveryMethod",
          hidden: !validKeys.includes("deliveryMethod"),
          render: (rowData) => {
            // console.log(rowData.slot);
            return (
              <>
                <p>
                  {rowData.deliveryMethod}
                  {/* <b>{rowData.slot}</b> */}
                </p>
              </>
            );
          },
        },
        {
          title: "Amt Paid ₹",
          field: "totalAmount",
          hidden: !validKeys.includes("totalAmount"),
          render: (rowData) => `₹ ${rowData.totalAmount}`,
        },
        {
          title: "Applied Coupons",
          field: "coupons",
          hidden: !validKeys.includes("coupons"),
          render: (rowData) => {
            let promocodeValue = [];
            let appliedCoupon = "no";
            let discountAmt = 0;
            if (typeof rowData.coupons == "object") {
              appliedCoupon = rowData.coupons.length;
              rowData.coupons.map((elements) => {
                promocodeValue.push(elements.promocode);
                discountAmt = elements.discountAmount || 0;
              });
            }

            return (
              <>
                <p>{appliedCoupon}</p>
                {promocodeValue.map((elements) => {
                  return (
                    <>
                      <p>{elements}</p>
                      <br />
                    </>
                  );
                })}
              </>
            );
          },
        },

        {
          title: "Order Status",
          field: "status",
          hidden: !validKeys.includes("orderStatus"),
          render: (rowData) => {
            return (
              <>
                {rowData.status === "complete" && (
                  <Badge pill variant="danger">
                    Delivered
                  </Badge>
                )}
                {rowData.status === "startPackaging" && (
                  <Badge pill variant="danger">
                    Order Accepted
                  </Badge>
                )}
                {rowData.status === "placed" && (
                  <Badge pill variant="danger">
                    new Order Placed
                  </Badge>
                )}
                {rowData.status === "readyForDelivery" && (
                  <Badge pill variant="danger">
                    Ready for Delivery
                  </Badge>
                )}
                {rowData.status === "rejected" && (
                  <Badge pill variant="danger">
                    Rejected
                  </Badge>
                )}
                {rowData.status === "readyForPickup" && (
                  <Badge pill variant="danger">
                    Ready for Pick-up
                  </Badge>
                )}
              </>
            );
          },
        },
        {
          title: "Offer Imge",
          field: "offerImageLocation",
          render: (rowData) => (
            <img
              // src={JSON.parse(item.offerImageLocation)}
              src={rowData.offerImageLocation}
              alt={rowData.offerImageLocation}
              width="100"
              height="100"
            ></img>
          ),
          hidden: !validKeys.includes("offerImageLocation"),
        },
        {
          title: "Type",
          field: "offerType",
          hidden: !validKeys.includes("offerType"),
        },
        {
          title: "Type",
          field: "offerDetails",
          hidden: !validKeys.includes("offerDetails"),
        },
        {
          title: "Review",
          field: "review",
          hidden: !validKeys.includes("review"),
        },
        {
          title: "Status",
          field: "offerStatus",
          hidden: !validKeys.includes("offerStatus"),
        },
        {
          title: "Created On",
          field: "offerAddedDate",
          hidden: !validKeys.includes("offerAddedDate"),
        },
        {
          title: "Expiry Date",
          field: "offerEndDate",
          hidden: !validKeys.includes("offerEndDate"),
        },
        {
          title: "Reviewed on",
          field: "reviewedOn",
          hidden: !validKeys.includes("reviewedOn"),
        },
        {
          title: "Reviewed By",
          field: "reviewedBy",
          hidden: !validKeys.includes("reviewedBy"),
        },
      ]}
    />
  );
}

const OrderDetailExpansion = ({ rowData }) => {
  return (
    <>
      <ul className="horizontalList">
        {Object.entries(rowData.cart).map(([key, value], index) => {
          return (
            <li
              id="noBorder"
              style={{ flex: "0 0 auto" }}
              className="horizontalListItem"
            >
              <div className="badgeContainer">
                {value.cartCount} x {value.productName}
                <span style={{ fontStyle: "8px", color: "red" }}>
                  &nbsp;&nbsp;{value.unitOfMeasurment}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const CustomerOrderLogs = ({ rowData }) => {
  const classes = useStyles();
  return (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell key={"1"} align={"1"}>
                Order ID
              </TableCell>
              <TableCell>Billed Date</TableCell>

              <TableCell>Total Amount</TableCell>

              <TableCell>Total Products</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.cartDetails.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  <TableCell key={row.orderId}>{row.orderId}</TableCell>
                  <TableCell key={row.id}>{row.billedDate}</TableCell>
                  <TableCell key={row.id}>{row.totalAmount}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
