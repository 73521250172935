import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import config from "../config";
import WelcomePage from "./templates/welcomePage";
import AboutUs from "./templates/aboutUs";
import TopNotification from "./templates/topNotificationBar";
import OurService from "./templates/ourServices";
import OurGallery from "./templates/ourGallery";
import OurClients from "./templates/ourClients";
import OurProjects from "./templates/ourProjects";
import OurBrands from "./templates/ourBrands";
import ClientTestimonials from "./templates/clientTestimonials";
import VideoGallery from "./templates/videoGallery";
import SocialMedia from "./templates/socialMedia";
import ContactUs from "./templates/contactUs";
import OnlineProduct from "./templates/onlineProduct";
import Orders from "./templates/orders";
import OurTeam from "./templates/ourTeam";
import Shops from "./templates/shops";
import PoweredByGhoshak from "./templates/poweredByGhoshak";
import PhoneNumber from "./templates/phoneNumber";
import BlockOutPrice from "./templates/blockOutPrice";
import AddDNS from "./templates/addDNS";
import SubProducts from "./templates/manageSubProducts";
import AddProducts from "./templates/addProducts";
// import AddRider from "./templates/aggregatedShop/addRider";
// import ManageSubShops from "./templates/aggregatedShop/manageAggregatedSubShops";
// import AggregatedOrders from "./templates/aggregatedShop/aggregatedOrders";
import { Auth } from "aws-amplify";
// import AggregatedReports from "./templates/aggregatedShop/aggregatedReports";
// import DeletedShops from "./templates/aggregatedShop/deletedShops";
// import PhoneOrders from "./templates/aggregatedShop/phoneOrders";
const ContentUpload = () => {
  const [isFranchiseUser, setIsFranchiseUser] = useState(false);
  const [userId10Digit, setUserId10Digit] = useState("");
  const [selectedPage, setSelectedPage] = useState("");
  const [shopId, setShopId] = useState("");
  const [clicked, setClicked] = useState(false);
  const [profile, setProfile] = useState({});
  const [shopType, setShopType] = useState("");
  useEffect(() => {
    setShopType(profile.shopType);
  }, [profile]);
  const sideMenuStyle = {
    fontSize: "21px",
    fontWeight: "500",
    color: "gray",
  };
  useEffect(() => {
    async function getUser() {
      await Auth.currentAuthenticatedUser().then((user) => {
        let { phone_number } = user.attributes;

        let [role] =
          user?.signInUserSession?.idToken?.payload["cognito:groups"];

        // franchise user verification and assign phone number
        if (role == "franchise") {
          setIsFranchiseUser(true);
          setUserId10Digit(phone_number);
        }
      });
    }
    getUser();
  }, []);

  useEffect(() => {
    if (isFranchiseUser) {
      getShop();
    }
  }, [userId10Digit]);
  const getShop = async () => {
    await fetchUserDetails(userId10Digit).then((data) => {
      if (!data || data.length === 0) {
        setShopId("");
        return alert("User not found, Please enter a valid  shop id");
      }
      setProfile(data[0]);
      setShopId(data[0].userId);
      setSelectedPage("");
    });
  };
  useEffect(() => {
    if (isFranchiseUser && shopType == "Aggregated") {
      setSelectedPage("manageSubShops");
    }
  }, [isFranchiseUser, shopType]);
  const aggregatedUser = isFranchiseUser && shopType == "Aggregated";
  return (
    <Container fluid>
      <div style={{ width: "100%", height: "30px" }}></div>
      <div className="websiteHeader">
        {/* {!aggregatedUser && ( */}
        <h2 className="websiteHeaderText">Content Upload</h2>
        {/* )} */}
      </div>

      {!isFranchiseUser && (
        <>
          <Row style={{ marginTop: "20px" }}>
            <Col className="dashboardColumnBox columnCenter" sm="8">
              <div style={{ marginLeft: "30%", margin: "20px" }}>
                <label>Enter Shop Id: </label>
                <input
                  style={{
                    width: "50%",
                    height: "40px",
                    borderRadius: "5px",
                    marginLeft: "22px",
                  }}
                  placeholder="Enter 10 digit Id"
                  onChange={(e) => {
                    setUserId10Digit(e.target.value);
                    setShopId("");
                  }}
                  value={userId10Digit}
                />{" "}
                <Button onClick={() => getShop()}>Search</Button>
              </div>
            </Col>
          </Row>
        </>
      )}

      {shopId && (
        <div className="contentUploadContainer">
          <div className="contentUploadSideMenu">
            <ProSidebar width="100%">
              <Menu iconShape="square">
                {isFranchiseUser && shopType !== "Aggregated" && (
                  <>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("shops")}
                    >
                      Manage Shops
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("subProducts")}
                    >
                      Manage Products
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("orders")}
                    >
                      Orders
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("welcomePage")}
                    >
                      Welcome Page
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("onlineProduct")}
                    >
                      Online Product
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("aboutUs")}
                    >
                      About Us
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("topNotification")}
                    >
                      Top Notification
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("ourService")}
                    >
                      Our Services
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("ourGallery")}
                    >
                      Our Gallery
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("ourClients")}
                    >
                      Our Clients
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("ourProject")}
                    >
                      Our Projects
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("ourTeam")}
                    >
                      Our Team
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("ourBrands")}
                    >
                      Our brands
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("clientTestimonials")}
                    >
                      Client testimonials
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("videoGallery")}
                    >
                      Video Gallery
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("socialMedia")}
                    >
                      Social Media
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("ourContacts")}
                    >
                      Contact Us Form
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("addProducts")}
                    >
                      Add Products
                    </MenuItem>
                  </>
                )}
                {aggregatedUser && (
                  <div>
                    {/* <MenuItem onClick={() => setSelectedPage("manageSubShops")}>
                      <span style={sideMenuStyle}> Manage Shops </span>
                    </MenuItem> */}
                    {/* <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("addRiderPage")}
                    >
                      <span style={sideMenuStyle}>Manage Riders</span>
                    </MenuItem> */}
                    {/* <MenuItem
                      onClick={() => setSelectedPage("AggregatedOrders")}
                    >
                      <span style={sideMenuStyle}> Manage Orders</span>
                    </MenuItem> */}
                    {/* <MenuItem
                      onClick={() => setSelectedPage("aggregatedShopSettings")}
                    >
                      <span style={sideMenuStyle}> Settings</span>
                    </MenuItem> */}

                    {/* <MenuItem
                      onClick={() => setSelectedPage("aggregatedReports")}
                    >
                      <span style={sideMenuStyle}> Reports</span>
                    </MenuItem> */}
                    {/* <MenuItem onClick={() => setSelectedPage("deletedShops")}>
                      <span style={sideMenuStyle}> Deleted Shops</span>
                    </MenuItem> */}
                  </div>
                )}

                {!isFranchiseUser && shopType != "Aggregated" && (
                  <>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("welcomePage")}
                    >
                      Welcome Page
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("onlineProduct")}
                    >
                      Online Product
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("aboutUs")}
                    >
                      About Us
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("topNotification")}
                    >
                      Top Notification
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("ourService")}
                    >
                      Our Services
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("ourGallery")}
                    >
                      Our Gallery
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("ourClients")}
                    >
                      Our Clients
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("ourProject")}
                    >
                      Our Projects
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("ourTeam")}
                    >
                      Our Team
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("ourBrands")}
                    >
                      Our brands
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("clientTestimonials")}
                    >
                      Client testimonials
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("videoGallery")}
                    >
                      Video Gallery
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("socialMedia")}
                    >
                      Social Media
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("ourContacts")}
                    >
                      Contact Us Form
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("addProducts")}
                    >
                      Add Products
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("poweredByGhoshak")}
                    >
                      Powered by Ghoshak
                    </MenuItem>
                    {/* <MenuItem style={sideMenuStyle} onClick={() => setSelectedPage("addDNS")}>
                      Add DNS
                    </MenuItem> */}
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("phoneNumber")}
                    >
                      Phone Number
                    </MenuItem>
                    <MenuItem
                      style={sideMenuStyle}
                      onClick={() => setSelectedPage("blockOutPrice")}
                    >
                      Blockout Price
                    </MenuItem>
                  </>
                )}
              </Menu>
            </ProSidebar>
          </div>
          <div className="contentUploadMainPage">
            {selectedPage === "shops" && (
              <Shops shopId={userId10Digit} profile={profile} />
            )}
            {selectedPage === "subProducts" && (
              <SubProducts shopId={userId10Digit} profile={profile} />
            )}
            {selectedPage === "orders" && <Orders profile={profile} />}
            {selectedPage === "welcomePage" && (
              <WelcomePage shopId={userId10Digit} />
            )}
            {selectedPage === "aboutUs" && <AboutUs shopId={userId10Digit} />}
            {selectedPage === "topNotification" && (
              <TopNotification shopId={userId10Digit} />
            )}
            {selectedPage === "ourService" && (
              <OurService shopId={userId10Digit} />
            )}
            {selectedPage === "ourTeam" && <OurTeam shopId={userId10Digit} />}
            {selectedPage === "ourGallery" && (
              <OurGallery shopId={userId10Digit} />
            )}
            {selectedPage === "ourClients" && (
              <OurClients shopId={userId10Digit} />
            )}
            {selectedPage === "ourProject" && (
              <OurProjects shopId={userId10Digit} />
            )}
            {selectedPage === "ourBrands" && (
              <OurBrands shopId={userId10Digit} />
            )}

            {selectedPage === "clientTestimonials" && (
              <ClientTestimonials shopId={userId10Digit} />
            )}
            {selectedPage === "videoGallery" && (
              <VideoGallery shopId={userId10Digit} />
            )}
            {selectedPage === "socialMedia" && (
              <SocialMedia shopId={userId10Digit} />
            )}
            {selectedPage === "ourContacts" && (
              <ContactUs shopId={userId10Digit} />
            )}
            {selectedPage === "addDNS" && <AddDNS shopId={userId10Digit} />}
            {selectedPage === "onlineProduct" && (
              <>
                <OnlineProduct shopId={userId10Digit} />
              </>
            )}
            {selectedPage === "poweredByGhoshak" && (
              <>
                <PoweredByGhoshak shopId={userId10Digit} />
              </>
            )}
            {selectedPage === "addProducts" && (
              <>
                <AddProducts shopId={userId10Digit} />
              </>
            )}
            {/* {selectedPage === "addRiderPage" && (
              <>
                <AddRider shopId={shopId} />
              </>
            )} */}
            {/* {selectedPage === "AggregatedOrders" && (
              <AggregatedOrders profile={profile} />
            )} */}
            {/* {selectedPage === "manageSubShops" && (
              <>
                <ManageSubShops profile={profile} />
              </>
            )} */}
            {/* {selectedPage === "aggregatedShopReports" && (
              <>
                <AggregatedShopSettings profile={profile} />
              </>
            )} */}
            {/* {selectedPage === "aggregatedShopSettings" && (
              <>
                <AggregatedShopSettings profile={profile} />
              </>
            )} */}
            {/* {selectedPage === "aggregatedReports" && (
              <>
                <AggregatedReports profile={profile} />
              </>
            )} */}
            {/* {selectedPage === "deletedShops" && (
              <>
                <DeletedShops profile={profile} />
              </>
            )} */}
            {/* {selectedPage === "PhoneOrders" && (
              <>
                <PhoneOrders profile={profile} />
              </>
            )} */}

            {selectedPage === "phoneNumber" && (
              <>
                <PhoneNumber shopId={userId10Digit} />
              </>
            )}
            {selectedPage === "blockOutPrice" && (
              <>
                <BlockOutPrice shopId={userId10Digit} />
              </>
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

const fetchUserDetails = async (shopId) => {
  console.log("shopId", shopId);
  return await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
      method: "POST",
      body: JSON.stringify({
        userId10Digit: shopId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      return data;
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("Something went wrong, please try again");
    });
};

export default ContentUpload;
