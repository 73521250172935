import React, { useEffect, useState } from "react";
import MasterSheetTable from "./masterSheetTable";
import { ToWords } from "to-words";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import TableRow from "@material-ui/core/TableRow";
import Modal from "react-bootstrap/Modal";
import Validation from "./handler/validation";
import Handler from "./handler/handler";
import Button from "react-bootstrap/Modal";
import { InvoiceAPI, GenerateInvoiceAPI } from "./handler/apiHandler";
import moment from "moment";

let initialAddPackageItems = {
  description: "Appointment Booking",
  month: "",
  amount: "",
  startDate: new Date().toISOString().substring(0, 10),
  endDate: "",
};
let initialGenerateInvoice = {
  items: [],
  amount: "",
  totalBillAmount: 0,
  totalTaxCost: 0,
  paymentType: "Cash",
  transactionId: "NA",
  notes: "",
};
export default function Invoice({ profile, classes, crmUser }) {
  const words = new ToWords();
  let [validationHandler] = Validation();
  const [findHandler] = Handler(); // Handler class
  const [invoiceList, setInvoiceList] = useState([]); // Invoice list
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [state, setState] = useState(initialGenerateInvoice);
  const [modalState, setModalState] = useState(false); // Modal State
  const [addPackageItem, setAddPackageItem] = useState(initialAddPackageItems);
  const closeModal = () => {
    // close modal and reset to initial state

    setModalState(false);
    setState(initialGenerateInvoice);
  };
  useEffect(async () => {
    return await findHandler("SUCCESS_ALERT");
  }, []);

  useEffect(() => {
    GetInvoiceAPI();
  }, [profile]);
  const GetInvoiceAPI = async () => {
    return await new Promise(async (resolve, reject) => {
      try {
        await InvoiceAPI({ shopId: profile.userId }).then((data) => {
          if (data.status) {
            setInvoiceList(data.data.bills);
            setIsLoading(false);
            return resolve();
          }

          reject();
        });
      } catch (err) {
        alert("data fetching failed ");
        reject();
      }
    });
  };

  const addPackageOnChange = (name, value) =>
    setAddPackageItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  const concatBillingPackageItems = (e) => {
    // Manages billing package invoice

    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      items: [
        ...prevState.items,
        {
          description: `Ghoshak ${addPackageItem.description} Subscription Charges - ${addPackageItem.month} months `,
          period: `period ( ${moment(new Date(addPackageItem.startDate)).format(
            "D MMMM YYYY"
          )} - ${moment(new Date(addPackageItem.endDate)).format(
            "D MMMM YYYY"
          )} ) `,

          month: addPackageItem.month,
          amount: addPackageItem.amount,
          packageType: addPackageItem.description,
          offerValidity: `${addPackageItem.month} months`,
          packageCost: (
            parseFloat(addPackageItem.amount) *
            (100 / 118)
          ).toFixed(2),
          tax: (
            parseFloat(addPackageItem.amount) -
            parseFloat(addPackageItem.amount) * (100 / 118)
          ).toFixed(2),
        },
      ],
      totalBillAmount:
        parseFloat(prevState.totalBillAmount) +
        parseFloat(addPackageItem.amount),

      totalTaxCost: (
        parseFloat(prevState.totalTaxCost) +
        (parseFloat(addPackageItem.amount) -
          parseFloat(addPackageItem.amount) * (100 / 118))
      ).toFixed(2),
    }));
    setAddPackageItem(initialAddPackageItems); // reset package item
  };
  const removeBillingPackageItem = (removeRowIndex) => {
    const removeData = [...state.items];

    let splicedData = removeData.splice(removeRowIndex, 1);

    setState((prevState) => ({
      ...prevState,
      items: [...removeData],
      totalBillAmount:
        parseInt(prevState.totalBillAmount) - parseInt(splicedData[0].amount),
      totalTaxCost:
        parseInt(prevState.totalTaxCost) - parseInt(splicedData[0].tax),
    }));
  };
  const handleChange = (name, value) => {
    console.log(name, value, "invoice");
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const generateInvoice = async (e) => {
    // Generate Invoice for shop
    e.preventDefault();

    let input = {
      userId: profile.userId,
      amount: state.amount,
      items: state.items,
      totalBillAmount: state.totalBillAmount.toString(),
      totalTaxCost: (parseFloat(state.totalTaxCost) / 2).toString(),
      paymentType: state.paymentType,
      currentDate: moment(new Date()).format("D MMMM YYYY"),
      transactionId: state.transactionId,
      notes: state.notes,
      amtInWords: words.convert(state.totalBillAmount, {
        currency: true,
      }),
      billedBy: crmUser,
    };

    let removedFields = await validationHandler("removeFields", input);

    await GenerateInvoiceAPI(input).then((data) => {
      if (data.status) {
        alert("Invoice Genrated");
        setState(initialGenerateInvoice);
        setAddPackageItem(initialAddPackageItems);
      } else {
      }
    });
  };

  return (
    <>
      <div className="vertical-space-10"></div>
      <div className="shopCardRow">
        <div className="shopCardHeader">
          <p>Invoice</p>
        </div>
        <div className="customDateRangePickerContainer">
          <button
            onClick={() => setModalState(true)}
            id="success"
            className="actionButton"
          >
            Generate Invoice
          </button>
        </div>
      </div>
      {isLoading ? null : (
        <>
          <div className="shopCardRow">
            <div className="shopCardColumn">
              <div className="shopCardBox">
                <ul className="horizontalList">
                  <li className="horizontalListItem">
                    <h6>Total Invoices</h6>
                    <span>{invoiceList.length || 0}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="shopCardRow">
            <div id="col70" className="shopCardColumn">
              <div className="wrapTable">
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableHeader}
                          key={"1"}
                          align={"1"}
                        >
                          Invoice NO
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          Billing Date
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          Amount Paid ₹
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          Description
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          Bill Pdf URL
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          Billed By
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoiceList.map((row) => {
                        let description = row.items.map(
                          (elements) =>
                            `${elements.details || elements.description}, `
                        );

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.date}
                          >
                            <TableCell
                              className={classes.tableCell}
                              key={row.invoiceNo}
                            >
                              {row.invoiceNo}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.billingDate}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                            >{`₹ ${row.amountPaid}`}</TableCell>
                            <TableCell className={classes.tableCell}>
                              {<p>{description}</p>}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <a href={row.pdfURL}> {row.pdfURL}</a>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.billedBy}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>

            <div id="col70" className="shopCardColumn">
              {/* <div className="wrapTable">
            <MasterSheetTable
              title={"Invoice"}
              validKeys={[
                "invoiceNumber",
                "customerName",
                "customerPhoneNumber",
                "billingDate",
                "amountPaid",
                "paymentMethod",
                "employee",
                "bills3Location",
              ]}
              data={billingObject.bills}
            />
          </div> */}
            </div>
          </div>
          <div className="vertical-space-10"></div>
        </>
      )}

      <Modal
        className="modal-order-coupon"
        show={modalState}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Generate Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <form onSubmit={concatBillingPackageItems}>
              <div className="fieldBox">
                <div className="fieldWrap">
                  <label id="required">Description</label>
                  <select
                    value={addPackageItem.description}
                    className={"flexInputBox"}
                    required
                    onChange={(e) =>
                      addPackageOnChange("description", e.target.value)
                    }
                    value={addPackageItem.description}
                  >
                    <option value="Appointment Booking">Appointment</option>
                    <option value="Billing">Billing</option>
                    <option value="Online order">Delivery</option>
                    <option value="Loyalty">Loyalty</option>
                    <option value="Website">Website</option>
                  </select>
                </div>
                <div className="fieldWrap">
                  <label id="required"> Month</label>
                  <input
                    className={"flexInputBox"}
                    onChange={(e) =>
                      addPackageOnChange("month", e.target.value)
                    }
                    title="Numbers only allowed"
                    pattern="[0-9]{1,10}"
                    value={addPackageItem.month}
                  ></input>
                </div>
                <div className="fieldWrap">
                  <label id="required"> Amount</label>
                  <input
                    required
                    title="Numbers only allowed"
                    className={"flexInputBox"}
                    pattern="[0-9]{1,10}"
                    onChange={(e) =>
                      addPackageOnChange("amount", e.target.value)
                    }
                    value={addPackageItem.amount}
                  ></input>
                </div>
                <div className="fieldWrap">
                  <label id="required"> Start Date</label>
                  <input
                    type="date"
                    required
                    onChange={(e) =>
                      addPackageOnChange("startDate", e.target.value)
                    }
                    className={"flexInputBox"}
                    value={addPackageItem.startDate}
                  ></input>
                </div>
                <div className="fieldWrap">
                  <label id="required"> End Date</label>
                  <input
                    required
                    disabled={!Boolean(addPackageItem.startDate)}
                    min={addPackageItem.startDate}
                    type="date"
                    onChange={(e) =>
                      addPackageOnChange("endDate", e.target.value)
                    }
                    className={"flexInputBox"}
                    value={addPackageItem.endDate}
                  ></input>
                </div>
                <div className="fieldWrap">
                  <button
                    type="submit"
                    style={{ border: "none", backgroundColor: "transparent" }}
                    className="centerText"
                  >
                    <AddCircleOutlineOutlinedIcon />
                  </button>
                </div>
              </div>
            </form>
            <form onSubmit={generateInvoice}>
              <>
                <div className="fieldBox">
                  <>
                    <div id="twoCol" className="fieldWrap">
                      <label id="required">Payment Type</label>
                      <select
                        value={state.paymentType}
                        className={"flexInputBox"}
                        required
                        onChange={(e) =>
                          handleChange("paymentType", e.target.value)
                        }
                      >
                        <option value="Cash">Cash</option>
                        <option value="Card">Card</option>
                        <option value="Gpay">Gpay</option>
                        <option value="Paytm">Paytm</option>
                        <option value="UPI">UPI</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    {state.paymentType !== "Cash" && (
                      <>
                        <div id="twoCol" className="fieldWrap">
                          <label id="required">Transaction ID</label>
                          <input
                            required
                            onChange={(e) =>
                              handleChange("transactionId", e.target.value)
                            }
                            className={"flexInputBox"}
                            value={state.transactionId}
                          ></input>
                        </div>
                        <div id="twoCol" className="fieldWrap">
                          <label id="required">Notes</label>
                          <textarea
                            required
                            placeholder="Should not exceed more than 120 characters"
                            onChange={(e) =>
                              handleChange("notes", e.target.value)
                            }
                            className={"flexInputBox"}
                            value={state.notes}
                          ></textarea>
                        </div>
                      </>
                    )}

                    <div className="flexBoxEnd">
                      <div className="updateButton">
                        <button
                          type="submit"
                          onClick={() => {
                            console.log("eieie");
                            closeModal();
                            // alert("success");
                          }}
                          disabled={state.items.length <= 0}
                          id="success"
                          className="actionButton"
                        >
                          Generate Invoice
                        </button>
                      </div>
                      <div className="updateButton">
                        <button
                          onClick={() => {
                            closeModal();
                            // alert("cancelled");
                          }}
                          id="danger"
                          className="actionButton"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </>
                </div>
              </>
            </form>
            {state.items.length > 0 && (
              <>
                <TableContainer className={classes.container}>
                  <Table
                    className={classes.table}
                    style={{ border: "1px dotted black" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeader}>
                          Index
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          Description
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          Package Cost in INR (
                          <b>
                            <small>Without Tax</small>
                          </b>
                          )
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          Tax in INR
                        </TableCell>
                        <TableCell className={classes.tableHeader}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.items.map((row, index) => (
                        <TableRow key={row.description}>
                          <TableCell
                            className={classes.tableCell}
                            component="th"
                            scope="row"
                          >
                            {index + 1}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {row.description}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.packageCost}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.tax}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Button
                              onClick={() => removeBillingPackageItem(index)}
                            >
                              Remove
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="vertical-space-5"></div>
                <div className="totalList">
                  <div className="rowFlex">
                    <div className="rowFlex-left">
                      <label className="rowFlex-label">SGST 9% :</label>
                    </div>

                    <div className="rowFlex-right">
                      <label className="totalListValue">
                        ₹ {parseFloat(state.totalTaxCost) / 2}
                      </label>
                    </div>
                  </div>
                  <div className="rowFlex">
                    <div className="rowFlex-left">
                      <label className="rowFlex-label">CGST 9% :</label>
                    </div>

                    <div className="rowFlex-right">
                      <label className="totalListValue">
                        ₹ {parseFloat(state.totalTaxCost) / 2}
                      </label>
                    </div>
                  </div>
                  <div className="rowFlex">
                    <div className="rowFlex-left">
                      <label className="rowFlex-label">
                        <b> Grand Total :</b>
                      </label>
                    </div>

                    <div className="rowFlex-right">
                      <label className="totalListValue">
                        ₹ {state.totalBillAmount}
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
