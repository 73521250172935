import React from "react";
import "../styles/extendSmsPackage.css";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import config from "../config";
import Moment from "moment";

const ExtendSMSPackage = () => {
  // Get today's date
  const today = Moment();

  // Add 100 days to today's date
  const futureDate = today.add(365, "days");

  // Format the future date as "YYYY-MM-DD"
  const formattedDate = futureDate.format("YYYY-MM-DD");

  console.log("formattedDate", typeof formattedDate);

  const [enterShopIdModal, setEnterShopIdModal] = useState(true);
  const [shopId, setShopId] = useState("");
  const [shopDetails, setShopDetails] = useState("");
  const [loading, setLoading] = useState(true);
  const [packageStatus, setPackageStatus] = useState({
    status: "",
    expDate: "",
    count: "",
  });
  const customStylesesms = {
    content: {
      width: "450px",
      height: "200px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      padding: "5px",
      marginRight: "-50%",
      borderRadius: "12px",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      background: "white",
    },
    overlay: {
      background: `rgba(0, 0, 0, 0.5)`,
    },
  };
  const getShopDetails = async () => {
    setLoading(true);
    try {
      let response = await fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
        method: "POST",
        body: JSON.stringify({
          userId10Digit: String(shopId),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      let received = await response.json();
      if (received[0].userId) {
        setShopDetails(received[0]);
      }
      setLoading(false);
      setEnterShopIdModal(false);
    } catch (err) {
      setLoading(false);
      console.log("Error fethcing shops data", err);
    }
  };
  useEffect(() => {
    if (shopDetails.hasOwnProperty("smsPackage")) {
      if (shopDetails.smsPackage === "yes") {
        if (shopDetails?.smsCount <= 0) {
          setPackageStatus({
            status: "false",
            expDate: shopDetails.smsExpiryDate || "NA",
            count: shopDetails.smsCount,
          });
        } else {
          setPackageStatus({
            status: "true",
            expDate: shopDetails.smsExpiryDate || "NA",
            count: shopDetails.smsCount,
          });
        }
      } else if (shopDetails.smsPackage === "no") {
        setPackageStatus({
          status: "false",
          expDate: shopDetails.smsExpiryDate || "NA",
          count: shopDetails.smsCount,
        });
      }
    } else {
      setPackageStatus({ status: "notAdded", expDate: "", count: "" });
    }
  }, [shopDetails]);

  const updateSmsPackage = async (incoming) => {
    let tempBody = {
      ...shopDetails,
      typename: "updateData",
      smsCount: 1000,
      smsPackage: incoming === "deactivate" ? "no" : "yes",
      smsExpiryDate: formattedDate,
    };
    let response = await fetch(`${config.ApiGateway}/updateWebsiteStatus`, {
      method: "POST",
      body: JSON.stringify(tempBody),
      headers: {
        "Content-Type": "application/json",
      },
    });

    let received = await response.json();
    if (received.status === "update") {
      setShopDetails({ ...tempBody });
    }
  };

  return (
    <div className="extendSmsPackageMain">
      {/* <div className="extendSmsPackageMainInner"> */}
      <div className="showShopSmsDetailsMain">
        <div className="showShopSmsDetailsHeader">
          <div className="shopNameSMSDiv">
            <div>{shopDetails.shopName}</div>
          </div>
          <div className="shopIdDivSms">
            <div className="shopIdSms">{shopDetails.userId10Digit}</div>
            <div
              className="shopIdSmsEditIconDiv"
              onClick={() => setEnterShopIdModal(true)}
            >
              edit
            </div>
          </div>
        </div>
        <div className="detailsdivsms">
          <div>SMS Package</div>
          <div>
            {shopDetails.smsPackage && shopDetails.smsPackage === "yes" ? (
              <div className="activesms">Active</div>
            ) : (
              <div className="nasms">NA</div>
            )}
          </div>
        </div>
        <div className="detailsdivsms">
          <div>SMS Count</div>
          <div>{shopDetails.smsCount || 0}</div>
        </div>
        <div className="detailsdivsms">
          <div>Expiry Date</div>
          <div>{shopDetails.smsExpiryDate || "NA"}</div>
        </div>
        {packageStatus.status === "notAdded" && (
          <div className="addRenBtn" onClick={() => updateSmsPackage()}>
            Add Package
          </div>
        )}
        {packageStatus.status === "true" && (
          <div
            className="addRenBtn"
            onClick={() => updateSmsPackage("deactivate")}
          >
            Remove Package
          </div>
        )}
        {packageStatus.status === "false" && (
          <div className="addRenBtn" onClick={() => updateSmsPackage()}>
            Renew Package
          </div>
        )}
      </div>

      <Modal
        isOpen={enterShopIdModal}
        style={customStylesesms}
        onRequestClose={() => setEnterShopIdModal(false)}
      >
        <div className="enterShopIdModalMain">
          <input
            type="number"
            onChange={(e) => {
              setShopId(e.target.value);
            }}
            value={shopId}
            placeholder="Enter 10digit ShopId"
            className="smsShopIdINP"
          />
          <button
            type="submit"
            className="smsShopIdSubmit"
            onClick={() => getShopDetails()}
          >
            Submit
          </button>
        </div>
      </Modal>
      {/* </div> */}
    </div>
  );
};
export default ExtendSMSPackage;
