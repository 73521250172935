import React, { useEffect, useState } from "react";
import config from "../../config";
import { makeStyles } from "@material-ui/core/styles";
import DisplayTable from "./displayTable";
import moment from "moment";
import { Col, Form } from "react-bootstrap";
import Limitation from "./limitation";
import { B2B } from "./service";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
const useStyles = makeStyles({
  table: {
    backgroundColor: "#fff",
    maxWidth: 900,
  },
  cell: {
    fontSize: "12px",
    paddingLeft: 4,
    paddingRight: 5,
  },
});
export default function Registration() {
  const classes = useStyles();
  let getFutureYears = [...new Array(20)].map((items, index) => (
    <option value={Limitation.startYear + index}>
      {Limitation.startYear + index}
    </option>
  ));
  const [totalDownload, setTotalDownload] = useState(0);
  const [filterByYear, setFilterbyYear] = useState(getFutureYears);
  const [downloads, setDownloads] = useState([]); // Downloads count` 1
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(12, "months").format("YYYY-MM-DD"),
    endDate: new Date().toISOString().substring(0, 10),
  }); // Date Range
  const [userList, setUserList] = useState([]); // Download List
  const getData = async () => {
    await Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                {
                  range: {
                    userAddedDate: {
                      gte: dateRange.startDate,
                      lte: dateRange.endDate,
                    },
                  },
                },
                {
                  match: {
                    userType: "Shop",
                  },
                },
              ],
              must_not: {
                bool: {
                  must: [{ term: { userPincode: "111111" } }],
                },
              },
            },
          },
          aggs: {
            userAddedDate: {
              date_histogram: {
                field: "userAddedDate",
                interval: "month",
                format: "yyyy-MM",
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        let b2bDownloads = data.aggregations.userAddedDate.buckets.map(
          (items) => ({
            date: items.key_as_string,
            count: items.doc_count,
            month:
              Limitation.month[parseInt(items.key_as_string.substring(5, 7))],
            year: items.key_as_string.substring(0, 4),
          })
        );
        let user = data.hits.hits.map((element) => element._source);
        console.log(b2bDownloads);
        setDownloads(b2bDownloads);
        setUserList(user);
      })
      .catch((err) => console.log("data fetching failed", err));
  };

  useEffect(() => {
    getData();
  }, [dateRange]);

  useEffect(() => {
    async function loadData() {
      await B2B().then((data) => {
        setTotalDownload(data.length);
      });
    }

    loadData();
  }, []);

  return (
    <>
      <div className="titleHeader">
        <h4>Registration</h4>
      </div>
      <div style={{ justifyContent: "flex-start" }} className="flexRow">
        <>
          <div className="labelWrapper">
            <h5>Overview</h5>
          </div>
          <div id="offer" className="flexColumn">
            <div className="flexCard">
              <div className="flexIcon">
                <GetAppIcon />
              </div>
              <div className="flexContent">
                <h5>B2B Downloads</h5>
                <h4>{totalDownload}</h4>
              </div>
            </div>
          </div>

          <div id="offer" className="flexColumn">
            <div style={{ marginLeft: "10px" }} className="flexCard">
              <div className="flexIcon">
                <GetAppIcon />
              </div>
              <div className="flexContent">
                <h5>
                  Downloads &nbsp;&nbsp;&nbsp;&nbsp;
                  <b>
                    {dateRange.startDate} - {dateRange.endDate}
                  </b>
                </h5>
                <h4>{userList.length}</h4>
              </div>
            </div>
          </div>
        </>
      </div>
      <div className="vertical-space-20"></div>
      <div className="flexRow">
        <div className="labelWrapper">
          <h5>Registration</h5>
        </div>
        <div id="pin" className="flexColumn">
          <div className="vertical-space-5"></div>
          <div className="labelWrapper">
            <Form.Row style={{ flexBasis: "100%", alignItems: "end" }}>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Filter Downloads</Form.Label>
                <Form.Control
                  defaultValue="6 Month"
                  as="select"
                  onChange={(e) =>
                    setDateRange({
                      startDate: e.target.value.split(",")[0],
                      endDate: e.target.value.split(",")[1],
                    })
                  }
                >
                  <option>Select</option>
                  {Limitation.filterby.map((items) => (
                    <option value={items.range}>{items.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Filter By Year</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) =>
                    setDateRange({
                      startDate: `${e.target.value}-01-01`,
                      endDate: `${e.target.value}-12-31`,
                    })
                  }
                >
                  <option>Select</option>
                  {filterByYear}
                </Form.Control>
              </Form.Group>
            </Form.Row>
          </div>

          <ResponsiveContainer aspect={1.6}>
            <LineChart
              data={downloads}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              style={{
                padding: "20px",
                boxShadow: " 0 1px 4px 0 rgba(0, 0, 0, 0.14)",
                backgroundColor: "#fff",
              }}
              className="recharts"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                tickLine={true}
                xAxisId={0}
                dy={0}
                dx={-0}
                label={{
                  value: `B2B downloads ${dateRange.startDate} -
                  ${dateRange.endDate}`,
                  angle: 0,
                  position: "bottom",
                  fontSize: 10,
                }}
                interval={0}
                dataKey="month"
                tickLine={false}
                tick={{ fontSize: 9, angle: 0 }}
              />

              <XAxis
                xAxisId={1}
                dy={-15}
                dx={0}
                label={{
                  value: "",
                  angle: 0,
                  position: "bottom",
                }}
                dataKey="year"
                tickLine={false}
                tick={{ fontSize: 9, angle: 0 }}
              />
              <YAxis
                label={{
                  value: "Shop",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="count"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
                label={<CustomizedLabel />}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="vertical-space-20"></div>

      <div className="flexRow">
        <div className="labelWrapper">
          <h5>User List</h5>
        </div>
        <div id="displayTable" className="flexColumn">
          <DisplayTable
            data={userList}
            validKeys={[
              "userId10Digit",
              "shopName",
              "userName",
              "shopType",
              "shopAddress",
              "userPincode",
              "userAddedDate",
            ]}
            title="Downloads"
          />
        </div>
      </div>
    </>
  );
}

const CustomizedLabel = ({ x, y, stroke, value }) => {
  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={15} textAnchor="middle">
      {value}
    </text>
  );
};
