import React, { useState, useEffect, useMemo } from "react";
import Form from "react-bootstrap/Form";
import MaterialTable from "material-table";
import config from "../config";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
export default function ReachUsInbox() {
  const [userType, setUserType] = useState("Shop");
  const [messageStatus, setMessageStatus] = useState("Unread");
  const [messageInbox, setMessageInbox] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    getReachusInbox();
  }, [userType, messageStatus]);
  const getReachusInbox = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/reachus`, {
        method: "POST",
        body: JSON.stringify({ userType, messageStatus }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        setMessageInbox(data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const columns = useMemo(
    () => [
      {
        title: "Action",
        field: "action",
        hidden: messageStatus == "read",
        render: (rowData) => (
          <Button onClick={() => updateMessageStatus(rowData)} size="sm">
            Mark As Read
          </Button>
        ),
      },
      {
        title: "Ghoshak Id",
        field: "userId10Digit",
      },
      {
        title: "Shop Name",
        field: "shopName",
        hidden: !(userType == "Shop"),
      },

      {
        title: "Message Details",
        field: "messageDetails",
      },
      {
        title: "Name",
        field: "userName",
      },
      {
        title: "Message Id",
        field: "messageId",
      },
      {
        title: "Shop Type",
        field: "shopType",
        hidden: !(userType == "Shop"),
      },
      {
        title: "Address",
        field: "shopAddress",
        hidden: !(userType == "Shop"),
      },
      {
        title: "Address",
        field: "userAddress",
        hidden: !(userType == "Customer"),
      },
      {
        title: "Phone Number",
        field: "userPhoneNumber",
      },

      {
        title: "Posted On",
        field: "messageAddedDate",
      },
    ],
    [userType, messageStatus, messageInbox]
  );
  const updateMessageStatus = async (input) => {
    await Promise.all([
      fetch(`${config.ApiGateway}/reachusMessageUpdate`, {
        method: "POST",
        body: JSON.stringify({
          userId: input.userId,
          messageId: input.messageId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        alert();
        getReachusInbox();
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="flexContainer">
        <div className="vertical-space-10"></div>

        <div className="flexBoxRow">
          <div className="flexBoxHeader">
            <h2 className="orderHeaderText">Reach Us</h2>
          </div>
          <div className="wrapContent">
            <div id="displayTable" className="flexBoxColumn">
              <div className="flexBoxColumn">
                <label>User Type</label>
                <select
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                >
                  <option value="Shop">Shop</option>
                  <option value="Customer">Customer</option>
                </select>
              </div>
              <div
                style={{
                  justifyContent: "flex-end",
                }}
                id="max55"
                className="flexBoxColumn"
              >
                <label style={{ textAlign: "right" }}>Message Status</label>
                <select
                  value={messageStatus}
                  onChange={(e) => setMessageStatus(e.target.value)}
                >
                  <option value="read">Read</option>
                  <option value="Unread">Un Read</option>
                </select>
              </div>
            </div>
            <div id="displayTable" className="flexBoxColumn">
              <MaterialTable
                title={`${userType} - ${messageStatus} Inbox`}
                columns={columns}
                data={messageInbox}
                style={{ width: "100%" }}
                isLoading={isLoading}
                options={{
                  exportButton: true,
                  pageSize: 20,
                  rowStyle: { fontSize: 12 },
                  pageSizeOptions: [5, 10, 20, 100, 1000, 500],
                  grouping: false,
                  filtering: false,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
