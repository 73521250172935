import { Table, Dropdown, Typography, Space, Input, message } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";

import React, { useEffect, useState } from "react";
import "../styles/broadCasting.css";
import ReactModal from "react-modal";
import { ColorRing } from "react-loader-spinner";
import uuid from "uuid/dist/v4";
import { groupBy, sumBy, isEqual } from "lodash";
import config from "../config";
import S3 from "react-aws-s3";
import { RiCloseCircleLine } from "react-icons/ri";
import { BiAddToQueue } from "react-icons/bi";
import { uploadJson, uploadImageToS3 } from "../service";

const SendNotifications = ({ campaignData = {}, updateJson }) => {
  const { TextArea } = Input;

  const [sendingNotification, setSendingNotification] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedProduct, setSelectedProduct] = useState("");
  const notificationObjInit = {
    title: "",
    text: "",
    img: "",
  };
  const [notificationObj, setNotificationObj] = useState({
    ...notificationObjInit,
  });
  const [sendNotificationModal, setSendNotificationModal] = useState(false);
  const feedbackToast = {
    success: function (message) {
      messageApi.open({
        type: "success",
        content: message,
      });
    },
    error: function (message) {
      messageApi.open({
        type: "error",
        content: message,
      });
    },
  };

  useEffect(() => {
    if (selectedProduct && selectedProduct != "default") {
      setSendNotificationModal(true);
    }
  }, [selectedProduct]);
  async function sendCustomerNotification(payload) {
    if (!payload?.userId) return;
    try {
      var sendCustomerNotificationRes = await fetch(
        `${config.coreBackendGateway}/customer/sendCustomerNotification`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "x-api-key": `${config.coreBackendApiKey}`,
            "Content-Type": "application/json",
          },
        }
      );
      sendCustomerNotificationRes = await sendCustomerNotificationRes.json();
      return sendCustomerNotificationRes;
    } catch (error) {
      console.error("Error in event handler:", error);
      return {
        statusCode: 500,
      };
      // Display an error message to the user, if necessary
    }
  }
  const sendNotifications = async () => {
    setSendingNotification(true);
    if (!notificationObj.title) {
      setSendingNotification(false);
      return feedbackToast.error("Enter the notification Title");
    }

    if (!notificationObj.text && !notificationObj.img) {
      setSendingNotification(false);

      return feedbackToast.error("Please add message or image.");
    }
    let payload = {
      userId: selectedProduct,
      from: "crm",
      title: notificationObj.title,
      body: notificationObj.description,
      image: notificationObj.img,
    };
    const response = await sendCustomerNotification(payload);
    if (response?.statusCode === 200) {
      document.querySelector("#notifImgSN").value = "";
      setNotificationObj({ ...notificationObj, ...notificationObjInit });
      setSendingNotification(false);
      feedbackToast.success("Notifications sent successfully");
    }
    setSendingNotification(false);
  };
  const customStylesSendNotification = {
    content: {
      width: "450px",
      height: "500px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      padding: "5px",
      marginRight: "-50%",
      borderRadius: "12px",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      background: "white",
    },
    overlay: {
      background: `rgba(0, 0, 0, 0.5)`,
    },
  };
  const ringLoader = () => {
    return (
      <ColorRing
        visible={true}
        height="20"
        width="20"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#f6666", "#f6666", "#f6666", "#f6666", "#f6666"]}
      />
    );
  };

  const notifDataHandler = (e) => {
    let { name, value } = e.target;
    setNotificationObj({ ...notificationObj, [name]: value });
  };
  const imageUploader = async (image) => {
    let bucketParams = {
      bucketName: "ghoshak-website-builder",
      region: "ap-south-1",
      dirName: "NOTIFICATIONS",
      fileName: uuid() + ".jpeg",
    };

    try {
      let data = await uploadImageToS3(image, bucketParams);
      if (data.includes("https")) {
        setNotificationObj({ ...notificationObj, img: data });
      } else {
        console.log("Image addder response", data);
        feedbackToast.error("Error adding image,please try again later");
      }
    } catch (err) {
      console.log("Image addder response", err);
      feedbackToast.error("Error adding image,please try again later");
      return {
        code: "500",
        message: "Image upload failed,please try again",
        status: "error",
      };
    }
  };

  return (
    <div className="topNotifications_Main_Div">
      {contextHolder}
      <div className="topNotifications_Header">
        <select
          className="selectOfProductSN"
          id="productSelectSN"
          onChange={(e) => setSelectedProduct(e.target.value)}
        >
          <option className="default" value="default">
            Select Product
          </option>
          <option value="kirme">Ghoshak B2B</option>
          <option value="pos">POS</option>
          <option value="onlineStoreDashBoard">Onlinestore Dashboard</option>
          <option value="ghoDeliveryB2B">GhoDelivery B2B</option>
          <option value="ghoDeliveryDashBoard">GhoDelivery Dashboard</option>
        </select>
      </div>
      <ReactModal
        isOpen={sendNotificationModal}
        style={customStylesSendNotification}
        onRequestClose={() => {
          document.querySelector("#notifImgSN").value = "";
          setNotificationObj({ ...notificationObj, ...notificationObjInit });
          setSendNotificationModal(false);
        }}
      >
        <div className="sendNotificationModal_Header">
          <div className="sendNotificationModal_Title">Send Notification</div>
        </div>
        <div className="sendNotificationModal_Body">
          <div className="selectNotifyTitleDiv">
            <div className="chooseImgCD">Title</div>
            <Input
              showCount
              maxLength={30}
              value={notificationObj.title}
              onChange={notifDataHandler}
              name="title"
            />
          </div>
          <div className="selectNotifyTitleDiv">
            <div className="chooseImgCD">Text</div>
            <TextArea
              showCount
              maxLength={100}
              value={notificationObj.text}
              onChange={notifDataHandler}
              name="text"
              style={{ minHeight: "100px" }}
            />
          </div>
          <div className="selectNotifyImgDiv">
            <div className="chooseImgCD">Choose Image</div>
            <div className="sendNotAddImgLabelDiv">
              {notificationObj.img && (
                <img
                  src={
                    notificationObj.img ||
                    "https://ghoshak-website-builder.s3.ap-south-1.amazonaws.com/defaultImage.png"
                  }
                  width={280}
                  height={155}
                  style={{ objectFit: "cover", borderRadius: "7px" }}
                />
              )}
              {notificationObj.img ? (
                <RiCloseCircleLine
                  className="notiImgRemoveIcon"
                  onClick={() => {
                    document.querySelector("#notifImgSN").value = "";
                    setNotificationObj({ ...notificationObj, img: "" });
                  }}
                />
              ) : (
                <label for="notifImgSN" className="sendNotAddImgLabel">
                  <BiAddToQueue className="sendNotAddImgIcon" />
                </label>
              )}
              <input
                id="notifImgSN"
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={(e) => imageUploader(e)}
                style={{ display: "none" }}
              />
            </div>
          </div>
          <div className="send_Notif_Btn_Div">
            <div
              className={
                sendingNotification
                  ? "send_Notif_Btn_Inner_Div_Disabled"
                  : "send_Notif_Btn_Inner_Div"
              }
              onClick={() => sendNotifications()}
            >
              <div className="send_Word">Send</div>
              {sendingNotification && ringLoader()}
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default SendNotifications;
