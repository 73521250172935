import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import config from "../config";
import { CSVLink } from "react-csv";

const styles = theme => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide"
  },
  table: {
    minWidth: 340
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5
  }
});
class CouponDistribution extends Component {
  constructor(props) {
    super(props);
    this.month = [];
    const year = new Date().getFullYear();
    this.dropdownYears = Array.from(
      new Array(20),
      (val, index) => index + year
    );
    this.years = [];
    this.state = {
      couponDetails: [],
      show: false,
      showCouponDetails: true,
      sortDate: new Date().toISOString().substr(0, 10),
      couponDetailsList: [],
      year: undefined,
      month: undefined,
      headers: []
    };
    this.getCouponByMonth = this.getCouponByMonth.bind(this);
    this.getDailyStatus = this.getDailyStatus.bind(this);
    this.showCouponDetails = this.showCouponDetails.bind(this);
    this.getCouponByMonth = this.getCouponByMonth.bind(this);
    this.onChange = this.onChange.bind(this);
    this.export = this.export.bind(this);
    this.csvLink = React.createRef();
  }

  async componentDidMount() {
    await this.getDailyStatus();
  }

  export() {
    const headers = [
      { label: "PINCODE", key: "pincode" },
      { label: "LOYALTY TOTAL COUPON", key: "totalLoyaltyCount" },
      { label: "LOYALTY COUPONS CREATED", key: "LOYALTY" },
      { label: "LOYALTY COUPONS CONSUMED", key: "loyaltyCouponsConsumed" },
      { label: "LOYALTY COUPONS REDEEMED", key: "loyaltyRedeemed" },
      { label: "LOYALTY  REDEEMED VALUE", key: "loyaltyCouponsConsumedValue" },
      { label: "LOYALTY  ALLOCATED VALUE", key: "loyaltyAllocatedValue" },
      { label: "GHOSHAK TOTAL COUPON", key: "totalGhoshakCount" },
      { label: "GHOSHAK COUPONS CREATED", key: "GHOSHAK" },
      { label: "GHOSHAK COUPONS CONSUMED", key: "ghoshakCouponsConsumed" },
      { label: "GHOSHAK COUPONS REDEEMED", key: "ghoshakRedeemed" },
      { label: "GHOSHAK  REDEEMED VALUE", key: "ghoshakCouponsConsumedValue" },
      { label: "GHOSHAK  ALLOCATED VALUE", key: "ghoshakAllocatedValue" },
      { label: "MERCHANT TOTAL COUPON", key: "totalMerchantCount" },
      { label: "MERCHANT COUPONS CREATED", key: "MERCHANT" },
      { label: "MERCHANT COUPONS CONSUMED", key: "merchantCouponsConsumed" },
      { label: "MERCHANT COUPONS REDEEMED", key: "merchantRedeemed" },
      {
        label: "MERCHANT  REDEEMED VALUE",
        key: "merchantCouponsConsumedValue"
      },
      { label: "MERCHANT  ALLOCATED VALUE", key: "merchantAllocatedValue" }
    ];

    this.setState(
      {
        headers: headers
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }

  sortByDate(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
        show: false
      },
      () => {
        this.getDailyStatus();
      }
    );
  }
  showCouponDetails(coupondetails) {
    this.setState({
      showCouponDetails: false,
      couponDetailsList: coupondetails
    });
  }
  getDailyStatus() {
 
    Promise.all([
      fetch(
        `${config.ApiGateway}/coupondistribution`,

        {
          method: "POST",
          body: JSON.stringify({
            month: this.state.month,
            year: this.state.year
          }),

          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        // let merchantExpiry = [];
      

        // data.forEach(element => {
        //   Object.entries(element.merchantExpiryStatus).forEach(
        //     ([args1, args2]) => {
        //       let getExpiry = store_six_months.find(
        //         element => args1 === element
        //       );
        //       if (getExpiry !== undefined) {
              
        //       }
        //     }
        //   );
        // });
        // console.log(merchantExpiry);
        return this.setState({
          couponDetails: data,
          show: true
        });
      })
      .catch(err => console.log(err));
  }

  getCouponByMonth(e) {
    e.preventDefault();
    Promise.all([
      fetch(
        `${config.ApiGateway}/coupondistribution`,

        {
          method: "POST",
          body: JSON.stringify({
            month: this.state.month,
            year: this.state.year
          }),

          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        return this.setState({
          couponDetails: data,
          show: true
        });
      })
      .catch(err => alert("function call failed"));
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render() {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    this.month = monthNames.map(items => {
      return <option>{items}</option>;
    });

    this.years = this.dropdownYears.map((year, index) => {
      return (
        <option key={`year${index}`} value={year}>
          {year}
        </option>
      );
    });
    const { classes } = this.props;
    return (
      <Container fluid>
        {this.state.show ? (
          <>
            <Row>
              <Col>
                {this.state.showCouponDetails ? (
                  <>
                    <div
                      style={{
                        backgroundColor: "#fafafa",

                        margin: 1 + "%"
                      }}
                    >
                      <h3 style={{ textAlign: "center" }}>
                        {" "}
                        COUPON DISTRIBUTION{" "}
                      </h3>
                    </div>

                    <Form onSubmit={this.getCouponByMonth}>
                      <Row style={{ width: 100 + "%", marginBottom: 2 + "%" }}>
                        <Col md={{ span: 3, offset: 3 }}>
                          <Form.Label>MONTH</Form.Label>
                          <Form.Control
                            as="select"
                            name="month"
                            value={this.state.month}
                            onChange={this.onChange}
                            required
                          >
                            <option value="">SELECT MONTH</option>
                            {this.month}
                          </Form.Control>
                        </Col>
                        <Col md={{ span: 3 }}>
                          <Form.Label>YEAR</Form.Label>
                          <Form.Control
                            as="select"
                            name="year"
                            value={this.state.year}
                            onChange={this.onChange}
                            required
                          >
                            <option value="">SELECT YEARS</option>
                            {this.years}
                          </Form.Control>
                        </Col>
                        <Col style={{ marginTop: 1.5 + "%" }}>
                          <Button type="submit">SEARCH</Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={{ span: 4, offset: 8 }}>
                          <Form.Label>DOWNLOAD AS CSV FILE</Form.Label>
                          <Button
                            style={{ marginBottom: 3 + "%" }}
                            onClick={() => {
                              this.export();
                            }}
                          >
                            DOWNLOAD
                          </Button>
                          <CSVLink
                            enclosingCharacter={`'`}
                            data={this.state.couponDetails}
                            headers={this.state.headers}
                            ref={this.csvLink}
                            target="_blank"
                          />
                        </Col>
                      </Row>
                    </Form>

                    <Table
                      style={{
                        // width: 100 + "%",
                        border: 0.5 + "px",
                        borderColor: "#ced4da",
                        borderStyle: "solid"
                      }}
                      className={classes.table}
                      size="small"
                    >
                      <TableHead>
                        <TableRow
                          style={
                            {
                              // background: "#ced4da"
                            }
                          }
                        >
                          <TableCell style={{ textAlign: "center" }}>
                            S.NO
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            PINCODE
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            AREA
                          </TableCell>

                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={7}
                          >
                            LOYALTY COUPON
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={7}
                          >
                            GHOSHAK COUPON
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={7}
                          >
                            MERCHANT COUPON
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={3}></TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            TOTAL COUPON
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            CREATED
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            CLAIMED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            REDEEMED
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            CONSUMED VALUE
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            ALLOCATED COST
                          </TableCell>

                          <TableCell
                            style={{
                              textAlign: "center"
                            }}
                          >
                            COUPON DETAILS
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            TOTAL COUPON
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            CREATED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            CONSUMED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            REDEEMED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            CONSUMED VALUE
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            ALLOCATED COST
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            COUPON DETAILS
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            TOTAL COUPON
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            CREATED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            CONSUMED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            REDEEMED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            CONSUMED VALUE
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            ALLOCATED COST
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            COUPON DETAILS
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.couponDetails.map((elements, index) => (
                          <TableRow key={elements.pincode}>
                            <TableCell className={classes.tableCell}>
                              {index + 1}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.pincode}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {elements.place}
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: "center",
                                borderLeft: 0.5 + "px",
                                borderLeftColor: "#ced4da",
                                borderLeftStyle: "solid"
                              }}
                            >
                              {elements.totalLoyaltyCount}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {elements.LOYALTY}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.loyaltyCouponsConsumed}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.loyaltyRedeemed}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.loyaltyCouponsConsumedValue}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.loyaltyAllocatedValue}
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: "center",
                                borderRight: 0.5 + "px",
                                borderRightColor: "#ced4da",
                                borderRightStyle: "solid"
                              }}
                            >
                              <Button
                                onClick={() => {
                                  this.showCouponDetails(
                                    elements.loyaltyCouponDetails
                                  );
                                }}
                              >
                                VIEW
                              </Button>
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: "center",
                                borderLeft: 0.5 + "px",
                                borderLeftColor: "#ced4da",
                                borderLeftStyle: "solid"
                              }}
                            >
                              {elements.totalGhoshakCount}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.GHOSHAK}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {parseInt(elements.ghoshakCouponsConsumed) -
                                parseInt(
                                  elements.ghoshakRedeemed != undefined
                                    ? elements.ghoshakRedeemed
                                    : 0
                                )}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {elements.ghoshakRedeemed}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {elements.ghoshakCouponsConsumedValue}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.ghoshakAllocatedValue}
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: "center",
                                borderRight: 0.5 + "px",
                                borderRightColor: "#ced4da",
                                borderRightStyle: "solid"
                              }}
                            >
                              <Button
                                onClick={() => {
                                  this.showCouponDetails(
                                    elements.ghoshakCouponDetails
                                  );
                                }}
                              >
                                VIEW
                              </Button>
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: "center",
                                borderLeft: 0.5 + "px",
                                borderLeftColor: "#ced4da",
                                borderLeftStyle: "solid"
                              }}
                            >
                              {elements.totalMerchantCount}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.MERCHANT}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.merchantCouponsConsumed}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {elements.merchantRedeemed}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {elements.merchantCouponsConsumedValue}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {elements.merchantAllocatedValue}
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                                borderRight: 0.5 + "px",
                                borderRightColor: "#ced4da",
                                borderRightStyle: "solid"
                              }}
                            >
                              <Button
                                onClick={() => {
                                  this.showCouponDetails(
                                    elements.merchantCouponDetails
                                  );
                                }}
                              >
                                VIEW
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        backgroundColor: "#fafafa",
                        width: 100 + "%",
                        margin: 1 + "%"
                      }}
                    >
                      <h3 style={{ textAlign: "center" }}> COUPON DETAILS </h3>
                    </div>

                    <Table
                      style={{
                        // width: 100 + "%",
                        border: 0.5 + "px",
                        borderColor: "#ced4da",
                        borderStyle: "solid"
                      }}
                      size="medium"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            S NO
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            COUPON DETAILS
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.couponDetailsList.map((item, index) => (
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              {index + 1}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}
              </Col>
            </Row>
          </>
        ) : (
          "LOADING"
        )}
      </Container>
    );
  }
}
CouponDistribution.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CouponDistribution);
