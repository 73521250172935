import React, { Component } from "react";
import Login from "./authentication/login";
import { Authenticator } from "aws-amplify-react";
import Navigation from "./component/navigationBar";
export default function CustomAuth(InnerClass) {
  return class Wrap extends Component {
    state = {
      authState: "",
    };
    render() {
      return (
        <>
          <Authenticator
            hideDefault={true}
            onStateChange={(authState) => this.setState({ authState })}
          >
            <Navigation />
            {this.state.authState !== "signedIn" ? <Login /> : <InnerClass />}
          </Authenticator>
        </>
      );
    }
  };
}
