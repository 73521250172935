import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Button, Typography, Popover } from "@material-ui/core";
import { Row, Col, Container, Form } from "react-bootstrap";
import MembershipDetails from "./membershipDetails";
import config from "../config";
export default class Membership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId10Digit: "",
      toggle: true,
      userName: "",
      userPhoneNumber: "",
      membershipArray: [],
      userPincode: "",
      shopName: "",
      shopAddress: "",
      user: "",
      lastUpdatedby: "",
      currentDate: new Date().toISOString().substr(0, 10),
      lastMembershipexpiry: "",
      lastUpdatedOn: "",
      membership: {
        userId: "",
        membershipId: "",
        // loyaltyPackage: "",
        membershipPaymentID: "",

        membershipupdatedOn: new Date().toISOString().substr(0, 10),
        membershipupdatedBy: "",
        membershipExpiry: ""
      },
      seeMembership: false,
      message: ""
    };
    this.reset = this.state;
    this.resetForm = this.resetForm.bind(this);
    this.reviewOnchange = this.reviewOnchange.bind(this);
    this.getShop = this.getShop.bind(this);
    this.onChange = this.onChange.bind(this);
    this.membershipOnchange = this.membershipOnchange.bind(this);
    this.membershiptypeOnchange = this.membershiptypeOnchange.bind(this);
    this.membershipUpdate = this.membershipUpdate.bind(this);
  }
  resetForm() {
    this.setState(this.reset);
  }
  // componentDidUpdate() {

  // }
  componentDidMount() {
    var member = [];
    Promise.all([
      fetch(`${config.ApiGateway}/getMembershipLimit`, {
        method: "POST"
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        member = data.map(elements => {
          return (
            <option key={elements.membershipType}>
              {" "}
              {elements.membershipType}
            </option>
          );
        });
        // member = data.select.map(element => {
        //
        // });
        this.setState(
          {
            membershipArray: member
          },
          () => {
            Auth.currentAuthenticatedUser({
              bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            })
              .then(data => {
                let user = { email: data.email, ...data.attributes };
                this.setState({
                  membership: {
                    membershipupdatedBy: user.phone_number,
                    membershipupdatedOn: new Date().toISOString().substr(0, 10)
                  }
                });
              })

              .catch(err => console.log(err));
          }
        );
      });
  }
  membershiptypeOnchange(e) {
    var value = e.target.value;

    fetch(`${config.ApiGateway}/getMembershipLimit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(data => {
        data.forEach(item => {
          if (item.membershipType === value) {
            this.setState({
              ...this.state,
              membership: {
                ...this.state.membership,
                membershipId: item.membershipId
              }
            });
          }
        });
      });
  }
  membershipOnchange(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,
      membership: {
        ...this.state.membership,
        [name]: value
      }
    });
  }
  reviewOnchange(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,
      reviewed: {
        ...this.state.reviewed,
        [name]: value
      }
    });
  }
  onChange(e) {
    var target = e.target;
    var name = target.name;
    var value = target.value;
    this.setState({
      [name]: value //====>
    });
  }
  getShop(e) {
    e.preventDefault();
    fetch(
      `${config.ApiGateway}/getshop`,
      // `${config.ApiGateway}/getshop`,
      {
        method: "POST",

        body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        data.Items.forEach(elements => {
          this.setState({
            ...this.state,

            membership: {
              ...this.state.membership,
              membershipExpiry: elements.membershipExpiry,
              membershipPaymentID: elements.membershipPaymentID,
              membershipId: elements.membershipId,
              userId: elements.userId
              // loyaltyPackage: elements.loyaltyPackage
            },
            lastMembershipexpiry: elements.membershipExpiry,
            lastUpdatedby: elements.membershipupdatedBy,
            lastUpdatedOn: elements.membershipupdatedOn,
            userName: elements.userName,
            userPhoneNumber: elements.userPhoneNumber,
            userId10Digit: elements.userId10Digit,
            userPincode: elements.userPincode,
            shopName: elements.shopName,
            shopAddress: elements.shopAddress,
            toggle: false
          });
        });
      })
      .catch(err => {
        this.setState({
          message: "NO SHOPS FOUND"
        });
      });
  }

  membershipUpdate(e) {
    e.preventDefault();

    fetch(
      `${config.ApiGateway}/membershipUpdate`,
      // `${config.ApiGateway}/membershipUpdate`,
      {
        method: "POST",
        body: JSON.stringify(this.state.membership),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        alert("membership updated");

        this.resetForm();
      })
      .catch(err => {
        console.log(err);
        alert("submit failed");
      });
  }

  render() {
    window.onpopstate = e => {
      e.preventDefault();
      if (window.confirm("are you want to leave the page")) {
        window.location.assign("/");
      } else {
        window.location.assign("/membership");
      }
    };

    return (
      <Container>
        {this.state.toggle && (
          <>
            <div
              style={{
                // backgroundColor: "#fafafa",
                width: 100 + "%",
                margin: 1 + "%"
              }}
            >
              <h3 style={{ textAlign: "center" }}>MEMBERSHIP UPDATE</h3>
            </div>

            <Form onSubmit={this.getShop}>
              <Row>
                <>
                  <Col md={{ span: 8 }}>
                    <Form.Label className="asterik">
                      ENTER YOUR SHOP ID
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="userId10Digit"
                      placeholder="SHOP ID"
                      onChange={this.onChange}
                      value={this.state.userId10Digit}
                      // required
                    />
                  </Col>
                  <Col>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ marginTop: 33 + "px" }}
                    >
                      submit
                    </Button>

                    <h4 style={{ marginTop: 5 + "%" }}>{this.state.message}</h4>
                  </Col>
                </>
              </Row>
            </Form>
          </>
        )}

        {!this.state.toggle && (
          <>
            <Form onSubmit={this.membershipUpdate}>
              <Row>
                <Col md={{ span: 8 }}>
                  <Typography
                    variant="h3"
                    component="h2"
                    style={{ textAlign: "center" }}
                    gutterBottom
                  >
                    SHOP DETAILS
                  </Typography>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      SHOP NAME
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="shopName"
                        onChange={this.reviewOnchange}
                        value={this.state.shopName}
                        disabled={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      MERCHANT NAME
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="userName"
                        onChange={this.reviewOnchange}
                        value={this.state.userName}
                        disabled={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      SHOP ID
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="userId10Digit"
                        value={this.state.userId10Digit}
                        disabled={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      PHONE NUMBER
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="userPhoneNumber"
                        value={this.state.userPhoneNumber}
                        disabled={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      PINCODE
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="pincode"
                        onChange={this.reviewOnchange}
                        value={this.state.userPincode}
                        disabled={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Typography
                    variant="h3"
                    component="h2"
                    style={{ textAlign: "center" }}
                    gutterBottom
                  >
                    MEMBERSHIP DETAILS
                  </Typography>

                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      MEMBERSHIP ID
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        onChange={this.membershipOnchange}
                        name="membershipId"
                        disabled={true}
                        value={this.state.membership.membershipId}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      MEMBERSHIP TYPE
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="membershipType"
                        value={this.state.membership.membershipType}
                        onChange={this.membershiptypeOnchange}
                      >
                        <option value="">CHOOSE MEMBERSHIP</option>
                        {this.state.membershipArray}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      MEMBERSHIP PAYMENT ID
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        onChange={this.membershipOnchange}
                        name="membershipPaymentID"
                        // disabled={true}
                        value={this.state.membership.membershipPaymentID}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      MEMBERSHIP EXPIRY DATE
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="date"
                        min={
                          this.state.lastMembershipexpiry ||
                          this.state.currentDate
                        }
                        name="membershipExpiry"
                        onChange={this.membershipOnchange}
                        value={this.state.membership.membershipExpiry}
                        required
                      />
                    </Col>
                  </Form.Group>

                  {/* <Form.Row>
                    <Col>
                      <Form.Label>ACTIVE LOYALTY PACKAGE</Form.Label>
                    </Col>
                    <Col>
                      <Form.Check
                        label="YES"
                        type="radio"
                        name="loyaltyPackage"
                        onChange={this.membershipOnchange}
                        checked={this.state.membership.loyaltyPackage === "yes"}
                        value="yes"
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        label="NO"
                        type="radio"
                        name="loyaltyPackage"
                        onChange={this.membershipOnchange}
                        checked={this.state.membership.loyaltyPackage === "no"}
                        value="no"
                      />
                    </Col>
                  </Form.Row> */}

                  <Button
                    variant="outlined"
                    type="submit"
                    style={{
                      float: "right",
                      marginBottom: 20 + "px",
                      marginTop: 20 + "px"
                    }}
                  >
                    submit
                  </Button>
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    onClick={() => {
                      this.setState({
                        seeMembership: true
                      });
                    }}
                  >
                    AVAIALBLE MEMBERSHIP PACKAGES
                  </Button>
                  <Popover
                    open={this.state.seeMembership}
                    onClose={() => {
                      this.setState({
                        seeMembership: false
                      });
                    }}
                  >
                    <MembershipDetails />
                  </Popover>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ textAlign: "center" }}
                  gutterBottom
                >
                  LAST MEMBERSHIP UPDATED/CREATED BY
                </Typography>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ textAlign: "center" }}
                  gutterBottom
                >
                  {this.state.lastUpdatedby}
                </Typography>
              </Col>
              <Col>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ textAlign: "center" }}
                  gutterBottom
                >
                  LAST MEMBERSHIP UPDATED/CREATED ON
                </Typography>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ textAlign: "center" }}
                  gutterBottom
                >
                  {this.state.lastUpdatedOn}
                </Typography>
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  }
}
