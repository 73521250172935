import React, { Component } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  Legend
} from "recharts";

import Grid from "@material-ui/core/Grid/Grid";
import { CSVLink } from "react-csv";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import config from "../../config";
import Paper from "@material-ui/core/Paper/Paper";
export default class PincodeInfo extends Component {
  constructor(props) {
    super(props);
    this.shopInfo = [];
    this.state = {
      pincodeInfo: "",
      // pincode: [],
      pincode: "",
      segment: [],
      totalOffers: "",
      showData: false,
      temp: [],
      Offerlist: [],
      Collectionlist: [],
      stats: [],
      Zeroofferlist: [],
      showMerchantSegment: false,
      totalOfferStats: [],
      totalCollectionStats: [],
      totalRegistration: "",
      offerShopDetails: [],
      totalShops: [],
      collectionShopDetails: [],
      topCollection: [],
      topOffer: [],
      headers: [],
      data: [],
      totalSegements: [],
      couponDetails: []
    };
    this.pincodeSearch = this.pincodeSearch.bind(this);
    this.onChange = this.onChange.bind(this);
    this.renderFunction = this.renderFunction.bind(this);
    this.export = this.export.bind(this);
    this.csvLink = React.createRef();
  }
  pincodeSearch(e) {
    e.preventDefault();

    var userDetails = [];

    var tempShopid = [];

    var totalOffers = [];
    var allShops = [];
    var offers = [];
    var totalShopsegment = [];
    var noOffers = [];
    var shopOfferlist = [];
    var userArray = [];
    var collectionShoplist = [];

    Promise.all([
      fetch(
        `${config.Elasticsearch.URL}/users/_search?size=10000`,

        {
          method: "POST",
          body: JSON.stringify({
            query: {
              bool: {
                filter: [
                  {
                    match: {
                      userType: "Shop"
                    }
                  }
                ],
                must: [
                  {
                    terms: {
                      userPincode: [this.state.pincode]
                    }
                  }
                ]
              }
            }
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      ),
      fetch(`${config.json.URL}/fields.json`)
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, data2]) => {
        userDetails = data.hits.hits.map(element => element._source);

        userDetails.forEach(items => {
          userArray.push({
            userId: items.userId,
            userId10Digit: items.userId10Digit,
            shopName: items.shopName,
            shopType: items.shopType,
            shopAddress: items.shopAddress,
            userPincode: items.userPincode,
            userPhoneNumber: items.userPhoneNumber,
            userName: items.userName
          });
        });

        tempShopid = userDetails.map(items => items.userId); // To get ShopId
        totalShopsegment = userDetails.map(items => items.shopType); // To get Shoptype

        Promise.all([
          fetch(
            `${config.Elasticsearch.URL}/offers/_search?size=10000`,

            {
              method: "POST",
              body: JSON.stringify({
                query: {
                  constant_score: {
                    filter: {
                      bool: {
                        should: [{ terms: { shopId: tempShopid } }]
                      }
                    }
                  }
                }
              }),
              headers: {
                "Content-Type": "application/json"
              }
            }
          ),
          fetch(
            `${config.Elasticsearch.URL}/coupons/_search?size=10000`,

            {
              method: "POST",
              body: JSON.stringify({
                query: {
                  constant_score: {
                    filter: {
                      bool: {
                        must: [
                          { match: { couponCategory: "Created" } },
                          { match: { pincode: this.state.pincode } }
                        ]
                      }
                    }
                  }
                }
              }),
              headers: {
                "Content-Type": "application/json"
              }
            }
          )
        ])
          .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
          .then(([data1, coupons]) => {
            offers = data1.hits.hits.map(element => element._source);
            let tempCoupon = coupons.hits.hits.map(element => element._source);
            totalOffers = offers.map(items => items.offerId);
            var result = {};

            tempCoupon.map(item => {
              if (result[item.pincode] == undefined) {
                result[item.pincode] = {
                  merchantCouponsConsumed: 0,
                  merchantCouponsConsumedValue: 0,
                  totalMerchantcoupon: 0,
                  merchantAllocatedValue: 0,
                  ghoshakCouponsConsumed: 0,
                  ghoshakCouponsConsumedValue: 0,
                  ghoshakAllocatedValue: 0,
                  totalGhoshakcoupon: 0,
                  loyaltyCouponsConsumed: 0,
                  loyaltyCouponsConsumedValue: 0,
                  totalLoyaltycoupon: 0,
                  loyaltyAllocatedValue: 0,
                  loyaltyCouponDetails: [],
                  merchantCouponDetails: [],
                  ghoshakCouponDetails: []
                };
              }

              if (result[item.pincode][item.couponType] == undefined) {
                result[item.pincode][item.couponType] = 1;
              } else {
                result[item.pincode][item.couponType] += 1;
              }

              if (item.couponType === "LOYALTY") {
                result[item.pincode].loyaltyCouponsConsumed +=
                  parseInt(item.totalCouponCount) -
                  parseInt(item.remainingCouponCount);
                result[item.pincode].loyaltyCouponsConsumedValue +=
                  (parseInt(item.totalCouponCount) -
                    parseInt(item.remainingCouponCount)) *
                  parseInt(item.maximumCouponAmount);

                result[item.pincode].totalLoyaltycoupon += parseInt(
                  item.totalCouponCount
                );
                result[item.pincode].loyaltyAllocatedValue +=
                  parseInt(item.totalCouponCount) *
                  parseInt(item.maximumCouponAmount);
                result[item.pincode].loyaltyCouponDetails.push(
                  item.couponDetails
                );
              } else if (item.couponType === "MERCHANT") {
                result[item.pincode].merchantCouponsConsumed +=
                  parseInt(item.totalCouponCount) -
                  parseInt(item.remainingCouponCount);
                result[item.pincode].merchantCouponsConsumedValue +=
                  (parseInt(item.totalCouponCount) -
                    parseInt(item.remainingCouponCount)) *
                  parseInt(item.maximumCouponAmount);
                result[item.pincode].totalMerchantcoupon += parseInt(
                  item.totalCouponCount
                );
                result[item.pincode].merchantAllocatedValue +=
                  parseInt(item.totalCouponCount) *
                  parseInt(item.maximumCouponAmount);

                result[item.pincode].merchantCouponDetails.push(
                  item.couponDetails
                );
              } else if (item.couponType === "GHOSHAK") {
                result[item.pincode].ghoshakCouponsConsumed +=
                  parseInt(item.totalCouponCount) -
                  parseInt(item.remainingCouponCount);
                result[item.pincode].ghoshakCouponsConsumedValue +=
                  (parseInt(item.totalCouponCount) -
                    parseInt(item.remainingCouponCount)) *
                  parseInt(item.maximumCouponAmount);
                result[item.pincode].totalGhoshakcoupon += parseInt(
                  item.totalCouponCount
                );
                result[item.pincode].ghoshakAllocatedValue +=
                  parseInt(item.totalCouponCount) *
                  parseInt(item.maximumCouponAmount);
                result[item.pincode].ghoshakCouponDetails.push(
                  item.couponDetails
                );
              }
            });

            var returnArray = [];
            Object.entries(result).forEach(([key, value]) => {
              let obj = {
                pincode: key,
                merchantCouponsConsumedValue:
                  value.merchantCouponsConsumedValue,
                ghoshakCouponsConsumed: value.ghoshakCouponsConsumed,
                ghoshakCouponsConsumedValue: value.ghoshakCouponsConsumedValue,
                merchantCouponsConsumed: value.merchantCouponsConsumed,
                loyaltyCouponsConsumed: value.loyaltyCouponsConsumed,
                loyaltyCouponsConsumedValue: value.loyaltyCouponsConsumedValue,

                MERCHANT: value.MERCHANT == undefined ? 0 : value.MERCHANT,
                LOYALTY: value.LOYALTY == undefined ? 0 : value.LOYALTY,
                GHOSHAK: value.GHOSHAK == undefined ? 0 : value.GHOSHAK,
                totalGhoshakCount:
                  value.totalGhoshakcoupon == undefined
                    ? 0
                    : value.totalGhoshakcoupon,
                totalMerchantCount:
                  value.totalMerchantcoupon == undefined
                    ? 0
                    : value.totalMerchantcoupon,
                totalLoyaltyCount:
                  value.totalLoyaltycoupon == undefined
                    ? 0
                    : value.totalLoyaltycoupon,
                ghoshakAllocatedValue:
                  value.ghoshakAllocatedValue == undefined
                    ? 0
                    : value.ghoshakAllocatedValue,
                merchantAllocatedValue:
                  value.merchantAllocatedValue == undefined
                    ? 0
                    : value.merchantAllocatedValue,
                loyaltyAllocatedValue:
                  value.loyaltyAllocatedValue == undefined
                    ? 0
                    : value.loyaltyAllocatedValue,
                loyaltyCouponDetails: value.loyaltyCouponDetails,
                merchantCouponDetails: value.merchantCouponDetails,
                ghoshakCouponDetails: value.ghoshakCouponDetails
              };

              returnArray.push(obj);
            });

            userArray.forEach(element => {
              var a;
              var b;

              var count = 0;
              var collectionCount = 0;
              offers.forEach(items => {
                if (items.offerType === "offer") {
                  if (items.shopId === element.userId) {
                    count++;
                    a = "offer";
                  }
                } else if (items.offerType === "item") {
                  if (items.shopId === element.userId) {
                    collectionCount++;
                    b = "item";
                  }
                }
              });
              // console.log(c);
              if (a === "offer") {
                shopOfferlist.push({
                  totaloffers: count,
                  userId: element.userId,
                  userId10Digit: element.userId10Digit,
                  shopName: element.shopName,
                  shopType: element.shopType,
                  shopAddress: element.shopAddress,
                  userPincode: element.userPincode,
                  userPhoneNumber: element.userPhoneNumber,
                  userName: element.userName
                });
              }
              if (b === "item") {
                collectionShoplist.push({
                  totalCollection: collectionCount,
                  userId: element.userId,
                  userId10Digit: element.userId10Digit,
                  shopName: element.shopName,
                  shopType: element.shopType,
                  shopAddress: element.shopAddress,
                  userPincode: element.userPincode,
                  userPhoneNumber: element.userPhoneNumber,
                  userName: element.userName
                });
              }
              // console.log(element);
              if (a !== "offer" && b !== "item") {
                noOffers.push({
                  totalItems: "0",
                  userId: element.userId,
                  userId10Digit: element.userId10Digit,
                  shopName: element.shopName,
                  shopType: element.shopType,
                  shopAddress: element.shopAddress,
                  userPincode: element.userPincode,
                  userPhoneNumber: element.userPhoneNumber,
                  userName: element.userName
                });
              }

              allShops.push({
                totalCollection: collectionCount,
                totaloffers: count,
                userId: element.userId,
                userId10Digit: element.userId10Digit,
                shopName: element.shopName,
                shopType: element.shopType,
                userPincode: element.userPincode,
                shopAddress: element.shopAddress,
                userPhoneNumber: element.userPhoneNumber,
                userName: element.userName
              });
            });

            this.setState(
              {
                totalOffers: totalOffers.length,
                segment: userArray,
                Collectionlist: collectionShoplist,
                Zeroofferlist: noOffers,
                Offerlist: shopOfferlist,
                totalShops: allShops,
                totalRegistration: tempShopid.length,
                totalSegements: data2,
                couponDetails: returnArray
              },
              () => {
                this.renderFunction();
              }
            );
          });
      });

    this.setState({
      showData: true
    });
  }
  onChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({
      [name]: value
    });
  }
  renderFunction() {
    var shopDetails = [];
    var statistics = [];
    var zero_ten = [];
    var zeroOffers = [];
    var lessThanFifty = [];
    var lessThanNinetyOffers = [];
    var greaterThanHundred = [];
    var zero = 0;
    var ten = 0;
    var fifty = 0;
    var ninety = 0;
    var shopCollectionDetails = [];
    var hundred = 0;

    var totalOfferStatistics = [];
    var totalCollectionStatistics = [];
    /*****     OFFER  *****************************/
    this.state.Offerlist.forEach(element => {
      if (element.totaloffers >= 1 && element.totaloffers <= 9) {
        zero_ten.push(element);

        ten++;
      } else if (element.totaloffers === 0) {
        zeroOffers.push(element);
        zero++;
      } else if (element.totaloffers <= 50 && element.totaloffers >= 10) {
        lessThanFifty.push(element);
        fifty++;
      } else if (element.totaloffers >= 51 && element.totaloffers <= 99) {
        lessThanNinetyOffers.push(element);
        ninety++;
      } else if (element.totaloffers >= 100) {
        greaterThanHundred.push(element);
        hundred++;
      }
    });

    totalOfferStatistics.push(
      {
        ZEROoffers: zero
      },
      {
        TENoffers: ten
      },
      {
        FIFTYoffers: fifty
      },
      {
        NINETYoffers: ninety
      },
      {
        greaterThanHundred: hundred
      }
    );
    shopDetails.push(
      {
        name: "zero offers",
        value: zeroOffers
      },
      { name: "1-9", value: zero_ten },
      { name: "10-50", value: lessThanFifty },
      { name: "51-99", value: lessThanNinetyOffers },
      { name: "100-500", value: greaterThanHundred }
    );
    /********************  COLLECTIONS*********************** */
    zero = 0;
    ten = 0;
    fifty = 0;
    hundred = 0;
    ninety = 0;
    zero_ten = [];
    zeroOffers = [];
    lessThanFifty = [];
    lessThanNinetyOffers = [];
    this.state.Collectionlist.forEach(element => {
      // console.log(element);
      if (element.totalCollection >= 1 && element.totalCollection <= 9) {
        zero_ten.push(element);

        ten++;
      } else if (element.totalCollection === 0) {
        zeroOffers.push(element);
        zero++;
      } else if (
        element.totalCollection <= 50 &&
        element.totalCollection >= 10
      ) {
        lessThanFifty.push(element);
        fifty++;
      } else if (
        element.totalCollection >= 51 &&
        element.totalCollection <= 90
      ) {
        lessThanNinetyOffers.push(element);
        ninety++;
      } else if (element.totaloffers >= 100) {
        greaterThanHundred.push(element);
        hundred++;
      }
    });

    totalCollectionStatistics.push(
      {
        ZEROoffers: zero
      },
      {
        TENoffers: ten
      },
      {
        FIFTYoffers: fifty
      },
      {
        NINETYoffers: ninety
      },
      {
        greaterThanHundred: hundred
      }
    );
    shopCollectionDetails.push(
      {
        name: "zero offers",
        value: zeroOffers
      },
      { name: "1-9", value: zero_ten },
      { name: "10-50", value: lessThanFifty },
      { name: "51-99", value: lessThanNinetyOffers },
      { name: "100-500", value: greaterThanHundred }
    );

    /*****************   SEGMENT         ********************** */

    var count = 0;
    this.state.totalSegements.shopTypes.slice(1).map(elements => {
      count = 0;
      this.state.segment.forEach(items => {
        if (elements.value === items.shopType) {
          count++;
        }
      });

      statistics.push({
        name: elements.value.toUpperCase(),
        totalCount: count
      });
    });

    var statss = statistics.sort((a, b) => b.totalCount - a.totalCount);

    var top10Collection = this.state.Collectionlist.sort(
      (a, b) => b.totalCollection - a.totalCollection
    );

    var top10Offer = this.state.Offerlist.sort(
      (a, b) => b.totaloffers - a.totaloffers
    );

    // console.log(shopCollectionDetails);
    this.setState({
      // stats: statistics,
      stats: statss,
      totalOfferStats: totalOfferStatistics,
      totalCollectionStats: totalCollectionStatistics,
      offerShopDetails: shopDetails,
      collectionShopDetails: shopCollectionDetails,
      showMerchantSegment: true,
      topCollection: top10Collection,
      topOffer: top10Offer
    });
  }
  export(response) {
    const headers = [
      { label: "SHOP ID", key: "userId10Digit" },
      { label: "SHOP NAME", key: "shopName" },
      { label: "SHOP TYPE", key: "shopType" },
      { label: "TOTAL OFFERS", key: "totaloffers" },
      { label: "TOTAL COLLECTION", key: "totalCollection" },
      { label: "ADDRESS", key: "shopAddress" },
      { label: "USERNAME", key: "userName" },
      { label: "PHONE NUMBER", key: "userPhoneNumber" },
      { label: "PINCODE", key: "userPincode" }
    ];
    this.setState(
      {
        data: response,
        headers: headers
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }

  render() {
    return (
      <>
        <Typography variant="h2" style={{ textAlign: "center" }} gutterBottom>
          {this.props.title}
        </Typography>
        <Row>
          <Col md={{ span: 4, offset: 3 }} className="division">
            <h4 style={{ textAlign: "center" }}>VIEW PINCODE STATS</h4>
            <Form onSubmit={this.pincodeSearch}>
              <Form.Control
                type="text"
                name="pincode"
                onChange={this.onChange}
              ></Form.Control>
              <Button
                style={{
                  width: 100 + "%",
                  height: 30 + "%",
                  marginTop: 2 + "%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                type="submit"
              >
                {" "}
                SEARCH
              </Button>
            </Form>
          </Col>
        </Row>
        {this.state.showMerchantSegment && (
          <>
            <Row style={{ width: 100 + "%" }}>
              <Col md={{ span: 3 }} className="division">
                <b>TOTAL REGISTRATION</b>
                <h3 style={{ textAlign: "center" }}>
                  {this.state.totalRegistration}
                </h3>
              </Col>
              <Col md={{ span: 3, offset: 1 }} className="division">
                <b>
                  <span style={{ textAlign: "center" }}>TOTAL OFFERS </span>
                </b>
                <h3 style={{ textAlign: "center" }}>
                  {this.state.Offerlist.length}
                </h3>
              </Col>
              <Col md={{ span: 3, offset: 1 }} className="division">
                <b>TOTAL COLLECTION</b>
                <h3 style={{ textAlign: "center" }}>
                  {this.state.Collectionlist.length}
                </h3>
              </Col>
            </Row>
            <Row>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  margin: 1 + "%"
                }}
              >
                <h3 style={{ textAlign: "center" }}> COUPON DISTRIBUTION </h3>
              </div>

              <Grid container>
                <Grid item xs={12} md={12}>
                  <Paper
                    style={{
                      overflowX: "auto",
                      width: 100 + "%",
                      overflowY: "hidden"
                    }}
                  >
                    <Table
                      style={{
                        // width: 100 + "%",
                        border: 0.5 + "px",
                        borderColor: "#ced4da",
                        borderStyle: "solid"
                      }}
                      size="small"
                    >
                      <TableHead>
                        <TableRow style={{}}>
                          <TableCell style={{ textAlign: "center" }}>
                            S.NO
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            PINCODE
                          </TableCell>

                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={7}
                          >
                            LOYALTY COUPON
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={7}
                          >
                            GHOSHAK COUPON
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={7}
                          >
                            MERCHANT COUPON
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}></TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            TOTAL COUPON
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            CREATED
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            CONSUMED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            REDEEMED
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            CONSUMED VALUE
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            ALLOCATED COST
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            TOTAL COUPON
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            CREATED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            CONSUMED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            REDEEMED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            CONSUMED VALUE
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            ALLOCATED COST
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            TOTAL COUPON
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            CREATED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            CONSUMED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            REDEEMED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            CONSUMED VALUE
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            ALLOCATED COST
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.couponDetails.map((elements, index) => (
                          <TableRow key={elements.pincode}>
                            <TableCell style={{ textAlign: "center" }}>
                              {index + 1}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {elements.pincode}
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: "center",
                                borderLeft: 0.5 + "px",
                                borderLeftColor: "#ced4da",
                                borderLeftStyle: "solid"
                              }}
                            >
                              {elements.totalLoyaltyCount}
                            </TableCell>

                            <TableCell style={{ textAlign: "center" }}>
                              {elements.LOYALTY}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {elements.loyaltyCouponsConsumed}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {elements.loyaltyRedeemed}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {elements.loyaltyCouponsConsumedValue}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {elements.loyaltyAllocatedValue}
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: "center",
                                borderLeft: 0.5 + "px",
                                borderLeftColor: "#ced4da",
                                borderLeftStyle: "solid"
                              }}
                            >
                              {elements.totalGhoshakCount}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {elements.GHOSHAK}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {elements.ghoshakCouponsConsumed}
                            </TableCell>

                            <TableCell style={{ textAlign: "center" }}>
                              {elements.ghoshakRedeemed}
                            </TableCell>

                            <TableCell style={{ textAlign: "center" }}>
                              {elements.ghoshakCouponsConsumedValue}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {elements.ghoshakAllocatedValue}
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: "center",
                                borderLeft: 0.5 + "px",
                                borderLeftColor: "#ced4da",
                                borderLeftStyle: "solid"
                              }}
                            >
                              {elements.totalMerchantCount}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {elements.MERCHANT}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {elements.merchantCouponsConsumed}
                            </TableCell>

                            <TableCell style={{ textAlign: "center" }}>
                              {elements.merchantRedeemed}
                            </TableCell>

                            <TableCell style={{ textAlign: "center" }}>
                              {elements.merchantCouponsConsumedValue}
                            </TableCell>

                            <TableCell style={{ textAlign: "center" }}>
                              {elements.merchantAllocatedValue}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              </Grid>
            </Row>

            <Row style={{ width: 100 + "%" }}>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  margin: 1 + "%"
                }}
              >
                <h3 style={{ textAlign: "center" }}> SEGMENT DISTRIBUTION </h3>
              </div>
              <Col className="division">
                <div
                  style={{
                    backgroundColor: "#fafafa",
                    width: 100 + "%",
                    margin: 1 + "%"
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>MERCHANT BY SEGMENT</h3>
                </div>
                <BarChart
                  width={600}
                  height={300}
                  data={this.state.stats}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    interval={0}
                    angle={-45}
                    textAnchor="end"
                    height={100}
                  />
                  <YAxis
                    label={{
                      value: "TOTAL SHOPS",
                      angle: -90,
                      position: "insideLeft"
                    }}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="totalCount" fill="#8884d8">
                    <LabelList dataKey="totalCount" position="top" />
                  </Bar>
                </BarChart>
              </Col>
              <Col className="division">
                <h3 style={{ textAlign: "center" }}>SHOPLIST</h3>
                {this.state.stats.map(row => (
                  <ul style={{ listStyle: "none" }}>
                    <li key={row.name}>
                      <strong>{row.name}</strong> {"  - "}
                      {row.totalCount}
                    </li>
                  </ul>
                ))}
              </Col>
            </Row>

            <Row className="division">
              <Col>
                <div
                  style={{
                    backgroundColor: "#fafafa",
                    width: 100 + "%",
                    margin: 1 + "%"
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>
                    {" "}
                    OVERVIEW OF TOTAL SHOP OFFERS{" "}
                  </h3>
                </div>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid"
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">OFFER RANGE</TableCell>
                      <TableCell align="center">TOTAL SHOPS</TableCell>
                      <TableCell align="center">VIEW SHOP DETAILS</TableCell>
                      <TableCell align="center">DOWNLOAD</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH ZERO OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {/* {this.state.totalOfferStats[0].ZEROoffers} */}
                        {this.state.Zeroofferlist.length}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.props.info(
                                this.state.Zeroofferlist,
                                "TOTAL COLLECTION"
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(this.state.Zeroofferlist);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">2</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH LESS THAN 10 OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.totalOfferStats[1].TENoffers}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.props.info(
                                this.state.offerShopDetails[1].value,
                                "TOTAL COLLECTION"
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(this.state.offerShopDetails[1].value);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">3</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH 11 - 50 OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.totalOfferStats[2].FIFTYoffers}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.props.info(
                                this.state.offerShopDetails[2].value,
                                "TOTAL COLLECTION"
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(this.state.offerShopDetails[2].value);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">4</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH 50-90 OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.totalOfferStats[3].NINETYoffers}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.props.info(
                                this.state.offerShopDetails[3].value,
                                "TOTAL COLLECTION"
                              );
                            }}
                          >
                            View
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(this.state.offerShopDetails[3].value);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">5</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH MORE THAN 100 OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.totalOfferStats[4].greaterThanHundred}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.props.info(
                                this.state.offerShopDetails[4].value,
                                "TOTAL COLLECTION"
                              );
                            }}
                          >
                            View
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(this.state.offerShopDetails[4].value);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Col>
            </Row>

            <Row className="division">
              <Col>
                <div
                  style={{
                    backgroundColor: "#fafafa",
                    width: 100 + "%",
                    margin: 1 + "%"
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>
                    {" "}
                    OVERVIEW OF TOTAL SHOP COLLECTIONS{" "}
                  </h3>
                </div>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid"
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">OFFER RANGE</TableCell>
                      <TableCell align="center">TOTAL SHOPS</TableCell>
                      <TableCell align="center">VIEW SHOP DETAILS</TableCell>
                      <TableCell align="center">DOWNLOAD</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH ZERO COLLECTIONS
                      </TableCell>
                      <TableCell align="center">
                        {/* {this.state.totalCollectionStats[0].ZEROoffers} */}
                        {this.state.Zeroofferlist.length}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.props.info(
                                // this.state.collectionShopDetails[0].value
                                this.state.Zeroofferlist,
                                "TOTAL OFFERS"
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(
                                // this.state.collectionShopDetails[0].value
                                this.state.Zeroofferlist
                              );
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">2</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH LESS THAN 10 COLLECTIONS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.totalCollectionStats[1].TENoffers}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.props.info(
                                this.state.collectionShopDetails[1].value,
                                "TOTAL OFFERS"
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(
                                this.state.collectionShopDetails[1].value
                              );
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">3</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH 11 - 50 COLLECTIONS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.totalCollectionStats[2].FIFTYoffers}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.props.info(
                                this.state.collectionShopDetails[2].value,
                                "TOTAL OFFERS"
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(
                                this.state.collectionShopDetails[2].value
                              );
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">4</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH 50-90 COLLECTIONS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.totalCollectionStats[3].NINETYoffers}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.props.info(
                                this.state.collectionShopDetails[3].value,
                                "TOTAL OFFERS"
                              );
                            }}
                          >
                            View
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(
                                this.state.collectionShopDetails[3].value
                              );
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">5</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH MORE THAN 100 COLLECTIONS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.totalCollectionStats[4].greaterThanHundred}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.props.info(
                                this.state.collectionShopDetails[4].value,
                                "TOTAL OFFERS"
                              );
                            }}
                          >
                            View
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(
                                this.state.collectionShopDetails[4].value
                              );
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Col>
            </Row>
            <Row className="division">
              <Col>
                <h4>VIEW ALL SHOPS</h4>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    this.props.info(this.state.totalShops);
                  }}
                >
                  VIEW
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    this.export(this.state.totalShops);
                  }}
                >
                  DOWNLOAD
                </Button>
              </Col>

              {/* <Col>
                <Button
                  onClick={() => {
                    this.props.info(this.state.Zeroofferlist);
                  }}
                >
                  VIEW ZERO OFFER BY SHOPS
                </Button>
              </Col> */}
            </Row>
            <Row className="division">
              <Col>
                <h4>TOP 10 OFFERS</h4>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    this.props.info(this.state.topOffer);
                  }}
                >
                  VIEW
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    this.export(this.state.topOffer);
                  }}
                >
                  DOWNLOAD
                </Button>
              </Col>
            </Row>
            <Row className="division">
              <Col>
                <h4>TOP 10 COLLECTION</h4>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    this.props.info(this.state.topCollection);
                  }}
                >
                  VIEW
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    this.export(this.state.topCollection);
                  }}
                >
                  DOWNLOAD
                </Button>
              </Col>
            </Row>
            <Row>
              <CSVLink
                data={this.state.data}
                headers={this.state.headers}
                ref={this.csvLink}
                target="_blank"
              />
            </Row>
          </>
        )}
      </>
    );
  }
}
