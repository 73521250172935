import React, { useState, useEffect, Fragment } from "react";
import S3 from "react-aws-s3";
import uuid from "uuid/dist/v4";
import config from "../../config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { ChromePicker } from "react-color";
import { Modal, Button } from "react-bootstrap";

const TopNotificationBar = (props) => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [notificationDetails, setNotificationDetails] = useState("");
  const [showTimer, setShowTimer] = useState(false);
  const [textColor, setTextColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [websiteTemplate, setWebsiteTemplate] = useState();
  const [colorChooseModal, setColorChooseModal] = useState(false);
  const [colorType, setColorType] = useState("");

  const [active, setActive] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [templateName, setTemplateName] = useState("");

  const fetchDetails = async () => {
    await fetchUserDetails(props.shopId).then((data) => {
      var shopData = data[0];
      if (shopData.websiteTemplate) {
        setWebsiteTemplate(shopData.websiteTemplate);
        var websiteData = shopData.websiteTemplate;
        if (websiteData.topNotification) {
          setActive(
            websiteData.topNotification.active
              ? websiteData.topNotification.active
              : active
          );
          setTemplateId(
            websiteData.topNotification.templateId
              ? websiteData.topNotification.templateId
              : ""
          );
          setTemplateName(
            websiteData.topNotification.templateName
              ? websiteData.topNotification.templateName
              : ""
          );
          var pageProps = websiteData.topNotification.pageProps;
          if (pageProps.cardData.length > 0) {
            var cardData = pageProps.cardData[0];
            if (cardData.startTime)
              setStartTime(cardData.startTime.replace(" ", "T"));
            if (cardData.endTime)
              setEndTime(cardData.endTime.replace(" ", "T"));
            setBgColor(cardData.bgColor);
            setNotificationDetails(cardData.notificationDetails);
            setTextColor(cardData.textColor);
          }
        }
      }
    });
  };
  useEffect(() => {
    fetchDetails();
  }, [props]);

  const handleSave = async () => {
    let dataObj = {};
    if (websiteTemplate) {
      dataObj = {
        ...websiteTemplate,
      };
      if (websiteTemplate.topNotification) {
        dataObj = {
          ...dataObj,
          topNotification: {
            ...websiteTemplate.topNotification,
            active: active,
            templateId: templateId,
            templateName: templateName,
            pageProps: {
              cardData: [
                {
                  startTime: startTime.replace("T", " "),
                  endTime: endTime.replace("T", " "),
                  bgColor: bgColor,
                  notificationDetails: notificationDetails,
                  showTimer: showTimer,
                  textColor: textColor,
                },
              ],
            },
          },
        };
      } else {
        dataObj = {
          ...dataObj,
          topNotification: {
            active: active,
            templateId: templateId,
            templateName: templateName,
            pageProps: {
              cardData: [
                {
                  startTime: startTime.replace("T", " "),
                  endTime: endTime.replace("T", " "),
                  bgColor: bgColor,
                  notificationDetails: notificationDetails,
                  showTimer: showTimer,
                  textColor: textColor,
                },
              ],
            },
          },
        };
      }
    } else {
      dataObj = {
        colorTheme: "default",
        order: [
          "aboutUs",
          "about",
          "onlineProduct",
          "ourServices",
          "ourProjects",
          "ourBrands",
          "ourClients",
          "ourGallery",
          "clientTestimonials",
          "videoGallery",
          "ourPartner",
          "socialMedia",
          "ourTeam"
        ],
        topNotification: {
          pageProps: {
            cardData: [
              {
                startTime: startTime.replace("T", " "),
                endTime: endTime.replace("T", " "),
                bgColor: bgColor,
                notificationDetails: notificationDetails,
                showTimer: showTimer,
                textColor: textColor,
              },
            ],
          },
          active: active,
          templateId: templateId,
          templateName: templateName,
        },
      };
    }
    await contentupload(dataObj, props.shopId).then((data) => {
      fetchDetails();
    });
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h3>Top Notification Bar</h3>
      <strong>
        <label>Show Top Notification</label>
      </strong>{" "}
      <FormControlLabel
        control={
          <Switch
            checked={active}
            onChange={() => setActive(!active)}
            color="primary"
          />
        }
      ></FormControlLabel>
      <div>
        <strong>Notification Details</strong>
        <br />
        <textarea
          value={notificationDetails}
          placeholder="Enter Your notification text"
          onChange={(e) => setNotificationDetails(e.target.value)}
          rows="2"
          cols="40"
        />
      </div>
      <div>
        <strong>
          <label>Enable Timer</label>
        </strong>{" "}
        <FormControlLabel
          control={
            <Switch
              checked={showTimer}
              onChange={() => setShowTimer(!showTimer)}
              color="primary"
            />
          }
        ></FormControlLabel>
      </div>
      {showTimer && (
        <div>
          <div>
            <strong>
              <label>Start Date and Time</label>
            </strong>
            <input
              type="datetime-local"
              value={startTime}
              max={new Date().toISOString().substr(0, 10)}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </div>
          <div>
            <strong>
              <label>End Date and Time</label>
            </strong>
            <input
              type="datetime-local"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              min={new Date(startTime)}
            />
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          textAlign: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div style={{ margin: "20px" }}>
          <strong>
            <p>Choose Background Color</p>
          </strong>
          <div
            onClick={() => {
              setColorType("backGround");
              setColorChooseModal(true);
            }}
            style={{
              width: "200px",
              height: "30px",
              border: "2px solid grey",
              backgroundColor: `${bgColor}`,
              cursor: "pointer",
            }}
          ></div>
        </div>
        <div style={{ margin: "20px" }}>
          <strong>
            <p>Choose Text Color</p>
          </strong>
          <div
            onClick={() => {
              setColorType("text");
              setColorChooseModal(true);
            }}
            style={{
              width: "200px",
              height: "30px",
              border: "2px solid grey",
              backgroundColor: `${textColor}`,
              cursor: "pointer",
            }}
          ></div>
        </div>
        <div></div>
      </div>
      <div>
        <strong>
          <label>Choose Template</label>
        </strong>
        <br />
        <select
          onChange={(e) => {
            setTemplateId(e.target.value);
            setTemplateName(e.target.name);
          }}
          value={templateId}
          style={{
            width: "300px",
          }}
        >
          <option value="" disabled>
            Select a template
          </option>
          <option value="topBar_001" name="Squarescape">
            Squarescape
          </option>
        </select>{" "}
      </div>
      <br />
      <Button onClick={() => handleSave()}>Save</Button>
      <Modal
        show={colorChooseModal}
        onHide={() => {
          setColorChooseModal(!colorChooseModal);
          setColorType("");
        }}
      >
        <Modal.Header>
          <h4>
            {colorType === "backGround"
              ? "Choose Background Color"
              : "Choose Text Color"}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <ChromePicker
            color={colorType === "backGround" ? bgColor : textColor}
            onChangeComplete={(e) =>
              colorType === "backGround"
                ? setBgColor(e.hex)
                : setTextColor(e.hex)
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => {
              setColorChooseModal(!colorChooseModal);
              setColorType("");
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const fetchUserDetails = async (shopId) => {
  if (shopId === "") return alert("Enter valid Shop Id");
  return await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
      method: "POST",
      body: JSON.stringify({
        userId10Digit: shopId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data[0].websiteTemplate);
      return data;
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("Something went wrong, please try again");
    });
};

const contentupload = async (obj, userId) => {
  await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainContentUpload`, {
      method: "POST",
      body: JSON.stringify({
        obj: obj,
        userId: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data);
      alert("uploaded success");
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("error while uploading data. Please try again");
    });
};

export default TopNotificationBar;
