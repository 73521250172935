import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  root: {
    display: "flex",

    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
  editCell: {
    paddingRight: 6,
    paddingLeft: 6,
  },
});

const DeliveryTable = ({
  group,
  category,
  subCategory,
  productName,
  productBrand,
  productMrp,
  unitOfMeasurment,
  productQty,
  index,
  edit,
  productEANCode,
  editProduct,
  productId,
  deleteProduct,
  isEditatble,
}) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.tableCell}>{index + 1}</TableCell>

      <TableCell className={[classes.tableCell]}>{group}</TableCell>
      <TableCell className={classes.tableCell}>{category}</TableCell>
      <TableCell className={classes.tableCell}>{subCategory}</TableCell>
      <TableCell className={classes.tableCell}>{productName}</TableCell>
      <TableCell className={classes.tableCell}>{productBrand}</TableCell>
      <TableCell className={classes.tableCell}>{productMrp}</TableCell>
      <TableCell className={classes.tableCell}>{unitOfMeasurment}</TableCell>
      <TableCell className={classes.tableCell}>{productQty}</TableCell>
      <TableCell className={classes.tableCell}>{productEANCode}</TableCell>
      {edit && (
        <>
          <TableCell
            onClick={() => editProduct(productId)}
            className={classes.editCell}
          >
            <EditIcon />
          </TableCell>
          <TableCell
            onClick={() => deleteProduct(productId)}
            className={classes.editCell}
          >
            <DeleteIcon />
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default DeliveryTable;
