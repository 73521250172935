import React, { Component } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

import { CSVLink } from "react-csv";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter
} from "@material-ui/core";
import config from "../../config";
import Limit from "../../limit";
import DropDown from "../../dropDown";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide"
  },
  table: {
    minWidth: 340
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5
  }
});
class CouponsCreated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponList: [],
      toggle: true,
      sortbydate: "",
      selectPincode: "All",
      couponType: "All",
      headers: [],
      couponCategoryArray: [],
      pincodeDropDown: [],
      allPincode: [],
      endDate: Limit.size.endDate,
      startDate: "2020-01-09",
      couponCategory: "Created",
      page: 0,
      rowsPerPage: 10
    };
    this.reset = this.state;
    this.getShop = this.getShop.bind(this);
    this.onChange = this.onChange.bind(this);
    this.searchCoupon = this.searchCoupon.bind(this);
    this.getMerchantDetails = this.getMerchantDetails.bind(this);
    this.csvLink = React.createRef();
    this.export = this.export.bind(this);
    this.deleteCouponFuction = this.deleteCouponFuction.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  async deleteCouponFuction(couponId) {
    let couponDeletedBy = "";
    let couponDeletedOn = new Date().toISOString().slice(0, 10);
    await Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(data => {
        let user = { email: data.email, ...data.attributes };
        couponDeletedBy = user.phone_number;
      })

      .catch(err => console.log(err));

    Promise.all([
      await fetch(
        `${config.ApiGateway}/deletecoupon`,

        {
          method: "POST",

          body: JSON.stringify({
            couponId: couponId.couponId,
            couponCategory: "Deleted",
            couponDeletedBy: couponDeletedBy,
            couponDeletedOn: couponDeletedOn
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert("coupon deleted successfully");

        this.setState(this.reset);
        this.componentDidMount();
      })
      .catch(err => console.log(err));
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  export() {
    const headers = [
      { label: "PINCODE", key: "pincode" },
      { label: "SHOP NAME", key: "shopName" },
      { label: "SHOP TYPE", key: "shopType" },
      { label: "USER ID", key: "userId10Digit" },
      { label: "ADDRESS", key: "userAddress" },
      { label: "PHONE NUMBER", key: "userPhoneNumber" },
      { label: "NAME", key: "userName" },
      { label: "COUPON TYPE", key: "couponType" },
      { label: "USER ADDED DATE", key: "addedDate" },
      { label: "MAXUIMUM COUPON AMOUNT", key: "maximumCouponAmount" },
      { label: "DETAILS", key: "couponDetails" }
    ];

    this.setState(
      {
        headers: headers
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }

  searchCoupon(e) {
    e.preventDefault();
    // console.log(this.state.couponType);
    var tempdata = [];
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/coupons/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                this.state.couponCategory !== "All"
                  ? {
                      match: {
                        couponCategory: this.state.couponCategory
                      }
                    }
                  : {
                      terms: {
                        couponCategory: ["Created", "Deleted"]
                      }
                    },
                {
                  range: {
                    addedDate: {
                      gte: this.state.startDate,
                      lte: this.state.endDate,
                      boost: 2.0
                    }
                  }
                }
              ],
              must_not: [{ term: { pincode: Limit.size.ghoshakInternal } }]
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map(element => element._source);
        var coupondetails = [];
        var shopId = [];
        tempdata.forEach(items => {
          coupondetails.push({
            userId10Digit: items.userId10Digit,
            pincode: items.pincode,
            maximumCouponAmount: items.maximumCouponAmount,
            shopId: items.shopId,
            totalCouponCount: items.totalCouponCount,
            couponDetails: items.couponDetails,
            addedDate: items.addedDate,
            couponType: items.couponType,
            couponCategory: items.couponCategory,
            couponId: items.couponId
          });
          shopId.push(items.shopId);
        });
        shopId = [...new Set(shopId)];

        this.getShop(shopId, coupondetails);
      })
      .catch(err => console.log(err));
  }
  componentDidMount() {
    let userPincode = [];
    var tempdata = [];
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/coupons/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [{ match: { couponCategory: "Created" } }],
              must_not: {
                bool: {
                  must: [{ term: { pincode: Limit.size.ghoshakInternal } }]
                }
              }
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      }),
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must_not: {
                bool: {
                  must: [{ term: { userPincode: "111111" } }]
                }
              }
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      }),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1, res2, res3]) =>
        Promise.all([res1.json(), res2.json(), res3.json()])
      )
      .then(([couponCreated, data, data2]) => {
        tempdata = couponCreated.hits.hits.map(element => element._source);
        data.hits.hits.forEach(element => userPincode.push(element._source));

        userPincode = userPincode.map(elements => elements.userPincode);

        userPincode = [...new Set(userPincode)];
        userPincode = userPincode.filter(items => items !== undefined);
        var mergePincodeAndArea = userPincode.map(items => {
          let count = data2.Items.find(elements => items === elements.pincode);
          let appendObject = {};

          count !== undefined
            ? (appendObject = Object.assign({}, count))
            : (appendObject = Object.assign(
                {},
                {
                  pincode: items,
                  place: "NOT FOUND"
                }
              ));

          return appendObject;
        });

        let mergedArea = mergePincodeAndArea.map(elements => (
          <option key={elements.pincode}>
            {elements.place.toUpperCase()}
            {" - "}
            {elements.pincode}
          </option>
        ));
        this.setState({
          pincodeDropDown: mergedArea,
          allPincode: userPincode
        });

        var coupondetails = [];
        var shopId = [];
        tempdata.forEach(items => {
          coupondetails.push({
            userId10Digit: items.userId10Digit,
            pincode: items.pincode,
            maximumCouponAmount: items.maximumCouponAmount,
            shopId: items.shopId,
            totalCouponCount: items.totalCouponCount,
            couponDetails: items.couponDetails,
            addedDate: items.addedDate,
            couponType: items.couponType,
            couponCategory: items.couponCategory,
            couponId: items.couponId
          });
          shopId.push(items.shopId);
        });
        shopId = [...new Set(shopId)];

        this.getShop(shopId, coupondetails);
      })
      .catch(err => console.log(err));
  }
  getShop(shopId, coupondetails) {
    var tempdata = [];
    // console.log(shopId);
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              should: [{ terms: { userId: shopId } }]
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      }),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, data2]) => {
        tempdata = data.hits.hits.map(element => element._source);
        var userdetails = {};

        var result = coupondetails.map(items => {
          userdetails = tempdata.find(
            elements => items.shopId === elements.userId
          );
          let appendObject = Object.assign({}, items);
          appendObject.userName =
            userdetails.userName !== undefined ? userdetails.userName : "";
          appendObject.userPhoneNumber =
            userdetails.userPhoneNumber !== undefined
              ? userdetails.userPhoneNumber
              : "";
          appendObject.shopName =
            userdetails.shopName !== undefined ? userdetails.shopName : "";
          appendObject.shopAddress =
            userdetails.shopAddress !== undefined
              ? userdetails.shopAddress
              : "";
          appendObject.shopType =
            userdetails.shopType !== undefined ? userdetails.shopType : "";
          appendObject.userAddedDate =
            userdetails.userAddedDate !== undefined
              ? userdetails.userAddedDate
              : "";
          appendObject.userAddedDate =
            userdetails.userId10Digit !== undefined
              ? userdetails.userId10Digit
              : "";
          return appendObject;
        });

        var mergePincodeAndArea = result.map(items => {
          let count = data2.Items.find(
            elements => items.pincode === elements.pincode
          );

          let appendObject = Object.assign({}, items, count);

          return appendObject;
        });
        const category = DropDown.coupon.couponCategory.map(item => (
          <option>{item}</option>
        ));
        this.setState({
          couponList: mergePincodeAndArea,
          couponCategoryArray: category
        });
      })
      .catch(err => console.log(err));
  }
  getMerchantDetails(userid) {
    this.props.getMerchant(userid);
  }
  render() {
    const { classes } = this.props;
    if (this.state.couponList > 0) {
      const emptyRows =
        this.state.rowsPerPage -
        Math.min(
          this.state.rowsPerPage,
          this.state.couponList.length -
            this.state.page * this.state.rowsPerPage
        );
    }
    return (
      <>
        <Row>
          <Col md={{ span: 10 }}>
            <h3 style={{ textAlign: "center" }}>CREATED COUPON DETAILS</h3>

            <Form onSubmit={this.searchCoupon}>
              <Row style={{ marginTop: 2 + "%", marginBottom: 2 + "%" }}>
                <Col>
                  <Form.Label>START DATE</Form.Label>
                  <Form.Control
                    type="date"
                    name="startDate"
                    onChange={this.onChange}
                    value={this.state.startDate}
                    required
                  ></Form.Control>
                </Col>
                <Col>
                  <Form.Label>END DATE</Form.Label>
                  <Form.Control
                    type="date"
                    name="endDate"
                    onChange={this.onChange}
                    value={this.state.endDate}
                    required
                  ></Form.Control>
                </Col>

                <Col>
                  <Form.Label>COUPON TYPE</Form.Label>
                  <Form.Control
                    as="select"
                    name="couponType"
                    onChange={this.onChange}
                    value={this.state.couponType}
                    required
                  >
                    <option value="Empty">SELECT COUPON TYPE</option>
                    <option value="LOYALTY">LOYALTY</option>
                    <option value="MERCHANT">MERCHANT</option>
                    <option value="GHOSHAK">GHOSHAK</option>
                    <option value="PROMOCODE">PROMOCODE</option>
                    <option value="All">ALL</option>
                  </Form.Control>
                </Col>
                <Col>
                  <Form.Label>COUPON STATUS</Form.Label>
                  <Form.Control
                    as="select"
                    name="couponCategory"
                    onChange={this.onChange}
                    value={this.state.couponCategory}
                    required
                  >
                    <option value="Empty">COUPON CATEGORY</option>
                    {this.state.couponCategoryArray}
                  </Form.Control>
                </Col>

                <Col>
                  <Form.Label>PINCODE</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.onChange}
                    name="selectPincode"
                  >
                    <option value="">SELECT PINCODE</option>
                    <option value="All">All</option>
                    {this.state.pincodeDropDown}
                  </Form.Control>
                </Col>
                <Col style={{ marginTop: 32 + "px" }}>
                  <Button
                    style={{
                      float: "right",

                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    type="submit"
                  >
                    {" "}
                    SEARCH
                  </Button>
                </Col>
                <Col style={{ marginTop: 32 + "px" }}>
                  <Button
                    onClick={() => {
                      this.export();
                    }}
                    style={{
                      float: "right",

                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    DOWNLOAD
                  </Button>
                  <CSVLink
                    enclosingCharacter={`'`}
                    data={this.state.couponList}
                    headers={this.state.headers}
                    ref={this.csvLink}
                    target="_blank"
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.toggle ? (
              <>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid"
                  }}
                  size="small"
                  className={classes.table}
                >
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          S NO
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          SHOPNAME
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          USER ID
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          SHOPTYPE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPON TYPE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPON ADDED ON
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          SHOP PINCODE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          PLACE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          MERCHANT NAME
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          PHONE NUMBER
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPON COUNT
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPON DETAILS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPON VALUE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          SHOP ADDRESS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          DELETE COUPON
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.couponList
                        .filter(
                          coupon =>
                            (this.state.couponType === "All"
                              ? coupon
                              : this.state.couponType === coupon.couponType) &&
                            (this.state.selectPincode === "All"
                              ? coupon
                              : this.state.selectPincode.slice(-6) ===
                                coupon.pincode)
                        )
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((items, index) => (
                          <>
                            <TableRow key={items.userId10Digit}>
                              <TableCell className={classes.tableCell}>
                                {index + 1}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.shopName}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                <Button
                                  variant="link"
                                  onClick={() => {
                                    this.getMerchantDetails(
                                      items.userId10Digit
                                    );
                                  }}
                                >
                                  {items.userId10Digit}
                                </Button>
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {items.shopType}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.couponType}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.addedDate}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.pincode}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.place}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.userName}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.userPhoneNumber}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.totalCouponCount}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.couponDetails}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {items.maximumCouponAmount}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.shopAddress}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                <Button
                                  disabled={
                                    items.couponCategory === "Deleted"
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Are you sure want to delete a coupon"
                                      )
                                    ) {
                                      this.deleteCouponFuction(items);
                                    }
                                  }}
                                >
                                  DELETE COUPON
                                </Button>
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={this.state.couponList.length}
                          rowsPerPage={this.state.rowsPerPage}
                          page={this.state.page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </>
                </Table>
              </>
            ) : (
              "loading"
            )}
          </Col>
        </Row>
      </>
    );
  }
}
CouponsCreated.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CouponsCreated);
