import React, { useState, useEffect } from "react";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { Container, Col, Button, Row, Tabs, Tab, Form } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import config from "../config";
import { useParams, useHistory } from "react-router-dom";
import MaterialTable from "material-table";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
const findUnit = (value) => {
  var returnType = "";
  if (value !== undefined) {
    let unitValue = value.toLowerCase();

    if (["gms", "grams", "g"].includes(unitValue)) returnType = "g";

    if (["kilograms", "kilo grams", "kg"].includes(unitValue))
      returnType = "kg";
    if (["ltr", "litre", "l"].includes(unitValue)) returnType = "L";
    if (["glass"].includes(unitValue)) returnType = "glass";
    if (["cup"].includes(unitValue)) returnType = "cup";
    if (["milli litre", "ml", "milli"].includes(unitValue)) returnType = "ml";
    if (
      ["pcs", "packs", "pieces", "piece", "count", "counts"].includes(unitValue)
    )
      returnType = "count";
  }
  return returnType;
};
export default function AddInventoryToShop() {
  const [productList, setProductList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [productSegment, setProductSegment] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [shopId, setShopId] = useState("");
  const [shopName, setShopName] = useState("");
  const [masterOptions, setMasterOptions] = useState([]);
  const history = useHistory();
  const classes = useStyles();
  const [productShopType, setProductShopType] = useState("grocery");
  const editSubmit = (rowData) => {
    let input = {};
    input = rowData;

    if (input.group === "") return alert("Select sub Category");
    if (input.category === "") return alert("Select sub Category");
    if (input.subCategory === "") return alert("Select sub Category");

    if (input.measurementValue !== "" && input.measurementValue !== undefined) {
    } else {
      return alert("Enter measurement value");
    }

    if (input.productName !== "" && input.productName !== undefined) {
    } else {
      return alert("Enter Product Name");
    }

    if (input.productMrp !== "" && input.productMrp !== undefined) {
    } else {
      return alert("Enter Product Mrp");
    }
    if (input.productQty !== "" && input.productQty !== undefined) {
    } else {
      return alert("Enter Product Qty");
    }
    if (parseInt(input.inventoryQty) <= 0) {
      alert("Inventory Quantity must be greater than 1");
      return false;
    }
    if (input.productPrice === "TBD") {
      alert("Enter Seller Price");
      return false;
    }
    if (input.productPrice === "") {
      alert("Enter Seller Price");
      return false;
    }
    input.productPrice = input.productPrice.toString();

    input.unitOfMeasurment =
      input.measurementValue + " " + input.measurementUnit;
    input.productMrp = input.productMrp.toString();

    input.productQty = input.productQty.toString();

    delete input.measurementUnit;
    delete input.measurementValue;

    input.inventoryQty = parseInt(input.inventoryQty);
    input.shopId = shopId;
    const dataDelete = [...productList];
    const index = input.tableData.id;
    dataDelete.splice(index, 1);
    Promise.all([
      fetch(`${config.ApiGateway}/addInventoryItem`, {
        method: "POST",
        body: JSON.stringify({ productList: [input] }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setProductList([...dataDelete]);
      })
      .catch((err) => {
        alert("Product Not Updated");
        console.log(err);
      });
  };
  const filehandler = (event) => {
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let input = {};
        let products = [];
        let deliveryFormat = [];

        resp.rows.slice(1).map((elements, index) => {
          var input = {};
          elements.map((items, index) => (input[resp.rows[0][index]] = items));
          products.push(input);
        });

        products.forEach((elements) => {
          if (elements.uploadedStatus !== "yes") {
            if (elements.unitOfMeasurment !== undefined) {
              let totalUnitvalue = elements.unitOfMeasurment;
              let unitSplitedvalue = totalUnitvalue.split(" ");

              elements.measurementUnit = findUnit(unitSplitedvalue[1]);

              elements.measurementValue = unitSplitedvalue[0];
            }

            deliveryFormat.push({
              productName: elements.productName,
              inventoryQty:
                elements.inventoryQty !== undefined ? elements.inventoryQty : 1,
              productPrice:
                elements.productPrice !== undefined &&
                elements.productPrice !== null &&
                elements.productPrice !== ""
                  ? elements.productPrice.toString()
                  : "TBD",
              measurementValue: elements.measurementValue,
              measurementUnit: elements.measurementUnit,
              productMrp:
                elements.productMrp !== undefined &&
                elements.productMrp !== null &&
                elements.productMrp !== ""
                  ? elements.productMrp.toString() || "0"
                  : elements.productPrice.toString() || "0",
              subCategory: "",
              group: "",
              category: "",

              productBrand:
                elements.productBrand != undefined
                  ? elements.productBrand
                  : shopName,

              vegNonVeg: "Veg",

              addedDate: new Date().toISOString().substring(0, 10),
              productEANCode:
                elements.productEANCode !== undefined
                  ? elements.productEANCode
                  : "TBD",
              productQty:
                elements.productQty !== undefined ? elements.productQty : "1",
            });
          }
        });

        setProductList(deliveryFormat);
      }
    });
  };

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);

    let unitArray = [];

    async function fetchS3Json() {
      try {
        await Promise.all([
          fetch(
            `${config.ApiGateway}/getCouponLimit`,

            {
              method: "POST",

              body: JSON.stringify({
                userId10Digit: searchParams.get("shopId"),
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          ),

          fetch(`${config.json.URL}/masterList.json`),

          fetch(`${config.json.URL}/fields.json`),
        ])
          .then(([res1, res2, res3]) =>
            Promise.all([res1.json(), res2.json(), res3.json()])
          )
          .then(([data, data1, data2]) => {
            data2.unitOfMesurement.map((elements) =>
              unitArray.push(<option value={elements}>{elements}</option>)
            );
            let getMasterType = [];
            data1.masterList.forEach((elements) => {
              getMasterType.push(
                <option value={elements.type}>{elements.type}</option>
              );
            });
            setMasterOptions(getMasterType);
            setUnitList(unitArray);
            productShopTypeOnchange("grocery");
            if (Object.keys(data).length > 0) {
              setShopId(data.shopId);
              setShopName(data.shopName);
            } else {
              history.push("/inventoryProductsByShop");
              alert("Entered shop id not found");
            }
          })
          .catch((err) => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    if (
      searchParams.get("shopId") !== null &&
      searchParams.get("shopId") !== undefined &&
      searchParams.get("shopId") !== ""
    ) {
      fetchS3Json();
    } else {
      history.push("/inventoryProductsByShop");
      alert("Shop ID Not Found");
    }
  }, []);

  const productShopTypeOnchange = async (value) => {
    if (Boolean(value)) {
      let productTypeJson = await getProductCategory(value);
      let getProductsDropdwn = await CategoryDrowdown(productTypeJson);
    }
  };

  const CategoryDrowdown = (productsJson) => {
    let itemBySubCategoryList = [];
    let subOption = [];
    let subArray = [];
    Object.entries(productsJson.groups).map(([elements, headers]) => {
      Object.entries(headers.category).forEach(([items, values]) => {
        values.subCategory.map(
          (sub) =>
            sub !== "ALL" &&
            itemBySubCategoryList.push({
              group: headers.name,
              category: values.name,
              subCategory: sub,
            }) &&
            subArray.push(sub)
        );
      });
    });
    subArray = subArray.sort();

    subArray.forEach((sub) => {
      subOption.push(<option value={sub}>{sub}</option>);
    });
    setSubCategoryList(subOption);
    setProductSegment(itemBySubCategoryList);
  };

  const getProductCategory = async (productType) => {
    switch (productType) {
      case "grocery":
        try {
          const response = await fetch(
            `${config.json.URL}/productCategory.json`
          );
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }
      case "sweets":
        try {
          const response = await fetch(
            `${config.json.URL}/productCategorySweetsAndSnacks.json`
          );
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }
      case "restaurant":
        try {
          const response = await fetch(`${config.json.URL}/restaurant.json`);
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }
      default:
        return {};
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <h1>Add Inventory Product</h1>
        </Row>

        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            <input
              type="file"
              onChange={filehandler}
              style={{ padding: "10px" }}
            ></input>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Product Shop Type
                <span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Col md={{ span: 9 }}>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setProductShopType(e.target.value);
                    productShopTypeOnchange(e.target.value);
                  }}
                  name="productShopType"
                  value={productShopType}
                >
                  <option value="">Select Product Shop Type</option>
                  {masterOptions}
                </Form.Control>
              </Col>
            </Form.Group>
          </div>
        </div>

        <MaterialTable
          title="Product Listt"
          columns={[
            {
              title: "Group",
              field: "group",
              editComponent: (props) => {
                return <input value={props.value} disabled></input>;
              },
            },
            {
              title: "Category",
              field: "category",
              editComponent: (props) => {
                return <input value={props.value} disabled></input>;
              },
            },
            {
              title: "Sub Category",
              field: "subCategory",
              editComponent: (props) => {
                let subValue = props.value;

                let getCategoryValue = productSegment.find(
                  (elements) => elements.subCategory === subValue
                );

                if (getCategoryValue !== undefined) {
                  props.rowData.category = getCategoryValue.category;
                  props.rowData.group = getCategoryValue.group;
                }

                return (
                  <select
                    type="text"
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                  >
                    <option value="">Select Sub Category</option>
                    {subCategoryList}
                  </select>
                );
              },
            },
            { title: " Inventory Quantity", field: "inventoryQty" },

            { title: "Selling Price ₹", field: "productPrice" },

            { title: "Brand", field: "productBrand" },

            { title: "Product Name", field: "productName" },

            {
              title: "Measurement value",
              field: "measurementValue",
            },

            {
              title: "Measurement Unit",
              field: "measurementUnit",

              editComponent: (props) => {
                return (
                  <select
                    name="measurementUnit"
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                  >
                    <select value="">Select Unit</select>
                    {unitList}
                  </select>
                );
              },
            },

            { title: "Product Quantity ", field: "productQty" },

            { title: "MRP ₹", field: "productMrp" },

            {
              title: "Veg/ Non Veg",
              field: "vegNonVeg",
              editable: "always",
              editComponent: (props) => (
                <select
                  type="text"
                  value={props.value}
                  onChange={(e) => props.onChange(e.target.value)}
                >
                  <option value="Veg">Vegeterian</option>
                  <option value="Non-Veg">Non Vegterian</option>
                </select>
              ),
            },

            {
              title: "Ean Code",
              field: "productEANCode",
              editable: "always",
            },
          ]}
          data={productList}
          actions={[
            {
              icon: "save",
              tooltip: "Save User",
              onClick: (event, rowData) => editSubmit(rowData),
            },
          ]}
          options={{
            search: false,
            pageSize: 30,
            pageSizeOptions: [5, 10, 20, 100, 300, 500],
            grouping: false,
            filtering: false,
          }}
          editable={{
            // only group(b) rows would be deletable

            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  const dataUpdate = [...productList];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setProductList([...dataUpdate]);
                  resolve();
                }, 600);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataDelete = [...productList];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setProductList([...dataDelete]);

                  resolve();
                }, 1000);
              }),
          }}
        />
      </Container>
    </>
  );
}
