import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import config from "../config";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import { Link } from "react-router-dom";
import { responsiveFontSizes } from "@mui/material";
import axios from "axios";

export default function BillingProgram() {
  const [bills, setBills] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);
  //"https://vkbmrh8wc3.execute-api.ap-south-1.amazonaws.com/dev/billingProgram";
  const getData = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/billingProgram`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        console.log("data:-", data);
      })
      .catch((err) => console.log(err));
  };

  //   setTimeout(() => {
  //     console.log('Initial timeout!')
  //     console.log('Initial timeout!')
  // }, 5000);
  // const timer = setTimeout(() => getDataG(), 70000);

  useEffect(() => {
    getDataG();
  }, []);

  //const myRequest = new Request('https://prod-ghoshak.s3.ap-south-1.amazonaws.com/productJson/crmBillingProgram.JSON');//GET

  // React.useEffect(()=> {
  //   async function fetchData(){
  //     await fetch(myRequest)
  //     .then(response => response.json())
  //     .then(data => {
  //       setBills(data);
  //       setIsLoading(false); //  loading state
  //       console.log("length---",data.length);
  //     })
  //     .catch(console.error);
  //     }
  //     fetchData()
  // },[myRequest])

  const getDataG = async () => {
    await axios
      .get(
        "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/productJson/crmBillingProgram.JSON"
      )
      .then(function (response) {
        // handle success
        setBills(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log("getDataG error", error);
      })
      .finally(function () {
        // always executed
      });
    //await fetch(
    //   "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/productJson/crmBillingProgram.JSON",
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // ).then((res1) => res1.json()).then((data) => {
    //   console.log("data343434",data.length)
    // setBills(data);
    // setIsLoading(false); //  loading state
    // })
    // .catch((err) => console.log(err));
  };

  const [getTestData, setGetTestData] = useState([]);
  const getTesterData = () => {
    fetch(
      "https://payload-response.s3.ap-south-1.amazonaws.com/testAccount.json"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setGetTestData(data.testAccount);
      });
  };

  useEffect(() => {
    getTesterData();
  }, []);

  const testdata = [];
  getTestData.forEach((items) => {
    testdata.push(items.userPhoneNumber);
  });
  let getBills = [];
  const data12 = bills.find((i) => i.userId10Digit === "1284883470");
  bills.forEach((items) => {
    if (!testdata.includes(items.userPhoneNumber)) {
      getBills.push(items);
    }
  });

  return (
    <>
      <div className="flexContainer">
        <div className="vertical-space-10"></div>
        {/* <div>getBills: {getBills.length}, {bills.length}</div> */}
        <div className="flexBoxHeader">
          <h5 className="orderHeaderText">Billing Program</h5>
        </div>
        <div className="vertical-space-20"></div>
        <div className="flexBoxRow">
          <div id="displayTable" className="flexBoxColumn">
            <MaterialTable
              title={"Shops"}
              columns={[
                {
                  title: "Ghoshak Id",
                  field: "userId10Digit",
                },
                {
                  title: "userPhoneNumber",
                  field: "userPhoneNumber",
                },
                {
                  title: "Shop Name",
                  field: "shopName",
                },

                {
                  title: "Shop Type",
                  field: "shopType",
                },
                {
                  title: "Address",
                  field: "shopAddress",
                },
                {
                  title: "Package Status",
                  field: "billingPackage",
                  render: (rowData) =>
                    rowData.billingPackage === "yes" ? (
                      <Badge variant="success">Active</Badge>
                    ) : (
                      <Badge variant="danger">Inactive</Badge>
                    ),
                },
                {
                  title: "Expiration",
                  field: "billingPackage",
                  render: (rowData) => {
                    return new Date().toISOString().substring(0, 10) <
                      rowData.billingExpiryDate ? (
                      <p>
                        {rowData.billingExpiryDate} <br />
                        {`( ${moment(rowData.billingExpiryDate).diff(
                          moment(new Date().toISOString().substring(0, 10)),
                          "days"
                        )}days remaining )`}
                      </p>
                    ) : (
                      <Badge variant="danger">Expired</Badge>
                    );
                  },
                },
                {
                  title: "Address",
                  field: "shopAddress",
                },

                {
                  title: "No of Bills",
                  field: "count",
                  defaultSort: "desc",
                },
                {
                  title: "Bill Amount",
                  field: "billAmount",
                  render: ({ billAmount }) =>
                    `₹ ${parseFloat(billAmount || 0).toFixed(2)}`,
                },
                {
                  title: "Customers",
                  field: "customer",
                  render: ({ customer }) => customer.length || 0,
                },

                {
                  title: "Action",
                  field: "action",

                  render: (rowData) => (
                    <Link
                      to={{
                        pathname: "/info",
                        search: `?userid=${JSON.stringify({
                          userId: rowData.userId10Digit,
                          startDate: `null`,
                          endDate: `null`,
                          type: `bill`,
                          indicate: `null`,
                        })}`,
                      }}
                    >
                      <Button size="sm">View</Button>
                    </Link>
                    // <Link
                    //   to={{
                    //     pathname: "/info",
                    //     search: `?userid=${rowData.userId10Digit}`,
                    //   }}
                    // >
                    //   <Button size="sm">View</Button>
                    // </Link>
                  ),
                },
              ]}
              data={getBills}
              style={{ width: "100%" }}
              isLoading={isLoading}
              options={{
                exportButton: true,
                pageSize: 10,
                //   maxBodyHeight: 437,

                pageSizeOptions: [5, 10, 20, 100, 1000, 500],
                grouping: false,
                filtering: false,
                tableLayout: "fixed",
              }}
            />
          </div>
        </div>
        <div className="vertical-space-20"></div>
      </div>
    </>
  );
}

//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@Data Pecker Functionallity@@@@@@@@@@@@@@@@@@@@@@@@
//________________________________________________________________________________________________

// import React, { useState, useEffect } from "react";
// import MaterialTable from "material-table";
// import config from "../config";
// import Button from "react-bootstrap/Button";
// import Badge from "react-bootstrap/Badge";
// import moment from "moment";
// import { Link } from "react-router-dom";
// import { responsiveFontSizes } from "@mui/material";
// import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";

// export default function BillingProgram() {
//   const [bills, setBills] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   var initialDate = new Date();
//   initialDate.setDate(initialDate.getDate() - 30);
//   console.log("initialDate", initialDate);
//   const [dateRange, setDateRange] = useState({
//     startDate: initialDate.toISOString().substring(0, 10),
//     endDate: new Date().toISOString().substring(0, 10),
//   });
//   useEffect(() => {
//     if (dateRange.startDate !== "" && dateRange.endDate !== "") {
//       getData();
//     }
//   }, [dateRange.startDate, dateRange.endDate]);
//   // useEffect(() => {
//   //   getData();
//   // }, []);
//   //ProductionApi: https://uvx5horwme.execute-api.ap-south-1.amazonaws.com/prod/billingProgram
//   //DevApi: https://vkbmrh8wc3.execute-api.ap-south-1.amazonaws.com/dev/billingProgram
//   const getData = async () => {
//     await Promise.all([
//       fetch(
//         "https://vkbmrh8wc3.execute-api.ap-south-1.amazonaws.com/dev/billingProgram",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             startDate: dateRange.startDate,
//             endDate: dateRange.endDate,
//           }),
//         }
//       ),
//     ])
//       .then(([res1]) => Promise.all([res1.json()]))
//       .then(async ([data]) => {
//         console.log(data);
//         var uploadData = [];
//         if (data.status) {
//           try {
//             uploadData = await (await fetch(data.bills)).json();
//             setBills(uploadData);
//             setIsLoading(false);
//             console.log("data from s3 bucket---", uploadData);
//           } catch (err) {
//             alert("Fetch Failed");
//           }
//         } else {
//           setBills(data.bills);
//           setIsLoading(false);
//         }
//         console.log("data:-", data);

//         // setBillsG(data.billsD);
//         // setIsLoadingG(false); //  loading state
//       })
//       .catch((err) => {
//         alert("70 second wait ");
//         setTimeout(() => getDataG(), 70000);
//         console.log(err);
//       });
//   };

//   //   setTimeout(() => {
//   //     console.log('Initial timeout!')
//   //     console.log('Initial timeout!')
//   // }, 5000);

//   // useEffect(() => {
//   //   getDataG();
//   //   // const timer = setTimeout(() => getDataG(), 70000);
//   // }, []);

//   // useEffect(() => {
//   //   getDataG();
//   // }, []);
//   // data fetch from s3 bucket
//   const getDataG = async () => {
//     await Promise.all([
//       fetch(
//         "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/productJson/crmBillingProgram.JSON",
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       ),
//     ])
//       .then(([res1]) => Promise.all([res1.json()]))
//       .then(async ([data]) => {
//         setBills(data);
//         setIsLoading(false); //  loading state
//       })
//       .catch((err) => console.log(err));
//   };

//   return (
//     <>
//       {/* <div style={{ width: "100%", height: "40px" }}></div> */}

//       <div className="flexContainer">
//         <div className="vertical-space-10"></div>
//         <div className="flexBoxHeader">
//           <h5 className="orderHeaderText">Billing Program</h5>
//         </div>
//         <Col sm="4" ml="3" style={{ marginLeft: "1172px" }}>
//           <InputGroup className="mb-4">
//             <InputGroup.Prepend>
//               <InputGroup.Text id="basic-addon1">
//                 <strong> Date Range</strong>
//               </InputGroup.Text>
//             </InputGroup.Prepend>
//             {console.log("startDate", dateRange.startDate)}
//             <Form.Control
//               value={dateRange.startDate}
//               type="date"
//               name="startDate"
//               onChange={(e) =>
//                 setDateRange((prevState) => ({
//                   ...prevState,
//                   [e.target.name]: e.target.value,
//                 }))
//               }
//             ></Form.Control>
//             {console.log("endDate", dateRange.endDate)}
//             <Form.Control
//               type="date"
//               min={dateRange.startDate}
//               value={dateRange.endDate}
//               name="endDate"
//               required
//               onChange={(e) =>
//                 setDateRange((prevState) => ({
//                   ...prevState,
//                   [e.target.name]: e.target.value,
//                 }))
//               }
//             ></Form.Control>
//           </InputGroup>
//         </Col>
//         {/* <div className="vertical-space-20" style={{height:"0px"}}></div> */}
//         <div className="flexBoxRow" style={{ marginTop: "-25px" }}>
//           <div id="displayTable" className="flexBoxColumn">
//             <MaterialTable
//               title={"Shops"}
//               columns={[
//                 {
//                   title: "Ghoshak Id",
//                   field: "userId10Digit",
//                 },
//                 {
//                   title: "Shop Name",
//                   field: "shopName",
//                 },

//                 {
//                   title: "Shop Type",
//                   field: "shopType",
//                 },
//                 {
//                   title: "Address",
//                   field: "shopAddress",
//                 },
//                 {
//                   title: "Package Status",
//                   field: "billingPackage",
//                   render: (rowData) =>
//                     rowData.billingPackage == "yes" ? (
//                       <Badge variant="success">Active</Badge>
//                     ) : (
//                       <Badge variant="danger">Inactive</Badge>
//                     ),
//                 },
//                 {
//                   title: "Expiration",
//                   field: "billingPackage",
//                   render: (rowData) => {
//                     return new Date().toISOString().substring(0, 10) <
//                       rowData.billingExpiryDate ? (
//                       <p>
//                         {rowData.billingExpiryDate} <br />
//                         {`( ${moment(rowData.billingExpiryDate).diff(
//                           moment(new Date().toISOString().substring(0, 10)),
//                           "days"
//                         )}days remaining )`}
//                       </p>
//                     ) : (
//                       <Badge variant="danger">Expired</Badge>
//                     );
//                   },
//                 },
//                 {
//                   title: "Address",
//                   field: "shopAddress",
//                 },

//                 {
//                   title: "No of Bills",
//                   field: "count",
//                   defaultSort: "desc",
//                 },
//                 {
//                   title: "Bill Amount",
//                   field: "billAmount",
//                   render: ({ billAmount }) =>
//                     `₹ ${parseFloat(billAmount || 0).toFixed(2)}`,
//                 },
//                 {
//                   title: "Customers",
//                   field: "customer",
//                   render: ({ customer }) => customer.length || 0,
//                 },

//                 {
//                   title: "Action",
//                   field: "action",

//                   render: (rowData) => (
//                     <Link
//                       to={{
//                         pathname: "/info",
//                         search: `?userid=${rowData.userId10Digit}`,
//                       }}
//                     >
//                       <Button size="sm">View</Button>
//                     </Link>
//                   ),
//                 },
//               ]}
//               data={bills}
//               style={{ width: "100%" }}
//               isLoading={isLoading}
//               options={{
//                 exportButton: true,
//                 pageSize: 10,
//                 //   maxBodyHeight: 437,
//                 pageSizeOptions: [5, 10, 20, 100, 1000, 500],
//                 grouping: false,
//                 filtering: false,
//                 draggable: true,
//                 tableLayout: "fixed",
//               }}
//             />
//           </div>
//         </div>
//         <div className="vertical-space-20"></div>
//       </div>
//     </>
//   );
// }
