import React, { Component } from "react";
import { Row, Col, Container, Form } from "react-bootstrap";
import AddGhoshakGift from "./addGhoshakgift";
import ViewGhoshakGift from "./viewGhoshakgift";

export default class Ghoshakgift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      condition: ""
    };
    this.ghoshakGiftstate = this.ghoshakGiftstate.bind(this);
  }

  ghoshakGiftstate(e) {
    var value = e.target.value;
    this.setState({
      condition: value
    });
  }
  componentDidUpdate() {
    window.onpopstate = e => {
      e.preventDefault();
      if (window.confirm("are you sure want to leave the page ?")) {
        window.location.assign("/");
      } else {
        window.location.assign("/ghoshakgift");
      }
    };
  }
  render() {
    switch (this.state.condition) {
      case "addGift":
        return <AddGhoshakGift />;

      case "giftReceived":
        return <ViewGhoshakGift />;
    }
    return (
      <Container>
        <Form>
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              CHECK
            </Form.Label>

            <Col>
              <Form.Check
                label="ADD GIFT"
                type="radio"
                name="ghoshakGiftState"
                onChange={this.ghoshakGiftstate}
                value="addGift"
              />
            </Col>
            <Col>
              <Form.Check
                label="GIFT RECEIVED"
                type="radio"
                name="ghoshakGiftState"
                onChange={this.ghoshakGiftstate}
                value="giftReceived"
              />
            </Col>
          </Form.Group>
        </Form>
      </Container>
    );
  }
}
