// Import Dependency

import React, { useCallback, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import { GetJSONFields } from "./apiHandler";
import Constant from "../../deleivery/constant";
import Alert from "react-bootstrap/Alert";
import config from "../../config";

export default function Handler() {
  const findHandler = useCallback((handlerName, argument1) => {
    // pick function based on handler value

    switch (handlerName) {
      case "GENERATE_QRCODE":
        return GenerateQRCode();
      case "IMAGE_HANDLER":
        return GetImageBase64(argument1);
      case "COLLECTION_CATEGORY":
        return GetCollectionCategory(argument1);
      case "PRODUCT_HANDLER":
        return GetProductHandler(argument1);
      case "GET_SHOPTYPE":
        return GetShopType(argument1);
      case "SUCCESS_ALERT":
        return SucessMessage(argument1);
      case "FAILED_ALERT":
        return FailedMessage(argument1);
      default:
        return <></>;
    }
  }, []);

  const GetShopType = async () => {
    //fn to get shop dropdwown
    return await new Promise(async (resolve, reject) => {
      await GetJSONFields().then(async (data) => {
        let shopTypeOptions = data.shopTypes
          .slice(1)
          .map((items) => <option value={items.value}>{items.value}</option>);

        resolve(shopTypeOptions);
      });
    });
  };
  const SucessMessage = async () => {
    return await new Promise((resolve, reject) => {
      return resolve(
        <Alert key={"idx"} variant={"success"}>
          This is a alert—check it out!
        </Alert>
      );
    });
  };
  const FailedMessage = async () => {
    return await new Promise((resolve, reject) => {
      resolve("hello Peter");
    });
  };

  const GetProductHandler = async (shopType) => {
    return await new Promise(async (resolve, reject) => {
      // get json field ffrom s3
      // get category, subcategory, unit and duration from json

      let returnType = {
        otherShopType: false,
        fields: [],
        category: [],
        subCategory: [],
        group: [],
        subDropdown: [],
      };
      let getFieldsFromAPI = await getShopJsonbyShopType(shopType);

      if (Constant.SALOON.key.includes(shopType)) {
        // Get Category and duration for saloon and beauty parlor

        let jsonType =
          shopType == "Saloon"
            ? getFieldsFromAPI.salonCategory
            : getFieldsFromAPI.beautyParlourCategory;
        returnType.category = jsonType.map((items) => (
          <option value={items.value}>{items.value}</option>
        ));

        returnType.duration = getFieldsFromAPI.duration.map((items) => (
          <option value={items}>{items}</option>
        ));
        returnType.fields = Constant.SALOON.fields;
      } else if (Constant.KIRANA.key.includes(shopType)) {
        Object.entries(getFieldsFromAPI.groups).map(([elements, headers]) => {
          Object.entries(headers.category).forEach(([items, values]) => {
            values.subCategory.map((sub) => {
              if (sub !== "ALL") {
                returnType.subCategory.push({
                  group: headers.name,
                  category: values.name,
                  subCategory: sub,
                });
                returnType.subDropdown.push(sub);
              }
            });
          });
        });

        returnType.subDropdown = returnType.subDropdown.map((items) => (
          <option value={items}>{items}</option>
        ));

        await GetJSONFields().then((data) => {
          returnType.unit = data.unitOfMesurement.map((items) => (
            <option value={items}>{items}</option>
          ));
        });

        returnType.fields = Constant.KIRANA.fields;
      } else if (Constant.RESTAURANT.key.includes(shopType)) {
        Object.entries(getFieldsFromAPI).forEach(([key, value]) => {
          Object.values(value).forEach((key1) => {
            returnType.group.push(
              <option value={key1.name}>{key1.name}</option>
            );
            Object.values(key1.category).forEach((key2) => {
              Object.values(key2.subCategory).forEach((key3) => {
                returnType.subCategory.push(
                  <option value={key3}>{key3}</option>
                );
              });

              returnType.category.push(
                <option value={key2.name}>{key2.name}</option>
              );
            });
          });
        });
        returnType.fields = Constant.RESTAURANT.fields;
      } else if (Constant.TEXTILE.key.includes(shopType)) {
        // Extract group, category and subcategory for textile segment

        getFieldsFromAPI.category.map((item) => {
          getFieldsFromAPI.subCategory.map((elements) => {
            if (item.value === elements.category) {
              returnType.category.push({
                group: "Others",
                category: elements.category,
                subCategory: elements.value,
              });
            }
          });
        });
        returnType.category = returnType.category
          .sort((a, b) => b.subCategory - a.subCategory)
          .map((items) => (
            <option
              value={JSON.stringify({
                category: items.category,
                subCategory: items.subCategory,
              })}
            >{`${items.category} - ${items.subCategory}`}</option>
          ));
        returnType.fields = Constant.TEXTILE.fields;
      } else if (Constant.MOBILE.key.includes(shopType)) {
        // Extract category for mobile segment

        getFieldsFromAPI.mobileCategory.forEach((items) => {
          returnType.category.push(
            <option value={items.value}>{items.value}</option>
          );
        });
        returnType.fields = Constant.MOBILE.fields;
      } else {
        returnType.otherShopType = true;
        returnType.fields = Constant.OTHERS.fields;
      }

      return resolve(returnType);
    });
  };

  const GenerateQRCode = async () => {
    return await new Promise((resolve, reject) => {
      resolve("hello Peter");
    });
  };
  const GetImageBase64 = async (imagePath) => {
    return await new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        imagePath, //is the file of the new image that can now be uploaded...
        300, // is the maxWidth of the  new image
        400, // is the maxHeight of the  new image
        "JPEG", // is the compressFormat of the  new image
        100, // is the quality of the  new image
        0, // is the rotatoion of the  new image
        (uri) => {
          // Structur{base64,uri}

          resolve({
            base64: uri.replace(/^data:image\/\w+;base64,/, ""),
            imageURI: uri,
            fileName: imagePath.name,
          });
        },
        "base64" // is the output type of the new image
      );
    });
  };

  const GetCollectionCategory = async (shopType) => {
    return await new Promise(async (resolve, reject) => {
      // get json field ffrom s3
      await GetJSONFields().then((data) => {
        let returnType = {};
        if (shopType == "Textile") {
          // Valid for Textile Segment
          // merge category and subcategory
          returnType.category = data.subCategory.map((items) => (
            <option value={JSON.stringify([items.category, items.value])}>
              {items.value}
            </option>
          ));
        } else if (shopType == "Mobile") {
          //valid for mobile segment
          returnType.category = ["mobile", "accessories"].map((item) => {
            return <option value={item}>{item}</option>;
          });
          returnType.operatingSystem = data.operatingSystem.map((items) => (
            <option value={items.value}>{items.value}</option>
          ));
          returnType.brand = data.brand.map((items) => (
            <option value={items.value}>{items.value}</option>
          ));
        }

        resolve(returnType);
      });
    });
  };

  return [findHandler];
}

const getShopJsonbyShopType = async (shopType) => {
  switch (shopType) {
    case "Supermarket":
    case "Provisional Store":
      try {
        const response = await fetch(`${config.json.URL}/productCategory.json`);
        var productsJson = await response.json();
        return productsJson;
      } catch (error) {
        console.log(error);
      }
    case "Sweets and Snacks":
      try {
        const response = await fetch(
          `${config.json.URL}/productCategorySweetsAndSnacks.json`
        );
        var productsJson = await response.json();
        return productsJson;
      } catch (error) {
        console.log(error);
      }
    case "Restaurant":
    case "Bakery":
    case "Juice Shop":
    case "Tea Shop":
      try {
        const response = await fetch(`${config.json.URL}/restaurant.json`);
        var productsJson = await response.json();
        return productsJson;
      } catch (error) {
        console.log(error);
      }
    case "Saloon":
    case "Textile":
    case "Beauty Parlour":
    case "Mobile":
      try {
        const response = await fetch(`${config.json.URL}/fields.json`);
        var productsJson = await response.json();
        return productsJson;
      } catch (error) {
        console.log(error);
      }

    default:
      return {};
  }
};
