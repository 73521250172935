import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from "@material-ui/core";
import config from "../../config";
import { Link } from "react-router-dom";
export default class CustomerGhoshakCoins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showData: true,
      ghoshakCoins: "",
      coins: [],
      coinTable: [],
      tableTitle: "CUSTOMER HAVING MORE THAN 200 GHOSHAK COINS LIST",
      page: 0,
      rowsPerPage: 10
    };
    this.onChange = this.onChange.bind(this);
    this.ghoshakCoinSearch = this.ghoshakCoinSearch.bind(this);
    this.getCustomerCoins = this.getCustomerCoins.bind(this);
    this.fetchCustomerDetails = this.fetchCustomerDetails.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      page: 0
    });
  }
  fetchCustomerDetails(response) {
    var tempdata = [];
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=1000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              filter: [
                {
                  match: {
                    userId: response
                  }
                }
              ]
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map(element => element._source);
        this.props.customerDetails(tempdata[0].userId10Digit);
      })
      .catch(err => console.log(err));
  }
  ghoshakCoinSearch(e) {
    e.preventDefault();
    var showCoins = [];
    // var value = this.state.ghoshakCoins;
    this.state.coins.forEach(element => {
      if (this.state.ghoshakCoins <= element.currentPoints) {
        showCoins.push(element);
      }
    });
    this.setState({
      coinTable: showCoins,
      // showData: false,
      tableTitle:
        " Customer having more than " +
        this.state.ghoshakCoins +
        " Ghoshak coin list"
    });
  }
  async componentDidMount() {
    await this.getCustomerCoins();
  }
  getCustomerCoins() {
    var tempdata = [];

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/points/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                {
                  match: {
                    pointsType: "ghoshak"
                  }
                }
              ],
              must_not: [
                {
                  terms: {
                    points: ["true"]
                  }
                }
              ]
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map(element => element._source);
        let custid = [];

        let custCoinsObj = {};
        tempdata.map(elements => {
          if (custCoinsObj[elements.custId] === undefined) {
            custCoinsObj[elements.custId] = {
              currentPoints: 0,
              added: 0,
              redeemed: 0
            };
          }
          if (elements.pointsAction === "added") {
            custCoinsObj[elements.custId].added += parseInt(elements.points);
            custCoinsObj[elements.custId].currentPoints += parseInt(
              elements.points
            );
          } else if (elements.pointsAction === "redeemed") {
            custCoinsObj[elements.custId].redeemed += parseInt(elements.points);
            custCoinsObj[elements.custId].currentPoints -= parseInt(
              elements.points
            );
          }
        });

        let getCust10idArray = [];
        Object.entries(custCoinsObj).map(([elements, items]) => {
          getCust10idArray.push(elements);
        });

        this.findCustID(getCust10idArray, custCoinsObj);
      })
      .then()
      .catch(err => console.log(err));
  }
  findCustID(custRefArray, custPoints) {
    var showCoins = [];
    let tempCustInfo = [];

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            terms: {
              userId: custRefArray
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempCustInfo = data.hits.hits.map(element => element._source);
        // this.props.customerDetails(tempdata[0].userId10Digit);

        let coins = tempCustInfo.map(elements => {
          let count = Object.entries(custPoints).find(
            ([user, ref]) => elements.userId === user
          );
          let appendObject = {};

          if (count !== undefined) {
            appendObject = Object.assign({}, { userId: count[0] }, count[1]);
            appendObject.userId10Digit = elements.userId10Digit;
            appendObject.userName = elements.userName;
            appendObject.userPincode = elements.userPincode;
            appendObject.userPhoneNumber = elements.userPhoneNumber;
          }

          return appendObject;
        });
        coins.map(elements => {
          if (
            parseInt(elements.currentPoints) >=
            parseInt(this.state.ghoshakCoins)
          ) {
            showCoins.push(elements);
          }
        });
        return this.setState({
          coins: coins,
          coinTable: showCoins,
          showData: true
        });
      })
      .catch(err => console.log(err));
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  render() {
    if (this.state.coinTable.length > 0) {
      const emptyRows =
        this.state.rowsPerPage -
        Math.min(
          this.state.rowsPerPage,
          this.state.coinTable.length - this.state.page * this.state.rowsPerPage
        );
    }
    return (
      <>
        {this.state.showData && (
          <>
            <Row>
              <Col md={{ span: 4, offset: 3 }} className="division">
                <h4 style={{ textAlign: "center" }}>ENTER GHOSHAK COINS</h4>
                <Form onSubmit={this.ghoshakCoinSearch}>
                  <Form.Control
                    type="text"
                    name="ghoshakCoins"
                    onChange={this.onChange}
                  ></Form.Control>
                  <Button
                    style={{
                      width: 100 + "%",
                      height: 30 + "%",
                      marginTop: 2 + "%",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    type="submit"
                  >
                    {" "}
                    SEARCH
                  </Button>
                </Form>
              </Col>
            </Row>

            <Row>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  margin: 1 + "%"
                }}
              >
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  {this.state.coinTable.length} {this.state.tableTitle}
                </h3>
              </div>
              <Table
                style={{
                  width: 100 + "%",
                  border: 0.5 + "px",
                  borderColor: "#ced4da",
                  borderStyle: "solid"
                }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S NO</TableCell>
                    <TableCell align="center">Customer ID</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Phone Number</TableCell>
                    <TableCell align="center">Pincode</TableCell>
                    <TableCell align="center">Current Points</TableCell>
                    <TableCell align="center">Added Points</TableCell>
                    <TableCell align="center">Redeemed Points</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.coinTable
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((row, sno) => (
                      <>
                        <TableRow>
                          <TableCell align="center">{sno + 1}</TableCell>
                          <TableCell align="center">
                            <Link
                              to={{
                                pathname: "/customer",
                                search: `?userid=${row.userId10Digit}`
                              }}
                            >
                              {row.userId10Digit}
                            </Link>
                          </TableCell>

                          <TableCell align="center">{row.userName}</TableCell>
                          <TableCell align="center">
                            {row.userPhoneNumber}
                          </TableCell>
                          <TableCell align="center">
                            {row.userPincode}
                          </TableCell>
                          <TableCell align="center">
                            {row.currentPoints}
                          </TableCell>

                          <TableCell align="center">{row.added}</TableCell>
                          <TableCell align="center">{row.redeemed}</TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={this.state.coinTable.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Row>
          </>
        )}
      </>
    );
  }
}
