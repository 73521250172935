import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import S3 from "react-aws-s3";
import uuid from "uuid/dist/v4";
import config from "../../config";
import Youtube from "react-youtube";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const OnlineProduct = (props) => {
  const ref = useRef();
  const [header, setHeader] = useState("");
  const [description, setDescription] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [websiteTemplate, setWebsiteTemplate] = useState();
  const [videos, setVideos] = useState([]);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [from, setFrom] = useState("");
  const [shopDetails, setShopDetails] = useState({
    userId: "",
  });
  const [active, setActive] = useState(false);
  // const [templateId, setTemplateId] = useState("videoGallery_001");
  const [templateName, setTemplateName] = useState("Video Landing");

  const fetchDetails = async () => {
    await fetchUserDetails(props.shopId).then((data) => {
      var shopData = data[0];
      if (shopData.websiteTemplate) {
        setShopDetails(shopData);
        var websiteData = shopData.websiteTemplate;
        console.log(websiteData.onlineProduct, "shop Type");
        setWebsiteTemplate(shopData.websiteTemplate);
        setActive(
          websiteData.onlineProduct.active == true
            ? websiteData.onlineProduct.active
            : active
        );
      }
    });
  };

  const update = () => {
    var dataObj = {};
    if (websiteTemplate) {
      dataObj = {
        ...websiteTemplate,
        onlineProduct: {
          active: active,
          templateId: "onlineProduct_001",
          pageProps: {},
        },
      };
    } else {
      dataObj = {
        colorTheme: "default",
        order: [
          "aboutUs",
          "about",
          "onlineProduct",
          "ourServices",
          "ourProjects",
          "ourBrands",
          "ourClients",
          "ourGallery",
          "clientTestimonials",
          "videoGallery",
          "socialMedia",
          "ourPartner",
          "ourTeam"
        ],
        onlineProduct: {
          active: active,
          templateId: "onlineProduct_001",
          pageProps: {},
        },
      };
    }

    contentupload(dataObj);
  };
  const contentupload = async (obj, userId) => {
    console.log("before update", obj, shopDetails.userId);
    await Promise.all([
      fetch(`${config.ApiGateway}/ownDomainContentUpload`, {
        method: "POST",
        body: JSON.stringify({
          obj: obj,
          userId: shopDetails.userId10Digit,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res]) => Promise.all([res.json()]))
      .then(async ([data]) => {
        console.log(data);

        alert("Changes Updated");
      })
      .catch((err) => {
        console.log("updating A Record Error", err);
        alert("error while uploading data. Please try again");
      });
  };

  useEffect(() => {
    fetchDetails();
  }, [props]);

  return (
    <div style={{ width: "100%" }}>
      <h4>Online Product</h4>
      <div>
        <>
          <div>
            <strong>
              <label>Show Online Product</label>
            </strong>{" "}
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={(e) => {
                    setActive(!active);
                  }}
                  color="primary"
                  value={active}
                />
              }
            ></FormControlLabel>
          </div>
        </>
      </div>
      <Button onClick={() => update()}>Save</Button>
    </div>
  );
};

export default OnlineProduct;

const fetchUserDetails = async (shopId) => {
  if (shopId === "") return alert("Enter valid Shop Id");
  return await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
      method: "POST",
      body: JSON.stringify({
        userId10Digit: shopId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data[0].websiteTemplate);
      return data;
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("Something went wrong, please try again");
    });
};

const contentupload = async (obj, userId) => {
  await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainContentUpload`, {
      method: "POST",
      body: JSON.stringify({
        obj: obj,
        userId: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data);
      alert("uploaded success");
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("error while uploading data. Please try again");
    });
};
