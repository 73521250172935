function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const uploadImageToS3 = async (originalFiles, bucketParams) => {
  let bucketName = bucketParams.bucketName;
  let fileName = bucketParams.fileName;
  let folder = bucketParams?.dirName ? bucketParams.dirName : "";
  console.log("originalFiles", originalFiles);
  const files = await Promise.all(
    Array?.from(originalFiles.target.files).map(async (file) => {
      const base64 = await getBase64(file);
      return {
        base64,
        type: file.type,
      };
    })
  );

  try {
    const res = await fetch(
      "https://pgtj1fkue6.execute-api.ap-south-1.amazonaws.com/prod/uploadToS3",
      // "https://2z4hriwfak.execute-api.ap-south-1.amazonaws.com/dev/createKot",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          files,
          bucketName,
          fileName,
          folder,
        }),
      }
    );
    const data = await res.json();
    if (data.length > 0) {
      var tempUri = "";
      tempUri = data[0];
    }
    return tempUri;
  } catch (err) {
    console.log(err);
  }
};

export const uploadJson = async (data, bucketParams) => {
  const base64Encoded = btoa(data);

  if (data) {
    let bucketName = bucketParams.bucketName;
    let fileName = bucketParams.fileName;
    let folder = bucketParams.dirName;
    const files = [
      {
        base64: base64Encoded,
        type: "json",
      },
    ];
    try {
      const res = await fetch(
        "https://pgtj1fkue6.execute-api.ap-south-1.amazonaws.com/prod/uploadToS3",
        // "https://2z4hriwfak.execute-api.ap-south-1.amazonaws.com/dev/createKot",
        {
          method: "POST",
          body: JSON.stringify({
            files,
            bucketName,
            fileName,
            folder,
            action: "update",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return res;
    } catch (err) {
      console.log(err);
    }
  }
};
