import React, { useEffect, useState } from "react";
import MasterSheetTable from "./masterSheetTable";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { DeliveryAPI } from "./handler/apiHandler";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";

import TableRow from "@material-ui/core/TableRow";
export default function Delivery({
  profile,
  classes,
  crmUser,
  dateRange,
  days,
}) {
  const [tabKey, setTabKey] = useState("orderHistory");
  const [state, setState] = useState({
    orders: [],
    customerLogs: [],
    status: {},
    ordergraph: [],
    productGraph: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      if (Boolean(profile.userId) && Boolean(dateRange)) {
        setIsLoading(true);
        await getDeliveryAPI();
      }
    }

    loadData();
  }, [profile]);
  const getDeliveryAPI = async () => {
    return await new Promise(async (resolve, reject) => {
      try {
        await DeliveryAPI({
          shopId: profile.userId,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          days: days,
          billingType: "order",
        }).then((data) => {
          if (data.status) {
            setIsLoading(false);
            setState((prevState) => ({ ...prevState, ...data.data }));
            return resolve();
          } else {
            reject();
          }
        });
      } catch (err) {
        alert("data fetching failed ");
        reject();
      }
    });
  };

  const CustomizedTick = (props) => {
    const { x, y, stroke, payload } = props;

    let displayXAxisvalue =
      days > 30
        ? moment(payload.value).format("YYYY/MMMM")
        : moment(payload.value).format("DD-MM-YYYY");

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {displayXAxisvalue}
        </text>
      </g>
    );
  };

  return (
    <>
      <div className="vertical-space-10"></div>

      <div className="shopCardRow">
        <div className="shopCardHeader">
          <p>Delivery</p>
        </div>
        {isLoading ? null : (
          <>
            <div className="shopCardColumn">
              <div className="shopCardBox">
                <ul className="horizontalList">
                  <li className="horizontalListItem">
                    <h6>Order Status Analytics</h6>
                    <ul className="horizontalList">
                      {Object.values(state.status).map((items) => (
                        <li id="noBorder" className="horizontalListItem">
                          <h6> ₹ {items.totalAmount}</h6>
                          <p className="statusTracker">{items.name}</p>
                          <span>
                            Total Orders <b>{items.status}</b>
                          </span>
                        </li>
                      ))}
                    </ul>
                  </li>
                  {state.customerLogs.length > 0 && (
                    <>
                      <li className="horizontalListItem">
                        <h6>Most Visited Customer</h6>
                        <span>
                          {Array.isArray(state.customerLogs) &&
                            state.customerLogs.length > 0 &&
                            `Mr/Ms ${state.customerLogs[0].customerName} has visted ${state.customerLogs[0].visit} times and total purchase cost ₹ ${state.customerLogs[0].totalAmount}`}
                        </span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>

            <div className="vertical-space-10"></div>

            <div className="shopCardBox">
              <div className="ShopCardTabs">
                <Tabs
                  defaultActiveKey="details"
                  transition={false}
                  id="noanim-tab-example"
                  className="mb-3"
                  activeKey={tabKey}
                  onSelect={(k) => setTabKey(k)}
                >
                  <Tab eventKey="orderHistory" title="Order History">
                    <div className="shopCardColumn">
                      <div className="wrapTable">
                        <MasterSheetTable
                          isSearchable={false}
                          title={"Track Orders"}
                          validKeys={[
                            "orderId",
                            "customerName",
                            "customerPhoneNumber",
                            "billingDate",
                            "deliveryMethod",
                            "totalAmount",
                            "coupons",
                            "orderStatus",
                          ]}
                          expandableType={"orders"}
                          isExpandable={true}
                          data={state.orders}
                        />
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="customerLogs" title="Customer Logs">
                    <div className="shopCardColumn">
                      <div className="wrapTable">
                        <MasterSheetTable
                          isSearchable={false}
                          title={"Customer Logs"}
                          validKeys={["customerName", "customerPhoneNumber"]}
                          expandableType={"customerLogs"}
                          isExpandable={true}
                          data={state.customerLogs}
                        />
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="dashboard" title="Dashboard">
                    <div className="shopCardRow">
                      <div id="col50" className="shopCardColumn">
                        <div
                          style={{ boxShadow: "none" }}
                          className="wrapTable"
                        >
                          <p className="tableTitle"> Successful Orders </p>
                          <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                              width={500}
                              height={300}
                              data={state.ordergraph}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                interval={0}
                                angle={-45}
                                textAnchor="end"
                                height={100}
                                dataKey="date"
                                tick={<CustomizedTick />}
                              ></XAxis>
                              <YAxis
                                label={{
                                  value: "No Of Bills Generated",
                                  angle: -90,
                                  position: "insideBottomLeft",
                                  fontSize: 12,
                                }}
                              />
                              <Tooltip />
                              <Legend />
                              <Line
                                type="monotone"
                                dataKey="count"
                                stroke="#8884d8"
                                activeDot={{ r: 8 }}
                                label={<CustomizedLabel />}
                              >
                                <LabelList dataKey="amount" position="bottom" />
                              </Line>
                            </LineChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                      <div id="col50" className="shopCardColumn">
                        <div
                          style={{ boxShadow: "none", overflow: "hidden" }}
                          className="wrapTable"
                        >
                          <p className="tableTitle"> Most Purchased Product </p>
                          <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    className={classes.tableHeader}
                                    key={"1"}
                                    align={"1"}
                                  >
                                    Product Name
                                  </TableCell>
                                  <TableCell className={classes.tableHeader}>
                                    Subcategory
                                  </TableCell>
                                  <TableCell className={classes.tableHeader}>
                                    Cart Count
                                  </TableCell>
                                  <TableCell className={classes.tableHeader}>
                                    Product Price ₹
                                  </TableCell>
                                  <TableCell className={classes.tableHeader}>
                                    Total Cart Amount ₹
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {state.productGraph.map((row) => {
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.date}
                                    >
                                      <TableCell
                                        className={classes.tableCell}
                                        key={row.productId}
                                      >
                                        {row.productName}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {row.subCategory}
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableCell}
                                      >{`₹ ${row.productPrice}`}</TableCell>
                                      <TableCell
                                        className={classes.tableCell}
                                      >{`₹ ${row.inventoryCost}`}</TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

const CustomizedLabel = (props) => {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  );
};
