import React, { useEffect, useState } from "react";
import Pincode from "./pincode";
import Registration from "./registration";
import Offer from "./offer";
import Loyalty from "./loyalty";
import MenuIcon from "@material-ui/icons/Menu";
import ShopDetails from "./shopDetails";
import Segment from "./segment";
import OfferCreation from "./offerCreation";
import AddedCustomer from "./shopAddedCustomer";
import CouponDistribution from "./couponDistribution";
import "../crm.css";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  table: {
    boxShadow: "0 0 3px #e2e5e8",
    backgroundColor: "#ffffff",
    margin: "0 auto",
    border: "0 solid transparent",
    paddingBottom: 20,
  },
  container: {
    maxHeight: 200,
    overflowY: "scroll",
    overflowX: "auto",
    overflow: "hidden",
  },
  tableHead: {
    fontWeight: 500,
    backgroundColor: "#ffffff",
    color: "#333",
    letterSpacing: "1.5px",
    textTransform: "capitalize",
  },
  tableHeadBody: {
    backgroundColor: "#ffffff",
    borderBottomWidth: "1px",
  },
  root: {
    width: "100%",
  },
  tableCell: {
    fontWeight: 300,
    lineHeight: "20px",
    fontSize: "14px",
    color: "#212529",
  },
  tableIcon: {
    fontSize: "13px",

    fontWeight: 300,
    lineHeight: "20px",
  },
  tableBodyRow: {
    borderBottom: "1px solid #e9ecef",
  },
});
export default function MerchantCrm() {
  const classes = useStyles();
  const [typename, setTypename] = useState("pincode");
  const [isCollapsible, setIsCollapsible] = useState(true); // Sidebar - open/close
  return (
    <>
      <div className="flexContainer">
        <div className={isCollapsible ? "sidebar" : "sidebar closed"}>
          {/* <div
            onClick={() => setIsCollapsible((prevState) => !prevState)}
            className="collabsibleArrow"
          >
            {isCollapsible ? (
              <ArrowForwardIosIcon id="collapsible" />
            ) : (
              <ArrowBackIosIcon id="collapsible" />
            )}
          </div> */}
          <div
            className={
              isCollapsible ? "interactionBox" : "interactionBox closed"
            }
          >
            <ul className={isCollapsible ? null : "closed"}>
              <>
                <li
                  className={typename == "pincode" && "active"}
                  onClick={() => setTypename("pincode")}
                >
                  <a className={typename == "pincode" && "active"}>Pincode</a>
                </li>
                <li
                  className={typename == "registration" && "active"}
                  onClick={() => setTypename("registration")}
                >
                  <a className={typename == "registration" && "active"}>
                    Registration
                  </a>
                </li>
                <li
                  className={typename == "offer" && "active"}
                  onClick={() => setTypename("offer")}
                >
                  <a className={typename == "offer" && "active"}>Offer</a>
                </li>
                <li
                  className={typename == "collection" && "active"}
                  onClick={() => setTypename("collection")}
                >
                  <a className={typename == "collection" && "active"}>
                    Collection
                  </a>
                </li>
                <li
                  className={typename == "loyalty" && "active"}
                  onClick={() => setTypename("loyalty")}
                >
                  <a className={typename == "loyalty" && "active"}>Loyalty</a>
                </li>
                <li
                  className={typename == "shopDetails" && "active"}
                  onClick={() => setTypename("shopDetails")}
                >
                  <a className={typename == "shopDetails" && "active"}>
                    Shop Details
                  </a>
                </li>
                <li
                  className={typename == "segment" && "active"}
                  onClick={() => setTypename("segment")}
                >
                  <a className={typename == "segment" && "active"}>Segment</a>
                </li>
                <li
                  className={typename == "offerCreation" && "active"}
                  onClick={() => setTypename("offerCreation")}
                >
                  <a className={typename == "offerCreation" && "active"}>
                    Offer Creation
                  </a>
                </li>
                <li
                  className={typename == "addedCustomer" && "active"}
                  onClick={() => setTypename("addedCustomer")}
                >
                  <a className={typename == "addedCustomer" && "active"}>
                    Added Customer
                  </a>
                </li>
                <li
                  className={typename == "ghoshakCoupon" && "active"}
                  onClick={() => setTypename("ghoshakCoupon")}
                >
                  <a className={typename == "ghoshakCoupon" && "active"}>
                    Ghoshak Coupons
                  </a>
                </li>
                <li
                  className={typename == "merchantCoupon" && "active"}
                  onClick={() => setTypename("merchantCoupon")}
                >
                  <a className={typename == "merchantCoupon" && "active"}>
                    Merchant Coupons
                  </a>
                </li>
                <li
                  className={typename == "loyaltyCoupon" && "active"}
                  onClick={() => setTypename("loyaltyCoupon")}
                >
                  <a className={typename == "loyaltyCoupon" && "active"}>
                    Loyalty Coupons
                  </a>
                </li>
                <li
                  className={typename == "promocodeCoupon" && "active"}
                  onClick={() => setTypename("promocodeCoupon")}
                >
                  <a className={typename == "promocodeCoupon" && "active"}>
                    Promocode Coupons
                  </a>
                </li>
              </>
            </ul>
          </div>
        </div>

        <div className="content">
          <div
            onClick={() => setIsCollapsible((prevState) => !prevState)}
            className="menuIcon"
          >
            <MenuIcon />
          </div>
          <div className="contentBody">
            <LoadContentType typename={typename} classes={classes} />
          </div>
        </div>
      </div>
    </>
  );
}

function LoadContentType({ typename, classes }) {
  switch (typename) {
    case "pincode":
      return <Pincode />;
    case "registration":
      return <Registration />;
    case "offer":
      return <Offer offerType={"offer"} header={"Offer"} />;
    case "collection":
      return <Offer offerType={"item"} header={"Collection"} />;
    case "loyalty":
      return <Loyalty />;
    case "shopDetails":
      return <ShopDetails classes={classes} />;
    case "segment":
      return <Segment />;
    case "offerCreation":
      return <OfferCreation />;
    case "addedCustomer":
      return <AddedCustomer />;
    case "ghoshakCoupon":
      return <CouponDistribution couponType={"GHOSHAK"} />;
    case "merchantCoupon":
      return <CouponDistribution couponType={"MERCHANT"} />;
    case "loyaltyCoupon":
      return <CouponDistribution couponType={"LOYALTY"} />;
    case "promocodeCoupon":
      return <CouponDistribution couponType={"PROMOCODE"} />;
  }
}
