export default {
  json: ["Live", "Rejected", "Draft", "All", "NONDELETED", "Deleted"],
  content: {
    offerType: ["offer", "item", "none"],
  },
  coupon: {
    couponCategory: ["Created", "Deleted"],
  },
  filter: {
    activity: [
      {
        "Last 30 Days": "30",
        Year: "365",
        Yesterday: "1",
        Week: "7",
        "6 Months": "183",
        Today: "0",
      },
    ],
  },
  dashboard: {
    activity: [
      { name: "Today", value: "0" },
      { name: "Yesterday", value: "1" },
      { name: "Week", value: "7" },
      { name: "1 months", value: "30" },
      { name: "3 months", value: "181" },
      { name: "6 Months", value: "183" },
      { name: "1 Year", value: "365" },
      { name: "Custom Date Range", value: "datepicker" },
    ],
    Dtypes: [
      { name: "Bill", value: "1" },
      { name: "Order", value: "2" },
    ],

    PinCodes: [
      641035, 600001, 600002, 600003, 600004, 600005, 600006, 600007, 600008,
      600009, 600010, 600011, 600012, 600013, 600014, 600015, 600016, 600017,
      600018, 600019, 600020, 600021, 600022, 600023, 600024, 600025, 600026,
      600027, 600028, 600029, 600030, 600031, 600032, 600033, 600034, 600035,
      600036, 600037, 600038, 600039, 600040, 600041, 600042, 600043, 600044,
      600045, 600046, 600047, 600048, 600049, 600050, 600051, 600052, 600053,
      600054, 600055, 600056, 600057, 600058, 600059, 600060, 600061, 600062,
      600063, 600064, 600065, 600066, 600067, 600068, 600069, 600070, 600071,
      600072, 600073, 600074, 600075, 600076, 600077, 600078, 600079, 600080,
      600081, 600082, 600083, 600084, 600085, 600086, 600087, 600088, 600089,
      600090, 600091, 600092, 600093, 600094, 600095, 600096, 600097, 600098,
      600099, 600100, 600101, 600102, 600103, 600104, 600105, 600106, 600107,
      600108, 600109, 600110, 600111, 600112, 600113, 600114, 600115, 600116,
      600117, 600118, 600119, 600120, 600122, 600123, 600124, 600125, 600126,
      600127, 600128, 600129, 600130, 600201, 601202, 601203, 601204, 601205,
      601206, 601207, 601208, 601209, 601210, 601211, 601212, 601213, 601214,
      601215, 601216, 601217, 601218, 601219, 601220, 601221, 601223, 601224,
      601225, 601226, 601227, 601228, 601229, 601230, 601301, 601302, 602001,
      602002, 602003, 602021, 602023, 602024, 602025, 602026, 602028, 602101,
      602103, 602107, 603103, 603104, 602105, 602106, 602107, 602108, 602109,
      602111, 602112, 602201, 602202, 602203, 602204, 602210,
    ],
  },
};




 

