import React, { Component } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { Row, Col, Button, Container, Form } from "react-bootstrap";
import config from "../../config";
export default class PincodeMerchant extends Component {
  constructor(props) {
    super(props);
    this.pincodeBasedShop = [];
    this.statistics = [];
    this.state = {
      showData: false,
      store: [],
      counter: "",
      showStats: false,
      pincode: "",
      tableData: [],
      tableArrayValue: "",
      tableDisplay: false,
      top10Pincode: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.calculatePincode = this.calculatePincode.bind(this);
  }
  componentDidMount() {
    var pincodeDetails = [];

    var totalPincode = [];
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",

        body: JSON.stringify({
          query: {
            bool: {
              must: [{ match: { userType: "Shop" } }],
              must_not: {
                bool: {
                  must: [{ term: { userPincode: "111" } }]
                }
              }
            }
          }
        }),
        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        totalPincode = data.hits.hits.map(element => element._source);

        pincodeDetails = totalPincode.map(items => items.userPincode);
      });
    var count = 0;

    this.props.allPincode.map(item => {
      count = 0;
      this.props.pincode.map(element => {
        if (element.userPincode === item) {
          count++;
        }
      });
      this.statistics.push({
        name: item,
        totalMerchant: count
      });
    });

    this.setState(
      {
        showData: true,
        stats: this.statistics
      },
      () => {
        this.calculatePincode();
      }
    );
  }
  onChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({
      [name]: value
    });
  }
  handleChange(e) {
    var value = e.target.value;
    var count = 0;
    var renderTable = 0;

    switch (value) {
      case "0":
        this.state.stats.map(items => {
          if (items.totalMerchant === 0) {
            count++;
          }
        });
        renderTable = 0;
        break;
      case "1-10":
        this.state.stats.map(items => {
          if (items.totalMerchant >= 1 && items.totalMerchant <= 10) {
            count++;
          }
        });
        renderTable = 1;
        break;
      case "11-50":
        this.state.stats.map(items => {
          if (items.totalMerchant >= 11 && items.totalMerchant <= 50) {
            count++;
          }
          renderTable = 2;
        });

        break;
      case "51-99":
        this.state.stats.map(items => {
          if (items.totalMerchant >= 50 && items.totalMerchant <= 99) {
            count++;
          }
        });
        renderTable = 3;
        break;
      case "100-500":
        this.state.stats.map(items => {
          if (items.totalMerchant >= 100) {
            count++;
          }
        });
        renderTable = 4;
        break;

      default:
        return <></>;
    }
    // console.log(count);
    this.setState({
      counter: count,
      showStats: true,
      tableArrayValue: renderTable,
      tableDisplay: true
    });
  }
  calculatePincode() {
    var zeroPincode = [];
    var oneTenth = [];
    var elevenFifty = [];
    var fiftyoneNinetynine = [];
    var greaterHundred = [];
    var stats = [];

    this.state.stats.map(items => {
      if (items.totalMerchant === 0) {
        zeroPincode.push({
          pincode: items.name,
          total: items.totalMerchant
        });
      }

      if (items.totalMerchant >= 100) {
        greaterHundred.push({
          pincode: items.name,
          total: items.totalMerchant
        });
      }

      if (items.totalMerchant >= 51 && items.totalMerchant <= 99) {
        fiftyoneNinetynine.push({
          pincode: items.name,
          total: items.totalMerchant
        });
      }

      if (items.totalMerchant >= 11 && items.totalMerchant <= 50) {
        elevenFifty.push({
          pincode: items.name,
          total: items.totalMerchant
        });
      }

      if (items.totalMerchant >= 1 && items.totalMerchant <= 10) {
        oneTenth.push({
          pincode: items.name,
          total: items.totalMerchant
        });
      }
    });
    stats.push(
      zeroPincode,
      oneTenth,
      elevenFifty,
      fiftyoneNinetynine,
      greaterHundred
    );

    var order = this.state.stats
      .sort((a, b) => b.totalMerchant - a.totalMerchant)
      .slice(0, 10);
    console.log(order);
    this.setState({
      tableData: stats,
      top10Pincode: order
    });
  }

  render() {
    return (
      <>
        <Typography variant="h2" style={{ textAlign: "center" }} gutterBottom>
          {this.props.title}
        </Typography>
        {this.state.showData && (
          <>
            <Col md={{ span: 6, offset: 3 }} className="division">
              <h4 style={{ textAlign: "center" }}>TOP 10 PINCODE </h4>
              <BarChart
                width={600}
                height={300}
                data={this.state.top10Pincode}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  interval={0}
                  angle={-45}
                  textAnchor="end"
                  height={100}
                />
                <YAxis
                  label={{
                    value: "TOTAL MERCHANT",
                    angle: -90,
                    position: "insideLeft"
                  }}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="totalMerchant" fill="#8884d8" />
              </BarChart>
            </Col>
            <Col md={{ span: 6, offset: 3 }} className="division">
              PINCODES WITH SHOPS
              <Form>
                <Form.Control as="select" onChange={this.handleChange}>
                  <option value="">SELECT PINCODE </option>

                  <option value="100-500">
                    pincodes more than 250 merchant
                  </option>
                  <option value="51-99">pincodes with 51-99 merchant</option>
                  <option value="11-50">pincodes with 11-50 merchant</option>
                  <option value="1-10">pincodes less than 10 merchant</option>
                  <option value="0">pincodes with zero merchants</option>
                </Form.Control>
              </Form>
            </Col>
            {this.state.showStats && (
              <Row>
                <Col md={{ span: 6, offset: 3 }} className="division">
                  <h3 style={{ textAlign: "center" }}>
                    TOTAL IS {this.state.counter}
                  </h3>
                </Col>
              </Row>
            )}
          </>
        )}
        {this.state.tableDisplay && (
          <Table
            className="division"
            style={{
              width: 100 + "%",
              border: 0.5 + "px",
              borderColor: "#ced4da",
              borderStyle: "solid"
            }}
            size="medium"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">S NO</TableCell>
                <TableCell align="center">PINCODE</TableCell>
                <TableCell align="center">TOTAL SHOPS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.tableData[this.state.tableArrayValue].map(
                (elements, index) => (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{elements.pincode}</TableCell>
                    <TableCell align="center">{elements.total}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        )}
      </>
    );
  }
}
