import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import config from "./config";
import { Auth } from "aws-amplify";

const firebaseConfig = {
  apiKey: "AIzaSyBFFLzyY5lPKo35-jd0xl4TUXi5z8ZUM9o",
  authDomain: "poslowstockalert.firebaseapp.com",
  projectId: "poslowstockalert",
  storageBucket: "poslowstockalert.appspot.com",
  messagingSenderId: "663903057220",
  appId: "1:663903057220:web:57eb0a297eb7505be1d311",
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

export const RequestForToken = async () => {
  const addToken = async (shopId, updateARN) => {
    try {
      var res = await fetch(`${config.POSApi}/addTaxMaster`, {
        method: "POST",
        body: JSON.stringify({
          type: "updateARN",
          updateARN: updateARN,
          shopId: shopId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      res = await res.json();
    } catch (err) {
      console.log("Error while Adding Token. Please try again.");
    }
  };
  //   const getShopDetailsApi = async (id, currentToken) => {
  //     var shopDetailsRes = await fetch(`${config.POSApi}/getShopDetails`, {
  //       method: "POST",
  //       body: JSON.stringify({
  //         shopId: id,
  //       }),
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     shopDetailsRes = await shopDetailsRes.json();
  //     let token = shopDetailsRes.Items
  //       ? shopDetailsRes.Items[0]
  //         ? shopDetailsRes.Items[0].userEndpointARN
  //           ? shopDetailsRes.Items[0].userEndpointARN
  //           : ""
  //         : ""
  //       : "";
  //     if (typeof token == "string") {
  //       var newTokenStructure = {
  //         mobileTokens: [token],
  //         webTokens: [currentToken],
  //       };
  //       addTax(newTokenStructure);
  //     } else if (typeof token === "object" && !Array.isArray(token)) {
  //       if (!token.webTokens.includes(currentToken)) {
  //         token.webTokens.push(currentToken);
  //         addTax(token);
  //       }
  //     }
  //   };

  const fetchUserDetails = async (shopId, currentToken) => {
    return await Promise.all([
      fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
        method: "POST",
        body: JSON.stringify({
          userId10Digit: shopId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res]) => Promise.all([res.json()]))
      .then(async ([data]) => {
        let token = data[0].userEndpointARN ? data[0].userEndpointARN : "";
        if (token) {
          if (typeof token == "string") {
            var newTokenStructure = {
              mobileTokens: [token],
              webTokens: [],
              crmTokens: [currentToken],
            };
            addToken(data[0].userId, newTokenStructure);
          } else {
            if (typeof token == "object" && !Array.isArray(token)) {
              if (token.hasOwnProperty("crmTokens")) {
                if (Array.isArray(token.crmTokens)) {
                  if (!token.crmTokens.includes(currentToken)) {
                    let existing = [...token.crmTokens];
                    let updated = {
                      ...token,
                      crmTokens: [...existing, currentToken],
                    };
                    addToken(data[0].userId, updated);
                  }
                } else {
                  let up = { ...token, crmTokens: [currentToken] };
                  addToken(data[0].userId, up);
                }
              } else {
                let updatedToken = {
                  ...token,
                  crmTokens: [currentToken],
                };
                addToken(data[0].userId, updatedToken);
              }
            }
          }
        } else {
          var newTokenStructure = {
            mobileTokens: [],
            webTokens: [],
            crmTokens: [currentToken],
          };
          addToken(shopId, newTokenStructure);
        }
      })
      .catch((err) => {
        alert("Something went wrong, please try again");
      });
  };

  async function getUser(currentToken) {
    let num = "";
    await Auth.currentAuthenticatedUser().then((user) => {
      let { phone_number } = user.attributes;
      num = phone_number;
    });
    //await fetchUserDetails(num, currentToken);
  }

  return getToken(messaging, {
    vapidKey:
      "BCyv8hsexIa90rvvPXmJFfxs4WOFsHg_l7aUHov1L4iNov1kMPhYPOa9oWUdvPPk4grn1cIVQIsPcRlBpe0DlH4",
  })
    .then((currentToken) => {
      if (currentToken) {
        getUser(currentToken);
      } else {
        console.error(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.error("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
