// Import Dependency

import React, { useCallback, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import { GetJSONFields } from "./apiHandler";
import Constant from "../../deleivery/constant";
import config from "../../config";

export default function Validation() {
  const validationHandler = useCallback((handlerName, argument1) => {
    // pick function based on handler value

    switch (handlerName) {
      case "membership":
        return validateMembershipPackage();
      case "delivery":
        return validateDeliveryPackage(argument1);
      case "appointment":
        return validateAppointmentPackage(argument1);
      case "website":
        return validateWebsitePackage(argument1);
      case "billing":
        return validateWebsitePackage(argument1);
      case "loyalty":
        return validateLoyaltyPackage(argument1);
      case "billing":
        return validateBillingPackage(argument1);
      case "loyaltyPoints":
        return validateLoyaltyPoints(argument1);
      case "addProductInventory":
        return validateInventoryItems(argument1);
      case "removeFields":
        return removeEmptyFields(argument1);
      default:
        return <></>;
    }
  }, []);

  const validateInventoryItems = async (items) => {
    return await new Promise(async (resolve, reject) => {
      let { Constant, profile, product, fields, otherShopType } = items;
      let invalidData = [];
      let validData = [];
      product.forEach((elements) => {
        // Loops all records
        if (Constant.KIRANA.key.includes(profile.shopType)) {
          let unitValue =
            typeof elements.measurementUnit == "undefined"
              ? "g"
              : elements.measurementUnit;

          elements.unitOfMeasurment =
            elements.measurementValue + " " + unitValue;

          elements.vegNonVeg =
            typeof elements.vegNonVeg == "undefined"
              ? "Veg"
              : elements.vegNonVeg;

          delete elements.measurementValue;
          delete elements.measurementUnit;
        }

        if (otherShopType) {
          elements.group = "Others";
          elements.subCategory = "Others";
          elements.category = "Others";
        }

        elements.shopId = profile.userId;

        let evaluatedList = fields.map((key) => Boolean(elements[key])); // if key value is undefined, null or emplty returns false
        if (evaluatedList.includes(false)) {
          // evaluated data contains false it is invalid
          invalidData.push(elements);
        } else {
          // valid data
          validData.push(elements);
        }
      });

      // let validatedResults = await validateItemsByKey(fields, removedFields);

      let returnData = {
        status: invalidData.length > 0 ? false : true,
        uploadObject: invalidData.length > 0 ? [] : product,
      };

      resolve(returnData);
    });
  };

  const validateLoyaltyPoints = async (items) => {
    return await new Promise(async (resolve, reject) => {
      let fields = [
        "loyaltyPoints",
        "loyaltyAmount",
        "loyaltyRatio",
        "loyaltyupdatedBy",
        "userId",
      ];
      let removedFields = await removeEmptyFields(items);

      let validatedResults = await validateItemsByKey(fields, removedFields);

      let returnData = {
        status: validatedResults[1].length > 0 ? false : true,
        uploadObject: validatedResults[1].length > 0 ? {} : removedFields,
      };

      resolve(returnData);
    });
  };

  const validateMembershipPackage = async (items) => {
    return await new Promise(async (resolve, reject) => {
      let fields = [""];

      let validatedResults = await validateItemsByKey(fields, items);

      let returnData = {
        status: validatedResults[1].length > 0 ? false : true,
        uploadObject:
          validatedResults[1].length > 0 ? {} : await removeEmptyFields(items),
      };

      resolve(returnData);
    });
  };
  const validateDeliveryPackage = async (items) => {
    return await new Promise(async (resolve, reject) => {
      let fields = [
        "deliveryPackage",
        "deliveryExpiryDate",
        "deliveryTransactionId",
        "notes",
        "userId",
      ];
      let validatedResults = await validateItemsByKey(fields, items);

      let returnData = {
        status: validatedResults[1].length > 0 ? false : true,
        uploadObject:
          validatedResults[1].length > 0 ? {} : await removeEmptyFields(items),
      };

      resolve(returnData);
    });
  };
  const validateAppointmentPackage = async (items) => {
    return await new Promise(async (resolve, reject) => {
      let fields = [
        "appointmentPackage",
        "appointmentExpiryDate",
        "appointmentTransactionId",
        "notes",
        "userId",
      ];

      let validatedResults = await validateItemsByKey(fields, items);

      let returnData = {
        status: validatedResults[1].length > 0 ? false : true,
        uploadObject:
          validatedResults[1].length > 0 ? {} : await removeEmptyFields(items),
      };

      resolve(returnData);
    });
  };
  const validateWebsitePackage = async (items) => {
    return await new Promise(async (resolve, reject) => {
      let fields = [
        "websiteStatus",
        "websiteExpiryOn",
        "websitePaymentId",
        "notes",
        "userId",
      ];
      let validatedResults = await validateItemsByKey(fields, items);

      let returnData = {
        status: validatedResults[1].length > 0 ? false : true,
        uploadObject:
          validatedResults[1].length > 0 ? {} : await removeEmptyFields(items),
      };

      resolve(returnData);
    });
  };
  const validateLoyaltyPackage = async (items) => {
    return await new Promise(async (resolve, reject) => {
      let fields = [
        "loyaltyPackage",
        "loyaltyExpiryDate",
        "loyaltyTransactionid",
        "notes",
        "userId",
      ];
      let validatedResults = await validateItemsByKey(fields, items);

      let returnData = {
        status: validatedResults[1].length > 0 ? false : true,
        uploadObject:
          validatedResults[1].length > 0 ? {} : await removeEmptyFields(items),
      };

      resolve(returnData);
    });
  };

  const validateBillingPackage = async (items) => {
    return await new Promise(async (resolve, reject) => {
      let fields = [
        "billingPackage",
        "billingExpiryDate",
        "billingTransactionid",
        "notes",
        "userId",
      ];
      let validatedResults = await validateItemsByKey(fields, items);

      let returnData = {
        status: validatedResults[1].length > 0 ? false : true,
        uploadObject:
          validatedResults[1].length > 0 ? {} : await removeEmptyFields(items),
      };

      resolve(returnData);
    });
  };

  const validateItemsByKey = async (fields, items) => {
    return await new Promise((resolve) => {
      let validData = [];
      let invalidData = [];

      fields.forEach((key) =>
        Boolean(items[key]) ? validData.push(key) : invalidData.push(key)
      ); // if key value is undefined, null or emplty returns false

      resolve([validData, invalidData]); // syntax 0 - valid, 1 - invalid
    });
  };

  const removeEmptyFields = async (input) => {
    return await new Promise((resolve) => {
      console.log(input, "removed fields");
      Object.keys(input).forEach(
        (key) => !Boolean(input[key]) && delete input[key]
      );
      return resolve(input);
    });
  };

  return [validationHandler];
}
