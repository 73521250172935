import React, { useState } from "react";
import config from "../config";
import { Container, Col, Button, Row, Form } from "react-bootstrap";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
export default function GStoreActivation() {
  const [userId10Digit, setUserId10Digit] = useState("");
  const [shopDetails, setShopDetails] = useState({
    deliverySlot: {},
    typeOfService: [],
    typeOfPayment: [],
  });
  const [testResults, setTestResults] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const getShop = async (e) => {
    // Get Shop Details using ghoshak id
    e.preventDefault();

    await Promise.all([
      fetch(`${config.ApiGateway}/ghoshakStoreActivation`, {
        method: "POST",
        body: JSON.stringify({
          userId10Digit: userId10Digit,
          typename: "verifyShop",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        if (data.status == "success") {
          console.log(data.shopDetails);
          setShopDetails(data.shopDetails);
          setTestResults(data.data);
          setisLoading(true);
        } else if (data.status == "failed") {
          setisLoading(false);
          alert("Invalid Shop Id");
        }
      })
      .catch((err) => console.log("Error getting shop", err));
  };

  const updateStatus = async (e) => {
    // Update website status 
    e.preventDefault();

    await Promise.all([
      fetch(`${config.ApiGateway}/ghoshakStoreActivation`, {
        method: "POST",
        body: JSON.stringify({
          userId: shopDetails.userId,
          typename: "activate",
          onlineStoreLive: "true",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert("Shop Is Live");
        setShopDetails({
          deliverySlot: {},
          typeOfService: [],
          typeOfPayment: [],
        });
        setTestResults({});
        setisLoading(false);
        setUserId10Digit("");
      })
      .catch((err) => console.log("Error getting shop", err));
  };

  return (
    <>
      <Container>
        <div style={{ width: "100%", height: "40px" }}></div>

        <div className="orderHeader">
          <h2 className="orderHeaderText">GStore Activation</h2>
        </div>
        <div style={{ width: "100%", height: "5px" }}></div>
        <Form onSubmit={getShop}>
          <Form.Row>
            <Col sm="4">
              <Form.Label>
                <b>Shop Id</b>
              </Form.Label>
            </Col>
            <Col sm="6">
              <Form.Control
                value={userId10Digit}
                onChange={(e) => setUserId10Digit(e.target.value)}
              ></Form.Control>
            </Col>
            <Col sm="2">
              <Button type="submit">get Shop</Button>
            </Col>
          </Form.Row>
        </Form>
        {isLoading && (
          <>
            <div style={{ width: "100%", height: "20px" }}></div>
            <div style={{ marginBottom: "20px" }} className="flexCard">
              <h2>Shop Profile</h2>
              <Row>
                <Col sm={6}>
                  <div className="imageContainer">
                    <img
                      src={
                        typeof shopDetails.shopImageLocation == "string"
                          ? shopDetails.shopImageLocation
                          : "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/images/offers/default_offer_image.png"
                      }
                      className="imageBox"
                    ></img>
                  </div>
                </Col>
                <Col style={{ paddingTop: "27px" }} sm={6}>
                  <h1 className="shopProfile">{shopDetails.shopName}</h1>
                  <span className="shopProfile">{shopDetails.shopAddress}</span>
                  <div className="vertical-space-5"></div>
                  <div className="shopLabeltext">
                    <label style={{ color: "#89959b", width: "30%" }}>
                      Merchant Name :{" "}
                    </label>
                    <p style={{ width: "40%" }}>{shopDetails.userName}</p>
                  </div>
                  <div className="shopLabeltext">
                    <label style={{ color: "#89959b", width: "30%" }}>
                      Phone Number :{" "}
                    </label>
                    <p style={{ width: "40%" }}>
                      {shopDetails.userPhoneNumber}
                    </p>
                  </div>
                  <div className="shopLabeltext">
                    <label style={{ color: "#89959b", width: "30%" }}>
                      Shop Pincode :
                    </label>
                    <p style={{ width: "40%" }}>{shopDetails.userPincode}</p>
                  </div>
                  <div className="shopLabeltext">
                    <label style={{ color: "#89959b", width: "30%" }}>
                      Shop Type :
                    </label>
                    <p style={{ width: "40%" }}>{shopDetails.shopType}</p>
                  </div>
                  <div className="shopLabeltext">
                    <label style={{ color: "#89959b", width: "30%" }}>
                      Shop Id :
                    </label>
                    <p style={{ width: "40%" }}>{shopDetails.userId10Digit}</p>
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingLeft: "10px" }}>
                <Col>
                  <label style={{ color: "#89959b", width: "80%" }}>
                    Min bill Amt :
                  </label>
                  <p style={{ width: "40%" }}>
                    {"₹ "}
                    {shopDetails.minBillAmount}
                  </p>

                  <div style={{ width: "100%", height: "3px" }}></div>
                  <label style={{ color: "#89959b", width: "80%" }}>
                    Delivery Charge:
                  </label>
                  <p style={{ width: "40%" }}>
                    {"₹ "}
                    {shopDetails.chargeForDelivery}
                  </p>
                </Col>
                <Col>
                  <label style={{ color: "#89959b", width: "80%" }}>
                    Delivery Slots:
                  </label>
                  <div style={{ overflowX: "auto", height: "150px" }}>
                    {typeof shopDetails.deliverySlot != "undefined" &&
                      Object.entries(shopDetails.deliverySlot).map(
                        ([key, value]) => (
                          <p style={{ width: "40%" }}>
                            <span>{value.startTime}</span> -
                            <span>{value.endTime}</span>
                          </p>
                        )
                      )}
                  </div>
                </Col>
                <Col>
                  <label style={{ color: "#89959b", width: "80%" }}>
                    Accepted Delivery Type :
                  </label>
                  <div style={{ overflowX: "auto", height: "150px" }}>
                    {typeof shopDetails.typeOfService != "undefined" &&
                      shopDetails.typeOfService.map((key) => (
                        <p style={{ width: "40%" }}>
                          <span>{key}</span>
                        </p>
                      ))}
                  </div>
                </Col>
                <Col>
                  <label style={{ color: "#89959b", width: "80%" }}>
                    Payment Type :
                  </label>
                  <div style={{ overflowX: "auto", height: "150px" }}>
                    {typeof shopDetails.typeOfPayment != "undefined" &&
                      shopDetails.typeOfPayment.map((key) => (
                        <p style={{ width: "40%" }}>
                          <span>{key}</span>
                        </p>
                      ))}
                  </div>
                </Col>
              </Row>
            </div>

            <div style={{ marginBottom: "20px" }} className="flexCard">
              <h2>Test Condition</h2>
              <Row>
                <Col>
                  <Timeline align="right">
                    {Object.entries(testResults).map(
                      ([key, value]) =>
                        key != "Result" && (
                          <TimelineItem>
                            <TimelineSeparator>
                              <TimelineDot
                                color={value == "yes" ? "primary" : "secondary"}
                              />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>{key}</TimelineContent>
                          </TimelineItem>
                        )
                    )}
                  </Timeline>
                </Col>
              </Row>
              <div style={{ padding: "30px" }}>
                <Row style={{ backgroundColor: "#dedede" }}>
                  <Col>
                    <h2>Test Status :</h2>
                  </Col>
                  <Col>
                    <h4
                      style={{
                        color:
                          testResults["Result"] == "passed" ? "green" : "red",
                      }}
                    >
                      {" "}
                      <b>{testResults["Result"]}</b>
                    </h4>
                  </Col>
                  {testResults["Result"] == "failed" && (
                    <Col>
                      <h4>
                        Failed out{" "}
                        {
                          Object.values(testResults).filter(
                            (items) => items == "no"
                          ).length
                        }{" "}
                        of {Object.keys(testResults).length - 1}
                      </h4>
                    </Col>
                  )}
                </Row>
              </div>
            </div>

            <div style={{ padding: "20px" }} className="flexCard">
              <Form onSubmit={updateStatus}>
                <Row>
                  <Col sm="6">Shop Status : {shopDetails.onlineStoreLive}</Col>
                  <Col sm="6">
                    <Button
                      style={{ marginRight: "15px", float: "right" }}
                      variant="danger"
                      onClick={() => {
                        alert("Deactivated ");
                      }}
                    >
                      Deactivate
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={testResults["Result"] == "failed"}
                      style={{ marginRight: "15px", float: "right" }}
                    >
                      Activate
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        )}
      </Container>
    </>
  );
}
