import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Container, Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import config from "../config";
import { Auth } from "aws-amplify";
export default class PromoteOffers extends Component {
  constructor(props) {
    super(props);
    this.sponsorSpaces = [];
    this.availableSpaces = [];
    this.bookedSpaces = [];
    this.state = {
      userId10Digit: "",
      todaydate: new Date().toISOString().substr(0, 10),
      bookspaces: false,
      sponsorArray: [],
      getOfferToggle: true,
      maxDate: "",
      bannerArray: [],
      sponseredSpaces: [],
      sponseredoffer: {
        offerId: "",
        bannerImageUrl: "",
        pincode: "",
        startDate: "",
        endDate: "",
        createdOn: new Date().toISOString().substr(0, 10),

        status: "Active",
        reviewedBy: "",
        notes: "",
        referenceId: ""
      },
      showForm: true,
      itemEndDate: "",
      itemStartDate: "",
      showItem: false,
      itemstatus: false,
      itemDetails: [],
      userInformation: []
    };
    this.reset = this.state;
    this.getShopDetails = this.getShopDetails.bind(this);
    this.getOfferid = this.getOfferid.bind(this);
    this.checkAvaialable = this.checkAvaialable.bind(this);
    this.sponsorSubmit = this.sponsorSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.offerBookingAvailability = this.offerBookingAvailability.bind(this);
    this.itemBookingAvailability = this.itemBookingAvailability.bind(this);
    this.itemSubmit = this.itemSubmit.bind(this);
  }
  getShopDetails(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleChange(e) {
    var target = e.target;
    var name = target.name;
    var value = target.value;
    // console.log(this.value);
    this.setState({
      ...this.state,
      sponseredoffer: { ...this.state.sponseredoffer, [name]: value } //====>
    });
  }

  sponsorSubmit(e) {
    e.preventDefault();
    fetch(
      `${config.ApiGateway}/promoteOffer`,

      {
        method: "POST",
        body: JSON.stringify(this.state.sponseredoffer),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        alert("offer promoted");
        this.setState(this.reset);
      })
      .catch(err => console.log(err));
  }

  async componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({
          ...this.state,
          sponseredoffer: {
            ...this.state.sponseredoffer,
            reviewedBy: user.attributes.phone_number
          }
        });
      })

      .catch(err => console.log(err));

    var store = [];

    await fetch(`${config.json.URL}/bannerDropdown.json`, {
      // method: "POST"
    })
      .then(res => res.json())
      .then(data => {
        data.types.forEach(item => {
          store.push(item.SPONSORED);
        });

        store[1].map(items => this.sponsorSpaces.push(items));
      });
  }
  itemSubmit(response) {
    this.itemBookingAvailability(response);
  }
  checkAvaialable(response) {
    response.offerType === "offer"
      ? this.offerBookingAvailability(response)
      : this.setState({
          showForm: false,
          showItem: false,
          itemDetails: response
        });
  }

  itemBookingAvailability(response) {
    var check = [];
    var storeTemp;
    fetch(
      `${config.ApiGateway}/checkavailability`,

      {
        method: "POST",
        body: JSON.stringify({
          availabilityStartDate: this.state.itemStartDate,
          availabilityEndDate: this.state.itemEndDate,
          availabilityPincode: response.shopPincode
        }),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        if (data.length > 0) {
          data.forEach(item => {
            this.sponsorSpaces.forEach(items => {
              if (items === item.bannerlocation) {
                this.bookedSpaces.push(item);

                check.push(items);
              }
            });
          });
        } else {
          this.sponsorSpaces.forEach(items => {
            this.availableSpaces.push(items);
          });
        }
        this.availableSpaces = this.sponsorSpaces.filter(function(val) {
          return check.indexOf(val) === -1;
        });

        storeTemp = this.availableSpaces.map(element => {
          return <option key={element}>{element}</option>;
        });

        this.setState({
          ...this.state,
          sponseredoffer: {
            ...this.state.sponseredoffer,
            offerId: response.offerId,
            pincode: response.shopPincode,
            endDate: this.state.itemEndDate,
            startDate: this.state.itemStartDate,
            bannerImageUrl: response.offerImageLocation
          },
          itemstatus: true,
          bannerArray: storeTemp,
          getOfferToggle: false,

          showForm: false,
          showItem: true
        });
      });
  }

  offerBookingAvailability(response) {
    var check = [];
    var storeTemp;
    fetch(
      `${config.ApiGateway}/checkavailability`,

      {
        method: "POST",
        body: JSON.stringify({
          availabilityStartDate: this.state.todaydate,
          availabilityEndDate: response.offerEndDate,
          availabilityPincode: response.shopPincode
        }),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        if (data.length > 0) {
          data.forEach(item => {
            this.sponsorSpaces.forEach(items => {
              if (items === item.bannerlocation) {
                this.bookedSpaces.push(item);

                check.push(items);
              }
            });
          });
        } else {
          this.sponsorSpaces.forEach(items => {
            this.availableSpaces.push(items);
          });
        }
        this.availableSpaces = this.sponsorSpaces.filter(function(val) {
          return check.indexOf(val) === -1;
        });

        storeTemp = this.availableSpaces.map(element => {
          return <option key={element}>{element}</option>;
        });

        this.setState({
          ...this.state,
          sponseredoffer: {
            ...this.state.sponseredoffer,
            offerId: response.offerId,
            pincode: response.shopPincode,
            bannerImageUrl: response.offerImageLocation
          },

          bannerArray: storeTemp,
          getOfferToggle: false,
          showForm: false,
          showItem: true,

          maxDate: response.offerEndDate
        });
      });
  }
  getOfferid(e) {
    var store = [];
    e.preventDefault();

    fetch(
      `${config.ApiGateway}/promotegetoffers`,
      // `${config.ApiGateway}/getOffers`,
      {
        method: "POST",
        body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        data[1].forEach(items => {
          store.push(items);
        });

        this.setState({
          availableOffer: store,
          getOfferToggle: false,
          userInformation: data[0]
        });
      })
      .catch(err => console.log(err));
  }
  render() {
    return (
      <Container>
        {this.state.showForm ? (
          <>
            {this.state.getOfferToggle ? (
              <>
                <div
                  style={{
                    width: 100 + "%",
                    margin: 1 + "%"
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>PROMOTE OFFERS</h3>
                </div>

                <Form onSubmit={this.getOfferid}>
                  <Row style={{ marginTop: 3 + "%" }}>
                    <Col md={{ span: 6, offset: 3 }}>
                      <Form.Label className="asterik">
                        ENTER YOUR SHOP ID
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="userId10Digit"
                        placeholder="SHOP ID"
                        onChange={this.getShopDetails}
                        value={this.state.userId10Digit}
                        pattern="[0-9]{8,10}"
                        required
                      />
                    </Col>
                    <Col>
                      <Button
                        type="submit"
                        style={{
                          marginTop: 33 + "px"
                        }}
                      >
                        SUBMIT
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            ) : (
              <>
                <h3>CHOOSE OFFER TO PROMOTE</h3>
                <Table className="tableSize" size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">OFFER IMAGE</TableCell>
                      <TableCell align="center">OFFER ID</TableCell>
                      <TableCell align="center">ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.availableOffer.map((row, index) => (
                      <TableRow key={row.offerId}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          <img
                            src={row.offerImageLocation}
                            alt={row.offerImageLocation}
                            height="200"
                            width="200"
                          />
                        </TableCell>
                        <TableCell align="center">{row.offerId}</TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              this.checkAvaialable(row);
                            }}
                          >
                            BOOK
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </>
        ) : (
          <>
            {this.state.showItem ? (
              <>
                <Row>
                  <Col md={{ span: 6 }}>
                    <Form onSubmit={this.sponsorSubmit}>
                      <Form.Label column sm="4">
                        SHOP ID
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="userInformation"
                        onChange={this.couponChange}
                        value={this.state.userInformation.userId}
                        disabled={true}
                      ></Form.Control>

                      <Form.Label>SHOP TYPE</Form.Label>

                      <Form.Control
                        type="text"
                        name="userInformation"
                        onChange={this.couponChange}
                        value={this.state.userInformation.shopType}
                        disabled={true}
                      ></Form.Control>

                      <Form.Label>SHOP NAME</Form.Label>

                      <Form.Control
                        type="text"
                        name="userInformation"
                        onChange={this.couponChange}
                        value={this.state.userInformation.shopName}
                        disabled={true}
                      ></Form.Control>

                      <Form.Label>PHONE NUMBER</Form.Label>

                      <Form.Control
                        type="text"
                        name="userInformation"
                        onChange={this.couponChange}
                        value={this.state.userInformation.userPhoneNumber}
                        disabled={true}
                      ></Form.Control>

                      <Form.Label column sm="4">
                        SHOP ADDRESS
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="userInformation"
                        onChange={this.couponChange}
                        value={this.state.userInformation.shopAddress}
                        disabled={true}
                      ></Form.Control>

                      <Form.Label className="asterik">PINCODE</Form.Label>
                      <Form.Control
                        type="text"
                        name="pincode"
                        pattern="[0-9]{6}"
                        onChange={this.handleChange}
                        value={this.state.sponseredoffer.pincode}
                        disabled={true}
                        required
                      />
                      <Form.Label className="asterik">OFFER ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="offerId"
                        // pattern="^[A-Za-z][A-Za-z ]{1,}"
                        onChange={this.handleChange}
                        disabled={true}
                        value={this.state.sponseredoffer.offerId}
                        required
                      />
                      <Form.Label className="asterik">
                        BANNER LOCATION
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="bannerlocation"
                        onChange={this.handleChange}
                        value={this.state.sponseredoffer.bannerlocation}
                        required
                      >
                        <option value="">SELECT BANNER LOCATION</option>
                        {this.state.bannerArray}
                      </Form.Control>
                      <Form.Label className="asterik">DATE</Form.Label>
                      <Form.Row>
                        <Col md={{ span: 6 }}>
                          <Form.Control
                            type="date"
                            min={this.state.todaydate}
                            name="startDate"
                            onChange={this.handleChange}
                            value={this.state.sponseredoffer.startDate}
                            disabled={this.state.itemstatus}
                            required
                          />
                        </Col>
                        <Col md={{ span: 6 }}>
                          <Form.Control
                            type="date"
                            name="endDate"
                            min={this.state.sponseredoffer.startDate}
                            onChange={this.handleChange}
                            value={this.state.sponseredoffer.endDate}
                            max={this.state.maxDate}
                            disabled={this.state.itemstatus}
                            required
                          />
                        </Col>
                      </Form.Row>
                      <Form.Label className="asterik">NOTES</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="notes"
                        pattern="^[A-Za-z][A-Za-z ]{1,}"
                        onChange={this.handleChange}
                        value={this.state.sponseredoffer.notes}
                        required
                      />
                      <Form.Label className="asterik">
                        TRANSACTION ID
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="referenceId"
                        pattern="^[A-Za-z0-9][A-Za-z0-9 ]{1,}"
                        onChange={this.handleChange}
                        value={this.state.sponseredoffer.referenceId}
                        required
                      />

                      <Button
                        type="submit"
                        style={{
                          float: "right",
                          marginBottom: 20 + "px",
                          marginTop: 20 + "px"
                        }}
                      >
                        SUBMIT
                      </Button>
                    </Form>
                  </Col>
                  <Col md={{ span: 4 }}>
                    <Image
                      src={this.state.sponseredoffer.bannerImageUrl}
                      thumbnail
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Col md={{ span: 8 }}>
                  <Form>
                    <Form.Label className="asterik">
                      CHECK AVAILABILITY FOR BOOKING ITEMS
                    </Form.Label>
                    <Form.Row>
                      <Col md={{ span: 6 }}>
                        <Form.Control
                          type="date"
                          min={this.state.todaydate}
                          name="itemStartDate"
                          onChange={this.getShopDetails}
                          value={this.state.itemStartDate}
                          required
                        />
                      </Col>
                      <Col md={{ span: 6 }}>
                        <Form.Control
                          type="date"
                          name="itemEndDate"
                          onChange={this.getShopDetails}
                          min={this.state.itemStartDate}
                          value={this.state.itemEndDate}
                          required
                        />
                      </Col>
                    </Form.Row>

                    <Button
                      variant="outlined"
                      onClick={() => {
                        this.itemSubmit(this.state.itemDetails);
                      }}
                      style={{
                        float: "right",
                        marginBottom: 20 + "px",
                        marginTop: 20 + "px"
                      }}
                    >
                      submit
                    </Button>
                  </Form>
                </Col>
              </>
            )}
          </>
        )}
      </Container>
    );
  }
}
