import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  root: {
    display: "flex",

    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});

const TableHeadClass = ({ edit }) => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableCell}>S NO</TableCell>
        <TableCell className={classes.tableCell}>Group </TableCell>
        <TableCell className={classes.tableCell}>Category</TableCell>
        <TableCell className={classes.tableCell}>Sub Category</TableCell>
        <TableCell className={classes.tableCell}>Product Name</TableCell>
        <TableCell className={classes.tableCell}>Brand</TableCell>
        <TableCell className={classes.tableCell}>MRP</TableCell>
        <TableCell className={classes.tableCell}>Unit of Measurment</TableCell>
        <TableCell className={classes.tableCell}>
          Product Quantity
        </TableCell>{" "}
        <TableCell className={classes.tableCell}>
         Ean Code
        </TableCell>{" "}
        {edit && (
          <>
            <TableCell className={classes.tableCell}>Edit</TableCell>
            <TableCell className={classes.tableCell}>Delete</TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadClass;
