import React, { Component } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { CSVLink } from "react-csv";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import config from "../../config";
import Limit from "../../limit";
import DropDown from "../../dropDown";
import { Auth } from "aws-amplify";

export default class PromocodeCreated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCouponGraph: [],
      redeemedCouponsArray: [],
      couponObject: {},
      redeemedObject: {},
      pincodeDropDown: [],
      searchPincode: "all",
      Search: true,
      startDate: "2019-12-01",
      endDate: new Date().toISOString().substring(0, 10),
      couponInformation: [],
      couponType: "",
      func1: this.fetchCoupon.bind(this)
    };
    this.getPromocodeCreated = this.getPromocodeCreated.bind(this);
    this.onChange = this.onChange.bind(this);
    this.viewShop = this.viewShop.bind(this);
  }

  componentDidUpdate(props, state) {
    if (props.couponType !== state.couponType) {
      // return { func1: state.func1() };
      // console.log(props,state)
    }

    return null;
  }

  viewShop() {
    this.props.getCouponShops(this.state.couponInformation);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  async componentDidMount() {
    await this.fetchCoupon();
  }
  async fetchCoupon() {
    let pincodeDropdown = [];
    Promise.all([
      await fetch(`${config.Elasticsearch.URL}/coupons/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                {
                  match: {
                    couponCategory: "Created"
                  }
                },
                {
                  match: {
                    couponType: this.props.couponType
                  }
                }
              ],
              must_not: [{ term: { pincode: Limit.size.ghoshakInternal } }]
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let tempPincode = data.hits.hits.map(element => element._source);

        tempPincode.forEach(element => pincodeDropdown.push(element.pincode));
        pincodeDropdown = [...new Set(pincodeDropdown)];

        pincodeDropdown = pincodeDropdown.map(elements => (
          <option key={elements}>{elements}</option>
        ));
        this.setState({
          pincodeDropDown: pincodeDropdown,
          couponType: this.props.couponType
        });
      })
      .catch(err => console.log(err));

    await this.getPromocodeCreated();
  }

  getPromocodeCreated() {
    let pincodeDropdown = [];
    let queryBody =
      this.state.searchPincode === "all"
        ? {
            query: {
              bool: {
                must: [
                  {
                    match: {
                      couponCategory: "Created"
                    }
                  },
                  {
                    match: {
                      couponType: this.props.couponType
                    }
                  },
                  {
                    range: {
                      addedDate: {
                        gte: this.state.startDate,
                        lte: this.state.endDate,
                        boost: 2.0
                      }
                    }
                  }
                ],
                must_not: [{ term: { pincode: Limit.size.ghoshakInternal } }]
              }
            }
          }
        : {
            query: {
              bool: {
                must: [
                  {
                    match: {
                      couponCategory: "Created"
                    }
                  },
                  {
                    match: {
                      couponType: this.props.couponType
                    }
                  },
                  {
                    match: {
                      pincode: this.state.searchPincode
                    }
                  },
                  {
                    range: {
                      addedDate: {
                        gte: this.state.startDate,
                        lte: this.state.endDate,
                        boost: 2.0
                      }
                    }
                  }
                ],
                must_not: [{ term: { pincode: Limit.size.ghoshakInternal } }]
              }
            }
          };

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/coupons/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify(queryBody),

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let tempCoupon = data.hits.hits.map(element => element._source);
        let promocodeCreated = [];
        let couponId = [];
        let pincodeDropdown = [];
        let livePromoCoupons = {
          liveCoupons: 0,
          totalLiveCouponCount: 0,
          livePincode: {},
          expiredCoupons: 0
        };
        let activeCoupon = [];

        let todayDate = new Date().toISOString().substring(0, 10);

        tempCoupon.forEach(elements => {
          let expiry =
            this.props.couponType === "PROMOCODE"
              ? elements.promocodeExpiryDate
              : elements.expiryDate;
          couponId.push(elements.couponId);
          if (this.props.couponType !== "LOYALTY") {
            if (expiry > todayDate) {
              livePromoCoupons.liveCoupons += 1;

              livePromoCoupons.totalLiveCouponCount +=
                this.props.couponType === "PROMOCODE"
                  ? parseInt(elements.promocodeCount)
                  : parseInt(elements.totalCouponCount);
              if (
                livePromoCoupons["livePincode"][elements.pincode] === undefined
              ) {
                livePromoCoupons["livePincode"][elements.pincode] =
                  this.props.couponType === "PROMOCODE"
                    ? parseInt(elements.promocodeCount)
                    : parseInt(elements.totalCouponCount);
              } else {
                livePromoCoupons["livePincode"][elements.pincode] +=
                  this.props.couponType === "PROMOCODE"
                    ? parseInt(elements.promocodeCount)
                    : parseInt(elements.totalCouponCount);
              }
            } else {
              livePromoCoupons.expiredCoupons += 1;
            }
          } else {
            livePromoCoupons.liveCoupons += 1;

            livePromoCoupons.totalLiveCouponCount +=
              this.props.couponType === "PROMOCODE"
                ? parseInt(elements.promocodeCount)
                : parseInt(elements.totalCouponCount);
            if (
              livePromoCoupons["livePincode"][elements.pincode] === undefined
            ) {
              livePromoCoupons["livePincode"][elements.pincode] =
                this.props.couponType === "PROMOCODE"
                  ? parseInt(elements.promocodeCount)
                  : parseInt(elements.totalCouponCount);
            } else {
              livePromoCoupons["livePincode"][elements.pincode] +=
                this.props.couponType === "PROMOCODE"
                  ? parseInt(elements.promocodeCount)
                  : parseInt(elements.totalCouponCount);
            }
          }

          promocodeCreated.push({
            couponId: elements.couponId,
            addedDate: elements.addedDate,
            userId10Digit: elements.userId10Digit,
            shopId: elements.shopId,
            pincode: elements.pincode,
            maximumCouponAmount: elements.maximumCouponAmount,
            ExpiryDate:
              this.props.couponType === "PROMOCODE"
                ? elements.promocodeExpiryDate
                : elements.expiryDate,
            promocode: elements.promocode,
            totalCouponCount:
              this.props.couponType === "PROMOCODE"
                ? elements.promocodeCount
                : elements.totalCouponCount,
            couponDetails: elements.couponDetails,
            couponsused:
              this.props.couponType === "PROMOCODE"
                ? parseInt(elements.promocodeCount) -
                  parseInt(elements.promocodeRemainingCount)
                : parseInt(elements.totalCouponCount) -
                  parseInt(elements.remainingCouponCount),
            allocatedCost:
              this.props.couponType === "PROMOCODE"
                ? parseInt(elements.maximumCouponAmount) *
                  parseInt(elements.promocodeCount)
                : parseInt(elements.maximumCouponAmount) *
                  parseInt(elements.totalCouponCount),

            consumedCost:
              this.props.couponType === "PROMOCODE"
                ? (parseInt(elements.promocodeCount) -
                    parseInt(elements.promocodeRemainingCount)) *
                  parseInt(elements.maximumCouponAmount)
                : (parseInt(elements.totalCouponCount) -
                    parseInt(elements.remainingCouponCount)) *
                  parseInt(elements.maximumCouponAmount)
          });
        });

        Object.entries(livePromoCoupons).forEach(([element, value]) => {
          if (element === "livePincode") {
            for (let key in value) {
              activeCoupon.push({
                pincode: key,
                totalCoupons: value[key]
              });
            }
          }
        });

        this.setState({
          activeCouponGraph: activeCoupon,
          couponObject: livePromoCoupons,
          couponInformation: promocodeCreated
        });

        this.getRedeemed(couponId);
      })
      .catch(err => console.log(err));
  }

  getRedeemed(couponId) {
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/coupons/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              should: [
                {
                  terms: {
                    redeemedCouponId: couponId
                  }
                }
              ]
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let tempcouponRedeemed = data.hits.hits.map(element => element._source);

        let redeemedCoupon = {};
        let redeemedCouponCount = {
          redeemed: 0,
          claimed: 0
        };
        tempcouponRedeemed.forEach(elements => {
          if (redeemedCoupon[elements.pincode] === undefined) {
            redeemedCoupon[elements.pincode] = {};
          }

          if (elements.couponStatus === "Redeemed") {
            redeemedCouponCount.redeemed += 1;
          } else if (elements.couponStatus === "Claim") {
            redeemedCouponCount.claimed += 1;
          }
          if (
            redeemedCoupon[elements.pincode][elements.couponStatus] ===
            undefined
          ) {
            redeemedCoupon[elements.pincode][elements.couponStatus] = 1;
          } else {
            redeemedCoupon[elements.pincode][elements.couponStatus] += 1;
          }

          if (redeemedCouponCount[elements.paidStatus] === undefined) {
            redeemedCouponCount[elements.paidStatus] = 1;
          } else {
            redeemedCouponCount[elements.paidStatus] += 1;
          }
        });
        let redeemedPromoCoupons = [];
        Object.entries(redeemedCoupon).forEach(([elements, value]) => {
          redeemedPromoCoupons.push({
            pincode: elements,
            Reedemed: value.Redeemed !== undefined ? value.Redeemed : 0,
            Claimed: value.Claim !== undefined ? value.Claim : 0
          });
        });

        this.setState({
          redeemedCouponsArray: redeemedPromoCoupons,
          redeemedObject: redeemedCouponCount
        });
      })
      .catch(err => console.log(err));
  }
  render() {
    if (this.props.couponType !== this.state.couponType) {
      this.fetchCoupon();
    }

    return (
      <>
        <div
          style={{
            backgroundColor: "#fafafa",
            width: 100 + "%"
          }}
        >
          <h3 style={{ textAlign: "center" }}>
            {this.props.couponType.toUpperCase()} COUPON
          </h3>
        </div>

        <Row>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <strong> DATE RANGE</strong>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="startDate"
                value={this.state.startDate}
                onChange={this.onChange}
                type="date"
                required
              ></Form.Control>
              <Form.Control
                name="endDate"
                onChange={this.onChange}
                value={this.state.endDate}
                type="date"
                min={this.state.startDate}
                required
              ></Form.Control>
            </InputGroup>
          </Col>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <b>PINCODE </b>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                onChange={this.onChange}
                name="searchPincode"
                as="select"
                value={this.state.searchPincode}
              >
                <option value="">SELECT PINCODE</option>
                <option value="all">ALL</option>
                {this.state.pincodeDropDown}
              </Form.Control>
            </InputGroup>
          </Col>
          <Col>
            <Button
              onClick={() => {
                this.getPromocodeCreated();
              }}
            >
              SEARCH
            </Button>
          </Col>
        </Row>

        <Row>
          <Col style={{ textAlign: "center" }} className="division">
            <b>
              {"TOTAL"}
              {this.props.couponType.toUpperCase()} COUPONS
            </b>
            <h3 style={{ textAlign: "center" }}>
              {this.state.couponObject.totalLiveCouponCount}
            </h3>
          </Col>
          <Col style={{ textAlign: "center" }} className="division">
            <b>REEDEEMED </b>
            <h3 style={{ textAlign: "center" }}>
              {this.state.redeemedObject.redeemed}
            </h3>
          </Col>
          <Col style={{ textAlign: "center" }} className="division">
            <b>CLAIMED</b>
            <h3 style={{ textAlign: "center" }}>
              {this.state.redeemedObject.claimed}
            </h3>
          </Col>
        </Row>
        <Row>
          <div
            style={{
              backgroundColor: "#fafafa",
              width: 100 + "%"
            }}
          >
            <h3 style={{ textAlign: "center" }}>TRANSACTION</h3>
          </div>
          <Col style={{ textAlign: "center" }} className="division">
            <b>PAID</b>

            <h3 style={{ textAlign: "center" }}>
              {this.state.redeemedObject.Paid !== undefined
                ? this.state.redeemedObject.Paid
                : 0}
            </h3>
          </Col>
          <Col style={{ textAlign: "center" }} className="division">
            <b>UNPAID</b>
            <h3 style={{ textAlign: "center" }}>
              {this.state.redeemedObject.Unpaid !== undefined
                ? this.state.redeemedObject.Unpaid
                : 0}
            </h3>
          </Col>
        </Row>
        <Row className="division">
          <div
            style={{
              backgroundColor: "#fafafa",
              width: 100 + "%"
            }}
          >
            <h3 style={{ textAlign: "center" }}>GRAPH</h3>
          </div>
          <Col>
            <div className="cardDisplay">
              <h4 style={{ textAlign: "center" }}>TOTAL COUPON BY PINCODE</h4>
              <BarChart
                width={600}
                height={300}
                data={this.state.activeCouponGraph}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
                className="recharts"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  tickLine={true}
                  xAxisId={0}
                  dy={0}
                  dx={-0}
                  label={{
                    value: "",
                    angle: 0,
                    position: "bottom"
                  }}
                  interval={0}
                  dataKey="pincode"
                  tickLine={false}
                  tick={{ fontSize: 9, angle: 0 }}
                />

                <YAxis
                  label={{
                    value: "TOTAL COUPONS",
                    angle: -90,
                    position: "insideLeft"
                  }}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="totalCoupons" fill="#8884d8">
                  <LabelList dataKey="totalCoupons" position="top" />
                </Bar>
              </BarChart>
            </div>
          </Col>
          <Col>
            <div className="cardDisplay">
              <h4 style={{ textAlign: "center" }}>
                REDEEMED COUPON BY PINCODE
              </h4>
              <BarChart
                width={600}
                height={300}
                data={this.state.redeemedCouponsArray}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
                className="recharts"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  tickLine={true}
                  xAxisId={0}
                  dy={0}
                  dx={-0}
                  label={{
                    value: "",
                    angle: 0,
                    position: "bottom"
                  }}
                  interval={0}
                  dataKey="pincode"
                  tickLine={false}
                  tick={{ fontSize: 9, angle: 0 }}
                />

                <YAxis
                  label={{
                    value: "TOTAL COUPONS",
                    angle: -90,
                    position: "insideLeft"
                  }}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="Reedemed" fill="#8884d8">
                  <LabelList dataKey="Reedemed" position="top" />
                </Bar>
                <Bar dataKey="Claimed" fill="green">
                  <LabelList dataKey="Claimed" position="top" />
                </Bar>
              </BarChart>
            </div>
          </Col>
        </Row>
        <Row className="division">
          <Col>
            <h4>VIEW ALL SHOPS</h4>
          </Col>
          <Col>
            <Button
              onClick={() => {
                this.viewShop();
              }}
            >
              VIEW ALL SHOPS
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}
