import React, { useState, useContext, useEffect } from "react";
import { Container, Col, Button, Row, Form } from "react-bootstrap";
import config from "../config";
export default function RedeemPromocode() {
  const [promocode, setPromocode] = useState("");
  const [custUserId, setCustUserId] = useState("");
  const [custDetails, setCustDetails] = useState({});
  const [state, setState] = useState({
    status: "",
    err: "",
    couponDetails: "",
  });
  const getCustomer = (e) => {
    e.preventDefault();
    // Get Customer Details
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              should: [
                {
                  term: {
                    userPhoneNumber: custUserId,
                  },
                },
                {
                  term: {
                    userId10Digit: custUserId,
                  },
                },
                {
                  term: {
                    userId: custUserId,
                  },
                },
              ],
              minimum_should_match: 1,
              filter: {
                match: {
                  userType: "Customer",
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let tempdata = data.hits.hits.map((element) => element._source);
        if (tempdata.length > 0) setCustDetails(tempdata[0]);
      })
      .catch((err) => console.log("Getting Customer Details", err));
  };

  const getPromocode = (e) => {
    //Check promocode availability
    e.preventDefault();

    Promise.all([
      fetch(`${config.ApiGateway}/checkpromocodeAvailability`, {
        method: "POST",
        body: JSON.stringify({
          promocode: promocode,
          custId: custDetails.userId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        if (data.status === "SUCCESS") {
          setState({
            status: "SUCCESS",
            err: "",
            couponDetails: data.couponDetails.couponDetails,
          });
        } else if (data.status === "INVALID PROMOCODE") {
          setState({
            status: "INVALID PROMOCODE",
            err: "Invalid promocode.Please use valid Coupon",
            couponDetails: data.couponDetails,
          });
        } else if (data.status === "PROMOCODE ALREADY USED") {
          alert("Promocode already used");
          setState({
            status: "PROMOCODE ALREADY USED",
            err: "Promocode already used",
            couponDetails: data.couponDetails,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const redeemPromocode = (e) => {
    // Change coupon status to claim
    e.preventDefault();
    Promise.all([
      fetch(`${config.ApiGateway}/promocodeAvail`, {
        method: "POST",
        body: JSON.stringify({
          promocode: promocode,
          custId: custDetails.userId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        if (data.status === "SUCCESS") {
          alert("Claimed successfully");
          setPromocode("");
          setState({
            status: "",
            err: "",
            couponDetails: "",
          });
          setCustDetails({});
          setCustUserId("");
        } else if (data.status === "INVALID PROMOCODE") {
          setState({
            status: "INVALID PROMOCODE",
            err: "Invalid promocode.Please use valid Coupon",
            couponDetails: data.couponDetails,
          });
        } else if (data.status === "PROMOCODE ALREADY USED") {
          alert("Promocode already used");
          setState({
            status: "PROMOCODE ALREADY USED",
            err: "Promocode already used",
            couponDetails: data.couponDetails,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container>
      <div style={{ width: "100%", height: "40px" }}></div>
      <div className="orderHeader">
        <h2 className="orderHeaderText">Redeem Promocode</h2>
      </div>
      <div style={{ width: "100%", height: "40px" }}></div>
      <Form onSubmit={getCustomer} style={{ width: "100%" }}>
        <Row>
          <Col sm={3}>
            <Form.Label>Enter Ghoshak customer ID</Form.Label>
          </Col>
          <Col sm={6}>
            <Form.Control
              name="custUserId"
              onChange={(e) => setCustUserId(e.target.value)}
              value={custUserId}
              placeholder="Enter Customer Ghoshak Id"
              required
            ></Form.Control>
          </Col>
          <Col sm={3}>
            <Button type="submit">Search</Button>
          </Col>
        </Row>
      </Form>
      {Object.keys(custDetails).length > 0 && (
        <>
          <Row className="CustomCard">
            <div className="customCard-box">
              <h4 style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                Customer Details
              </h4>
            </div>
            <Col>
              <h6 className="userAddedSub">Name</h6>
              <h5>{custDetails.userName}</h5>
            </Col>
            <Col>
              <h6 className="userAddedSub">Phone Number</h6>
              <h5 className="userAddedValue">{custDetails.userPhoneNumber}</h5>
            </Col>
            <Col>
              <h6 className="userAddedSub">Pincode</h6>
              <h5 className="userAddedValue">{custDetails.userPincode}</h5>
            </Col>
            <Col>
              <h6 className="userAddedSub">Address</h6>
              <h5 className="userAddedValue">{custDetails.userAddress}</h5>
            </Col>
            <Col>
              <h6 className="userAddedSub">Ghoshak Id</h6>
              <h5 className="userAddedValue">{custDetails.userId10Digit}</h5>
            </Col>
          </Row>
          <Row>
            <Form
              onSubmit={getPromocode}
              style={{ width: "100%", margin: "40px 5px" }}
            >
              <Row>
                <Col sm={3}>
                  <Form.Label>Check Promocode Availability</Form.Label>
                </Col>
                <Col sm={6}>
                  <Form.Control
                    onChange={(e) => setPromocode(e.target.value)}
                    placeholder="Promocode"
                    value={promocode}
                    required
                    disabled={state.status === "SUCCESS"}
                  ></Form.Control>
                  {state.status === "INVALID PROMOCODE" && (
                    <Form.Text style={{ color: "red" }}>{state.err}</Form.Text>
                  )}
                </Col>
                <Col sm={3}>
                  <Button type="submit">Search</Button>
                </Col>
              </Row>
            </Form>
          </Row>
          {state.status === "SUCCESS" && (
            <>
              <Row className="CustomCard">
                <div className="customCard-box">
                  <h4 style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                    Coupon Details
                  </h4>
                </div>

                <Col>
                  <h6 className="userAddedSub">{state.couponDetails}</h6>
                </Col>
              </Row>
              <div className="vertical-space-30"></div>
              <Row style={{ Width: "50%", float: "right" }}>
                <Form onSubmit={redeemPromocode}>
                  <Button type="submit" style={{ marginRight: "5px" }}>
                    Redeem
                  </Button>
                  <Button
                    onClick={() => {
                      setPromocode("");

                      setState({
                        status: "",
                        err: "",
                        couponDetails: "",
                      });
                    }}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Form>
              </Row>
            </>
          )}
        </>
      )}
    </Container>
  );
}
