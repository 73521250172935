import React, { useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import S3 from "react-aws-s3";
import uuid from "uuid/dist/v4";
import config from "../../config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { ExcelRenderer } from "react-excel-renderer";
import { v4 as uuidv4 } from "uuid";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import "../../styles/addProducts.css";
// import { tableIcons } from "../../tableIcons";

const AddProducts = (props) => {
  const [shopId, setShopId] = useState("");
  const [shopData, setShopData] = useState(null);
  const [categoryList, setCategorylist] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [productDetails, setProductDetails] = useState({ ...INITIAL_STATE });
  var INITIAL_STATE = {
    productBrand: "",
    productName: "",
    productGroup: "",
    productCategory: "",
    productSubCategory: "",
    productMrp: "",
    productPrice: "",
    shopId: "",
    measurmentValue: "",
    measurmentUnit: "",
    inventoryQty: "",
    purchaseRate: "",
    eanCode: "",
    hsnCode: "",
    productCode: "",
    wastage: "",
    labour: "",
    vegNonVeg: "",
    tax: "",
    taxType: "",
    discount: "",
    discountType: "",
    imei1: "",
    imei2: "",
    size: "",
    productImageArray: [],
    productType: "product",
    frequentPriceChange: false,
    taxIncExc: "",
    taxId: "",
    expiryDate: "",
  };
  const [taxList, setTaxList] = useState("");
  const [productImages, setProductImages] = useState([]);
  const [addIndividualProducts, setAddIndividualProducts] = useState(false);
  useEffect(() => {
    const fetchUserDetails = async (shopId) => {
      if (shopId === "") return alert("Enter valid Shop Id");
      return await Promise.all([
        fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
          method: "POST",
          body: JSON.stringify({
            userId10Digit: shopId,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ])
        .then(([res]) => Promise.all([res.json()]))
        .then(async ([data]) => {
          console.log("data(o)", data[0]);
          setShopData(data[0]);
          setProductDetails({ ...productDetails, shopId: data[0].userId });
          // await fetchTaxsList(data.userId);
        })
        .catch((err) => {
          console.log("updating A Record Error", err);
          alert("Something went wrong, please try again");
        });
    };
    if (props.shopId != undefined) {
      fetchUserDetails(props.shopId);
    }
    // var data = await fetchUserDetails(props.shopId);
    // var taxList = await fetchTaxsList(data.userId);
    // console.log("category,category", categoryList);
    // console.log("category,category", categoryList);
    // setShopData(data);
  }, [props]);
  // useEffect(() => {
  //   if(shopData)
  //   setProductDetails({ ...productDetails, shopId: shopData.userId });

  // }, [shopData]);
  console.log("PROPS PROPS", props);
  const ref = useRef();
  const [dataArray, setDataArray] = useState([]);
  useEffect(() => {
    console.log("DataArray", dataArray);
  }, [dataArray]);
  const rowTemplate = [
    "group",
    "category",
    "subCategory",
    "productBrand",
    "productName",
    "productMrp",
    "productPrice",
    "measurement Value",
    "measurement Unit",
    "inventoryQty",
    "productEANCode",
    "purchasePrice",
    "productCode",
    "productTax",
    "productTaxType",
    "productHsnCode",
    "productImageArray",
  ];
  const errorPopUp = () => {
    alert(
      "Invalid Excel Sheet, Please Download the Excel Template given in this page add your products and upload again."
    );
    setDataArray([]);
    ref.current.value = "";
  };
  const imageUploader = async (e) => {
    const configSettings = {
      bucketName: "ghoshak-website-builder",
      region: "ap-south-1",
      accessKeyId: config.AccessKeyId,
      secretAccessKey: config.SecretAccessKey,
    };
    var fileName = uuid() + ".jpeg";
    var image = e.target.files[0];
    var S3Client = new S3(configSettings);
    let data = await S3Client.uploadFile(image, fileName);
    console.log("IMAGE DATA", data);
    // productImages.push(data.location);
    setProductImages([...productImages, data.location]);
    setProductDetails({
      ...productDetails,
      productImageArray: [...productImages, data.location],
    });
    console.log("productImageArray", productImages);
  };

  function PatchedPagination(props) {
    const {
      ActionsComponent,
      onChangePage,
      onChangeRowsPerPage,
      ...tablePaginationProps
    } = props;
    return (
      <TablePagination
        {...tablePaginationProps}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        ActionsComponent={(subprops) => {
          const { onPageChange, ...actionsComponentProps } = subprops;
          return (
            <ActionsComponent
              {...actionsComponentProps}
              onChangePage={onPageChange}
            />
          );
        }}
      />
    );
  }

  const removeSelectedProduct = (image) => {
    var updatedArray = productImages.filter((item) => !item.includes(image));
    console.log("updatedArray", updatedArray);
    setProductImages(updatedArray);
    setProductDetails({ ...productDetails, productImageArray: updatedArray });
    // return updatedArray;
  };

  const handleImageUpload = async (image) => {
    const configSettings = {
      bucketName: "ghoshak-website-builder",
      region: "ap-south-1",
      accessKeyId: config.AccessKeyId,
      secretAccessKey: config.SecretAccessKey,
    };
    var fileName = uuid() + ".jpeg";
    var S3Client = new S3(configSettings);
    let data = await S3Client.uploadFile(image, fileName)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log("S3 Error", err);
      });
    console.log("IMAGE DATA", data);
    return data;
  };
  const addNewProduct = async () => {
    if (productDetails.productCategory == "") {
      return alert("Add Category");
    }
    if (productDetails.productSubCategory == "") {
      return alert("Add Subcategory");
    }

    if (productDetails.productName == "") {
      return alert("Add Product Name");
    }
    if (productDetails.productBrand == "") {
      return alert("Add Product Brand");
    }
    if (productDetails.shopId == "") {
      return alert("Select atleast one shop");
    }
    try {
      var response = await fetch(
        `${config.POSApi}/addProduct`,
        // "https://pgtj1fkue6.execute-api.ap-south-1.amazonaws.com/prod/addProduct",
        // `https://2z4hriwfak.execute-api.ap-south-1.amazonaws.com/dev/addProduct`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(productDetails),
        }
      );
      alert("Product Added Successfully");
      setProductDetails({
        ...INITIAL_STATE,
      });
    } catch (err) {
      console.log(err);
    }

    // await fetch(
    //   // https://pgtj1fkue6.execute-api.ap-south-1.amazonaws.com/prod
    //   `https://2z4hriwfak.execute-api.ap-south-1.amazonaws.com/dev/addProduct`,
    //   {
    //     method: "POST",
    //     body: JSON.stringify(productDetails),
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // )
    //   .then(([res]) => Promise.all([res.json()]))
    //   .then(async ([data]) => {
    //     setProductDetails({
    //       ...INITIAL_STATE,
    //     });
    //     alert("Product added successfully");
    //   })
    //   .catch((err) => {
    //     console.log("Err", err);
    //     // setDisabled(false);
    //   });
  };
  const uploadProduct = async () => {
    console.log("DATA_ARRAY_DATA", dataArray);
    await Promise.all([
      fetch(
        `https://pgtj1fkue6.execute-api.ap-south-1.amazonaws.com/prod/productBulkUpload`,
        {
          method: "POST",
          body: JSON.stringify(dataArray),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res]) => Promise.all([res.json()]))
      .then(([data]) => {
        alert("Product added successfully", data);
        setDataArray([]);
      })
      .catch((err) => {
        console.log("err", err);
        alert("failed, try again");
      });
  };
  const addProduct = () => {
    console.log("productcateogruy", productDetails.productCategory);
  };
  const getExcelData = (e) => {
    let fileObj = e.target.files[0];
    ExcelRenderer(fileObj, (err, data) => {
      console.log("exceldata", data);
      if (err) {
        console.log("Error", err);
      } else {
        if (rowTemplate.length !== data.rows[0].length) {
          errorPopUp();
          return;
        }
        for (var i = 0; i < data.rows[0].length; i++) {
          if (data.rows[0].includes(rowTemplate[i])) {
            continue;
          }
          errorPopUp();
          return;
        }
        let arr = [];
        for (var i = 1; i < data.rows.length; i++) {
          let rowArr = data.rows[i];
          let headingArr = data.rows[0];
          let count = 0;
          let mValue = "";
          let mUnit = "";
          if (rowArr.length <= 0) continue;
          console.log("headingArr", headingArr);
          let obj = {
            productId: uuidv4(),
            addedDate: new Date().toISOString().substr(0, 10),
            shopId: shopData.userId,
            status: "live",
            group: "",
            category: "",
            subCategory: "",
            productBrand: "",
            productName: "",
            productMrp: "",
            productPrice: "TBD",
            unitOfMeasurment: "Others",
            productEANCode: "TBD",
            purchasePrice: "",
            inventoryQty: 0,
            productCode: "",
            versionId: "1",
            latest: "true",
            lowStockAlert: 5,
            productImageArray: [],
            productType: "product",
            productTax: "",
            productTaxType: "",
            productHsnCode: "",
            taxIncExc: "",
            taxId: "",
          };
          rowArr.map((data, index) => {
            console.log("DATA", data, "INDEX", index);
            if (count !== index) {
              for (var i = count; i < index; i++) {
                if (headingArr[i] === "category") {
                  obj = {
                    ...obj,
                    category: "Others",
                  };
                }
                if (headingArr[i] === "group") {
                  obj = {
                    ...obj,
                    group: "Others",
                  };
                }
                if (headingArr[i] === "productPrice") {
                  obj = {
                    ...obj,
                    productPrice: "TBD",
                  };
                }
                if (headingArr[i] === "productImageArray") {
                  console.log("PROD IMG DATA", data);
                  obj = {
                    ...obj,
                    productImageArray: "TBD",
                  };
                }
                if (
                  headingArr[i] === "measurement Value" ||
                  headingArr[i] === "measurement Unit"
                ) {
                  obj = {
                    ...obj,
                    unitOfMeasurment: "Others",
                  };
                }

                if (headingArr[i] === "subCategory") {
                  obj = {
                    ...obj,
                    subCategory: "Others",
                  };
                }
                if (headingArr[i] === "productEANCode") {
                  obj = {
                    ...obj,
                    productEANCode: "TBD",
                  };
                }
              }
              count = index;
            }
            if (count === index) {
              if (headingArr[count] === "measurement Value") {
                mValue = data.toString();
                count++;
                return;
              } else if (headingArr[count] === "measurement Unit") {
                mUnit = data;
                count++;
                return;
              } else if (headingArr[count] === "productImageArray") {
                var arr = data.split(",");
                // var arr = [];
                // if(data.length > 1) {
                //   arr = data.split(',')
                // }else{
                //   arr = data[0]
                // }
                console.log("IMAGE ARR IMAGE ARR", arr);
                obj = {
                  ...obj,
                  [headingArr[count]]: arr,
                };
              } else
                obj = {
                  ...obj,
                  [headingArr[count]]: data.toString(),
                };
              if (count === rowArr.length - 1) {
                if (mValue !== "" && mUnit !== "") {
                  obj = {
                    ...obj,
                    unitOfMeasurment: mValue + " " + mUnit,
                  };
                }
              }
            }
            count++;
          });
          arr.push(obj);
          console.log("OBJ OBJ OBJ", obj);
        }
        setDataArray(arr);
      }
    });
  };
  const getShopDetails = () => {
    var categories = Object.keys(shopData.categoryList);
    setCategorylist(categories);

    const fetchTaxsList = async (shopId) => {
      var res = await fetch(`${config.POSApi}/fetchTaxMaster`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          shopId,
        }),
      });
      res = await res.json();
      if (res.code === "ERROR") {
        console.log("Err", res.data);
        return alert(res.message);
      }
      setTaxList(res.data);
      setAddIndividualProducts(true);
    };
    fetchTaxsList(shopData.userId);
  };
  const getProductExcel = () => {
    var productExcel =
      "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/productExcelUploadTemplate.xlsx";
    window.open(productExcel);
  };
  const subCategoryAssigner = (category) => {
    if (category == "Select Category") return setSubCategory([]);
    var subCategory = shopData.categoryList[category].subCategory;
    setSubCategory(subCategory);
    setProductDetails({ ...productDetails, productCategory: category });
  };
  const inputFieldDataUpdater = (e) => {
    setProductDetails({ ...productDetails, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    console.log("productDetailsUPDATE", productDetails);
  }, [productDetails]);
  const UNIT = [
    "g",
    "kg",
    "mg",
    "ml",
    "L",
    "cup",
    "count",
    "box",
    "pkt",
    "glass",
    "cm",
    "m",
    "km",
    "min",
    "hour",
    "days",
    "inch",
    "ton",
  ];

  return (
    <div>
      {shopData == null ? (
        <div>Loading...</div>
      ) : (
        <div className="addProductsMainOuter">
          <div className="addProductsHeader">
            <div className="addProductsHeaderButtonsContainer">
              <button onClick={() => getProductExcel()}>
                Download Excel Template
              </button>
              <div>
                <button onClick={() => getShopDetails()}>Add product</button>{" "}
              </div>
              <div className="excelUploadButtonContainer">
                Upload Excel
                <input
                  ref={ref}
                  id="excelInput"
                  className="fileInput"
                  style={{
                    marginLeft: "20px",
                  }}
                  type="file"
                  accept="application/vnd.ms-excel,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={(e) => {
                    getExcelData(e);
                  }}
                  onClick={() => (ref.current.value = "")}
                />{" "}
              </div>
            </div>
            {dataArray.length > 0 ? (
              <div style={{ textAlign: "right" }}>
                <Button
                  onClick={() => {
                    uploadProduct();
                  }}
                >
                  Upload
                </Button>{" "}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="addProductsBody">
            <div className="excelUploadMaterialTable">
              {dataArray.length > 0 && (
                <MaterialTable
                  columns={[
                    {
                      title: "Group",
                      field: "group",
                    },
                    {
                      title: "Category",
                      field: "category",
                    },
                    {
                      title: "Sub Category",
                      field: "subCategory",
                    },
                    {
                      title: "Product Brand",
                      field: "productBrand",
                    },
                    {
                      title: "Product Name",
                      field: "productName",
                    },
                    {
                      title: "MRP(Rs.)",
                      field: "productMrp",
                    },
                    {
                      title: "Selling Price(Rs.)",
                      field: "productPrice",
                    },
                    {
                      title: "Measurement",
                      field: "unitOfMeasurment",
                    },
                    {
                      title: "Inventory Quantity",
                      field: "inventoryQty",
                    },
                    {
                      title: "Product EAN Code",
                      field: "productEANCode",
                    },
                    {
                      title: "Purchase Price(Rs.)",
                      field: "purchasePrice",
                    },
                    {
                      title: "Product Code",
                      field: "productCode",
                    },
                    {
                      title: "Product Image",
                      field: "productImageArray",
                    },
                  ]}
                  title="Products"
                  options={{
                    search: false,
                  }}
                  data={dataArray}
                  // icons={tableIcons}
                  components={{
                    Pagination: PatchedPagination,
                  }}
                  style={{
                    margin: "20px",
                  }}
                />
              )}
            </div>
            {addIndividualProducts && (
              <div>
                <div className="addIndividualProductsSection">
                  <div>
                    <div>
                      <div>Select Category</div>
                      <select
                        onChange={(e) => subCategoryAssigner(e.target.value)}
                      >
                        <option>Select Category</option>
                        {categoryList.map((key, value) => (
                          <option>{key}</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <div>Select Subcategory</div>
                      <select
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            productSubCategory: e.target.value,
                          })
                        }
                      >
                        <option>Select Subcategory</option>
                        {subCategory.map((key, value) => (
                          <option>{key}</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <div>Enter Brand Name</div>
                      <div>
                        {" "}
                        <input
                          type="text"
                          placeholder="Brand Name"
                          name="productBrand"
                          onChange={(e) => inputFieldDataUpdater(e)}
                          maxlength={50}
                        />
                      </div>
                    </div>
                    <div>
                      <div>Enter Product Name</div>
                      <div>
                        {" "}
                        <input
                          type="text"
                          placeholder="Product Name"
                          name="productName"
                          onChange={(e) => inputFieldDataUpdater(e)}
                          maxlength={50}
                        />
                      </div>
                    </div>
                    <div>
                      <div>Unit of Measurement</div>
                      <div>
                        <input
                          type="text"
                          placeholder="Measurement Value"
                          name="measurmentValue"
                          onChange={(e) => inputFieldDataUpdater(e)}
                          maxlength={50}
                        />
                      </div>
                    </div>
                    <div>
                      <select
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            measurmentUnit: e.target.value,
                          })
                        }
                      >
                        <option>Select Unit</option>
                        {UNIT.map((data) => (
                          <option>{data}</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <div>Inventory Quantity</div>
                      <div>
                        <input
                          type="number"
                          placeholder="Inventory Quantity"
                          name="inventoryQty"
                          onChange={(e) => inputFieldDataUpdater(e)}
                          maxlength={50}
                        />
                      </div>
                    </div>
                    <div>
                      <div>Product Ean Code</div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter productEANCode"
                          name="productEANCode"
                          onChange={(e) => inputFieldDataUpdater(e)}
                          maxlength={50}
                        />
                      </div>
                    </div>
                    <div>
                      <div>Product Description</div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Product Description"
                          name="productDescription"
                          onChange={(e) => inputFieldDataUpdater(e)}
                          maxlength={50}
                        />
                      </div>
                    </div>
                    <div>
                      <div>Product Mrp</div>
                      <div>
                        <input
                          type="number"
                          placeholder="Enter Product Mrp"
                          name="productMrp"
                          onChange={(e) => inputFieldDataUpdater(e)}
                          maxlength={50}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div>Product Selling Price</div>
                      <div>
                        <input
                          type="number"
                          placeholder="Enter Selling Price "
                          name="productPrice"
                          onChange={(e) => inputFieldDataUpdater(e)}
                          maxlength={50}
                        />
                      </div>
                    </div>

                    <div>
                      <div>Purchase Rate</div>
                      <div>
                        <input
                          type="number"
                          placeholder="Enter purchase rate"
                          name="purchaseRate"
                          onChange={(e) => inputFieldDataUpdater(e)}
                          maxlength={50}
                        />
                      </div>
                    </div>

                    <div>
                      <div>Discount</div>
                      <div>
                        <input
                          type="number"
                          placeholder="Enter discount"
                          name="discount"
                          onChange={(e) => inputFieldDataUpdater(e)}
                          maxlength={50}
                        />
                      </div>
                    </div>

                    <div>
                      <div>Discount Type</div>
                      <div>
                        <select
                          name="discountType"
                          // onChange={(e) => {

                          //   setProductDetails({
                          //     ...productDetails,
                          //     discountType: e.target.value,
                          //   });
                          // }}
                          onChange={(e) => inputFieldDataUpdater(e)}
                        >
                          <option disabled={true}>Select discount Type</option>
                          <option value="flat">Flat</option>
                          <option value="percentage">Percentage</option>{" "}
                        </select>
                      </div>
                    </div>
                    <div>
                      <div>HSN Code</div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter HSN"
                          name="hsnCode"
                          onChange={(e) => inputFieldDataUpdater(e)}
                          maxlength={50}
                        />
                      </div>
                    </div>
                    <div>
                      <div>Tax(%)</div>
                      <select
                        name="taxId"
                        onChange={(e) => inputFieldDataUpdater(e)}
                      >
                        <option disabled>Select Tax</option>
                        {taxList.map((tax, index) => (
                          <option key={index} value={tax.taxId}>
                            {tax.taxName}
                          </option>
                        ))}
                      </select>
                      <select
                        // value={productDetails.taxIncExc}
                        name="taxIncExc"
                        onChange={(e) => inputFieldDataUpdater(e)}
                      >
                        <option value="" disabled>
                          Inclusive/Exclusive{" "}
                        </option>
                        <option value="inclusive">Inclusive</option>
                        <option value="exclusive">Exclusive</option>
                      </select>
                    </div>
                    <div>
                      <div>Product Code</div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Product Code"
                          name="productCode"
                          onChange={(e) => inputFieldDataUpdater(e)}
                          maxlength={50}
                        />
                      </div>
                    </div>
                    <div>
                      <div>Expiration Date</div>
                      <div>
                        <input
                          type="date"
                          placeholder="Enter Expiration Date"
                          name="expiryDate"
                          onChange={(e) => inputFieldDataUpdater(e)}
                          maxlength={50}
                        />
                      </div>
                    </div>
                    {(shopData.shopType === "Supermarket" ||
                      shopData.shopType === "Provisional Store" ||
                      shopData.shopType === "Bakery" ||
                      shopData.shopType === "Restaurant" ||
                      shopData.shopType === "Juice Shop" ||
                      shopData.shopType === "Tea Shop" ||
                      shopData.shopType === "Sweets and Snacks") && (
                      <div>
                        <div>
                          <input
                            type="radio"
                            value="Veg"
                            id="veg"
                            name="vegNonVeg"
                            onChange={(e) =>
                              setProductDetails({
                                ...productDetails,
                                vegNonVeg: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="veg">Veg</label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            value="Non-Veg"
                            id="nonVeg"
                            name="vegNonVeg"
                            onChange={(e) =>
                              setProductDetails({
                                ...productDetails,
                                vegNonVeg: e.target.value,
                              })
                            }
                          />
                          <label htmlFor="nonVeg">Non Veg</label>
                        </div>
                      </div>
                    )}
                    <div>
                      <input
                        type="file"
                        onChange={(e) => imageUploader(e)}
                        accept="image/png, image/jpeg"
                      />
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {productImages.map((data) => (
                        <div>
                          <div
                            onClick={() => removeSelectedProduct(data)}
                            style={{
                              textAlign: "right",
                              color: "red",
                              marginBottom: "4px",
                              cursor: "pointer",
                            }}
                          >
                            x
                          </div>
                          <div>
                            <img src={data} width={80} height={80} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="addButtonContainer">
                  <button onClick={() => addNewProduct("sdfds")}>Add</button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddProducts;
