// Import Depenedency
import React, { useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import "./shopCard.css";
import Modal from "react-bootstrap/Modal";
import Website from "./profile/website";
import Employee from "./profile/employee";
import Notification from "./profile/notification";
import Billing from "./billing";
import Appointment from "./appointment";
import Delivery from "./delivery";
import Loyalty from "./profile/loyalty";
import ContentReview from "./contentReview";
import ProductAndService from "./productAndService";
import Coupon from "./coupon";
import Invoice from "./invoice";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReachUs from "./profile/reachus";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PaymentIntegration from "./profile/payment";
import ProfileConfiguration from "./profile/profileConfiguration";
import PermIdentityRoundedIcon from "@material-ui/icons/PermIdentityRounded";
import PhoneIcon from "@material-ui/icons/Phone";
import moment from "moment";
import { Auth } from "aws-amplify";
import config from "../config";
import GlobalModal from "./globalModal";
import PackageUpdate from "./profile/packageUpdate";
import { makeStyles } from "@material-ui/core/styles";
import Profile from "./profile/profile";
import TodayIcon from "@material-ui/icons/Today";
import SendIcon from "@material-ui/icons/Send";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  tableHeader: {
    fontSize: "12px",
    textTransform: "uppercase",
    letterSpacing: "0.0625rem",
    lineHeight: "1.75rem",
  },
  tableCell: {
    fontSize: "12px",
  },
  tabTableCell: {
    padding: "0px",
  },
  tabTableHeader: {
    padding: "0px",
  },
});
export default function ShopCard() {
  //9457680567
  // 7793187914 - supermarket
  const classes = useStyles();
  const [userId10Digit, setUserId10Digit] = useState("7793187914");
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [notificationMessage, setNotificationMessage] = useState("");
  const [customDays, setCustomDays] = useState("300");
  const [modalState, setModalState] = useState(false); // Modal State
  const [RenderModalBody, setRenderModalBody] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);
  const [shopVerified, setShopVerified] = useState(false);

  const [shopProfile, setShopProfile] = useState({
    shopType: "",
    userId: "",
    shopId: "",
  });
  const [days, setDays] = useState(30);
  const [packages, setPackages] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [crmUsername, setCrmUsername] = useState("");
  const PROFILE_IMAGE =
    "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/images/offers/default_offer_image.png";

  const modalContent = (modalContent, key, defaultFields) => {
    setModalState(true);
    setRenderModalBody(key);
    setDefaultValue(defaultFields);
  };

  const closeModal = () => setModalState(false);

  useEffect(() => {
    getShopProfile();
  }, []);
  useEffect(() => {
    // To find number of days between two dates for graph
    let { startDate, endDate } = dateRange;
    if (Boolean(startDate) && Boolean(endDate)) {
      let differenceBetweenTwoDates = moment(dateRange.startDate).diff(
        moment(dateRange.endDate),
        "days"
      );

      setDays(Math.abs(differenceBetweenTwoDates));
    }
  }, [dateRange]);
  useEffect(() => {
    if (customDays !== "random") {
      // This is to get date by last no of days from today

      let endDate = moment().format("YYYY-MM-DD");

      let startDate = moment(endDate, "YYYY-MM-DD")
        .subtract(parseInt(customDays || 30), "days")
        .format("YYYY-MM-DD");

      setDateRange({
        startDate,
        endDate,
      });
    }
  }, [customDays]);
  const getShopProfile = async () => {
    let [crmProfile, isShopProfile] = await Promise.all([
      getCrmCurrentUser(),
      shopProfileAPI(),
    ]);

    if (isShopProfile.status) {
      let profile =
        typeof isShopProfile.data.profile == "undefined"
          ? {}
          : isShopProfile.data.profile || {};
      let validatePackage =
        typeof isShopProfile.data.package == "undefined"
          ? {}
          : isShopProfile.data.package || {};
      let validateEmployee =
        typeof isShopProfile.data.employee == "undefined"
          ? []
          : isShopProfile.data.employee || [];
      setEmployee(validateEmployee);
      setPackages(validatePackage); // package
      setShopProfile(profile); //shop profile
    }
    setCrmUsername(crmProfile);
    setShopVerified(true);
  };
  const shopProfileAPI = async () => {
    return await new Promise((resolve) => {
      Promise.all([
        fetch(`${config.ApiGateway}/shopProfileById`, {
          method: "POST",
          body: JSON.stringify({
            userId10Digit: userId10Digit,
          }),

          headers: {
            "Content-Type": "application/json",
          },
        }),
      ])
        .then(([res1]) => Promise.all([res1.json()]))
        .then(async ([data]) => {
          let responseData = await getDataFromExternalAPI(data);
          resolve(responseData);
        })
        .catch((err) => console.log("error getting mac address", err));
    });
  };
  const getCrmCurrentUser = async () => {
    return await new Promise(async (resolve) => {
      await Auth.currentAuthenticatedUser({
        bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then((data) => {
        let user = { email: data.email, ...data.attributes };

        return resolve(user.phone_number);
      });
    });
  };

  const getDataFromExternalAPI = async (data) => {
    if (data.status) {
      if (data.payloadResponseExceededLimit) {
        try {
          let uploadData = await (await fetch(data.data)).json();

          return {
            status: true,
            data: uploadData,
          };
        } catch (err) {
          return {
            status: false,
            data: [],
          };
        }
      } else {
        return {
          status: true,
          data: data.data || data,
        };
        // setTellcallingRecords(data.data || []);
      }

      // setTableLoading(false);
    }
  };

  const sendNotificationToShop = (e) => {
    e.preventDefault();
    setModalState(false);
    alert("Notification sent");
  };

  return (
    <>
      <div className="shopCardContainer">
        <div className="searchField">
          {/* <form onSubmit={getShopProfile}> */}
          <div className="wrapInput">
            <input
              onChange={(e) => setUserId10Digit(e.target.value)}
              value={userId10Digit}
              placeholder="Enter Shop Id"
            ></input>
            <button
              onClick={() => {
                setShopVerified(false);
                getShopProfile();
              }}
              className="searchButton"
            >
              Search
            </button>
          </div>
          {/* </form> */}

          {/* <span>Error Text</span> */}
        </div>
        {shopVerified && (
          <>
            <div className="shopCardRow">
              <div className="shopCardHeader">
                <button
                  onClick={() => setModalState(true)}
                  id="info"
                  className="actionButton"
                >
                  <SendIcon />
                  Send Notification
                </button>
              </div>
              <div className="customDateRangePickerContainer">
                <ul className="dateRangeUL">
                  <li
                    id="dateRange"
                    onClick={() => setCustomDays("30")}
                    className={
                      customDays == "30"
                        ? "dateRangeList active"
                        : "dateRangeList"
                    }
                  >
                    30
                  </li>
                  <li
                    id="dateRange"
                    onClick={() => setCustomDays("60")}
                    className={
                      customDays == "60"
                        ? "dateRangeList active"
                        : "dateRangeList"
                    }
                  >
                    60
                  </li>
                  <li
                    id="dateRange"
                    onClick={() => setCustomDays("90")}
                    className={
                      customDays == "90"
                        ? "dateRangeList active"
                        : "dateRangeList"
                    }
                  >
                    90
                  </li>
                  <li
                    id="dateRange"
                    onClick={() => setCustomDays("260")}
                    className={
                      customDays == "260"
                        ? "dateRangeList active"
                        : "dateRangeList"
                    }
                  >
                    260
                  </li>
                  <li
                    id="dateRange"
                    onClick={() => setCustomDays("360")}
                    className={
                      customDays == "360"
                        ? "dateRangeList active"
                        : "dateRangeList"
                    }
                  >
                    360
                  </li>
                  <li
                    id="dateRange"
                    className={
                      customDays == "random"
                        ? "dateRangeList active"
                        : "dateRangeList"
                    }
                  >
                    Custom
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ marginTop: "-30px" }} className="shopCardRow">
              <div className="shopCardColumn">
                <div
                  style={{ justifyContent: "flex-end" }}
                  className="fieldBox"
                >
                  <div id="row" className="fieldWrap">
                    <div className="wrapInputAndIcon">
                      <TodayIcon />
                      <input
                        onChange={(e) => {
                          e.persist();
                          let value = e.target.value;

                          if (Boolean(value)) {
                            setCustomDays("random");
                            setDateRange((prevState) => ({
                              ...prevState,
                              startDate: e.target.value,
                            }));
                          }
                        }}
                        max={dateRange.endDate}
                        value={dateRange.startDate}
                        type="date"
                      ></input>
                    </div>

                    <div className="wrapInputAndIcon">
                      <TodayIcon />
                      <input
                        onChange={(e) => {
                          e.persist();
                          let value = e.target.value;
                          if (Boolean(value)) {
                            setCustomDays("random");
                            setDateRange((prevState) => ({
                              ...prevState,
                              endDate: e.target.value,
                            }));
                          }
                        }}
                        value={dateRange.endDate}
                        min={dateRange.startDate}
                        type="date"
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "" }} className="shopCardRow">
              <div id="col25" className="shopCardColumn">
                <div className="shopCardBox">
                  <div className="ShopDetailsBox">
                    <div id="col25" className="shopCardColumn">
                      <div className="wrapProfileImage">
                        <img
                          src={
                            shopProfile.shopImageLocation ||
                            process.env.REACT_APP_DEFAULT_IMAGE
                          }
                        ></img>
                      </div>
                    </div>

                    <div id="col75" className="shopCardColumn">
                      <p>{shopProfile.shopName}</p>
                    </div>
                  </div>
                  <div id="miniBox" className="fieldBox">
                    <div className="fieldWrap">
                      <label>
                        <PermIdentityRoundedIcon />
                        Name
                      </label>
                      <input
                        className="flexInputView"
                        value={shopProfile.userName}
                      ></input>
                    </div>
                    <div className="fieldWrap">
                      <label>
                        <PhoneIcon />
                        Phone
                      </label>
                      <input
                        className="flexInputView"
                        value={
                          Boolean(shopProfile.userPhoneNumber) &&
                          shopProfile.userPhoneNumber.length > 10
                            ? shopProfile.userPhoneNumber.slice(-10)
                            : "NA"
                        }
                      ></input>
                    </div>
                    <div className="fieldWrap">
                      <label>
                        <GroupAddIcon />
                        Refferal Code
                      </label>
                      <input
                        className="flexInputView"
                        value={shopProfile.referalCode}
                      ></input>
                    </div>
                    <div className="fieldWrap">
                      <label>
                        <GetAppIcon />
                        Registered on
                      </label>
                      <input
                        className="flexInputView"
                        value={moment(shopProfile.userAddedDate).format(
                          "DD-MMMM-YYYY"
                        )}
                      ></input>
                    </div>
                    <div className="fieldWrap">
                      <label>
                        <AssignmentIndIcon />
                        Ghoshak Id
                      </label>
                      <input
                        className="flexInputView"
                        value={shopProfile.userId10Digit}
                      ></input>
                    </div>
                    <div className="fieldWrap">
                      <label>
                        <AssignmentIndIcon />
                        Ref Id
                      </label>
                      <input
                        className="flexInputView"
                        value={shopProfile.userId}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              <div id="col75" className="shopCardColumn">
                <div className="shopCardBox">
                  <div className="ShopCardTabs">
                    <Tabs
                      defaultActiveKey="details"
                      transition={false}
                      id="noanim-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="details" title="Details">
                        <Profile profile={shopProfile} />
                      </Tab>
                      <Tab eventKey="packageUpdate" title="Package Details">
                        <PackageUpdate
                          packages={packages}
                          profile={shopProfile}
                          crmUser={crmUsername}
                        />
                      </Tab>

                      <Tab eventKey="loyalty" title="Loyalty">
                        <Loyalty profile={shopProfile} crmUser={crmUsername} />
                      </Tab>

                      <Tab
                        eventKey="profileConfiguration"
                        title="Profile Configuration"
                      >
                        <ProfileConfiguration
                          classes={classes}
                          profile={shopProfile}
                        />
                      </Tab>
                      <Tab eventKey="Bank Account" title="Payment">
                        <PaymentIntegration
                          classes={classes}
                          profile={shopProfile}
                        />
                      </Tab>

                      <Tab eventKey="employee" title="Employee">
                        <Employee
                          classes={classes}
                          employee={employee}
                          profile={shopProfile}
                        />
                      </Tab>
                      <Tab eventKey="website" title="Website">
                        <Website
                          classes={classes}
                          isLoading={shopVerified}
                          data={shopProfile}
                        />
                      </Tab>
                      <Tab eventKey="notification" title="Notification">
                        <Notification classes={classes} profile={shopProfile} />
                      </Tab>
                      <Tab eventKey="reachUs" title="Reach Us">
                        <ReachUs classes={classes} profile={shopProfile} />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-space-10"></div>

            <Billing
              classes={classes}
              profile={shopProfile}
              crmUser={crmUsername}
              dateRange={dateRange}
              days={days}
            />

            <Appointment
              classes={classes}
              profile={shopProfile}
              crmUser={crmUsername}
              dateRange={dateRange}
              days={days}
            />
            <Delivery
              classes={classes}
              profile={shopProfile}
              crmUser={crmUsername}
              dateRange={dateRange}
              days={days}
            />
            <ContentReview
              profile={shopProfile}
              crmUser={crmUsername}
              dateRange={dateRange}
            />
            <ProductAndService profile={shopProfile} />
            <Invoice
              classes={classes}
              profile={shopProfile}
              crmUser={crmUsername}
              dateRange={dateRange}
            />
            <Coupon
              classes={classes}
              profile={shopProfile}
              crmUser={crmUsername}
              dateRange={dateRange}
            />
            <div className="vertical-space-40"></div>
          </>
        )}

        <GlobalModal
          openModal={modalState}
          closeModal={closeModal}
          typename={RenderModalBody}
          defaultValue={defaultValue}
        ></GlobalModal>

        <Modal
          className="modal-order-coupon"
          show={modalState}
          onHide={closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={sendNotificationToShop}>
              <div style={{ alignItems: "center" }} className="fieldBox">
                <div id="twoCol" className="fieldWrap">
                  <label id="required">Enter a Message</label>
                  <textarea
                    required
                    placeholder="Should not exceed more than 120 characters"
                    onChange={(e) => setNotificationMessage(e.target.value)}
                    className={"flexInputBox"}
                    value={notificationMessage}
                  ></textarea>
                </div>
                <div className="fieldWrap">
                  <button type="submit" id="success" className="actionButton">
                    <SendIcon />
                    Send
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <div className="vertical-space-30"></div>
      </div>
    </>
  );
}
