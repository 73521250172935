import React, { useEffect, useState } from "react";
import config from "../../config";
import DisplayTable from "./displayTable";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Limitation from "./limitation";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { B2B } from "./service";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
const useStyles = makeStyles({
  table: {
    boxShadow: "0 0 3px #e2e5e8",
    backgroundColor: "#ffffff",
    margin: "0 auto",
    border: "0 solid transparent",
    paddingBottom: 20,
  },
  container: {
    maxHeight: 200,
    overflowY: "scroll",
    overflowX: "auto",
    overflow: "hidden",
  },
  tableHead: {
    fontWeight: 500,
    backgroundColor: "#ffffff",
    color: "#333",
    letterSpacing: "1.5px",
    textTransform: "capitalize",
  },
  tableHeadBody: {
    backgroundColor: "#ffffff",
    borderBottomWidth: "1px",
  },
  root: {
    width: "100%",
  },
  tableCell: {
    fontWeight: 300,
    lineHeight: "20px",
    fontSize: "14px",
    color: "#212529",
  },
  tableIcon: {
    fontSize: "13px",

    fontWeight: 300,
    lineHeight: "20px",
  },
  tableBodyRow: {
    borderBottom: "1px solid #e9ecef",
  },
});
export default function Offer({ offerType, header }) {
  const classes = useStyles();
  const [dateRange, setDateRange] = useState({
    startDate: Limitation.defaultOfferRange[0], // start date
    endDate: Limitation.defaultOfferRange[1], // end date
  });
  const [offerStatus, setOfferStatus] = useState({});
  const [shopType, setShopType] = useState([]);
  const [offerRange, setOfferRange] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [allShopList, setAllShopList] = useState([]);
  const [tableDetails, setTableDetails] = useState({
    data: [],
    validKeys: [],
    name: "",
  });
  const handleClose = () => {
    setTableDetails({ data: [], validKeys: [], name: "" });
    setModalShow(false);
  }; // close modal
  useEffect(() => {
    // get offer data

    async function getData() {
      let getShops = await B2B();
      setAllShopList(getShops);
    }

    getData();
  }, []);
  useEffect(() => {
    async function loadOffer() {
      await getOffer();
    }
    loadOffer();
  }, [offerType]);
  const getOffer = () => {
    fetch(`${config.Elasticsearch.URL}/offers/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        // Projection Expression
        _source: {
          includes: [
            "offerOriginalPrice",
            "offerStartDate",
            "approvedStatus",
            "offerAddedDate",
            "offerEndDate",
            "review",
            "shopType",
            "offerType",
            "offerStatus",
            "shopId",
          ],
        },
        //Query Expression
        query: {
          constant_score: {
            filter: {
              bool: {
                must: [
                  {
                    range: {
                      offerAddedDate: {
                        gte: dateRange.startDate,
                        lte: dateRange.endDate, // Fetch offer within offer posted date range
                      },
                    },
                  },
                  {
                    match: {
                      offerType: offerType, // Get Only offer items
                    },
                  },
                ],
                must_not: [{ match: { shopPincode: "111111" } }], // Ignore pincode
              },
            },
          },
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let items = data.hits.hits.map((element) => element._source);

        let trackOfferStatus = {
          Expired: 0,
          Offers: 0,
        };
        let trackOfferByShop = {};
        let trackShopType = {};
        items.forEach((elements) => {
          // Offers Posted by Shop

          if (trackOfferByShop[elements.shopId] == undefined) {
            trackOfferByShop[elements.shopId] = {
              offers: 0,
              status: {},
            };
          }

          if (
            trackOfferByShop[elements.shopId]["status"][elements.offerStatus] ==
            undefined
          ) {
            trackOfferByShop[elements.shopId]["status"][
              elements.offerStatus
            ] = 0;
          }
          trackOfferByShop[elements.shopId].offers++; // Count total offer
          trackOfferByShop[elements.shopId]["status"][elements.offerStatus]++;

          // Track Offer Status
          if (trackOfferStatus[elements.offerStatus] == undefined) {
            trackOfferStatus[elements.offerStatus] = 0;
          }
          if (elements.offerStatus == "Live") {
            Limitation.todayDate < elements.offerEndDate
              ? trackOfferStatus[elements.offerStatus]++
              : trackOfferStatus["Expired"]++;

            // Track Shop Type
            if (
              new Date(Limitation.todayDate) < new Date(elements.offerEndDate)
            ) {
              if (trackShopType[elements.shopType] == undefined) {
                trackShopType[elements.shopType] = 0;
              }
              trackShopType[elements.shopType] += 1;
            }
          } else {
            trackOfferStatus[elements.offerStatus]++; // count status expect live & expired
          }

          trackOfferStatus["Offers"]++; // count all offers
        });
        // console.log(trackOfferByShop, "offers");

        let shopOffers = Object.entries(trackOfferByShop)
          .map(([key, elements]) => ({
            shopId: key,
            offers: elements.offers,
            status: elements.status,
          }))
          .sort((a, b) => b.offers - a.offers);

        let filterShopsByOfferRange = Limitation.offerDistribution.map(
          (elements) => {
            let filteredRange = shopOffers.filter(
              (items) =>
                elements.range[0] <= items.offers && // range[0] -  low range from limitation
                elements.range[1] >= items.offers //range[1] - high range from limitation
            );
            return {
              name: elements.name,
              count: filteredRange.length,
              shops: filteredRange,
            };
          }
        );

        setShopType((prevstate) =>
          Object.keys(trackShopType)
            .map((elements) => ({
              segment: elements,
              offers: trackShopType[elements],
            }))
            .sort((a, b) => b.offers - a.offers)
        );
        setOfferStatus(trackOfferStatus);
        setOfferRange(filterShopsByOfferRange);

        // shopOffers.map(elemets)
      })
      .catch((err) => console.log("err"));
  };
  const filterShops = ({ name, count, shops }) => {
    let mergeShops = shops.map((items) => {
      let shopDetails = allShopList.find(
        (elements) => elements.userId === items.shopId
      );
      let appendObject = {};
      console.log(typeof shopDetails, shopDetails);
      if (typeof shopDetails != "undefined") {
        appendObject = Object.assign({}, items, shopDetails); // concat shop details & offer details
      } else {
        appendObject = items;
      }
      return appendObject;
    });

    setTableDetails({
      data: mergeShops,
      validKeys: [
        "userId10Digit",
        "shopName",
        "userName",
        "shopType",
        "shopAddress",
        "userPincode",
        "userAddedDate",
        "status",
        "offers",
        "status",
      ],
      name: name,
    });
    setModalShow(true);
  };
  return (
    <>
      <div className="titleHeader">
        <h4>{header}</h4>
      </div>
      <div className="flexRow">
        <div className="labelWrapper">
          <h5>Status</h5>
        </div>

        {Object.keys(offerStatus).map((items) => (
          <>
            <div id="offer" className="flexColumn">
              <div className="flexCard">
                <div className="flexIcon">
                  <LocalOfferIcon />
                </div>
                <div className="flexContent">
                  <h5> {items}</h5>
                  <h4>{offerStatus[items]}</h4>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      <div className="vertical-space-20"></div>
      <div className="flexRow">
        <div className="labelWrapper">
          <h5>Segment</h5>
        </div>

        <div className="flexColumn">
          <p> {`${header}`} Distribution by segment </p>
          <div className={classes.root}>
            <TableContainer className={classes.container}>
              <Table size="small" stickyHeader className={classes.table}>
                <TableHead className={classes.tableHeadBody}>
                  <TableRow>
                    <TableCell className={classes.tableHead}>Segment</TableCell>
                    <TableCell className={classes.tableHead}>Offers</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shopType.map(({ segment, offers }) => (
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        {segment}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {offers}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <div className="flexColumn">
          <p> Top 5 Segment </p>
          <ResponsiveContainer aspect={1.6}>
            <BarChart
              width={500}
              height={300}
              data={shopType.slice(0, 5)}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={"segment"}
                interval={0}
                angle={-45}
                textAnchor="end"
                height={100}
              />
              <YAxis
                label={{
                  value: "Offers",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              <Bar dataKey={"offers"} fill="#8884d8">
                <LabelList dataKey={"offers"} position="top" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="vertical-space-10"></div>
      <div className="flexRow">
        <div className="labelWrapper">
          <h5>Offer Distribution</h5>
        </div>

        <div id="offerrange" className="flexColumn">
          <p> {`${header}`} posted by shops </p>
          <div className={classes.root}>
            <TableContainer
              style={{ maxHeight: "450px" }}
              className={classes.container}
            >
              <Table size="small" stickyHeader className={classes.table}>
                <TableHead className={classes.tableHeadBody}>
                  <TableRow>
                    <TableCell className={classes.tableHead}>Range</TableCell>
                    <TableCell className={classes.tableHead}>Shops</TableCell>
                    <TableCell className={classes.tableHead}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {offerRange.map(({ name, count, shops }) => (
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        {name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {count}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button
                          onClick={() => filterShops({ name, count, shops })}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <Modal className="viewCartDetails" show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <DisplayTable
            data={tableDetails.data}
            validKeys={tableDetails.validKeys}
            title={tableDetails.name}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
