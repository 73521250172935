import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Row, Col, Container, Button } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import config from "../config";
import Form from "react-bootstrap/Form";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class UserAdded extends Component {
  constructor(props) {
    super(props);
    var d = new Date();
    d.setDate(d.getDate() - 1);

    this.state = {
      loadPage: false,
      packageObject: {},
      showUserList: false,
      userArray: [],
      page: 0,
      segments: {},
      rowsPerPage: 20,

      exportData: [],
      headers: [],
      userType: {},
      userDetails: [],
      hideCell: true,
      userAddedDate: d.toISOString().substring(0, 10), // Today date
      setUserType: "Shop",
      CustomerList: [],
      ShopList: [],
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.csvLink = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.getUser = this.getUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  getUser = () => {
    Promise.all([
      fetch(`${config.ApiGateway}/userAddedByDate`, {
        method: "POST",
        body: JSON.stringify({ userAddedDate: this.state.userAddedDate }), // date value
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        return this.setState({
          userType: data.userType, //count of shop & customer
          userDetails: data.shops, // shop
          showUserList: true, // loading state
          ShopList: data.shops, //  shop details
          segments: data.segments, // shop segments
          CustomerList: data.customers, // customer details
        });
      })
      .catch((err) => console.log(err));
  };
  onChange = (e) => {
    let value = e.target.value;
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.getUser();
      }
    );
  };
  export() {
    let tempArr = [];

    const headers = [
      { label: "User  ID", key: "userId10Digit" },
      { label: "Pincode", key: "userPincode" },
      { label: "Name", key: "userName" },
      { label: "Phone Number", key: "userPhoneNumber" },
      { label: "Place", key: "userArea" },
      { label: "Referral Code", key: "referralCode" },
      { label: "Reffered By", key: "referredBy" },
      { label: "Shop Name", key: "shopName" },
      { label: "Shop Address", key: "shopAddress" },
      { label: "Shop Type", key: "shopType" },
    ];

    this.setState(
      {
        headers: headers,
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  handleChange = (e) => {
    let usertype = e.target.value;

    console.log(usertype);

    this.setState({
      setUserType: usertype,
      userDetails:
        usertype === "Customer" ? this.state.CustomerList : this.state.ShopList,
    });
  };

  componentDidMount = async () => {
    // initial class render and calls function
    await this.getUser();
  };

  render() {
    const { classes } = this.props;

    return (
      <Container>
        <>
          <div
            style={{
              padding: "1rem 0 3.5rem 0",

              position: "relative",

              zIndex: "100",
            }}
          >
            <h2>User Downloads</h2>
          </div>

          <Row>
            <Col sm={{ span: 8, offset: 3 }}>
              <Form onSubmit={this.getShop}>
                <Row style={{ marginTop: 10 + "%" }}>
                  <Col md={{ span: 7, offset: 2 }}>
                    <Form.Label>Select Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="userAddedDate"
                      onChange={this.onChange}
                      value={this.state.userAddedDate}
                    ></Form.Control>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          {this.state.showUserList && (
            <>
              <Row className="CustomCard">
                <div
                  style={{
                    width: "100%",

                    borderBottom: "1px solid rgba(0, 0, 0, 0.07)",
                    marginBottom: "20px",
                    borderRadius: "4px 4px 0 0",
                    backgroundColor: "#f4f5fb",
                  }}
                >
                  <h4 style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                    Downloads
                  </h4>
                </div>
                <Col>
                  <h6 className="userAddedSub">Shops</h6>
                  <h5>
                    {this.state.userType.Shop === undefined
                      ? 0
                      : this.state.userType.Shop}
                  </h5>
                </Col>
                <Col>
                  <h6 className="userAddedSub">Customers</h6>
                  <h5 className="userAddedValue">
                    {this.state.userType.Customer === undefined
                      ? 0
                      : this.state.userType.Customer}
                  </h5>
                </Col>
                <Col>
                  <h6 className="userAddedSub">Total Downloads</h6>
                  <h5 className="userAddedValue">
                    {parseInt(
                      this.state.userType.Customer === undefined
                        ? 0
                        : this.state.userType.Customer
                    ) +
                      parseInt(
                        this.state.userType.Shop === undefined
                          ? 0
                          : this.state.userType.Shop
                      )}
                  </h5>
                </Col>
              </Row>

              <Row
                style={{
                  display: "flex",
                  boxShadow: "0px 0px 13px 0px rgba(26, 52, 95, 0.08)",
                  marginTop: "60px",
                  border: "1px solid rgba(0, 0, 0, 0.09)",
                }}
              >
                <div
                  style={{
                    width: "100%",

                    borderBottom: "1px solid rgba(0, 0, 0, 0.07)",
                    marginBottom: "20px",
                    borderRadius: "4px 4px 0 0",
                    backgroundColor: "#f4f5fb",
                  }}
                >
                  <h4 style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                    Segments
                  </h4>
                </div>
                {Object.entries(this.state.segments).map(([key, value]) => (
                  <>
                    <Col>
                      <h6 className="userAddedSub">{key}</h6>
                      <h5 className="userAddedValue">{value}</h5>
                    </Col>
                  </>
                ))}
              </Row>

              <Row
                style={{
                  display: "flex",
                  boxShadow: "0px 0px 13px 0px rgba(26, 52, 95, 0.08)",
                  marginTop: "60px",
                  border: "1px solid rgba(0, 0, 0, 0.09)",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#f4f5fb",
                    width: 100 + "%",
                    margin: 1 + "%",
                  }}
                >
                  <h4> List of users</h4>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                >
                  <Col sm={4}>
                    <Button
                      onClick={() => this.export()}
                      style={{ marginTop: "20px" }}
                    >
                      Download
                    </Button>
                    <CSVLink
                      enclosingCharacter={`'`}
                      data={this.state.userDetails} // array value
                      headers={this.state.headers} // Excel column header
                      ref={this.csvLink} //
                      target="_blank"
                    />
                  </Col>
                  <Col sm={4}>
                    <Form.Label>Filter By </Form.Label>
                    <Form.Control
                      as="select"
                      name="setUserType"
                      onChange={this.handleChange}
                      value={this.state.setUserType}
                    >
                      <option value="Shop">Shops</option>
                      <option value="Customer">Customer</option>
                    </Form.Control>
                  </Col>
                </div>

                <Col>
                  <Table
                    style={{
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid",
                    }}
                    className={classes.table}
                    size="medium"
                  >
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            S NO
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Name
                          </TableCell>
                          <TableCell
                            style={{
                              display:
                                this.state.setUserType === "Shop" ? "" : "none",
                            }}
                            className={classes.tableCell}
                          >
                            Shop Name
                          </TableCell>
                          <TableCell
                            style={{
                              display:
                                this.state.setUserType === "Shop" ? "" : "none",
                            }}
                            className={classes.tableCell}
                          >
                            Shop Type
                          </TableCell>
                          <TableCell
                            style={{
                              display:
                                this.state.setUserType === "Shop" ? "" : "none",
                            }}
                            className={classes.tableCell}
                          >
                            Shop Address
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Address
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Phone Number
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            User ID
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Pincode
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Area
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Referral Code
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Referred By
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {this.state.userDetails
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((items, index) => (
                            <TableRow>
                              <TableCell className={classes.tableCell}>
                                {index + 1}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.userName}
                              </TableCell>
                              <TableCell
                                style={{
                                  display:
                                    this.state.setUserType === "Shop"
                                      ? ""
                                      : "none",
                                }}
                                className={classes.tableCell}
                              >
                                {items.shopName}
                              </TableCell>
                              <TableCell
                                style={{
                                  display:
                                    this.state.setUserType === "Shop"
                                      ? ""
                                      : "none",
                                }}
                                className={classes.tableCell}
                              >
                                {items.shopType}
                              </TableCell>
                              <TableCell
                                style={{
                                  display:
                                    this.state.setUserType === "Shop"
                                      ? ""
                                      : "none",
                                }}
                                className={classes.tableCell}
                              >
                                {items.shopAddress}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.userAddress}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.userPhoneNumber}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.userId10Digit}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.userPincode}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.userArea}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.referralCode}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.referredBy}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </>
                  </Table>
                  <TablePagination
                    component="div"
                    count={this.state.userDetails.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      </Container>
    );
  }
}
UserAdded.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserAdded);
