import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
export default class CustomerPincodeStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showData: false,
      statistics: [],
      pincodeUsers: [],
      showTable: false,
    };
    this.handleFunction = this.handleFunction.bind(this);
    this.fetchDetails = this.fetchDetails.bind(this);
  }
  async componentDidMount() {
    await this.handleFunction();
  }
  handleFunction() {
    var zeroCustomer = [];
    var lessThanThousand = [];
    var lessThantwoThousandFiveHundred = [];
    var lessThanFiveThousand = [];
    var moreThanFiveThousand = [];
    var temparray = [];
    var i = 0;
    var j = 0;
    var k = 0;
    var l = 0;
    var m = 0;
    this.props.pincodeStats.forEach((items) => {
      if (items.totalCustomers === 0) {
        zeroCustomer.push({
          userPincode: items.pincode,
          users: items.totalCustomers,
        });
        i++;
      } else if (items.totalCustomers >= 1 && items.totalCustomers <= 999) {
        lessThanThousand.push({
          userPincode: items.pincode,
          users: items.totalCustomers,
        });
        j++;
      } else if (items.totalCustomers >= 1000 && items.totalCustomers <= 2499) {
        lessThantwoThousandFiveHundred.push({
          userPincode: items.pincode,
          users: items.totalCustomers,
        });
        k++;
      } else if (items.totalCustomers >= 2500 && items.totalCustomers <= 4999) {
        lessThanFiveThousand.push({
          userPincode: items.pincode,
          users: items.totalCustomers,
        });
        l++;
      } else if (items.totalCustomers >= 5000) {
        moreThanFiveThousand.push({
          userPincode: items.pincode,
          users: items.totalCustomers,
        });
        m++;
      }
    });
    temparray.push(
      { zeroCustomer: zeroCustomer, count: i },
      { lessThanThousand: lessThanThousand, count: j },
      {
        lessThantwoThousandFiveHundred: lessThantwoThousandFiveHundred,
        count: k,
      },
      { lessThanFiveThousand: lessThanFiveThousand, count: l },
      { moreThanFiveThousand: moreThanFiveThousand, count: m }
    );
    return this.setState({
      showData: true,
      statistics: temparray,
    });
  }
  fetchDetails(items) {
    this.setState({
      pincodeUsers: items,
      showTable: true,
    });
  }
  render() {
    var j = 1;
    return (
      <>
        {this.state.showData && (
          <>
            <Row className="division">
              <Col>
                <div
                  style={{
                    backgroundColor: "#fafafa",
                    width: 100 + "%",
                    margin: 1 + "%",
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>
                    {" "}
                    OVERVIEW OF CUSTOMER PINCODE
                  </h3>
                </div>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid",
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">CONTENT</TableCell>
                      <TableCell align="center">TOTAL PINCODES</TableCell>
                      <TableCell align="center">
                        VIEW CUSTOMER DETAILS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">
                        PINCODE WITH ZERO CUSTOMER
                      </TableCell>
                      <TableCell align="center">
                        {this.state.statistics[0].count}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.fetchDetails(
                                this.state.statistics[0].zeroCustomer
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">2</TableCell>
                      <TableCell align="center">
                        PINCODES WITH 1-999 CUSTOMERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.statistics[1].count}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.fetchDetails(
                                this.state.statistics[1].lessThanThousand
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">3</TableCell>
                      <TableCell align="center">
                        PINCODE WITH 1000-2499 CUSTOMERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.statistics[2].count}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.fetchDetails(
                                this.state.statistics[2]
                                  .lessThantwoThousandFiveHundred
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">4</TableCell>
                      <TableCell align="center">
                        PINCODE WITH 2500-4999 CUSTOMERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.statistics[3].count}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.fetchDetails(
                                this.state.statistics[3].lessThanFiveThousand
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">5</TableCell>
                      <TableCell align="center">
                        PINCODE WITH MORE THAN 5000 CUSTOMERS
                      </TableCell>

                      <TableCell align="center">
                        {this.state.statistics[4].count}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.fetchDetails(
                                this.state.statistics[4].moreThanFiveThousand
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Col>
            </Row>
            {this.state.showTable && (
              <>
                <Row className="division">
                  <Col>
                    <div
                      style={{
                        backgroundColor: "#fafafa",
                        width: 100 + "%",
                        margin: 1 + "%",
                      }}
                    >
                      <h3 style={{ textAlign: "center" }}>
                        {" "}
                        OVERVIEW OF PINCODE
                      </h3>
                    </div>
                    <Table
                      style={{
                        width: 100 + "%",
                        border: 0.5 + "px",
                        borderColor: "#ced4da",
                        borderStyle: "solid",
                      }}
                      size="medium"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S NO</TableCell>
                          <TableCell align="center">PINCODES</TableCell>
                          <TableCell align="center">NO OF CUSTOMERS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.pincodeUsers.map((rows) => (
                          <TableRow key={rows.userPincode}>
                            <TableCell align="center">{j++}</TableCell>
                            <TableCell align="center">
                              {rows.userPincode}
                            </TableCell>
                            <TableCell align="center">{rows.users}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </>
    );
  }
}
