import React, { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import S3 from "react-aws-s3";
import uuid from "uuid/dist/v4";
import config from "../../config";
import { FormControlLabel, Switch } from "@material-ui/core";
import { uploadImageToS3 } from "../../service";
const AboutUs = (props) => {
  const ref = useRef();
  const [header, setHeader] = useState("");
  const [description, setDescription] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [websiteTemplate, setWebsiteTemplate] = useState();
  const [active, setActive] = useState(true);
  const [templateId, setTemplateId] = useState("about_001");
  const [templateName, setTemplateName] = useState("Classic Landing");
  const fetchDetails = async () => {
    await fetchUserDetails(props.shopId).then((data) => {
      var shopData = data[0];
      if (shopData.websiteTemplate) {
        setWebsiteTemplate(shopData.websiteTemplate);
        var websiteData = shopData.websiteTemplate;
        if (websiteData.about) {
          setActive(
            websiteData.about.active ? websiteData.about.active : active
          );
          setTemplateId(
            websiteData.about.templateId
              ? websiteData.about.templateId
              : templateId
          );
          setTemplateName(
            websiteData.about.templateName
              ? websiteData.about.templateName
              : templateName
          );
          var pageProps = websiteData.about.pageProps;
          setHeader(pageProps.heading);
          setDescription(pageProps.description);
          setImageUrl(pageProps.imageUrl);
          setImageName(pageProps.imgName);
        }
      }
    });
  };
  useEffect(() => {
    fetchDetails();
  }, [props]);

  const handleSave = async () => {
    let dataObj = {};
    if (websiteTemplate) {
      dataObj = {
        ...websiteTemplate,
      };
      if (websiteTemplate.about) {
        dataObj = {
          ...dataObj,
          about: {
            ...websiteTemplate.about,
            active: active,
            templateId: templateId,
            templateName: templateName,
            pageProps: {
              description: description,
              heading: header,
              imageUrl: imageUrl,
              imgName: imageName,
              imageUri: imageUrl,
            },
          },
        };
      } else {
        dataObj = {
          ...dataObj,
          about: {
            active: active,
            templateId: templateId,
            templateName: templateName,
            pageProps: {
              description: description,
              heading: header,
              imageUrl: imageUrl,
              imgName: imageName,
              imageUri: imageUrl,
            },
          },
        };
      }
    } else {
      dataObj = {
        colorTheme: "default",
        order: [
          "aboutUs",
          "about",
          "onlineProduct",
          "ourServices",
          "ourProjects",
          "ourBrands",
          "ourClients",
          "ourGallery",
          "clientTestimonials",
          "videoGallery",
          "socialMedia",
          "ourPartner",
          "ourTeam",
        ],
        about: {
          pageProps: {
            description: description,
            heading: header,
            imageUrl: imageUrl,
            imgName: imageName,
            imageUri: imageUrl,
          },
          active: active,
          templateId: templateId,
          templateName: templateName,
        },
      };
    }
    await contentupload(dataObj, props.shopId).then((data) => {
      fetchDetails();
    });
  };

  const handleDelete = async (key) => {
    var dataObj = {
      ...websiteTemplate,
      about: {
        ...websiteTemplate.about,
        pageProps: {
          ...websiteTemplate.about.pageProps,
          imageUrl: "",
          imageUri: "",
          imgName: "",
        },
      },
    };
    await contentupload(dataObj, props.shopId).then(async (data) => {
      await fetchDetails();
    });
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h3>About Us</h3>
      <strong>
        <label>Show About Us</label>
      </strong>{" "}
      <FormControlLabel
        control={
          <Switch
            checked={active}
            onChange={() => setActive(!active)}
            color="primary"
          />
        }
      ></FormControlLabel>
      <div>
        <strong>
          <label>Add Header</label>
        </strong>
        <br />
        <textarea
          value={header}
          placeholder="Enter About Us Header"
          onChange={(e) => setHeader(e.target.value)}
          rows="3"
          cols="40"
        />
      </div>
      <div>
        <strong>
          <label>Add Description</label>
        </strong>
        <br />
        <textarea
          value={description}
          placeholder="Enter About Us Description"
          onChange={(e) => setDescription(e.target.value)}
          rows="6"
          cols="40"
        />
      </div>
      <div>
        <strong>
          <label>Upload Image</label>
        </strong>
        <br />
        <input
          ref={ref}
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          onChange={async (e) => {
            setLoading(true);
            console.log("Image File", e.target.files[0]);
            var data = await handleImageUpload(e);
            var imageLink = `https://ghoshak-website-builder.s3-ap-south-1.amazonaws.com/${data.key}`;
            setImageUrl(imageLink);
            setImageName(data.key);
            setLoading(false);
          }}
        />
      </div>
      <div style={{ marginLeft: "35%" }}>
        {imageUrl && (
          <div
            style={{
              margin: "10px",
              width: "40%",
              boxShadow: "5px 5px 20px 1px #d4d4d4",
              borderRadius: "5px",
            }}
          >
            <img src={imageUrl} alt="Image" width="100" height="100" />
            <Button
              variant="outline-danger"
              size="sm"
              style={{ float: "right", margin: "10px" }}
              onClick={() => handleDelete()}
            >
              Delete
            </Button>
          </div>
        )}
      </div>
      <div>
        <strong>
          <label>Choose Template</label>
        </strong>
        <br />
        <select
          onChange={(e) => {
            setTemplateId(e.target.value);
            setTemplateName(e.target.name);
          }}
          value={templateId}
          style={{
            width: "300px",
          }}
        >
          <option value="" disabled>
            Select a template
          </option>
          <option value="about_001" name="Classic Landing">
            Classic Landing
          </option>
          <option value="about_002" name="Parallax scroll">
            Parallax scroll
          </option>
          <option value="about_003" name="Highlighter">
            Highlighter
          </option>
          <option value="about_004" name="Highlighter">
            Highlighter
          </option>
        </select>{" "}
      </div>
      <div style={{ margin: "20px" }}>
        <Button disabled={loading} onClick={() => handleSave()}>
          Save
        </Button>
      </div>
    </div>
  );
};

const fetchUserDetails = async (shopId) => {
  if (shopId === "") return alert("Enter valid Shop Id");
  return await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
      method: "POST",
      body: JSON.stringify({
        userId10Digit: shopId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data[0].websiteTemplate);
      return data;
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("Something went wrong, please try again");
    });
};

const contentupload = async (obj, userId) => {
  await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainContentUpload`, {
      method: "POST",
      body: JSON.stringify({
        obj: obj,
        userId: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data);
      alert("uploaded success");
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("error while uploading data. Please try again");
    });
};

const handleImageUpload = async (image) => {
  const configSettings = {
    bucketName: "ghoshak-website-builder",
    region: "ap-south-1",
    accessKeyId: config.AccessKeyId,
    secretAccessKey: config.SecretAccessKey,
  };

  let data = await uploadImageToS3(image, configSettings)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("S3 Error", err);
    });
  return data;
};

export default AboutUs;
