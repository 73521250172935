import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import MaterialTable from "material-table";
import config from "../config";

const ListShops = () => {
  const [shopList, setShopList] = useState([]);
  var fetchShopList = async () => {
    try {
      var res = await fetch(`${config.ApiGateway}/fetchDeliveryAddedShop`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userType: "Shop",
        }),
      });
      res = await res.json();
      if (res.code === "ERROR") return alert(res.message);
      setShopList(res.data);
    } catch (err) {
      alert(err);
    }
  };
  useEffect(() => {
    fetchShopList();
  }, []);
  const unlinkDeliveryPartner = async (shopId) => {
    try {
      var res = await fetch(`${config.ApiGateway}/unlinkDeliveryPartner`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ shopId: shopId }),
      });
      res = await res.json();
      if (res.code === "ERROR") return alert(res.message);
      fetchShopList();
      alert(res.message);
    } catch (err) {
      alert(err);
    }
  };
  return (
    <Container>
      <div style={{ margin: "20px" }}>
        <h3>Delivery Partner Linked Shops</h3>
        <div>
          <MaterialTable
            data={shopList}
            columns={[
              {
                title: "Shop Id",
                field: "userId10Digit",
              },
              {
                title: "Shop Name",
                field: "shopName",
              },
              {
                title: "Mobile Number",
                field: "userPhoneNumber",
              },
              {
                title: "Address",
                field: "shopAddress",
              },
              {
                title: "Delivery Partners",
                field: "deliveryPartners",
                // render: (data) => {
                //   // if (data.deliveryPartners.length > 1) {
                //   //   let
                //   // }
                //   var jsx = data.deliveryPartners.map((data) => <>{data}, </>);
                //   return jsx;
                // },
              },
              {
                title: "Action",
                render: (data) => {
                  return (
                    <Button onClick={() => unlinkDeliveryPartner(data.userId)}>
                      Unlink Delivery Partner
                    </Button>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </Container>
  );
};

export default ListShops;
