import React, { Component } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { Col } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import config from "../../config";
export default class MerchantSegment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showGraph: false,
      stats: []
    };
  }
  componentDidMount() {
    var count = 0;
    var segmentCount = [];
    fetch(`${config.json.URL}/fields.json`)
      .then(res => res.json())

      .then(data => {
        data.shopTypes.slice(1).map(elements => {
          count = 0;
          this.props.SHOPID.map(items => {
            if (elements.value === items.shopType) {
              count++;
            }
          });

          segmentCount.push({
            name: elements.value,
            totalCount: count
          });
        });

        this.setState({
          showGraph: true,
          stats: segmentCount
        });
      })
      .catch(err => console.log("the error", err));
  }
  render() {
    return (
      <>
        <Typography variant="h2" style={{ textAlign: "center" }} gutterBottom>
          {this.props.title}
        </Typography>
        {this.state.showGraph && (
          <>
            <Col md={{ span: 6, offset: 3 }} className="division">
              <h1 style={{ textAlign: "center" }}>TOTAL MERCHANT BY SEGMENT</h1>
              <>
                <BarChart
                  width={600}
                  height={300}
                  data={this.state.stats}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    interval={0}
                    angle={-45}
                    textAnchor="end"
                    height={100}
                  />
                  <YAxis
                    label={{
                      value: "TOTAL MERCHANT",
                      angle: -90,
                      position: "insideLeft"
                    }}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="totalCount" fill="#8884d8" />
                </BarChart>
              </>
            </Col>

            <Col md={{ span: 6, offset: 3 }}>
              <Table
                className="division"
                style={{
                  width: 100 + "%",
                  border: 0.5 + "px",
                  borderColor: "#ced4da",
                  borderStyle: "solid"
                }}
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S NO</TableCell>
                    <TableCell align="center">SHOP TYPE</TableCell>
                    <TableCell align="center">TOTAL SHOPS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.stats.map((row, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>

                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.totalCount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Col>
          </>
        )}
      </>
    );
  }
}
