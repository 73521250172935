import React, { useEffect, useState, useCallback, createRef } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import MaterialTable from "material-table";
import config from "../config";
import { CSVLink } from "react-csv";
import { Auth } from "aws-amplify";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
    overflowY: "scroll",

    padding: "10px",
    border: "1px solid #dedede",
  },
  tableBodyCellStyle: {
    fontWeight: 300,

    color: "#212529",
  },
  container: {
    maxHeight: 700,
    overflowY: "scroll",
    overflowX: "auto",
    overflow: "hidden",
  },
  root: {
    width: "100%",
  },
  tableCell: {
    padding: "2px",
    fontSize: "10px",
    margin: 0,
    width: "2px",
  },
});

export default function Statement() {
  let initialState = {
    updatedOn: new Date().toISOString().substring(0, 10),
    verificationStatus: "",
    remarkForPartner: "",
    remarkForGhoshak: "",
    approvalStatus: "",
    paymentDate: "",
    amountPaid: "",
  };
  const classes = useStyles();
  const [statementData, setStatementData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({
    paymentVersion: [],
  });
  const [paymentMonthDropdown, setPaymentMonthDropdown] = useState([]);
  const [state, setState] = useState(initialState);
  const [updatedValue, setUpdatedValue] = useState(0);
  const csvLink = createRef();
  const [downloadData, setDownloadData] = useState([]);
  const [active, setActive] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = (input) => {
    setState((prevState) => ({
      ...prevState,

      verificationStatus: input.verificationStatus,
      remarkForPartner: input.remarkForPartner,
      remarkForGhoshak: input.remarkForGhoshak,
      paymentDate: input.paymentDate,
      approvalStatus: input.approvalStatus,
      userId: input.userId,
      addAmount: "",
    }));
    setUpdatedValue(parseFloat(input.amountPaid));
    setModalData(input);
    setModalShow(true);
  };
  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [downloadData]);
  useEffect(() => {
    async function loadData() {
      await Auth.currentAuthenticatedUser().then((data) => {
        onChange("paidUpdatedBy", data.attributes.phone_number);
      });
      await getData();
      let todayDate = new Date().toISOString().substring(0, 10);
      let dropdownStartDate = moment("2019-12-01");
      let dropdownEndDate = moment(todayDate);
      let monthYearRanges = [];

      while (
        dropdownEndDate > dropdownStartDate ||
        dropdownStartDate.format("M") === dropdownEndDate.format("M")
      ) {
        monthYearRanges.push(
          <option value={dropdownStartDate.format("YYYY-MM")}>
            {dropdownStartDate.format("MMM YYYY")}
          </option>
        );
        dropdownStartDate.add(1, "month");
      }
      monthYearRanges.sort((a, b) => b - a);
      setPaymentMonthDropdown(monthYearRanges);
    }

    loadData();
  }, []);
  const getData = () => {
    // Get data from Backend
    Promise.all([
      fetch(`${config.ApiGateway}/partnerStatement`, {
        method: "POST",

        body: JSON.stringify({
          template: "getStatementData", // To get all Statement data
          statementStatus: "paid",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setStatementData(data);
      })
      .catch((err) => console.log(err));
  };

  const onChange = (name, value) => {
    // onchange event for form event
    let objectName = name;
    let objectValue = value;
    setState((prevState) => ({ ...prevState, [objectName]: objectValue }));
  };
  const updatePayment = (e) => {
    e.preventDefault();
    let input = state;

    input.remarkForPartner =
      input.remarkForPartner == "" ? "NA" : input.remarkForPartner;
    input.remarkForGhoshak =
      input.remarkForGhoshak == "" ? "NA" : input.remarkForGhoshak;
    input.notes = input.notes == "" ? "NA" : input.notes;

    Object.keys(input).forEach((key) => input[key] == "" && delete input[key]); // Removes Empty String

    input.addAmount =
      typeof input.addAmount == "string" ? parseInt(input.addAmount) : 0;

    if (input.addAmount > 0) {
      input.amountPaid =
        parseInt(input.addAmount) + parseFloat(modalData.amountPaid);

      let concatPaymentVersion = modalData.paymentVersion;
      concatPaymentVersion.push({
        date: new Date().toISOString().substring(0, 10),

        amount: input.addAmount,
        paidBy: input.paidUpdatedBy,
      });
      input.paymentVersion = concatPaymentVersion;
    }
    input.template = "edit";
    Promise.all([
      fetch(`${config.ApiGateway}/partnerStatement`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        handleClose();
        alert("Statement Updated");
        setState(initialState);
        getData();
      })
      .catch((err) => console.log(err));
  };
  const exportAsExcel = (input) => {
    setDownloadData([input]);
    setActive(true);
  };

  return (
    <Container>
      <div className="orderHeader">
        <h2 className="orderHeaderText"> Statement</h2>
      </div>
      <Row>
        <Col sm="12">
          <MaterialTable
            title={"Statement"}
            columns={[
              {
                title: "Period",
                field: "cycleStartDate",
                render: (rowData) => {
                  return `${moment(rowData.cycleStartDate).format(
                    "MMM Do YYYY"
                  )} - ${moment(rowData.cycleEndDate).format("MMM Do YYYY")}`;
                },
              },
              { title: "Criteria", field: "criteria" },
              { title: "Shop Name", field: "shopName" },
              { title: "Company Name", field: "companyName" },
              { title: "Ref Code", field: "referralCode" },
              { title: "Amount", field: "amountPaid" },
              { title: "verification", field: "verificationStatus" },
              { title: "Remark (Ghoshak)", field: "remarkForGhoshak" },
              {
                title: "Action",
                field: "action",
                render: (rowData) => (
                  <Button onClick={() => handleShow(rowData)}>Edit</Button>
                ),
              },
              {
                title: "Export",
                field: "export",
                render: (rowData) => (
                  <Button onClick={() => exportAsExcel(rowData)}>Edit</Button>
                ),
              },
            ]}
            data={statementData}
            options={{
              exportButton: true,
              exportCsv: (columns, data) => {
                console.log(data, columns);
              },
            }}
          />
        </Col>
        <CSVLink data={downloadData} ref={csvLink} target="_blank" />
      </Row>
      <Modal className="viewCartDetails" show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row
            style={{ borderBottom: "1px solid #dedede", paddingBottom: "18px" }}
          >
            <div style={{ paddingBottom: "10px" }} className="title">
              <h4>
                <span>Profile</span>
              </h4>
            </div>
            <Col xs="6">
              <Form.Group as={Row} id="partnerStatement">
                <Form.Label column sm="4" className="labelStyle">
                  Period
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    plaintext
                    readOnly
                    className="inputValue"
                    defaultValue={`${moment(modalData.cycleStartDate).format(
                      "MMM Do YYYY"
                    )} - ${moment(modalData.cycleEndDate).format(
                      "MMM Do YYYY"
                    )}`}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} id="partnerStatement">
                <Form.Label column sm="4" className="labelStyle">
                  Refferal Code
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    className="inputValue"
                    plaintext
                    value={modalData.referralCode}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs="6" style={{ textAlign: "right" }}>
              <Form.Group as={Row} id="partnerStatement">
                <Form.Label column sm="4" className="labelStyle">
                  Paid On
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    style={{ textAlign: "right" }}
                    className="inputValue"
                    plaintext
                    defaultValue={modalData.paidOn}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} id="partnerStatement">
                <Form.Label column sm="4" className="labelStyle">
                  Updated by
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    style={{ textAlign: "right" }}
                    plaintext
                    readOnly
                    className="inputValue"
                    value={modalData.paidBy}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Form onSubmit={updatePayment}>
            <Row
              style={{
                borderBottom: "1px solid #dedede",
                paddingBottom: "18px",
                paddingTop: "10px",
              }}
            >
              <Col md="7" xs="7">
                <div style={{ paddingBottom: "10px" }} className="title">
                  <h4>
                    <span>Edit Statement</span>
                  </h4>
                </div>
                <div style={{ width: "100%", float: "left" }}>
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Form.Label column sm="4" className="labelStyle">
                      Add Amount
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        value={state.addAmount}
                        name="addAmount"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="4">
                      Payment Date
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="select"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        value={state.paymentDate}
                        name="paymentDate"
                        placeholder="₹ INR"
                        required
                      >
                        {paymentMonthDropdown}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Row>
                    <Col md="4">
                      <Form.Label>Criteria Met</Form.Label>
                    </Col>
                    <Col md="8">
                      <div key={"inline-radio"} className="mb-3">
                        <Form.Check
                          inline
                          label="yes"
                          name="verificationStatus"
                          type={"radio"}
                          id={`inline-radio-1`}
                          onChange={(e) => {
                            onChange(e.target.name, e.target.value);
                          }}
                          value={"yes"}
                          checked={state.verificationStatus == "yes"}
                        />

                        <Form.Check
                          inline
                          label="no"
                          type={"radio"}
                          id={`inline-radio-2`}
                          name="verificationStatus"
                          onChange={(e) => {
                            onChange(e.target.name, e.target.value);
                          }}
                          value={"no"}
                          checked={state.verificationStatus == "no"}
                        />
                      </div>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md="4">
                      <Form.Label>Criteria Met</Form.Label>
                    </Col>
                    <Col md="8">
                      <div key={"inline-radio"} className="mb-3">
                        <Form.Check
                          inline
                          label="yes"
                          name="approvalStatus"
                          type={"radio"}
                          id={`inline-radio-1`}
                          onChange={(e) => {
                            onChange(e.target.name, e.target.value);
                          }}
                          value={"yes"}
                          checked={state.approvalStatus == "yes"}
                        />

                        <Form.Check
                          inline
                          label="no"
                          type={"radio"}
                          id={`inline-radio-2`}
                          name="approvalStatus"
                          onChange={(e) => {
                            onChange(e.target.name, e.target.value);
                          }}
                          value={"no"}
                          checked={state.approvalStatus == "no"}
                        />
                      </div>
                    </Col>
                  </Form.Row>
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Form.Label column sm="4" className="labelStyle">
                      Notes
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        name="notes"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        as="textarea"
                        value={state.notes}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Form.Label column sm="4" className="labelStyle">
                      Remarks (Ghoshak)
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="textarea"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        value={state.remarkForGhoshak}
                        name="remarkForGhoshak"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Form.Label column sm="4" className="labelStyle">
                      Remarks (Partner)
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="textarea"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        value={state.remarkForPartner}
                        name="remarkForPartner"
                      />
                    </Col>
                  </Form.Group>
                </div>
              </Col>
              <Col md="5" xs="5">
                <div style={{ paddingBottom: "10px" }} className="title">
                  <h4>
                    <span>Payment Version</span>
                  </h4>
                </div>
                <div className={classes.root}>
                  <TableContainer className={classes.container}>
                    <Table size="small" className={classes.table}>
                      <TableHead className={classes.tableHeadBody}>
                        <TableRow>
                          <TableCell className={classes.tableHead}>
                            Date
                          </TableCell>

                          <TableCell className={classes.tableHead}>
                            Amount
                          </TableCell>
                          <TableCell className={classes.tableHead}>
                            Paid By
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {modalData.paymentVersion
                          .sort((a, b) => new Date(a.date) - new Date(b.date))
                          .map((elements) => (
                            <TableRow key={"amount"}>
                              <TableCell className={classes.tableCell}>
                                {elements.date}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                component="th"
                                scope="row"
                              >
                                {elements.amount}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {elements.paidBy}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Col>
            </Row>
            <Row style={{ textAlign: "right" }}>
              <Col sm="5" xs="7"></Col>
              <Col sm="7" xs="5">
                <Form.Group as={Row} id="partnerStatement">
                  <Form.Label
                    style={{
                      fontWeight: "400",
                      color: "#333",
                      fontSize: "16px",
                    }}
                    column
                    sm="4"
                  >
                    Total
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      plaintext
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        color: "#888",
                        fontSize: "16px",
                      }}
                      value={`₹ ${
                        isNaN(parseFloat(state.addAmount))
                          ? 0 + parseFloat(modalData.amountPaid)
                          : parseFloat(state.addAmount) +
                            parseFloat(modalData.amountPaid)
                      }`}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ paddingTop: "40px", float: "right" }}>
              <Col sm="12">
                <Button type="submit">Update</Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
