import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Chip from "@material-ui/core/Chip";
import config from "../config";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import SearchIcon from "@material-ui/icons/Search";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));
export default function PincodeSynonyms() {
  const classes = useStyles();
  const [addPincode, setAddPincode] = useState({
    pincode: "",
    subPincode: [],
  });
  const [searchValue, setSearchValue] = useState("");
  const [pincodeList, setPincodeList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const handleClose = () => {
    setEditPincodeList({
      synonyms: [],
      pincode: "",
    });
    setAddNewPin({
      pincode: "",
      synonyms: [],
      disabled: false,
    });
    setModalShow(false);
  };
  const handleShow = () => setModalShow(true);
  const [modalShow, setModalShow] = useState(false);
  const [addPin, setAddPin] = useState("");
  const [editPincodeList, setEditPincodeList] = useState({
    synonyms: [],
    pincode: "",
  });
  const [addPincodeToggle, setAddPincodeToggle] = useState(false);
  const [addNewPin, setAddNewPin] = useState({
    pincode: "",
    synonyms: [],
    disabled: false,
  });

  useEffect(() => {
    // Initialize funtion call
    getPincodeSynonmys();
  }, []);
  const getPincodeSynonmys = () => {
    Promise.all([
      fetch(`${config.ApiGateway}/pincodeSynonyms`, {
        method: "POST",
        body: JSON.stringify({
          editType: "getPincode",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setPincodeList(data); // stores backend data
        setFilteredList(data);
      })
      .catch((err) => console.log("Error in pincode" + err));
  };
  const handleDelete = (data) => {
    // remove pincode based on user action
    let removePincode = editPincodeList.synonyms.filter(
      (elements) => elements !== data
    );

    setEditPincodeList((prevStyle) => ({
      ...prevStyle,
      synonyms: removePincode,
    }));
  };

  const concatenatePincode = (e) => {
    e.preventDefault();
    let mergePincode = [...editPincodeList.synonyms, addPin];
    setEditPincodeList((prevStyle) => ({
      ...prevStyle,
      synonyms: mergePincode,
    }));
    setAddPin("");
  };
  const addNewPincodeSubmit = (e) => {
    // ...addNewPin.synonyms - old list pincode and addpin - new pincode
    e.preventDefault();
    let mergePincode = [...addNewPin.synonyms, addPin];
    setAddNewPin((prevStyle) => ({
      ...prevStyle,
      synonyms: mergePincode,
    }));
    setAddPin("");
  };
  const updatePincode = () => {
    Promise.all([
      fetch(`${config.ApiGateway}/pincodeSynonyms`, {
        method: "POST",
        body: JSON.stringify({
          editType: "updatePincode",
          editPincodeList: editPincodeList,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert(data.status);
        getPincodeSynonmys();
        handleClose();
      })
      .catch((err) => console.log("Error in pincode" + err));
  };
  const addNewPincode = () => {
    Promise.all([
      fetch(`${config.ApiGateway}/pincodeSynonyms`, {
        method: "POST",
        body: JSON.stringify({
          editType: "addPincode",
          editPincodeList: addNewPin,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert(data.status);
        getPincodeSynonmys();
        handleClose();
      })
      .catch((err) => console.log("Error in pincode" + err));
  };
  return (
    <Container>
      <div className="vertical-space-40"></div>
      <div className="orderHeader">
        <h2 className="orderHeaderText">Pincode Synonyms</h2>
      </div>

      <Row>
        <Col sm={"8"}>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Filter By Pincode"
              aria-label="Pincode"
              aria-describedby="basic-addon1"
              value={searchValue}
              name="searchValue"
              onChange={(e) => {
                const lowercasedFilter = e.target.value.toLowerCase();
                const filteredArrayData = pincodeList.filter((item) => {
                  return Object.keys(item).some((key) => {
                    if (item[key] !== null) {
                      return item[key]
                        .toString()
                        .toLowerCase()
                        .includes(lowercasedFilter);
                    }
                  });
                });

                setFilteredList(filteredArrayData);
                setSearchValue(e.target.value);
              }}
            />
          </InputGroup>
        </Col>

        <Col sm="4">
          <Button
            onClick={() => {
              setModalShow(true);
              setAddPincodeToggle(true);
            }}
          >
            Add Pincode
          </Button>
        </Col>
      </Row>
      <div className="vertical-space-10"></div>

      {filteredList.map((elements) => (
        <Row style={{ borderBottom: "1px solid black", width: "100%" }}>
          <div className="vertical-space-10"></div>
          <Col style={{ background: "white" }} sm="12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h3>{elements.pincode}</h3>
              </div>
              <div>
                <span
                  onClick={() => {
                    setEditPincodeList(elements);
                    setModalShow(true); // open modal
                    setAddPincodeToggle(false);
                  }}
                >
                  <EditIcon />
                </span>
              </div>
            </div>
            <div className={classes.root} style={{ width: "100%" }}>
              {Object.values(elements.synonyms).map((items) => {
                return (
                  <li key={items}>
                    <Chip label={items} className={classes.chip} />
                  </li>
                );
              })}
            </div>
          </Col>
          <div className="vertical-space-10"></div>
        </Row>
      ))}
      <Modal className="viewCartDetails" show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {addPincodeToggle ? (
            <>
              {addNewPin.disabled == true ? (
                <>
                  <h1>{addNewPin.pincode}</h1>
                  <div className="vertical-space-30"></div>
                  <label>Enter Sub Pincode</label>
                  <div className="vertical-space-30"></div>
                  <Form onSubmit={addNewPincodeSubmit}>
                    <Form.Row>
                      <Col sm="9">
                        <Form.Control
                          placeholder="Enter Pin code"
                          pattern="[0-9]{6}"
                          onChange={(e) => setAddPin(e.target.value)}
                          value={addPin}
                        />
                      </Col>
                      <Col sm="3">
                        <Button type="submit">Add</Button>
                      </Col>
                    </Form.Row>
                  </Form>

                  <div className={classes.root} style={{ width: "100%" }}>
                    {addNewPin.synonyms.map((items) => {
                      return (
                        <>
                          <li key={items}>
                            <Chip
                              label={items}
                              onDelete={() => {
                                let removePincode = addNewPin.synonyms.filter(
                                  (elements) => elements !== items
                                );

                                setAddNewPin((prevStyle) => ({
                                  ...prevStyle,
                                  synonyms: removePincode,
                                }));
                              }}
                              className={classes.chip}
                            />
                          </li>
                        </>
                      );
                    })}
                  </div>
                </>
              ) : (
                <Form.Row>
                  <Col sm="9">
                    <Form.Control
                      placeholder="Enter Pin code"
                      pattern="[0-9]{6}"
                      onChange={(e) => {
                        let value = e.target.value;
                        return setAddNewPin((prevStyle) => ({
                          ...prevStyle,
                          pincode: value,
                        }));
                      }}
                      value={addNewPin.pincode}
                    />
                  </Col>
                  <Col sm="3">
                    <Button
                      onClick={() => {
                        setAddNewPin((prevStyle) => ({
                          ...prevStyle,
                          disabled: true,
                        }));
                      }}
                    >
                      Add
                    </Button>
                  </Col>
                </Form.Row>
              )}
            </>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1>{editPincodeList.pincode}</h1>
                <Form onSubmit={concatenatePincode}>
                  <Form.Row>
                    <Col sm="9">
                      <Form.Control
                        placeholder="Enter Pin code"
                        pattern="[0-9]{6}"
                        onChange={(e) => setAddPin(e.target.value)}
                        value={addPin}
                      />
                    </Col>
                    <Col sm="3">
                      <Button type="submit">Add</Button>
                    </Col>
                  </Form.Row>
                </Form>
              </div>
              <label>Sub Pincode List</label>
              <div className={classes.root} style={{ width: "100%" }}>
                {editPincodeList.synonyms.map((items) => {
                  return (
                    <>
                      <li key={items}>
                        <Chip
                          label={items}
                          onDelete={() => handleDelete(items)}
                          className={classes.chip}
                        />
                      </li>
                    </>
                  );
                })}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {addPincodeToggle ? (
            <Button
              disabled={!(addNewPin.pincode.length === 6 && addNewPin.disabled)}
              onClick={() => addNewPincode()}
            >
              Add
            </Button>
          ) : (
            <Button onClick={() => updatePincode()}>Update</Button>
          )}

          <Button
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
