import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import config from "../config";
import { makeStyles } from "@material-ui/core/styles";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
export default function ExtendLoyalty() {
  const classes = useStyles();
  const [userId10Digit, setUserId10Digit] = useState([]);
  const [shoplist, setShoplist] = useState([]);
  const packageInitialState = {
    loyaltyupdatedOn: "",
    loyaltyupdatedBy: "",
    loyaltyExpiryDate: "",
  };
  const [importExcel, setImportExcel] = useState(true);
  const [state, setState] = useState(packageInitialState);
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((data) => {
        let user = { email: data.email, ...data.attributes };

        setState({ ...state, loyaltyupdatedBy: user.phone_number });
      })

      .catch((err) => console.log(err));
  }, []);

  const fileHandler = (event) => {
    let fileObj = event.target.files[0];

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let response = resp.rows.map((elements) => {
          return elements[0].toString().trim();
        });
        setUserId10Digit(response);
      }
    });
  };
  const getShoplist = (e) => {
    e.preventDefault();
    Promise.all([
      fetch(`${config.ApiGateway}/getMembershipDates`, {
        method: "POST",
        body: JSON.stringify({
          userId10Digit: userId10Digit,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        if (data.length <= 0) {
          return alert("No Shops Found");
        } else {
          setShoplist(data);
          setImportExcel(false);
        }
      })
      .catch((err) => alert(err));
  };

  const updatePackage = (e) => {
    e.preventDefault();
    Promise.all([
      fetch(`${config.ApiGateway}/extendLoyalty`, {
        method: "POST",
        body: JSON.stringify({
          shopArray: shoplist,
          loyaltyExpiryDate: state.loyaltyExpiryDate,
          loyaltyupdatedBy: state.loyaltyupdatedBy,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert(data.response);
        setState({ ...packageInitialState });
        setShoplist([]);
        setUserId10Digit([]);
        setImportExcel(true);
      })
      .catch((err) => alert(err));
  };
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Container>
        <div style={{ width: "100%", height: "100px" }}></div>
        <div className="pageTitleFlex">
          <h2 className="pageTitleText">Extend Loyalty Package</h2>
        </div>

        {importExcel ? (
          <>
            <Form onSubmit={getShoplist}>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginTop: "40px",
                }}
              >
                <Col>
                  <label>Import Excel File</label>
                  <input
                    onChange={fileHandler}
                    style={{ padding: "10px" }}
                    type="file"
                  ></input>
                </Col>
                <Col>
                  <Button type="submit"> Submit</Button>
                </Col>
              </Row>
            </Form>
          </>
        ) : (
          <Form onSubmit={updatePackage}>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                marginTop: "40px",
              }}
            >
              <Col>
                <Form.Group as={Row}>
                  <Form.Label>Update Expiry Date</Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="date"
                      name="loyaltyExpiryDate"
                      value={state.loyaltyExpiryDate}
                      onChange={onChange}
                      required
                    ></Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Button type="submit"> Submit</Button>
              </Col>
            </Row>
          </Form>
        )}
        {shoplist.length > 0 && (
          <Row>
            <div className="flexCard">
              <div className="flexCardHeader">
                <label className="flexCardHeaderlabel">Shop List</label>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S No</TableCell>
                        <TableCell className={classes.tableCell}>
                          Shop ID
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Shop Name
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          UserName
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Phone Number
                        </TableCell>
                        <TableCell>Pincode</TableCell>
                        <TableCell className={classes.tableCell}>
                          Package activated Status
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Updated on
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Updated By
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Expiration Date
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {shoplist.map((item, index) => (
                        <TableRow key={item.userId10Digit}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Link
                              to={{
                                pathname: "/merchant",
                                search: `?userid=${item.userId10Digit}`,
                              }}
                            >
                              {" "}
                              {item.userId10Digit}
                            </Link>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.shopName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.userName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.userPhoneNumber}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.userPincode}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.loyaltyPackage}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.loyaltyupdatedOn}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.loyaltyupdatedBy}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {item.loyaltyExpiryDate}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Row>
        )}
      </Container>
    </>
  );
}
