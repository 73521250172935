import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import config from "../config";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import limit from "../limit";
import InputGroup from "react-bootstrap/InputGroup";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class LoyaltyDistribution extends Component {
  constructor(props) {
    super(props);

    var initialDate = new Date();
    initialDate.setDate(initialDate.getDate() - 30);
    this.state = {
      loyltyCouponArray: [],
      displayTable: false,
      redeemedUsers: [],
      couponType: "LOYALTY",
      showNotification: false,
      showCustomer: false,
      notification: "",
      updatedBy: "",
      startDate: initialDate.toISOString().substring(0, 10),
      endDate: limit.size.endDate,
      headers: [],
      prevNotification: {},
    };
    this.reset = this.state;
    this.sendNotification = this.sendNotification.bind(this);
    this.showCouponDetails = this.showCouponDetails.bind(this);
    this.onChange = this.onChange.bind(this);
    this.sendNotificationToCust = this.sendNotificationToCust.bind(this);
    this.csvLink = React.createRef();
    this.fetchDetails = this.fetchDetails.bind(this);
  }
  export() {
    const headers = [
      { label: "USER ID", key: "userId10Digit" },
      { label: "PINCODE", key: "pincode" },
      { label: "TOTAL  COUPONS", key: "totalCouponCount" },
      { label: "CLAIMED", key: "Claim" },
      { label: "REDEEMED", key: "Redeemed" },
      { label: "COUPON AMOUNT", key: "maximumCouponAmount" },

      { label: "COUPON DETAILS", key: "couponDetails" },
      { label: "CREATED ON", key: "addedDate" },
      { label: "CREATED BY", key: "couponAddedby" },
      { label: "ID", key: "couponId" },
    ];

    this.setState(
      {
        headers: headers,
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  sendNotificationToCust(users) {
    let claimedUsers = [];
    let redeemedCouponId = "";
    let updatedBy = "";
    users.map((elements) => {
      if (elements.couponStatus === "Claim") {
        claimedUsers.push(elements.userId10Digit);
        redeemedCouponId = elements.redeemedCouponId;
      }
    });

    Promise.all([
      fetch(
        `${config.ApiGateway}/sendNotificationToCustomer`,

        {
          method: "POST",
          body: JSON.stringify({
            claimed: claimedUsers,
            notification: this.state.notification,
            updatedBy: this.state.updatedBy,
            notificationPostedOn: new Date().toISOString().substring(0, 10),
            redeemedCouponId: redeemedCouponId,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert(JSON.stringify(data));
        this.setState(this.reset);
        this.componentDidMount();
      })
      .catch((err) => alert(err));
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  sendNotification() {
    this.setState({
      showNotification: !this.state.showNotification,
    });
  }
  showCouponDetails(couponDetails) {
    Promise.all([
      fetch(
        `${config.ApiGateway}/couponRedemption`,

        {
          method: "POST",
          body: JSON.stringify({ couponId: couponDetails.couponId }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, data2]) => {
        var mergePincodeAndArea = data.map((items) => {
          let count = data2.Items.find(
            (elements) => items.userPincode === elements.pincode
          );
          let appendObject = {};
          count !== undefined
            ? (appendObject = Object.assign({}, items, count))
            : (appendObject = Object.assign(
                {},
                {
                  pincode: items,
                  place: "NOT FOUND",
                }
              ));

          return appendObject;
        });

        this.setState({
          // prevNotification: couponDetails.notification,
          redeemedUsers: mergePincodeAndArea,
          displayTable: false,
          showCustomer: true,
        });
      })
      .catch((err) => alert(err));
  }
  async componentDidMount() {
    console.log(this.props);
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({
          updatedBy: user.attributes.phone_number,
        });
      })

      .catch((err) => console.log(err));
    await this.fetchDetails();
  }
  fetchDetails() {
    Promise.all([
      fetch(
        `${config.ApiGateway}/DistributionOfCoupons`,

        {
          method: "POST",
          body: JSON.stringify({
            couponType: "LOYALTY",
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          }),

          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, data2]) => {
        var mergePincodeAndArea = data.map((items) => {
          let count = data2.Items.find(
            (elements) => items.pincode === elements.pincode
          );
          let appendObject = {};
          count !== undefined
            ? (appendObject = Object.assign({}, items, count))
            : (appendObject = Object.assign(
                {},
                {
                  pincode: items,
                  place: "NOT FOUND",
                }
              ));

          return appendObject;
        });

        this.setState({
          loyltyCouponArray: mergePincodeAndArea,
          displayTable: true,
        });
      })
      .catch((err) => alert(err));
  }
  render() {
    const { classes } = this.props;
    this.state.loyltyCouponArray.map((elements) => {
      console.log(
        elements.custumerAddedCouponToFav !== undefined
          ? Object.keys(elements.custumerAddedCouponToFav).length
          : 0
      );
    });
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              {this.state.displayTable ? (
                <>
                  <div
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%",
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}>
                      {" "}
                      LOYALTY DISTRIBUTION{" "}
                    </h3>
                  </div>

                  <Row>
                    <Col>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <strong> DATE RANGE</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name="startDate"
                          value={this.state.startDate}
                          onChange={this.onChange}
                          type="date"
                          required
                        ></Form.Control>
                        <Form.Control
                          name="endDate"
                          onChange={this.onChange}
                          value={this.state.endDate}
                          type="date"
                          min={this.state.startDate}
                          required
                        ></Form.Control>
                      </InputGroup>
                    </Col>

                    <Col>
                      <Button
                        onClick={() => {
                          this.fetchDetails();
                        }}
                      >
                        SEARCH
                      </Button>
                    </Col>
                    <Col xs={2}>
                      <Button
                        onClick={() => {
                          this.export();
                        }}
                      >
                        DOWNLOAD
                      </Button>
                      <CSVLink
                        enclosingCharacter={`'`}
                        data={this.state.loyltyCouponArray}
                        headers={this.state.headers}
                        ref={this.csvLink}
                        target="_blank"
                      />
                    </Col>
                  </Row>
                  <Table
                    style={{
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid",
                    }}
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          S NO
                        </TableCell>

                        {this.state.couponType === "PROMOCODE" && (
                          <TableCell className={classes.tableCell}>
                            PROMOCODE
                          </TableCell>
                        )}
                        <TableCell className={classes.tableCell}>
                          DETAILS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          SHOP ID
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          SHOP NAME
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          PINCODE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          AREA
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPON COUNT
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          CLAIMED
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          REDEEMED
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          ALLOCATED COST
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          CONSUMED COST
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPON AMOUNT
                        </TableCell>
                        {this.state.couponType === "PROMOCODE" && (
                          <TableCell className={classes.tableCell}>
                            PROMOCODE EXPIRY
                          </TableCell>
                        )}
                        <TableCell className={classes.tableCell}>
                          COUPON EXPIRY
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          CUSTOMER LIKED
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          CREATED ON
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          CREATED BY
                        </TableCell>
                        <TableCell className={classes.tableCell}>ID</TableCell>
                        <TableCell className={classes.tableCell}>
                          REDEMPTION
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.loyltyCouponArray.map((elements, index) => (
                        <TableRow key={elements.promocode}>
                          <TableCell className={classes.tableCell}>
                            {index + 1}
                          </TableCell>
                          {elements.couponType === "PROMOCODE" && (
                            <TableCell className={classes.tableCell}>
                              {elements.promocode}
                            </TableCell>
                          )}
                          <TableCell className={classes.tableCell}>
                            {elements.couponDetails}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Link
                              to={{
                                pathname: "/merchant",
                                search: `?userid=${elements.userId10Digit}`,
                              }}
                            >
                              {" "}
                              {elements.userId10Digit}{" "}
                            </Link>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.shopName}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {elements.pincode}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.place}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {elements.promocodeCount ||
                              elements.totalCouponCount}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {elements.Claim}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {elements.Redeemed}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {parseInt(
                              elements.promocodeCount ||
                                elements.totalCouponCount
                            ) * parseInt(elements.maximumCouponAmount)}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {(parseInt(
                              elements.promocodeCount ||
                                elements.totalCouponCount
                            ) -
                              parseInt(
                                elements.promocodeRemainingCount ||
                                  elements.remainingCouponCount
                              )) *
                              parseInt(elements.maximumCouponAmount)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.maximumCouponAmount}
                          </TableCell>
                          {elements.couponType === "PROMOCODE" && (
                            <TableCell className={classes.tableCell}>
                              {elements.promocodeExpiryDate}
                            </TableCell>
                          )}
                          <TableCell className={classes.tableCell}>
                            {elements.CouponExpiryDate || elements.expiryDate}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.custumerAddedCouponToFav !== undefined
                              ? Object.keys(elements.custumerAddedCouponToFav)
                                  .length
                              : 0}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {elements.addedDate}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.couponAddedby}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.couponId}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Button
                              onClick={() => {
                                this.showCouponDetails(elements);
                              }}
                            >
                              VIEW
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              ) : (
                <>
                  {this.state.showCustomer && (
                    <>
                      {this.state.showNotification ? (
                        <>
                          <div
                            style={{
                              marginTop: 60 + "px",
                              width: 50 + "%",
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginBottom: 60 + "px",
                              position: "relative",
                            }}
                          >
                            <Form.Label
                              style={{
                                fontWeight: 600,
                                marginBottom: 10 + "px",
                              }}
                            >
                              ENTER NOTIFICATION MESSAGE
                            </Form.Label>

                            <Form.Control
                              style={{
                                border: 0 + "px",
                                borderRadius: 0,
                                height: 140 + "px",
                                marginBottom: 20 + "px",
                                boxShadow: "5px 5px 15px #ccc",
                              }}
                              as="textarea"
                              onChange={this.onChange}
                              name="notification"
                              value={this.state.notification}
                            ></Form.Control>

                            <Button
                              style={{
                                right: 0,
                                borderRadius: 0,
                                position: "absolute",
                                paddingLeft: 30 + "px",
                                paddingRight: 30 + "px",
                                paddingTop: 10 + "px",
                                paddingBottom: 10 + "px",
                              }}
                              onClick={() =>
                                this.sendNotificationToCust(
                                  this.state.redeemedUsers
                                )
                              }
                            >
                              SEND
                            </Button>
                          </div>

                          <Row>
                            <div>
                              <h3>Notification History</h3>
                            </div>
                            <Table
                              style={{
                                width: 100 + "%",
                                border: 0.5 + "px",
                                borderColor: "#ced4da",
                                borderStyle: "solid",
                              }}
                              className={classes.table}
                              size="small"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>S.NO</TableCell>
                                  <TableCell>NOTES</TableCell>
                                  <TableCell>DATE</TableCell>
                                  <TableCell>SENT BY</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.values(this.state.prevNotification).map(
                                  (items, index) => (
                                    <>
                                      <TableRow>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>
                                          <h5>{items.notes}</h5>
                                        </TableCell>
                                        <TableCell>
                                          {" "}
                                          <h5>{items.date}</h5>
                                        </TableCell>
                                        <TableCell>
                                          {" "}
                                          <h5>{items.updatedBy}</h5>
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </Row>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              backgroundColor: "#fafafa",
                              width: 100 + "%",
                              margin: 1 + "%",
                            }}
                          >
                            <h3 style={{ textAlign: "center" }}>
                              {" "}
                              CUSTOMER DETAILS{" "}
                            </h3>
                          </div>
                          <Row>
                            <Col>
                              <h3 style={{ textAlign: "center" }}>
                                {" "}
                                SEND NOTIFICATION FOR CLAIMED CUSTOMERS
                              </h3>
                            </Col>
                            <Col>
                              <Button
                                onClick={() => {
                                  this.sendNotification(
                                    this.state.redeemedUsers
                                  );
                                }}
                                disabled={
                                  this.state.redeemedUsers.length > 0
                                    ? false
                                    : true
                                }
                              >
                                ENTER MESSAGE
                              </Button>
                            </Col>
                          </Row>
                          <Table
                            style={{
                              width: 100 + "%",
                              border: 0.5 + "px",
                              borderColor: "#ced4da",
                              borderStyle: "solid",
                            }}
                            className={classes.table}
                            size="small"
                          >
                            <>
                              <TableHead>
                                <TableRow>
                                  <TableCell className={classes.tableCell}>
                                    S NO
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    CUSTOMER ID
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    CUSTOMER NAME
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    PHONE NUMBER
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    PINCODE
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    PLACE
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    REDEEMED DATE
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    REDEEMED COUPON ID
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    COUPON STATUS
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    PAID STATUS
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.redeemedUsers.map(
                                  (elements, index) => (
                                    <TableRow key={elements.userId10Digit}>
                                      <TableCell className={classes.tableCell}>
                                        {index + 1}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        <Link
                                          to={{
                                            pathname: "/customer",
                                            search: `?userid=${elements.userId10Digit}`,
                                          }}
                                        >
                                          {" "}
                                          {elements.userId10Digit}{" "}
                                        </Link>
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {elements.userName}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {elements.userPhoneNumber}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {elements.userPincode}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {elements.place}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {elements.redeemedDate}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {elements.redeemedCouponId}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {elements.couponStatus}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {elements.paidStatus}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </>
                          </Table>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
LoyaltyDistribution.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoyaltyDistribution);
