import React, { useEffect, useState, useContext, createRef } from "react";
import { Container, Col, Button, Row, Badge } from "react-bootstrap";
import config from "../config";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import { store } from "../provider";
import { CSVLink } from "react-csv";
const useStyles = makeStyles({
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 5,
    paddingLeft: 5,
  },
});

export default function OnlineOrders() {
  const classes = useStyles();
  const context = useContext(store);
  const [orderList, setOrderList] = useState([]);
  const [days, setDays] = useState(15); //default last 15 days orders
  const history = useHistory();
  const csvLink = createRef();
  const [downloadData, setDownloadData] = useState([]);
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [downloadData]);
  const downloadCSV = (data) => {
    let modifyCsvData = [];

    data.forEach((elements) => {
      let promocodeValue = [];
      let appliedCoupon = "no";
      let discountAmt = 0;
      if (typeof elements.coupons == "object") {
        appliedCoupon = elements.coupons.length;
        elements.coupons.map((elements) => {
          promocodeValue.push(elements.promocode);
          discountAmt = elements.discountAmount || 0;
        });
      }
      var cartAmt = 0.0;

      Object.entries(elements.cart).forEach(([key, value]) => {
        cartAmt +=
          (parseFloat(value.cartCount) || 0) *
          (parseFloat(value.productPrice) || 0);
      });
      let modifyData = {
        orderId: elements.orderId,
        shopName: elements.shopName,
        billedDate: elements.billedDate,
        deliveryMethod: elements.deliveryMethod,
        amtPaidByCustomer: parseFloat(elements.totalAmount).toFixed(2) || 0,
        cartAmt: cartAmt.toFixed(2),
        deliveryCharge: parseFloat(elements.chargeForDelivery) || 0,
        "Amt W/o disc":
          elements.deliveryMethod === "Delivery"
            ? (
                parseFloat(cartAmt) + parseFloat(elements.chargeForDelivery)
              ).toFixed(2)
            : parseFloat(cartAmt).toFixed(2),
        "Discount Amt": discountAmt,
        "Amt With disc":
          elements.deliveryMethod === "Delivery"
            ? (
                parseFloat(cartAmt) +
                parseFloat(elements.chargeForDelivery) -
                (parseFloat(elements.couponDiscount) || 0)
              ).toFixed(2)
            : (
                parseFloat(cartAmt) - (parseFloat(elements.couponDiscount) || 0)
              ).toFixed(2),

        Check:
          elements.deliveryMethod === "Delivery" ? (
            (
              parseFloat(cartAmt) +
              parseFloat(elements.chargeForDelivery) -
              (parseFloat(elements.couponDiscount) || 0)
            ).toFixed(2) == parseFloat(elements.totalAmount).toFixed(2) ? (
              <span>Valid</span>
            ) : (
              <span>Not valid</span>
            )
          ) : (
              parseFloat(cartAmt) - (parseFloat(elements.couponDiscount) || 0)
            ).toFixed(2) == parseFloat(elements.totalAmount).toFixed(2) ? (
            <span> Valid</span>
          ) : (
            <span>Not valid</span>
          ),
        "Coupons Applied": appliedCoupon,
        Promocode: promocodeValue.map((elements) => elements),
        "Customer Name": elements.customerName,
        "customer phone number": elements.customerPhoneNumber,
        "Delivery Address":
          Object.keys(elements.deliveryAddress).map.length > 0 ? (
            <span>
              {elements.deliveryAddress.address}
              {elements.deliveryAddress.query}
            </span>
          ) : (
            <span>{" - "}</span>
          ),
        "Order Status": elements.status,
      };

      modifyCsvData.push(modifyData);
    });

    setDownloadData(modifyCsvData);
    setActive(true);
  };
  useEffect(() => {
    // Api call to get online orders

    Promise.all([
      fetch(`${config.ApiGateway}/viewOnlineOrders`, {
        method: "POST",
        body: JSON.stringify({
          dateRangeVal: days,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        //Delivery method - pick up, delivery
        // Delivery method(delivery) -  totalAmount = cart amount + delivery charge -  coupons discount  //
        // slot timing - delivery slot timing (4pm -6pm) (10am-5pm)

        // Delivery method(pickup) - totalAmount = cart amount -  coupons discount  // Delivery method
        // slot timing - Nill & delivery charge - Nil, slot timing - Nil

        
        setOrderList(data);
      })
      .catch((err) => alert(err));
  }, [days]);
  const handleClick = async (value) => {
    setDays(value);
  };

  return (
    <>
      <Container fluid>
        <>
          <div style={{ width: "100%", height: "100px" }}></div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "1.4rem",
            }}
          >
            <h2
              style={{
                textTransform: "capitalize",
                marginBottom: 0,
                fontSize: "2.4rem",
                fontWeight: "600",
                color: "#4f4e4e",
              }}
            >
              Online Orders
            </h2>
          </div>
          <Row>
            <Col sm="8"></Col>
            <Col sm="4">
              <Button
                onClick={() => downloadCSV(orderList)}
                style={{ float: "right", marginBottom: "10px" }}
              >
                Download
              </Button>
              <CSVLink data={downloadData} ref={csvLink} target="_blank" />
            </Col>{" "}
          </Row>
          <Row>
            <Col style={{ margin: "0 auto" }}>
              <div style={{ float: "left" }} className="flexCard">
                <div className="flexCardHeader">
                  <label className="flexCardHeaderlabel">
                    Online order/delivery
                  </label>

                  <nav class="site-nav">
                    <ul className="flexCardUl">
                      <li
                        className={days === "15" ? "active" : ""}
                        onClick={() => handleClick("15")}
                      >
                        <a href="#">15</a>
                      </li>
                      <li
                        className={days === "30" ? "active" : ""}
                        onClick={() => handleClick("30")}
                      >
                        <a href="#">30</a>
                      </li>
                      <li
                        className={days === "90" ? "active" : ""}
                        onClick={() => handleClick("90")}
                      >
                        <a href="#">90</a>
                      </li>
                      <li
                        className={days === "180" ? "active" : ""}
                        onClick={() => handleClick("180")}
                      >
                        <a href="#">180</a>
                      </li>
                      <li
                        className={days === "365" ? "active" : ""}
                        onClick={() => handleClick("365")}
                      >
                        <a href="#">365</a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div>
                  <Table
                    stickyHeader
                    className={classes.table}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          <b>S No</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <b> Order ID</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <b> Shop Name</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <b> Shop ID</b>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          <b> Billed Date</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <b> Delivery Method</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <b> Amt paid by cust</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <b> Cart Amt</b>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          <b> Delivery Charge</b>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          <b> Amt W/o disc </b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <b> Discount Amt</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <b> Amt With disc </b>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          <b> Check</b>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          <b> Coupons Applied</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <b> Promocode</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <b> Customer Name</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <b> Customer Phone Number</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <b> Delivery Address</b>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          <b> Order Status</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <b> Action</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderList.map((row, index) => {
                        let promocodeValue = [];
                        let appliedCoupon = "no";
                        let discountAmt = 0;
                        if (typeof row.coupons == "object") {
                          appliedCoupon = row.coupons.length;
                          row.coupons.map((elements) => {
                            promocodeValue.push(elements.promocode);
                            discountAmt = elements.discountAmount || 0;
                          });
                        }
                        var cartAmt = 0.0;

                        Object.entries(row.cart).forEach(([key, value]) => {
                          cartAmt +=
                            (parseFloat(value.cartCount) || 0) *
                            (parseFloat(value.productPrice) || 0);
                        });

                        return (
                          <>
                            <TableRow key={row.orderId}>
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.orderId}
                                {/* Order id - Track order status */}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {row.shopName}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {row.shopIduserId10Digit}
                                {/* /Shop id/  */}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {row.billingDate}
                                {/* Order Placed Date */}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {row.deliveryMethod}
                                {/* Pick up or Delivery */}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {"₹ "}
                                {parseFloat(row.totalAmount).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {"₹ "}
                                {cartAmt.toFixed(2)}

                                {/* Product cost  */}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {"₹ "}
                                {row.deliveryMethod === "Delivery"
                                  ? parseFloat(row.chargeForDelivery)
                                  : 0}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {"₹ "}
                                {row.deliveryMethod === "Delivery"
                                  ? (
                                      parseFloat(cartAmt) +
                                      parseFloat(row.chargeForDelivery)
                                    ).toFixed(2)
                                  : parseFloat(cartAmt).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {"₹ "}
                                {discountAmt}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {"₹ "}
                                {row.deliveryMethod === "Delivery"
                                  ? (
                                      parseFloat(cartAmt) +
                                      parseFloat(row.chargeForDelivery) -
                                      (parseFloat(row.couponDiscount) || 0)
                                    ).toFixed(2)
                                  : (
                                      parseFloat(cartAmt) -
                                      (parseFloat(row.couponDiscount) || 0)
                                    ).toFixed(2)}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {/* To check total amount & cart amount,delivery charge,coupon discount are same */}
                                {row.deliveryMethod === "Delivery" ? (
                                  (
                                    parseFloat(cartAmt) +
                                    parseFloat(row.chargeForDelivery) -
                                    (parseFloat(row.couponDiscount) || 0)
                                  ).toFixed(2) ==
                                  parseFloat(row.totalAmount).toFixed(2) ? (
                                    <Badge pill variant="success">
                                      Valid
                                    </Badge>
                                  ) : (
                                    <Badge pill variant="danger">
                                      Not valid
                                    </Badge>
                                  )
                                ) : (
                                    parseFloat(cartAmt) -
                                    (parseFloat(row.couponDiscount) || 0)
                                  ).toFixed(2) ==
                                  parseFloat(row.totalAmount).toFixed(2) ? (
                                  <Badge pill variant="success">
                                    Valid
                                  </Badge>
                                ) : (
                                  <Badge pill variant="danger">
                                    Not valid
                                  </Badge>
                                )}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {appliedCoupon}
                              </TableCell>
                              <TableCell
                                style={{
                                  backgroundColor:
                                    promocodeValue.length > 1 ? "yellow" : null,
                                }}
                                className={classes.tableCell}
                              >
                                {/* Type array contains list of promocode */}
                                {promocodeValue.map((elements) => {
                                  return (
                                    <>
                                      <p> {elements}</p>

                                      <br />
                                    </>
                                  );
                                })}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {row.customerName}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {row.customerPhoneNumber}
                              </TableCell>
                              {Object.keys(row.deliveryAddress).map.length >
                              0 ? (
                                <TableCell className={classes.tableCell}>
                                  {row.deliveryAddress.address}
                                  {row.deliveryAddress.query}
                                </TableCell>
                              ) : (
                                <TableCell className={classes.tableCell}>
                                  {" - "}
                                </TableCell>
                              )}

                              <TableCell className={classes.tableCell}>
                                {row.status === "complete" && "Delivered"}
                                {row.status === "startPackaging" &&
                                  "Order Accepted"}
                                {row.status === "placed" && "new Order Placed"}
                                {row.status === "readyForDelivery" &&
                                  "Ready for Delivery"}
                                {row.status === "rejected" && "Rejected"}
                                {row.status === "readyForPickup" &&
                                  "Ready for Pick-up"}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                <Button
                                  onClick={async () => {
                                    // Store state global
                                    await context.dispatch({
                                      type: "SHOP_DETAILS",
                                      currentDelivery: row, // Stores order information
                                    });
                                    history.push(`/shop/${row.shopId}`); // navigating to onlineshopstats
                                  }}
                                >
                                  View
                                </Button>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </>
      </Container>
    </>
  );
}
