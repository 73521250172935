import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Modal, Row, Col, Spinner } from "react-bootstrap";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import CreateQrCode from "./createQrCode";
import { saveAs } from "file-saver";
import config from "../config";
const GoDaddyWebsiteTracker = (props) => {
  const [websiteData, setWebsiteData] = useState([]);
  const [modal, setModal] = useState(false);
  const [userId10Digit, setUserId10Digit] = useState("");
  const [siteUrl, setSiteUrl] = useState("");
  const [proofImage, setProofImage] = useState();
  const [loading, setLoading] = useState(false);
  const ref = useRef();

  const updateARecord = async () => {
    setLoading(true);
    if (userId10Digit === "") return alert("Enter valid Shop Id");
    if (siteUrl === "") return alert("Enter Valid Website Url");
    if (!proofImage) return alert("Please choose customer proof image");
    await Promise.all([
      fetch(`${config.ApiGateway}/goDaddyUpdateARecord`, {
        method: "POST",
        body: JSON.stringify({
          siteUrl: siteUrl,
          userId10Digit: userId10Digit,
          image: proofImage,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res]) => Promise.all([res.json()]))
      .then(async ([data]) => {
        if (data) {
          setLoading(false);
          if (data.code === "INVALID_USER") return alert(data.message);
          if (data.code === "NOT_FOUND") return alert(data.message);
          if (data.code === "ALREADY_LINKED") return alert(data.message);
          if (data.code === "ERROR") return alert(data.message);
          if (data.code === "UPDATE_SUCCESS") {
            setUserId10Digit("");
            setSiteUrl("");
            setProofImage();
            ref.current.value = "";
            fetchDomains();
            return alert(data.message);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("updating A Record Error", err);
        alert("Something went wrong, please try again");
      });
  };

  const handleImageSelect = (imageData) => {
    var reader = new FileReader();
    reader.onloadend = () => {
      setProofImage(reader.result.replace(/^data:image\/\w+;base64,/, ""));
    };
    reader.readAsDataURL(imageData);
  };
  // const fetchFromS3=async(data)=>{
  //    try{
  //      var response = await fetch(data.data,{
  //        method:'post',
  //        headers:{
  //          'Content-Type':'application/json'
  //        }

  //      })
  //    }catch(err){
  //      console.log(err)
  //    }
  // }

  const fetchDomains = async () => {
    await Promise.all([
      fetch(
        `${config.ApiGateway}/goDaddyGetWebsiteDetails`,
        // "https://vkbmrh8wc3.execute-api.ap-south-1.amazonaws.com/dev/goDaddyGetWebsiteDetails"
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userType: "Shop" }),
        }
      ),
    ])
      .then(async ([res]) => await res.json())
      .then(async (data) => {
        if (data.payloadResponseExceededLimit) {
          //  var response = await fetchFromS3(data)
          await fetch(data.data)
            .then((res) => res.json())
            .then((out) => {
              console.log("Checkout this JSON! ", out);
              setWebsiteData(out);
            });
        } else {
          console.log("websiteData", data);
          setWebsiteData(data);
        }
      })
      .catch((err) => console.log("Err", err));
  };

  useEffect(async () => {
    fetchDomains();
  }, []);

  const retrySSL = async (data) => {
    try {
      var res = await fetch(`${config.ApiGateway}/sslAutomateRetry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          siteUrl: data.customDomainUrl.split("//")[1],
          userId: data.userId,
          certificateArn: data.certificateArn,
          hostedZoneId: data.hostedZoneId,
        }),
      });
      res = await res.json();
      if (res) {
        if (res.code === "SSL_UPDATED") return alert(res.message);
        if (res.code === "ERROR") return alert(res.message);
        if (res.code === "RETRY_INITIATED") return alert(res.message);
      }
    } catch (err) {
      console.log(err);
      alert("Error while retry please try again");
    }
  };

  return (
    <Container fluid>
      <div style={{ width: "100%", height: "30px" }}></div>
      <div className="websiteHeader">
        <h2 className="websiteHeaderText">Own Domain Tracker</h2>
      </div>
      <div
        style={{
          margin: "20px 0px 20px 0px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={() => setModal(true)}>Add Domain</Button>
      </div>
      <div>
        <MaterialTable
          data={websiteData}
          columns={[
            {
              title: "Used Id",
              field: "userId10Digit",
              render: (rowData) => (
                <Link
                  to={{
                    pathname: "/merchant",
                    search: `?userid=${rowData.userId10Digit}`,
                  }}
                >
                  {rowData.userId10Digit}
                </Link>
              ),
            },
            { title: "Shop Name", field: "shopName" },
            { title: "Shop Type", field: "shopType" },
            {
              title: "Phone Number",
              field: "userPhoneNumber",
            },
            {
              title: "Website Username",
              field: "customDomainUserName",
            },
            {
              title: "Website Url",
              field: "customDomainUrl",
              render: (rowData) => {
                return (
                  <a
                    style={{ display: "table-cell" }}
                    href={rowData.customDomainUrl}
                    target="_blank"
                  >
                    {rowData.customDomainUrl}
                  </a>
                );
              },
            },
            {
              title: "Domain Purchase Date",
              field: "customDomainActivatedOn",
            },
            {
              title: "Domain Expiry Date",
              field: "customDomainExpiryOn",
            },
            {
              title: "QR Code",
              field: "customDomainUrl",
              render: (rowData) => (
                <Button
                  onClick={async () => {
                    let blob = await pdf(
                      <CreateQrCode shopdetails={rowData} />
                    ).toBlob();
                    saveAs(blob, rowData.shopName);
                  }}
                >
                  Generate Qr Code
                </Button>
              ),
            },
            {
              title: "SSL Status",
              field: "sslStatus",
            },
          ]}
        />
      </div>
      <Modal
        show={modal}
        onHide={() => setModal(!modal)}
        dialogClassName="ownDomainModal"
      >
        <Modal.Header>
          <div>
            <div>
              <h3>Update Domain Record</h3>
            </div>
            {/* <div style={{ marginLeft: "50%" }}>
              <CloseIcon
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={() => setModal(!modal)}
              />
            </div> */}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ marginTop: "20px" }}>
            <Col className="dashboardColumnBox columnCenter" sm="8">
              <div style={{ marginLeft: "20%", marginTop: "30px" }}>
                <label>Enter Shop Id: </label>
                <input
                  style={{
                    width: "50%",
                    height: "40px",
                    borderRadius: "5px",
                    marginLeft: "22px",
                  }}
                  placeholder="Enter 10 digit Id"
                  onChange={(e) => setUserId10Digit(e.target.value)}
                  value={userId10Digit}
                />{" "}
              </div>
              <div style={{ marginLeft: "20%", marginTop: "25px" }}>
                <label>Enter shop URL: </label>
                <input
                  style={{
                    width: "50%",
                    height: "40px",
                    borderRadius: "5px",
                    marginLeft: "10px",
                  }}
                  placeholder="Eg: hellobeautician.in"
                  onChange={(e) => setSiteUrl(e.target.value)}
                  value={siteUrl}
                />{" "}
              </div>
              <div style={{ marginLeft: "20%", marginTop: "25px" }}>
                <label>Customer Proof Image: </label>{" "}
                <input
                  ref={ref}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                    handleImageSelect(e.target.files[0]);
                  }}
                />{" "}
              </div>
              <div
                style={{
                  marginLeft: "45%",
                  marginTop: "25px",
                  marginBottom: "20px",
                }}
              >
                {loading ? (
                  <Button disabled>
                    Updating...{" "}
                    <Spinner size="sm" animation="border" variant="light" />
                  </Button>
                ) : (
                  <Button onClick={updateARecord}>Update</Button>
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button onClick={() => setModal(!modal)}>Close</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default GoDaddyWebsiteTracker;
