import React, { useEffect, useState, useMemo } from "react";
import MaterialTable from "material-table";
import config from "../config";
import Modal from "react-bootstrap/Modal";
import ShopProfile from "../shopProfile/shopProfile";
import Button from "react-bootstrap/Button";
import "./delivery.css";
export default function DeliveryPaymentTracker() {
  const [isLoading, setIsLoading] = useState(true);
  const [paymentStatus, setpaymentStatus] = useState("initiated");
  const [order, setOrder] = useState([]);
  const [shopDetails, setShopDetails] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [days, setDays] = useState("15");
  useEffect(() => {
    setIsLoading(true);
    getDelivery();
  }, [days, paymentStatus]);

  const getDelivery = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/deliveryPaymentTracker`, {
        method: "POST",
        body: JSON.stringify({ paymentStatus, days }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        setOrder(data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const getShopDetails = async (shopId) => {
    // get Shop Details
    await Promise.all([
      fetch(`${config.ApiGateway}/viewShopById`, {
        method: "POST",
        body: JSON.stringify({ shopId: shopId }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setShopDetails(data);
        setModalShow(true);
      })
      .catch((err) => console.log(err));
  };
  const columns = useMemo(
    () => [
      {
        title: "Order Id",
        field: "orderId",
      },
      {
        title: "Order Status",
        field: "status",
      },
      {
        title: "Billing Date",
        field: "billingDate",
      },
      {
        title: "Cust Name",
        field: "customerName",
        render: (rowData) => (
          <p>{`${rowData.customerName} 
          ${rowData.customerPhoneNumber} `}</p>
        ),
      },
      {
        title: "Ghoshak Id",
        field: "userId10Digit",
      },

      {
        title: "Shop Name",
        field: "shopName",
        render: (rowData) => (
          <>
            <p>{`${rowData.shopName} 
           ${rowData.userName} `}</p>
            <b>{rowData.shopType}</b>
          </>
        ),
      },

      {
        title: "Payment Status",
        field: "paymentStatus",
      },
      {
        title: "Razor Order ID",
        field: "razorpayOrderId",
      },
      {
        title: "Razor Payment ID",
        field: "razorpayPaymentId",
      },

      {
        title: "Delivery Method",
        field: "deliveryMethod",
      },
      {
        title: "Amount",
        field: "totalAmount",
      },

      {
        title: "Last Updated on",
        field: "statusUpdatedDate",
      },
      {
        title: "View Shop",
        field: "shopId",
        render: (rowData) => {
          return (
            <Button
              onClick={() => {
                getShopDetails(rowData.shopId);
              }}
            >
              View Shop
            </Button>
          );
        },
      },
    ],
    [paymentStatus, order]
  );
  return (
    <>
      <div className="flexContainer">
        <div className="vertical-space-10"></div>

        <div className="flexBoxRow">
          <div className="flexBoxHeader">
            <h2 className="orderHeaderText">Delivery Payment Tracker</h2>
          </div>
        </div>
        <div className="wrapContent">
          <div id="displayTable" className="flexBoxColumn">
            <div className="headerBox">
              <div className="filterRow">
                <label>Filter By</label>
                <select
                  onChange={(e) => setpaymentStatus(e.target.value)}
                  value={paymentStatus}
                >
                  <option value="initiated">Initiated</option>
                  <option value="paid">Paid</option>
                  <option value="failed">Failed</option>
                </select>
              </div>
              <div className="filterRow">
                <ul>
                  <li onClick={() => setDays("15")}>15</li>
                  <li onClick={() => setDays("30")}>30</li>
                  <li onClick={() => setDays("90")}>90</li>
                  <li onClick={() => setDays("180")}>180</li>
                  <li onClick={() => setDays("365")}>365</li>
                  <li onClick={() => setDays("700")}>700</li>
                </ul>
              </div>
            </div>
          </div>
          <div id="displayTable" className="flexBoxColumn">
            <MaterialTable
              title={`Payment Tracker`}
              columns={columns}
              data={order}
              style={{ width: "100%" }}
              isLoading={isLoading}
              options={{
                exportButton: true,
                pageSize: 20,
                rowStyle: { fontSize: 12 },
                pageSizeOptions: [5, 10, 20, 100, 1000, 500],
                grouping: false,
                filtering: false,
              }}
            />
          </div>
        </div>
        <Modal
          className="viewCartDetails"
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <ShopProfile shopDetails={shopDetails} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
