import { Row, Col, Container, Form, Button } from "react-bootstrap";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import React, { Component } from "react";
import config from "../config";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { createBrowserHistory } from "history";
import Resizer from "react-image-file-resizer";
const history = createBrowserHistory();

// Get the current location.
const location = history.location;
export default class CreateOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getShopdetailsToggle: true,
      // getShopdetailsToggle: false,
      userId10Digit: "",
      offer: {
        offerDetails: "",
        offerStartDate: "",
        offerStartTime: "",
        offerEndDate: "",
        offerEndTime: "",
        // offerImageLocation: "",
        offerOriginalPrice: "",
        offerNewPrice: "",
        offerDiscount: "",
        // offerCategory: "",
        // offerSubCategory: "",
        // offerTag: "",
        reviewedBy: "",
        offerTemplateType: "Default",
        offerSentStatus: "False",

        offerType: "offer",
        shopPincode: "",
        shopId: "",
        shopType: "",
        offerAddedDate: new Date().toISOString().substring(0, 10),
        custumerAddedOfferToFav: {},
        imageFile: [],
      },
      buttonDisabled: false,
      userInformation: {},
      imagePreviewUrl: [],
      remainingoffer: "",
      membershipExpiry: "",
      currentMembershipType: "",
    };
    this.reset = this.state;
    this.getShop = this.getShop.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.offerOnchange = this.offerOnchange.bind(this);
    this.handleimage = this.handleimage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.priceChange = this.priceChange.bind(this);
    this.discountCalculation = this.discountCalculation.bind(this);
    this.createoffersubmit = this.createoffersubmit.bind(this);
  }
  async componentDidMount() {
    try {
      const response = await fetch(`${config.json.URL}/imagesize.json`);
      const size = await response.json();
      this.setState({
        imageSize: size.BANNER,
      });
    } catch (error) {
      console.log(error);
    }
    await Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then((data) => {
      let user = { email: data.email, ...data.attributes };
      this.setState({
        offer: {
          ...this.state.offer,

          reviewedBy: user.phone_number,
        },
      });
    });

    try {
      const response = await fetch(`${config.json.URL}/fields.json`);
      const json = await response.json();

      this.setState({
        itemArray: json,
      });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate() {
    window.onpopstate = (e) => {
      e.preventDefault();
      if (window.confirm("are you want to leave the page")) {
        window.location.assign("/");
      } else {
        window.location.assign("/createoffer");
      }
    };
  }
  getShop(e) {
    // get shop information
    e.preventDefault();

    Promise.all([
      fetch(
        `${config.ApiGateway}/getCouponLimit`,

        {
          method: "POST",

          body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.setState({
          ...this.state,
          offer: {
            ...this.state.offer,
            shopId: data["shopId"],
            shopPincode: data["userPincode"],
            shopType: data["shopType"],
          }, // Store offer info

          userInformation: data, // store shop information
          remainingoffer: data["remainingoffer"], // How many offer pending to be created
          currentMembershipType: data["membershipType"], // get membership type
          membershipExpiry: data["membershipExpiry"], // get membership expiry date

          getShopdetailsToggle: false,
        });
      })
      .catch((err) => console.log(err));
  }
  createoffersubmit(e) {
    // To create offer
    e.preventDefault();

    if (this.state)
      this.setState({
        buttonDisabled: true,
      });
    Promise.all([
      fetch(
        `${config.ApiGateway}/createoffer`,

        {
          method: "POST",

          body: JSON.stringify(this.state.offer),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert(data.status); // get create offer status from backend
        this.setState(this.reset); // clears state
        this.componentDidMount(); // calls initial state
      })
      .catch((err) => console.log(err));
  }

  removeImage() {
    // Remove image from image upload
    this.setState({
      ...this.state,
      offer: {
        ...this.state.offer,
        imageFile: [],
      },
      imagePreviewUrl: [],
    });
  }
  handleimage(event) {
    // Extract image data from image upload
    this.setState({
      selectedFile: event.target.files[0],
    });

    let reader = new FileReader();
    let imageFormData = new FormData();
    imageFormData.append("photo", event.target.files[0]);

    console.log(
      event.target.files[0].size,
      Math.round(event.target.files[0].size / 1024) + " KB ",
      "get image size   --------------------"
    );

    Resizer.imageFileResizer(
      event.target.files[0], //is the file of the new image that can now be uploaded...
      300, // is the maxWidth of the  new image
      400, // is the maxHeight of the  new image
      "JPEG", // is the compressFormat of the  new image
      100, // is the quality of the  new image
      0, // is the rotatoion of the  new image
      (uri) => {
        this.setState({
          ...this.state,
          offer: {
            ...this.state.offer,

            imageFile: uri.replace(/^data:image\/\w+;base64,/, ""),
          },
        });
        this.setState({
          imagePreviewUrl: uri,
        });
      },
      "base64" // is the output type of the new image
    );
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  offerOnchange(e) {
    // Dynamic onchange for offer
    this.setState({
      ...this.state,
      offer: {
        ...this.state.offer,
        [e.target.name]: e.target.value,
      },
    });
  }
  priceChange(e) {
    // Price onchange
    this.setState(
      {
        ...this.state,
        offer: {
          ...this.state.offer,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        this.discountCalculation(); // calls function to calculate price discount
      }
    );
  }
  discountCalculation() {
    if (
      parseFloat(this.state.offer.offerOriginalPrice) <=
      parseFloat(this.state.offer.offerNewPrice)
    ) {
      this.setState({
        ...this.state,
        offer: { ...this.state.offer, offerNewPrice: "" },
      });
      return alert("OFFER PRICE SHOULD NE LESS THAN ORIGINAL PRICE");
    }
    if (
      this.state.offer.offerOriginalPrice !== "" &&
      this.state.offer.offerNewPrice !== ""
    ) {
      var originalPrice = parseFloat(this.state.offer.offerOriginalPrice);
      var newPrice = parseFloat(this.state.offer.offerNewPrice);
      var discount;

      discount = ((originalPrice - newPrice) / originalPrice) * 100;
      discount = Math.round(discount);
      this.setState({
        ...this.state,
        offer: {
          ...this.state.offer,
          offerDiscount: discount,
        },
      });
    } else {
      this.setState({
        ...this.state,
        offer: {
          ...this.state.offer,
          offerDiscount: 0,
        },
      });
    }
  }

  render() {
    return (
      <Container>
        <h3 style={{ textAlign: "center" }}>CREATE OFFER</h3>

        {this.state.getShopdetailsToggle ? (
          <>
            <Form onSubmit={this.getShop}>
              <Col md={{ span: 8, offset: 2 }}>
                <Form.Group as={Row}>
                  <Form.Label className="asterik" column sm="4">
                    ENTER SHOP ID
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      onChange={this.handleChange}
                      name="userId10Digit"
                      pattern="[0-9]{8,10}"
                      value={this.state.userId10Digit}
                      required
                    ></Form.Control>
                  </Col>
                </Form.Group>

                <Button style={{ float: "right" }} type="submit">
                  GET SHOP
                </Button>
              </Col>
            </Form>
          </>
        ) : (
          <>
            {this.state.remainingoffer <= 0 ? (
              <>
                <div className="listDetails">
                  <>
                    <strong>REMAINING OFFER COUNT</strong>
                    <h5>{this.state.remainingoffer}</h5>
                  </>
                </div>
                <div className="listDetails">
                  <strong>CURRENT MEMBERSHIP</strong>
                  <h5>{this.state.currentMembershipType}</h5>
                </div>
                <div className="listDetails">
                  <strong>MEMBERSHIP EXPIRY DATE</strong>
                  <h5>{this.state.membershipExpiry}</h5>
                </div>
                <div style={{ paddingLeft: 56 + "%" }}>
                  <Link to="/membership">
                    <Button>ACTIVATE MEMBERSHIP</Button>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <Row>
                  <Col md={{ span: 8 }}>
                    <Form onSubmit={this.createoffersubmit}>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          SHOP ID
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            type="text"
                            onChange={this.offerOnchange}
                            name="shopId"
                            disabled={true}
                            value={this.state.offer.shopId}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          USERNAME
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            type="text"
                            onChange={this.offerOnchange}
                            name="shopId"
                            disabled={true}
                            value={this.state.userInformation["userName"]}
                          ></Form.Control>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          PHONE NUMBER
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            type="text"
                            onChange={this.offerOnchange}
                            name="shopId"
                            disabled={true}
                            value={
                              this.state.userInformation["userPhoneNumber"]
                            }
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          SHOP NAME
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            type="text"
                            onChange={this.offerOnchange}
                            name="shopId"
                            disabled={true}
                            value={this.state.userInformation["shopName"]}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          SHOP ADDRESS
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            type="text"
                            onChange={this.offerOnchange}
                            name="shopId"
                            disabled={true}
                            value={this.state.userInformation["shopAddress"]}
                          ></Form.Control>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          SHOP TYPE
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            type="text"
                            onChange={this.offerOnchange}
                            name="shopType"
                            disabled={true}
                            value={this.state.offer.shopType}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          SHOP PINCODE
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            type="text"
                            onChange={this.offerOnchange}
                            name="shopPincode"
                            disabled={true}
                            value={this.state.offer.shopPincode}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          OFFER DETAILS
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            as="textarea"
                            rows="6"
                            onChange={this.offerOnchange}
                            name="offerDetails"
                            value={this.state.offer.offerDetails}
                            required
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          ORIGINAL PRICE
                        </Form.Label>
                        <Col md={{ span: 3 }}>
                          <Form.Control
                            type="text"
                            onChange={this.priceChange}
                            pattern="[0-9]{1,10}"
                            name="offerOriginalPrice"
                            value={this.state.offer.offerOriginalPrice}
                            placeholder="₹ 0.00"
                          ></Form.Control>
                        </Col>
                        <Form.Label column sm="3">
                          OFFER PRICE
                        </Form.Label>
                        <Col md={{ span: 3 }}>
                          <Form.Control
                            type="text"
                            pattern="[0-9]{1,10}"
                            onChange={this.priceChange}
                            name="offerNewPrice"
                            value={this.state.offer.offerNewPrice}
                            placeholder="₹ 0.00"
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          OFFER START DATE
                        </Form.Label>
                        <Col md={{ span: 3 }}>
                          <Form.Control
                            type="date"
                            onChange={this.offerOnchange}
                            name="offerStartDate"
                            value={this.state.offer.offerStartDate}
                            min={this.state.offer.offerAddedDate}
                          ></Form.Control>
                        </Col>
                        <Form.Label column sm="3">
                          OFFER START TIME
                        </Form.Label>
                        <Col md={{ span: 3 }}>
                          <Form.Control
                            type="time"
                            onChange={this.offerOnchange}
                            name="offerStartTime"
                            value={this.state.offer.offerStartTime}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          OFFER END DATE
                        </Form.Label>
                        <Col md={{ span: 3 }}>
                          <Form.Control
                            type="date"
                            onChange={this.offerOnchange}
                            name="offerEndDate"
                            value={this.state.offer.offerEndDate}
                            min={
                              this.state.offer.offerStartDate ||
                              this.state.offer.offerAddedDate
                            }
                            required
                          ></Form.Control>
                        </Col>
                        <Form.Label column sm="3">
                          OFFER END TIME
                        </Form.Label>
                        <Col md={{ span: 3 }}>
                          <Form.Control
                            type="time"
                            onChange={this.offerOnchange}
                            name="offerEndTime"
                            value={this.state.offer.offerEndTime}
                            required
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label classname="asterik" column sm="3">
                          UPLOAD IMAGE
                        </Form.Label>
                        <Col>
                          <input
                            type="file"
                            onChange={this.handleimage}
                          ></input>
                        </Col>
                      </Form.Group>

                      <Button
                        disabled={this.state.buttonDisabled}
                        type="submit"
                        color="primary"
                      >
                        SUBMIT
                      </Button>
                    </Form>
                  </Col>

                  <Col>
                    {this.state.imagePreviewUrl.length !== 0 && (
                      <div>
                        <img
                          src={this.state.imagePreviewUrl}
                          alt={this.state.imagePreviewUrl}
                          width="300"
                          height="180"
                        ></img>
                        <Button
                          onClick={this.removeImage}
                          variant="outline-light"
                        >
                          <CancelOutlinedIcon
                            style={{
                              fontSize: 20,
                              backgroundColor: "red",
                              position: "absolute",
                            }}
                          />
                        </Button>
                      </div>
                    )}
                    <div
                      style={{
                        display: "inline",
                        width: 100 + "px",
                      }}
                    >
                      <strong>REMAINING OFFER COUNT</strong>
                      <h5>{this.state.remainingoffer}</h5>
                    </div>

                    <strong>CURRENT MEMBERSHIP</strong>
                    <h5>{this.state.currentMembershipType}</h5>

                    <strong>MEMBERSHIP EXPIRY DATE</strong>
                    <h5>{this.state.membershipExpiry}</h5>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Container>
    );
  }
}
