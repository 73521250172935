import React, { Component } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { CSVLink } from "react-csv";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import config from "../../config";
export default class MerchantCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCollection: "",
      totalLive: "",
      totalRejected: "",
      stats: [],
      showData: false,
      totalShops: [],
      allShops: [],
      Topmerchant: [],
      headers: [],
      data: [],
      sortDate: this.props.dates,
      totalDraft: "",
      totalExpired: "",
      totalDeleted: "",
      totalNonExpired: "",
    };
    this.renderFunction = this.renderFunction.bind(this);
    this.totalStats = this.totalStats.bind(this);
    this.zeroStats = this.zeroStats.bind(this);
    this.export = this.export.bind(this);
    this.csvLink = React.createRef();
    this.Zeroexport = this.Zeroexport.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    var tempCollection = [];
    var live = 0;
    var temp;
    var rejected = 0;
    var deleted = 0;
    var draft = 0;
    let nonExpired = 0;
    var expired = 0;
    var tempData = [];
    var userTableid = [];
    var collectionShops = [];
    let today = new Date().toISOString().substring(0, 10);
    this.props.collection.map((element) => {
      // filter collection
      if (element.offerType === "item") {
        tempCollection.push(element);
        if (element.offerStatus === "Live") {
          if (element.offerEndDate !== undefined) {
            if (today < element.offerEndDate) {
              live++; // count live collection
            } else {
              expired++;
            }
          } else {
            nonExpired++; // count expired offer
          }
        } else if (element.offerStatus === "Rejected") {
          rejected++;
        } else if (element.offerStatus === "Deleted") {
          deleted++;
        } else if (element.offerStatus === "Draft") {
          draft++;
        }
      }
    });
    userTableid = tempCollection.map((element) => element.shopId);

    fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        query: {
          bool: {
            must: [{ match: { userType: "Shop" } }],
            must_not: {
              bool: {
                must: [{ term: { userPincode: "111111" } }],
              },
            },
          },
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        tempData = data.hits.hits.map((element) => element._source);
        // Get user details
        tempData.forEach((items) =>
          collectionShops.push({
            userId: items.userId,
            userId10Digit: items.userId10Digit,
            shopName: items.shopName,
            shopType: items.shopType,
            shopAddress: items.shopAddress,
            userPincode: items.userPincode,
            userPhoneNumber: items.userPhoneNumber,
            userName: items.userName,
          })
        );
      });

    this.setState(
      {
        totalCollection: tempCollection.length, //Total collection
        totalLive: live, // live collection
        totalRejected: rejected, // Rejected collection
        allShops: collectionShops, //All shops
        totalDraft: draft, // Pending collection
        totalDeleted: deleted, //Deleted collection
        totalExpired: expired, // Expired
        totalNonExpired: nonExpired, // Non expired
      },
      () => {
        this.renderFunction(tempCollection, this.props.shop);
      }
    );
  }
  renderFunction(collections, shops) {
    var collectionDetails = [];
    var shopDetails = [];
    var totalColletionStatistics = [];
    var zero_ten = [];
    var zeroOffers = [];
    var lessThanFifty = [];
    var lessThanNinetyOffers = [];
    var greaterThanHundred = [];
    var zero = 0;
    var ten = 0;
    var fifty = 0;
    var ninety = 0;
    var hundred = 0;
    shops.map((element) => {
      var count = 0;
      collections.map((items) => {
        if (element === items.shopId) {
          count++;
        }
      });
      collectionDetails.push({
        shopId: element,
        totalCollection: count,
      });
    });
    // Get collection posted by shops or count collection between ranges
    collectionDetails.map((element) => {
      if (element.totalCollection >= 1 && element.totalCollection <= 9) {
        zero_ten.push(element);
        ten++;
      } else if (element.totalCollection === 0) {
        zeroOffers.push(element);
        zero++;
      } else if (
        element.totalCollection <= 50 &&
        element.totalCollection >= 10
      ) {
        lessThanFifty.push(element);
        fifty++;
      } else if (
        element.totalCollection >= 51 &&
        element.totalCollection <= 99
      ) {
        lessThanNinetyOffers.push(element);
        ninety++;
      } else if (element.totalCollection >= 100) {
        greaterThanHundred.push(element);
        hundred++;
      }
    });

    totalColletionStatistics.push(
      {
        name: "0",
        value: zero, //0
      },
      {
        name: "1-9",
        value: ten, // 1-9  collection posted
      },
      {
        name: "10-50",
        value: fifty, // 10-50  collection posted
      },
      {
        name: "51-99",
        value: ninety, // 51- 99  collection posted
      },
      {
        name: "100-500",
        value: hundred, /// more than 100  collection posted
      }
    );
    shopDetails.push(
      zeroOffers,
      zero_ten,
      lessThanFifty,
      lessThanNinetyOffers,
      greaterThanHundred
    );

    var topShop = collectionDetails
      .sort((a, b) => b.totalCollection - a.totalCollection)
      .slice(0, 10);

    this.setState({
      stats: totalColletionStatistics,
      showData: true,
      totalShops: shopDetails,
      Topmerchant: topShop,
    });
  }
  totalStats(collections) {
    var arr = [];
    collections.map((element) => {
      this.state.allShops.map((items) => {
        if (element.shopId === items.userId) {
          arr.push(Object.assign({}, element, items));
        }
      });
    });
    this.props.info(arr, "TOTAL OFFERS");
  }

  zeroStats(collections, toggleState) {
   
    var dummy = [];
    var arr = [];
    var tempData = [];
    collections.map((element) => {
      dummy.push(element.shopId);
    });

    fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        query: {
          constant_score: {
            filter: {
              bool: {
                should: [{ terms: { userId: dummy } }],
              },
            },
          },
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        tempData = data.hits.hits.map((element) => element._source);
        tempData.map((items) =>
          arr.push({
            userId: items.userId,
            userId10Digit: items.userId10Digit,
            shopName: items.shopName,
            shopType: items.shopType,
            shopAddress: items.shopAddress,
            userPincode: items.userPincode,
            userPhoneNumber: items.userPhoneNumber,
            userName: items.userName,
          })
        );
        var decision = toggleState
          ? this.props.info(arr, "TOTAL OFFERS")
          : this.Zeroexport(arr);
      });
  }
  Zeroexport(response) {
    console.log(response);
    const headers = [
      { label: "SHOP ID", key: "userId10Digit" },
      { label: "SHOP NAME", key: "shopName" },
      { label: "SHOP TYPE", key: "shopType" },
      { label: "TOTAL COLLECTION", key: "totalcollection" },
      { label: "ADDRESS", key: "shopAddress" },
      { label: "USERNAME", key: "userName" },
      { label: "PHONE NUMBER", key: "userPhoneNumber" },
      { label: "PINCODE", key: "userPincode" },
    ];
    this.setState(
      {
        data: response,
        headers: headers,
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }

  export(response) {
    var arr = [];
    response.map((element) => {
      this.state.allShops.map((items) => {
        if (element.shopId === items.userId) {
          arr.push(Object.assign({}, element, items));
        }
      });
    });

    const headers = [
      { label: "SHOP ID", key: "userId10Digit" },
      { label: "SHOP NAME", key: "shopName" },
      { label: "SHOP TYPE", key: "shopType" },
      { label: "TOTAL COLLECTION", key: "totaloffers" },
      { label: "ADDRESS", key: "shopAddress" },
      { label: "USERNAME", key: "userName" },
      { label: "PHONE NUMBER", key: "userPhoneNumber" },
      { label: "PINCODE", key: "userPincode" },
    ];

    this.setState(
      {
        data: arr,
        headers: headers,
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  onChange(e) {
    var value = e.target.value;
    var name = e.target.name;

    this.setState(
      {
        sortDate: value,
      },
      () => {
        this.props.sortbyDate(this.state.sortDate);
      }
    );
  }
  render() {
    return (
      <>
        <Typography variant="h2" style={{ textAlign: "center" }} gutterBottom>
          {this.props.title}
        </Typography>
        <Row>
          <Col md={{ span: 6 }}>
            <Form.Label>SORT BY DATE</Form.Label>
            <Form.Control
              type="date"
              name="sortDate"
              value={this.state.sortDate}
              onChange={this.onChange}
            ></Form.Control>
          </Col>
        </Row>

        {this.state.showData && (
          <>
            <Row>
              <Col md={{ span: 3 }} className="division">
                <strong>
                  <span style={{ textAlign: "center" }}>TOTAL COLLECTION</span>
                </strong>
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  {this.state.totalCollection}
                </h3>
              </Col>
              <Col md={{ span: 3, offset: 1 }} className="division">
                <strong>TOTAL LIVE</strong>
                <h3 style={{ textAlign: "center" }}> {this.state.totalLive}</h3>
              </Col>
              <Col md={{ span: 3, offset: 1 }} className="division">
                <strong>TOTAL REJECTED</strong>
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  {this.state.totalRejected}
                </h3>
              </Col>
              <Col md={{ span: 3 }} className="division">
                <strong>ON DRAFT</strong>
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  {this.state.totalDraft}
                </h3>
              </Col>
              <Col md={{ span: 3, offset: 1 }} className="division">
                <strong>TOTAL DELETED</strong>
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  {this.state.totalDeleted}
                </h3>
              </Col>
              <Col md={{ span: 3, offset: 1 }} className="division">
                <strong>TOTAL EXPIRED</strong>
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  {this.state.totalExpired}
                </h3>
              </Col>
              <Col md={{ span: 3 }} className="division">
                <strong>TOTAL NON-EXPIRED</strong>
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  {this.state.totalNonExpired}
                </h3>
              </Col>
            </Row>
            <Row className="division">
              <BarChart
                width={600}
                height={300}
                data={this.state.stats}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  label={{
                    value: "OFFER RANGE",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <Legend />

                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </Row>
            <Row className="division">
              <Col>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid",
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">COLLECTION RANGE</TableCell>
                      <TableCell align="center">TOTAL SHOPS</TableCell>
                      <TableCell align="center">VIEW SHOP DETAILS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH ZERO COLLECTION
                      </TableCell>
                      <TableCell align="center">
                        {this.state.stats[0].value}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.zeroStats(this.state.totalShops[0], true);
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.zeroStats(this.state.totalShops[0], false);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">2</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH 1-9 COLLECTION
                      </TableCell>
                      <TableCell align="center">
                        {this.state.stats[1].value}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.totalStats(this.state.totalShops[1]);
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(this.state.totalShops[1]);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">3</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS COLLECTION BETWEEN 10 -50 COLLECTION
                      </TableCell>
                      <TableCell align="center">
                        {this.state.stats[2].value}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.totalStats(this.state.totalShops[2]);
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(this.state.totalShops[2]);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">4</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH 51 - 99 COLLECTION
                      </TableCell>
                      <TableCell align="center">
                        {this.state.stats[3].value}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.totalStats(this.state.totalShops[3]);
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(this.state.totalShops[3]);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">5</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH MORE THAN 100 COLLECTION
                      </TableCell>
                      <TableCell align="center">
                        {this.state.stats[4].value}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.totalStats(this.state.totalShops[4]);
                            }}
                          >
                            View
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(this.state.totalShops[4]);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Col>
            </Row>
            <Row className="division">
              <Col>
                <h4 style={{ textAlign: "center" }}>TOP 10 MERCHANT</h4>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    this.totalStats(this.state.Topmerchant);
                  }}
                >
                  VIEW
                </Button>
              </Col>

              <Col>
                <Button
                  onClick={() => {
                    this.export(this.state.Topmerchant);
                  }}
                >
                  DOWNLOAD
                </Button>
              </Col>
            </Row>
            <Row>
              <CSVLink
                enclosingCharacter={`'`}
                data={this.state.data}
                headers={this.state.headers}
                ref={this.csvLink}
                target="_blank"
              />
            </Row>
          </>
        )}
      </>
    );
  }
}
