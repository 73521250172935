import React, { useEffect, useState } from "react";
import DisplayTable from "./displayTable";
import { getLoyaltyCustomers, ActivatedLoyaltyShops } from "./service";
import StorefrontIcon from "@material-ui/icons/Storefront";
export default function Loyalty() {
  const [loyaltyProgram, setLoyaltyProgram] = useState([]);
  const [activatedCount, setActivatedCount] = useState(0);
  useEffect(() => {
    // Call class on mounting
    async function getData() {
      await getLoyalty();
    }
    getData();
  }, []);

  const getLoyalty = async () => {
    // To get cutomers who added loyalty program
    await getLoyaltyCustomers().then(async (data) => {
      let uniqueCustomer = {};
      data.map((items) => {
        if (uniqueCustomer[items.shopId] == undefined) {
          uniqueCustomer[items.shopId] = { customer: [] };
        }
        // concat customer to shop object and remove duplicate
        uniqueCustomer[items.shopId]["customer"] = [
          ...new Set([
            ...uniqueCustomer[items.shopId]["customer"],
            items.custId,
          ]),
        ];
      });
      // Get Loyalty points configured shops
      let shops = await ActivatedLoyaltyShops();

      // Merge customers and shops

      let mergeShop = shops.map((elements) => {
        let getShop = Object.keys(uniqueCustomer).find(
          (items) => elements.userId == items
        );

        if (typeof getShop != "undefined") {
          return Object.assign(
            {},
            { customer: uniqueCustomer[getShop].customer.length },
            elements
          );
        } else return Object.assign({}, { customer: 0 }, elements);
      });

      setLoyaltyProgram(mergeShop);
      setActivatedCount(shops.length);
    });
  };

  return (
    <>
      <div className="titleHeader">
        <h4>Loyalty</h4>
      </div>
      <div className="flexRow">
        <div className="labelWrapper">
          <h5>Shops in Loyalty Program</h5>
        </div>

        <>
          <div id="offer" className="flexColumn">
            <div className="flexCard">
              <div className="flexIcon">
                <StorefrontIcon />
              </div>
              <div className="flexContent">
                <h5> {"Shops"}</h5>
                <h4>{activatedCount}</h4>
              </div>
            </div>
          </div>
        </>
      </div>
      <div className="flexRow">
        <div className="labelWrapper">
          <h5>Shops</h5>
        </div>
        <div id="displayTable" className="flexColumn">
          <DisplayTable
            data={loyaltyProgram}
            validKeys={[
              "userId10Digit",
              "shopName",
              "userName",
              "shopType",
              "shopAddress",
              "loyaltyPackage",
              "loyaltyExpiryDate",
              "loyaltyAmount",
              "loyaltyPoints",
              "loyaltyRatio",
              "customer",
            ]}
            title="Loyalty Program"
          />
        </div>
      </div>
    </>
  );
}
