import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { OnlineGhoshakCoupons } from "./couponFields";
export default function GlobalModal({
  openModal,
  closeModal,
  typename,
  defaultValue,
}) {
  const [renderBody, setRenderBody] = useState(null);
  // console.log(OnlineGhoshakCoupons, "name");
  useEffect(() => {
    // console.log("called", typename, defaultValue);
  }, [typename, defaultValue]);
  return (
    <>
      <Modal
        className="modal-order-coupon"
        show={openModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sample Header</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <h1>modal body</h1>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
