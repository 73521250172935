import React, { Component } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import config from "../config";
import { Link } from "react-router-dom";
export default class ReachUsCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusUnread: [],
      showData: true,
      viewDetails: [],
      userId: "",
      statusRead: [],
      messageToggle: [],
      titleMessage: "",
      showTable: false,
      listMessageStatus: "",
      messagemapDetails: {
        messageId: "",
        messageAddedDate: "",
        messageDetails: "",
        messageStatus: "",
        shopName: ""
      }
    };
    this.reset = this.state;
    this.viewStatusList = this.viewStatusList.bind(this);
    this.viewDetails = this.viewDetails.bind(this);
    this.messageUpdate = this.messageUpdate.bind(this);
    this.listFunction = this.listFunction.bind(this);
    this.renderAfterSubmit = this.renderAfterSubmit.bind(this);
  }
  viewStatusList(e) {
    var value = e.target.value;
    var toggle = [];
    var storeState =
      value === "statusRead" ? this.state.statusRead : this.state.statusUnread;

    toggle = storeState === undefined ? [] : storeState;
    var title = value === "statusRead" ? "READ MESSAGES" : "UNREAD MESSAGE";
    this.setState({
      messageToggle: toggle,
      titleMessage: title,
      showTable: true,
      listMessageStatus: value
    });
  }
  renderAfterSubmit() {
    var toggle = [];
    var storeState =
      this.state.listMessageStatus === "statusRead"
        ? this.state.statusRead
        : this.state.statusUnread;

    toggle = storeState === undefined ? [] : storeState;
    var title =
      this.state.listMessageStatus === "statusRead"
        ? "READ MESSAGES"
        : "UNREAD MESSAGE";
    this.setState({
      messageToggle: toggle,
      titleMessage: title,
      showTable: true
    });
  }
  async componentDidMount() {
    await this.listFunction();
  }
  listFunction() {
    var messageRead = [];
    var message = [];

    Promise.all([
      fetch(
        `${config.ApiGateway}/reachuscustomer`,

        {
          method: "POST"
        }
      )
    ])
      .then(([res2]) => Promise.all([res2.json()]))
      .then(([data1]) => {
        data1.forEach(element => {
          if (element.userType === "Customer") {
            for (var messageMapid in element.messageDetailsMap) {
              if (
                Object.prototype.toString
                  .call(element.messageDetailsMap[messageMapid])
                  .slice(8, -1) === "Object"
              ) {
                if (
                  element.messageDetailsMap[messageMapid].messageStatus ===
                  "Unread"
                ) {
                  message.push({
                    userId: element.userId,
                    userId10Digit: element.userId10Digit,
                    messageId: messageMapid,
                    messageAddedDate:
                      element.messageDetailsMap[messageMapid].messageAddedDate,
                    messageDetails:
                      element.messageDetailsMap[messageMapid].messageDetails,
                    messageStatus:
                      element.messageDetailsMap[messageMapid].messageStatus,
                    userName: element.userName,
                    userPincode: element.userPincode,
                    userPhoneNumber: element.userPhoneNumber
                  });
                } else {
                  messageRead.push({
                    userId: element.userId,
                    userId10Digit: element.userId10Digit,
                    messageId: messageMapid,
                    messageAddedDate:
                      element.messageDetailsMap[messageMapid].messageAddedDate,
                    messageDetails:
                      element.messageDetailsMap[messageMapid].messageDetails,
                    messageStatus:
                      element.messageDetailsMap[messageMapid].messageStatus,
                    userPhoneNumber: element.userPhoneNumber,
                    userName: element.userName,
                    userPincode: element.userPincode
                  });
                }
                // }
              }
            }
          }
        });

        this.setState({
          statusRead: messageRead,
          statusUnread: message,
          showData: true
        });
        if (this.state.listMessageStatus !== "") {
          this.renderAfterSubmit();
        }
      });
  }

  viewDetails(details) {
    // details.map(items =>
    this.setState(
      {
        ...this.state,
        messagemapDetails: {
          ...this.state.messagemapDetails,
          userId: details.userId,

          messageId: details.messageId,
          messageAddedDate: details.messageAddedDate,
          messageStatus: details.messageStatus,

          shopName: details.shopName,
          messageDetails: details.messageDetails
        }
      },
      () => {
        this.messageUpdate();
      }
    );
    // );
  }
  messageUpdate() {
    fetch(`${config.ApiGateway}/reachusMessageUpdate`, {
      method: "POST",
      body: JSON.stringify(this.state.messagemapDetails),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(data => {
        alert(data.result);

        this.setState(
          {
            messageToggle: []
          },
          () => {
            this.listFunction();
          }
        );
      })
      .catch(err => {
        alert(err);
      });
  }

  render() {
    return (
      <Container fluid>
        {this.state.showData && (
          <>
            <div style={{ width: 100 + "%", marginBottom: 5 + "px" }}>
              <h3 style={{ textAlign: "center" }}>REACH US INBOX - CUSTOMER</h3>
            </div>
            <Row>
              <Col xs={8} style={{ float: "none", margin: "auto" }}>
                <Form>
                  <Form.Row>
                    <Col>
                      <Form.Label>INBOX</Form.Label>
                    </Col>
                    <Col>
                      <Form.Check
                        label="READ MESSAGE"
                        type="radio"
                        name="listMessageStatus"
                        onChange={this.viewStatusList}
                        value="statusRead"
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        label="UNREAD MESSAGE"
                        type="radio"
                        name="listMessageStatus"
                        onChange={this.viewStatusList}
                        value="statusUnread"
                      />
                    </Col>
                  </Form.Row>
                </Form>
              </Col>
            </Row>

            {this.state.showTable && (
              <>
                <Row className="division">
                  <div
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%"
                    }}
                  >
                    <Col>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.titleMessage}
                      </h3>
                    </Col>
                  </div>
                  <Table
                    style={{
                      width: 100 + "%",
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid"
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">S NO</TableCell>
                        <TableCell align="center">USER ID</TableCell>
                        <TableCell align="center">USER NAME</TableCell>
                        <TableCell align="center">PINCODE</TableCell>
                        <TableCell align="center">PHONE NUMBER</TableCell>
                        <TableCell align="center">MESSAGE</TableCell>
                        <TableCell align="center">MESSAGE ID</TableCell>
                        <TableCell align="center">POSTED ON</TableCell>
                        <TableCell align="center">ACTION</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.messageToggle.map((items, index) => (
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            <Link
                              to={{
                                pathname: "/customer",
                                search: `?userid=${items.userId10Digit}`
                              }}
                            >
                              {" "}
                              {items.userId10Digit}
                            </Link>
                          </TableCell>
                          <TableCell align="center">{items.userName}</TableCell>
                          <TableCell align="center">
                            {items.userPincode}
                          </TableCell>
                          <TableCell align="center">
                            {items.userPhoneNumber}
                          </TableCell>
                          <TableCell align="center">
                            {items.messageDetails}
                          </TableCell>
                          <TableCell align="center">
                            {items.messageId}
                          </TableCell>

                          <TableCell align="center">
                            {items.messageAddedDate}
                          </TableCell>

                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                this.viewDetails(items);
                              }}
                            >
                              MARK AS READ
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Row>
              </>
            )}
          </>
        )}
      </Container>
    );
  }
}
