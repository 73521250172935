import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import config from "../config";
import MaterialTable from "material-table";
import { useParams, Link, useHistory } from "react-router-dom";

export default function PartnerDashboard() {
  const [data, setData] = useState([]);
  const history = useHistory();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/viewPartner`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setData(data);
      })
      .catch((err) => console.log("error getting in view partner", err));
  };
  const removeAccount = async (rowData) => {
    let input = {};
    input = rowData;
    input.typename = "deletepartneraccount"; //  lambda pass delete

    await Promise.all([
      fetch(`${config.ApiGateway}/createAccount`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        if (data.errorType == "UserNotFoundException") {
          alert(data.errorMessage);
        }
        getData();
      })
      .catch((err) => console.log("error getting data", err));
  };
  const viewStatement = (input) => {
    let { userId } = input;
    history.push("/managingDirector/report");
  };
  return (
    <>
      <Container>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Partner</h2>
        </div>
        <div className="vertical-space-40"></div>

        <Row>
          <Col sm="12">
            <MaterialTable
              title={`Partner Dashboard`}
              columns={[
                {
                  title: "Used Id",
                  field: "userId10Digit",
                },
                { title: "Name", field: "userName" },
                { title: "Number", field: "userPhoneNumber" },
                { title: "Pincode", field: "userPincode" },
                { title: "Address", field: "userAddress" },
                { title: "Added Date", field: "userAddedDate" },
                // {
                //   title: "Statement",
                //   field: "Statement",
                //   render: (rowData) => (
                //     <Button onClick={() => viewStatement(rowData)}>
                //       View Statement
                //     </Button>
                //   ),
                // },
                {
                  title: "Login Link",
                  field: "loginLink",
                  render: (rowData) => {
                    return (
                      <>
                        <Link
                          to={`http://${window.location.host}/login?phone=${rowData.userPhoneNumber}&pass=pass1234`}
                        >
                          {`http://${window.location.host}/login?phone=${rowData.userPhoneNumber}&pass=pass1234`}
                        </Link>
                      </>
                    );
                  },
                },

                {
                  title: "Action",
                  field: "action",
                  render: (rowData) => (
                    <Link
                      to={{
                        pathname: "/managingDirector/viewpartner",
                        search: `?userid=${rowData.cognitoId}`,
                      }}
                    >
                      <Button>View Partner</Button>
                    </Link>
                  ),
                },
                {
                  title: "Access",
                  field: "access",
                  render: (rowData) => (
                    <Button
                      onClick={() => removeAccount(rowData)}
                      variant="danger"
                    >
                      Remove Account
                    </Button>
                  ),
                },
              ]}
              data={data}
              options={{
                exportButton: true,
                pageSize: 30,
                pageSizeOptions: [5, 10, 20, 100, 300, 500, 1000, 2000],
                grouping: false,
                filtering: false,
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
