import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import config from "../../config";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId10Digit: "",
      showData: true,
      refferers: [],
      coupons: [],
      shops: [],
      items: [],
      transactionHistory: [],
      offers: [],
      customerPhoneNumber: "",
      customerPincode: "",
      page: 0,
      rowsPerPage: 10,
      sortDate: "2010-01-01",
      couponConsumed: {},
      customerInformation: [],
      couponsHistory: {},
      customerPoints: {},
      addedFavourites: {},
    };
    this.customerSearch = this.customerSearch.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fetchDetails = this.fetchDetails.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.transactionHistory = this.transactionHistory.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.dateonchange = this.dateonchange.bind(this);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  dateonchange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.transactionHistory(this.state.userId10Digit);
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  async componentDidMount() {
    if (this.props.user !== null && this.props.user !== undefined) {
      await this.transactionHistory();
    }
  }
  transactionHistory(userId10Digit) {
    var favouriteShops = [];

    var userId = "";
    var referralCode = "";
    var tempdata = [];
    var phone = "";
    var pincode = "";

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            match: { userId10Digit: this.props.user || userId10Digit },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map((element) => element._source);
        let userFav = [];
        tempdata.forEach((element) => {
          favouriteShops.push(Object.keys(element.favoriteShops));

          Object.entries(element.favoriteItems).forEach(([offerId, value]) => {
            userFav.push(offerId);
          });
          Object.entries(element.favoriteOffers).forEach(([offerId, value]) => {
            userFav.push(offerId);
          });
          userId = element.userId;
          referralCode = element.referralCode;
          phone = element.userPhoneNumber;
          pincode = element.userPincode;
        });
        this.setState({
          customerPhoneNumber: phone,
          customerPincode: pincode,
          customerInformation: tempdata,
        });

        this.fetchDetails(
          userId,

          favouriteShops,
          referralCode,
          userFav
        );
      })
      .catch((err) => console.log(err));
  }
  customerSearch(e) {
    var favouriteOffers = [];
    var favouriteShops = [];
    var favouriteItems = [];
    var userId = "";
    var referralCode = "";
    var tempdata = [];
    e.preventDefault();

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              should: [
                {
                  term: {
                    userPhoneNumber: this.state.userId10Digit,
                  },
                },
                {
                  term: {
                    userId10Digit: this.state.userId10Digit,
                  },
                },
                {
                  term: {
                    userId: this.state.userId10Digit,
                  },
                },
              ],
              minimum_should_match: 1,
              filter: {
                match: {
                  userType: "Customer",
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map((element) => element._source);
        var phone = "";
        var pincode = "";
        let userFav = [];
        tempdata.forEach((element) => {
          favouriteShops.push(Object.keys(element.favoriteShops));
          Object.entries(element.favoriteItems).forEach(([offerId, value]) => {
            userFav.push(offerId);
          });
          Object.entries(element.favoriteOffers).forEach(([offerId, value]) => {
            userFav.push(offerId);
          });

          userId = element.userId;
          referralCode = element.referralCode;
          phone = element.userPhoneNumber;
          pincode = element.userPincode;
        });

        this.setState({
          customerPhoneNumber: phone,
          customerPincode: pincode,
          customerInformation: tempdata,
        });

        this.fetchDetails(
          userId,

          favouriteShops,
          referralCode,
          userFav
        );
      })
      .catch((err) => alert("enter valid customer 10 digit "));
  }

  fetchDetails(
    userId,

    favouriteShops,
    referralCode,
    userFav
  ) {
    var totalRefferes = [];
    var tempdata = [];
    var tempdata3 = [];

    var tempdata4 = [];

    var tempdata6 = [];
    var shopDetails = [];

    var couponDetails = [[], [], []];
    let getShop = [];

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/coupons/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              filter: [
                {
                  match: {
                    custId: userId,
                  },
                },
              ],
              must_not: [
                {
                  match: {
                    pincode: "111111",
                  },
                },
              ],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              filter: [
                {
                  terms: {
                    userId: favouriteShops[0],
                  },
                },
              ],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            match: {
              referredBy: referralCode,
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
      fetch(`${config.Elasticsearch.URL}/points/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              filter: [
                {
                  term: {
                    custId: userId,
                  },
                },
                {
                  range: {
                    transactionDate: {
                      gte: this.state.sortDate,
                    },
                  },
                },
              ],
              must: [
                {
                  terms: {
                    pointsType: ["ghoshak", "loyalty"],
                  },
                },
              ],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
      fetch(`${config.Elasticsearch.URL}/offers/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              filter: [
                {
                  terms: {
                    offerId: userFav,
                  },
                },
              ],
              must_not: [{ match: { shopPincode: "111111" } }],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1, res3, res4, res6, res7]) =>
        Promise.all([
          res1.json(),

          res3.json(),
          res4.json(),

          res6.json(),
          res7.json(),
        ])
      )
      .then(([redeemedCoupons, data3, data4, points, favouritesData]) => {
        tempdata = redeemedCoupons.hits.hits.map((element) => element._source);
        let tempFav = favouritesData.hits.hits.map(
          (element) => element._source
        );

        tempdata3 = data3.hits.hits.map((element) => element._source);
        tempdata4 = data4.hits.hits.map((element) => element._source);

        tempdata6 = points.hits.hits.map((element) => element._source);

        tempdata3.forEach((elements) => {
          shopDetails.push({
            userId10Digit: elements.userId10Digit,
            shopPincode: elements.userPincode,
            shopPhonenumber: elements.userPhoneNumber,
            shopkepperName: elements.userName,
            shopType: elements.shopType,
          });
        });

        tempFav.forEach((elements) => {
          getShop.push(elements.shopId);
        });

        tempdata4.forEach((elements) => {
          totalRefferes.push({
            userId: elements.userId,
            userPincode: elements.userPincode,
            userPhoneNumber: elements.userPhoneNumber,
            userName: elements.userName,
            userId10Digit: elements.userId10Digit,
          });
        });

        tempdata.forEach((elements) => {
          couponDetails[0].push(elements);
          couponDetails[2].push(elements.redeemedCouponId);
          getShop.push(elements.shopId);
        });

        var customerPoints = {};

        var transactionDetails = [];
        tempdata6.forEach((elements) => {
          if (customerPoints[elements.pointsType] === undefined) {
            customerPoints[elements.pointsType] = {
              pointsTimeline: [],
            };
          }

          if (
            customerPoints[elements.pointsType][elements.pointsAction] ===
            undefined
          ) {
            customerPoints[elements.pointsType][
              elements.pointsAction
            ] = parseFloat(elements.points);
          } else {
            customerPoints[elements.pointsType][
              elements.pointsAction
            ] += parseFloat(elements.points);
          }
          if (elements.shopId !== undefined) {
            getShop.push(elements.shopId);
          }
          transactionDetails.push({
            transactionId: elements.transactionId,
            pointsAction: elements.pointsAction,
            points: elements.points,
            pointsType: elements.pointsType,
            transactionDate: elements.transactionDate,
            shopId: elements.shopId,
            transactionDetails: elements.transactionDetails,
          });
        });

        this.setState({
          refferers: totalRefferes,
          shops: shopDetails,

          customerPoints: customerPoints,
        });
        console.log(transactionDetails.length);
        this.fetchUsers(
          couponDetails,
          getShop,
          tempFav,

          transactionDetails
        );
      })
      .catch((err) => alert("enter valid customer  10digit"));
  }
  fetchUsers(
    couponDetails,
    getShop,
    tempFav,

    transactionDetails
  ) {
    var tempdata = [];
    var couponShopdetails = [];

    var shopsUserid = [...new Set(getShop)];

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            constant_score: {
              filter: {
                bool: {
                  should: [{ terms: { userId: shopsUserid } }],
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
      fetch(`${config.Elasticsearch.URL}/coupons/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            constant_score: {
              filter: {
                bool: {
                  should: [{ terms: { couponId: couponDetails[2] } }],
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, redeemed]) => {
        tempdata = data.hits.hits.map((element) => element._source);
        let tempdata1 = redeemed.hits.hits.map((element) => element._source);
        let addedtoFav = tempFav.map((elements) => {
          let count = tempdata.find((item) => item.userId === elements.shopId);
          let appendObject = {};

          appendObject.offerId = elements.offerId;
          appendObject.offerDetails = elements.offerDetails;
          appendObject.shopPincode = elements.shopPincode;
          appendObject.shopType = elements.shopType;
          appendObject.offerType = elements.offerType;
          appendObject.offerNewPrice =
            elements.offerNewPrice !== undefined
              ? elements.offerNewPrice
              : "not defined";
          appendObject.offerOriginalPrice =
            elements.offerOriginalPrice !== undefined
              ? elements.offerOriginalPrice
              : "not defined";

          appendObject.userId10Digit = count.userId10Digit;

          return appendObject;
        });

        let favObj = {};
        addedtoFav.forEach((elements) => {
          if (favObj[elements.offerType] === undefined) {
            favObj[elements.offerType] = { details: [] };
          }

          favObj[elements.offerType].details.push(elements);
        });

        let couponConsumed = {};
        let couponCreated = {};
        tempdata1.forEach((elements) => {
          if (couponConsumed[elements.couponType] === undefined) {
            couponConsumed[elements.couponType] = 1;
          } else {
            couponConsumed[elements.couponType] += 1;
          }
          if (couponCreated[elements.couponType] === undefined) {
            couponCreated[elements.couponType] = [];
          }

          couponCreated[elements.couponType].push({
            couponId: elements.couponId,
            userId10Digit: elements.userId10Digit,
            couponDetails: elements.couponDetails,
            couponAmount: elements.maximumCouponAmount,
          });
        });
        let mergeShopName = [];
        transactionDetails.map((headers) => {
          if (headers.shopId !== undefined) {
            let getShopName = tempdata.find(
              (key) => key.userId === headers.shopId
            );
            let appendObject = {};
            if (getShopName !== undefined) {
              appendObject = Object.assign({}, headers, {
                shopName: getShopName.shopName,

                shopUserId: getShopName.userId10Digit,
                shopkeeperName: getShopName.userName,
                phonenumber: getShopName.userPhoneNumber,
                shopAddress: getShopName.shopAddress,
                shopType: getShopName.shopType,
                shopPincode: getShopName.userPincode,
              });
              mergeShopName.push(appendObject);
            } else {
              mergeShopName.push(headers);
            }
          } else {
            mergeShopName.push(headers);
          }
        });

        // tempdata1.forEach(elements => {
        //   console.log(elements);
        // });

        // var couponTimeline = Object.entries(couponCreated).map(
        //   ([elements, value]) => {

        //     return value.map(findElement => {
        //       let count = couponDetails[0].find(
        //         item => item.redeemedCouponId === findElement.couponId
        //       );
        //       let appendObject = {};

        //       appendObject = Object.assign({}, findElement);

        //       appendObject.redeemedDate =
        //         count !== undefined ? count.redeemedDate : "TBD";
        //       appendObject.couponStatus =
        //         count !== undefined ? count.couponStatus : "";
        //       appendObject.couponType = elements;
        //       appendObject.paidStatus =
        //         count !== undefined ? count.paidStatus : "NIL";

        //       return appendObject;
        //     });
        //   }
        // );

        let couponMerge = [
          [{ couponType: "GHOSHAK", store: [] }],
          [{ couponType: "PROMOCODE", store: [] }],
          [{ couponType: "MERCHANT", store: [] }],
          [{ couponType: "LOYALTY", store: [] }],
        ];
        couponDetails[0].map((elements) => {
          let appendObject = {};
          let count = Object.entries(couponCreated).map(([items, value]) => {
            let temp = value.find(
              (findId) => findId.couponId === elements.redeemedCouponId
            );
            if (temp !== undefined) {
              appendObject = Object.assign({}, temp);

              appendObject.couponType = items;

              return appendObject;
            }
          });

          appendObject.redeemedDate =
            elements.redeemedDate !== undefined ? elements.redeemedDate : "TBD";
          appendObject.couponStatus =
            elements.couponStatus !== undefined ? elements.couponStatus : "";

          appendObject.paidStatus =
            elements.paidStatus !== undefined ? elements.paidStatus : "NIL";

          if (appendObject.couponType === "GHOSHAK") {
            couponMerge[0][0].store.push(appendObject);
          } else if (appendObject.couponType === "PROMOCODE") {
            couponMerge[1][0].store.push(appendObject);
          } else if (appendObject.couponType === "MERCHANT") {
            couponMerge[2][0].store.push(appendObject);
          } else if (appendObject.couponType === "LOYALTY") {
            couponMerge[3][0].store.push(appendObject);
          }
        });

        this.setState({
          transactionHistory: mergeShopName,
          showData: false,
          couponsConsumed: couponConsumed,
          addedFavourites: favObj,
          couponsHistory: couponMerge,
        });
      })
      .catch((err) => alert("enter valid customer 10digit"));
  }
  render() {
    if (!this.state.showData) {
      const emptyRows =
        this.state.rowsPerPage -
        Math.min(
          this.state.rowsPerPage,
          this.state.transactionHistory.length -
            this.state.page * this.state.rowsPerPage
        );
    }
    var i = 1;

    // Object.entries(this.state.addedFavourites).map(([header, value]) => {
    //   value.details.map(elements => {
    //     console.log(elements);
    //   });
    // });
    const { classes } = this.props;

    return (
      <>
        {this.state.showData && (
          <>
            <Row>
              <Col md={{ span: 4, offset: 3 }} className="division">
                <h4 style={{ textAlign: "center" }}>VIEW CUSTOMER DETAILS</h4>
                <Form onSubmit={this.customerSearch}>
                  <Form.Control
                    type="text"
                    name="userId10Digit"
                    onChange={this.onChange}
                  ></Form.Control>
                  <Button
                    style={{
                      width: 100 + "%",
                      height: 30 + "%",
                      marginTop: 2 + "%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    type="submit"
                  >
                    {" "}
                    SEARCH
                  </Button>
                </Form>
              </Col>
            </Row>
          </>
        )}
        {!this.state.showData && (
          <>
            <Typography
              variant="h2"
              style={{ textAlign: "center" }}
              gutterBottom
            >
              CUSTOMER INFORMATION
            </Typography>
            <Row style={{ width: 100 + "%" }}>
              <Col md={{ span: 3, offset: 1 }}>
                <b>NAME</b>
                <h5>{this.state.customerInformation[0].userName}</h5>
                <br />

                <b>ADDRESS</b>
                <h5>{this.state.customerInformation[0].userAddress}</h5>

                <br />
                <b>USER ID</b>
                <h5>{this.state.customerInformation[0].userId10Digit}</h5>

                <br />
              </Col>

              <Col md={{ span: 3, offset: 1 }}>
                <b>PHONE NUMBER</b>
                <h5>{this.state.customerInformation[0].userPhoneNumber}</h5>
                <br />
                <b>EMAIL</b>
                <h5>{this.state.customerInformation[0].userEmail}</h5>
                <br />

                {/* <b>DATE OF BIRTH</b>
                <h5>{(this.state.customerInformation[0].userDOB)}</h5>
                <br /> */}
              </Col>

              <Col md={{ span: 3, offset: 1 }}>
                <b>PINCODE</b>
                <h5>{this.state.customerInformation[0].userPincode}</h5>
                <br />

                {/* <b>ANNIVERSARY</b>
                <h5>{this.state.customerInformation[0].userAnniversary}</h5>
                <br /> */}
                <b>GENDER</b>
                <h5>{this.state.customerInformation[0].userGender}</h5>

                <br />
                <b>MARITAL STATUS</b>
                <h5>{this.state.customerInformation[0].maritalStatus}</h5>

                <br />
              </Col>
            </Row>

            <Row style={{ width: 100 + "%" }}>
              {Object.entries(this.state.customerPoints).map(
                ([header, value]) => (
                  <>
                    <div
                      style={{
                        backgroundColor: "#fafafa",
                        width: 100 + "%",
                        margin: 1 + "%",
                      }}
                    >
                      <h3 style={{ textAlign: "center" }}>
                        {header.toUpperCase()} {" POINTS "}
                      </h3>
                    </div>
                    <Col
                      style={{ textAlign: "center" }}
                      md={{ span: 3, offset: 1 }}
                      className="division"
                    >
                      <b>ADDED</b>
                      <h3 style={{ textAlign: "center" }}> {value.added}</h3>
                    </Col>
                    <Col
                      style={{ textAlign: "center" }}
                      md={{ span: 3, offset: 1 }}
                      className="division"
                    >
                      <b>REDEEMED</b>
                      <h3 style={{ textAlign: "center" }}> {value.redeemed}</h3>
                    </Col>
                    <Col
                      style={{ textAlign: "center" }}
                      md={{ span: 3, offset: 1 }}
                      className="division"
                    >
                      <b>CURRENT POINTS</b>
                      <h3 style={{ textAlign: "center" }}>
                        {parseFloat(
                          value.added != undefined ? value.added : 0
                        ) -
                          parseFloat(
                            value.redeemed != undefined ? value.redeemed : 0
                          )}
                      </h3>
                    </Col>
                  </>
                )
              )}
            </Row>
            <Row style={{ width: 100 + "%" }}>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  margin: 1 + "%",
                }}
              >
                <h3 style={{ textAlign: "center" }}>
                  {"OVERVIEW OF  COUPONS "}
                </h3>
              </div>

              {this.state.couponsHistory.map((elements, index) => (
                <>
                  <Col md={{ span: 3, offset: 1 }} className="division">
                    <b>
                      {elements !== undefined ? elements[0].couponType : ""}
                      {" COUPONS CONSUMED"}
                    </b>
                    <h3 style={{ textAlign: "center" }}>
                      {elements[0].store.length}
                    </h3>
                  </Col>
                </>
              ))}

              <Col md={{ span: 3, offset: 1 }} className="division">
                <b>TOTAL REFERS</b>
                <h3 style={{ textAlign: "center" }}>
                  {this.state.refferers.length}
                </h3>
              </Col>
            </Row>

            {this.state.couponsHistory.map((elements) => (
              <>
                {elements[0].store.length <= 0 ? (
                  ""
                ) : (
                  <>
                    <Row>
                      <div
                        style={{
                          backgroundColor: "#fafafa",
                          width: 100 + "%",
                          margin: 1 + "%",
                        }}
                      >
                        <h3 style={{ textAlign: "center" }}>
                          {elements[0].couponType.toUpperCase()}
                          {" COUPONS "}
                        </h3>
                      </div>
                      <Table
                        style={{
                          width: 100 + "%",
                          border: 0.5 + "px",
                          borderColor: "#ced4da",
                          borderStyle: "solid",
                        }}
                        size="small"
                      >
                        <TableHead>
                          {elements[0].store.slice(-1).map((items) => (
                            <TableRow>
                              <TableCell className={classes.tableCell}>
                                S NO
                              </TableCell>
                              {Object.keys(items).map((header) => (
                                <TableCell className={classes.tableCell}>
                                  {header}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableHead>
                        <TableBody>
                          {elements[0].store.map((items, index) => (
                            <TableRow>
                              <TableCell className={classes.tableCell}>
                                {index + 1}
                              </TableCell>
                              {Object.keys(items).map((header) =>
                                header === "userId10Digit" ? (
                                  <TableCell className={classes.tableCell}>
                                    {" "}
                                    <Link
                                      to={{
                                        pathname: "/merchant",
                                        search: `?userid=${items[header]}`,
                                      }}
                                    >
                                      {items[header]}
                                    </Link>
                                  </TableCell>
                                ) : (
                                  <TableCell className={classes.tableCell}>
                                    {items[header]}
                                  </TableCell>
                                )
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Row>
                  </>
                )}
              </>
            ))}

            <Row>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  margin: 1 + "%",
                }}
              >
                <h3 style={{ textAlign: "center" }}> FAVOURITE SHOPS</h3>
              </div>

              <Table
                style={{
                  width: 100 + "%",
                  border: 0.5 + "px",
                  borderColor: "#ced4da",
                  borderStyle: "solid",
                }}
                size="small"
                className={classes.table}
              >
                <TableHead>
                  {this.state.shops.slice(-1).map((header) => (
                    <>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          S NO
                        </TableCell>
                        {Object.keys(header).map((th) => (
                          <TableCell className={classes.tableCell}>
                            {th}
                          </TableCell>
                        ))}
                      </TableRow>
                    </>
                  ))}
                </TableHead>
                <TableBody>
                  {this.state.shops.map((header, sno) => (
                    <>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          {sno + 1}
                        </TableCell>
                        {Object.keys(header).map((tbody) =>
                          tbody === "userId10Digit" ? (
                            <TableCell className={classes.tableCell}>
                              {" "}
                              <Link
                                to={{
                                  pathname: "/merchant",
                                  search: `?userid=${header[tbody]}`,
                                }}
                              >
                                {header[tbody]}
                              </Link>
                            </TableCell>
                          ) : (
                            <TableCell className={classes.tableCell}>
                              {header[tbody]}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Row>

            {Object.entries(this.state.addedFavourites).map(
              ([header, value]) => (
                <>
                  <Row>
                    <div
                      style={{
                        backgroundColor: "#fafafa",
                        width: 100 + "%",
                        margin: 1 + "%",
                      }}
                    >
                      <h3 style={{ textAlign: "center" }}>
                        {"FAVOURITE "}
                        {header.toUpperCase()}
                      </h3>
                    </div>

                    <Table
                      style={{
                        width: 100 + "%",
                        border: 0.5 + "px",
                        borderColor: "#ced4da",
                        borderStyle: "solid",
                      }}
                      size="small"
                    >
                      <TableHead>
                        {value.details.slice(-1).map((items) => (
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              S NO
                            </TableCell>
                            {Object.keys(items).map((header) => (
                              <TableCell className={classes.tableCell}>
                                {header}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableHead>
                      <TableBody>
                        {value.details.map((items, index) => (
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              {index + 1}
                            </TableCell>
                            {Object.keys(items).map((header) =>
                              // <TableCell className={classes.tableCell}>
                              //   {items[header]}
                              // </TableCell>

                              header === "userId10Digit" ? (
                                <TableCell className={classes.tableCell}>
                                  {" "}
                                  <Link
                                    to={{
                                      pathname: "/merchant",
                                      search: `?userid=${items[header]}`,
                                    }}
                                  >
                                    {items[header]}
                                  </Link>
                                </TableCell>
                              ) : (
                                <TableCell className={classes.tableCell}>
                                  {items[header]}
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Row>
                </>
              )
            )}

            <Row>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  margin: 1 + "%",
                }}
              >
                <h3 style={{ textAlign: "center" }}> REFERER LIST</h3>
              </div>
              {this.state.refferers.length > 0 ? (
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid",
                  }}
                  size="small"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>S NO</TableCell>
                      <TableCell className={classes.tableCell}>
                        USER ID
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        USER NAME
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {" "}
                        PINODE
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {" "}
                        PHONE NUMBER
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.refferers.map((row, sno) => (
                      <>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            {sno + 1}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {" "}
                            <Link
                              to={{
                                pathname: "/customer",
                                search: `?userid=${row.userId10Digit}`,
                              }}
                            >
                              {row.userId10Digit}
                            </Link>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.userName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.userPincode}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.userPhoneNumber}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <p style={{ width: 100 + "%", textAlign: "center" }}>
                  No Data to Display
                </p>
              )}
            </Row>

            <Row>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  margin: 1 + "%",
                }}
              >
                <h3 style={{ textAlign: "center" }}> TRANSACTION DETAILS</h3>
              </div>

              <Col xs={2}>
                <Form.Label>TRANSACTION SORT BY DATE</Form.Label>
              </Col>
              <Col xs={2}>
                <Form.Control
                  type="date"
                  name="sortDate"
                  value={this.state.sortDate}
                  onChange={this.dateonchange}
                ></Form.Control>
              </Col>

              <Table
                style={{
                  width: 100 + "%",
                  border: 0.5 + "px",
                  borderColor: "#ced4da",
                  borderStyle: "solid",
                }}
                className={classes.table}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>S No</TableCell>
                    <TableCell className={classes.tableCell}>
                      Transaction ID
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Points Action
                    </TableCell>
                    <TableCell className={classes.tableCell}>Points</TableCell>

                    <TableCell className={classes.tableCell}>
                      Points Type
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Transactio Address
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      SHOP ID{" "}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Shop Name{" "}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Merchant Name{" "}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      Shop Pincode
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Phone Number{" "}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Shop Address{" "}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Transaction Details{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.transactionHistory
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((row, sno) => (
                      <>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            {sno + 1}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.transactionId}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.pointsAction}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.points}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.pointsType}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.transactionDate}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Link
                              to={{
                                pathname: "/customer",
                                search: `?userid=${row.userId10Digit}`,
                              }}
                            >
                              {" "}
                              {row.shopUserId}
                            </Link>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.shopName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.shopkeeperName}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {row.shopPincode}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {row.phonenumber}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.shopAddress}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.transactionDetails}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={this.state.transactionHistory.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Row>
          </>
        )}
      </>
    );
  }
}
CustomerDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomerDetails);
