import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import config from "../config";
export default function CreatePartnerAccount() {
  let initialState = {
    userPhoneNumber: "",
    companyName: "",
    userAddress: "",
    userName: "",
    gstNo: "",
    panNo: "",
    companyLocation: "",
    emailAddress: "",
    userAddedDate: "",
    userId: "",
    userId10Digit: "",
    referralCode: "",
  };
  const [state, setState] = useState(initialState);
  const [status, setStatus] = useState({
    refCode: "",
    confirmStatus: false,
    shopObject: {},
  });

  const [errMessage, setErrMessage] = useState("");
  const onChange = (name, value) => {
    // onchange event for form event
    let objectName = name;
    let objectValue = value;
    setState((prevState) => ({ ...prevState, [objectName]: objectValue }));
  };
  const handleChange = (name, value) => {
    // onchange event for status
    let objectName = name;
    let objectValue = value;
    setState((prevState) => ({ ...prevState, [objectName]: objectValue }));
  };
  const getReferalCode = (e) => {
    e.preventDefault();
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            constant_score: {
              filter: {
                bool: {
                  must: [{ match: { referralCode: status.refCode } }],
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let tempdata = data.hits.hits.map((element) => element._source);
        if (tempdata.length > 0) {
          setStatus({ ...status, shopObject: tempdata[0] });
        }
      })
      .catch((err) => console.log(err));
  };
  const confirmStatus = (userAction) => {
    if (userAction) {
      setStatus({ ...status, confirmStatus: true });
      let shopDetails = status.shopObject;
      setState({
        ...state,
        userId10Digit: shopDetails.userId10Digit,
        userName: shopDetails.userName,
        userPhoneNumber: shopDetails.userPhoneNumber.slice(-10),
        userId: shopDetails.userId,
        emailAddress: shopDetails.userEmail,
        referralCode: shopDetails.referralCode,
        userAddedDate: shopDetails.userAddedDate,
      });
    } else {
      setStatus({
        ...status,
        confirmStatus: false,
        shopObject: {},
        refCode: "",
      });
    }
  };
  const createAccount = async (e) => {
    e.preventDefault();

    let input = state;
    Object.keys(input).forEach((key) => input[key] == "" && delete input[key]); // Removes Empty String
    input.user_role = "partner";
    input.temporary_Password = "pass1234";
    input.typename = "createpartneraccount";
    await Promise.all([
      fetch(`${config.ApiGateway}/createAccount`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        if (data.code == "UsernameExistsException") {
          setErrMessage(
            "An account with the given phone_number already exists."
          );
        } else if (data.code == "InternalErrorException") {
          setErrMessage(
            "Unfortunately account not created. Please try again after some time"
          );
        } else {
          alert("account created");
          setState(initialState);
          setErrMessage("");
          setStatus({
            ...status,
            confirmStatus: false,
            shopObject: {},
            refCode: "",
          });
        }
      })
      .catch((err) => console.log("error getting data", err));
  };
  return (
    <Container>
      <div className="orderHeader">
        <h2 className="orderHeaderText">Create Partner Account</h2>
      </div>
      <div className="vertical-space-40"></div>
      <Row>
        <Col className="dashboardColumnBox columnCenter" sm="8">
          <Form onSubmit={getReferalCode}>
            <Form.Row>
              <Col sm="4">
                <Form.Label>Enter Refferal Code</Form.Label>
              </Col>
              <Col sm="6">
                <Form.Control
                  name="refCode"
                  onChange={(e) => {
                    let objName = e.target.name;
                    let objValue = e.target.value;
                    setStatus((prevState) => ({
                      ...prevState,
                      [objName]: objValue,
                    }));
                  }}
                  value={status.refCode}
                />
                <Form.Text className="text-muted">
                  Referal code is case sensitive
                </Form.Text>
              </Col>
              <Col sm="2">
                <Button type="submit">Search</Button>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      <div className="vertical-space-40"></div>

      {Object.keys(status.shopObject).length > 0 && (
        <>
          {status.confirmStatus ? (
            <>
              <Row>
                <div
                  style={{
                    display: errMessage.length > 0 ? "initial" : "none",
                  }}
                  className="errorBox"
                >
                  {errMessage}
                </div>
                <Col className="dashboardColumnBox columnCenter" sm={"8"}>
                  <Form onSubmit={createAccount}>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          value={state.userName}
                          name="userName"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom02"
                      >
                        <Form.Label>Company name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          value={state.companyName}
                          name="companyName"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          value={state.userAddress}
                          name="userAddress"
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          pattern="[0-9]{10}"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          value={state.userPhoneNumber}
                          name="userPhoneNumber"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          required
                          // type="email"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          value={state.emailAddress}
                          name="emailAddress"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          value={state.companyLocation}
                          name="companyLocation"
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label>GST</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          value={state.gstNo}
                          name="gstNo"
                          pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationCustom01"
                      >
                        <Form.Label>Pan</Form.Label>
                        <Form.Control
                          type="text"
                          pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          value={state.panNo}
                          name="panNo"
                        />
                      </Form.Group>
                    </Form.Row>

                    <Button style={{ float: "right" }} type="submit">
                      Create Account
                    </Button>
                  </Form>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col className="dashboardColumnBox columnCenter" sm="8">
                  <Row>
                    <Col sm={"6"}>
                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label column sm="4">
                          Name
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            plaintext
                            value={status.shopObject.userName}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm={"6"}>
                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label column sm="4">
                          Phone number
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            plaintext
                            value={status.shopObject.userPhoneNumber}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm={"6"}>
                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label column sm="4">
                          Refferal Code
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            plaintext
                            value={status.shopObject.referralCode}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm={"6"}>
                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label column sm="4">
                          user id
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={status.shopObject.userId10Digit}
                            plaintext
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div id="confirmButtonBox" className="floatLeft">
                    <Button
                      onClick={() => confirmStatus(true)}
                      style={{ marginRight: "10px" }}
                    >
                      Confirm
                    </Button>
                    <Button
                      onClick={() => confirmStatus(true)}
                      variant="danger"
                    >
                      No
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </>
      )}

      <div className="vertical-space-20"></div>
    </Container>
  );
}
