import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@material-ui/core/Button";
import Container from "react-bootstrap/Container";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import config from "../config";
import Resizer from "react-image-file-resizer";
import { Auth } from "aws-amplify";
export default class Addbanner extends Component {
  constructor(props) {
    super(props);
    this.resetBannerSpace = [];
    this.bannerSpaces = [];
    this.availableSpaces = [];
    this.bookedSpaces = [];
    this.targetPageLink = [];
    this.bookedTargetPage = [];
    this.availableTargetpage = [];
    this.state = {
      availability: {
        availabilityStartDate: "",
        availabilityEndDate: "",
        availabilityPincode: "",
      },
      selectedFile: null,

      // imagePreviewUrl: null,
      toggle: true,

      imagePreviewUrl: [],
      todaydate: new Date().toISOString().substr(0, 10),
      bannerArray: [],
      displayAvailability: false,
      status: false,
      targetObject: [],
      targetArray: [],
    };
    // this.handleimage = this.handleimage.bind(this);
    this.reset = this.state.availability;
    this.checkAvailability = this.checkAvailability.bind(this);
    this.checkAvailabilitySubmit = this.checkAvailabilitySubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.toggle = this.toggle.bind(this);

    // this.onImgLoad = this.onImgLoad.bind(this);
  }
  //STATE FOR RESET
  resetForm() {
    this.bookedSpaces = [];
    this.availableTargetpage = [];
    this.setState({
      availability: this.reset,
      toggle: true,
    });
  }
  // REST API CALL FOR GETTING ALL BANNER ADSPACES
  componentDidMount() {
    this.bannerSpaces = [];
    var targetlink = [];
    var temp = [];
    Promise.all([
      fetch(`${config.json.URL}/bannerDropdown.json`, {
        // method: "POST"
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        data.types.forEach((item) => {
          temp.push(item.BANNER);
          targetlink.push(item.TARGET);
        });
        temp[0].forEach((items) => this.bannerSpaces.push(items));

        targetlink[3].forEach((item) =>
          this.targetPageLink.push(item.targetPage)
        );

        targetlink = targetlink[3].map((item) => item);
        this.setState({
          targetObject: targetlink,
        });
      });
  }

  toggle() {
    this.setState({
      toggle: true,
    });
  }
  checkAvailability(e) {
    var target = e.target;
    var name = target.name;
    var value = target.value.toUpperCase();
    this.setState({
      ...this.state,
      availability: { ...this.state.availability, [name]: value }, //====>
    });
  }
  // REST API CALL FOR GETTING AVAILABILITY SPACES
  checkAvailabilitySubmit(e) {
    var check = [];
    var targetCheck = [];
    var storeTemp;
    e.preventDefault();
    fetch(`${config.ApiGateway}/checkavailability`, {
      method: "POST",
      body: JSON.stringify(this.state.availability),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.length > 0) {
          data.forEach((item) => {
            this.bannerSpaces.forEach((items) => {
              if (items === item.bannerlocation && item.status === "Active") {
                this.bookedSpaces.push(item);
                check.push(items);
              }
            });

            this.targetPageLink.forEach((items) => {
              if (items === item.targetPage) {
                this.bookedTargetPage.push(item);

                targetCheck.push(items);
              }
            });
          });
        } else {
          this.bannerSpaces.forEach((items) => {
            this.availableSpaces.push(items);
          });
        }
        this.availableSpaces = this.bannerSpaces.filter(function (val) {
          return check.indexOf(val) === -1;
        });

        this.targetPageLink.forEach((items) => {
          this.availableTargetpage.push(items);
        });
        // this.availableTargetpage = this.targetPageLink.filter(function(val) {
        //   return targetCheck.indexOf(val) === -1;
        // });

        storeTemp = this.availableSpaces.map((element) => {
          return <option key={element}>{element}</option>;
        });

        var getTargetName = [];
        var tempTargetSpace = [];
        getTargetName = this.availableTargetpage.map((element, value) => {
          return this.state.targetObject.find((item) => {
            if (item.targetPage === element) {
              return item.NAME;
            }
          });
        });
        tempTargetSpace = getTargetName.map((element) => {
          return <option value={element.NAME}>{element.NAME}</option>;
        });
        this.setState({
          toggle: false,
          bannerArray: storeTemp,
          targetArray: tempTargetSpace,
        });
      });
  }

  render() {
    return (
      <Container fluid>
        <h3 style={{ textAlign: "center" }}>CHECK AVAILABILITY FOR BANNERS</h3>
        <Row>
          {this.state.toggle && (
            <>
              <Col md={{ span: 5, offset: 4 }}>
                {/* CHECK AVAILABILTY FOR BANNER */}
                <Form onSubmit={this.checkAvailabilitySubmit}>
                  <Form.Label className="asterik">
                    CHECK AVAILABILITY
                  </Form.Label>
                  <Form.Row>
                    <Col md={{ span: 6 }}>
                      <Form.Control
                        type="date"
                        min={this.state.todaydate}
                        name="availabilityStartDate"
                        onChange={this.checkAvailability}
                        value={this.state.availability.availabilityStartDate}
                        required
                      />
                    </Col>
                    <Col md={{ span: 6 }}>
                      <Form.Control
                        type="date"
                        name="availabilityEndDate"
                        onChange={this.checkAvailability}
                        min={this.state.availability.availabilityStartDate}
                        value={this.state.availability.availabilityEndDate}
                        required
                      />
                    </Col>
                  </Form.Row>
                  <Form.Label className="asterik">PINCODE</Form.Label>
                  <Form.Control
                    type="text"
                    name="availabilityPincode"
                    pattern="[0-9]{6}"
                    onChange={this.checkAvailability}
                    placeholder="PINCODE"
                    value={this.state.availability.availabilityPincode}
                    required
                  />
                  <Button
                    variant="outlined"
                    type="submit"
                    style={{
                      float: "right",
                      marginBottom: 20 + "px",
                      marginTop: 20 + "px",
                    }}
                  >
                    submit
                  </Button>
                </Form>
              </Col>
            </>
          )}
          {/* TOGGLE STATE FOR VIEWING BANNER */}
          {!this.state.toggle && (
            <DisplayAvailability
              pincode={this.state.availability.availabilityPincode}
              startDate={this.state.availability.availabilityStartDate}
              endDate={this.state.availability.availabilityEndDate}
              bannerArray={this.state.bannerArray}
              resetForm={this.resetForm}
              toggle={this.toggle}
              targetArray={this.state.targetArray}
              targetObject={this.state.targetObject}
            />
          )}
        </Row>
        {/* DISPLAY BOOKED BANNERS */}
        {!this.state.toggle && (
          <Row>
            <Typography
              component="h6"
              align="center"
              variant="h6"
              color="error"
            >
              BOOKED RESULT
            </Typography>
            <Table
              style={{
                width: 100 + "%",
                border: 0.5 + "px",
                borderColor: "#ced4da",
                borderStyle: "solid",
              }}
              size="medium"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">S NO</TableCell>
                  <TableCell align="center">PINCODE</TableCell>
                  <TableCell align="center">BOOKED LOCATION</TableCell>
                  <TableCell align="center">BOOKED START DATE</TableCell>
                  <TableCell align="center">BOOKED END DATE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.bookedSpaces.map((row, index) => (
                  <TableRow key={index + 1}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row.pincode}</TableCell>
                    <TableCell align="center">{row.bannerlocation}</TableCell>
                    <TableCell align="center">{row.startDate}</TableCell>
                    <TableCell align="center">{row.endDate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Row>
        )}
      </Container>
    );
  }
}
class DisplayAvailability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkPage: false,
      banner: {
        bannerlocation: "",

        merchantName: "",
        pincode: this.props.pincode,
        bannerImageUrl: "",
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        createdOn: new Date().toISOString().substr(0, 10),
        reviewedBy: "",
        notes: "",
        referenceId: "",
        imageFile: [],
        bannerFor: "customer",
        targetPage: "",
      },
      imagePreviewUrl: [],
      width: "",
      height: "",
      fileimage: null,
      bannerArray: this.props.bannerArray,
      targetArray: this.props.targetArray,
      imageSize: "",
      targetObject: this.props.targetObject,
      targetname: "",
    };
    this.bannerSubmit = this.bannerSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.bannerImage = this.bannerImage.bind(this);
    this.handleimage = this.handleimage.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
    this.targetOnchange = this.targetOnchange.bind(this);
  }
  //TO FIND IMAGE HEIGHT AND WIDTH
  onImgLoad({ target: img }) {
    this.setState({
      width: img.offsetWidth,
      height: img.offsetHeight,
    });
  }
  // SETTING IMAGE
  handleimage(event) {
    this.setState({
      selectedFile: event.target.files[0],
    });

    // let reader = new FileReader();
    // let imageFormData = new FormData();
    // imageFormData.append("photo", event.target.files[0]);
    // Resizer.imageFileResizer(
    //   event.target.files[0], //is the file of the new image that can now be uploaded...
    //   300, // is the maxWidth of the  new image
    //   400, // is the maxHeight of the  new image
    //   "WEBP", // is the compressFormat of the  new image
    //   100, // is the quality of the  new image
    //   0, // is the rotatoion of the  new image
    //   (uri) => {
    //     this.setState({
    //       ...this.state,
    //       banner: {
    //         ...this.state.banner,

    //         imageFile: uri.replace(/^data:image\/\w+;base64,/, ""),
    //       },
    //     });
    //     this.setState({
    //       imagePreviewUrl: uri,
    //     });
    //   },
    //   "base64" // is the output type of the new image
    // );

    //
    //
    //****************************************************************//////////////////////// */

    //// A L T E R N A T I V E    I M A G E      U P L O A D   //////////////

    let reader = new FileReader();
    let imageFormData = new FormData();
    imageFormData.append("photo", event.target.files[0]);
    reader.onloadend = () => {
      this.setState({
        ...this.state,
        banner: {
          ...this.state.banner,

          imageFile: reader.result.replace(/^data:image\/\w+;base64,/, ""),
        },
      });
      this.setState({
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(event.target.files[0]);
  }

  // GET USER NAME
  componentDidMount() {
    Promise.all([fetch(`${config.json.URL}/imagesize.json`)])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.setState({
          imageSize: data.BANNER,
        });
      })
      .catch((err) => console.log(err));

    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({
          ...this.state,
          banner: {
            ...this.state.banner,
            reviewedBy: user.attributes.phone_number,
          }, //====>
        });
      })

      .catch((err) => console.log(err));
  }
  // NOT IN USE
  bannerImage = (picture, pictureDataURLs) => {
    var file = [];
    file = pictureDataURLs.map((item) => {
      return item.replace(/data:image\/jpeg;name=.*(jpeg|jpg);base64,/, "");
    });
    this.setState({
      ...this.state,
      banner: {
        ...this.state.banner,

        imageFile: file,
      },
    });
  };
  // ONCHANGE FOR BANNER
  onChange(e) {
    var target = e.target;
    var name = target.name;
    var value = target.value;

    this.setState({
      ...this.state,
      banner: { ...this.state.banner, [name]: value }, //====>
    });
  }
  targetOnchange(e) {
    var name = e.target.value;
    var tempname = this.state.targetObject.find((item) => {
      if (name == item.NAME) {
        return item.targetPage;
      }
    });

    var value = tempname.targetPage;
    this.setState({
      ...this.state,
      banner: { ...this.state.banner, targetPage: value },
      targetname: e.target.value,
    });
  }

  // SUBMIT FOR BANNER
  bannerSubmit(e) {
    e.preventDefault();
    fetch(`${config.ApiGateway}/addBanner`, {
      method: "POST",
      body: JSON.stringify(this.state.banner),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        alert(
          "BANNER UPLOADED SUCCESSFULLY FOR THE PINCODE" +
            this.state.banner.pincode
        );
        this.props.resetForm();

        this.setState({
          checkPage: true,
        });
      })
      .catch((err) => alert(err));
  }

  render() {
    return (
      <>
        <Col md={{ span: 4, offset: 2 }}>
          <>
            {/****************** INPUT FOR FILLING BANNERS *************************/}
            <Form onSubmit={this.bannerSubmit}>
              <Form.Label className="asterik">PINCODE</Form.Label>
              <Form.Control
                type="text"
                name="pincode"
                pattern="[0-9]{6}"
                onChange={this.onChange}
                value={this.state.banner.pincode}
                disabled={true}
                required
              />
              <Form.Label className="asterik">MERCHANT NAME</Form.Label>
              <Form.Control
                type="text"
                name="merchantName"
                pattern="^[A-Za-z][A-Za-z ]{1,}"
                onChange={this.onChange}
                value={this.state.banner.merchantName}
                required
              />
              <Form.Label className="asterik">BANNER LOCATION</Form.Label>
              <Form.Control
                as="select"
                name="bannerlocation"
                onChange={this.onChange}
                value={this.state.banner.bannerlocation}
                required
              >
                <option value="">SELECT BANNER LOCATION</option>
                <option value="HOME PAGE BANNER">HOME PAGE BANNER</option>
                {this.state.bannerArray}
              </Form.Control>

              <Form.Label className="asterik">TARGET PAGE</Form.Label>
              <Form.Control
                as="select"
                name="targetname"
                onChange={this.targetOnchange}
                value={this.state.targetname}
                required
              >
                <option value="">SELECT TARGET PAGE</option>
                {this.state.targetArray}
              </Form.Control>

              <Form.Label className="asterik">DATE</Form.Label>
              <Form.Row>
                <Col md={{ span: 6 }}>
                  <Form.Control
                    type="date"
                    min={this.state.todaydate}
                    name="startDate"
                    onChange={this.onChange}
                    disabled={true}
                    value={this.state.banner.startDate}
                    required
                  />
                </Col>
                <Col md={{ span: 6 }}>
                  <Form.Control
                    type="date"
                    name="endDate"
                    onChange={this.onChange}
                    disabled={true}
                    value={this.state.banner.endDate}
                    required
                  />
                </Col>
              </Form.Row>
              <Form.Label className="asterik">NOTES</Form.Label>
              <Form.Control
                as="textarea"
                name="notes"
                pattern="^[A-Za-z][A-Za-z ]{1,}"
                onChange={this.onChange}
                value={this.state.banner.notes}
                required
              />
              <Form.Label className="asterik">TRANSACTION ID</Form.Label>
              <Form.Control
                type="text"
                name="referenceId"
                pattern="^[A-Za-z0-9][A-Za-z0-9 ]{1,}"
                onChange={this.onChange}
                value={this.state.banner.referenceId}
                required
              />
              <input type="file" onChange={this.handleimage}></input>
              <label
                style={{ color: "red", marginTop: "5px", marginBottom: "5px" }}
              >
                Upload a image under 5 MB{" "}
              </label>
              <Button
                type="submit"
                style={{
                  float: "right",
                  marginBottom: 20 + "px",
                  marginTop: 20 + "px",
                }}
              >
                submit
              </Button>
            </Form>
          </>

          {this.state.checkPage && (
            <Button
              variant="outlined"
              style={{
                float: "right",
                marginBottom: 20 + "px",
                marginTop: 20 + "px",
              }}
              onClick={() => this.props.toggle()}
            >
              CHECK AVAIALABILITY
            </Button>
          )}
        </Col>
        {/* VIEW BANNER IN DUMMY MOBILE    */}
        <Col>
          <div className="smartphone">
            <h3>original height and width</h3>
            <img
              src={this.state.imagePreviewUrl}
              alt={this.state.imagePreviewUrl}
              onLoad={this.onImgLoad}
              width="100%"
              // height="100"
            ></img>
            <h1>
              width : {this.state.width}
              <br />
              height : {this.state.height}
            </h1>
            <img
              src={this.state.imagePreviewUrl}
              alt={this.state.imagePreviewUrl}
              onLoad={this.onImgLoad}
              width="100%"
              height={parseInt(this.state.width) / 3}
            ></img>
          </div>
        </Col>
      </>
    );
  }
}
