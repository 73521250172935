import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";

export default function ReachUs({ classes, profile }) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getNotificationAPI();
  }, [profile]);

  const getNotificationAPI = async () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? null : (
        <>
          <div className="shopCardRow" id="fullHeight">
            <div id="packageExpired" className="centerFlex">
              <h3>No Message Found</h3>
            </div>
          </div>
        </>
      )}
    </>
  );
}
