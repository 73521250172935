import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TableRow from "@material-ui/core/TableRow";
import { BillingAPI } from "./handler/apiHandler";
export default function Billing({ profile, classes, dateRange, days }) {
  const [billingObject, setBillingObject] = useState({
    isLoading: true,
    bills: [],
    monthlyBills: [],
    billAmount: 0,
    averageSales: 0,
    billGraph: [],
  });
  const [tabKey, setTabKey] = useState("dashboard");
  useEffect(() => {
    async function loadData() {
      if (Boolean(profile.userId) && Boolean(dateRange)) {
        console.log("initially loaded");
        await getBillingAPI();
      }
    }

    loadData();
  }, [profile, dateRange]);

  const getBillingAPI = async () => {
    return await new Promise(async (resolve, reject) => {
      try {
        await BillingAPI({
          shopId: profile.userId,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          days: days,
        }).then((data) => {
          // if response are valid
          if (data.status) {
            setBillingObject({
              isLoading: false,
              bills: data.data.bills,
              monthlyBills: data.data.monthlyBills,
              billAmount: data.data.billAmount,
              averageSales: data.data.averageSales,
              billGraph: data.data.billGraph,
            });

            return resolve();
          }
          // Calls during invalid response
          reject();
        });
      } catch (err) {
        alert("data fetching failed ");
        reject();
      }
    });
  };

  useEffect(() => {
    let graphData = billingObject.billGraph;
    console.log(graphData, "graph data");
  }, [billingObject.billGraph]);

  const CustomizedTick = (props) => {
    const { x, y, stroke, payload } = props;

    let displayXAxisvalue =
      days > 30
        ? moment(payload.value).format("YYYY/MMMM")
        : moment(payload.value).format("DD-MM-YYYY");

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {displayXAxisvalue}
        </text>
      </g>
    );
  };
  return (
    <>
      <div className="vertical-space-10"></div>

      <div className="shopCardRow">
        <div className="shopCardHeader">
          <p>Billing</p>
        </div>

        <div className="shopCardColumn">
          <div className="shopCardBox">
            <ul className="horizontalList">
              <li className="horizontalListItem">
                <h6>Total Bills</h6>
                <span>{billingObject.bills.length}</span>
              </li>
              <li className="horizontalListItem">
                <h6 className="horizontalListItemSubheading">Sales Amount</h6>
                <span className="horizontalListItemValue">{`₹ ${billingObject.billAmount}`}</span>
              </li>
              <li className="horizontalListItem">
                <h6 className="horizontalListItemSubheading">Average Sales</h6>
                <span className="horizontalListItemValue">{`₹ ${billingObject.averageSales}`}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="shopCardRow">
        <div className="shopCardColumn">
          <div className="shopCardBox">
            <div className="ShopCardTabs">
              <Tabs
                defaultActiveKey="details"
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
                activeKey={tabKey}
                onSelect={(k) => setTabKey(k)}
              >
                <Tab eventKey="invoice" title="Invoice">
                  <div className="wrapTable">
                    <MaterialTable
                      title="Invoice"
                      options={{
                        search: true,
                        headerStyle: { position: "sticky", top: 0 },
                        maxBodyHeight: "600px",
                        exportButton: {
                          pdf: false,
                        },
                      }}
                      columns={[
                        {
                          title: "Invoice Number",
                          field: "invoiceNumber",
                        },
                        {
                          title: "Billing Date",
                          field: "billingDate",
                        },
                        {
                          title: "Customer Name",
                          field: "customerName",
                        },
                        {
                          title: "Customer Ph No",
                          field: "customerPhoneNumber",
                        },

                        {
                          title: "Amount Paid ₹",
                          field: "amountPaid",
                          render: (rowData) => `₹ ${rowData.amountPaid}`,
                        },
                        {
                          title: "Payment Method",
                          field: "paymentMethod",
                          render: (rowData) => {
                            return typeof rowData.otherDetails === "object"
                              ? rowData.otherDetails["paymentMethod"]
                              : "NA";
                          },
                        },
                        {
                          title: "Employee",
                          field: "employee",
                          render: (rowData) => {
                            return typeof rowData.otherDetails === "object"
                              ? rowData.otherDetails["employee"] || "NA"
                              : "NA";
                          },
                        },
                        {
                          title: "Bill",
                          field: "bills3Location",
                          render: (rowData) => (
                            <button
                              onClick={() =>
                                window.open(
                                  rowData.bills3Location || rowData.tinyUrl
                                )
                              }
                            >
                              View PDF
                            </button>
                          ),
                        },
                      ]}
                      data={billingObject.bills}
                    />
                  </div>
                </Tab>
                <Tab
                  eventKey="generatedBillsPerMonth"
                  title="Bills Generated by Month"
                >
                  <div className="wrapTable">
                    <TableContainer className={classes.container}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className={classes.tableHeader}
                              key={"1"}
                              align={"1"}
                            >
                              Date
                            </TableCell>
                            <TableCell className={classes.tableHeader}>
                              Amount Paid
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {billingObject.monthlyBills.map((row) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.date}
                              >
                                <TableCell
                                  className={classes.tableCell}
                                  key={row.date}
                                >
                                  {moment(row.date).format("DD-MM-YYYY")}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                >{`₹ ${row.amountPaid}`}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Tab>
                <Tab eventKey="dashboard" title="Dashboard">
                  <div className="shopCardRow">
                    <div id="col50" className="shopCardColumn">
                      <div style={{ boxShadow: "none" }} className="wrapTable">
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart
                            width={500}
                            height={300}
                            data={billingObject.billGraph}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              interval={0}
                              angle={-45}
                              textAnchor="end"
                              height={100}
                              dataKey="date"
                              tick={<CustomizedTick />}
                            ></XAxis>
                            <YAxis
                              label={{
                                value: "No Of Bills Generated",
                                angle: -90,
                                position: "insideBottomLeft",
                                fontSize: 12,
                              }}
                            />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="count"
                              stroke="#8884d8"
                              activeDot={{ r: 8 }}
                              label={<CustomizedLabel />}
                            >
                              <LabelList dataKey="amount" position="bottom" />
                            </Line>
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                    <div id="col50" className="shopCardColumn">
                      <div className="wrapTable">
                        <TableContainer className={classes.container}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  className={classes.tableHeader}
                                  key={"1"}
                                  align={"1"}
                                >
                                  Date
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                  No of Bills Generated
                                </TableCell>
                                <TableCell className={classes.tableHeader}>
                                  Total Bill Amount
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {billingObject.billGraph.map((row) => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.date}
                                  >
                                    <TableCell
                                      className={classes.tableCell}
                                      key={row.billingDate}
                                    >
                                      {row.billingDate}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {row.count}
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableCell}
                                    >{`₹ ${row.amount}`}</TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const CustomizedLabel = (props) => {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  );
};
