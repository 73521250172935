import React, { Component } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import config from "../../config";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class CustomerEmail extends Component {
  constructor(props) {
    super(props);

    this.handleFunction = this.handleFunction.bind(this);
    this.state = {
      showData: false,
      nextMonthList: [],
      nextWeekList: [],
      page: 0,
      Customerpage: 0,
      rowsPerPage: 5,
      rowsPerPageCustomer: 10,
      customerDetails: [],
      showCustomerList: false,
      filterBy: "All",
      sortDob: {},
      tomorrowList: [],
      mergedArea: [],
      filterPincode: "All",
      exportData: [],
      headers: [],
      custList: [],
    };
    this.handleFunction = this.handleFunction.bind(this);

    this.getCustomer = this.getCustomer.bind(this);
    this.onChange = this.onChange.bind(this);
    this.csvLink = React.createRef();
  }

  handleFilterByCustomer(e) {
    let value = e.target.value;

    this.setState({
      filterBy: value,
      Customerpage: 0,
    });
  }

  onChange(e) {
    this.setState({
      filterPincode: e.target.value,
      Customerpage: 0,
    });
  }

  getCustomer(customerid) {
    let getID = customerid.map((key) => key.userId);
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [{ terms: { userId: getID } }],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, data2]) => {
        let tempdata = data.hits.hits.map((element) => element._source);

        var mergePincodeAndArea = tempdata.map((items) => {
          let count = data2.Items.find(
            (elements) => items.userPincode === elements.pincode
          );
          let appendObject = {};
          appendObject.userName = items.userName;
          appendObject.userPhoneNumber = items.userPhoneNumber;
          appendObject.userAddress = items.userAddress;
          appendObject.userId10Digit = items.userId10Digit;
          appendObject.userAnniversary = items.userAnniversary;
          if (count !== undefined) {
            appendObject.userPincode = items.userPincode;
            appendObject.place = count.place;
          } else {
            appendObject.userPincode = items.userPincode;
            appendObject.place = "Not Found";
          }

          return appendObject;
        });
        this.setState({
          customerDetails: mergePincodeAndArea,
          showCustomerList: true,
        });
      })
      .catch((err) => console.log(err));
  }

  componentDidMount() {
    this.handleFunction();
  }
  handleFunction() {
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        // api call to fetch DOB
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                { match: { userType: "Customer" } },
                {
                  exists: {
                    field: "userEmail",
                  },
                },
              ],
              must_not: {
                bool: {
                  must: [{ term: { userPincode: "111111" } }],
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, area]) => {
        let tempdata = data.hits.hits.map((element) => element._source);
        console.log(tempdata, "Email id");
        this.setState({
          custList: tempdata,
          showCustomerList: true,
          loadPage: true,
        });
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.loadPage && (
          <>
            <Row style={{ width: 100 + "%", justifyContent: "center" }}>
              <b style={{ fontSize: 32 }}>Customer Email</b>
            </Row>
            {this.state.showCustomerList && (
              <>
                {" "}
                <div className="vertical-space-10"></div>
                <Row style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <Col sm={12}>
                    <MaterialTable
                      title="Email "
                      columns={[
                        {
                          title: "Customer Id",
                          field: "userId10Digit",
                          render: (rowData) => (
                            <Link
                              to={{
                                pathname: "/customer",
                                search: `?userid=${rowData.userId10Digit}`,
                              }}
                            >
                              {rowData.userId10Digit}
                            </Link>
                          ),
                        },
                        {
                          title: "Name",
                          field: "userName",
                        },
                        {
                          title: "E-mail",
                          field: "userEmail",
                        },

                        {
                          title: "Pincode",
                          field: "userPincode",
                        },
                      ]}
                      data={this.state.custList}
                      options={{
                        exportButton: true,
                        pageSize: 30,

                        pageSizeOptions: [5, 10, 20, 100, 1000],
                        grouping: false,
                        filtering: false,
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </>
    );
  }
}
CustomerEmail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomerEmail);
