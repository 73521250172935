import React from "react";
import ReactPDF from "@react-pdf/renderer";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import GhoshakLogo from "./Logo_Orginal.png";

export default function CreateQrCode({ shopdetails }) {
  let shopType = shopdetails.shopType;
  let QRText = "";
  let shopName = shopdetails.shopName.toUpperCase();
  let urlLink = shopdetails.websiteUrl;
  let stepInstruction = [];
  if (
    shopType === "Supermarket" ||
    shopType === "Provisional Store" ||
    shopType === "Textile" ||
    shopType === "Mobile" ||
    shopType === "Others"
  ) {
    QRText = "For Products";
  } else if (
    shopType === "Restaurant" ||
    shopType === "Bakery" ||
    shopType === "Sweets and Snacks" ||
    shopType === "Juice Shop" ||
    shopType === "Tea Shop"
  ) {
    QRText = "For Menu Card";
  } else if (shopType === "Saloon" || shopType === "Beauty Parlour") {
    QRText = "For Services";
  }

  if (
    shopType == "Saloon" ||
    shopType == "Beauty Parlour" ||
    shopType === "Mobile"
  ) {
    stepInstruction = "View Products & Services";
  } else if (
    shopType === "Restaurant" ||
    shopType === "Bakery" ||
    shopType === "Sweets and Snacks" ||
    shopType === "Juice Shop" ||
    shopType === "Tea Shop"
  ) {
    stepInstruction = "View our Menu";
  } else if (shopType === "Supermarket" || shopType === "Provisional Store") {
    stepInstruction = "View our Product";
  } else if (shopType === "Textile") {
    stepInstruction = "View our Catalog ";
  } else {
    stepInstruction = "View our Product / Services";
  }
  // For QR Code
  var QRCode = require("qrcode");
  let canvas;

  canvas = document.createElement("canvas");
  QRCode.toCanvas(canvas, shopdetails.websiteUrl);
  const qr = canvas.toDataURL();
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.detailBox}>
            <View style={styles.headerBox}>
              <Text> {shopName}</Text>
            </View>
            <Text style={styles.text}>Scan QR Code</Text>
            <View style={styles.verticalSpace10}></View>
            <Text style={styles.text}>{QRText}</Text>
            <View>
              <Image style={styles.qrCodeImage} src={qr} />
            </View>
            <Text style={styles.websiteLink}>{`${urlLink}`}</Text>
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View style={styles.steps}>
                <Text style={styles.instructionSteps}>1. Open camera (or)</Text>
                <Text style={styles.instructionSteps}>use Google lens</Text>
              </View>

              <View style={styles.steps}>
                <Text style={styles.instructionSteps}>2. Scan the QR Code</Text>
              </View>
              <View style={styles.steps}>
                <Text style={styles.instructionSteps}>
                  3. {stepInstruction}
                </Text>
              </View>
            </View>
            <View>
              <View style={styles.verticalSpace10}></View>
              <Text style={styles.poweredByGhoshak}>Powered By Ghoshak</Text>
              <Image style={styles.image} src={GhoshakLogo}></Image>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.detailBox}>
            <View style={styles.headerBox}>
              <Text> {shopName}</Text>
            </View>
            <Text style={styles.text}>Scan QR Code</Text>
            <View style={styles.verticalSpace10}></View>
            <Text style={styles.text}>{QRText}</Text>
            <View>
              <Image style={styles.qrCodeImage} src={qr} />
            </View>
            <Text style={styles.websiteLink}>{`${urlLink}`}</Text>
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View style={styles.steps}>
                <Text style={styles.instructionSteps}>1. Open camera (or)</Text>
                <Text style={styles.instructionSteps}>use Google lens</Text>
              </View>

              <View style={styles.steps}>
                <Text style={styles.instructionSteps}>2. Scan the QR Code</Text>
              </View>
              <View style={styles.steps}>
                <Text style={styles.instructionSteps}>
                  3. {stepInstruction}
                </Text>
              </View>
            </View>
            <View>
              <View style={styles.verticalSpace10}></View>
              <Text style={styles.poweredByGhoshak}>Powered By Ghoshak</Text>
              <Image style={styles.image} src={GhoshakLogo}></Image>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
// Pdf Style
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#EF4667",
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    paddingTop: 40,
  },
  section: {
    width: 200,

    "@media max-width: 400": {
      width: 300,
    },
    "@media orientation: landscape": {
      width: 400,
    },
  },
  headerBox: {
    boxShadow: "0px 10px 10px #00000024",
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 15,
    justifyContent: "center",
    width: 300,
    padding: 20,
    marginLeft: 50,
    marginRight: 50,
    backgroundColor: "#fbd6de",
    marginBottom: 20,
  },
  detailBox: {
    width: 400,
    textAlign: "center",
  },
  text: {
    fontSize: 24,
    color: "#FFF",
    fontWeight: "bold",
    paddingBotttom: 30,
  },
  image: { marginVertical: 15, marginHorizontal: 100 },
  verticalSpace10: {
    height: 10,
  },
  poweredByGhoshak: {
    fontSize: 16,
    color: "#FFF",
  },
  steps: {
    flex: 1,
  },
  instructionSteps: {
    fontSize: 10,
    color: "#ffffff",
    textAlign: "center",
  },
  instructionStepsIcon: { marginVertical: 15, marginHorizontal: 100 },
  qrCodeImage: {
    width: "45%",
    alignSelf: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  websiteLink: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#ffffff",
    marginBottom: 15,
  },
});
