import React, { Component } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import config from "../../config";
export default class ShopDetails extends Component {
  constructor(props) {
    super(props);
    this.shopInfo = [];
    this.state = {
      showData: false,
      temp: []
    };
  }
  componentDidMount() {
    var exchange = [];

    this.props.shopId.map(element => {
      Promise.all([
        fetch(
          `${config.Elasticsearch.URL}/users/_search?size=10000&q=userId:` +
            element
        )
      ])
        .then(([res1]) => Promise.all([res1.json()]))
        .then(([data]) => {
          exchange = data.hits.hits.map(item => item._source);
          exchange.map(items =>
            this.shopInfo.push({
              shopName: items.shopName,
              shopType: items.shopType,
              userAddress: items.shopAddress,
              userName: items.userName,
              userPhoneNumber: items.userPhoneNumber,
              userPincode: items.userPincode,
              userId10Digit: items.userId10Digit
            })
          );

          this.setState({
            temp: this.shopInfo
          });
        });
    });
    this.setState({
      showData: true
    });
  }
  render() {
    return (
      <>
        {this.state.showData && (
          <>
            <h3>SHOP DETAILS</h3>
            <div className="division">
              <Table
                style={{
                  // width: 100 + "%",
                  border: 0.5 + "px",
                  borderColor: "#ced4da",
                  borderStyle: "solid"
                }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S NO</TableCell>
                    <TableCell align="center">USER ID</TableCell>
                    <TableCell align="center">SHOP NAME</TableCell>
                    <TableCell align="center">MERCHANT NAME</TableCell>
                    <TableCell align="center">SHOP TYPE</TableCell>
                    <TableCell align="center">ADDRESS</TableCell>
                    <TableCell align="center">PHONE NUMBER</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.temp.map((row, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{row.userId10Digit}</TableCell>
                      <TableCell align="center">{row.shopName}</TableCell>
                      <TableCell align="center">{row.userName}</TableCell>
                      <TableCell align="center">{row.shopType}</TableCell>
                      <TableCell align="center">{row.userAddress}</TableCell>
                      <TableCell align="center">
                        {row.userPhoneNumber}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        )}
      </>
    );
  }
}
