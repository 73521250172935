import React, { useEffect, useState, useContext } from "react";
import { store } from "../provider";
import { Auth } from "aws-amplify";
export default function LinkInvite(props) {
  const context = useContext(store);
  useEffect(() => {
    Auth.signOut()
      .then(async (data) => {
        props.onStateChange("signedOut");
        await context.dispatch({
          type: "IS_LOGGEDIN",
          loggedIn: false,
        });
      })

      .catch((err) => console.log(err));
  }, []);

  return <></>;
}
