import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import config from "../../config";
export default class CustomerLikedOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderPage: false,
      statistics: [],
      topLikedCustomer: []
    };
    this.handleLogic = this.handleLogic.bind(this);
    this.viewTable = this.viewTable.bind(this);
  }
  async componentDidMount() {
    await this.handleLogic();
  }

  handleLogic() {
    var zeroLiked = [];
    var one_nine_Liked = [];
    var ten_fortynine_Liked = [];
    var fifty_ninetynine_Liked = [];
    var above_hundred_Liked = [];
    var stats = [];
    var count_1 = 0;
    var count_2 = 0;
    var count_3 = 0;
    var count_4 = 0;
    var count_5 = 0;
    this.props.likedOffers.map(elements => {
      if (elements.totalAddedtoFavourites === 0) {
        zeroLiked.push(elements);
        count_1++;
      } else if (
        elements.totalAddedtoFavourites >= 1 &&
        elements.totalAddedtoFavourites <= 9
      ) {
        one_nine_Liked.push(elements);
        count_2++;
      } else if (
        elements.totalAddedtoFavourites >= 10 &&
        elements.totalAddedtoFavourites <= 49
      ) {
        ten_fortynine_Liked.push(elements);
        count_3++;
      } else if (
        elements.totalAddedtoFavourites >= 50 &&
        elements.totalAddedtoFavourites <= 99
      ) {
        fifty_ninetynine_Liked.push(elements);
        count_4++;
      } else if (elements.totalAddedtoFavourites >= 100) {
        above_hundred_Liked.push(elements);
        count_5++;
      }
    });
    stats.push(
      { zeroLiked: zeroLiked, count: count_1 },
      { one_nine_Liked: one_nine_Liked, count: count_2 },
      { ten_fortynine_Liked: ten_fortynine_Liked, count: count_3 },
      { fifty_ninetynine_Liked: fifty_ninetynine_Liked, count: count_4 },
      { above_hundred_Liked: above_hundred_Liked, count: count_5 }
    );
    var top_liked_customer = this.props.likedOffers
      .sort((a, b) => b.totalAddedtoFavourites - a.totalAddedtoFavourites)
      .slice(0, 10);

    return this.setState({
      renderPage: true,
      statistics: stats,
      topLikedCustomer: top_liked_customer
    });
  }
  viewTable(items) {
    this.props.renderTable(items, ["addedToFav"]);
  }

  render() {
    return (
      <>
        {this.state.renderPage && (
          <>
            <Row>
              <div
                style={{
                  margin: "auto",
                  backgroundColor: "#fafafa",
                  width: 100 + "%"
                }}
              >
                <h3>TOP 10 CUSTOMER LIKED THE OFFER</h3>
              </div>
              <Col>
                <Button
                  onClick={() => {
                    this.viewTable(this.state.topLikedCustomer);
                  }}
                >
                  VIEW
                </Button>
              </Col>
            </Row>
            <Row className="division">
              <Col>
                <div
                  style={{
                    backgroundColor: "#fafafa",
                    width: 100 + "%",
                    margin: 1 + "%"
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>
                    {" "}
                    OVERVIEW CUSTOMER ADDED FAVOURITE OFFERS{" "}
                  </h3>
                </div>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid"
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">ADDED TO FAVOURITE</TableCell>
                      <TableCell align="center">TOTAL CUSTOMERS</TableCell>
                      <TableCell align="center">
                        VIEW CUSTOMER DETAILS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">ZERO LIKED</TableCell>
                      <TableCell align="center">
                        {/* {this.state.totalOfferStats[0].ZEROoffers} */}
                        {this.state.statistics[0].count}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.statistics[0].zeroLiked
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">2</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH LESS THAN 10 OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.statistics[1].count}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.statistics[1].one_nine_Liked
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">3</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH 11 - 50 OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.statistics[2].count}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.statistics[2].ten_fortynine_Liked
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">4</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH 50-90 OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.statistics[3].count}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.statistics[3].fifty_ninetynine_Liked
                              );
                            }}
                          >
                            View
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">5</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH MORE THAN 100 OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.statistics[4].count}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.statistics[4].above_hundred_Liked
                              );
                            }}
                          >
                            View
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}
