import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  createRef,
} from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Tabs,
  Tab,
} from "react-bootstrap";
import Skeleton from "@material-ui/lab/Skeleton";
import config from "../config";
import PartnerProfile from "./partnerProfile";
import PartnerDownload from "./partnerDownloads";
import PartnerEmployee from "./partnerEmployee";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import MaterialTable from "material-table";
import DefaultShopImage from "./shop-df-img.png";
import EditIcon from "@material-ui/icons/Edit";
import "./partner.css";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import ViewShopProfileByPartner from "./viewShopProfileByPartner";
const useStyles = makeStyles({
  table: {
    boxShadow: "0 0 3px #e2e5e8",
    backgroundColor: "#ffffff",
    margin: "0 auto",
    border: "0 solid transparent",
    paddingBottom: 20,
  },
  container: {
    maxHeight: 200,
    overflowY: "scroll",
    overflowX: "auto",
    overflow: "hidden",
  },
  tableHead: {
    fontWeight: 500,
    backgroundColor: "#ffffff",
    color: "#333",
    letterSpacing: "1.5px",
    textTransform: "capitalize",
  },
  tableHeadBody: {
    backgroundColor: "#ffffff",
    borderBottomWidth: "1px",
  },
  root: {
    width: "100%",
  },
  tableCell: {
    fontWeight: 300,
    lineHeight: "20px",
    fontSize: "14px",
    color: "#212529",
  },
  tableIcon: {
    fontSize: "13px",

    fontWeight: 300,
    lineHeight: "20px",
  },
  tableBodyRow: {
    borderBottom: "1px solid #e9ecef",
  },
});
export default function PartnerHome({ cognitoId, role }) {
  let initialState = {
    updatedOn: new Date().toISOString().substring(0, 10),
    criteriaMet: "",
    remarkForPartner: "",
    remarkForGhoshak: "",
    approvalStatus: "",
    paymentDate: "",
    amountPaid: "",
  };
  const classes = useStyles();
  const csvLink = createRef();
  const [isLoading, setIsLoading] = useState(true);
  const [shopDetails, setShopDetails] = useState({});
  const [downloadData, setDownloadData] = useState([]);
  const [active, setActive] = useState(false);
  const [shopObject, setShopObject] = useState({
    profile: {},
  });
  const [modalShow, setModalShow] = useState(false);
  const [key, setKey] = useState("statement");
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const [modalData, setModalData] = useState({});
  const [modalStatement, setModalStatement] = useState(false);
  const [modelStatementData, setModelStatementData] = useState({
    paymentVersion: [],
  });
  const [pendingStatement, setPendingStatement] = useState([]);
  const [referenceUserId, setReferenceUserId] = useState("");
  const [state, setState] = useState({
    userProfile: {},
    shop: [],
    employee: [],
    download: {},
    organization: [],
    partnerStatement: [],
  });
  const [statementInput, setStatementInput] = useState(initialState);
  const [filteredPayment, setFilteredPayment] = useState("all");
  const [employeeRefShops, setEmployeeRefShops] = useState(0);
  const [paymentMonthDropdown, setPaymentMonthDropdown] = useState([]);
  const [partnerId, setPartnerId] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [filterByPayment, setFilterByPayment] = useState("");
  useEffect(() => {
    // get initial data
    async function loadData() {
      await Auth.currentAuthenticatedUser().then((data) => {
        setCurrentUser(data.attributes.phone_number);
      });
      getPartnerData();
      let todayDate = new Date().toISOString().substring(0, 10);
      let dropdownStartDate = moment("2019-12-01");
      let dropdownEndDate = moment(todayDate);
      let monthYearRanges = [];

      while (
        dropdownEndDate > dropdownStartDate ||
        dropdownStartDate.format("M") === dropdownEndDate.format("M")
      ) {
        monthYearRanges.push(
          <option value={dropdownStartDate.format("YYYY-MM")}>
            {dropdownStartDate.format("MMM YYYY")}
          </option>
        );
        dropdownStartDate.add(1, "month");
      }
      monthYearRanges.sort((a, b) => b - a);
      setPaymentMonthDropdown(monthYearRanges);
    }
    loadData();
  }, [cognitoId]);
  const onChange = (name, value) => {
    // onchange event for form event
    let objectName = name;
    let objectValue = value;
    setStatementInput((prevState) => ({
      ...prevState,
      [objectName]: objectValue,
    }));
  };
  const getPartnerData = async () => {
    if (Boolean(cognitoId)) {
      await Promise.all([
        fetch(`${config.ApiGateway}/partnerHome`, {
          method: "POST",
          body: JSON.stringify({
            cognitoId: cognitoId,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ])
        .then(([res1]) => Promise.all([res1.json()]))
        .then(async ([data]) => {
          if (data.status) {
            let totalDownloads = await getTotalDownloads(data.shop);

            let employeeDownloads = await getEmployeeDownloads(
              data.organization,
              data.shop,
              data.subEmployeeList
            );

            setReferenceUserId(data.data[0].userId);
            handleChange("organization", data.organization);
            handleChange("userProfile", data.data[0]); // update company profile
            handleChange("shop", data.shop); // reffered shops
            handleChange("employee", employeeDownloads);
            handleChange("download", totalDownloads); // download by segments
            handleChange("partnerStatement", data.statement); // download by segments
            var pendingStatementList = await getPendingStatement(
              data.organization
            );
            setFilteredPayment(data.statement);
            // setPendingStatement(pendingStatementList);
            setIsLoading(false);
          } else {
            alert(data.message);
          }
        })
        .catch((err) => console.log("Error getting partner profile", err));
    }
  };
  const getEmployeeDownloads = (organization, shop, subEmployee) => {
    let findEmployeeRef = [];
    let emplDownload = 0;
    subEmployee.forEach((elements) => {
      let refShop = shop.filter(
        (items) => items.referredBy == elements.referralCode
      );
      emplDownload += refShop.length;
      findEmployeeRef.push({
        totalDownloads: refShop.length,
        referralCode: elements.referralCode,
        userName: elements.userName,
        role: elements.role,
        userId: elements.userId,
      });
    });
    setEmployeeRefShops(emplDownload);
    return findEmployeeRef;
  };
  const getPendingStatement = async (organization) => {
    return await Promise.all([
      fetch(`${config.ApiGateway}/partnerReport`, {
        method: "POST",
        body: JSON.stringify({
          account: organization,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        setPendingStatement(data);
        return [];
      })
      .catch((err) => console.log(err));
  };
  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const getTotalDownloads = (shops) => {
    let segments = {};
    shops.forEach((items) => {
      if (segments[items.shopType] == undefined) {
        segments[items.shopType] = 0;
      }
      segments[items.shopType] += 1;
    });

    return segments;
  };

  const getPartnerInfo = async () => {
    await getPartnerData();
  };
  const addStatement = (addData) => {
    let input = addData;
    Object.keys(input).forEach((key) => input[key] == "" && delete input[key]); // Removes Empty String
    input.template = "addStatement";
    input.referenceUserId = referenceUserId;
    input.paidBy = currentUser;
    input.approvedBy = currentUser;
    Promise.all([
      fetch(`${config.ApiGateway}/partnerStatement`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        getPartnerData();
        getPendingStatement();
      })
      .catch((err) => console.log(err));
  };
  const modalStatementShow = (input) => {
    // Show Modal Statement Data
    setStatementInput((prevState) => ({
      ...prevState,

      criteriaMet: input.criteriaMet,
      remarkForPartner: input.remarkForPartner,
      remarkForGhoshak: input.remarkForGhoshak,
      paymentDate: input.paymentDate,
      approvalStatus: input.approvalStatus,
      userId: input.userId,

      addAmount: "",
    }));

    setModelStatementData(input);
    setModalStatement(true);
  };
  const updatePayment = (e) => {
    e.preventDefault();
    let input = statementInput;

    input.remarkForPartner =
      typeof input.remarkForPartner == "undefined"
        ? "NA"
        : input.remarkForPartner;
    input.remarkForGhoshak =
      typeof input.remarkForGhoshak == "undefined"
        ? "NA"
        : input.remarkForGhoshak;
    input.notes = typeof input.notes == "undefined" ? "NA" : input.notes;

    Object.keys(input).forEach((key) => input[key] == "" && delete input[key]); // Removes Empty String

    input.addAmount =
      typeof input.addAmount == "string" ? parseInt(input.addAmount) : 0;

    if (input.addAmount > 0) {
      input.amountPaid =
        parseInt(input.addAmount) + parseFloat(modelStatementData.amountPaid);

      let concatPaymentVersion = modelStatementData.paymentVersion;
      concatPaymentVersion.push({
        date: new Date().toISOString().substring(0, 10),

        amount: input.addAmount,
        paidBy: input.paidUpdatedBy,
      });
      input.paymentVersion = concatPaymentVersion;
    }
    input.paidBy = currentUser;
    input.approvedBy = currentUser;
    input.template = "edit";

    Promise.all([
      fetch(`${config.ApiGateway}/partnerStatement`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        setModalStatement(false);
        alert("Statement Updated");
        setStatementInput(initialState);
        await getPartnerData();
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    let FilteredData = state.partnerStatement.filter((items) =>
      items.paymentDate == filterByPayment
        ? items.paymentDate
        : filterByPayment == "all"
        ? items
        : null
    );

    setFilteredPayment(FilteredData);
  }, [filterByPayment]);
  const exportAsExcel = (dataArray) => {
    let excelDownloadArray = dataArray.map((rowData) => {
      let input = {};
      let findPaymentMonth = paymentMonthDropdown.find(
        (elements) => rowData.paymentDate == elements.props.value
      );

      input.shopId = rowData.ghoshakId;
      input.shopName = rowData.shopName;
      input.amountPaid = rowData.amountPaid;
      input.criteria = rowData.criteria;
      input.criteriaMet = rowData.criteriaMet;
      input.paymentDate =
        findPaymentMonth != undefined ? findPaymentMonth.props.children : "NA";
      input.approvedStatus = rowData.approvedStatus;
      input.approvedBy = rowData.approvedBy;
      // input.remarkForGhoshak = rowData.remarkForGhoshak;
      input.remarkForPartner = rowData.remarkForPartner;
      input.notes = rowData.notes;
      input.period = `${moment(rowData.cycleStartDate).format(
        "MMM Do YYYY"
      )} - ${moment(rowData.cycleEndDate).format("MMM Do YYYY")}`;
      input.referralCode = rowData.referralCode;
      input.paidBy = rowData.paidBy;
      input.paidOn = rowData.paidOn;
      input.updatedOn = rowData.updatedOn;
      input.paymentVersion = JSON.stringify(rowData.paymentVersion);
      input.details = JSON.stringify(rowData.details);
      return input;
    });

    setDownloadData(excelDownloadArray);
    setActive(true);
  };
  const getShopDetails = async (shopId) => {
    await Promise.all([
      fetch(`${config.ApiGateway}/viewShopById`, {
        method: "POST",
        body: JSON.stringify({ shopId: shopId }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        console.log(data);
        setShopDetails(data);
        setModalShow(true);
        // setModalStatement(false);
        // alert("Statement Updated");
        // setStatementInput(initialState);
        // await getPartnerData();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [downloadData]);
  return (
    <div className="profileCardContainer">
      <Container fluid>
        <PartnerProfile userProfile={state.userProfile} />
        <div className="vertical-space-20"></div>

        <PartnerDownload
          e
          shop={state.shop}
          download={state.download}
          classes={classes}
        />
        <div className="vertical-space-20"></div>

        <div className="vertical-space-20"></div>

        <PartnerEmployee
          employee={state.employee}
          employeeRefShops={employeeRefShops}
          getPartnerInfo={getPartnerInfo}
          userProfile={state.userProfile}
          classes={classes}
        />

        <div className="vertical-space-20"></div>
        <Row>
          <Col sm="12">
            <Tabs
              id="controlled-tab-example"
              className="partnerDashboardTab"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="refShop" title="Reffered Shops">
                <div className="vertical-space-20"></div>
                <div className="profileCardContainer">
                  <Row>
                    <Col sm="12">
                      <div className="tableCard">
                        <div className="tableCardBody">
                          <MaterialTable
                            style={{
                              border: "none",
                              boxShadow: "none",
                              backgroundColor: "#ffffff",
                            }}
                            title="Shops"
                            columns={[
                              {
                                title: "Shop Id",
                                field: "userId10Digit",
                              },
                              { title: "Shop Name", field: "shopName" },
                              { title: "Shop Type", field: "shopType" },
                              {
                                title: "Registered on",
                                field: "userAddedDate",
                              },
                              {
                                title: "Phone Number",
                                field: "userPhoneNumber",
                              },
                              { title: "Shop Address", field: "shopAddress" },
                              {
                                title: "Reffered By",
                                field: "referredBy",
                                render: (rowData) => {
                                  let value = state.organization.find(
                                    (elements) =>
                                      rowData.referredBy ==
                                      elements.referralCode
                                  );
                                  if (value != undefined) {
                                    return `${value.userName} (${rowData.referredBy})`;
                                  }
                                },
                              },

                              {
                                title: "Action",
                                field: "view",
                                render: (rowData) => {
                                  return (
                                    <Button
                                      onClick={() => {
                                        getShopDetails(rowData.userId);
                                      }}
                                    >
                                      View Shop
                                    </Button>
                                  );
                                },
                              },
                            ]}
                            data={state.shop}
                            options={{
                              rowStyle: {
                                fontSize: 12,
                              },
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>

              <Tab eventKey="statement" title="Statement">
                {isLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      float: "left",
                      width: "100%",
                    }}
                  >
                    <Skeleton animation="wave" width={340} height={138} />
                  </div>
                ) : (
                  <>
                    <div className="vertical-space-20"></div>
                    <div className="profileCardContainer">
                      <Row>
                        <Col sm="4">
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                              <Form.Label>Filter By Payment Year</Form.Label>
                              <Form.Control
                                onChange={(e) =>
                                  setFilterByPayment(e.target.value)
                                }
                                as="select"
                              >
                                <option value="all">All</option>
                                {paymentMonthDropdown}
                              </Form.Control>
                            </Form.Group>
                          </Form.Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <div className="tableCard">
                            <div className="tableCardBody">
                              <MaterialTable
                                title={"Statement"}
                                style={{
                                  border: "none",
                                  boxShadow: "none",
                                  backgroundColor: "#ffffff",
                                }}
                                options={{
                                  rowStyle: {
                                    fontSize: 12,
                                  },
                                }}
                                columns={[
                                  {
                                    title: "Period",
                                    field: "cycleStartDate",
                                    render: (rowData) => {
                                      return `${moment(
                                        rowData.cycleStartDate
                                      ).format("MMM Do YYYY")} - ${moment(
                                        rowData.cycleEndDate
                                      ).format("MMM Do YYYY")}`;
                                    },
                                  },
                                  {
                                    title: "Criteria",
                                    field: "criteria",
                                    render: (rowData) => (
                                      <b style={{ textTransform: "uppercase" }}>
                                        {rowData.criteria}
                                      </b>
                                    ),
                                  },
                                  { title: "Shop Name", field: "shopName" },
                                  { title: "Status", field: "approvedStatus" },
                                  {
                                    title: "Ref Code",
                                    field: "referralCode",
                                    render: (rowData) => {
                                      let value = state.organization.find(
                                        (elements) =>
                                          rowData.referralCode ==
                                          elements.referralCode
                                      );
                                      if (value != undefined) {
                                        return `${value.userName} (${rowData.referralCode})`;
                                      }
                                    },
                                  },
                                  { title: "Amount", field: "amountPaid" },
                                  {
                                    title: "Payment (M/Y)",
                                    field: "paymentDate",
                                    render: (rowData) => {
                                      let value = paymentMonthDropdown.find(
                                        (elements) =>
                                          rowData.paymentDate ==
                                          elements.props.value
                                      );

                                      if (value != undefined) {
                                        return `${value.props.children}`;
                                      }
                                    },
                                  },
                                  {
                                    title: "Criteria Details",
                                    field: "details",
                                    export: false,
                                    editable: "never",
                                    render: (rowData) => {
                                      return (
                                        <ul
                                          style={{
                                            listStyle: "none",
                                            padding: "0px",
                                            margin: "0px",
                                          }}
                                        >
                                          {Object.keys(rowData.details).map(
                                            (items) => (
                                              <li
                                                style={{
                                                  listStyle: "none",
                                                  padding: "0px",
                                                  margin: "0px",
                                                  fontSize: "10px",
                                                }}
                                              >
                                                <b>{items}</b> :{" "}
                                                {rowData.details[
                                                  items
                                                ].toString()}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      );
                                    },
                                  },
                                  {
                                    title: "Criteria Met",
                                    field: "criteriaMet",
                                  },
                                  {
                                    title: "Remark (Ghoshak)",
                                    field: "remarkForGhoshak",
                                    hidden: !(role == "managingDirector"),
                                  },
                                  {
                                    title: "Remark",
                                    field: "remarkForPartner",
                                    hidden: role == "managingDirector",
                                  },
                                  {
                                    title: "Action",
                                    field: "view",
                                    render: (rowData) => {
                                      return (
                                        <Button
                                          onClick={() => {
                                            getShopDetails(rowData.shopId);
                                          }}
                                        >
                                          View Shop
                                        </Button>
                                      );
                                    },
                                  },
                                  {
                                    title: "Action",
                                    field: "action",
                                    hidden: !(role == "managingDirector"),
                                    render: (rowData) => (
                                      <Button
                                        onClick={() =>
                                          modalStatementShow(rowData)
                                        }
                                      >
                                        Edit
                                      </Button>
                                    ),
                                  },

                                  {
                                    title: "Export",
                                    field: "export",
                                    render: (rowData) => (
                                      <>
                                        <Button
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "#909090",
                                            border: "none",
                                            boxShadow: "none",
                                          }}
                                          onClick={() => {
                                            return exportAsExcel([rowData]);
                                          }}
                                        >
                                          <SystemUpdateAltIcon />
                                        </Button>
                                      </>
                                    ),
                                  },
                                ]}
                                data={filteredPayment}
                                options={{
                                  exportButton: true,
                                  exportCsv: (columns, data) => {
                                    return exportAsExcel(data);
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              </Tab>

              <Tab eventKey="pendingStatement" title="Pending Statement">
                {isLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      float: "left",
                      width: "100%",
                    }}
                  >
                    <Skeleton animation="wave" width={340} height={138} />
                  </div>
                ) : (
                  <>
                    <div className="profileCardContainer">
                      <Row>
                        <Col sm="12">
                          <div className="tableCard">
                            <div className="tableCardBody">
                              <MaterialTable
                                style={{
                                  border: "none",
                                  boxShadow: "none",
                                  backgroundColor: "#ffffff",
                                }}
                                title="Pending Statement"
                                columns={[
                                  {
                                    title: "Period",
                                    field: "period",
                                    editable: "never",
                                    render: (rowData) => {
                                      return `${moment(
                                        rowData.cycleStartDate
                                      ).format("MMM Do YYYY")} - ${moment(
                                        rowData.cycleEndDate
                                      ).format("MMM Do YYYY")}`;
                                    },
                                  },
                                  {
                                    title: "Criteria",
                                    field: "criteria",
                                    editable: "never",
                                    render: (rowData) => (
                                      <b style={{ textTransform: "uppercase" }}>
                                        {rowData.criteria}
                                      </b>
                                    ),
                                  },
                                  {
                                    title: "Shop Name",
                                    field: "shopName",
                                    editable: "never",
                                  },

                                  {
                                    title: "Amount",
                                    field: "amountPaid",
                                    type: "numeric",
                                    validate: (rowData) =>
                                      rowData.amountPaid > -1,
                                    render: (rowData) => (
                                      <b>₹&nbsp;{rowData.amountPaid}</b>
                                    ),
                                  },
                                  {
                                    title: "Payment Date",
                                    field: "paymentDate",
                                    editComponent: (props) => (
                                      <select
                                        type="text"
                                        value={props.value}
                                        onChange={(e) =>
                                          props.onChange(e.target.value)
                                        }
                                      >
                                        <option>Select Payment(Y/M)</option>
                                        {paymentMonthDropdown}
                                      </select>
                                    ),
                                  },
                                  {
                                    title: "Approved Status",
                                    field: "approvedStatus",
                                    editComponent: (props) => (
                                      <select
                                        type="text"
                                        value={props.value}
                                        onChange={(e) =>
                                          props.onChange(e.target.value)
                                        }
                                      >
                                        <option value="approved">
                                          Approved
                                        </option>
                                        <option value="pending">Pending</option>
                                        <option value="rejected">
                                          Rejected
                                        </option>
                                      </select>
                                    ),
                                  },
                                  {
                                    title: "Criteria Met",
                                    field: "criteriaMet",
                                    editComponent: (props) => (
                                      <select
                                        type="text"
                                        value={props.value}
                                        onChange={(e) =>
                                          props.onChange(e.target.value)
                                        }
                                      >
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                      </select>
                                    ),
                                  },

                                  {
                                    title: "Criteria Details",
                                    field: "details",
                                    export: false,
                                    editable: "never",
                                    render: (rowData) => {
                                      return (
                                        <ul
                                          style={{
                                            listStyle: "none",
                                            padding: "0px",
                                            margin: "0px",
                                          }}
                                        >
                                          {Object.keys(rowData.details).map(
                                            (items) => (
                                              <li
                                                style={{
                                                  listStyle: "none",
                                                  padding: "0px",
                                                  margin: "0px",
                                                }}
                                              >
                                                <b>{items}</b> :{" "}
                                                {rowData.details[
                                                  items
                                                ].toString()}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      );
                                    },
                                  },
                                  {
                                    title: "Notes",
                                    field: "notes",
                                  },
                                  {
                                    title: "Remarks (Ghoshak)",
                                    field: "remarkForGhoshak",
                                    hidden: !(role == "managingDirector"),
                                  },
                                  {
                                    title: "Remarks (Partner)",
                                    field: "remarkForPartner",
                                    hidden: !(role == "managingDirector"),
                                  },
                                  {
                                    title: "Reffered By",
                                    field: "referredBy",
                                    editable: "never",
                                    render: (rowData) => {
                                      let value = state.organization.find(
                                        (elements) =>
                                          rowData.referralCode ==
                                          elements.referralCode
                                      );
                                      if (value != undefined) {
                                        return `${value.userName} (${rowData.referralCode})`;
                                      }
                                    },
                                  },

                                  {
                                    title: "Action",
                                    field: "view",
                                    editable: "never",
                                    render: (rowData) => {
                                      return (
                                        <Button
                                          onClick={() => {
                                            getShopDetails(rowData.shopId);
                                          }}
                                        >
                                          View Shop
                                        </Button>
                                      );
                                    },
                                  },
                                ]}
                                data={pendingStatement}
                                options={{
                                  rowStyle: {
                                    fontSize: 12,
                                  },
                                }}
                                editable={{
                                  isEditable: (rowData) =>
                                    role == "managingDirector",
                                  onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                      setTimeout(() => {
                                        addStatement(newData);
                                        resolve();
                                      });
                                    }),
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              </Tab>
            </Tabs>
          </Col>
        </Row>

        <div className="vertical-space-20"></div>
        <CSVLink data={downloadData} ref={csvLink} target="_blank" />
        <div className="vertical-space-20"></div>
        <Modal
          className="viewCartDetails"
          show={modalStatement}
          onHide={() => setModalStatement(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Row
              style={{
                borderBottom: "1px solid #dedede",
                paddingBottom: "18px",
              }}
            >
              <div style={{ paddingBottom: "10px" }} className="title">
                <h4>
                  <span>Profile</span>
                </h4>
              </div>
              <Col xs="6">
                <Form.Group as={Row} id="partnerStatement">
                  <Form.Label column sm="4" className="labelStyle">
                    Period
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      plaintext
                      readOnly
                      className="inputValue"
                      defaultValue={`${moment(
                        modelStatementData.cycleStartDate
                      ).format("MMM Do YYYY")} - ${moment(
                        modelStatementData.cycleEndDate
                      ).format("MMM Do YYYY")}`}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} id="partnerStatement">
                  <Form.Label column sm="4" className="labelStyle">
                    Refferal Code
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      className="inputValue"
                      plaintext
                      value={modelStatementData.referralCode}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs="6" style={{ textAlign: "right" }}>
                <Form.Group as={Row} id="partnerStatement">
                  <Form.Label column sm="4" className="labelStyle">
                    Paid On
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      style={{ textAlign: "right" }}
                      className="inputValue"
                      plaintext
                      defaultValue={modelStatementData.paidOn}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} id="partnerStatement">
                  <Form.Label column sm="4" className="labelStyle">
                    Updated by
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      style={{ textAlign: "right" }}
                      plaintext
                      readOnly
                      className="inputValue"
                      value={modelStatementData.paidBy}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Form onSubmit={updatePayment}>
              <Row
                style={{
                  borderBottom: "1px solid #dedede",
                  paddingBottom: "18px",
                  paddingTop: "10px",
                }}
              >
                <Col md="7" xs="7">
                  <div style={{ paddingBottom: "10px" }} className="title">
                    <h4>
                      <span>Edit Statement</span>
                    </h4>
                  </div>
                  <div style={{ width: "100%", float: "left" }}>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                      <Form.Label column sm="4" className="labelStyle">
                        Add Amount
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          value={statementInput.addAmount}
                          name="addAmount"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                      <Form.Label column sm="4">
                        Payment Date
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          value={statementInput.paymentDate}
                          name="paymentDate"
                          placeholder="₹ INR"
                          required
                        >
                          {paymentMonthDropdown}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                      <Form.Label column sm="4">
                        Criteria Met
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          value={statementInput.criteriaMet}
                          name="criteriaMet"
                          placeholder="₹ INR"
                          required
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                      <Form.Label column sm="4">
                        Approved Status
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          value={statementInput.approvedStatus}
                          name="approvedStatus"
                          required
                        >
                          <option value="approved">Approved</option>
                          <option value="pending">Pending</option>
                          <option value="rejected">Rejected</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                      <Form.Label column sm="4" className="labelStyle">
                        Notes
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="notes"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          as="textarea"
                          value={statementInput.notes}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                      <Form.Label column sm="4" className="labelStyle">
                        Remarks (Ghoshak)
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          value={statementInput.remarkForGhoshak}
                          name="remarkForGhoshak"
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                      <Form.Label column sm="4" className="labelStyle">
                        Remarks (Partner)
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                          }
                          value={statementInput.remarkForPartner}
                          name="remarkForPartner"
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
                <Col md="5" xs="5">
                  <div style={{ paddingBottom: "10px" }} className="title">
                    <h4>
                      <span>Payment Version</span>
                    </h4>
                  </div>
                  <div className={classes.root}>
                    <TableContainer className={classes.container}>
                      <Table size="small" className={classes.table}>
                        <TableHead className={classes.tableHeadBody}>
                          <TableRow>
                            <TableCell className={classes.tableHead}>
                              Date
                            </TableCell>

                            <TableCell className={classes.tableHead}>
                              Amount
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                              Paid By
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {modelStatementData.paymentVersion
                            .sort((a, b) => new Date(a.date) - new Date(b.date))
                            .map((elements) => (
                              <TableRow key={"amount"}>
                                <TableCell className={classes.tableCell}>
                                  {elements.date}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  component="th"
                                  scope="row"
                                >
                                  {elements.amount}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {elements.paidBy}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Col>
              </Row>
              <Row style={{ textAlign: "right" }}>
                <Col sm="5" xs="7"></Col>
                <Col sm="7" xs="5">
                  <Form.Group as={Row} id="partnerStatement">
                    <Form.Label
                      style={{
                        fontWeight: "400",
                        color: "#333",
                        fontSize: "16px",
                      }}
                      column
                      sm="4"
                    >
                      Total
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        plaintext
                        style={{
                          textAlign: "center",
                          fontWeight: "400",
                          color: "#888",
                          fontSize: "16px",
                        }}
                        value={`₹ ${
                          isNaN(parseFloat(statementInput.addAmount))
                            ? 0 + parseFloat(modelStatementData.amountPaid)
                            : parseFloat(statementInput.addAmount) +
                              parseFloat(modelStatementData.amountPaid)
                        }`}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ paddingTop: "40px", float: "right" }}>
                <Col sm="12">
                  <Button type="submit">Update</Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          className="viewCartDetails"
          show={modalShow}
          onHide={handleClose}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <ViewShopProfileByPartner shopDetails={shopDetails} />
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
}
