import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Row, Button } from "react-bootstrap";
import config from "../../config";
export default class PincodeShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showdata: false,
      showTable: ""
    };
    this.getMerchantDetails = this.getMerchantDetails.bind(this);
  }
  componentDidMount() {
    Promise.all([
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        var mergePincodeAndArea = this.props.details.map(items => {
          let count = data.Items.find(
            elements => items.userPincode === elements.pincode
          );

          let appendObject = Object.assign({}, items, count);

          return appendObject;
        });
        // console.log(mergePincodeAndArea);
        this.setState({
          showdata: true,
          showTable: mergePincodeAndArea
        });
      })
      .catch(err => alert("FUNCTION CALL FAILED"));
  }
  getMerchantDetails(userid) {
    this.props.getMerchant(userid);
  }

  render() {
    return (
      <>
        {this.state.showdata && (
          <>
            <Row>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  margin: 1 + "%"
                }}
              >
                <h3 style={{ textAlign: "center" }}> SHOP DETAILS </h3>
              </div>
              <Table
                className="division"
                style={{
                  width: 100 + "%",
                  border: 0.5 + "px",
                  borderColor: "#ced4da",
                  borderStyle: "solid"
                }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S NO</TableCell>
                    <TableCell align="center">USER ID</TableCell>
                    <TableCell align="center">SHOP NAME</TableCell>
                    <TableCell align="center">MERCHANT NAME</TableCell>
                    <TableCell align="center">PINCODE</TableCell>
                    <TableCell align="center">PLACE</TableCell>
                    <TableCell align="center">SHOP TYPE</TableCell>
                    <TableCell align="center">ADDRESS</TableCell>
                    <TableCell align="center">PHONE NUMBER</TableCell>

                    <TableCell
                      style={{
                        display:
                          this.props.hide === "TOTAL OFFERS" ? "none" : ""
                      }}
                      align="center"
                      align="center"
                    >
                      TOTAL OFFERS
                    </TableCell>
                    <TableCell
                      style={{
                        display:
                          this.props.hide === "TOTAL COLLECTION" ? "none" : ""
                      }}
                      align="center"
                    >
                      TOTAL COLLECTION
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.showTable.map((row, index) => (
                    <>
                      <TableRow key={row.userId10Digit}>
                        <>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            <Button
                              variant="link"
                              onClick={() => {
                                this.getMerchantDetails(row.userId10Digit);
                              }}
                            >
                              {row.userId10Digit}
                            </Button>
                          </TableCell>
                          <TableCell align="center">{row.shopName}</TableCell>
                          <TableCell align="center" key={row.userName}>
                            {row.userName}
                          </TableCell>
                          <TableCell align="center">
                            {row.userPincode}
                          </TableCell>
                          <TableCell align="center">{row.place}</TableCell>
                          <TableCell align="center">{row.shopType}</TableCell>

                          <TableCell align="center">
                            {row.shopAddress}
                          </TableCell>
                          <TableCell align="center">
                            {row.userPhoneNumber}
                          </TableCell>
                          <TableCell
                            style={{
                              display:
                                this.props.hide === "TOTAL OFFERS" ? "none" : ""
                            }}
                            align="center"
                          >
                            {row.totaloffers}
                          </TableCell>
                          <TableCell
                            style={{
                              display:
                                this.props.hide === "TOTAL COLLECTION"
                                  ? "none"
                                  : ""
                            }}
                            align="center"
                          >
                            {row.totalCollection}
                          </TableCell>
                        </>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Row>
          </>
        )}
      </>
    );
  }
}
