import React, { Component } from "react";
import TotalRegistration from "./merchantAnalysis/TotalRegistration";
import CouponTable from "./merchantAnalysis/couponTable";
import PincodeMerchant from "./merchantAnalysis/PincodeMerchant";
import MerchantOffers from "./merchantAnalysis/merchantOffers";
import { Row, Col, Container } from "react-bootstrap";
import CouponsCreated from "./merchantAnalysis/couponsCreated";
import Pincode from "./merchantAnalysis/Pincode";
import TotalLoyality from "./merchantAnalysis/TotalLoyality";
import MerchantSegment from "./merchantAnalysis/MerchantSegment";
import ShopDetails from "./merchantAnalysis/ShopDetails";
import PincodeInfo from "./merchantAnalysis/PincodeInfo";
import PincodeShop from "./merchantAnalysis/PincodeShop";
import MerchantDetails from "./merchantAnalysis/merchantDetails";
import MerchantCollection from "./merchantAnalysis/merchantCollection";
import SegmentDetails from "./merchantAnalysis/SegmentDetails";
import PromocodeCreated from "./merchantAnalysis/promocodeCreated";
import MerchantAddedCustomer from "./merchantAnalysis/merchantAddedCustomer";
import OffersCreated from "./merchantAnalysis/offerCreation";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MerchantCoupon from "./merchantAnalysis/merchantCoupons";
import CustomerTable from "./merchantAnalysis/customerTable";
import config from "../config";

function getStepContent(
  item,
  store1,
  store,
  functioncall,
  functioncall2,
  store2,
  heading,
  dates,
  functioncall3,
  functioncall4,
  functioncall5,
  functionCall6,
  getCouponShops,
  getCustomer,
  getRedeemedCoupons
) {
  switch (item) {
    case "Pincode":
      // Renders import class
      return <Pincode allPincode={store1} title={heading} />;
    case "merchantCoupons":
      return <MerchantCoupon coupon={store} getMerchant={functionCall6} />;
    case "totalRegistration":
      return <TotalRegistration title={heading} />;
    case "merchantOffers":
      return (
        <MerchantOffers
          offer={store1}
          shop={store}
          info={functioncall2}
          title={heading}
          dates={dates}
          sortDate={functioncall3}
        />
      );
    case "pincodeMerchant":
      return (
        <PincodeMerchant pincode={store1} allPincode={store} title={heading} />
      );
    case "merchantSegment":
      return <MerchantSegment SHOPID={store1} title={heading} />;
    case "loyaltyProgram":
      return (
        <TotalLoyality
          totalMerchant={store1}
          title={heading}
          info={functioncall5}
          getMerchant={functionCall6}
        />
      );
    case "shopDetails":
      return <ShopDetails shopId={store1} />;
    case "pincodeinfo":
      return (
        <PincodeInfo pincode={store1} info={functioncall2} title={heading} />
      );
    case "merchantDetails":
      return (
        <MerchantDetails
          title={heading}
          userId={store1}
          info={getCustomer}
          getRedeemedCoupons={getRedeemedCoupons}
        />
      );
    case "getShop":
      return (
        <PincodeShop
          details={store1}
          getMerchant={functionCall6}
          hide={store2}
        />
      );
    case "merchantCollection":
      return (
        <MerchantCollection
          collection={store1}
          shop={store}
          info={functioncall2}
          title={heading}
          dates={dates}
          sortbyDate={functioncall4}
        />
      );
    case "segmentdetails":
      return (
        <SegmentDetails
          shops={store1}
          title={heading}
          getMerchant={functionCall6}
        />
      );
    case "addedCustomer":
      return <MerchantAddedCustomer getMerchant={functionCall6} />;
    case "couponsCreated":
      return <CouponsCreated getMerchant={functionCall6} />;
    case "promocodeCreated":
      return (
        <PromocodeCreated
          getMerchant={functionCall6}
          getCouponShops={getCouponShops}
          couponType={store1}
        />
      );
    case "getCouponShops":
      return (
        <CouponTable
          getMerchant={functionCall6}
          shops={store}
          store1={store1}
        />
      );
    case "getCustomer":
      return <CustomerTable customerId={store} />;
    case "offersCreated":
      return <OffersCreated />;
    default:
      return <></>;
  }
}
export default class MerchantIndex extends Component {
  constructor(props) {
    super(props);
    this.merchantDetails = [];
    this.state = {
      pincode: "",
      checkTable: "",
      tableFetch: [],
      returnData: false,
      allpincode: [],
      classRender: "",
      store1: "",
      store2: "",
      dddd: "",
      store: [],
      open: false,
      heading: "",
      dates: "",
      totalSegment: [],
      userId: [],
    };
    this.addedCustomers = this.addedCustomers.bind(this);
    this.totalPincode = this.totalPincode.bind(this);
    this.totalRegistration = this.totalRegistration.bind(this);
    this.MerchantOffers = this.MerchantOffers.bind(this);
    this.MerchantCollection = this.MerchantCollection.bind(this);
    this.pincodeMerchant = this.pincodeMerchant.bind(this);
    this.loyaltyProgram = this.loyaltyProgram.bind(this);
    this.merchantSegment = this.merchantSegment.bind(this);
    this.ShopDetails = this.ShopDetails.bind(this);
    this.viewShopDetails = this.viewShopDetails.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.segment = this.segment.bind(this);
    this.loyaltyShops = this.loyaltyShops.bind(this);
    this.renderUserDetails = this.renderUserDetails.bind(this);
    this.merchantCoupon = this.merchantCoupon.bind(this);
    this.couponsCreated = this.couponsCreated.bind(this);
    this.getMerchant = this.getMerchant.bind(this);
    this.promocode = this.promocode.bind(this);
    this.getCouponShops = this.getCouponShops.bind(this);
    this.getCustomer = this.getCustomer.bind(this);
    this.getRedeemedCoupons = this.getRedeemedCoupons.bind(this);
    this.offersCreated = this.offersCreated.bind(this);
  }
  promocode(coupon) {
    this.setState({
      classRender: "promocodeCreated",
      store1: coupon,
    });
  }
  offersCreated() {
    this.setState({
      classRender: "offersCreated",
    });
  }
  getMerchant(userId) {
    this.setState({
      heading: "MERCHANT DETAILS",
      classRender: "merchantDetails",
      store1: userId,
    });
  }

  async componentDidMount() {
    let searchParams = new URLSearchParams(window.location.search);

    if (
      searchParams.get("userid") !== null &&
      searchParams.get("userid") !== undefined
    ) {
      await this.getMerchant(searchParams.get("userid"));
    }
    await this.renderUserDetails();
  }

  renderUserDetails() {
    // Get all shop details
    var userId = [];
    var userDetails = [];
    var getSegment = [];
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [{ match: { userType: "Shop" } }],
              must_not: {
                bool: {
                  must: [{ term: { userPincode: "111111" } }],
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
      fetch(`${config.json.URL}/fields.json`),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, segment]) => {
        segment.shopTypes.slice(1).forEach((elements) => {
          getSegment.push(elements.value);
        });

        data.hits.hits.map((element) => userDetails.push(element._source));
        console.log(userDetails, "total shops");
        userId = userDetails.map((elements) => elements.userId);

        return this.setState({
          tableFetch: userDetails, // all shop list
          returnData: true,
          userId: userId,
          totalSegment: getSegment,
        });
      })
      .catch((err) => console.log(err, "function call failed"));
  }
  addedCustomers() {
    this.setState({
      classRender: "addedCustomer",
    });
  }

  merchantCoupon() {
    var tempdata = [];
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/coupons/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                { match: { couponCategory: "Created" } },
                { match: { couponType: "MERCHANT" } },
              ],
              must_not: {
                bool: {
                  must: [{ term: { pincode: "111111" } }],
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map((element) => element._source);
        var shopIdArray = tempdata.map((items) => items.shopId);

        shopIdArray = [...new Set(shopIdArray)];

        var used = 0;
        var consumedValue = 0;
        var result = [];
        shopIdArray.map((elements) => {
          used = 0;
          consumedValue = 0;
          tempdata.map((items) => {
            if (elements === items.shopId) {
              used +=
                parseInt(items.totalCouponCount) -
                parseInt(items.remainingCouponCount);
              consumedValue +=
                (parseInt(items.totalCouponCount) -
                  parseInt(items.remainingCouponCount)) *
                parseInt(items.maximumCouponAmount);
            }
          });
          result.push(
            {
              userId: elements,
              couponsUsed: used,
              consumedValue: consumedValue,
            },
            {
              userId: shopIdArray,
            }
          );
        });

        this.setState({
          heading: "MERCHANT COUPONS",
          classRender: "merchantCoupons",
          store: result,
        });
      })
      .catch((err) => console.log(err));
  }
  loyaltyShops(shops) {
    this.setState({
      heading: "LOYALTY SHOP DETAILS",
      classRender: "segmentdetails",
      store1: shops,
    });
  }
  segment(items) {
    var segment = [];
    Promise.all([
      fetch(
        `${config.Elasticsearch.URL}/users/_search?size=10000`,

        {
          method: "POST",
          body: JSON.stringify({
            query: {
              bool: {
                must: [{ match: { shopType: items } }],
                must_not: {
                  bool: {
                    must: [{ term: { userPincode: "111111" } }],
                  },
                },
              },
            },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        data.hits.hits.map((element) => segment.push(element._source));

        this.setState({
          heading: items,
          classRender: "segmentdetails",
          store1: segment,
        });
      });
  }
  ShopDetails(items) {
    this.setState({
      classRender: "shopDetails",
      store1: items,
    });
  }

  getCustomer(argument) {
    this.setState({
      heading: "PINCODE STATISTICS",
      classRender: "getCustomer",
      store: argument,
    });
  }

  viewShopDetails(items, hideCell) {
    this.setState({
      heading: "PINCODE STATISTICS",
      classRender: "getShop",
      store1: items,
      store2: hideCell,
    });
  }
  getRedeemedCoupons(couponid) {
    this.setState({
      classRender: "getCouponShops",
      store1: couponid,
      store: [],
    });
  }
  getCouponShops(shops) {
    this.setState({
      classRender: "getCouponShops",
      store: shops,
      store1: "",
    });
  }
  couponsCreated() {
    this.setState({
      classRender: "couponsCreated",
    });
  }
  merchantSegment() {
    var dummy = [];
    var tempData = [];

    this.state.tableFetch.map((Element) => {
      dummy.push({ shopId: Element.userId, shopType: Element.shopType });
    });

    this.setState({
      heading: "MERCHANT SEGMENT",
      classRender: "merchantSegment",
      store1: dummy,
    });
  }
  loyaltyProgram() {
    this.setState({
      // store1: merchant,
      heading: "LOYALTY PROGRAM",
      classRender: "loyaltyProgram",
    });
    // });
  }
  MerchantOffers(items) {
    this.setState({
      classRender: "",
    });
    var tempData = [];
    var dummy = [];
    var totalItem = [];
    var offerDetails = [];
    var shopId = [];

    fetch(`${config.Elasticsearch.URL}/offers/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        query: {
          constant_score: {
            filter: {
              bool: {
                should: [{ terms: { shopId: this.state.userId } }],
                must: [
                  {
                    range: {
                      offerAddedDate: {
                        gte: items,
                      },
                    },
                  },
                  {
                    match: {
                      offerType: "offer",
                    },
                  },
                ],
                must_not: [{ match: { shopPincode: "111111" } }],
              },
            },
          },
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        totalItem = data.hits.hits.map((element) => element._source);

        offerDetails = totalItem.map((items) => items);

        shopId = this.state.userId;

        this.setState({
          heading: "MERCHANT OFFERS",
          classRender: "merchantOffers",
          store1: offerDetails,
          store: shopId,
          dates: items,
        });
      });
  }
  MerchantCollection(items) {
    let getSortBydate = items === "" ? undefined : items;
    var dummy = [];
    var totalItem = [];
    var offerDetails = [];
    var shopId = [];
    var tempData = [];
    this.setState({
      classRender: "",
    });
    // this.state.tableFetch.map(items => dummy.push(items.userId));

    fetch(`${config.Elasticsearch.URL}/offers/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        query: {
          constant_score: {
            filter: {
              bool: {
                should: [{ terms: { shopId: this.state.userId } }],
                must: [
                  {
                    range: {
                      offerAddedDate: {
                        gte: getSortBydate,
                      },
                    },
                  },
                  {
                    match: {
                      offerType: "item",
                    },
                  },
                ],
                must_not: [{ match: { shopPincode: "111111" } }],
              },
            },
          },
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        totalItem = data.hits.hits.map((element) => element._source);

        offerDetails = totalItem.map((items) => items);

        shopId = this.state.userId;

        this.setState({
          heading: "MERCHANT COLLECTION",
          classRender: "merchantCollection",
          store1: offerDetails,
          store: shopId,
          dates: items,
        });
      });
  }
  totalPincode() {
    var allPincode = [];

    allPincode = this.state.tableFetch.map((items) => {
      return items.userPincode; // extract pincode from
    });
    allPincode = [...new Set(allPincode)]; // remove duplicated
    this.setState({
      heading: "PINCODE",
      classRender: "Pincode",
      store1: allPincode, // all pincode
    });
  }
  totalRegistration() {
    this.setState({
      heading: "TOTAL REGISTRATION",
      classRender: "totalRegistration",
    });
  }
  pincodeMerchant() {
    var pincodeDetails = [];
    var totalPincode = [];
    var allPincode = [];

    allPincode = this.state.tableFetch.map((items) => {
      return items.userPincode;
    });
    allPincode = [...new Set(allPincode)];

    this.setState({
      heading: "PINCODE MERCHANT",
      classRender: "pincodeMerchant",

      store1: this.state.tableFetch,
      store: allPincode,
    });
  }
  handleClick(e) {
    this.setState({
      open: !this.state.open,
    });
  }
  render() {
    return (
      <>
        <Container fluid>
          <Row>
            {this.state.returnData && (
              <>
                <Col md={{ span: 2 }}>
                  <List
                    style={{ width: "100%", maxWidth: 360 }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        METRICS
                      </ListSubheader>
                    }
                  >
                    <Divider />
                    <ListItem
                      button
                      onClick={() => {
                        this.totalPincode(); // call stotal pincode function
                      }}
                    >
                      <ListItemText primary=" TOTAL PINCODE" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        this.totalRegistration();
                      }}
                    >
                      <ListItemText primary=" TOTAL REGISTRATION" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        this.MerchantOffers();
                      }}
                    >
                      <ListItemText primary=" MERCHANT OFFERS" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        this.pincodeMerchant();
                      }}
                    >
                      <ListItemText primary="PINCODE BASED MERCHANT" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({
                          heading: "PINCODE STATISTICS",
                          classRender: "pincodeinfo",
                        });
                      }}
                    >
                      <ListItemText primary="PINCODE STATISTICS" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        this.getMerchant("");
                      }}
                    >
                      <ListItemText primary="MERCHANT DETAILS" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        this.merchantSegment(3);
                      }}
                    >
                      <ListItemText primary="MERCHANT BY SEGMENT" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        this.merchantCoupon();
                      }}
                    >
                      <ListItemText primary="MERCHANT COUPON" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        this.loyaltyProgram();
                      }}
                    >
                      <ListItemText primary="MERCHANT LOYALTY PROGRAM" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        this.MerchantCollection();
                      }}
                    >
                      <ListItemText primary=" MERCHANT COLLECTION" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        this.addedCustomers();
                      }}
                    >
                      <ListItemText primary=" MERCHANT ADDED CUSTOMERS" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        this.couponsCreated();
                      }}
                    >
                      <ListItemText primary="COUPONS CREATED" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        this.offersCreated();
                      }}
                    >
                      <ListItemText primary="OFFERS CREATED" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        this.promocode("PROMOCODE");
                      }}
                    >
                      <ListItemText primary="PROMOCODE COUPONS CREATED" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        this.promocode("GHOSHAK");
                      }}
                    >
                      <ListItemText primary="GHOSHAK COUPONS CREATED" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        this.promocode("MERCHANT");
                      }}
                    >
                      <ListItemText primary="MERCHANT COUPONS CREATED" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => {
                        this.promocode("LOYALTY");
                      }}
                    >
                      <ListItemText primary="LOYALTY COUPONS CREATED" />
                    </ListItem>

                    <ListItem button onClick={this.handleClick}>
                      <ListItemText primary="SEGMENTS" />
                      {this.state.open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <>
                          {this.state.totalSegment.map((elements) => (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  this.segment(elements);
                                }}
                              >
                                <ListItemText primary={elements} />
                              </ListItem>
                            </>
                          ))}
                        </>
                      </List>
                    </Collapse>
                  </List>
                </Col>
              </>
            )}

            {/* <Row> */}
            <Col md={{ span: 10 }}>
              {getStepContent(
                this.state.classRender,
                this.state.store1,
                this.state.store,
                this.ShopDetails,
                this.viewShopDetails,
                this.state.store2,
                this.state.heading,
                this.state.dates,
                this.MerchantOffers,
                this.MerchantCollection,
                this.loyaltyShops,
                this.getMerchant,
                this.getCouponShops,
                this.getCustomer,
                this.getRedeemedCoupons
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
