import React, { useState, useEffect } from "react";
import { Container, Col, Button, Row, Form, Modal } from "react-bootstrap";
import MaterialTable from "material-table";
import config from "../config";
import Limit from "../limit";
import { Link } from "react-router-dom";
import { ClickAwayListener } from "@material-ui/core";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Col10 } from "aws-amplify-react/lib-esm/AmplifyTheme";
export default function OffersCreatedByDate() {
  let currentDate = new Date();
  const [state, setState] = useState({
    startDate: new Date(currentDate.setDate(currentDate.getDate() - 225))
      .toISOString()
      .substring(0, 10),
    endDate: new Date().toISOString().substring(0, 10),
  });

  const [offerType, setOffertype] = useState("All");
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const [filterByPincode, setfilterByPincode] = useState([]);
  const [pincode, setPincode] = useState("All");
  const [offerList, setOfferList] = useState([]);
  let offers = [];
  useEffect(() => {
    getOffers("offer");
  }, []);
  const getOffers = async (value) => {
    return await Promise.all([
      fetch(`${config.Elasticsearch.URL}/offers/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                {
                  terms: {
                    offerType: ["offer", "item"],
                  },
                },
                {
                  match: {
                    offerStatus: "Live",
                  },
                },

                {
                  range: {
                    offerAddedDate: {
                      gte: state.startDate,
                      lte: state.endDate,
                      boost: 2.0,
                    },
                  },
                },
              ],
              must_not: [{ term: { shopPincode: Limit.size.ghoshakInternal } }],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        let tempdata = data.hits.hits.map((element) => element._source);
        let uniqueShopDetails = [];
        let uniquePincode = [];
        tempdata.forEach((elements) => {
          uniqueShopDetails.push(elements.shopId);
          uniquePincode.push(elements.shopPincode);
        });

        uniqueShopDetails = [...new Set(uniqueShopDetails)];
        uniquePincode = [...new Set(uniquePincode)];
        let pincodeOption = [];
        uniquePincode.forEach((elements) => {
          pincodeOption.push(<option value={elements}>{elements}</option>);
        });
        let shopList = await getShop(uniqueShopDetails);
        let consolidatedList = await mergeShopAndOffer(tempdata, shopList);
        setfilterByPincode(pincodeOption);

        let removeExpiredOffers = consolidatedList.filter((elements) =>
          typeof elements.offerEndDate == "string"
            ? new Date(elements.offerEndDate) > new Date()
            : elements
        );
        // console.log(removeExpiredOffers);
        setOfferList(removeExpiredOffers);
      })
      .catch((err) => console.log(err));
  };
  const getShop = async (shopId) => {
    return await Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              should: [
                {
                  terms: {
                    userId: shopId,
                  },
                },
              ],
              must_not: [{ term: { pincode: Limit.size.ghoshakInternal } }],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let tempdata = data.hits.hits.map((element) => element._source);

        return tempdata;
      })
      .catch((err) => console.log(err));
  };
  const mergeShopAndOffer = async (offers, shops) => {
    let consolidatedOffers = [];
    offers.map((elements) => {
      let getCustomerArr = shops.find(
        (items) => elements.shopId === items.userId
      );
      if (getCustomerArr !== undefined) {
        let appendObject = Object.assign({}, elements, getCustomerArr);

        consolidatedOffers.push(appendObject);
      }
    });
    return await consolidatedOffers;
  };

  offers = offerList
    .filter((elements) =>
      elements.shopPincode === pincode
        ? elements.shopPincode
        : pincode === "All"
        ? elements
        : null
    )
    .filter((items) =>
      offerType === items.offerType
        ? items.offerType
        : offerType === "All"
        ? items
        : null
    );

  return (
    <Container fluid>
      <div style={{ width: "100%", height: "50px" }}></div>
      <div className="orderHeader">
        <h2 className="orderHeaderText">Offer Creation </h2>
      </div>
      <div style={{ width: "100%", height: "30px" }}></div>
      <Row>
        <Col sm={4}>
          <Form.Group>
            <Col sm={12}>
              <Form.Label>Offer Created Date</Form.Label>
            </Col>
            <Row>
              <Col sm={6}>
                <Form.Control
                  type="date"
                  name="startDate"
                  value={state.startDate}
                  onChange={(e) => {
                    setState((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                    getOffers(offerType);
                  }}
                />
              </Col>

              <Col sm={6}>
                <Form.Control
                  type="date"
                  name="endDate"
                  value={state.endDate}
                  min={state.startDate}
                  onChange={(e) => {
                    setState((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                    getOffers(offerType);
                  }}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group>
            <Col>
              <Form.Label>Type</Form.Label>
            </Col>
            <Col sm={6}>
              <Form.Control
                name="offerType"
                value={offerType}
                onChange={(e) => setOffertype(e.target.value)}
                as="select"
              >
                <option value="All">All</option>
                <option value="offer">Offer </option>
                <option value="item">Collection</option>
              </Form.Control>
            </Col>
          </Form.Group>
        </Col>

        <Col style={{ textAlign: "right" }} sm={4}>
          <Form.Group>
            <Col>
              <Form.Label>Filter By Pincode</Form.Label>
            </Col>
            <Col sm={6} style={{ float: "right" }}>
              <Form.Control
                name="pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                as="select"
              >
                <option value="All">All</option>
                {filterByPincode}
              </Form.Control>
            </Col>
          </Form.Group>
        </Col>

        <Col sm={12}>
          <MaterialTable
            title="Offers"
            columns={[
              {
                title: "Offer Imge",
                field: "offerImageLocation",
                render: (rowData) => (
                  <img
                    src={rowData.offerImageLocation}
                    alt={rowData.offerImageLocation}
                    width="200"
                    height="200"
                  ></img>
                ),
              },

              {
                title: "Shop Name",
                field: "shopName",
              },
              {
                title: "Name",
                field: "userName",
              },
              {
                title: "Phone Number",
                field: "userPhoneNumber",
              },
              {
                title: "Pincode",
                field: "shopPincode",
              },
              {
                title: "Address",
                field: "shopAddress",
              },
              {
                title: "type",
                field: "offerType",
                render: (rowData) =>
                  rowData.offerType == "offer" ? "offer" : "collection",
              },
              {
                title: "Details",
                field: "offerDetails",
              },

              {
                title: "Expiry on ",
                field: "offerEndDate",
              },
              {
                title: "Created on ",
                field: "offerAddedDate",
              },
            ]}
            data={offers}
            options={{
              exportButton: false,

              pageSize: 30,
              pageSizeOptions: [5, 10, 20, 100],
              grouping: false,
              filtering: false,
            }}
          />
        </Col>
      </Row>

      <div style={{ width: "100%", height: "50px" }}></div>
    </Container>
  );
}
