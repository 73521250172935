import React, { Component } from "react";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import config from "../config";
import { Auth } from "aws-amplify";
export default class EditCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId10Digit: "",
      showResult: true,
      createdCoupon: [],
      editCoupon: true,
      coupon: {
        couponId: "",
        couponType: "",
        couponDetails: "",
        imageFile: [],
        maximumCouponAmount: "",
        totalCouponCount: "",
        expiryDate: "",
        remainingCouponCount: "",
        shopId: "",
        pincode: "",
        additionalCoupon: "0",
        couponAddedby: "",
        promocode: "",
        CouponExpiryDate: "",
        promocodeCount: "",
        promocodeExpiryDate: "",
        promocodeRemainingCount: "",
        updatedDate: new Date().toISOString().substr(0, 10)
      },
      tempCouponExpiryDate: ""
    };
    this.reset = this.state;
    this.getCouponDetails = this.getCouponDetails.bind(this);
    this.onChange = this.onChange.bind(this);
    this.showCoupon = this.showCoupon.bind(this);
    this.couponChange = this.couponChange.bind(this);
    this.updateCoupon = this.updateCoupon.bind(this);
    this.setPromocodeExpiry = this.setPromocodeExpiry.bind(this);
  }
  componentDidUpdate() {
    {
      this.state.editCoupon === false &&
        (window.onpopstate = e => {
          e.preventDefault();
          if (window.confirm("are you want to leave the page")) {
            window.location.assign("/");
          } else {
            window.location.assign("/editcoupon");
          }
        });
    }
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  couponChange(e) {
    this.setState({
      ...this.state,
      coupon: {
        ...this.state.coupon,
        [e.target.name]: e.target.value
      }
    });
  }
  setPromocodeExpiry(e) {
    let tempPromoExpiryDate = e.target.value;
    this.setState(
      {
        ...this.state,
        coupon: {
          ...this.state.coupon,
          promocodeExpiryDate: e.target.value
        }
      },
      () => {
        if (
          this.state.tempCouponExpiryDate <
          this.state.coupon.promocodeExpiryDate
        ) {
          this.setState({
            ...this.state,
            coupon: {
              ...this.state.coupon,
              CouponExpiryDate: tempPromoExpiryDate
            }
          });
        }
      }
    );
  }
  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({
          ...this.state,
          coupon: {
            ...this.state.coupon,
            couponAddedby: user.attributes.phone_number
          }
        });
      })

      .catch(err => console.log(err));
  }

  updateCoupon(e) {
    e.preventDefault();

    Promise.all([
      fetch(
        `${config.ApiGateway}/updatecoupon`,

        {
          method: "POST",

          body: JSON.stringify(this.state.coupon),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        alert("coupon updated successfully");
        this.setState(this.reset);
      })
      .catch(err => console.log(err));
  }
  showCoupon(response) {
    this.setState({
      ...this.state,
      coupon: {
        ...this.state.coupon,
        couponId: response.couponId,
        couponType: response.couponType,
        couponDetails: response.couponDetails,
        maximumCouponAmount: response.maximumCouponAmount,
        totalCouponCount: response.totalCouponCount,
        expiryDate: response.expiryDate,
        remainingCouponCount: response.remainingCouponCount,
        shopId: response.shopId,
        pincode: response.pincode,

        promocode: response.promocode,

        promocodeCount: response.promocodeCount,
        promocodeExpiryDate: response.promocodeExpiryDate,
        promocodeRemainingCount: response.promocodeRemainingCount
      },
      editCoupon: false,
      tempCouponExpiryDate: response.CouponExpiryDate
    });
  }
  getCouponDetails(e) {
    e.preventDefault();
    Promise.all([
      fetch(
        `${config.ApiGateway}/editcoupon`,
        // `${config.ApiGateway}/editcoupon`,
        {
          method: "POST",

          body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.setState({
          createdCoupon: data[1],
          showResult: false,
          userInformation: data[0][0]
        });
      })
      .catch(err => console.log(err));
  }
  render() {
    return (
      <Container>
        <>
          {this.state.editCoupon ? (
            <>
              {this.state.showResult && (
                <>
                  <div
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%"
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}>EDIT COUPON</h3>
                  </div>

                  <Form onSubmit={this.getCouponDetails}>
                    <Form.Group style={{ marginTop: 32 + "px" }} as={Row}>
                      <Form.Label column sm="4">
                        ENTER SHOP ID
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="userId10Digit"
                          pattern="[0-9]{1,}"
                          onChange={this.onChange}
                          value={this.state.userId10Digit}
                        ></Form.Control>
                      </Col>
                      <Button type="submit">SUBMIT</Button>
                    </Form.Group>
                  </Form>
                </>
              )}
              {!this.state.showResult && (
                <>
                  <Row>
                    <h3>CHOOSE COUPON TO EDIT</h3>
                    <Table
                      style={{
                        width: 100 + "%",
                        border: 0.5 + "px",
                        borderColor: "#ced4da",
                        borderStyle: "solid"
                      }}
                      size="medium"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S NO</TableCell>
                          <TableCell align="center">COUPON ID</TableCell>
                          <TableCell align="center">COUPON TYPE</TableCell>
                          <TableCell align="center">
                            COUPON CREATED ON
                          </TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.createdCoupon.map((row, sno) => (
                          <TableRow>
                            <TableCell align="center">{sno++}</TableCell>

                            <TableCell align="center">{row.couponId}</TableCell>
                            <TableCell align="center">
                              {row.couponType}
                            </TableCell>
                            <TableCell align="center">
                              {" "}
                              {row.addedDate}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                onClick={() => {
                                  this.showCoupon(row);
                                }}
                              >
                                VIEW
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Row>
                </>
              )}
            </>
          ) : (
            <>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  margin: 1 + "%"
                }}
              >
                <h3 style={{ textAlign: "center" }}>EDIT COUPON</h3>
              </div>

              <Form onSubmit={this.updateCoupon}>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    SHOP ID
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="userInformation"
                      onChange={this.couponChange}
                      value={this.state.userInformation.userId}
                      disabled={true}
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    PINCODE
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="userInformation"
                      onChange={this.couponChange}
                      value={this.state.userInformation.userPincode}
                      disabled={true}
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    SHOP TYPE
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="userInformation"
                      onChange={this.couponChange}
                      value={this.state.userInformation.shopType}
                      disabled={true}
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    SHOP NAME
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="userId10Digit"
                      onChange={this.couponChange}
                      value={this.state.userInformation.shopName}
                      disabled={true}
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    PHONE NUMBER
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="userId10Digit"
                      onChange={this.couponChange}
                      value={this.state.userInformation.userPhoneNumber}
                      disabled={true}
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    SHOP ADDRESS
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="userInformation"
                      onChange={this.couponChange}
                      value={this.state.userInformation.shopAddress}
                      disabled={true}
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    COUPON ID
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="userId10Digit"
                      onChange={this.couponChange}
                      value={this.state.coupon.couponId}
                      disabled={true}
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    COUPON TYPE
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="userId10Digit"
                      onChange={this.couponChange}
                      value={this.state.coupon.couponType}
                      disabled={true}
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    MAXIMUM COUPON AMOUNT
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="maximumCouponAmount"
                      onChange={this.couponChange}
                      value={this.state.coupon.maximumCouponAmount}
                      disabled={true}
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    COUPON DETAILS
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="textarea"
                      name="couponDetails"
                      onChange={this.couponChange}
                      value={this.state.coupon.couponDetails}
                      disabled={true}
                      rows="6"
                    ></Form.Control>
                  </Col>
                </Form.Group>
                {this.state.coupon.couponType !== "PROMOCODE" ? (
                  <>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        EXPIRY DATE
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="date"
                          name="expiryDate"
                          onChange={this.couponChange}
                          value={this.state.coupon.expiryDate}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        TOTAL COUPON COUNT
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="totalCouponCount"
                          pattern="[0-9]{1,}"
                          onChange={this.couponChange}
                          value={this.state.coupon.totalCouponCount}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        COUPON REMAINING COUNT
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="remainingCouponCount"
                          onChange={this.couponChange}
                          value={this.state.coupon.remainingCouponCount}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        ADD COUPON COUNT
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          pattern="[0-9]{1,}"
                          name="additionalCoupon"
                          onChange={this.couponChange}
                          value={this.state.coupon.additionalCoupon}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        PROMOCODE EXPIRY DATE
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="date"
                          name="promocodeExpiryDate"
                          onChange={this.setPromocodeExpiry}
                          value={this.state.coupon.promocodeExpiryDate}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        PROMOCODE COUNT
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="promocodeCount"
                          pattern="[0-9]{1,}"
                          onChange={this.couponChange}
                          value={this.state.coupon.promocodeCount}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        PROMOCODE REMAINING COUNT
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="promocodeRemainingCount"
                          onChange={this.couponChange}
                          value={this.state.coupon.promocodeRemainingCount}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        ADD COUPON COUNT
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          pattern="[0-9]{1,}"
                          name="additionalCoupon"
                          onChange={this.couponChange}
                          value={this.state.coupon.additionalCoupon}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                  </>
                )}
                <Button type="submit">SUBMIT</Button>
              </Form>
            </>
          )}
        </>
      </Container>
    );
  }
}
