import MaterialTable from "material-table";
import React, { useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import config from "../config";
import { IoEyeOutline } from "react-icons/io5";
import { MdOutlineNoAccounts, MdOutlineAccountCircle } from "react-icons/md";

const Resellers = ({ history }) => {
  const [userDetails, setUserDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const getResellers = async () => {
    try {
      const res = await fetch(`${config.ApiGateway}/getshop`, {
        method: "POST",
        body: JSON.stringify({
          type: "getResellers",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      console.log("dataresellers", data.resellers);
      const resellers = data.resellers.map((reseller) => {
        const purchaseId = reseller.package?.purchaseId;
        const purchaseDetails = data.bill?.find((i) => i.billId === purchaseId);

        return {
          userName: reseller.userName,
          phoneNumber: reseller.userPhoneNumber,
          userAddedDate: reseller.userCreatedOn,
          userEmail: reseller.userEmail,
          userId: reseller.userId,
          referralCode: reseller.referralCode,
          planName: purchaseDetails?.items?.[0]?.name
            ? purchaseDetails?.items?.[0]?.name
            : "-",
          planStartDate: reseller?.package?.purchaseDate
            ? reseller?.package?.purchaseDate
            : "-",
          licenseUsed: reseller?.package?.license
            ? reseller?.package?.license
            : "",
          license: purchaseDetails?.items?.[0]?.license,
          gstNumber: reseller?.gstNumber ? reseller?.gstNumber : "-",
          enabled: reseller?.enabled,
        };
      });
      console.log("resellers88", resellers);
      setUserDetails(resellers);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleBlockUser = async (resellerId, status) => {
    try {
      if (status) {
        const res = await fetch(`${config.ApiGateway}/getshop`, {
          method: "POST",
          body: JSON.stringify({
            type: "disableUser",
            resellerId,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await res.json();
        if (data.code === "SUCCESS") {
          alert("User Blocked");
          setUserDetails((prev) => {
            return prev.map((user) => {
              if (user.userId === resellerId) {
                return {
                  ...user,
                  enabled: false,
                };
              }
              return user;
            });
          });
        }
      }
      if (!status) {
        const res = await fetch(`${config.ApiGateway}/getshop`, {
          method: "POST",
          body: JSON.stringify({
            type: "enableUser",
            resellerId,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await res.json();
        if (data.code === "SUCCESS") {
          alert("User Enabled");
          setUserDetails((prev) => {
            return prev.map((user) => {
              if (user.userId === resellerId) {
                return {
                  ...user,
                  enabled: true,
                };
              }
              return user;
            });
          });
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    (async () => {
      await getResellers();
    })();
  }, []);

  console.log("userDetails99", userDetails);

  return (
    <div>
      <div>
        <MaterialTable
          isLoading={loading}
          title=" List of Resellers"
          columns={[
            {
              title: "Name",
              field: "userName",
              width: "10%",
            },
            {
              title: "Phone Number",
              field: "phoneNumber",
              width: "10%",
            },
            {
              title: "Email",
              field: "userEmail",
              width: "15%",
              cellStyle: {
                overflowWrap: "break-word",
              },
            },
            {
              title: "Date of Joining",
              field: "userAddedDate",
              width: "10%",
            },

            {
              title: "Referral Code",
              field: "referralCode",
              width: "10%",
            },
            {
              title: "Plan Name",
              field: "planName",
              render: (rowData) => {
                return `${rowData.planName} ${
                  rowData.licenseUsed
                    ? `(${rowData.licenseUsed} out of ${rowData?.license})`
                    : ""
                }`;
              },
              width: "10%",
            },
            {
              title: "Package Start Date",
              field: "planStartDate",
              width: "10%",
            },
            {
              title: "GST Number",
              field: "gstNumber",
              width: "10%",
            },
            {
              title: "Action",
              width: "15%",
              render: (rowData) => (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <OverlayTrigger
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={"tooltip-top"}>View Customer</Tooltip>
                    }
                  >
                    <IoEyeOutline
                      onClick={() => {
                        history.push(`/resellers/${rowData.userId}`);
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                      size={24}
                    />
                  </OverlayTrigger>

                  {rowData.enabled ? (
                    <MdOutlineAccountCircle
                      onClick={() => {
                        handleBlockUser(rowData.userId, rowData.enabled);
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                      size={24}
                    />
                  ) : (
                    <MdOutlineNoAccounts
                      onClick={() => {
                        handleBlockUser(rowData.userId, rowData.enabled);
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                      size={24}
                    />
                  )}
                </div>
              ),
            },
          ]}
          data={userDetails}
          options={{
            exportButton: true,
            pageSize: 5,
            maxBodyHeight: 437,
            pageSizeOptions: [5, 10, 20, 100],
            grouping: false,
            filtering: false,
            tableLayout: "fixed",
          }}
          style={{
            width: "90%",
            margin: "90px auto",
          }}
        />
      </div>
    </div>
  );
};

export default Resellers;
