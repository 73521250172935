import React, { useEffect, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import config from "../../config";
import { Button } from "react-bootstrap";
export default function PoweredByGhoshak({ shopId }) {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);

  const [shopDetails, setShopDetails] = useState({
    userId: "",
  });
  const [websiteTemplate, setWebsiteTemplate] = useState({});
  useEffect(() => {
    (async () => {
      await Promise.all([
        fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
          method: "POST",
          body: JSON.stringify({
            userId10Digit: shopId,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ])
        .then(([res]) => Promise.all([res.json()]))
        .then(([data]) => {
          if (data.length > 0) {
            let profile = data[0];
            let { userId, websiteTemplate } = profile;
            if (profile.websiteTemplate) {
              setLoading(true);
              setShopDetails(profile);
              setWebsiteTemplate(profile?.websiteTemplate || {});
              setActive(
                Boolean(websiteTemplate?.poweredByGhoshak?.active)
                  ? websiteTemplate?.poweredByGhoshak?.active
                  : false
              );
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          alert("Something went wrong, please try again");
        });
    })();
  }, [shopId]);
  const update = async () => {
    //

    var dataObj = {};
    if (websiteTemplate) {
      dataObj = {
        ...websiteTemplate,
        poweredByGhoshak: {
          active: active,
          templateId: "default",
          pageProps: {},
        },
      };
    } else {
      dataObj = {
        colorTheme: "default",
        order: [
          "aboutUs",
          "about",
          "onlineProduct",
          "ourServices",
          "ourProjects",
          "ourBrands",
          "ourClients",
          "ourGallery",
          "clientTestimonials",
          "videoGallery",
          "socialMedia",
          "ourPartner",
          "ourTeam",
        ],
        poweredByGhoshak: {
          active: active,
          templateId: "default",
          pageProps: {},
        },
      };
    }

    await Promise.all([
      fetch(`${config.ApiGateway}/ownDomainContentUpload`, {
        method: "POST",
        body: JSON.stringify({
          obj: dataObj,
          userId: shopDetails.userId10Digit,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res]) => Promise.all([res.json()]))
      .then(async ([data]) => {
        alert("Changes Updated");
      })
      .catch((err) => {
        console.log("updating A Record Error", err);
        alert("error while uploading data. Please try again");
      });
  };
  return (
    <>
      {!loading ? (
        <div style={{ width: "100%" }}>
          <h4>Phone Number</h4>
          <div>
            <strong>
              <label>Enable Powered by ghoshak</label>
            </strong>
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={(e) => {
                    setActive(!active);
                  }}
                  color="primary"
                  value={active}
                />
              }
            ></FormControlLabel>
            <Button onClick={() => update()}>Save</Button>
          </div>
        </div>
      ) : (
        <div>Loading</div>
      )}
    </>
  );
}
