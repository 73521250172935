import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssessmentIcon from "@material-ui/icons/Assessment";
import RateReviewIcon from "@material-ui/icons/RateReview";
import Dashboard from "./dashboard";
import Statement from "./statement";
import config from "../config";
import { useParams, Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import "./partner.css";

export default function PartnerHome() {
  const [id, setId] = useState("");
  useEffect(() => {
    async function getUser() {
      await Auth.currentUserInfo().then((data) => {
    
        setId(data.username);
      });
    }
    getUser();
  }, []);
  const params = useParams(); // get paramter from url to load class
  useEffect(() => {
    // initialize when value is updated
    loadClass();
  }, [params.pathname]);
  const loadClass = () => {
    // load class in main content
    switch (params.pathname) {
      case "dashboard":
        return <Dashboard cognitoId={id} />;
      case "pending":
        return <Statement />;

      default:
        return <></>;
    }
  };
  return (
    <Container fluid style={{ padding: "0", overflow: "hidden" }}>
      <Row>
        <Col id="partnerSideBar" sm="2">
          <nav>
            <ul>
              <li className="active">
                <Link className="nav-link" to="/partner/dashboard">
                  <>
                    <span>
                      <DashboardIcon className="sidebarIcon" />
                    </span>
                    Dashboard
                  </>
                </Link>
              </li>
              {/* <li>
                <Link className="nav-link" to="/partner/pending">
                  <>
                    <span>
                      <RateReviewIcon className="sidebarIcon" />
                    </span>
                    Pending
                  </>
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="/partner/pending">
                  <>
                    <span>
                      <AssessmentIcon className="sidebarIcon" />
                    </span>
                    Statement
                  </>
                </Link>
              </li> */}
            </ul>
          </nav>
        </Col>
        <Col id="mainPageContent" sm={"10"}>
          <div className="verticalBodyContainer">{loadClass()}</div>
        </Col>
      </Row>
    </Container>
  );
}
