export default {
  size: {
    loyaltyAtleastCustomer: -1,
    ghoshakInternal: "111111",
    merchantUserType: "Shop",
    customerUserType: "Customer",
    endDate: new Date().toISOString().substring(0, 10),
    startDate: "2019-12-16",
    internalPhoneNumber: ["9840855150", "9791145114"],
    defaultImageUrl:
      "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/images/offers/default_offer_image.png",
  },

  activateLimit: 1,
  engageLimit: 2,
  retainLimit: 5,

  MactivateLimit: 1,
  MengageLimit: 2,
  MretainLimit: 5,
};
