import moment from "moment";
export default {
  defaultImage:
    "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/images/offers/default_offer_image.png",
  filterby: [
    {
      name: "Year",
      range: [
        moment().subtract(12, "months").format("YYYY-MM-DD"),
        new Date().toISOString().substring(0, 10),
      ],
    },
    {
      name: "Today",
      range: [
        moment().subtract(0, "days").format("YYYY-MM-DD"),
        new Date().toISOString().substring(0, 10),
      ],
    },
    {
      name: "Yesterday",
      range: [
        moment().subtract(1, "days").format("YYYY-MM-DD"),
        new Date().toISOString().substring(0, 10),
      ],
    },

    {
      name: "Month",
      range: [
        moment().subtract(1, "months").format("YYYY-MM-DD"),
        new Date().toISOString().substring(0, 10),
      ],
    },
    {
      name: "6 Month",
      range: [
        moment().subtract(6, "months").format("YYYY-MM-DD"),
        new Date().toISOString().substring(0, 10),
      ],
    },
  ],
  pincodeDistribution: [
    { name: "Range Between 1 - 10", range: [0, 10] },
    { name: "Range Between 11 - 50", range: [11, 50] },
    { name: "Range Between 51 - 100", range: [51, 100] },
    { name: "Range Between 100 - 300", range: [101, 300] },
    { name: "More than 300 shops", range: [301, 3000000] },
  ],
  offerDistribution: [
    { name: "Range Between 1 - 10", range: [0, 10] },
    { name: "Range Between 11 - 50", range: [11, 50] },
    { name: "Range Between 51 - 100", range: [51, 100] },
    { name: "Range Between 100 - 300", range: [101, 300] },
    { name: "More than 300 shops", range: [301, 3000000] },
  ],
  month: [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  startYear: 2019,
  defaultOfferRange: [
    moment().subtract(100, "months").format("YYYY-MM-DD"),
    new Date().toISOString().substring(0, 10),
  ],
  todayDate: new Date().toISOString().substring(0, 10),




};
