import { Col } from "react-bootstrap";
import React, { Component } from "react";

import config from "../../config";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
export default class Pincode extends Component {
  constructor(props) {
    super(props);
    this.pincodeBasedShop = [];
    this.state = {
      showData: false,
      showArea: []
    };
  }

  componentDidMount() {
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, data2]) => {
        var mergePincodeAndArea = this.props.allPincode.map(items => {
          let count = data2.Items.find(elements => items === elements.pincode);
          let appendObject = {};
          if (count !== undefined) {
            appendObject = Object.assign({}, count);
          } else {
            appendObject = { pincode: items, place: "Not Found" };
          }
          return appendObject;
        });

        this.setState({
          showData: true,
          showArea: mergePincodeAndArea
        });
      });
  }
  render() {
    return (
      <>
        {this.state.showData && (
          <>
            <Typography
              variant="h2"
              style={{ textAlign: "center" }}
              gutterBottom
            >
              {this.props.title}
            </Typography>
            <Col className="division" md={{ span: 3, offset: 3 }}>
              <>
                <h3>TOTAL PINCODE ARE {this.state.showArea.length}</h3>
              </>
            </Col>
            <Col md={{ span: 8, offset: 2 }}>
              <>
                <div style={{ width: 100 + "%" }}>
                  <h3 style={{ textAlign: "center" }}> SHOP DETAILS </h3>
                </div>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid"
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">PINCODE</TableCell>
                      <TableCell align="center">PLACE</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.showArea.map((items, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{items.pincode}</TableCell>
                        <TableCell
                          style={{
                            backgroundColor:
                              items.place === "Not Found" ? "red" : ""
                          }}
                          align="center"
                        >
                          {items.place}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            </Col>
          </>
        )}
      </>
    );
  }
}
