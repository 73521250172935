import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import config from "../config";
import { Auth } from "aws-amplify";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
    overflowY: "scroll",
    maxHeight: 200,
    padding: "15px",
  },
  tableBodyCellStyle: {
    fontWeight: 300,
    lineHeight: "14px",
    fontSize: "12px",
    color: "#212529",
  },
  container: {
    maxHeight: 400,
    overflowY: "scroll",
    overflowX: "auto",
    overflow: "hidden",
  },
  root: {
    width: "100%",
  },
});
let initialState = {
  verificationStatus: "no",
  approvalStatus: "no",
  amountPaid: "",
  cycleStartDate: "",
  cycleEndDate: "",
  salePackage: "NA",
  notes: "",
  commision: "",
  details: {},
  shopId: "",
  criteria: "",
  remarkForGhoshak: "",
  remarkForPartner: "",
  paymentDate: "",
};

export default function PendingPayment() {
  const classes = useStyles();
  const [pendinglist, setPendingList] = useState({});
  const [partnerList, setPartnerList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [state, setState] = useState(initialState);
  const handleClose = () => setModalShow(false);
  const [paymentMonthDropdown, setPaymentMonthDropdown] = useState([]);
  const handleShow = (input, company) => {
    setState((prevState) => ({
      ...prevState,
      criteria: input.criteria,
      details: input.details,
      cycleStartDate: input.cycleStartDate,
      cycleEndDate: input.cycleEndDate,
      shopName: input.shopName,
      shopId: input.userId,
      referralCode: company.referralCode,
      referenceUserId: company.userId,
      companyName: company.companyName,
      userId: input.userId,
      verificationStatus: input.verificationStatus,
    }));
    setModalShow(true);
  };
  useEffect(() => {
    async function loadData() {
      await Auth.currentAuthenticatedUser().then((data) => {
        onChange("paidBy", data.attributes.phone_number);
      });
      await getPartner();
      await getData();

      let todayDate = new Date().toISOString().substring(0, 10);
      let dropdownStartDate = moment("2019-12-01");
      let dropdownEndDate = moment(todayDate);
      let monthYearRanges = [];

      while (
        dropdownEndDate > dropdownStartDate ||
        dropdownStartDate.format("M") === dropdownEndDate.format("M")
      ) {
        monthYearRanges.push(
          <option value={dropdownStartDate.format("YYYY-MM")}>
            {dropdownStartDate.format("MMM YYYY")}
          </option>
        );
        dropdownStartDate.add(1, "month");
      }
      monthYearRanges.sort((a, b) => b - a);
      setPaymentMonthDropdown(monthYearRanges);
    }

    loadData();
  }, []);
  const getData = async () => {
    // Get data from Backend
    await Promise.all([
      fetch(`${config.ApiGateway}/partnerStatement`, {
        method: "POST",
        body: JSON.stringify({
          template: "getStatementData", // To get all Statement data
          statementStatus: "pending",
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        // let sortByShop = data.sort((a, b) => a.companyName - b.companyName);

        let sortByShop = {};
        data.forEach((elements) => {
          if (sortByShop[elements.referenceUserId] == undefined) {
            sortByShop[elements.referenceUserId] = { statement: [] };
          }
          sortByShop[elements.referenceUserId].statement.push(elements);
        });

        setPendingList(sortByShop);
      })
      .catch((err) => console.log(err));
  };
  const getPartner = async () => {
    // Get data from Backend
    await Promise.all([
      fetch(`${config.ApiGateway}/partnerStatement`, {
        method: "POST",
        body: JSON.stringify({
          template: "getAllPartner", // To get all  partner
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setPartnerList(data);
      })
      .catch((err) => console.log(err));
  };

  const onChange = (name, value) => {
    // onchange event for form event
    let objectName = name;
    let objectValue = value;

    setState((prevState) => ({ ...prevState, [objectName]: objectValue }));
  };
  const makePayment = (e) => {
    e.preventDefault();
    let input = state;
    Object.keys(input).forEach((key) => input[key] == "" && delete input[key]); // Removes Empty String
    input.template = "add";

    Promise.all([
      fetch(`${config.ApiGateway}/partnerStatement`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        // console.log(data);
        handleClose();
        setState(initialState);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container>
      <div className="orderHeader">
        <h2 className="orderHeaderText">Pending Payment</h2>
      </div>
      {Object.entries(pendinglist).map(([key, items]) => {
        let findPartner = partnerList.find(
          (elements) => elements.userId == key
        );

        if (typeof findPartner != "undefined") {
          return (
            <>
              <Row>
                <Col className="pendingBox" sm={"12"}>
                  <div className="companyName">
                    <Row>
                      <Col sm={"6"}>
                        <h4 style={{ fontWeight: "500", fontSize: "20px" }}>
                          {findPartner.companyName}
                        </h4>
                        <h6>{findPartner.userName}</h6>
                      </Col>
                      <Col style={{ textAlign: "right" }} sm={"6"}>
                        <h4 style={{ fontWeight: "500", fontSize: "20px" }}>
                          {findPartner.companyName}
                        </h4>
                        <h6>{findPartner.userPhoneNumber.slice(-10)}</h6>
                      </Col>
                    </Row>
                  </div>
                  <div className="refferedShops">
                    <div className={classes.root}>
                      <TableContainer className={classes.container}>
                        <Table className={classes.table} stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Period</TableCell>
                              <TableCell>Criteria</TableCell>
                              <TableCell>Shop Details</TableCell>
                              <TableCell>Details</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className={classes.tbody}>
                            {items.statement.map((elements) => {
                              return (
                                <TableRow>
                                  <TableCell
                                    className={classes.tableBodyCellStyle}
                                  >
                                    {`${moment(elements.cycleStartDate).format(
                                      "MMM Do YYYY"
                                    )} - ${moment(elements.cycleEndDate).format(
                                      "MMM Do YYYY"
                                    )}`}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      textTransform: "uppercase",
                                      fontWeight: "600",
                                    }}
                                    className={classes.tableBodyCellStyle}
                                  >
                                    {elements.criteria}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableBodyCellStyle}
                                  >
                                    <h6
                                      style={{
                                        padding: "0",
                                        margin: "0px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {elements.shopName}
                                    </h6>
                                    <small>
                                      <b> {elements.shopId}</b>{" "}
                                      <span style={{ textAlign: "right" }}>
                                        {elements.shopType}
                                      </span>
                                    </small>
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableBodyCellStyle}
                                  >
                                    <ul
                                      style={{
                                        listStyle: "none",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    >
                                      {Object.keys(elements.details).map(
                                        (items) => (
                                          <li
                                            style={{
                                              listStyle: "none",
                                              padding: "0px",
                                              margin: "0px",
                                            }}
                                          >
                                            <b>{items}</b> :{" "}
                                            {elements.details[items].toString()}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableBodyCellStyle}
                                  >
                                    {elements.statementStatus}
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      onClick={() =>
                                        handleShow(elements, findPartner)
                                      }
                                    >
                                      Approval
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="vertical-space-40"></div>
            </>
          );
        }
      })}
      <Modal className="viewCartDetails" show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="columnCenter" sm={"10"}>
              <Form onSubmit={makePayment}>
                <Row>
                  <Col sm="4">
                    <Form.Label>Period</Form.Label>
                  </Col>
                  <Col sm="8">
                    {`${moment(state.cycleStartDate).format(
                      "MMM Do YYYY"
                    )} - ${moment(state.cycleEndDate).format("MMM Do YYYY")}`}
                  </Col>
                </Row>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                  <Form.Label column sm="4">
                    Amount (₹)
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      value={state.amountPaid}
                      name="amountPaid"
                      placeholder="₹ INR"
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                  <Form.Label column sm="4">
                    Payment Date
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="select"
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      value={state.paymentDate}
                      name="paymentDate"
                      placeholder="₹ INR"
                      required
                    >
                      {paymentMonthDropdown}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Row>
                  <Col md="4">
                    <Form.Label>Criteria Met</Form.Label>
                  </Col>
                  <Col md="8">
                    <div key={"inline-radio"} className="mb-3">
                      <Form.Check
                        inline
                        label="yes"
                        name="verificationStatus"
                        type={"radio"}
                        id={`inline-radio-1`}
                        onChange={(e) => {
                          onChange(e.target.name, e.target.value);
                        }}
                        value={"yes"}
                        checked={state.verificationStatus == "yes"}
                      />

                      <Form.Check
                        inline
                        label="no"
                        type={"radio"}
                        id={`inline-radio-2`}
                        name="verificationStatus"
                        onChange={(e) => {
                          onChange(e.target.name, e.target.value);
                        }}
                        value={"no"}
                        checked={state.verificationStatus == "no"}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col md="4">
                    <Form.Label>Approval Status</Form.Label>
                  </Col>
                  <Col md="8">
                    <div key={"inline-radio"} className="mb-3">
                      <Form.Check
                        inline
                        label="yes"
                        name="approvalStatus"
                        type={"radio"}
                        id={`inline-radio-1`}
                        onChange={(e) => {
                          onChange(e.target.name, e.target.value);
                        }}
                        value={"yes"}
                        checked={state.approvalStatus == "yes"}
                      />

                      <Form.Check
                        inline
                        label="no"
                        type={"radio"}
                        id={`inline-radio-2`}
                        name="approvalStatus"
                        onChange={(e) => {
                          onChange(e.target.name, e.target.value);
                        }}
                        value={"no"}
                        checked={state.approvalStatus == "no"}
                      />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                  <Form.Label column sm="4">
                    Notes
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      value={state.notes}
                      name="notes"
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                  <Form.Label column sm="4">
                    Remarks ( Ghoshak )
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      value={state.remarkForGhoshak}
                      name="remarkForGhoshak"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formPlaintextEmail">
                  <Form.Label column sm="4">
                    Remarks ( Partner )
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      value={state.remarkForPartner}
                      name="remarkForPartner"
                    />
                  </Col>
                </Form.Group>
                <div className="vertical-space-10"></div>
                <Button style={{ float: "right" }} type="submit">
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
