import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import uuid from "uuid/dist/v4";
import config from "../../config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const SocialMedia = (props) => {
  const ref = useRef();
  const [profileLink, setProfileLink] = useState("");
  const [profileName, setProfileName] = useState("");
  const [followers, setFollowers] = useState("");
  const [socialMedia, setSocialMedia] = useState("");
  const [others, setOthers] = useState("");
  const [showOthers, setShowOthers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [websiteTemplate, setWebsiteTemplate] = useState();
  const [social, setSocial] = useState([]);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [from, setFrom] = useState("");

  const [active, setActive] = useState(true);
  const [templateId, setTemplateId] = useState("SocialMedia_001");
  const [templateName, setTemplateName] = useState("Classic Landing");

  const fetchDetails = async () => {
    await fetchUserDetails(props.shopId).then((data) => {
      var shopData = data[0];
      if (shopData.websiteTemplate) {
        setWebsiteTemplate(shopData.websiteTemplate);
        var websiteData = shopData.websiteTemplate;
        if (websiteData.socialMedia) {
          setActive(
            websiteData.socialMedia.active
              ? websiteData.socialMedia.active
              : active
          );
          setTemplateId(
            websiteData.socialMedia.templateId
              ? websiteData.socialMedia.templateId
              : templateId
          );
          setTemplateName(
            websiteData.socialMedia.templateName
              ? websiteData.socialMedia.templateName
              : templateName
          );
          if (websiteData.socialMedia.pageProps.cardData) {
            if (websiteData.socialMedia.pageProps.cardData.length > 0) {
              setSocial(websiteData.socialMedia.pageProps.cardData);
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    fetchDetails();
  }, [props]);

  const handleEdit = (data) => {
    setProfileLink(data.profileLink);
    setProfileName(data.profileName);
    setFollowers(data.followers);
    setShowOthers(data.showOthers);
    if (data.showOthers) {
      setOthers(data.socialMedia);
      setSocialMedia("Others");
    } else {
      setSocialMedia(data.socialMedia);
    }
    setId(data.id);
    setModal(true);
    setFrom("update");
  };

  const handleUpdate = async () => {
    var filteredSocial = social.filter((data) => data.id !== id);
    var updateObj = {
      ...websiteTemplate,
      socialMedia: {
        ...websiteTemplate.socialMedia,
        pageProps: {
          cardData: [
            ...filteredSocial,
            {
              profileLink: profileLink,
              profileName: profileName,
              followers: followers,
              socialMedia: showOthers ? others : socialMedia,
              showOthers: showOthers,
              id: id,
            },
          ],
        },
      },
    };
    await contentupload(updateObj, props.shopId).then(async (data) => {
      setModal(false);
      setProfileLink("");
      setProfileName("");
      setFollowers("");
      setSocialMedia("");
      setShowOthers(false);
      setId("");
      setOthers("");
      await fetchDetails();
    });
  };

  const handleDelete = async (key) => {
    var filteredSocial = social.filter((data) => data.id !== key.id);
    var dataObj = {
      ...websiteTemplate,
      socialMedia: {
        ...websiteTemplate.socialMedia,
        pageProps: {
          cardData: [...filteredSocial],
        },
      },
    };
    await contentupload(dataObj, props.shopId).then(async (data) => {
      setSocial([]);
      await fetchDetails();
    });
  };

  const handleAdd = async () => {
    var dataObj = {};
    if (websiteTemplate) {
      dataObj = {
        ...websiteTemplate,
      };
      if (social.length > 0) {
        dataObj = {
          ...dataObj,
          socialMedia: {
            ...websiteTemplate.socialMedia,
            active: active,
            templateId: templateId,
            templateName: templateName,
            pageProps: {
              cardData: [
                ...social,
                {
                  profileLink: profileLink,
                  profileName: profileName,
                  followers: followers,
                  socialMedia: showOthers ? others : socialMedia,
                  showOthers: showOthers,
                  id: uuid(),
                },
              ],
            },
          },
        };
      } else {
        dataObj = {
          ...dataObj,
          socialMedia: {
            active: active,
            templateId: templateId,
            templateName: templateName,
            pageProps: {
              cardData: [
                ...social,
                {
                  profileLink: profileLink,
                  profileName: profileName,
                  followers: followers,
                  socialMedia: showOthers ? others : socialMedia,
                  showOthers: showOthers,
                  id: uuid(),
                },
              ],
            },
          },
        };
      }
    } else {
      dataObj = {
        colorTheme: "default",
        order: [
          "aboutUs",
          "about",
          "onlineProduct",
          "ourServices",
          "ourProjects",
          "ourBrands",
          "ourClients",
          "ourGallery",
          "clientTestimonials",
          "videoGallery",
          "socialMedia",
          "ourPartner",
          "ourTeam"
        ],
        socialMedia: {
          pageProps: {
            cardData: [
              {
                profileLink: profileLink,
                profileName: profileName,
                followers: followers,
                socialMedia: showOthers ? others : socialMedia,
                showOthers: showOthers,
                id: uuid(),
              },
            ],
          },
          active: active,
          templateId: templateId,
          templateName: templateName,
        },
      };
    }
    await contentupload(dataObj, props.shopId).then(async (data) => {
      await fetchDetails();
      setProfileLink("");
      setProfileName("");
      setFollowers("");
      setSocialMedia("");
      setShowOthers(false);
      setId("");
      setOthers("");
      setModal(false);
    });
  };

  const handleActiveTemplateChange = async (t, n, a) => {
    var updateObj = {
      ...websiteTemplate,
      socialMedia: {
        ...websiteTemplate.socialMedia,
        active: a,
        templateId: t,
        templateName: n,
        pageProps: {
          cardData: [...social],
        },
      },
    };
    await contentupload(updateObj, props.shopId).then(async () => {
      fetchDetails();
    });
  };
  return (
    <div style={{ width: "100%" }}>
      <h4>Social Media</h4>
      <div>
        {social.length > 0 && (
          <>
            <div>
              <strong>
                <label>Show Social Media</label>
              </strong>{" "}
              <FormControlLabel
                control={
                  <Switch
                    checked={active}
                    onChange={(e) => {
                      setActive(!active);
                    }}
                    color="primary"
                    value={active}
                  />
                }
              ></FormControlLabel>
            </div>
            <div>
              <strong>
                <label>Choose Template</label>
              </strong>
              <br />
              <select
                onChange={(e) => {
                  setTemplateId(e.target.value);
                  setTemplateName(e.target.name);
                }}
                value={templateId}
                style={{
                  width: "200px",
                }}
              >
                <option value="" disabled>
                  Select a template
                </option>
                <option value="SocialMedia_001" name="Classic Landing">
                  Classic Landing
                </option>
                <option value="SocialMedia_002" name="Parallax scroll">
                  Parallax scroll
                </option>
                <option value="SocialMedia_003" name="Highlighter">
                  Highlighter
                </option>
              </select>{" "}
            </div>
            <br />
            <Button
              onClick={() => {
                handleActiveTemplateChange(templateId, templateName, active);
              }}
            >
              Save
            </Button>
          </>
        )}
      </div>
      <br />
      <Button
        onClick={() => {
          setFrom("add");
          setModal(true);
        }}
      >
        Add Social Media
      </Button>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {social.map((data) => {
          var sm = data.socialMedia;
          if (data.showOthers) {
            sm = "Others";
          }
          return (
            <div
              className="ourServicesCard"
              key={data.id}
              style={{
                margin: "10px",
                width: "20%",
                boxShadow: "5px 5px 20px 1px #d4d4d4",
                borderRadius: "5px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <img
                    src={require(`./socialMediaImages/${sm}.png`)}
                    width={75}
                    height={75}
                  />
                </div>
                <div>
                  <div style={{ marginLeft: "5px" }}>
                    <strong>
                      <label>{data.profileName}</label>
                    </strong>
                    <br />
                    <label>{data.followers}</label>
                  </div>
                  <div style={{ margin: "5px" }}>
                    <Button
                      variant="outline-info"
                      onClick={() => handleEdit(data)}
                      size="sm"
                    >
                      Edit
                    </Button>{" "}
                    <Button
                      variant="outline-danger"
                      onClick={() => handleDelete(data)}
                      size="sm"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        show={modal}
        onHide={() => {
          setProfileLink("");
          setProfileName("");
          setFollowers("");
          setSocialMedia("");
          setShowOthers(false);
          setId("");
          setOthers("");
          setModal(!modal);
        }}
        centered
      >
        <Modal.Header>
          {from === "update" ? (
            <h4>Update Social Media</h4>
          ) : (
            <h4>Add Social Media</h4>
          )}
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <div>
              <strong>
                <label>Choose Social Media * </label>
              </strong>
              <br />
              <select
                onChange={(e) => {
                  setSocialMedia(e.target.value);
                  if (e.target.value === "Others") {
                    setShowOthers(true);
                  } else {
                    setShowOthers(false);
                  }
                }}
                value={socialMedia}
                style={{
                  width: "300px",
                }}
                rows="2"
                cols="40"
              >
                <option value="" disabled>
                  Select a social media
                </option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Youtube">Youtube</option>
                <option value="Twitter">Twitter</option>
                <option value="Snapchat">Snapchat</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Medium">Medium</option>
                <option value="Behance">Behance</option>
                <option value="Others">Others</option>
              </select>{" "}
            </div>
            {showOthers && (
              <div>
                <strong>
                  <label>Others</label>
                </strong>
                <br />
                <textarea
                  placeholder="Enter the social media you want to add"
                  onChange={(e) => setOthers(e.target.value)}
                  value={others}
                  rows="2"
                  cols="40"
                />{" "}
              </div>
            )}
            <div>
              <strong>
                <label>Enter Profile Url:</label>
              </strong>
              <br />
              <textarea
                placeholder="Enter Video Url"
                onChange={(e) => setProfileLink(e.target.value)}
                value={profileLink}
                rows="2"
                cols="40"
              />{" "}
            </div>
            <div>
              <strong>
                <label>Enter Profile Name: </label>
              </strong>
              <br />
              <textarea
                placeholder="Enter Profile Name"
                onChange={(e) => setProfileName(e.target.value)}
                value={profileName}
                rows="2"
                cols="40"
              />{" "}
            </div>
            <div style={{}}>
              <strong>
                <label>Enter Subscribers/Followers count</label>
              </strong>
              <br />
              <textarea
                placeholder="Enter Subscribers/Followers Count"
                onChange={(e) => setFollowers(e.target.value)}
                value={followers}
                rows="2"
                cols="40"
              />{" "}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant={from === "update" ? "outline-info" : "outline-warning"}
            onClick={
              from === "update" ? () => handleUpdate() : () => handleAdd()
            }
          >
            {from === "update" ? <>update</> : <>Add</>}
          </Button>
          <Button
            onClick={() => {
              setProfileLink("");
              setProfileName("");
              setFollowers("");
              setSocialMedia("");
              setShowOthers(false);
              setId("");
              setOthers("");
              setModal(!modal);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SocialMedia;

const fetchUserDetails = async (shopId) => {
  if (shopId === "") return alert("Enter valid Shop Id");
  return await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
      method: "POST",
      body: JSON.stringify({
        userId10Digit: shopId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data[0].websiteTemplate);
      return data;
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("Something went wrong, please try again");
    });
};

const contentupload = async (obj, userId) => {
  await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainContentUpload`, {
      method: "POST",
      body: JSON.stringify({
        obj: obj,
        userId: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data);
      alert("uploaded success");
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("error while uploading data. Please try again");
    });
};
