import React, { Component } from "react";
import { Row, Col, Button, Container, Form } from "react-bootstrap";

import { CSVLink, CSVDownload } from "react-csv";
import config from "../../config";
import Limit from "../../limit";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide"
  },
  table: {
    minWidth: 340
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5
  }
});
class TotalLoyality extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showData: false,
      shopkeeperDetails: [],
      headers: [],
      data: [],
      pincode: "",
      orderBy: true
    };
    this.getLoyaltyCustomers = this.getLoyaltyCustomers.bind(this);
    this.searchByPincode = this.searchByPincode.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getMerchantDetails = this.getMerchantDetails.bind(this);
    this.sortFunction = this.sortFunction.bind(this);
    this.csvLink = React.createRef();
    this.export = this.export.bind(this);
  }
  getMerchantDetails(userid) {
    this.props.getMerchant(userid);
  }
  export() {
    const headers = [
      { label: "PINCODE", key: "pincode" },
      { label: "SHOP NAME", key: "shopName" },
      { label: "SHOP TYPE", key: "shopType" },
      { label: "USER ID", key: "userId10Digit" },
      { label: "ADDRESS", key: "userAddress" },
      { label: "PHONE NUMBER", key: "userPhoneNumber" },
      { label: "NAME", key: "userName" },
      { label: "LOYALTY AMOUNT", key: "loyaltyAmount" },
      { label: "LOYALTY POINTS", key: "loyaltyPoints" },
      { label: "TOTAL CUSTOMERS", key: "totalCustomers" },
      { label: "LOYALTY RATIO", key: "loyaltyRatio" }
    ];

    this.setState(
      {
        headers: headers
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  sortFunction(orderBy) {
    var value = !orderBy;
    var sort = this.state.shopkeeperDetails.sort((a, b) =>
      this.state.orderBy
        ? b.totalCustomers - a.totalCustomers
        : a.totalCustomers - b.totalCustomers
    );
    console.log(sort);
    this.setState({
      shopkeeperDetails: sort,
      orderBy: !this.state.orderBy
    });
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  searchByPincode(e) {
    e.preventDefault();
    var tempData = [];
    var merchant = [];

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            constant_score: {
              filter: {
                bool: {
                  must: [
                    {
                      match: { userType: "Shop" }
                    },
                    { term: { loyaltyPackage: "yes" } },
                    { term: { userPincode: this.state.pincode } }
                  ]
                }
              }
            }
          }
        }),
        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempData = data.hits.hits.map(element => element._source);
        var shopId = [];
        tempData.map(items => {
          if (items.userType === "Shop") {
            merchant.push({
              userId: items.userId,
              userId10Digit: items.userId10Digit,
              shopName: items.shopName,
              shopType: items.shopType,
              userAddress: items.shopAddress,
              userPincode: items.userPincode,
              userPhoneNumber: items.userPhoneNumber,
              userName: items.userName,
              loyaltyAmount: items.loyaltyAmount,
              loyaltyPoints: items.loyaltyPoints,
              loyaltyRatio: items.loyaltyRatio
            });
            shopId.push(items.userId);
          }
        });

        shopId = [...new Set(shopId)];
        this.getLoyaltyCustomers(shopId, merchant);
      })
      .catch(err => console.log(err));
  }

  componentDidMount() {
    var tempData = [];
    var merchant = [];

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            constant_score: {
              filter: {
                bool: {
                  must: [
                    {
                      match: { userType: "Shop" }
                    },
                    { term: { loyaltyPackage: "yes" } }
                  ],
                  must_not: [
                    { term: { userPincode: Limit.size.ghoshakInternal } }
                  ]
                }
              }
            }
          }
        }),
        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempData = data.hits.hits.map(element => element._source);
        var shopId = [];
        tempData.map(items => {
          merchant.push({
            userId: items.userId,
            userId10Digit: items.userId10Digit,
            shopName: items.shopName,
            shopType: items.shopType,
            userAddress: items.shopAddress,
            userPincode: items.userPincode,
            userPhoneNumber: items.userPhoneNumber,
            userName: items.userName,
            loyaltyAmount: items.loyaltyAmount,
            loyaltyPoints: items.loyaltyPoints,
            loyaltyRatio: items.loyaltyRatio
          });
          shopId.push(items.userId);
        });

        shopId = [...new Set(shopId)];

        this.getLoyaltyCustomers(shopId, merchant);
      })
      .catch(err => console.log(err));
  }

  getLoyaltyCustomers(shopId, shopDetails) {
    var tempData = [];
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/points/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            constant_score: {
              filter: {
                bool: {
                  must: [
                    { match: { pointsAction: "added" } },
                    { match: { pointsType: "loyalty" } }
                  ],
                  should: [
                    {
                      terms: { shopId: shopId }
                    }
                  ]
                }
              }
            }
          }
        }),
        headers: {
          "Content-Type": "application/json"
        }
      }),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, data2]) => {
        tempData = data.hits.hits.map(element => element._source);
        var loyaltyCustomer = {};
        tempData.forEach(items => {
          if (loyaltyCustomer[items.shopId] == undefined) {
            loyaltyCustomer[items.shopId] = [];
          }

          loyaltyCustomer[items.shopId].push(items.custId);
        });
        var customerUnderLoyaltyProgram = [];
        Object.entries(loyaltyCustomer).map(([item, value]) => {
          let uniqueCustId = [...new Set(value)];

          customerUnderLoyaltyProgram.push({
            shopId: item,
            custId: uniqueCustId,
            totalCustomers: value !== undefined ? uniqueCustId.length : 0
          });
        });
        var loyaltyList = shopDetails.map(element => {
          let tempObject = customerUnderLoyaltyProgram.find(
            item => item.shopId === element.userId
          );
          let appendObject = {};
          if (tempObject !== undefined && tempObject !== null) {
            appendObject = Object.assign({}, element, tempObject);
          } else {
            appendObject = Object.assign({}, element, {
              totalCustomers: 0
            });
          }

          return appendObject;
        });

        // loyaltyList = loyaltyList.filter(
        //   item => item.totalCustomers > Limit.size.loyaltyAtleastCustomer
        // );
        var mergePincodeAndArea = loyaltyList.map(items => {
          let count = data2.Items.find(
            elements => items.userPincode === elements.pincode
          );

          let appendObject = Object.assign({}, items, count);

          return appendObject;
        });

        this.setState({
          showData: true,

          shopkeeperDetails: mergePincodeAndArea
        });
      })
      .catch(err => console.log(err));
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.showData && (
          <>
            {
              <>
                <Row>
                  <div
                    style={{
                      width: 80 + "%",
                      marginTop: 2 + "%",
                      marginBottom: 2 + "%"
                    }}
                  >
                    <h1 style={{ textAlign: "center" }}>
                      <b>{this.state.shopkeeperDetails.length}</b> SHOPS IN
                      LOYALTY PROGRAM
                    </h1>
                  </div>
                </Row>

                <Form
                  style={{ marginBottom: 5 + "px" }}
                  onSubmit={this.searchByPincode}
                >
                  <Form.Row>
                    <Col md={{ span: 3, offset: 1 }}>
                      <h5>SEARCH BY PINCODE</h5>
                    </Col>
                    <Col md={{ span: 2 }}>
                      <Form.Control
                        onChange={this.onChange}
                        type="text"
                        name="pincode"
                        pattern="^[0-9]{6}"
                        required
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Button type="submit">SEARCH</Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => {
                          this.sortFunction(this.state.orderBy);
                        }}
                      >
                        SORT
                      </Button>
                    </Col>
                    <Col md={{}}>
                      <Button
                        onClick={() => {
                          this.export();
                        }}
                      >
                        DOWNLOAD
                      </Button>
                      <CSVLink
                        enclosingCharacter={`'`}
                        data={this.state.shopkeeperDetails}
                        headers={this.state.headers}
                        ref={this.csvLink}
                        target="_blank"
                      />
                    </Col>
                  </Form.Row>
                </Form>
                <Row>
                  <Table
                    style={{
                      // width: 90 + "%",
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid"
                      // paddingLeft: 10 + "%",
                      // paddingRight: 10 + "%"
                    }}
                    size="medium"
                    className={classes.table}
                  >
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            S NO
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            SHOPNAME
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            SHOPTYPE
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            USERID
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            ADDRESS
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            PINCODE
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            PLACE
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            PHONE NUMBER
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            MERCHANT NAME
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            LOYALTY RATIO
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            LOYALTY AMOUNT
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            LOYALTY POINTS
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            TOTAL CUSTOMER
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.shopkeeperDetails.map((items, index) => (
                          <>
                            <TableRow key={items.userId10Digit}>
                              <TableCell className={classes.tableCell}>
                                {index + 1}
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {items.shopName}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.shopType}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                <Button
                                  variant="link"
                                  onClick={() => {
                                    this.getMerchantDetails(
                                      items.userId10Digit
                                    );
                                  }}
                                >
                                  {" "}
                                  {items.userId10Digit}
                                </Button>
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.userAddress}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.userPincode}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.place}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.userPhoneNumber}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.userName}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.loyaltyRatio}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.loyaltyAmount}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.loyaltyPoints}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {items.totalCustomers}
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </>
                  </Table>
                </Row>
              </>
            }
          </>
        )}
      </>
    );
  }
}
TotalLoyality.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TotalLoyality);
