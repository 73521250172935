import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import MaterialTable from "material-table";
import config from "../config";
import { Link, useHistory } from "react-router-dom";
export default function AppointmentDashboard() {
  const [shopList, setShopList] = useState([]);
  const history = useHistory();
  const fetchData = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/trackAppointment`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setShopList(data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Container>
        <div style={{ width: "100%", height: "100px" }}></div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "1.4rem",
          }}
        >
          <h2
            style={{
              textTransform: "capitalize",
              marginBottom: 0,
              fontSize: "2.4rem",
              fontWeight: "600",
              color: "#4f4e4e",
            }}
          >
            Appointment Dashboard
          </h2>
        </div>

        <Row>
          <MaterialTable
            title="Appointment Dashboard"
            columns={[
              {
                title: "Shop Id",
                field: "shopIduserId10Digit",
                render: (rowData) => (
                  <Link
                    to={{
                      pathname: "/merchant",
                      search: `?userid=${rowData.shopIduserId10Digit}`,
                    }}
                  >
                    {rowData.shopIduserId10Digit}
                  </Link>
                ),
              },
              {
                title: "Shop Name",
                field: "shopName",
              },
              {
                title: "Owner Name",
                field: "merchantName",
              },

              {
                title: "Pincode",
                field: "shopPincode",
              },

              {
                title: "Total Appointments",
                field: "totalAppointments",
                render: (rowData) => (
                  <td>
                    {(parseInt(rowData.appointmentStatus.booked) || 0) +
                      (parseInt(rowData.appointmentStatus.rejected) || 0) +
                      (parseInt(rowData.appointmentStatus.completed) || 0) +
                      (parseInt(rowData.appointmentStatus.cancelled) || 0)}
                  </td>
                ),
              },

              {
                title: "Booked",
                field: "bookedAppointment",
                render: (rowData) => (
                  <td>{parseInt(rowData.appointmentStatus.booked) || 0}</td>
                ),
              },
              {
                title: "Rejected ",
                field: "rejectedAppointment",
                render: (rowData) => (
                  <td>{parseInt(rowData.appointmentStatus.rejected) || 0}</td>
                ),
              },
              {
                title: "Completed ",
                field: "completedAppointment",
                render: (rowData) => (
                  <td>{parseInt(rowData.appointmentStatus.completed) || 0}</td>
                ),
              },
              {
                title: "Cancelled ",
                field: "cancelledAppointment",
                render: (rowData) => (
                  <td>{parseInt(rowData.appointmentStatus.cancelled) || 0}</td>
                ),
              },
              {
                title: "Action",
                field: "view",
                render: (rowData) => (
                  <Button
                    onClick={() => {
                      history.push(`/appointment/${rowData.shopId}`);
                    }}
                  >
                    View Shop
                  </Button>
                ),
              },
            ]}
            data={shopList}
            options={{
              exportButton: true,
              pageSize: 5,
              maxBodyHeight: 437,
              pageSizeOptions: [5, 10, 20, 100],
              grouping: false,
              filtering: false,
            }}
          />
        </Row>
      </Container>
    </>
  );
}
