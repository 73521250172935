import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Badge,
} from "react-bootstrap";
import config from "../config";
import moment from "moment";
import MaterialTable from "material-table";
import { Auth } from "aws-amplify";
export default function Statement() {
  const [id, setId] = useState("");
  const [statementData, setStatementData] = useState([]);
  useEffect(() => {
    async function getUser() {
      await Auth.currentUserInfo().then((data) => {
        setId(data.username);
        getData(data.username);
      });
    }
    getUser();
  }, []);
  const getData = async (cogId) => {
    await Promise.all([
      fetch(`${config.ApiGateway}/partnerStatement`, {
        method: "POST",
        body: JSON.stringify({
          template: "partnerById",
          cognitoId: cogId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        // let result = await Promise.all(
        //   data.map(async (element) => await trackShop(element))
        // );

        setStatementData(data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container>
      <>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Statement</h2>
        </div>
        {statementData.map((items) => (
          <Row>
            <Col className="pendingBox" sm={"12"}>
              <div className="companyName">
                <div style={{ width: "100%", float: "left" }}>
                  <Badge
                    style={{ textAlign: "right" }}
                    pill
                    variant={
                      items.profile.accountType == "primary"
                        ? "success"
                        : "warning"
                    }
                  >
                    {items.profile.accountType == "primary"
                      ? items.profile.accountType
                      : "Employee"}
                  </Badge>
                </div>
                <div style={{ width: "100%", float: "left" }}>
                  <Row>
                    <Col sm={"6"}>
                      <h4 style={{ fontWeight: "500", fontSize: "20px" }}>
                        {items.profile.companyName}
                      </h4>
                      <h6>
                        {items.profile.userName}(
                        <b>{items.profile.referralCode}</b>)
                      </h6>
                    </Col>
                    <Col style={{ textAlign: "right" }} sm={"6"}>
                      <h4 style={{ fontWeight: "500", fontSize: "20px" }}>
                        {items.profile.companyName}
                      </h4>
                      <h6>{items.profile.userPhoneNumber.slice(-10)}</h6>
                    </Col>
                  </Row>
                  <div className="profileCardContainer">
                    <Row>
                      <Col sm="12">
                        <div
                          style={{ backgroundColor: "#fff" }}
                          className="tableCard"
                        >
                          <div
                            style={{ backgroundColor: "#fff" }}
                            className="tableCardBody"
                          >
                            <MaterialTable
                              title={""}
                              options={{
                                rowStyle: {
                                  fontSize: 12,
                                },
                              }}
                              style={{
                                border: "none",
                                boxShadow: "none",
                                backgroundColor: "#ffffff",
                              }}
                              columns={[
                                {
                                  title: "Period",
                                  field: "cycleStartDate",
                                  render: (rowData) => {
                                    return `${moment(
                                      rowData.cycleStartDate
                                    ).format("MMM Do YYYY")} - ${moment(
                                      rowData.cycleEndDate
                                    ).format("MMM Do YYYY")}`;
                                  },
                                },
                                { title: "Criteria", field: "criteria" },
                                { title: "Shop Name", field: "shopName" },
                                {
                                  title: "Activity",
                                  field: "details",
                                  render: (rowData) => (
                                    <ul
                                      style={{
                                        listStyle: "none",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    >
                                      {Object.keys(rowData.details).map(
                                        (items) => (
                                          <li
                                            style={{
                                              listStyle: "none",
                                              padding: "0px",
                                              margin: "0px",
                                            }}
                                          >
                                            <b>{items}</b> :{" "}
                                            {rowData.details[items]
                                              .toString()
                                              .replace(/true|false| - /gi, "")}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ),
                                },
                                {
                                  title: "Amount",
                                  field: "amountPaid",
                                  render: (rowData) =>
                                    typeof rowData.amountPaid == "undefined"
                                      ? ""
                                      : `₹ ${rowData.amountPaid}`,
                                },
                                {
                                  title: "verification",
                                  field: "verificationStatus",
                                },
                                {
                                  title: "Paid On",
                                  field: "paidOn",
                                },
                                {
                                  title: "Status",
                                  field: "statementStatus",
                                  render: (rowData) =>
                                    rowData.statementStatus == "paid" ? (
                                      <Badge pill variant={"success"}>
                                        Paid
                                      </Badge>
                                    ) : (
                                      <Badge pill variant={"warning"}>
                                        Pending
                                      </Badge>
                                    ),
                                },
                                {
                                  title: "Remark",
                                  field: "remarkForPartner",
                                },
                                // {
                                //   title: "Action",
                                //   field: "action",
                                //   render: (rowData) => (
                                //     <Button onClick={() => handleShow(rowData)}>
                                //       Edit
                                //     </Button>
                                //   ),
                                // },
                              ]}
                              data={items.statement}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
            <div className="vertical-space-40"></div>
          </Row>
        ))}
      </>
    </Container>
  );
}
