import React from "react";
import { Container, Col, Button, Row, Form } from "react-bootstrap";
export default function ErrorPage() {
  return (
    <Container
      fluid
      style={{
        backgroundColor: "#ffffff",
        height: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h3>Oops Page Not Found</h3>
    </Container>
  );
}
