import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Row, Button, Container } from "react-bootstrap";
import config from "../config";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing.unit * 3,
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class CouponRedeemedCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showdata: false,
      showTable: [],
      redeemedUsers: [],
      showusers: true,
    };
    this.getReedemedCouponFunction = this.getReedemedCouponFunction.bind(this);
  }
  componentDidMount() {
    let searchParams = new URLSearchParams(window.location.search);

    if (
      searchParams.get("id") !== null &&
      searchParams.get("id") !== undefined
    ) {
      this.getReedemedCouponFunction(searchParams.get("id"));
      //id -  coupon id
    }
  }
  getReedemedCouponFunction(couponId) {
    // couponId - coupon unique ,coupon create -[{ name : "Abc" , id "01",couponType - merchant,status - added}] , After customer redeemed  [{reedemedCouponId : "01",custId : "",status : "claimed or redeemed"}]
    Promise.all([
      fetch(
        `${config.ApiGateway}/couponRedemption`,

        {
          method: "POST",
          body: JSON.stringify({ couponId: couponId }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, data2]) => {
        var mergePincodeAndArea = data.map((items) => {
          let count = data2.Items.find(
            (elements) => items.userPincode === elements.pincode
          );
          let appendObject = {};
          count !== undefined
            ? (appendObject = Object.assign({}, items, count))
            : (appendObject = Object.assign(
                {},
                {
                  pincode: items,
                  place: "NOT FOUND",
                }
              ));

          return appendObject;
        });
        console.log(mergePincodeAndArea);
        this.setState({
          // prevNotification: couponDetails.notification,
          redeemedUsers: mergePincodeAndArea, // Redeemed coupon customers
          //   displayTable: false,
          //   showCustomer: true
        });
      })
      .catch((err) => alert(err));
  }

  render() {
    const { classes } = this.props;
    return (
      <Container fluid>
        <Row>
          <>
            <div
              style={{
                backgroundColor: "#fafafa",
                width: 100 + "%",
                margin: 1 + "%",
              }}
            >
              <h3 style={{ textAlign: "center" }}> CUSTOMER DETAILS </h3>
            </div>
            <Table
              style={{
                width: 100 + "%",
                border: 0.5 + "px",
                borderColor: "#ced4da",
                borderStyle: "solid",
              }}
              className={classes.table}
              size="small"
            >
              <>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>S NO</TableCell>
                    <TableCell className={classes.tableCell}>
                      CUSTOMER ID
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      CUSTOMER NAME
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      PHONE NUMBER
                    </TableCell>
                    <TableCell className={classes.tableCell}>PINCODE</TableCell>
                    <TableCell className={classes.tableCell}>PLACE</TableCell>
                    <TableCell className={classes.tableCell}>
                      REDEEMED DATE
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      REDEEMED COUPON ID
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      COUPON STATUS
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      PAID STATUS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.redeemedUsers.map((elements, index) => (
                    <TableRow key={elements.userId10Digit}>
                      <TableCell className={classes.tableCell}>
                        {index + 1}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Link
                          to={{
                            pathname: "/customer",
                            search: `?userid=${elements.userId10Digit}`,
                          }}
                        >
                          {" "}
                          {elements.userId10Digit}{" "}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {elements.userName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {elements.userPhoneNumber}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {elements.userPincode}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {elements.place}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {elements.redeemedDate}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {elements.redeemedCouponId}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {elements.couponStatus}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {elements.paidStatus}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            </Table>
          </>
        </Row>
      </Container>
    );
  }
}
CouponRedeemedCustomers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CouponRedeemedCustomers);
