import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";

// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   Typography
// } from "@material-ui/core";
import config from "../../config";
export default class CustomerRefferals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showdata: false,
      topReffers: [],
      successRefferal: "",
    };
    this.renderFunction = this.renderFunction.bind(this);
    this.viewTable = this.viewTable.bind(this);
  }
  async componentDidMount() {
    await this.renderFunction();
  }
  renderFunction() {
    var top10Reffers = this.props.refer
      .sort((a, b) => b.count - a.count)
      .slice(0, 10);

    let successRefferal = 0;
    this.props.refer.forEach(
      (items) => (successRefferal += parseInt(items.count))
    );

    return this.setState({
      topReffers: top10Reffers,
      showdata: true,
      successRefferal: successRefferal,
    });
  }
  viewTable(items) {
    this.props.renderTable(items, ["referedCount"]);
  }

  render() {
    return (
      <>
        {this.state.showdata && (
          <>
            <Row style={{ width: 100 + "%", justifyContent: "center" }}>
              <b style={{ fontSize: 32 }}>Customer Refferals</b>
            </Row>
            <Row style={{ width: 100 + "%", justifyContent: "center" }}>
              <Col
                style={{ textAlign: "center" }}
                className="division"
                xs={{ span: 3, offset: 1 }}
              >
                <h3>
                  {" "}
                  <b>{this.props.refer.length}</b>
                </h3>

                <p>Total Refferals</p>
              </Col>
              <Col
                style={{ textAlign: "center" }}
                className="division"
                xs={{ span: 3, offset: 1 }}
              >
                <h3>
                  {" "}
                  <b>{this.state.successRefferal}</b>
                </h3>

                <p>successful referal Customer</p>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 5, offset: 1 }} className="division">
                <b>Top 10 Referrer</b>
                <h3 style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      this.viewTable(this.state.topReffers);
                    }}
                  >
                    View
                  </Button>
                </h3>
              </Col>
              <Col xs={{ span: 5, offset: 1 }} className="division">
                <b>View All Referrers</b>
                <h3 style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      this.viewTable(this.props.refer);
                    }}
                  >
                    View
                  </Button>
                </h3>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}
