import React, { Component } from "react";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import config from "../config";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class AddGhoshakCoins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: [],
      userId10Digit: "",
      page: 0,
      rowsPerPage: 5,
      pointsTracker: [],
      getCustomerToggle: true,
      points: {
        transactionDate: new Date().toISOString().substring(0, 10),
        transactionDetails: "",
        pointsType: "ghoshak",
        pointsAction: "added",
        points: "",
        custId: "",
      },
    };
    this.onChange = this.onChange.bind(this);
    this.getCustomerHandler = this.getCustomerHandler.bind(this);
    this.pointsHandler = this.pointsHandler.bind(this);
    this.addPointsSubmit = this.addPointsSubmit.bind(this);
    this.reset = this.state;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }
  pointsHandler(e) {
    this.setState({
      ...this.state,
      points: { ...this.state.points, [e.target.name]: e.target.value },
    });
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  addPointsSubmit(e) {
    e.preventDefault();
    // this.setState({
    //   ...this.state,
    //   points: { ...this.state.points, custId: this.state.userInfo.userId }
    // });
    fetch(
      `${config.ApiGateway}/addGhoshakCoins`,

      {
        method: "POST",
        body: JSON.stringify(this.state.points),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        alert(data.value);
        this.setState(this.reset);
      })
      .catch((err) => console.log(err));
  }

  getCustomerHandler(e) {
    e.preventDefault();
    fetch(
      `${config.ApiGateway}/getCustomer`,

      {
        method: "POST",
        body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          ...this.state,
          points: {
            ...this.state.points,
            custId: data[0][0].userId,
          },

          userInfo: data[0][0],
          getCustomerToggle: false,
          pointsTracker: data[1][0],
        });
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Container fluid>
          <>
            <div>
              <h4 style={{ textAlign: "center" }}> Add Ghoshak Coins</h4>
            </div>
            {this.state.getCustomerToggle ? (
              <>
                <Form onSubmit={this.getCustomerHandler}>
                  <Row style={{ marginTop: 10 + "%" }}>
                    <Col xs={{ span: 4, offset: 4 }}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Enter Customer ID</Form.Label>
                        <Form.Control
                          type="text"
                          name="userId10Digit"
                          value={this.state.userId10Digit}
                          onChange={this.onChange}
                        ></Form.Control>
                      </Form.Group>
                      <Button
                        style={{
                          width: 100 + "%",
                          height: 30 + "%",
                          marginTop: 2 + "%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        type="submit"
                      >
                        {" "}
                        SEARCH
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            ) : (
              <>
                <Row>
                  <Col xs={6}>
                    <div
                      style={{
                        marginLeft: "120px",
                        marginTop: "55px",
                        width: 50 + "%",
                        justifyContent: "center",
                      }}
                    >
                      <h4 style={{ textAlign: "center" }}>
                        <b>Customer Details</b>
                      </h4>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="userName"
                          disabled
                          value={this.state.userInfo.userName}
                          onChange={this.onChange}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>User ID </Form.Label>
                        <Form.Control
                          type="text"
                          name="userId10Digit"
                          disabled
                          value={this.state.userInfo.userId10Digit}
                          onChange={this.onChange}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="userPhoneNumber"
                          disabled
                          value={this.state.userInfo.userPhoneNumber}
                          onChange={this.onChange}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Pincode</Form.Label>
                        <Form.Control
                          type="text"
                          name="userId10Digit"
                          disabled
                          value={this.state.userInfo.userPincode}
                          onChange={this.onChange}
                        ></Form.Control>
                      </Form.Group>
                      <Form onSubmit={this.addPointsSubmit}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Points</Form.Label>
                          <Form.Control
                            type="text"
                            name="points"
                            pattern="[0-9.]{1,20}"
                            value={this.state.points.points}
                            onChange={this.pointsHandler}
                            required
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Transaction Details</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="transactionDetails"
                            value={this.state.points.transactionDetails}
                            onChange={this.pointsHandler}
                            required
                          ></Form.Control>
                        </Form.Group>
                        <Button
                          style={{
                            width: 100 + "%",
                            height: 30 + "%",
                            marginTop: 2 + "%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          type="submit"
                        >
                          Add Points
                        </Button>
                      </Form>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div style={{ marginLeft: "120px", marginTop: "55px" }}>
                      <h4 style={{ textAlign: "center" }}>
                        <b>Ghoshak Transaction Details</b>
                      </h4>
                      <Table
                        style={{
                          border: 0.5 + "px",
                          borderColor: "#ced4da",
                          borderStyle: "solid",
                        }}
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              S NO
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Details
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Points Type
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Action
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Points
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Redeemed CouponId
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Date
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.pointsTracker
                            .slice(
                              this.state.page * this.state.rowsPerPage,
                              this.state.page * this.state.rowsPerPage +
                                this.state.rowsPerPage
                            )
                            .map((key, value) => (
                              <>
                                <TableRow>
                                  <TableCell className={classes.tableCell}>
                                    {value + 1}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {key.transactionDetails}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {key.pointsType}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {key.pointsAction}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {key.points}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {key.redeemedCouponId}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {key.transactionDate}
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        component="div"
                        count={this.state.pointsTracker.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </>
        </Container>
      </>
    );
  }
}
AddGhoshakCoins.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddGhoshakCoins);
