import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import config from "../config";
export default class PackageDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      package: {
        pincode: "",
        imageFile: [],
        packageAddedon: new Date().toISOString().substr(0, 10),
        packageStatus: "",
        packageId: "",
        lastUpdatedon: new Date().toISOString().substr(0, 10),
        packageAddedBy: ""
      },
      toggleState: true,
      searchPincode: "",
      packageBookedStatus: true,
      imagePreviewUrl: [],
      packageBookedDetails: []
    };
    this.reset = this.state;
    this.changeStatus = this.changeStatus.bind(this);
    this.onChange = this.onChange.bind(this);
    this.packageInsert = this.packageInsert.bind(this);
    this.handleimage = this.handleimage.bind(this);
    this.searchPincode = this.searchPincode.bind(this);
    this.searchPincodeSubmit = this.searchPincodeSubmit.bind(this);
  }
  componentDidMount() {
    Promise.all([fetch(`${config.json.URL}/imagesize.json`)])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.setState({
          imageSize: data.PACKAGE
        });
      })
      .catch(err => console.log(err));

    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({
          ...this.state,
          package: {
            ...this.state.package,
            packageAddedBy: user.attributes.phone_number
          } //====>
        });
      })

      .catch(err => console.log(err));
  }

  searchPincode(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  changeStatus(response) {
    console.log(response);
    this.setState({
      ...this.state,
      package: {
        ...this.state.package,
        packageId: response.packageId,
        pincode: response.pincode,
        packageAddedBy: response.packageAddedBy,
        packageStatus: "UpdatePackage"
      },
      toggleState: false
    });
  }
  searchPincodeSubmit(e) {
    e.preventDefault();

    Promise.all([
      fetch(
        `${config.ApiGateway}/checkpackageavailability`,
        // `${process.env.REACT_APP_DEV_API_URL}/checkpackageavailability`,
        {
          method: "POST",
          body: JSON.stringify({
            searchPincode: this.state.searchPincode
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        if (data.length !== 0) {
          this.setState({
            packageBookedStatus: false,
            packageBookedDetails: data
          });
        } else {
          this.setState({
            ...this.state,
            package: {
              ...this.state.package,
              pincode: this.state.searchPincode,
              packageStatus: "InsertPackage"
            },
            toggleState: false
          });
        }
      })
      .catch(err => console.log(err));
  }
  handleimage(event) {
    if (event.target.files[0].size > parseInt(this.state.imageSize)) {
      alert("image file should be under 150kb ");
      return false;
    }

    this.setState({
      selectedFile: event.target.files[0]
    });

    let reader = new FileReader();
    let imageFormData = new FormData();
    imageFormData.append("photo", event.target.files[0]);
    reader.onloadend = () => {
      this.setState({
        ...this.state,
        package: {
          ...this.state.package,
          imageFile: reader.result.replace(/^data:image\/\w+;base64,/, "")
        }
      });

      this.setState({
        imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(event.target.files[0]);
  }
  packageInsert(e) {
    e.preventDefault();

    fetch(
      `${config.ApiGateway}/package`,
      // `${process.env.REACT_APP_DEV_API_URL}/package`,
      {
        method: "POST",
        body: JSON.stringify(this.state.package),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        alert("package details inserted for" + this.state.package.pincode);
        this.setState(this.reset);
      })
      .catch(err => {
        alert("failed to insert package");

        console.log(err);
      });
  }
  onChange(e) {
    this.setState({
      ...this.state,
      package: { ...this.state.package, [e.target.name]: e.target.value }
    });
  }
  render() {
    return (
      <Container>
        {this.state.toggleState ? (
          <>
            {this.state.packageBookedStatus ? (
              <>
                <Col md={{ span: 4, offset: 3 }} className="division">
                  <h4 style={{ textAlign: "center" }}>ENTER PINCODE</h4>
                  <Form onSubmit={this.searchPincodeSubmit}>
                    <Form.Control
                      type="text"
                      name="searchPincode"
                      onChange={this.searchPincode}
                      value={this.state.searchPincode}
                    ></Form.Control>
                    <Button
                      style={{
                        width: 100 + "%",
                        height: 30 + "%",
                        marginTop: 2 + "%",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {" "}
                      SEARCH
                    </Button>
                  </Form>
                </Col>
              </>
            ) : (
              <>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid"
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">PACKAGE ID</TableCell>
                      <TableCell align="center">PACKAGE IMAGE</TableCell>
                      <TableCell align="center">PINCODE</TableCell>
                      <TableCell align="center">POSTED ON</TableCell>
                      <TableCell align="center">ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.packageBookedDetails.map((item, index) => (
                      <TableRow key={item.packageId}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{item.packageId}</TableCell>

                        <>
                          <TableCell align="center">
                            <img
                              src={item.imageUrl}
                              alt={item.imageUrl}
                              width="50%"
                            ></img>
                          </TableCell>
                          <TableCell align="center">{item.pincode}</TableCell>
                          <TableCell align="center">
                            {item.packageAddedon}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure want to change the exsisting package ?"
                                  )
                                ) {
                                  this.changeStatus(item);
                                }
                              }}
                              variant="outlined"
                            >
                              CHANGE PACKAGE
                            </Button>
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </>
        ) : (
          <>
            <Row>
              <div
                style={{
                  width: 100 + "%",
                  margin: 1 + "%"
                }}
              >
                <h3 style={{ textAlign: "center" }}>PACKAGE</h3>
              </div>
            </Row>
            <Form onSubmit={this.packageInsert}>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  ENTER PINCODE{" "}
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="pincode"
                    onChange={this.onChange}
                    value={this.state.package.pincode}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label classname="asterik" column sm="4">
                  UPLOAD IMAGE
                </Form.Label>
                <Col>
                  <input type="file" onChange={this.handleimage}></input>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="4"></Form.Label>
                <Col>
                  <img
                    src={this.state.imagePreviewUrl}
                    alt={this.state.imagePreviewUrl}
                    onLoad={this.onImgLoad}
                    width="50%"
                  ></img>
                </Col>
              </Form.Group>
              <Button
                variant="outlined"
                type="submit"
                style={{
                  marginBottom: 20 + "px",
                  marginTop: 20 + "px"
                }}
              >
                SUBMIT
              </Button>
            </Form>
          </>
        )}
      </Container>
    );
  }
}
