import React, { Component } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { Select } from "@material-ui/core";
import config from "../config";
import MaterialTable from "material-table";
import { withStyles } from "@material-ui/core/styles";

import { OutTable, ExcelRenderer } from "react-excel-renderer";
const useStyles = (theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

class OnlineCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopList: {
        shopId: "",
        userId10Digit: "",
        pincode: "",
        shopName: "",
      },

      shopDetails: [],
      confirmShop: false,
      userId10Digit: "",
      addCoupon: {
        couponAddedby: "",
        pincode: "",
        remainingCouponCount: "",
        shopId: "",
        maximumDiscount: "",
        expiryDate: "",
        discountType: "flat",
        discountAmount: "",
        default: "true",
        couponGivenBy: "Ghoshak",
        promocode: "",
        minPrice: "",
        conditionType: "mThAndnTh",
        hidden: "false",
      },
      page: 0,
      rowsPerPage: 10,
    };
    this.onChange = this.onChange.bind(this);
    this.getShop = this.getShop.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addShops = this.addShops.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.createCoupon = this.createCoupon.bind(this);
    this.reset = this.state;
    this.rowUpdate = this.rowUpdate.bind(this);
    this.rowDelete = this.rowDelete.bind(this);
    this.filehandler = this.filehandler.bind(this);
    this.getBatchShops = this.getBatchShops.bind(this);
    this.batchShops = [];
  }
  rowUpdate = (newData, oldData) => {
    newData.totalCoupons = isNaN(parseInt(newData.totalCoupons))
      ? 0
      : newData.totalCoupons;
    const dataUpdate = [...this.state.shopDetails];
    const index = oldData.tableData.id;
    dataUpdate[index] = newData;

    this.setState({
      shopDetails: [...dataUpdate],
    });
  };
  rowDelete = (oldData) => {
    const dataUpdate = [...this.state.shopDetails];
    const index = oldData.tableData.id;
    dataUpdate.splice(index, 1);
    this.setState({
      shopDetails: [...dataUpdate],
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: +event.target.value,
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  componentDidMount = async () => {
    this._isMounted = true;

    await Auth.currentAuthenticatedUser()
      .then((user) => {
        if (this._isMounted) {
          this.setState({
            addCoupon: {
              ...this.state.addCoupon,
              couponAddedby: user.attributes.phone_number,
            },
          });
        }
      })

      .catch((err) => console.log(err));
  };
  componentWillUnmount = () => {
    this._isMounted = false;
  };
  handleChange = (e) => {
    this.setState({
      addCoupon: { ...this.state.addCoupon, [e.target.name]: e.target.value },
    });
  };

  getShop = (e) => {
    e.preventDefault();
    Promise.all([
      fetch(`${config.ApiGateway}/getCouponLimit`, {
        method: "POST",

        body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        Object.keys(data).length <= 0
          ? alert("Entered Shop id is not found")
          : this.setState({
              shopList: {
                shopId: data.shopId,
                userId10Digit: data.userId10Digit,
                pincode: data.userPincode,
                shopName: data.shopName,
                userPhoneNumber: data.userPhoneNumber,
                userName: data.userName,
                shopAddress: data.shopAddress,
                totalCoupons: 1,
              },

              confirmShop: true,
            });
      })
      .catch((err) => console.log("Error in getting Shop" + err));
  };
  addShops = () => {
    let newShop = [...this.state.shopDetails, ...[this.state.shopList]];

    this.setState({
      shopDetails: newShop,
      confirmShop: false,
    });
  };
  removeRow = (rows) => {
    this.setState({
      shopDetails: this.state.shopDetails.filter(
        (items, i) => items.userId10Digit !== rows
      ),
    });
  };
  filehandler = async (event) => {
    let file = event.target.files[0];
    var consolidatedArray = [];
    ExcelRenderer(file, async (err, resp) => {
      if (err) {
        console.log((err) => "imported file is err due to" + err);
      } else {
        resp.rows.map((elements, index) => {
          if (index > 0) {
            consolidatedArray.push({
              label: elements[0].toString(),
              totalCount: parseInt(elements[1]) || 10,
            });
          }
        });
        let mergeShops = [];
        let shopList = await this.getBatchShops(consolidatedArray);

        consolidatedArray.map((elements) => {
          let findShops = shopList.find(
            (items) => elements.label === items.userId10Digit
          );
          mergeShops.push({
            shopId: findShops.userId,
            userId10Digit: findShops.userId10Digit,
            pincode: findShops.userPincode,
            shopName: findShops.shopName,
            userPhoneNumber: findShops.userPhoneNumber,
            userName: findShops.userName,
            shopAddress: findShops.shopAddress,
            totalCoupons: elements.totalCount,
          });
        });

        let newShop = [...this.state.shopDetails, ...mergeShops];

        this.setState({
          shopDetails: newShop,
          confirmShop: false,
        });
      }
    });
  };

  getBatchShops = async (shopId) => {
    let filterShopById = shopId.map((items) => items.label);

    return await Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              should: [{ terms: { userId10Digit: filterShopById } }],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let tempdata = data.hits.hits.map((element) => element._source);
        return tempdata;
      })
      .catch((err) => console.log("Error in getting Shop" + err));
  };

  createCoupon = (e) => {
    e.preventDefault();
    Promise.all([
      fetch(`${config.ApiGateway}/addOrderCoupon`, {
        method: "POST",

        body: JSON.stringify({
          addCoupon: this.state.addCoupon,
          shopDetails: this.state.shopDetails,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        alert("coupon created successfully");
        this.setState(this.reset);
        this.componentDidMount();
      })
      .catch((err) => console.log("Error in getting Shop" + err));
  };

  render() {
    const { classes } = this.props;

    return (
      <Container>
        <div style={{ width: "100%", height: "100px" }}></div>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Online Coupons</h2>
        </div>
        <Row style={{ justifyContent: "center" }}>
          <Col
            className="flexCardHeader"
            style={{ boxShadow: "0px 0px 13px 0px rgba(26, 52, 95, 0.08)" }}
            sm={8}
          >
            <Form style={{ width: "100%" }} onSubmit={this.createCoupon}>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="4">
                  Provided By
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    value={this.state.addCoupon.couponGivenBy}
                    as={Select}
                    name="couponGivenBy"
                    required
                    onChange={this.handleChange}
                  >
                    <option value="Ghoshak">Ghoshak</option>
                    <option value="Merchant">Merchant</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              {this.state.addCoupon.couponGivenBy === "Ghoshak" && (
                <Form.Group as={Row} controlId="formPlaintextPassword">
                  <Form.Label column sm="4">
                    Coupon Code
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      name="promocode"
                      value={this.state.addCoupon.promocode}
                      onChange={this.handleChange}
                      required
                    />
                  </Col>
                </Form.Group>
              )}

              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="4">
                  Discount Type
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    value={this.state.addCoupon.discountType}
                    as={Select}
                    onChange={this.handleChange}
                    name="discountType"
                    required
                  >
                    <option value="flat">Flat</option>
                    <option value="percentage">Percentage</option>
                    <option value="product">Product</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              {this.state.addCoupon.discountType === "percentage" && (
                <Form.Group as={Row} controlId="formPlaintextPassword">
                  <Form.Label column sm="4">
                    Maximum Discount
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      name="maximumDiscount"
                      value={this.state.addCoupon.maximumDiscount}
                      onChange={this.handleChange}
                      pattern="[0-9]{1,}"
                      required
                    ></Form.Control>
                  </Col>
                </Form.Group>
              )}

              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="4">
                  Discount Amount
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    name="discountAmount"
                    value={this.state.addCoupon.discountAmount}
                    onChange={this.handleChange}
                    pattern="[0-9]{1,}"
                    required
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="4">
                  Apply Default
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    value={this.state.addCoupon.default}
                    as={Select}
                    onChange={this.handleChange}
                    name="default"
                    required
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="4">
                  Hidden
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    value={this.state.addCoupon.hidden}
                    as={Select}
                    name="hidden"
                    required
                    onChange={this.handleChange}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="4">
                  Expiry Date
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    value={this.state.addCoupon.expiryDate}
                    name="expiryDate"
                    type="date"
                    onChange={this.handleChange}
                    required
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="4">
                  Minimum Price
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    value={this.state.addCoupon.minPrice}
                    name="minPrice"
                    pattern="[0-9]{1,}"
                    onChange={this.handleChange}
                    required
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="4">
                  Condition Type
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    value={this.state.addCoupon.conditionType}
                    name="conditionType"
                    as="select"
                    onChange={this.handleChange}
                    required
                  >
                    <option value="mThAndnTh"> mTh & NTh Time</option>
                    <option value="once"> Once</option>
                    <option value="userOnce"> Unique</option>
                    <option value="perCustomerClaim">Per Customer Claim</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              {this.state.addCoupon.conditionType === "perCustomerClaim" && (
                <>
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Form.Label column sm="4">
                      Customer Number
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control
                        value={this.state.customerId}
                        name="customerId"
                        onChange={this.onChange}
                      ></Form.Control>
                    </Col>
                    <Button>Search</Button>
                  </Form.Group>
                </>
              )}

              <div className="vertical-space-30"></div>

              {this.state.confirmShop ? (
                <>
                  <div
                    style={{
                      border: "1px solid #dedede",
                    }}
                  >
                    <div className="shopLabeltext">
                      <label>Shop Name :</label>
                      <p style={{ width: "40%" }}>
                        {this.state.shopList.shopName}
                      </p>
                      <label>Merchant Name :</label>
                      <p style={{ width: "40%" }}>
                        {this.state.shopList.userName}
                      </p>
                    </div>
                    <div className="shopLabeltext">
                      <label>Phone Number :</label>
                      <p style={{ width: "40%" }}>
                        {" "}
                        {this.state.shopList.userPhoneNumber}
                      </p>
                      <label>Address :</label>
                      <p style={{ width: "40%" }}>
                        {" "}
                        {this.state.shopList.shopAddress}
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        onClick={() => this.addShops()}
                        style={{ marginRight: "5px" }}
                      >
                        Confirm
                      </Button>
                      <Button style={{ marginRight: "5px" }}>Cancel</Button>
                    </div>
                  </div>
                </>
              ) : (
                <Form style={{ width: "100%" }}>
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Col sm="4">
                      <Form.Control
                        onChange={this.onChange}
                        value={this.state.userId10Digit}
                        name="userId10Digit"
                        placeholder="Enter Shop Id"
                      />
                    </Col>
                    <Col sm="2">
                      <Button onClick={this.getShop}>Get Shop</Button>
                    </Col>
                    <Col style={{ borderLeft: "1px solid #dedede" }} sm="3">
                      <Form.Label>Import Excel</Form.Label>
                    </Col>
                    <Col sm="3">
                      <Form.Control
                        onChange={this.filehandler}
                        type="file"
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                </Form>
              )}
              <div className="vertical-space-30"></div>
              {this.state.shopDetails.length <= 0 ? (
                <small>No records to Show</small>
              ) : (
                <>
                  <MaterialTable
                    title="Shop List "
                    columns={[
                      {
                        title: "Coupon Count",
                        field: "totalCoupons",
                        editComponent: (props) => (
                          <input
                            type="number"
                            value={props.value}
                            onChange={(e) => props.onChange(e.target.value)}
                          ></input>
                        ),
                      },
                      {
                        title: "Shop Name",
                        field: "shopName",
                        editable: "never",
                      },
                      {
                        title: "Name",
                        field: "userName",
                        editable: "never",
                      },

                      {
                        title: "Phone Number",
                        field: "userPhoneNumber",
                        editable: "never",
                      },

                      {
                        title: "Address",
                        field: "shopAddress",
                        editable: "never",
                      },
                      {
                        title: "Pincode",
                        field: "pincode",
                        editable: "never",
                      },
                    ]}
                    data={this.state.shopDetails}
                    editable={{
                      // only group(b) rows would be deletable

                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            this.rowUpdate(newData, oldData);
                            resolve();
                          }, 600);
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                          setTimeout(() => {
                            this.rowDelete(oldData);
                            resolve();
                          }, 1000);
                        }),
                    }}
                    options={{
                      exportButton: true,
                      pageSize: 5,
                      maxBodyHeight: 437,
                      pageSizeOptions: [5, 10, 20, 100],
                      grouping: false,
                      filtering: false,
                    }}
                  />
                </>
              )}

              <div className="vertical-space-30"></div>
              {this.state.shopDetails.length > 0 && (
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withStyles(useStyles)(OnlineCoupon);
