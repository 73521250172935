import React, { useState, useEffect } from "react";
import config from "../config";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import Limit from "../limit";
export default function CouponStatistics() {
  const [state, setState] = useState({
    startDate: "",
    endDate: "",
  });
  const [count, setCount] = useState({
    total: 0,
    allocated: 0,
  });
  var couponCounts = 0;
  var allocatedCost = 0;
  const getCouponRecordsPerMonth = (e) => {
    e.preventDefault();
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/coupons/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                {
                  match: {
                    couponCategory: "Created",
                  },
                },

                {
                  range: {
                    addedDate: {
                      gte: state.startDate,
                      lte: state.endDate,
                      boost: 2.0,
                    },
                  },
                },
              ],
              must_not: [{ term: { pincode: Limit.size.ghoshakInternal } }],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let tempdata = data.hits.hits.map((element) => element._source);
        console.log(tempdata, "coup list");
        couponCounts = 0;
        allocatedCost = 0;
        tempdata.map((items) => {
          couponCounts += parseInt(items.totalCouponCount) || 0;
        });
        tempdata.map((items) => {
          allocatedCost +=
            (parseInt(items.totalCouponCount) || 0) *
            (parseInt(items.maximumCouponAmount) || 0);
        });
        console.log(couponCounts);

        setCount({
          total: couponCounts,
          allocated: allocatedCost,
        });
      })
      .catch((err) => console.log(err));
  };

  const onChange = (name, value) => {
    setState((prevState) => ({ ...state, [name]: value }));
  };
  return (
    <>
      <Form onSubmit={getCouponRecordsPerMonth}>
        <Row>
          <Col>
            {" "}
            <Form.Control
              type="date"
              onChange={(e) => onChange(e.target.name, e.target.value)}
              value={state.startDate}
              name="startDate"
            ></Form.Control>
          </Col>
          <Col>
            <Form.Control
              type="date"
              onChange={(e) => onChange(e.target.name, e.target.value)}
              value={state.endDate}
              name="endDate"
            ></Form.Control>
          </Col>
          <Col>
            {" "}
            <Button type="Submit">Search</Button>
          </Col>
        </Row>
      </Form>
      <Row>
        <ul>
          <li>coupon count - {count.total}</li>
          <li>Allocated cost - {count.allocated}</li>
        </ul>
      </Row>
    </>
  );
}
