//Import dependencies

import Amplify, { Auth } from "aws-amplify";
import { Authenticator } from "aws-amplify-react";
import { createBrowserHistory } from "history";
import React, { useEffect, useState, useContext } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import LinkInvite from "./authentication/invite";
import ErrorPage from "./component/errorPage";
import Navigation from "./component/navigationBar";
import config from "./config";
import "./index.css";
import { StateProvider } from "./provider";
import "react-pro-sidebar/dist/css/styles.css";
import Notification from "./notification";

// *********************    Appointment   ************************//
import RouterPath from "./routerPath";
import { store } from "./provider";
const customHistory = createBrowserHistory();

Amplify.configure({
  Auth: {
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.USERPOOL_WEBCLIENT_ID,
  },
});

export default function Main() {
  const context = useContext(store);
  const [userRole, setUserRole] = useState("");
  const [authState, setAuthState] = useState("");
  useEffect(() => {
    //Get user Role - admin,sales,manager,partner
    async function getUserRole() {
      await Auth.currentAuthenticatedUser()
        .then(async (user) => {
          console.log("user", user);
          var info = user.signInUserSession.idToken.payload["cognito:groups"];
          setUserRole(info[0]);
        })
        .catch(async (err) => {
          setUserRole({ sessionLogin: "sales" });
        });
    }
    getUserRole();
  }, []);
  const handleAuthStateChange = (authState, cognitoUser) => {
    setAuthState(authState); // update state with current auth state (confirm sign in,forget password, reset password)
  };

  return (
    <>
      <StateProvider>
        <Authenticator
          hideDefault={true}
          onStateChange={(authState, cognitoUser) => {
            handleAuthStateChange(authState, cognitoUser);
          }}
        >
          <>
            <Router history={customHistory}>
              <Navigation
                authState={authState}
                onStateChange={handleAuthStateChange}
              />
              <div style={{ width: "100%", float: "left" }}>
                <Route path="/error" exact component={ErrorPage} />
                <Route path="/login" exact>
                  <LinkInvite onStateChange={handleAuthStateChange} />
                </Route>
                <RouterPath
                  authState={authState}
                  authorizedControlAccess={userRole}
                  onStateChange={handleAuthStateChange}
                />
              </div>
              <Notification />
            </Router>
          </>
        </Authenticator>
      </StateProvider>
    </>
  );
}

ReactDOM.render(<Main />, document.getElementById("root"));
