import React, { Component } from "react";
import { Row, Col, Button, Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import config from "../../config";
import Limit from "../../limit";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide"
  },
  table: {
    minWidth: 340
  },
  tableCell: {
    paddingRight: 4,
    textAlign: "center",

    paddingLeft: 5
  }
});
class MerchantAddedCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showData: false,
      showShop: true,
      customerDetails: [],
      shopkeeperDetails: [],
      headers: [],
      data: [],
      pincode: "",
      orderBy: true
    };

    this.searchByPincode = this.searchByPincode.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getMerchantDetails = this.getMerchantDetails.bind(this);
    this.sortFunction = this.sortFunction.bind(this);
    this.csvLink = React.createRef();
    this.getCustomerDetails = this.getCustomerDetails.bind(this);
    this.export = this.export.bind(this);
  }

  getMerchantDetails(userid) {
    this.props.getMerchant(userid);
  }

  getCustomerDetails(userPhoneNumber) {
    console.log(userPhoneNumber);
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              should: [{ terms: { userPhoneNumber: userPhoneNumber } }]
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      }),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([users, place]) => {
        let tempdata = users.hits.hits.map(element => element._source);
        let customers = userPhoneNumber.map(items => {
          let count = tempdata.find(
            elements => elements.userPhoneNumber === items
          );
          let appendObject = {};
          if (count !== undefined) {
            appendObject = Object.assign({}, count);
            appendObject.userStatus = "Ghoshak User";
          } else {
            appendObject = {
              userPhoneNumber: items,
              userPincode: "",
              userId10Digit: "",
              userName: "",
              userAddress: "",
              userStatus: "Not a Ghoshak User"
            };
          }

          return appendObject;
        });

        var mergePincodeAndArea = customers.map(items => {
          if (items.userPincode !== undefined) {
            let count = place.Items.find(
              elements => items.userPincode === elements.pincode
            );
            let appendObject = {};
            if (count !== undefined) {
              appendObject = Object.assign({}, items, count);
            } else {
              appendObject = Object.assign({}, items, {
                pincode: items.userPincode,
                place: "Not Found"
              });
            }
            return appendObject;
          } else {
            return items;
          }
        });

        this.setState({
          customerDetails: mergePincodeAndArea,
          showShop: false
        });
      })
      .catch(err => console.log(err));
  }

  export() {
    const headers = [
      { label: "PINCODE", key: "pincode" },
      { label: "SHOP NAME", key: "shopName" },
      { label: "SHOP TYPE", key: "shopType" },
      { label: "SHOP ID", key: "userId10Digit" },
      { label: "ADDRESS", key: "userAddress" },
      { label: "PHONE NUMBER", key: "userPhoneNumber" },
      { label: "NAME", key: "userName" },

      { label: "TOTAL CUSTOMERS", key: "addedCustomers" }
    ];

    this.setState(
      {
        headers: headers
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  sortFunction(orderBy) {
    var value = !orderBy;
    var sort = this.state.shopkeeperDetails.sort((a, b) =>
      this.state.orderBy
        ? b.addedCustomers - a.addedCustomers
        : a.addedCustomers - b.addedCustomers
    );
    this.setState({
      shopkeeperDetails: sort,
      orderBy: !this.state.orderBy
    });
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  searchByPincode(e) {
    e.preventDefault();
    var tempData = [];
    var merchant = [];

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            constant_score: {
              filter: {
                bool: {
                  must: [
                    {
                      match: { userType: "Shop" }
                    },
                    {
                      term: { userPincode: this.state.pincode }
                    }
                  ],
                  must_not: [
                    { term: { userPincode: Limit.size.ghoshakInternal } }
                  ]
                }
              }
            }
          }
        }),
        headers: {
          "Content-Type": "application/json"
        }
      }),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, place]) => {
        tempData = data.hits.hits.map(element => element._source);

        tempData.map(items => {
          merchant.push({
            userId: items.userId,
            userId10Digit: items.userId10Digit,
            shopName: items.shopName,
            shopType: items.shopType,
            userAddress: items.shopAddress,
            userPincode: items.userPincode,
            userPhoneNumber: items.userPhoneNumber,
            userName: items.userName,
            addedCustomers: Object.keys(items.customerPhoneNumberMap).length,
            customerList: Object.keys(items.customerPhoneNumberMap).map(
              items => items
            )
          });
        });

        var mergePincodeAndArea = merchant.map(items => {
          let count = place.Items.find(
            elements => items.userPincode === elements.pincode
          );
          let appendObject = {};
          if (count !== undefined) {
            appendObject = Object.assign({}, items, count);
          } else {
            appendObject = Object.assign({}, items, {
              pincode: items.userPincode,
              place: "Not Found"
            });
          }

          return appendObject;
        });
        this.setState({
          shopkeeperDetails: mergePincodeAndArea,
          showData: true,
          showShop: true
        });
      })
      .catch(err => console.log(err));
  }

  async componentDidMount() {
    var tempData = [];
    var merchant = [];

    await Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            constant_score: {
              filter: {
                bool: {
                  must: [
                    {
                      match: { userType: "Shop" }
                    }
                  ],
                  must_not: [
                    { term: { userPincode: Limit.size.ghoshakInternal } }
                  ]
                }
              }
            }
          }
        }),
        headers: {
          "Content-Type": "application/json"
        }
      }),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, place]) => {
        tempData = data.hits.hits.map(element => element._source);
        var shopId = [];
        tempData.map(items => {
          merchant.push({
            userId: items.userId,
            userId10Digit: items.userId10Digit,
            shopName: items.shopName,
            shopType: items.shopType,
            userAddress: items.shopAddress,
            userPincode: items.userPincode,
            userPhoneNumber: items.userPhoneNumber,
            userName: items.userName,
            addedCustomers: Object.keys(items.customerPhoneNumberMap).length,
            customerList: Object.keys(items.customerPhoneNumberMap).map(
              items => items
            )
          });
        });

        var mergePincodeAndArea = merchant.map(items => {
          let count = place.Items.find(
            elements => items.userPincode === elements.pincode
          );
          let appendObject = {};
          if (count !== undefined) {
            appendObject = Object.assign({}, items, count);
          } else {
            appendObject = Object.assign({}, items, {
              pincode: items.userPincode,
              place: "Not Found"
            });
          }

          return appendObject;
        });
        this.setState({
          shopkeeperDetails: mergePincodeAndArea,
          showData: true,
          showShop: true
        });
      })
      .catch(err => console.log(err));
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.showData && (
          <>
            {
              <>
                <Row>
                  <div
                    style={{
                      width: 80 + "%",
                      marginTop: 2 + "%",
                      marginBottom: 2 + "%"
                    }}
                  >
                    <h1 style={{ textAlign: "center" }}>
                      {this.state.showShop === true
                        ? this.state.shopkeeperDetails.length
                        : this.state.customerDetails.length}{" "}
                      MERCHANT ADDED CUSTOMERS
                    </h1>
                  </div>
                </Row>

                <Form
                  style={{ marginBottom: 5 + "px" }}
                  onSubmit={this.searchByPincode}
                >
                  <Form.Row>
                    <Col md={{ span: 3, offset: 1 }}>
                      <h5>SEARCH BY PINCODE</h5>
                    </Col>
                    <Col md={{ span: 2 }}>
                      <Form.Control
                        onChange={this.onChange}
                        type="text"
                        name="pincode"
                        pattern="^[0-9]{6}"
                        required
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Button type="submit">SEARCH</Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => {
                          this.sortFunction(this.state.orderBy);
                        }}
                      >
                        SORT
                      </Button>
                    </Col>
                    <Col md={{}}>
                      <Button
                        onClick={() => {
                          this.export();
                        }}
                      >
                        DOWNLOAD
                      </Button>
                      <CSVLink
                        enclosingCharacter={`'`}
                        data={this.state.shopkeeperDetails}
                        headers={this.state.headers}
                        ref={this.csvLink}
                        target="_blank"
                      />
                    </Col>
                  </Form.Row>
                </Form>
                <Row>
                  <Table
                    style={{
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid"
                    }}
                    className={classes.table}
                    size="medium"
                  >
                    {this.state.showShop ? (
                      <>
                        {" "}
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              S NO
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              SHOPNAME
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              SHOPTYPE
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              USERID
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              ADDRESS
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              PINCODE
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              PLACE
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              PHONE NUMBER
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              MERCHANT NAME
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              TOTAL CUSTOMER
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              ACTION
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.shopkeeperDetails.map((items, index) => (
                            <>
                              <TableRow key={index + 1}>
                                <TableCell className={classes.tableCell}>
                                  {index + 1}
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  {items.shopName}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.shopType}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Button
                                    variant="link"
                                    onClick={() => {
                                      this.getMerchantDetails(
                                        items.userId10Digit
                                      );
                                    }}
                                  >
                                    {" "}
                                    {items.userId10Digit}
                                  </Button>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.userAddress}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.userPincode}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.place}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.userPhoneNumber}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.userName}
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  {items.addedCustomers}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Button
                                    onClick={() => {
                                      this.getCustomerDetails(
                                        items.customerList
                                      );
                                    }}
                                  >
                                    VIEW CUSTOMER
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </>
                    ) : (
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              S NO
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              USERID
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              ADDRESS
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              PINCODE
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              PLACE
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              PHONE NUMBER
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              USER NAME
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              USER STATUS
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.customerDetails.map((items, index) => (
                            <>
                              <TableRow key={index + 1}>
                                <TableCell className={classes.tableCell}>
                                  {index + 1}
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  {items.userId10Digit !== undefined ? (
                                    <Link
                                      to={{
                                        pathname: "/customer",
                                        search: `?userid=${items.userId10Digit}`
                                      }}
                                    >
                                      {" "}
                                      {items.userId10Digit}
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.userAddress}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.userPincode}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.place}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.userPhoneNumber}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.userName}
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  {items.userStatus}
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </>
                    )}
                  </Table>
                </Row>
              </>
            }
          </>
        )}
      </>
    );
  }
}
MerchantAddedCustomer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MerchantAddedCustomer);
