import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import config from "../config";
import Constant from "./constant";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import MaterialTable from "material-table";
import { Container, Col, Button, Row, Tabs, Tab, Form } from "react-bootstrap";
const findUnit = async (value) => {
  return await new Promise((resolve) => {
    let returnType = "";
    if (value !== undefined) {
      let unitValue = value.toLowerCase().trim();

      if (["gms", "grams", "g"].includes(unitValue)) returnType = "g";

      if (["kilograms", "kilo grams", "kg"].includes(unitValue))
        returnType = "kg";
      if (["ltr", "litre", "l"].includes(unitValue)) returnType = "L";
      if (["glass"].includes(unitValue)) returnType = "glass";
      if (["cup"].includes(unitValue)) returnType = "cup";
      if (["milli litre", "ml", "milli"].includes(unitValue)) returnType = "ml";
      if (
        ["pcs", "packs", "pieces", "piece", "count", "counts"].includes(
          unitValue
        )
      )
        returnType = "count";
    }

    resolve(returnType);
  });
};
export default function ProductUploadThroughExcel() {
  const [fields, setFields] = useState([]);
  const [profile, setProfile] = useState({});
  const [state, setState] = useState({
    category: [],
    subCategory: [],
    duration: [],
    unit: [],
  });
  const [errorText, setErrorText] = useState(false);
  const [product, setProduct] = useState([]);
  const [otherShopType, setOtherShopType] = useState(false);
  let searchParams = new URLSearchParams(window.location.search);
  const history = useHistory();
  useEffect(() => {
    async function getShopData() {
      try {
        await Promise.all([
          fetch(
            `${config.ApiGateway}/getCouponLimit`,

            {
              method: "POST",

              body: JSON.stringify({
                userId10Digit: searchParams.get("shopId"),
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          ),
        ])
          .then(([res1]) => Promise.all([res1.json()]))
          .then(async ([data]) => {
            if (Object.keys(data).length > 0) {
              let categoryDropdown = [];
              let subDropdown = [];
              let durationDropdown = [];
              let groupDropdown = [];
              let subArray = [];
              let unitDropdown = [];
              // setShopId(data.shopId);

              // console.log(Constant);
              let shopType = data.shopType;

              let getTemplate = await getShopJsonbyShopType(shopType);

              if (Constant.SALOON.key.includes(shopType)) {
                console.log(getTemplate);
                let jsonType =
                  shopType == "Saloon"
                    ? getTemplate.salonCategory
                    : getTemplate.beautyParlourCategory;
                jsonType.forEach((items) => {
                  categoryDropdown.push(
                    <option value={items.value}>{items.value}</option>
                  );
                });
                getTemplate.duration.map((elements) =>
                  durationDropdown.push(
                    <option value={elements}>{elements}</option>
                  )
                );
                setOtherShopType(false);
                setFields(Constant.SALOON.fields);
                console.log(Constant.SALOON.fields, "saloon");
              } else if (Constant.KIRANA.key.includes(shopType)) {
                Object.entries(getTemplate.groups).map(
                  ([elements, headers]) => {
                    Object.entries(headers.category).forEach(
                      ([items, values]) => {
                        values.subCategory.map(
                          (sub) =>
                            sub !== "ALL" &&
                            categoryDropdown.push({
                              group: headers.name,
                              category: values.name,
                              subCategory: sub,
                            }) &&
                            subArray.push(sub)
                        );
                      }
                    );
                  }
                );
                subArray = subArray.sort();

                subArray.forEach((sub) => {
                  subDropdown.push(<option value={sub}>{sub}</option>);
                });

                const getUnitDropdown = await fetch(
                  `${config.json.URL}/fields.json`
                );
                var getMeasurement = await getUnitDropdown.json();
                getMeasurement.unitOfMesurement.forEach((elements) =>
                  unitDropdown.push(
                    <option value={elements}>{elements}</option>
                  )
                );
                setOtherShopType(false);
                setFields(Constant.KIRANA.fields);
                console.log(Constant.KIRANA.fields, "KIRANA");
              } else if (Constant.RESTAURANT.key.includes(shopType)) {
                Object.entries(getTemplate).forEach(([key, value]) => {
                  Object.values(value).forEach((key1) => {
                    groupDropdown.push(
                      <option value={key1.name}>{key1.name}</option>
                    );
                    Object.values(key1.category).forEach((key2) => {
                      Object.values(key2.subCategory).forEach((key3) => {
                        subDropdown.push(<option value={key3}>{key3}</option>);
                      });

                      categoryDropdown.push(
                        <option value={key2.name}>{key2.name}</option>
                      );
                    });
                  });
                });
                setOtherShopType(false);
                setFields(Constant.RESTAURANT.fields);
                console.log(Constant.RESTAURANT.fields, "RESTAURANT");
              } else if (Constant.TEXTILE.key.includes(shopType)) {
                //Get Json for category and subcategory  ---  textile

                getTemplate.category.map((item) => {
                  getTemplate.subCategory.map((elements) => {
                    if (item.value === elements.category) {
                      categoryDropdown.push({
                        group: "Others",
                        category: elements.category,
                        subCategory: elements.value,
                      }) && subArray.push(elements.value);
                    }
                  });
                });
                categoryDropdown
                  .sort((a, b) => b.subCategory - a.subCategory)
                  .map((items) =>
                    subDropdown.push(
                      <option
                        value={items.subCategory}
                      >{`${items.category} - ${items.subCategory}`}</option>
                    )
                  );
                setOtherShopType(false);
                setFields(Constant.TEXTILE.fields);
                console.log(Constant.TEXTILE.fields, "TEXTILE");
              } else if (Constant.MOBILE.key.includes(shopType)) {
                getTemplate.mobileCategory.forEach((items) => {
                  categoryDropdown.push(
                    <option value={items.value}>{items.value}</option>
                  );
                });
                setOtherShopType(false);
                setFields(Constant.MOBILE.fields);
                console.log(Constant.MOBILE.fields, "MOBILE");
              } else {
                setOtherShopType(true);
                setFields(Constant.OTHERS.fields);
                console.log(Constant.OTHERS.fields, "OTHERS");
              }
              setProfile(data);
              setState((prevState) => ({
                ...prevState,
                category: categoryDropdown,
                subCategory: subDropdown,
                duration: durationDropdown,
                unit: unitDropdown,
              }));
            } else {
              history.push("/inventoryProductsByShop");
              alert("Entered shop id not found");
            }
          })
          .catch((err) => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    if (
      searchParams.get("shopId") !== null &&
      searchParams.get("shopId") !== undefined &&
      searchParams.get("shopId") !== ""
    ) {
      getShopData();
    } else {
      history.push("/inventoryProductsByShop");
      alert("Shop ID Not Found");
    }
  }, [searchParams.get("shopId")]);

  const getShopJsonbyShopType = async (shopType) => {
    switch (shopType) {
      case "Supermarket":
      case "Provisional Store":
        try {
          const response = await fetch(
            `${config.json.URL}/productCategory.json`
          );
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }
      case "Sweets and Snacks":
        try {
          const response = await fetch(
            `${config.json.URL}/productCategorySweetsAndSnacks.json`
          );
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }
      case "Restaurant":
      case "Bakery":
      case "Juice Shop":
      case "Tea Shop":
        try {
          const response = await fetch(`${config.json.URL}/restaurant.json`);
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }
      case "Saloon":
      case "Textile":
      case "Beauty Parlour":
      case "Mobile":
        try {
          const response = await fetch(`${config.json.URL}/fields.json`);
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }

      default:
        return {};
    }
  };
  const excelHandler = async (event) => {
    let fileObj = event.target.files[0];

    ExcelRenderer(fileObj, async (err, resp) => {
      // Read data from excel file

      if (err) {
        console.log(err);
      } else {
        let products = [];
        resp.rows.slice(1).map((elements, index) => {
          let input = {};
          elements.map((items, index) => (input[resp.rows[0][index]] = items));
          products.push(input);
          // console.log(products,"eXcel prod")
        });

        let validKeys = fields;
        // console.log(validKeys);

        let uploadProducts = await Promise.all(
          products.map(async (items) => {
            let newData = {};

            // For number data add toString() method to convert to string data type

            if (validKeys.includes("productPrice")) {
              newData.productPrice = isNaN(items.productPrice)
                ? "0"
                : items.productPrice.toString();
            }

            if (validKeys.includes("productMrp")) {
              // Checks product mrp in array. if value is not present, copies value of product price or 0

              newData.productMrp = isNaN(items.productMrp)
                ? newData.productPrice.toString() || "0"
                : items.productMrp.toString();
            }
            if (validKeys.includes("productName")) {
              newData.productName = items.productName || "NA";
            }
            if (validKeys.includes("category")) {
              newData.category = items.category || "NA";
            }
            if (validKeys.includes("subCategory")) {
              newData.subCategory = items.subCategory || "NA";
            }
            if (validKeys.includes("productImageArray")) {
              newData.productImageArray = (items.productImageArray).split(',') || "NA";
            }
            if (validKeys.includes("productBrand")) {
              newData.productBrand = items.productBrand || profile.shopName;
            }
            if (validKeys.includes("productQty")) {
              newData.productQty = isNaN(items.productQty)
                ? "1"
                : items.productQty;
            }
            if (validKeys.includes("duration")) {
              newData.duration = "15";
            }

            if (validKeys.includes("unitOfMeasurment")) {
              if (typeof items.unitOfMeasurment != "undefined") {
                let [word, digits] = String(items.unitOfMeasurment).match(
                  /\D+|\d+/g
                );

                newData.measurementUnit =
                  digits == "undefined" ? "g" : await findUnit(digits);

                newData.measurementValue =
                  word == "undefined" ? "0" : word || "0";
              } else {
                // Initially assign value "0 g"

                newData.measurementUnit = "g";
                newData.measurementValue = "0";
              }
            }

            if (validKeys.includes("productType")) {
              newData.productType = Boolean(items.productType)
                ? items.productType.toLowerCase() == "service"
                  ? "service"
                  : "product"
                : "product";
            }

            if (validKeys.includes("vegNonVeg")) {
              newData.vegNonVeg = items.vegNonVeg || "Veg";
            }
            if (validKeys.includes("productEANCode")) {
              newData.productEANCode = items.productEANCode || "NA";
            }
            newData.inventoryQty = isNaN(items.inventoryQty)
              ? 1
              : items.inventoryQty;
            newData.shopId = profile.shopId;
            return newData;
          })
        );
        console.log(uploadProducts, "UPLOAD PRODUCT");
        setProduct(uploadProducts);
      }
    });
  };

  const validateProduct = async () => {
    let input = product;
    let validated = await validateProductByCondition(input); //return  paramter - [valid,invalid]
    let validData = validated[0];
    let invalidData = validated[1];
    if (invalidData.length > 0) {
      setErrorText(true);
      setProduct(invalidData);
    } else {
      Promise.all([
        fetch(`${config.ApiGateway}/addInventoryItem`, {
          method: "POST",
          body: JSON.stringify({
            productList: validData,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ])
        .then(([res1]) => Promise.all([res1.json()]))
        .then(([data]) => {
          if (invalidData.length > 0) {
            setErrorText(true);
            setProduct(invalidData);
          } else {
            setErrorText(false);
            setProduct([]);
            setOtherShopType(false);
            setFields([]);
            setState((prevState) => ({
              ...prevState,
              category: [],
              subCategory: [],
              duration: [],
              unit: [],
            }));
          }
        })
        .catch((err) => {
          alert("Product Not Updated");
          console.log(err);
        });
    }
  };

  const validateProductByCondition = async (items) => {
    return await new Promise((resolve) => {
      let invalidData = [];
      let validData = [];
      let validFields = fields;
      items.forEach((elements) => {
        // Loops all records
        if (Constant.KIRANA.key.includes(profile.shopType)) {
          elements.unitOfMeasurment =
            elements.measurementValue + " " + elements.measurementUnit;

          delete elements.measurementValue;
          delete elements.measurementUnit;
        }

        if (otherShopType) {
          elements.group = "Others";
          elements.subCategory = "Others";
          elements.category = "Others";
        }
        // console.log(elements);

        let evaluatedList = validFields.map((key) => Boolean(elements[key])); // if key value is undefined, null or emplty returns false
        if (evaluatedList.includes(false)) {
          // evaluated data contains false it is invalid
          invalidData.push(elements);
        } else {
          // valid data
          validData.push(elements);
        }
      });
      resolve([validData, invalidData]); // syntax 0 - valid, 1 - invalid
    });
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h2>Shop Profile </h2>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <div className="excelUploadBox">
              <input onChange={(e) => excelHandler(e)} type="file"></input>
              <p>upload Excel Sheet</p>
            </div>
          </Col>
          <Col sm="12">
            <div className="alignButtonRight">
              <div style={{ width: "auto" }}>
                {errorText && (
                  <span style={{ background: "#fce4e4", color: "#cc0033" }}>
                    Product Upload failed. Please check all data are filled
                  </span>
                )}
              </div>
              <Button onClick={() => validateProduct()}>Upload</Button>
            </div>
            <MaterialTable
              title="Product Lis"
              columns={[
                {
                  title: "Group",
                  field: "group",
                  editable: "never",
                  hidden: !fields.includes("group"),
                },
                {
                  title: "Category",
                  field: "category",
                  hidden: !fields.includes("category"),
                  editable: !["Mobile"].includes(profile.shopType)
                    ? "never"
                    : "always",
                  editComponent: (props) => {
                    return (
                      <select
                        type="text"
                        value={props.value}
                        onChange={(e) => {
                          let choosenSubCategory = e.target.value;

                          if (Constant.MOBILE.key.includes(profile.shopType)) {
                            // handle category and group for mobile
                            props.rowData.subCategory = "Others";
                            props.rowData.group = "Others";
                          }
                          props.onChange(e.target.value);
                        }}
                      >
                        <option value="">Select Category</option>
                        {state.category}
                      </select>
                    );
                  },
                },
                {
                  title: "Sub Category",
                  field: "subCategory",
                  hidden: !fields.includes("subCategory"),

                  editable: ![
                    "Textile",
                    "Supermarket",
                    "Provisional Store",
                    "Sweets and Snacks",
                    "Restaurant",
                    "Juice Shop",
                    "Bakery",
                  ].includes(profile.shopType)
                    ? "never"
                    : "always",
                  editComponent: (props) => {
                    return (
                      <select
                        type="text"
                        value={props.value}
                        onChange={(e) => {
                          let choosenSubCategory = e.target.value;

                          if (
                            Constant.TEXTILE.key.includes(profile.shopType) ||
                            Constant.KIRANA.key.includes(profile.shopType)
                          ) {
                            // handle category and group for textile

                            let findCategory = state.category.find(
                              (elements) =>
                                elements.subCategory == choosenSubCategory
                            );

                            if (Object.keys(findCategory).length > 0) {
                              props.rowData.category = findCategory.category;
                              props.rowData.group = findCategory.group;
                            } else {
                              props.rowData.category = null;
                              props.rowData.group = null;
                            }
                          } else if (
                            Constant.RESTAURANT.key.includes(profile.shopType)
                          ) {
                            props.rowData.category = "RESTAURANT";
                            props.rowData.subCategory = "RESTAURANT";
                            props.rowData.group = "RESTAURANT";
                          } else {
                            props.rowData.category = "OTHERS";
                            props.rowData.subCategory = "OTHERS";
                            props.rowData.group = "OTHERS";
                          }
                          // if (Constant.KIRANA.key.includes(profile.shopType)) {
                          //   let findCategory = state.category.find(
                          //     (elements) =>
                          //       elements.subCategory == choosenSubCategory
                          //   );

                          //   if (Object.keys(findCategory).length > 0) {
                          //     props.rowData.category = findCategory.category;
                          //     props.rowData.group = findCategory.group;
                          //   } else {
                          //     props.rowData.category = null;
                          //     props.rowData.group = null;
                          //   }
                          // }
                          props.onChange(e.target.value);
                        }}
                      >
                        <option value="">Select Sub Category</option>
                        {state.subCategory}
                      </select>
                    );
                    // let selectedSubCategoryValue = props.value;

                    // let subValue = props.value;
                    // let getCategoryValue = productSegment.find(
                    //   (elements) => elements.subCategory === subValue
                    // );
                    // if (getCategoryValue !== undefined) {
                    //   props.rowData.category = getCategoryValue.category;
                    //   props.rowData.group = getCategoryValue.group;
                    // }
                    // return (
                    //   <select
                    //     type="text"
                    //     value={props.value}
                    //     onChange={(e) => props.onChange(e.target.value)}
                    //   >
                    //     <option value="">Select Sub Category</option>
                    //     {subCategoryList}
                    //   </select>
                    // );
                  },
                },
                {
                  title: "Product Name",
                  field: "productName",
                  hidden: !fields.includes("productName"),
                  validate: (rowData) => Boolean(rowData.productName),
                },
                {
                  title: "Product Images",
                  field: "productImageArray",
                  hidden: !fields.includes("productName"),
                  validate: (rowData) => Boolean(rowData.productImageArray),
                },
                {
                  title: "Duration",
                  field: "duration",
                  hidden: !fields.includes("duration"),
                  editComponent: (props) => {
                    return (
                      <select
                        type="text"
                        value={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.value);
                        }}
                      >
                        <option value="">Select Duration</option>
                        {state.duration}
                      </select>
                    );
                  },
                  validate: (rowData) => Boolean(rowData.duration),
                },
                {
                  title: "Brand",
                  field: "productBrand",
                  hidden: !fields.includes("productBrand"),
                },
                {
                  title: "Selling Price ₹",
                  field: "productPrice",
                  hidden: !fields.includes("productPrice"),
                  validate: (rowData) =>
                    !isNaN(rowData.productPrice) && rowData.productPrice != "",
                },
                {
                  title: "MRP ₹",
                  field: "productMrp",
                  hidden: !fields.includes("productMrp"),
                  validate: (rowData) =>
                    !isNaN(rowData.productMrp) && rowData.productMrp != "",
                },
                {
                  title: "Inventory Quantity",
                  field: "inventoryQty",
                  type: "numeric",
                  hidden: !fields.includes("inventoryQty"),
                  validate: (rowData) => parseInt(rowData.inventoryQty) > 0,
                },
                {
                  title: "Product Type",
                  field: "productType",
                  hidden: !fields.includes("productType"),
                  editComponent: (props) => (
                    <select
                      type="text"
                      value={props.value}
                      onChange={(e) => props.onChange(e.target.value)}
                    >
                      <select value="">Select Product Type</select>
                      <option value="product">Product</option>
                      <option value="service">Service</option>
                    </select>
                  ),
                },
                {
                  title: "Product Quantity ",
                  field: "productQty",

                  hidden: !fields.includes("productQty"),
                  validate: (rowData) => parseInt(rowData.productQty) > 0,
                },
                {
                  title: "Measurement value",
                  field: "measurementValue",
                  hidden: ![
                    "Sweets and Snacks",
                    "Supermarket",
                    "Provisional Store",
                  ].includes(profile.shopType),
                  validate: (rowData) =>
                    parseInt(rowData.measurementValue) >= 0,
                },

                {
                  title: "Measurement Unit",
                  field: "measurementUnit",
                  hidden: ![
                    "Sweets and Snacks",
                    "Supermarket",
                    "Provisional Store",
                  ].includes(profile.shopType),

                  editComponent: (props) => {
                    return (
                      <select
                        name="measurementUnit"
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                      >
                        <select value="">Select Unit</select>
                        {state.unit}
                      </select>
                    );
                  },
                },

                {
                  title: "Veg/ Non Veg",
                  field: "vegNonVeg",
                  editable: "always",
                  hidden: !fields.includes("vegNonVeg"),
                  editable: ![
                    "Sweets and Snacks",
                    "Supermarket",
                    "Provisional Store",
                    ...Constant.RESTAURANT.key,
                  ].includes(profile.shopType)
                    ? "always"
                    : "never",
                  editComponent: (props) => (
                    <select
                      type="text"
                      value={props.value}
                      onChange={(e) => props.onChange(e.target.value)}
                    >
                      <option value="Veg">Vegeterian</option>
                      <option value="Non-Veg">Non Vegterian</option>
                    </select>
                  ),
                },

                {
                  title: "Ean Code",
                  field: "productEANCode",
                  hidden: !fields.includes("productEANCode"),
                  editable: "always",
                },
              ]}
              data={product}
              // actions={[
              //   {
              //     icon: "save",
              //     tooltip: "Save User",
              //     onClick: (event, rowData) => validateProduct(rowData),
              //   },
              // ]}
              options={{
                search: false,
                pageSize: 30,
                pageSizeOptions: [5, 10, 20, 100, 300, 500],
                grouping: false,
                filtering: false,
              }}
              editable={{
                // only group(b) rows would be deletable

                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      const dataUpdate = [...product];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setProduct([...dataUpdate]);
                      resolve();
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...product];
                      const index = oldData.tableData.id;
                      dataDelete.splice(index, 1);
                      setProduct([...dataDelete]);
                      resolve();
                    }, 1000);
                  }),
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
