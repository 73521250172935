import React, { Component } from "react";
import { Form, Row, Col, Button, Container, Modal } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { Auth } from "aws-amplify";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import config from "../config";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import ShopProfilecard from "../template/shopProfile";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});

class ViewShopAddedProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId10Digit: "",
      userInformation: {},
      getShopDetails: true,
      page: 0,
      chooseStatus: "All",
      rowsPerPage: 20,
      productDetails: [],
      groupDropdown: [],
      categoryDropdown: [],
      subDropdown: [],
      category: "none",
      selectGroup: "none",
      selectSubCategory: "none",
      show: false,
      editProduct: {
        category: "",
        group: "",
        inventoryQty: 0,
        latest: "",
        productBrand: "",
        productCode: "",
        productId: "",
        productMrp: "",
        productName: "",
        productPrice: "",
        productQty: "",

        vegNonVeg: "Veg",
        shopId: "",
        status: "",
        subCategory: "",
        unitOfMeasurment: "",
        versionId: "",
        productEANCode: "",
      },
      groupList: [],
      categoryList: [],
      subList: [],
      unitList: [],
    };
    this.reset = this.state;
    this.getShop = this.getShop.bind(this);
    this.onChange = this.onChange.bind(this);
    this.makeAllLive = this.makeAllLive.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.editProduct = this.editProduct.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.subOnChange = this.subOnChange.bind(this);
    this.categoryOnchange = this.categoryOnchange.bind(this);
    this.editSubmit = this.editSubmit.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.initialAddProduct = this.state.editProduct;
  }
  addProduct = (e) => {
    e.preventDefault();
    let input = {};
    input = this.state.editProduct;
    if (input.subCategory === "") return alert("Select sub Category");

    if (input.productQty !== "" && input.productQty !== undefined) {
    } else {
      return alert("Enter Product Qty");
    }
    input.subCategory = input.subCategory.toLowerCase();
    input.unitOfMeasurment = "NA";
    input.group = "RESTAURANT";
    input.category = "RESTAURANT";
    input.productMrp = input.productMrp.toString();
    input.inventoryQty = 1;
    input.productQty = input.productQty.toString();

    if (input.productPrice === "TBD") {
      alert("Enter Seller Price");
      return false;
    }
    if (input.productPrice === "") {
      alert("Enter Seller Price");
      return false;
    }
    input.productMrp = input.productPrice.toString();
    input.productPrice = input.productPrice.toString();

    if (parseInt(input.inventoryQty) <= 0) {
      alert("Inventory Quantity must be greater than 1");
      return false;
    }
    input.shopId = this.state.userInformation.userId;
    input.inventoryQty = parseInt(input.inventoryQty);

    Promise.all([
      fetch(`${config.ApiGateway}/addInventoryItem`, {
        method: "POST",
        body: JSON.stringify({ productList: [input] }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        Promise.all([
          fetch(
            `${config.ApiGateway}/viewShopAddedProducts`,

            {
              method: "POST",

              body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          ),
        ])
          .then(([res1]) => Promise.all([res1.json()]))
          .then(([data]) => {
            this.hideModal();
            this.setState({
              userInformation: data.userDetails[0],
              productDetails: data.results,
              getShopDetails: false,
              shopId: data.userId,
              editProduct: this.initialAddProduct,
            });
          })
          .catch((err) => console.log(err));
      })

      .catch((err) => {
        alert("Product Not Updated");
        console.log(err);
      });
  };

  editSubmit(newObj) {
    let input = {};
    input = newObj;

    if (input.measurementValue !== "" && input.measurementValue !== undefined) {
    } else {
      return alert("Enter measurement value");
    }
    if (input.productMrp !== "" && input.productMrp !== undefined) {
    } else {
      return alert("Enter Product Mrp");
    }
    if (input.productQty !== "" && input.productQty !== undefined) {
    } else {
      return alert("Enter Product Qty");
    }

    input.unitOfMeasurment =
      input.measurementValue + " " + input.measurementUnit;
    input.productMrp = input.productMrp.toString();

    input.productQty = input.productQty.toString();

    delete input.measurementUnit;
    delete input.measurementValue;

    if (input.productPrice === "TBD") {
      alert("Enter Seller Price");
      return false;
    }
    if (input.productPrice === "") {
      alert("Enter Seller Price");
      return false;
    }
    input.productPrice = input.productPrice.toString();

    if (parseInt(input.inventoryQty) <= 0) {
      alert("Inventory Quantity must be greater than 1");
      return false;
    }

    input.inventoryQty = parseInt(input.inventoryQty);

    Promise.all([
      fetch(`${config.ApiGateway}/shopEditProducts`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        Promise.all([
          fetch(
            `${config.ApiGateway}/viewShopAddedProducts`,

            {
              method: "POST",

              body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          ),
        ])
          .then(([res1]) => Promise.all([res1.json()]))
          .then(([data]) => {
            this.setState({
              userInformation: data.userDetails[0],
              productDetails: data.results,
              getShopDetails: false,
              shopId: data.userId,
            });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  makeAllLive(object) {
    let results = object.map((elements) => {
      let appendObject = {};
      appendObject = elements;
      appendObject.inventoryQty = 10;
      appendObject.productPrice = "100";
      return appendObject;
    });

    Promise.all([
      fetch(`${config.ApiGateway}/shopEditProducts`, {
        method: "POST",
        body: JSON.stringify(results),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);

        Promise.all([
          fetch(
            `${config.ApiGateway}/viewShopAddedProducts`,

            {
              method: "POST",

              body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          ),
        ])
          .then(([res1]) => Promise.all([res1.json()]))
          .then(([data]) => {
            this.setState({
              userInformation: data.userDetails[0],
              productDetails: data.results,
              hDetails: false,
              shopId: data.userId,
            });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteProduct = (productId) => {
    let filterProduct = this.state.productDetails.find(
      (items) => items.productId === productId
    );

    Promise.all([
      fetch(`${config.ApiGateway}/deleteMasterProducts`, {
        method: "POST",
        body: JSON.stringify({
          productId: filterProduct.productId,
          versionId: filterProduct.versionId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        Promise.all([
          fetch(
            `${config.ApiGateway}/viewShopAddedProducts`,

            {
              method: "POST",

              body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          ),
        ])
          .then(([res1]) => Promise.all([res1.json()]))
          .then(([data]) => {
            this.setState({
              userInformation: data.userDetails[0],
              productDetails: data.results,
              hDetails: false,
              shopId: data.userId,
            });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => alert(err));
  };
  editProduct = (productId) => {
    let filterProduct = this.state.productDetails.find(
      (items) => items.productId === productId
    );
    let editObject = {};
    Object.keys(filterProduct).forEach((elements) => {
      editObject[elements] = filterProduct[elements];
    });

    this.setState({
      show: true,
      editProduct: editObject,
    });
  };
  hideModal = () => {
    this.setState({
      show: false,
      edit: false,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      page: 0,
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  getShop(e) {
    e.preventDefault();

    Promise.all([
      fetch(
        `${config.ApiGateway}/viewShopAddedProducts`,

        {
          method: "POST",

          body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        this.setState({
          userInformation: data.userDetails[0],
          productDetails: data.results,
          getShopDetails: false,
          shopId: data.userId,
        });
      })
      .catch((err) => console.log(err));
  }
  subOnChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    let subList = [];

    Object.entries(this.state.productsJson.groups).map(
      ([elements, headers]) => {
        console.log(elements, this.state.editProduct.group);
        if (elements === this.state.editProduct.group) {
          Object.entries(headers.category).forEach(([items, values]) => {
            if (items === value) {
              values.subCategory.map(
                (sub) =>
                  sub !== "ALL" &&
                  subList.push(<option value={sub}>{sub}</option>)
              );
            }
          });
        }
      }
    );

    this.setState({
      editProduct: {
        ...this.state.editProduct,
        [e.target.name]: e.target.value,
      },
      subList: subList,
    });
  }
  categoryOnchange(e) {
    let name = e.target.name;
    let value = e.target.value;
    let categoryList = [];
    Object.entries(this.state.productsJson.groups).map(
      ([elements, headers]) => {
        if (elements === value) {
          Object.entries(headers).map(([items, category]) => {
            if (items === "category") {
              Object.keys(category).map((getCategory) => {
                categoryList.push(
                  <option value={getCategory}>{getCategory}</option>
                );
              });
            }
          });
        }
      }
    );
    this.setState({
      editProduct: {
        ...this.state.editProduct,
        [e.target.name]: e.target.value,
      },
      categoryList: categoryList,
    });
  }
  componentDidMount = async () => {
    try {
      const response = await fetch(`${config.json.URL}/productCategory.json`);
      var productsJson = await response.json();
      const fields = await fetch(`${config.json.URL}/fields.json`);
      var fieldsJSON = await fields.json();
    } catch (error) {
      console.log(error);
    }

    let categoryDropdown = [];
    let groupDropdown = [];
    let subDropdown = [];
    let unitList = [];

    fieldsJSON.unitOfMesurement.map((elements) =>
      unitList.push(<option value={elements}>{elements}</option>)
    );
    Object.entries(productsJson).forEach(([key, value]) => {
      Object.values(value).forEach((key1) => {
        groupDropdown.push(<option value={key1.name}>{key1.name}</option>);
        Object.values(key1.category).forEach((key2) => {
          Object.values(key2.subCategory).forEach((key3) => {
            subDropdown.push(<option value={key3}>{key3}</option>);
          });

          categoryDropdown.push(<option value={key2.name}>{key2.name}</option>);
        });
      });
    });

    let groupList = [];
    Object.entries(productsJson.groups).forEach((elements) => {
      groupList.push(
        <option value={elements[1].name}>{elements[1].name}</option>
      );
    });

    this.setState({
      groupDropdown: groupDropdown,
      categoryDropdown: categoryDropdown,
      subDropdown: subDropdown,
      groupList: groupList,
      productsJson: productsJson,
      unitList: unitList,
    });
  };
  editonChange = (e) => {
    this.setState({
      editProduct: {
        ...this.state.editProduct,
        [e.target.name]: e.target.value,
      },
    });
  };
  render() {
    const { classes } = this.props;

    const PRODUCTS = this.state.productDetails
      .filter((items) =>
        this.state.category === items.category
          ? items.category
          : this.state.category === "none"
          ? items
          : null
      )
      .filter((items) =>
        this.state.selectSubCategory === items.subCategory
          ? items.subCategory
          : this.state.selectSubCategory === "none"
          ? items
          : null
      )
      .filter((items) =>
        this.state.selectGroup === items.group
          ? items.group
          : this.state.selectGroup === "none"
          ? items
          : null
      )
      .filter((items) =>
        this.state.chooseStatus === items.status
          ? items.status
          : this.state.chooseStatus === "All"
          ? items
          : null
      );

    return (
      <Container fluid>
        <div
          style={{ width: "100%", display: "inline-block", height: 10 + "px" }}
        >
          {" "}
        </div>
        <h3 style={{ textAlign: "center" }}>
          <b>View Shop added product</b>
        </h3>
        <div
          style={{ width: "100%", display: "inline-block", height: 10 + "px" }}
        >
          {" "}
        </div>
        {this.state.getShopDetails ? (
          <>
            <Row>
              <Col sm={{ span: 8, offset: 3 }}>
                <Form onSubmit={this.getShop}>
                  <Row style={{ marginTop: 10 + "%" }}>
                    <Col md={{ span: 7, offset: 2 }}>
                      <Form.Label>Enter Shop Id</Form.Label>
                      <Form.Control
                        pattern="^[0-9]{7,13}"
                        name="userId10Digit"
                        onChange={this.onChange}
                        value={this.state.userId10Digit}
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "15px" }}>
                    <Col md={{ span: 7, offset: 2 }}>
                      <Button type="submit" style={{ float: "right" }}>
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col style={{ margin: "0 auto" }} sm="10">
                <ShopProfilecard shopInfo={this.state.userInformation} />
              </Col>
              <Col style={{ margin: "0 auto" }} sm="10">
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "inline-block",
                      height: 10 + "px",
                    }}
                  >
                    {" "}
                  </div>
                  <div
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%",
                    }}
                  >
                    <h4 style={{ textAlign: "center" }}> Product List</h4>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "inline-block",
                      height: 10 + "px",
                    }}
                  >
                    {" "}
                  </div>
                  <Row
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {this.state.userInformation["shopType"] == "Restaurant" && (
                      <>
                        <Button
                          onClick={() => {
                            this.setState({
                              show: true,
                            });
                          }}
                        >
                          Add Product
                        </Button>
                      </>
                    )}

                    <Link
                      to={{
                        pathname: "/addInventory",
                        search: `?shopId=${this.state.userId10Digit}`,
                      }}
                    >
                      <Button>Add New Inventory Products in CSV</Button>
                    </Link>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <Form.Label>Group </Form.Label>
                      <Form.Control
                        as="select"
                        name="selectGroup"
                        onChange={this.onChange}
                        value={this.state.selectGroup}
                      >
                        <option value="">select Group</option>
                        <option value="none">None</option>
                        {this.state.groupDropdown}
                      </Form.Control>
                    </Col>
                    <Col sm={3}>
                      <Form.Label>Category</Form.Label>
                      <Form.Control
                        as="select"
                        name="category"
                        value={this.state.category}
                        onChange={this.onChange}
                      >
                        <option value="">select Category</option>
                        <option value="none">None</option>
                        {this.state.categoryDropdown}
                      </Form.Control>
                    </Col>
                    <Col sm={3}>
                      <Form.Label>Sub Category</Form.Label>
                      <Form.Control
                        as="select"
                        name="selectSubCategory"
                        value={this.state.selectSubCategory}
                        onChange={this.onChange}
                      >
                        {" "}
                        <option value="">select Sub Category</option>
                        <option value="none">None</option>
                        {this.state.subDropdown}
                      </Form.Control>
                    </Col>
                    <Col sm={3}>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="chooseStatus"
                        value={this.state.chooseStatus}
                        onChange={this.onChange}
                      >
                        {" "}
                        <option value="ported">Ported</option>
                        <option value="live">Live</option>
                        <option value="All">All</option>
                      </Form.Control>
                    </Col>
                  </Row>

                  <div
                    style={{
                      display: "inline-block",
                      width: "100%",
                      height: 10 + "px",
                    }}
                  ></div>

                  <MaterialTable
                    title="Product List"
                    columns={[
                      {
                        title: "Group",
                        field: "group",
                        editable: "never",
                        hidden:
                          this.state.userInformation["shopType"] ==
                          "Restaurant",
                      },
                      {
                        title: "Category",
                        field: "category",
                        editable: "never",
                        hidden:
                          this.state.userInformation["shopType"] ==
                          "Restaurant",
                      },
                      {
                        title: "Sub Category",
                        field: "subCategory",
                        editable: "never",
                      },
                      {
                        title: " Inventory Quantity",
                        field: "inventoryQty",
                        hidden:
                          this.state.userInformation["shopType"] ==
                          "Restaurant",
                      },

                      { title: "Selling Price ₹", field: "productPrice" },

                      {
                        title: "Brand",
                        field: "productBrand",
                        hidden:
                          this.state.userInformation["shopType"] ==
                          "Restaurant",
                      },

                      {
                        title: "Product Name",
                        field: "productName",
                        hidden:
                          this.state.userInformation["shopType"] ==
                          "Restaurant",
                      },
                      // {
                      //   title: "Unit Of Measurement",
                      //   field: "unitOfMeasurment",
                      // },
                      {
                        title: "Measurement value",
                        field: "measurementValue",
                        hidden:
                          this.state.userInformation["shopType"] ==
                          "Restaurant",
                        render: (rowData) => {
                          let measurementValue = "NA";
                          if (typeof rowData.unitOfMeasurment != "undefined") {
                            let tempValue = rowData.unitOfMeasurment.split(" ");
                            measurementValue = tempValue[0];
                          }

                          return <td>{measurementValue}</td>;
                        },
                      },

                      {
                        title: "Measurement Unit",
                        field: "measurementUnit",
                        hidden:
                          this.state.userInformation["shopType"] ==
                          "Restaurant",
                        render: (rowData) => {
                          let measurementUnit = "NA";
                          if (typeof rowData.unitOfMeasurment != "undefined") {
                            const tempValue = rowData.unitOfMeasurment.split(
                              " "
                            );
                            measurementUnit = tempValue[1];
                          }
                          return <td>{measurementUnit}</td>;
                        },
                        editComponent: (props) => {
                          const measurementUnit =
                            props.rowData.unitOfMeasurment.split(" ") || "NA";

                          return (
                            <select
                              name="measurementUnit"
                              value={props.value || measurementUnit[1]}
                              onChange={(e) => props.onChange(e.target.value)}
                            >
                              <select value="">Select Unit</select>
                              {this.state.unitList}
                            </select>
                          );
                        },
                      },

                      {
                        title: "Product Quantity ",
                        field: "productQty",
                        hidden:
                          this.state.userInformation["shopType"] ==
                          "Restaurant",
                      },

                      {
                        title: "Status",
                        field: "status",
                        editable: "never",
                      },
                      {
                        title: "MRP ₹",
                        field: "productMrp",
                        hidden:
                          this.state.userInformation["shopType"] ==
                          "Restaurant",
                      },
                      {
                        title: "Latest",
                        field: "latest",
                        editable: "never",
                      },
                      {
                        title: "Veg/ Non Veg",
                        field: "vegNonVeg",
                        editable: "always",
                        editComponent: (props) => (
                          <select
                            type="text"
                            value={props.value}
                            onChange={(e) => props.onChange(e.target.value)}
                          >
                            <option value="Veg">Vegeterian</option>
                            <option value="Non-Veg">Non Vegterian</option>
                          </select>
                        ),
                      },

                      {
                        title: "Version ID",
                        field: "versionId",
                        editable: "never",
                      },
                      {
                        title: "Master Id",
                        field: "masterId",
                        editable: "never",
                      },
                      {
                        title: "Product ID",
                        field: "productId",
                        editable: "never",
                      },
                      {
                        title: "Date",
                        field: "addedDate",
                        editable: "never",
                      },
                      {
                        title: "Ean Code",
                        field: "productEANCode",
                        editable: "always",
                      },
                    ]}
                    data={PRODUCTS}
                    options={{
                      pageSize: 30,
                      pageSizeOptions: [5, 10, 20, 100, 300, 500],
                      grouping: false,
                      filtering: false,
                    }}
                    editable={{
                      isEditable: (rowData) => rowData.productId,

                      isDeletable: (rowData) => rowData.productId, // only group(b) rows would be deletable

                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          setTimeout(() => {
                            if (newData.measurementUnit === undefined) {
                              let temp = newData.unitOfMeasurment.split(" ");

                              newData.measurementUnit = temp[1] || "g";
                            }
                            if (newData.measurementValue === undefined) {
                              let temp = newData.unitOfMeasurment.split(" ");
                              newData.measurementValue = temp[0];
                            }
                            this.editSubmit(newData);

                            resolve();
                          }, 1000);
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                          setTimeout(() => {
                            this.deleteProduct(oldData.productId);
                            resolve();
                          }, 1000);
                        }),
                    }}
                  />
                </>
              </Col>
            </Row>
            <Modal
              show={this.state.show}
              onHide={() => this.hideModal()}
              size="lg"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Add Product
                </Modal.Title>
              </Modal.Header>
              <Form onSubmit={this.addProduct}>
                <Modal.Body>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Sub Category
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        onChange={this.editonChange}
                        name="subCategory"
                        value={this.state.editProduct.subCategory}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Product Description
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        onChange={this.editonChange}
                        name="productName"
                        required
                        value={this.state.editProduct.productName}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Quantity
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        type="number"
                        onChange={this.editonChange}
                        name="productQty"
                        required
                        value={this.state.editProduct.productQty}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Selling Price (₹)
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        type="text"
                        onChange={this.editonChange}
                        name="productPrice"
                        required
                        value={this.state.editProduct.productPrice}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Food Type
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        as="select"
                        required
                        onChange={this.editonChange}
                        name="vegNonVeg"
                        value={this.state.editProduct.vegNonVeg}
                      >
                        <option value="">Select Veg Or Non Veg</option>
                        <option value="Veg">Vegeterian</option>
                        <option value="Non-Veg">Non Vegterian</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  <div className="vertical-space-10"></div>

                  <div className="vertical-space-10"></div>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit">Add Product</Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        )}
      </Container>
    );
  }
}
ViewShopAddedProduct.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewShopAddedProduct);
