import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import Badge from "react-bootstrap/Badge";
import config from "../../config";

export default function Orders({ profile }) {
  console.log(profile, "orders porfile");
  const [order, setOrder] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    getOrders();
  }, [profile]);
  const getOrders = async () => {
    // get order for all linked shops
    let { userId } = profile;
    let data = await (
      await fetch(`${config.ApiGateway}/franchiseOrderManagement`, {
        method: "POST",
        body: JSON.stringify({
          primaryFranchiseUserId: userId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();

    if (data.status) {
      if (data.payloadResponseExceededLimit) {
        try {
          let uploadData = await (await fetch(data.data)).json();
          setTableLoading(false);
          setOrder(uploadData || []);
        } catch (err) {
          setOrder([]);
          alert("Fetch Failed");
        }
      } else {
        setTableLoading(false);
        setOrder(data.data || []);
      }

      setTableLoading(false);
    }
  };

  const findOrderStatus = (orderState) => {
    switch (orderState) {
      case "complete":
        return "Delivered";

      case "startPackaging":
        return "Order Accepted";

      case "placed":
        return "new Order Placed";

      case "readyForDelivery":
        return "Ready for Delivery";

      case "rejected":
        return "Rejected";
      case "readyForPickup":
        return "Ready for Pick-up";
      default:
        return "order status not captured";
    }
  };

  return (
    <>
      <h1>orders</h1>
      <div className="vertical-space-40"></div>
      <MaterialTable
        title={null}
        editable={{}}
        columns={[
          {
            title: "Order Id",
            field: "orderId",
          },
          {
            title: "ghoshak Id",
            field: "g",
          },
          { title: "Shop Name", field: "shopName" },
          { title: "Billing Date", field: "billingDate" },
          { title: "Del Method", field: "deliveryMethod" },
          {
            title: "Payment Method",
            field: "paymentMethod",
          },
          // {
          //   title: "seller ID",
          //   field: "sellerId",
          // },
          {
            title: "Payment Status",
            field: "paymentStatus",
          },
          { title: "Total Amt", field: "totalAmount" },
          {
            title: "Cust Details",
            field: "totalAmount",
            render: (rowData) =>
              `${rowData.customerName ?? ""} - ${
                rowData.customerPhoneNumber ?? ""
              }`,
          },
          { title: "Total Amt", field: "totalAmount" },
          {
            title: "Order Status",
            field: "status",
            render: (rowData) => findOrderStatus(rowData.status),
          },
        ]}
        data={order}
        options={{
          pageSizeOptions: [10, 20, 100, 500, 1000],

          exportButton: false,
          rowStyle: {
            fontSize: 12,
          },
          headerStyle: {
            fontSize: 12,
          },
        }}
        isLoading={tableLoading}
      />
    </>
  );
}
