import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import S3 from "react-aws-s3";
import uuid from "uuid/dist/v4";
import config from "../../config";
import Youtube from "react-youtube";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const VideoGallery = (props) => {
  const ref = useRef();
  const [header, setHeader] = useState("");
  const [description, setDescription] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [websiteTemplate, setWebsiteTemplate] = useState();
  const [videos, setVideos] = useState([]);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [from, setFrom] = useState("");

  const [active, setActive] = useState(true);
  const [templateId, setTemplateId] = useState("videoGallery_001");
  const [templateName, setTemplateName] = useState("Video Landing");

  const fetchDetails = async () => {
    await fetchUserDetails(props.shopId).then((data) => {
      var shopData = data[0];
      if (shopData.websiteTemplate) {
        setWebsiteTemplate(shopData.websiteTemplate);
        var websiteData = shopData.websiteTemplate;
        if (websiteData.videoGallery) {
          setActive(
            websiteData.videoGallery.active
              ? websiteData.videoGallery.active
              : active
          );
          setTemplateId(
            websiteData.videoGallery.templateId
              ? websiteData.videoGallery.templateId
              : templateId
          );
          setTemplateName(
            websiteData.videoGallery.templateName
              ? websiteData.videoGallery.templateName
              : templateName
          );
          if (websiteData.videoGallery.pageProps.cardData) {
            if (websiteData.videoGallery.pageProps.cardData.length > 0) {
              setVideos(websiteData.videoGallery.pageProps.cardData);
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    fetchDetails();
  }, [props]);

  const handleEdit = (data) => {
    setHeader(data.heading);
    setDescription(data.description);
    setVideoUrl(data.videoUrl);
    setId(data.id);
    setModal(true);
    setFrom("update");
  };

  const handleUpdate = async () => {
    if (videoUrl === "") return "Enter Video Url";
    var filteredVideos = videos.filter((data) => data.id !== id);

    var updateObj = {
      ...websiteTemplate,
      videoGallery: {
        ...websiteTemplate.videoGallery,
        pageProps: {
          cardData: [
            ...filteredVideos,
            {
              description: description,
              id: id,
              heading: header,
              videoUrl: videoUrl,
            },
          ],
        },
      },
    };
    await contentupload(updateObj, props.shopId).then(async (data) => {
      setModal(false);
      setHeader("");
      setDescription("");
      setVideoUrl("");
      setId("");
      await fetchDetails();
    });
  };

  const handleDelete = async (key) => {
    var filteredVideos = videos.filter((data) => data.id !== key.id);
    var dataObj = {
      ...websiteTemplate,
      videoGallery: {
        ...websiteTemplate.videoGallery,
        pageProps: {
          cardData: [...filteredVideos],
        },
      },
    };
    await contentupload(dataObj, props.shopId).then(async (data) => {
      setVideos([]);
      await fetchDetails();
    });
  };

  const handleAdd = async () => {
    var dataObj = {};
    if (websiteTemplate) {
      dataObj = {
        ...websiteTemplate,
      };
      if (videos.length > 0) {
        dataObj = {
          ...dataObj,
          videoGallery: {
            ...websiteTemplate.videoGallery,
            active: active,
            templateId: templateId,
            templateName: templateName,
            pageProps: {
              cardData: [
                ...videos,
                {
                  description: description,
                  id: uuid(),
                  heading: header,
                  videoUrl: videoUrl,
                },
              ],
            },
          },
        };
      } else {
        dataObj = {
          ...dataObj,
          videoGallery: {
            active: active,
            templateId: templateId,
            templateName: templateName,
            pageProps: {
              cardData: [
                ...videos,
                {
                  description: description,
                  id: uuid(),
                  heading: header,
                  videoUrl: videoUrl,
                },
              ],
            },
          },
        };
      }
    } else {
      dataObj = {
        colorTheme: "default",
        order: [
          "aboutUs",
          "about",
          "onlineProduct",
          "ourServices",
          "ourProjects",
          "ourBrands",
          "ourClients",
          "ourGallery",
          "clientTestimonials",
          "videoGallery",
          "ourPartner",
          "socialMedia",
          "ourTeam",
        ],
        videoGallery: {
          pageProps: {
            cardData: [
              {
                description: description,
                id: uuid(),
                heading: header,
                videoUrl: videoUrl,
              },
            ],
          },
          active: active,
          templateId: templateId,
          templateName: templateName,
        },
      };
    }
    await contentupload(dataObj, props.shopId).then(async () => {
      await fetchDetails();
      setHeader("");
      setDescription("");
      setVideoUrl("");
      setId("");
      setModal(false);
    });
  };

  const handleActiveTemplateChange = async (t, n, a) => {
    var updateObj = {
      ...websiteTemplate,
      videoGallery: {
        ...websiteTemplate.videoGallery,
        active: a,
        templateId: t,
        templateName: n,
        pageProps: {
          cardData: [...videos],
        },
      },
    };
    await contentupload(updateObj, props.shopId).then(async () => {
      fetchDetails();
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <h4>Video Gallery</h4>
      <div>
        {videos.length > 0 && (
          <>
            <div>
              <strong>
                <label>Show Video Gallery</label>
              </strong>{" "}
              <FormControlLabel
                control={
                  <Switch
                    checked={active}
                    onChange={(e) => {
                      setActive(!active);
                    }}
                    color="primary"
                    value={active}
                  />
                }
              ></FormControlLabel>
            </div>
            <div>
              <strong>
                <label>Choose Template</label>
              </strong>
              <br />
              <select
                onChange={(e) => {
                  setTemplateId(e.target.value);
                  setTemplateName(e.target.name);
                }}
                value={templateId}
                style={{
                  width: "200px",
                }}
              >
                <option value="" disabled>
                  Select a template
                </option>
                <option value="videoGallery_001" name="Video Landing">
                  Video Landing
                </option>
                <option value="videoGallery_002" name="Parallax scroll">
                  Parallax scroll
                </option>
              </select>{" "}
            </div>
            <br />
            <Button
              onClick={() => {
                handleActiveTemplateChange(templateId, templateName, active);
              }}
            >
              Save
            </Button>
          </>
        )}
      </div>
      <br />
      <Button
        onClick={() => {
          setFrom("add");
          setModal(true);
        }}
      >
        Add Video
      </Button>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {videos.map((data) => {
          var tempArr = data.videoUrl.split("/");
          var vidId = tempArr[tempArr.length - 1];
          return (
            <div
              className="ourServicesCard"
              key={data.id}
              style={{
                margin: "10px",
                width: "30%",
                boxShadow: "5px 5px 20px 1px #d4d4d4",
                borderRadius: "5px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <Youtube
                    opts={{ width: "150", height: "150" }}
                    videoId={vidId}
                  />
                  {/* <iframe
                    type="text/html"
                    width={150}
                    height={150}
                    src={data.videoUrl}
                  ></iframe> */}
                </div>
                <div>
                  <div style={{ marginLeft: "5px" }}>
                    <strong>
                      <p>{data.heading}</p>
                    </strong>
                    <p>{data.description}</p>
                  </div>
                  <div style={{ margin: "5px" }}>
                    <Button
                      variant="outline-info"
                      onClick={() => handleEdit(data)}
                      size="sm"
                    >
                      Edit
                    </Button>{" "}
                    <Button
                      variant="outline-danger"
                      onClick={() => handleDelete(data)}
                      size="sm"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        show={modal}
        onHide={() => {
          setHeader("");
          setDescription("");
          setVideoUrl("");
          setId("");
          setModal(!modal);
        }}
        centered
      >
        <Modal.Header>
          {from === "update" ? <h4>Update Project</h4> : <h4>Add Project</h4>}
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <div>
              <strong>
                <label>Enter Video Url: </label>
              </strong>
              <br />
              <textarea
                placeholder="Enter Video Url"
                onChange={(e) => setVideoUrl(e.target.value)}
                value={videoUrl}
                rows="1"
                cols="40"
              />{" "}
            </div>
            <div>
              <strong>
                <label>Enter Video Header: </label>
              </strong>
              <br />
              <textarea
                placeholder="Enter Video header"
                onChange={(e) => setHeader(e.target.value)}
                value={header}
                rows="3"
                cols="40"
              />{" "}
            </div>
            <div style={{}}>
              <strong>
                <label>Enter Video description: </label>
              </strong>
              <br />
              <textarea
                placeholder="Enter Video description"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                rows="5"
                cols="40"
              />{" "}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant={from === "update" ? "outline-info" : "outline-warning"}
            onClick={
              from === "update" ? () => handleUpdate() : () => handleAdd()
            }
          >
            {from === "update" ? <>update</> : <>Add</>}
          </Button>
          <Button
            onClick={() => {
              setHeader("");
              setDescription("");
              setVideoUrl("");
              setId("");
              setModal(!modal);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VideoGallery;

const fetchUserDetails = async (shopId) => {
  if (shopId === "") return alert("Enter valid Shop Id");
  return await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
      method: "POST",
      body: JSON.stringify({
        userId10Digit: shopId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data[0].websiteTemplate);
      return data;
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("Something went wrong, please try again");
    });
};

const contentupload = async (obj, userId) => {
  await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainContentUpload`, {
      method: "POST",
      body: JSON.stringify({
        obj: obj,
        userId: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data);
      alert("uploaded success");
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("error while uploading data. Please try again");
    });
};

const handleImageUpload = async (image) => {
  const configSettings = {
    bucketName: "ghoshak-website-builder",
    region: "ap-south-1",
    accessKeyId: config.AccessKeyId,
    secretAccessKey: config.SecretAccessKey,
  };
  var fileName = uuid() + ".jpeg";
  var S3Client = new S3(configSettings);
  let data = await S3Client.uploadFile(image, fileName)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("S3 Error", err);
    });
  return data;
};
