import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import config from "../config";
export default class CustomerDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      segmentDetails: [],
      show: false,
      segment: {},

      startDate: undefined,
      endDate: undefined,

      headers: [],
      orderBy: false
    };
    this.sortByDate = this.sortByDate.bind(this);
    this.getDailyStatus = this.getDailyStatus.bind(this);
    this.csvLink = React.createRef();
    this.export = this.export.bind(this);
    this.getSearchResult = this.getSearchResult.bind(this);
    this.sortByShop = this.sortByShop.bind(this);
  }
  export() {
    const headers = [
      { label: "PINCODE", key: "pincode" },
      { label: "PLACE", key: "place" },
      { label: "TOTAL CUSTOMERS", key: "Customer" }
    ];

    this.setState(
      {
        headers: headers
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  sortByShop(orderBy) {
    var value = !orderBy;
    var sort = this.state.segmentDetails.sort((a, b) =>
      this.state.orderBy ? b.Customer - a.Customer : a.Customer - b.Customer
    );

    this.setState({
      shopkeeperDetails: sort,
      orderBy: !this.state.orderBy
    });
  }
  getSearchResult(e) {
    e.preventDefault();
    this.setState({
      show: false
    });
    Promise.all([
      fetch(
        `${config.ApiGateway}/customerDistribution`,

        {
          method: "POST",
          body: JSON.stringify({
            startDate: this.state.startDate,
            endDate: this.state.endDate
          }),

          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        return this.setState({
          segmentDetails: data,
          show: true
        });
      })
      .catch(err => alert("function call failed"));
  }

  sortByDate(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  async componentDidMount() {
    try {
      const response = await fetch(
        `${config.json.URL}/segmentdistribution.json`
      );
      const json = await response.json();

      this.setState({ segment: json });
    } catch (error) {
      console.log(error);
    }

    await this.getDailyStatus();
  }
  getDailyStatus() {
    Promise.all([
      fetch(
        `${config.ApiGateway}/customerDistribution`,

        {
          method: "POST",
          body: JSON.stringify({
            startDate: this.state.startDate,
            endDate: this.state.endDate
          }),

          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        var sortOrder = data.sort((a, b) => b.Customer - a.Customer);
        return this.setState({
          segmentDetails: sortOrder,
          show: true
        });
      })
      .catch(err => alert("function call failed"));
  }
  render() {
    const { segment } = this.state;
    return (
      <Container>
        {this.state.show ? (
          <>
            <Row>
              <Col>
                <div className="headerName">
                  <h3 style={{ textAlign: "center", marginBottom: 3 + "%" }}>
                    {" "}
                    CUSTOMER DISTRIBUTION{" "}
                  </h3>
                </div>
                <Form onSubmit={this.getSearchResult}>
                  <Form.Group controlId="formBasicEmail">
                    <Row>
                      <Form.Label>START DATE</Form.Label>
                      <Col>
                        <Form.Control
                          type="date"
                          name="startDate"
                          value={this.state.startDate}
                          onChange={this.sortByDate}
                        />
                      </Col>
                      <Form.Label>END DATE </Form.Label>
                      <Col>
                        <Form.Control
                          type="date"
                          name="endDate"
                          value={this.state.endDate}
                          onChange={this.sortByDate}
                        />
                      </Col>

                      <Col>
                        <Button type="submit">SEARCH</Button>
                      </Col>
                      <Col>
                        <Button
                          onClick={() => this.sortByShop(this.state.orderBy)}
                        >
                          SORT
                        </Button>
                      </Col>
                      <Col md={{}}>
                        <Button
                          onClick={() => {
                            this.export();
                          }}
                        >
                          DOWNLOAD
                        </Button>
                        <CSVLink
                          enclosingCharacter={`'`}
                          data={this.state.segmentDetails}
                          headers={this.state.headers}
                          ref={this.csvLink}
                          target="_blank"
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid"
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ textAlign: "center" }}>
                        S.NO
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        PINCODE
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        PLACE
                      </TableCell>

                      <TableCell style={{ textAlign: "center" }}>
                        TOTAL CUSTOMERS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.segmentDetails
                      .filter(items => parseInt(items.Customer) !== 0)
                      .map((elements, index) => (
                        <TableRow>
                          <TableCell style={{ textAlign: "center" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {elements.pincode}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {elements.place !== undefined
                              ? elements.place
                              : "Not found"}
                          </TableCell>

                          <TableCell
                            style={{
                              textAlign: "center",
                              backgroundColor:
                                elements.Customer <
                                parseInt(segment.Customer.Minimum)
                                  ? segment.Customer.Minimumcolor
                                  : segment.Customer.Maximumcolor
                            }}
                          >
                            {elements.Customer}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Col>
            </Row>
          </>
        ) : (
          "LOADING"
        )}
      </Container>
    );
  }
}
