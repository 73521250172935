import React, { useState, useEffect, useRef, useCallback } from "react";
import MaterialTable from "material-table";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import ShopProfile from "../shopProfile/shopProfile";
import config from "../config";
import { Auth } from "aws-amplify";
// import TelecallingJSON from "./telecalling.json";
import moment from "moment";
import "./telecalling.css";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "@material-ui/lab/Skeleton";
import { useMemo } from "react";
let initialState = {
  followUpStatus: "",
  followUpDate: "",
  followUpTime: "",
  potentialForPayment: "no",
  notes: "",
  sourceType: "",
  shopId: "",
  telecallerName: "",
};
export default function Home() {
  // Initialize State
  const [tableFilter, setTableFilter] = useState({});
  const [currentUser, setCurrentUser] = useState(""); // Logged in user name
  const [state, setState] = useState(initialState);
  const filterByDownload = useRef([]);
  const [shop, setShops] = useState([]);
  const [shopDetails, setShopDetails] = useState({});
  const [modalShow, setModalShow] = useState(false); // Shop Profile Modal
  const [showReport, setShowReport] = useState(false); // show add fields for report
  const [telecallingDropdownFields, setTelecallingDropdownFields] = useState(
    []
  ); // status option arrray
  const [sourceTypeDropdownFields, setSourceTypeDropdownFields] = useState([]); // source type option arrray
  const [telecallerNameDropdownFields, setTelecallerNameDropdownFields] =
    useState([]); // telecaller name option arrray
  const [isLoading, setIsLoading] = useState(true);
  const [monthYearDropdown, setMonthYearDropdown] = useState([]);
  const [filterByMonthyear, setFilterByMonthYear] = useState(
    moment().format("YYYY-MM")
  );
  const [filterby, setFilterby] = useState({
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().endOf("month").format("YYYY-MM-DD"),
  });
  const [filteredShops, setFilteredShops] = useState([]);
  const [assignStatus, setAssignStatus] = useState("assigned");
  const onChange = (name, value) => {
    // onchange event for add report  // parameter 1 - name & parameter 2 - value
    let objectName = name;
    let objectValue = value;
    setState((prevState) => ({
      ...prevState,
      [objectName]: objectValue,
    }));
  };
  useEffect(() => {
    async function getData() {
      // Get Current AUthenticated user name
      await Auth.currentAuthenticatedUser().then((data) => {
        setCurrentUser(data.attributes.phone_number);
      });

      const jsonRequest = await fetch(`${config.json.URL}/telecalling.json`);
      const TelecallingJSON = await jsonRequest.json();

      // Get follow up status from json file
      let telecallingOption = TelecallingJSON.callStatus.map((items) => (
        <option value={items}>{items.toUpperCase()}</option>
      ));

      // Get source type from json file
      let sourceTypeOption = TelecallingJSON.sourceType.map((items) => (
        <option value={items}>{items.toUpperCase()}</option>
      ));
      // Get telecaller name from json file
      let telecallerNameOption = TelecallingJSON.telecallerName.map((items) => (
        <option value={items}>{items.toUpperCase()}</option>
      ));

      let obj = {};
      TelecallingJSON.telecallerName.map((elements) => {
        obj = { ...obj, [elements]: elements.toUpperCase() };
      });

      let todayDate = new Date().toISOString().substring(0, 10);
      let dropdownStartDate = moment("2019-12-01");
      let dropdownEndDate = moment(todayDate);
      let monthYearRanges = [];
      while (
        dropdownEndDate > dropdownStartDate ||
        dropdownStartDate.format("M") === dropdownEndDate.format("M")
      ) {
        monthYearRanges.push(
          <option value={dropdownStartDate.format("YYYY-MM")}>
            {dropdownStartDate.format("MMM YYYY")}
          </option>
        );
        dropdownStartDate.add(1, "month");
      }
      monthYearRanges.sort((a, b) => b - a);

      setTelecallerNameDropdownFields(telecallerNameOption);
      setSourceTypeDropdownFields(sourceTypeOption);
      setTelecallingDropdownFields(telecallingOption);
      setMonthYearDropdown(monthYearRanges);

      setTableFilter(obj); // In table filter telecaller name
    }
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getShopsAndPending();
  }, [filterByMonthyear]);

  const getShop = async () => {
    return await new Promise((resolve) => {
      let dateRange =
        filterByMonthyear == "all"
          ? {
              startDate: "2019-12-12",
              endDate: new Date().toISOString().substring(0, 10),
            }
          : {
              startDate: new moment(filterByMonthyear)
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDate: new moment(filterByMonthyear)
                .endOf("month")
                .format("YYYY-MM-DD"),
            };
      Promise.all([
        fetch(`${config.ApiGateway}/getTelecallingShops`, {
          method: "POST",
          body: JSON.stringify({
            userType: "Shop",
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ])
        .then(([res1]) => Promise.all([res1.json()]))
        .then(([data]) => {
          console.log(data, "shop state");
          resolve(data);
        })
        .catch((err) => console.log("error getting mac address", err));
    });
  };

  const getShopsAndPending = async () => {
    let shopList = await getShop();
    setIsLoading(false);
    setShops(shopList || []);
  };

  const getShopDetails = async (shopId) => {
    // get shop details by user id
    await Promise.all([
      fetch(`${config.ApiGateway}/viewShopById`, {
        method: "POST",
        body: JSON.stringify({ shopId: shopId }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        setShopDetails(data);
        setModalShow(true);
      })
      .catch((err) => console.log(err));
  };
  const addReport = (e) => {
    // Add new Report

    e.preventDefault();
    let input = state;
    // Validation

    if (
      !["Accidental Download", "Fake Shop", "Paid"].includes(
        state.followUpStatus
      )
    ) {
      //Follow up date validation ignores if status has accidental, fake shop, paid status
      if (input.followUpDate === "") return alert("Select Follow Up Date");
    }
    if (input.sourceType === "") return alert("Select Source");
    if (input.telecallerName === "") return alert("Select Telecaller Name");
    Object.keys(input).forEach((key) => input[key] === "" && delete input[key]); // Removes Empty String and undefined values
    input.updatedBy = currentUser;
    input.template = "addTelecalling"; // checks condition in backend to add report
    Promise.all([
      fetch(`${config.ApiGateway}/telecallingStatus`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        if (data.status) {
          //  response from lambda if it is true, it executes
          setShowReport(false);
          alert(data.message);
          setState(initialState);
          await getShopsAndPending();
        }
      })
      .catch((err) => console.log(err));
  };

  const updateProfile = (value, rowData) => {
    let addTelecallerName = value;
    if (value != "Ignore") {
      Promise.all([
        fetch(`${config.ApiGateway}/telecallingStatus`, {
          method: "POST",
          body: JSON.stringify({
            shopId: rowData.userId,
            telecallerName: value,
            telecallerAssignedBy: currentUser,
            template: "assignTelecalling",
            ghoshakId: rowData.userId10Digit,
            shopPincode: rowData.userPincode,
            createdBy: currentUser,
            userId:
              rowData.followUpStatus == "pending"
                ? rowData.partnerTablePrimaryId
                : uuidv4(),
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ])
        .then(([res1]) => Promise.all([res1.json()]))
        .then(async ([data]) => {
          await getShopsAndPending();
        })
        .catch((err) => console.log(err));
    }
  };
  // console.log(filterby);

  const globalSearch = useCallback(() => {
    return shop.filter((items) =>
      assignStatus == "unassigned"
        ? !Boolean(items.telecallerName)
        : assignStatus == "assigned"
        ? Boolean(items.telecallerName)
        : assignStatus == "all"
        ? items
        : null
    );
  }, [shop, assignStatus, filterByMonthyear]);
  const columns = useMemo(
    () => [
      {
        filtering: false,
        title: "Shop Id",
        field: "userId10Digit",
      },
      {
        filtering: false,
        title: "Shop Name",
        field: "shopName",
      },
      {
        filtering: false,
        title: "Owner Name",
        field: "userName",
      },

      {
        filtering: false,
        title: "Shop Type",
        field: "shopType",
      },
      {
        filtering: false,
        title: "Address",
        field: "shopAddress",
      },

      {
        filtering: false,
        title: "Phone Number",
        field: "userPhoneNumber",
      },
      {
        filtering: false,
        title: "Pincode ",
        field: "userPincode",
      },

      {
        filtering: false,
        title: "Joined Ghoshak ",
        field: "userAddedDate",
      },
      {
        filtering: false,
        title: "Reffered By",
        field: "referredBy",
      },
      {
        title: "Last Updated Date",
        field: "lastUpdatedDate",
        filtering: false,
        render: (rowData) => {
          return rowData.userAddedDate;
        },
      },
      {
        title: "Assignee To",
        field: "telecallerName",
        filtering: true,
        lookup: tableFilter,
        render: (rowData) => {
          let finalValue = Boolean(rowData.telecallerName)
            ? rowData.telecallerName
            : "Ignore";

          return (
            <select
              onChange={(e) => updateProfile(e.target.value, rowData)}
              className="selectTelecallerDropdown"
              value={finalValue}
            >
              <option value={"Ignore"}>To Do</option>
              {telecallerNameDropdownFields}
            </select>
          );
        },
      },

      {
        title: "Action",
        field: "view",
        filtering: false,
        render: (rowData) => {
          return (
            <Button
              onClick={() => {
                getShopDetails(rowData.userId);
              }}
            >
              View Shop
            </Button>
          );
        },
      },
      {
        title: "Add Report",
        field: "view",
        filtering: false,
        render: (rowData) => {
          return (
            <Button
              onClick={() => {
                // Store state about shop information and display modal true
                setShowReport(true);
                onChange("shopId", rowData.userId);
                onChange("shopName", rowData.shopName);
                onChange("ghoshakId", rowData.userId10Digit);
                onChange("userPhoneNumber", rowData.userPhoneNumber);
                if (Boolean(rowData.telecallerName)) {
                  onChange("telecallerName", rowData.telecallerName);
                }
                if (rowData.followUpStatus == "pending") {
                  onChange("userId", rowData.partnerTablePrimaryId);
                } else {
                  onChange("userId", uuidv4());
                }
              }}
            >
              Add Report
            </Button>
          );
        },
      },
    ],
    [assignStatus, shop, filterByMonthyear]
  );
  return (
    <div className="flexContainer">
      <div className="rowBox">
        <div style={{ paddingLeft: "20px" }} className="orderHeader">
          <h2 className="orderHeaderText">Shops</h2>
        </div>
      </div>

      {/* Display all B2B Shops */}
      <div className="filterByShopsDownloadDate">
        <div style={{ paddingRight: "10px" }}>
          <Form.Group controlId="formGroupEmail">
            <Form.Label>Assign</Form.Label>
            <Form.Control
              onChange={(e) => setAssignStatus(e.target.value)}
              as="select"
              value={assignStatus}
            >
              <option value="all">All</option>
              <option value="assigned">Assigned</option>
              <option value="unassigned">Not Assigned</option>
            </Form.Control>
          </Form.Group>
        </div>

        <Form.Group controlId="formGroupEmail">
          <Form.Label>Filter by Month/year</Form.Label>
          <Form.Control
            onChange={(e) => setFilterByMonthYear(e.target.value)}
            as="select"
          >
            <option value="all">All</option>
            {monthYearDropdown}
          </Form.Control>
        </Form.Group>
      </div>
      <div className="rowBox">
        <div className="tableFlex">
          <div className="inner">
            <MaterialTable
              title={`Shop List`}
              columns={columns}
              data={globalSearch()}
              style={{ fontSize: "11px" }}
              isLoading={isLoading}
              options={{
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                pageSize: 10,
                pageSizeOptions: [10, 20, 100, 500, 1000, 2000],
                grouping: false,
                filtering: true,
              }}
            />
          </div>
        </div>
        {/* )} */}
      </div>

      {/* Display shop profile in modal */}

      <Modal
        className="viewCartDetails"
        show={modalShow}
        onHide={() => {
          setState(initialState);
          setModalShow(false);
        }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ShopProfile shopDetails={shopDetails} />
        </Modal.Body>
      </Modal>

      {/* Display fields to add report */}

      <Modal
        className="viewCartDetails"
        id="telecalling"
        show={showReport}
        onHide={() => setShowReport(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form onSubmit={addReport}>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Form.Label column sm="5" className="labelStyle required">
                Status
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  as="select"
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  value={state.followUpStatus}
                  name="followUpStatus"
                  required
                >
                  <option>Select call Status</option>
                  {telecallingDropdownFields}
                </Form.Control>
              </Col>
            </Form.Group>
            {!["Accidental Download", "Fake Shop", "Paid"].includes(
              state.followUpStatus
            ) && (
              <>
                <Form.Group as={Row} controlId="formPlaintextPassword">
                  <Form.Label column sm="5" className="labelStyle required">
                    Follow Up Date
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="date"
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      value={state.followUpDate}
                      name="followUpDate"
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextPassword">
                  <Form.Label column sm="5" className="labelStyle">
                    Follow Up Time
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="time"
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      value={state.followUpTime}
                      name="followUpTime"
                    ></Form.Control>
                  </Col>
                </Form.Group>
              </>
            )}

            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label className="labelStyle required" column sm="5">
                Potential For Payment
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  as="select"
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  value={state.potentialForPayment}
                  name="potentialForPayment"
                  placeholder="₹ INR"
                  required
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Form.Label column sm="5" className="labelStyle required">
                Notes
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  name="notes"
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  as="textarea"
                  value={state.notes}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Form.Label column sm="5" className="labelStyle required">
                Source
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  as="select"
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  value={state.sourceType}
                  name="sourceType"
                  required
                >
                  <option value="">Select Source</option>
                  {sourceTypeDropdownFields}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Form.Label column sm="5" className="labelStyle required">
                Telecaller Name
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  as="select"
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  value={state.telecallerName}
                  name="telecallerName"
                  required
                >
                  <option>Select Telecaller Name</option>
                  {telecallerNameDropdownFields}
                </Form.Control>
              </Col>
            </Form.Group>
            <div
              style={{ width: "100%", textAlign: "right", marginTop: "10px" }}
            >
              <Button type="submit">Submit</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
