//import dependencies
import React, { useEffect, useCallback, useState } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";

export default function Franchise() {
  return (
    <>
      <Container fluid>
        <div style={{ width: "100%", height: "30px" }}></div>
        <div className="websiteHeader">
          <h2 className="websiteHeaderText">Franchise</h2>
        </div>

        <div className="contentUploadContainer">
          <div className="contentUploadSideMenu">
            <ProSidebar width="100%">
              <Menu iconShape="square">
                <MenuItem>Welcome Page</MenuItem>
                <MenuItem>Online Product</MenuItem>
                <MenuItem>About Us</MenuItem>
                <MenuItem>Top Notification</MenuItem>
                <MenuItem>Our Services</MenuItem>
                <MenuItem>Our Clients</MenuItem>
                <MenuItem>Our Projects</MenuItem>
                <MenuItem>Our brands</MenuItem>
                <MenuItem>Client testimonials</MenuItem>
                <MenuItem>Video Gallery</MenuItem>
                <MenuItem>Social Media</MenuItem>
                <MenuItem>Contact Us Form</MenuItem>
                <MenuItem>Manage Shops</MenuItem>
              </Menu>
            </ProSidebar>
          </div>
          <div className="contentUploadMainPage">
            <h1>Hello Team</h1>
          </div>
        </div>
      </Container>
    </>
  );
}
