import React, { useState, useEffect, createRef, useMemo } from "react";
import MaterialTable from "material-table";
import config from "../config";
import TodayIcon from "@material-ui/icons/Today";
import { Auth } from "aws-amplify";
// import TelecallingJSON from "./telecalling.json";
import EditIcon from "@material-ui/icons/Edit";
import {
  Col,
  Button,
  Row,
  Modal,
  Form,
  Tabs,
  Tab,
  InputGroup,
} from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ShopProfile from "../shopProfile/shopProfile";
import Skeleton from "@material-ui/lab/Skeleton";
import ArrowRightAltSharpIcon from "@material-ui/icons/ArrowRightAltSharp";

import moment from "moment";
// initialize state
let initialState = {
  notes: [],
  addNotes: "",
  workLogs: [],
};
export default function TelecallingDashboard() {
  const classes = useStyles();
  const csvLink = createRef();
  const [active, setActive] = useState(false);
  const [telecallingRecords, setTellcallingRecords] = useState([]);
  const [key, setKey] = useState("today");
  const [sourceTypeDropdownFields, setSourceTypeDropdownFields] = useState([]);
  const [telecallerNameDropdownFields, setTelecallerNameDropdownFields] =
    useState([]);
  const [telecallingDropdownFields, setTelecallingDropdownFields] = useState(
    []
  );
  const [shopDetails, setShopDetails] = useState({});
  const [downloadData, setDownloadData] = useState([]);
  const [state, setState] = useState(initialState);
  const [showReport, setShowReport] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [oldData, setOldData] = useState({});
  const [dashboard, setDashboard] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [report, setReport] = useState([{ list: {} }]);
  const [attendedCalls, setAttenedCalls] = useState([{ list: {} }]);
  const [modalShow, setModalShow] = useState(false);
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(2, "months").format("YYYY-MM-DD"),
    to: new Date().toISOString().substring(0, 10),
  });

  useEffect(() => {
    async function loadData() {
      await getData();
      await Auth.currentAuthenticatedUser().then((data) => {
        setCurrentUser(data.attributes.phone_number);
      });
      const jsonRequest = await fetch(`${config.json.URL}/telecalling.json`);
      const TelecallingJSON = await jsonRequest.json();

      let telecallingOption = TelecallingJSON.callStatus.map((items) => (
        <option value={items}>{items.toUpperCase()}</option>
      ));

      let sourceOption = TelecallingJSON.sourceType.map((items) => (
        <option value={items}>{items.toUpperCase()}</option>
      ));
      let telecallerNameOption = TelecallingJSON.telecallerName.map((items) => (
        <option value={items}>{items.toUpperCase()}</option>
      ));

      setTelecallerNameDropdownFields(telecallerNameOption);
      setSourceTypeDropdownFields(sourceOption);
      setTelecallingDropdownFields(telecallingOption);
      setIsLoading(false);
    }
    loadData();
  }, []);
  const getData = async () => {
    await getTelecallingByFollowupstatus();
    await getDashboardData();
  };
  const getTelecallingByFollowupstatus = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/telecallingStatusByStatus`, {
        method: "POST",
        body: JSON.stringify({
          key: key,
          dateRange,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        if (data.status) {
          if (data.payloadResponseExceededLimit) {
            console.log("get data from bucket__");

            try {
              let uploadData = await (await fetch(data.data)).json();

              setTellcallingRecords(uploadData || []);
            } catch (err) {
              setTellcallingRecords([]);
              alert("Fetch Failed");
            }
          } else {
            console.log("get data from DB__");
            setTellcallingRecords(data.data || []);
          }

          setTableLoading(false);
        }
      })
      .catch((err) => console.log("Error getting telecalling status", err));
  };
  const getDashboardData = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/telecallingDashboard`, {
        method: "POST",
        body: JSON.stringify({
          key: key,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        let overviewData = data.filter((item) => item.key == "dashboard");
        let reportData = data.filter((item) => item.key == "todayReport");
        let todayDialedCalls = data.filter(
          (item) => item.key == "todayDialedCalls"
        );
        setAttenedCalls(todayDialedCalls);
        setIsLoading(false);
        setReport(reportData);
        setDashboard(overviewData);
      })
      .catch((err) => console.log("Error getting telecalling status", err));
  };
  useEffect(() => {
    getTelecallingByFollowupstatus();
    setTableLoading(true);
  }, [key, dateRange]);

  const columns = [
    {
      title: "Action",
      field: "view",
      render: (rowData) => {
        return (
          <span
            className="actionHover"
            onClick={async () => {
              // let readLog = await (
              //   await fetch(rowData.logUrl)
              // ).json();
              // setLogs(readLog);

              setShowReport(true);

              setState(rowData);

              setOldData(rowData);
            }}
          >
            <EditIcon />
          </span>
        );
      },
    },
    {
      title: "Shop Id",
      field: "ghoshakId",
    },
    {
      title: "Shop ph No",
      field: "userPhoneNumber",
    },
    {
      title: "Telecaller Name",
      field: "telecallerName",
    },
    {
      title: "Shop Name",
      field: "shopName",
    },

    {
      title: "Follow Up Date",
      field: "followUpDate",
    },
    {
      title: "Follow Up Time",
      field: "followUpTime",
    },

    {
      title: "Status", 
      field: "followUpStatus",
    },
    {
      title: "Potential For Payment",
      field: "potentialForPayment",
    },

    {
      title: "Source",
      field: "sourceType",
    },
    {
      title: "Created By",
      field: "createdBy",
      render: (rowData) => {
        return (
          <h6 style={{ fontSize: "12px" }}>
            {rowData.createdBy} <span>{rowData.createdOn}</span>
          </h6>
        );
      },
    },
    {
      title: "Posted By",
      field: "postedBy",
      render: (rowData) => {
        return (
          <h6 style={{ fontSize: "12px" }}>
            {rowData.updatedBy} <span>{rowData.updatedOn}</span>
          </h6>
        );
      },
    },

    {
      title: "Action",
      field: "view",
      render: (rowData) => {
        return (
          <Button
            onClick={() => {
              getShopDetails(rowData.shopId);
            }}
          >
            View Shop
          </Button>
        );
      },
    },
  ];
  const handleClose = () => {
    // Close modal
    setState(initialState);
    setShowReport(false);
    setOldData({});
  };
  const updateReport = async (e) => {
    e.preventDefault();
    let input = {};
    input = state;

    delete input.shopId;

    if (typeof input.addNotes != "undefined") {
      let latestVersion =
        input.notes.length > 0
          ? input.notes.sort((a, b) => b.version - a.version)[0].version
          : 0;

      let previousNotes = state.notes;
      previousNotes.push({
        date: new Date().toISOString().substring(0, 10),

        version: parseInt(latestVersion) + 1,
        message: input.addNotes,
      });
    } else {
      input.notes = state.notes;
    }
    if (
      !["Accidental Download", "Fake Shop", "Paid"].includes(
        state.followUpStatus
      )
    ) {
      if (input.followUpDate == "") return alert("Select Follow Up Date");
    }
    if (input.sourceType == "") return alert("Select Source");
    if (input.telecallerName == "") return alert("Select Telecaller Name");
    delete input.addNotes;
    Object.keys(input).forEach((key) => input[key] == "" && delete input[key]); // Removes Empty String
    input.workLogs = await updateWorkLogs();
    input.template = "edit";
    input.lastUpdatedBy = currentUser;
    input.lastUpdatedOn = new Date().toISOString().substring(0, 10);

    Promise.all([
      fetch(`${config.ApiGateway}/telecallingStatus`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        alert("Status Updated");
        await handleClose();
        await getData();
      })
      .catch((err) => console.log(err));
  };
  const updateWorkLogs = async () => {
    // Update work logs compare between old data and  new data
    return await new Promise((resolve) => {
      let addWorkLogs = [];
      let newDataRef = state;
      let oldDataRef = oldData;
      let previousWorkLogs = state.workLogs;
      let combinedObject = { ...oldDataRef, ...newDataRef };
      let latestVersion =
        newDataRef.workLogs.length > 0
          ? newDataRef.workLogs.sort((a, b) => b.version - a.version)[0].version
          : 0;
      const diff = Object.entries(combinedObject).reduce(
        (acc, [key, value]) => {
          if (
            !Object.values(oldDataRef).includes(value) ||
            !Object.values(newDataRef).includes(value)
          )
            acc[key] = value;

          return acc;
        },
        {}
      );

      if (diff.hasOwnProperty("potentialForPayment")) {
        addWorkLogs.push({
          message: `Changed payment status to - ${diff["potentialForPayment"]}`,
        });
      }
      if (diff.hasOwnProperty("followUpDate")) {
        addWorkLogs.push({
          message: `Follow up date - ${diff["followUpDate"]}`,
        });
      }
      if (diff.hasOwnProperty("followUpStatus")) {
        addWorkLogs.push({
          message: `Changed status to ${diff["followUpStatus"]}`,
        });
      }
      if (diff.hasOwnProperty("sourceType")) {
        addWorkLogs.push({
          message: `Changed source to ${diff["sourceType"]}`,
        });
      }
      if (diff.hasOwnProperty("telecallerName")) {
        addWorkLogs.push({
          message: `${currentUser} assigned to ${diff["telecallerName"]}`,
        });
      }
      if (diff.hasOwnProperty("addNotes")) {
        addWorkLogs.push({
          message: `Added Notes - ${diff["addNotes"]}`,
        });
      }

      let currentLogs = {
        date: moment().format("lll"),
        user: currentUser,
        status: newDataRef.updatedBy == currentUser ? "updated" : "modified",
        work: addWorkLogs,
        version: parseInt(latestVersion) + 1,
      };
      previousWorkLogs.push(currentLogs);
      resolve(previousWorkLogs);
    });
  };
  const onChange = (name, value) => {
    // onchange event for form event
    let objectName = name;
    let objectValue = value;

    setState((prevState) => ({
      ...prevState,
      [objectName]: objectValue,
    }));
  };
  const exportAsExcel = (dataArray) => {
    let excelDownloadArray = dataArray.map((rowData) => {
      let input = {};
      input.shopName = rowData.shopName;
      input.shopId = rowData.ghoshakId;
      input["Telecaller Name"] = rowData.telecallerName;
      input.status = rowData.followUpStatus;
      input["Follow up Date"] = rowData.followUpTime;
      input["Follow up Date"] = rowData.followUpDate;
      input.source = rowData.sourceType;
      input["Potential for Payment"] = rowData.potentialForPayment;
      input["Shop Ph No"] = rowData.userPhoneNumber;
      input["Created By"] = rowData.createdBy;
      input["Created on"] = rowData.createdOn;
      input["Last Updated By"] = rowData.updatedBy;
      input["Last Updated On"] = rowData.updatedOn;

      return input;
    });

    setDownloadData(excelDownloadArray);
    setActive(true);
  };
  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [downloadData]);
  const getShopDetails = async (shopId) => {
    await Promise.all([
      fetch(`${config.ApiGateway}/viewShopById`, {
        method: "POST",
        body: JSON.stringify({ shopId: shopId }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        setShopDetails(data);
        setModalShow(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div class="flexBoxContainer">
        <div className="flexBoxHeader">
          <h2>Dashboard</h2>
        </div>

        <div className="wrapComponent">
          <div className="flexBoxRow">
            <div className="flexBoxTitleHeader">
              <h4>Overview</h4>
            </div>
            {isLoading ? (
              <div style={{ display: "flex", flexGrow: "1" }}>
                <Skeleton animation="wave" width={"100%"} height={200} />
              </div>
            ) : (
              <>
                {dashboard.map((items) => (
                  <>
                    {items.list.map((elements) => (
                      <div id="auto" className="flexBoxColumn">
                        <div className="flexBoxCard">
                          <div className="flexBoxIcon">
                            <TodayIcon />
                          </div>
                          <div className="flexCardContent">
                            <h5>{elements.title}</h5>
                            <h6>{elements.count}</h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ))}
              </>
            )}
          </div>

          <div className="flexBoxRow">
            <div className="flexBoxTitleHeader">
              <h4>Reports</h4>
            </div>

            <div style={{ margin: "0 auto" }} className="column3">
              <p>Follow Up Calls</p>
              <div className={classes.root}>
                <TableContainer>
                  <Table size="small" stickyHeader className={classes.table}>
                    <TableHead className={classes.tableHeadBody}>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          SI No
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Follow Up calls
                        </TableCell>

                        <TableCell className={classes.tableHead}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(report[0].list).map(([name, logs]) => (
                        <TableRow key={name}>
                          <TableCell className={classes.tableCell}>
                            {name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <ul
                              style={{
                                padding: 0,
                                margin: 0,
                                listStyle: "none",
                              }}
                            >
                              {Object.keys(logs.followUp).map((status) => (
                                <li style={{ padding: 0 }}>
                                  {status} : {logs.followUp[status]}
                                </li>
                              ))}
                            </ul>
                          </TableCell>

                          <TableCell className={classes.tableIcon}>
                            <span
                              onClick={() => exportAsExcel(logs.list)}
                              style={{ color: "#fa6565" }}
                            >
                              Download
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            <div style={{ margin: "0 auto" }} className="column3">
              <p>Attended Calls</p>
              <div className={classes.root}>
                <TableContainer>
                  <Table size="small" stickyHeader className={classes.table}>
                    <TableHead className={classes.tableHeadBody}>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          SI No
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Follow Up calls
                        </TableCell>

                        <TableCell className={classes.tableHead}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(attendedCalls[0].list).map(
                        ([name, logs]) => (
                          <TableRow key={name}>
                            <TableCell className={classes.tableCell}>
                              {name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <ul
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  listStyle: "none",
                                }}
                              >
                                {Object.keys(logs.followUp).map((status) => (
                                  <li style={{ padding: 0 }}>
                                    {status} : {logs.followUp[status]}
                                  </li>
                                ))}
                              </ul>
                            </TableCell>

                            <TableCell className={classes.tableIcon}>
                              <span
                                onClick={() => exportAsExcel(logs.list)}
                                style={{ color: "#fa6565" }}
                              >
                                Download
                              </span>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>

          <div className="flexBoxRow">
            <div className="flexBoxTitleHeader">
              <h4>Call Logs</h4>
            </div>

            <div className="tabFlex">
              <Tabs
                id="controlled-tab-example"
                className="telecallingTab"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="today" title="Today"></Tab>
                <Tab eventKey="tomorrow" title="Tomorrow"></Tab>
                <Tab eventKey="yesterday" title="Yesterday"></Tab>
                <Tab eventKey="week" title="Week"></Tab>
                <Tab eventKey="nextWeek" title="Next Week"></Tab>
                <Tab eventKey="upcoming" title="Upcoming"></Tab>
                <Tab eventKey="past" title="Past"></Tab>
                <Tab eventKey="paid" title="Paid"></Tab>
              </Tabs>

              <div className="vertical-space-5"></div>
              {key == "past" && (
                <>
                  <Row>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <strong> Date Range</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          value={dateRange.from}
                          type="date"
                          name="from"
                          onChange={(e) =>
                            setDateRange((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value,
                            }))
                          }
                        ></Form.Control>
                        <Form.Control
                          type="date"
                          min={dateRange.from}
                          value={dateRange.to}
                          name="to"
                          required
                          onChange={(e) =>
                            setDateRange((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value,
                            }))
                          }
                        ></Form.Control>
                      </InputGroup>
                    </Col>
                  </Row>
                </>
              )}
              <div className="vertical-space-5"></div>

              <MaterialTable
                title={`Followup List`}
                columns={columns}
                data={telecallingRecords}
                isLoading={tableLoading}
                options={{
                  exportButton: {
                    csv: true,
                    pdf: false,
                  },
                  exportCsv: (columns, data) => {
                    return exportAsExcel(data);
                  },
                  pageSize: 30,
                  pageSizeOptions: [10, 20, 100, 500],
                  grouping: false,
                  filtering: false,
                  maxBodyHeight: 600,
                }}
                style={{
                  minWidth: "100%",
                  border: "0",
                  boxShadow: "none",
                  fontSize: "12px",
                }}
              />
            </div>
          </div>
        </div>
        <Modal
          className="viewCartDetails"
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <ShopProfile shopDetails={shopDetails} />
          </Modal.Body>
        </Modal>
        <Modal
          className="viewCartDetails"
          id="telecalling"
          show={showReport}
          onHide={() => {
            handleClose();
          }}
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body>
            <div className="editReportBox">
              <div className="formBox">
                <div style={{ paddingBottom: "10px" }} className="title">
                  <h4>
                    <span>Edit Status</span>
                  </h4>
                </div>
                <Form
                  style={{ width: "80%", float: "left" }}
                  onSubmit={updateReport}
                >
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Form.Label column sm="5" className="labelStyle required">
                      Status
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        as="select"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        value={state.followUpStatus}
                        name="followUpStatus"
                        required
                      >
                        <option>Select call Status</option>
                        {telecallingDropdownFields}
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  {!["Accidental Download", "Fake Shop", "Paid"].includes(
                    state.followUpStatus
                  ) && (
                    <>
                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label
                          column
                          sm="5"
                          className="labelStyle required"
                        >
                          Follow Up Date
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            type="date"
                            onChange={(e) =>
                              onChange(e.target.name, e.target.value)
                            }
                            value={state.followUpDate}
                            name="followUpDate"
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label
                          column
                          sm="5"
                          className="labelStyle required"
                        >
                          Follow Up Time
                        </Form.Label>
                        <Col sm="7">
                          <Form.Control
                            type="time"
                            onChange={(e) =>
                              onChange(e.target.name, e.target.value)
                            }
                            value={state.followUpTime}
                            name="followUpTime"
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    </>
                  )}
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label className="labelStyle required" column sm="5">
                      Potential For Payment
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        as="select"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        value={state.potentialForPayment}
                        name="potentialForPayment"
                        placeholder="₹ INR"
                        required
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Form.Label column sm="5" className="labelStyle required">
                      Notes
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        name="addNotes"
                        onChange={(e) => {
                          onChange(e.target.name, e.target.value);
                        }}
                        as="textarea"
                        value={state.addNotes}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Form.Label column sm="5" className="labelStyle required">
                      Source
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        as="select"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        value={state.sourceType}
                        name="sourceType"
                        required
                      >
                        <option value="">Select Source</option>
                        {sourceTypeDropdownFields}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Form.Label column sm="5" className="labelStyle required">
                      Telecaller Name
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        as="select"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        value={state.telecallerName}
                        name="telecallerName"
                        required
                      >
                        <option>Select Telecaller Name</option>
                        {telecallerNameDropdownFields}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "right",
                      marginTop: "10px",
                      display: key == "paid" ? "none" : "",
                    }}
                  >
                    <Button type="submit">Submit</Button>
                  </div>
                </Form>
              </div>

              <div className="notes">
                <div style={{ paddingBottom: "10px" }} className="title">
                  <h4>
                    <span>Notes</span>
                  </h4>
                </div>
                <div className={classes.root}>
                  <TableContainer className={classes.container}>
                    <Table size="small" className={classes.table}>
                      <TableHead className={classes.tableHeadBody}>
                        <TableRow>
                          <TableCell className={classes.tableHead}>
                            Date
                          </TableCell>

                          <TableCell className={classes.tableHead}>
                            Message
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state.notes.map((elements) => (
                          <TableRow key={elements.version}>
                            <TableCell className={classes.tableCell}>
                              {elements.date}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              component="th"
                              scope="row"
                            >
                              {elements.message}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div style={{ paddingBottom: "10px" }} className="title">
                  <h4>
                    <span>Work Logs</span>
                  </h4>
                </div>
                <div className="wrapWorklog">
                  {state.workLogs
                    .sort((a, b) => b.version - a.version)
                    .map((item) => (
                      <div className="workLogs">
                        <h5>
                          <b>{item.user}</b>
                          <span style={{ textTransform: "capitalize" }}>
                            {item.status}
                            <span style={{ textTransform: "none" }}>
                              {" the report"}
                            </span>
                          </span>

                          <span style={{ fontSize: "10px", color: "#909090" }}>
                            {item.date}
                          </span>
                        </h5>
                        <ul>
                          {item.work.map((elements) => (
                            <li>
                              <span>
                                <ArrowRightAltSharpIcon
                                  style={{ fontSize: "12px", color: "#909090" }}
                                />
                              </span>
                              {elements.message}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <CSVLink data={downloadData} ref={csvLink} target="_blank" />
      </div>
    </>
  );
}

const useStyles = makeStyles({
  table: {
    boxShadow: "0 0 3px #e2e5e8",
    backgroundColor: "#ffffff",
    margin: "0 auto",
    border: "1px solid #dedede",
    paddingBottom: 20,
  },
  container: {
    maxHeight: 200,
    overflowY: "scroll",
    overflowX: "auto",
    overflow: "hidden",
  },
  tableHead: {
    fontWeight: 500,
    backgroundColor: "#ffffff",
    color: "#333",
    letterSpacing: "1.5px",
    textTransform: "capitalize",
  },
  tableHeadBody: {
    backgroundColor: "#ffffff",
    borderBottomWidth: "1px",
  },
  root: {
    width: "100%",
    paddingBottom: 20,
  },
  tableCell: {
    fontWeight: 300,
    lineHeight: "11px",
    fontSize: "11px",
    color: "#212529",
  },
  tableIcon: {
    fontSize: "13px",

    fontWeight: 300,
    lineHeight: "20px",
  },
  tableBodyRow: {
    borderBottom: "1px solid #e9ecef",
  },
});
