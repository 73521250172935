import React, { useEffect, useState } from "react";
import { Container, Col, Button, Row, Form } from "react-bootstrap";
import config from "../config";
export default function DeleteAccount() {
  const [state, setState] = useState({
    userId10Digit: "",
  });
  let initialState = {
    userName: "",
    shopName: "",
    shopAddress: "",
    userPhoneNumber: "",
    shopType: "",
    userId10Digit: "",
    userId: "",
  };
  const [shop, setShop] = useState(initialState);
  const [showShop, setShowShop] = useState(false);
  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const getShop = (e) => {
    e.preventDefault();
    fetch(
      `${config.ApiGateway}/getshop`, // Get Shop Details

      {
        method: "POST",

        body: JSON.stringify({ userId10Digit: state.userId10Digit }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        if (data.Items.length > 0) {
          let shopObject = data.Items[0];
          setShop({
            userName: shopObject.userName,
            shopName: shopObject.shopName,
            shopAddress: shopObject.shopAddress,
            userPhoneNumber: shopObject.userPhoneNumber,
            shopType: shopObject.shopType,
            userId10Digit: shopObject.userId10Digit,
            userId: shopObject.userId,
          });
          setShowShop(true);
        }
      })
      .catch((err) => console.log(err));
  };
  const deleteAccConfirmation = (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "By Deleting Account, you'll be deleting offers and coupons "
      )
    ) {
      deleteAccount();
    } else {
      return;   
    }
  };
  const deleteAccount = () => {
    fetch(
      `${config.ApiGateway}/deleteAccount`, // Get Shop Details

      {
        method: "POST",

        body: JSON.stringify({ userId: shop.userId }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.errorMessage === "User does not exist.") {
          alert("user not found");
        }
        alert(data.status);
        setShowShop(false);
        setState({
          userId10Digit: "",
        });
        setShop(initialState);
      })

      .catch((err) => console.log(err));
  };
  return (
    <>
      <Container>
        <div style={{ width: "100%", height: "40px" }}></div>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Delete Account</h2>
        </div>
        <Row className="rowClass">
          <Col
            className="flexCardHeader"
            style={{
              boxShadow: "0px 0px 13px 0px rgba(26, 52, 95, 0.08)",
            }}
            sm={10}
          >
            <Form onSubmit={getShop} style={{ width: "100%" }}>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="4">
                  Shop Id
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    name="userId10Digit"
                    pattern="[0-9]{1,}"
                    onChange={(e) => onChange(e)}
                    value={state.userId10Digit}
                  ></Form.Control>
                </Col>
                <Col sm="2">
                  <Button type="submit">Submit</Button>
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        {showShop && (
          <Row className="rowClass">
            <Col
              className="flexCard"
              style={{ display: "table-cell", padding: "16px" }}
              sm={10}
            >
              <>
                <div style={{ marginBottom: "20px" }}>
                  <Row>
                    <Col sm={6}>
                      <div className="imageContainer">
                        <img
                          src={
                            "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/images/offers/default_offer_image.png"
                          }
                          className="imageBox"
                        ></img>
                      </div>
                    </Col>
                    <Col style={{ paddingTop: "27px" }} sm={6}>
                      <h1 className="shopProfile">{shop.shopName}</h1>
                      <span className="shopProfile">{shop.shopAddress}</span>
                      <div className="vertical-space-5"></div>
                      <div className="shopLabeltext">
                        <label style={{ color: "#89959b", width: "30%" }}>
                          Merchant Name :{" "}
                        </label>
                        <p style={{ width: "40%" }}>{shop.userName}</p>
                      </div>
                      <div className="shopLabeltext">
                        <label style={{ color: "#89959b", width: "30%" }}>
                          Phone Number :{" "}
                        </label>
                        <p style={{ width: "40%" }}>{shop.userPhoneNumber}</p>
                      </div>
                      <div className="shopLabeltext">
                        <label style={{ color: "#89959b", width: "30%" }}>
                          Shop Pincode :
                        </label>
                        <p style={{ width: "40%" }}>{shop.userPincode}</p>
                      </div>
                      <div className="shopLabeltext">
                        <label style={{ color: "#89959b", width: "30%" }}>
                          Shop Type :
                        </label>
                        <p style={{ width: "40%" }}>{shop.shopType}</p>
                      </div>
                      <div className="shopLabeltext">
                        <label style={{ color: "#89959b", width: "30%" }}>
                          Shop Id :
                        </label>
                        <p style={{ width: "40%" }}>{shop.userId10Digit}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingRight: "15px", float: "right" }}>
                    <Form onSubmit={deleteAccConfirmation}>
                      <Button
                        type="submit"
                        style={{ marginRight: "10px" }}
                        variant="danger"
                      >
                        Delete Account
                      </Button>{" "}
                      <Button variant="outline-secondary">Cancel</Button>{" "}
                    </Form>
                  </Row>
                </div>
              </>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
