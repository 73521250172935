import React, { useEffect, useState } from "react";
import DisplayTable from "./displayTable";
import { getSegment, getSegmentByShopType } from "./service";
import StorefrontIcon from "@material-ui/icons/Storefront";
export default function Segment() {
  const [segmentDistribution, setSegmentDistribution] = useState([]);
  const [shop, setShop] = useState([]);
  const [title, setTitle] = useState("");
  useEffect(() => {
    // Call class on mounting
    async function getData() {
      await getSegmentDistribution();
    }
    getData();
  }, []);

  const getSegmentDistribution = async () => {
    // get segment distributiion
    await getSegment().then(async (data) => {
      setSegmentDistribution(data);
    });
  };
  const getShopType = async (shopType) => {
    await getSegmentByShopType(shopType).then(async (data) => {
      setShop(data);
      setTitle(shopType);
    });
  };
  return (
    <>
      <div className="titleHeader">
        <h4>Segment</h4>
      </div>
      <div className="flexRow">
        <div className="labelWrapper">
          <h5>Distribution</h5>
        </div>

        <>
          {segmentDistribution.map((items) => (
            <div id="offer" className="flexColumn">
              <div className="flexCard">
                {/* <div className="flexIcon">
                  <StorefrontIcon />
                </div> */}
                <div className="flexContent">
                  <h5> {items.key}</h5>
                  <h4>{items.doc_count}</h4>
                  <button onClick={() => getShopType(items.key)}>View</button>
                </div>
              </div>
            </div>
          ))}
        </>
      </div>
      {shop.length > 0 && (
        <>
          <div className="flexRow">
            <div className="labelWrapper">
              <h5>Shops</h5>
            </div>
            <div id="displayTable" className="flexColumn">
              <DisplayTable
                data={shop}
                validKeys={[
                  "userId10Digit",
                  "shopName",
                  "userName",
                  "shopType",
                  "shopAddress",
                  "userAddedDate",
                ]}
                title={title}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
