import React, { useState } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import config from "../config";
import GetAppIcon from "@material-ui/icons/GetApp";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import GroupIcon from "@material-ui/icons/Group";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
const PartnerEmployee = ({
  employee,
  employeeRefShops,
  getPartnerInfo,
  classes,
  userProfile,
}) => {
  const [modalEmpShow, setEmpModalShow] = useState(false);
  const [subErr, setSubErr] = useState(false);
  const [subShopObject, setSubShopObject] = useState({
    role: "",
  });
  const [subUserId10Digit, setSubUserId10Digit] = useState("");
  const addPartnerEmployee = async (data) => {
    let input = {};
    input.accountType = "secondary";
    input.referralCode = data.referralCode;
    input.userPhoneNumber = data.userPhoneNumber;
    input.userId10Digit = data.userId10Digit;
    input.userName = data.userName;
    data.role == "" ? delete data.role : (input.role = data.role);
    input.ghoshakId = data.userId;
    input.adminId = userProfile.userId;
    input.typename = "addemployee";

    await Promise.all([
      fetch(`${config.ApiGateway}/partnerEmployeeAction`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setEmpModalShow(false);
        getPartnerInfo();
      })
      .catch((err) => console.log("error getting data", err));
  };
  const removePartnerAccount = async (id) => {
    let input = {};
    input.typename = "removeemployee";
    input.userId = id;

    await Promise.all([
      fetch(`${config.ApiGateway}/partnerEmployeeAction`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        await getPartnerInfo();
      })
      .catch((err) => console.log("error getting data", err));
  };
  const getSubEmployee = async (e) => {
    e.preventDefault();
    await Promise.all([
      fetch(`${config.ApiGateway}/getShopAndEmployee`, {
        method: "POST",
        body: JSON.stringify({
          userId10Digit: subUserId10Digit,
          typename: "secondary", // to get sub shop
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        if (data.sub.length > 0) {
          let subShop = data.sub[0];
          setSubShopObject((prevState) => ({ ...prevState, ...subShop }));
          setSubErr(false);
        } else {
          setSubErr(true);
        }
      })
      .catch((err) => console.log("error in getting shop", err));
  };

  return (
    <>
      <div className="title">
        <h4>
          <span>Employee</span>
        </h4>
      </div>
      <div className="profileCardContainer">
        <Row>
          <Col xs={"6"} sm={"6"}>
            <Row>
              <>
                <Col id="cardBox" className="colCenter" md="4" sm="3">
                  <Row>
                    <Col className="icon" xs="3" md="4" sm={"3"}>
                      <GroupIcon />
                    </Col>
                    <Col className="iconText" xs="3" md="8" sm={"10"}>
                      <span>Total Employee</span>
                      <span>{employee.length}</span>
                    </Col>
                  </Row>
                </Col>
                <Col id="cardBox" className="colCenter" md="4" sm="3">
                  <Row>
                    <Col className="icon" xs="3" md="4" sm={"3"}>
                      <GetAppIcon />
                    </Col>
                    <Col className="iconText" xs="3" md="8" sm={"10"}>
                      <span>Employee Downloads</span>

                      <span>{employeeRefShops}</span>
                    </Col>
                  </Row>
                </Col>
              </>
            </Row>
          </Col>
          <Col xs={"6"} sm={"6"}>
            <div className="tableCard">
              <div className="tableCardBody">
                <div className="tableCardTitle">
                  <div style={{ fontWeight: "400" }}>Employee List</div>
                  <div className="addEmployee">
                    <h5 onClick={() => setEmpModalShow(true)}>
                      <AddCircleOutlineIcon
                        style={{ fontSize: "14px", color: "#03a9f3" }}
                      />
                      Add New Employee
                    </h5>
                  </div>
                </div>
                <div className={classes.root}>
                  <TableContainer className={classes.container}>
                    <Table size="small" stickyHeader className={classes.table}>
                      <TableHead className={classes.tableHeadBody}>
                        <TableRow>
                          <TableCell className={classes.tableHead}>
                            SI No
                          </TableCell>
                          <TableCell className={classes.tableHead}>
                            Name
                          </TableCell>
                          <TableCell className={classes.tableHead}>
                            Downloads
                          </TableCell>
                          <TableCell className={classes.tableHead}>
                            Role
                          </TableCell>
                          <TableCell className={classes.tableHead}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {employee.map((row, index) => (
                          <TableRow key={row.name}>
                            <TableCell className={classes.tableCell}>
                              {index + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.userName}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.totalDownloads}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.role}
                            </TableCell>

                            <TableCell className={classes.tableIcon}>
                              <span
                                onClick={() => removePartnerAccount(row.userId)}
                                style={{ color: "#fa6565" }}
                              >
                                <DeleteOutlineIcon />
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
             
             
             
              
              
              
               
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          className="viewCartDetails"
          show={modalEmpShow}
          onHide={() => setEmpModalShow(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="dashboardColumnBox columnCenter" sm="10">
                <Form onSubmit={getSubEmployee}>
                  <Form.Row>
                    <Col sm="4">
                      <Form.Label>Enter Sub user Id</Form.Label>
                    </Col>
                    <Col sm="6">
                      <Form.Control
                        onChange={(e) => setSubUserId10Digit(e.target.value)}
                        value={subUserId10Digit}
                      />
                    </Col>
                    <Col sm="2">
                      <Button type="submit">Search</Button>
                    </Col>
                    <Form.Text style={{ display: subErr ? "" : "none" }}>
                      Shop Not Found
                    </Form.Text>
                  </Form.Row>
                </Form>
              </Col>
            </Row>
            <div className="vertical-space-5"></div>

            <Row
              style={{
                display: Object.keys(subShopObject).length > 1 ? "" : "none",
              }}
              className="rowFlex"
            >
              <Col className="columnCenter" sm="10">
                <Row>
                  <Col>
                    <label>Name : </label>
                    <p>{subShopObject.userName}</p>
                  </Col>
                  <Col>
                    <label>ID : </label>
                    <p>{subShopObject.userId10Digit}</p>
                  </Col>
                  <Col>
                    <label>Phone Number: </label>
                    <p>{subShopObject.userPhoneNumber}</p>
                  </Col>
                  <Col>
                    <label>Referal Code: </label>
                    <p>{subShopObject.referralCode}</p>
                  </Col>
                  <Col>
                    <label>Role: </label>
                    <Form.Control
                      onChange={(e) => {
                        let value = e.target.value;
                        setSubShopObject((prevState) => ({
                          ...prevState,
                          ["role"]: value,
                        }));
                      }}
                      value={subShopObject.role}
                    ></Form.Control>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{
                display: Object.keys(subShopObject).length > 1 ? "" : "none",
              }}
            >
              <Col
                style={{ marginTop: "7px" }}
                className="columnCenter"
                sm="10"
              >
                <Button
                  style={{ float: "right" }}
                  onClick={() => addPartnerEmployee(subShopObject)}
                >
                  Add Employee
                </Button>
              </Col>
            </Row>

            <div className="vertical-space-5"></div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default PartnerEmployee;
