import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import MaterialTable from "material-table";
import config from "../config";
import { Auth } from "aws-amplify";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
    overflowY: "scroll",

    padding: "10px",
    border: "1px solid #dedede",
  },
  tableBodyCellStyle: {
    fontWeight: 300,

    color: "#212529",
  },
  container: {
    maxHeight: 700,
    overflowY: "scroll",
    overflowX: "auto",
    overflow: "hidden",
  },
  root: {
    width: "100%",
  },
  tableCell: {
    padding: "2px",
    fontSize: "10px",
    margin: 0,
    width: "2px",
  },
});

export default function Report() {
  let initialState = {
    updatedOn: new Date().toISOString().substring(0, 10),
    verificationStatus: "",
    remarkForPartner: "",
    remarkForGhoshak: "",
    amountPaid: "",
  };
  const classes = useStyles();
  const [statementData, setStatementData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({
    paymentVersion: [],
  });
  const [state, setState] = useState(initialState);
  const [updatedValue, setUpdatedValue] = useState(0);
  const handleClose = () => setModalShow(false);
  const handleShow = (input) => {
    setState((prevState) => ({
      ...prevState,

      verificationStatus: input.verificationStatus,
      remarkForPartner: input.remarkForPartner,
      remarkForGhoshak: input.remarkForGhoshak,
      userId: input.userId,
      addAmount: "",
    }));
    setUpdatedValue(parseFloat(input.amountPaid));
    setModalData(input);
    setModalShow(true);
  };
  useEffect(() => {
    async function loadData() {
      await Auth.currentAuthenticatedUser().then((data) => {
        onChange("paidUpdatedBy", data.attributes.phone_number);
      });
      //   await getData();
    }

    loadData();
  }, []);
  const getData = () => {
    // Get data from Backend
    Promise.all([
      fetch(`${config.ApiGateway}/partnerStatement`, {
        method: "POST",

        body: JSON.stringify({
          template: "getStatementData", // To get all Statement data
          statementStatus: "paid",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setStatementData(data);
      })
      .catch((err) => console.log(err));
  };

  const onChange = (name, value) => {
    // onchange event for form event
    let objectName = name;
    let objectValue = value;
    setState((prevState) => ({ ...prevState, [objectName]: objectValue }));
  };
  const updatePayment = (e) => {
    e.preventDefault();
    let input = state;

    input.remarkForPartner =
      input.remarkForPartner == "" ? "NA" : input.remarkForPartner;
    input.remarkForGhoshak =
      input.remarkForGhoshak == "" ? "NA" : input.remarkForGhoshak;
    input.notes = input.notes == "" ? "NA" : input.notes;

    Object.keys(input).forEach((key) => input[key] == "" && delete input[key]); // Removes Empty String
    console.log(input);
    input.addAmount =
      typeof input.addAmount == "string" ? parseInt(input.addAmount) : 0;

    if (input.addAmount > 0) {
      input.amountPaid =
        parseInt(input.addAmount) + parseFloat(modalData.amountPaid);

      let concatPaymentVersion = modalData.paymentVersion;
      concatPaymentVersion.push({
        date: new Date().toISOString().substring(0, 10),

        amount: input.addAmount,
        paidBy: input.paidUpdatedBy,
      });
      input.paymentVersion = concatPaymentVersion;
    }
    input.template = "edit";
    Promise.all([
      fetch(`${config.ApiGateway}/partnerStatement`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        handleClose();
        alert("Statement Updated");
        setState(initialState);
        getData();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container>
      <div className="orderHeader">
        <h2 className="orderHeaderText"> Statement</h2>
      </div>
      <Row>
        <Col sm="12">
          
          {/* <MaterialTable
            title={"Statement"}
            columns={[
              {
                title: "Period",
                field: "cycleStartDate",
                render: (rowData) => {
                  return `${moment(rowData.cycleStartDate).format(
                    "MMM Do YYYY"
                  )} - ${moment(rowData.cycleEndDate).format("MMM Do YYYY")}`;
                },
              },
              { title: "Criteria", field: "criteria" },
              { title: "Shop Name", field: "shopName" },
              { title: "Company Name", field: "companyName" },
              { title: "Ref Code", field: "referralCode" },
              { title: "Amount", field: "amountPaid" },
              { title: "verification", field: "verificationStatus" },
              { title: "Remark (Ghoshak)", field: "remarkForGhoshak" },
              {
                title: "Action",
                field: "action",
                render: (rowData) => (
                  <Button onClick={() => handleShow(rowData)}>Edit</Button>
                ),
              },
            ]}
            data={statementData}
          /> */}
        </Col>
      </Row>
      <Modal className="viewCartDetails" show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h1>Modal Body</h1>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
