import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import config from "../../config";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

export default function Shops({ profile }) {
  const [shopDetails, setShopDetails] = useState({
    userId: "",
  });
  const [subShops, setSubShops] = useState([]); // linked shops
  const [tableLoading, setTableLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false); // modal state / true,false
  const [subShopId, setSubShopId] = useState("");
  const [subShopProfile, setSubShopProfile] = useState({});
  const [linkedStatus, setLinkedStatus] = useState("");
  const [imageFile, setImageFile] = useState([]);
  const [active, setActive] = useState(false);
  const [websiteTemplate, setWebsiteTemplate] = useState();
  const linked = {
    linked: "This Shop already linked with different shop",
    invalid: "Invalid Shop",
  };

  useEffect(() => {
    let { userId, websiteTemplate } = profile;

    if (Boolean(userId)) {
      getFranchiseLinkedShop();
      if (profile.websiteTemplate) {
        setShopDetails(profile);
        setWebsiteTemplate(profile?.websiteTemplate || {});
        setActive(
          websiteTemplate?.ourPartner?.active == true
            ? websiteTemplate?.ourPartner?.active
            : active
        );
      }
    }
  }, [profile]);
  const getFranchiseLinkedShop = async () => {
    let { userId } = profile;
    let data = await (
      await fetch(`${config.ApiGateway}/franchiseShops`, {
        method: "POST",
        body: JSON.stringify({
          userId,
          typename: "view",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    if (data.status) {
      if (data.payloadResponseExceededLimit) {
        // fetch data from s3
        try {
          let uploadData = await (await fetch(data.data)).json();
          setTableLoading(false);
          setSubShops(uploadData || []);
        } catch (err) {
          setSubShops([]);
          alert("Fetch Failed");
        }
      } else {
        setTableLoading(false);
        setSubShops(data.data || []);
      }

      setTableLoading(false);
    }
  };
  const ManageSecondaryShops = async (e) => {
    e.preventDefault();

    // verify valid shop
    await Promise.all([
      fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
        method: "POST",
        body: JSON.stringify({
          userId10Digit: subShopId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res]) => Promise.all([res.json()]))
      .then(([data]) => {
        console.log(data);
        if (data.length > 0) {
          let { websiteUserRole, primaryFranchiseUserId } = data[0];
          if (
            websiteUserRole == "secondary" &&
            !primaryFranchiseUserId == profile.userId
          ) {
            setLinkedStatus("linked");
          }

          setSubShopProfile(data[0]);
          setModalShow(true);
          setLinkedStatus(null);
        } else {
          setLinkedStatus("invalid");
        }
      })
      .catch((err) => {
        console.log("updating A Record Error", err);
        alert("Something went wrong, please try again");
      });

    //   setProfile(data.Items[0]);
    //   setShopId(data.Items[0].userId);

    //   setSelectedPage("");
  };

  const addSubShop = async () => {
    // link franchise
    let { userId } = subShopProfile;
    if (Boolean(userId)) {
      await manageSubShopAction("add", userId);
    }
  };

  const removeSubShop = async (rowData) => {
    // removes franchise linked sub shop
    let { userId } = rowData;

    if (Boolean(userId)) {
      await manageSubShopAction("remove", userId);
    }
  };

  const manageSubShopAction = async (typename, userId) => {
    // parameter  --> same fn split by action type aka method role and para2 - shop primary id

    let data = await (
      await fetch(`${config.ApiGateway}/franchiseShops`, {
        method: "POST",
        body: JSON.stringify({
          userId: userId,
          typename: typename,
          primaryFranchiseUserId: profile.userId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    console.log(data);
    if (data.status) {
      getFranchiseLinkedShop();
      setModalShow(false);
      setSubShopProfile({});
      setSubShopId("");
    }
  };
  const updateProfile = async (rowData) => {
    console.log(rowData, "manual test");
    // update sub shops profile

    rowData.imageFile = imageFile ?? [];

    Promise.all([
      fetch(`${config.ApiGateway}/editProfile`, {
        method: "POST",
        body: JSON.stringify(rowData),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        getFranchiseLinkedShop();
        setImageFile([]);
      })
      .catch((err) => console.log(err));
  };

  const enablePartnerAction = async (activeToggle) => {
    let partnerAction = !activeToggle;

    var dataObj = {};
    if (websiteTemplate) {
      dataObj = {
        ...websiteTemplate,
        ourPartner: {
          active: partnerAction,
          templateId: "default",
          pageProps: {},
        },
        order: [...new Set([...websiteTemplate.order, "ourPartner"])],
      };
    } else {
      dataObj = {
        colorTheme: "default",
        order: [
          "aboutUs",
          "about",
          "onlineProduct",
          "ourServices",
          "ourProjects",
          "ourBrands",
          "ourClients",
          "ourGallery",
          "clientTestimonials",
          "videoGallery",
          "socialMedia",
          "ourPartner",
          "ourTeam",
        ],
        ourPartner: {
          active: partnerAction,
          templateId: "default",
          pageProps: {},
        },
      };
    }
    setActive(!activeToggle);

    await Promise.all([
      fetch(`${config.ApiGateway}/ownDomainContentUpload`, {
        method: "POST",
        body: JSON.stringify({
          obj: dataObj,
          userId: shopDetails.userId10Digit,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res]) => Promise.all([res.json()]))
      .then(async ([data]) => {
        console.log(data);

        // alert("Changes Updated");
      })
      .catch((err) => {
        console.log("updating A Record Error", err);
        alert("error while uploading data. Please try again");
      });
  };

  return (
    <>
      <h1>Shops</h1>
      <div className="vertical-space-40"></div>
      <Row>
        <Col sm="12">
          <Form onSubmit={(e) => ManageSecondaryShops(e)}>
            <Form.Row>
              <Col sm="4">
                <Form.Label>Add New Shop</Form.Label>
              </Col>
              <Col sm="6">
                <Form.Control
                  onChange={(e) => setSubShopId(e.target.value)}
                  value={subShopId}
                />
                {linked[linkedStatus] && (
                  <Form.Text style={{ color: "red" }}>
                    {linked[linkedStatus]}
                  </Form.Text>
                )}
              </Col>
              <Col sm="2">
                <Button type="submit">Search</Button>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      <div className="vertical-space-40"></div>
      <div>
        <strong>
          <label>Enable Partner</label>
        </strong>{" "}
        <FormControlLabel
          control={
            <Switch
              checked={active}
              onChange={(e) => {
                enablePartnerAction(active);
              }}
              color="primary"
              value={active}
            />
          }
        ></FormControlLabel>
        {/* <Button onClick={() => enablePartnerAction()}>Save</Button> */}
      </div>
      <div className="vertical-space-40"></div>
      <MaterialTable
        title={null}
        editable={{
          onRowUpdateCancelled: (rowData) => setImageFile([]),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                let input = Object.assign(
                  {},
                  {
                    shopName: newData.shopName,
                    shopAddress: newData.shopAddress,
                    userPincode: newData.userPincode,
                    shopType: newData.shopType,
                    shopDescription: newData.shopDescription,
                    userName: newData.userName,
                    ytLink: newData.ytLink,
                    shopImageLocation: newData.shopImageLocation,
                    userId: newData.userId,
                  }
                );

                if (input.shopName === "") return alert("Enter Shop Name");

                if (input.shopAddress === "")
                  return alert("Enter Shop Address");
                if (input.shopType === "") return alert("Enter Shop Type");
                if (input.userPincode === "") return alert("Enter Pincode");
                if (
                  !(
                    /^\d+$/.test(input.userPincode) &&
                    input.userPincode.length == 6
                  )
                )
                  return alert("Enter valid pincode");
                if (!(input.shopImageLocation == "removed")) {
                  delete input.shopImageLocation;
                }

                updateProfile(input);
                resolve();
              }, 1000);
            }),
        }}
        columns={[
          {
            title: "Profile Image",
            field: "shopImageLocation",
            render: (rowData) => {
              if (
                typeof rowData.shopImageLocation != "undefined" &&
                rowData.shopImageLocation != "removed"
              ) {
                return rowData.shopImageLocation == "removed" ? (
                  <label>No Image Avaiable</label>
                ) : (
                  <img
                    src={rowData.shopImageLocation}
                    style={{
                      width: 150,
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      padding: "5px",
                    }}
                  />
                );
              } else {
                return <label>No Image Avaiable</label>;
              }
            },
            editComponent: (props) => {
              if (
                props.rowData.shopImageLocation != "removed" &&
                props.rowData.shopImageLocation != undefined
              ) {
                return (
                  <>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <img
                        src={
                          "data:image/png;base64," +
                          props.rowData.shopImageLocation
                        }
                        style={{
                          width: "150px",
                          height: "auto",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          padding: "5px",
                          position: "relative",
                        }}
                        onChange={() => props.onChange("removed")}
                      ></img>
                      <span
                        style={{
                          position: "absolute",

                          right: "3px",
                        }}
                        onClick={() => {
                          props.onChange("removed");
                        }}
                      >
                        <CancelOutlinedIcon
                          style={{
                            fontSize: 20,
                            backgroundColor: "blue",

                            color: "#fff",
                          }}
                        />
                      </span>
                    </div>
                  </>
                );
              } else {
                return (
                  <input
                    type="file"
                    onChange={(e) => {
                      let reader = new FileReader();
                      let imageFormData = new FormData();

                      imageFormData.append("photo", e.target.files[0]);
                      reader.onload = () => {
                        props.onChange(
                          reader.result.replace(/^data:image\/\w+;base64,/, "")
                        );
                        setImageFile(
                          reader.result.replace(/^data:image\/\w+;base64,/, "")
                        );
                      };
                      reader.readAsDataURL(e.target.files[0]);
                    }}
                  ></input>
                );
              }
            },
          },
          {
            title: "Ghoshak Id",
            field: "userId10Digit",
            editable: "never",
          },
          {
            title: "Used Id",
            field: "userId",
            hidden: true,
          },
          { title: "Shop Name", field: "shopName" },

          { title: "Shop Address", field: "shopAddress" },
          {
            title: "Shop Description",
            field: "shopDescription",
            cellStyle: {
              width: "400px",
              height: "300px",
              overflow: "hidden",
            },
          },
          { title: "Owner Name", field: "userName" },
          { title: "Ph No", field: "userPhoneNumber" },
          { title: "ytLink", field: "ytLink" },
          {
            title: "Access",
            field: "access",
            editable: "never",
            render: (rowData) => (
              <Button
                style={{ fontSize: 10 }}
                onClick={() => removeSubShop(rowData)}
                variant="danger"
              >
                Remove Account
              </Button>
            ),
          },
        ]}
        data={subShops}
        options={{
          exportButton: false,

          rowStyle: {
            fontSize: 12,
          },
          headerStyle: {
            fontSize: 12,
          },
        }}
        isLoading={tableLoading}
      />
      <Modal
        className="viewCartDetails"
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {Object.keys(subShopProfile).length > 0 && (
            <>
              <Row>
                <Col sm="12">
                  <Row>
                    <Col sm={"6"}>
                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label column sm="4">
                          Name
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            plaintext
                            value={subShopProfile.userName}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm={"6"}>
                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label column sm="4">
                          Phone number
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            plaintext
                            value={subShopProfile.userPhoneNumber}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm={"6"}>
                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label column sm="4">
                          Refferal Code
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            plaintext
                            value={subShopProfile.referralCode}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm={"6"}>
                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Form.Label column sm="4">
                          user id
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={subShopProfile.userId10Digit}
                            plaintext
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div id="confirmButtonBox" className="floatLeft">
                    <Button
                      onClick={() => addSubShop()}
                      style={{ marginRight: "10px" }}
                    >
                      Confirm
                    </Button>
                    <Button
                      onClick={() => {
                        setSubShopProfile({});
                        setSubShopId("");
                        setModalShow(false);
                      }}
                      variant="danger"
                    >
                      No
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
