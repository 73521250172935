import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Tab,
  Tabs,
  Modal,
} from "react-bootstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import config from "../config";
import ShopProfilecard from "../template/shopProfile";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function AddEmployee() {
  const classes = useStyles();
  const [modalShow, setModalShow] = useState(false); // modal state / true,false

  const [userId10Digit, setUserId10Digit] = useState(""); // Primary shop Id
  const [subUserId10Digit, setSubUserId10Digit] = useState(""); // secondary shop Id
  const [showShopDetails, setShowShopDetails] = useState(false); // Modal for secondary shop verification
  const [primaryShopObject, setPrimaryShopObject] = useState({}); // store primary shopid
  const [employeeList, setEmployeeList] = useState([]); // Employee list of primary shop
  const [subErr, setSubErr] = useState(false); // Error state
  const [secondaryShopObject, setSecondaryShopObject] = useState({}); //store state of secondary shop Object
  const handleClose = () => setModalShow(false); // modal state

  const findShop = async (e) => {
    e.preventDefault();

    await getShop();
  };
  const getShop = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/getShopAndEmployee`, {
        method: "POST",
        body: JSON.stringify({
          userId10Digit: userId10Digit, // shopid
          typename: "primary", // to get main shop
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        if (typeof data == "object") {
          if (data.shop.length > 0) {
            let shopProfile = data.shop[0]; // shop Profile
            setShowShopDetails(true); // display shop details
            setPrimaryShopObject(shopProfile); // update state of shop
            setEmployeeList(data.employee); // get employee details
          }
        }
      })
      .catch((err) => console.log("error in getting shop", err));
  };
  const getSubEmployee = async (e) => {
    // verify user is present or not
    e.preventDefault();
    await Promise.all([
      fetch(`${config.ApiGateway}/getShopAndEmployee`, {
        method: "POST",
        body: JSON.stringify({
          userId10Digit: subUserId10Digit, // sub employee shop id
          typename: "secondary", // to get sub shop
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        if (data.sub.length > 0) {
          // array is greater than 0

          let subShop = data.sub[0]; // sub employee profile , type - object
          setSecondaryShopObject(subShop); // update state sub employee
          setSubErr(false); // error state false
        } else {
          setSubErr(true); // true - shows error
        }
      })
      .catch((err) => console.log("error in getting shop", err));
  };
  const subEmployeeAction = (secondaryUserId, primaryUserId) => {
    // secondaryUserId - secondary shop id , primaryUserId - primaryShopid
    Promise.all([
      fetch(`${config.ApiGateway}/addEmployee`, {
        method: "POST",
        body: JSON.stringify({
          userId: secondaryUserId, // sub employee shopId
          ownerShopId: primaryUserId, // to get sub shop
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert("updated sucessfully"); // alert status
        getShop(); // to get updated shop ddetails
        handleClose(); // To close modal
      })
      .catch((err) => console.log("error in getting shop", err));
  };

  return (
    <Container>
      <div style={{ width: "100%", height: "30px" }}></div>
      <div className="orderHeader">
        <h2 className="orderHeaderText">Add Employee</h2>
      </div>
      <Row>
        <Col className="dashboardColumnBox columnCenter" sm="10">
          <Form onSubmit={findShop}>
            <Form.Row>
              <Col sm="4">
                <Form.Label>Enter Shop Id</Form.Label>
              </Col>
              <Col sm="6">
                <Form.Control
                  onChange={(e) => setUserId10Digit(e.target.value)}
                  value={userId10Digit}
                />
              </Col>
              <Col sm="2">
                <Button type="submit">Search</Button>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      <div className="vertical-space-30"></div>
      {showShopDetails && (
        <>
          <Row>
            <Col className="columnCenter" sm="10">
              <ShopProfilecard shopInfo={primaryShopObject} />
            </Col>
          </Row>
          <Row>
            <Col className="columnCenter" sm="10">
              <div className="rowFlex">
                <h4>Sub Employee</h4>
                <Button onClick={() => setModalShow(true)}>Add Employee</Button>
              </div>
              <TableContainer>
                <Table
                  className={classes.table}
                  style={{ border: "1px dotted black" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Employee Name</TableCell>
                      <TableCell>Id</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {employeeList.map((elements) => (
                      <TableRow>
                        <TableCell>{elements.userName}</TableCell>
                        <TableCell>{elements.userId10Digit}</TableCell>
                        <TableCell>
                          <Button
                            onClick={() =>
                              subEmployeeAction(elements.userId, "NA")
                            }
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
          </Row>

          <Modal
            className="viewCartDetails"
            show={modalShow}
            onHide={handleClose}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Row>
                <Col className="dashboardColumnBox columnCenter" sm="10">
                  <Form onSubmit={getSubEmployee}>
                    <Form.Row>
                      <Col sm="4">
                        <Form.Label>Enter Sub user Id</Form.Label>
                      </Col>
                      <Col sm="6">
                        <Form.Control
                          onChange={(e) => setSubUserId10Digit(e.target.value)} // enter sub employee shop Id
                          value={subUserId10Digit}
                        />
                      </Col>
                      <Col sm="2">
                        <Button type="submit">Search</Button>
                      </Col>
                      <Form.Text style={{ display: subErr ? "" : "none" }}>
                        Shop Not Found
                      </Form.Text>
                    </Form.Row>
                  </Form>
                </Col>
              </Row>
              <div className="vertical-space-5"></div>

              <Row
                style={{
                  display:
                    Object.keys(secondaryShopObject).length > 0 ? "" : "none", //
                }}
                className="rowFlex"
              >
                <Col className="columnCenter" sm="10">
                  <Row>
                    <Col>
                      <label>Name : </label>
                      <p>{secondaryShopObject.userName}</p>
                    </Col>
                    <Col>
                      <label>ID : </label>
                      <p>{secondaryShopObject.userId10Digit}</p>
                    </Col>
                    <Col>
                      <label>Phone Number: </label>
                      <p>{secondaryShopObject.userPhoneNumber}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{
                  display:
                    Object.keys(secondaryShopObject).length > 0 ? "" : "none",
                }}
              >
                <Col className="columnCenter" sm="10">
                  <Button
                    style={{ float: "right" }}
                    onClick={
                      () =>
                        subEmployeeAction(
                          secondaryShopObject.userId,
                          primaryShopObject.userId
                        ) // parameter 1 - sub employee shop id, primary shop user id
                    }
                  >
                    Add Employee
                  </Button>
                </Col>
              </Row>

              <div className="vertical-space-5"></div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </Container>
  );
}
