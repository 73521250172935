import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Dropdown from "../dropDown";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import config from "../config";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class OfferDistribution extends Component {
  constructor(props) {
    super(props);
    this.monthArray = [
      { id: "01", monthName: "JAN" },
      { id: "02", monthName: "FEB" },
      { id: "03", monthName: "MAR" },
      { id: "04", monthName: "APR" },
      { id: "05", monthName: "MAY" },
      { id: "06", monthName: "JUNE" },
      { id: "07", monthName: "JULY" },
      { id: "08", monthName: "AUG" },
      { id: "09", monthName: "SEPT" },
      { id: "10", monthName: "OCT" },
      { id: "11", monthName: "NOV" },
      { id: "12", monthName: "DEC" },
    ];
    this.state = {
      offerDetails: [],
      show: false,
      startDate: "2019-12-16",
      endDate: new Date().toISOString().substr(0, 10),
      segment: {},
      headers: [],
      statusBar: "All",
      offerType: "offer",
      statusArray: [],
      minValue: 2,
    };
    this.sortByDate = this.sortByDate.bind(this);
    this.getDailyStatus = this.getDailyStatus.bind(this);
    this.csvLink = React.createRef();
    this.export = this.export.bind(this);
    this.getSearchResult = this.getSearchResult.bind(this);
    this.renderTableRows = this.renderTableRows.bind(this);
  }
  renderTableRows() {
    let tableHeader = [];
    for (var i = 0; i < 6; i++) {
      tableHeader.push(
        <>
          <TableCell
            style={{
              textAlign: "center",
              borderLeft: 0.5 + "px",
              borderLeftColor: "#ced4da",
              borderLeftStyle: "solid",
            }}
          >
            LIVE
          </TableCell>
          <TableCell
            style={{
              textAlign: "center",
              borderRight: 0.5 + "px",
              borderRightColor: "#ced4da",
              borderRightStyle: "solid",
            }}
          >
            WILL EXPIRE
          </TableCell>
        </>
      );
    }
    return tableHeader;
  }
  getSearchResult(e) {
    e.preventDefault();
    this.setState({
      show: false,
    });

    let store_six_months = [];

    let currentDate = new Date().toISOString().substring(0, 10);
    let currentMonth = new Date().toISOString().substring(5, 7);
    let loop = new Date(currentDate);

    for (var i = 0; i < 6; i++) {
      let matchDate = loop.toISOString().substring(0, 10);
      store_six_months.push(matchDate.substring(0, 10));

      var newDate = loop.setMonth(loop.getMonth() + 1);
      loop = new Date(newDate);
    }

    Promise.all([
      fetch(
        `${config.ApiGateway}/offerdistribution`,

        {
          method: "POST",
          body: JSON.stringify({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            statusBar: this.state.statusBar,
            offerType: "offer",
          }),

          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let offerList = data.map((elements) => {
          let tempArray = [];
          for (var i = 0; i < store_six_months.length; i++) {
            let liveOffers = 0;
            let willExpireOffers = 0;
            let getMonthName = this.monthArray.find(
              (items) => store_six_months[i].substring(5, 7) === items.id
            );
            elements.expiryDate.forEach((items) => {
              if (
                store_six_months[i].substring(0, 7) === items.substring(0, 7)
              ) {
                liveOffers++;
                willExpireOffers++;
              } else if (
                new Date(store_six_months[i]).toISOString().substring(0, 10) <
                new Date(items).toISOString().substring(0, 10)
              ) {
                liveOffers++;
              }
            });
            tempArray.push({
              month: getMonthName.monthName,
              willExpire: willExpireOffers,
              currentlyAvailable: liveOffers,
            });
          }
          let appendobject = {};
          appendobject = Object.assign({}, elements);
          appendobject.couponTimeline = tempArray;
          return appendobject;
        });

        return this.setState({
          offerDetails: offerList,
          show: true,
        });
      })
      .catch((err) => alert("function call failed"));
  }
  export() {
    const headers = [
      { label: "PINCODE", key: "pincode" },

      { label: "TEXTILE", key: "Textile" },
      { label: "SALOON", key: "Saloon" },
      { label: "OPTICALS", key: "Opticals" },
      { label: "BEAUTY PARLOUR", key: "BeautyParlour" },

      {
        label: "KIRANA",
        key: "kirana",
      },

      { label: "MOBILE", key: "Mobile" },
      { label: "TOTAL OFFERS", key: "totalOffers" },
    ];

    this.setState(
      {
        headers: headers,
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }

  async componentDidMount() {
    try {
      const response = await fetch(
        `${config.json.URL}/segmentdistribution.json`
      );
      const json = await response.json();
      //  JSON  -  contains segment with minimum offer count
      this.setState({ segment: json });
    } catch (error) {
      console.log(error);
    }
    const statusArray = Dropdown.json.map((elements) => {
      return <option key={elements}>{elements}</option>;
    });
    // statusArray  -  live, draft, rejected, deleted and non deleted

    this.setState({
      statusArray: statusArray,
    });

    await this.getDailyStatus();
  }
  sortByDate(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  getDailyStatus() {
    let store_six_months = [];

    let currentDate = new Date().toISOString().substring(0, 10); // 19-03-2021  (yyyy-mm-dd)
    let currentMonth = new Date().toISOString().substring(5, 7); //get month 03
    let loop = new Date(currentDate);

    for (var i = 0; i < 6; i++) {
      let matchDate = loop.toISOString().substring(0, 10);
      store_six_months.push(matchDate.substring(0, 10));

      var newDate = loop.setMonth(loop.getMonth() + 1); // add + 1 month  19-3,19-04,
      loop = new Date(newDate);
      // Terminates loop after getting next 6 month date
    }
    Promise.all([
      fetch(
        `${config.ApiGateway}/offerdistribution`,

        {
          method: "POST",
          body: JSON.stringify({
            startDate: this.state.startDate, // offer posted start date
            endDate: this.state.endDate, // offer posted end date
            statusBar: this.state.statusBar,
            offerType: "offer", // offerType  - offer , item(collection)
          }),

          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        // data - offer posted by shop

        let offerList = data.map((elements) => {
          let tempArray = [];
          for (var i = 0; i < store_six_months.length; i++) {
            let liveOffers = 0;
            let willExpireOffers = 0;
            let getMonthName = this.monthArray.find(
              (items) => store_six_months[i].substring(5, 7) === items.id
            );
            elements.expiryDate.forEach((items) => {
              if (
                store_six_months[i].substring(0, 7) === items.substring(0, 7)
              ) {
                liveOffers++;
                willExpireOffers++;
              } else if (
                new Date(store_six_months[i]).toISOString().substring(0, 10) <
                new Date(items).toISOString().substring(0, 10)
              ) {
                liveOffers++;
              }
            });
            tempArray.push({
              month: getMonthName.monthName,
              willExpire: willExpireOffers,
              currentlyAvailable: liveOffers,
            });
          }
          let appendobject = {};
          appendobject = Object.assign({}, elements);
          appendobject.couponTimeline = tempArray;
          return appendobject;
        });

        return this.setState({
          offerDetails: offerList,
          show: true,
        });
      })
      .catch((err) => alert("function call failed"));
  }
  render() {
    const { segment } = this.state;
    const { classes } = this.props;
    return (
      <Container fluid>
        {this.state.show ? (
          <>
            <Row>
              <Col>
                <div className="headerName" style={{ marginBottom: 3 + "%" }}>
                  <h3 style={{ textAlign: "center" }}>
                    PINCODE SEGMENT AND OFFER DISTRIBUTION{" "}
                  </h3>
                </div>
                <Form onSubmit={this.getSearchResult}>
                  <Form.Group controlId="formBasicEmail">
                    <Row>
                      <Form.Label>START DATE</Form.Label>
                      <Col>
                        <Form.Control
                          type="date"
                          name="startDate"
                          value={this.state.startDate}
                          onChange={this.sortByDate}
                        />
                      </Col>
                      <Form.Label>END DATE </Form.Label>
                      <Col>
                        <Form.Control
                          type="date"
                          name="endDate"
                          value={this.state.endDate}
                          onChange={this.sortByDate}
                        />
                      </Col>
                      <Form.Label>Minimum Count</Form.Label>
                      <Col>
                        <Form.Control
                          name="minValue"
                          value={this.state.minValue}
                          onChange={this.sortByDate}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          as="select"
                          name="statusBar"
                          onChange={this.sortByDate}
                          value={this.state.statusBar}
                        >
                          <option value="">OFFER STATUS</option>
                          {this.state.statusArray}
                        </Form.Control>
                      </Col>

                      <Col>
                        <Button type="submit">SEARCH</Button>
                      </Col>
                      <Col md={{}}>
                        <Button
                          onClick={() => {
                            this.export();
                          }}
                        >
                          DOWNLOAD
                        </Button>
                        <CSVLink
                          enclosingCharacter={`'`}
                          data={this.state.offerDetails}
                          headers={this.state.headers}
                          ref={this.csvLink}
                          target="_blank"
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>

                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid",
                  }}
                  className={classes.table}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={12}></TableCell>
                      <TableCell
                        align="center"
                        colSpan={12}
                        className={classes.tableCell}
                      >
                        OFFER AVAILABILITY
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell}>S.NO</TableCell>
                      <TableCell className={classes.tableCell}>
                        PINCODE
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        AREA NAME
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        KIRANA
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        TEXTILE
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        SALOON
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        BEAUTY PARLOUR
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        MOBILE
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        SWEETS
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        TOTAL OFFERS
                      </TableCell>
                      {this.state.offerDetails.slice(-1).map((element, index) =>
                        element.couponTimeline.map((items) => (
                          <>
                            <TableCell
                              colSpan={2}
                              className={classes.tableCell}
                              style={{
                                textAlign: "center",
                                borderLeft: 0.5 + "px",
                                borderLeftColor: "#ced4da",
                                borderLeftStyle: "solid",
                              }}
                            >
                              {items.month}
                            </TableCell>
                          </>
                        ))
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={10}></TableCell>
                      {this.renderTableRows()}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.offerDetails.map((elements, index) => (
                      <TableRow key={elements.pincode}>
                        <TableCell className={classes.tableCell}>
                          {index + 1}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {elements.pincode}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {elements.place}
                        </TableCell>

                        <TableCell
                          style={{
                            textAlign: "center",
                            backgroundColor:
                              elements.Supermarket <
                                // parseInt(segment.Grocery.Minimum)
                                parseInt(this.state.minValue) || 0
                                ? segment.Grocery.Minimumcolor
                                : segment.Grocery.Maximumcolor,
                          }}
                        >
                          {parseInt(elements.kirana) || 0}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            backgroundColor:
                              elements.Textile <
                                // parseInt(segment.Textile.Minimum)
                                parseInt(this.state.minValue) || 0
                                ? segment.Textile.Minimumcolor
                                : segment.Textile.Maximumcolor,
                          }}
                        >
                          {elements.Textile}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            backgroundColor:
                              elements.Saloon <
                                // parseInt(segment.Saloon.Minimum)
                                parseInt(this.state.minValue) || 0
                                ? segment.Saloon.Minimumcolor
                                : segment.Saloon.Maximumcolor,
                          }}
                        >
                          {elements.Saloon}
                        </TableCell>

                        <TableCell
                          style={{
                            textAlign: "center",
                            backgroundColor:
                              elements.BeautyParlour <
                                // parseInt(segment.BeautyParlour.Minimum)
                                parseInt(this.state.minValue) || 0
                                ? segment.BeautyParlour.Minimumcolor
                                : segment.BeautyParlour.Maximumcolor,
                          }}
                        >
                          {elements.BeautyParlour}
                        </TableCell>

                        <TableCell
                          style={{
                            textAlign: "center",
                            backgroundColor:
                              elements.Mobile <
                                // parseInt(segment.Mobile.Minimum)
                                parseInt(this.state.minValue) || 0
                                ? segment.Mobile.Minimumcolor
                                : segment.Mobile.Maximumcolor,
                          }}
                        >
                          {elements.Mobile}
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            backgroundColor:
                              elements.Sweets <
                                // parseInt(segment.Gym.Minimum)
                                parseInt(this.state.minValue) || 0
                                ? segment.Gym.Minimumcolor
                                : segment.Gym.Maximumcolor,
                          }}
                        >
                          {elements.Sweets}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {elements.totalOffers}
                        </TableCell>
                        {elements.couponTimeline.map((items) => (
                          <>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                              style={{
                                textAlign: "center",
                                borderLeft: 0.5 + "px",
                                borderLeftColor: "#ced4da",
                                borderLeftStyle: "solid",
                              }}
                              key={items.currentlyAvailable}
                            >
                              {items.currentlyAvailable}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                              style={{
                                textAlign: "center",
                                borderRight: 0.5 + "px",
                                borderRightColor: "#ced4da",
                                borderRightStyle: "solid",
                              }}
                            >
                              {items.willExpire}
                            </TableCell>
                          </>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Col>
            </Row>
          </>
        ) : (
          "LOADING"
        )}
      </Container>
    );
  }
}
OfferDistribution.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OfferDistribution);
