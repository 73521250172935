import React, { Component } from "react";
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  MenuItem,
} from "@material-ui/core";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  LabelList,
  Legend,
} from "recharts";
import config from "../../config";
import { Link } from "react-router-dom";
import limit from "../../limit";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 4,
  },
});
class MerchantDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantid: "",
      offerDetails: [],
      userDetails: [],
      userName: "",
      showdata: true,
      message: "",
      offerHistory: [],
      AddedCustomer: "",
      showTable: false,
      renderTable: [],
      heading: [],
      couponHistory: [],
      headerName: "",
      showComponent: true,
      loyalty: {},
      todayDate: new Date().toISOString().substring(0, 10),
      membershipList: {},
      billingTracker: [],
      editProfileToggle: false,
      editProfile: {
        shopImageLocation: "",
        shopName: "",
        shopAddress: "",
        shopType: "",
        userPincode: "",
      },
      imageFile: [],
      imagePreviewUrl: [],
      editProfileText: false,
      totalSegement: [],
    };
    this.reset = this.state;
    this.merchantFetch = this.merchantFetch.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getCoupons = this.getCoupons.bind(this);
    this.getHistory = this.getHistory.bind(this);
    this.getCustomer = this.getCustomer.bind(this);
    this.showRedeemedCoupons = this.showRedeemedCoupons.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.handleimage = this.handleimage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.editProfileHandler = this.editProfileHandler.bind(this);
    this.editprofileTextHandler = this.editprofileTextHandler.bind(this);
    this.editProfileOnChange = this.editProfileOnChange.bind(this);
  }
  editProfileOnChange = (e) => {
    this.setState({
      editProfile: {
        ...this.state.editProfile,
        [e.target.name]: e.target.value,
      },
    });
  };
  removeImage() {
    this.setState({
      imageFile: [],
      imagePreviewUrl: [],
      editProfileToggle: false,
      editProfile: { ...this.state.editProfile, shopImageLocation: "" },
    });
  }
  editprofileTextHandler = () => {
    this.setState({
      editProfileText: !this.state.editProfileText,
    });
  };
  editProfileHandler() {
    this.setState({
      editProfileToggle: !this.state.editProfileToggle,
    });
  }
  handleimage(event) {
    let reader = new FileReader();
    let imageFormData = new FormData();
    imageFormData.append("photo", event.target.files[0]);
    reader.onloadend = () => {
      this.setState({
        imageFile: reader.result.replace(/^data:image\/\w+;base64,/, ""),
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(event.target.files[0]);
  }
  editProfile = () => {
    if (
      this.state.userDetails[0].userId != "" &&
      this.state.userDetails[0].userId !== undefined &&
      this.state.userDetails[0].userId !== null
    ) {
      if (this.state.editProfile.shopName === "")
        return alert("Enter Shop Name");

      if (this.state.editProfile.shopAddress === "")
        return alert("Enter Shop Address");
      if (this.state.editProfile.shopType === "")
        return alert("Enter Shop Type");
      if (this.state.editProfile.userPincode === "")
        return alert("Enter Pincode");
      Promise.all([
        fetch(`${config.ApiGateway}/editProfile`, {
          method: "POST",
          body: JSON.stringify({
            imageFile: this.state.imageFile,

            shopAddress: this.state.editProfile.shopAddress,
            shopName: this.state.editProfile.shopName,
            shopType: this.state.editProfile.shopType,
            userId: this.state.userDetails[0].userId,
            userPincode: this.state.editProfile.userPincode,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ])
        .then(([res1]) => Promise.all([res1.json()]))
        .then(([data]) => {
          console.log(data);
          this.setState({
            imageFile: [],
            imagePreviewUrl: [],
            editProfileText: false,
          });
          alert("profile updated");
          this.getUser();
        })
        .catch((err) => console.log(err));
    } else {
      return alert("Profile cannot be updated reference id is missing");
    }
  };
  showRedeemedCoupons(couponid) {
    this.props.getRedeemedCoupons(couponid);
  }
  getCustomer(customerId) {
    this.props.info(customerId);
  }

  componentWillUnmount() {
    this.setState({
      merchantid: "",
    });
  }
  componentDidMount() {
    if (this.props.userId !== "") {
      this.setState({
        merchantid: this.props.userId,
        showComponent: false,
      });
      this.getUser();
    }
  }
  getUser() {
    var tempuserId = this.props.userId;
    let tempuserDetails = [];

    Promise.all([
      fetch(
        `${config.Elasticsearch.URL}/users/_search?size=10000`,

        {
          method: "POST",
          body: JSON.stringify({
            query: {
              term: {
                userId10Digit: tempuserId,
              },
            },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
      fetch(`${config.ApiGateway}/getMembershipLimit`, {
        method: "POST",
      }),
      fetch(`${config.json.URL}/fields.json`),
    ])
      .then(([res1, res2, res3]) =>
        Promise.all([res1.json(), res2.json(), res3.json()])
      )
      .then(([data, membership, segment]) => {
        tempuserDetails = data.hits.hits.map((element) => element._source);

        let getSegment = [];
        segment.shopTypes.slice(1).forEach((elements) => {
          getSegment.push(
            <MenuItem key={elements.value} value={elements.value}>
              {elements.value}
            </MenuItem>
          );
        });
        if (tempuserDetails.length > 0) {
          var shopId = tempuserDetails.map(({ userId }) => userId);
          var userName = tempuserDetails.map(({ userName }) => userName);

          this.setState({
            userName: userName,
            userDetails: tempuserDetails,
            merchantid: this.props.userId,
            totalSegement: getSegment,
            editProfile: {
              ...this.state.editProfile,
              shopAddress: tempuserDetails[0].shopAddress,
              shopName: tempuserDetails[0].shopName,
              shopImageLocation: tempuserDetails[0].shopImageLocation,
              shopType: tempuserDetails[0].shopType,
              userPincode: tempuserDetails[0].userPincode,
            },
          });

          this.getCoupons(shopId, tempuserDetails, membership);
        } else {
          this.setState({
            message: "ENTER VALID USER ID",
          });
        }
      })
      .catch((err) => {
        this.setState({
          message: "ENTER VALID USER ID",
        });
      });
  }
  merchantFetch(e) {
    e.preventDefault();
    let tempuserDetails = [];

    Promise.all([
      fetch(
        `${config.Elasticsearch.URL}/users/_search?size=10000`,

        {
          method: "POST",
          body: JSON.stringify({
            query: {
              bool: {
                should: [
                  {
                    term: {
                      userPhoneNumber: this.state.merchantid, // shop id
                    },
                  },
                  {
                    term: {
                      userId10Digit: this.state.merchantid,
                    },
                  },
                ],
                minimum_should_match: 1,
                filter: {
                  match: {
                    userType: "Shop",
                  },
                },
              },
            },
          }),

          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
      fetch(`${config.ApiGateway}/getMembershipLimit`, {
        method: "POST",
      }),
      fetch(`${config.json.URL}/fields.json`),
    ])
      .then(([res1, res2, res3]) =>
        Promise.all([res1.json(), res2.json(), res3.json()])
      )
      .then(([data, membership, segment]) => {
        tempuserDetails = data.hits.hits.map((element) => element._source);
        let getSegment = [];
        segment.shopTypes.slice(1).forEach((elements) => {
          getSegment.push(
            <MenuItem key={elements.value} value={elements.value}>
              {elements.value}
            </MenuItem>
          );
        });
        if (tempuserDetails.length > 0) {
          var shopId = tempuserDetails.map(({ userId }) => userId);
          var userName = tempuserDetails.map(({ userName }) => userName);

          this.setState({
            userName: userName,
            userDetails: tempuserDetails,
            totalSegement: getSegment,
            editProfile: {
              ...this.state.editProfile,
              shopAddress: tempuserDetails[0].shopAddress,
              shopName: tempuserDetails[0].shopName,
              shopImageLocation: tempuserDetails[0].shopImageLocation,
              shopType: tempuserDetails[0].shopType,
              userPincode: tempuserDetails[0].userPincode,
            },
          });

          this.getCoupons(shopId, tempuserDetails, membership);
        } else {
          this.setState({
            message: "ENTER VALID USER ID",
          });
        }
      })
      .catch((err) => {
        this.setState({
          message: "ENTER VALID USER ID",
        });
      });
  }
  getCoupons(shopId, tempuserDetails, membership) {
    let tempdata = [];
    let couponTempData = [];
    let AddedCustomer = "";
    let loyaltyPackage = {
      status: "",
      exsistingCustomer: [],
      customers: {},
      ratio: "",
      updatedOn: "",
      expiry: "",
      remainingDays: "",
      loyaltyAmount: "",
      loyaltyPoints: "",
    };
    let membershipPackage = {
      package: "",
      remainingDays: "",
      id: "",
      updatedOn: "",
      expiry: "",
    };
    // offers
    Promise.all([
      fetch(
        `${config.Elasticsearch.URL}/offers/_search?size=10000`,

        {
          method: "POST",
          body: JSON.stringify({
            query: {
              bool: {
                filter: [
                  {
                    terms: {
                      shopId: shopId,
                    },
                  },
                ],
              },
            },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
      // coupons
      fetch(
        `${config.Elasticsearch.URL}/coupons/_search?size=10000`,

        {
          method: "POST",
          body: JSON.stringify({
            query: {
              bool: {
                filter: [
                  {
                    terms: {
                      shopId: shopId,
                    },
                  },
                  {
                    match: {
                      couponCategory: "Created",
                    },
                  },
                ],
              },
            },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
      // points - loyalty & ghoshak
      fetch(
        `${config.Elasticsearch.URL}/points/_search?size=10000`,

        {
          method: "POST",
          body: JSON.stringify({
            query: {
              bool: {
                must: [
                  {
                    match: {
                      shopId: tempuserDetails[0].userId,
                    },
                  },
                  {
                    match: {
                      pointsType: "loyalty",
                    },
                  },
                ],
              },
            },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1, res2, res3]) =>
        Promise.all([res1.json(), res2.json(), res3.json()])
      )
      .then(([data, data2, loyalty]) => {
        tempdata = data.hits.hits.map((element) => element._source); // get shop
        couponTempData = data2.hits.hits.map((element) => element._source);
        let loyaltyTempData = loyalty.hits.hits.map(
          (element) => element._source
        ); // get points

        let billingTracker = [
          { employee: [], tax: [], billingPackage: "", billingExpiryDate: "" },
        ];
        // tempuserDetails  - shop details
        tempuserDetails.map((elements) => {
          // Loyalty
          loyaltyPackage.status = elements.loyaltyPackage;
          loyaltyPackage.ratio = elements.loyaltyRatio;
          loyaltyPackage.updatedOn = elements.loyaltyupdatedOn;
          loyaltyPackage.expiry = elements.loyaltyExpiryDate;

          loyaltyPackage.loyaltyAmount = elements.loyaltyAmount;
          loyaltyPackage.loyaltyPoints = elements.loyaltyPoints;

          AddedCustomer = Object.keys(elements.customerPhoneNumberMap).length; // loyalty added customer

          let getMembershipPackage = membership.find(
            (headers) => headers.membershipId === elements.membershipId
          ); // find membership
          if (getMembershipPackage !== undefined) {
            membershipPackage.package = getMembershipPackage.membershipType;
          }
          membershipPackage.updatedOn = elements.membershipupdatedOn;
          membershipPackage.id = elements.membershipId;
          membershipPackage.expiry = elements.membershipExpiry;
          // Billing

          billingTracker[0].billingExpiryDate = elements.billingExpiryDate;
          billingTracker[0].billingPackage = elements.billingPackage;
          if (elements.employee !== undefined) {
            Object.entries(elements.employee).map(([key, value]) => {
              billingTracker[0].employee.push(value.M.employeeName.S);
            });
          }
          if (elements.taxRate !== undefined) {
            Object.entries(elements.taxRate).map(([key, value]) => {
              billingTracker[0].tax.push({
                taxname: value.M.taxname.S,
                taxRate: value.M.taxRate.S,
              });
            });
          }
        });
        // Package Expiration
        // membership

        if (this.state.todayDate < membershipPackage.expiry) {
          let oneDay = 24 * 60 * 60 * 1000;

          let firstDate = new Date(this.state.todayDate);
          let secondDate = new Date(membershipPackage.expiry);

          membershipPackage.remainingDays = Math.round(
            Math.abs((firstDate - secondDate) / oneDay)
          );
        } else {
          membershipPackage.remainingDays = "Expired";
        }
        // Loyalty

        if (this.state.todayDate < loyaltyPackage.expiry) {
          let oneDay = 24 * 60 * 60 * 1000;

          let firstDate = new Date(this.state.todayDate);
          let secondDate = new Date(loyaltyPackage.expiry);

          loyaltyPackage.remainingDays = Math.round(
            Math.abs((firstDate - secondDate) / oneDay)
          );
        } else {
          loyaltyPackage.remainingDays = "Expired";
        }

        loyaltyTempData.map((elements) => {
          loyaltyPackage.exsistingCustomer.push(elements.custId);

          if (loyaltyPackage.customers[elements.custId] === undefined) {
            loyaltyPackage.customers[elements.custId] = {};
          }
          if (
            loyaltyPackage.customers[elements.custId][elements.pointsAction] ===
            undefined
          ) {
            loyaltyPackage.customers[elements.custId][
              elements.pointsAction
            ] = parseFloat(elements.points);
            if (
              loyaltyPackage.customers[elements.custId]["visit"] === undefined
            ) {
              loyaltyPackage.customers[elements.custId]["visit"] = 1;
            }
          } else {
            loyaltyPackage.customers[elements.custId][
              elements.pointsAction
            ] += parseFloat(elements.points);
            loyaltyPackage.customers[elements.custId]["visit"] += 1;
          }
        });
        loyaltyPackage.exsistingCustomer = [
          ...new Set(loyaltyPackage.exsistingCustomer),
        ];
        // Coupons
        let couponList = [
          {
            merchantCouponsConsumed: 0,
            merchantCouponsConsumedValue: 0,
            merchantCouponsCreated: 0,
            ghoshakCouponsConsumed: 0,
            ghoshakCouponsConsumedValue: 0,
            ghoshakCouponsCreated: 0,
            loyaltyCouponsConsumed: 0,
            loyaltyCouponsConsumedValue: 0,
            loyaltyCouponsCreated: 0,
            promoCouponsConsumed: 0,
            promoCouponsConsumedValue: 0,
            promoCouponsCreated: 0,
          },
        ];
        couponTempData.forEach((items) => {
          if (items.couponType === "MERCHANT") {
            couponList[0].merchantCouponsCreated += 1; //count
            couponList[0].merchantCouponsConsumed += // used
              parseInt(items.totalCouponCount) -
              parseInt(items.remainingCouponCount);
            couponList[0].merchantCouponsConsumedValue += // used cost
              (parseInt(items.totalCouponCount) -
                parseInt(items.remainingCouponCount)) *
              parseInt(items.maximumCouponAmount);
          } else if (items.couponType === "GHOSHAK") {
            couponList[0].ghoshakCouponsCreated += 1;
            couponList[0].ghoshakCouponsConsumed +=
              parseInt(items.totalCouponCount) -
              parseInt(items.remainingCouponCount);
            couponList[0].ghoshakCouponsConsumedValue +=
              (parseInt(items.totalCouponCount) -
                parseInt(items.remainingCouponCount)) *
              parseInt(items.maximumCouponAmount);
          } else if (items.couponType === "LOYALTY") {
            couponList[0].loyaltyCouponsConsumed +=
              parseInt(items.totalCouponCount) -
              parseInt(items.remainingCouponCount);
            couponList[0].loyaltyCouponsConsumedValue +=
              (parseInt(items.totalCouponCount) -
                parseInt(items.remainingCouponCount)) *
              parseInt(items.maximumCouponAmount);
            couponList[0].loyaltyCouponsCreated += 1;
          } else if (items.couponType === "PROMOCODE") {
            couponList[0].promoCouponsConsumed +=
              parseInt(items.promocodeCount) -
              parseInt(items.promocodeRemainingCount);
            couponList[0].promoCouponsConsumedValue +=
              (parseInt(items.promocodeCount) -
                parseInt(items.promocodeRemainingCount)) *
              parseInt(items.maximumCouponAmount);
            couponList[0].promoCouponsCreated += 1;
          }
        });
        // Coupons data graph
        let couponBarChart = [
          {
            name: "GHOSHAK COUPON",
            couponsConsumed: couponList[0].ghoshakCouponsConsumed,
            consumedValue: couponList[0].ghoshakCouponsConsumedValue,
            created: couponList[0].ghoshakCouponsCreated,
          },
          {
            name: "MERCHANT COUPON",
            couponsConsumed: couponList[0].merchantCouponsConsumed,
            consumedValue: couponList[0].merchantCouponsConsumedValue,
            created: couponList[0].merchantCouponsCreated,
          },
          {
            name: "LOYALTY COUPON",
            couponsConsumed: couponList[0].loyaltyCouponsConsumed,
            consumedValue: couponList[0].loyaltyCouponsConsumedValue,
            created: couponList[0].loyaltyCouponsCreated,
          },
          {
            name: "PROMOCODE COUPON",
            couponsConsumed: couponList[0].promoCouponsConsumed,
            consumedValue: couponList[0].promoCouponsConsumedValue,
            created: couponList[0].promoCouponsCreated,
          },
        ];
        // Offers graph
        let offerList = [
          {
            totalApprovedOffers: 0,
            totalRejectedOffers: 0,
            totalApprovedItems: 0,
            totalRejectedItems: 0,
          },
        ];
        tempdata.forEach((elements) => {
          if (
            elements.approvedStatus === "yes" &&
            elements.offerType === "offer"
          ) {
            offerList[0].totalApprovedOffers += 1;
          } else if (
            elements.approvedStatus === "no" &&
            elements.offerType === "offer"
          ) {
            offerList[0].totalRejectedOffers += 1;
          } else if (
            elements.approvedStatus === "yes" &&
            elements.offerType === "item"
          ) {
            offerList[0].totalApprovedItems += 1;
          } else if (
            elements.approvedStatus === "no" &&
            elements.offerType === "item"
          ) {
            offerList[0].totalRejectedItems += 1;
          }
        });
        var offersHistory = [[], []];

        tempdata.forEach((elements) => {
          if (elements.offerType === "offer") {
            offersHistory[0].push({
              offerDetails: elements.offerDetails,
              createdOn: elements.offerAddedDate,
              approvedStatus: elements.approvedStatus,
              shopType: elements.shopType,
              offerStatus:
                elements.offerStatus === "Live"
                  ? this.state.todayDate > elements.offerEndDate
                    ? "Expired"
                    : elements.offerStatus
                  : elements.offerStatus,
              addedToFavourite: Object.keys(elements.custumerAddedOfferToFav)
                .length,
              shopPincode: elements.shopPincode,
              offerOriginalPrice: elements.offerOriginalPrice,
              offerNewPrice: elements.offerNewPrice,
              offerReviewedBy: elements.reviewedBy,
              reviewedOn: elements.reviewedOn,
              reviewRejectedReason: elements.reviewRejectedReason,
              offerEndDate: elements.offerEndDate,
              offerImageLocation:
                elements.offerImageLocation !== null &&
                elements.offerImageLocation !== undefined &&
                elements.offerImageLocation !== ""
                  ? elements.offerImageLocation
                  : limit.size.defaultImageUrl,
            });
          } else if (elements.offerType === "item") {
            offersHistory[1].push({
              offerDetails: elements.offerDetails,
              createdOn: elements.offerAddedDate,
              approvedStatus: elements.approvedStatus,
              shopType: elements.shopType,
              addedToFavourite: Object.keys(elements.custumerAddedOfferToFav)
                .length,
              shopPincode: elements.shopPincode,
              offerOriginalPrice: elements.offerOriginalPrice,
              offerNewPrice: elements.offerNewPrice,
              offerReviewedBy: elements.reviewedBy,
              offerStatus: elements.offerStatus,
              reviewedOn: elements.reviewedOn,
              reviewRejectedReason: elements.reviewRejectedReason,
              offerImageLocation:
                elements.offerImageLocation !== null &&
                elements.offerImageLocation !== undefined &&
                elements.offerImageLocation !== ""
                  ? elements.offerImageLocation
                  : limit.size.defaultImageUrl,
              offerEndDate:
                elements.offerEndDate !== undefined
                  ? elements.offerEndDate
                  : "Non Expired",
            });
          }
        });
        var couponHistory = [];
        couponTempData.forEach((elements) => {
          couponHistory.push({
            couponType: elements.couponType,
            couponAddedOn: elements.addedDate,
            Details: elements.couponDetails,
            promoCode:
              elements.promocode !== undefined ? elements.promocode : " - ",
            totalCouponCount: parseInt(
              elements.totalCouponCount != undefined
                ? elements.totalCouponCount
                : elements.promocodeCount
            ),
            couponsUsed:
              parseInt(
                elements.totalCouponCount != undefined
                  ? elements.totalCouponCount
                  : elements.promocodeCount
              ) -
              parseInt(
                elements.remainingCouponCount != undefined
                  ? elements.remainingCouponCount
                  : elements.promocodeRemainingCount
              ),
            consumedValue:
              (parseInt(
                elements.totalCouponCount != undefined
                  ? elements.totalCouponCount
                  : elements.promocodeCount
              ) -
                parseInt(
                  elements.remainingCouponCount != undefined
                    ? elements.remainingCouponCount
                    : elements.promocodeRemainingCount
                )) *
              parseInt(elements.maximumCouponAmount),
            allocatedCost:
              parseInt(
                elements.totalCouponCount != undefined
                  ? elements.totalCouponCount
                  : elements.promocodeCount
              ) * parseInt(elements.maximumCouponAmount),
            addedToFav:
              elements.custumerAddedCouponToFav !== undefined
                ? Object.keys(elements.custumerAddedCouponToFav).length
                : 0,
            couponCreatedBy: elements.couponAddedby,

            minimumPoints:
              elements.minLoyaltyPointsReq | elements.minGhoshakPointsReq,
            offerImageLocation:
              elements.imageLocation !== null &&
              elements.imageLocation !== undefined &&
              elements.imageLocation !== ""
                ? elements.imageLocation
                : limit.size.defaultImageUrl,

            id: elements.couponId,
          });
        });

        this.setState({
          offerDetails: offerList,
          showdata: false,
          couponData: couponBarChart,
          offerHistory: offersHistory,
          couponDetails: couponList,
          couponHistory: couponHistory,
          loyalty: loyaltyPackage,
          AddedCustomer: AddedCustomer,
          membershipList: membershipPackage,
          showComponent: true,
          billingTracker: billingTracker,
        });
      })

      .catch((err) => {
        this.setState({
          message: "ENTER VALID USER ID",
        });
      });
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  getHistory(response, headerName) {
    var heading = response.map((items) =>
      Object.entries(items).map(([elements]) => {
        if (elements !== "offerImageLocation" && elements !== "id")
          return elements;
      })
    );
    heading = [...new Set(heading)];

    this.setState({
      renderTable: response,
      showTable: heading.length === 0 ? false : true,
      heading: heading[0],
      headerName: headerName,
    });
  }

  render() {
    const { userDetails } = this.state;
    const { classes } = this.props;

    console.log(this.state.editProfile);
    return (
      <>
        <Container>
          {this.state.showComponent && (
            <>
              {this.state.showdata ? (
                <>
                  <Typography
                    variant="h2"
                    style={{ textAlign: "center" }}
                    gutterBottom
                  >
                    {this.props.title}
                  </Typography>
                  <Row>
                    <Col sm={{ span: 4, offset: 4 }} className="division">
                      <h4 style={{ textAlign: "center" }}>ENTER MERCHANT ID</h4>
                      <Form onSubmit={this.merchantFetch}>
                        <Form.Control
                          type="text"
                          name="merchantid"
                          value={this.state.merchantid}
                          onChange={this.onChange}
                          required
                        ></Form.Control>
                        <Button
                          style={{
                            width: 100 + "%",
                            height: 30 + "%",
                            marginTop: 2 + "%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          type="submit"
                        >
                          {" "}
                          SEARCH
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                  <h3 style={{ textAlign: "center", color: "red" }}>
                    {" "}
                    {this.state.message}
                  </h3>
                </>
              ) : (
                <>
                  <Typography
                    variant="h2"
                    style={{ textAlign: "center" }}
                    gutterBottom
                  >
                    MERCHANT INFORMATION
                  </Typography>

                  <Row
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    className="division"
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                        marginBottom: "10px",
                      }}
                    >
                      {this.state.editProfileText ? (
                        <>
                          <div style={{ marginRight: "20px" }}>
                            <span onClick={() => this.editProfile()}>
                              <DoneIcon />
                            </span>
                          </div>
                          <div style={{ marginRight: "20px" }}>
                            <div onClick={() => this.editprofileTextHandler()}>
                              <ClearIcon />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div onClick={() => this.editprofileTextHandler()}>
                          <EditIcon />
                        </div>
                      )}
                    </div>
                    <Col sm={8}>
                      <Row style={{ width: "100%" }}>
                        <div className="merchantdetailsublabel">
                          <label>User Profile</label>
                        </div>
                        <Col
                          style={{ marginBottom: "5px" }}
                          className="splitRowEqually"
                        >
                          <TextField
                            label="Name"
                            name="userName"
                            disabled={true}
                            value={userDetails[0].userName}
                            required
                          />
                          <TextField
                            label="Pincode"
                            name="userPincode"
                            onChange={this.editProfileOnChange}
                            disabled={!this.state.editProfileText}
                            value={this.state.editProfile.userPincode}
                            required
                          />
                          <TextField
                            label="Phone Number"
                            name="userPhoneNumber"
                            disabled={true}
                            value={userDetails[0].userPhoneNumber}
                            required
                          />
                        </Col>
                      </Row>

                      <Row style={{ width: "100%" }}>
                        <div className="merchantdetailsublabel">
                          <label>Shop Profile</label>
                        </div>
                        <Col
                          style={{ marginBottom: "5px" }}
                          className="splitRowEqually"
                        >
                          <TextField
                            label="Shop Name"
                            name="shopName"
                            value={this.state.editProfile.shopName}
                            onChange={this.editProfileOnChange}
                            required
                            disabled={!this.state.editProfileText}
                          />
                          <TextField
                            label="Shop Address"
                            name="shopAddress"
                            onChange={this.editProfileOnChange}
                            value={this.state.editProfile.shopAddress}
                            disabled={!this.state.editProfileText}
                            required
                          />

                          <TextField
                            label="Shop Type"
                            name="shopType"
                            // disabled={true}
                            onChange={this.editProfileOnChange}
                            select
                            disabled={!this.state.editProfileText}
                            // value={userDetails[0].shopType}
                            value={this.state.editProfile.shopType}
                            required
                          >
                            {this.state.totalSegement}
                          </TextField>
                        </Col>
                      </Row>
                      <Row style={{ width: "100%" }}>
                        <div className="merchantdetailsublabel">
                          <label>Payment Information</label>
                        </div>
                        <Col
                          style={{ marginBottom: "5px" }}
                          className="splitRowEqually"
                        >
                          <TextField
                            label="Payment Method"
                            name="paymentMethod"
                            disabled={true}
                            value={userDetails[0].paymentMethod}
                            required
                          />

                          <TextField
                            label="Payment Number"
                            name="paymentNumber"
                            disabled={true}
                            value={userDetails[0].paymentNumber}
                            required
                          />
                          <TextField
                            name="AddedCustomer"
                            disabled={true}
                            value={userDetails[0].AddedCustomer}
                            required
                          />
                        </Col>
                      </Row>
                      <Row>
                        <div className="merchantdetailsublabel">
                          <label>Ghoshak Registered Id</label>
                        </div>
                        <Col
                          style={{ marginBottom: "5px" }}
                          className="splitRowEqually"
                        >
                          <TextField
                            label="User ID"
                            disabled={true}
                            value={userDetails[0].userId10Digit}
                            required
                          />
                          <TextField
                            label="Reference ID"
                            name="paymentNumber"
                            disabled={true}
                            value={userDetails[0].userId}
                            required
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={4}>
                      <div class="img-wrap">
                        {this.state.editProfileText ? (
                          <span
                            onClick={this.removeImage}
                            className="imageCancelPosition"
                          >
                            <CancelIcon />
                          </span>
                        ) : null}

                        <img
                          width={300}
                          height={200}
                          src={
                            this.state.editProfileText
                              ? this.state.imagePreviewUrl
                              : this.state.editProfile.shopImageLocation ||
                                "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/images/offers/default_offer_image.png"
                          }
                        ></img>
                      </div>
                      {this.state.editProfileText ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "column",
                              marginTop: "10px",
                            }}
                          >
                            <input
                              type="file"
                              onChange={this.handleimage}
                            ></input>
                            {/* <Form onSubmit={this.editProfile}>
                              <Button type="Submit" variant="secondary">
                                Update
                              </Button>
                            </Form> */}
                          </div>
                        </>
                      ) : null}
                    </Col>
                  </Row>

                  {/* <Row>
                  <Col sm={{ span: 3, offset: 1 }}>
                    <b>NAME</b>
                    <h5>{userDetails[0].userName}</h5>
                    <br />

                    <b>PINCODE</b>
                    <h5>{userDetails[0].userPincode}</h5>
                    <br />

                    <b>PHONE NUMBER</b>
                    <h5>{userDetails[0].userPhoneNumber}</h5>
                    <br />
                    <b>ADDRESS</b>
                    <h5>{userDetails[0].userAddress}</h5>

                    <br />
                    <b>USER ID</b>
                    <h5>{userDetails[0].userId10Digit}</h5>

                    <br />
                  </Col>
                  <Col sm={{ span: 3, offset: 1 }}>
                    <b>SHOP NAME</b>
                    <h5>{userDetails[0].shopName}</h5>

                    <br />
                    <b>SHOP ADDRESS</b>
                    <h5>{userDetails[0].shopAddress}</h5>

                    <br />
                    <b>SHOP TYPE</b>
                    <h5>{userDetails[0].shopType}</h5>

                    <br />
                    <b>SHOP STATUS</b>
                    <h5>{userDetails[0].shopStatus}</h5>

                    <br />
                  </Col>
                  <Col sm={{ span: 3, offset: 1 }}>
                    <b>PAYMENT METHOD</b>
                    <h5>{userDetails[0].paymentMethod}</h5>

                    <br />
                    <b>PAYMENT NUMBER</b>
                    <h5>{userDetails[0].paymentNumber}</h5>

                    <br />
                    <b>ADDED CUSTOMER</b>
                    <h5>{this.state.AddedCustomer}</h5>

                    <br />
                  </Col>
                </Row> */}

                  <Row className="width100">
                    <div className="headerName">
                      <h3 style={{ textAlign: "center" }}>Billing</h3>
                    </div>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Billing Package</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.billingTracker[0].billingPackage}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Expiration Date</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.billingTracker[0].billingExpiryDate}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Total Employee</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.billingTracker[0].employee.length}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Total Employee</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.billingTracker[0].employee.length}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Tax List</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.billingTracker[0].tax.length}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }}>
                      <div style={{ marginTop: 20 + "%" }}>
                        <Link
                          to={{
                            pathname: "/info",
                            search: `?userid=${this.state.merchantid}`,
                          }}
                        >
                          <Button>View All</Button>
                        </Link>
                      </div>
                    </Col>
                  </Row>

                  <Row className="width100">
                    <div className="headerName">
                      <h3 style={{ textAlign: "center" }}>Membership</h3>
                    </div>

                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Current Membership Package</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.membershipList.package}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b> Membership ID</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.membershipList.id}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b> updated on</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.membershipList.updatedOn}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b> Expiration Date </b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.membershipList.expiry}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b> Days to Expiration</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.membershipList.remainingDays}
                      </h3>
                    </Col>
                  </Row>
                  <Row className="width100">
                    <div className="headerName">
                      <h3 style={{ textAlign: "center" }}>
                        Offers and Collections
                      </h3>
                    </div>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Total OFFERS Approved</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.offerDetails[0].totalApprovedOffers}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Total OFFERS Rejected</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.offerDetails[0].totalRejectedOffers}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Total Collections Approved</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.offerDetails[0].totalApprovedItems}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Total Collections Rejected</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.offerDetails[0].totalRejectedItems}
                      </h3>
                    </Col>
                  </Row>
                  <Row>
                    <div className="headerName">
                      <h3 style={{ textAlign: "center" }}>Loyalty </h3>
                    </div>

                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Amount</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.loyalty.loyaltyAmount}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Points</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.loyalty.loyaltyPoints}
                      </h3>
                    </Col>

                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Loyalty Ratio</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.loyalty.ratio}
                      </h3>
                    </Col>

                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Package Status</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.loyalty.status}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Total Customers Enrolled</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.loyalty.exsistingCustomer.length}
                      </h3>
                    </Col>

                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b>Expiration Date</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.loyalty.expiry}
                      </h3>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }} className="division">
                      <b> Days to Expiration</b>
                      <h3 style={{ textAlign: "center" }}>
                        {this.state.loyalty.remainingDays}
                      </h3>
                    </Col>
                    <Col
                      style={{ display: "flex", justifyContent: "center" }}
                      sm={{ span: 3, offset: 1 }}
                    >
                      <Button
                        style={{ marginTop: 52 + "px" }}
                        onClick={() => {
                          this.getCustomer(this.state.loyalty);
                        }}
                      >
                        VIEW CUSTOMERS
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <div className="headerName">
                      <h3 style={{ textAlign: "center" }}>Merchant History</h3>
                    </div>

                    <Col sm={{ span: 3, offset: 1 }}>
                      <Button
                        onClick={() => {
                          this.getHistory(
                            this.state.offerHistory[0],
                            "OFFER DETAILS"
                          );
                        }}
                      >
                        VIEW OFFERS HISTORY
                      </Button>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }}>
                      <Button
                        onClick={() => {
                          this.getHistory(
                            this.state.offerHistory[1],
                            "COLLECTION DETAILS"
                          );
                        }}
                      >
                        VIEW ITEMS HISTORY
                      </Button>
                    </Col>
                    <Col sm={{ span: 3, offset: 1 }}>
                      <Button
                        onClick={() => {
                          this.getHistory(
                            this.state.couponHistory,
                            "COUPON SUMMARY"
                          );
                        }}
                      >
                        VIEW COUPONS HISTORY
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <div className="headerName">
                      <h3 style={{ textAlign: "center" }}>
                        {" "}
                        Overview of coupons
                      </h3>
                    </div>
                    <Col className="division">
                      <h3 style={{ textAlign: "center" }}>
                        BAR CHART OF COUPONS
                      </h3>
                      <>
                        <ResponsiveContainer aspect={1.6}>
                          <BarChart
                            width={600}
                            height={300}
                            data={this.state.couponData}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="couponsConsumed" fill="#8884d8" />
                            <LabelList
                              dataKey="couponsConsumed"
                              position="top"
                            />
                            <Bar dataKey="consumedValue" fill="#82ca9d" />
                            <LabelList dataKey="consumedValue" position="top" />
                          </BarChart>
                        </ResponsiveContainer>
                      </>
                    </Col>
                    {/* <div style={{border:0.5+"px",borderStyle:"solid"}}></div> */}
                    <Col className="division">
                      <h3 style={{ textAlign: "center" }}>Coupon Stats</h3>
                      <Table
                        style={{
                          border: 0.5 + "px",
                          borderStyle: "solid",
                          borderColor: "#ced4da",
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>SNO</TableCell>
                            <TableCell>COUPON TYPE</TableCell>
                            <TableCell>COUPONS CREATED</TableCell>
                            <TableCell>CONSUMED</TableCell>
                            <TableCell>VALUE</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.couponData.map((elements, index) => (
                            <TableRow>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{elements.name}</TableCell>
                              <TableCell>{elements.created}</TableCell>

                              <TableCell>{elements.couponsConsumed}</TableCell>
                              <TableCell>{elements.consumedValue}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Col>
                  </Row>
                  <Row>
                    {this.state.showTable && (
                      <>
                        <div
                          style={{
                            backgroundColor: "#fafafa",
                            // width: 100 + "%",
                            marginTop: 1 + "%",
                          }}
                        >
                          <h3 style={{ textAlign: "center" }}>
                            {this.state.headerName}
                          </h3>
                        </div>

                        <Table
                          style={{
                            border: 0.5 + "px",
                            borderStyle: "solid",
                            borderColor: "#ced4da",
                          }}
                          size="small"
                          className={classes.table}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="center"
                                className={classes.tableCell}
                              >
                                S NO
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableCell}
                              >
                                IMAGE
                              </TableCell>
                              {this.state.heading.map((items) => (
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                >
                                  {items}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.renderTable.map((items, index) => (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell align="center">
                                  <img
                                    // src={JSON.parse(item.offerImageLocation)}
                                    src={items.offerImageLocation}
                                    alt={items.offerImageLocation}
                                    width="200"
                                    height="200"
                                  ></img>
                                </TableCell>
                                {Object.entries(items).map(
                                  ([header, value]) => (
                                    <>
                                      {header !== "offerImageLocation" &&
                                        header !== "id" && (
                                          <TableCell
                                            align="center"
                                            className={classes.tableCell}
                                          >
                                            {value}
                                          </TableCell>
                                        )}
                                    </>
                                  )
                                )}
                                {items.id !== null && items.id !== undefined && (
                                  <TableCell className={classes.tableCell}>
                                    <Button
                                      onClick={() => {
                                        this.showRedeemedCoupons(items.id);
                                      }}
                                    >
                                      VIEW REDEEMED COUPONS
                                    </Button>
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </Row>
                </>
              )}
            </>
          )}
        </Container>
      </>
    );
  }
}
MerchantDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MerchantDetails);
