import React, { Component } from "react";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import config from "../config";
import { Auth } from "aws-amplify";
import { createBrowserHistory } from "history";
import { Link } from "react-router-dom";
import Resizer from "react-image-file-resizer";
const history = createBrowserHistory();

// Get the current location.
const location = history.location;
export default class AddCoupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getShop: {
        userId10Digit: "",
      },
      coupon: {
        couponAddedby: "",
        shopId: "",
        couponDetails: "",
        expiryDate: "",
        claimExpiryDate: "",
        imageFile: [],
        imageLocation: "",
        promocode: "",
        promocodeCount: 0,
        promocodeExpiryDate: "",
        couponType: "",
        maximumCouponAmount: "",
        uniqueCode: "",
        totalCouponCount: "",
        pincode: "",
        minGhoshakPointsReq: "",
        userId10Digit: "",
        shopType: "",
        minLoyaltyPointsReq: "",
        CouponExpiryDate: "",
        addedDate: new Date().toISOString().substr(0, 10),
        notification: {},
      },
      minimumDate: new Date().toISOString().substr(0, 10),
      showResult: true,
      imagePreviewUrl: [],
      imageSize: "",
      userName: "",
      userPhoneNumber: "",
      shopName: "",
      shopType: "",
      remainingCoupon: "",
      checkPromo: "",
      checkPromocode: true,
      promocodeVerification: false,
      membershipExpiry: "",
      buttonDisabled: false,
    };
    this.reset = this.state;
    this.handleimage = this.handleimage.bind(this);
    this.insertCoupons = this.insertCoupons.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getShopSubmit = this.getShopSubmit.bind(this);
    this.coupononChange = this.coupononChange.bind(this);
    this.clear = this.clear.bind(this);
    this.initialState = this.state;
    this.checkPromocode = this.checkPromocode.bind(this);
    this.checkPromoOnchange = this.checkPromoOnchange.bind(this);
  }
  checkPromoOnchange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  componentDidUpdate() {
    {
      this.state.showResult === false &&
        (window.onpopstate = (e) => {
          e.preventDefault();
          if (window.confirm("are you want to leave the page")) {
            window.location.assign("/");
          } else {
            window.location.assign("/addcoupon");
          }
        });
    }
  }
  checkPromocode(e) {
    fetch(`${config.ApiGateway}/checkpromo`, {
      method: "POST",
      body: JSON.stringify({ checkPromo: this.state.checkPromo }),

      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data === "valid") {
          this.setState({
            ...this.state,
            coupon: {
              ...this.state.coupon,
              promocode: this.state.checkPromo,
            },
            checkPromocode: false,
            promocodeVerification: false,
          });
        } else {
          this.setState({
            promocodeVerification: true,
          });
        }
      })
      .catch((err) => {
        alert("record is not inserted");
        console.log(err);
      });
  }
  async componentDidMount() {
    Promise.all([fetch(await `${config.json.URL}/imagesize.json`)])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.setState({
          imageSize: data.COUPON,
        });
      })
      .catch((err) => console.log(err));

    await Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({
          ...this.state,
          coupon: {
            ...this.state.coupon,
            couponAddedby: user.attributes.phone_number,
          },
        });
      })

      .catch((err) => console.log(err));
  }

  handleimage(event) {
    this.setState({
      selectedFile: event.target.files[0],
    });

    let reader = new FileReader();
    let imageFormData = new FormData();
    imageFormData.append("photo", event.target.files[0]);
    Resizer.imageFileResizer(
      event.target.files[0], //is the file of the new image that can now be uploaded...
      300, // is the maxWidth of the  new image
      400, // is the maxHeight of the  new image
      "WEBP", // is the compressFormat of the  new image
      100, // is the quality of the  new image
      0, // is the rotatoion of the  new image
      (uri) => {
        this.setState({
          ...this.state,
          coupon: {
            ...this.state.coupon,

            imageFile: uri.replace(/^data:image\/\w+;base64,/, ""),
          },
        });
        this.setState({
          imagePreviewUrl: uri,
        });
      },
      "base64" // is the output type of the new image
    );
    // reader.onloadend = () => {
    //   this.setState({
    //     ...this.state,
    //     coupon: {
    //       ...this.state.coupon,

    //       imageFile: reader.result.replace(/^data:image\/\w+;base64,/, ""),
    //     },
    //   });
    //   this.setState({
    //     imagePreviewUrl: reader.result,
    //   });
    // };

    // reader.readAsDataURL(event.target.files[0]);
  }

  insertCoupons(e) {
    e.preventDefault();
    this.setState({
      buttonDisabled: true,
    });
    fetch(`${config.ApiGateway}/addCoupons`, {
      method: "POST",
      body: JSON.stringify(this.state.coupon),

      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        alert("coupon record insered");

        this.clear();
      })
      .catch((err) => {
        alert("record is not inserted");
        console.log(err);
      });
  }
  getShopSubmit(e) {
    e.preventDefault();
    fetch(`${config.ApiGateway}/getCouponLimit`, {
      method: "POST",
      body: JSON.stringify(this.state.getShop),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          ...this.state,
          coupon: {
            ...this.state.coupon,
            shopId: data["shopId"],
            pincode: data["userPincode"],
            userId10Digit: data["userId10Digit"],
            shopType: data["shopType"],
          },

          userName: data["userName"],
          userPhoneNumber: data["userPhoneNumber"],
          shopName: data["shopName"],
          shopType: data["shopType"],
          remainingCoupon: data["remainingCoupon"],
          remainingLoyaltyCoupon: data["remainingLoyaltyCoupon"],
          currentMembershipType: data["membershipType"],
          membershipExpiry: data["membershipExpiry"],
          showResult: false,
        });
      });
  }
  clear() {
    this.setState(this.initialState);
    this.componentDidMount();
  }
  onChange(e) {
    var target = e.target;
    var name = target.name;
    var value = target.value;
    this.setState({
      ...this.state,
      getShop: { ...this.state.getShop, [name]: value },
    });
  }
  coupononChange(e) {
    var target = e.target;
    var name = target.name;
    var value = target.value;
    this.setState({
      ...this.state,
      coupon: { ...this.state.coupon, [name]: value },
    });
  }

  render() {
    return (
      <Container fluid>
        {this.state.showResult && (
          <>
            <Row>
              <Col md={{ span: 4, offset: 4 }}>
                <div className="contentTableheader">
                  <h3 style={{ textAlign: "center" }}>COUPON</h3>
                </div>
                <Form onSubmit={this.getShopSubmit}>
                  <Row>
                    <Col md={{ span: 8 }}>
                      <Form.Label className="asterik">ENTER SHOP ID</Form.Label>

                      <Form.Control
                        type="text"
                        name="userId10Digit"
                        onChange={this.onChange}
                        value={this.state.getShop.userId10Digit}
                        pattern="[0-9]{8,10}"
                        required
                      ></Form.Control>
                    </Col>
                    <Col>
                      <Button type="submit" className="enterIdbutton">
                        SUBMIT
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </>
        )}

        {!this.state.showResult && (
          <>
            <Row>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  margin: 1 + "%",
                }}
              >
                <h3 style={{ textAlign: "center" }}>SHOP DETAILS</h3>
              </div>
              <Col md={{ span: 6, offset: 1 }}>
                <Form onSubmit={this.insertCoupons}>
                  <Col>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        USER NAME
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="userName"
                          onChange={this.onChange}
                          value={this.state.userName}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        USER ID
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="userId10Digit"
                          onChange={this.onChange}
                          value={this.state.getShop.userId10Digit}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        PHONE NUMBER
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="userPhoneNumber"
                          onChange={this.onChange}
                          value={this.state.userPhoneNumber}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        SHOP NAME
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="shopName"
                          onChange={this.onChange}
                          value={this.state.shopName}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        SHOP TYPE
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="shopType"
                          onChange={this.onChange}
                          value={this.state.shopType}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        SHOP ID
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="shopId"
                          onChange={this.coupononChange}
                          value={this.state.coupon.shopId}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        PINCODE
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="shopId"
                          onChange={this.coupononChange}
                          value={this.state.coupon.pincode}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <div
                      style={{
                        backgroundColor: "#fafafa",
                        width: 100 + "%",
                        margin: 1 + "%",
                      }}
                    >
                      <h3 style={{ textAlign: "center" }}>
                        ENTER COUPON DETAILS
                      </h3>
                    </div>
                    <Form.Group as={Row}>
                      <Form.Label className="asterik" column sm="4">
                        COUPON TYPE
                      </Form.Label>

                      <Col>
                        <Form.Check
                          label="GHOSHAK"
                          type="radio"
                          name="couponType"
                          onChange={this.coupononChange}
                          value="GHOSHAK"
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          label="MERCHANT"
                          type="radio"
                          name="couponType"
                          onChange={this.coupononChange}
                          value="MERCHANT"
                          disabled={
                            this.state.remainingCoupon === 0 ? true : false
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          label="LOYALTY"
                          type="radio"
                          name="couponType"
                          onChange={this.coupononChange}
                          value="LOYALTY"
                          disabled={
                            this.state.remainingLoyaltyCoupon === 0
                              ? true
                              : false
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          label="PROMOCODE"
                          type="radio"
                          name="couponType"
                          onChange={this.coupononChange}
                          value="PROMOCODE"
                        />
                      </Col>
                    </Form.Group>

                    {this.state.coupon.couponType === "GHOSHAK" && (
                      <Form.Group as={Row}>
                        <Form.Label className="asterik" column sm="4">
                          CLAIM DATE
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type="date"
                            name="claimExpiryDate"
                            onChange={this.coupononChange}
                            value={this.state.coupon.claimExpiryDate}
                            min={this.state.minimumDate}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    )}

                    {this.state.coupon.couponType === "GHOSHAK" && (
                      <Form.Group as={Row}>
                        <Form.Label className="asterik" column sm="4">
                          GHOSHAK POINTS REQUIRED
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type="text"
                            pattern="[0-9]{1,}"
                            name="minGhoshakPointsReq"
                            onChange={this.coupononChange}
                            value={this.state.coupon.minGhoshakPointsReq}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    )}

                    {this.state.coupon.couponType === "LOYALTY" && (
                      <Form.Group as={Row}>
                        <Form.Label className="asterik" column sm="4">
                          LOYALTY POINTS REQUIRED
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type="text"
                            pattern="[0-9]{1,}"
                            name="minLoyaltyPointsReq"
                            onChange={this.coupononChange}
                            value={this.state.coupon.minLoyaltyPointsReq}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    )}
                    {(this.state.coupon.couponType === "GHOSHAK" ||
                      this.state.coupon.couponType === "MERCHANT") && (
                      <Form.Group as={Row}>
                        <Form.Label className="asterik" column sm="4">
                          EXPIRY DATE
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type="date"
                            name="expiryDate"
                            onChange={this.coupononChange}
                            value={this.state.coupon.expiryDate}
                            min={this.state.minimumDate}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    )}
                    {this.state.coupon.couponType === "PROMOCODE" && (
                      <>
                        <Form.Group as={Row}>
                          <Form.Label className="asterik" column sm="4">
                            COUPON EXPIRY DATE
                          </Form.Label>
                          <Col>
                            <Form.Control
                              type="date"
                              name="CouponExpiryDate"
                              onChange={this.coupononChange}
                              value={this.state.coupon.CouponExpiryDate}
                              min={this.state.minimumDate}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                      </>
                    )}
                    {this.state.coupon.couponType === "PROMOCODE" &&
                      this.state.coupon.CouponExpiryDate !== "" && (
                        <>
                          {this.state.checkPromocode &&
                          this.state.coupon.couponType === "PROMOCODE" ? (
                            <>
                              <Form.Group as={Row}>
                                <Form.Label className="asterik" column sm="4">
                                  ENTER PROMOCODE
                                </Form.Label>
                                <Col>
                                  <Form.Control
                                    type="text"
                                    name="checkPromo"
                                    onChange={this.checkPromoOnchange}
                                    value={this.state.checkPromo}
                                  ></Form.Control>
                                  <h6 style={{ color: "red" }}>
                                    {this.state.promocodeVerification
                                      ? "PROMOCODE IS ALREADY USED. TRY ANOTHER CODE"
                                      : ""}
                                  </h6>
                                </Col>
                                <Col>
                                  <Button
                                    onClick={() => {
                                      this.checkPromocode();
                                    }}
                                  >
                                    CHECK VALID PROMOCODE
                                  </Button>
                                </Col>
                              </Form.Group>
                            </>
                          ) : (
                            <>
                              <Form.Group as={Row}>
                                <Form.Label className="asterik" column sm="4">
                                  PROMOCODE
                                </Form.Label>
                                <Col>
                                  <Form.Control
                                    name="promocode"
                                    onChange={this.coupononChange}
                                    value={this.state.coupon.promocode}
                                    disabled={!this.state.checkPromocode}
                                    required
                                  ></Form.Control>
                                </Col>
                              </Form.Group>

                              <Form.Group as={Row}>
                                <Form.Label className="asterik" column sm="4">
                                  PROMOCODE EXPIRY DATE
                                </Form.Label>
                                <Col>
                                  <Form.Control
                                    type="date"
                                    name="promocodeExpiryDate"
                                    onChange={this.coupononChange}
                                    value={
                                      this.state.coupon.promocodeExpiryDate
                                    }
                                    max={this.state.coupon.CouponExpiryDate}
                                    required
                                  ></Form.Control>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row}>
                                <Form.Label className="asterik" column sm="4">
                                  TOTAL PROMOCODE COUNT
                                </Form.Label>
                                <Col>
                                  <Form.Control
                                    name="promocodeCount"
                                    onChange={this.coupononChange}
                                    value={this.state.coupon.promocodeCount}
                                    required
                                  ></Form.Control>
                                </Col>
                              </Form.Group>
                            </>
                          )}
                        </>
                      )}

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        MAXIMUM COUPON AMOUNT
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          pattern="[0-9]{1,}"
                          name="maximumCouponAmount"
                          required
                          onChange={this.coupononChange}
                          value={this.state.coupon.maximumCouponAmount}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    {this.state.coupon.couponType !== "PROMOCODE" && (
                      <Form.Group as={Row}>
                        <Form.Label className="asterik" column sm="4">
                          TOTAL COUPON COUNT
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type="text"
                            pattern="[0-9]{1,}"
                            name="totalCouponCount"
                            onChange={this.coupononChange}
                            value={this.state.coupon.totalCouponCount}
                            required
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    )}
                    <Form.Group as={Row}>
                      <Form.Label className="asterik" column sm="4">
                        COUPON DETAILS
                      </Form.Label>
                      <Col>
                        <Form.Control
                          as="textarea"
                          name="couponDetails"
                          onChange={this.coupononChange}
                          value={this.state.coupon.couponDetails}
                          rows="5"
                          required
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    {/* {this.state.coupon.couponType === "LOYALTY" && ( */}
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        UPLOAD IMAGE
                      </Form.Label>
                      <Col>
                        <input type="file" onChange={this.handleimage}></input>
                      </Col>
                    </Form.Group>
                    {/* )} */}

                    {/* {this.state.coupon.couponType === "LOYALTY" && ( */}
                    <Form.Group as={Row}>
                      <Form.Label column sm="4"></Form.Label>
                      <Col>
                        <img
                          src={this.state.imagePreviewUrl}
                          alt={this.state.imagePreviewUrl}
                          // onLoad={this.onImgLoad}
                          width="50%"
                        ></img>
                      </Col>
                    </Form.Group>
                    {/* )} */}

                    {this.state.coupon.couponType === "PROMOCODE" ? (
                      <Button
                        disabled={
                          this.state.checkPromocode &&
                          this.state.coupon.couponType === "PROMOCODE"
                        }
                        style={{ marginLeft: 55 + "%" }}
                        type="submit"
                      >
                        SUBMIT
                      </Button>
                    ) : (
                      <Button
                        disabled={this.state.buttonDisabled}
                        style={{ marginLeft: 55 + "%" }}
                        type="submit"
                      >
                        SUBMIT
                      </Button>
                    )}
                  </Col>
                </Form>
              </Col>
              <Col md={{ span: 4 }}>
                <div
                  style={{
                    display: "inline",
                    width: 100 + "px",
                  }}
                >
                  <strong>REMAINING MERCHANT COUPON COUNT</strong>
                  <h5>{this.state.remainingCoupon}</h5>
                </div>
                <strong>REMAINING LOYALTY COUPON COUNT</strong>
                <h5>{this.state.remainingLoyaltyCoupon}</h5>

                <strong>CURRENT MEMBERSHIP</strong>
                <h5>{this.state.currentMembershipType}</h5>

                <strong>MEMBERSHIP EXPIRY DATE</strong>
                <h5>{this.state.membershipExpiry}</h5>
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  }
}
