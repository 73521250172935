import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import limit from "../limit";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import config from "../config";
import { CSVLink } from "react-csv";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide"
  },
  table: {
    minWidth: 340
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 4
  }
});
class PromocodeDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promocodeList: [],
      displayTable: false,
      startDate: limit.size.startDate,
      endDate: limit.size.endDate,
      couponDetails: []
    };
    this.showCouponDetails = this.showCouponDetails.bind(this);
    this.export = this.export.bind(this);
    this.onChange = this.onChange.bind(this);
    this.csvLink = React.createRef();
    this.fetchDetails = this.fetchDetails.bind(this);
  }
  fetchDetails() {
    Promise.all([
      fetch(
        `${config.ApiGateway}/promocodeDistribution`,

        {
          method: "POST",
          body: JSON.stringify({
            startDate: this.state.startDate,
            endDate: this.state.endDate
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        return this.setState({
          promocodeList: data,
          displayTable: true
        });
      })
      .catch(err => alert(err));
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  showCouponDetails(couponDetails) {
    this.setState({
      couponDetails: couponDetails,
      displayTable: false
    });
  }
  export() {
    const headers = [
      { label: "PINCODE", key: "pincode" },
      { label: "TOTAL COUPON", key: "values.totalCouponCount" },
      { label: "AREA", key: "place" },
      { label: "ALLOCATED COST", key: "values.allocatedCost" },
      { label: "CONSUMED COST", key: "values.consumedCost" },
      { label: "CREATED", key: "values.Added" },
      { label: "CLAIMED", key: "value.Claim" },
      { label: "REDEEMED", key: "value.Redeemed" },
      { label: "Live Coupons", key: "values.liveCouponDetails.length" },
      { label: "Expired Coupons", key: "values.expiredCouponDetails.length" }
    ];

    this.setState(
      {
        headers: headers
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  async componentDidMount() {
    await this.fetchDetails();
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              {this.state.displayTable ? (
                <>
                  <div
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%"
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}>
                      {" "}
                      PROMOCODE DISTRIBUTION{" "}
                    </h3>
                  </div>
                  <Row>
                    <Col>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <strong> DATE RANGE</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name="startDate"
                          value={this.state.startDate}
                          onChange={this.onChange}
                          type="date"
                          required
                        ></Form.Control>
                        <Form.Control
                          name="endDate"
                          onChange={this.onChange}
                          value={this.state.endDate}
                          type="date"
                          min={this.state.startDate}
                          required
                        ></Form.Control>
                      </InputGroup>
                    </Col>

                    <Col>
                      <Button
                        onClick={() => {
                          this.fetchDetails();
                        }}
                      >
                        SEARCH
                      </Button>
                    </Col>
                    <Col xs={2}>
                      <Button
                        onClick={() => {
                          this.export();
                        }}
                      >
                        DOWNLOAD
                      </Button>
                      <CSVLink
                        enclosingCharacter={`'`}
                        data={this.state.promocodeList}
                        headers={this.state.headers}
                        ref={this.csvLink}
                        target="_blank"
                      />
                    </Col>
                  </Row>
                  <Table
                    style={{
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid"
                    }}
                    className={classes.table}
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          S NO
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          PINCODE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          ALLOCATED COST
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          CONSUMED COST
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          CREATED
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          CLAIMED COUPONS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          REDEEMED COUPONS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          LIVE COUPONS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          ACTIVE COUPON DETAILS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          EXPIRED COUPONS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          EXPIRED COUPONS DETAILS
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.promocodeList.map((elements, index) => (
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            {index + 1}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.pincode}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {elements.values.allocatedCost}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.values.consumedCost}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.values.Added}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {elements.value !== undefined
                              ? elements.value.Claim
                              : 0}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.value !== undefined
                              ? elements.value.Redeemed
                              : 0}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {elements.values.active}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Button
                              onClick={() => {
                                this.showCouponDetails(
                                  elements.values.liveCouponDetails
                                );
                              }}
                            >
                              VIEW
                            </Button>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.values.expired}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Button
                              onClick={() => {
                                this.showCouponDetails(
                                  elements.values.expiredCouponDetails
                                );
                              }}
                            >
                              VIEW
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              ) : (
                <>
                  <div
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%"
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}> PROMOCODE DETAILS</h3>
                  </div>
                  <Table
                    style={{
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid"
                    }}
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: "center" }}>
                          S NO
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          SHOP ID
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          SHOP NAME
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          USERNAME
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          PHONE NUMBER
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          SHOP TYPE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          SHOP ADDRESS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          PINCODE
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          COUPON DETAILS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          PROMOCODE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          TOTAL COUPON
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPONS USED
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          ALLOCATED COST
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          CONSUMED COST
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPON AMOUNT
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPON ADDED ON
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          EXPIRED ON
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPON ADDED BY
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.couponDetails.map((elements, index) => (
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            {index + 1}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Link
                              to={{
                                pathname: "/merchant",
                                search: `?userid=${elements.userId10Digit}`
                              }}
                            >
                              {elements.userId10Digit}
                            </Link>
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {elements.shopName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.userName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.userPhoneNumber}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.shopType}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.shopAddress}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.userPincode}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.couponDetails}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.promocode}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.promocodeCount}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {parseInt(elements.promocodeCount) -
                              parseInt(elements.promocodeRemainingCount)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {parseInt(elements.promocodeCount) *
                              parseInt(elements.maximumCouponAmount)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {(parseInt(elements.promocodeCount) -
                              parseInt(elements.promocodeRemainingCount)) *
                              parseInt(elements.maximumCouponAmount)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.maximumCouponAmount}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.addedDate}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.promocodeExpiryDate}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.couponAddedby}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

PromocodeDistribution.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PromocodeDistribution);
