import React, { Component } from "react";
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CSVLink } from "react-csv";

import config from "../config";
import limit from "../limit";
export default class CustomerPhoneNumber extends Component {
  constructor(props) {
    super(props);
    this.shopInfo = [];
    this.state = {
      showData: false,
      userPincode: "",
      temp: [],
      phoneNumbers: [],
      headers: [],
      data: [],
      mergePincodeAndArea: "",
    };
    this.export = this.export.bind(this);
    this.csvLink = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.getNumbersSubmit = this.getNumbersSubmit.bind(this);
  }

  export(response) {
    let arrayFormat = response.map((elements) => [elements]);

    this.setState(
      {
        data: arrayFormat,
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  getNumbersSubmit(e) {
    e.preventDefault();

    Promise.all([
      fetch(
        `${config.ApiGateway}/getPhoneNumber`,

        {
          method: "POST",
          body: JSON.stringify({
            userType: "Customer", // user type
            userPincode: this.state.userPincode, // user input pincode

            fetchDetails: "userPhoneNumber",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, pincode]) => {
        let defaultNumber = limit.size.internalPhoneNumber;
        let mergeNumber = [...data, ...defaultNumber]; // merge internal number and customer in pincode

        let count = pincode.Items.find(
          (elements) => this.state.userPincode === elements.pincode
        );
        let appendObject = {};
        count !== undefined
          ? (appendObject = Object.assign({}, count))
          : (appendObject = Object.assign(
              {},
              {
                pincode: this.state.userPincode,
                place: "NOT FOUND",
              }
            ));

        this.setState({
          phoneNumbers: mergeNumber,
          showData: true,
          mergePincodeAndArea: appendObject,
        });
      })
      .catch((err) => console.log(err));
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    return (
      <>
        <Container>
          <Row>
            <Col
              md={{ span: 4, offset: 3 }}
              style={{
                margin: "auto",
                float: "none",
                marginTop: 30 + "px",
                marginBottom: 30 + "px",
              }}
              className="division"
            >
              <h4 style={{ textAlign: "center" }}>ENTER PINCODE</h4>
              <Form onSubmit={this.getNumbersSubmit}>
                <Form.Control
                  type="text"
                  name="userPincode"
                  value={this.state.userPincode}
                  onChange={this.onChange}
                  pattern="[0-9]{6}"
                  required
                ></Form.Control>
                <Button
                  style={{
                    width: 100 + "%",
                    height: 30 + "%",
                    marginTop: 2 + "%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  type="submit"
                >
                  SEARCH
                </Button>
              </Form>
            </Col>
          </Row>

          {this.state.showData && (
            <>
              <div
                style={{
                  backgroundColor: "#fafafa",

                  margin: 1 + "%",
                }}
              >
                <h3 style={{ textAlign: "center" }}>
                  PHONE NUMBER LIST {this.state.userPincode} -
                  {this.state.mergePincodeAndArea.place}
                </h3>
              </div>

              {this.state.phoneNumbers.length > 0 && (
                <>
                  <Row style={{ marginTop: 5 + "px" }}>
                    <CSVLink
                      headers={["Phone Number"]}
                      data={this.state.data}
                      ref={this.csvLink}
                      target="_blank"
                    />
                    <Col xs={{ span: 4, offset: 2 }}>
                      <Form.Label>DOWNLOAD AS CSV FILE</Form.Label>
                    </Col>
                    <Col xs={4}>
                      <Button
                        onClick={() => {
                          this.export(this.state.phoneNumbers);
                        }}
                        style={{ float: "right", margin: "2" }}
                      >
                        DOWNLOAD
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
              <h4 style={{ textAlign: "center" }}>
                Total numbers <b>{this.state.phoneNumbers.length}</b>
              </h4>
              <Table
                className="division"
                style={{
                  width: 900,
                  margin: "auto",
                  border: 0.5 + "px",
                  borderColor: "#ced4da",
                  borderStyle: "solid",
                }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S NO</TableCell>
                    <TableCell align="center">PHONE NUMBER</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.phoneNumbers.map((row, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{row}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </Container>
      </>
    );
  }
}
