import React, { useEffect, useState } from "react";
import config from "../../config";
import Button from "react-bootstrap/Button";
import PinDropIcon from "@material-ui/icons/PinDrop";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Limitation from "./limitation";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
const useStyles = makeStyles({
  table: {
    backgroundColor: "#fff",
    maxWidth: 900,
  },
  cell: {
    fontSize: "12px",
    paddingLeft: 4,
    paddingRight: 5,
  },
});
export default function Pincode() {
  const classes = useStyles();
  const [pincode, setPincode] = useState([]);
  const [pincodeDistribution, setPincodeDistribution] = useState([]);
  useEffect(() => {
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=0`, {
        method: "POST",
        body: JSON.stringify({
          aggs: {
            shopBucket: {
              filter: {
                bool: {
                  must: [
                    {
                      match: {
                        userType: "shop",
                      },
                    },
                  ],
                },
              },
              aggs: {
                countPincodes: {
                  terms: {
                    field: "userPincode",
                    size: 10000,
                    order: {
                      _term: "asc",
                    },
                  },
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let sortbyPincode = data.aggregations.shopBucket.countPincodes.buckets.sort(
          (a, b) => b.doc_count - a.doc_count
        );
        let filterPincodeByRange = Limitation.pincodeDistribution.map(
          (elements) => {
            let filteredRange = sortbyPincode.filter(
              (items) =>
                elements.range[0] <= items.doc_count && // range[0] -  low range from limitation
                elements.range[1] >= items.doc_count //range[1] - high range from limitation
            );
            return {
              name: elements.name,
              count: filteredRange.length,
              pincodeList: filteredRange,
            };
          }
        );
        setPincodeDistribution(filterPincodeByRange);
        setPincode(sortbyPincode);
      })
      .catch((err) => console.log("data fetching failed", err));
  }, []);
  return (
    <>
      <div className="flexBox">
        <div className="titleHeader">
          <h4>Pincode</h4>
        </div>

        <div className="flexRow">
          <div id="offer" className="flexColumn">
            <div className="flexCard">
              <div className="flexIcon">
                <PinDropIcon />
              </div>
              <div className="flexContent">
                <h5> B2B Pincode</h5>
                <h4>{pincode.length}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="vertical-space-20"></div>
        <div className="flexRow">
          <div className="flexColumn">
            <div className="labelWrapper">
              <h5>Pincode Distribution</h5>
            </div>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  {pincodeDistribution.map((row) => (
                    <TableRow>
                      <TableCell className={classes.cell}>{row.name}</TableCell>
                      <TableCell className={classes.cell}>
                        {row.count}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Button>View</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="flexColumn">
            <div className="labelWrapper">
              <h5>Top 5 Pincode</h5>
            </div>
            <ResponsiveContainer
              style={{ float: "left", width: "100%" }}
              aspect={1.6}
            >
              <BarChart
                style={{
                  padding: "20px",
                  boxShadow: " 0 1px 4px 0 rgba(0, 0, 0, 0.14)",
                  backgroundColor: "#fff",
                }}
                data={pincode.slice(0, 5)}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="key"
                  interval={0}
                  angle={-45}
                  textAnchor="end"
                  height={100}
                />
                <YAxis
                  label={{
                    value: "Shops",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="doc_count" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="vertical-space-20"></div>
        <div className="flexRow">
          <div id="displayTable" className="flexColumn">
            <TableContainer>
              <h5>List of Pincode</h5>

              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableCell className={classes.cell}>SI No</TableCell>
                  <TableCell className={classes.cell}>Pincode</TableCell>
                  <TableCell className={classes.cell}>Shops</TableCell>
                </TableHead>
                <TableBody>
                  {pincode.map((row, index) => (
                    <TableRow key={row.key}>
                      <TableCell className={classes.cell}>
                        {index + 1}
                      </TableCell>
                      <TableCell className={classes.cell}>{row.key}</TableCell>
                      <TableCell className={classes.cell}>
                        {row.doc_count}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
