import { Row, Col, Container, Form, Button } from "react-bootstrap";
import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import config from "../config";
export default class CouponPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paidStatus: "",
      showData: true,
      userTable: [],
      toggleClass: true,
      couponDetails: [],
      searchMerchant: "",
      userDetails: [],
      errorMessage: false
    };
    this.paidAmountstatus = this.paidAmountstatus.bind(this);
    this.pay = this.pay.bind(this);
    this.getCouponstatus = this.getCouponstatus.bind(this);
    this.onChange = this.onChange.bind(this);
    this.searchMerchant = this.searchMerchant.bind(this);
    this.reset = this.state;
    this.resetForm = this.resetForm.bind(this);
  }

  resetForm() {
    this.setState(this.reset);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  componentDidUpdate() {
    window.onpopstate = e => {
      e.preventDefault();
      if (window.confirm("are you sure want to leave the page ?")) {
        window.location.assign("/");
      } else {
        window.location.assign("/couponpayment");
      }
    };
  }

  searchMerchant(e) {
    e.preventDefault();
    Promise.all([
      fetch(
        `${config.ApiGateway}/searchmerchantbypayment`,

        {
          method: "POST",
          body: JSON.stringify({
            paidStatus: this.state.paidStatus,
            searchMerchant: this.state.searchMerchant
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        if (data !== "error") {
          this.setState({
            userTable: data,
            showData: false,
            errorMessage: false
          });
        } else {
          this.setState({
            errorMessage: true
          });
        }
      })
      .catch(err => console.log(err));
  }

  /************************  GET COUPON ONCHANGE   *************************/
  paidAmountstatus(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        this.getCouponstatus();
      }
    );
  }
  /************************  GET COUPON STATUS   *************************/
  getCouponstatus() {
    Promise.all([
      fetch(
        `${config.ApiGateway}/couponpaidstatus`,

        {
          method: "POST",
          body: JSON.stringify({
            paidStatus: this.state.paidStatus,
            searchMerchant: this.state.searchMerchant
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        this.setState({
          userTable: data,
          showData: false
        });
      })
      .catch(err => console.log(err));
  }
  pay(response) {
    Promise.all([
      fetch(
        `${config.ApiGateway}/getcoupondetails`,

        {
          method: "POST",
          body: JSON.stringify({
            shopId: response.shopId,
            couponId: response.redeemedCouponId
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        let user = data;
        let userInformation = {
          userName: user.userName,
          userId10Digit: user.userId10Digit,
          userPhoneNumber: user.userPhoneNumber,
          userPincode: user.userPincode,
          shopName: user.shopName,
          shopType: user.shopType,
          couponDetails: user.couponDetails,
          paymentMethod: user.paymentMethod,
          paymentNumber: user.paymentNumber,
          couponType: user.couponType
        };

        this.setState({
          toggleClass: false,
          couponDetails: response,
          userDetails: userInformation
        });
      })
      .catch(err => console.log(err));
  }

  render() {
    return (
      <Container fluid>
        {this.state.toggleClass ? (
          <>
            {this.state.showData && (
              <>
                <div
                  style={{
                    backgroundColor: "#fafafa",
                    width: 100 + "%"
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>COUPON PAYMENT</h3>
                </div>
                <Row>
                  <Col md={{ span: 8, offset: 3 }}>
                    <Form>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          PAID STATUS
                        </Form.Label>

                        <Col>
                          <Form.Check
                            label="UNPAID"
                            type="radio"
                            name="paidStatus"
                            onChange={this.paidAmountstatus}
                            value="Unpaid"
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            label="PAID"
                            type="radio"
                            name="paidStatus"
                            onChange={this.paidAmountstatus}
                            value="Paid"
                          />
                        </Col>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
              </>
            )}

            {!this.state.showData && (
              <>
                <Form onSubmit={this.searchMerchant}>
                  <Row>
                    <Col md={{ span: 4, offset: 3 }} className="division">
                      <h4 style={{ textAlign: "center" }}>SEARCH BY</h4>
                      <Form.Control
                        type="text"
                        name="searchMerchant"
                        onChange={this.onChange}
                        pattern="[0-9]{5,13}"
                        required
                        value={this.state.searchMerchant}
                        placeholder="ENTER MERCHANT ID OR PHONE NUMBER"
                      ></Form.Control>
                      <h6>
                        {this.state.errorMessage
                          ? "Please provide a vaild Userid or PhoneNumber"
                          : ""}
                      </h6>
                      <Button
                        type="submit"
                        style={{
                          width: 100 + "%",
                          height: 30 + "%",
                          marginTop: 2 + "%",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        SEARCH
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <h3 style={{ textAlign: "center" }}>
                  {this.state.paidStatus === "Unpaid" ? "TO BE PAID" : "PAID"}
                </h3>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid"
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">ID</TableCell>
                      <TableCell align="center">SHOP ID</TableCell>
                      <TableCell align="center">REDEEMED COUPON ID</TableCell>
                      <TableCell align="center">PAY</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this.state.userTable.map((items, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{items.couponId}</TableCell>
                        <TableCell align="center">
                          <Link
                            to={{
                              pathname: "/merchant",
                              search: `?userid=${items.userId10Digit}`
                            }}
                          >
                            {" "}
                            {items.userId10Digit}{" "}
                          </Link>
                        </TableCell>
                        <TableCell align="center">
                          {items.redeemedCouponId}
                        </TableCell>
                        <TableCell align="center">
                          {this.state.paidStatus === "Unpaid" ? (
                            <Button
                              onClick={() => {
                                this.pay(items);
                              }}
                            >
                              PAY
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                this.pay(items);
                              }}
                            >
                              VIEW
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </>
        ) : (
          <CouponPaymentTransaction
            couponDetails={this.state.couponDetails}
            resetForm={this.resetForm}
            userDetails={this.state.userDetails}
          />
        )}
      </Container>
    );
  }
}

class CouponPaymentTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponPayment: {
        couponId: this.props.couponDetails.couponId,
        custId: this.props.couponDetails.custId,
        shopId: this.props.couponDetails.shopId,
        expiryDate: this.props.couponDetails.expiryDate,
        redeemedDate: this.props.couponDetails.redeemedDate,
        maximumCouponAmount: this.props.couponDetails.maximumCouponAmount,
        paymentId:
          this.props.couponDetails.paymentId != undefined
            ? this.props.couponDetails.paymentId
            : this.props.userDetails.paymentNumber,
        paidAmount: this.props.couponDetails.paidAmount,
        paymentDate:
          this.props.couponDetails.paidStatus === "Paid"
            ? this.props.couponDetails.paymentDate
            : new Date().toISOString().substr(0, 10),
        paymentType:
          this.props.couponDetails.paymentType != undefined
            ? this.props.couponDetails.paymentType
            : this.props.userDetails.paymentMethod,
        transactionId: this.props.couponDetails.transactionId,
        notes: "",

        paymentDoneby: "",
        paidStatus: "Paid",
        updatedOn: new Date().toISOString().substr(0, 10)
      }
    };
    this.payCouponSubmit = this.payCouponSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  async componentDidMount() {
    await this.getUser();
  }
  getUser() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        return this.setState({
          ...this.state,
          couponPayment: {
            ...this.state.couponPayment,
            paymentDoneby: user.attributes.phone_number
          } //====>
        });
      })

      .catch(err => console.log(err));
  }
  payCouponSubmit(e) {
    e.preventDefault();
    Promise.all([
      fetch(
        `${config.ApiGateway}/updatecouponpayment`,

        {
          method: "POST",
          body: JSON.stringify(this.state.couponPayment),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert("paid successfully");
        this.props.resetForm();
      })
      .catch(err => console.log(err));
  }
  onChange(e) {
    this.setState({
      ...this.state,
      couponPayment: {
        ...this.state.couponPayment,
        [e.target.name]: e.target.value
      }
    });
  }

  render() {
    return (
      <>
        <h3 style={{ textAlign: "center" }}>
          {" "}
          {this.props.couponDetails.paidStatus === "Paid"
            ? "PAID STATMENT"
            : "UNPAID"}{" "}
        </h3>
        <Form onSubmit={this.payCouponSubmit}>
          <h3>SHOP DETAILS</h3>

          <Row>
            <Col md={{ span: 6 }}>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  USER NAME
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="userName"
                    onChange={this.onChange}
                    value={this.props.userDetails.userName}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  USER ID
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="userId10Digit"
                    onChange={this.onChange}
                    value={this.props.userDetails.userId10Digit}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  PHONE NUMBER
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="userPhoneNumber"
                    onChange={this.onChange}
                    value={this.props.userDetails.userPhoneNumber}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  PINCODE
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="userPincode"
                    onChange={this.onChange}
                    value={this.props.userDetails.userPincode}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  SHOP NAME
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="shopName"
                    onChange={this.onChange}
                    value={this.props.userDetails.shopName}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  SHOP TYPE
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="shopType"
                    onChange={this.onChange}
                    value={this.props.userDetails.shopType}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  COUPON DETAILS
                </Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    name="couponDetails"
                    rows="6"
                    onChange={this.onChange}
                    disabled={true}
                    value={this.props.userDetails.couponDetails}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <h3 style={{ textAlign: "center" }}>COUPON DETAILS</h3>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  COUPON TYPE
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="couponType"
                    onChange={this.onChange}
                    value={this.props.userDetails.couponType}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  COUPON ID
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="couponId"
                    onChange={this.onChange}
                    value={this.state.couponPayment.couponId}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  CUSTOMER ID
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="custId"
                    onChange={this.onChange}
                    value={this.state.couponPayment.custId}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  SHOP ID
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="shopId"
                    onChange={this.onChange}
                    value={this.state.couponPayment.shopId}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  EXPIRY DATE
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="expiryDate"
                    onChange={this.onChange}
                    value={this.state.couponPayment.expiryDate}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  REDEEMED DATE
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="redeemedDate"
                    onChange={this.onChange}
                    value={this.state.couponPayment.redeemedDate}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  MAXIMUM COUPON AMOUNT
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="maximumCouponAmount"
                    onChange={this.onChange}
                    value={this.state.couponPayment.maximumCouponAmount}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  PAYMENT ID
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="paymentId"
                    onChange={this.onChange}
                    value={this.state.couponPayment.paymentId}
                    disabled={true}
                  ></Form.Control>
                  <h6 style={{ color: "red " }}>
                    {this.state.couponPayment.paymentId !== undefined &&
                    this.state.couponPayment.paymentId !== null &&
                    this.state.couponPayment.paymentId !== ""
                      ? ""
                      : "NO PAYMENT ID IS AVAILABLE.PAYMENT CAN BE DONE BY CASH"}
                  </h6>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  PAYMENT TYPE
                </Form.Label>

                <Col>
                  <Form.Check
                    label="PAYTM"
                    type="radio"
                    name="paymentType"
                    onChange={this.onChange}
                    checked={this.state.couponPayment.paymentType === "Paytm"}
                    value="Paytm"
                    disabled={true}
                  />
                </Col>
                <Col>
                  <Form.Check
                    label="GOOGLE PAY"
                    type="radio"
                    name="paymentType"
                    onChange={this.onChange}
                    value="Gpay"
                    disabled={true}
                    checked={this.state.couponPayment.paymentType === "Gpay"}
                  />
                </Col>
                {this.state.couponPayment.paymentType !== "Gpay" &&
                  this.state.couponPayment.paymentType !== "Paytm" && (
                    <Col>
                      <Form.Check
                        label="CASH"
                        type="radio"
                        name="paymentType"
                        onChange={this.onChange}
                        value="Cash"
                        required
                        checked={
                          this.state.couponPayment.paymentType === "Cash"
                        }
                      />
                    </Col>
                  )}
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  TRANSACTION ID
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="transactionId"
                    onChange={this.onChange}
                    value={this.state.couponPayment.transactionId}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  AMOUNT PAID
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="paidAmount"
                    onChange={this.onChange}
                    value={this.state.couponPayment.paidAmount}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  NOTES
                </Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    name="notes"
                    rows="6"
                    onChange={this.onChange}
                    value={this.state.couponPayment.notes}
                    placeholder="ENTER NOTES"
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Button
                style={{ marginLeft: 55 + "%", float: "right" }}
                type="submit"
              >
                {this.props.couponDetails.paidStatus !== "Paid"
                  ? "PAY"
                  : "UPDATE "}
              </Button>
            </Col>
            {this.props.couponDetails.paidStatus === "Paid" ? (
              <>
                <Col style={{ textAlign: "center" }} md={{ span: 6 }}>
                  <h4>NOTES HISTORY</h4>
                  <Table
                    style={{
                      width: 100 + "%",
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid"
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>S.NO</TableCell>
                        <TableCell>NOTES</TableCell>
                        <TableCell>DATE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.values(this.props.couponDetails.Note).map(
                        (items, index) => (
                          <>
                            <TableRow>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                <h5>{items.notes}</h5>
                              </TableCell>
                              <TableCell>
                                {" "}
                                <h5>{items.date}</h5>
                              </TableCell>
                            </TableRow>
                          </>
                        )
                      )}
                    </TableBody>
                  </Table>
                </Col>
                <Col>
                  <h3>LAST UPDATED BY</h3>
                  <h5>{this.props.couponDetails.paymentDoneby}</h5>
                </Col>
                <Col>
                  <h3>PAID ON</h3>
                  <h5>{this.props.couponDetails.updatedOn}</h5>
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>
        </Form>
      </>
    );
  }
}
