import React, { Component } from "react";
import { Row, Col, Button, Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import config from "../config";
import Limit from "../limit";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide"
  },
  table: {
    minWidth: 340
  },
  tableCell: {
    paddingRight: 4,
    textAlign: "center",

    paddingLeft: 5
  }
});
class BillingDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showData: false,
      billPackageList: [],
      headers: [],
      data: [],
      setPackageStatus: "All",
      orderBy: false,
      pincode: "",
      todayDate: new Date().toISOString().substring(0, 10),
      productPerPage: 10,
      productPage: 0,
      merchantPerPage: 10,
      merchantPage: 0,
      biannualYear: [],
      passShops: []
    };
    this.handleProductChangePerRow = this.handleProductChangePerRow.bind(this);
    this.productPage = this.productPage.bind(this);
    this.handleMerchantChangePerRow = this.handleMerchantChangePerRow.bind(
      this
    );
    this.merchantPage = this.merchantPage.bind(this);
    this.csvLink = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.viewShops = this.viewShops.bind(this);
  }
  viewShops(request) {
    this.setState({
      passShops: request,
      showData: false
    });
  }
  getMerchantDetails(userid) {
    this.props.getMerchant(userid);
  }
  handleProductChangePerRow = event => {
    this.setState({ productPerPage: event.target.value });
  };
  productPage = (event, page) => {
    this.setState({ productPage: page });
  };
  handleMerchantChangePerRow = event => {
    this.setState({ merchantPerPage: event.target.value });
  };
  merchantPage = (event, page) => {
    this.setState({ merchantPage: page });
  };
  export() {
    const headers = [
      { label: "PINCODE", key: "pincode" },
      { label: "SHOP NAME", key: "shopName" },
      { label: "SHOP TYPE", key: "shopType" },
      { label: "USER ID", key: "userId10Digit" },
      { label: "ADDRESS", key: "shopAddress" },
      { label: "PHONE NUMBER", key: "userPhoneNumber" },
      { label: "NAME", key: "userName" },
      { label: "BILLING PACKAGE", key: "billingPackage" },
      { label: "BILLING EXPIRY DATE", key: "billingExpiryDate" }
    ];

    this.setState(
      {
        headers: headers
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  renderTableRows() {
    let tableHeader = [];
    for (var i = 0; i < 6; i++) {
      tableHeader.push(
        <>
          <TableCell
            style={{
              textAlign: "center",
              borderLeft: 0.5 + "px",
              borderLeftColor: "#ced4da",
              borderLeftStyle: "solid"
            }}
          >
            LIVE
          </TableCell>
          <TableCell
            style={{
              textAlign: "center",
              borderRight: 0.5 + "px",
              borderRightColor: "#ced4da",
              borderRightStyle: "solid"
            }}
          >
            WILL EXPIRE
          </TableCell>
        </>
      );
    }
    return tableHeader;
  }
  componentDidMount() {
    Promise.all([
      fetch(`${config.ApiGateway}/billingDashboard`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let biannualYear = [];

        data.sixMonthArray.forEach(items => biannualYear.push(items.month));
        this.setState({
          billPackageList: data.mergePincodeAndAreaName, // billing list
          showData: true, // loading state
          biannualYear: biannualYear // next 6 month dates
        });
      })
      .catch(err => console.log(err));
  }

  render() {
    const { classes } = this.props;

    return (
      <Container fluid>
        <Row className="rowClass">
          <b style={{ fontSize: 32 }}>Merchant Billing Program</b>
        </Row>
        <>
          {this.state.showData ? (
            <>
              {
                <>
                  <Row className="rowClass">
                    <Col sm={10}>
                      <Table
                        style={{
                          width: 100 + "%",
                          border: 0.5 + "px",
                          borderColor: "#ced4da",
                          borderStyle: "solid"
                        }}
                        className={classes.table}
                        size="medium"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={12}></TableCell>
                            <TableCell
                              align="center"
                              colSpan={12}
                              className={classes.tableCell}
                            >
                              Billing Package
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              S.NO
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Pincode
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Area Name
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Active Merchant
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              View Active Merchant
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Expired
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              View Expired
                            </TableCell>
                            {this.state.biannualYear.map((element, index) => (
                              <>
                                <TableCell
                                  colSpan={2}
                                  className={classes.tableCell}
                                  style={{
                                    textAlign: "center",
                                    borderLeft: 0.5 + "px",
                                    borderLeftColor: "#ced4da",
                                    borderLeftStyle: "solid"
                                  }}
                                >
                                  {element}
                                </TableCell>
                              </>
                            ))}
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={7}></TableCell>
                            {this.renderTableRows()}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.billPackageList.map((elements, index) => (
                            <TableRow key={elements.pincode}>
                              <TableCell className={classes.tableCell}>
                                {index + 1}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {elements.userPincode}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {elements.place}
                              </TableCell>
                              <TableCell>
                                {elements.activePackage.length}
                              </TableCell>
                              <TableCell>
                                <Button
                                  onClick={() =>
                                    this.viewShops(elements.activePackage)
                                  }
                                >
                                  View
                                </Button>
                              </TableCell>
                              <TableCell>
                                {elements.expiredPackage.length}
                              </TableCell>
                              <TableCell>
                                <Button
                                  onClick={() =>
                                    this.viewShops(elements.expiredPackage)
                                  }
                                >
                                  View
                                </Button>
                              </TableCell>

                              {Object.values(elements.statusTracker).map(
                                items => (
                                  <>
                                    <TableCell
                                      align="center"
                                      className={classes.tableCell}
                                      style={{
                                        textAlign: "center",
                                        borderLeft: 0.5 + "px",
                                        borderLeftColor: "#ced4da",
                                        borderLeftStyle: "solid"
                                      }}
                                      key={items.live}
                                    >
                                      {items.live}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.tableCell}
                                      style={{
                                        textAlign: "center",
                                        borderRight: 0.5 + "px",
                                        borderRightColor: "#ced4da",
                                        borderRightStyle: "solid"
                                      }}
                                    >
                                      {items.expire}
                                    </TableCell>
                                  </>
                                )
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        component="div"
                        count={this.state.billPackageList.length}
                        rowsPerPage={this.state.productPerPage}
                        page={this.state.productPage}
                        onChangePage={this.productPage}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        onChangeRowsPerPage={this.handleProductChangePerRow}
                      />
                    </Col>
                  </Row>
                </>
              }
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  justifyContent: "flex-end",
                  paddingBottom: 20 + "px"
                }}
              >
                <Col sm={{}}>
                  <Button
                    onClick={() => {
                      this.export();
                    }}
                  >
                    DOWNLOAD
                  </Button>
                  <CSVLink
                    enclosingCharacter={`'`}
                    data={this.state.passShops}
                    headers={this.state.headers}
                    ref={this.csvLink}
                    target="_blank"
                  />
                </Col>
              </div>
              <Table
                style={{
                  border: 0.5 + "px",
                  borderColor: "#ced4da",
                  borderStyle: "solid"
                }}
                className={classes.table}
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>S NO</TableCell>
                    <TableCell className={classes.tableCell}>
                      Shop Name
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Shop Type
                    </TableCell>
                    <TableCell className={classes.tableCell}>UserId</TableCell>
                    <TableCell className={classes.tableCell}>Address</TableCell>
                    <TableCell className={classes.tableCell}>Pincode</TableCell>
                    <TableCell className={classes.tableCell}>Place</TableCell>
                    <TableCell className={classes.tableCell}>
                      Phone Number
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Merchant Name
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      Billing Package
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Expiration Date
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Billing Package Status
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Billing Activated by
                    </TableCell>
                    <TableCell className={classes.tableCell}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.passShops
                    .slice(
                      this.state.merchantPage * this.state.merchantPerPage,
                      this.state.merchantPage * this.state.merchantPerPage +
                        this.state.merchantPerPage
                    )
                    .map((items, index) => (
                      <>
                        <TableRow key={index + 1}>
                          <TableCell className={classes.tableCell}>
                            {index + 1}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {items.shopName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {items.shopType}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Link
                              to={{
                                pathname: "/merchant",
                                search: `?userid=${items.userId10Digit}`
                              }}
                            >
                              {" "}
                              {items.userId10Digit}
                            </Link>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {items.shopAddress}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {items.userPincode}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {items.place}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {items.userPhoneNumber}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {items.userName}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {items.billingPackage}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {items.billingExpiryDate}
                          </TableCell>
                          <TableCell
                            style={{
                              color:
                                this.state.todayDate <= items.billingExpiryDate
                                  ? "green"
                                  : "red"
                            }}
                            className={classes.tableCell}
                          >
                            {this.state.todayDate <= items.billingExpiryDate
                              ? "Active"
                              : "Expired"}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <b> {items.billingUpdatedBy}</b>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Link
                              to={{
                                pathname: "/info",
                                search: `?userid=${items.userId10Digit}`
                              }}
                            >
                              <Button size="sm">View Bill History</Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={this.state.passShops.length}
                rowsPerPage={this.state.merchantPerPage}
                page={this.state.merchantPage}
                onChangePage={this.merchantPage}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                onChangeRowsPerPage={this.handleMerchantChangePerRow}
              />
            </>
          )}
        </>
      </Container>
    );
  }
}
BillingDashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BillingDashboard);
