import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";

// or 'aws-amplify-react-native'
import { Auth } from "aws-amplify";
import { store } from "../provider";
import { withRouter } from "react-router-dom";

class Login extends Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      password: "",
      phoneNumber: "",
      confirm_password: "",
      userObject: {},
      show_form: true,
      header: true,
      verficationCode: "",
      resetNewPassword: "",
      resetPhoneNumber: "",
      showForgetPassword: false,
      newpassword_show_form: false,
      showNewPassword: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.forgetPassword = this.forgetPassword.bind(this);
    this.handleClickNewShowPassword =
      this.handleClickNewShowPassword.bind(this);
  }

  handlePassword(e) {
    e.preventDefault();

    Auth.completeNewPassword(this.state.userObject, this.state.confirm_password)
      .then(async (user) => {
        await Auth.currentAuthenticatedUser().then(async (user) => {
          var info = user.signInUserSession.idToken.payload["cognito:groups"];

          this.props.onStateChange(
            "signedIn",
            typeof info == "undefined" ? "sales" : info[0]
          );

          await this.context
            .dispatch({
              type: "IS_LOGGEDIN",
              loggedIn: true,
            })
            .then(() => {
              this.props.history.push("/");
            });
        });
      })
      .catch((err) => console.log(err));
  }
  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({ [name]: value });
  };
  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }
  handleClickNewShowPassword() {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  }
  forgetPassword(e) {
    var username = this.state.resetPhoneNumber;

    var new_password = this.state.resetNewPassword;
    var code = this.state.verficationCode;

    Auth.forgotPasswordSubmit(username, code, new_password)
      .then((data) => {
        this.setState({
          showForgetPassword: false,
          show_form: true,
          newpassword_show_form: false,
        });
        this.props.onStateChange("signIn");
      })
      .catch((err) => console.log(err));
  }
  handleSubmit(e) {
    e.preventDefault();
    var username = this.state.phoneNumber;
    var password = this.state.password;

    const data = JSON.stringify({
      username,
      password,
    });
    localStorage.setItem("userData", data);

    Auth.signIn({
      username, // Required, the username
      password, // Optional, the password
    })
      .then(async (user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.setState({
            show_form: false,
            newpassword_show_form: true,
            showForgetPassword: false,
            userObject: user,
            error: false,
            header: false,
          });
        } else {
          await Auth.currentAuthenticatedUser().then(async (user) => {
            var info = user.signInUserSession.idToken.payload["cognito:groups"];

            this.props.onStateChange(
              "signedIn",
              typeof info == "undefined" ? "sales" : info[0]
            );

            await this.context.dispatch({
              type: "IS_LOGGEDIN",
              loggedIn: true,
            });
            await this.context.dispatch({
              type: "userNameAndPAsswors",
              data: {
                username,
                password,
              },
            });
            this.props.history.push("/");
          });
        }
      })
      .catch(async (err) => {
        await this.context.dispatch({
          type: "IS_LOGGEDIN",
          loggedIn: false,
        });
        if (err.code === "UserNotConfirmedException") {
          Auth.resendSignUp(username)
            .then(() => {
              console.log("code resent successfully");
            })
            .catch((e) => {
              console.log(e);
            });
          this.props.onStateChange("confirmSignUp", username);
        } else if (err.code === "PasswordResetRequiredException") {
          this.setState({
            show_form: false,
            newpassword_show_form: false,
            showForgetPassword: true,
          });
        }

        this.setState({
          error: true,
          errorMessage: err.message,
        });
      });
  }
  componentWillUnmount() {
    this.setState({
      error: false,
    });
  }
  async componentDidMount() {
    let searchParams = await new URLSearchParams(window.location.search);
    let getPhoneNumber = searchParams.get("phone");
    let getPass = searchParams.get("pass");

    this.setState({
      phoneNumber:
        typeof getPhoneNumber == "string"
          ? `+91${getPhoneNumber.slice(-10)}`
          : "",
      password: typeof getPass == "string" ? getPass : "",
    });
  }
  render() {
    return (
      <>
        <Container fluid>
          <Row>
            <Col md={{ span: 4, offset: 4 }} xs={{ span: 12 }}>
              <Card
                bg="light"
                style={{ width: 20 + "rem", marginTop: 60 + "px" }}
              >
                <Card.Body>
                  <Card.Header style={{ textAlign: "center" }}>
                    {this.state.header ? "Sign In" : "NEW PASSWORD"}
                  </Card.Header>
                  {this.state.error && (
                    <Typography
                      component="h6"
                      align="center"
                      variant="h6"
                      color="error"
                    >
                      {this.state.errorMessage}
                    </Typography>
                  )}
                  {this.state.show_form && (
                    <form
                      ref="form"
                      onSubmit={this.handleSubmit}
                      onError={(errors) => console.log(errors)}
                    >
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          label="phoneNumber"
                          onChange={this.handleChange}
                          name="phoneNumber"
                          value={this.state.phoneNumber}
                        />
                      </FormControl>
                      <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="adornment-password">
                          Password
                        </InputLabel>

                        <Input
                          id="adornment-password"
                          type={this.state.showPassword ? " text" : "password"}
                          value={this.state.password}
                          name="password"
                          onChange={this.handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickShowPassword}
                              >
                                {this.state.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Sign in
                      </Button>
                    </form>
                  )}
                  {this.state.newpassword_show_form && (
                    <form
                      onSubmit={this.handlePassword}
                      onError={(errors) => console.error(errors)}
                    >
                      <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="adornment-password">
                          Password
                        </InputLabel>

                        <Input
                          id="adornment-password"
                          type={
                            this.state.showNewPassword ? " text" : "password"
                          }
                          value={this.state.confirm_password}
                          name="confirm_password"
                          onChange={this.handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickNewShowPassword}
                              >
                                {this.state.showNewPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        NEW PASSWORD
                      </Button>
                    </form>
                  )}

                  {this.state.showForgetPassword && (
                    <form
                      onSubmit={this.forgetPassword}
                      onError={(errors) => console.error(errors)}
                    >
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          label="ENTER YOUR VERFICATION CODE"
                          value={this.state.verficationCode}
                          onChange={this.handleChange}
                          name="verficationCode"
                        />
                      </FormControl>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          name="resetNewPassword"
                          label="ENTER NEW PASSWORD"
                          onChange={this.handleChange}
                          value={this.state.resetNewPassword}
                        />
                      </FormControl>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          label="ENTER YOUR PHONE NUMBER"
                          onChange={this.handleChange}
                          name="resetPhoneNumber"
                          value={this.state.resetPhoneNumber}
                          validators={["required", "isPhone"]}
                          errorMessages={[
                            "this field is required",
                            "PhoneNumber is not valid",
                          ]}
                        />
                      </FormControl>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        RESET PASSWORD
                      </Button>
                    </form>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(Login);
