import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import config from "../config";
export default class ViewGhoshakGift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showData: [],
      search: "",
      showUsers: [],
      showUserTable: undefined,
      showForm: true,
      ghoshakgift: {
        giftstatus: "",
        userId: "",
        giftImage: "",
        userPincode: "",
        giftDetails: "",
        userName: "",
        userId10Digit: "",
        imageFile: [],
        claimedDate: "",
        giftAddeddate: "",
        giftId: "",
        updatedOn: new Date().toISOString().substr(0, 10),
        giftAddedBy: ""
      },
      imagePreviewUrl: [],
      imageSize: ""
    };
    this.reset = this.state;
    this.searchResult = this.searchResult.bind(this);
    this.onChange = this.onChange.bind(this);
    this.viewUser = this.viewUser.bind(this);
    this.updateGhoshakGiftsubmit = this.updateGhoshakGiftsubmit.bind(this);
    this.handleimage = this.handleimage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.searchGift = this.searchGift.bind(this);
  }
  onChange(e) {
    this.setState({
      ...this.state,
      ghoshakgift: {
        ...this.state.ghoshakgift,
        [e.target.name]: e.target.value
      }
    });
  }
  searchGift(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  viewUser(userdetails) {
    this.setState({
      ...this.state,
      ghoshakgift: {
        ...this.state.ghoshakgift,
        userId: userdetails.userId,
        giftstatus: userdetails.giftstatus,
        giftImage: userdetails.giftImage,
        userPincode: userdetails.userPincode,
        giftDetails: userdetails.giftDetails,
        userName: userdetails.userName,
        userId10Digit: userdetails.userId10Digit,
        giftId: userdetails.giftId,
        claimDate: userdetails.claimDate,
        giftAddeddate: userdetails.giftAddeddate
      },

      showForm: false
    });
  }
  removeImage() {
    this.setState({
      ...this.state,
      ghoshakgift: {
        ...this.state.ghoshakgift,
        imageFile: []
      },
      imagePreviewUrl: []
    });
  }

  componentDidMount() {
    // console.log(`${config.json.URL}/imagesize.json`);
    Promise.all([fetch(`${config.json.URL}/imagesize.json`)])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        this.setState({
          imageSize: data.GIFT
        });
      })
      .catch(err => console.log(err));

    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({
          ...this.state,
          ghoshakgift: {
            ...this.state.ghoshakgift,
            giftAddedBy: user.attributes.phone_number
          }
        });
      })

      .catch(err => console.log(err));
  }

  handleimage(event) {
    if (event.target.files[0].size > parseInt(this.state.imageSize)) {
      alert("image file should be under 150kb ");
      return false;
    }
    this.setState({
      selectedFile: event.target.files[0]
    });

    let reader = new FileReader();
    let imageFormData = new FormData();
    imageFormData.append("photo", event.target.files[0]);
    reader.onloadend = () => {
      this.setState({
        ...this.state,
        ghoshakgift: {
          ...this.state.ghoshakgift,

          imageFile: reader.result.replace(/^data:image\/\w+;base64,/, "")
        }
      });
      this.setState({
        imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(event.target.files[0]);
  }

  updateGhoshakGiftsubmit(e) {
    e.preventDefault();
    Promise.all([
      fetch(
        `${config.ApiGateway}/updategift`,

        {
          method: "POST",
          body: JSON.stringify(this.state.ghoshakgift),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert("ghoshak gift updated");

        this.setState(this.reset);
      })
      .catch(err => {
        alert(err.message);
      });
  }
  searchResult(e) {
    e.preventDefault();
    Promise.all([
      fetch(
        `${config.ApiGateway}/viewgiftusers`,

        {
          method: "POST",
          body: JSON.stringify({ search: this.state.search }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.setState({
          showUsers: data,
          showUserTable: true
        });
      })
      .catch(err => {
        alert("ENTER VALID USER ID AND PINCODE");
      });
  }
  render() {
    return (
      <>
        {this.state.showForm ? (
          <>
            <Row>
              <Col md={{ span: 4, offset: 3 }} className="division">
                <h4 style={{ textAlign: "center" }}>ENTER USERID</h4>
                <Form onSubmit={this.searchResult}>
                  <Form.Control
                    type="text"
                    name="search"
                    pattern="[0-9]{6,10}"
                    onChange={this.searchGift}
                  ></Form.Control>
                  <Button
                    style={{
                      width: 100 + "%",
                      height: 30 + "%",
                      marginTop: 2 + "%",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    type="submit"
                  >
                    {" "}
                    SEARCH
                  </Button>
                </Form>
              </Col>
            </Row>
            {this.state.showUserTable ? (
              <>
                <Row className="division">
                  <Col>
                    <div
                      style={{
                        backgroundColor: "#fafafa",
                        width: 100 + "%",
                        margin: 1 + "%"
                      }}
                    >
                      <Col>
                        <h3 style={{ textAlign: "center" }}>RESULTS</h3>
                      </Col>
                    </div>
                    <Table
                      style={{
                        width: 100 + "%",
                        border: 0.5 + "px",
                        borderColor: "#ced4da",
                        borderStyle: "solid"
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S NO</TableCell>
                          <TableCell align="center">
                            USER TEN DIGIT ID
                          </TableCell>
                          <TableCell align="center">GIFT ADDED ON</TableCell>
                          <TableCell align="center">GIFT STATUS</TableCell>

                          <TableCell align="center">USER PINCODE</TableCell>

                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.showUsers.map((items, index) => (
                          <TableRow key={Row.userId}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                              {items.userId10Digit}
                            </TableCell>

                            <TableCell align="center">
                              {items.giftAddeddate}
                            </TableCell>
                            <TableCell align="center">
                              {items.giftstatus}
                            </TableCell>
                            <TableCell align="center">
                              {items.userPincode}
                            </TableCell>

                            <TableCell align="center">
                              <Button
                                onClick={() => {
                                  this.viewUser(items);
                                }}
                              >
                                VIEW
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <Row>
              <Col md={{ span: 6, offset: 2 }}>
                <Form onSubmit={this.updateGhoshakGiftsubmit}>
                  <Col>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        GIFT ID
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="userId"
                          onChange={this.onChange}
                          value={this.state.ghoshakgift.giftId}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        USER NAME
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="userName"
                          onChange={this.onChange}
                          value={this.state.ghoshakgift.userName}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        PINCODE
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="userPincode"
                          onChange={this.onChange}
                          value={this.state.ghoshakgift.userPincode}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        10-DIGIT ID
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="pincode"
                          onChange={this.onChange}
                          value={this.state.ghoshakgift.userId10Digit}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        GIFT ADDED DATE
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="giftAddeddate"
                          onChange={this.onChange}
                          value={this.state.ghoshakgift.giftAddeddate}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        GIFT DETAILS
                      </Form.Label>
                      <Col>
                        <Form.Control
                          as="textarea"
                          name="giftDetails"
                          onChange={this.onChange}
                          value={this.state.ghoshakgift.giftDetails}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    {/* <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        GIFT STATUS
                      </Form.Label>
                      <Col>
                        <Form.Control
                          as="textarea"
                          name="giftstatus"
                          onChange={this.onChange}
                          value={this.state.ghoshakgift.giftstatus}
                        ></Form.Control>
                      </Col>
                    </Form.Group> */}

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        GIFT STATUS
                      </Form.Label>

                      <Col>
                        <Form.Check
                          label="DRAFT"
                          type="radio"
                          name="giftstatus"
                          checked={
                            this.state.ghoshakgift.giftstatus === "Draft"
                          }
                          onChange={this.onChange}
                          value="Draft"
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          label="CLAIMED"
                          type="radio"
                          name="giftstatus"
                          checked={
                            this.state.ghoshakgift.giftstatus === "Claimed"
                          }
                          onChange={this.onChange}
                          value="Claimed"
                        />
                      </Col>
                    </Form.Group>
                    {this.state.ghoshakgift.giftstatus === "Claimed" && (
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          CLAIM DATE
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type="date"
                            name="claimedDate"
                            onChange={this.onChange}
                            value={this.state.ghoshakgift.claimedDate}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    )}

                    <Form.Group as={Row}>
                      <Form.Label classname="asterik" column sm="4">
                        ADD VERIFICATION IMAGE
                      </Form.Label>
                      <Col>
                        <input type="file" onChange={this.handleimage}></input>
                      </Col>
                    </Form.Group>

                    <Button type="submit" color="primary">
                      SUBMIT
                    </Button>
                  </Col>
                </Form>
              </Col>

              <Col md={{ span: 4 }}>
                <div>
                  <img
                    src={this.state.imagePreviewUrl}
                    alt={this.state.imagePreviewUrl}
                    width="300"
                    height="180"
                  ></img>

                  <img
                    src={this.state.ghoshakgift.giftImage}
                    alt={this.state.ghoshakgift.giftImage}
                    width="300"
                    height="180"
                  ></img>
                  <Button onClick={this.removeImage} variant="outline-light">
                    <CancelOutlinedIcon style={{ fontSize: 20 }} />
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}
