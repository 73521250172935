import React, { Component } from "react";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Auth } from "aws-amplify";
import config from "../config";
export default class Bookedbanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getBookingStatus: {
        pincode: "",
        bookingStatus: ""
      },

      changeStatusofBanner: {
        createdOn: new Date().toISOString().substr(0, 10),
        changestatus: "",
        reviewedBy: "",

        id: ""
      },
      changeStatus: "",
      showBannerTable: false,
      bookedSpaces: []
    };
    this.ReviewedBy = this.ReviewedBy.bind(this);
    this.reset = this.state;
    this.getBannerstatus = this.getBannerstatus.bind(this);
    this.onChange = this.onChange.bind(this);
    this.changeStatusSubmit = this.changeStatusSubmit.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
  }
  async componentDidMount() {
    await this.ReviewedBy();
  }
  componentDidUpdate() {
    window.onpopstate = e => {
      e.preventDefault();
      if (window.confirm("are you sure want to leave the page ?")) {
        window.location.assign("/");
      } else {
        window.location.assign("/editbanner");
      }
    };
  }
  ReviewedBy() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        return this.setState({
          ...this.state,
          changeStatusofBanner: {
            ...this.state.changeStatusofBanner,
            reviewedBy: user.attributes.phone_number
          }
        });
      })

      .catch(err => console.log(err));
  }
  changeStatusSubmit() {
    fetch(
      `${config.ApiGateway}/changeBannerStatus`,

      {
        method: "POST",
        body: JSON.stringify(this.state.changeStatusofBanner),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        alert(" CHANGED STATUS OF BANNER");
        this.ReviewedBy();
        this.setState(this.reset);
      })
      .catch(err => console.log(err));
  }
  changeStatus(response) {
    var bookedstatus = response.status === "Active" ? "Inactive" : "Active";

    this.setState(
      {
        ...this.state,
        changeStatusofBanner: {
          ...this.state.changeStatusofBanner,
          id: response.id,
          changestatus: bookedstatus,
          pincode: response.pincode
        }
      },
      () => {
        this.changeStatusSubmit();
      }
    );
  }
  getBannerstatus(e) {
    e.preventDefault();
    var bookedBanner = [];
    fetch(
      `${config.ApiGateway}/bookedBanners`,

      {
        method: "POST",
        body: JSON.stringify(this.state.getBookingStatus),
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        data.Items.map(elements => {
          return bookedBanner.push(elements);
        });
        this.setState({
          bookedSpaces: bookedBanner,
          showBannerTable: true
        });
      })
      .catch(err => console.log(err));
  }
  onChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,
      getBookingStatus: { ...this.state.getBookingStatus, [name]: value }
    });

    this.setState({
      [name]: value
    });
  }
  render() {
    return (
      <Container>
        <>
          {this.state.showBannerTable ? (
            <Row>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  margin: 1 + "%"
                }}
              >
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  BANNER LIST OF {
                    this.state.getBookingStatus.bookingStatus
                  }{" "}
                  BANNERS FOR {this.state.getBookingStatus.pincode}
                </h3>
              </div>
              <Table
                style={{
                  width: 100 + "%",
                  border: 0.5 + "px",
                  borderColor: "#ced4da",
                  borderStyle: "solid"
                }}
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S NO</TableCell>
                    <TableCell align="center">BANNER IMAGE</TableCell>
                    <TableCell align="center">PINCODE</TableCell>
                    <TableCell align="center">BANNER LOCATION</TableCell>
                    <TableCell align="center">START DATE</TableCell>
                    <TableCell align="center">END DATE</TableCell>
                    <TableCell align="center">STATUS</TableCell>
                    <TableCell align="center">CHANGE STATUS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.bookedSpaces.map((event, index) => (
                    <>
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          <img
                            src={event.bannerImageUrl}
                            alt={event.bannerImageUrl}
                            // height="300"
                            width="90%"
                          ></img>
                        </TableCell>
                        <TableCell align="center">{event.pincode}</TableCell>
                        <TableCell align="center">
                          {event.bannerlocation}
                        </TableCell>
                        <TableCell align="center">{event.startDate}</TableCell>
                        <TableCell align="center">{event.endDate}</TableCell>
                        <TableCell align="center">{event.status}</TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              if (
                                window.confirm(
                                  this.state.getBookingStatus.bookingStatus ===
                                    "Active"
                                    ? "Are you sure want to disable banner?"
                                    : "Are you sure want to activate banner again?"
                                )
                              ) {
                                this.changeStatus(event);
                              }
                            }}
                          >
                            {" "}
                            CHANGE STATUS
                          </Button>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Row>
          ) : (
            <Col>
              <div
                style={{
                  width: 100 + "%",
                  margin: 1 + "%"
                }}
              >
                <h3 style={{ textAlign: "center" }}>BOOKED BANNERS</h3>
              </div>
              <Form onSubmit={this.getBannerstatus}>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    ENTER PINCODE
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="pincode"
                      onChange={this.onChange}
                      value={this.state.getBookingStatus.pincode}
                      required
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    CHECK
                  </Form.Label>

                  <Col>
                    <Form.Check
                      label="INACTIVE"
                      type="radio"
                      name="bookingStatus"
                      onChange={this.onChange}
                      value="Inactive"
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      label="ACTIVE"
                      type="radio"
                      name="bookingStatus"
                      onChange={this.onChange}
                      value="Active"
                    />
                  </Col>
                </Form.Group>
                <Button type="submit">SUBMIT</Button>
              </Form>
            </Col>
          )}
        </>
      </Container>
    );
  }
}
