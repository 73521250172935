import React, { useEffect, useState, useContext } from "react";
import { Container, Col, Button, Row, Tabs, Tab, Modal } from "react-bootstrap";
import { store } from "../provider";
import MaterialTable from "material-table";
import { Link, useHistory } from "react-router-dom";
export default function ViewCustomerDetails() {
  const context = useContext(store);
  const [customerList, setCustomerList] = useState([]);
  const customerDetails = context.state.customerDetails;
  const CmanagementTitle = context.state.CmanagementTitle;
  const history = useHistory();
  const [managementTitle, setManagementTitle] = useState("");
  useEffect(() => {
    setManagementTitle(CmanagementTitle);
    setCustomerList(customerDetails);
  }, []);
  return (
    <Container>
      <div style={{ width: "100%", height: "100px" }}></div>
      <div className="orderHeader">
        <h2 className="orderHeaderText">Customer Details</h2>
      </div>

      <MaterialTable
        title={`${managementTitle} Customer Details`}
        columns={[
          {
            title: "Used Id",
            field: "userId10Digit",
            render: (rowData) => (
              <Link
                to={{
                  pathname: "/merchant",
                  search: `?userid=${rowData.userId10Digit}`,
                }}
              >
                {rowData.userId10Digit}
              </Link>
            ),
          },
          { title: "Name", field: "userName" },
          { title: "Number", field: "userPhoneNumber" },
          { title: "Pincode", field: "userPincode" },
          { title: "Address", field: "userAddress" },
          { title: "Joined Ghoshak", field: "userAddedDate" },

          {
            title: "Appointment",
            field: "appointment",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Orders",
            field: "order",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Bills",
            field: "bill",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Ghoshak points added",
            field: "ghoshakPointsAdded",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Ghoshak points Redeemed",
            field: "ghoshakPointsRedeemed",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Loyalty points added",
            field: "loyaltyPointsAdded",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Loyalty points Redeemed",
            field: "loyaltyPointsRedeemed",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Coupon Claimed",
            field: "couponClaimed",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Coupon Redeemed",
            field: "couponReedemed",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Total Activity",
            field: "active",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Total Refferals",
            field: "totalReferes",
            hidden: !(managementTitle === "referals"),
          },
        ]}
        data={customerList}
        options={{
          exportButton: true,
          pageSize: 5,
          pageSizeOptions: [5, 10, 20, 100, 300, 500, 1000, 2000],
          grouping: false,
          filtering: false,
        }}
      />
    </Container>
  );
}
