import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import Constant from "../deleivery/constant";
import Handler from "./handler/handler";
import Validation from "./handler/validation";
import {
  ProductAndServiceAPI,
  AddInventoryItemAPI,
  UpdateInventoryItemAPI,
  DeleteInventoryItemAPI,
} from "./handler/apiHandler";
let initialDropdownState = {
  fields: [],
};
export default function ProductAndService({ profile }) {
  const [findHandler] = Handler(); // Handler class
  const [productStatus, setProductStatus] = useState("all");
  const [state, setState] = useState({
    isLoading: true,
    products: [],
    count: {},
  });
  const [productStatistics, setProductStatistics] = useState({});
  let [validationHandler] = Validation();
  const [dropdown, setDropdown] = useState(initialDropdownState);
  useEffect(() => {
    getInitialDropdown();
  }, [profile]);

  const getInitialDropdown = async () => {
    // Initialize first function call
    let { shopType } = profile;
    let getProductList = await getProductAndServiceAPI();
    try {
      let getFieldsAndDropdown = await findHandler("PRODUCT_HANDLER", shopType);
      setDropdown(getFieldsAndDropdown);
    } catch (err) {
      console.log(err, "error getting json dropdown.fields fro handler");
    }
  };  
  const getProductAndServiceAPI = async () => {
    return await new Promise(async (resolve, reject) => {
      try {
        await ProductAndServiceAPI({
          shopId: profile.userId,
          productStatus: productStatus,
        }).then((data) => {
          if (data.status) {
            setState({
              isLoading: false,
              products: data.data.products,
              count: data.data.count,
            });

            return resolve(data.data.products || []);
          }

          reject();
        });
      } catch (err) {
        alert("data fetching failed ");
        reject();
      }
    });
  };
  const deleteProduct = async (rowData) => {
    await DeleteInventoryItemAPI({
      productId: rowData.productId,
      versionId: rowData.versionId,
    }).then((data) => {
      getInitialDropdown();
      alert("product deleted");
    });
  };
  const addInventoryItems = async (item, productUploadType) => {
    let validateProduct = await validationHandler("addProductInventory", {
      product: item,
      profile,
      Constant,
      fields: dropdown.fields,
      otherShopType: dropdown.otherShopType,
    });

    if (validateProduct.status) {
      let input = validateProduct.uploadObject;
      console.log(input, "light up sketchers");
      productUploadType == "add"
        ? await AddInventoryItemAPI(input).then((data) => {
            if (data.status) {
              alert("product uploaded");
              getInitialDropdown();
            } else {
              alert("upload failed");
            }
          })
        : await UpdateInventoryItemAPI(input[0]).then((data) => {
            //input  ---> COnvert to array to object
            if (data.status) {
              alert("product uploaded");
              getInitialDropdown();
            } else {
              alert("upload failed");
            }
          });

      return;
    } else {
      alert("product upload failed");
    }
  };

  return (
    <>
      <div className="vertical-space-10"></div>

      <div className="shopCardRow">
        <div className="shopCardHeader">
          <p>Product And Service</p>
        </div>
      </div>

      {state.isLoading ? null : (
        <>
          <div className="shopCardRow">
            <div className="shopCardColumn">
              <div className="shopCardBox">
                <ul className="horizontalList">
                  {Object.keys(state.count)
                    .sort((a, b) => b - a)
                    .map((item) => (
                      <li className="horizontalListItem">
                        <h6>{item}</h6>
                        <span>{state.count[item]}</span>
                      </li>
                    ))}
                </ul>
              </div>
            </div>

            <div className="vertical-space-10"></div>
            <div className="shopCardColumn">
              <div id="filterBox" className="fieldWrap">
                <label>Product Status</label>
                <select
                  onChange={(e) => setProductStatus(e.target.value)}
                  className="flexInputBox"
                  value={productStatus}
                >
                  <option value="live">Live</option>
                  <option value="ported">Ported</option>
                  <option value="all">All</option>
                </select>
              </div>
              <div className="wrapTable">
                <MaterialTable
                  style={{
                    width: "100%",
                  }}
                  actions={[
                    {
                      icon: "delete",
                      tooltip: "Delete Product",
                      onClick: (event, rowData) => deleteProduct(rowData),
                    },
                  ]}
                  columns={[
                    {
                      title: "Group",
                      field: "group",
                      editable: "never",
                      hidden: !dropdown.fields.includes("group"),
                    },
                    {
                      title: "Category",
                      field: "category",
                      hidden: !dropdown.fields.includes("category"),
                      editable: !["Mobile"].includes(profile.shopType)
                        ? "never"
                        : "always",
                      editComponent: (props) => {
                        return (
                          <select
                            type="text"
                            value={props.value}
                            onChange={(e) => {
                              let choosenSubCategory = e.target.value;

                              if (
                                Constant.MOBILE.key.includes(profile.shopType)
                              ) {
                                // handle category and group for mobile
                                props.rowData.subCategory = "Others";
                                props.rowData.group = "Others";
                              }
                              props.onChange(e.target.value);
                            }}
                          >
                            <option value="">Select Category</option>
                            {state.category}
                          </select>
                        );
                      },
                    },
                    {
                      title: "Sub Category",
                      field: "subCategory",
                      hidden: !dropdown.fields.includes("subCategory"),

                      editable: ![
                        "Textile",
                        "Supermarket",
                        "Provisional Store",
                        "Sweets and Snacks",
                        "Restaurant",
                        "Juice Shop",
                        "Bakery",
                      ].includes(profile.shopType)
                        ? "never"
                        : "always",
                      editComponent: (props) => {
                        return (
                          <select
                            type="text"
                            value={props.value}
                            onChange={(e) => {
                              let choosenSubCategory = e.target.value;

                              if (
                                Constant.TEXTILE.key.includes(
                                  profile.shopType
                                ) ||
                                Constant.KIRANA.key.includes(profile.shopType)
                              ) {
                                // handle category and group for textile

                                let findCategory = dropdown.subCategory.find(
                                  (elements) =>
                                    elements.subCategory == choosenSubCategory
                                );

                                if (Object.keys(findCategory).length > 0) {
                                  props.rowData.category =
                                    findCategory.category;
                                  props.rowData.group = findCategory.group;
                                } else {
                                  props.rowData.category = null;
                                  props.rowData.group = null;
                                }
                              } else {
                                props.rowData.category = "Others";
                                props.rowData.subCategory = "Others";
                                props.rowData.group = "Others";
                              }

                              props.onChange(e.target.value);
                            }}
                          >
                            <option value="">Select Sub Category</option>
                            {dropdown.subDropdown}
                          </select>
                        );
                      },
                    },
                    {
                      title: "Product Name",
                      field: "productName",
                      hidden: !dropdown.fields.includes("productName"),
                      validate: (rowData) => Boolean(rowData.productName),
                    },
                    {
                      title: "Duration",
                      field: "duration",
                      hidden: !dropdown.fields.includes("duration"),
                      editComponent: (props) => {
                        return (
                          <select
                            type="text"
                            value={props.value}
                            onChange={(e) => {
                              props.onChange(e.target.value);
                            }}
                          >
                            <option value="">Select Duration</option>
                            {state.duration}
                          </select>
                        );
                      },
                      validate: (rowData) => Boolean(rowData.duration),
                    },
                    {
                      title: "Brand",
                      field: "productBrand",
                      hidden: !dropdown.fields.includes("productBrand"),
                    },
                    {
                      title: "Selling Price ₹",
                      field: "productPrice",
                      hidden: !dropdown.fields.includes("productPrice"),
                      validate: (rowData) =>
                        !isNaN(rowData.productPrice) &&
                        rowData.productPrice != "",
                    },
                    {
                      title: "MRP ₹",
                      field: "productMrp",
                      hidden: !dropdown.fields.includes("productMrp"),
                      validate: (rowData) =>
                        !isNaN(rowData.productMrp) && rowData.productMrp != "",
                    },
                    {
                      title: "Inventory Quantity",
                      field: "inventoryQty",
                      type: "numeric",
                      hidden: !dropdown.fields.includes("inventoryQty"),
                      validate: (rowData) => parseInt(rowData.inventoryQty) > 0,
                    },
                    {
                      title: "Product Type",
                      field: "productType",
                      hidden: !dropdown.fields.includes("productType"),
                      editComponent: (props) => (
                        <select
                          type="text"
                          value={props.value}
                          onChange={(e) => props.onChange(e.target.value)}
                        >
                          <select value="">Select Product Type</select>
                          <option value="product">Product</option>
                          <option value="service">Service</option>
                        </select>
                      ),
                    },
                    { title: "Status ", field: "status", editable: "never" },

                    {
                      title: "Product Quantity ",
                      field: "productQty",

                      hidden: !dropdown.fields.includes("productQty"),
                      validate: (rowData) => parseInt(rowData.productQty) > 0,
                    },
                    {
                      title: "Measurement value",
                      field: "measurementValue",
                      hidden: ![
                        "Sweets and Snacks",
                        "Supermarket",
                        "Provisional Store",
                      ].includes(profile.shopType),
                      render: (rowData) => {
                        let measurementValue = "NA";
                        if (typeof rowData.unitOfMeasurment != "undefined") {
                          let tempValue = rowData.unitOfMeasurment.split(" ");
                          measurementValue = tempValue[0];
                        }

                        return <td>{measurementValue}</td>;
                      },

                      editComponent: (props) => {
                        let measurementValue = 0;
                        if (typeof props.unitOfMeasurment != "undefined") {
                          let tempValue = props.unitOfMeasurment.split(" ");
                          props.value = tempValue[0] || 0;
                        }

                        return (
                          <input
                            name="measurementValue"
                            value={props.value}
                            onChange={(e) => props.onChange(e.target.value)}
                          ></input>
                        );
                      },

                      validate: (rowData) =>
                        parseInt(rowData.measurementValue) >= 0,
                    },

                    {
                      title: "Measurement Unit",
                      field: "measurementUnit",
                      hidden: ![
                        "Sweets and Snacks",
                        "Supermarket",
                        "Provisional Store",
                      ].includes(profile.shopType),
                      validate: (rowData) => Boolean(rowData.measurementUnit),
                      render: (rowData) => {
                        let measurementUnit = "NA";
                        if (typeof rowData.unitOfMeasurment != "undefined") {
                          const tempValue = rowData.unitOfMeasurment.split(" ");
                          measurementUnit = tempValue[1];
                        }
                        return <td>{measurementUnit}</td>;
                      },
                      editComponent: (props) => {
                        return (
                          <select
                            name="measurementUnit"
                            value={props.value}
                            onChange={(e) => props.onChange(e.target.value)}
                          >
                            <option>Select Unit</option>
                            {dropdown.unit}
                          </select>
                        );
                      },
                    },

                    {
                      title: "Veg/ Non Veg",
                      field: "vegNonVeg",
                      editable: "always",
                      hidden: ![
                        "Sweets and Snacks",
                        "Supermarket",
                        "Provisional Store",
                      ].includes(profile.shopType),

                      validate: (rowData) => Boolean(rowData.vegNonVeg),
                      editComponent: (props) => (
                        <select
                          type="text"
                          value={props.value}
                          onChange={(e) => props.onChange(e.target.value)}
                        >
                          <option>Select Veg/ Non Veg</option>
                          <option value="Veg">Vegeterian</option>
                          <option value="Non-Veg">Non Vegterian</option>
                        </select>
                      ),
                    },

                    {
                      title: "Ean Code",
                      field: "productEANCode",
                      hidden: !dropdown.fields.includes("productEANCode"),
                      editable: "always",
                    },
                  ]}
                  editable={{
                    // only group(b) rows would be deletable
                    onRowAdd: (newData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          addInventoryItems([newData], "add");

                          resolve();
                        }, 1000);
                      }),

                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          addInventoryItems([newData], "update");

                          resolve();
                        }, 600);
                      }),
                  }}
                  options={{
                    addRowPosition: "first",
                    search: true,
                    headerStyle: { position: "sticky", top: 0 },
                    maxBodyHeight: 600,
                    exportButton: {
                      pdf: false,
                    },
                  }}
                  data={state.products}
                  title="Product List"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
