import React from "react";
import {
  Container,
  Col,
  Button,
  Row,
  Badge,
  Modal,
  Form,
} from "react-bootstrap";
export default function ViewShopProfileByPartner({ shopDetails }) {
  console.log(shopDetails);
  let { profile } = shopDetails;
  return (
    <>
      <Row
        style={{
          borderBottom: "1px solid rgb(219, 219, 219)",
          marginBottom: "5px",
        }}
      >
        <Col sm={12}>
          <div className="orderHeader">
            <h6 style={{ fontSize: "24px" }} className="orderHeaderText">
              Profile
            </h6>
          </div>
        </Col>
        <Col style={{ textAlign: "left" }} sm={6}>
          <h4 className="shopProfile">{profile.shopName}</h4>
          <span className="shopProfile">{profile.shopAddress}</span>
          <br />

          <span className="shopProfile">{profile.userPincode}</span>
        </Col>
        <Col style={{ textAlign: "right" }} sm={6}>
          <h4 className="shopProfile">{profile.userName}</h4>
          <span className="shopProfile">{profile.userPhoneNumber}</span>
          <br />
          <span className="shopProfile">{profile.shopType}</span>
        </Col>
      </Row>

      <Row
        style={{
          borderBottom: "1px solid rgb(219, 219, 219)",
          marginBottom: "5px",
        }}
      >
        <Col sm={12}>
          <div className="orderHeader">
            <h6 style={{ fontSize: "24px" }} className="orderHeaderText">
              Shop Timings
            </h6>
          </div>
        </Col>
        {typeof profile.shopTimings == "undefined" ? (
          <>
            <Col style={{ textAlign: "center" }}>
              <p>No Data to show</p>
            </Col>
          </>
        ) : (
          <>
            {Object.entries(profile.shopTimings).map(([key, value]) => {
              return (
                <Col>
                  <span style={{ fontSize: "16px" }}>{key}</span>
                  <br />
                  <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                    {value.openTime} - {value.closeTime}
                  </span>
                </Col>
              );
            })}
          </>
        )}
      </Row>
      <Row
        style={{
          borderBottom: "1px solid rgb(219, 219, 219)",
          marginBottom: "5px",
        }}
      >
        <Col sm={12}>
          <div className="orderHeader">
            <h6 style={{ fontSize: "24px" }} className="orderHeaderText">
              Activities
            </h6>
          </div>
        </Col>

        <Col>
          <span style={{ fontSize: "16px" }}>Loyalty</span>
          <br />

          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            <>
              <span style={{ color: "#89959b", fontSize: "14px" }}>
                Total Customers :{" "}
              </span>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {shopDetails.loyalty}
              </span>
              <br />
            </>
          </span>
        </Col>
        <Col>
          <span style={{ fontSize: "16px" }}>Orders</span>
          <br />

          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            <>
              <span style={{ color: "#89959b", fontSize: "14px" }}>
                Total Orders :{" "}
              </span>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {shopDetails.order}
              </span>
              <br />
            </>
          </span>
        </Col>
        <Col>
          <span style={{ fontSize: "16px" }}>Products</span>
          <br />

          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            <>
              <span style={{ color: "#89959b", fontSize: "14px" }}>
                Products Live :{" "}
              </span>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {shopDetails.product}
              </span>
              <br />
            </>
          </span>
        </Col>
        <Col>
          <span style={{ fontSize: "16px" }}>Appointments</span>
          <br />

          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            <>
              <span style={{ color: "#89959b", fontSize: "14px" }}>
                Total Appointmets :{" "}
              </span>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {shopDetails.appointment}
              </span>
              <br />
            </>
          </span>
        </Col>
        <Col>
          <span style={{ fontSize: "16px" }}>Bills</span>
          <br />

          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            <>
              <span style={{ color: "#89959b", fontSize: "14px" }}>
                Total bills :{" "}
              </span>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {shopDetails.bill}
              </span>
              <br />
            </>
          </span>
        </Col>
      </Row>

      <Row
        style={{
          borderBottom: "1px solid rgb(219, 219, 219)",
          marginBottom: "5px",
        }}
      >
        <Col sm={12}>
          <div className="orderHeader">
            <h6 style={{ fontSize: "24px" }} className="orderHeaderText">
              Shop Profile
            </h6>
          </div>
        </Col>
        <Col>
          <span style={{ fontSize: "16px" }}>Profile Picture Enabled</span>
          <br />

          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            {typeof profile.shopImageLocation == "string" ? (
              profile.shopImageLocation == "removed" ? (
                <>
                  <span style={{ color: "#89959b", fontSize: "14px" }}>
                    Status :{" "}
                  </span>
                  <Badge pill variant="warning">
                    Removed
                  </Badge>
                </>
              ) : (
                <>
                  <span style={{ color: "#89959b", fontSize: "14px" }}>
                    Status :
                  </span>
                  <Badge pill variant="success">
                    Enabled
                  </Badge>
                </>
              )
            ) : (
              <>
                <span style={{ color: "#89959b", fontSize: "14px" }}>
                  Status :{" "}
                </span>
                <Badge pill variant="danger">
                  Disabled
                </Badge>
              </>
            )}
          </span>
        </Col>
        <Col>
          <span style={{ fontSize: "16px" }}>Google Location</span>
          <br />

          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            {typeof profile.pin == "undefined" ? (
              <>
                <span style={{ color: "#89959b", fontSize: "14px" }}>
                  Status :{" "}
                </span>
                <Badge pill variant="danger">
                  Disabled
                </Badge>
                <br />
              </>
            ) : (
              <>
                <span style={{ color: "#89959b", fontSize: "14px" }}>
                  Status :{" "}
                </span>
                <Badge pill variant="success">
                  Enabled
                </Badge>
                <br />
                <span style={{ color: "#89959b", fontSize: "14px" }}>
                  Lat :{" "}
                </span>
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                  {profile.pin.location.lat}
                </span>
                <br />
                <span style={{ color: "#89959b", fontSize: "14px" }}>
                  Long :{" "}
                </span>
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                  {profile.pin.location.lon}
                </span>
                <br />
              </>
            )}

            {/* {profile.shopImageLocation !== "" ? "Enabled" : "Not Enabled"} */}
          </span>
        </Col>

        <Col>
          <span style={{ fontSize: "16px" }}>Loyalty Ratio</span>
          <br />

          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            {typeof profile.loyaltyRatio == "undefined" ? (
              <>
                <span style={{ color: "#89959b", fontSize: "14px" }}>
                  Status :{" "}
                </span>
                <Badge pill variant="danger">
                  Not Activated
                </Badge>
                <br />
              </>
            ) : (
              <>
                <span style={{ color: "#89959b", fontSize: "14px" }}>
                  Status :{" "}
                </span>
                <Badge pill variant="success">
                  Activated
                </Badge>
                <br />
                <span style={{ color: "#89959b", fontSize: "14px" }}>
                  Amount :{" "}
                </span>
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                  {" ₹ "} {profile.loyaltyAmount}
                </span>
                <br />
                <span style={{ color: "#89959b", fontSize: "14px" }}>
                  Points :{" "}
                </span>
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                  {profile.loyaltyPoints}
                </span>
                <br />
                <span style={{ color: "#89959b", fontSize: "14px" }}>
                  Ratio :{" "}
                </span>
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                  {profile.loyaltyRatio} {" % "}
                </span>
                <br />
              </>
            )}

            {/* {profile.shopImageLocation !== "" ? "Enabled" : "Not Enabled"} */}
          </span>
        </Col>
      </Row>
      <Row
        style={{
          borderBottom: "1px solid rgb(219, 219, 219)",
          marginBottom: "5px",
        }}
      >
        <Col sm={12}>
          <div className="orderHeader">
            <h6 style={{ fontSize: "24px" }} className="orderHeaderText">
              Packages
            </h6>
          </div>
        </Col>
        <Col>
          <span style={{ fontSize: "16px" }}>Billing</span>
          <br />
          <span style={{ color: "#89959b", fontSize: "14px" }}>Status : </span>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            {profile.billingPackage === "yes" ? "Activated" : "Not Activated"}
          </span>
          <br />
          <span style={{ color: "#89959b", fontSize: "14px" }}>
            Expiry Date :{" "}
          </span>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            {profile.billingPackage === "yes"
              ? profile.billingExpiryDate
              : " - "}
          </span>
        </Col>
        <Col>
          <span style={{ fontSize: "16px" }}>Website</span>
          <br />
          <span style={{ color: "#89959b", fontSize: "14px" }}>Status : </span>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            {profile.websiteStatus === "live" ? "Activated" : "Not Activated"}
          </span>
          <br />
          <span style={{ color: "#89959b", fontSize: "14px" }}>
            Expiry Date :{" "}
          </span>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            {profile.websiteStatus === "live" ? profile.websiteExpiryOn : " - "}
          </span>
          <br />
          <span style={{ color: "#89959b", fontSize: "14px" }}>URL : </span>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            {profile.websiteUrl}
          </span>
        </Col>

        <Col>
          <span style={{ fontSize: "16px" }}>Appointment</span>
          <br />
          <span style={{ color: "#89959b", fontSize: "14px" }}>Status : </span>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            {profile.appointmentPackage === "yes"
              ? "Activated"
              : "Not Activated"}
          </span>
          <br />
          <span style={{ color: "#89959b", fontSize: "14px" }}>
            Expiry Date :{" "}
          </span>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            {profile.appointmentPackage === "yes"
              ? profile.appointmentExpiryDate
              : " - "}
          </span>
        </Col>
        <Col>
          <span style={{ fontSize: "16px" }}>Orders</span>
          <br />
          <span style={{ color: "#89959b", fontSize: "14px" }}>Status : </span>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            {profile.deliveryPackage === "yes" ? "Activated" : "Not Activated"}
          </span>
          <br />
          <span style={{ color: "#89959b", fontSize: "14px" }}>
            Expiry Date :{" "}
          </span>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            {profile.deliveryPackage === "yes"
              ? profile.deliveryExpiryDate
              : " - "}
          </span>
        </Col>
        <Col>
          <span style={{ fontSize: "16px" }}>Loyalty</span>
          <br />
          <span style={{ color: "#89959b", fontSize: "14px" }}>Status : </span>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            {profile.loyaltyPackage === "yes" ? "Activated" : "Not Activated"}
          </span>
          <br />
          <span style={{ color: "#89959b", fontSize: "14px" }}>
            Expiry Date :{" "}
          </span>
          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
            {profile.loyaltyPackage === "yes"
              ? profile.loyaltyExpiryDate
              : " - "}
          </span>
        </Col>
      </Row>
    </>
  );
}
