import React, { useEffect, useState } from "react";
import DisplayTable from "./displayTable";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import Limitation from "./limitation";
import { GetCouponByCouponType } from "./service";
export default function CouponDistribution({ couponType }) {
  const [state, setState] = useState({
    startDate: Limitation.defaultOfferRange[0], // start date
    endDate: Limitation.defaultOfferRange[1], // end date
    couponType: couponType,
  });
  const [overview, setOverview] = useState({});
  const [pincode, setPincode] = useState([]);
  const [couponDetails, setCouponDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // get coupon by cupon type

    onChange("couponType", couponType);
  }, [couponType]);
  const getData = async () => {
    await GetCouponByCouponType(state).then((data) => {
      let trackCoupon = {
        overview: {
          count: 0,
          created: 0,
          allocatedCost: 0,

          expiredCoupons: 0,
          liveCoupons: 0,
          consumedCost: 0,
          consumedCoupon: 0,
        },
        pincode: {},
      };
      // sort coupon by recently posted
      let details = data.created.sort((a, b) => b.addedDate - a.addedDate);

      // filter by pincode
      data.used.map((elements) => {
        if (trackCoupon.pincode[elements.pincode] == undefined) {
          trackCoupon.pincode[elements.pincode] = {};
        }
        if (
          trackCoupon.pincode[elements.pincode][elements.couponStatus] ==
          undefined
        ) {
          trackCoupon.pincode[elements.pincode][elements.couponStatus] = 0;
        }
        trackCoupon.pincode[elements.pincode][elements.couponStatus]++;
      });
      data.created.forEach((elements) => {
        // track pincode by added status

        if (trackCoupon.pincode[elements.pincode] == undefined) {
          trackCoupon.pincode[elements.pincode] = {};
        }
        if (
          trackCoupon.pincode[elements.pincode][elements.couponStatus] ==
          undefined
        ) {
          trackCoupon.pincode[elements.pincode][elements.couponStatus] = 0;
        }
        trackCoupon.pincode[elements.pincode][elements.couponStatus] +=
          parseInt(elements.totalCouponCount) ||
          parseInt(elements.promocodeCount) ||
          0;

        // overview data

        trackCoupon.overview.count +=
          parseInt(elements.totalCouponCount) ||
          parseInt(elements.promocodeCount) ||
          0;

        trackCoupon.overview.consumedCost +=
          (parseInt(elements.promocodeCount) -
            parseInt(elements.promocodeRemainingCount)) *
            parseInt(elements.maximumCouponAmount) ||
          (parseInt(elements.totalCouponCount) -
            parseInt(elements.remainingCouponCount)) *
            parseInt(elements.maximumCouponAmount) ||
          0;

        trackCoupon.overview.consumedCoupon +=
          parseInt(elements.totalCouponCount) -
            parseInt(elements.remainingCouponCount) ||
          parseInt(elements.promocodeCount) -
            parseInt(elements.promocodeRemainingCount) ||
          0;

        trackCoupon.overview.allocatedCost +=
          parseInt(elements.maximumCouponAmount) *
            parseInt(elements.promocodeCount) ||
          parseInt(elements.maximumCouponAmount) *
            parseInt(elements.totalCouponCount) ||
          0;
        trackCoupon.overview.created += 1;
        (elements.expiryDate || elements.promocodeExpiryDate) >
        Limitation.todayDate
          ? trackCoupon.overview.liveCoupons++
          : trackCoupon.overview.expiredCoupons++;
      });
      let sortPincode = Object.entries(trackCoupon.pincode)
        .map(([key, value]) => ({ pincode: key, ...value }))
        .sort((a, b) => parseInt(b.Added || 0) - parseInt(a.Added || 0));

      setPincode(sortPincode);
      setOverview(trackCoupon.overview);
      setCouponDetails(details);
      setIsLoading(false);
    });
  };
  const onChange = (name, value) =>
    setState((prevState) => ({ ...prevState, [name]: value }));

  useEffect(() => {
    getData();
  }, [state]);

  return (
    <>
      <div className="titleHeader">
        <h4>{`${couponType}`} Coupons</h4>
      </div>

      <div className="flexRow">
        <div className="vertical-space-10"></div>
        <div className="labelWrapper">
          <h5>Overview</h5>
        </div>

        <div id="offer" className="flexColumn">
          <div className="flexCard">
            <div className="flexContent">
              <h5> {"Total Coupons"}</h5>
              <h4>{overview.count}</h4>
            </div>
          </div>
        </div>
        <div id="offer" className="flexColumn">
          <div className="flexCard">
            <div className="flexContent">
              <h5> {"Reedemed / Claimed"}</h5>
              <h4>{overview.consumedCoupon}</h4>
            </div>
          </div>
        </div>
        <div id="offer" className="flexColumn">
          <div className="flexCard">
            <div className="flexContent">
              <h5> {"Allocated Cost"}</h5>
              <h4>₹ {overview.allocatedCost}</h4>
            </div>
          </div>
        </div>
        <div id="offer" className="flexColumn">
          <div className="flexCard">
            <div className="flexContent">
              <h5> {"Claimed/Redeemed Cost"}</h5>
              <h4>₹ {overview.consumedCost}</h4>
            </div>
          </div>
        </div>
        <div className="vertical-space-10"></div>
        <div className="labelWrapper">
          <h5> Status</h5>
        </div>
        <div id="offer" className="flexColumn">
          <div className="flexCard">
            <div className="flexContent">
              <h5> {"Active Coupons"}</h5>
              <h4>{overview.liveCoupons}</h4>
            </div>
          </div>
        </div>

        <div id="offer" className="flexColumn">
          <div className="flexCard">
            <div className="flexContent">
              <h5> {"Expired Coupons"}</h5>
              <h4>{overview.expiredCoupons}</h4>
            </div>
          </div>
        </div>

        <div id="offer" className="flexColumn">
          <div className="flexCard">
            <div className="flexContent">
              <h5> {"No of times coupon created"}</h5>
              <h4>{overview.created}</h4>
            </div>
          </div>
        </div>
        <div className="vertical-space-10"></div>
        <div className="labelWrapper">
          <h5> Dashboard</h5>
        </div>
        <div className="flexColumn">
          <ResponsiveContainer
            style={{ float: "left", width: "100%" }}
            aspect={1.6}
          >
            <BarChart
              width={600}
              height={300}
              data={pincode}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              className="recharts"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                tickLine={true}
                xAxisId={0}
                dy={0}
                dx={-0}
                label={{
                  value: "",
                  angle: 0,
                  position: "bottom",
                }}
                interval={0}
                dataKey="pincode"
                tickLine={false}
                tick={{ fontSize: 9, angle: 0 }}
              />

              <YAxis
                label={{
                  value: "TOTAL COUPONS",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />

              <Bar dataKey="Redeemed" fill="red">
                <LabelList dataKey="Redeemed" position="top" />
              </Bar>
              <Bar dataKey="Added" fill="black">
                <LabelList dataKey="Added" position="top" />
              </Bar>
              <Bar dataKey="Claim" fill="#ffc107">
                <LabelList dataKey="Claim" position="top" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="vertical-space-10"></div>

        <div className="labelWrapper">
          <h5> Coupons</h5>
        </div>
        <div className="vertical-space-10"></div>

        <div id="displayTable" className="flexColumn">
          <DisplayTable
            data={couponDetails}
            validKeys={[
              "userId10Digit",
              "couponDetails",
              "shopName",

              "shopType",
              "promocode",
              "totalCouponCount",
              "allocatedCost",
              "consumedCost",
              "pincode",
              "addedDate",
            ]}
            title={`${couponType}`}
            isLoading={isLoading}
            // openModal={showData}
          />
        </div>
      </div>
    </>
  );
}
