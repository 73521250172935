import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
// import Button from "@material-ui/core/Button";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Reviewupdate from "./reviewUpdate";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Dropdown from "../dropDown";
import TablePagination from "@material-ui/core/TablePagination";
import { Auth } from "aws-amplify";
import config from "../config";
import { createBrowserHistory } from "history";
import MaterialTable from "material-table";
const history = createBrowserHistory();

const location = history.location;
export default class ContentReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getreview: {
        review: "no", //  yes -- reviewed offer, no -- offer status is draft
        offerType: "none",
      },
      reviewResult: [],
      contentReview: {},
      filterByImgQual: "All",
      reviewUpdate: false,
      tableResult: false,
      user: "",
      error: false,
      message: "",
      pending: "",
      pendingStatus: false,
      page: 0,
      rowsPerPage: 10,
      offerDropdownArray: [],
    };
    this.reset = this.reset.bind(this);
    this.resetall = this.state;

    // this.resetForm= this.resetForm.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.reviewChange = this.reviewChange.bind(this);
    this.reviewSubmit = this.reviewSubmit.bind(this);
    this.fetchShop = this.fetchShop.bind(this);
    this.result = this.result.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidUpdate() {
    window.onpopstate = (e) => {
      e.preventDefault();
      if (window.confirm("are you sure want to leave the page ?")) {
        window.location.assign("/");
      } else {
        window.location.assign("/review");
      }
    };
  }

  async result() {
    var d = new Date().toISOString().slice(0, 10);

    var store = [];
    var count = 0;
    var storeExpiredOfferId = [];
    await fetch(
      `${config.ApiGateway}/contentreview`,

      {
        method: "POST",
        body: JSON.stringify(this.state.getreview),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        data.forEach((element) => {
          if (element.offerEndDate < d && element.offerStatus === "Draft") {
            count++;
            storeExpiredOfferId.push(element); // to count expired offers
          }
        });

        let tempOfferDropdown = Dropdown.content.offerType.map((item) => {
          return <option key={item}>{item}</option>; // offers
        });
        return this.setState({
          pending: data,
          expiry: count,
          offerDropdownArray: tempOfferDropdown,
          pendingStatus: true,
        });
      });
  }
  reset() {
    this.componentDidMount();
    this.setState(this.resetall);
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  reviewChange(e) {
    this.setState({
      ...this.state,
      getreview: {
        ...this.state.getreview,
        [e.target.name]: e.target.value,
      },
    });
  }
  reviewSubmit(e) {
    e.preventDefault();
    var store = [];
    fetch(
      `${config.ApiGateway}/contentreview`,

      {
        method: "POST",
        body: JSON.stringify(this.state.getreview),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          reviewResult: data,
          tableResult: true,
        });
      });
  }

  async fetchShop(item) {
    var store;
    // to get shop details and offers
    await fetch(
      `${config.ApiGateway}/reviewGetShop`,

      {
        method: "POST",
        body: JSON.stringify({ shopId: item.shopId }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        store = data.Items.map((items) => ({
          userId: items.userId,
          pincode: items.userPincode,
          shopName: items.shopName,
          userName: items.userName,
          userId10Digit: items.userId10Digit,
          userPhoneNumber: items.userPhoneNumber,
          membershipId: items.membershipId,
          membershipType: items.membershipType,

          membershipupdatedOn: items.membershipupdatedOn,
          membershipupdatedBy: items.membershipupdatedBy,
          membershipExpiry: items.membershipExpiry,
          membershipPaymentID: items.membershipPaymentID,
        }));

        let reviewd = Object.assign(item, store[0]);

        this.setState({
          contentReview: reviewd,
          reviewUpdate: true,
          error: false,
          message: "",
        });
      })
      .catch((err) => {
        this.setState({
          error: true,
          message: "SHOP NOT FOUND",
        });
      });
  }
  async componentDidMount() {
    // get current user
    await Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((data) => {
        let user = { email: data.email, ...data.attributes };
        this.setState({ user });
      })

      .catch((err) => console.log(err));
    await this.result(); // to get offer based on status
  }
  render() {
    if (!this.state.showData) {
      const emptyRows =
        this.state.rowsPerPage -
        Math.min(
          this.state.rowsPerPage,
          this.state.reviewResult.length -
            this.state.page * this.state.rowsPerPage
        );
    }
    let offerList = this.state.reviewResult.filter((elements) =>
      elements.imageQuality === this.state.filterByImgQual
        ? elements.imageQuality
        : this.state.filterByImgQual == "All"
        ? elements
        : null
    );

    console.log();
    return (
      <Container fluid>
        <Col>
          {!this.state.reviewUpdate && (
            <>
              {this.state.pendingStatus && (
                <>
                  <div style={{ width: 100 + "%" }}>
                    <h1 style={{ textAlign: "center" }}>CONTENT REVIEW</h1>
                  </div>
                  <Row>
                    <Col md={{ span: 3, offset: 2 }} className="division">
                      <strong> PENDING REVIEW OFFERS </strong>{" "}
                      <h4 style={{ textAlign: "center" }}>
                        {" "}
                        {this.state.pending.length}
                      </h4>
                    </Col>
                    <Col md={{ span: 3, offset: 2 }} className="division">
                      <strong> EXPIRED OFFERS</strong>{" "}
                      <h4 style={{ textAlign: "center" }}>
                        {this.state.expiry}
                      </h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ span: 12 }}>
                      <Form onSubmit={this.reviewSubmit}>
                        <Form.Row style={{ marginTop: 1 + "%" }}>
                          <Col>
                            <strong>REVIEWED</strong>
                          </Col>

                          <Col>
                            <Form.Check
                              label="YES"
                              type="radio"
                              name="review"
                              onChange={this.reviewChange}
                              value="yes"
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              label="NO"
                              type="radio"
                              name="review"
                              onChange={this.reviewChange}
                              value="no"
                            />
                          </Col>

                          <Col>
                            <strong>OFFER TYPE</strong>
                          </Col>
                          <Col>
                            <Form.Control
                              as="select"
                              name="offerType"
                              onChange={this.reviewChange}
                              value={this.state.getreview.offerType}
                            >
                              {this.state.offerDropdownArray}
                            </Form.Control>
                          </Col>

                          {this.state.getreview.review === "yes" && (
                            <>
                              <Col>
                                <strong>APPROVED STATUS</strong>
                              </Col>
                              <Col>
                                <Form.Check
                                  label="YES"
                                  type="radio"
                                  name="approvedStatus"
                                  onChange={this.reviewChange}
                                  value="yes"
                                />
                              </Col>
                              <Col>
                                <Form.Check
                                  label="NO"
                                  type="radio"
                                  name="approvedStatus"
                                  onChange={this.reviewChange}
                                  value="no"
                                />
                              </Col>
                              <Col>
                                <strong>FILTER BY DATE </strong>
                              </Col>
                              <Col>
                                <Form.Control
                                  type="date"
                                  name="reviewedFrom"
                                  value={this.state.getreview.reviewedFrom}
                                  onChange={this.reviewChange}
                                />
                              </Col>

                              <Col>
                                <Form.Control
                                  type="date"
                                  name="reviewedTo"
                                  value={this.state.getreview.reviewedTo}
                                  onChange={this.reviewChange}
                                />
                              </Col>
                            </>
                          )}
                          <Col>
                            <Button type="submit">SEARCH</Button>
                          </Col>
                        </Form.Row>
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6"></Col>
                    <Col sm={"6"}>
                      <Form.Group style={{ float: "right" }}>
                        <Form.Label>Filter By Image Quality</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.onChange}
                          name="filterByImgQual"
                          value={this.state.filterByImgQual}
                          required
                        >
                          <option value="All">All</option>
                          <option value="good"> Good</option>
                          <option value="low">Low</option>

                          <option value="medium">Medium</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}

              {this.state.tableResult && (
                <>
                  <MaterialTable
                    title="Content Review"
                    columns={[
                      {
                        title: "Offer Imge",
                        field: "offerImageLocation",
                        render: (rowData) => (
                          <img
                            // src={JSON.parse(item.offerImageLocation)}
                            src={rowData.offerImageLocation}
                            alt={rowData.offerImageLocation}
                            width="200"
                            height="200"
                          ></img>
                        ),
                      },
                      {
                        title: "Shop Id",
                        field: "userId10Digit",
                        render: (rowData) => (
                          <Link
                            to={{
                              pathname: "/merchant",
                              search: `?userid=${rowData.userId10Digit}`,
                            }}
                          >
                            {rowData.userId10Digit}
                          </Link>
                        ),
                      },

                      {
                        title: "Shop Name",
                        field: "shopName",
                      },
                      {
                        title: "Phone Number",
                        field: "userPhoneNumber",
                      },
                      {
                        title: "Offer type",
                        field: "offerType",
                      },
                      {
                        title: "Details",
                        field: "offerDetails",
                      },

                      {
                        title: "Review ",
                        field: "review",
                      },
                      {
                        title: "Created on ",
                        field: "offerAddedDate",
                      },
                      {
                        title: "Reviewed on ",
                        field: "reviewedOn",
                        hidden: this.state.getreview.review === "no",
                      },
                      {
                        title: "Reviewed By",
                        field: "reviewedBy",
                        hidden: this.state.getreview.review === "no",
                      },

                      {
                        title: "Action",
                        field: "view",
                        render: (rowData) => (
                          <Button
                            onClick={() => {
                              this.fetchShop(rowData);
                            }}
                          >
                            Review offer
                          </Button>
                        ),
                      },
                    ]}
                    data={offerList}
                    options={{
                      exportButton: true,
                      pageSize: 30,

                      pageSizeOptions: [5, 10, 20, 100, 200, 500, 1000, 2000],
                      grouping: false,
                      filtering: false,
                    }}
                  />
                </>
              )}

              {/* {this.state.tableResult && (
                <>
                  <Row>
                    <div className="contentTableheader">
                      <h3
                        style={{
                          textAlign: "center",
                        }}
                      >
                        OFFER LIST
                      </h3>
                    </div>
                    <Table className="contentTableSize" size="medium">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S NO</TableCell>
                          <TableCell align="center">OFFER IMAGE </TableCell>
                          <TableCell align="center">OFFER ID</TableCell>
                          <TableCell align="center">SHOP NAME</TableCell>
                          <TableCell align="center">PHONE NUMBER</TableCell>
                          <TableCell align="center">USER ID</TableCell>

                          <TableCell align="center">SHOP PINCODE </TableCell>
                          <TableCell align="center">OFFER TYPE </TableCell>
                          <TableCell align="center">REVIEWED</TableCell>
                          <TableCell align="center">STATUS</TableCell>
                          <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {this.state.reviewResult
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((item, index) => (
                            <TableRow key={item.offerId}>
                              <>
                                <TableCell align="center">
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  // key={JSON.parse(item.offerImageLocation)}
                                  key={item.offerImageLocation}
                                >
                                  <img
                                    // src={JSON.parse(item.offerImageLocation)}
                                    src={item.offerImageLocation}
                                    alt={item.offerImageLocation}
                                    width="200"
                                    height="200"
                                  ></img>
                                </TableCell>

                                <TableCell align="center" key={item.offerId}>
                                  {item.offerId}
                                </TableCell>
                                <TableCell align="center">
                                  {item.shopName}
                                </TableCell>
                                <TableCell align="center">
                                  {item.userPhoneNumber}
                                </TableCell>
                                <TableCell align="center">
                                  <Link
                                    to={{
                                      pathname: "/merchant",
                                      search: `?userid=${item.userid}`,
                                    }}
                                  >
                                    {" "}
                                    {item.userId10Digit}{" "}
                                  </Link>
                                </TableCell>

                                <TableCell>{item.shopPincode}</TableCell>
                                <TableCell align="center" key={item.offerType}>
                                  {item.offerType}
                                </TableCell>
                                <TableCell align="center" key={item.review}>
                                  {item.review}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  key={item.offerStatus}
                                >
                                  {item.offerStatus}
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    onClick={() => {
                                      this.fetchShop(item);
                                    }}
                                  >
                                    REVIEW OFFER
                                  </Button>
                                </TableCell>
                              </>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      count={this.state.reviewResult.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </Row>
                </>
              )} */}
            </>
          )}

          {this.state.reviewUpdate && (
            <Reviewupdate
              contentReview={this.state.contentReview}
              reviewedBy={this.state.user.phone_number}
              reset={this.reset}
            />
          )}

          {this.state.error && (
            <>
              <h5 style={{ color: "red" }}> {this.state.message}</h5>
            </>
          )}
        </Col>
      </Container>
    );
  }
}
