import React, { useEffect, useState, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ShopProfilecard from "../template/shopProfile";
import MaterialTable from "material-table";
import Constant from "./constant";
import PublishIcon from "@material-ui/icons/Publish";
import Modal from "react-bootstrap/Modal";
import AddBoxIcon from "@material-ui/icons/AddBox";
import config from "../config";
import uuid from "uuid/dist/v4";
import S3 from "react-aws-s3";
import "../App.css";
import { Link } from "react-router-dom";
import { uploadImageToS3 } from "../service";
// 7793187914
export default function ShopInventoryProducts() {
  const [userId10Digit, setUserId10Digit] = useState("");
  const [productStatus, setProductStatus] = useState("all");
  const [state, setState] = useState({
    category: [],
    subCategory: [],
    duration: [],
    unit: [],
  });
  const [fields, setFields] = useState([]);
  const [otherShopType, setOtherShopType] = useState(false);
  const [profile, setProfile] = useState({});
  const [show, setShow] = useState(false); // modal state
  const [error, setError] = useState(false);
  const [product, setProduct] = useState([]);
  const [filterBy, setFilterBy] = useState("ported"); // default filter by status
  const [isShopVerified, setIsShopVerified] = useState(false);
  const [productImageArray, setProductImageArray] = useState([]);
  const [productChoosed, setProductChoosed] = useState("product");
  const handleClose = () => {
    setShow(false);
  };

  const getShopinventoryItems = async () => {
    Promise.all([
      fetch(
        `${config.ApiGateway}/shopInventoryProducts`,

        {
          method: "POST",

          body: JSON.stringify({ shopId: userId10Digit, productStatus }), // shop id
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        if (data.status) {
          if (data.payloadResponseExceededLimit) {
            try {
              let getDataFromBucket = await (await fetch(data.data)).json();
              setProduct(getDataFromBucket || []);
            } catch (err) {
              setProduct([]);
              alert("Fetch Failed");
            }
          } else {
            console.log("get data from DB__", data);
            setProduct(data.data.products || []);
          }

          let categoryDropdown = [];
          let subDropdown = [];
          let durationDropdown = [];
          let groupDropdown = [];
          let subArray = [];
          let unitDropdown = [];

          let shopProfile = data.data.profile;

          let { shopType } = shopProfile;

          let getTemplate = await getShopJsonbyShopType(shopType);

          if (Constant.SALOON.key.includes(shopType)) {
            let jsonType =
              shopType == "Saloon"
                ? getTemplate.salonCategory
                : getTemplate.beautyParlourCategory;
            jsonType.forEach((items) => {
              categoryDropdown.push(
                <option value={items.value}>{items.value}</option>
              );
            });
            getTemplate.duration.map((elements) =>
              durationDropdown.push(
                <option value={elements}>{elements}</option>
              )
            );
            setOtherShopType(false);
            setFields(Constant.SALOON.fields);
          } else if (Constant.KIRANA.key.includes(shopType)) {
            Object.entries(getTemplate.groups).map(([elements, headers]) => {
              Object.entries(headers.category).forEach(([items, values]) => {
                values.subCategory.map(
                  (sub) =>
                    sub !== "ALL" &&
                    categoryDropdown.push({
                      group: headers.name,
                      category: values.name,
                      subCategory: sub,
                    }) &&
                    subArray.push(sub)
                );
              });
            });
            subArray = subArray.sort();

            subArray.forEach((sub) => {
              subDropdown.push(<option value={sub}>{sub}</option>);
            });

            const getUnitDropdown = await fetch(
              `${config.json.URL}/fields.json`
            );
            var getMeasurement = await getUnitDropdown.json();
            getMeasurement.unitOfMesurement.forEach((elements) =>
              unitDropdown.push(<option value={elements}>{elements}</option>)
            );
            setOtherShopType(false);
            setFields(Constant.KIRANA.fields);
          } else if (Constant.RESTAURANT.key.includes(shopType)) {
            Object.entries(getTemplate).forEach(([key, value]) => {
              Object.values(value).forEach((key1) => {
                groupDropdown.push(
                  <option value={key1.name}>{key1.name}</option>
                );
                Object.values(key1.category).forEach((key2) => {
                  Object.values(key2.subCategory).forEach((key3) => {
                    subDropdown.push(<option value={key3}>{key3}</option>);
                  });

                  categoryDropdown.push(
                    <option value={key2.name}>{key2.name}</option>
                  );
                });
              });
            });
            setOtherShopType(false);
            setFields(Constant.RESTAURANT.fields);
          } else if (Constant.TEXTILE.key.includes(shopType)) {
            //
            getTemplate.category.map((item) => {
              getTemplate.subCategory.map((elements) => {
                if (item.value === elements.category) {
                  categoryDropdown.push({
                    group: "Others",
                    category: elements.category,
                    subCategory: elements.value,
                  }) && subArray.push(elements.value);
                }
              });
            });
            categoryDropdown
              .sort((a, b) => b.subCategory - a.subCategory)
              .map((items) =>
                subDropdown.push(
                  <option
                    value={items.subCategory}
                  >{`${items.category} - ${items.subCategory}`}</option>
                )
              );
            setOtherShopType(false);
            setFields(Constant.TEXTILE.fields);
          } else if (Constant.MOBILE.key.includes(shopType)) {
            getTemplate.mobileCategory.forEach((items) => {
              categoryDropdown.push(
                <option value={items.value}>{items.value}</option>
              );
            });
            setOtherShopType(false);
            setFields(Constant.MOBILE.fields);
          } else {
            setOtherShopType(true);
            setFields(Constant.OTHERS.fields);
          }
          setState((prevState) => ({
            ...prevState,
            category: categoryDropdown,
            subCategory: subDropdown,
            duration: durationDropdown,
            unit: unitDropdown,
          }));
          setProfile(shopProfile);
          setIsShopVerified(true);
        } else {
          setError(false);
        }
      })
      .catch((err) => {
        setError(true);
        console.log("Error getting products", err);
      });
  };
  const getShopJsonbyShopType = async (shopType) => {
    switch (shopType) {
      case "Supermarket":
      case "Provisional Store":
        try {
          const response = await fetch(
            `${config.json.URL}/productCategory.json`
          );
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }
      case "Sweets and Snacks":
        try {
          const response = await fetch(
            `${config.json.URL}/productCategorySweetsAndSnacks.json`
          );
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }
      case "Restaurant":
      case "Bakery":
      case "Juice Shop":
      case "Tea Shop":
        try {
          const response = await fetch(`${config.json.URL}/restaurant.json`);
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }
      case "Saloon":
      case "Textile":
      case "Beauty Parlour":
      case "Mobile":
        try {
          const response = await fetch(`${config.json.URL}/fields.json`);
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }

      default:
        return {};
    }
  };
  const validateProductByCondition = async (items) => {
    console.log("ITEMS", items);

    return await new Promise((resolve) => {
      let invalidData = [];
      let validData = [];
      let validFields = fields;
      items.forEach((elements) => {
        if (Constant.KIRANA.key.includes(profile.shopType)) {
          let unitValue =
            typeof elements.measurementUnit == "undefined"
              ? "g"
              : elements.measurementUnit;

          elements.unitOfMeasurment =
            elements.measurementValue + " " + unitValue;

          elements.vegNonVeg =
            typeof elements.vegNonVeg == "undefined"
              ? "Veg"
              : elements.vegNonVeg;

          delete elements.measurementValue;
          delete elements.measurementUnit;
        }

        if (otherShopType) {
          elements.group = "Others";
          elements.subCategory = "Others";
          elements.category = "Others";
        }

        elements.shopId = profile.userId;
        console.log("validFields", validFields);
        let evaluatedList = validFields.map((key) =>
          console.log("BOOLEAN VALUES", Boolean(elements[key]))
        ); // if key value is undefined, null or emplty returns false
        if (evaluatedList.includes(false)) {
          // evaluated data contains false in it is invalid
          invalidData.push(elements);
        } else {
          // valid data
          validData.push(elements);
        }
      });
      resolve([validData, invalidData]); // syntax 0 - valid, 1 - invalid
    });
  };
  const validateProduct = async (input) => {
    console.log("INPUT INPUT", input);
    let validated = await validateProductByCondition(input); //return  paramter - [valid,invalid]
    let validData = validated[0];
    let invalidData = validated[1];
    console.log("invalidData DATA", invalidData);

    if (invalidData.length > 0) {
      alert("Product Not Updated");
    } else {
      Promise.all([
        fetch(`${config.ApiGateway}/addInventoryItem`, {
          method: "POST",
          body: JSON.stringify({
            productList: validData,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ])
        .then(([res1]) => Promise.all([res1.json()]))
        .then(([data]) => {
          //   console.log(data);
          if (invalidData.length > 0) {
            alert("Product upload failed");
          } else {
            resetAll();
            getShopinventoryItems();
          }
        })
        .catch((err) => {
          alert("Product Not Updated");
          console.log(err);
        });
    }

    return;
  };

  const updateProduct = async (input) => {
    //console.log(input);
    let validated = await validateProductByCondition(input); //return  paramter - [valid,invalid]
    let validData = validated[0];
    let invalidData = validated[1];
    if (invalidData.length > 0) {
      alert("Product Not Updated");
    } else {
      let updateProductData = validData[0];
      Promise.all([
        fetch(`${config.ApiGateway}/shopEditProducts`, {
          method: "POST",
          body: JSON.stringify(updateProductData),
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ])
        .then(([res1]) => Promise.all([res1.json()]))
        .then(([data]) => {
          console.log("__ Product Edited __", updateProductData, data);
          resetAll();
          getShopinventoryItems();
          alert("product updated");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteProduct = (data) => {
    // Delete product
    Promise.all([
      fetch(`${config.ApiGateway}/deleteMasterProducts`, {
        method: "POST",
        body: JSON.stringify({
          productId: data.productId,
          versionId: data.versionId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert("product Deleted");
        getShopinventoryItems();
      })
      .catch((err) => alert(err));
  };
  // const addImage =async(e)=>{

  // }
  const removeSelectedProduct = (image) => {
    var updatedArray = productImageArray.filter(
      (item) => !item.includes(image)
    );
    console.log("updatedArray", updatedArray);
    setProductImageArray(updatedArray);
    return updatedArray;
  };
  useEffect(() => {
    getShopinventoryItems();
  }, [productStatus]);
  const resetAll = () => {
    setOtherShopType(false);
    setFields([]);
    setState((prevState) => ({
      ...prevState,
      category: [],
      subCategory: [],
      duration: [],
      unit: [],
    }));
  };
  return (
    <>
      <div className="flexBoxContainer">
        <div className="flexBoxHeader">
          <h2>Shop Inventory Products dev Mode</h2>
        </div>

        <div className="flexBoxRow">
          <div
            style={{ justifyContent: "center" }}
            id="displayTable"
            className="flexBoxColumn"
          >
            <div className="textInputBox">
              <Col lg="4" md="4" sm="2">
                <Form.Label>Enter Shop Id</Form.Label>
              </Col>
              <Col lg="6" md="6" sm="8">
                <Form.Control
                  onChange={(e) => setUserId10Digit(e.target.value)}
                  value={userId10Digit}
                />
                <Form.Text
                  style={{
                    display: error ? "inherit" : "none",
                    marginTop: "5px",
                    color: "#cc0033",
                  }}
                >
                  {/* Invalid Shop */}
                </Form.Text>
              </Col>

              <Col lg="2" md="1" sm="2">
                <Button onClick={() => getShopinventoryItems()}> Search</Button>
              </Col>
            </div>
          </div>
        </div>

        {isShopVerified && (
          <>
            <div className="flexBoxRow">
              <div
                style={{ justifyContent: "center", flexBasis: "100%" }}
                id="displayTable"
                className="flexBoxColumn"
              >
                <ShopProfilecard shopInfo={profile} />
              </div>
            </div>

            <div className="flexBoxRow">
              <div style={{ alignItems: "center" }} className="tableFilterRow">
                <div
                  id="displayTable"
                  style={{
                    justifyContent: "flex-end",
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  className="flexBoxColumn"
                >
                  <div
                    style={{ justifyContent: "flex-end", alignItems: "center" }}
                    id="quater"
                    className="flexBoxColumn"
                  >
                    <label style={{ textAlign: "right" }}>Product Status</label>
                    <select
                      onChange={(e) => setProductStatus(e.target.value)}
                      className="tableFilterSelect"
                      value={productStatus}
                    >
                      <option value="live">Live</option>
                      <option value="ported">Ported</option>
                      <option value="all">All</option>
                    </select>
                  </div>
                  <div
                    style={{ justifyContent: "flex-end", alignItems: "center" }}
                    id="quater"
                    className="flexBoxColumn"
                  >
                    <label style={{ textAlign: "right" }}> Upload</label>
                    <Link
                      to={{
                        pathname: "/uploadThroughExcel",
                        search: `?shopId=${userId10Digit}`,
                      }}
                    >
                      <button className="tableFilterButton">
                        <span>
                          <PublishIcon />
                        </span>
                        Excel Upload
                      </button>
                    </Link>
                  </div>
                  {["Restaurant", "Bakery", "Juice Shop"].includes(
                    profile.shopType
                  ) && (
                    <div
                      style={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                      id="quater"
                      className="flexBoxColumn"
                    >
                      <label style={{ textAlign: "right" }}> Scan Image</label>
                      <Link
                        to={{
                          pathname: "/gpbs",
                          search: `?shopId=${userId10Digit}`,
                        }}
                      >
                        <button className="tableFilterButton">
                          <span>
                            <PublishIcon />
                          </span>
                          Scan Upload
                        </button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div
                id="displayTable"
                style={{ width: "100%", maxWidth: "100%" }}
                className="flexBoxColumn tableBox"
              >
                <MaterialTable
                  style={{
                    width: "100%",
                  }}
                  actions={[
                    {
                      icon: "delete",
                      tooltip: "Delete Product",
                      onClick: (event, rowData) => deleteProduct(rowData),
                    },
                  ]}
                  columns={[
                    {
                      title: "Group",
                      field: "group",
                      editable: "never",
                      hidden: !fields.includes("group"),
                    },
                    {
                      title: "Category",
                      field: "category",
                      hidden: !fields.includes("category"),
                      editable: "always",

                      // editable: !["Mobile"].includes(profile.shopType)
                      //   ? "never"
                      //   : "always",
                      editComponent: (props) => {
                        return (
                          <div>
                            <input
                              type="text"
                              // value={props.value}
                              placeholder="Add Category"
                              onChange={(e) => {
                                console.log("EEEEEEE", e.target.value);
                                props.onChange(e.target.value);
                              }}
                              style={{ marginBottom: "10px" }}
                            />
                            <select
                              type="text"
                              value={props.value}
                              onChange={(e) => {
                                let choosenSubCategory = e.target.value;
                                if (
                                  Constant.MOBILE.key.includes(profile.shopType)
                                ) {
                                  // handle category and group for mobile
                                  props.rowData.subCategory = "Others";
                                  props.rowData.group = "Others";
                                }
                                props.onChange(e.target.value);
                              }}
                            >
                              <option value="">Select Category</option>
                              {state.category}
                            </select>
                          </div>
                        );
                      },
                    },
                    {
                      title: "Sub Category",
                      field: "subCategory",
                      hidden: !fields.includes("subCategory"),
                      editable: "always",

                      // editable: ![
                      //   "Textile",
                      //   "Supermarket",
                      //   "Provisional Store",
                      //   "Sweets and Snacks",
                      //   "Restaurant",
                      //   "Juice Shop",
                      //   "Bakery",
                      // ].includes(profile.shopType)
                      //   ? "never"
                      //   : "always",

                      editComponent: (props) => {
                        return (
                          <div>
                            <input
                              type="text"
                              // value={props.value}
                              placeholder="Add Subcategory"
                              onChange={(e) => {
                                console.log("EEEEEEE", e.target.value);
                                props.onChange(e.target.value);
                              }}
                              style={{ marginBottom: "10px" }}
                            />
                            <select
                              type="text"
                              value={props.value}
                              onChange={(e) => {
                                let choosenSubCategory = e.target.value;
                                if (
                                  Constant.TEXTILE.key.includes(
                                    profile.shopType
                                  ) ||
                                  Constant.KIRANA.key.includes(profile.shopType)
                                ) {
                                  // handle category and group for textile

                                  let findCategory = state.category.find(
                                    (elements) =>
                                      elements.subCategory == choosenSubCategory
                                  );

                                  if (Object.keys(findCategory).length > 0) {
                                    props.rowData.category =
                                      findCategory.category;
                                    props.rowData.group = findCategory.group;
                                  } else {
                                    props.rowData.category = null;
                                    props.rowData.group = null;
                                  }
                                } else if (
                                  Constant.RESTAURANT.key.includes(
                                    profile.shopType
                                  )
                                ) {
                                  props.rowData.category = "RESTAURANT";
                                  props.rowData.subCategory = "RESTAURANT";
                                  props.rowData.group = "RESTAURANT";
                                } else {
                                  props.rowData.category = "OTHERS";
                                  props.rowData.subCategory = "OTHERS";
                                  props.rowData.group = "OTHERS";
                                }

                                props.onChange(e.target.value);
                              }}
                            >
                              <option value="">Select Sub Category</option>
                              {state.subCategory}
                            </select>
                          </div>
                        );
                      },
                    },
                    {
                      title: "Product Name",
                      field: "productName",
                      hidden: !fields.includes("productName"),
                      validate: (rowData) => Boolean(rowData.productName),
                    },

                    {
                      title: "Brand",
                      field: "productBrand",
                      hidden: !fields.includes("productBrand"),
                    },
                    {
                      title: "Selling Price ₹",
                      field: "productPrice",
                      hidden: !fields.includes("productPrice"),
                      validate: (rowData) =>
                        !isNaN(rowData.productPrice) &&
                        rowData.productPrice != "",
                    },
                    {
                      title: "MRP ₹",
                      field: "productMrp",
                      hidden: !fields.includes("productMrp"),
                      validate: (rowData) =>
                        !isNaN(rowData.productMrp) && rowData.productMrp != "",
                    },
                    {
                      title: "Inventory Quantity",
                      field: "inventoryQty",
                      type: "numeric",
                      hidden: !fields.includes("inventoryQty"),
                      // validate: (rowData) => parseInt(rowData.inventoryQty) > 0,
                    },
                    {
                      title: "Product Type",
                      field: "productType",
                      hidden: !fields.includes("productType"),
                      editComponent: (props) => (
                        <select
                          type="text"
                          value={props.value}
                          onChange={(e) => {
                            props.onChange(e.target.value);
                            setProductChoosed(e.target.value);
                          }}
                        >
                          <select value="">Select Product Type</select>
                          <option value="product">Product</option>
                          <option value="service">Service</option>
                        </select>
                      ),
                    },
                    {
                      title: "Duration",
                      field: "duration",
                      hidden:
                        !fields.includes("duration") ||
                        productChoosed == "product",
                      editComponent: (props) => {
                        return (
                          <div>
                            <select
                              type="text"
                              value={props.value}
                              onChange={(e) => {
                                props.onChange(e.target.value);
                              }}
                            >
                              <option value="">Select Duration</option>
                              {state.duration}
                            </select>
                          </div>
                        );
                      },
                      validate: (rowData) => Boolean(rowData.duration),
                    },
                    { title: "Status ", field: "status", editable: "never" },
                    {
                      title: "Product Image ",
                      field: "productImageArray",
                      editComponent: (props) => {
                        return (
                          <div>
                            <input
                              type="file"
                              onChange={async (e) => {
                                if (productImageArray.length >= 8) {
                                  return alert("Maximum images added");
                                } else {
                                  var data = await handleImageUpload(e);
                                  productImageArray.push(data);
                                  props.onChange(productImageArray);
                                }
                              }}
                              // onChange={(e)=>addImage(e)}
                              accept="image/png, image/jpeg"
                            />
                            <div className="imageContainerMain">
                              {productImageArray.map((data) => (
                                // <div >
                                <div className="imageContainer">
                                  <p
                                    className="xbutton"
                                    onClick={() =>
                                      props.onChange(
                                        removeSelectedProduct(data)
                                      )
                                    }
                                  >
                                    x
                                  </p>
                                  <img src={data} height={70} width={70} />
                                </div>
                                // </div>
                              ))}
                            </div>
                          </div>
                        );
                      },
                    },

                    {
                      title: "Quantity",
                      field: "productQty",
                      hidden: !fields.includes("productQty"),
                      validate: (rowData) => parseInt(rowData.productQty) > 0,
                    },
                    {
                      title: "Measurement value",
                      field: "measurementValue",
                      hidden: ![
                        "Sweets and Snacks",
                        "Supermarket",
                        "Provisional Store",
                      ].includes(profile.shopType),
                      render: (rowData) => {
                        let measurementValue = "NA";
                        if (typeof rowData.unitOfMeasurment != "undefined") {
                          let tempValue = rowData.unitOfMeasurment.split(" ");
                          measurementValue = tempValue[0];
                        }

                        return <td>{measurementValue}</td>;
                      },

                      editComponent: (props) => {
                        let measurementValue = 0;
                        if (typeof props.unitOfMeasurment != "undefined") {
                          let tempValue = props.unitOfMeasurment.split(" ");
                          props.value = tempValue[0] || 0;
                        }

                        return (
                          <input
                            name="measurementValue"
                            value={props.value}
                            onChange={(e) => props.onChange(e.target.value)}
                          ></input>
                        );
                      },

                      validate: (rowData) =>
                        parseInt(rowData.measurementValue) >= 0,
                    },

                    {
                      title: "Measurement Unit",
                      field: "measurementUnit",
                      hidden: ![
                        "Sweets and Snacks",
                        "Supermarket",
                        "Provisional Store",
                      ].includes(profile.shopType),
                      validate: (rowData) => Boolean(rowData.measurementUnit),
                      render: (rowData) => {
                        let measurementUnit = "NA";
                        if (typeof rowData.unitOfMeasurment != "undefined") {
                          const tempValue = rowData.unitOfMeasurment.split(" ");
                          measurementUnit = tempValue[1];
                        }
                        return <td>{measurementUnit}</td>;
                      },
                      editComponent: (props) => {
                        return (
                          <select
                            name="measurementUnit"
                            value={props.value}
                            onChange={(e) => props.onChange(e.target.value)}
                          >
                            <option>Select Unit</option>
                            {state.unit}
                          </select>
                        );
                      },
                    },

                    {
                      title: "Veg/ Non Veg",
                      field: "vegNonVeg",
                      editable: "always",
                      hidden: ![
                        "Sweets and Snacks",
                        "Supermarket",
                        "Provisional Store",
                        ...Constant.RESTAURANT.key,
                      ].includes(profile.shopType),
                      // editable: ![
                      //   "Sweets and Snacks",
                      //   "Supermarket",
                      //   "Provisional Store",
                      // ].includes(profile.shopType),
                      validate: (rowData) => Boolean(rowData.vegNonVeg),
                      editComponent: (props) => (
                        <select
                          type="text"
                          value={props.value}
                          onChange={(e) => props.onChange(e.target.value)}
                        >
                          <option>Select Veg/ Non Veg</option>
                          <option value="Veg">Vegeterian</option>
                          <option value="Non-Veg">Non Vegterian</option>
                        </select>
                      ),
                    },

                    {
                      title: "Ean Code",
                      field: "productEANCode",
                      hidden: !fields.includes("productEANCode"),
                      editable: "always",
                    },
                  ]}
                  editable={{
                    // only group(b) rows would be deletable
                    onRowAdd: (newData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          validateProduct([newData]);
                          resolve();
                        }, 1000);
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          updateProduct([newData]);
                          resolve();
                        }, 600);
                      }),
                    // onRowDelete: (oldData) =>
                    //   new Promise((resolve, reject) => {
                    //     setTimeout(() => {

                    //       // const dataDelete = [...product];
                    //       // const index = oldData.tableData.id;
                    //       // dataDelete.splice(index, 1);
                    //       // setProduct([...dataDelete]);
                    //       resolve();
                    //     }, 1000);
                    //   }),
                  }}
                  options={{
                    addRowPosition: "first",
                    exportButton: {
                      csv: true,
                      pdf: false,
                    },
                  }}
                  data={product}
                  title="Product List"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
const handleImageUpload = async (image) => {
  const configSettings = {
    bucketName: "ghoshak-website-builder",
    region: "ap-south-1",

    fileName: uuid() + ".jpeg",
  };

  let data = await uploadImageToS3(image, configSettings)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("S3 Error", err);
    });
  console.log("IMAGE DATA", data);
  return data;
};
