import { is } from "@babel/types";
import React, { useState } from "react";
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import config from "../config";

const LinkPartnerToShop = () => {
  const [userId10Digit, setUserId10Digit] = useState("");
  const [shopDetails, setShopDetails] = useState({});
  const [deliveryChargeType, setDeliveryChargeType] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState("");
  // const [button]
  const verifyShop = async () => {
    console.log(userId10Digit);
    try {
      var res = await fetch(`${config.ApiGateway}/getshop`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId10Digit: userId10Digit,
        }),
      });
      res = await res.json();
      console.log("Shop details", res);
      if (res.Items[0].length === 0) return alert("Invaild shopId");
      setShopDetails(res.Items[0]);
      if (res.Items[0].deliveryChargeType)
        setDeliveryChargeType(res.Items[0].deliveryChargeType);
      if (res.Items[0].chargeForDelivery)
        setDeliveryCharge(res.Items[0].chargeForDelivery);
      // if (res.Items.length > 0) {
      // }
    } catch (err) {
      console.log("Error", err);
    }
  };

  const linkDeliveryPartner = async () => {
    var charge = "0";
    if (deliveryChargeType === "")
      return alert("Please Choose Delivery charge type");
    if (deliveryChargeType !== "partnerCharge") {
      charge = deliveryCharge;
      if (deliveryCharge === "") return alert("Enter valid Delivery charge");
      if (isNaN(deliveryCharge))
        return alert("Please enter a valid delivery charge");
    } else {
      if (deliveryCharge) charge = deliveryCharge;
      else charge = "0";
    }
    try {
      let res = await fetch(`${config.ApiGateway}/linkPartnerToShop`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lat: shopDetails.pin.location.lat,
          lon: shopDetails.pin.location.lon,
          shopId: shopDetails.userId,
          deliveryPartner: "Dunzo",
          deliveryCharge: charge,
          deliveryChargeType: deliveryChargeType,
        }),
      });
      res = await res.json();
      console.log("Result", res);
      if (res.code === "ERROR") return alert(res.message);
      else alert(res.message);
      setUserId10Digit("");
      setShopDetails({});
      setDeliveryChargeType("");
      setDeliveryCharge("");
    } catch (err) {
      console.log("Error", err);
      alert(err);
    }
  };

  return (
    <Container>
      <div style={{ marginTop: "20px" }}>
        <h3>Link Delivery Partner to Shop</h3>
        {/* <input /> */}
        <div className="flexRow">
          <Col
            className="dashboardColumnBox columnCenter"
            style={{ marginTop: "10px" }}
            sm="8"
          >
            <Form>
              <Form.Row>
                <Col sm="4">
                  <Form.Label>Enter Shop Id</Form.Label>
                </Col>
                <Col sm="6">
                  <Form.Control
                    onChange={(e) => setUserId10Digit(e.target.value)}
                    value={userId10Digit}
                  />
                  {/* <Form.Text
                  style={{
                    color: "red",
                    display: errMessage.length > 0 ? "" : "none",
                  }}
                >
                  {errMessage}
                </Form.Text> */}
                </Col>
                <Col sm="2">
                  <Button onClick={verifyShop}>Search</Button>
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </div>
        {Object.keys(shopDetails).length > 0 && (
          <div>
            <div>
              <Col
                className="dashboardColumnBox columnCenter"
                style={{ marginTop: "10px" }}
                sm="8"
              >
                <div>
                  <h4>Shop Name</h4>
                  <p>{shopDetails.shopName}</p>
                </div>
                <div>
                  <h4>Shop Address</h4>
                  <p>{shopDetails.shopAddress}</p>
                </div>
                <div>
                  <h4>Shop Phone Number</h4>
                  <p>{shopDetails.userPhoneNumber}</p>
                </div>
                <div>
                  <h4>Shop Pincode</h4>
                  <p>{shopDetails.userPincode}</p>
                </div>
                {shopDetails.pin ? (
                  <div>
                    <h4>Shop Latitude and longitude</h4>
                    <p>Lat: {shopDetails.pin.location.lat}</p>
                    <p>Lng: {shopDetails.pin.location.lon}</p>
                  </div>
                ) : (
                  <p style={{ color: "red" }}>
                    User has Not added their google location
                  </p>
                )}
                <div>
                  <h4>
                    Choose delivery charge type
                    <span style={{ color: "red" }}>*</span>
                  </h4>
                  <input
                    type="radio"
                    name="charge"
                    value="merchantCharge"
                    onChange={(e) => setDeliveryChargeType(e.target.value)}
                    checked={deliveryChargeType === "merchantCharge"}
                  />{" "}
                  <label>Merchant delivery charge</label>
                  <br />
                  <input
                    type="radio"
                    name="charge"
                    value="partnerCharge"
                    onChange={(e) => setDeliveryChargeType(e.target.value)}
                    checked={deliveryChargeType === "partnerCharge"}
                  />{" "}
                  <label>Delivery Partner Charge</label>
                  <br />
                  <input
                    type="radio"
                    name="charge"
                    value="merchantPlusPartner"
                    onChange={(e) => setDeliveryChargeType(e.target.value)}
                    checked={deliveryChargeType === "merchantPlusPartner"}
                  />{" "}
                  <label>Delivery Partner + Merchant define charge</label>
                  <br />
                  {deliveryChargeType === "merchantCharge" && (
                    <div>
                      <h5>
                        Enter Merchant delivery charge
                        <span style={{ color: "red" }}>*</span>
                      </h5>
                      <input
                        type="text"
                        value={deliveryCharge}
                        onChange={(e) => setDeliveryCharge(e.target.value)}
                      />
                    </div>
                  )}
                  {deliveryChargeType === "merchantPlusPartner" && (
                    <div>
                      <h5>
                        Enter Merchant additional charge(Added with partners
                        charge)<span style={{ color: "red" }}>*</span>
                      </h5>
                      <input
                        type="text"
                        value={deliveryCharge}
                        onChange={(e) => setDeliveryCharge(e.target.value)}
                      />
                    </div>
                  )}
                </div>
                <br />
                <Button
                  style={{ marginBottom: "50px" }}
                  onClick={linkDeliveryPartner}
                >
                  Add delivery partner
                </Button>
              </Col>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default LinkPartnerToShop;
