import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";

export default function Payment({ classes, profile }) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getNotificationAPI();
  }, [profile]);
  //   razorpayLinkedAccount
  const getNotificationAPI = async () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="shopCardRow" id="fullHeight">
          <div className="centerFlex">
            <h3>Loading...</h3>
          </div>
        </div>
      ) : (
        <>
          {profile.razorpayLinkedAccount ? (
            <>
              <div className="shopCardRow">
                <div className="shopCardColumn">
                  <div id="fullWidth" className="fieldWrap">
                    <ul className="horizontalList">
                      <li id="noBorder" className="horizontalListItem">
                        <h6>Beneficiary Name</h6>
                        <span>
                          {profile.razorpayLinkedAccount["beneficiaryName"]}
                        </span>
                      </li>
                      <li id="noBorder" className="horizontalListItem">
                        <h6>Account Type</h6>
                        <span>
                          {profile.razorpayLinkedAccount["accountType"]}
                        </span>
                      </li>
                      <li id="noBorder" className="horizontalListItem">
                        <h6>Account Number</h6>
                        <span>
                          {profile.razorpayLinkedAccount["accountNumber"]}
                        </span>
                      </li>
                      <li id="noBorder" className="horizontalListItem">
                        <h6>Raxorpay Account Id</h6>
                        <span>
                          {profile.razorpayLinkedAccount["razorpayAccountId"]}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="shopCardRow" id="fullHeight">
                <div id="packageExpired" className="centerFlex">
                  <h3>Account Not Linked</h3>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
