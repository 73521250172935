//import dependencies
import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button, Badge } from "react-bootstrap";
import Dropdown from "../dropDown";
import MaterialTable from "material-table";
import moment from "moment";
import { Link } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays, format } from "date-fns";
import Modal from "react-bootstrap/Modal";
import PrintIcon from "@material-ui/icons/Print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import config from "../config";

import * as XLSX from "xlsx/xlsx.mjs";
export default function CustomerInsight(props) {
  const [range, setRange] = useState([
    {
      startDate: addDays(new Date(), -10),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [tempRange, setTempRange] = useState([
    {
      startDate: addDays(new Date(), -10),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // open close

  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const checkIfDateValid = () => {
    if (Object.keys(tempRange).length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const processApply = () => {
    if (!checkIfDateValid()) return alert("Please select valid dates");
    setRange([...tempRange]);
  };
  const handleShow = () => setShow(true);
  const refOne = useRef(null);

  const [bills, setBills] = useState([]);
  const [orders, setOrders] = useState([]);

  let tcountbill = 0;
  let tcountorder = 0;
  let tcountvalue = 0;
  const [billsbackup, setBillsbackup] = useState([]);
  const [ordesbackup, setOrdersbackup] = useState([]);
  const [billscount, setBillscount] = useState(tcountbill);
  const [totalBillValue, setTotalBillValue] = useState("");
  const [orderscount, setOrderscount] = useState(tcountorder);

  const [isLoadingbill, setIsLoadingbill] = useState(true);
  const [isLoadingorder, setIsLoadingorder] = useState(true);
  const [billsbackupsecond, setBillsbackupsecond] = useState([]);
  const [ordesbackupsecond, setOrdersbackupsecond] = useState([]);
  // Loading State

  const [filteratererror, setFilteratererror] = useState(false);
  const [filterBybills, setFilterBybills] = useState("Bill");
  const [filterByPeriod, setFilterByPeriod] = useState([]);
  const [inputnumberfirst, setInputnumberfirst] = useState("");
  const [inputnumbercecond, setInputnumbercecond] = useState("");
  const [isValidFirst, setisValidFirst] = useState(false);
  const [isInvalidFirst, setisInvalidFirst] = useState(false);
  const [isValidSecond, setisValidSecond] = useState(false);
  const [isInvalidSecond, setisInvalidSecond] = useState(false);

  useEffect(() => {
    async function loadData() {
      let dropdownDays = Dropdown.dashboard.Dtypes.map((items) => (
        <option value={items.name}>{items["name"]}</option>
      ));
      setFilterByPeriod(dropdownDays);
    }
    loadData();
  }, []);

  // useEffect(() => {
  //   getData();
  // }, []);

  useEffect(() => {
    if (
      `${format(range[0].startDate, "yyyy-MM-dd")}` !== "" &&
      `${format(range[0].endDate, "yyyy-MM-dd")}` !== ""
    ) {
      getData();
    }
  }, [
    `${format(range[0].startDate, "yyyy-MM-dd")}`,
    `${format(range[0].endDate, "yyyy-MM-dd")}`,
  ]);

  const daterange = JSON.stringify({
    startDate: `${format(range[0].startDate, "yyyy-MM-dd")}`,
    endDate: `${format(range[0].endDate, "yyyy-MM-dd")}`,
  });

  localStorage.setItem("daterange", daterange);

  const handleSearchbillsfirst = (event) => {
    const onlyDigits = event.target.value.replace(/\D/g, "");
    if (onlyDigits != event.target.value) {
      setisInvalidFirst(true);
      setisValidFirst(false);
    } else {
      if (parseInt(event.target.value) > parseInt(inputnumbercecond)) {
        setisInvalidFirst(true);
        setisValidFirst(false);
      } else {
        setisInvalidFirst(false);
        setisValidFirst(true);
      }
    }

    setInputnumberfirst(event.target.value);
    let resultbill = [];
    let resultorder = [];
    let tcountbill = 0;
    let tcountorder = 0;
    let tocountValue = 0;
    resultbill = billsbackup.filter((data) => {
      if (data.count >= parseInt(event.target.value)) {
        tcountbill += parseInt(data.count);
        tocountValue += parseFloat(data.billAmount);
      }

      return data.count >= parseInt(event.target.value);
    });
    resultorder = ordesbackup.filter((data) => {
      if (data.count >= parseInt(event.target.value)) {
        tcountorder += parseInt(data.count);
      }

      return data.count >= parseInt(event.target.value);
    });
    setBills(resultbill);
    setOrders(resultorder);
    setBillscount(tcountbill);
    setTotalBillValue(tocountValue);
    setOrderscount(tcountorder);
    setBillsbackupsecond(resultbill);
    setOrdersbackupsecond(resultorder);

    if (event.target.value === "") {
      if (inputnumbercecond === "") {
        getData();
      }
      setisValidFirst(false);
      console.log("inputnumberfirst", inputnumberfirst);
      setBills(billsbackup);
      setOrders(ordesbackup);
      let tbc = 0;
      let toc = 0;
      let tbv = 0;
      billsbackup.filter((data) => {
        tbc += parseInt(data.count);
        tbv += parseFloat(data.billAmount);
      });
      ordesbackup.filter((data) => {
        toc += parseInt(data.count);
      });
      console.log("tcountbill tcountorder ---", tcountbill, tcountorder);
      setTotalBillValue(tbv);
      setBillscount(tcountbill);
      setOrderscount(tcountorder);
      setBillscount(tbc);
      setOrderscount(toc);
    }
  };

  // const isInvalid = (event) => {
  //   return flase;
  // };

  // const isValid = (event) => {
  //   return true;
  // };

  const handleSearchbillssecond = (event) => {
    const onlyDigits = event.target.value.replace(/\D/g, "");
    if (onlyDigits != event.target.value) {
      setisInvalidSecond(true);
      setisValidSecond(false);
    } else {
      if (parseInt(event.target.value) < parseInt(inputnumberfirst)) {
        setisInvalidSecond(true);
        setisValidSecond(false);
      } else {
        setisInvalidSecond(false);
        setisValidSecond(true);
      }
    }

    setInputnumbercecond(event.target.value);
    let resultbill = [];
    let resultorder = [];
    let tcountbill = 0;
    let tcountorder = 0;
    let billTotalValue = 0;

    resultbill = billsbackupsecond.filter((data) => {
      if (data.count <= parseInt(event.target.value)) {
        tcountbill += parseInt(data.count);
        billTotalValue += parseFloat(data.billAmount).toFixed(2);
      }

      return data.count <= parseInt(event.target.value);
    });

    resultorder = ordesbackupsecond.filter((data) => {
      if (data.count <= parseInt(event.target.value)) {
        tcountorder += parseInt(data.count);
      }
      return data.count <= parseInt(event.target.value);
    });

    setBills(resultbill);
    setBillscount(tcountbill);
    setTotalBillValue(billTotalValue);
    setOrders(resultorder);
    setOrderscount(tcountorder);

    if (event.target.value === "") {
      if (inputnumberfirst === "") {
        getData();
      }
      setisValidSecond(false);
      setBills(billsbackupsecond);
      setOrders(ordesbackupsecond);
      let tbc = 0;
      let toc = 0;
      let tbv = 0;
      billsbackup.filter((data) => {
        tbc += parseInt(data.count);
        tbv += parseFloat(data.billAmount);
      });
      ordesbackup.filter((data) => {
        toc += parseInt(data.count);
      });
      setBillscount(tcountbill);
      setOrderscount(tcountorder);
      setBillscount(tbc);
      setTotalBillValue(tbv);
      setOrderscount(toc);
    }
  };
  // const getData = async () => {
  //   alert("coming inside");
  //   try {
  //     let data = await fetch(
  //       `https://vkbmrh8wc3.execute-api.ap-south-1.amazonaws.com/dev/billingProgram`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": "http://localhost:3000",
  //         },
  //         method: "post",
  //         body: JSON.stringify({
  //           type: "customerinsight",
  //           startDate: `${format(range[0].startDate, "yyyy-MM-dd")}`,
  //           endDate: `${format(range[0].endDate, "yyyy-MM-dd")}`,
  //         }),
  //       }
  //     );
  //     let response = await data.json();

  //     console.log("rxponse", response);
  //   } catch (Err) {}
  // };

  const getData = async () => {
    setOpen(false);
    setTotalBillValue("");
    setBillscount();
    try {
      let data =
        await //  fetch(`${config.ApiGateway}/billingProgram`, {https://vkbmrh8wc3.execute-api.ap-south-1.amazonaws.com/dev/billingProgram
        fetch(`${config.ApiGateway}/billingProgram`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "customerinsight",
            startDate: `${format(range[0].startDate, "yyyy-MM-dd")}`,
            endDate: `${format(range[0].endDate, "yyyy-MM-dd")}`,
          }),
        });

      data = await data.json();

      console.log("data_received", await data);
      if (data.status && data.limitExceeds) {
        getDatabill(data.bills);
        getDataorder(data.orders);
        setOpen(false);
      } else if (!data.limitExceeds) {
        setBills(data.bills);
        setOrders(data.orders);
        setBillsbackup(data.bills);
        setOrdersbackup(data.orders);
        setBillsbackupsecond(data.bills);
        setOrdersbackupsecond(data.orders);
        setIsLoadingbill(false);
        setIsLoadingorder(false);
        data.bills.filter((data) => {
          tcountbill += parseInt(data.count);
          tcountvalue += parseFloat(data.billAmount);
        });
        data.orders.filter((data) => {
          tcountorder += parseInt(data.count);
        });
        setBillscount(tcountbill);
        setOrderscount(tcountorder);
        setTotalBillValue(tcountvalue);
        setOpen(false);
      }
    } catch (err) {
      console.log("ERROR_RESPONSE_GET_DATA", err);
      // setTimeout(() => {
      //   getDatabill();
      //   getDataorder();
      // }, 70000);
    }
  };

  const urlbill =
    "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/productJson/crmBillingProgrambill.JSON";
  const urlorder =
    "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/productJson/crmBillingProgramorder.JSON";

  const getDatabill = async (url) => {
    await Promise.all([
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        setBills(data);
        setBillsbackup(data);
        setBillsbackupsecond(data.bills);

        setIsLoadingbill(false); //  loading state
        setIsLoadingorder(false);
        data.filter((data) => {
          tcountbill += parseInt(data.count);
        });
        setBillscount(tcountbill);
      })
      .catch((err) => console.log(err));
  };

  const getDataorder = async (url) => {
    await Promise.all([
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        setOrders(data);
        setOrdersbackup(data);

        setOrdersbackupsecond(data.orders);
        setIsLoadingbill(false); //  loading state

        data.filter((data) => {
          tcountorder += parseInt(data.count);
        });
        setOrderscount(tcountorder);
      })
      .catch((err) => console.log(err));
  };

  const headers = [
    { label: "Ghoshak Id", key: "userId10Digit" },
    { label: "Shop Name", key: "shopName" },
    { label: "Shop Type", key: "shopType" },
    { label: "Address", key: "shopAddress" },
    { label: "Package Status", key: "billingPackage" },
    { label: "Expiration", key: "billingPackage" },
    {
      label: filterBybills === "Bill" ? "No of Bills" : "No of Orders",
      key: "count",
    },
    { label: "Bill Amount", key: "billAmount" },
    { label: "Customers", key: "customer" },
    { label: "Type", key: "type" },
  ];

  const headerspdf = [
    { title: "Ghoshak Id", field: "userId10Digit" },
    { title: "Shop Name", field: "shopName" },
    { title: "Shop Type", field: "shopType" },
    { title: "Address", field: "shopAddress" },
    {
      title: "Package Status yes:Active, no:Inactive",
      field: "billingPackage",
    },

    {
      title: filterBybills === "Bill" ? "No of Bills" : "No of Orders",
      field: "count",
    },
    { title: "Bill Amount", field: "billAmount" },
    { title: "Type", field: "type" },
  ];

  //PDF Downloading
  const downloadPdf = () => {
    const docc = new jsPDF({
      orientation: "landscape",
    });
    docc.text(filterBybills === "Bill" ? "bills" : "orders", 20, 20);
    docc.autoTable({
      theme: "grid",
      columns: headerspdf.map((col) => ({ dataKey: col.field, ...col })),
      body: filterBybills === "Bill" ? bills : orders,
    });
    docc.save(filterBybills === "Bill" ? "billsType.pdf" : "ordersType.pdf");
  };

  //For downloading in CSV format
  const csvReport = {
    data: filterBybills === "Bill" ? bills : orders,
    headers: headers,
    filename: filterBybills === "Bill" ? "bills" : "orders",
  };

  //For downloading in Excel sheet format
  const downloadExcel = () => {
    const data = filterBybills === "Bill" ? bills : orders;
    const newData = data.map((row) => {
      delete row.tableData;
      return row;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet);
    //Buffer
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(
      workBook,
      filterBybills === "Bill" ? "bills.xlsx" : "orders.xlsx"
    );
  };

  const [getTestData, setGetTestData] = useState([]);
  const getTesterData = () => {
    fetch(
      "https://payload-response.s3.ap-south-1.amazonaws.com/testAccount.json"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setGetTestData(data.testAccount);
      });
  };

  useEffect(() => {
    getTesterData();
  }, []);

  const testdata = [];
  getTestData.forEach((items) => {
    testdata.push(items.userPhoneNumber);
  });

  let getBills = [];
  let getOrders = [];
  bills.forEach((itemsbill) => {
    if (!testdata.includes(itemsbill.userPhoneNumber)) {
      getBills.push(itemsbill);
    }
  });

  orders.forEach((itemsorder) => {
    if (!testdata.includes(itemsorder.userPhoneNumber)) {
      getOrders.push(itemsorder);
    }
  });

  return (
    <Container fluid>
      <div className="orderHeader">
        <h2 className="orderHeaderText" style={{ marginLeft: "40%" }}>
          Customer Insight
        </h2>
      </div>
      <>
        <div className="customerinsight" style={{ marginLeft: "30%" }}>
          <Container>
            <Row>
              <Col sm="2" style={{ marginBottom: "10px" }}>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>No of Bills/Orders</Form.Label>
                    <Form.Control
                      value={inputnumberfirst}
                      name="inputnumberfirst"
                      onChange={(inputnumberfirst) =>
                        handleSearchbillsfirst(inputnumberfirst)
                      }
                      as="Input"
                      type="inputnumberfirst"
                      placeholder="Min Value"
                      isValid={isValidFirst}
                      isInvalid={isInvalidFirst}
                    />
                    <Form.Text className="text-muted">
                      {isInvalidFirst == true
                        ? "Enter  Valid and less Then Second Number"
                        : ""}
                    </Form.Text>
                  </Form.Group>
                </Form>
              </Col>
              <Col sm="2" style={{ marginBottom: "10px" }}>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>No of Bills/Orders</Form.Label>
                    <Form.Control
                      value={inputnumbercecond}
                      name="inputnumbercecond"
                      onChange={(inputnumbercecond) =>
                        handleSearchbillssecond(inputnumbercecond)
                      }
                      as="Input"
                      type="inputnumbercecond"
                      placeholder="Max Value"
                      isValid={isValidSecond}
                      isInvalid={isInvalidSecond}
                    />
                    <Form.Text className="text-muted">
                      {isInvalidSecond == true
                        ? "Enter Valid and Greater Then First Number"
                        : ""}
                    </Form.Text>
                  </Form.Group>
                </Form>
              </Col>
              <Col sm="2" style={{ marginBottom: "1px" }}>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Type</Form.Label>

                    <Form.Control
                      value={filterBybills}
                      name="filterBybills"
                      onChange={(e) => setFilterBybills(e.target.value)}
                      as="Select"
                      type="filterBybills"
                      placeholder="Enter No of Bills"
                    >
                      {filterByPeriod}
                    </Form.Control>
                  </Form.Group>
                </Form>
              </Col>
              <Col sm="4" style={{ marginBottom: "10px" }}>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Date Range Picker</Form.Label>
                    <Form.Control
                      value={`From :  ${format(
                        range[0].startDate,
                        "yyyy-MM-dd"
                      )}  to :  ${format(range[0].endDate, "yyyy-MM-dd")} `}
                      name="inputnumber"
                      onClick={() => setOpen((open) => !open)}
                      as="Input"
                      type="DateFilter"
                      placeholder="Date Filter"
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col sm="4" style={{ marginBottom: "10px" }}>
                <div>
                  <Modal
                    show={open}
                    onHide={handleClose}
                    animation={true}
                    centered={true}
                  >
                    <Modal.Body>
                      <div className="calendarWrap">
                        <div ref={refOne}>
                          <DateRangePicker
                            onChange={(item) => {
                              console.log("item", item);
                              // setRange([item.selection]);
                              setTempRange([item.selection]);
                              setIsLoadingbill(true);
                              setIsLoadingorder(true);
                            }}
                            editableDateInputs={true}
                            moveRangeOnFirstSelection={true}
                            ranges={tempRange}
                            months={2}
                            direction="horizontal"
                            className="calendarElement"
                            alwaysShowCalendars={true}
                            rangeColors={["#f33e5b", "#3ecf8e", "#fed14c"]}
                            showDateDisplay={true}
                            scroll={{ enabled: true }}
                            color="#3d91ff"
                          />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={() => processApply()}>
                        Apply
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
      <div className="dashboardContainer">
        <>
          <div className="flexContainer">
            <h5 className="orderHeaderText" style={{ marginRight: "67%" }}>
              {filterBybills === "Bill" ? (
                <div>
                  {" "}
                  <div>{`Total Bills: ${billscount}`}</div>
                  <div>{`Total Bill value: ₹ ${
                    totalBillValue ? totalBillValue.toFixed(2) : ""
                  }`}</div>
                </div>
              ) : (
                `Total No. of Orders: ${orderscount} `
              )}
            </h5>

            <div className="vertical-space-10"></div>

            <div style={{ marginLeft: "70%" }}>
              <button onClick={() => downloadPdf()}>Export as PDF</button>
              <button>
                <CSVLink
                  {...csvReport}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Export as CSV
                </CSVLink>
              </button>
              <button onClick={() => downloadExcel()}>Export as Excel</button>
            </div>
            <div className="vertical-space-20"></div>
            <div className="flexBoxRow">
              <div id="displayTable" className="flexBoxColumn">
                <MaterialTable
                  title={"Shops"}
                  columns={[
                    {
                      title: "Ghoshak Id",
                      field: "userId10Digit",
                    },
                    {
                      title: "Shop Name",
                      field: "shopName",
                    },

                    {
                      title: "Shop Type",
                      field: "shopType",
                    },
                    {
                      title: "Address",
                      field: "shopAddress",
                    },
                    {
                      title: "Package Status",
                      field: "billingPackage",
                      render: (rowData) =>
                        rowData.billingPackage == "yes" ? (
                          <Badge variant="success">Active</Badge>
                        ) : (
                          <Badge variant="danger">Inactive</Badge>
                        ),
                    },
                    {
                      title: "Expiration",
                      field: "billingPackage",
                      render: (rowData) => {
                        return new Date().toISOString().substring(0, 10) <
                          rowData.billingExpiryDate ? (
                          <p>
                            {rowData.billingExpiryDate} <br />
                            {`( ${moment(rowData.billingExpiryDate).diff(
                              moment(new Date().toISOString().substring(0, 10)),
                              "days"
                            )}days remaining )`}
                          </p>
                        ) : (
                          <Badge variant="danger">Expired</Badge>
                        );
                      },
                    },

                    {
                      title:
                        filterBybills === "Bill"
                          ? "No of Bills"
                          : "No of Orders",
                      field: "count",
                      defaultSort: "desc",
                    },
                    {
                      title:
                        filterBybills === "Bill"
                          ? "Bill Amount"
                          : "Order Amount",
                      field:
                        filterBybills === "Bill" ? "billAmount" : "totalAmount",
                      render:
                        filterBybills === "Bill"
                          ? ({ billAmount }) =>
                              `₹ ${parseFloat(billAmount || 0).toFixed(2)}`
                          : ({ totalAmount }) =>
                              `₹ ${parseFloat(totalAmount || 0).toFixed(2)}`,
                    },

                    {
                      title: "Customers",
                      field: "customer",
                      render: ({ customer }) => customer.length || 0,
                    },
                    {
                      title: "type",
                      field: "type",
                    },

                    {
                      title: "Action",
                      field: "action",

                      render: (rowData) => (
                        <Link
                          to={{
                            pathname: "/info",
                            search: `?userid=${JSON.stringify({
                              userId: rowData.userId10Digit,
                              startDate: `${format(
                                range[0].startDate,
                                "yyyy-MM-dd"
                              )}`,
                              endDate: `${format(
                                range[0].endDate,
                                "yyyy-MM-dd"
                              )}`,
                              type: filterBybills === "Bill" ? "bill" : "order",
                              indicate: `customerinsight`,
                            })}`,
                          }}
                        >
                          <Button size="sm">View</Button>
                        </Link>
                      ),
                    },
                  ]}
                  data={filterBybills === "Bill" ? getBills : getOrders}
                  style={{ width: "100%" }}
                  isLoading={isLoadingbill && isLoadingorder}
                  options={{
                    exportButton: true,
                    pageSize: 5,
                    //maxBodyHeight: 437,
                    pageSizeOptions: [5, 10, 20, 100, 500, 1000],
                    grouping: false,
                    filtering: false,
                    tableLayout: "fixed",
                  }}
                />
              </div>
            </div>
            <div className="vertical-space-20"></div>
          </div>
        </>
      </div>
    </Container>
  );
}
