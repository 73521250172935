import React, { useEffect, useState } from "react";
import DisplayTable from "./displayTable";
import { B2B } from "./service";
export default function AddedCustomer() {
  const [segmentDistribution, setSegmentDistribution] = useState([]);
  const [shop, setShop] = useState([]);
  const [title, setTitle] = useState("");
  useEffect(() => {
    // Call class on mounting

    getData();
  }, []);

  const getData = async () => {
    await B2B().then(async (data) => {
      console.log(data);
    });
  };
  return (
    <>
      <div className="titleHeader">
        <h4>Shop Added Customer</h4>
      </div>

      {shop.length > 0 && (
        <>
          <div className="flexRow">
            <div className="labelWrapper">
              <h5>Shops</h5>
            </div>
            <div id="displayTable" className="flexColumn">
              <DisplayTable
                data={shop}
                validKeys={[
                  "userId10Digit",
                  "shopName",
                  "userName",
                  "shopType",
                  "shopAddress",
                  "userAddedDate",
                ]}
                title={title}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
