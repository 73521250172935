export default function Administrator() {
  this.fields = [
    "review",
    "banner",
    "promoteoffers",
    "couponpayment",
    "ghoshakgift",
    "reachus",
    "customer",
    "createoffer",
    "package",
    "couponPayment",
    "distribution",
    "coupons",
    "crm",
    "salestarget",
    "notification",
    "phonenumber",
    "pincodeDistribution",
    "extendValidityDate",
    "addGhoshakCoins",
    "getExpiration",
    "billing",
    "dashboard",
    "licenseManagement",
    "resellers",
    "delivery",
    "userAdded",
    "customerManagement",
    "appointment",
    "deleteAccount",
    "merchantManagement",
    "offerCreation",
    "pincodeSynonyms",
    "gstore",
    "mac",
    "referal",
    "telecalling",
    "gamification",
    "payment",
  ];
}
