import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import config from "../config";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend,
  ResponsiveContainer,
} from "recharts";
export default function AppointmentPackage() {
  const [userObject, setUserObject] = useState({
    activeAppointment: [],
    nxtWeekAppointmentExp: [],
    nxtMonthAppointmentExp: [],
    thisMonthAppointmentExp: [],
    todayAppointmentExp: [],
    AppointmentExpired: [],
  });
  const month = [
    "",
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUNE",
    "JULY",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const [activatedUsers, setActivatedUsers] = useState([]);
  const [pkgActivatedByMonth, setPkgActivatedByMonth] = useState([]);
  const [users, setUsers] = useState([]);
  const [orderByDate, setOrderByDate] = useState([]);
  const listUsers = (merchantList) => {
    setUsers(merchantList);
  };

  useEffect(() => {
    let upcomingWeekDates = [];
    let orderByDateAppointmentObj = {};
    let orderBydateArrayMerchant = [];
    let i = 0;
    let date = new Date();
    do {
      date.setDate(date.getDate() - 1);
      upcomingWeekDates.push(date.toISOString().substring(0, 10)); // Next week dates
      i++;
    } while (i <= 7);
    Promise.all([
      fetch(
        `${config.ApiGateway}/appointmentExpiration`,

        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        // expirationObject = {next week expiry, tomorrow, today, yesterday, next month}

        let sortByDate = {};
        data.activatedUser.forEach((elements) => {
          if (
            elements.appointmentPackage === "yes" &&
            elements.appointmentUpdatedOn !== undefined
          ) {
            if (
              sortByDate[elements.appointmentUpdatedOn.substring(0, 4)] ===
              undefined
            ) {
              sortByDate[elements.appointmentUpdatedOn.substring(0, 4)] = {};
            }
            if (
              sortByDate[elements.appointmentUpdatedOn.substring(0, 4)][
                parseInt(elements.appointmentUpdatedOn.substring(5, 7))
              ] === undefined
            ) {
              sortByDate[elements.appointmentUpdatedOn.substring(0, 4)][
                parseInt(elements.appointmentUpdatedOn.substring(5, 7))
              ] = 1;
            } else {
              sortByDate[elements.appointmentUpdatedOn.substring(0, 4)][
                parseInt(elements.appointmentUpdatedOn.substring(5, 7))
              ] += 1;
            }
          }
          if (upcomingWeekDates.includes(elements.appointmentUpdatedOn)) {
            if (elements.appointmentPackage === "yes") {
              if (
                orderByDateAppointmentObj[elements.appointmentUpdatedOn] ===
                undefined
              ) {
                orderByDateAppointmentObj[elements.appointmentUpdatedOn] = 1;
              } else {
                orderByDateAppointmentObj[elements.appointmentUpdatedOn] += 1;
              }
            }
          }
        });

        let appointmentbyMonth = [];
        // Month appointment
        Object.entries(sortByDate).forEach(([element, value]) => {
          for (var key in value) {
            appointmentbyMonth.push({
              year: element,
              month: month[parseInt(key)],
              users: value[key],
            });
          }
        });
        // Week appointment
        upcomingWeekDates.forEach((items) => {
          let count = Object.entries(orderByDateAppointmentObj).find(
            ([elements]) => elements === items
          );

          count != undefined
            ? orderBydateArrayMerchant.push({
                date: count[0],
                users: count[1],
              })
            : orderBydateArrayMerchant.push({
                date: items,
                users: 0,
              });
        });
        orderBydateArrayMerchant = orderBydateArrayMerchant.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setPkgActivatedByMonth(appointmentbyMonth); //Package activated by month
        setUserObject(data.expirationObject); // Appointment Expiration
        setActivatedUsers(data.activatedUser); //Appointment users
        setOrderByDate(orderBydateArrayMerchant);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Container>
      <div style={{ width: "100%", height: "100px" }}></div>
      <div className="mainTitleBox">
        <h2 className="mainTitleBoxText">Package Activated</h2>
      </div>

      {activatedUsers.length > 0 ? (
        <>
          <Row className="division">
            <Col sm={6}>
              <div style={{ width: "100%", height: "10px" }}></div>
              <div className="appointmentHeader">
                <strong className="appHeaderText"> Activated by month</strong>
              </div>
              <ResponsiveContainer aspect={1.6}>
                <BarChart
                  width={600}
                  height={300}
                  data={pkgActivatedByMonth}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  className="recharts"
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    tickLine={true}
                    xAxisId={0}
                    dy={0}
                    dx={-0}
                    label={{
                      value: "",
                      angle: 0,
                      position: "bottom",
                    }}
                    interval={0}
                    dataKey="month"
                    tickLine={false}
                    tick={{ fontSize: 9, angle: 0 }}
                  />

                  <XAxis
                    xAxisId={1}
                    dy={-15}
                    dx={0}
                    label={{
                      value: "",
                      angle: 0,
                      position: "bottom",
                    }}
                    // interval={10}
                    dataKey="year"
                    tickLine={false}
                    tick={{ fontSize: 9, angle: 0 }}
                  />
                  <YAxis
                    label={{
                      value: "MERCHANT",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="users" fill="#8884d8">
                    <LabelList dataKey="users" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Col>

            <Col sm={6}>
              <div style={{ width: "100%", height: "10px" }}></div>
              <div className="appointmentHeader">
                <strong className="appHeaderText">
                  {" "}
                  Activated by Last week
                </strong>
              </div>
              <ResponsiveContainer aspect={1.6}>
                <BarChart
                  width={600}
                  height={300}
                  data={orderByDate}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />

                  <XAxis
                    dataKey="date"
                    interval={0}
                    angle={-45}
                    textAnchor="end"
                    height={100}
                  />
                  <YAxis
                    label={{
                      value: "Merchant",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="users" fill="#8884d8">
                    <LabelList dataKey="users" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Col>
          </Row>
          <div style={{ width: "100%", height: "50px" }}></div>
          <Row>
            <MaterialTable
              title="Shop List"
              columns={[
                {
                  title: "Shop Id",
                  field: "userId10Digit",
                  render: (rowData) => (
                    <Link
                      to={{
                        pathname: "/merchant",
                        search: `?userid=${rowData.userId10Digit}`,
                      }}
                    >
                      {rowData.userId10Digit}
                    </Link>
                  ),
                },
                {
                  title: "Shop Name",
                  field: "shopName",
                },
                {
                  title: "Owner Name",
                  field: "userName",
                },

                {
                  title: "Pincode",
                  field: "userPincode",
                },
                {
                  title: "Place",
                  field: "place",
                },
                {
                  title: "Shop Type",
                  field: "shopType",
                },

                {
                  title: "Address",
                  field: "shopAddress",
                },
                {
                  title: "Package Status ",
                  field: "appointmentPackage",
                  // To check package expired or not, yes - active & No - Expired
                  render: (rowData) => (
                    <td>
                      {rowData.appointmentPackage === "yes"
                        ? "Activated"
                        : "not Activated"}
                    </td>
                  ),
                },
                {
                  title: "Updated on ",
                  field: "appointmentUpdatedOn",
                },
                {
                  title: "Expiration on ",
                  field: "appointmentExpiryDate",
                },
              ]}
              data={activatedUsers}
              options={{
                exportButton: true,
                pageSize: 5,
                maxBodyHeight: 1000,
                pageSizeOptions: [5, 10, 20, 100],
                grouping: false,
                filtering: false,
              }}
            />
          </Row>
        </>
      ) : (
        <h6>No Records to Show</h6>
      )}

      <div style={{ width: "100%", height: "50px" }}></div>
      <div className="mainTitleBox">
        <h2 className="mainTitleBoxText">Package Expiration</h2>
      </div>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Col className="division" sm={{ span: 11 }}>
          <div
            className="headerName"
            style={{
              backgroundColor: "#fafafa",
              width: 100 + "%",
              margin: 1 + "%",
            }}
          >
            <h3 style={{ textAlign: "center" }}> Appointment</h3>
          </div>
          <Row
            style={{
              marginBottom: 20 + "px",
              marginTop: 20 + "px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Col className="verticalRightBorder" sm={{ span: 2 }}>
              <label style={{ textAlign: "center", fontSize: "12px" }}>
                Appointment Expired
              </label>
              <h3 style={{ textAlign: "center" }}>
                <b>{userObject.AppointmentExpired.length}</b>
              </h3>
              <div className="buttonCenter">
                <Button
                  onClick={() =>
                    listUsers(userObject.AppointmentExpired, "Appointment")
                  }
                  size="sm"
                  variant="outline-dark"
                >
                  View
                </Button>
              </div>
            </Col>
            <Col className="verticalRightBorder" sm={{ span: 2 }}>
              <label style={{ textAlign: "center", fontSize: "12px" }}>
                Next month Expiry
              </label>
              <h3 style={{ textAlign: "center" }}>
                <b> {userObject.nxtMonthAppointmentExp.length}</b>
              </h3>
              <div className="buttonCenter">
                <Button
                  onClick={() =>
                    listUsers(userObject.nxtMonthAppointmentExp, "Appointment")
                  }
                  size="sm"
                  variant="outline-dark"
                >
                  View
                </Button>
              </div>
            </Col>
            <Col className="verticalRightBorder" sm={{ span: 2 }}>
              <label style={{ textAlign: "center", fontSize: "12px" }}>
                Today Expiry
              </label>
              <h3 style={{ textAlign: "center" }}>
                <b> {userObject.todayAppointmentExp.length}</b>
              </h3>
              <div className="buttonCenter">
                <Button
                  onClick={() =>
                    listUsers(userObject.todayAppointmentExp, "Appointment")
                  }
                  size="sm"
                  variant="outline-dark"
                >
                  View
                </Button>
              </div>
            </Col>
            <Col className="verticalRightBorder" sm={{ span: 2 }}>
              <label style={{ textAlign: "center", fontSize: "12px" }}>
                Expire by this month
              </label>
              <h3 style={{ textAlign: "center" }}>
                <b> {userObject.thisMonthAppointmentExp.length}</b>
              </h3>
              <div className="buttonCenter">
                <Button
                  onClick={() =>
                    listUsers(userObject.thisMonthAppointmentExp, "Appointment")
                  }
                  size="sm"
                  variant="outline-dark"
                >
                  View
                </Button>
              </div>
            </Col>
            <Col
              className="verticalRightBorder"
              style={{ borderRightColor: "transparent" }}
              sm={{ span: 2 }}
            >
              <label style={{ textAlign: "center", fontSize: "12px" }}>
                upcoming Week Expiration
              </label>
              <h3 style={{ textAlign: "center" }}>
                <b> {userObject.nxtWeekAppointmentExp.length}</b>
              </h3>
              <div className="buttonCenter">
                <Button
                  onClick={() =>
                    listUsers(userObject.nxtWeekAppointmentExp, "Appointment")
                  }
                  size="sm"
                  variant="outline-dark"
                >
                  View
                </Button>
              </div>
            </Col>
          </Row>
          <div className="rowClass">
            <h5 style={{ textAlign: "center" }}>
              {userObject.activeAppointment.length} Merchants are in Appointment
              package
            </h5>
            <Button
              onClick={() =>
                listUsers(userObject.activeAppointment, "Appointment")
              }
              size="sm"
              variant="outline-dark"
            >
              View All
            </Button>
          </div>
        </Col>
      </Row>
      <div style={{ width: "100%", height: "50px" }}></div>

      {users.length > 0 && (
        <Row>
          <MaterialTable
            title="Shop List"
            columns={[
              {
                title: "Shop Id",
                field: "userId10Digit",
                render: (rowData) => (
                  <Link
                    to={{
                      pathname: "/merchant",
                      search: `?userid=${rowData.userId10Digit}`,
                    }}
                  >
                    {rowData.userId10Digit}
                  </Link>
                ),
              },
              {
                title: "Shop Name",
                field: "shopName",
              },
              {
                title: "Owner Name",
                field: "userName",
              },

              {
                title: "Pincode",
                field: "userPincode",
              },
              {
                title: "Place",
                field: "place",
              },
              {
                title: "Shop Type",
                field: "shopType",
              },

              {
                title: "Address",
                field: "shopAddress",
              },
              {
                title: "Package Status ",
                field: "appointmentPackage",
                render: (rowData) => (
                  <td>
                    {rowData.appointmentPackage === "yes"
                      ? "Activated"
                      : "not Activated"}
                  </td>
                ),
              },
              {
                title: "Updated on ",
                field: "appointmentUpdatedOn",
              },
              {
                title: "Expiration on ",
                field: "appointmentExpiryDate",
              },
            ]}
            data={users}
            options={{
              exportButton: true,
              pageSize: 5,

              pageSizeOptions: [5, 10, 20, 100],
              grouping: false,
              filtering: false,
            }}
          />
        </Row>
      )}
    </Container>
  );
}
