import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function BarGraph({ data, Xaxis, Yaxis, YaxisLabel }) {
  return (
    <ResponsiveContainer aspect={1.6}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={Xaxis}
          interval={0}
          angle={-45}
          textAnchor="end"
          height={100}
        />
        <YAxis
          label={{
            value: YaxisLabel,
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Tooltip />
        <Legend />
        <Bar dataKey={Yaxis} fill="#8884d8">
          <LabelList dataKey={Yaxis} position="top" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
