import React, { createContext, useReducer, useState, useContext } from "react";

const initialState = {
  currentDelivery: { deliveryAddress: {}, cart: {}, slot: {} },
  customerDetails: [],
  CmanagementTitle: "",
  loggedIn: false,
  currentUser: {},
};
const store = createContext(initialState);

const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
   
    switch (action.type) {
      case "IS_LOGGEDIN":
        return { ...state, loggedIn: action.loggedIn }; // yes or no
      case "SHOP_DETAILS":
        return { ...state, currentDelivery: action.currentDelivery };
      case "Activate":
        return {
          ...state,
          customerDetails: action.customerDetails,
          CmanagementTitle: "activate",
        };
      case "Engage":
        return {
          ...state,
          customerDetails: action.customerDetails,
          CmanagementTitle: "engage",
        };
      case "Retain":
        return {
          ...state,
          customerDetails: action.customerDetails,
          CmanagementTitle: "retain",
        };
      case "Acquired":
        return {
          ...state,
          customerDetails: action.customerDetails,
          CmanagementTitle: "acquired",
        };
      case "Referals":
        return {
          ...state,
          customerDetails: action.customerDetails,
          CmanagementTitle: "referals",
        };
      case "CURRENT_USER":
        return {
          ...state,
          currentUser: action.data,
        };
      case "userNameAndPAsswors":
        return {
          ...state,
          userNameAndPass: action.data,
        };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
