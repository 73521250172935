import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Modal from "react-bootstrap/Modal";
import config from "../../config";
import { Button } from "react-bootstrap";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { AddEmployeeAPI } from "../handler/apiHandler";
import CloseIcon from "@material-ui/icons/Close";

export default function Employee({ classes, profile, employee }) {
  const [employeeList, setEmployeeList] = useState([]);
  const [modalShow, setModalShow] = useState(false); // Modal State
  const [verifySubUser, setVerifySubUser] = useState(false);
  const [subErr, setSubErr] = useState(false); // Error state
  const [subEmployee, setSubEmployee] = useState({});
  const [secondaryShopObject, setSecondaryShopObject] = useState({}); //store state of secondary shop Object
  const [subUserId10Digit, setSubUserId10Digit] = useState(""); // sub employee shop Id
  useEffect(() => {
    // Verfy data type is array or not. if it is undefined, assign a new array

    setEmployeeList(employee);
  }, [profile, employee]);

  const addSubEmployee = async (secondaryUserId, primaryUserId) => {
    await AddEmployeeAPI(secondaryUserId, primaryUserId).then((data) => {
      if (data.status) {
        alert("Changes done Successfully");
        return clearEmployee();
      }

      alert("Employee not added/Removed");
    });
  };
  const getSubUser = async (e) => {
    // verify user is present or not
    e.preventDefault();
    await Promise.all([
      fetch(`${config.ApiGateway}/getShopAndEmployee`, {
        method: "POST",
        body: JSON.stringify({
          userId10Digit: subUserId10Digit, // sub employee shop id
          typename: "secondary", // to get sub shop
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        if (data.sub.length > 0) {
          //   // array is greater than 0
          let subShop = data.sub[0]; // sub employee profile , type - object
          setSecondaryShopObject(subShop); // update state sub employee
          setSubErr(false); // error state false
        } else {
          setSubErr(true); // true - shows error
        }
      })
      .catch((err) => console.log("error in getting shop", err));
  };
  const clearEmployee = async () => {
    setVerifySubUser(false);
    setSecondaryShopObject({});
    setModalShow(false);
  };

  return (
    <>
      <div className="shopCardRow">
        <div className="shopCardColumn">
          <button
            id="search"
            className="actionButton"
            onClick={() => {
              setModalShow(true);
            }}
          >
            <PersonAddIcon />
            Add Employee
          </button>
          <div id="noPadding" className="wrapTable">
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.tabTableHeader}
                      key={"1"}
                      align={"1"}
                    >
                      Employee Name
                    </TableCell>
                    <TableCell className={classes.tabTableHeader}>
                      Ghoshak ID
                    </TableCell>
                    <TableCell className={classes.tabTableHeader}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeList.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.date}
                      >
                        <TableCell
                          className={classes.tabTableCell}
                          key={row.userName}
                        >
                          {row.userName}
                        </TableCell>
                        <TableCell className={classes.tabTableCell}>
                          {row.userId10Digit}
                        </TableCell>
                        <TableCell className={classes.tabTableCell}>
                          <CloseIcon
                            onClick={() => {
                              addSubEmployee(row.userId, "NA");
                              // parameter 1 - sub employee shop id, primary shop
                              // user id

                              // "NA" --- Removing sub employee from owner shop
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Modal
              className="modal-order-coupon"
              show={modalShow}
              onHide={() => setModalShow(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Sub Employee</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <>
                  <div className="shopCardRow">
                    <div className="shopCardColumn">
                      <form onSubmit={getSubUser} className="inputContainer">
                        <label>Enter Sub Employee Ghoshak ID</label>
                        <input
                          onChange={(e) => setSubUserId10Digit(e.target.value)}
                        ></input>
                        <button id="search" className="actionButton">
                          Search
                        </button>
                      </form>
                    </div>

                    <div
                      style={{
                        display:
                          Object.keys(secondaryShopObject).length > 0
                            ? ""
                            : "none",
                      }}
                      className="shopCardColumn"
                    >
                      <div id="boxBorder" className="fieldBox">
                        <div className="fieldWrap">
                          <label>Ghoshak ID</label>
                          <input
                            className={"flexInputView"}
                            value={secondaryShopObject.userId10Digit}
                          ></input>
                        </div>
                        <div className="fieldWrap">
                          <label>Username</label>
                          <input
                            className={"flexInputView"}
                            value={secondaryShopObject.userName}
                          ></input>
                        </div>
                        <div className="fieldWrap">
                          <label>Phone Number</label>
                          <input
                            className={"flexInputView"}
                            value={secondaryShopObject.userPhoneNumber}
                          ></input>
                        </div>
                        <div className="flexBoxEnd">
                          <div className="updateButton">
                            <button
                              onClick={() => {
                                addSubEmployee(
                                  secondaryShopObject.userId,
                                  profile.userId
                                ); // parameter 1 - sub employee shop id, primary shop user id
                              }}
                              id="success"
                              className="actionButton"
                            >
                              Confirm
                            </button>
                          </div>
                          <div className="updateButton">
                            <button
                              onClick={() => clearEmployee()}
                              id="danger"
                              className="actionButton"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}
