import React, { useState, useEffect } from "react";
import { Container, Col, Button, Row, Tabs, Tab, Modal } from "react-bootstrap";
import config from "../config";
import { useLocation, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MaterialTable from "material-table";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  table: {
    minWidth: 650,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});

export default function AppointmentShopDetails() {
  const { Shop } = useParams();
  const classes = useRowStyles();
  useEffect(() => {
    fetchData();
  }, []);
  const [shopInfo, setShopInfo] = useState({ typeOfPayment: [] });
  const [shopTimings, setShopTimings] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [listOfServ, setListOfServ] = useState([]);
  const [key, setKey] = useState("home");
  const [customerList, setCustomerList] = useState([]);

  const [activity, setActivity] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const modalProps = (modalState, modalProps) => {
    setModalShow(modalState);
    setModalData(modalProps);
  };

  const fetchData = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/appointmentShopDetails`, {
        method: "POST",
        body: JSON.stringify({
          shopId: Shop,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setActivity(data.activityLogs);
        setCustomerList(data.customers);
        setShopInfo(data.shopDetails);
        setListOfServ(data.listOfService);
        setEmployeeList(data.employeeList);
        setShopTimings(data.shopTimings);
      })
      .catch((err) => alert(err));
  };
  const findEmployeeName = (employeeList, value) => {
    let findName = employeeList.find((elements) => elements.id == value);
    if (findName !== undefined) {
      return findName.name;
    }
    return false;
  };
  return (
    <>
      <Container>
        <div style={{ width: "100%", height: "100px" }}></div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "1.4rem",
          }}
        >
          <h2
            style={{
              textTransform: "capitalize",
              marginBottom: 0,
              fontSize: "2.4rem",
              fontWeight: "600",
              color: "#4f4e4e",
            }}
          >
            Shop Details
          </h2>
        </div>

        <div style={{ marginBottom: "20px" }} className="flexCard">
          <Row>
            <Col sm={6}>
              <div className="imageContainer">
                <img
                  src={
                    "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/images/offers/default_offer_image.png"
                  }
                  className="imageBox"
                ></img>
              </div>
            </Col>
            <Col style={{ paddingTop: "27px" }} sm={6}>
              <h1 className="shopProfile">{shopInfo.shopName}</h1>
              <span className="shopProfile">{shopInfo.shopAddress}</span>
              <div className="vertical-space-5"></div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Merchant Name :{" "}
                </label>
                <p style={{ width: "40%" }}>{shopInfo.userName}</p>
              </div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Phone Number :{" "}
                </label>
                <p style={{ width: "40%" }}>{shopInfo.userPhoneNumber}</p>
              </div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Shop Pincode :
                </label>
                <p style={{ width: "40%" }}>{shopInfo.userPincode}</p>
              </div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Shop Type :
                </label>
                <p style={{ width: "40%" }}>{shopInfo.shopType}</p>
              </div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Shop Id :
                </label>
                <p style={{ width: "40%" }}>{shopInfo.userId10Digit}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingLeft: "10px" }}>
            <Col>
              <label style={{ color: "#89959b", width: "80%" }}>
                Total Employee :
              </label>
              <p style={{ width: "40%" }}>{employeeList.length}</p>

              <div style={{ width: "100%", height: "3px" }}></div>
              <label style={{ color: "#89959b", width: "80%" }}>
                Package Status:
              </label>
              <p style={{ width: "40%" }}>
                {shopInfo.appointmentPackage === "yes"
                  ? "Activated"
                  : "Not Activated"}
              </p>
              <div style={{ width: "100%", height: "3px" }}></div>
              <label style={{ color: "#89959b", width: "80%" }}>
                Package Expiry Date:
              </label>
              <p style={{ width: "40%" }}>{shopInfo.appointmentExpiryDate}</p>
              <div style={{ width: "100%", height: "3px" }}></div>
              <label style={{ color: "#89959b", width: "80%" }}>
                Expiration Days:
              </label>
              <p style={{ width: "40%" }}>
                {shopInfo.appointmentExpiryDate != undefined
                  ? new Date() < new Date(shopInfo.appointmentExpiryDate)
                    ? Math.floor(
                        (new Date().getTime() -
                          new Date(shopInfo.appointmentExpiryDate)) /
                          (1000 * 60 * 60 * 24)
                      )
                    : "Expired"
                  : "Expired"}
              </p>
            </Col>
            <Col>
              <label style={{ color: "#89959b", width: "80%" }}>
                Shop Timing:
              </label>
              <div style={{ overflowX: "auto", height: "250px" }}>
                {shopTimings.map((value) => (
                  <>
                    <b
                      style={{
                        width: "100%",
                        fontSize: "12px",
                        textTransform: "capitalize",
                      }}
                    >
                      {value.weekday}
                    </b>
                    <p style={{ width: "40%" }}>
                      <span>{value.openTime}</span> -
                      <span>{value.closeTime}</span>
                    </p>
                  </>
                ))}
              </div>
            </Col>
            <Col>
              <label style={{ color: "#89959b", width: "80%" }}>
                Employee List :
              </label>
              <div style={{ overflowX: "auto", height: "250px" }}>
                {employeeList.map((key, index) => (
                  <p style={{ width: "40%" }}>
                    <span>{index + 1}</span> . <span>{key.name}</span>
                  </p>
                ))}
              </div>
            </Col>
            <Col>
              <label style={{ color: "#89959b", width: "80%" }}>
                Payment Type :
              </label>
              <div
                style={{ overflowX: "auto", height: "250px", width: "100%" }}
              >
                {shopInfo.typeOfPayment.map((key) => (
                  <p style={{ width: "100%" }}>
                    <span>{key}</span>
                  </p>
                ))}
              </div>
            </Col>
          </Row>
        </div>

        <div style={{ width: "100%", height: "100px" }}></div>

        <div style={{ width: "100%" }}>
          <Tabs
            id="controlled-tab-example"
            className="deliveryTab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="home" title="Service">
              <Row className="tabRowContent">
                <div style={{ width: "100%", height: "100px" }}></div>
                <MaterialTable
                  title="Available service"
                  columns={[
                    {
                      title: "Product Name",
                      field: "productName",
                    },
                    {
                      title: "Product Price",
                      field: "productPrice",
                      render: (rowData) => (
                        <td>
                          {"₹ "}
                          {rowData.productPrice}
                        </td>
                      ),
                    },
                    { title: "Brand", field: "productBrand" },
                    { title: "Sub Category", field: "subCategory" },

                    {
                      title: "Quantity",
                      field: "productQty",
                    },

                    {
                      title: "Duration",
                      field: "duration",
                      render: (rowData) => (
                        <td>
                          {rowData.duration} {" min "}
                        </td>
                      ),
                    },
                  ]}
                  data={listOfServ}
                  options={{
                    exportButton: true,
                    pageSize: 10,

                    pageSizeOptions: [5, 10, 20, 100],
                    grouping: false,
                    filtering: false,
                  }}
                />
              </Row>
            </Tab>
            <Tab eventKey="customer" title="Customer">
              <Row className="tabRowContent">
                <div style={{ width: "100%", display: "inline" }}>
                  <span className="subHeadertab">Customer List</span>
                </div>

                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell} />
                        <TableCell className={classes.tableCell}>
                          Customer ID
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Name
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Phone Number
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Pincode
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Amount
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Total Appointment
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customerList.map((row) => (
                        <RowClass
                          key={row.name}
                          row={row}
                          modalProps={modalProps}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Row>
            </Tab>

            <Tab eventKey="activityLog" title="Activity Logs">
              <div style={{ width: "100%", height: "100px" }}></div>
              <div style={{ float: "left" }} className="orderHeader">
                <h2 className="orderHeaderText"> Activity Logs </h2>
              </div>

              <Row className="tabRowContent">
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          S No
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          Booked on
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Customer Name
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Customer ID
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          Phone Number
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Pincode
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          Amount Paid
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Status
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Appointment Date
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Duration
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Employee Name
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          cart Details
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activity.map((row, index) => (
                        <>
                          <TableRow key={row.orderId}>
                            <TableCell
                              className={classes.tableCell}
                              component="th"
                              scope="row"
                            >
                              {index + 1}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {" "}
                              {row.billingDate}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {" "}
                              {row.customerName}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {" "}
                              {row.customerId}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.customerPhoneNumber}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {" "}
                              {row.customerPincode}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {"₹ "}
                              {row.totalAmount}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {" "}
                              {row.status}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {row.date} - {row.time}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {" "}
                              {row.duration} {"min"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {findEmployeeName(employeeList, row.employeeId)}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <IconButton
                                onClick={() => modalProps(true, row.cart)}
                              >
                                <VisibilityIcon></VisibilityIcon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Row>
            </Tab>
          </Tabs>
        </div>
        <Modal
          className="viewCartDetails"
          show={modalShow}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Cart Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>S No</TableCell>
                  <TableCell className={classes.tableCell}>
                    Product Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>Price</TableCell>
                  <TableCell className={classes.tableCell}> Count</TableCell>
                  <TableCell className={classes.tableCell}>Duration</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {Object.entries(modalData).map(([key, value], index) => (
                    <>
                      <TableRow key={key}>
                        <TableCell
                          className={classes.tableCell}
                          component="th"
                          scope="row"
                        >
                          {index + 1}
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {value.productName}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {"₹ "} {value.productPrice}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {" "}
                          {value.count}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {" "}
                          {value.duration}
                          {" min"}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </>
              </TableBody>
            </Table>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
}
function RowClass(props) {
  const { row, modalProps } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell className={classes.tableCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.tableCell} component="th" scope="row">
          {row.customerId}
        </TableCell>
        <TableCell className={classes.tableCell}>{row.customerName}</TableCell>
        <TableCell className={classes.tableCell}>
          {row.customerPhoneNumber}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {row.customerPincode}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {"₹ "}
          {row.paidAmount}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {row.totalAppointment}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          className={classes.tableCell}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      Appointment Date
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Booked On
                    </TableCell>
                    <TableCell className={classes.tableCell}>Status</TableCell>
                    <TableCell className={classes.tableCell}>
                      Reason for Rejection
                    </TableCell>
                    <TableCell className={classes.tableCell}>Amount</TableCell>
                    <TableCell className={classes.tableCell}>Time</TableCell>
                    <TableCell className={classes.tableCell}>Rating</TableCell>
                    <TableCell className={classes.tableCell}>
                      Cart Details
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.billdetails.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell
                        className={classes.tableCell}
                        component="th"
                        scope="row"
                      >
                        {historyRow.date}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {historyRow.billingDate}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        component="th"
                        scope="row"
                      >
                        {historyRow.status}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        component="th"
                        scope="row"
                      >
                        {historyRow.reasonForRejection.map(
                          (elements) => elements
                        )}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {"₹ "} {historyRow.totalAmount}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {" "}
                        {historyRow.time}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {" "}
                        {historyRow.starCount}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <IconButton
                          onClick={() => modalProps(true, historyRow.cart)}
                        >
                          <VisibilityIcon></VisibilityIcon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
