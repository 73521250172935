import React, { useEffect, useState } from "react";
import "../styles/marketingCampaign.css";
import TopNotifications from "./topNotifications_MC";
import MarketingBanners from "./marketingBanners";
import BroadCasting from "./sendNotification";
import config from "../config";
import S3 from "react-aws-s3";
import { message } from "antd";
import { uploadJson } from "../service";
const MarketingCampaign = () => {
  const [campaignDetails, setCampaignDetails] = useState({});

  const fetchCampaignDetails = async () => {
    let data = await fetch(config.MarketingCampaigns, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let response = await data.json();
    setCampaignDetails({ ...response });
    console.log("response", response);
  };

  useEffect(() => {
    fetchCampaignDetails();
  }, []);
  const [messageApi, contextHolder] = message.useMessage();

  const feedbackToast = {
    success: function (message) {
      messageApi.open({
        type: "success",
        content: message,
      });
    },
    error: function (message) {
      messageApi.open({
        type: "error",
        content: message,
      });
    },
  };
  const updateJson = async (file) => {
    let stringified = JSON.stringify(file);
    let bucketParams = {
      bucketName: "prod-ghoshak",
      region: "ap-south-1",
      dirName: "marketingCampaigns",
      fileName: config.MCFileName,
    };

    try {
      let resp = await uploadJson(stringified, bucketParams);
      console.log("resp", resp);

      feedbackToast.success("Updated successfully");
      // fetchCampaignDetails();
    } catch (err) {
      feedbackToast.error("Error updating, please try again later.");
      console.log("err", err);
    }
  };

  const [selectedMenu, setSelectedMenu] = useState("Top Notifications");

  const menuList = ["Top Notifications", "Banners", "Broad Casting"];

  return (
    <div className="marketing_Campaign_Main-Div">
      {contextHolder}
      <div className="marketing_Campaign_SideMenu_Div">
        {menuList.map((data) => {
          return (
            <div
              className={
                selectedMenu === data
                  ? "marketing_Campaign_SideMenu_Selected"
                  : "marketing_Campaign_SideMenu"
              }
              onClick={() => setSelectedMenu(data)}
            >
              {data}
            </div>
          );
        })}
      </div>
      <div className="marketing_Campaign_DisplayMenu_Div">
        {selectedMenu === "Top Notifications" && (
          <TopNotifications
            campaignData={campaignDetails}
            updateJson={(obj) => updateJson(obj)}
          />
        )}
        {selectedMenu === "Banners" && (
          <MarketingBanners
            campaignData={campaignDetails}
            updateJson={(obj) => {
              console.log("receivedObj", obj);
              updateJson(obj);
            }}
          />
        )}
        {selectedMenu === "Broad Casting" && <BroadCasting />}
      </div>
    </div>
  );
};
export default MarketingCampaign;
