import React from "react";
import { Row, Col } from "react-bootstrap";
import "../deleivery/delivery.css";
export default function ShopProfilecard({ shopInfo }) {
  let deliveryType = shopInfo.typeOfPayment || [];
  let typeOfService = shopInfo.typeOfService || [];
  let deliverySlotObject = shopInfo.deliverySlot || {};

  return (
    <div style={{ marginBottom: "20px" }} className="deliveryCard">
      <Row style={{ width: "100%", float: "left", textAlign: "left" }}>
        <Col sm={6}>
          <div className="imageContainer">
            <img
              src={
                typeof shopInfo.shopImageLocation == "string"
                  ? shopInfo.shopImageLocation
                  : "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/images/offers/default_offer_image.png"
              }
              className="imageBox"
            ></img>
          </div>
        </Col>
        <Col style={{ paddingTop: "27px" }} sm={6}>
          <h1 className="shopProfile">{shopInfo.shopName}</h1>
          <span className="shopProfile">{shopInfo.shopAddress}</span>
          <div className="vertical-space-5"></div>
          <div className="shopLabeltext">
            <label style={{ color: "#89959b", width: "30%" }}>
              Merchant Name :{" "}
            </label>
            <p style={{ width: "40%" }}>{shopInfo.userName}</p>
          </div>
          <div className="shopLabeltext">
            <label style={{ color: "#89959b", width: "30%" }}>
              Phone Number :{" "}
            </label>
            <p style={{ width: "40%" }}>{shopInfo.userPhoneNumber}</p>
          </div>
          <div className="shopLabeltext">
            <label style={{ color: "#89959b", width: "30%" }}>
              Shop Pincode :
            </label>
            <p style={{ width: "40%" }}>{shopInfo.userPincode}</p>
          </div>
          <div className="shopLabeltext">
            <label style={{ color: "#89959b", width: "30%" }}>
              Shop Type :
            </label>
            <p style={{ width: "40%" }}>{shopInfo.shopType}</p>
          </div>
          <div className="shopLabeltext">
            <label style={{ color: "#89959b", width: "30%" }}>Shop Id :</label>
            <p style={{ width: "40%" }}>{shopInfo.userId10Digit}</p>
          </div>
        </Col>
      </Row>
      <Row style={{ paddingLeft: "10px" }}>
        <Col>
          <label style={{ color: "#89959b", width: "80%" }}>
            Min bill Amt :
          </label>
          <p style={{ width: "40%" }}>
            {"₹ "}
            {shopInfo.minBillAmount}
          </p>

          <div style={{ width: "100%", height: "3px" }}></div>
          <label style={{ color: "#89959b", width: "80%" }}>
            Delivery Charge:
          </label>
          <p style={{ width: "40%" }}>
            {"₹ "}
            {shopInfo.chargeForDelivery}
          </p>
        </Col>
        <Col>
          <label style={{ color: "#89959b", width: "80%" }}>
            Delivery Slots:
          </label>
          <div style={{ overflowX: "auto", height: "150px" }}>
            {Object.entries(deliverySlotObject).map(([key, value]) => (
              <p style={{ width: "40%" }}>
                <span>{value.startTime}</span> -<span>{value.endTime}</span>
              </p>
            ))}
          </div>
        </Col>
        <Col>
          <label style={{ color: "#89959b", width: "80%" }}>
            Accepted Delivery Type :
          </label>
          <div style={{ overflowX: "auto", height: "150px" }}>
            {typeOfService.map((key) => (
              <p style={{ width: "40%" }}>
                <span>{key}</span>
              </p>
            ))}
          </div>
        </Col>
        <Col>
          <label style={{ color: "#89959b", width: "80%" }}>
            Payment Type :
          </label>
          <div style={{ overflowX: "auto", height: "150px" }}>
            {deliveryType.map((key) => (
              <p style={{ width: "40%" }}>
                <span>{key}</span>
              </p>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}
