import React, { useEffect, useState, useContext } from "react";
import { Container, Col, Button, Row, Tabs, Tab, Modal } from "react-bootstrap";
import config from "../config";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PersonIcon from "@material-ui/icons/Person";
import TodayIcon from "@material-ui/icons/Today";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useLocation, useParams } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MaterialTable from "material-table";
import { store } from "../provider";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  LabelList,
  LineChart,
  Line,
  Legend,
} from "recharts";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    fontSize: "12px",
  },
});

const CustomizedLabel = (props) => {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  );
};

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );
};

export default function OnlineShopStats() {
  const classes = useStyles();

  const [orderStatus, setOrderStatus] = useState({});

  const [shopInfo, setShopInfo] = useState({
    deliverySlot: {},
    typeOfService: [],
    typeOfPayment: [],
  });
  const [open, setOpen] = useState(false);
  const [activity, setActivity] = useState([]);
  const { shopId } = useParams();
  const [key, setKey] = useState("home");
  const [customerList, setCustomerList] = useState([]);
  const [todayOrder, setTodayOrder] = useState(0);
  const [rejectedStats, setRejectedStats] = useState({ details: [] });
  const [salesGraph, setSalesGraph] = useState([]);
  const [days, setDays] = useState("15");
  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const modalProps = (modalState, modalProps) => {
    setModalShow(modalState);
    setModalData(modalProps);
  };
  const context = useContext(store); // Get global state value

  const shopDetails = context.state.currentDelivery; // get current delivery details

  const fetchData = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/trackShopOrders`, {
        method: "POST",
        body: JSON.stringify({
          shopId: shopId, // shopId
          dateRangeVal: days, // Last days
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        //  data structure
        // product details - product name 7up, group - beverage, category - cool drinks, sub category - cool drinks name
        // let data = {
        // activityLog  -  Track orders by shopId,
        // categoryList - get ategory list,
        // orderStatus - get total order status (completed, rejected,ready for delivery)
        // customers - customer who booked delivery from this shop,
        // rejectedOrders - rejected orders,
        // shopDetails -  shop Profile
        // subCategoryList - sub category list
        // };
        setIsLoading(true);
        setSubCategoryList(data.subCategoryList);
        setCategoryList(data.categoryList);
        setRejectedStats(data.rejectedOrders);
        setShopInfo(data.shopDetails);
        setSalesGraph(data.salesGraphData);
        setTodayOrder(data.todayOrders);
        setActivity(data.activityLog);
        setOrderStatus(data.orderStatus);
        setCustomerList(data.customers);
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = async (value) => {
    setDays(value);
    setIsLoading(true);
    await fetchData();
  };

  return (
    <>
      <Container>
        <div style={{ width: "100%", height: "100px" }}></div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "1.4rem",
          }}
        >
          <h2
            style={{
              textTransform: "capitalize",
              marginBottom: 0,
              fontSize: "2.4rem",
              fontWeight: "600",
              color: "#4f4e4e",
            }}
          >
            Delivery Shop Details
          </h2>
        </div>

        <div style={{ marginBottom: "20px" }} className="deliveryCard">
          <Row>
            <Col sm={6}>
              <div className="imageContainer">
                <img
                  src={
                    typeof shopInfo.shopImageLocation == "string"
                      ? shopInfo.shopImageLocation
                      : "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/images/offers/default_offer_image.png"
                  }
                  className="imageBox"
                ></img>
              </div>
            </Col>
            <Col style={{ paddingTop: "27px" }} sm={6}>
              <h1 className="shopProfile">{shopInfo.shopName}</h1>
              <span className="shopProfile">{shopInfo.shopAddress}</span>
              <div className="vertical-space-5"></div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Merchant Name :{" "}
                </label>
                <p style={{ width: "40%" }}>{shopInfo.userName}</p>
              </div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Phone Number :{" "}
                </label>
                <p style={{ width: "40%" }}>{shopInfo.userPhoneNumber}</p>
              </div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Shop Pincode :
                </label>
                <p style={{ width: "40%" }}>{shopInfo.userPincode}</p>
              </div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Shop Type :
                </label>
                <p style={{ width: "40%" }}>{shopInfo.shopType}</p>
              </div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Shop Id :
                </label>
                <p style={{ width: "40%" }}>{shopInfo.userId10Digit}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingLeft: "10px" }}>
            <Col>
              <label style={{ color: "#89959b", width: "80%" }}>
                Min bill Amt :
              </label>
              <p style={{ width: "40%" }}>
                {"₹ "}
                {shopInfo.minBillAmount}
              </p>

              <div style={{ width: "100%", height: "3px" }}></div>
              <label style={{ color: "#89959b", width: "80%" }}>
                Delivery Charge:
              </label>
              <p style={{ width: "40%" }}>
                {"₹ "}
                {shopInfo.chargeForDelivery}
              </p>
            </Col>
            <Col>
              <label style={{ color: "#89959b", width: "80%" }}>
                Delivery Slots:
              </label>
              <div style={{ overflowX: "auto", height: "150px" }}>
                {Object.entries(shopInfo.deliverySlot).map(([key, value]) => (
                  <p style={{ width: "40%" }}>
                    <span>{value.startTime}</span> -<span>{value.endTime}</span>
                  </p>
                ))}
              </div>
            </Col>
            <Col>
              <label style={{ color: "#89959b", width: "80%" }}>
                Accepted Delivery Type :
              </label>
              <div style={{ overflowX: "auto", height: "150px" }}>
                {shopInfo.typeOfService.map((key) => (
                  <p style={{ width: "40%" }}>
                    <span>{key}</span>
                  </p>
                ))}
              </div>
            </Col>
            <Col>
              <label style={{ color: "#89959b", width: "80%" }}>
                Payment Type :
              </label>
              <div style={{ overflowX: "auto", height: "150px" }}>
                {shopInfo.typeOfPayment.map((key) => (
                  <p style={{ width: "40%" }}>
                    <span>{key}</span>
                  </p>
                ))}
              </div>
            </Col>
          </Row>
        </div>

        {Object.keys(shopDetails.cart).length > 0 && (
          <>
            <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
              <Col style={{ height: "340px" }} sm="6">
                <div className="deliveryCard">
                  <div style={{ display: "inline-block", width: "100%" }}>
                    <div
                      className="orderHeader"
                      style={{ textAlign: "center" }}
                    >
                      <b>Customer Details</b>
                    </div>
                  </div>
                  <div style={{ flex: 1, flexDirection: "column" }}>
                    <div className="shopLabeltext">
                      <label
                        style={{
                          color: "#89959b",
                          width: "30%",
                          paddingLeft: "10px",
                        }}
                      >
                        Order Id :
                      </label>
                      <p style={{ width: "40%" }}>{shopDetails.orderId}</p>
                    </div>
                    <div className="shopLabeltext">
                      <label
                        style={{
                          color: "#89959b",
                          width: "30%",
                          paddingLeft: "10px",
                        }}
                      >
                        Billed Date:
                      </label>
                      <p style={{ width: "40%" }}>{shopDetails.billingDate}</p>
                    </div>
                    <div className="shopLabeltext">
                      <label
                        style={{
                          color: "#89959b",
                          width: "30%",
                          paddingLeft: "10px",
                        }}
                      >
                        Customer Name :
                      </label>
                      <p style={{ width: "40%" }}>{shopDetails.customerName}</p>
                    </div>
                    <div className="shopLabeltext">
                      <label
                        style={{
                          color: "#89959b",
                          width: "30%",
                          paddingLeft: "10px",
                        }}
                      >
                        Phone Number :
                      </label>
                      <p style={{ width: "40%" }}>
                        {shopDetails.customerPhoneNumber}
                      </p>
                    </div>
                    <div className="shopLabeltext">
                      <label
                        style={{
                          color: "#89959b",
                          width: "30%",
                          paddingLeft: "10px",
                        }}
                      >
                        Customer ID :
                      </label>
                      <p style={{ width: "40%" }}>{shopDetails.customerId}</p>
                    </div>
                    <div className="shopLabeltext">
                      <label
                        style={{
                          color: "#89959b",
                          width: "30%",
                          paddingLeft: "10px",
                        }}
                      >
                        Delivery Method :
                      </label>
                      <p style={{ width: "40%" }}>
                        {shopDetails.deliveryMethod}
                      </p>
                    </div>

                    <div className="shopLabeltext">
                      <label
                        style={{
                          color: "#89959b",
                          width: "30%",
                          paddingLeft: "10px",
                        }}
                      >
                        Delivery Address :
                      </label>
                      <p style={{ width: "40%" }}>
                        {Object.keys(shopDetails.deliveryAddress).map.length >
                          0 && (
                          <address>
                            {shopDetails.deliveryAddress.address}
                            {shopDetails.query}
                          </address>
                        )}
                      </p>
                    </div>
                    <div className="shopLabeltext">
                      <label
                        style={{
                          color: "#89959b",
                          width: "30%",
                          paddingLeft: "10px",
                        }}
                      >
                        Slot Time :
                      </label>
                      <p style={{ width: "40%" }}>
                        {Object.keys(shopDetails.slot).map.length > 0 ? (
                          <span>
                            {shopDetails.slot.startTime} -{" "}
                            {shopDetails.slot.endTime} ({shopDetails.slot.day})
                          </span>
                        ) : (
                          "-"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div className="deliveryCard">
                  <div className="deliveryCardHeader">
                    <label className="deliveryCardHeaderlabel">
                      Cart Details
                    </label>
                  </div>
                  <TableContainer
                    style={{ height: "340px", overflow: "auto" }}
                    component={Paper}
                  >
                    <Table
                      size="small"
                      className={classes.table}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            S No
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Product Name
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Price
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Cart Count
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Order cost
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            Order Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(shopDetails.cart).map(
                          ([key, value], index) => (
                            <TableRow key={value.orderId}>
                              <TableCell
                                className={classes.tableCell}
                                component="th"
                                scope="row"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                component="th"
                                scope="row"
                              >
                                {value.productName}
                                {value.unitOfMeasurment}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {"₹ "} {value.productPrice}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {" "}
                                {value.cartCount}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {"₹ "}{" "}
                                {parseFloat(value.productPrice) *
                                  parseFloat(value.cartCount)}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {" "}
                                {value.status}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Col>
            </Row>
          </>
        )}
        <div style={{ width: "100%", height: "30px" }}></div>
        <Row>
          <Col sm={6}>
            <div className="deliveryCard">
              <div className="deliveryCardHeader">
                <label className="deliveryCardHeaderlabel">Order Coupon</label>
              </div>

              {typeof shopDetails.coupons == "object" ? (
                <>
                  {shopDetails.coupons.map((elements) => (
                    <>
                      <div
                        style={{
                          margin: "9px 18px",
                          border: "1px dotted black",
                        }}
                      >
                        <div className="shopLabeltext">
                          <label
                            style={{
                              color: "#89959b",
                              width: "30%",
                              paddingLeft: "10px",
                            }}
                          >
                            Coupon Applied:
                          </label>
                          <p style={{ width: "40%" }}>
                            {elements.__typename == "OnlineOrderPromocodeOutput"
                              ? "yes"
                              : "no"}
                          </p>
                        </div>

                        <div className="shopLabeltext">
                          <label>Coupon Id:</label>
                          <p style={{ width: "40%" }}>{elements.couponId}</p>
                        </div>

                        <div className="shopLabeltext">
                          <label
                            style={{
                              color: "#89959b",
                              width: "30%",
                              paddingLeft: "10px",
                            }}
                          >
                            Promocode:
                          </label>
                          <p style={{ width: "40%" }}>
                            {elements.promocode || " -- "}
                          </p>
                        </div>
                        <div className="shopLabeltext">
                          <label
                            style={{
                              color: "#89959b",
                              width: "30%",
                              paddingLeft: "10px",
                            }}
                          >
                            Discount Amount:
                          </label>
                          <p style={{ width: "40%" }}>
                            {"₹ "} {elements.discountAmount || 0}
                          </p>
                        </div>
                        <div className="shopLabeltext">
                          <label
                            style={{
                              color: "#89959b",
                              width: "30%",
                              paddingLeft: "10px",
                            }}
                          >
                            Discount Type:
                          </label>
                          <p style={{ width: "40%" }}>
                            {elements.discountType || " -- "}
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <div className="shopLabeltext">
                    <label
                      style={{
                        color: "#89959b",
                        width: "30%",
                        paddingLeft: "10px",
                      }}
                    >
                      Coupon Applied:
                    </label>
                    <p style={{ width: "40%" }}>No</p>
                  </div>
                  <div className="shopLabeltext">
                    <label
                      style={{
                        color: "#89959b",
                        width: "30%",
                        paddingLeft: "10px",
                      }}
                    >
                      Promocode:
                    </label>
                    <p style={{ width: "40%" }}>No</p>
                  </div>
                </>
              )}
            </div>
          </Col>

          <Col sm={6}>
            <div className="deliveryCard">
              <div className="deliveryCardHeader">
                <label className="deliveryCardHeaderlabel">Bill Details</label>
              </div>

              <div className="shopLabeltext">
                <label> Sub Total:</label>
                <p>
                  {" "}
                  ₹{" "}
                  {shopDetails.deliveryMethod === "Delivery"
                    ? (
                        parseFloat(shopDetails.totalAmount) -
                        parseFloat(shopDetails.chargeForDelivery) +
                        parseFloat(shopDetails.couponDiscount)
                      ).toFixed(2)
                    : (
                        parseFloat(shopDetails.totalAmount) +
                        parseFloat(shopDetails.couponDiscount)
                      ).toFixed(2)}
                </p>
              </div>

              <div className="shopLabeltext">
                <label> Delivery Charge:</label>
                <p>
                  {"+ ₹ "} {shopDetails.chargeForDelivery}
                </p>
              </div>

              <div className="shopLabeltext">
                <label> Amount without discount:</label>
                <p>
                  ₹{" "}
                  {(
                    parseFloat(shopDetails.totalAmount) +
                    parseFloat(shopDetails.couponDiscount)
                  ).toFixed(2)}
                </p>
              </div>
              <div className="shopLabeltext">
                <label> Discount Amount:</label>
                <p> - ₹ {parseFloat(shopDetails.couponDiscount).toFixed(2)}</p>
              </div>

              <div className="shopLabeltext">
                <label> Amount with discount:</label>
                <p>₹ {parseFloat(shopDetails.totalAmount).toFixed(2)}</p>
              </div>

              <div className="shopLabeltext">
                <label>
                  <b> Total:</b>
                </label>
                <p>
                  {"₹ "} {parseFloat(shopDetails.totalAmount).toFixed(2)}
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: "30px", marginBottom: "100px" }}>
          <div style={{ width: "100%" }}>
            {isLoading && (
              <Tabs
                id="controlled-tab-example"
                className="deliveryTab"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="home" title="Overview">
                  <div style={{ width: "100%", height: "100px" }}></div>
                  <div style={{ float: "left" }} className="orderHeader">
                    <h2 className="orderHeaderText"> Dashboard </h2>
                  </div>
                  <Row className="tabRowContent">
                    <Col className="splitCol">
                      <div className="cardBodyRow">
                        <Row>
                          <Col className="cardBodyDelivery">
                            <p className="tabRowContentSubHeading">
                              Total Orders
                            </p>
                            <h3>
                              {(parseInt(orderStatus.complete) || 0) +
                                (parseInt(orderStatus.readyForDelivery) || 0) +
                                (parseInt(orderStatus.readyForPickup) || 0) +
                                (parseInt(orderStatus.startPackaging) || 0)}
                            </h3>
                          </Col>
                          <Col className="cardBodyDeliveryIcon">
                            <ShoppingCartIcon />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col className="splitCol">
                      <div className="cardBodyRow">
                        <Row>
                          <Col className="cardBodyDelivery">
                            <p className="tabRowContentSubHeading">
                              Total Customers
                            </p>
                            <h3>{customerList.length}</h3>
                          </Col>
                          <Col className="cardBodyDeliveryIcon">
                            <PersonIcon />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col className="splitCol">
                      <div className="cardBodyRow">
                        <Row>
                          <Col className="cardBodyDelivery">
                            <p className="tabRowContentSubHeading">
                              Today Orders
                            </p>
                            <h3>{todayOrder}</h3>
                          </Col>
                          <Col className="cardBodyDeliveryIcon">
                            <TodayIcon />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <div style={{ float: "left" }} className="orderHeader">
                    <h2 className="orderHeaderText"> Status </h2>
                  </div>
                  <Row
                    style={{
                      float: "left",
                      width: "100%",
                      marginTop: "20px",
                      display: "flex",
                    }}
                  >
                    <Col className="splitCol">
                      <div className="cardBodyRow">
                        <Row>
                          <Col className="cardBodyDelivery">
                            <p className="tabRowContentSubHeading">Delivered</p>
                            <h3>
                              <b>{orderStatus.complete || 0}</b>
                            </h3>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col className="splitCol">
                      <div className="cardBodyRow">
                        <Row>
                          <Col className="cardBodyDelivery">
                            <p className="tabRowContentSubHeading">
                              Order on Draft
                            </p>
                            <h3>
                              <b>{orderStatus.readyForDelivery || 0}</b>
                            </h3>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col className="splitCol">
                      <div className="cardBodyRow">
                        <Row>
                          <Col className="cardBodyDelivery">
                            <p className="tabRowContentSubHeading">Rejected</p>
                            <h3>
                              <b>{orderStatus.rejected || 0}</b>
                            </h3>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col className="splitCol">
                      <div className="cardBodyRow">
                        <Row>
                          <Col className="cardBodyDelivery">
                            <p className="tabRowContentSubHeading">
                              Ready for Pick Up
                            </p>
                            <h3>
                              <b>{orderStatus.readyForPickup || 0}</b>
                            </h3>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col className="splitCol">
                      <div className="cardBodyRow">
                        <Row>
                          <Col className="cardBodyDelivery">
                            <p className="tabRowContentSubHeading">
                              Delivery Accepted{" "}
                            </p>
                            <h3>
                              <b>{orderStatus.startPackaging || 0}</b>
                            </h3>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Tab>

                <Tab eventKey="orderSales" title="Orders">
                  <div style={{ width: "100%", height: "100px" }}></div>
                  <div style={{ float: "left" }} className="orderHeader">
                    <h2 className="orderHeaderText"> Sales </h2>
                  </div>
                  <Row className="tabRowContent">
                    <Col className="deliveryCard" sm={6}>
                      <div style={{ marginTop: "40px" }}>
                        <div className="deliveryCardHeader">
                          <label
                            style={{ fontSize: "14px", marginRight: "5px" }}
                          >
                            Sales graph for {days} Days
                          </label>
                          <nav class="site-nav">
                            <ul className="deliveryCardUl">
                              <li
                                className={days === "15" ? "active" : ""}
                                onClick={() => handleClick("15")}
                              >
                                <a href="#">15</a>
                              </li>
                              <li
                                className={days === "30" ? "active" : ""}
                                onClick={() => handleClick("30")}
                              >
                                <a href="#">30</a>
                              </li>
                              <li
                                className={days === "90" ? "active" : ""}
                                onClick={() => handleClick("90")}
                              >
                                <a href="#">90</a>
                              </li>
                              <li
                                className={days === "182" ? "active" : ""}
                                onClick={() => handleClick("182")}
                              >
                                <a href="#">182</a>
                              </li>
                              <li
                                className={days === "365" ? "active" : ""}
                                onClick={() => handleClick("365")}
                              >
                                <a href="#">365</a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                        <ResponsiveContainer aspect={1.6}>
                          <LineChart
                            width={600}
                            height={300}
                            data={salesGraph}
                            margin={{
                              top: 20,
                              right: 30,
                              left: 20,
                              bottom: 10,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="date"
                              height={60}
                              tick={<CustomizedAxisTick />}
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="count"
                              stroke="#8884d8"
                              label={<CustomizedLabel />}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <MaterialTable
                          title="bill Amount"
                          columns={[
                            { title: "Date", field: "date" },
                            {
                              title: "Total Orders",
                              field: "count",
                            },
                            {
                              title: "Purchased Amount",
                              field: "amount",
                              render: (rowData) => (
                                <td>
                                  {"₹ "}
                                  {rowData.amount}
                                </td>
                              ),
                            },
                          ]}
                          data={salesGraph}
                          options={{
                            exportButton: true,
                            pageSize: 5,
                            maxBodyHeight: 437,
                            pageSizeOptions: [5, 10, 20, 100],
                            grouping: false,
                            filtering: false,
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div style={{ width: "100%", height: "100px" }}></div>
                  <div style={{ float: "left" }} className="orderHeader">
                    <h2 className="orderHeaderText"> Category</h2>
                  </div>
                  <Row className="tabRowContent">
                    <Col className="deliveryCard" sm={6}>
                      <div style={{ marginTop: "40px" }}>
                        {/* Recharts library -  */}
                        <ResponsiveContainer aspect={1.6}>
                          <BarChart
                            width={600}
                            height={300}
                            data={categoryList.slice(0, 10)}
                            margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="type"
                              interval={0}
                              angle={-45}
                              textAnchor="end"
                              height={100}
                              tick={{ fontSize: 12 }}
                            />
                            <YAxis
                              label={{
                                value: "Count",
                                angle: -90,
                                position: "insideLeft",
                              }}
                            />
                            <Tooltip />
                            <Legend />

                            <Bar dataKey="count" fill="#82ca9d">
                              <LabelList dataKey="count" position="top" />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <MaterialTable
                        title="category bill"
                        columns={[
                          { title: "Category", field: "type" },
                          { title: "Count", field: "count" },
                          {
                            title: "Purchased Price",
                            field: "purchasedPrice",
                            render: (rowData) => (
                              <td>
                                {"₹ "}
                                {rowData.purchasedPrice}
                              </td>
                            ),
                          },
                        ]}
                        data={categoryList}
                        options={{
                          exportButton: true,
                          maxBodyHeight: 437,
                          pageSize: 5,
                          pageSizeOptions: [5, 10, 20, 100],
                          grouping: false,
                          filtering: false,
                        }}
                      />
                    </Col>
                  </Row>
                  <div style={{ width: "100%", height: "100px" }}></div>
                  <div style={{ float: "left" }} className="orderHeader">
                    <h2 className="orderHeaderText">Sub Category</h2>
                  </div>
                  <Row className="tabRowContent">
                    <Col className="deliveryCard" sm={6}>
                      <div style={{ marginTop: "40px" }}>
                        <ResponsiveContainer aspect={1.6}>
                          <BarChart
                            width={600}
                            height={300}
                            data={subcategoryList.slice(0, 10)}
                            margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="type"
                              interval={0}
                              angle={-45}
                              textAnchor="end"
                              height={100}
                              tick={{ fontSize: 12 }}
                            />
                            <YAxis
                              label={{
                                value: "Count",
                                angle: -90,
                                position: "insideLeft",
                              }}
                            />
                            <Tooltip />
                            <Legend />

                            <Bar dataKey="count" fill="#82ca9d">
                              <LabelList dataKey="count" position="top" />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <MaterialTable
                        title="Sub category bill"
                        columns={[
                          { title: "Sub Category", field: "type" },
                          { title: "Count", field: "count" },
                          {
                            title: "Purchased Price",
                            field: "purchasedPrice",
                            render: (rowData) => (
                              <td>
                                {"₹ "}
                                {rowData.purchasedPrice}
                              </td>
                            ),
                          },
                        ]}
                        data={subcategoryList}
                        options={{
                          exportButton: true,
                          maxBodyHeight: 437,
                          pageSize: 5,
                          pageSizeOptions: [5, 10, 20, 100],
                          grouping: false,
                          filtering: false,
                        }}
                      />
                    </Col>
                  </Row>
                </Tab>

                <Tab eventKey="reject" title="Rejected">
                  <div style={{ width: "100%", height: "100px" }}></div>
                  <div style={{ float: "left" }} className="orderHeader">
                    <h2 className="orderHeaderText"> Rejected Stats</h2>
                  </div>
                  <Row className="tabRowContent">
                    <Col sm={3} className="splitCol">
                      <div className="cardBodyRow">
                        <Row>
                          <Col className="cardBodyDelivery">
                            <p className="tabRowContentSubHeading">
                              Total Order Rejected
                            </p>
                            <h3>
                              <b>{rejectedStats.count || 0}</b>
                            </h3>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col sm={3} className="splitCol">
                      <div className="cardBodyRow">
                        <Row>
                          <Col className="cardBodyDelivery">
                            <p className="tabRowContentSubHeading">
                              Rejected order Amount
                            </p>
                            <h3>
                              <b>
                                {"₹ "}
                                {rejectedStats.amount || 0}
                              </b>
                            </h3>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>

                  <div style={{ width: "100%", height: "100px" }}></div>
                  <div style={{ float: "left" }} className="orderHeader">
                    <h2 className="orderHeaderText"> Rejected History</h2>
                  </div>

                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Order Id</TableCell>
                          <TableCell>Billing Date</TableCell>
                          <TableCell>Order Costs</TableCell>
                          <TableCell>customer Name</TableCell>
                          <TableCell>customer Id</TableCell>
                          <TableCell colSpan={2}>
                            Reason for Rejection
                          </TableCell>
                          <TableCell>Cart Details</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rejectedStats.details.map((row, index) => (
                          <>
                            <TableRow className={classes.root}>
                              <TableCell component="th" scope="row">
                                {row.orderId}
                              </TableCell>
                              <TableCell>{row.billingDate}</TableCell>
                              <TableCell>
                                {"₹ "}
                                {row.totalAmount}
                              </TableCell>
                              <TableCell>{row.customerName}</TableCell>
                              <TableCell>{row.customerId}</TableCell>

                              <TableCell colspan={2}>
                                {row.reasonForRejection}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => setOpen(!open)}
                                >
                                  {open ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={6}
                              >
                                <Collapse
                                  in={open}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box margin={1}>
                                    <Typography
                                      variant="h6"
                                      gutterBottom
                                      component="div"
                                    >
                                      History
                                    </Typography>
                                    <Table size="small" aria-label="purchases">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell colspan={3}>
                                            Product Name
                                          </TableCell>
                                          <TableCell>Sub Category</TableCell>
                                          <TableCell>Cart Count</TableCell>
                                          <TableCell>Brand</TableCell>
                                          <TableCell>Price</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {Object.entries(row.cart).map(
                                          ([historyRow, value]) => (
                                            <TableRow key={historyRow}>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                colspan={3}
                                              >
                                                {value.productName}{" "}
                                                <b>{value.unitOfMeasurment}</b>
                                              </TableCell>
                                              <TableCell>
                                                {value.subCategory}
                                              </TableCell>
                                              <TableCell>
                                                {value.cartCount}
                                              </TableCell>
                                              <TableCell>
                                                {value.productBrand}
                                              </TableCell>
                                              <TableCell>
                                                {"₹ "} {value.productPrice}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Tab>

                <Tab eventKey="profile" title="Customer">
                  <Row className="tabRowContent">
                    <div style={{ width: "100%", display: "inline" }}>
                      <span className="subHeadertab">Customer List</span>
                    </div>

                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>S No</TableCell>
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Customer ID</TableCell>

                            <TableCell>Phone Number</TableCell>
                            <TableCell>Pincode</TableCell>
                            <TableCell>Total Orders</TableCell>

                            <TableCell>Amount Paid</TableCell>
                            <TableCell>Past Order</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {customerList.map((row, index) => (
                            <>
                              <TableRow key={row.orderId}>
                                <TableCell component="th" scope="row">
                                  {index + 1}
                                </TableCell>

                                <TableCell> {row.customerName}</TableCell>
                                <TableCell> {row.customerId}</TableCell>
                                <TableCell>{row.customerPhoneNumber}</TableCell>
                                <TableCell> {row.customerPincode}</TableCell>
                                <TableCell> {row.visit}</TableCell>
                                <TableCell>
                                  {"₹ "}
                                  {row.totalAmount}
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                  >
                                    {open ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={8}
                                >
                                  <Collapse
                                    in={open}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Box margin={1}>
                                      <Typography
                                        variant="h6"
                                        gutterBottom
                                        component="div"
                                      >
                                        History
                                      </Typography>
                                      <Table
                                        size="small"
                                        aria-label="purchases"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Order Id</TableCell>
                                            <TableCell>Billed Date</TableCell>
                                            <TableCell>
                                              Delivery Method
                                            </TableCell>
                                            <TableCell>
                                              Delivery Address
                                            </TableCell>
                                            <TableCell>
                                              Amount Paid {" ₹ "}
                                            </TableCell>
                                            <TableCell>
                                              Total Products
                                            </TableCell>

                                            <TableCell colSpan={2}>
                                              Slot
                                            </TableCell>
                                            <TableCell>Order Status</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {row.purchaseDetails.map(
                                            (historyRow) => (
                                              <TableRow
                                                key={historyRow.orderId}
                                              >
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {historyRow.orderId}
                                                </TableCell>
                                                <TableCell>
                                                  {historyRow.billedDate}
                                                </TableCell>
                                                <TableCell>
                                                  {historyRow.deliveryMethod}
                                                </TableCell>
                                                {Object.keys(
                                                  historyRow.deliveryAddress
                                                ).length > 0 ? (
                                                  <TableCell>
                                                    {
                                                      historyRow.deliveryAddress
                                                        .address
                                                    }
                                                    {
                                                      historyRow.deliveryAddress
                                                        .query
                                                    }
                                                  </TableCell>
                                                ) : (
                                                  <TableCell>{" - "}</TableCell>
                                                )}
                                                <TableCell>
                                                  {" ₹ "}{" "}
                                                  {historyRow.totalAmount}
                                                </TableCell>
                                                <TableCell>
                                                  {
                                                    Object.keys(
                                                      historyRow.cartDetails
                                                    ).map.length
                                                  }
                                                </TableCell>
                                                {Object.keys(historyRow.slot)
                                                  .length > 0 ? (
                                                  <TableCell colSpan={2}>
                                                    {historyRow.slot.startTime}
                                                    {" - "}
                                                    {historyRow.slot.endTime}
                                                    <b>
                                                      {" "}
                                                      {historyRow.slot.day}
                                                    </b>
                                                  </TableCell>
                                                ) : (
                                                  <TableCell colSpan={2}>
                                                    {" - "}
                                                  </TableCell>
                                                )}
                                                <TableCell>
                                                  {historyRow.status}
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Row>
                </Tab>
                <Tab eventKey="contact" title="Activity Logs">
                  <div style={{ width: "100%", height: "100px" }}></div>
                  <div style={{ float: "left" }} className="orderHeader">
                    <h2 className="orderHeaderText"> Activity Logs </h2>
                  </div>

                  <Row className="tabRowContent">
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>S No</TableCell>
                            <TableCell>Order ID</TableCell>
                            <TableCell>Billed Date</TableCell>
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Customer ID</TableCell>

                            <TableCell>Phone Number</TableCell>
                            <TableCell>Pincode</TableCell>

                            <TableCell>Amount Paid</TableCell>
                            <TableCell>cart Details</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {activity.map((row, index) => (
                            <>
                              <TableRow key={row.orderId}>
                                <TableCell component="th" scope="row">
                                  {index + 1}
                                </TableCell>
                                <TableCell> {row.orderId}</TableCell>
                                <TableCell> {row.billingDate}</TableCell>
                                <TableCell> {row.customerName}</TableCell>
                                <TableCell> {row.customerId}</TableCell>
                                <TableCell>{row.customerPhoneNumber}</TableCell>
                                <TableCell> {row.customerPincode}</TableCell>

                                <TableCell>
                                  {"₹ "}
                                  {row.totalAmount}
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => modalProps(true, row.cart)}
                                  >
                                    <VisibilityIcon></VisibilityIcon>
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Row>
                </Tab>
              </Tabs>
            )}
          </div>

          <Modal
            className="viewCartDetails"
            show={modalShow}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Cart Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>S No</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Cart Count</TableCell>
                    <TableCell>Sub Category</TableCell>
                    <TableCell>Brand</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {Object.entries(modalData).map(([key, value], index) => (
                      <>
                        <TableRow key={key}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>

                          <TableCell>
                            {value.productName} {value.unitOfMeasurment}
                          </TableCell>
                          <TableCell>
                            {"₹ "} {value.productPrice}
                          </TableCell>
                          <TableCell> {value.cartCount}</TableCell>
                          <TableCell> {value.subCategory}</TableCell>
                          <TableCell>{value.productBrand}</TableCell>
                        </TableRow>
                      </>
                    ))}
                  </>
                </TableBody>
              </Table>
            </Modal.Body>
          </Modal>
        </Row>
      </Container>
    </>
  );
}
