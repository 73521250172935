import React, { useEffect, useState, useRef, createRef, useMemo } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import config from "../config";

import { Container, Col, Button, Row, Form, Modal } from "react-bootstrap";

import GhoshakLogo from "./Logo_Orginal.png";

import { saveAs } from "file-saver";
import CreateQrCode from "./createQrCode";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
} from "@react-pdf/renderer";
import { Switch } from "@material-ui/core";

export default function ViewWebsiteStatus() {
  const ref = React.createRef();
  const viewPdf = createRef();
  const options = {
    orientation: "landscape",
  };
  let initialState = {
    websiteUserName: "",
    websiteUserNamevalidation: false,
    websiteExpiryOn: "",
    websitePaymentStatus: "free",
    websiteErrorMessage: "",
    userId: "",
    websiteNotes: "NA",
    websitePaymentId: "NA",
    websiteActivatedBy: "",
    typename: "addData", // addData for draft status || updateData for live website update status
  };
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef("value is copied");
  const [websiteStatus, setWebsiteStatus] = useState("draft");
  const [websiteStatusData, setWebsiteStatusData] = useState([]);
  const handleClose = () => {
    setUpdateShopStatus(initialState);
    setModalShow(false);
  };
  const handleShow = () => {
    setModalShow(true);
  };
  const [updateShopStatus, setUpdateShopStatus] = useState(initialState);
  const [updateActivatedWeb, setUpdateActivatedWeb] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [refurl, setRefurl] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const updateBillLocally = () => {};
  useEffect(() => {
    setTableLoading(true);
    Promise.all([
      fetch(`${config.ApiGateway}/websiteTracker`, {
        // fetch(
        //   `https://vkbmrh8wc3.execute-api.ap-south-1.amazonaws.com/dev/websiteTracker`,
        //   {
        method: "POST",
        body: JSON.stringify({
          websiteStatus: websiteStatus,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        if (data.limitExceeds == true) {
          fetch(data.products)
            .then((res) => res.json())
            .then((out) => {
              console.log("Checkout this JSON! ", out);
              setWebsiteStatusData(out);
              setTableLoading(false);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setWebsiteStatusData(data.products);
          setTableLoading(false);
        }
      })
      .catch((err) => alert(err));
  }, [websiteStatus]);
  const makeWebsiteLive = async (e) => {
    // update shop website status
    e.preventDefault();

    let tempBody = {
      websiteUserName: updateShopStatus.websiteUserName.toLowerCase(),
      websiteExpiryOn: updateShopStatus.websiteExpiryOn,
      userId: updateShopStatus.userId,
      websitePaymentStatus: updateShopStatus.websitePaymentStatus,
      typename: updateShopStatus.typename,
      websitePaymentId: updateShopStatus.websitePaymentId,
      shopType: updateShopStatus.shopType,
    };
    if (updateShopStatus.shopType === "Aggregated") {
      tempBody.ghoDeliveryPackageActive =
        updateShopStatus.ghoDeliveryPackageActive;

      tempBody.ghoDeliveryPackageStartDate =
        updateShopStatus.ghoDeliveryPackageStartDate;

      tempBody.ghoDeliveryPackageExpiryDate =
        updateShopStatus.ghoDeliveryPackageExpiryDate;
    }
    await Promise.all([
      fetch(`${config.ApiGateway}/updateWebsiteStatus`, {
        method: "POST",
        body: JSON.stringify(tempBody),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data, "what happend");

        if (data.status == "failed") {
          setUpdateShopStatus((prevState) => ({
            ...prevState,
            websiteUserNamevalidation: false,
            websiteErrorMessage: data.errorMessage,
          }));
        } else if (data.status == "sucess") {
          let currentData = websiteStatusData;
          let removeData = currentData.filter(
            (elements) => elements.userId != updateShopStatus.userId
          );
          setWebsiteStatusData(removeData); //

          setUpdateShopStatus(initialState);
          handleClose();
          alert("Website is live");
        } else if (data.status == "update") {
          updateBillLocally();
          setWebsiteStatus("live");
          setUpdateShopStatus(initialState);
          handleClose();
          alert("Website updated");
        } else {
          alert("Website activation failed");
        }
      })
      .catch((err) => console.log(err, "Error at making website live"));
  };
  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "ghoDeliveryPackageActive") {
      setUpdateShopStatus((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
      return;
    }
    setUpdateShopStatus((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    console.log("updateShopStatus", updateShopStatus);
  }, [updateShopStatus]);
  const handleShopData = async (data) => {
    let today = new Date();
    let websiteExpiryOn = new Date(today.getTime() + 15 * 24 * 60 * 60 * 1000)
      .toISOString()
      .substring(0, 10);

    let websiteUserName = data.shopName
      .replace(/[^A-Z0-9]/gi, "")
      .toLowerCase();
    let websiteUserNamevalidation = websiteUserName.length <= 25 ? true : false;

    setUpdateShopStatus((prevState) => ({
      ...prevState,
      websiteUserName: websiteUserName,
      websiteUserNamevalidation: websiteUserNamevalidation,
      shopType: data.shopType,
      websiteExpiryOn: websiteExpiryOn,
      websiteErrorMessage: "",
      shopName: data.shopName,
      userId: data.userId,
      typename: "addData",
    }));
    await handleShow();
  };
  const deleteWebsite = async (rowData) => {
    await Promise.all([
      fetch(`${config.ApiGateway}/updateWebsiteStatus`, {
        method: "POST",
        body: JSON.stringify({
          websiteUserName: rowData.websiteUserName.toLowerCase(),
          userId: rowData.userId,
          typename: "delete",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        if (data.errorType == "InvalidChangeBatch") {
          return alert("Delete failed. Website Not Found");
        }

        if (data.status == true) {
          alert("Website Deleted.");
          let currentData = websiteStatusData;
          let removeData = currentData.filter(
            (elements) => elements.userId != rowData.userId
          );
          setWebsiteStatusData(removeData);
        } else {
          alert("Website Deletion failed");
        }
      })
      .catch((err) => console.log(err, "Error at making website live"));
  };
  const columns = useMemo(
    () => [
      {
        title: "Action",
        field: "view",
        hidden: !(websiteStatus == "live" || websiteStatus == "expired"),
        render: (rowData) => (
          <Button
            onClick={() => {
              console.log("rowData", rowData);
              setUpdateActivatedWeb(true); // Display modal
              if (rowData.shopType === "Aggregated") {
                setUpdateShopStatus((prevState) => ({
                  ...prevState,
                  userId: rowData.userId,
                  websiteUserName: rowData.websiteUserName,
                  typename: "updateData",
                  shopType: rowData.shopType,
                  websiteExpiryOn: rowData.websiteExpiryOn,
                  websitePaymentStatus: rowData.websitePaymentStatus,
                  shopType: rowData.shopType,
                  shopName: rowData.shopName,
                  userId: rowData.userId,
                  ghoDeliveryPackageActive: rowData.ghoDeliveryPackageActive
                    ? rowData.ghoDeliveryPackageActive
                    : "",
                  ghoDeliveryPackageStartDate:
                    rowData?.ghoDeliveryPackageStartDate || "",
                  ghoDeliveryPackageExpiryDate:
                    rowData?.ghoDeliveryPackageExpiryDate || "",
                }));
              } else {
                setUpdateShopStatus((prevState) => ({
                  ...prevState,
                  userId: rowData.userId,
                  websiteUserName: rowData.websiteUserName,
                  typename: "updateData",
                  shopType: rowData.shopType,
                  websiteExpiryOn: rowData.websiteExpiryOn,
                  websitePaymentStatus: rowData.websitePaymentStatus,
                  shopType: rowData.shopType,
                  shopName: rowData.shopName,
                  userId: rowData.userId,
                }));
              }
              setRefurl(rowData.websiteUrl);
              handleShow();
            }}
          >
            Update
          </Button>
        ),
      },
      {
        title: "Used Id",
        field: "userId10Digit",
        render: (rowData) => (
          <Link
            to={{
              pathname: "/merchant",
              search: `?userid=${rowData.userId10Digit}`,
            }}
          >
            {rowData.userId10Digit}
          </Link>
        ),
      },
      {
        title: "Url",
        field: "websiteUrl",
        render: (rowData) => {
          return (
            <a
              style={{ display: "table-cell" }}
              href={rowData.websiteUrl}
              target="_blank"
            >
              {rowData.websiteUrl}
            </a>
          );
        },
        hidden: !(websiteStatus == "live"),
      },
      {
        title: "QR Code",
        field: "websiteUrl",
        hidden: !(websiteStatus == "live"),
        render: (rowData) => (
          <Button
            onClick={async () => {
              let blob = await pdf(
                <CreateQrCode shopdetails={rowData} />
              ).toBlob();
              saveAs(blob, rowData.shopName);
            }}
          >
            Generate Qr Code
          </Button>
        ),
      },
      { title: "Shop Name", field: "shopName" },
      { title: " Name", field: "userName" },
      { title: "Shop Type", field: "shopType" },
      {
        title: "Phone Number",
        field: "userPhoneNumber",
      },
      {
        title: "Pincode",
        field: "userPincode",
      },
      {
        title: "Website Username",
        field: "websiteUserName",
        hidden: !(websiteStatus == "live" || websiteStatus == "expired"),
      },

      {
        title: "Payment Type",
        field: "websitePaymentStatus",
        hidden: !(websiteStatus == "live" || websiteStatus == "expired"),
      },

      {
        title: "Updated on",
        field: "websiteActivatedOn",
        hidden: !(websiteStatus == "live" || websiteStatus == "expired"),
      },
      {
        title: "Expiration on",
        field: "websiteExpiryOn",
        hidden: !(websiteStatus == "live" || websiteStatus == "expired"),
      },

      {
        title: "Action",
        field: "view",
        hidden: !(websiteStatus == "draft"),
        render: (rowData) => (
          <Button
            onClick={() => {
              setUpdateActivatedWeb(false);
              handleShopData(rowData);
            }}
          >
            Make Website Live
          </Button>
        ),
      },
      {
        title: "Delete",
        field: "delete",
        hidden: !(websiteStatus == "live" || websiteStatus == "expired"),
        render: (rowData) => (
          <Button variant="danger" onClick={() => deleteWebsite(rowData)}>
            Delete
          </Button>
        ),
      },
    ],
    [websiteStatus, websiteStatusData]
  );
  // console.log(websiteStatusData, "global");
  return (
    <Container fluid>
      <div style={{ width: "100%", height: "40px" }}></div>

      <div className="orderHeader">
        <h2 className="orderHeaderText">Website status tracker</h2>
      </div>
      <Row>
        <Col sm={"9"}></Col>
        <Col sm={"3"}>
          <Form.Group
            as={Col}
            style={{ float: "right" }}
            controlId="formHorizontalEmail"
          >
            <Form.Label column sm={12}>
              Filter by website status
            </Form.Label>
            <Col sm={12}>
              <Form.Control
                onChange={(e) => setWebsiteStatus(e.target.value)}
                as="select"
                value={websiteStatus}
              >
                <option value="draft">Draft</option>
                <option value="live">Live</option>
                <option value="expired">Expired</option>
              </Form.Control>
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <MaterialTable
        title={`Shop Details`}
        columns={columns}
        data={websiteStatusData}
        options={{
          exportButton: false,
          pageSize: 20,
          pageSizeOptions: [5, 10, 20, 100, 1000, 500],
          grouping: false,
          filtering: false,
        }}
      />
      {/* ModalShow - true */}
      <Modal className="viewCartDetails" show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="websiteModalContainer">
            <div className="websiteModalContainerHeader">
              <h4>Website Activation Details</h4>
            </div>
            <div className="vertical-space-10"></div>
            <Form
              onSubmit={makeWebsiteLive}
              style={{ width: "100%", height: "100%" }}
            >
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  Shop Name
                </Form.Label>
                <Col sm={6}>
                  <Form.Control value={updateShopStatus.shopName} plaintext />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  Shop Name
                </Form.Label>
                <Col sm={6}>
                  <Form.Control value={updateShopStatus.shopType} plaintext />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  Website Username
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    value={updateShopStatus.websiteUserName}
                    name="websiteUserName"
                    // disabled={updateShopStatus.websiteUserNamevalidation}
                    plaintext={updateActivatedWeb}
                    onChange={(e) => handleChange(e)}
                    pattern="^[a-zA-Z0-9]{1,25}"
                  />
                  {!updateShopStatus.websiteUserNamevalidation && (
                    <Form.Text>
                      Character should be alphanumberic.No Special characters
                      are not allowed
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  Payment Id
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    value={updateShopStatus.websitePaymentId}
                    name="websitePaymentId"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  Payment Type
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    as="select"
                    value={updateShopStatus.websitePaymentStatus}
                    name="websitePaymentStatus"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="free">Free</option>
                    <option value="paid">Paid</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  Notes
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    as="textarea"
                    value={updateShopStatus.websiteNotes}
                    name="websiteNotes"
                    onChange={(e) => handleChange(e)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  Website Expiry Date
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="date"
                    value={updateShopStatus.websiteExpiryOn}
                    name="websiteExpiryOn"
                    onChange={(e) => handleChange(e)}
                  />
                  {!updateShopStatus.websiteUserNamevalidation && (
                    <Form.Text style={{ color: "red" }}>
                      {updateShopStatus.websiteUserName.length <= 25
                        ? updateShopStatus.websiteErrorMessage
                        : "Character should not exceed more than 25 characters "}
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>
              {/* {updateShopStatus.shopType === "Aggregated" && (
                <>
                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Ghodelivery Activation
                    </Form.Label>
                    <Col sm={6}>
                      <Switch
                        name="ghoDeliveryPackageActive"
                        onClick={(e) => handleChange(e)}
                        checked={
                          updateShopStatus.ghoDeliveryPackageActive
                            ? updateShopStatus.ghoDeliveryPackageActive
                            : false
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Ghodelivery package start date
                    </Form.Label>
                    <Col sm={6}>
                      <Form.Control
                        type="date"
                        value={
                          updateShopStatus.ghoDeliveryPackageStartDate
                            ? updateShopStatus.ghoDeliveryPackageStartDate
                            : ""
                        }
                        name="ghoDeliveryPackageStartDate"
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formHorizontalEmail">
                    <Form.Label column sm={4}>
                      Ghodelivery package expiry date
                    </Form.Label>
                    <Col sm={6}>
                      <Form.Control
                        type="date"
                        value={
                          updateShopStatus.ghoDeliveryPackageExpiryDate
                            ? updateShopStatus.ghoDeliveryPackageExpiryDate
                            : ""
                        }
                        name="ghoDeliveryPackageExpiryDate"
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </>
              )} */}
              {updateActivatedWeb && (
                <Form.Group as={Row} controlId="formHorizontalEmail">
                  <Form.Label column sm={4}>
                    Url
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control value={refurl} plaintext />
                  </Col>
                </Form.Group>
              )}

              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Col sm={10}>
                  <Button style={{ float: "right" }} type="submit">
                    {updateActivatedWeb ? "Update Website" : "Activate Website"}
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
