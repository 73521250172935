// Import Dependencies

import MaterialTable from "material-table";
import React, { useEffect, useState, useContext } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import config from "../config";
import { useHistory } from "react-router-dom";
import { store } from "../provider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { CSVLink } from "react-csv";

function Gamification(props) {
  let history = useHistory();
  const context = useContext(store);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [packagePayment, setPackagePayment] = useState([]);
  const [generatedData, setGeneratedData] = useState([]);

  useEffect(() => {
    fetchAllRequest();
  }, []);

  const fetchAllRequest = async () => {
    let response = await fetch(`${config.ApiGateway}/getPackagePayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    // console.log(result);
    setPackagePayment(result.Items);
  };

  const generateReport = () => {
    // alert("hi");
    // DatePicker().
    let temp = [];

    temp = packagePayment.filter(
      (item) =>
        moment(item.billingDate).isSameOrAfter(startDate) &&
        moment(item.billingDate).isSameOrBefore(endDate)
    );

    temp = temp.map((item) => {
      return { ...item, ...item.razorpayDetails };
    });

    setGeneratedData(temp);
  };

  return (
    <>
      <Container fluid>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Package Payment</h2>
        </div>{" "}
        <div
          style={{
            margin: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy-MM-dd"

            // style={{ marginRight: 10 }}
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="yyyy-MM-dd"

            // style={{ marginRight: 10 }}
          />
          <Button style={{ margin: 10 }} onClick={() => generateReport()}>
            {" "}
            Generate Report{" "}
          </Button>
          <CSVLink data={generatedData} target="_blank">
            Download{" "}
          </CSVLink>
          ;
        </div>
        <MaterialTable
          // actions={[
          //   (rowData) => ({
          //     icon: "save",
          //     tooltip: "Change status",
          //     onClick: (event, rowData) => {},
          //   }),
          // ]}
          columns={[
            {
              title: "Date",
              field: "billingDate",
            },
            {
              title: "Payment Id",
              field: "paymentDetails.razorpay_payment_id",
            },
            {
              title: "Packages",
              field: "items",
              render: (data) =>
                data.items.map((item) => item.packageType + " "),
            },
            { title: "Paid", field: "amountPaid" },
            { title: "Combo Discount", field: "comboDiscount" },
            { title: "Promocode Discount", field: "promocodeDiscount" },
            { title: "Tax", field: "razorpayDetails.tax" },
            { title: "Razorpay Charges", field: "razorpayDetails.fee" },
          ]}
          data={packagePayment}
          // title={currentTab + " claims"}
          options={{
            actionsColumnIndex: -1,
            search: true,
            exportButton: true,
          }}
        />
      </Container>
    </>
  );
}

export default Gamification;
