import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Container, Col, Button, Row, Tabs, Tab, Form } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  LabelList,
  Legend,
} from "recharts";
import config from "../config";
import MaterialTable from "material-table";
export default function DistributionOfCategoryAndSub() {
  const [isLoading, setIsLoading] = useState(false);
  // this.consolidatedList = [];
  const [allProducts, setAllProducts] = useState([]);
  const [currentTitle, setCurrentTitle] = useState(false);

  const { type } = useParams();
  const [title, setTitle] = useState(
    type == "subCategory" ? "Sub Category" : "Category"
  );
  const [list, setList] = useState([]);
  const [days, setDays] = useState("15");
  const [pincodeArray, setPincodeArray] = useState([]);
  const [filterPincode, setFilterPincode] = useState("All");
  const [purchasePriceList, setPurchasePriceList] = useState([]);
  const onChange = (e) => {
    setFilterPincode(e.target.value);
  };

  const fetchData = () => {
    Promise.all([
      fetch(`${config.ApiGateway}/categoryAndSubCategoryStats`, {
        method: "POST",
        body: JSON.stringify({
          countOfDays: days,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        // data structure
        // category = {pincode , count : category count, category name}
        // sub category = {pincode , count : category count, category name}
        let getData =
          title === "Sub Category" ? data.subCategory : data.category;

        let pincodeList = [];

        let getHighestPurchase = {};

        getData.forEach((elements) => {
          if (getHighestPurchase[elements.pincode] === undefined) {
            getHighestPurchase[elements.pincode] = parseFloat(
              elements.purchasedPrice
            );
          } else {
            getHighestPurchase[elements.pincode] += parseFloat(
              elements.purchasedPrice
            );
          }
        });

        let purchasedArray = [];

        Object.entries(getHighestPurchase).forEach(([key, value]) => {
          purchasedArray.push({
            pincode: key,
            purchasedPrice: value,
          });
        });

        getData.map((elements) => pincodeList.push(elements.pincode));
        pincodeList = [...new Set(pincodeList)].map((elements) => (
          <option value={elements}>{elements}</option>
        ));
        setPincodeArray(pincodeList);
        setPurchasePriceList(purchasedArray);
        setList(getData);
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleClick = async (value) => {
    setDays(value);
    await fetchData();
  };
  const consolidatedList = list.filter((elements) =>
    filterPincode === "All"
      ? elements
      : filterPincode === elements.pincode
      ? elements.pincode
      : null
  );

  const sortByCount = consolidatedList.sort((a, b) => b.count - a.count);

  return (
    <>
      <Container>
        <div style={{ width: "100%", height: "100px" }}></div>
        <div className="orderHeader">
          <h2 className="orderHeaderText">
            {title === "Sub Category" ? "Sub Category" : "Category"}
          </h2>
        </div>

        <div
          style={{
            display: "flex",
            height: "100px",
            justifyContent: "flex-end",
          }}
        >
          <Col sm={3}>
            <Form.Label>Pincode</Form.Label>
            <Form.Control
              onChange={onChange}
              name="filterPincode"
              value={filterPincode}
              as="select"
            >
              <option value="All">All</option>
              {pincodeArray}
            </Form.Control>
          </Col>
        </div>

        <Row>
          <Col className="flexCard" sm={6}>
            <div style={{ marginTop: "40px" }}>
              <div className="flexCardHeader">
                <label style={{ fontSize: "14px", marginRight: "5px" }}>
                  Top {title === "Sub Category" ? "Sub Category" : "Category"}{" "}
                  By Count
                </label>
                <nav class="site-nav">
                  <ul className="flexCardUl">
                    <li
                      className={days === "15" ? "active" : ""}
                      onClick={() => handleClick("15")}
                    >
                      <a href="#">15</a>
                    </li>
                    <li
                      className={days === "30" ? "active" : ""}
                      onClick={() => handleClick("30")}
                    >
                      <a href="#">30</a>
                    </li>
                    <li
                      className={days === "90" ? "active" : ""}
                      onClick={() => handleClick("90")}
                    >
                      <a href="#">90</a>
                    </li>
                    <li
                      className={days === "182" ? "active" : ""}
                      onClick={() => handleClick("182")}
                    >
                      <a href="#">182</a>
                    </li>
                    <li
                      className={days === "365" ? "active" : ""}
                      onClick={() => handleClick("365")}
                    >
                      <a href="#">365</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <ResponsiveContainer aspect={1.6}>
                <BarChart
                  width={600}
                  height={300}
                  data={sortByCount.slice(0, 10)}
                  margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="type"
                    interval={0}
                    angle={-45}
                    textAnchor="end"
                    height={100}
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis
                    label={{
                      value: "Count",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Legend />

                  <Bar dataKey="count" fill="#82ca9d">
                    <LabelList dataKey="count" position="top" />

                    <LabelList
                      dataKey="pincode"
                      offset={30}
                      angle={-45}
                      position="bottom"
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col sm={6}>
            <MaterialTable
              title="Purchased Price by pincode"
              columns={[
                { title: "Pincode", field: "pincode" },
                {
                  title: "Purchased Price",
                  field: "purchasedPrice",

                  render: (rowData) => (
                    <td>
                      {"₹ "}
                      {rowData.purchasedPrice}
                    </td>
                  ),
                },
              ]}
              data={purchasePriceList}
              options={{
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 20, 100],
                grouping: false,
                filtering: false,
              }}
            />
          </Col>
        </Row>
        <div style={{ width: "100%", height: "100px" }}></div>
        <div className="orderHeader">
          <h2 className="orderHeaderText">List</h2>
        </div>

        <Row>
          <Col sm={12}>
            <MaterialTable
              title={
                title === "Sub Category" ? "Sub Category List" : "Category List"
              }
              columns={[
                {
                  title: title,
                  field: "type",
                },
                { title: "Count", field: "count" },
                { title: "Pincode", field: "pincode" },
                {
                  title: "Purchased Price",
                  field: "purchasedPrice",
                  render: (rowData) => (
                    <td>
                      {"₹ "}
                      {rowData.purchasedPrice}
                    </td>
                  ),
                },
              ]}
              data={consolidatedList}
              options={{
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 20, 100],
                grouping: false,
                filtering: false,
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
