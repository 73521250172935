import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Form, Row, Col, Button, Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import config from "../config";
import ShopProfilecard from "../template/shopProfile";
import MaterialTable from "material-table";
let initialState = {
  category: null,
  group: null,
  inventoryQty: 1,
  latest: "",
  productBrand: "",
  productCode: "",
  productId: "",
  productMrp: "",
  productName: "",
  productPrice: "",
  productQty: "1",
  unitOfMeasurmentUnit: "g",
  unitOfMeasurmentValue: null,
  duration: null,
  vegNonVeg: "Veg",
  shopId: "",
  status: "",
  subCategory: null,
  unitOfMeasurment: "",
  versionId: "",
  productEANCode: "",
  productType: "",
};
export default function InventoryProductsByShop() {
  //7101514418 -  textile
  //8282907746  - saloon
  // 3859310120  -  mobile
  // 7793187914  - super market

  const [userId10Digit, setUserId10Digit] = useState(""); // shop id
  const [show, setShow] = useState(false); // modal state
  const [state, setState] = useState(initialState);
  const [errMessage, setErrMessage] = useState(""); // error message
  const [productList, setProductList] = useState([]); // Product list
  const [filteredProducts, setFilteredProducts] = useState([]); // Filter status by live,ported, all
  const [filterBy, setFilterBy] = useState("All"); // default filter by status
  const [dropdown, setDropDown] = useState({
    groupList: [],
    categoryList: [],
    subCategoryList: [],
    unitList: [],
    productListJson: [],
    shopDetails: {},
    isLoading: false,
    tableLoading: true,
    shopType: "",
    durationList: [],
    allProductBySegment: [],
  });

  useEffect(() => {
    async function getAllDropdown() {
      try {
        const response = await fetch(`${config.json.URL}/productCategory.json`);
        var productsJson = await response.json();
        const fields = await fetch(`${config.json.URL}/fields.json`);
        var fieldsJSON = await fields.json();
      } catch (error) {
        console.log(error);
      }
      // productsJson  -  Product json file for supermarket and provision stores (kirana)
      let categoryDropdown = [];
      let groupDropdown = [];
      let subDropdown = [];
      let unitList = [];
      let durationDropdown = [];
      fieldsJSON.unitOfMesurement.map((elements) =>
        unitList.push(<option value={elements}>{elements}</option>)
      );
      fieldsJSON.duration.map((elements) =>
        durationDropdown.push(<option value={elements}>{elements}</option>)
      );
      Object.entries(productsJson).forEach(([key, value]) => {
        Object.values(value).forEach((key1) => {
          groupDropdown.push(<option value={key1.name}>{key1.name}</option>);
          Object.values(key1.category).forEach((key2) => {
            Object.values(key2.subCategory).forEach((key3) => {
              subDropdown.push(<option value={key3}>{key3}</option>);
            });

            categoryDropdown.push(
              <option value={key2.name}>{key2.name}</option>
            );
          });
        });
      });

      let groupList = [];
      Object.entries(productsJson.groups).forEach((elements) => {
        groupList.push(
          <option value={elements[1].name}>{elements[1].name}</option>
        );
      });

      setDropDown((prevState) => ({
        ...prevState,
        unitList: unitList,
        durationList: durationDropdown,
        productListJson: productsJson,
      }));
    }

    getAllDropdown();
  }, []);

  const getShopInventoryProducts = (e) => {
    e.preventDefault();
    getShopAddedProducts();
  };
  const getShopAddedProducts = () => {
    setErrMessage("");
    Promise.all([
      fetch(
        `${config.ApiGateway}/viewShopAddedProducts`,

        {
          method: "POST",

          body: JSON.stringify({ userId10Digit: userId10Digit }), // shop id
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        let categoryDropdown = [];
        let groupDropdown = [];
        let subDropdown = [];
        let itemBySubCategoryList = [];

        let subArray = [];
        if (data.userDetails.length > 0) {
          let shopType = data.userDetails[0].shopType; // get shop type
          let getTemplate = await getShopJsonbyShopType(shopType);

          if (shopType == "Saloon" || shopType == "Beauty Parlour") {
            let jsonType =
              shopType == "Saloon"
                ? getTemplate.salonCategory
                : getTemplate.beautyParlourCategory;
            jsonType.forEach((items) => {
              categoryDropdown.push(
                <option value={items.value}>{items.value}</option>
              );
            });
          } else if (shopType == "Mobile") {
            getTemplate.mobileCategory.forEach((items) => {
              categoryDropdown.push(
                <option value={items.value}>{items.value}</option>
              );
            });
          } else if (
            shopType == "Supermarket" ||
            shopType == "Provisional Store" ||
            shopType == "Sweets and Snacks"
          ) {
            Object.entries(getTemplate.groups).map(([elements, headers]) => {
              Object.entries(headers.category).forEach(([items, values]) => {
                values.subCategory.map(
                  (sub) =>
                    sub !== "ALL" &&
                    itemBySubCategoryList.push({
                      group: headers.name,
                      category: values.name,
                      subCategory: sub,
                    }) &&
                    subArray.push(sub)
                );
              });
            });
            subArray = subArray.sort();

            subArray.forEach((sub) => {
              subDropdown.push(<option value={sub}>{sub}</option>);
            });
          } else if (shopType == "Textile") {
            getTemplate.category.map((item) => {
              getTemplate.subCategory.map((elements) => {
                if (item.value === elements.category) {
                  itemBySubCategoryList.push({
                    group: "Others",
                    category: elements.category,
                    subCategory: elements.value,
                  }) && subArray.push(elements.value);
                }
              });
            });
            itemBySubCategoryList = itemBySubCategoryList.sort(
              (a, b) => b.subCategory - a.subCategory
            );

            itemBySubCategoryList.forEach((sub) => {
              subDropdown.push(
                <option
                  value={sub.subCategory}
                >{`${sub.category} - ${sub.subCategory}`}</option>
              );
            });
          } else if (shopType == "Restaurant") {
            Object.entries(getTemplate).forEach(([key, value]) => {
              Object.values(value).forEach((key1) => {
                groupDropdown.push(
                  <option value={key1.name}>{key1.name}</option>
                );
                Object.values(key1.category).forEach((key2) => {
                  Object.values(key2.subCategory).forEach((key3) => {
                    subDropdown.push(<option value={key3}>{key3}</option>);
                  });

                  categoryDropdown.push(
                    <option value={key2.name}>{key2.name}</option>
                  );
                });
              });
            });
          }
          setProductList(data.results);
          setDropDown((prevState) => ({
            ...prevState,
            productListJson: getTemplate,
            allProductBySegment: itemBySubCategoryList,
            categoryList: categoryDropdown,
            groupList: groupDropdown,
            subCategoryList: subDropdown,
            shopDetails: data.userDetails[0],
            isLoading: true,
            tableLoading: false,
            shopType: data.userDetails[0].shopType,
          }));
        } else {
          setErrMessage("Invalid Shop Id");
        }
      })
      .catch((err) => console.log(err));
  };
  const collectionSubCategory = (categoryValue) => {
    let tempSubcategory = [];
    dropdown.productListJson.subCategory.map((elements) => {
      if (categoryValue === elements.category) {
        tempSubcategory.push(<option>{elements.value}</option>);
      }
    });
    setDropDown((prevState) => ({
      ...prevState,

      subCategoryList: tempSubcategory,
    }));
    setState((prevState) => ({ ...prevState, category: categoryValue }));
  };

  const handleClose = () => {
    setShow(false);
    setState(initialState);
  };

  const getShopJsonbyShopType = async (shopType) => {
    switch (shopType) {
      case "Supermarket":
      case "Provisional Store":
        try {
          const response = await fetch(
            `${config.json.URL}/productCategory.json`
          );
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }
      case "Sweets and Snacks":
        try {
          const response = await fetch(
            `${config.json.URL}/productCategorySweetsAndSnacks.json`
          );
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }
      case "Restaurant":
      case "Bakery":
      case "Juice Shop":
      case "Tea Shop":
        try {
          const response = await fetch(`${config.json.URL}/restaurant.json`);
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }
      case "Saloon":
      case "Textile":
      case "Beauty Parlour":
      case "Mobile":
        try {
          const response = await fetch(`${config.json.URL}/fields.json`);
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }

      default:
        return {};
    }
  };

  const validateInput = (e) => {
    e.preventDefault();
    let shopType = dropdown.shopType;
    let input = state;
    input.shopId = dropdown.shopDetails.userId;

    const regNumber = /^[1-9][0-9]*$/;

    if (!regNumber.test(input.productPrice))
      return alert("Enter valid product price");

    if (input.productPrice === "TBD") {
      alert("Enter Seller Price");
      return false;
    }
    if (input.productPrice === "") {
      alert("Enter Seller Price");
      return false;
    }
    if (
      shopType == "Saloon" ||
      shopType == "Beauty Parlour" ||
      shopType == "Mobile"
    ) {
      if (input.category == null) {
        return alert("Select Category");
      }

      if (input.duration == null) {
        if (shopType == "Saloon" || shopType == "Beauty Parlour") {
          return alert("Select Duration");
        }
      }

      input.unitOfMeasurment = "NA";
      input.subCategory = "Others";
      input.group = "Others";
      input.productMrp = input.productPrice.toString();

      input.productPrice = input.productPrice.toString();
      input.inventoryQty = 1;
      input.productQty = "1";
    }

    if (
      shopType == "Provisional Store" ||
      shopType == "Supermarket" ||
      shopType == "Sweets and Snacks"
    ) {
      if (input.group === null) return alert("Select sub Category");
      if (input.category === null) return alert("Select sub Category");
      if (input.subCategory === null) return alert("Select sub Category");

      if (
        input.unitOfMeasurmentValue !== "" &&
        input.unitOfMeasurmentValue !== undefined &&
        input.unitOfMeasurmentValue !== null
      ) {
      } else {
        console.log(input.unitOfMeasurmentValue, "UOM VALUE");
        return alert("Enter measurement value");
      }

      if (input.productName !== "" && input.productName !== undefined) {
      } else {
        return alert("Enter Product Name");
      }

      if (parseInt(input.inventoryQty) <= 0) {
        alert("Inventory Quantity must be greater than 1");
        return false;
      }
      if (input.productPrice === "TBD") {
        alert("Enter Seller Price");
        return false;
      }
      if (input.productPrice === "") {
        alert("Enter Seller Price");
        return false;
      }
      input.productPrice = input.productPrice.toString();
      input.productQty = "1";
      input.unitOfMeasurment =
        input.unitOfMeasurmentValue + " " + input.unitOfMeasurmentUnit;
      input.productMrp = input.productMrp.toString();

      input.inventoryQty = parseInt(input.inventoryQty);
      if (input.productMrp == "") return alert("Enter product mrp");
      if (!regNumber.test(input.productMrp))
        return alert("Enter valid product MRP");
    }

    if (
      shopType == "Restaurant" ||
      shopType == "Bakery" ||
      shopType == "Juice Shop"
    ) {
      if (input.subCategory === "") return alert("Select sub Category");

      if (input.productQty !== "" && input.productQty !== undefined) {
      } else {
        return alert("Enter Product Qty");
      }
      input.subCategory = input.subCategory.toLowerCase();
      input.unitOfMeasurment = "NA";
      input.group = "RESTAURANT";
      input.category = "RESTAURANT";

      input.inventoryQty = 1;
      input.productQty = input.productQty.toString();

      if (input.productPrice === "TBD") {
        alert("Enter Seller Price");
        return false;
      }
      if (input.productPrice === "") {
        alert("Enter Seller Price");
        return false;
      }

      input.productMrp = input.productPrice.toString();
      input.productPrice = input.productPrice.toString();

      if (parseInt(input.inventoryQty) <= 0) {
        alert("Inventory Quantity must be greater than 1");
        return false;
      }
      input.inventoryQty = parseInt(input.inventoryQty);
    }

    if (shopType == "Textile") {
      input.productMrp = input.productPrice.toString();

      input.productPrice = input.productPrice.toString();
      if (input.group === null) return alert("Select sub Category");
      if (input.category === null) return alert("Select sub Category");
      if (input.subCategory === null) return alert("Select sub Category");
      input.inventoryQty = 1;
      input.productQty = "1";
    }

    delete input.unitOfMeasurmentUnit;
    delete input.unitOfMeasurmentValue;

    input.productEANCode =
      input.productEANCode === "" ? "TBD" : input.productEANCode;
    addProducts(input);
  };
  const addProducts = (input) => {
    if (input.shopId == "") {
      return alert("Invalid Shop");
    }
    Promise.all([
      fetch(`${config.ApiGateway}/addInventoryItem`, {
        method: "POST",
        body: JSON.stringify({ productList: [input] }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setDropDown((prevState) => ({
          ...prevState,

          tableLoading: true,
        }));
        getShopAddedProducts();
        handleClose();
      })
      .catch((err) => console.log("add product status", err));
  };

  const updateProduct = (newObj) => {
    //  To update product list
    let input = {};
    input = newObj;
    const regNumber = /^[1-9][0-9.]*$/;
    input.productImage = input.productImage || "removed";
    if (!regNumber.test(input.productPrice))
      // to check price is number
      return alert("Enter valid product price");

    if (input.productQty !== "" && input.productQty !== undefined) {
    } else {
      // to check quantity is number
      // return alert("Enter Product Qty");
      input.productQty = "1";
    }

    if (
      ["Supermarket", "Provisional Store", "Sweets and Snacks"].includes(
        dropdown.shopDetails["shopType"]
      )
    ) {
      // to check price is number
      if (input.productMrp !== "" && input.productMrp !== undefined) {
      } else {
        return alert("Enter Product Mrp");
      }
      // to check measurement value is number
      if (
        input.measurementValue !== "" &&
        input.measurementValue !== undefined
      ) {
      } else {
        return alert("Enter measurement value");
      }
      // Stock should be more than 0.
      if (parseInt(input.inventoryQty) <= 0) {
        alert("Inventory Quantity must be greater than 1");
        return false;
      }

      input.inventoryQty = parseInt(input.inventoryQty);
      input.unitOfMeasurment =
        input.measurementValue + " " + input.measurementUnit;
    } else {
      input.productType = input.productType || "";
      input.productMrp =
        typeof input.productMrp == "undefined"
          ? input.productPrice
          : input.productMrp.replace(/[^0-9]/g, "") || "0";
    }
    delete input.measurementUnit;
    delete input.measurementValue;

    input.productMrp = input.productMrp.toString();

    input.productQty = input.productQty.toString();
    if (input.productPrice === "TBD") {
      alert("Enter Seller Price");
      return false;
    }
    if (input.productPrice === "") {
      alert("Enter Seller Price");
      return false;
    }
    input.productPrice = input.productPrice.toString();

    if (input.productMrp == "") return alert("Enter product mrp");
    if (!regNumber.test(input.productMrp))
      return alert("Enter valid product MRP");

    Promise.all([
      fetch(`${config.ApiGateway}/shopEditProducts`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log("__ Product Edited __");
        setDropDown((prevState) => ({
          ...prevState,

          tableLoading: true,
        }));
        getShopAddedProducts(); // Get Updated data
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteProduct = (productId) => {
    //  product list -- all product
    let filterProduct = productList.find(
      (items) => items.productId === productId
    );
    // APi called to delete product from database
    Promise.all([
      fetch(`${config.ApiGateway}/deleteMasterProducts`, {
        method: "POST",
        body: JSON.stringify({
          productId: filterProduct.productId,
          versionId: filterProduct.versionId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setDropDown((prevState) => ({
          ...prevState,

          tableLoading: true,
        }));
        getShopAddedProducts(); // get updated product list
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    //  To Filter product by product status
    let PRODUCTS = productList.filter((items) =>
      filterBy === items.status
        ? items.status
        : filterBy === "All"
        ? items
        : null
    );

    setFilteredProducts(PRODUCTS);
  }, [filterBy, productList]);

  // const onChange = (name, value) => {
  // let name = e.target.name;
  // let value = e.target.value;
  //   setState((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const onChange = useCallback((name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);
  // console.log(state);

  const columns = useMemo(
    () => [
      {
        title: "Image",
        field: "productImage",
        render: (rowData) => (
          <img
            src={rowData.productImage || "NA"}
            style={{
              width: "150px",
              height: "auto",
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "5px",
              position: "relative",
            }}
          ></img>
        ),
      },
      {
        title: "Group",
        field: "group",
        editable: "never",
        hidden: ![
          "Provisional Store",
          "Supermarket",
          "Sweets and Snacks",
        ].includes(dropdown.shopDetails["shopType"]),
      },

      {
        title: "Category",
        field: "category",
        editable: "never",
        hidden: dropdown.shopDetails["shopType"] == "Restaurant",
      },
      {
        title: "Sub Category",
        field: "subCategory",
        editable: "never",
      },
      {
        title: " Inventory Quantity",
        field: "inventoryQty",
        hidden: [
          "Provisional Store",
          "Supermarket",
          "Sweets and Snacks",
        ].includes(dropdown.shopDetails["shopType"]),
      },

      {
        title: "Selling Price ₹",
        field: "productPrice",
        type: "numeric",
        validate: (rowData) => rowData.productPrice > 0,
      },

      {
        title: "Brand",
        field: "productBrand",
        hidden: dropdown.shopDetails["shopType"] == "Restaurant",
      },

      {
        title: "Product Name",
        field: "productName",
        validate: (rowData) =>
          rowData.productName === "" ? "Product Name must have 3 chars" : true,
      },
      {
        title: "Product Type",
        field: "productType",
        hidden: ![
          "Provisional Store",
          "Supermarket",
          "Sweets and Snacks",
        ].includes(dropdown.shopDetails["shopType"]),
        editComponent: (props) => (
          <select
            type="text"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          >
            <select value="">Select Product Type</select>
            <option value="product">Product</option>
            <option value="service">Service</option>
          </select>
        ),
      },

      {
        title: "Measurement value",
        field: "measurementValue",
        hidden: [
          "Provisional Store",
          "Supermarket",
          "Sweets and Snacks",
        ].includes(dropdown.shopDetails["shopType"]),
        render: (rowData) => {
          let measurementValue = "NA";
          if (typeof rowData.unitOfMeasurment != "undefined") {
            let tempValue = rowData.unitOfMeasurment.split(" ");
            measurementValue = tempValue[0];
          }

          return <td>{measurementValue}</td>;
        },
      },

      {
        title: "Measurement Unit",
        field: "measurementUnit",
        hidden: [
          "Provisional Store",
          "Supermarket",
          "Sweets and Snacks",
        ].includes(dropdown.shopDetails["shopType"]),
        render: (rowData) => {
          let measurementUnit = "NA";
          if (typeof rowData.unitOfMeasurment != "undefined") {
            const tempValue = rowData.unitOfMeasurment.split(" ");
            measurementUnit = tempValue[1];
          }
          return <td>{measurementUnit}</td>;
        },
        editComponent: (props) => {
          const measurementUnit =
            props.rowData.unitOfMeasurment.split(" ") || "NA";

          return (
            <select
              name="measurementUnit"
              value={props.value || measurementUnit[1]}
              onChange={(e) => props.onChange(e.target.value)}
            >
              <select value="">Select Unit</select>
              {dropdown.unitList}
            </select>
          );
        },
      },

      {
        title: "Product Quantity ",
        field: "productQty",
        hidden: ![
          "Provisional Store",
          "Supermarket",
          "Sweets and Snacks",
        ].includes(dropdown.shopDetails["shopType"]),
      },

      {
        title: "Status",
        field: "status",
        editable: "never",
      },
      {
        title: "MRP ₹",
        field: "productMrp",
        hidden: ![
          "Provisional Store",
          "Supermarket",
          "Sweets and Snacks",
        ].includes(dropdown.shopDetails["shopType"]),
      },
      {
        title: "Latest",
        field: "latest",
        editable: "never",
      },
      {
        title: "Veg/ Non Veg",
        field: "vegNonVeg",
        editable: "always",
        hidden: ![
          "Provisional Store",
          "Supermarket",
          "Sweets and Snacks",
        ].includes(dropdown.shopDetails["shopType"]),
        editComponent: (props) => (
          <select
            type="text"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          >
            <option value="Veg">Vegeterian</option>
            <option value="Non-Veg">Non Vegterian</option>
          </select>
        ),
      },

      {
        title: "Product ID",
        field: "productId",
        editable: "never",
      },
      {
        title: "Date",
        field: "addedDate",
        editable: "never",
      },
      {
        title: "Ean Code",
        field: "productEANCode",
        editable: "always",
      },
    ],
    []
  );

  return (
    <Container fluid>
      <div style={{ padding: "20px" }}>
        <div style={{ width: "100%", height: "30px" }}></div>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Shop Inventory Products</h2>
        </div>

        <Row>
          <Col className="dashboardColumnBox columnCenter" sm="8">
            <Form onSubmit={getShopInventoryProducts}>
              <Form.Row>
                <Col sm="4">
                  <Form.Label>Enter Shop Id</Form.Label>
                </Col>
                <Col sm="6">
                  <Form.Control
                    onChange={(e) => setUserId10Digit(e.target.value)}
                    value={userId10Digit}
                  />
                  <Form.Text
                    style={{
                      color: "red",
                      display: errMessage.length > 0 ? "" : "none",
                    }}
                  >
                    {errMessage}
                  </Form.Text>
                </Col>
                <Col sm="2">
                  <Button type="submit">Search</Button>
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </Row>
        <div className="vertical-space-30"></div>

        {dropdown.isLoading && (
          <>
            <Row>
              <Col>
                <ShopProfilecard shopInfo={dropdown.shopDetails} />
              </Col>
            </Row>
            <Row>
              <Col sm={"6"}>
                <Button onClick={() => setShow(true)}>Add Product</Button>
              </Col>
              <Col sm={"6"}>
                <Link
                  to={{
                    pathname: "/uploadThroughExcel",
                    search: `?shopId=${userId10Digit}`,
                  }}
                >
                  <Button>New Excel </Button>
                </Link>

                {[
                  "Provisional Store",
                  "Supermarket",
                  "Sweets and Snacks",
                ].includes(dropdown.shopType) && (
                  <Link
                    to={{
                      pathname: "/addInventory",
                      search: `?shopId=${userId10Digit}`,
                    }}
                  >
                    <Button style={{ float: "right" }}>
                      Add New Inventory Products in CSV
                    </Button>
                  </Link>
                )}
                {["Restaurant", "Bakery", "Juice Shop"].includes(
                  dropdown.shopType
                ) && (
                  <Link
                    to={{
                      pathname: "/gpbs",
                      search: `?shopId=${userId10Digit}`,
                    }}
                  >
                    <Button style={{ float: "right" }}>
                      Add New Inventory Products through image
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>
            <div className="vertical-space-30"></div>
            <Row>
              <Col sm="10"> </Col>
              <Col sm="2">
                <Form.Label>Filter By Status</Form.Label>
                <Form.Control
                  as="select"
                  value={filterBy}
                  onChange={(e) => setFilterBy(e.target.value)}
                >
                  <option value="ported">Ported</option>
                  <option value="live">Live</option>
                  <option value="All">All</option>
                </Form.Control>
              </Col>
            </Row>
            <div className="vertical-space-30"></div>
            <Row>
              <Col sm="12">
                <MaterialTable
                  title="Product List"
                  columns={
                    columns
                    //  [ {
                    //     title: "Group",
                    //     field: "group",
                    //     editable: "never",
                    //     hidden: ![
                    //       "Provisional Store",
                    //       "Supermarket",
                    //       "Sweets and Snacks",
                    //     ].includes(dropdown.shopDetails["shopType"]),
                    //   },
                    //   {
                    //     title: "Category",
                    //     field: "category",
                    //     editable: "never",
                    //     hidden: dropdown.shopDetails["shopType"] == "Restaurant",
                    //   },
                    //   {
                    //     title: "Sub Category",
                    //     field: "subCategory",
                    //     editable: "never",
                    //   },
                    //   {
                    //     title: " Inventory Quantity",
                    //     field: "inventoryQty",
                    //     hidden: ![
                    //       "Provisional Store",
                    //       "Supermarket",
                    //       "Sweets and Snacks",
                    //     ].includes(dropdown.shopDetails["shopType"]),
                    //   },
                    //   {
                    //     title: "Selling Price ₹",
                    //     field: "productPrice",
                    //     type: "numeric",
                    //     validate: (rowData) => rowData.productPrice > 0,
                    //   },
                    //   {
                    //     title: "Brand",
                    //     field: "productBrand",
                    //     hidden: dropdown.shopDetails["shopType"] == "Restaurant",
                    //   },
                    //   {
                    //     title: "Product Name",
                    //     field: "productName",
                    //     validate: (rowData) =>
                    //       rowData.productName === ""
                    //         ? "Product Name must have 3 chars"
                    //         : true,
                    //   },
                    //   {
                    //     title: "Product Type",
                    //     field: "productType",
                    //     hidden: [
                    //       "Provisional Store",
                    //       "Supermarket",
                    //       "Sweets and Snacks",
                    //     ].includes(dropdown.shopDetails["shopType"]),
                    //     editComponent: (props) => (
                    //       <select
                    //         type="text"
                    //         value={props.value}
                    //         onChange={(e) => props.onChange(e.target.value)}
                    //       >
                    //         <select value="">Select Product Type</select>
                    //         <option value="product">Product</option>
                    //         <option value="service">Service</option>
                    //       </select>
                    //     ),
                    //   },
                    //   {
                    //     title: "Measurement value",
                    //     field: "measurementValue",
                    //     hidden: ![
                    //       "Provisional Store",
                    //       "Supermarket",
                    //       "Sweets and Snacks",
                    //     ].includes(dropdown.shopDetails["shopType"]),
                    //     render: (rowData) => {
                    //       let measurementValue = "NA";
                    //       if (typeof rowData.unitOfMeasurment != "undefined") {
                    //         let tempValue = rowData.unitOfMeasurment.split(" ");
                    //         measurementValue = tempValue[0];
                    //       }
                    //       return <td>{measurementValue}</td>;
                    //     },
                    //   },
                    //   {
                    //     title: "Measurement Unit",
                    //     field: "measurementUnit",
                    //     hidden: ![
                    //       "Provisional Store",
                    //       "Supermarket",
                    //       "Sweets and Snacks",
                    //     ].includes(dropdown.shopDetails["shopType"]),
                    //     render: (rowData) => {
                    //       let measurementUnit = "NA";
                    //       if (typeof rowData.unitOfMeasurment != "undefined") {
                    //         const tempValue = rowData.unitOfMeasurment.split(" ");
                    //         measurementUnit = tempValue[1];
                    //       }
                    //       return <td>{measurementUnit}</td>;
                    //     },
                    //     editComponent: (props) => {
                    //       const measurementUnit =
                    //         props.rowData.unitOfMeasurment.split(" ") || "NA";
                    //       return (
                    //         <select
                    //           name="measurementUnit"
                    //           value={props.value || measurementUnit[1]}
                    //           onChange={(e) => props.onChange(e.target.value)}
                    //         >
                    //           <select value="">Select Unit</select>
                    //           {dropdown.unitList}
                    //         </select>
                    //       );
                    //     },
                    //   },
                    //   {
                    //     title: "Product Quantity ",
                    //     field: "productQty",
                    //     hidden: ![
                    //       "Provisional Store",
                    //       "Supermarket",
                    //       "Sweets and Snacks",
                    //     ].includes(dropdown.shopDetails["shopType"]),
                    //   },
                    //   {
                    //     title: "Status",
                    //     field: "status",
                    //     editable: "never",
                    //   },
                    //   {
                    //     title: "MRP ₹",
                    //     field: "productMrp",
                    //     hidden: ![
                    //       "Provisional Store",
                    //       "Supermarket",
                    //       "Sweets and Snacks",
                    //     ].includes(dropdown.shopDetails["shopType"]),
                    //   },
                    //   {
                    //     title: "Latest",
                    //     field: "latest",
                    //     editable: "never",
                    //   },
                    //   {
                    //     title: "Veg/ Non Veg",
                    //     field: "vegNonVeg",
                    //     editable: "always",
                    //     hidden: ![
                    //       "Provisional Store",
                    //       "Supermarket",
                    //       "Sweets and Snacks",
                    //     ].includes(dropdown.shopDetails["shopType"]),
                    //     editComponent: (props) => (
                    //       <select
                    //         type="text"
                    //         value={props.value}
                    //         onChange={(e) => props.onChange(e.target.value)}
                    //       >
                    //         <option value="Veg">Vegeterian</option>
                    //         <option value="Non-Veg">Non Vegterian</option>
                    //       </select>
                    //     ),
                    //   },
                    //   {
                    //     title: "Product ID",
                    //     field: "productId",
                    //     editable: "never",
                    //   },
                    //   {
                    //     title: "Date",
                    //     field: "addedDate",
                    //     editable: "never",
                    //   },
                    //   {
                    //     title: "Ean Code",
                    //     field: "productEANCode",
                    //     editable: "always",
                    //   },
                    // ]
                  }
                  data={filteredProducts}
                  isLoading={dropdown.tableLoading}
                  options={{
                    pageSize: 20,
                    pageSizeOptions: [5, 10, 20, 100, 300, 500],
                    grouping: false,
                    filtering: false,
                    maxBodyHeight: 800,
                  }}
                  editable={{
                    isEditable: (rowData) => rowData.productId,

                    isDeletable: (rowData) => rowData.productId, // only group(b) rows would be deletable
                    // on edit changes
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          if (typeof newData.measurementUnit === undefined) {
                            let temp = newData.unitOfMeasurment.split(" ");

                            newData.measurementUnit = temp[1] || "g";
                          }
                          if (typeof newData.measurementValue === undefined) {
                            let temp = newData.unitOfMeasurment.split(" ");
                            newData.measurementValue = temp[0];
                          }
                          updateProduct(newData);

                          resolve();
                        }, 1000);
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          deleteProduct(oldData.productId);
                          resolve();
                        }, 1000);
                      }),
                  }}
                />
              </Col>
            </Row>
          </>
        )}

        <Modal
          show={show}
          onHide={() => handleClose()}
          size="lg"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Add Product
            </Modal.Title>
          </Modal.Header>

          <Form onSubmit={validateInput}>
            <Modal.Body>
              {[
                "Provisional Store",
                "Supermarket",
                "Sweets and Snacks",
              ].includes(dropdown.shopType) && (
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Group
                  </Form.Label>
                  <Col md={{ span: 9 }}>
                    <Form.Control
                      name="group"
                      value={state.group}
                      disabled={true}
                    ></Form.Control>
                  </Col>
                </Form.Group>
              )}
              {[
                "Provisional Store",
                "Supermarket",
                "Sweets and Snacks",
                "Textile",
              ].includes(dropdown.shopType) && (
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Category
                  </Form.Label>
                  <Col md={{ span: 9 }}>
                    <Form.Control
                      name="category"
                      value={state.category}
                      disabled={true}
                    ></Form.Control>
                  </Col>
                </Form.Group>
              )}
              {["Saloon", "Beauty Parlour", "Mobile"].includes(
                dropdown.shopType
              ) && (
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Category
                  </Form.Label>
                  <Col md={{ span: 9 }}>
                    <Form.Control
                      as="select"
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      name="category"
                      value={state.category}
                    >
                      <option>category</option>
                      {dropdown.categoryList}
                    </Form.Control>
                  </Col>
                </Form.Group>
              )}
              {["Juice Shop", "Restaurant", "Bakery"].includes(
                dropdown.shopType
              ) && (
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Sub Category
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        as="select"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        name="subCategory"
                        value={state.subCategory}
                      >
                        <option>Select Sub category</option>
                        {dropdown.subCategoryList}
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Product Quantity
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        name="productQty"
                        pattern="^[0-9]{0,30}"
                        required
                        value={state.productQty}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                </>
              )}
              {[
                "Provisional Store",
                "Supermarket",
                "Sweets and Snacks",
                "Textile",
              ].includes(dropdown.shopType) && (
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Sub Category
                  </Form.Label>
                  <Col md={{ span: 9 }}>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        let value = e.target.value;

                        let getCategoryValue =
                          dropdown.allProductBySegment.find(
                            (elements) => elements.subCategory === value
                          );
                        if (Object.keys(getCategoryValue).length > 0) {
                          setState((prevState) => ({
                            ...prevState,

                            subCategory: value,
                            category: getCategoryValue.category,
                            group: getCategoryValue.group,
                          }));
                        } else {
                          setState((prevState) => ({
                            ...prevState,

                            subCategory: null,
                            category: null,
                            group: null,
                          }));
                        }
                      }}
                      name="subCategory"
                      value={state.subCategory}
                    >
                      <option>Select Sub category</option>
                      {dropdown.subCategoryList}
                    </Form.Control>
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Product Name
                </Form.Label>
                <Col md={{ span: 9 }}>
                  <Form.Control
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    name="productName"
                    required
                    value={state.productName}
                  ></Form.Control>
                </Col>
              </Form.Group>
              {![
                "Provisional Store",
                "Supermarket",
                "Sweets and Snacks",
              ].includes(dropdown.shopType) && (
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Product Type
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        as="select"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        name="productType"
                        value={state.productType}
                      >
                        <option>Select Product Type</option>
                        <option value="product">Product</option>
                        <option value="service">Service</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </>
              )}
              {["Saloon", "Beauty Parlour"].includes(dropdown.shopType) && (
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Duration
                  </Form.Label>
                  <Col md={{ span: 9 }}>
                    <Form.Control
                      as="select"
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      name="duration"
                      value={state.duration}
                    >
                      <option>Select Duration</option>
                      {dropdown.durationList}
                    </Form.Control>
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Product Brand
                </Form.Label>
                <Col md={{ span: 9 }}>
                  <Form.Control
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    name="productBrand"
                    required
                    value={state.productBrand}
                  ></Form.Control>
                </Col>
              </Form.Group>
              {[
                "Provisional Store",
                "Supermarket",
                "Sweets and Snacks",
              ].includes(dropdown.shopType) && (
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    MRP (₹)
                  </Form.Label>
                  <Col md={{ span: 9 }}>
                    <Form.Control
                      type="text"
                      pattern="^[0-9]{0,30}"
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      name="productMrp"
                      required
                      value={state.productMrp}
                    ></Form.Control>
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Selling Price (₹)
                </Form.Label>
                <Col md={{ span: 9 }}>
                  <Form.Control
                    type="text"
                    pattern="^[0-9]{0,30}"
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    name="productPrice"
                    required
                    value={state.productPrice}
                  ></Form.Control>
                </Col>
              </Form.Group>
              {[
                "Provisional Store",
                "Supermarket",
                "Sweets and Snacks",
              ].includes(dropdown.shopType) && (
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Unit Of Measurement{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Col md={{ span: 4 }}>
                      <Form.Control
                        type="text"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        name="unitOfMeasurmentValue"
                        required
                        value={state.unitOfMeasurmentValue}
                      ></Form.Control>
                    </Col>
                    <Col md={{ span: 4 }}>
                      <Form.Control
                        as="select"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        name="unitOfMeasurmentUnit"
                        value={state.unitOfMeasurmentUnit}
                        required
                      >
                        {dropdown.unitList}
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Inventory Quantity
                    </Form.Label>
                    <Col md={{ span: 9 }}>
                      <Form.Control
                        type="text"
                        pattern="^[0-9]{0,30}"
                        onChange={(e) =>
                          onChange(e.target.name, e.target.value)
                        }
                        name="inventoryQty"
                        required
                        value={state.inventoryQty}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                </>
              )}
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Product EANCode
                </Form.Label>
                <Col md={{ span: 9 }}>
                  <Form.Control
                    type="text"
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    name="productEANCode"
                    value={state.productEANCode}
                  ></Form.Control>
                </Col>
              </Form.Group>
              {[
                "Provisional Store",
                "Supermarket",
                "Sweets and Snacks",
                "Juice Shop",
                "Restaurant",
                "Bakery",
              ].includes(dropdown.shopType) && (
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Food Type
                  </Form.Label>
                  <Col md={{ span: 9 }}>
                    <Form.Control
                      as="select"
                      required
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      name="vegNonVeg"
                      value={state.vegNonVeg}
                    >
                      <option value="">Select Veg Or Non Veg</option>
                      <option value="Veg">Vegeterian</option>
                      <option value="Non-Veg">Non Vegterian</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit"> Add Product</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </Container>
  );
}
