import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Row, Col, Modal } from "react-bootstrap";
import S3 from "react-aws-s3";
import uuid from "uuid/dist/v4";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import config from "../../config";

const OurGallery = (props) => {
  const [websiteTemplate, setWebsiteTemplate] = useState();
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageBuffer, setImageBuffer] = useState();

  const [active, setActive] = useState(true);
  const [templateId, setTemplateId] = useState("ourGallery_001");
  const [templateName, setTemplateName] = useState("Compact view");

  const ref = useRef();
  const handleAdd = async () => {
    if (!imageBuffer) return alert("Please choose an image");
    setLoading(true);
    var data = await handleImageUpload(imageBuffer);
    let imageLink = `https://ghoshak-website-builder.s3-ap-south-1.amazonaws.com/${data.key}`;
    var dataObj = {};
    if (websiteTemplate) {
      dataObj = {
        ...websiteTemplate,
      };
      if (websiteTemplate.ourGallery) {
        dataObj = {
          ...dataObj,
          ourGallery: {
            ...websiteTemplate.ourGallery,
            active: active,
            templateId: templateId,
            templateName: templateName,
            pageProps: {
              cardData: [
                ...gallery,
                {
                  id: uuid(),
                  heading: "",
                  description: "",
                  imageUrl: imageLink,
                  imgName: data.key,
                  imageUri: imageLink,
                },
              ],
            },
          },
        };
      } else {
        dataObj = {
          ...dataObj,
          ourGallery: {
            active: active,
            templateId: templateId,
            templateName: templateName,
            pageProps: {
              cardData: [
                ...gallery,
                {
                  id: uuid(),
                  heading: "",
                  description: "",
                  imageUrl: imageLink,
                  imgName: data.key,
                  imageUri: imageLink,
                },
              ],
            },
          },
        };
      }
    } else {
      dataObj = {
        colorTheme: "default",
        order: [
          "aboutUs",
          "about",
          "onlineProduct",
          "ourServices",
          "ourProjects",
          "ourBrands",
          "ourClients",
          "ourGallery",
          "clientTestimonials",
          "videoGallery",
          "socialMedia",
          "ourPartner",
          "ourTeam",
        ],
        ourGallery: {
          active: active,
          templateId: templateId,
          templateName: templateName,
          pageProps: {
            cardData: [
              ...gallery,
              {
                id: uuid(),
                heading: "",
                description: "",
                imageUrl: imageLink,
                imgName: data.key,
                imageUri: imageLink,
              },
            ],
          },
        },
      };
    }
    await contentupload(dataObj, props.shopId).then((data) => {
      setLoading(false);
      setImageBuffer();
      fetchDetails();
      ref.current.value = "";
    });
  };
  const fetchDetails = async () => {
    await fetchUserDetails(props.shopId).then((data) => {
      var shopData = data[0];
      if (shopData.websiteTemplate) {
        setWebsiteTemplate(shopData.websiteTemplate);
        var websiteData = shopData.websiteTemplate;
        if (websiteData.ourGallery) {
          setActive(
            websiteData.ourGallery.active
              ? websiteData.ourGallery.active
              : active
          );
          setTemplateId(
            websiteData.ourGallery.templateId
              ? websiteData.ourGallery.templateId
              : templateId
          );
          setTemplateName(
            websiteData.ourGallery.templateName
              ? websiteData.ourGallery.templateName
              : templateName
          );
          if (websiteData.ourGallery.pageProps.cardData) {
            if (websiteData.ourGallery.pageProps.cardData.length > 0) {
              setGallery(websiteData.ourGallery.pageProps.cardData);
            }
          }
        }
      }
    });
  };
  useEffect(() => {
    fetchDetails();
  }, [props]);

  const handleDelete = async (key) => {
    var filteredGallery = gallery.filter((data) => data.id !== key.id);
    var dataObj = {
      ...websiteTemplate,
      ourGallery: {
        ...websiteTemplate.ourGallery,
        pageProps: {
          cardData: [...filteredGallery],
        },
      },
    };
    await contentupload(dataObj, props.shopId).then(async (data) => {
      setGallery([]);
      await fetchDetails();
    });
  };

  const handleActiveTemplateChange = async (t, n, a) => {
    var updateObj = {
      ...websiteTemplate,
      ourGallery: {
        ...websiteTemplate.ourGallery,
        active: a,
        templateId: t,
        templateName: n,
        pageProps: {
          cardData: [...gallery],
        },
      },
    };
    await contentupload(updateObj, props.shopId).then(async () => {
      fetchDetails();
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <h4>Our Gallery</h4>
      {gallery.length > 0 && (
        <>
          <div>
            <strong>
              <label>Show Our Gallery</label>
            </strong>{" "}
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={() => {
                    setActive(!active);
                  }}
                  color="primary"
                  value={active}
                />
              }
            ></FormControlLabel>
          </div>
          <div>
            <strong>
              <label>Choose Template</label>
            </strong>
            <br />
            <select
              onChange={(e) => {
                setTemplateId(e.target.value);
                setTemplateName(e.target.name);
              }}
              value={templateId}
              style={{
                width: "200px",
              }}
            >
              <option value="" disabled>
                Select a template
              </option>
              <option value="ourGallery_001" name="Compact view">
                Compact view
              </option>
              <option value="ourGallery_002" name="Classic View">
                Classic View
              </option>
              <option value="ourGallery_003" name="Modern Pack">
                Modern Pack
              </option>
            </select>{" "}
          </div>
          <br />
          <Button
            onClick={() => {
              handleActiveTemplateChange(templateId, templateName, active);
            }}
          >
            Save
          </Button>
        </>
      )}
      <br />
      <input
        ref={ref}
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        onChange={(e) => {
          setImageBuffer(e.target.files[0]);
        }}
      />
      <Button disabled={loading} onClick={handleAdd}>
        Upload
      </Button>
      {gallery.length > 0 && (
        <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
          {gallery.map((data) => {
            return (
              <div
                key={data.id}
                style={{
                  margin: "10px",
                  width: "30%",
                  boxShadow: "5px 5px 20px 1px #d4d4d4",
                  borderRadius: "5px",
                }}
              >
                <img src={data.imageUrl} alt="Image" width="100" height="100" />
                <Button
                  variant="outline-danger"
                  size="sm"
                  style={{ float: "right", margin: "10px" }}
                  onClick={() => handleDelete(data)}
                >
                  Delete
                </Button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const fetchUserDetails = async (shopId) => {
  if (shopId === "") return alert("Enter valid Shop Id");
  return await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
      method: "POST",
      body: JSON.stringify({
        userId10Digit: shopId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data[0].websiteTemplate);
      return data;
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("Something went wrong, please try again");
    });
};

const contentupload = async (obj, userId) => {
  console.log("before update", obj);
  await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainContentUpload`, {
      method: "POST",
      body: JSON.stringify({
        obj: obj,
        userId: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data);
      alert("uploaded success");
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("error while uploading data. Please try again");
    });
};

const handleImageUpload = async (image) => {
  const configSettings = {
    bucketName: "ghoshak-website-builder",
    region: "ap-south-1",
    accessKeyId: config.AccessKeyId,
    secretAccessKey: config.SecretAccessKey,
  };
  var fileName = uuid() + ".jpeg";
  var S3Client = new S3(configSettings);
  let data = await S3Client.uploadFile(image, fileName)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("S3 Error", err);
    });
  return data;
};

export default OurGallery;
