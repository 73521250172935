// let stage = "dev";
let stage = "prod";
let serverPointing = {
  dev: {
    cognito: {
      //production
      REGION: "ap-south-1",
      USER_POOL_ID: "ap-south-1_Khw3RPRAC",
      USERPOOL_WEBCLIENT_ID: "7pbabcv09f9l5mukl16rl7nic2",
    },
    json: {
      URL: "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/json-files",
    },
    ApiGateway: "https://vkbmrh8wc3.execute-api.ap-south-1.amazonaws.com/dev",
    coreBackendGateway:
      "https://oq3418l4jh.execute-api.ap-south-1.amazonaws.com/dev/lambda",
    coreBackendApiKey: "n75esSYyXz2wQREs6zlHb2iELMATYvIG2MR90Vjr",
    POSApi: "https://2z4hriwfak.execute-api.ap-south-1.amazonaws.com/dev",
    OnlineStoreApi:
      "https://g4yon8s2d0.execute-api.ap-south-1.amazonaws.com/dev",
    Elasticsearch: {
      URL: "https://search-dev-ghoshak-f46izmmagrcg2qa6pfg2dlvuim.ap-south-1.es.amazonaws.com",
    },
    AccessKeyId: "AKIA3V2QXK2UIWAVYLOL",
    SecretAccessKey: "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",
    CRMExcelTemplate:
      "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/EXCEL_TEMPLATE_CRM.xlsx",
    MarketingCampaigns:
      "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/marketingCampaigns/marketingCampaignsDev.json",
    MCFileName: "marketingCampaignsDev",
  },
  prod: {
    cognito: {
      //production
      REGION: "ap-south-1",
      USER_POOL_ID: "ap-south-1_Khw3RPRAC",
      USERPOOL_WEBCLIENT_ID: "7pbabcv09f9l5mukl16rl7nic2",
    },
    json: {
      URL: "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/json-files",
    },
    ApiGateway: "https://uvx5horwme.execute-api.ap-south-1.amazonaws.com/prod",
    coreBackendGateway:
      "https://ghh4h3vwg2.execute-api.ap-south-1.amazonaws.com/prod/lambda",
    coreBackendApiKey: "It9ON6uGcW6KyTU3i4NYu8A1EOCujMkyonukSHy6",
    POSApi: "https://pgtj1fkue6.execute-api.ap-south-1.amazonaws.com/prod",
    OnlineStoreApi:
      " https://k6p5lb2w2c.execute-api.ap-south-1.amazonaws.com/prod",
    Elasticsearch: {
      URL: "https://search-prod-ghoshak-4wpot673rcsasinszx6gk4mdee.ap-south-1.es.amazonaws.com",
    },
    AccessKeyId: "AKIA3V2QXK2UIWAVYLOL",
    SecretAccessKey: "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",
    CRMExcelTemplate:
      "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/EXCEL_TEMPLATE_CRM.xlsx",
    MarketingCampaigns:
      "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/marketingCampaigns/marketingCampaigns.json",
    MCFileName: "marketingCampaigns",
  },
};
export default serverPointing[stage];
