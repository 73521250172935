const fields = {
  couponGivenBy: {
    label: "Provided By",
    name: "couponGivenBy",
    type: "select",
    require: true,
    options: [
      { label: "Ghoshak", value: "Ghoshak" },
      { label: "Merchant", value: "Merchant" },
    ],
  },
  promocode: {
    label: "Coupon Code",
    type: "text",
    name: "promocode",
    require: true,
    // condition : couponGivenBy == ""
  },
  discountType: {
    label: "Discount Type",
    type: "text",
    name: "discountType",
    require: true,
    options: [
      { label: "Ghoshak", value: "flat" },
      { label: "Merchant", value: "percentage" },
      { label: "Product", value: "product" },
    ],
  },
  maximumDiscount: {
    label: "Maximum Discount",
    type: "text",
    name: "maximumDiscount",
    require: true,
    pattern: "[0-9]{1,}",
    // condition : couponGivenBy == ""
  },
  discountAmount: {
    label: "Discount Amount",
    type: "text",
    name: "discountAmount",
    require: true,
    pattern: "[0-9]{1,}",
  },
  default: {
    label: "Apply Default",
    name: "default",
    type: "select",
    require: true,
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
  },
  hidden: {
    label: "Hidden",
    name: "hidden",
    type: "select",
    require: true,
    options: [
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ],
  },
  expiryDate: {
    label: "Expiry Date",
    type: "date",
    name: "expiryDate",
    require: true,
  },
  minPrice: {
    label: "Minimum Price",
    type: "text",
    name: "minPrice",
    require: true,
    pattern: "[0-9]{1,}",
  },
  conditionType: {
    label: "Condition Type",
    type: "select",
    name: "conditionType",
    require: true,
    options: [
      { label: "mTh & NTh Time", value: "mThAndnTh" },
      { label: "Once", value: "once" },
      { label: "Unique", value: "userOnce" },
    ],
  },
  totalCoupons: {
    label: "Coupon Count",
    type: "text",
    name: "totalCoupons",
    require: true,
    pattern: "[0-9]{1,}",
  },
};

// Online order coupon

export const OnlineGhoshakCoupons = [
  fields.couponGivenBy,
  fields.promocode,
  fields.discountType,
  fields.maximumDiscount,
  fields.discountAmount,
  fields.default,
  fields.hidden,
  fields.expiryDate,
  fields.minPrice,
  fields.conditionType,
  fields.totalCoupons,
];
