import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import MaterialTable from "material-table";
import Handler from "./handler/handler";
import CloseIcon from "@material-ui/icons/Close";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { CouponById } from "./handler/apiHandler";
const sampleImage =
  "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/images/offers/default_offer_image.png";

const initialState = {
  couponType: "ORDER-PROMO-CODE",
  default: "true",
  couponGivenBy: "Ghoshak",
  conditionType: "mThAndnTh",
  hidden: "false",
  userId10Digit: "",
};
export default function Coupon({ profile, classes, crmUser, dateRange, days }) {
  let fileUploadHandler = useRef(); // Create Ref for File input html
  const [findHandler] = Handler(); // Handler class
  const [modalState, setModalState] = useState(false); // Modal State
  const [state, setState] = useState(initialState);
  const [isValidPromocode, setIsValidPromocode] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [imageUrl, setImageUrl] = useState({
    selectedImage: null,
    fileName: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function loadData() {
      if (Boolean(profile.userId) && Boolean(dateRange)) {
        setIsLoading(true);
        await getCouponAPI();
      }
    }

    loadData();
  }, [profile]);
  const getCouponAPI = async () => {
    // Get Coupon by Shop Id

    return await new Promise(async (resolve, reject) => {
      try {
        await CouponById({
          shopId: profile.userId,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          days: days,
        }).then((data) => {
          console.log(data, "couppons");
          if (data.status) {
            setIsLoading(false);
            setCouponList(data.data.coupons);
            return resolve();
          } else {
            reject();
          }
        });
      } catch (err) {
        alert("data fetching failed ");
        reject();
      }
    });
  };

  const handleChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const closeModal = () => {
    // close modal and reset to initial state

    setModalState(false);
    setState(initialState);
  };

  const validatePromocode = () => {
    setIsValidPromocode(true);
  };
  const createCoupon = () => {
    alert("coupons created");
  };
  return (
    <>
      <div className="vertical-space-10"></div>

      <div className="shopCardRow">
        <div className="shopCardHeader">
          <p>Coupon</p>
        </div>
        <div className="customDateRangePickerContainer">
          <button
            onClick={() => setModalState(true)}
            id="success"
            className="actionButton"
          >
            Create Coupon
          </button>
        </div>
      </div>

      {isLoading ? null : (
        <>
          <div className="shopCardRow">
            <div className="shopCardColumn">
              <div className="wrapTable">
                <MaterialTable
                  title="Invoice"
                  options={{
                    search: true,
                    headerStyle: { position: "sticky", top: 0 },
                    maxBodyHeight: "600px",
                    exportButton: {
                      pdf: false,
                    },
                  }}
                  actions={[
                    {
                      icon: "delete",
                      tooltip: "Delete Offer",
                      onClick: (event, rowData) => {
                        alert("coupon deleted");
                      },
                    },
                    {
                      icon: "edit",
                      tooltip: "edit Offer",
                      onClick: (event, rowData) => {
                        // let input = {};
                        // setEditState(true);
                        // Object.keys(rowData).forEach((elements) => {
                        //   input[elements] = rowData[elements];
                        // });
                        // setModalState(true);
                        // setState(input);
                      },
                    },
                  ]}
                  columns={[
                    {
                      title: "Promocode",
                      field: "promocode",
                    },
                    {
                      title: "No of Coupon",
                      field: "totalCouponCount",

                      render: (rowData) =>
                        parseInt(rowData.totalCouponCount) ||
                        parseInt(rowData.promocodeCount) ||
                        0,
                    },
                    {
                      title: "Allocated Cost",
                      field: "allocatedCost",

                      render: (rowData) =>
                        `₹ ${
                          parseInt(rowData.maximumCouponAmount) *
                            parseInt(rowData.promocodeCount) ||
                          parseInt(rowData.maximumCouponAmount) *
                            parseInt(rowData.totalCouponCount) ||
                          0
                        }`,
                    },
                    {
                      title: "Consumed Cost",
                      field: "consumedCost",

                      render: (rowData) =>
                        `₹ ${
                          (parseInt(rowData.promocodeCount) -
                            parseInt(rowData.promocodeRemainingCount)) *
                            parseInt(rowData.maximumCouponAmount) ||
                          (parseInt(rowData.totalCouponCount) -
                            parseInt(rowData.remainingCouponCount)) *
                            parseInt(rowData.maximumCouponAmount) ||
                          0
                        }`,
                    },

                    {
                      title: "Details",
                      field: "couponDetails",
                    },
                    {
                      title: "Created on",
                      field: "addedDate",
                    },
                    {
                      title: "Edit Coupon",
                      field: "addedDate",
                    },
                  ]}
                  data={couponList}
                  title={"Coupons"}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <Modal
        className="modal-order-coupon"
        show={modalState}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="fieldBox">
              <div
                style={{ flexBasis: "100%", maxWidth: "100%" }}
                className="fieldWrap"
              >
                <label>Coupon Type</label>
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check
                    inline
                    onChange={(e) => handleChange(e.target.name, "GHOSHAK")}
                    label="Ghoshak"
                    value="GHOSHAK"
                    name="couponType"
                    checked={state.couponType === "GHOSHAK"}
                    type="radio"
                    id={`inline-radio-1`}
                  />
                  <Form.Check
                    inline
                    onChange={(e) => handleChange(e.target.name, "MERCHANT")}
                    label="Merchant"
                    name="couponType"
                    checked={state.couponType === "MERCHANT"}
                    type="radio"
                    id={`inline-radio-2`}
                  />
                  <Form.Check
                    inline
                    onChange={(e) => handleChange(e.target.name, "LOYALTY")}
                    label="Loyalty"
                    name="couponType"
                    type="radio"
                    id={`inline-radio-2`}
                    checked={state.couponType === "LOYALTY"}
                  />
                  <Form.Check
                    inline
                    onChange={(e) => handleChange(e.target.name, "PROMOCODE")}
                    label="Promocode"
                    name="couponType"
                    type="radio"
                    id={`inline-radio-2`}
                    checked={state.couponType === "PROMOCODE"}
                  />
                  <Form.Check
                    inline
                    onChange={(e) =>
                      handleChange(e.target.name, "ORDER-PROMO-CODE")
                    }
                    label="Online Order"
                    name="couponType"
                    type="radio"
                    id={`inline-radio-2`}
                    checked={state.couponType === "ORDER-PROMO-CODE"}
                  />
                </div>
              </div>

              {state.couponType == "ORDER-PROMO-CODE" && (
                <>
                  <div id="twoCol" className="fieldWrap">
                    <label> Coupon Provided By</label>
                    <select
                      type="time"
                      className={"flexInputBox"}
                      value={state.couponGivenBy}
                      onChange={(e) =>
                        handleChange("couponGivenBy", e.target.value)
                      }
                    >
                      <option value="Ghoshak">Ghoshak</option>
                      <option value="Merchant">Merchant</option>
                    </select>
                  </div>
                  <div id="twoCol" className="fieldWrap">
                    <label> Apply Default</label>
                    <select
                      type="time"
                      className={"flexInputBox"}
                      value={state.default}
                      onChange={(e) => handleChange("default", e.target.value)}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>{" "}
                    </select>
                  </div>

                  <div id="twoCol" className="fieldWrap">
                    <label id="required"> Hidden</label>
                    <select
                      name="hidden"
                      required
                      className={"flexInputBox"}
                      value={state.hidden}
                      onChange={(e) => handleChange("hidden", e.target.value)}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>

                  {state.discountType == "percentage" && (
                    <div id="twoCol" className="fieldWrap">
                      <label id="required">Maximum Discount</label>
                      <input
                        pattern="[0-9]{1,}"
                        title="only numbers allowed"
                        required
                        className={"flexInputBox"}
                        onChange={(e) =>
                          handleChange("maximumDiscount", e.target.value)
                        }
                        value={state.maximumDiscount}
                      ></input>
                    </div>
                  )}

                  <div id="twoCol" className="fieldWrap">
                    <label id="required">Discount Amount</label>
                    <input
                      type="date"
                      className={"flexInputBox"}
                      pattern="[0-9]{1,}"
                      onChange={(e) =>
                        handleChange("discountAmount", e.target.value)
                      }
                      value={state.discountAmount}
                      required
                    ></input>
                  </div>
                  <div id="twoCol" className="fieldWrap">
                    <label id="required">Minimum Price</label>
                    <input
                      className={"flexInputBox"}
                      pattern="[0-9]{1,}"
                      onChange={(e) =>
                        handleChange("discountAmount", e.target.value)
                      }
                      value={state.discountAmount}
                      required
                    ></input>
                  </div>
                  <div id="twoCol" className="fieldWrap">
                    <label id="required"> Condition Type</label>
                    <select
                      className={"flexInputBox"}
                      value={state.conditionType}
                      onChange={(e) =>
                        handleChange("conditionType", e.target.value)
                      }
                      required
                    >
                      <option value="mThAndnTh"> mTh & NTh Time</option>
                      <option value="once"> Once</option>
                      <option value="userOnce"> Unique</option>
                      <option value="perCustomerClaim">
                        Per Customer Claim
                      </option>
                    </select>
                  </div>
                </>
              )}

              {["LOYALTY", "PROMOCODE", "GHOSHAK", "MERCHANT"].includes(
                state.couponType
              ) && (
                <>
                  {state.couponType == "GHOSHAK" && (
                    <>
                      <div id="twoCol" className="fieldWrap">
                        <label id="required">Claim Date</label>
                        <input
                          type="date"
                          className={"flexInputBox"}
                          onChange={(e) =>
                            handleChange("claimExpiryDate", e.target.value)
                          }
                          required
                          value={state.claimExpiryDate}
                          min={new Date().toISOString().substring(0, 10)}
                        ></input>
                      </div>
                      <div id="twoCol" className="fieldWrap">
                        <label id="required">
                          Points Required (Ghoshak Points)
                        </label>
                        <input
                          required
                          pattern="[0-9]{1,}"
                          name="minGhoshakPointsReq"
                          onChange={(e) =>
                            handleChange("minGhoshakPointsReq", e.target.value)
                          }
                          className={"flexInputBox"}
                          value={state.minGhoshakPointsReq}
                        ></input>
                      </div>
                    </>
                  )}

                  {state.couponType == "LOYALTY" && (
                    <>
                      <div id="twoCol" className="fieldWrap">
                        <label id="required">
                          Points Required (Loyalty Points)
                        </label>
                        <input
                          pattern="[0-9]{1,}"
                          name="minLoyaltyPointsReq"
                          className={"flexInputBox"}
                          onChange={(e) =>
                            handleChange("minLoyaltyPointsReq", e.target.value)
                          }
                          value={state.minLoyaltyPointsReq}
                          required
                        ></input>
                      </div>
                    </>
                  )}

                  <div id="twoCol" className="fieldWrap">
                    <label id="required">Maximum Coupon Amount(₹)</label>
                    <input
                      className={"flexInputBox"}
                      pattern="[0-9]{1,}"
                      name="maximumCouponAmount"
                      title="only numbers allowed"
                      onChange={(e) =>
                        handleChange("maximumCouponAmount", e.target.value)
                      }
                      value={state.maximumCouponAmount}
                      required
                    ></input>
                  </div>

                  <div style={{ flexBasis: "100%" }} className="fieldWrap">
                    <label id="required">Coupon Details</label>
                    <textarea
                      className={"flexInputBox"}
                      name="couponDetails"
                      onChange={(e) =>
                        handleChange("couponDetails", e.target.value)
                      }
                      value={state.couponDetails}
                      required
                    ></textarea>
                  </div>
                </>
              )}

              <div id="twoCol" className="fieldWrap">
                <label id="required">Total Coupon Count</label>
                <input
                  className={"flexInputBox"}
                  value={
                    state.couponType === "PROMOCODE"
                      ? state.promocodeCount
                      : state.totalCouponCount
                  }
                  onChange={(e) =>
                    handleChange(
                      state.couponType === "PROMOCODE"
                        ? "promocodeCount"
                        : "promocodeCount",
                      e.target.value
                    )
                  }
                  pattern="[0-9]{1,}"
                  title="only numbers allowed"
                  required
                ></input>
              </div>
              <div id="twoCol" className="fieldWrap">
                <label id="required">
                  Coupon Valid Till(<small>Expiry Date</small>)
                </label>
                <input
                  type="date"
                  className={"flexInputBox"}
                  onChange={(e) => handleChange("expiryDate", e.target.value)}
                  required
                  value={state.expiryDate}
                  min={new Date().toISOString().substring(0, 10)}
                ></input>
              </div>

              {["PROMOCODE", "ORDER-PROMO-CODE"].includes(state.couponType) && (
                <>
                  {(state.couponType === "ORDER-PROMO-CODE" &&
                    state.couponGivenBy === "Ghoshak") ||
                    (state.couponType === "PROMOCODE" && (
                      <div id="twoCol" className="fieldWrap">
                        <label id="required">Enter Promocode</label>
                        <input
                          className={"flexInputBox"}
                          value={state.promocode}
                          onChange={(e) =>
                            handleChange("promocode", e.target.value)
                          }
                          required
                        ></input>
                      </div>
                    ))}
                </>
              )}
              {state.couponType == "PROMOCODE" && (
                <>
                  <div
                    style={{ alignItems: "flex-end" }}
                    id="fullWidth"
                    className="fieldWrap"
                  ></div>
                  {/* {isValidPromocode && ( */}
                  <>
                    <div id="twoCol" className="fieldWrap">
                      <label>
                        Promocode Valid Till(<small>Expiry Date</small>)
                      </label>
                      <input
                        type="date"
                        onChange={(e) =>
                          handleChange("promocodeExpiryDate", e.target.value)
                        }
                        className={"flexInputBox"}
                        value={state.promocodeExpiryDate}
                        max={state.expiryDate}
                        disabled={!Boolean(state.expiryDate)}
                      ></input>
                    </div>
                  </>
                  {/* )} */}
                </>
              )}

              {Boolean(imageUrl.selectedImage) ? (
                <div style={{ flexBasis: "100%" }} className="centerFlex">
                  <div className="flexBox1">
                    <img src={sampleImage}></img>
                  </div>
                  <div className="flexBox2">
                    <span>{imageUrl.fileName}</span>
                  </div>

                  <div className="flexBox1">
                    <CloseIcon
                      onClick={() => {
                        setImageUrl({
                          selectedImage: null,

                          fileName: "",
                        });
                      }}
                      style={{ color: "#333" }}
                    />
                  </div>
                </div>
              ) : (
                <div id="fullWidth" className="fieldWrap">
                  <div
                    onClick={() => fileUploadHandler.current.click()}
                    className="uploadImageBox"
                  >
                    <span className="centerText">Upload Image</span>
                    <input
                      ref={fileUploadHandler}
                      onChange={async (e) => {
                        let imagePath = await findHandler(
                          "IMAGE_HANDLER",
                          e.target.files[0]
                        );
                        setImageUrl({
                          selectedImage:
                            imagePath.imageURI == "undefined"
                              ? null
                              : imagePath.imageURI,
                          fileName: imagePath.fileName,
                        });
                      }}
                      style={{ display: "none" }}
                      type="file"
                    ></input>
                  </div>
                </div>
              )}
              <div className="flexBoxEnd">
                <div className="updateButton">
                  <button
                    onClick={() => {
                      createCoupon();
                    }}
                    id="success"
                    className="actionButton"
                  >
                    Create Coupon
                  </button>
                </div>
                <div className="updateButton">
                  <button
                    onClick={() => {
                      closeModal();
                      // alert("cancelled");
                    }}
                    id="danger"
                    className="actionButton"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
