import React, { useState, useEffect, useRef } from "react";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { Container, Col, Button, Row, Tabs, Tab, Form } from "react-bootstrap";
import ShopProfilecard from "../template/shopProfile";
import config from "../config";
import CancelIcon from "@material-ui/icons/Cancel";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import ImageIcon from "@material-ui/icons/Image";
export default function GetProductsByScan() {
  let imageRef = useRef();
  const [imageFile, setImageFile] = useState("");
  const [extractLines, setExtractLines] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const history = useHistory();
  const [shopDetails, setShopDetails] = useState({});
  const [shopId, setShopId] = useState("");
  const [errorText, setErrorText] = useState(false);
  let searchParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    async function getShopData() {
      try {
        await Promise.all([
          fetch(
            `${config.ApiGateway}/getCouponLimit`,

            {
              method: "POST",

              body: JSON.stringify({
                userId10Digit: searchParams.get("shopId"),
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          ),
        ])
          .then(([res1]) => Promise.all([res1.json()]))
          .then(([data]) => {
            if (Object.keys(data).length > 0) {
              setShopId(data.shopId);
              setShopDetails(data);
            } else {
              history.push("/inventoryProductsByShop");
              alert("Entered shop id not found");
            }
          })
          .catch((err) => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }
    if (
      searchParams.get("shopId") !== null &&
      searchParams.get("shopId") !== undefined &&
      searchParams.get("shopId") !== ""
    ) {
      getShopData();
    } else {
      history.push("/inventoryProductsByShop");
      alert("Shop ID Not Found");
    }
  }, [searchParams.get("shopId")]);
  useEffect(() => {
    async function getJson() {
      const response = await fetch(`${config.json.URL}/restaurant.json`);
      var productsJson = await response.json();
      let groupDropdown = [];
      let subDropdown = [];
      Object.entries(productsJson).forEach(([key, value]) => {
        Object.values(value).forEach((key1) => {
          groupDropdown.push(<option value={key1.name}>{key1.name}</option>);
          Object.values(key1.category).forEach((key2) => {
            Object.values(key2.subCategory).forEach((key3) => {
              subDropdown.push(<option value={key3}>{key3}</option>);
            });
          });
        });
      });
      setSubCategoryList(subDropdown);
    }
    getJson();
  }, []);
  const imageHandler = (event) => {
    let reader = new FileReader();
    let imageFormData = new FormData();
    imageFormData.append("photo", event.target.files[0]);
    reader.onloadend = () => {
      setImageFile(reader.result);
      getExtractText(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };
  const getExtractText = (data) => {
    fetch("https://kbwoktrw47.execute-api.ap-southeast-1.amazonaws.com/dev/", {
      method: "POST",
      body: JSON.stringify({
        bytes: data.replace(/^data:image\/\w+;base64,/, ""),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        var words = [];
        var lines = [];
        data.Blocks.forEach((item, index) => {
          if (item.BlockType == "WORD") {
            words.push({ id: item.Id, text: item.Text });
          }
          if (item.BlockType == "LINE") {
            lines.push({ id: index, text: item.Text });
          }
        });
        let restProduct = [];
        let concatProduct = "";
        let price = "";
        lines.forEach((elements) => {
          if (/\d/.test(elements.text)) {
            price = elements.text;
          }
          if (/\w+$/.test(elements.text)) {
            concatProduct += elements.text; // concat string value
          }
          if (price.length > 0 && concatProduct.length > 0) {
            restProduct.push({
              group: "RESTAURANT",
              category: "RESTAURANT",

              productName: concatProduct.replace(/[0-9]/g, ""),
              inventoryQty: 1,
              productMrp: price.replace(
                /[a-zA-Z`~!@#$%^₹&*()_|/+\-=?;:'",<>\{\}\[\]\\\ ]/g, // Remve string nd special character
                ""
              ),
              productPrice: price.replace(
                /[a-zA-Z`~!@#$₹%^&*()_|/+\-=?;:'",<>\{\}\[\]\\\ ]/g,
                ""
              ),
              unitOfMeasurment: "NA",
              productQty: "1",
              vegNonVeg: "Veg",
              productBrand: "NA",
              shopId: shopId,
            });
            price = "";
            concatProduct = "";
          }
        });

        setExtractLines(restProduct);
      });
  };
  const PRODUCTS = extractLines.filter(
    (item) => item.productPrice != "" && item.productName !== ""
  );
  const getValidatedData = (data) => {
    return new Promise((resolve) => {
      let errorData = [];
      let checkedData = [];
      data.forEach((elements) => {
        if (
          Boolean(elements.subCategory) &&
          Boolean(elements.productName) &&
          Boolean(elements.productPrice) &&
          Boolean(elements.productQty)
        ) {
          checkedData.push(elements);
        } else {
          errorData.push(elements);
        }
      });

      resolve([checkedData, errorData]);
    });
  };
  const updateProducts = async () => {
    let validated = await getValidatedData(extractLines);
    let validData = validated[0];
    let invalidData = validated[1];
    if (validData.length > 0) {
      Promise.all([
        fetch(`${config.ApiGateway}/addInventoryItem`, {
          method: "POST",
          body: JSON.stringify({ productList: validData }),
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ])
        .then(([res1]) => Promise.all([res1.json()]))
        .then(([data]) => {
          if (invalidData.length > 0) {
            setErrorText(true);
            setExtractLines(invalidData);
          } else {
            setErrorText(false);
            setExtractLines([]);
          }
        })
        .catch((err) => {
          alert("Product Not Updated");
          console.log(err);
        });
    } else {
      setErrorText(true);
      setExtractLines(invalidData);
    }
  };
  return (
    <>
      <Container fluid>
        <div style={{ width: "100%", height: "40px" }}></div>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Get Products By Scan</h2>
        </div>
        <Row style={{ justifyContent: "center" }}>
          <Col sm={"8"}>
            <ShopProfilecard shopInfo={shopDetails} />

            {PRODUCTS.length > 0 && (
              <>
                <div className="alignButtonRight">
                  <div style={{ width: "auto" }}>
                    {errorText && (
                      <span style={{ background: "#fce4e4", color: "#cc0033" }}>
                        Product Upload failed. Please check all data are filled
                      </span>
                    )}
                  </div>
                  <Button onClick={() => updateProducts()}>Upload</Button>
                </div>
                <MaterialTable
                  title="Product List"
                  columns={[
                    {
                      title: "Sub Category",
                      field: "subCategory",
                      editable: "always",
                      validate: (rowData) => Boolean(rowData.subCategory),
                      editComponent: (props) => (
                        <select
                          type="text"
                          style={{
                            padding: "20px 30px",
                            borderRadius: "4px",
                            border: "1px solid #dedede",
                          }}
                          value={props.value}
                          onChange={(e) => props.onChange(e.target.value)}
                        >
                          <option>Select Sub Category</option>
                          {subCategoryList}
                        </select>
                      ),
                    },
                    {
                      title: "Product Description",
                      field: "productName",
                      validate: (rowData) => Boolean(rowData.productName),
                    },

                    {
                      title: "Selling Price ₹",
                      field: "productPrice",
                      validate: (rowData) =>
                        !isNaN(rowData.productPrice) &&
                        rowData.productPrice != "",
                    },
                    {
                      title: "Veg/ Non Veg",
                      field: "vegNonVeg",
                      editable: "always",
                      editComponent: (props) => (
                        <select
                          type="text"
                          style={{
                            padding: "20px 30px",
                            borderRadius: "4px",
                            border: "1px solid #dedede",
                          }}
                          value={props.value}
                          onChange={(e) => props.onChange(e.target.value)}
                        >
                          <option value="Veg">Vegeterian</option>
                          <option value="Non-Veg">Non Vegterian</option>
                        </select>
                      ),
                    },
                  ]}
                  options={{
                    search: false,
                    pageSize: 30,
                    pageSizeOptions: [5, 10, 20, 100, 300, 500],
                    grouping: false,
                    filtering: false,
                    addRowPosition: "first",
                  }}
                  editable={{
                    // only group(b) rows would be deletable
                    onRowAddCancelled: (rowData) =>
                      console.log("Row adding cancelled"),
                    onRowUpdateCancelled: (rowData) =>
                      console.log("Row editing cancelled"),
                    onRowAdd: (newData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          newData.group = "RESTAURANT";
                          newData.category = "RESTAURANT";
                          newData.inventoryQty = 1;
                          newData.productMrp = newData.productPrice;
                          newData.unitOfMeasurment = "NA";
                          newData.productQty = "1";
                          newData.vegNonVeg = "Veg";
                          newData.productBrand = "NA";
                          newData.shopId = shopId;
                          setExtractLines([...extractLines, newData]);

                          resolve();
                        }, 1000);
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          const dataUpdate = [...extractLines];
                          const index = oldData.tableData.id;
                          dataUpdate[index] = newData;
                          setExtractLines([...dataUpdate]);
                          resolve();
                        }, 600);
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          const dataDelete = [...extractLines];
                          const index = oldData.tableData.id;
                          dataDelete.splice(index, 1);
                          setExtractLines([...dataDelete]);

                          resolve();
                        }, 1000);
                      }),
                  }}
                  data={PRODUCTS}
                />
              </>
            )}
          </Col>
          <Col sm={"3"}>
            <div className="scanImageContainer">
              <>
                {imageFile != "" ? (
                  <>
                    <img src={imageFile}></img>
                    <div
                      onClick={() => {
                        setImageFile("");
                        setExtractLines([]);
                      }}
                      className="cancelImage"
                    >
                      <CancelIcon style={{ fontSize: "20px", color: "red" }} />
                    </div>
                  </>
                ) : (
                  <>
                    <span onClick={() => imageRef.current.click()}>
                      <ImageIcon style={{ fontSize: "40px" }}></ImageIcon>
                    </span>
                    <input
                      ref={imageRef}
                      onChange={(e) => imageHandler(e)}
                      style={{ display: "none" }}
                      type={"file"}
                      disabled={!Boolean(shopId)}
                    />
                  </>
                )}
              </>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
