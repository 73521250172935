import React, { Component } from "react";
import { Row, Col, Button, Container, Form } from "react-bootstrap";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { CSVLink, CSVDownload } from "react-csv";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableRow,
} from "@material-ui/core";
import config from "../../config";
export default class MerchantOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalOffer: "",
      totalLive: "",
      totalRejected: "",
      stats: [],
      showData: false,
      totalShops: [],
      allShops: [],
      Topmerchant: [],
      headers: [],
      data: [],
      sortDate: this.props.dates,
      totalDraft: "",
      totalDeleted: "",
      totalExpired: "",
    };
    this.renderFunction = this.renderFunction.bind(this);
    this.totalStats = this.totalStats.bind(this);
    this.getInactiveUser = this.getInactiveUser.bind(this);
    this.export = this.export.bind(this);
    this.csvLink = React.createRef();
    this.csvExport = this.csvExport.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    var temporaryOfferDetails = [];
    var live = 0;
    var temp;
    var rejected = 0;
    var deleted = 0;
    var draft = 0;
    var tempData = [];
    var expired = 0;
    var userTableid = [];
    var offerShops = [];
    let today = new Date().toISOString().substring(0, 10);
    this.props.offer.map((element) => {
      if (element.offerType === "offer") {
        temporaryOfferDetails.push(element);

        if (element.offerStatus === "Live") {
          // filter live offer
          if (today < element.offerEndDate) {
            live++;
          } else {
            expired++;
          }
        } else if (element.offerStatus === "Rejected") {
          rejected++; // count rejected offers
        } else if (element.offerStatus === "Deleted") {
          deleted++; // count deleted offers
        } else if (element.offerStatus === "Draft") {
          draft++; // count draft
        }
      }
    });
    userTableid = temporaryOfferDetails.map((element) => element.shopId);

    fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        query: {
          bool: {
            must: [{ match: { userType: "Shop" } }],
            must_not: {
              bool: {
                must: [{ term: { userPincode: "111111" } }],
              },
            },
          },
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        tempData = data.hits.hits.map((element) => element._source);

        tempData.map((items) =>
          offerShops.push({
            userId: items.userId,
            shopName: items.shopName,
            shopType: items.shopType,
            shopAddress: items.shopAddress,
            userPincode: items.userPincode,
            userPhoneNumber: items.userPhoneNumber,
            userName: items.userName,
            userId10Digit: items.userId10Digit,
          })
        );
      });

    this.setState(
      {
        totalOffer: temporaryOfferDetails.length,
        totalLive: live,
        totalRejected: rejected,
        allShops: offerShops,
        totalDraft: draft,
        totalDeleted: deleted,
        totalExpired: expired,
      },
      () => {
        this.renderFunction(temporaryOfferDetails, this.props.shop);
      }
    );
  }
  renderFunction(offers, shops) {
    var offerDetails = [];
    var shopDetails = [];
    var totalOfferStatistics = [];
    var zero_ten = [];
    var zeroOffers = [];
    var lessThanFifty = [];
    var lessThanNinetyOffers = [];
    var greaterThanHundred = [];
    var zero = 0;
    var ten = 0;
    var fifty = 0;
    var ninety = 0;
    var hundred = 0;
    shops.map((element) => {
      var count = 0;
      offers.map((items) => {
        if (element === items.shopId) {
          count++;
        }
      });
      offerDetails.push({
        shopId: element,
        totaloffers: count,
      });
    });
    // segregate offer count
    offerDetails.map((element) => {
      if (element.totaloffers >= 1 && element.totaloffers <= 9) {
        zero_ten.push(element); // zero offers

        ten++;
      } else if (element.totaloffers === 0) {
        zeroOffers.push(element);

        zero++;
      } else if (element.totaloffers <= 50 && element.totaloffers >= 10) {
        lessThanFifty.push(element);
        fifty++;
      } else if (element.totaloffers >= 51 && element.totaloffers <= 99) {
        lessThanNinetyOffers.push(element);
        ninety++;
      } else if (element.totaloffers >= 100) {
        greaterThanHundred.push(element);
        hundred++;
      }
    });

    totalOfferStatistics.push(
      {
        name: "0",
        value: zero, // user list
      },
      {
        name: "1-9",
        value: ten,
      },
      {
        name: "10-50",
        value: fifty,
      },
      {
        name: "51-99",
        value: ninety,
      },
      {
        name: "100-500",
        value: hundred,
      }
    );
    shopDetails.push(
      zeroOffers,
      zero_ten,
      lessThanFifty,
      lessThanNinetyOffers,
      greaterThanHundred
    );

    var topShop = offerDetails
      .sort((a, b) => b.totaloffers - a.totaloffers)
      .slice(0, 10); // Top 10 offers posted by shop

    this.setState({
      stats: totalOfferStatistics,
      showData: true,
      totalShops: shopDetails,
      Topmerchant: topShop,
    });
  }
  totalStats(offers) {
    var arr = [];
    offers.map((element) => {
      this.state.allShops.map((items) => {
        if (element.shopId === items.userId) {
          arr.push(Object.assign({}, element, items));
        }
      });
    });
    this.props.info(arr, "TOTAL COLLECTION");
  }
  getInactiveUser(offers, toggleState) {
    var dummy = [];
    var arr = [];
    var tempData = [];
    offers.map((element) => {
      dummy.push(element.shopId);
    });

    fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        query: {
          constant_score: {
            filter: {
              bool: {
                should: [{ terms: { userId: dummy } }],
              },
            },
          },
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        tempData = data.hits.hits.map((element) => element._source);
        tempData.map((items) =>
          arr.push({
            userId: items.userId,
            userId10Digit: items.userId10Digit,
            shopName: items.shopName,
            shopType: items.shopType,
            shopAddress: items.shopAddress,
            userPincode: items.userPincode,
            userPhoneNumber: items.userPhoneNumber,
            userName: items.userName,
          })
        );
        var decision = toggleState
          ? this.props.info(arr, "TOTAL COLLECTION")
          : this.csvExport(arr);
      });
  }
  csvExport(response) {
    // excel import
    const headers = [
      { label: "SHOP ID", key: "userId10Digit" },
      { label: "SHOP NAME", key: "shopName" },
      { label: "SHOP TYPE", key: "shopType" },
      { label: "TOTAL OFFERS", key: "totaloffers" },
      { label: "ADDRESS", key: "shopAddress" },
      { label: "USERNAME", key: "userName" },
      { label: "PHONE NUMBER", key: "userPhoneNumber" },
      { label: "PINCODE", key: "userPincode" },
    ];
    this.setState(
      {
        data: response,
        headers: headers,
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }

  export(response) {
    var arr = [];
    response.map((element) => {
      this.state.allShops.map((items) => {
        if (element.shopId === items.userId) {
          arr.push(Object.assign({}, element, items));
        }
      });
    });
    console.log(arr);
    const headers = [
      { label: "SHOP ID", key: "userId10Digit" },
      { label: "SHOP NAME", key: "shopName" },
      { label: "SHOP TYPE", key: "shopType" },
      { label: "TOTAL OFFERS", key: "totaloffers" },
      { label: "ADDRESS", key: "shopAddress" },
      { label: "USERNAME", key: "userName" },
      { label: "PHONE NUMBER", key: "userPhoneNumber" },
      { label: "PINCODE", key: "userPincode" },
    ];

    this.setState(
      {
        data: arr,
        headers: headers,
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  onChange(e) {
    var value = e.target.value;
    var name = e.target.name;

    this.setState(
      {
        sortDate: value,
      },
      () => {
        this.props.sortDate(this.state.sortDate);
      }
    );
  }
  render() {
    return (
      <>
        <Typography variant="h2" style={{ textAlign: "center" }} gutterBottom>
          {this.props.title}
        </Typography>
        <Row>
          <Col md={{ span: 4, offset: 7 }}>
            <Form>
              <Form.Label>SORT BY DATE</Form.Label>
              <Form.Control
                type="date"
                name="sortDate"
                value={this.state.sortDate || ""}
                onChange={this.onChange}
              ></Form.Control>
            </Form>
          </Col>
        </Row>
        {this.state.showData && (
          <>
            <Row>
              <Col md={{ span: 3 }} className="division">
                <strong>
                  <span style={{ textAlign: "center" }}>TOTAL OFFERS</span>
                </strong>
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  {this.state.totalOffer}
                </h3>
              </Col>
              <Col md={{ span: 3, offset: 1 }} className="division">
                <strong>TOTAL LIVE</strong>
                <h3 style={{ textAlign: "center" }}> {this.state.totalLive}</h3>
              </Col>
              <Col md={{ span: 3, offset: 1 }} className="division">
                <strong>TOTAL REJECTED</strong>
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  {this.state.totalRejected}
                </h3>
              </Col>
              <Col md={{ span: 3 }} className="division">
                <strong>ON DRAFT</strong>
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  {this.state.totalDraft}
                </h3>
              </Col>
              <Col md={{ span: 3, offset: 1 }} className="division">
                <strong>TOTAL DELETED</strong>
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  {this.state.totalDeleted}
                </h3>
              </Col>
              <Col md={{ span: 3, offset: 1 }} className="division">
                <strong>TOTAL EXPIRED</strong>
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  {this.state.totalExpired}
                </h3>
              </Col>
            </Row>
            <Row className="division">
              <div style={{ width: 100 + "%", display: "inline-block" }}>
                <h3 style={{ textAlign: "center" }}>
                  TOTAL MERCHANTS POSTED OFFER RANGE
                </h3>
              </div>

              <BarChart
                width={600}
                height={300}
                data={this.state.stats}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  label={{
                    value: "OFFER RANGE",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip />
                <Legend />

                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </Row>
            <Row className="division">
              <Col>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid",
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">OFFER RANGE</TableCell>
                      <TableCell align="center">TOTAL SHOPS</TableCell>
                      <TableCell align="center">VIEW SHOP DETAILS</TableCell>
                      <TableCell align="center">DOWNLOAD</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH ZERO OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.stats[0].value}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.getInactiveUser(
                                this.state.totalShops[0],
                                true
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.getInactiveUser(
                                this.state.totalShops[0],
                                false
                              );
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">2</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH 1-9 OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.stats[1].value}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              // this.props.ca(this.storeShopId[3].merchantTen);
                              this.totalStats(this.state.totalShops[1]);
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(this.state.totalShops[1]);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">3</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS OFFERS BETWEEN 10 -50 OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.stats[2].value}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.totalStats(this.state.totalShops[2]);
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(this.state.totalShops[2]);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">4</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH 51 - 99 OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.stats[3].value}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.totalStats(this.state.totalShops[3]);
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(this.state.totalShops[3]);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">5</TableCell>
                      <TableCell align="center">
                        TOTAL SHOPS WITH MORE THAN 100 OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.stats[4].value}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.totalStats(this.state.totalShops[4]);
                            }}
                          >
                            View
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.export(this.state.totalShops[4]);
                            }}
                          >
                            DOWNLOAD
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Col>
            </Row>
            <Row className="division">
              <Col>
                <h4 style={{ textAlign: "center" }}>TOP 10 MERCHANT</h4>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    this.totalStats(this.state.Topmerchant);
                  }}
                >
                  VIEW
                </Button>

                <Button
                  onClick={() => {
                    this.export(this.state.Topmerchant);
                  }}
                >
                  DOWNLOAD
                </Button>
              </Col>
            </Row>
            <Row>
              <CSVLink
                data={this.state.data}
                headers={this.state.headers}
                ref={this.csvLink}
                target="_blank"
              />
            </Row>
          </>
        )}
      </>
    );
  }
}
