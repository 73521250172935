import React, { useEffect, useState } from "react";
import DisplayTable from "./displayTable";
import { Form, Row, Col, Modal } from "react-bootstrap";
import { GetOfferCreation, B2B } from "./service";

export default function OfferCreation() {
  const [isLoading, setIsLoading] = useState(true);
  const [shop, setShop] = useState([]);
  let currentDate = new Date();
  const [state, setState] = useState({
    startDate: new Date(currentDate.setDate(currentDate.getDate() - 90))
      .toISOString()
      .substring(0, 10),
    endDate: new Date().toISOString().substring(0, 10),
    offerType: "offer",
  });
  const [modalShow, setModalShow] = useState(false);

  const [viewOffer, setviewOffer] = useState({});
  const [offerList, setOfferList] = useState([]);

  const handleClose = () => {
    // Handle modal state and reset the offer object
    setviewOffer({});
    setModalShow(false);
  };
  useEffect(() => {
    // Call class on mounting

    async function getShop() {
      let shopList = await B2B();
      setShop(shopList);
    }

    getShop();
  }, []);

  const getData = async () => {
    await GetOfferCreation(state).then(async (data) => {
      let consolidateList = await mergeShopAndOffer(shop, data);
      setOfferList(consolidateList);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    getData();
    setIsLoading(true);
  }, [state, shop]);
  const onChange = (name, value) =>
    setState((prevState) => ({ ...prevState, [name]: value }));

  const mergeShopAndOffer = async (shop, offers) => {
    // merge offer and shop

    return await new Promise((resolve) => {
      let shopOffers = offers.map((elements) => {
        let findShop = shop.find((items) => elements.shopId === items.userId);
        if (findShop !== undefined) {
          return Object.assign({}, elements, findShop);
        } else return elements;
      });
      resolve(shopOffers);
    });
  };
  const showData = (data) => {
    // callback from user action and get object data from table
    setviewOffer(data);
    setModalShow(true);
  };
  return (
    <>
      <div className="titleHeader">
        <h4>Offer Creation</h4>
      </div>
      <div className="vertical-spae-20"></div>
      <div className="flexRow">
        <Col sm={6}>
          <Form.Row>
            <Col sm={4}>
              <strong>Offer Type</strong>
            </Col>

            <Col sm={4}>
              <Form.Check
                label="Offer"
                type="radio"
                name="offerType"
                onChange={(e) => onChange(e.target.name, e.target.value)}
                value="offer"
                checked={state.offerType == "offer"}
              />
            </Col>
            <Col sm={4}>
              <Form.Check
                label="Collection"
                type="radio"
                name="offerType"
                onChange={(e) => onChange(e.target.name, e.target.value)}
                value="item"
                checked={state.offerType == "item"}
              />
            </Col>
          </Form.Row>
        </Col>
        <Col sm={6}>
          <Form.Row>
            <Col>
              <strong>Offer Created Range </strong>
            </Col>
            <Col>
              <Form.Control
                type="date"
                name="startDate"
                value={state.startDate}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Col>

            <Col>
              <Form.Control
                type="date"
                name="endDate"
                value={state.endDate}
                min={state.startDate}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Col>
          </Form.Row>
        </Col>
      </div>

      <>
        <div className="flexRow">
          <div className="labelWrapper">
            <h5>Shops</h5>
          </div>
          <div id="displayTable" className="flexColumn">
            <DisplayTable
              data={offerList}
              validKeys={[
                "offerImageLocation",
                "userId10Digit",
                "shopName",
                "userPhoneNumber",
                "shopType",
                "offerType",
                "offerDetails",
                "review",
                "shopPincode",
                "offerStatus",
                "offerAddedDate",
                "offerEndDate",
                "reviewedOn",
                "reviewedBy",
                "action",
              ]}
              title={"Offers"}
              isLoading={isLoading}
              openModal={showData}
            />
          </div>
        </div>
        <Modal
          className="viewCartDetails"
          show={modalShow}
          onHide={handleClose}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                OFFER DATE
              </Form.Label>
              <Col>
                <Form.Control
                  plaintext
                  type="date"
                  name="offerStartDate"
                  value={viewOffer.offerStartDate}
                ></Form.Control>
              </Col>

              <Col>
                <Form.Control
                  plaintext
                  type="date"
                  name="offerEndDate"
                  value={viewOffer.offerEndDate}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                OFFER TIMING
              </Form.Label>
              <Col>
                <Form.Control
                  plaintext
                  type="time"
                  name="offerStartTime"
                  value={viewOffer.offerStartTime}
                ></Form.Control>
              </Col>
              <Col>
                <Form.Control
                  plaintext
                  type="time"
                  name="offerEndTime"
                  value={viewOffer.offerEndTime}
                ></Form.Control>
              </Col>
            </Form.Group>
            {!(
              viewOffer.offerType === "item" && viewOffer.shopType === "Mobile"
            ) && (
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  OFFER DESCRIPTION
                </Form.Label>
                <Col>
                  <Form.Control
                    plaintext
                    as="textarea"
                    rows="4"
                    name="offerDetails"
                    value={viewOffer.offerDetails}
                  ></Form.Control>
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                OFFER ADDED ON
              </Form.Label>
              <Col>
                <Form.Control
                  plaintext
                  type="text"
                  name="offerAddedDate"
                  value={viewOffer.offerAddedDate}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                OFFER ORIGINAL PRICE
              </Form.Label>
              <Col>
                <Form.Control
                  plaintext
                  type="text"
                  pattern="[0-9]{1,10}"
                  name="offerOriginalPrice"
                  value={viewOffer.offerOriginalPrice}
                ></Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="4">
                OFFER NEW PRICE
              </Form.Label>
              <Col>
                <Form.Control
                  plaintext
                  type="text"
                  pattern="[0-9]{1,10}"
                  name="offerNewPrice"
                  value={viewOffer.offerNewPrice}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                OFFER DISCOUNT
              </Form.Label>
              <Col>
                <Form.Control
                  plaintext
                  type="text"
                  name="offerDiscount"
                  //

                  value={viewOffer.offerDiscount}
                ></Form.Control>
              </Col>
            </Form.Group>
            {viewOffer.offerType === "item" &&
              viewOffer.shopType === "Textile" && (
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      OFFER CATEGORY
                    </Form.Label>
                    <Col>
                      <Form.Control
                        plaintext
                        type="text"
                        name="offerCategory"
                        value={viewOffer.offerCategory}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      SUB CATEGORY
                    </Form.Label>
                    <Col>
                      <Form.Control
                        plaintext
                        type="text"
                        name="offerSubCategory"
                        value={viewOffer.offerSubCategory}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      OFFER TAG
                    </Form.Label>
                    <Col>
                      <Form.Control
                        plaintext
                        type="text"
                        name="offerTag"
                        value={viewOffer.offerTag}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                </>
              )}

            {viewOffer.offerType === "item" && viewOffer.shopType === "Mobile" && (
              <>
                <Form.Group as={Row}>
                  <Form.Label className="asterik" column sm="4">
                    Category
                  </Form.Label>
                  <Col>
                    <Form.Control
                      plaintext
                      name="offerCategory"
                      value={viewOffer.offerCategory}
                      required
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label className="asterik" column sm="4">
                    Brand
                  </Form.Label>
                  <Col>
                    <Form.Control
                      plaintext
                      name="mobileBrand"
                      value={viewOffer.mobileBrand}
                      required
                    ></Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label className="asterik" column sm="4">
                    Mobile Model
                  </Form.Label>
                  <Col>
                    <Form.Control
                      plaintext
                      type="text"
                      name="mobileModel"
                      value={viewOffer.mobileModel}
                      required
                    ></Form.Control>
                  </Col>
                </Form.Group>
                {viewOffer.offerCategory === "mobile" && (
                  <>
                    <Form.Group as={Row}>
                      <Form.Label className="asterik" column sm="4">
                        Memory
                      </Form.Label>
                      <Col>
                        <Form.Control
                          plaintext
                          type="text"
                          name="mobileMemory"
                          value={viewOffer.mobileMemory}
                          required
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Memory Extendable
                      </Form.Label>
                      <Col>
                        <Form.Control
                          plaintext
                          type="text"
                          name="mobileMemoryExtendable"
                          value={viewOffer.mobileMemoryExtendable}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Dimension
                      </Form.Label>
                      <Col>
                        <Form.Control
                          plaintext
                          type="text"
                          name="mobileDimensions"
                          placeholder="15.7 * 0.9"
                          value={viewOffer.mobileDimensions}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label className="asterik" column sm="4">
                        RAM
                      </Form.Label>
                      <Col>
                        <Form.Control
                          plaintext
                          type="text"
                          name="mobileRAM"
                          value={viewOffer.mobileRAM}
                          required
                        ></Form.Control>
                      </Col>
                      <Form.Label className="asterik" column sm="4">
                        OS
                      </Form.Label>
                      <Col>
                        <Form.Control
                          plaintext
                          name="mobileOS"
                          value={viewOffer.mobileOS}
                          required
                        >
                          <option value="">{"Select OS"}</option>
                          {this.state.OsList}
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Front Camera Pixel
                      </Form.Label>
                      <Col>
                        <Form.Control
                          plaintext
                          type="text"
                          pattern="[0-9]{1,10}"
                          name="mobileFrontFacingCamera"
                          value={viewOffer.mobileFrontFacingCamera}
                          placeholder="Mega Pixel"
                        ></Form.Control>
                      </Col>
                      <Form.Label column sm="4">
                        Rear Camera pixel
                      </Form.Label>
                      <Col>
                        <Form.Control
                          plaintext
                          type="text"
                          pattern="[0-9]{1,10}"
                          name="mobileRearFacingCamera"
                          value={viewOffer.mobileRearFacingCamera}
                          placeholder="Mega Pixel"
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                  </>
                )}

                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    Mobile Color
                  </Form.Label>
                  <Col>
                    <Form.Control
                      plaintext
                      type="text"
                      name="mobileColor"
                      value={viewOffer.mobileColor}
                    ></Form.Control>
                  </Col>
                  <Form.Label column sm="4">
                    Mobile Weight
                  </Form.Label>
                  <Col>
                    <Form.Control
                      plaintext
                      type="text"
                      pattern="[0-9]{1,10}"
                      name="mobileWeight"
                      value={viewOffer.mobileWeight}
                      placeholder="In Gms"
                    ></Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    Other Details
                  </Form.Label>
                  <Col>
                    <Form.Control
                      plaintext
                      as="textarea"
                      rows="6"
                      name="mobileOtherDetails"
                      value={viewOffer.mobileOtherDetails}
                    ></Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    Service Details
                  </Form.Label>
                  <Col>
                    <Form.Control
                      plaintext
                      as="textarea"
                      rows="6"
                      name="mobileServiceDetails"
                      value={viewOffer.mobileServiceDetails}
                    ></Form.Control>
                  </Col>
                </Form.Group>
              </>
            )}

            <Form.Group as={Row}>
              <Form.Label column sm="4">
                OFFER STATUS
              </Form.Label>
              <Col>
                <Form.Control
                  plaintext
                  name="offerStatus"
                  value={viewOffer.offerStatus}
                  required
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                OFFER TYPE
              </Form.Label>
              <Col>
                <Form.Control
                  plaintext
                  type="text"
                  name="offerType"
                  value={viewOffer.offerType}
                ></Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="4">
                REVIEW COMMENT
              </Form.Label>
              <Col>
                <Form.Control
                  plaintext
                  as="textarea"
                  rows="4"
                  name="reviewComment"
                  value={viewOffer.reviewComment}
                ></Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="4">
                REVIEW COMMENT
              </Form.Label>
              <Col>
                <Form.Control
                  plaintext
                  as="textarea"
                  rows="4"
                  name="reviewComment"
                  value={viewOffer.reviewComment}
                ></Form.Control>
              </Col>
            </Form.Group>
          </Modal.Body>
        </Modal>
      </>

      <div className="vertical-spae-20"></div>
    </>
  );
}
