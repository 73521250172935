import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import CreatePartnerAccount from "./createPartnerAccount";
import PartnerDashboard from "./partnerDashboard";
import ViewPartnerDashboard from "../partner/dashboard";
import PendingPayment from "./pendingPayment";
import Statement from "./statement";
import Report from "./reports";
import CreateFranchiseAccount from "./createFranchiseAccount";
import "./managingDirector.css";
export default function ManagingDirector() {
  const params = useParams(); // get paramter from url to load class
  useEffect(() => {
    // initialize when value is updated
    loadClass();
  }, [params.pathname]);
  const loadClass = () => {
    // load class in main content
    switch (params.pathname) {
      case "createpartneraccount":
        return <CreatePartnerAccount />;
      case "partnerdashboard":
        return <PartnerDashboard />;
      case "statement":
        return <Statement />;
      case "managefranchiseaccount":
        return <CreateFranchiseAccount />;
      case "viewpartner":
        let searchParams = new URLSearchParams(window.location.search);
        let id = "";
        if (
          searchParams.get("userid") !== null &&
          searchParams.get("userid") !== undefined
        ) {
          id = searchParams.get("userid");
        }
        return (
          <>
            <ViewPartnerDashboard cognitoId={id} role={"managingDirector"} />
          </>
        );
      case "pending":
        return <PendingPayment />;
      case "reports":
        return <Report />;
      default:
        return <></>;
    }
  };
  return (
    <Container fluid style={{ padding: "0" }}>
      <Row style={{ minHeight: "100vh", width: "100%", float: "left" }}>
        <Col id="SideBar" sm={2}>
          <nav>
            <ul>
              <li>
                <Link
                  className="nav-link"
                  to="/managingDirector/createpartneraccount"
                >
                  Create Partner Account
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  to="/managingDirector/managefranchiseaccount"
                >
                  Manage Franchise Account
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  to="/managingDirector/createpartneraccount"
                >
                  Create Employee Account
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  to="/managingDirector/partnerdashboard"
                >
                  View Partner
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  to="/managingDirector/createpartneraccount"
                >
                  View Employee
                </Link>
              </li>
              {/* <li>
                <Link className="nav-link" to="/managingDirector/statement">
                  Statement
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="/managingDirector/pending">
                  Pending Payment
                </Link>
              </li> */}
            </ul>
          </nav>
        </Col>
        <Col id="mainPageContent" sm={10}>
          <div className="verticalBodyContainer">{loadClass()}</div>
        </Col>
      </Row>
    </Container>
  );
}
