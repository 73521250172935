"use strict";
import { ConsoleLogger } from "@aws-amplify/core";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import "../../styles/manageProducts.css";
import { ThreeDots, RotatingLines } from "react-loader-spinner";
import AddProducts from "./addProducts";
import config from "../../config";
export default function SubShopProducts(props) {
  var primaryShopId = props.profile.userId;
  let defaultImg = "https://ghoshak-website-builder.s3.ap-south-1.amazonaws.com/defaultImage.png";

  const [partnersData, setPartnersData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [partnerObj, setPartnerObj] = useState({});
  const [partnerIndex, setPartnerIndex] = useState(0);
  const [shopId, setSubShopId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [impExpLoading, setImpExpLoading] = useState(false);
  const [addProductsClicked, setAddProductsClicked] = useState(false);
  const [shop, setShop] = useState({});

  useEffect(() => {
    getSubShopProducts();
  }, [primaryShopId]);

  useEffect(() => {
    console.log(productsData, "ProdDATA");
  }, [productsData]);
  const alertShopNotSelected = () => {
    setAddProductsClicked(false);
    alert("Select Any One Shop To Add Products");
  };

  const getSubShopProducts = async () => {
    setIsLoading(true);
    try {
      let fetchedProducts = await fetch(
        `${config.ApiGateway}/subShopProducts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: primaryShopId,
          }),
        }
      );
      var partnersData = await fetchedProducts.json();
      console.log("subproddata", partnersData);
      setPartnersData(partnersData);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const manageImport = async (index, product) => {
    console.log(product.productId, "PRODUCT ID");
    setImpExpLoading(true);
    try {
      var exportProduct = await fetch(
        `${config.ApiGateway}/importProductsToPrimaryFranchise`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: primaryShopId,
            productId: product.productId,
          }),
        }
      );
      // await getSubShopProducts();
      // console.log(await exportProduct.json(), "EXPORT PROD");
      var obj = {
        ...product,
        exportStatus: true,
        exportedTo: primaryShopId,
      };
      var dataArr = productsData.filter(
        (data) => data.productId !== product.productId
      );
      dataArr = [...dataArr.slice(0, index), obj, ...dataArr.slice(index)];
      setProductsData([...dataArr]);
      var partnerArr = partnersData.filter((data) => data.userId !== shopId);
      var partObj = {
        ...partnerObj,
        products: dataArr,
      };
      partnerArr = [
        ...partnerArr.slice(0, partnerIndex),
        partObj,
        ...partnerArr.slice(partnerIndex),
      ];
      setPartnersData(partnerArr);
      setImpExpLoading(false);
    } catch (err) {
      console.log(err, "Error");
    }
  };

  const manageRemove = async (index, product) => {
    console.log(product, "REMOVED PRODUCT");
    setImpExpLoading(true);
    try {
      var removedProduct = await fetch(
        `${config.ApiGateway}/removeProductFromPrimaryFranchise`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productId: product.productId,
            exportedTo: primaryShopId,
            versionId: product.versionId,
          }),
        }
      );
      // await getSubShopProducts();
      // console.log(await removedProduct.json(), "REMOVED PROD");
      var obj = {
        ...product,
        exportStatus: false,
        exportedTo: "none",
      };
      var dataArr = productsData.filter(
        (data) => data.productId !== product.productId
      );
      dataArr = [...dataArr.slice(0, index), obj, ...dataArr.slice(index)];
      setProductsData([...dataArr]);
      var partnerArr = partnersData.filter((data) => data.userId !== shopId);
      var partObj = {
        ...partnerObj,
        products: dataArr,
      };
      partnerArr = [
        ...partnerArr.slice(0, partnerIndex),
        partObj,
        ...partnerArr.slice(partnerIndex),
      ];
      setPartnersData(partnerArr);
      setImpExpLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {!addProductsClicked ? (
        <div className="manageShopMain">
          <div>
            <p className="PartnersTitle">Partners</p>
            {isLoading ? (
              <ThreeDots
                height="50"
                width="50"
                color="grey"
                ariaLabel="loading"
              />
            ) : (
              <div className="sideMenuSection">
                {partnersData.map((data, index) => {
                  return (
                    <div className="shopListDiv">
                      {data.userId === shopId ? (
                        <>
                          <button
                            className="shopNameButtonClicked"
                            onClick={(e) => {
                              setProductsData(data.products);
                              setSubShopId(data.userId);
                              setPartnerObj(data);
                              setPartnerIndex(index);
                              setShop(data);
                            }}
                          >
                            {data.shopName}
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="shopNameButton"
                            onClick={(e) => {
                              setProductsData(data.products);
                              setSubShopId(data.userId);
                              setPartnerObj(data);
                              setPartnerIndex(index);
                              setShop(data);
                            }}
                          >
                            {data.shopName}
                          </button>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="displayProductsSection">
            {!isLoading && shop.userId10Digit && (
              <div className="addProductsButtonContainer">
                <button onClick={() => setAddProductsClicked(true)}>
                  Add Product
                </button>
              </div>
            )}
            <div className="products">
              {productsData.map((data, index) => {
                console.log(data, "productsDATA");
                return (
                  <div className="singleProductStyle">
                    <img
                      src={
                        data.productImageArray
                          ? data.productImageArray.length > 0
                            ? data.productImageArray[0]
                            : defaultImg
                          : data.productImage && data.productImage !== "removed"
                          ? data.productImage
                          : defaultImg
                      }
                      height={250}
                      width={250}
                    />
                    <div className="productName">{data.productName}</div>
                    <div className="productPrice">₹ {data.productPrice}</div>
                    {impExpLoading ? (
                      <RotatingLines
                        height="50"
                        width="50"
                        color="grey"
                        ariaLabel="loading"
                      />
                    ) : (
                      <div>
                        {data.exportedTo ? (
                          data.exportedTo == primaryShopId &&
                          data.exportStatus ? (
                            <button
                              onClick={(e) => manageRemove(index, data)}
                              className={"addedButton"}
                            >
                              Remove
                            </button>
                          ) : (
                            <button
                              onClick={(e) => manageImport(index, data)}
                              className={"importButton"}
                            >
                              Import
                            </button>
                          )
                        ) : (
                          <button
                            onClick={(e) => manageImport(index, data)}
                            className={"importButton"}
                          >
                            Import
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div onClick={() => setAddProductsClicked(false)}>Back</div>
          <AddProducts shopId={shop.userId10Digit} />
        </div>
      )}
    </div>
  );
}
