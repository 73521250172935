import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { CSVLink } from "react-csv";
export default class CustomerTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showData: true,
      page: 0,
      exportData: [],
      headers: [],
      showNegative: false,
      rowsPerPage: 20,
    };
    this.getCustomerDetails = this.getCustomerDetails.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.csvLink = React.createRef();
    this.onChange = this.onChange.bind(this);
  }
  export() {
    const headers = [
      { label: "Customer ID", key: "userId10Digit" },
      { label: "Pincode", key: "userPincode" },
      { label: "Name", key: "userName" },
      { label: "Phone Number", key: "userPhoneNumber" },
      { label: "Place", key: "place" },
      { label: "Ghoshak Added Points", key: "ghoshakaddedPoints" },
      { label: "Loyalty Added Points", key: "loyaltyaddedPoints" },
      { label: "Ghoshak Redeemed Points", key: "ghoshakredeemedPoints" },
      { label: "Redeemed Points", key: "points" },
      { label: "Loyalty Redeemed Points", key: "loyaltyredeemedPoints" },

      { label: "Reffered Count", key: "count" },

      { label: "Added To Fav", key: "totalAddedtoFavourites" },
      { label: "Offers Liked", key: "likedOffers" },
      { label: "Fav Shops", key: "favouriteShops" },

      { label: "Current Points", key: "totalPoints" },
      { label: "Total Interaction", key: "totalinteraction" },
      { label: "Coupons Consumed", key: "consumed" },
    ];

    this.setState(
      {
        headers: headers,
        exportData: this.props.details,
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  onChange(e) {
    this.setState({
      [e.target.name]: !this.state.showNegative,
    });
  }
  getCustomerDetails(userid) {
    this.props.getCustomer(userid);
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  render() {
    console.log(this.state);
    return (
      <>
        {this.state.showData && (
          <>
            <div
              style={{
                backgroundColor: "#fafafa",
                width: 100 + "%",
                margin: 1 + "%",
              }}
            >
              <h3 style={{ textAlign: "center" }}> DETAILS</h3>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBottom: "10px",
              }}
            >
              {this.props.hideTable.includes("Interaction/Transaction") && (
                <>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      name="showNegative"
                      value={this.state.showNegative}
                      onChange={this.onChange}
                      type="checkbox"
                      label="Show negative points"
                    />
                  </Form.Group>
                </>
              )}

              <Button
                onClick={() => {
                  this.export();
                }}
              >
                Download
              </Button>
              <CSVLink
                enclosingCharacter={`'`}
                data={this.state.exportData}
                headers={this.state.headers}
                ref={this.csvLink}
                target="_blank"
              />
            </div>
            <Table
              style={{
                width: 100 + "%",
                border: 0.5 + "px",
                borderColor: "#ced4da",
                borderStyle: "solid",
              }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">S NO</TableCell>
                  <TableCell align="center">User ID</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Pincode</TableCell>
                  <TableCell align="center">Address</TableCell>
                  <TableCell align="center">Phone Number</TableCell>
                  <TableCell
                    style={{
                      display: this.props.hideTable.includes("addedPts")
                        ? ""
                        : "none",
                    }}
                    align="center"
                  >
                    Added Points
                  </TableCell>
                  <TableCell
                    style={{
                      display: this.props.hideTable.includes("redeemedPts")
                        ? ""
                        : "none",
                    }}
                    align="center"
                  >
                    REDEEMED POINTS
                  </TableCell>
                  <TableCell
                    style={{
                      display: this.props.hideTable.includes("referedCount")
                        ? ""
                        : "none",
                    }}
                    align="center"
                  >
                    REFERED COUNT
                  </TableCell>
                  <TableCell
                    style={{
                      display: this.props.hideTable.includes("addedToFav")
                        ? ""
                        : "none",
                    }}
                    align="center"
                  >
                    TOTAL ADDED TO FAVOURITE
                  </TableCell>

                  <TableCell
                    style={{
                      display: this.props.hideTable.includes("currentPts")
                        ? ""
                        : "none",
                    }}
                    align="center"
                  >
                    Current Points
                  </TableCell>
                  <TableCell
                    style={{
                      display: this.props.hideTable.includes(
                        "Interaction/Transaction"
                      )
                        ? ""
                        : "none",
                    }}
                    align="center"
                  >
                    TOTAL TRANSACTION/INTERACTION(redeemed)
                  </TableCell>
                  <TableCell
                    style={{
                      display: this.props.hideTable.includes("couponsConsumed")
                        ? ""
                        : "none",
                    }}
                    align="center"
                  >
                    COUPONS CONSUMED
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.details
                  .filter((items) =>
                    this.state.showNegative
                      ? parseInt(items.totalPoints) < 0
                      : items
                  )
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((row, index) => (
                    <>
                      <TableRow key={row.userId}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {" "}
                          <Button
                            variant="link"
                            onClick={() => {
                              this.getCustomerDetails(row.userId10Digit);
                            }}
                          >
                            {row.userId10Digit}
                          </Button>
                        </TableCell>
                        <TableCell align="center">{row.userName}</TableCell>
                        <TableCell align="center">{row.userPincode}</TableCell>
                        <TableCell align="center">{row.userAddress}</TableCell>
                        <TableCell align="center">
                          {row.userPhoneNumber}
                        </TableCell>
                        <TableCell
                          style={{
                            display: this.props.hideTable.includes("addedPts")
                              ? ""
                              : "none",
                          }}
                          align="center"
                        >
                          {row.ghoshakaddedPoints || row.loyaltyaddedPoints}
                        </TableCell>
                        <TableCell
                          style={{
                            display: this.props.hideTable.includes(
                              "redeemedPts"
                            )
                              ? ""
                              : "none",
                          }}
                          align="center"
                        >
                          {row.points ||
                            row.loyaltyredeemedPoints ||
                            row.ghoshakredeemedPoints}
                        </TableCell>
                        <TableCell
                          style={{
                            display: this.props.hideTable.includes(
                              "referedCount"
                            )
                              ? ""
                              : "none",
                          }}
                          align="center"
                        >
                          {row.count}
                        </TableCell>
                        <TableCell
                          style={{
                            display: this.props.hideTable.includes("addedToFav")
                              ? ""
                              : "none",
                          }}
                          align="center"
                        >
                          {row.totalAddedtoFavourites ||
                            row.likedOffers ||
                            row.favouriteShops}
                        </TableCell>

                        <TableCell
                          style={{
                            backgroundColor:
                              parseInt(row.totalPoints) < 0 ? "red" : "",
                            display: this.props.hideTable.includes("currentPts")
                              ? ""
                              : "none",
                          }}
                          align="center"
                        >
                          {row.totalPoints}
                        </TableCell>

                        <TableCell
                          style={{
                            display: this.props.hideTable.includes(
                              "Interaction/Transaction"
                            )
                              ? ""
                              : "none",
                          }}
                          align="center"
                        >
                          {row.totalinteraction}
                        </TableCell>
                        <TableCell
                          style={{
                            display: this.props.hideTable.includes(
                              "couponsConsumed"
                            )
                              ? ""
                              : "none",
                          }}
                          align="center"
                        >
                          {row.consumed}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={this.props.details.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000]}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </>
        )}
      </>
    );
  }
}
