import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Typography } from "@material-ui/core";

export default class CustomerTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ghoshakPoints: [],
      loyaltyPoints: [],
      top10GhoshakPoints: [],
      top10LoyaltyPoints: [],
      showData: false
    };
    // this.handleLogic = this.handleLogic.bind(this);
    this.viewTable = this.viewTable.bind(this);
    this.renderFunction = this.renderFunction.bind(this);
  }
  async componentDidMount() {
    // await this.handleLogic();
    await this.renderFunction();
  }
  renderFunction() {
    var userArray_loyaltyPoints = this.props.transactionDetails[0].loyalty.map(
      elements => {
        return elements;
      }
    );
    var top10_loyaltytransaction = userArray_loyaltyPoints
      .sort((a, b) => b.totalinteraction - a.totalinteraction)
      .slice(0, 10);

    var userArray_ghoshakPoints = this.props.transactionDetails[0].ghoshak.map(
      elements => {
        return elements;
      }
    );
    var top10_ghoshaktransaction = userArray_ghoshakPoints
      .sort((a, b) => b.totalinteraction - a.totalinteraction)
      .slice(0, 10);

    return this.setState({
      ghoshakPoints: userArray_ghoshakPoints,
      loyaltyPoints: userArray_loyaltyPoints,
      top10GhoshakPoints: top10_ghoshaktransaction,
      top10LoyaltyPoints: top10_loyaltytransaction,
      showData: true
    });
  }

  viewTable(items) {
    this.props.renderTable(items, [
      "addedPts",
      "currentPts",
      "redeemedPts",
      "Interaction/Transaction"
    ]);
  }
  render() {
    return (
      <>
        {this.state.showData && (
          <>
            <Row style={{ width: 100 + "%", justifyContent: "center" }}>
              <b style={{ fontSize: 32 }}>Interaction / Transaction</b>
            </Row>
            <Row style={{ width: 100 + "%", justifyContent: "center" }}>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  marginTop: 3 + "%"
                }}
              >
                <h3 style={{ textAlign: "center" }}> GHOSHAK POINTS</h3>
              </div>
              <Col
                style={{ textAlign: "center" }}
                className="division"
                xs={{ span: 3, offset: 1 }}
              >
                <p>View All Customers Redeemed Ghoshak Points</p>
                <Button
                  onClick={() => {
                    this.viewTable(this.state.ghoshakPoints);
                  }}
                >
                  view
                </Button>
              </Col>
              <Col
                style={{ textAlign: "center" }}
                className="division"
                xs={{ span: 3, offset: 1 }}
              >
                <p>Top 10 Customers Redeemed Ghoshak Points</p>
                <Button
                  onClick={() => {
                    this.viewTable(this.state.top10GhoshakPoints);
                  }}
                >
                  View
                </Button>
              </Col>
            </Row>
            <Row
              style={{
                width: 100 + "%",
                justifyContent: "center",
                marginTop: 3 + "%"
              }}
            >
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%"
                }}
              >
                <h3 style={{ textAlign: "center" }}> LOYALTY POINTS</h3>
              </div>
              <Col
                style={{ textAlign: "center" }}
                className="division"
                xs={{ span: 3, offset: 1 }}
              >
                <p>View All Customers Redeemed Loyalty Points</p>
                <Button
                  onClick={() => {
                    this.viewTable(this.state.loyaltyPoints);
                  }}
                >
                  view
                </Button>
              </Col>
              <Col
                style={{ textAlign: "center" }}
                className="division"
                xs={{ span: 3, offset: 1 }}
              >
                <p>Top 10 Customers Redeemed Loyalty Points</p>
                <Button
                  onClick={() => {
                    this.viewTable(this.state.top10LoyaltyPoints);
                  }}
                >
                  View
                </Button>
              </Col>
            </Row>
            {/* <Row>
              <div style={{ width: 100 + "%", textAlign: "center" }}>
                <b> REDEEMED CUSTOMERS</b>
              </div>
              <div style={{ width: 100 + "%", display: "flex" }}>
                <Col>
                  <h3 style={{ textAlign: "center" }}>
                    <Button
                      onClick={() => {
                        this.viewTable(this.state.ghoshakPoints);
                      }}
                    >
                      VIEW
                    </Button>
                  </h3>
                </Col>
                <Col>
                  <h3 style={{ textAlign: "center" }}>
                    <Button
                      onClick={() => {
                        this.viewTable(this.state.top10GhoshakPoints);
                      }}
                    >
                      TOP 10 CUSTOMER GHOSHAK POINTS
                    </Button>
                  </h3>
                </Col>
              </div>
            </Row> */}

            {/* <Row>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%"
                }}
              >
                <h3 style={{ textAlign: "center" }}> LOYALTY POINTS</h3>
              </div>
              <b> REDEEMED CUSTOMERS</b>
              <Col>
                <h3 style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      this.viewTable(this.state.loyaltyPoints);
                    }}
                  >
                    VIEW
                  </Button>
                </h3>
              </Col>
              <Col>
                <h3 style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      this.viewTable(this.state.top10LoyaltyPoints);
                    }}
                  >
                    TOP 10 CUSTOMER LOYALTY POINTS
                  </Button>
                </h3>
              </Col>
            </Row> */}
          </>
        )}
      </>
    );
  }
}
