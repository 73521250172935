import React, { Component } from "react";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { Auth } from "aws-amplify";
import config from "../config";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class ExtendOfferEndDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [{ id: undefined, customerId: undefined }],
      custdId: "",
      updatedBy: "",
      offerResults: [],
      offerExpiryDate1: "",
      offerExpiryDate2: "",
      offerType: "offer",
      userId10Digit: [],
      buttonDisable: false,
      showImportExcel: true,
      updateExpiryDate: "",
      reviewedOn: new Date().toISOString().substring(0, 10),
      reviewedBy: "",
    };
    this.fileHandler = this.fileHandler.bind(this);

    this.reset = this.state;
    this.handleDelete = this.handleDelete.bind(this);
    this.fetchOffers = this.fetchOffers.bind(this);
    this.onChange = this.onChange.bind(this);
    this.textAreaOnchange = this.textAreaOnchange.bind(this);

    this.updateOfferEndDate = this.updateOfferEndDate.bind(this);
  }
  fileHandler = (event) => {
    let fileObj = event.target.files[0];

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let response = resp.rows.map((elements) => {
          return elements[0].toString().trim();
        });
        this.setState({
          userId10Digit: response,
        });
      }
    });
  };
  textAreaOnchange(e) {
    this.multilineTextarea.style.height = "auto";

    this.multilineTextarea.style.height =
      this.multilineTextarea.scrollHeight + "px";
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async componentDidMount() {
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({
          reviewedBy: user.attributes.phone_number,
        });
      })

      .catch((err) => console.log(err));
  }
  fetchOffers(e) {
    e.preventDefault();

    Promise.all([
      fetch(`${config.ApiGateway}/getExpiryOffers`, {
        method: "POST",
        body: JSON.stringify({
          userId10Digit: this.state.userId10Digit,
          offerExpiryDate1: this.state.offerExpiryDate1,
          offerExpiryDate2: this.state.offerExpiryDate2,
          offerType: this.state.offerType,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        this.setState({
          offerResults: data,
          showImportExcel: false,
        });
      })
      .catch((err) => alert(err));
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  updateOfferEndDate(e) {
    e.preventDefault();
    Promise.all([
      fetch(`${config.ApiGateway}/extendOfferDate`, {
        method: "POST",
        body: JSON.stringify({
          offerResults: this.state.offerResults,
          updateExpiryDate: this.state.updateExpiryDate,
          reviewedBy: this.state.reviewedBy,
          reviewedOn: this.state.reviewedOn,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert(data.response);

        this.setState(this.reset);
        this.componentDidMount();
      })
      .catch((err) => alert(err));
  }

  handleDelete(i) {
    this.setState({
      offerResults: this.state.offerResults.filter(
        (tags, index) => index !== i
      ),
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Container fluid>
        <>
          <div className="headingStyle">
            <h3>EXTEND OFFER END DATE</h3>
          </div>

          <Row>
            <Col xs={12}>
              <div
                style={{
                  backgroundColor: "#fafafa",

                  margin: 1 + "%",
                }}
              >
                <h3 style={{ textAlign: "center" }}> ADD CUSTOMER</h3>
              </div>

              {this.state.showImportExcel ? (
                <>
                  <Form onSubmit={this.fetchOffers}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label
                          style={{ width: 100 + "%", textAlign: "center" }}
                        >
                          EXPIRY DATE RANGE
                        </Form.Label>
                        <Row>
                          <Col xs={6}>
                            <Form.Control
                              type="date"
                              name="offerExpiryDate1"
                              onChange={this.onChange}
                              value={this.state.offerExpiryDate1}
                            ></Form.Control>
                          </Col>
                          <Col xs={6}>
                            <Form.Control
                              type="date"
                              name="offerExpiryDate2"
                              onChange={this.onChange}
                              value={this.state.offerExpiryDate2}
                              min={this.state.offerExpiryDate1}
                              required
                            ></Form.Control>
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label
                          style={{ width: 100 + "%", textAlign: "center" }}
                        >
                          OFFER TYPE
                        </Form.Label>
                        <Row>
                          <Col xs={{ span: 4, offset: 1 }}>
                            <Form.Check
                              label="OFFER"
                              type="radio"
                              name="offerType"
                              onChange={this.onChange}
                              value="offer"
                            />
                          </Col>
                          <Col xs={4}>
                            <Form.Check
                              label="COLLECTION"
                              type="radio"
                              name="offerType"
                              onChange={this.onChange}
                              value="item"
                            />
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label
                          style={{ width: 100 + "%", textAlign: "center" }}
                        >
                          IMPORT EXCEL FILE
                        </Form.Label>
                        <Row>
                          <Col xs={6}>
                            <input
                              type="file"
                              required
                              onChange={this.fileHandler.bind(this)}
                              // style={{ padding: "10px" }}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Form.Row>
                    <Col>
                      <Button type="submit">GET OFFER</Button>
                    </Col>
                  </Form>
                </>
              ) : (
                <>
                  <Form onSubmit={this.updateOfferEndDate}>
                    <Row
                      style={{ marginTop: 20 + "px", marginBottom: 20 + "px" }}
                    >
                      <Col xs={2}>
                        <Form.Label>UPDATE EXPIRY DATE</Form.Label>
                      </Col>
                      <Col xs={2}>
                        <Form.Control
                          type="date"
                          name="updateExpiryDate"
                          onChange={this.onChange}
                          value={this.state.updateExpiryDate}
                          required
                        ></Form.Control>
                      </Col>

                      <Col>
                        <Button type="submit">UPDATE</Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
              {this.state.offerResults.length > 0 && (
                <>
                  <h4>
                    OFFER END DATE{" "}
                    <b>
                      {this.state.offerExpiryDate1}
                      {" - "}
                      {this.state.offerExpiryDate1}
                    </b>
                  </h4>
                  <Table
                    style={{
                      width: 100 + "%",
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid",
                    }}
                    size="medium"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          S NO
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          OFFER IMAGE
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          USER ID
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          OFFER DETAILS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          OFFER TYPE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          OFFER STATUS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          SHOP PINCODE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          APPROVED STATUS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          OFFER END DATE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          ACTION
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.offerResults.map((item, index) => (
                        <>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              {index + 1}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <img
                                // src={JSON.parse(item.offerImageLocation)}
                                src={item.offerImageLocation}
                                alt={item.offerImageLocation}
                                width="200"
                                height="200"
                              ></img>
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {item.userId10Digit}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.offerDetails}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.offerType}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.offerStatus}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.userPincode}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.approvedStatus}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.offerEndDate}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Button onClick={() => this.handleDelete(index)}>
                                <HighlightOffIcon color="secondary" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </Col>
          </Row>
        </>
      </Container>
    );
  }
}
ExtendOfferEndDate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExtendOfferEndDate);
