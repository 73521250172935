import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import config from "../../config";
export default class TopCustomer extends Component {
  constructor(props) {
    super(props);
    this.handleFunction = this.handleFunction.bind(this);
    this.state = {
      top10customerBypoints: [],
      showData: false
    };
  }
  getCustomerDetails(userid) {
    this.props.getCustomer(userid);
  }

  async componentDidMount() {
    await this.handleFunction();
  }
  handleFunction() {
    var user = [];
    var userArray = [];

    const customerid = this.props.customer.map(elements => {
      return elements.customerid;
    });

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            constant_score: {
              filter: {
                bool: {
                  should: [{ terms: { userId: customerid } }]
                }
              }
            }
          }
        }),
        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        user = data.hits.hits.map(element => element._source);

        user.forEach(elements => {
          this.props.customer.forEach(items => {
            if (elements.userId === items.customerid) {
              userArray.push(
                Object.assign({}, items, {
                  userId: elements.userId,
                  userId10Digit: elements.userId10Digit,
                  userName: elements.userName,
                  userPhoneNumber: elements.userPhoneNumber,
                  userPincode: elements.userPincode,
                  userAddress: elements.userAddress
                })
              );
            }
          });
        });

        var top_customer = userArray
          .sort((a, b) => b.points - a.points)
          .slice(0, 10);
        return this.setState({
          top10customerBypoints: top_customer,
          showData: true
        });
      })
      .catch(err => console.log(err));
  }

  render() {
    var i = 1;
    return (
      <>
        {this.state.showData && (
          <>
            <h3
              style={{
                backgroundColor: "#fafafa",
                width: 100 + "%",
                margin: 1 + "%",
                textAlign: "center"
              }}
            >
              TOP CUSTOMERS BY REDEEMED POINTS(GHOSHAK & LOYALTY)
            </h3>
            <div className="division">
              <Table
                style={{
                  // width: 100 + "%",
                  border: 0.5 + "px",
                  borderColor: "#ced4da",
                  borderStyle: "solid"
                }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S NO</TableCell>
                    <TableCell align="center">SHOP ID</TableCell>
                    <TableCell align="center">CUSTOMER NAME</TableCell>
                    <TableCell align="center">PINCODE</TableCell>

                    <TableCell align="center">PHONE NUMBER</TableCell>
                    <TableCell align="center">USER ADDRESS</TableCell>
                    <TableCell align="center">REDEEMED POINTS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.top10customerBypoints.map(row => (
                    <TableRow>
                      <TableCell align="center">{i++}</TableCell>
                      <TableCell align="center">
                        {" "}
                        <Button
                          variant="link"
                          onClick={() => {
                            this.getCustomerDetails(row.userId10Digit);
                          }}
                        >
                          {row.userId10Digit}
                        </Button>
                      </TableCell>
                      <TableCell align="center">{row.userName}</TableCell>
                      <TableCell align="center">{row.userPincode}</TableCell>

                      <TableCell align="center">
                        {row.userPhoneNumber}
                      </TableCell>
                      <TableCell align="center">{row.userAddress}</TableCell>
                      <TableCell align="center">{row.points}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        )}
      </>
    );
  }
}
