import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { ProSidebar, Menu, MenuItem, SidebarFooter } from "react-pro-sidebar";
import LinkPartnerToShop from "./linkPartnerToShop";
import ListShops from "./listShops";
import OrderStatus from "./orderStatus";

const Dashboard = () => {
  const [selectedPage, setSelectedPage] = useState("");
  useEffect(() => {
    setSelectedPage("linkPartner");
  }, []);
  return (
    <Container fluid style={{ display: "flex" }}>
      <div style={{ width: "20%", minHeight: "90vh" }}>
        <ProSidebar width="100%">
          <Menu iconShape="square">
            <MenuItem
              active={selectedPage === "linkPartner" ? true : false}
              onClick={() => setSelectedPage("linkPartner")}
            >
              Link Delivery Partner to Shop
            </MenuItem>
            <MenuItem
              active={selectedPage === "deliveryAdded" ? true : false}
              onClick={() => setSelectedPage("deliveryAdded")}
            >
              Delivery Partner Integrated Shops
            </MenuItem>
            <MenuItem
              active={selectedPage === "orderStatus" ? true : false}
              onClick={() => setSelectedPage("orderStatus")}
            >
              Order Status
            </MenuItem>
          </Menu>
        </ProSidebar>
      </div>
      <div style={{ width: "80%" }}>
        {selectedPage === "linkPartner" && <LinkPartnerToShop />}
        {selectedPage === "deliveryAdded" && <ListShops />}
        {selectedPage === "orderStatus" && <OrderStatus />}
      </div>
    </Container>
  );
};

export default Dashboard;
