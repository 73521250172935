import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import config from "../../config";
export default class CustomerCoupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ghoshakCoupon: [],
      merchantCoupon: [],
      loyaltyCoupon: [],
      promoCoupon: []
    };
    this.couponFunction = this.couponFunction.bind(this);
  }
  async componentDidMount() {
    await this.couponFunction();
  }
  couponFunction() {
    var couponid = [];
    var ghoshakCouponArray = [];
    var merchantCouponArray = [];
    var consumedCustomers = 0;
    var consumedCustomerValue = 0;

    this.props.coupons[1].map(elements => {
      consumedCustomers = 0;
      var consumedCustomerValue = 0;
      this.props.coupons[0].map(items => {
        if (elements === items.pincode) {
          consumedCustomers += parseInt(items.consumed);
          consumedCustomerValue += parseInt(items.consumedvalue);
        }
      });

      ghoshakCouponArray.push({
        pincode: elements,
        consumed: consumedCustomers,
        consumedvalue: consumedCustomerValue
      });
    });
    var loyaltyCouponArray = [];
    this.props.coupons[5].map(elements => {
      consumedCustomers = 0;
      var consumedCustomerValue = 0;
      this.props.coupons[4].map(items => {
        if (elements === items.pincode) {
          consumedCustomers += parseInt(items.consumed);
          consumedCustomerValue += parseInt(items.consumedvalue);
        }
      });

      loyaltyCouponArray.push({
        pincode: elements,
        consumed: consumedCustomers,
        consumedvalue: consumedCustomerValue
      });
    });
    let promoCouponArray = [];
    this.props.coupons[7].map(elements => {
      consumedCustomers = 0;
      var consumedCustomerValue = 0;
      this.props.coupons[6].map(items => {
        if (elements === items.pincode) {
          consumedCustomers += parseInt(items.consumed);
          consumedCustomerValue += parseInt(items.consumedvalue);
        }
      });

      promoCouponArray.push({
        pincode: elements,
        consumed: consumedCustomers,
        consumedvalue: consumedCustomerValue
      });
    });

    this.props.coupons[3].map(elements => {
      consumedCustomers = 0;
      var consumedCustomerValue = 0;
      this.props.coupons[2].map(items => {
        if (elements === items.pincode) {
          consumedCustomers += parseInt(items.consumed);
          consumedCustomerValue += parseInt(items.consumedvalue);
        }
      });
      merchantCouponArray.push({
        pincode: elements,
        consumed: consumedCustomers,
        consumedvalue: consumedCustomerValue
      });
    });
    console.log();
    return this.setState({
      showData: true,
      ghoshakCoupon: ghoshakCouponArray,
      merchantCoupon: merchantCouponArray,
      loyaltyCoupon: loyaltyCouponArray,
      promoCoupon: promoCouponArray
    });
  }
  render() {
    var j = 1;
    return (
      <>
        {this.state.showData && (
          <>
            <Row className="division">
              <Col>
                <div
                  style={{
                    backgroundColor: "#fafafa",
                    width: 100 + "%",
                    margin: 1 + "%"
                  }}
                >
                  <h3 style={{ textAlign: "center" }}> GHOSHAK COUPONS</h3>
                </div>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.7 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid"
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">PINCODE</TableCell>
                      <TableCell align="center">COUPONS CONSUMED</TableCell>
                      <TableCell align="center">
                        COUPONS CONSUMED VALUE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.ghoshakCoupon.map((rows, index) => (
                      <TableRow key={rows.userPincode}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{rows.pincode}</TableCell>
                        <TableCell align="center">{rows.consumed}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {rows.consumedvalue}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Col>
            </Row>
            <Row className="division">
              <Col>
                <div
                  style={{
                    backgroundColor: "#fafafa",
                    width: 100 + "%",
                    margin: 1 + "%"
                  }}
                >
                  <h3 style={{ textAlign: "center" }}> MERCHANT COUPONS</h3>
                </div>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid"
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">PINCODE</TableCell>
                      <TableCell align="center">COUPONS CONSUMED</TableCell>
                      <TableCell align="center">
                        COUPONS CONSUMED VALUE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.merchantCoupon.map((rows, index) => (
                      <TableRow key={rows.userPincode}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{rows.pincode}</TableCell>
                        <TableCell align="center">{rows.consumed}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {rows.consumedvalue}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Col>
            </Row>

            <Row className="division">
              <Col>
                <div
                  style={{
                    backgroundColor: "#fafafa",
                    width: 100 + "%",
                    margin: 1 + "%"
                  }}
                >
                  <h3 style={{ textAlign: "center" }}> LOYALTY COUPONS</h3>
                </div>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid"
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">PINCODE</TableCell>
                      <TableCell align="center">COUPONS CONSUMED</TableCell>
                      <TableCell align="center">
                        COUPONS CONSUMED VALUE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.loyaltyCoupon.map((rows, index) => (
                      <TableRow key={rows.userPincode}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{rows.pincode}</TableCell>
                        <TableCell align="center">{rows.consumed}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {rows.consumedvalue}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Col>
            </Row>

            <Row className="division">
              <Col>
                <div
                  style={{
                    backgroundColor: "#fafafa",
                    width: 100 + "%",
                    margin: 1 + "%"
                  }}
                >
                  <h3 style={{ textAlign: "center" }}> PROMOCODE COUPONS</h3>
                </div>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid"
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">PINCODE</TableCell>
                      <TableCell align="center">COUPONS CONSUMED</TableCell>
                      <TableCell align="center">
                        COUPONS CONSUMED VALUE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.promoCoupon.map((rows, index) => (
                      <TableRow key={rows.userPincode}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{rows.pincode}</TableCell>
                        <TableCell align="center">{rows.consumed}</TableCell>
                        <TableCell align="center">
                          {" "}
                          {rows.consumedvalue}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}
