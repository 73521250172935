import React, { useState, useRef } from "react";
// import { B2BPackageAPI } from "../handler/apiHandler";
import Validation from "../handler/validation";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Badge from "react-bootstrap/Badge";
import { B2BPackageAPI } from "../handler/apiHandler";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";

let initialState = {
  packageStatus: "no",
  packageExpiryDate: "",
  packageUpdatedby: "NA",
  packageTransactionDetails: "",
  userId: "",
  key: "",
  notes: "",
  title: "NA",
};

export default function PackageUpdate({ classes, profile, packages, crmUser }) {
  const [state, setState] = useState({});
  const todayDate = new Date().toISOString().substring(0, 10); // Today date
  const [modalState, setModalState] = useState(false); // Modal State
  let [validationHandler] = Validation();
  const [errorMessage, setErrorMessage] = useState("");
  const preventDoubleClick = useRef();
  const closeModal = () => {
    // close modal and reset to initial state

    setModalState(false);
    setState(initialState);
  };
  const updatePackageByKey = (item) => {
    //Update package by key -- appointment,website, loyalty and  delivery

    setModalState(true);
    setState({
      key: item.key,
      title: item.title,
      packageStatus: item.packageStatus,
      packageExpiryDate: item.packageExpiryDate,
    });
  };

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const updatePackage = async (e) => {
    e.preventDefault();
    preventDoubleClick.current.disabled = true; // prevent user double click from submission
    // update package details in database through rest api
    let mappedFields = await mapPackageFields(state);
    let validatedResults = await validationHandler(state.key, mappedFields);

    if (validatedResults.status) {
      await B2BPackageAPI(validatedResults.uploadObject).then((data) => {
        console.log(data, "lambda call");
        alert("Package Updated");
      });
    } else {
      alert("Package Update failed");
    }

    preventDoubleClick.current.removeAttribute("disabled");
  };

  const mapPackageFields = async (packageObject) => {
    let { key } = packageObject;

    let input = {};
    switch (key) {
      case "membership":
        input.userId = profile.userId;
        return {};
      case "delivery":
        input.userId = profile.userId;
        input.deliveryPackage = state.packageStatus;
        input.deliveryExpiryDate = state.packageExpiryDate;
        input.deliveryTransactionId = state.packageTransactionDetails;
        input.notes = state.notes;
        input.noteName = "deliveryNotes";
        input.deliveryUpdatedBy = crmUser;
        input.deliveryUpdatedOn = new Date().toISOString().substr(0, 10);
        return input;
      case "appointment":
        input.userId = profile.userId;
        input.appointmentPackage = state.packageStatus;
        input.appointmentExpiryDate = state.packageExpiryDate;
        input.appointmentTransactionId = state.packageTransactionDetails;
        input.notes = state.notes;
        input.appointmentUpdatedBy = crmUser;
        input.appointmentUpdatedOn = new Date().toISOString().substr(0, 10);
        input.noteName = "appointmentNotes";
        return input;
      case "website":
        input.userId = profile.userId;
        input.websiteStatus = state.websiteStatus == "yes" ? "live" : "expired";
        input.websiteExpiryOn = state.packageExpiryDate;
        input.websitePaymentId = state.packageTransactionDetails;
        input.notes = state.notes;
        input.websiteUpdatedBy = crmUser;
        input.websiteActivatedOn = new Date().toISOString().substr(0, 10);
        input.noteName = "websiteNotes";
        return input;
      case "loyalty":
        input.userId = profile.userId;
        input.loyaltyPackage = state.packageStatus;
        input.loyaltyExpiryDate = state.packageExpiryDate;
        input.loyaltyTransactionid = state.packageTransactionDetails;
        input.notes = state.notes;
        input.loyaltyupdatedBy = crmUser;
        input.loyaltyupdatedOn = new Date().toISOString().substr(0, 10);
        input.noteName = "loyaltyNotes";

        return input;
      case "billing":
        input.userId = profile.userId;
        input.billingPackage = state.packageStatus;
        input.billingExpiryDate = state.packageExpiryDate;
        input.billingTransactionid = state.packageTransactionDetails;
        input.notes = state.notes;
        input.billingUpdatedBy = crmUser;
        input.billingupdatedOn = new Date().toISOString().substr(0, 10);
        input.noteName = "billingNotes";

        return input;
      default:
        return input;
    }
  };

  return (
    <>
      <div className="shopCardRow">
        {packages.map((items) => {
          return (
            <>
              <div key={items.key} className="shopCardColumn">
                <p className="subHeader">{items.title} </p>
                <ul className="horizontalList">
                  <li id="noBorder" className="horizontalListItem">
                    <h6>Package Status</h6>
                    <span>
                      {items.packageStatus == "yes" ||
                      items.packageStatus == "live" ? (
                        <Badge style={{ color: "#fff" }} pill variant="success">
                          Active
                        </Badge>
                      ) : (
                        <Badge style={{ color: "#fff" }} pill variant="danger">
                          Expired
                        </Badge>
                      )}
                    </span>
                  </li>
                  <li id="noBorder" className="horizontalListItem">
                    <h6>Expiry Date</h6>
                    <span>
                      {typeof items.packageExpiryDate == "undefined"
                        ? "NA"
                        : items.packageExpiryDate}
                    </span>
                  </li>

                  <li id="noBorder" className="horizontalListItem">
                    <h6>Expiration Days</h6>
                    <span>
                      {items.packageStatus == "yes" ? (
                        <>
                          {todayDate < items.packageExpiryDate
                            ? `${items.packageExpiryDate} (${Math.abs(
                                moment(todayDate).diff(
                                  moment(items.packageExpiryDate),
                                  "days"
                                )
                              )} days remaining)`
                            : "Expired"}
                        </>
                      ) : (
                        "Expired"
                      )}
                    </span>
                  </li>
                  {items.editAction && (
                    <li
                      id="noBorder"
                      style={{ flex: "1 1 auto !important" }}
                      className="horizontalListItem"
                    >
                      <h6>Action</h6>
                      <span>
                        <button
                          className="editButton"
                          onClick={() => updatePackageByKey(items)}
                        >
                          <EditTwoToneIcon />
                        </button>
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </>
          );
        })}
      </div>
      <Modal
        className="modal-order-coupon"
        show={modalState}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`${state.title} Package`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <form onSubmit={updatePackage}>
              <div className="fieldBox">
                <>
                  <div id="twoCol" className="fieldWrap">
                    <label>Package Status</label>
                    <div key={`inline-radio`} className="mb-3">
                      <Form.Check
                        inline
                        label="Activate"
                        name="packageStatus"
                        type="radio"
                        onChange={(e) => handleChange("packageStatus", "yes")}
                        id={`inline-radio-1`}
                        checked={state.packageStatus === "yes"}
                      />
                      <Form.Check
                        inline
                        label="Deactivate"
                        name="packageStatus"
                        type="radio"
                        onChange={(e) => handleChange("packageStatus", "no")}
                        id={`inline-radio-2`}
                        checked={state.packageStatus === "no"}
                      />
                    </div>
                  </div>
                  <div id="twoCol" className="fieldWrap">
                    <label id="required">Transaction ID</label>
                    <input
                      name="packageTransactionDetails"
                      className={"flexInputBox"}
                      onChange={(e) =>
                        handleChange(
                          "packageTransactionDetails",
                          e.target.value
                        )
                      }
                      required
                      value={state.packageTransactionDetails}
                    ></input>
                  </div>
                  <div id="twoCol" className="fieldWrap">
                    <label id="required">Expiry Date</label>
                    <input
                      type="date"
                      className={"flexInputBox"}
                      onChange={(e) =>
                        handleChange("packageExpiryDate", e.target.value)
                      }
                      required
                      value={state.packageExpiryDate}
                    ></input>
                  </div>
                  <div id="twoCol" className="fieldWrap">
                    <label id="required">Notes</label>
                    <textarea
                      className={"flexInputBox"}
                      value={state.notes}
                      // required
                      onChange={(e) => handleChange("notes", e.target.value)}
                      title="Please fill notes"
                    ></textarea>
                  </div>
                  <div className="flexBoxEnd">
                    <div className="updateButton">
                      <button
                        type="submit"
                        id="success"
                        ref={preventDoubleClick}
                        className="actionButton"
                      >
                        Update Package
                      </button>
                    </div>
                    <div className="updateButton">
                      <button
                        onClick={() => {
                          closeModal();
                          // alert("cancelled");
                        }}
                        id="danger"
                        className="actionButton"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              </div>
            </form>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
