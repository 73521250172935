import React, { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import S3 from "react-aws-s3";
import uuid from "uuid/dist/v4";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import config from "../../config";
// import template from "./template.json";
import { ThreeDots, RotatingLines } from "react-loader-spinner";

const AboutUs = (props) => {
  const ref = useRef();
  const [header, setHeader] = useState("");
  const [description, setDescription] = useState("");
  const [whatsAppToggle, setWhatsAppToggle] = useState(false);
  const [whatsAppNumber, setWhatsAppNumber] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [websiteTemplate, setWebsiteTemplate] = useState();
  const [saving, setSaving] = useState(false);
  const [active, setActive] = useState(true);
  const [templateId, setTemplateId] = useState("aboutUs_001");
  const [templateName, setTemplateName] = useState("Classic Landing");
  const [pageLoading, setPageLoading] = useState(false);
  const fetchDetails = async () => {
    await fetchUserDetails(props.shopId).then((data) => {
      setPageLoading(true);
      var shopData = data[0];
      if (shopData.websiteTemplate) {
        setWebsiteTemplate(shopData.websiteTemplate);
        var websiteData = shopData.websiteTemplate;
        if (websiteData.aboutUs) {
          setActive(
            websiteData.aboutUs.active ? websiteData.aboutUs.active : active
          );
          setTemplateId(
            websiteData.aboutUs.templateId
              ? websiteData.aboutUs.templateId
              : templateId
          );
          setTemplateName(
            websiteData.aboutUs.templateName
              ? websiteData.aboutUs.templateName
              : templateName
          );
          var pageProps = websiteData.aboutUs.pageProps;
          setHeader(pageProps.heading);
          setDescription(pageProps.description);
          setWhatsAppNumber(pageProps.whatsappNumber);
          if (pageProps.whatsappSwitch)
            setWhatsAppToggle(pageProps.whatsappSwitch);
          setImageUrl(pageProps.imageUrl);
          setImageName(pageProps.imgName);
        }
      }
      setPageLoading(false);
    });
  };
  useEffect(() => {
    fetchDetails();
  }, [props]);

  const handleSave = async () => {
    console.log(whatsAppNumber, whatsAppToggle, description, header, imageUrl);
    setSaving(true);
    let dataObj = {};
    if (websiteTemplate) {
      dataObj = {
        ...websiteTemplate,
      };
      if (websiteTemplate.aboutUs) {
        dataObj = {
          ...dataObj,
          aboutUs: {
            ...websiteTemplate.aboutUs,
            active: active,
            templateId: templateId,
            templateName: templateName,
            pageProps: {
              whatsappNumber: whatsAppNumber,
              whatsappSwitch: whatsAppToggle,
              description: description,
              heading: header,
              imageUrl: imageUrl,
              imgName: imageName,
              imageUri: imageUrl,
            },
          },
        };
      } else {
        dataObj = {
          ...dataObj,
          aboutUs: {
            active: active,
            templateId: templateId,
            templateName: templateName,
            pageProps: {
              whatsappNumber: whatsAppNumber,
              whatsappSwitch: whatsAppToggle,
              description: description,
              heading: header,
              imageUrl: imageUrl,
              imgName: imageName,
              imageUri: imageUrl,
            },
          },
        };
      }
    } else {
      dataObj = {
        colorTheme: "default",
        order: [
          "aboutUs",
          "about",
          "onlineProduct",
          "ourServices",
          "ourProjects",
          "ourBrands",
          "ourClients",
          "ourGallery",
          "clientTestimonials",
          "videoGallery",
          "socialMedia",
          "ourPartner",
          "ourTeam",
        ],
        aboutUs: {
          pageProps: {
            whatsappNumber: whatsAppNumber,
            whatsappSwitch: whatsAppToggle,
            description: description,
            heading: header,
            imageUrl: imageUrl,
            imgName: imageName,
            imageUri: imageUrl,
          },
          active: active,
          templateId: templateId,
          templateName: templateName,
        },
      };
    }
    await contentupload(dataObj, props.shopId).then((data) => {
      fetchDetails();
      setSaving(false);
    });
  };

  const handleDelete = async (key) => {
    var dataObj = {
      ...websiteTemplate,
      aboutUs: {
        ...websiteTemplate.aboutUs,
        pageProps: {
          ...websiteTemplate.aboutUs.pageProps,
          imageUrl: "",
          imageUri: "",
          imgName: "",
        },
      },
    };
    await contentupload(dataObj, props.shopId).then(async (data) => {
      await fetchDetails();
    });
  };

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h3>Welcome Page</h3>
        <strong>
          <label>Show Welcome Page</label>
        </strong>{" "}
        <FormControlLabel
          control={
            <Switch
              checked={active}
              onChange={() => setActive(!active)}
              color="primary"
            />
          }
        ></FormControlLabel>
        <div>
          <strong>
            <label>Add Header</label>
          </strong>
          <br />
          <textarea
            value={header}
            placeholder="Enter Welcome Page Header"
            onChange={(e) => setHeader(e.target.value)}
            rows="3"
            cols="40"
          />
        </div>
        <div>
          <strong>
            <label>Add Description</label>
          </strong>
          <br />
          <textarea
            value={description}
            placeholder="Enter Welcome Page Description"
            onChange={(e) => setDescription(e.target.value)}
            rows="6"
            cols="40"
          />
        </div>
        <div>
          <strong>
            <label>Upload Image</label>
          </strong>
          <br />
          <input
            ref={ref}
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={async (e) => {
              setLoading(true);
              console.log("Image File", e.target.files[0]);
              var data = await handleImageUpload(e.target.files[0]);
              var imageLink = `https://ghoshak-website-builder.s3-ap-south-1.amazonaws.com/${data.key}`;
              setImageUrl(imageLink);
              setImageName(data.key);
              setLoading(false);
            }}
          />
        </div>
        <div style={{ marginLeft: "35%" }}>
          {imageUrl && (
            <div
              style={{
                margin: "10px",
                width: "40%",
                boxShadow: "5px 5px 20px 1px #d4d4d4",
                borderRadius: "5px",
              }}
            >
              <img src={imageUrl} alt="Image" width="100" height="100" />
              <Button
                variant="outline-danger"
                size="sm"
                style={{ float: "right", margin: "10px" }}
                onClick={() => handleDelete()}
              >
                Delete
              </Button>
            </div>
          )}
        </div>
        <div>
          <strong>
            <label>Show WhatsApp Chat</label>
          </strong>{" "}
          <FormControlLabel
            control={
              <Switch
                checked={whatsAppToggle}
                onChange={() => setWhatsAppToggle(!whatsAppToggle)}
                color="primary"
              />
            }
          ></FormControlLabel>
        </div>
        {whatsAppToggle && (
          <div>
            <TextField
              style={{ width: "21%" }}
              placeholder="Enter Your whats app number"
              onChange={(e) => setWhatsAppNumber(e.target.value)}
              value={whatsAppNumber}
              InputProps={{
                startAdornment: <InputAdornment>+91</InputAdornment>,
              }}
            />
          </div>
        )}
        <div>
          <strong>
            <label>Choose Template</label>
          </strong>
          <br />
          <select
            onChange={(e) => {
              setTemplateId(e.target.value);
              setTemplateName(e.target.name);
            }}
            value={templateId}
            style={{
              width: "300px",
            }}
          >
            <option value="" disabled>
              Select a template
            </option>
            <option value="aboutUs_001" name="Classic Landing">
              Classic Landing
            </option>
            <option value="aboutUs_002" name="Parallax scroll">
              Parallax scroll
            </option>
            <option value="aboutUs_003" name="Highlighter">
              Highlighter
            </option>
            <option value="cabBooking" name="Cabs">
              Cab Booking
            </option>
          </select>{" "}
        </div>
        <div style={{ margin: "20px" }}>
          <Button disabled={loading} onClick={() => handleSave()}>
            {saving ? (
              <RotatingLines height="20" width="20" radius="9" color="white" />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

const fetchUserDetails = async (shopId) => {
  if (shopId === "") return alert("Enter valid Shop Id");
  return await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
      method: "POST",
      body: JSON.stringify({
        userId10Digit: shopId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data[0].websiteTemplate);
      return data;
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("Something went wrong, please try again");
    });
};

const contentupload = async (obj, userId) => {
  await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainContentUpload`, {
      method: "POST",
      body: JSON.stringify({
        obj: obj,
        userId: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data);
      alert("uploaded success");
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("error while uploading data. Please try again");
    });
};

const handleImageUpload = async (image) => {
  const configSettings = {
    bucketName: "ghoshak-website-builder",
    region: "ap-south-1",
    accessKeyId: config.AccessKeyId,
    secretAccessKey: config.SecretAccessKey,
  };
  var fileName = uuid() + ".jpeg";
  var S3Client = new S3(configSettings);
  let data = await S3Client.uploadFile(image, fileName)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("S3 Error", err);
    });
  return data;
};

export default AboutUs;
