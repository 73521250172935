//import dependencies
import React, {
  useState,
  useEffect,
  useRef,
  createRef,
  useContext,
} from "react";
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
// import { GetPincode, GetShop } from "../services/constant";
import BarGraph from "./barchart";
import config from "../config";
import Dropdown from "../dropDown";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { CSVLink, CSVDownload } from "react-csv";

import { SignIn } from "aws-amplify-react"; // or 'aws-amplify-react-native'

import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays, format } from "date-fns";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import CircularProgress from "@mui/material/CircularProgress";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// import CSVLink from 'react-csv';

import * as XLSX from 'xlsx';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Dashboard(props) {
  const dempa = "2022-01-01"
  const dempb = "2022-01-31"
  const [getTestData, setGetTestData] = useState([]);
  const getTesterData = () => {
    fetch(
      "https://payload-response.s3.ap-south-1.amazonaws.com/testAccount.json"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setGetTestData(data.testAccount);
      });
  };

  useEffect(() => {
    getTesterData();
  }, []);

  const testdata = [];

  getTestData.forEach((items) => {
    testdata.push(items.userPhoneNumber);
  });
  // date state
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const [range, setRange] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [tempRange, setTempRange] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    console.log("range", range)
  }, [range])

  const processApply = () => {

    setRange(tempRange);
    setShow(false);
    setIsLoading(true);
  };

  // open close
  const [open, setOpen] = useState(false);

  // get the target element to toggle
  const refOne = useRef(null);
  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);
  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };
  const classes = useStyles();

  const datakey = JSON.parse(localStorage.getItem("userData"));

  const key = `${datakey.username}.JSON`;

  let initialData = {
    shopTypeandshopId: [],
    shops: [],
    allShops: [],
    customers: [],
    couponsCreated: [],
    redeemedCoupons: [],
    orders: [],
    generatedBills: [],
    offers: [],
    collection: [],
  };

  const [isLoading, setIsLoading] = useState(true); // Loading State
  const [refShop, setRefShop] = useState([]);
  const [userDetails, setUserDetails] = useState();
  const [referenceID, setReferenceID] = useState();
  const [initiatedOrders, setInitiatedOrders] = useState([])
  const [initiatedOrdersB, setInitiatedOrdersB] = useState([])
  const [shopsReceivedtemp, setShopsReceivedtemp] = useState([])

  const [month, setMonth] = useState([
    "",
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUNE",
    "JULY",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ]);
  const testShopIds = [
    "3875aa25-6f00-458c-b48e-5dc8cf6a55c8",
    "56b63caf-2960-4790-979f-52c230dce482",
    "f125e660-7623-4597-b8b8-45d06ae95649",
    "b1c83b46-c8c3-4f4d-afab-5142d1769471",
  ];
  const momentDate = moment();
  const csvLink = createRef();
  const [downloadData, setDownloadData] = useState([]);
  const [active, setActive] = useState(false);
  const [totalBillCost, setTotalBillCost] = useState(0); //setTotalBillCount
  const [totalBillCount, setTotalBillCount] = useState(0);
  const [mainData, setMainData] = useState(initialData);
  const [refData, setRefData] = useState(initialData);
  const [billStats, setBillStats] = useState({});
  const [filterByPeriodValue, setFilterByPeriodValue] = useState("7");
  // const [filterByPeriod, setFilterByPeriod] = useState([]);
  // const [pincodeDropdown, setPincodeDropDown] = useState([]);
  const [pincode, setPincode] = useState("All");
  const [shopData, setShopData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [offerData, setOfferData] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [segmentDistribution, setSegmentDistribution] = useState([]);
  const [billsGenerated, setBillsGenerated] = useState([]);
  const [orderStats, setOrderStats] = useState([]);
  const [couponData, setCouponData] = useState([]);
  const [finalAmount, setFinalAmount] = useState();
  const [offerStats, setOfferStats] = useState({
    offerStatus: {},
    segment: {},
  });
  const [fullShops, setFullShops] = useState([]);
  const [collectionStats, setCollectionStats] = useState({
    offerStatus: {},
    segment: {},
  });

  const graphByMonth = async (data, attributeName) => {
    return await new Promise((resolve) => {
      let countByValue = {};

      data.forEach((elements) => {
        if (
          countByValue[elements[attributeName].substring(0, 7)] === undefined
        ) {
          countByValue[elements[attributeName].substring(0, 7)] = {
            count: 0,
            month: "",
          };
        }
        let getMonthName = parseInt(elements[attributeName].substring(5, 7));

        countByValue[elements[attributeName].substring(0, 7)].count += 1;
        countByValue[elements[attributeName].substring(0, 7)].month =
          month[getMonthName];
      });
      let returnArray = [];

      Object.entries(countByValue).forEach(([key, value]) => {
        returnArray.push({
          name: value.month,
          count: value.count,
        });
      });
      returnArray = returnArray.sort(
        (a, b) => month.indexOf(a.name) - month.indexOf(b.name)
      );
      resolve(returnArray);
    });
  };
  const graphByDay = async (data, attributeName) => {
    return await new Promise((resolve) => {
      let countByValue = {};

      data.forEach((elements) => {
        if (countByValue[elements[attributeName]] === undefined) {
          countByValue[elements[attributeName]] = {
            count: 0,
            name: "",
          };
        }

        countByValue[elements[attributeName]].count += 1;
        countByValue[elements[attributeName]].name = elements[attributeName];
      });
      let returnArray = [];

      Object.entries(countByValue).forEach(([key, value]) => {
        returnArray.push({
          name: value.name,
          count: value.count,
        });
      });
      let sortByDate = returnArray.sort(
        (a, b) => new Date(a.name) - new Date(b.name)
      );

      resolve(sortByDate);
    });
  };
  //get order status
  const [totalOrderValueObj, setTotalOrderValueObj] = useState({
    totalOrders: 0,
    totalOrdersValue: 0,
  });
  const [codOrderValueObj, setCodOrderValueObj] = useState({
    totalOrders: 0,
    totalOrdersValue: 0,
  });
  const [onlinePaymentOrderValueObj, setOnlinePaymentOrderValueObj] = useState({
    totalOrders: 0,
    totalOrdersValue: 0,
  });
  const getTotalOrdersValue = (data) => {
    let totalvALUE = 0;

    data.map((key) => {
      if (Number(key.totalAmount)) {
        totalvALUE += parseFloat(key.totalAmount);
      }
    });

    return totalvALUE;
  };

  const getOrdersSplitUp = (orders) => {
    let codOrders = [];
    let onlineOrders = [];
    let initiatedOrdersLocal = []
    orders.map((data) => {
      if (!Number(data.totalAmount)) return;
      if (
        data.paymentStatus ||
        data.cashFreeOrderId ||
        data.paymentMode === "online"
      ) {
        onlineOrders.push(data);
      } else {
        codOrders.push(data);
      }
    });

    let totalPaid = 0;
    let totalPaidValue = 0;
    let totalInitiated = 0;
    let totalInitiatedValue = 0;

    if (onlineOrders.length > 0) {
      onlineOrders.map((data) => {
        if (data.paymentStatus === "paid") {
          totalPaid += 1;
          totalPaidValue += parseFloat(data.totalAmount);
        } else {
          initiatedOrdersLocal.push(data)
          totalInitiated += 1;
          totalInitiatedValue += parseFloat(data.totalAmount);
        }
      });
    }
    if (initiatedOrdersLocal.length > 0) {
      let temp = []

      initiatedOrdersLocal.map((data) => {
        let shop = shopsReceivedtemp.find((element) => element.userId === data.shopId)
        console.log("shops_shops", shop)
        if (shop && shop.shopName && shop.userPhoneNumber) {
          delete temp["shopId"]
          delete temp["billId"]
          delete temp["cashFreeOrderId"]
          delete temp["paymentMode"]
          delete temp["paymentStatus"]
          delete temp["settlementStatus"]
          delete temp["status"]
          temp.push({ ...data, shopName: shop.shopName, shopNumber: shop.userPhoneNumber })
        } else {
          temp.push(data)
        }

      })
      console.log("temp", temp)
      setInitiatedOrders([...temp])

    }

    setOnlinePaymentOrderValueObj({
      totalOrders: onlineOrders.length,
      totalOrdersValue: getTotalOrdersValue(onlineOrders),
      totalPaid: totalPaid,
      totalPaidValue: totalPaidValue,
      totalInitiated: totalInitiated,
      totalInitiatedValue: totalInitiatedValue,
    });

    setCodOrderValueObj({
      totalOrders: codOrders.length,
      totalOrdersValue: getTotalOrdersValue(codOrders),
    });
  };
  const getOrderStatus = async (data) => {
    setTotalOrderValueObj({
      ...totalOrderValueObj,
      totalOrders: data.length || 0,
      totalOrdersValue: getTotalOrdersValue(data),
    });

    return await new Promise((resolve) => {
      let consolidatedObject = {};
      let returnArray = [];
      data.forEach((elements) => {
        if (!Number(elements.totalAmount)) return;

        if (consolidatedObject[elements.status] == undefined) {
          consolidatedObject[elements.status] = {
            status: elements.status,
            cost: 0,
            count: 0,
          };
        }
        consolidatedObject[elements.status].count += 1;
        consolidatedObject[elements.status].cost +=
          parseFloat(elements.totalAmount) || 0;
      });
      Object.values(consolidatedObject).map((items) => {
        returnArray.push({
          status: items.status,
          cost: items.cost,
          count: items.count,
        });
      });

      resolve(returnArray);
    });
  };
  // get offer and collection statistics
  const getOfferStatus = async (data) => {
    return await new Promise((resolve) => {
      let todayDate = new Date().toISOString().substring(0, 10);
      let consolidatedObject = {};
      let offerSegment = {};

      let initialOfferStatus = {
        totalApproved: 0,
        totalNotApproved: 0,
        currentLive: 0,
        totalExpired: 0,
        totalDraft: 0,
        totalDeleted: 0,
        totalNonExpired: 0,
      };
      data.forEach((elements) => {
        if (consolidatedObject[elements.offerStatus] == undefined) {
          consolidatedObject[elements.offerStatus] = 1;
        } else {
          consolidatedObject[elements.offerStatus] += 1;
        }

        if (elements.offerStatus === "Live") {
          if (todayDate > elements.offerEndDate) {
            initialOfferStatus.totalExpired += 1;
          } else {
            initialOfferStatus.currentLive += 1;

            if (offerSegment[elements.shopType] === undefined) {
              offerSegment[elements.shopType] = 1;
            } else {
              offerSegment[elements.shopType] += 1;
            }
          }
          if (elements.offerEndDate === undefined) {
            if (initialOfferStatus["totalNonExpired"] === undefined) {
              initialOfferStatus["totalNonExpired"] = 1;
            } else {
              initialOfferStatus["totalNonExpired"] += 1;
            }
          }
        }
      });

      let offerStatus = { ...consolidatedObject, ...initialOfferStatus };
      let returnObj = {
        offerStatus: offerStatus,
        segment: offerSegment,
      };
      resolve(returnObj);
    });
  };
  //Couon created
  const getCouponsCreated = async (data) => {
    return await new Promise((resolve) => {
      let consolidtedCoupon = {};
      data.forEach((elements) => {
        if (consolidtedCoupon[elements.couponType] == undefined) {
          consolidtedCoupon[elements.couponType] = {
            count: 0,
            allocatedCost: 0,
            couponsCreated: 0,
            redeemed: 0,
            redeemedCost: 0,
          };
        }
        if (elements.couponCategory == "Created") {
          consolidtedCoupon[elements.couponType].count +=
            parseFloat(elements.totalCouponCount) || 0;
          consolidtedCoupon[elements.couponType].couponsCreated += 1;
          consolidtedCoupon[elements.couponType].allocatedCost +=
            parseFloat(elements.maximumCouponAmount) *
            parseFloat(elements.totalCouponCount) || 0;
        } else if (elements.couponCategory == "Used") {
          consolidtedCoupon[elements.couponType].redeemed += 1;
          consolidtedCoupon[elements.couponType].redeemedCost +=
            parseFloat(elements.maximumCouponAmount) || 0;
        }
      });

      let returnArray = [];
      Object.entries(consolidtedCoupon).forEach(([key, value]) => {
        returnArray.push({
          totalCouponCount: value.count,
          allocatedCost: value.allocatedCost,
          couponsCreated: value.couponsCreated,
          redeemed: value.redeemed,
          redeemedCost: value.redeemedCost,
          couponType: key,
        });
      });
      resolve(returnArray);
    });
  };

  //Get Bill
  const getBillStatus = async (data) => {
    return await new Promise((resolve) => {
      let calculateBills = {};
      let totalBillAmount = 0;
      let totalBillCount = 0;
      data.forEach((items) => {
        if (testShopIds.includes(items.shopId)) return;
        if (!testdata.includes(items.customerPhoneNumber)) {
          totalBillCount += 1;
          totalBillAmount += !Boolean(Number(items.amountPaid))
            ? 0
            : parseInt(items.amountPaid);
          let getBilledShops = fullShops.find(
            (elements) => items.shopId == elements.userId
          );
          if (typeof getBilledShops === "object" && getBilledShops.userId) {
            let shopType = getBilledShops.shopType || "Others";

            if (calculateBills[shopType]) {
              calculateBills[shopType].type += 1;
              calculateBills[shopType].amount += !Boolean(
                Number(items.amountPaid)
              )
                ? 0
                : parseInt(items.amountPaid);
            } else {
              calculateBills[shopType] = {
                type: 1,
                amount: !Boolean(Number(items.amountPaid))
                  ? 0
                  : parseInt(items.amountPaid),
              };
            }
          } else {
            if (calculateBills["Others"]) {
              calculateBills["Others"].type += 1;
              calculateBills["Others"].amount += !Boolean(
                Number(items.amountPaid)
              )
                ? 0
                : parseInt(items.amountPaid);
            } else {
              calculateBills["Others"] = {
                type: 1,
                amount: !Boolean(Number(items.amountPaid))
                  ? 0
                  : parseInt(items.amountPaid),
              };
            }
          }
        }
      });
      setTotalBillCost(totalBillAmount);
      setTotalBillCount(totalBillCount);
      let amountArray = Object.values(calculateBills);
      let totalOfTypes = 0;
      amountArray.map((data) => {
        totalOfTypes += data.amount;
      });

      resolve(calculateBills);
    });
  };

  const getDashboardData = async (startDate, endDate, fetchData) => {
    try {
      let dashboardDataFetch = await fetch(`${config.ApiGateway}/dashboard`, {
        method: "POST",
        body: JSON.stringify({
          // startDate: startDate,
          // endDate: endDate,
          startDate: dempa,
          endDate: dempb,
          key: key,
          fetchData: fetchData,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      let response = await dashboardDataFetch.json();

      let returnData = [];

      if (response.limitExceeded) {
        let dsf = await fetch(response.location)
          .then((res) => res.json())
          .then((inn) => {
            return inn;
          })
          .catch((err) => {
            setIsLoading(false);
            alert("Error fetching data");
            console.log("s3fetch", err);
          });
        returnData = dsf;
      } else {
        returnData = { ...response.data };
      }
      return returnData;
    } catch (err) {
      console.log("AwaitErr", err);
    }
  };

  function getDatesBetween(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      let r = new Date(currentDate);
      dates.push(`${format(r, "yyyy-MM-dd")}`);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const [billList, setBillList] = useState([]);

  const fetchSalesReport = async (payload, fromDate, toDate) => {
    try {
      let res = await fetch(`${config.ApiGateway}/dashboard`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // fromDate: fromDate,
          // toDate: toDate,
          fromDate: dempa,
          toDate: dempb,
          fetchData: "bills",
          lastItem: undefined,
          ...payload,
        }),
      });
      res = await res.json();

      setBillList((prev) => {
        return [...prev, ...res.bills];
      });

      return res;
    } catch (err) {
      console.error("fetchSalesReport", err);
    }
  };

  const getSalesReports = async (fromDate, toDate) => {
    let temp = [];
    try {
      let res = await fetchSalesReport(null, fromDate, toDate);

      while (res.lastItem) {
        temp = [...temp, ...res.bills];
        const nextRespones = await fetchSalesReport(
          { lastItem: res.lastItem },
          fromDate,
          toDate
        );
        res = nextRespones;
      }
      if (res.lastItem === undefined) {
        temp = [...temp, ...res.bills];
      }
    } catch (err) {
      console.error("getSalesReports", err);
    } finally {
      return temp;
    }
  };

  ///hex
  const datafetcher = async (sd, ed, days) => {
    let shops = await getDashboardData(sd, ed, "shops");
    let bills = await getSalesReports(sd, ed);
    let others = await getDashboardData(sd, ed, "others");
    let obj = { ...shops, ...others, generatedBills: bills };
    setShopsReceivedtemp([...shops.allShops])
    setRefData({ ...refData, ...obj });
    setMainData({ ...mainData, ...obj });
  };
  useEffect(() => {
    if (
      `${format(range[0].startDate, "yyyy-MM-dd")}` !== "" &&
      `${format(range[0].endDate, "yyyy-MM-dd")}` !== ""
    ) {
      let startuDate = `${format(range[0].startDate, "yyyy-MM-dd")}`;
      let enduDate = `${format(range[0].endDate, "yyyy-MM-dd")}`;

      const starteDate = new Date(startuDate);
      const endeDate = new Date(enduDate);
      const timeDifferenceMs = endeDate - starteDate;
      const numberOfDays = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));

      datafetcher(startuDate, enduDate, numberOfDays);
    }
  }, [
    `${format(range[0].startDate, "yyyy-MM-dd")}`,
    `${format(range[0].endDate, "yyyy-MM-dd")}`,
  ]);

  var testAccounts = [
    "23fa9a2c-c331-4762-934a-5f48f2dfae91",
    "43954b7a-a0a1-4557-b818-8d9013a8fe67",
    "29877a07-61a6-4a2f-a479-a710119c7a6e",
    "7f92f990-aa66-4515-8972-3eedceb1bc77",
    "43954b7a-a0a1-4557-b818-8d9013a8fe67",
    "08e12c54-0ac3-4bb9-b606-59cd1bf3bb46",
    "feae5135-fd94-4b76-8af4-1405ebfd96a4",
    "43c9b830-f0cc-4364-aee3-bf69e5ad8c99",
    "bcd807ab-c479-4ee5-9119-eda607223ea6",
    "ec529e09-7c39-476c-ad17-8c9f29a5be89",
  ];

  useEffect(() => {
    async function renderData() {
      const {
        allShops,
        shopTypeandshopId,
        shops,
        customers,
        couponsCreated,
        redeemedCoupons,
        orders,
        generatedBills,
        offers,
        collection,
      } = mainData;
      setShopsReceivedtemp([...allShops])
      let start = `${format(range[0].startDate, "yyyy-MM-dd")}`;
      let end = `${format(range[0].endDate, "yyyy-MM-dd")} `;
      let dayOrMonth = Math.abs(
        (new Date(start) - new Date(end)) / (1000 * 24 * 60 * 60)
      );
      getOrdersSplitUp(orders);
      let orderStatus = await getOrderStatus(orders);
      let offerStatus = await getOfferStatus(offers);
      let collectionStatus = await getOfferStatus(collection);
      let filteredShops = allShops.filter((keys) => {
        if (keys.userAddedDate >= start && keys.userAddedDate <= end) {
          return keys;
        }
      });
      let shopsDataByGraph =
        dayOrMonth > 30
          ? await graphByMonth(filteredShops, "userAddedDate")
          : await graphByDay(filteredShops, "userAddedDate");
      let shopBySegment = await graphByDay(filteredShops, "shopType");
      let customerDataByGraph =
        dayOrMonth > 30
          ? await graphByMonth(customers, "userAddedDate")
          : await graphByDay(customers, "userAddedDate");
      let offerDataByGraph =
        dayOrMonth > 30
          ? await graphByMonth(offers, "offerAddedDate")
          : await graphByDay(offers, "offerAddedDate");

      let collectionDataByGraph =
        dayOrMonth > 30
          ? await graphByMonth(collection, "offerAddedDate")
          : await graphByDay(collection, "offerAddedDate");

      let orderByGraph =
        dayOrMonth > 30
          ? await graphByMonth(orders, "billingDate")
          : await graphByDay(orders, "billingDate");

      let billsByGraph =
        dayOrMonth > 30
          ? await graphByMonth(generatedBills, "billingDate")
          : await graphByDay(generatedBills, "billingDate");

      let coupons = await getCouponsCreated([
        ...couponsCreated,
        ...redeemedCoupons,
      ]);

      let billsBySegment = await getBillStatus(generatedBills);

      setCouponData([...couponData, ...coupons]);
      setBillStats({ ...billStats, ...billsBySegment });
      setOrderStats([...orderStats, ...orderStatus]);
      setCollectionStats({ ...collectionStats, ...collectionStatus });
      setOfferStats({ ...offerStats, ...offerStatus });
      setOrderData([...orderData, ...orderByGraph]);
      setShopData(shopsDataByGraph);
      setSegmentDistribution(shopBySegment);
      setFullShops([...fullShops, ...allShops]);
      setCustomerData([...customerData, ...customerDataByGraph]);
      setOfferData([...offerData, ...offerDataByGraph]);
      setCollectionData([...collectionData, ...collectionDataByGraph]);
      setBillsGenerated([...billsGenerated, ...billsByGraph]);
      console.log("Done");
      setIsLoading(false);
    }

    if (mainData["allShops"].length > 0) {
      renderData();
    }
  }, [mainData]);

  // Filter by Pincode
  // useEffect(() => {
  //   const {
  //     shopTypeandshopId,
  //     shops,
  //     customers,
  //     couponsCreated,
  //     redeemedCoupons,
  //     orders,
  //     generatedBills,
  //     offers,
  //     collection,
  //   } = refData;
  //   async function renderData() {

  //     let filterShopsByPincode = shops.filter(
  //       (items) => items.userPincode == pincode
  //     );

  //     let filterCustomerByPincode = customers.filter(
  //       (items) => items.userPincode == pincode
  //     );
  //     let filterOfferByPincode = offers.filter(
  //       (items) => items.shopPincode == pincode
  //     );
  //     let filterCollectionByPincode = collection.filter(
  //       (items) => items.shopPincode == pincode
  //     );
  //     setMainData((prevObj) => ({
  //       ...prevObj,
  //       shopTypeandshopId,
  //       shops: filterShopsByPincode,
  //       customers: filterCustomerByPincode,
  //       couponsCreated,
  //       redeemedCoupons,
  //       orders,
  //       generatedBills,
  //       offers: filterOfferByPincode,
  //       collection: filterCollectionByPincode,
  //     }));
  //   }
  //   if (pincode == "All") {
  //     setMainData(refData);
  //   } else {
  //     renderData();
  //   }
  // }, [pincode]);
  // Filter by Period of time

  useEffect(() => {
    let numberOfDays = parseInt(filterByPeriodValue);
    let today = new Date();
    today.setDate(today.getDate() - numberOfDays);

    // setDateRange((prevState) => ({
    //   ...prevState,
    //   startDate: today.toISOString().substring(0, 10),
    //   endDate: new Date().toISOString().substring(0, 10),
    // }));
  }, [filterByPeriodValue]);
  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [downloadData]);
  const exportCsv = (items) => {
    let referedData = refData.shops;
    const headers = [
      { label: "User  ID", key: "userId10Digit" },
      { label: "Pincode", key: "userPincode" },
      { label: "Name", key: "userName" },
      { label: "Phone Number", key: "userPhoneNumber" },
      { label: "Place", key: "userArea" },
      { label: "Referral Code", key: "referralCode" },
      { label: "Reffered By", key: "referredBy" },
      { label: "Shop Name", key: "shopName" },
      { label: "Shop Address", key: "shopAddress" },
      { label: "Shop Type", key: "shopType" },
    ];
    setDownloadData(referedData);
    setActive(true);
  };

  useEffect(() => {
    var a = Object.entries(billStats).map(([elements, value]) => {
      return value.amount;
    });
    // console.log("billStats EFFECT", billStats);
    if (a.length > 1) {
      var billfinal = a.reduce((a, b) => a + b);
      setFinalAmount(billfinal);
    }
  }, [billStats]);
  ///////Excel function

  ///////







  return (
    <Container fluid>
      <div className="orderHeader">
        <h2 className="orderHeaderText">Dashboard</h2>
      </div>
      <div className="dashboardContainer">
        <div style={{ marginLeft: "75%" }}>
          <Button variant="primary" onClick={handleShow}>
            <input
              size="33"
              value={`From :  ${format(
                range[0].startDate,
                "yyyy-MM-dd"
              )}  to :  ${format(range[0].endDate, "yyyy-MM-dd")} `}
              readOnly
              className="inputBox"
              onClick={() => setOpen((open) => !open)}
            />
          </Button>

          <Modal show={open} onHide={handleClose} style={{ top: "20%" }}>
            <Modal.Body>
              <div className="calendarWrap">
                <div ref={refOne}>
                  {open && (
                    <DateRangePicker
                      onChange={(item) => {
                        // setRange([item.selection]);

                        setTempRange([item.selection]);
                      }}
                      editableDateInputs={true}
                      moveRangeOnFirstSelection={true}
                      ranges={tempRange}
                      months={2}
                      direction="horizontal"
                      className="calendarElement"
                      alwaysShowCalendars={true}
                      rangeColors={["#f33e5b", "#3ecf8e", "#fed14c"]}
                      showDateDisplay={true}
                      scroll={{ enabled: true }}
                      color="#3d91ff"
                    />
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={processApply}>
                Apply
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div className="vertical-space-10"></div>
        {/* shop */}

        {isLoading === true ? (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Skeleton variant="rectangular" width={2100} height={118} />
            </div>
          </>
        ) : (
          <Row className="dashboardRow">
            <Col style={{ marginBottom: "10px" }} sm="12">
              <h4> Shop</h4> <small>B2B</small>
              <span>
                <div style={{ float: "right" }}>
                  <Button onClick={() => exportCsv(shopData)}>Download</Button>
                  {/* <CSVLink data={downloadData} ref={csvLink} target="_blank" /> */}
                  <CSVLink data={initiatedOrders} ref={csvLink} target="_blank" />

                </div>
              </span>
            </Col>

            <div className="vertical-space-5"></div>
            <Col sm="6">
              <div className="dashboardColumnBox">
                <div className="dashboardColumnBox-label">
                  <label>name</label>
                </div>
                <div className="vertical-space-5"></div>
                <BarGraph
                  data={shopData}
                  Xaxis={"name"}
                  Yaxis={"count"}
                  YaxisLabel={"Merchant"}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="dashboardColumnBox">
                <div className="dashboardColumnBox-label">
                  <label>Segment Distribution</label>
                </div>
                <BarGraph
                  data={segmentDistribution}
                  Xaxis={"name"}
                  Yaxis={"count"}
                  YaxisLabel={"Merchant"}
                />
              </div>
            </Col>
            <div className="vertical-space-10"></div>
            <Col sm="3">
              <div className="dashboardColumn-smallBox">
                <p className="tabRowContentSubHeading">Total Downloads</p>
              </div>
            </Col>
          </Row>
        )}
        {/* Customer */}
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton animation="wave" width={2100} height={118} />
          </div>
        ) : (
          <Row className="dashboardRow">
            <Col sm="12">
              <span>
                <h4> Customer</h4> <small>B2C</small>
              </span>
            </Col>

            <div className="vertical-space-5"></div>
            <Col sm="6">
              <div className="dashboardColumnBox">
                <div className="dashboardColumnBox-label">
                  <label>name</label>
                </div>
                <BarGraph
                  data={customerData}
                  Xaxis={"name"}
                  Yaxis={"count"}
                  YaxisLabel={"Customer"}
                />
              </div>
            </Col>
            <Col sm="6">
              <Row className="pageTitleFlex">
                <Col className="dashboardColumn-smallBox" sm="5">
                  <p className="tabRowContentSubHeading">Total Registered</p>
                  <h3>{mainData.customers.length || 0}</h3>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {/* Offers */}
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton animation="wave" width={2100} height={118} />
          </div>
        ) : (
          <Row className="dashboardRow">
            <Col sm="12">
              <span>
                <h4> Offer</h4>
              </span>
            </Col>

            <div className="vertical-space-5"></div>
            <Col sm="6">
              <div className="dashboardColumnBox">
                <div className="dashboardColumnBox-label">
                  <label>Offers Posted </label>
                </div>
                <BarGraph
                  data={offerData}
                  Xaxis={"name"}
                  Yaxis={"count"}
                  YaxisLabel={"Offers Created"}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="dashboardColumnBox">
                <div className="dashboardColumnBox-label">
                  <label>Offer Distribution</label>
                </div>
                <TableContainer>
                  <Table className={classes.table} size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell>Current Live</TableCell>
                        <TableCell>Approved offers</TableCell>
                        <TableCell>Rejected Offers</TableCell>
                        <TableCell>Deleted</TableCell>
                        <TableCell>Expired</TableCell>
                        <TableCell> Draft </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        <TableRow>
                          <TableCell>{offerStats.offerStatus.Live}</TableCell>
                          <TableCell>
                            {offerStats.offerStatus.totalApproved}
                          </TableCell>
                          <TableCell>
                            {offerStats.offerStatus.totalNotApproved}
                          </TableCell>
                          <TableCell>
                            {offerStats.offerStatus.totalDeleted}
                          </TableCell>
                          <TableCell>
                            {offerStats.offerStatus.totalExpired}
                          </TableCell>
                          <TableCell>{offerStats.offerStatus.Draft}</TableCell>
                        </TableRow>
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Col>
            <div className="vertical-space-10"></div>
            <span>
              <h6> Live Offers by segment</h6>
            </span>
            <div className="vertical-space-10"></div>
            {Object.entries(offerStats.segment).map(([key, value]) => {
              return (
                <Col sm="3">
                  <div className="dashboardColumn-smallBox">
                    <p className="tabRowContentSubHeading">{key}</p>

                    <h3>
                      {value}{" "}
                      <small style={{ fontWeight: "bold", fontSize: "14px" }}>
                        (
                        {Math.round(
                          (parseInt(value) / parseInt(mainData.offers.length)) *
                          100
                        )}{" "}
                        {"%"})
                      </small>
                    </h3>
                  </div>
                </Col>
              );
            })}
          </Row>
        )}
        {/* Collection */}

        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton animation="wave" width={2100} height={118} />
          </div>
        ) : (
          <Row className="dashboardRow">
            <Col sm="12">
              <span>
                <h4> Collection</h4>
              </span>
            </Col>

            <div className="vertical-space-5"></div>
            <Col sm="6">
              <div className="dashboardColumnBox">
                <div className="dashboardColumnBox-label">
                  <label>Collection Posted</label>
                </div>
                <BarGraph
                  data={collectionData}
                  Xaxis={"name"}
                  Yaxis={"count"}
                  YaxisLabel={"Collection Created"}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="dashboardColumnBox">
                <div className="dashboardColumnBox-label">
                  <label>Collection Distribution</label>
                </div>
                <TableContainer>
                  <Table className={classes.table} size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell>Current Live</TableCell>
                        <TableCell>Approved offers</TableCell>
                        <TableCell>Rejected Offers</TableCell>
                        <TableCell>Deleted</TableCell>
                        <TableCell>Expired</TableCell>
                        <TableCell> Draft </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        <TableRow>
                          <TableCell>
                            {collectionStats.offerStatus.Live}
                          </TableCell>
                          <TableCell>
                            {collectionStats.offerStatus.totalApproved}
                          </TableCell>
                          <TableCell>
                            {collectionStats.offerStatus.totalNotApproved}
                          </TableCell>
                          <TableCell>
                            {collectionStats.offerStatus.totalDeleted}
                          </TableCell>
                          <TableCell>
                            {collectionStats.offerStatus.totalExpired}
                          </TableCell>
                          <TableCell>
                            {collectionStats.offerStatus.Draft}
                          </TableCell>
                        </TableRow>
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Col>

            <div className="vertical-space-10"></div>
            <span>
              <h6> Live Collection by segment</h6>
            </span>
            <div className="vertical-space-10"></div>
            {Object.entries(collectionStats.segment).map(([key, value]) => {
              return (
                <Col sm="3">
                  <div className="dashboardColumn-smallBox">
                    <p className="tabRowContentSubHeading">{key}</p>
                    <h3>
                      {value}{" "}
                      <small style={{ fontWeight: "bold", fontSize: "14px" }}>
                        (
                        {Math.round(
                          (parseInt(value) /
                            parseInt(mainData.collection.length)) *
                          100
                        )}{" "}
                        {"%"})
                      </small>
                    </h3>
                  </div>
                </Col>
              );
            })}
          </Row>
        )}
        {/* Orders */}
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton animation="wave" width={2100} height={118} />
          </div>
        ) : (
          <Row className="dashboardRow">
            <Col sm="12">
              <span>
                <h4> Orders</h4>
              </span>
            </Col>

            <div className="vertical-space-5"></div>
            <Col sm="6">
              <div className="dashboardColumnBox">
                <div className="dashboardColumnBox-label">
                  <label>name</label>
                </div>
                <BarGraph
                  data={orderData}
                  Xaxis={"name"}
                  Yaxis={"count"}
                  YaxisLabel={"Orders"}
                />
              </div>
            </Col>
            <Col sm="6">
              <Row style={{ display: "flex", flexFlow: "wrap" }}>
                <h3>Order status</h3>
                <div className="vertical-space-5"></div>

                <Col sm="4" className="dashboardColumn-smallBox">
                  <span style={{ display: "inline-block", width: "100%" }}>
                    <b>Total Orders:</b>
                    <h6>{totalOrderValueObj.totalOrders}</h6>
                  </span>
                  <span style={{ display: "inline-block", width: "100%" }}>
                    <b>Order Cost:</b>
                    <h6>
                      {"₹ "}
                      {totalOrderValueObj.totalOrdersValue.toFixed(2) || 0}
                    </h6>
                  </span>
                </Col>

                {orderStats.map((row) => (
                  <>
                    <Col sm="4" className="dashboardColumn-smallBox">
                      <span style={{ display: "inline-block", width: "100%" }}>
                        <b>
                          {row.status === "complete" && "Delivered"}
                          {row.status === "startPackaging" && "Order Accepted"}
                          {row.status === "placed" && "New Orders"}
                          {row.status === "readyForDelivery" &&
                            "Ready for Delivery"}
                          {row.status === "rejected" && "Rejected"}
                          {row.status === "readyForPickup" &&
                            "Ready for Pick-up"}
                          :
                        </b>
                        <h6>{row.count}</h6>
                      </span>
                      <span style={{ display: "inline-block", width: "100%" }}>
                        <b>Order Cost:</b>
                        <h6>
                          {"₹ "}
                          {row.cost.toFixed(2) || 0}
                        </h6>
                      </span>
                    </Col>
                  </>
                ))}
                <Col sm="4" className="dashboardColumn-smallBox">
                  <span style={{ display: "inline-block", width: "100%" }}>
                    <b>COD Orders:</b>
                    <h6>{codOrderValueObj.totalOrders}</h6>
                  </span>
                  <span style={{ display: "inline-block", width: "100%" }}>
                    <b>Order Cost:</b>
                    <h6>
                      {"₹ "}
                      {codOrderValueObj.totalOrdersValue.toFixed(2) || 0}
                    </h6>
                  </span>
                </Col>
                <Col sm="4" className="dashboardColumn-smallBox">
                  <span style={{ display: "inline-block", width: "100%" }}>
                    <b>Online pay orders:</b>
                    <h6>{onlinePaymentOrderValueObj.totalOrders}</h6>
                  </span>
                  <span style={{ display: "inline-block", width: "100%" }}>
                    <b>Order Cost:</b>
                    <h6>
                      {"₹ "}
                      {onlinePaymentOrderValueObj.totalOrdersValue.toFixed(2) ||
                        0}
                    </h6>
                  </span>
                </Col>
                <Col sm="4" className="dashboardColumn-smallBox">
                  <span style={{ display: "inline-block", width: "100%" }}>
                    <b>Online payment initiated</b>
                    <h6>{onlinePaymentOrderValueObj.totalInitiated}</h6>
                  </span>
                  <span style={{ display: "inline-block", width: "100%" }}>
                    <b>Order Cost:</b>
                    <h6>
                      {"₹"}
                      {onlinePaymentOrderValueObj.totalInitiatedValue.toFixed(
                        2
                      ) || 0}
                    </h6>
                  </span>
                </Col>
                <Col sm="4" className="dashboardColumn-smallBox">
                  <span style={{ display: "inline-block", width: "100%" }}>
                    <b>Online payment Paid</b>
                    <h6>{onlinePaymentOrderValueObj.totalPaid}</h6>
                  </span>
                  <span style={{ display: "inline-block", width: "100%" }}>
                    <b>Order Cost:</b>
                    <h6>
                      {"₹"}
                      {onlinePaymentOrderValueObj.totalPaidValue.toFixed(2) ||
                        0}
                    </h6>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {/* Bills */}
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton animation="wave" width={2100} height={118} />
          </div>
        ) : (
          <Row className="dashboardRow">
            <Col sm="12">
              <span>
                <h4>Bills</h4>
              </span>
            </Col>

            <div className="vertical-space-5"></div>
            <Col sm="6">
              <div className="dashboardColumnBox">
                <div className="dashboardColumnBox-label">
                  <label>name</label>
                </div>
                <BarGraph
                  data={billsGenerated}
                  Xaxis={"name"}
                  Yaxis={"count"}
                  YaxisLabel={"Bills generated"}
                />
              </div>
            </Col>
            <Col sm="6">
              <Row style={{ display: "flex", flexFlow: "wrap" }}>
                <h3>Bill Generated by Segment</h3>
                <div className="vertical-space-5"></div>

                <Col className="dashboardColumn-smallBox" sm="4">
                  <span style={{ display: "inline-block", width: "100%" }}>
                    <b>Bill Amount Generated</b>
                    <h6>₹ {totalBillCost}</h6>
                    <h6>
                      {"Total Bills : "}
                      <b> {totalBillCount} </b>
                    </h6>
                  </span>
                </Col>

                {/* {Object.entries(billStats).map(([elements, value]) => (
              <>
                <Col className="dashboardColumn-smallBox" sm="4">
                  <span style={{ display: "inline-block", width: "100%" }}>
                    <b>{elements.toUpperCase()}</b>
                    <h6>{value}</h6>
                  </span>
                </Col>
              </>
            ))} */}
                {Object.entries(billStats).map(([elements, value]) => {
                  return (
                    <>
                      <Col className="dashboardColumn-smallBox" sm="4">
                        <>
                          <span
                            style={{
                              display: "inline-block",
                              width: "100%",
                            }}
                          >
                            <b>{elements.toUpperCase()}</b>
                            <h6>
                              {"No. of Bills : "}
                              <b> {value.type} </b>
                            </h6>
                          </span>

                          <span
                            style={{
                              display: "inline-block",
                              width: "100%",
                            }}
                          >
                            <b>{"Amount"}</b>
                            <h6> ₹ {value.amount}</h6>
                          </span>
                        </>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </Col>
          </Row>
        )}

        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton animation="wave" width={2100} height={118} />
          </div>
        ) : (
          <Row className="dashboardRow">
            <Col sm="12">
              <span>
                <h4> Coupons</h4>
              </span>
            </Col>
            <Col sm="6">
              <div className="dashboardColumnBox">
                <div className="dashboardColumnBox-label">
                  <label>Coupon and Redemption</label>
                </div>
                <ResponsiveContainer aspect={1.6}>
                  <BarChart
                    width={600}
                    height={300}
                    data={couponData}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                    className="recharts"
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      // tickLine={true}
                      xAxisId={0}
                      dy={0}
                      dx={-0}
                      label={{
                        value: "",
                        angle: 0,
                        position: "bottom",
                      }}
                      interval={0}
                      dataKey="couponType"
                      tickLine={false}
                      tick={{ fontSize: 9, angle: 0 }}
                    />

                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="totalCouponCount" fill="#8884d8">
                      <LabelList dataKey="totalCouponCount" position="top" />
                    </Bar>
                    <Bar dataKey="redeemed" fill="red">
                      <LabelList dataKey="redeemed" position="top" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Col>
            <Col sm="6">
              <div className="dashboardColumnBox">
                <div className="dashboardColumnBox-label">
                  <label>Coupon Distribution</label>
                </div>
                <TableContainer>
                  <Table className={classes.table} size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell>Coupon Type</TableCell>
                        <TableCell>Total Coupon Count</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Allocated Cost</TableCell>
                        <TableCell>Redeemed coupon Count</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        {couponData.map((row) => (
                          <TableRow>
                            <TableCell>{row.couponType}</TableCell>
                            <TableCell>{row.totalCouponCount}</TableCell>
                            <TableCell>{row.couponsCreated}</TableCell>
                            <TableCell>
                              {"₹ "}
                              {row.allocatedCost}
                            </TableCell>
                            <TableCell>{row.redeemed}</TableCell>
                          </TableRow>
                        ))}
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
}
