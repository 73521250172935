import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Typography from "@material-ui/core/Typography";
import { Auth } from "aws-amplify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import config from "../config";
import EditSharpIcon from "@material-ui/icons/EditSharp";

export default class Reviewupdate extends Component {
  constructor(props) {
    super(props);
    // To store shop details
    this.state = {
      review: this.props.contentReview,
      statusArray: [],
      reviewArray: [],
      membershipArray: [],
      imageSize: "",
      pincode: this.props.contentReview.pincode,
      shopName: this.props.contentReview.shopName,
      userName: this.props.contentReview.userName,
      shopId10Digit: this.props.contentReview.userId10Digit,
      userPhoneNumber: this.props.contentReview.userPhoneNumber,
      // imageUpload: {
      //   imageFile: [],

      //   selectedFile: [],
      // },
      imagePreviewUrl: [],
      OsList: [],
      brandList: [],
      // Offer information
      reviewed: {
        offerId: this.props.contentReview.offerId,
        imageFile: [],
        offerDetails: this.props.contentReview.offerDetails,
        offerStatus: this.props.contentReview.offerStatus,
        offerImageLocation: this.props.contentReview.offerImageLocation,
        offerNewPrice: this.props.contentReview.offerNewPrice,
        offerDiscount: this.props.contentReview.offerDiscount,
        offerOriginalPrice: this.props.contentReview.offerOriginalPrice,
        reviewedBy: this.props.reviewedBy,
        offerEndDate: this.props.contentReview.offerEndDate,
        offerStartDate: this.props.contentReview.offerStartDate,
        offerStartTime: this.props.contentReview.offerStartTime,
        offerEndTime: this.props.contentReview.offerEndTime,

        offerCategory: this.props.contentReview.offerCategory,
        offerSubCategory: this.props.contentReview.offerSubCategory,

        offerTag: this.props.contentReview.offerTag,

        mobileBrand: this.props.contentReview.mobileBrand,
        mobileDimensions: this.props.contentReview.mobileDimensions,
        mobileColor: this.props.contentReview.mobileColor,
        mobileWeight: this.props.contentReview.mobileWeight,
        mobileModel: this.props.contentReview.mobileModel,
        mobileFrontFacingCamera: this.props.contentReview
          .mobileFrontFacingCamera,

        mobileRearFacingCamera: this.props.contentReview.mobileRearFacingCamera,
        mobileRAM: this.props.contentReview.mobileRAM,
        mobileOS: this.props.contentReview.mobileOS,
        mobileOtherDetails: this.props.contentReview.mobileOtherDetails,
        mobileServiceDetails: this.props.contentReview.mobileServiceDetails,
        mobileMemory: this.props.contentReview.mobileMemory,
        mobileMemoryExtendable: this.props.contentReview.mobileMemoryExtendable,
        imageQuality: this.props.contentReview.imageQuality,
        reviewedOn: new Date().toISOString().substr(0, 10),
        reviewRejectedReason: this.props.contentReview.reviewRejectedReason,
        reviewComment: this.props.contentReview.reviewComment,
        review: "yes",
        approvedStatus: this.props.contentReview.approvedStatus,
      },

      todaydate: new Date().toISOString().substr(0, 10),
      isOpen: false,
      imageIndex: 0,
      lastApprovedby: this.props.contentReview.reviewedBy,
      seeMembership: false,
      imageFile: [],
      changeImage: false,
      width: "",
      height: "",
      editOffer: true,
    };
    this.uploadImage = this.uploadImage.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
    this.reviewOnchange = this.reviewOnchange.bind(this);
    this.reviewUpdate = this.reviewUpdate.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.ApprovedStatus = this.ApprovedStatus.bind(this);
    this.handleimage = this.handleimage.bind(this);
    this.editOffer = this.editOffer.bind(this);
    this.discountCalculation = this.discountCalculation.bind(this);
    this.priceChange = this.priceChange.bind(this);
  }
  priceChange(e) {
    this.setState(
      {
        ...this.state,
        reviewed: {
          ...this.state.reviewed,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        this.discountCalculation();
      }
    );
  }
  editOffer() {
    let checkEdit = this.state.editOffer;
    this.setState({
      editOffer: !this.state.editOffer,
      changeImage: checkEdit ? true : false,
    });
  }

  discountCalculation() {
    if (
      parseFloat(this.state.reviewed.offerOriginalPrice) <=
      parseFloat(this.state.reviewed.offerNewPrice)
    ) {
      this.setState({
        ...this.state,
        reviewed: { ...this.state.reviewed, offerNewPrice: "" },
      });
      return alert("OFFER PRICE SHOULD NE LESS THAN ORIGINAL PRICE");
    }
    if (
      this.state.reviewed.offerOriginalPrice !== "" &&
      this.state.reviewed.offerNewPrice !== ""
    ) {
      var originalPrice = parseFloat(this.state.reviewed.offerOriginalPrice);
      var newPrice = parseFloat(this.state.reviewed.offerNewPrice);
      var discount;

      discount = ((originalPrice - newPrice) / originalPrice) * 100; // To find discount
      discount = Math.round(discount);
      this.setState({
        ...this.state,
        reviewed: {
          ...this.state.reviewed,
          offerDiscount: discount,
        },
      });
    } else {
      this.setState({
        ...this.state,
        reviewed: {
          ...this.state.reviewed,
          offerDiscount: 0,
        },
      });
    }
  }

  uploadImage(e) {
    e.preventDefault();
    Promise.all([
      fetch(
        `${config.ApiGateway}/imageUpload`,

        {
          method: "POST",
          body: JSON.stringify(this.state.imageUpload),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.setState({
          ...this.state,
          reviewed: { ...this.state.reviewed, offerImageLocation: data },
          changeImage: false,
        });
      })
      .catch((err) => console.log(err));
  }
  onImgLoad({ target: img }) {
    this.setState({
      width: img.offsetWidth,
      height: img.offsetHeight,
    });
  }
  removeImage() {
    this.setState({
      // changeImage: !this.state.changeImage,
      changeImage: true,
      imagePreviewUrl: [],
      reviewed: {
        ...this.state.reviewed,
        offerImageLocation: "",
        imageFile: [],
      },
    });
  }
  componentDidMount() {
    var reviewStore = [];
    var store = [];
    var member = [];
    var brandList = [];
    var OsList = [];
    Promise.all([
      fetch(`${config.json.URL}/status.json`),
      fetch(`${config.json.URL}/membershiplimits.json`, {
        // method: "POST"
      }),
      fetch(`${config.json.URL}/imagesize.json`),
      fetch(`${config.json.URL}/fields.json`),
    ])
      .then(([res1, res2, res3, res4]) =>
        Promise.all([res1.json(), res2.json(), res3.json(), res4.json()])
      )
      .then(([data, data2, data3, data4]) => {
        this.setState({
          imageSize: data3.BANNER,
        });
        store = data.STATUS.map((item) => {
          return <option key={item}>{item}</option>;
        });
        reviewStore = data.REJECTED.map((item) => {
          return <option key={item}>{item}</option>;
        });
        member = data2.select.map((element) => {
          return (
            <option key={element.MEMBERSHIP}> {element.MEMBERSHIP}</option>
          );
        });

        data4.operatingSystem.forEach((elements) =>
          OsList.push(<option value={elements.value}>{elements.value}</option>)
        );

        data4.brand.forEach((elements) =>
          brandList.push(
            <option value={elements.value}>{elements.value}</option>
          )
        );

        this.setState({
          statusArray: store,
          reviewArray: reviewStore,
          membershipArray: member,
          OsList: OsList,
          brandList: brandList,
        });
      });

    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then((data) => {
      let user = { email: data.email, ...data.attributes };
      this.setState({
        ...this.state,
        reviewed: {
          ...this.state.reviewed,

          reviewedBy: user.phone_number,
        },
      });
    });
  }
  handleimage(event) {
    // Get image name
    this.setState({
      imageUpload: {
        ...this.state.imageUpload,
        selectedFile: event.target.files[0],
      },
    });

    let reader = new FileReader();
    let imageFormData = new FormData();
    imageFormData.append("photo", event.target.files[0]); // convert image to base 64
    reader.onloadend = () => {
      
      this.setState({
        imagePreviewUrl: reader.result,

        reviewed: {
          ...this.state.reviewed,
          imageFile: reader.result.replace(/^data:image\/\w+;base64,/, ""), // To remove image format(JPEG,png) 
        },
      });
    };

    reader.readAsDataURL(event.target.files[0]);
  }
  reviewOnchange(e) {
    var name = e.target.name;
    var value = e.target.value;

    this.setState({
      ...this.state,
      reviewed: {
        ...this.state.reviewed,
        [name]: value,
      },
    });
  }

  ApprovedStatus(e) {
    //
    var value = e.target.value;
    if (value === "no") {
      this.setState({
        ...this.state,
        reviewed: {
          ...this.state.reviewed,

          offerStatus: "Rejected",
          approvedStatus: "no",
        },
      });
    }
    if (value === "Live") {
      this.setState({
        ...this.state,
        reviewed: {
          ...this.state.reviewed,
          offerStatus: "Live",
          approvedStatus: "yes",
        },
      });
    }

    if (value === "Deleted") {
      this.setState({
        ...this.state,
        reviewed: {
          ...this.state.reviewed,
          offerStatus: "Deleted",
          approvedStatus: "no",
        },
      });
    }
    if (value === "Rejected") {
      this.setState({
        ...this.state,
        reviewed: {
          ...this.state.reviewed,
          approvedStatus: "no",
          offerStatus: "Rejected",
        },
      });
    }
    if (value === "yes") {
      this.setState({
        ...this.state,
        reviewed: {
          ...this.state.reviewed,
          offerStatus: "Live",
          approvedStatus: "yes",
        },
      });
    }
  }

  reviewUpdate(e) {
    e.preventDefault();

    if (
      this.props.contentReview.shopType === "Mobile" &&
      this.props.contentReview.offerType === "item"
    ) {
      var numReg = /^\d+$/;
      if (this.state.reviewed.mobileBrand == "Select a Brand")
        return alert("Please select a brand");
      if (this.state.reviewed.mobileModel == "")
        return alert("Please Enter Model");
      if (this.state.reviewed.offerCategory === "mobile") {
        if (
          this.state.reviewed.mobileRAM == "" ||
          this.state.reviewed.mobileRAM == undefined
        )
          return alert("Please Enter RAM");
        if (
          this.state.reviewed.mobileOS == "Select OS" ||
          this.state.reviewed.mobileOS == undefined
        )
          return alert("Please select Operating System");
        if (
          this.state.reviewed.mobileMemory == "" ||
          this.state.reviewed.mobileMemory == undefined
        )
          return alert("Please Enter Memory");
      }
    }

    Promise.all([
      fetch(
        `${config.ApiGateway}/reviewUpdate`,
        // `${config.ApiGateway}/reviewUpdate`,
        {
          method: "POST",
          body: JSON.stringify(this.state.reviewed),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        alert(data.result);
        this.props.reset();
      })
      .catch((err) => console.log(err));
  }
  render() {
    var imageStorage = [];

    imageStorage = [this.state.reviewed.offerImageLocation];
    let displayImage = [];
    displayImage = imageStorage;

    // console.log(this.state.imageUpload.imagePreviewUrl);
    displayImage =
      this.state.imagePreviewUrl.length > 0
        ? [this.state.imagePreviewUrl]
        : imageStorage;

    return (
      <>
        <Form onSubmit={this.reviewUpdate}>
          <Row>
            <Col md={{ span: 6, offset: 2 }}>
              <Typography
                variant="h3"
                component="h2"
                style={{ textAlign: "center" }}
                gutterBottom
              >
                SHOP DETAILS
              </Typography>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  SHOP NAME
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="shopName"
                    onChange={this.reviewOnchange}
                    value={this.state.shopName}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  MERCHANT NAME
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="userName"
                    onChange={this.reviewOnchange}
                    value={this.state.userName}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  SHOP ID
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="shopId10Digit"
                    value={this.state.shopId10Digit}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  PHONE NUMBER
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="userPhoneNumber"
                    value={this.state.userPhoneNumber}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  PINCODE
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="pincode"
                    onChange={this.reviewOnchange}
                    value={this.state.pincode}
                    disabled={true}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  paddingLeft: 174 + "px",
                }}
              >
                <Typography
                  variant="h3"
                  component="h2"
                  style={{ textAlign: "center" }}
                  gutterBottom
                >
                  OFFER DETAILS
                </Typography>
                <Button
                  onClick={() => {
                    this.editOffer();
                  }}
                >
                  {this.state.editOffer ? (
                    <>
                      EDIT <EditSharpIcon />
                    </>
                  ) : (
                    "CANCEL"
                  )}
                </Button>
              </div>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  OFFER ID
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="offerId"
                    disabled={true}
                    onChange={this.reviewOnchange}
                    value={this.props.contentReview.offerId}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  OFFER DATE
                </Form.Label>
                <Col>
                  <Form.Control
                    type="date"
                    disabled={this.state.editOffer}
                    name="offerStartDate"
                    onChange={this.reviewOnchange}
                    value={this.state.reviewed.offerStartDate}
                  ></Form.Control>
                </Col>

                <Col>
                  <Form.Control
                    type="date"
                    disabled={this.state.editOffer}
                    onChange={this.reviewOnchange}
                    name="offerEndDate"
                    value={this.state.reviewed.offerEndDate}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  OFFER TIMING
                </Form.Label>
                <Col>
                  <Form.Control
                    type="time"
                    name="offerStartTime"
                    onChange={this.reviewOnchange}
                    disabled={this.state.editOffer}
                    value={this.state.reviewed.offerStartTime}
                  ></Form.Control>
                </Col>
                <Col>
                  <Form.Control
                    type="time"
                    name="offerEndTime"
                    onChange={this.reviewOnchange}
                    disabled={this.state.editOffer}
                    value={this.state.reviewed.offerEndTime}
                  ></Form.Control>
                </Col>
              </Form.Group>
              {!(
                this.props.contentReview.offerType === "item" &&
                this.props.contentReview.shopType === "Mobile"
              ) && (
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    OFFER DESCRIPTION
                  </Form.Label>
                  <Col>
                    <Form.Control
                      as="textarea"
                      rows="4"
                      name="offerDetails"
                      onChange={this.reviewOnchange}
                      value={this.state.reviewed.offerDetails}
                    ></Form.Control>
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  OFFER ADDED ON
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    disabled={true}
                    name="offerAddedDate"
                    onChange={this.reviewOnchange}
                    value={this.props.contentReview.offerAddedDate}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  OFFER ORIGINAL PRICE
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    onChange={this.priceChange}
                    pattern="[0-9]{1,10}"
                    name="offerOriginalPrice"
                    disabled={this.state.editOffer}
                    value={this.state.reviewed.offerOriginalPrice}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  OFFER NEW PRICE
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    pattern="[0-9]{1,10}"
                    name="offerNewPrice"
                    onChange={this.priceChange}
                    disabled={this.state.editOffer}
                    value={this.state.reviewed.offerNewPrice}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  OFFER DISCOUNT
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="offerDiscount"
                    // onChange={this.reviewOnchange}
                    disabled={true}
                    value={this.state.reviewed.offerDiscount}
                  ></Form.Control>
                </Col>
              </Form.Group>
              {this.props.contentReview.offerType === "item" &&
                this.props.contentReview.shopType === "Textile" && (
                  <>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        OFFER CATEGORY
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="offerCategory"
                          onChange={this.reviewOnchange}
                          value={this.state.reviewed.offerCategory}
                          disabled={this.state.editOffer}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        SUB CATEGORY
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="offerSubCategory"
                          onChange={this.reviewOnchange}
                          value={this.state.reviewed.offerSubCategory}
                          disabled={this.state.editOffer}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        OFFER TAG
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="offerTag"
                          onChange={this.reviewOnchange}
                          disabled={this.state.editOffer}
                          value={this.state.reviewed.offerTag}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                  </>
                )}

              {this.props.contentReview.offerType === "item" &&
                this.props.contentReview.shopType === "Mobile" && (
                  <>
                    <Form.Group as={Row}>
                      <Form.Label className="asterik" column sm="4">
                        Category
                      </Form.Label>
                      <Col>
                        <Form.Control
                          as="select"
                          onChange={this.reviewOnchange}
                          name="offerCategory"
                          value={this.state.reviewed.offerCategory}
                          disabled={this.state.editOffer}
                          required
                        >
                          <option value="mobile">Mobile</option>
                          <option value="accessories">Accessories</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label className="asterik" column sm="4">
                        Brand
                      </Form.Label>
                      <Col>
                        <Form.Control
                          as="select"
                          onChange={this.reviewOnchange}
                          name="mobileBrand"
                          value={this.state.reviewed.mobileBrand}
                          disabled={this.state.editOffer}
                          required
                        >
                          <option value="">{"Select Brand"}</option>
                          {this.state.brandList}
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label className="asterik" column sm="4">
                        Mobile Model
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          onChange={this.reviewOnchange}
                          name="mobileModel"
                          disabled={this.state.editOffer}
                          value={this.state.reviewed.mobileModel}
                          required
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    {this.state.reviewed.offerCategory === "mobile" && (
                      <>
                        <Form.Group as={Row}>
                          <Form.Label className="asterik" column sm="4">
                            Memory
                          </Form.Label>
                          <Col>
                            <Form.Control
                              type="text"
                              onChange={this.reviewOnchange}
                              name="mobileMemory"
                              disabled={this.state.editOffer}
                              value={this.state.reviewed.mobileMemory}
                              required
                            ></Form.Control>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Memory Extendable
                          </Form.Label>
                          <Col>
                            <Form.Control
                              disabled={this.state.editOffer}
                              type="text"
                              onChange={this.reviewOnchange}
                              name="mobileMemoryExtendable"
                              value={this.state.reviewed.mobileMemoryExtendable}
                            ></Form.Control>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Dimension
                          </Form.Label>
                          <Col>
                            <Form.Control
                              type="text"
                              disabled={this.state.editOffer}
                              onChange={this.reviewOnchange}
                              name="mobileDimensions"
                              placeholder="15.7 * 0.9"
                              value={this.state.reviewed.mobileDimensions}
                            ></Form.Control>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                          <Form.Label className="asterik" column sm="4">
                            RAM
                          </Form.Label>
                          <Col>
                            <Form.Control
                              type="text"
                              onChange={this.reviewOnchange}
                              name="mobileRAM"
                              value={this.state.reviewed.mobileRAM}
                              required
                              disabled={this.state.editOffer}
                            ></Form.Control>
                          </Col>
                          <Form.Label className="asterik" column sm="4">
                            OS
                          </Form.Label>
                          <Col>
                            <Form.Control
                              as="select"
                              onChange={this.reviewOnchange}
                              name="mobileOS"
                              disabled={this.state.editOffer}
                              value={this.state.reviewed.mobileOS}
                              required
                            >
                              <option value="">{"Select OS"}</option>
                              {this.state.OsList}
                            </Form.Control>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Front Camera Pixel
                          </Form.Label>
                          <Col>
                            <Form.Control
                              type="text"
                              onChange={this.reviewOnchange}
                              pattern="[0-9]{1,10}"
                              name="mobileFrontFacingCamera"
                              value={
                                this.state.reviewed.mobileFrontFacingCamera
                              }
                              placeholder="Mega Pixel"
                              disabled={this.state.editOffer}
                            ></Form.Control>
                          </Col>
                          <Form.Label column sm="4">
                            Rear Camera pixel
                          </Form.Label>
                          <Col>
                            <Form.Control
                              type="text"
                              pattern="[0-9]{1,10}"
                              onChange={this.reviewOnchange}
                              name="mobileRearFacingCamera"
                              disabled={this.state.editOffer}
                              value={this.state.reviewed.mobileRearFacingCamera}
                              placeholder="Mega Pixel"
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                      </>
                    )}

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Mobile Color
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          onChange={this.reviewOnchange}
                          name="mobileColor"
                          disabled={this.state.editOffer}
                          value={this.state.reviewed.mobileColor}
                        ></Form.Control>
                      </Col>
                      <Form.Label column sm="4">
                        Mobile Weight
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          pattern="[0-9]{1,10}"
                          onChange={this.reviewOnchange}
                          name="mobileWeight"
                          value={this.state.reviewed.mobileWeight}
                          placeholder="In Gms"
                          disabled={this.state.editOffer}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Other Details
                      </Form.Label>
                      <Col>
                        <Form.Control
                          as="textarea"
                          rows="6"
                          onChange={this.reviewOnchange}
                          name="mobileOtherDetails"
                          disabled={this.state.editOffer}
                          value={this.state.reviewed.mobileOtherDetails}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Service Details
                      </Form.Label>
                      <Col>
                        <Form.Control
                          as="textarea"
                          rows="6"
                          onChange={this.reviewOnchange}
                          name="mobileServiceDetails"
                          disabled={this.state.editOffer}
                          value={this.state.reviewed.mobileServiceDetails}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                  </>
                )}

              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  OFFER STATUS
                </Form.Label>
                <Col>
                  <Form.Control
                    as="select"
                    name="offerStatus"
                    value={this.state.reviewed.offerStatus}
                    onChange={this.ApprovedStatus}
                    required
                  >
                    <option selected={this.state.reviewed.offerStatus}></option>
                    {this.state.statusArray}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  OFFER TYPE
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    onChange={this.reviewOnchange}
                    name="offerType"
                    disabled={true}
                    value={this.props.contentReview.offerType}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label className="astreik" column sm="4">
                  APPROVED
                </Form.Label>

                <Col>
                  <Form.Check
                    label="YES"
                    type="radio"
                    name="approvedStatus"
                    onChange={this.ApprovedStatus}
                    checked={this.state.reviewed.approvedStatus === "yes"}
                    value="yes"
                    required
                  />
                </Col>
                <Col>
                  <Form.Check
                    label="NO"
                    type="radio"
                    name="approvedStatus"
                    checked={this.state.reviewed.approvedStatus === "no"}
                    onChange={this.ApprovedStatus}
                    value="no"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label className="astreik" column sm="4">
                  OFFER IMAGE QUALITY
                </Form.Label>

                <Col>
                  <Form.Control
                    as="select"
                    onChange={this.reviewOnchange}
                    name="imageQuality"
                    value={this.state.reviewed.imageQuality}
                    required
                  >
                    <option value="">Select Image Quality</option>
                    <option value="good"> Good</option>
                    <option value="low">Low</option>

                    <option value="medium">Medium</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              {this.state.reviewed.approvedStatus === "no" && (
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      REJECTED REASON
                    </Form.Label>

                    <Col>
                      <Form.Control
                        as="select"
                        onChange={this.reviewOnchange}
                        name="reviewRejectedReason"
                        value={this.state.reviewed.reviewRejectedReason}
                      >
                        <option
                          selected={this.state.reviewed.reviewRejectedReason}
                        ></option>
                        {this.state.reviewArray}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      REVIEW COMMENT
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="textarea"
                        rows="4"
                        name="reviewComment"
                        onChange={this.reviewOnchange}
                        value={this.state.reviewed.reviewComment}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                </>
              )}
            </Col>

            <Col md={{ span: 4 }}>
              <>
                {displayImage.map((item) => (
                  <>
                    <div className="brightness">
                      <img
                        name="offerImageLocation"
                        src={item}
                        alt={item}
                        height="250px"
                        width="250px"
                        style={{
                          marginTop: 25 + "%",
                          border: 0.5 + "px",
                          borderStyle: "solid",
                          borderColor: "#ced4da",
                          maxwidth: 100 + "%",
                          maxHeight: 100 + "%",
                          marginLeft: 10 + "%",
                        }}
                        onClick={() => {
                          this.setState({
                            isOpen: true,
                          });
                        }}
                      />
                    </div>
                    <div className="removeimage">
                      {!this.state.editOffer && (
                        <Button onClick={this.removeImage}>
                          <CancelOutlinedIcon style={{ fontSize: 20 }} />
                        </Button>
                      )}
                    </div>
                    {this.state.changeImage && (
                      <Col>
                        <input type="file" onChange={this.handleimage}></input>
                      </Col>
                    )}
                  </>
                ))}
                {/* {this.state.changeImage && !this.state.editOffer ? (
                  <>
                    <Button
                      style={{ marginLeft: 90 + "%" }}
                      onClick={this.removeImage}
                    >
                      CANCEL
                    </Button>

                    <Col>
                      <div className="smartphone">
                        <h3>PREVIEW OFFER IMAGE</h3>

                        <h1>
                          width : {this.state.width}
                          <br />
                          height : {this.state.height}
                        </h1>
                        <img
                          src={this.state.imageUpload.imagePreviewUrl}
                          alt={this.state.imageUpload.imagePreviewUrl}
                          onLoad={this.onImgLoad}
                          style={{
                            paddingLeft: 7 + "px",
                          }}
                          width="300px"
                          height="300px"
                        ></img>
                      </div>
                    </Col>
                    <Col>
                      <Form.Label column md={2}>
                        UPLOAD IMAGE
                      </Form.Label>
                      <input type="file" onChange={this.handleimage}></input>
                    </Col>
                    <Button
                      onClick={this.uploadImage}
                      style={{
                        float: "right",
                        marginBottom: 20 + "px",
                        marginTop: 20 + "px",
                      }}
                    >
                      UPLOAD
                    </Button>
                  </>
                ) : (
                  <>
                    {displayImage.map((item) => (
                      <>
                        <div className="brightness">
                          <img
                            name="offerImageLocation"
                            src={item}
                            alt={item}
                            height="250px"
                            width="250px"
                            style={{
                              marginTop: 25 + "%",
                              border: 0.5 + "px",
                              borderStyle: "solid",
                              borderColor: "#ced4da",
                              maxwidth: 100 + "%",
                              maxHeight: 100 + "%",
                              marginLeft: 10 + "%",
                            }}
                            onClick={() => {
                              this.setState({
                                isOpen: true,
                              });
                            }}
                          />
                        </div>
                        <div className="removeimage">
                          {!this.state.editOffer && (
                            <Button onClick={this.removeImage}>
                              <CancelOutlinedIcon style={{ fontSize: 20 }} />
                            </Button>
                          )}
                        </div>
                      </>
                    ))}
                  </>
                )} */}
              </>

              {this.state.isOpen && (
                <Lightbox
                  mainSrc={imageStorage[this.state.imageIndex]}
                  nextSrc={
                    imageStorage[
                      (this.state.imageIndex + 1) % imageStorage.length
                    ]
                  }
                  prevSrc={
                    imageStorage[
                      (this.state.imageIndex + imageStorage.length - 1) %
                        imageStorage.length
                    ]
                  }
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      imageIndex:
                        (this.state.imageIndex + imageStorage.length - 1) %
                        imageStorage.length,
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      imageIndex:
                        (this.state.imageIndex + 1) % imageStorage.length,
                    })
                  }
                />
              )}
            </Col>
          </Row>
          <Button
            disabled={this.state.editOffer ? false : true}
            style={{ marginLeft: 55 + "%" }}
            type="submit"
          >
            submit
          </Button>
        </Form>

        {this.state.lastApprovedby !== undefined && (
          <>
            <Row>
              <Col>
                <Typography
                  variant="h6"
                  component="h6"
                  // style={{ textAlign: "center" }}
                  gutterBottom
                >
                  LAST APPROVED BY
                </Typography>
                <Typography
                  variant="h6"
                  component="h6"
                  // style={{ textAlign: "center" }}
                  gutterBottom
                >
                  {this.state.lastApprovedby}
                </Typography>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}
