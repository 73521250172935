import React, { useEffect, useState, useContext, createRef } from "react";
import { Container, Col, Button, Row, Form, Tab, Tabs } from "react-bootstrap";
import config from "../config";
import Limit from "../limit";
import GetAppIcon from "@material-ui/icons/GetApp";
import PeopleIcon from "@material-ui/icons/People";
import { useHistory } from "react-router-dom";
import { store } from "../provider";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import { CSVLink } from "react-csv";
export default function MerchantManagement() {
  const history = useHistory();
  const context = useContext(store);
  const csvLink = createRef();
  const [downloadData, setDownloadData] = useState([]);
  const [active, setActive] = useState(false);

  var initialDate = new Date();

  initialDate.setDate(initialDate.getDate() - 15);
  const [days, setDays] = useState("30");
  const [ghoshakShops, setGhoshakShops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activate, setActivate] = useState([]);
  const [engage, setEngage] = useState([]);
  const [retain, setRetain] = useState([]);
  const [accquiredList, setAccquiredList] = useState([]);
  const [key, setKey] = useState("home");
  const [selectMonthLifeCycle, setSelectMonthLifeCycle] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: initialDate.toISOString().substring(0, 10),
    endDate: new Date().toISOString().substring(0, 10),
  });
  const [daysInaMonth, setDaysInaMonth] = useState([]);
  const [monthLifeCycleValue, setMonthLifeCycleValue] = useState("");
  const [month, setMonth] = useState([
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUNE",
    "JULY",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ]);
  const [DAU, setDAU] = useState({});
  const [monthDays, setMonthDays] = useState([]);
  const handleitemsByDay = (value, index) => {
    let dateNumber = value.toString().length == 1 ? `0${value}` : value;
    let date = `${monthLifeCycleValue.substring(0, 8)}${dateNumber}`;
    console.log(value, index, date);
    setDateRange((prevState) => ({
      ...prevState,
      startDate: date,
      endDate: date,
    }));
    setMonthDays(value);
  };
  const handleClick = async (value) => {
    let lastActivityDays = value;
    let currentDate = new Date();
    let date1 = new Date(
      currentDate.setDate(currentDate.getDate() - lastActivityDays)
    )
      .toISOString()
      .substring(0, 10);
    const date2 = new Date().toISOString().substring(0, 10);

    setDateRange((prevState) => ({
      ...prevState,
      startDate: date1,
      endDate: date2,
    }));
    setIsLoading(true);
    setDays(value);
  };
  useEffect(() => {
    var getShopDetails = [];

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          _source: {
            includes: [
              "userPhoneNumber",
              "userId",
              "userType",
              "userPincode",
              "userName",
              "userId10Digit",
              "pin",
            ],
          },
          query: {
            bool: {
              must: [{ match: { userType: "Shop" } }],
              must_not: {
                bool: {
                  must: [{ term: { userPincode: "111111" } }],
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let initialDate = new Date();
        let currentYear = initialDate.getFullYear();
        let monthLifeCycle = [];
        for (let i = 0; i < 12; i++) {
          let firstDay = new Date(currentYear, i, 1);
          let lastDay = new Date(currentYear, i + 1, 0);

          monthLifeCycle.push(
            <option
              value={[
                [moment(firstDay).format("YYYY-MM-DD")],
                [moment(lastDay).format("YYYY-MM-DD")],
              ]}
            >
              {month[0 + i]}
            </option>
          );
        }

        let tempdata = data.hits.hits.map((element) => element._source);

        tempdata.forEach((elements) => {
          getShopDetails.push({
            userId: elements.userId,

            userId10Digit: elements.userId10Digit,
            userName: elements.userName,
            userPhoneNumber: elements.userPhoneNumber,
            userPincode: elements.userPincode,
          });
        });
        setSelectMonthLifeCycle(monthLifeCycle);
        setGhoshakShops(getShopDetails);
      })

      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    findMerchantActivity();
  }, [ghoshakShops, dateRange]);
  const findMerchantActivity = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/merchantManagement`, {
        method: "POST",
        body: JSON.stringify({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        let activateList = [];
        let engageList = [];
        let retainList = [];
        let segementByb2b = {};

        if (key == "DAU") {
          data.shop.forEach((elements) => {
            if (segementByb2b[elements.shopType] == undefined) {
              segementByb2b[elements.shopType] = {
                activateList: [],
                engageList: [],
                retainList: [],
                accquiredList: [],
              };
            }
            if (parseInt(elements.totalActivity) <= Limit.MactivateLimit)
              segementByb2b[elements.shopType].activateList.push(elements);
            if (
              parseInt(elements.totalActivity) >= Limit.MengageLimit &&
              parseInt(elements.totalActivity) <= Limit.MretainLimit - 1
            )
              segementByb2b[elements.shopType].engageList.push(elements);
            if (parseInt(elements.totalActivity) >= Limit.MretainLimit)
              segementByb2b[elements.shopType].retainList.push(elements);
          });

          data.accquiredList.forEach((elements) => {
            if (segementByb2b[elements.shopType] == undefined) {
              segementByb2b[elements.shopType] = {
                activateList: [],
                engageList: [],
                retainList: [],
                accquiredList: [],
              };
            }

            segementByb2b[elements.shopType].accquiredList.push(elements);
          });

          setDAU(segementByb2b);
        } else if (key == "MAU") {
          data.shop.forEach((elements) => {
            if (segementByb2b[elements.shopType] == undefined) {
              segementByb2b[elements.shopType] = {
                activateList: [],
                engageList: [],
                retainList: [],
                accquiredList: [],
              };
            }
            if (parseInt(elements.totalActivity) <= Limit.MactivateLimit)
              segementByb2b[elements.shopType].activateList.push(elements);
            if (
              parseInt(elements.totalActivity) >= Limit.MengageLimit &&
              parseInt(elements.totalActivity) <= Limit.MretainLimit - 1
            )
              segementByb2b[elements.shopType].engageList.push(elements);
            if (parseInt(elements.totalActivity) >= Limit.MretainLimit)
              segementByb2b[elements.shopType].retainList.push(elements);
          });

          data.accquiredList.forEach((elements) => {
            if (segementByb2b[elements.shopType] == undefined) {
              segementByb2b[elements.shopType] = {
                activateList: [],
                engageList: [],
                retainList: [],
                accquiredList: [],
              };
            }

            segementByb2b[elements.shopType].accquiredList.push(elements);
          });

          setDAU(segementByb2b);
        } else {
          data.shop.forEach((elements) => {
            if (parseInt(elements.totalActivity) <= Limit.MactivateLimit)
              activateList.push(elements);
            if (
              parseInt(elements.totalActivity) >= Limit.MengageLimit &&
              parseInt(elements.totalActivity) <= Limit.MretainLimit - 1
            )
              engageList.push(elements);
            if (parseInt(elements.totalActivity) >= Limit.MretainLimit)
              retainList.push(elements);
          });

          setActivate(activateList);
          setEngage(engageList);
          setRetain(retainList);
          setAccquiredList(data.accquiredList);
        }

        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [downloadData]);

  const downloadCSV = async (data) => {
    let modifyData = [];
    let monthName = moment()
      .month(parseInt(monthLifeCycleValue.substring(5, 7)) - 1)
      .format("MMMM");

    Object.entries(data).forEach(([header, value]) => {
      let avgSegment = `${(
        (value.retainList.length +
          value.engageList.length +
          value.activateList.length +
          value.accquiredList.length) /
        parseInt(moment(monthLifeCycleValue.substring(0, 7)).daysInMonth())
      ).toFixed(2)} %`;

      modifyData.push({
        date:
          key == "DAU"
            ? `${monthLifeCycleValue.substring(0, 8)}${monthDays}`
            : monthName,
        segment: header,

        accquired: value.accquiredList.length,
        retain: value.retainList.length,
        activate: value.activateList.length,
        engage: value.engageList.length,
        average: avgSegment,
      });
    });
    setDownloadData(modifyData);
    setActive(true);
  };
  return (
    <>
      <Container fluid>
        <div style={{ width: "100%", height: "40px" }}></div>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Merchant Management</h2>
        </div>

        <Tabs
          activeKey={key}
          onSelect={(k) => {
            if (k == "DAU") {
            }

            setKey(k);
          }}
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
        >
          <Tab eventKey="home" title="Merchant Managemnet">
            <div className="flexCardHeader">
              <label className="flexCardHeaderlabel">
                <Form.Row>
                  <Form.Label>Filter By Month</Form.Label>
                  <Form.Control
                    as="select"
                    value={monthLifeCycleValue}
                    onChange={(e) => {
                      let value = e.target.value;

                      setDateRange((prevState) => ({
                        ...prevState,
                        startDate: value.substring(0, 10),
                        endDate: value.substring(11, 21),
                      }));
                      setMonthLifeCycleValue(value);
                      setDays("custom");
                      setIsLoading(true);
                    }}
                  >
                    {selectMonthLifeCycle}
                  </Form.Control>
                </Form.Row>
              </label>

              <nav class="site-nav">
                <ul className="flexCardUl">
                  <li
                    className={days === "15" ? "active" : ""}
                    onClick={() => handleClick("15")}
                  >
                    <a href="#">15</a>
                  </li>
                  <li
                    className={days === "30" ? "active" : ""}
                    onClick={() => handleClick("30")}
                  >
                    <a href="#">30</a>
                  </li>
                  <li
                    className={days === "60" ? "active" : ""}
                    onClick={() => handleClick("60")}
                  >
                    <a href="#">60</a>
                  </li>
                  <li
                    className={days === "90" ? "active" : ""}
                    onClick={() => handleClick("90")}
                  >
                    <a href="#">90</a>
                  </li>
                  <li
                    className={days === "182" ? "active" : ""}
                    onClick={() => handleClick("182")}
                  >
                    <a href="#">182</a>
                  </li>
                  <li
                    className={days === "365" ? "active" : ""}
                    onClick={() => handleClick("365")}
                  >
                    <a href="#">365</a>
                  </li>
                  <li className={days === "custom" ? "active" : ""}>
                    <a href="#">Custom</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div style={{ width: "100%", height: "10px" }}></div>
            <div className="orderHeader">
              <h2 className="ordersubHeaderText">Acquired Shops</h2>
              <button
                onClick={async () => {
                  await context.dispatch({
                    type: "Acquired",
                    customerDetails: accquiredList,
                  });
                  history.push(`/merchantDetails`);
                }}
                className="orderDashboardViewallBtn"
              >
                View all
              </button>
            </div>
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Skeleton animation="wave" width={340} height={138} />
              </div>
            ) : (
              <Row className="subContentRow">
                <Col sm={4} className="splitCol">
                  <div className="cardBodyRow">
                    <Row>
                      <Col className="cardBodyDelivery">
                        <p className="tabRowContentSubHeading">
                          Total Downloads
                        </p>
                        <h3>{accquiredList.length}</h3>
                      </Col>
                      <Col
                        style={{ justifyContent: "center" }}
                        className="cardBodyDeliveryIcon"
                      >
                        <GetAppIcon fontSize="large" />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}
            <div style={{ width: "100%", height: "10px" }}></div>
            <div className="orderHeader">
              <h2 className="ordersubHeaderText">Activate </h2>
              <button
                onClick={async () => {
                  await context.dispatch({
                    type: "Activate",
                    customerDetails: activate,
                  });
                  history.push(`/merchantDetails`);
                }}
                className="orderDashboardViewallBtn"
              >
                View all
              </button>
            </div>
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Skeleton animation="wave" width={340} height={138} />
              </div>
            ) : (
              <Row className="subContentRow">
                <Col sm={4} className="splitCol">
                  <div className="cardBodyRow">
                    <Row>
                      <Col className="cardBodyDelivery">
                        <p className="tabRowContentSubHeading">
                          Total Customers
                        </p>
                        <h3>{activate.length}</h3>
                      </Col>
                      <Col
                        style={{ justifyContent: "center" }}
                        className="cardBodyDeliveryIcon"
                      >
                        <PeopleIcon fontSize="large" />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}
            <div style={{ width: "100%", height: "10px" }}></div>

            <div className="orderHeader">
              <h2 className="ordersubHeaderText">Engage </h2>
              <button
                onClick={async () => {
                  await context.dispatch({
                    type: "Engage",
                    customerDetails: engage,
                  });
                  history.push(`/merchantDetails`);
                }}
                className="orderDashboardViewallBtn"
              >
                View all
              </button>
            </div>
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Skeleton animation="wave" width={340} height={138} />
              </div>
            ) : (
              <Row className="subContentRow">
                <Col sm={4} className="splitCol">
                  <div className="cardBodyRow">
                    <Row>
                      <Col className="cardBodyDelivery">
                        <p className="tabRowContentSubHeading">
                          Total Customers
                        </p>
                        <h3>{engage.length}</h3>
                      </Col>
                      <Col
                        style={{ justifyContent: "center" }}
                        className="cardBodyDeliveryIcon"
                      >
                        <PeopleIcon fontSize="large" />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}
            <div style={{ width: "100%", height: "10px" }}></div>
            <div className="orderHeader">
              <h2 className="ordersubHeaderText">Retain </h2>
              <button
                onClick={async () => {
                  await context.dispatch({
                    type: "Retain",
                    customerDetails: retain,
                  });
                  history.push(`/merchantDetails`);
                }}
                className="orderDashboardViewallBtn"
              >
                View all
              </button>
            </div>

            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Skeleton animation="wave" width={340} height={138} />
              </div>
            ) : (
              <Row className="subContentRow">
                <Col sm={4} className="splitCol">
                  <div className="cardBodyRow">
                    <Row>
                      <Col className="cardBodyDelivery">
                        <p className="tabRowContentSubHeading">
                          Total Customers
                        </p>
                        <h3>{retain.length}</h3>
                      </Col>
                      <Col
                        style={{ justifyContent: "center" }}
                        className="cardBodyDeliveryIcon"
                      >
                        <PeopleIcon fontSize="large" />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}
          </Tab>
          <Tab eventKey="DAU" title="Daily Active users">
            <div className="flexCardHeader">
              <label className="flexCardHeaderlabel">
                <Form.Row>
                  <Form.Label>Filter By Month</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let value = e.target.value;
                      const daysInMonth = moment(
                        value.substring(0, 7)
                      ).daysInMonth();

                      let daysArray = Array.from(
                        { length: daysInMonth },
                        (v, k) => k + 1
                      );
                      setMonthLifeCycleValue(value);
                      setDays("custom");

                      setDaysInaMonth(daysArray);
                    }}
                    as="select"
                    value={monthLifeCycleValue}
                  >
                    {selectMonthLifeCycle}
                  </Form.Control>
                </Form.Row>
              </label>
              <label className="flexCardHeaderlabel">
                <Button
                  onClick={() => downloadCSV(DAU)}
                  style={{ float: "right", marginBottom: "10px" }}
                >
                  Download
                </Button>
                <CSVLink data={downloadData} ref={csvLink} target="_blank" />
              </label>
            </div>
            <div style={{ fontSize: "12px", width: "100%", overflowX: "auto" }}>
              <nav class="site-nav">
                <ul className="b2bActiveuser">
                  {daysInaMonth.map((items, index) => (
                    <li
                      className={monthDays === items ? "active" : ""}
                      onClick={() => handleitemsByDay(items, index + 1)}
                    >
                      <a href="#">{items}</a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>

            {Object.entries(DAU).map(([header, value]) => (
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col className="division" sm={{ span: 11 }}>
                  <div
                    className="headerName"
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%",
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}> {header}</h3>
                  </div>
                  <Row
                    style={{
                      marginBottom: 20 + "px",
                      marginTop: 20 + "px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {isLoading ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Skeleton animation="wave" width={340} height={138} />
                      </div>
                    ) : (
                      <Col className="verticalRightBorder" sm={{ span: 2 }}>
                        <label style={{ textAlign: "center" }}>Acquired</label>
                        <h3 style={{ textAlign: "center" }}>
                          <b>{value.accquiredList.length}</b>
                        </h3>
                      </Col>
                    )}
                    {isLoading ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Skeleton animation="wave" width={340} height={138} />
                      </div>
                    ) : (
                      <Col className="verticalRightBorder" sm={{ span: 2 }}>
                        <label style={{ textAlign: "center" }}>Activate</label>
                        <h3 style={{ textAlign: "center" }}>
                          <b>{value.activateList.length} </b>
                        </h3>
                      </Col>
                    )}
                    {isLoading ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Skeleton animation="wave" width={340} height={138} />
                      </div>
                    ) : (
                      <Col className="verticalRightBorder" sm={{ span: 2 }}>
                        <label style={{ textAlign: "center" }}>Engage</label>
                        <h3 style={{ textAlign: "center" }}>
                          <b>{value.engageList.length} </b>
                        </h3>
                      </Col>
                    )}
                    {isLoading ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Skeleton animation="wave" width={340} height={138} />
                      </div>
                    ) : (
                      <Col className="verticalRightBorder" sm={{ span: 2 }}>
                        <label style={{ textAlign: "center" }}>Retain</label>
                        <h3 style={{ textAlign: "center" }}>
                          <b>{value.retainList.length} </b>
                        </h3>
                      </Col>
                    )}
                    {isLoading ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Skeleton animation="wave" width={340} height={138} />
                      </div>
                    ) : (
                      <Col className="verticalRightBorder" sm={{ span: 2 }}>
                        <label style={{ textAlign: "center" }}>Average</label>
                        <h3 style={{ textAlign: "center" }}>
                          <b>
                            {(
                              (value.retainList.length +
                                value.engageList.length +
                                value.activateList.length +
                                value.accquiredList.length) /
                              parseInt(
                                moment(
                                  monthLifeCycleValue.substring(0, 7)
                                ).daysInMonth()
                              )
                            ).toFixed(2)}{" "}
                            {" % "}
                          </b>
                        </h3>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            ))}
          </Tab>

          <Tab eventKey="MAU" title="Monthly Active users">
            <div className="flexCardHeader">
              <label className="flexCardHeaderlabel">
                <Form.Row>
                  <Form.Label>Filter By Month</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      let value = e.target.value;
                      setDateRange((prevState) => ({
                        ...prevState,
                        startDate: value.substring(0, 10),
                        endDate: value.substring(11, 21),
                      }));
                      setMonthLifeCycleValue(value);
                      setDays("custom");
                      setIsLoading(true);
                    }}
                    as="select"
                    value={monthLifeCycleValue}
                  >
                    {selectMonthLifeCycle}
                  </Form.Control>
                </Form.Row>
              </label>
              <label className="flexCardHeaderlabel">
                <Button
                  onClick={() => downloadCSV(DAU)}
                  style={{ float: "right", marginBottom: "10px" }}
                >
                  Download
                </Button>
                <CSVLink data={downloadData} ref={csvLink} target="_blank" />
              </label>
            </div>
            <div className="vertical-space-5"></div>
            {Object.entries(DAU).map(([header, value]) => (
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col className="division" sm={{ span: 11 }}>
                  <div
                    className="headerName"
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%",
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}> {header}</h3>
                  </div>
                  <Row
                    style={{
                      marginBottom: 20 + "px",
                      marginTop: 20 + "px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {isLoading ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Skeleton animation="wave" width={340} height={138} />
                      </div>
                    ) : (
                      <Col className="verticalRightBorder" sm={{ span: 2 }}>
                        <label style={{ textAlign: "center" }}>Acquired</label>
                        <h3 style={{ textAlign: "center" }}>
                          <b>{value.accquiredList.length}</b>
                        </h3>
                      </Col>
                    )}
                    {isLoading ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Skeleton animation="wave" width={340} height={138} />
                      </div>
                    ) : (
                      <Col className="verticalRightBorder" sm={{ span: 2 }}>
                        <label style={{ textAlign: "center" }}>Activate</label>
                        <h3 style={{ textAlign: "center" }}>
                          <b>{value.activateList.length} </b>
                        </h3>
                      </Col>
                    )}
                    {isLoading ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Skeleton animation="wave" width={340} height={138} />
                      </div>
                    ) : (
                      <Col className="verticalRightBorder" sm={{ span: 2 }}>
                        <label style={{ textAlign: "center" }}>Engage</label>
                        <h3 style={{ textAlign: "center" }}>
                          <b>{value.engageList.length} </b>
                        </h3>
                      </Col>
                    )}
                    {isLoading ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Skeleton animation="wave" width={340} height={138} />
                      </div>
                    ) : (
                      <Col className="verticalRightBorder" sm={{ span: 2 }}>
                        <label style={{ textAlign: "center" }}>Retain</label>
                        <h3 style={{ textAlign: "center" }}>
                          <b>{value.retainList.length} </b>
                        </h3>
                      </Col>
                    )}
                    {isLoading ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Skeleton animation="wave" width={340} height={138} />
                      </div>
                    ) : (
                      <Col className="verticalRightBorder" sm={{ span: 2 }}>
                        <label style={{ textAlign: "center" }}>Average</label>
                        <h3 style={{ textAlign: "center" }}>
                          <b>
                            {(
                              (value.retainList.length +
                                value.engageList.length +
                                value.activateList.length +
                                value.accquiredList.length) /
                              parseInt(
                                moment(
                                  monthLifeCycleValue.substring(0, 7)
                                ).daysInMonth()
                              )
                            ).toFixed(2)}{" "}
                            {" % "}
                          </b>
                        </h3>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            ))}
          </Tab>
        </Tabs>
      </Container>
    </>
  );
}
