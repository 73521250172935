export default function SalesManager() {
  this.fields = [
    "promoteoffers",
    "couponpayment",
    "reachus",
    "customer",
    "couponPayment",
    "distribution",
    "getExpiration",
    "crm",
    "salestarget",
    "notification",
    "phonenumber",
    "pincodeDistribution",
    "dashboard",
    "customerManagement",
    "userAdded",
    "gstore",
    "package",
    "telecalling"
  ];
}
