import React, { Component } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import config from "../../config";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class CustomerDOB extends Component {
  constructor(props) {
    super(props);
    this.month = [
      { id: "01", monthName: "JAN" },
      { id: "02", monthName: "FEB" },
      { id: "03", monthName: "MAR" },
      { id: "04", monthName: "APR" },
      { id: "05", monthName: "MAY" },
      { id: "06", monthName: "JUNE" },
      { id: "07", monthName: "JULY" },
      { id: "08", monthName: "AUG" },
      { id: "09", monthName: "SEPT" },
      { id: "10", monthName: "OCT" },
      { id: "11", monthName: "NOV" },
      { id: "12", monthName: "DEC" },
    ];
    this.handleFunction = this.handleFunction.bind(this);
    this.state = {
      showData: false,
      nextMonthList: [],
      nextWeekList: [],
      nextWeek: [],
      page: 0,
      Customerpage: 0,
      rowsPerPage: 5,
      rowsPerPageCustomer: 10,
      customerDetails: [],
      showCustomerList: false,
      filterBy: "All",
      sortDob: {},
      tomorrowList: [],
      mergedArea: [],
      filterPincode: "All",
      exportData: [],
      headers: [],
    };
    this.handleFunction = this.handleFunction.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleCustomerPage = this.handleCustomerPage.bind(this);
    this.handleCustomerPagePerRow = this.handleCustomerPagePerRow.bind(this);
    this.handleFilterByCustomer = this.handleFilterByCustomer.bind(this);
    this.getCustomer = this.getCustomer.bind(this);
    this.onChange = this.onChange.bind(this);
    this.csvLink = React.createRef();
  }
  export() {
    let tempArr = [];
    this.state.customerDetails
      .filter((headers) =>
        this.state.filterPincode === "All"
          ? headers
          : headers.userPincode === this.state.filterPincode.slice(-6)
      )
      .map((elements) => tempArr.push(elements));

    const headers = [
      { label: "Customer ID", key: "userId10Digit" },
      { label: "Pincode", key: "userPincode" },
      { label: "Name", key: "userName" },
      { label: "Phone Number", key: "userPhoneNumber" },
      { label: "Place", key: "place" },

      { label: "Date of birth", key: "userDOB" },
    ];

    this.setState(
      {
        headers: headers,
        exportData: tempArr,
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  handleFilterByCustomer(e) {
    let value = e.target.value;

    this.setState({
      filterBy: value,
      Customerpage: 0,
    });
  }

  onChange(e) {
    this.setState({
      filterPincode: e.target.value,
      Customerpage: 0,
    });
  }

  getCustomer(customerid) {
    let getID = customerid.map((key) => key.userId);
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [{ terms: { userId: getID } }],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, data2]) => {
        let tempdata = data.hits.hits.map((element) => element._source);

        var mergePincodeAndArea = tempdata.map((items) => {
          let count = data2.Items.find(
            (elements) => items.userPincode === elements.pincode
          );
          let appendObject = {};
          appendObject.userName = items.userName;
          appendObject.userPhoneNumber = items.userPhoneNumber;
          appendObject.userAddress = items.userAddress;
          appendObject.userId10Digit = items.userId10Digit;
          appendObject.userDOB = items.userDOB;
          if (count !== undefined) {
            appendObject.userPincode = items.userPincode;
            appendObject.place = count.place;
          } else {
            appendObject.userPincode = items.userPincode;
            appendObject.place = "Not Found";
          }

          return appendObject;
        });
        this.setState({
          customerDetails: mergePincodeAndArea,
          showCustomerList: true,
        });
      })
      .catch((err) => console.log(err));
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleCustomerPage = (event, page) => {
    this.setState({ Customerpage: page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  handleCustomerPagePerRow = (event) => {
    this.setState({ rowsPerPageCustomer: event.target.value });
  };
  componentDidMount() {
    this.handleFunction();
  }
  handleFunction() {
    let store_Next_Month = [];
    let nextWeek = [];
    let tommorow = [];
    let currentDate = new Date().toISOString().substring(0, 10); //get current date

    let findNextMonth = new Date();
    findNextMonth.setMonth(findNextMonth.getMonth() + 1); // To find next month

    store_Next_Month.push(findNextMonth.toISOString().substring(5, 7)); //To find tommorow
    let loop = new Date(currentDate);
    tommorow.push(
      new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        .toISOString()
        .substring(0, 10)
    );
    for (var i = 0; i < 7; i++) {
      let matchDate = loop.toISOString().substring(0, 10);

      nextWeek.push(matchDate.substring(5, 10));

      var newDate = loop.setDate(loop.getDate() + 1);
      loop = new Date(newDate);
    }

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        // api call to fetch DOB
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                { match: { userType: "Customer" } },
                {
                  exists: {
                    field: "userDOB",
                  },
                },
              ],
              must_not: {
                bool: {
                  must: [{ term: { userPincode: "111111" } }],
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, area]) => {
        let tempdata = data.hits.hits.map((element) => element._source);
        let sortDob = {};
        let getNextWeek = [];
        let getNextMonth = [];
        let getTomorow = [];
        let pincodes = [];
        tempdata.forEach((list) => {
          pincodes.push(list.userPincode);
          let getMonth = this.month.find(
            (elements) => elements.id === list.userDOB.substring(5, 7)
          );

          if (store_Next_Month.includes(list.userDOB.substring(5, 7))) {
            getNextMonth.push({
              userId: list.userId,
              DOB: list.userDOB,
            });
          }
          if (nextWeek.includes(list.userDOB.substring(5, 10))) {
            getNextWeek.push({
              userId: list.userId,
              DOB: list.userDOB,
            });
          }
          if (tommorow.includes(list.userDOB.substring(0, 10))) {
            getTomorow.push({
              userId: list.userId,
              DOB: list.userDOB,
            });
          }

          if (sortDob[getMonth.monthName] === undefined) {
            sortDob[getMonth.monthName] = [];
          }
          sortDob[getMonth.monthName].push({
            userId: list.userId,
            DOB: list.userDOB,
          });
        });
        pincodes = [...new Set(pincodes)];
        var mergePincodeAndArea = pincodes.map((items) => {
          let count = area.Items.find((elements) => items === elements.pincode);
          let appendObject = {};
          count !== undefined
            ? (appendObject = Object.assign({}, count))
            : (appendObject = Object.assign(
                {},
                {
                  pincode: items,
                  place: "NOT FOUND",
                }
              ));

          return appendObject;
        });

        let mergedArea = mergePincodeAndArea.map((
          elements //Pincode dropdown
        ) => (
          <option key={elements.pincode}>
            {elements.place.toUpperCase()}
            {" - "}
            {elements.pincode}
          </option>
        ));

        this.setState({
          nextMonthList: getNextMonth,
          nextWeekList: getNextWeek,
          sortDob: sortDob,
          loadPage: true,
          tomorrowList: getTomorow,
          mergedArea: mergedArea,
        });
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.loadPage && (
          <>
            <Row style={{ width: 100 + "%", justifyContent: "center" }}>
              <b style={{ fontSize: 32 }}>Date of Birth</b>
            </Row>
            <Row style={{ width: 100 + "%", justifyContent: "center" }}>
              <Col xs={{ span: 3 }} className="division">
                <b>Upcoming Week</b>
                <h3 style={{ textAlign: "center" }}>
                  {this.state.nextWeekList.length}
                </h3>
              </Col>
              <Col xs={{ span: 3, offset: 1 }} className="division">
                <b>
                  <span style={{ textAlign: "center" }}>Next Month</span>
                </b>
                <h3 style={{ textAlign: "center" }}>
                  {this.state.nextMonthList.length}
                </h3>
              </Col>
              <Col xs={{ span: 3, offset: 1 }} className="division">
                <b>
                  <span style={{ textAlign: "center" }}>Tommorow</span>
                </b>
                <h3 style={{ textAlign: "center" }}>
                  {this.state.tomorrowList.length}
                </h3>
              </Col>
            </Row>
            <Row style={{ width: 100 + "%" }}>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  margin: 1 + "%",
                }}
              >
                <h3 style={{ textAlign: "center" }}> Birthday Tracker </h3>
              </div>

              <Col xs={{ span: 6 }}>
                <Table
                  className="division"
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid",
                  }}
                  size="medium"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>Month</TableCell>
                      <TableCell className={classes.tableCell}>Users</TableCell>

                      <TableCell className={classes.tableCell}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(this.state.sortDob)
                      .filter(([header, values]) =>
                        ["JAN", "FEB", "MAR", "APR", "MAY", "JUNE"].includes(
                          header
                        )
                      )
                      .map(([key, value], index) => (
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            {key}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {value.length}
                          </TableCell>
                          <TableCell>
                            <Button onClick={() => this.getCustomer(value)}>
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Col>
              <Col xs={{ span: 6 }}>
                <Table
                  className="division"
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid",
                  }}
                  size="medium"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>Month</TableCell>
                      <TableCell className={classes.tableCell}>Users</TableCell>

                      <TableCell className={classes.tableCell}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(this.state.sortDob)
                      .filter(([header, values]) =>
                        ["JULY", "AUG", "SEPT", "OCT", "NOV", "DEC"].includes(
                          header
                        )
                      )
                      .map(([key, value], index) => (
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            {key}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {value.length}
                          </TableCell>
                          <TableCell>
                            <Button onClick={() => this.getCustomer(value)}>
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Col>
            </Row>{" "}
            {this.state.showCustomerList && (
              <>
                {" "}
                <Row>
                  <Col
                    style={{ textAlign: "center" }}
                    className="division"
                    xs={{ span: 12 }}
                  >
                    <h3> Customers List</h3>
                    <Row>
                      <Col xs={{ span: 3 }}>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <strong> Filter By</strong>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            as="select"
                            name="filterBy"
                            value={this.state.filterBy}
                            onChange={this.handleFilterByCustomer}
                          >
                            <option value="">Select</option>
                            <option
                              onClick={() =>
                                this.getCustomer(this.state.customerDetails)
                              }
                              value="All"
                            >
                              All
                            </option>
                            <option
                              onClick={() =>
                                this.getCustomer(this.state.nextMonthList)
                              }
                              value="Month"
                            >
                              Month{" "}
                            </option>
                            <option
                              onClick={() =>
                                this.getCustomer(this.state.nextWeekList)
                              }
                              value="Week"
                            >
                              Week
                            </option>
                            <option
                              onClick={() =>
                                this.getCustomer(this.state.tomorrowList)
                              }
                              value="Tomorrow"
                            >
                              Tomorrow
                            </option>
                          </Form.Control>
                        </InputGroup>
                      </Col>
                      <Col xs={{ span: 3, offset: 1 }}>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <strong> Pincode</strong>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            as="select"
                            name="filterPincode"
                            value={this.state.filterPincode}
                            onChange={this.onChange}
                          >
                            <option value="">Select</option>

                            {this.state.mergedArea}
                          </Form.Control>
                        </InputGroup>
                      </Col>
                      <Col xs={{ span: 3, offset: 1 }}>
                        <Button
                          onClick={() => {
                            this.export();
                          }}
                        >
                          Download
                        </Button>
                        <CSVLink
                          enclosingCharacter={`'`}
                          data={this.state.exportData}
                          headers={this.state.headers}
                          ref={this.csvLink}
                          target="_blank"
                        />
                      </Col>
                    </Row>
                    <Table
                      style={{
                        width: 100 + "%",
                        border: 0.5 + "px",
                        borderColor: "#ced4da",
                        borderStyle: "solid",
                      }}
                      className={classes.table}
                      size="small"
                    >
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              S NO
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Customer ID
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Customer Name
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Phone Number
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Pincode
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Place
                            </TableCell>
                            {/* <TableCell className={classes.tableCell}>
                              DOB
                            </TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.customerDetails
                            .filter((headers) =>
                              this.state.filterPincode === "All"
                                ? headers
                                : headers.userPincode ===
                                  this.state.filterPincode.slice(-6)
                            )
                            .slice(
                              this.state.Customerpage *
                                this.state.rowsPerPageCustomer,
                              this.state.Customerpage *
                                this.state.rowsPerPageCustomer +
                                this.state.rowsPerPageCustomer
                            )

                            .map((elements, index) => (
                              <TableRow key={elements.userId10Digit}>
                                <TableCell className={classes.tableCell}>
                                  {index + 1}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Link
                                    to={{
                                      pathname: "/customer",
                                      search: `?userid=${elements.userId10Digit}`,
                                    }}
                                  >
                                    {" "}
                                    {elements.userId10Digit}{" "}
                                  </Link>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {elements.userName}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {elements.userPhoneNumber}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {elements.userPincode}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {elements.place}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {elements.userDOB}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </>
                    </Table>
                    <TablePagination
                      component="div"
                      count={this.state.customerDetails.length}
                      rowsPerPage={this.state.rowsPerPageCustomer}
                      page={this.state.Customerpage}
                      onChangePage={this.handleCustomerPage}
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      onChangeRowsPerPage={this.handleCustomerPagePerRow}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </>
    );
  }
}
CustomerDOB.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomerDOB);
