import React, { Component } from "react";
import { Form, Row, Col, Button, Container, Modal } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { Auth } from "aws-amplify";
import config from "../config";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Typography } from "@material-ui/core";
import DeliveryTable from "./deliveryTable";
import TableHeadClass from "./deliveryTableHead";
import MaterialTable from "material-table";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});

class Addproducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custdId: "",
      category: "none",
      selectGroup: "none",
      selectSubCategory: "none",
      updatedBy: "",
      couponCreatedArray: [],
      addProducts: {
        group: "",
        category: "",
        subCategory: "",
        productName: "",
        productQty: "",
        productImage: "removed",
        unitOfMeasurment: "",
        productShopType: "",
        vegNonVeg: "",
        productMrp: "",
        productBrand: "",
        productEANCode: "",
      },
      editProduct: {
        group: "",
        category: "",
        subCategory: "",
        productName: "",
        productQty: "",
        vegNonVeg: "",
        unitOfMeasurment: "",
        productMrp: "",
        productBrand: "",
        productEANCode: "",
      },
      masterOptions: [], // grocery,sweets
      renderText: "pri 1",
      expiryEndDate1: "",
      expiryEndDate2: "",
      userId10Digit: [],
      groupList: [],
      categoryList: [],
      subList: [],
      buttonDisable: false,
      showImportExcel: true,
      updateExpiryDate: "",
      updatedDate: new Date().toISOString().substring(0, 10),
      updatedBy: "",
      cols: [],
      getProductList: [],
      productList: [],
      rows: [],
      value: "one",
      showForm: true,
      show: false,
      loading: false,
      unitList: [],
      groupDropdown: [],
      categoryDropdown: [],
      subDropdown: [],
      unit: "",
    };
    this.fileHandler = this.fileHandler.bind(this);
    this.reset = this.state;

    this.handleDelete = this.handleDelete.bind(this);
    this.productShopTypeOnchange = this.productShopTypeOnchange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.textAreaOnchange = this.textAreaOnchange.bind(this);
    this.categoryOnchange = this.categoryOnchange.bind(this);
    this.addProductSubmit = this.addProductSubmit.bind(this);
    this.getProduct = this.getProduct.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.editProduct = this.editProduct.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.editonChange = this.editonChange.bind(this);
    this.addOnChange = this.addOnChange.bind(this);
    this.editSubmit = this.editSubmit.bind(this);
    this.subOnChange = this.subOnChange.bind(this);
    this.change = this.change.bind(this);
    this.getProductCategory = this.getProductCategory.bind(this);
    this.getJsonType = this.getJsonType.bind(this);
    this.setCategoryDrowdown = this.setCategoryDrowdown.bind(this);
  }

  change = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  productShopTypeOnchange = (e) => {
    let value = e.target.value; // grocery, sweets
    this.setState(
      {
        addProducts: {
          ...this.state.addProducts,
          [e.target.name]: value,
        },
      },
      async () => {
        if (Boolean(value)) {
          // data casting to get valid string value  // ignores null,undefined, empty stringh
          let productTypeJson = await this.getProductCategory(value);
          let setProductTypeJson = await this.setCategoryDrowdown(
            productTypeJson
          );
        }
      }
    );
  };
  setCategoryDrowdown = (productsJson) => {
    let categoryDropdown = [];
    let groupDropdown = [];
    let subDropdown = [];
    let groupList = [];
    Object.entries(productsJson.groups).forEach((elements) => {
      groupList.push(
        <option value={elements[1].name}>{elements[1].name}</option>
      );
    });

    Object.entries(productsJson).forEach(([key, value]) => {
      Object.values(value).forEach((key1) => {
        groupDropdown.push(<option value={key1.name}>{key1.name}</option>);
        Object.values(key1.category).forEach((key2) => {
          Object.values(key2.subCategory).forEach((key3) => {
            subDropdown.push(<option value={key3}>{key3}</option>);
          });

          categoryDropdown.push(<option value={key2.name}>{key2.name}</option>);
        });
      });
    });
    return this.setState({
      groupList: groupList,
      productsJson: productsJson,
      groupDropdown: groupDropdown,
      categoryDropdown: categoryDropdown,
      subDropdown: subDropdown,
    });
  };

  subOnChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    let subList = [];

    Object.entries(this.state.productsJson.groups).map(
      ([elements, headers]) => {
        console.log(elements, this.state.addProducts.group);
        if (elements === this.state.addProducts.group) {
          Object.entries(headers.category).forEach(([items, values]) => {
            if (items === value) {
              values.subCategory.map(
                (sub) =>
                  sub !== "ALL" &&
                  subList.push(<option value={sub}>{sub}</option>)
              );
            }
          });
        }
      }
    );

    this.setState({
      addProducts: {
        ...this.state.addProducts,
        [e.target.name]: e.target.value,
      },
      subList: subList,
    });
  }
  categoryOnchange(e) {
    let name = e.target.name;
    let value = e.target.value;
    let categoryList = [];
    Object.entries(this.state.productsJson.groups).map(
      ([elements, headers]) => {
        if (elements === value) {
          Object.entries(headers).map(([items, category]) => {
            if (items === "category") {
              Object.keys(category).map((getCategory) => {
                categoryList.push(
                  <option value={getCategory}>{getCategory}</option>
                );
              });
            }
          });
        }
      }
    );
    this.setState({
      addProducts: {
        ...this.state.addProducts,
        [e.target.name]: e.target.value,
      },
      categoryList: categoryList,
    });
  }
  deleteProduct(productId) {
    let filterProduct = this.state.getProductList.find(
      (items) => items.productId === productId
    );

    Promise.all([
      fetch(`${config.ApiGateway}/deleteMasterProducts`, {
        method: "POST",
        body: JSON.stringify({
          productId: filterProduct.productId,
          versionId: filterProduct.versionId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.componentDidMount();
      })
      .catch((err) => alert(err));
  }
  hideModal = () => {
    this.setState({
      show: false,
      edit: false,
    });
  };
  addOnChange = (e) => {
    this.setState({
      addProducts: {
        ...this.state.addProducts,
        [e.target.name]: e.target.value,
      },
    });
  };
  editonChange = (e) => {
    this.setState({
      editProduct: {
        ...this.state.editProduct,
        [e.target.name]: e.target.value,
      },
    });
  };
  editProduct = (productId) => {
    // let filterProduct = this.state.getProductList.find(
    //   (items) => items.productId === productId
    // );
    // let editObject = {};
    // Object.keys(filterProduct).forEach((elements) => {
    //   editObject[elements] = filterProduct[elements];
    // });
    // this.setState({
    //   show: true,
    //   editProduct: editObject,
    // });
  };
  getProduct() {
    //Api call to get master product list
    Promise.all([
      fetch(`${config.ApiGateway}/getMasterProducts`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let sortProduct = data.sort((a, b) =>
          a.productName.localeCompare(b.productName)
        );
        // console.log("sort by name");
        // console.log(data);

        this.setState({
          getProductList: sortProduct, // product /type array
          loading: true, //loading state
        });
      })
      .catch((err) => alert(err));
  }

  fileHandler = (event) => {
    let fileObj = event.target.files[0];

    //  just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let products = [];

        resp.rows.slice(1).map((elements, index) => {
          var input = {};
          elements.map((items, index) => (input[resp.rows[0][index]] = items));

          products.push(input);
        });
        this.setState({
          productList: products,
          showForm: false,
        });
      }
    });
  };
  textAreaOnchange(e) {
    this.multilineTextarea.style.height = "auto";

    this.multilineTextarea.style.height =
      this.multilineTextarea.scrollHeight + "px";
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  getProductCategory = async (productType) => {
    // get Json file based on shop type
    switch (productType) {
      case "grocery":
        try {
          const response = await fetch(
            `${config.json.URL}/productCategory.json`
          );
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }
      case "sweets":
        try {
          const response = await fetch(
            `${config.json.URL}/productCategorySweetsAndSnacks.json`
          );
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }
      case "restaurant":
        try {
          const response = await fetch(`${config.json.URL}/restaurant.json`);
          var productsJson = await response.json();
          return productsJson;
        } catch (error) {
          console.log(error);
        }

      default:
        return {};
    }
  };

  async componentDidMount() {
    // Get current user
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({
          updatedBy: user.attributes.phone_number,
        });
      })

      .catch((err) => console.log(err));

    try {
      // get product json
      const fields = await fetch(`${config.json.URL}/fields.json`);
      var fieldsJSON = await fields.json();
    } catch (error) {
      console.log(error);
    }
    try {
      const masterFields = await fetch(`${config.json.URL}/masterList.json`);
      var MasterJSON = await masterFields.json();
    } catch (error) {
      console.log(error);
    }
    // Get Product Shop Type
    let getMasterType = [];
    MasterJSON.masterList.forEach((elements) => {
      getMasterType.push(
        <option value={elements.type}>{elements.type}</option>
      );
    });

    // Get unit of measurement
    let unitList = [];
    fieldsJSON.unitOfMesurement.map((elements) =>
      unitList.push(<option value={elements}>{elements}</option>)
    );

    this.setState({
      unitList: unitList,
      masterOptions: getMasterType,
    });
    await this.getProduct(); // To get master status product
  }
  getJsonType = () => {};

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  addProductSubmit(e) {
    e.preventDefault();
    if (this.state.addProducts.productShopType == "") {
      // checks productShopType = grocery or sweets
      return alert("Select Product Shop Type");
    }
    var RemoveUndefinedObj = [];
    if (this.state.showForm) {
      var input = {};
      input = this.state.addProducts;

      Object.keys(input).forEach(
        (key) => input[key] == "" && delete input[key]
      );
      input.unitOfMeasurment =
        this.state.addProducts.unitOfMeasurment + " " + this.state.unit;
    } else {
      RemoveUndefinedObj = this.state.productList.filter(
        (elements) => Object.keys(elements).length !== 0
      );
    }

    Promise.all([
      fetch(`${config.ApiGateway}/addProduct`, {
        method: "POST",
        body: JSON.stringify({
          productList: this.state.showForm ? [input] : RemoveUndefinedObj,
          productShopType: this.state.addProducts.productShopType,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        // alert("Products added successfuly");
        this.setState(this.reset);
        this.componentDidMount();
      })
      .catch((err) => alert(err));
  }
  editSubmit(editObject) {
    // To check measurement value is valid

    if (
      editObject.measurementValue !== "" &&
      editObject.measurementValue !== undefined
    ) {
    } else {
      return alert("Enter measurement value");
    }
    // To check proice value is valid
    if (editObject.productMrp !== "" && editObject.productMrp !== undefined) {
    } else {
      return alert("Enter Product Mrp");
    }
    // To check quantitu value is valid
    if (editObject.productQty !== "" && editObject.productQty !== undefined) {
    } else {
      return alert("Enter Product Qty");
    }

    editObject.unitOfMeasurment =
      editObject.measurementValue + " " + editObject.measurementUnit;
    editObject.productMrp = editObject.productMrp.toString();

    editObject.productQty = editObject.productQty.toString();

    delete editObject.measurementUnit;
    delete editObject.measurementValue;
    if (editObject.productImage.length > 0) {
      editObject.productImage =
        editObject.productImage == "removed"
          ? "removed"
          : editObject.productImage;
    } else {
      editObject.productImage = "removed";
      // Verify product image is removed
    }

    Promise.all([
      fetch(`${config.ApiGateway}/editMasterProducts`, {
        method: "POST",
        body: JSON.stringify(editObject), // updated product Object
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.componentDidMount();
      })
      .catch((err) => alert(err));
  }

  handleDelete(i) {
    this.setState({
      couponCreatedArray: this.state.couponCreatedArray.filter(
        (tags, index) => index !== i
      ),
    });
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const PRODUCTS = this.state.getProductList
      .filter((items) =>
        this.state.category === items.category
          ? items.category
          : this.state.category === "none"
          ? items
          : null
      )
      .filter((items) =>
        this.state.selectSubCategory === items.subCategory
          ? items.subCategory
          : this.state.selectSubCategory === "none"
          ? items
          : null
      )
      .filter((items) =>
        this.state.selectGroup === items.group
          ? items.group
          : this.state.selectGroup === "none"
          ? items
          : null
      );
    // console.log(typeof this.state.addProducts.productShopType);
    return (
      <Container fluid>
        <>
          <Row>
            <Col sm={12}>
              {/* {

                ___________ ADD Product ________
                                                                       } */}
              <div
                style={{
                  backgroundColor: "#fafafa",

                  margin: 1 + "%",
                }}
              >
                <h3 style={{ textAlign: "center" }}> Add Delivery Products</h3>
              </div>
              <div className="vertical-space-40"></div>
              <Tabs
                style={{
                  width: 100 + "%",
                  justifyContent: "space-between",
                  display: "flex",
                }}
                value={this.state.value}
                onChange={this.handleChange}
              >
                <Tab value="one" label="Add Product"></Tab>
                <Tab value="two" label="View Product" />
              </Tabs>
              <div className="vertical-space-10"></div>

              {/* 1 -  Add Product */}
              {/* 2 -  Edit Product */}

              {this.state.value === "one" && (
                <>
                  <Typography component="div" style={{ padding: 8 * 3 }}>
                    <Row>
                      <Col
                        style={{ margin: "0 auto", textAlign: "right" }}
                        sm={6}
                      >
                        <Form.Label>
                          <b>IMPORT EXCEL FILE</b>
                        </Form.Label>

                        <input
                          type="file"
                          required
                          onChange={this.fileHandler.bind(this)}
                          style={{ padding: "10px" }}
                        />
                      </Col>
                    </Row>
                    <div className="vertical-space-10"></div>
                    {this.state.showForm ? (
                      <>
                        <Row>
                          <Col style={{ margin: "0 auto" }} sm={6}>
                            <Form onSubmit={this.addProductSubmit}>
                              <Form.Group as={Row}>
                                <Form.Label column sm="3">
                                  Product Shop Type
                                  <span style={{ color: "red" }}> *</span>
                                </Form.Label>
                                <Col md={{ span: 9 }}>
                                  <Form.Control
                                    as="select"
                                    onChange={this.productShopTypeOnchange}
                                    name="productShopType"
                                    value={
                                      this.state.addProducts.productShopType
                                    }
                                  >
                                    <option value="">
                                      Select Product Shop Type
                                    </option>
                                    {this.state.masterOptions}
                                  </Form.Control>
                                </Col>
                              </Form.Group>
                              {Boolean(
                                this.state.addProducts.productShopType
                              ) && (
                                <>
                                  <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                      Group
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <Col md={{ span: 9 }}>
                                      <Form.Control
                                        as="Select"
                                        onChange={this.categoryOnchange}
                                        name="group"
                                        required
                                        value={this.state.addProducts.group}
                                      >
                                        <option value="">Select Group</option>
                                        {this.state.groupList}
                                      </Form.Control>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                      Category
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <Col md={{ span: 9 }}>
                                      <Form.Control
                                        as="Select"
                                        onChange={this.subOnChange}
                                        name="category"
                                        required
                                        value={this.state.addProducts.category}
                                      >
                                        <option value="">
                                          Select Category
                                        </option>
                                        {this.state.categoryList}
                                      </Form.Control>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                      Sub Category{" "}
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <Col md={{ span: 9 }}>
                                      <Form.Control
                                        as="select"
                                        onChange={this.addOnChange}
                                        name="subCategory"
                                        value={
                                          this.state.addProducts.subCategory
                                        }
                                        required
                                      >
                                        <option value="">
                                          Select Sub Category
                                        </option>
                                        {this.state.subList}
                                      </Form.Control>
                                    </Col>
                                  </Form.Group>

                                  {this.state.addProducts.productShopType ===
                                    "grocery" && (
                                    <>
                                      <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                          Food Type
                                        </Form.Label>
                                        <Col md={{ span: 9 }}>
                                          <Form.Control
                                            as="select"
                                            onChange={this.addOnChange}
                                            name="vegNonVeg"
                                            value={
                                              this.state.addProducts.vegNonVeg
                                            }
                                          >
                                            <option value="">
                                              Select Veg Or Non Veg
                                            </option>
                                            <option value="Veg">
                                              Vegeterian
                                            </option>
                                            <option value="Non-Veg">
                                              Non Vegterian
                                            </option>
                                          </Form.Control>
                                        </Col>
                                      </Form.Group>
                                    </>
                                  )}

                                  <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                      Product Name
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <Col md={{ span: 9 }}>
                                      <Form.Control
                                        onChange={this.addOnChange}
                                        name="productName"
                                        required
                                        value={
                                          this.state.addProducts.productName
                                        }
                                      ></Form.Control>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                      Brand
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <Col md={{ span: 9 }}>
                                      <Form.Control
                                        type="text"
                                        onChange={this.addOnChange}
                                        name="productBrand"
                                        value={
                                          this.state.addProducts.productBrand
                                        }
                                      ></Form.Control>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                      Quantity
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <Col md={{ span: 9 }}>
                                      <Form.Control
                                        type="text"
                                        onChange={this.addOnChange}
                                        name="productQty"
                                        pattern="^[0-9]{0,30}"
                                        required
                                        value={
                                          this.state.addProducts.productQty
                                        }
                                      ></Form.Control>
                                    </Col>
                                  </Form.Group>
                                  <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                      Unit Of Measurement
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <Col md={{ span: 5 }}>
                                      <Form.Control
                                        type="text"
                                        onChange={this.addOnChange}
                                        name="unitOfMeasurment"
                                        value={
                                          this.state.addProducts
                                            .unitOfMeasurment
                                        }
                                        required
                                      ></Form.Control>
                                    </Col>
                                    <Col md={{ span: 4 }}>
                                      <Form.Control
                                        as="select"
                                        name="unit"
                                        value={this.state.unit}
                                        onChange={this.change}
                                        required
                                      >
                                        <option>Select unit</option>
                                        {this.state.unitList}
                                      </Form.Control>
                                    </Col>
                                  </Form.Group>

                                  <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                      Mrp
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <Col md={{ span: 9 }}>
                                      <Form.Control
                                        type="text"
                                        onChange={this.addOnChange}
                                        pattern="^[0-9.]{0,30}"
                                        name="productMrp"
                                        value={
                                          this.state.addProducts.productMrp
                                        }
                                        required
                                      ></Form.Control>
                                    </Col>
                                  </Form.Group>

                                  <Form.Group as={Row}>
                                    <Form.Label column sm="3">
                                      Ean Code
                                    </Form.Label>
                                    <Col md={{ span: 9 }}>
                                      <Form.Control
                                        type="text"
                                        onChange={this.addOnChange}
                                        name="productEANCode"
                                        value={
                                          this.state.addProducts.productEANCode
                                        }
                                      ></Form.Control>
                                    </Col>
                                  </Form.Group>
                                  {!(
                                    this.state.addProducts.productImage ==
                                    "removed"
                                  ) ? (
                                    <div
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                        textAlign: "center",
                                      }}
                                    >
                                      <img
                                        src={
                                          "data:image/png;base64," +
                                          this.state.addProducts.productImage
                                        }
                                        width={"150"}
                                      ></img>
                                      <span
                                        onClick={() => {
                                          this.setState({
                                            addProducts: {
                                              ...this.state.addProducts,
                                              productImage: "removed",
                                            },
                                          });
                                        }}
                                        style={{
                                          position: "absolute",
                                          right: "35%",
                                        }}
                                      >
                                        <CancelOutlinedIcon></CancelOutlinedIcon>
                                      </span>
                                    </div>
                                  ) : (
                                    <Row>
                                      <Form.Label column sm="3">
                                        Product image
                                      </Form.Label>
                                      <Col md={{ span: 9 }}>
                                        <input
                                          type="file"
                                          onChange={(e) => {
                                            let reader = new FileReader();
                                            let imageFormData = new FormData();

                                            imageFormData.append(
                                              "photo",
                                              e.target.files[0]
                                            );
                                            reader.onload = () => {
                                              this.setState({
                                                addProducts: {
                                                  ...this.state.addProducts,
                                                  productImage:
                                                    reader.result.replace(
                                                      /^data:image\/\w+;base64,/,
                                                      ""
                                                    ),
                                                },
                                              });
                                            };
                                            reader.readAsDataURL(
                                              e.target.files[0]
                                            );
                                          }}
                                          name="productImage"
                                        ></input>
                                      </Col>
                                    </Row>
                                  )}
                                  <div className="vertical-space-10"></div>
                                  <Button
                                    style={{ float: "right" }}
                                    type="submit"
                                  >
                                    Submit
                                  </Button>
                                  <div className="vertical-space-10"></div>
                                </>
                              )}
                            </Form>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        {this.state.productList.length > 0 && (
                          <>
                            <Form
                              style={{ Width: "100%" }}
                              onSubmit={this.addProductSubmit}
                            >
                              <Row>
                                <Col sm={6}>
                                  <Form.Group as={Row}>
                                    <Form.Label column sm="4">
                                      Product Shop Type
                                      <span style={{ color: "red" }}> *</span>
                                    </Form.Label>
                                    <Col md={{ span: 6 }}>
                                      <Form.Control
                                        as="select"
                                        onChange={this.addOnChange}
                                        name="productShopType"
                                        value={
                                          this.state.addProducts.productShopType
                                        }
                                      >
                                        <option value="">
                                          Select Product Shop Type
                                        </option>
                                        {this.state.masterOptions}
                                      </Form.Control>
                                    </Col>
                                  </Form.Group>
                                </Col>
                                <Col sm={6}>
                                  <Button type="submit">Submit</Button>
                                </Col>
                              </Row>
                              <Table
                                style={{
                                  width: 100 + "%",
                                  border: 0.5 + "px",
                                  borderColor: "#ced4da",
                                  borderStyle: "solid",
                                }}
                                size="medium"
                                className={classes.table}
                              >
                                <TableHeadClass edit={false} />
                                <TableBody>
                                  {this.state.productList.map(
                                    (header, index) => (
                                      <DeliveryTable
                                        group={header.group}
                                        category={header.category}
                                        subCategory={header.subCategory}
                                        productName={header.subCategory}
                                        productBrand={header.productBrand}
                                        productMrp={header.productMrp}
                                        unitOfMeasurment={
                                          header.unitOfMeasurment
                                        }
                                        productQty={header.productQty}
                                        index={index}
                                        productEANCode={header.productEANCode}
                                        edit={false}
                                      />
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </Form>
                          </>
                        )}
                      </>
                    )}
                  </Typography>
                </>
              )}

              {this.state.value === "two" && (
                <>
                  <Row>
                    <Col sm={3}>
                      <Form.Label>Group </Form.Label>
                      <Form.Control
                        as="select"
                        name="selectGroup"
                        onChange={this.onChange}
                        value={this.state.selectGroup}
                      >
                        <option value="">select Group</option>
                        <option value="none">None</option>
                        {this.state.groupDropdown}
                      </Form.Control>
                    </Col>
                    <Col sm={3}>
                      <Form.Label>Category</Form.Label>
                      <Form.Control
                        as="select"
                        name="category"
                        value={this.state.category}
                        onChange={this.onChange}
                      >
                        <option value="">select Category</option>
                        <option value="none">None</option>
                        {this.state.categoryDropdown}
                      </Form.Control>
                    </Col>
                    <Col sm={3}>
                      <Form.Label>Sub Category</Form.Label>
                      <Form.Control
                        as="select"
                        name="selectSubCategory"
                        value={this.state.selectSubCategory}
                        onChange={this.onChange}
                      >
                        {" "}
                        <option value="">select Sub Category</option>
                        <option value="none">None</option>
                        {this.state.subDropdown}
                      </Form.Control>
                    </Col>
                    <Col sm={3}>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="chooseStatus"
                        value={this.state.chooseStatus}
                        onChange={this.onChange}
                      >
                        {" "}
                        <option value="ported">Ported</option>
                        <option value="live">Live</option>
                        <option value="All">All</option>
                      </Form.Control>
                    </Col>
                  </Row>
                  <div className="vertical-space-10"></div>
                  <Row>
                    <Col sm={12}>
                      <MaterialTable
                        title="Product List"
                        columns={[
                          {
                            title: "Product Image",
                            field: "productImage",
                            render: (rowData) => {
                              if (
                                typeof rowData.productImage != "undefined" &&
                                rowData.productImage != "removed"
                              ) {
                                return rowData.productImage == "removed" ? (
                                  <label>No Image Avaiable</label>
                                ) : (
                                  <img
                                    src={rowData.productImage}
                                    style={{
                                      width: 150,
                                      border: "1px solid #ddd",
                                      borderRadius: "5px",
                                      padding: "5px",
                                    }}
                                  />
                                );
                              } else {
                                return <label>No Image Avaiable</label>;
                              }
                            },
                            editComponent: (props) => {
                              console.log(
                                props.rowData.productImage,
                                "Current Status"
                              );
                              if (
                                props.rowData.productImage != "removed" &&
                                props.rowData.productImage != undefined
                              ) {
                                return (
                                  <>
                                    <div
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      <img
                                        src={
                                          "data:image/png;base64," +
                                          props.rowData.productImage
                                        }
                                        style={{
                                          width: "150px",
                                          height: "auto",
                                          border: "1px solid #ddd",
                                          borderRadius: "5px",
                                          padding: "5px",
                                          position: "relative",
                                        }}
                                        onChange={() =>
                                          props.onChange("removed")
                                        }
                                      ></img>
                                      <span
                                        style={{
                                          position: "absolute",

                                          right: "3px",
                                        }}
                                        onClick={() => {
                                          props.onChange("removed");
                                        }}
                                      >
                                        <CancelOutlinedIcon
                                          style={{
                                            fontSize: 20,
                                            backgroundColor: "blue",

                                            color: "#fff",
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </>
                                );
                              } else {
                                return (
                                  <input
                                    type="file"
                                    onChange={(e) => {
                                      let reader = new FileReader();
                                      let imageFormData = new FormData();

                                      imageFormData.append(
                                        "photo",
                                        e.target.files[0]
                                      );
                                      reader.onload = () => {
                                        props.onChange(
                                          reader.result.replace(
                                            /^data:image\/\w+;base64,/,
                                            ""
                                          )
                                        );
                                      };
                                      reader.readAsDataURL(e.target.files[0]);
                                    }}
                                  ></input>
                                );
                              }
                            },
                          },

                          { title: "Group", field: "group", editable: "never" },
                          {
                            title: "Category",
                            field: "category",
                            editable: "never",
                          },
                          {
                            title: "Sub Category",
                            field: "subCategory",
                            editable: "never",
                          },
                          { title: "Product Name", field: "productName" },

                          { title: "Brand", field: "productBrand" },
                          { title: "MRP ₹", field: "productMrp" },
                          // {
                          //   title: "Unit Of Measurement",
                          //   field: "unitOfMeasurment",
                          // },

                          {
                            title: "Measurement value",
                            field: "measurementValue",
                            render: (rowData) => {
                              const measurementValue =
                                rowData.unitOfMeasurment.split(" ");
                              return <td>{measurementValue[0]}</td>;
                            },
                          },

                          {
                            title: "Measurement Unit",
                            field: "measurementUnit",
                            render: (rowData) => {
                              const measurementUnit =
                                rowData.unitOfMeasurment.split(" ");
                              return <td>{measurementUnit[1]}</td>;
                            },
                            editComponent: (props) => {
                              const measurementUnit =
                                props.rowData.unitOfMeasurment.split(" ");

                              return (
                                <select
                                  name="measurementUnit"
                                  value={props.value || measurementUnit[1]}
                                  onChange={(e) =>
                                    props.onChange(e.target.value)
                                  }
                                >
                                  <select value="">Select Unit</select>
                                  {this.state.unitList}
                                </select>
                              );
                            },
                          },

                          { title: "Product Quantity ", field: "productQty" },
                          {
                            title: "Veg/ Non Veg",
                            field: "vegNonVeg",
                            editable: "always",
                            editComponent: (props) => (
                              <select
                                type="text"
                                value={props.value}
                                onChange={(e) => props.onChange(e.target.value)}
                              >
                                <option value="Veg">Vegeterian</option>
                                <option value="Non-Veg">Non Vegterian</option>
                              </select>
                            ),
                          },
                          {
                            title: "Product Shop Type",
                            field: "productShopType",
                            editable: "never",
                          },
                          { title: "Ean Code", field: "productEANCode" },
                        ]}
                        data={PRODUCTS}
                        options={{
                          search: true,
                          pageSize: 30,
                          pageSizeOptions: [5, 10, 20, 100, 300, 500],
                          grouping: false,
                          filtering: false,
                        }}
                        editable={{
                          isEditable: (rowData) => rowData.productId,

                          // only group(a) rows would be editable
                          isDeletable: (rowData) => rowData.productId, // only group(b) rows would be deletable

                          onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                if (newData.measurementUnit === undefined) {
                                  let temp =
                                    newData.unitOfMeasurment.split(" ");
                                  newData.measurementUnit = temp[1];
                                }
                                if (newData.measurementValue === undefined) {
                                  let temp =
                                    newData.unitOfMeasurment.split(" ");
                                  newData.measurementValue = temp[0];
                                }
                                console.log(newData);
                                this.editSubmit(newData);

                                resolve();
                              }, 1000);
                            }),
                          onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                this.deleteProduct(oldData.productId);
                                resolve();
                              }, 1000);
                            }),
                        }}
                      />
                    </Col>
                    {/* <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid",
                  }}
                  size="medium"
                  className={classes.table}
                >
                  <TableHeadClass edit={true} />
                  <TableBody>
                    {this.state.getProductList.map((header, index) => (
                      <DeliveryTable
                        group={header.group}
                        category={header.category}
                        subCategory={header.subCategory}
                        productName={header.subCategory}
                        productBrand={header.productBrand}
                        productMrp={header.productMrp}
                        unitOfMeasurment={header.unitOfMeasurment}
                        productQty={header.productQty}
                        productId={header.productId}
                        productEANCode={header.productEANCode}
                        editProduct={this.editProduct}
                        deleteProduct={this.deleteProduct}
                        index={index}
                        edit={true}
                        isEditatble={true}
                      />
                    ))}
                  </TableBody>
                </Table> */}
                  </Row>

                  <Row>
                    <Modal
                      show={this.state.show}
                      onHide={() => this.hideModal()}
                      size="lg"
                      // dialogClassName="modal-90w"
                      // style={{
                      //   maxWidth: "100%",
                      //   width: "1140px",
                      //   margin: "100px auto",
                      // }}
                      aria-labelledby="example-custom-modal-styling-title"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                          Edit Product
                        </Modal.Title>
                      </Modal.Header>
                      <Form onSubmit={this.editSubmit}>
                        <Modal.Body>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              Group
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                type="text"
                                as="Select"
                                onChange={this.categoryOnchange}
                                // onChange={this.editonChange}
                                name="group"
                                value={this.state.editProduct.group}
                              >
                                <option value="">Select Group</option>
                                {this.state.groupList}
                              </Form.Control>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              Category
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                as="Select"
                                onChange={this.subOnChange}
                                name="category"
                                value={this.state.editProduct.category}
                              >
                                <option value="">Select Category</option>
                                {this.state.categoryList}
                              </Form.Control>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              Sub Category
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                as="select"
                                onChange={this.editonChange}
                                name="subCategory"
                                value={this.state.editProduct.subCategory}
                              >
                                <option value="">Select Sub Category</option>
                                {this.state.subList}
                              </Form.Control>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              Product Description
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                onChange={this.editonChange}
                                name="productName"
                                value={this.state.editProduct.productName}
                              ></Form.Control>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              Quantity
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                type="text"
                                onChange={this.editonChange}
                                name="productQty"
                                value={this.state.editProduct.productQty}
                              ></Form.Control>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              Unit Of Measurement
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                type="text"
                                onChange={this.editonChange}
                                name="unitOfMeasurment"
                                value={this.state.editProduct.unitOfMeasurment}
                              ></Form.Control>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              Mrp
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                type="text"
                                onChange={this.editonChange}
                                name="productMrp"
                                value={this.state.editProduct.productMrp}
                              ></Form.Control>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              Brand
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                type="text"
                                onChange={this.editonChange}
                                name="productBrand"
                                value={this.state.editProduct.productBrand}
                              ></Form.Control>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              Ean Code
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                type="text"
                                onChange={this.editonChange}
                                name="productEANCode"
                                value={this.state.editProduct.productEANCode}
                              ></Form.Control>
                            </Col>
                          </Form.Group>
                          <div className="vertical-space-10"></div>

                          <div className="vertical-space-10"></div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button type="submit">Submit</Button>
                        </Modal.Footer>
                      </Form>
                    </Modal>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </>
      </Container>
    );
  }
}
Addproducts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Addproducts);
