import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Row, Col, Modal } from "react-bootstrap";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import S3 from "react-aws-s3";
import uuid from "uuid/dist/v4";
import config from "../../config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const ContactUs = (props) => {
  const [customerName, setCustomerName] = useState(false);
  const [customerEmail, setCustomerEmail] = useState(false);
  const [shortNote, setShortNote] = useState(false);
  const [websiteTemplate, setWebsiteTemplate] = useState();

  const fetchDetails = async () => {
    await fetchUserDetails(props.shopId).then((data) => {
      var shopData = data[0];
      if (shopData.websiteTemplate) {
        setWebsiteTemplate(shopData.websiteTemplate);
        var websiteData = shopData.websiteTemplate;
        if (websiteData.contactUs) {
          var pageProps = websiteData.contactUs.pageProps;
          setCustomerName(pageProps.customerName);
          setCustomerEmail(pageProps.customerEmailId);
          setShortNote(pageProps.shortNote);
        }
      }
    });
  };

  useEffect(() => {
    fetchDetails();
  }, [props]);

  const handleUpdate = async () => {
    var dataObj = {};
    if (websiteTemplate) {
      dataObj = {
        ...websiteTemplate,
        contactUs: {
          pageProps: {
            customerEmailId: customerEmail,
            shortNote: shortNote,
            customerName: customerName,
          },
        },
      };
    } else {
      dataObj = {
        colorTheme: "default",
        order: [
          "aboutUs",
          "about",
          "onlineProduct",
          "ourServices",
          "ourProjects",
          "ourBrands",
          "ourClients",
          "ourGallery",
          "clientTestimonials",
          "videoGallery",
          "socialMedia",
          "ourPartner",
          "ourTeam"
        ],
        contactUs: {
          pageProps: {
            customerEmailId: customerEmail,
            shortNote: shortNote,
            customerName: customerName,
          },
        },
      };
    }
    await contentupload(dataObj, props.shopId);
  };

  return (
    <div style={{ width: "70%" }}>
      <h4>Contact Us Form</h4>
      <div>
        <>
          <label>Customer Name</label>
        </>{" "}
        <FormControlLabel
          control={
            <Switch
              checked={customerName}
              onChange={() => setCustomerName(!customerName)}
              color="primary"
            />
          }
        ></FormControlLabel>
      </div>
      <div>
        <>
          <label>Customer Email Id</label>
        </>{" "}
        <FormControlLabel
          control={
            <Switch
              checked={customerEmail}
              onChange={() => setCustomerEmail(!customerEmail)}
              color="primary"
            />
          }
        ></FormControlLabel>
      </div>
      <div>
        <>
          <label>Short Note</label>
        </>{" "}
        <FormControlLabel
          control={
            <Switch
              checked={shortNote}
              onChange={() => setShortNote(!shortNote)}
              color="primary"
            />
          }
        ></FormControlLabel>
        <br />
        <Button onClick={() => handleUpdate()}>Apply Changes</Button>
      </div>
    </div>
  );
};

const fetchUserDetails = async (shopId) => {
  if (shopId === "") return alert("Enter valid Shop Id");
  return await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
      method: "POST",
      body: JSON.stringify({
        userId10Digit: shopId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data[0].websiteTemplate);
      return data;
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("Something went wrong, please try again");
    });
};

const contentupload = async (obj, userId) => {
  await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainContentUpload`, {
      method: "POST",
      body: JSON.stringify({
        obj: obj,
        userId: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      alert("uploaded success");
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("error while uploading data. Please try again");
    });
};

export default ContactUs;
