import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Row, Button } from "react-bootstrap";
import config from "../../config";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    display: "flex",
    marginTop: theme.spacing.unit * 3,
    overflowX: "hide"
  },
  table: {
    minWidth: 340
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5
  }
});
class CouponTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showdata: false,
      showTable: [],
      showusers: true
    };
    this.getMerchantDetails = this.getMerchantDetails.bind(this);
    this.redemptionFunction = this.redemptionFunction.bind(this);
    this.getCustomerDetails = this.getCustomerDetails.bind(this);
  }
  getMerchantDetails(userid) {
    this.props.getMerchant(userid);
  }
  redemptionFunction(couponArgs) {
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/coupons/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                {
                  match: {
                    redeemedCouponId: couponArgs
                  }
                }
              ]
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let tempcouponRedeemed = data.hits.hits.map(element => element._source);

        let redeemedHistory = [];
        let custId = [];
        tempcouponRedeemed.forEach(elements => {
          custId.push(elements.custId);
          redeemedHistory.push({
            redeemedDate: elements.redeemedDate,
            custId: elements.custId,
            billAmount: elements.billAmount,
            couponStatus: elements.couponStatus,
            paidStatus: elements.paidStatus,
            redeemedCouponId: elements.redeemedCouponId
          });
        });

        this.getCustomerDetails(custId, redeemedHistory);
      })
      .catch(err => console.log(err));
  }

  getCustomerDetails(custId, redeemedHistory) {
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [{ terms: { userId: custId } }]
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      }),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([users, data2]) => {
        let tempdata = users.hits.hits.map(element => element._source);

        var result = redeemedHistory.map(items => {
          let userdetails = tempdata.find(
            elements => items.custId === elements.userId
          );
          let appendObject = Object.assign({}, items);
          appendObject.userName =
            userdetails.userName !== undefined ? userdetails.userName : "";
          appendObject.userPhoneNumber =
            userdetails.userPhoneNumber !== undefined
              ? userdetails.userPhoneNumber
              : "";
          appendObject.userPincode =
            userdetails.userPincode !== undefined
              ? userdetails.userPincode
              : "";

          appendObject.userAddedDate =
            userdetails.userAddedDate !== undefined
              ? userdetails.userAddedDate
              : "";
          appendObject.userId10Digit =
            userdetails.userId10Digit !== undefined
              ? userdetails.userId10Digit
              : "";
          return appendObject;
        });

        var mergePincodeAndArea = result.map(items => {
          let count = data2.Items.find(
            elements => items.userPincode === elements.pincode
          );

          let appendObject = Object.assign({}, items, count);

          return appendObject;
        });

        this.setState({
          redeemedUsers: mergePincodeAndArea,
          showusers: false,
          showdata: true
        });
      })
      .catch(err => console.log(err));
  }
  async componentDidMount() {
    if (this.props.store1.length > 0) {
      this.redemptionFunction(this.props.store1);
    } else {
      let shopId = this.props.shops.map(elements => elements.shopId);

      Promise.all([
        await fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
          method: "POST",
          body: JSON.stringify({
            query: {
              bool: {
                should: [{ terms: { userId: shopId } }]
              }
            }
          }),

          headers: {
            "Content-Type": "application/json"
          }
        }),
        fetch(`${config.ApiGateway}/getPlace`, {
          method: "POST",

          headers: {
            "Content-Type": "application/json"
          }
        })
      ])
        .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
        .then(([users, data2]) => {
          let tempdata = users.hits.hits.map(element => element._source);

          var result = this.props.shops.map(items => {
            let userdetails = tempdata.find(
              elements => items.shopId === elements.userId
            );
            let appendObject = Object.assign({}, items);
            appendObject.userName =
              userdetails.userName !== undefined ? userdetails.userName : "";
            appendObject.userPhoneNumber =
              userdetails.userPhoneNumber !== undefined
                ? userdetails.userPhoneNumber
                : "";
            appendObject.shopName =
              userdetails.shopName !== undefined ? userdetails.shopName : "";
            appendObject.shopAddress =
              userdetails.shopAddress !== undefined
                ? userdetails.shopAddress
                : "";
            appendObject.shopType =
              userdetails.shopType !== undefined ? userdetails.shopType : "";
            appendObject.userAddedDate =
              userdetails.userAddedDate !== undefined
                ? userdetails.userAddedDate
                : "";
            appendObject.userId10Digit =
              userdetails.userId10Digit !== undefined
                ? userdetails.userId10Digit
                : "";
            return appendObject;
          });

          var mergePincodeAndArea = result.map(items => {
            let count = data2.Items.find(
              elements => items.pincode === elements.pincode
            );

            let appendObject = Object.assign({}, items, count);

            return appendObject;
          });

          this.setState({
            showTable: mergePincodeAndArea,
            showdata: true
          });
        })
        .catch(err => console.log(err));
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.showdata && (
          <>
            <Row>
              {this.state.showusers ? (
                <>
                  <div
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%"
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}> SHOP DETAILS </h3>
                  </div>
                  <Table
                    style={{
                      width: 100 + "%",
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid"
                    }}
                    className={classes.table}
                    size="small"
                  >
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            S NO
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            SHOPNAME
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            USER ID
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            SHOPTYPE
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            PROMOCODE
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            COUPON ADDED ON
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            SHOP PINCODE
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            PLACE
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            MERCHANT NAME
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            PHONE NUMBER
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            COUPON COUNT
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            COUPON DETAILS
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            COUPON USED
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            ALLOCATED COST
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            CONSUMED COST
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            COUPON AMOUNT
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            SHOP ADDRESS
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            REDEMPTION HISTORY
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <>
                          {this.state.showTable.map((items, index) => (
                            <>
                              <TableRow key={items.couponId}>
                                <TableCell className={classes.tableCell}>
                                  {index + 1}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.shopName}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Button
                                    variant="link"
                                    onClick={() => {
                                      this.getMerchantDetails(
                                        items.userId10Digit
                                      );
                                    }}
                                  >
                                    {items.userId10Digit}
                                  </Button>
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  {items.shopType}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.promocode}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.addedDate}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.pincode}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.place}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.userName}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.userPhoneNumber}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.totalCouponCount}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.couponDetails}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.couponsused}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {"₹"} {items.allocatedCost}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {"₹"} {items.consumedCost}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {"₹"} {items.maximumCouponAmount}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.shopAddress}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Button
                                    onClick={() => {
                                      this.redemptionFunction(items.couponId);
                                    }}
                                  >
                                    VIEW
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                        </>
                      </TableBody>
                    </>
                  </Table>
                </>
              ) : (
                <>
                  <div
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%"
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}> CUSTOMER DETAILS </h3>
                  </div>
                  <Table
                    style={{
                      width: 100 + "%",
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid"
                    }}
                    className={classes.table}
                    size="small"
                  >
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            S NO
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            CUSTOMER ID
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            CUSTOMER NAME
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            PHONE NUMBER
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            PINCODE
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            PLACE
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            REDEEMED DATE
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            REDEEMED COUPON ID
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            COUPON STATUS
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            PAID STATUS
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.redeemedUsers.map((elements, index) => (
                          <TableRow key={elements.userId10Digit}>
                            <TableCell className={classes.tableCell}>
                              {index + 1}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Link
                                to={{
                                  pathname: "/customer",
                                  search: `?userid=${elements.userId10Digit}`
                                }}
                              >
                                {" "}
                                {elements.userId10Digit}{" "}
                              </Link>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.userName}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.userPhoneNumber}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.userPincode}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.place}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.redeemedDate}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.redeemedCouponId}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.couponStatus}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.paidStatus}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </>
                  </Table>
                </>
              )}
            </Row>
          </>
        )}
      </>
    );
  }
}
CouponTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CouponTable);
