import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Col,
  Button,
  Row,
  Badge,
  Modal,
  Form,
} from "react-bootstrap";
import { store } from "../provider";
import MaterialTable from "material-table";
import config from "../config";
import { Link } from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));
export default function ViewMerchantDetails() {
  const context = useContext(store);
  const [customerList, setCustomerList] = useState([]);
  const [segment, setSegment] = useState([]);
  const customerDetails = context.state.customerDetails;
  const CmanagementTitle = context.state.CmanagementTitle;
  const [filterSegment, setFilterSegment] = useState("All");
  const [managementTitle, setManagementTitle] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({
    shopTimings: {},
  });
  const handleClose = () => setModalShow(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  useEffect(() => {
    setManagementTitle(CmanagementTitle);
    let shops = customerDetails.filter(
      (elements) => elements.userPincode !== "111111"
    );

    setCustomerList(shops);
    let segmentList = [];
    fetch(`${config.json.URL}/fields.json`)
      .then((res) => res.json())

      .then((data) => {
        data.shopTypes.slice(1).map((elements) => {
          segmentList.push(
            <option value={elements.value}>{elements.value}</option>
          );
          return;
        });
        setSegment(segmentList);
      })
      .catch((err) => console.log("the error", err));
  }, []);

  const filteredData = () => {
    let filtered = customerDetails.filter((elements) =>
      elements.shopType === filterSegment
        ? elements.shopType
        : filterSegment == "All"
        ? elements
        : null
    );

    setCustomerList(filtered);
  };

  useEffect(() => {
    filteredData();
  }, [filterSegment]);
  return (
    <Container fluid>
      <div style={{ width: "100%", height: "4px" }}></div>
      <div className="orderHeader">
        <h2 className="orderHeaderText">Merchant Details</h2>
        <span
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          style={{ float: "right" }}
        >
          <InfoIcon />
        </span>
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div>
          <b>Calculation Of Activity</b>
          <br />
          <span>
            <b>Bill</b> : No of Bills generated for customers
          </span>
          <br />
          <span>
            <b>Appointment</b> : No of Appointment booked by customers
          </span>
          <br />
          <span>
            <b>Orders</b> : No of Orders booked by customers
          </span>
          <br />
          <span>
            <b>Offers</b> : No of offers Created by shop
          </span>
          <br />
          <span>
            <b>Collection</b> : No of Collection Created by shop
          </span>
          <br />
          <span>
            <b>Loyalty Points </b> : Loyalty points added and redeemed for
            unique customers will calculated as 1
          </span>
          <br />
          <span>
            <b>Redeemed Coupons </b> : coupons redeemed for unique customers
            will be calculated as 1
          </span>
          <br />
          <span>
            <b>Coupons </b> :No of Coupons created for Shop
          </span>
          <br />
        </div>
      </Popover>
      <Row style={{ justifyContent: "end" }}>
        <Col sm={3}>
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>Filter By Segement</Form.Label>
            <Form.Control
              value={filterSegment}
              as="select"
              onChange={(e) => {
                setFilterSegment(e.target.value);
              }}
            >
              <option value={"All"}>{"All"}</option>
              {segment}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <MaterialTable
        title={`${managementTitle} Shop Details`}
        columns={[
          {
            title: "Used Id",
            field: "userId10Digit",
            render: (rowData) => (
              <Link
                to={{
                  pathname: "/merchant",
                  search: `?userid=${rowData.userId10Digit}`,
                }}
              >
                {rowData.userId10Digit}
              </Link>
            ),
          },
          { title: "Shop Name", field: "shopName" },
          { title: "Name", field: "userName" },
          {
            title: "Number",
            field: "userPhoneNumber",
            hidden: !(managementTitle === "acquired "),
          },
          {
            title: "Pincode",
            field: "userPincode",
          },
          {
            title: "Shop Type",
            field: "shopType",
          },

          {
            title: "Address",
            field: "userAddress",
            hidden: !(managementTitle === "acquired "),
          },
          { title: "Joined Ghoshak", field: "userAddedDate" },
          { title: "Refered By", field: "referedname" },

          {
            title: "Appointment",
            field: "appointment",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Orders",
            field: "order",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Bills",
            field: "bill",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Offers",
            field: "offer",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Collection",
            field: "collection",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Coupons",
            field: "coupons",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "coupon redeemed Cust",
            field: "couponRedeemedCount",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "coupon redeemed Cust",
            field: "couponRedeemedCount",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Loyalty Ratio",
            field: "loyaltyRatio",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
            render: (rowData) => {
              return typeof rowData.loyaltyRatio == "undefined" ? (
                <Badge pill variant="danger">
                  Not Activated
                </Badge>
              ) : (
                <>
                  <Badge pill variant="success">
                    Activated
                  </Badge>
                  <label style={{ textAlign: "center" }}>
                    {rowData.loyaltyRatio} {" % "}
                  </label>
                </>
              );
            },
          },
          {
            title: "Profile Picture",
            field: "shopImageLocation",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
            render: (rowData) => {
              return typeof rowData.shopImageLocation == "string" ? (
                rowData.shopImageLocation == "removed" ? (
                  <>
                    <Badge pill variant="warning">
                      Removed
                    </Badge>
                  </>
                ) : (
                  <Badge pill variant="success">
                    Enabled
                  </Badge>
                )
              ) : (
                <>
                  <Badge pill variant="danger">
                    Disabled
                  </Badge>
                </>
              );
            },
          },
          {
            title: "Google Location",
            field: "pin",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
            render: (rowData) => {
              return typeof rowData.pin == "undefined" ? (
                <>
                  <Badge pill variant="danger">
                    Disabled
                  </Badge>
                </>
              ) : (
                <>
                  <Badge pill variant="success">
                    Enabled
                  </Badge>
                </>
              );
            },
          },
          {
            title: "Shop Timings",
            field: "shopTimings",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
            render: (rowData) => {
              return typeof rowData.shopTimings == "undefined" ? (
                <>
                  <Badge pill variant="danger">
                    Disabled
                  </Badge>
                </>
              ) : (
                <>
                  <Badge pill variant="success">
                    Enabled
                  </Badge>
                </>
              );
            },
          },

          {
            title: "Total Activity",
            field: "totalActivity",
            hidden: !(
              managementTitle === "activate" ||
              managementTitle === "engage" ||
              managementTitle === "retain"
            ),
          },
          {
            title: "Total Refferals",
            field: "totalReferes",
            hidden: !(managementTitle === "referals"),
          },

          {
            title: "Action",
            field: "view",
            render: (rowData) => (
              <Button
                onClick={() => {
                  setModalShow(true);
                  setModalData(rowData);
                }}
              >
                View Shop
              </Button>
            ),
          },
        ]}
        data={customerList}
        options={{
          exportButton: true,
          pageSize: 5,
          pageSizeOptions: [5, 10, 20, 100, 300, 500, 1000, 2000],
          grouping: false,
          filtering: false,
        }}
      />
      <Modal className="viewCartDetails" show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row
            style={{
              borderBottom: "1px solid rgb(219, 219, 219)",
              marginBottom: "5px",
            }}
          >
            <Col sm={12}>
              <div className="orderHeader">
                <h6 style={{ fontSize: "24px" }} className="orderHeaderText">
                  Profile
                </h6>
              </div>
            </Col>
            <Col style={{ textAlign: "left" }} sm={6}>
              <h4 className="shopProfile">{modalData.shopName}</h4>
              <span className="shopProfile">{modalData.shopAddress}</span>
              <br />

              <span className="shopProfile">{modalData.userPincode}</span>
            </Col>
            <Col style={{ textAlign: "right" }} sm={6}>
              <h4 className="shopProfile">{modalData.userName}</h4>
              <span className="shopProfile">{modalData.userPhoneNumber}</span>
              <br />
              <span className="shopProfile">{modalData.shopType}</span>
            </Col>
          </Row>

          <Row
            style={{
              borderBottom: "1px solid rgb(219, 219, 219)",
              marginBottom: "5px",
            }}
          >
            <Col sm={12}>
              <div className="orderHeader">
                <h6 style={{ fontSize: "24px" }} className="orderHeaderText">
                  Shop Timings
                </h6>
              </div>
            </Col>
            {typeof modalData.shopTimings == "undefined" ? (
              <>
                <Col style={{ textAlign: "center" }}>
                  <p>No Data to show</p>
                </Col>
              </>
            ) : (
              <>
                {Object.entries(modalData.shopTimings).map(([key, value]) => {
                  return (
                    <Col>
                      <span style={{ fontSize: "16px" }}>{key}</span>
                      <br />
                      <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                        {value.openTime} - {value.closeTime}
                      </span>
                    </Col>
                  );
                })}
              </>
            )}
          </Row>
          <Row
            style={{
              borderBottom: "1px solid rgb(219, 219, 219)",
              marginBottom: "5px",
            }}
          >
            <Col sm={12}>
              <div className="orderHeader">
                <h6 style={{ fontSize: "24px" }} className="orderHeaderText">
                  Transaction / Interaction
                </h6>
              </div>
            </Col>

            <Col>
              <span style={{ fontSize: "16px" }}>
                Customers Enrolled in Loyalty
              </span>
              <br />

              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                <>
                  <span style={{ color: "#89959b", fontSize: "14px" }}>
                    Total Customers :{" "}
                  </span>
                  <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                    {modalData.loyaltyCustomers}
                  </span>
                  <br />
                </>
              </span>
            </Col>
            <Col>
              <span style={{ fontSize: "16px" }}>Coupons Redeemed</span>
              <br />

              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                <>
                  <span style={{ color: "#89959b", fontSize: "14px" }}>
                    unique Customers :{" "}
                  </span>
                  <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                    {modalData.couponRedeemedCount}
                  </span>
                  <br />
                </>
              </span>
            </Col>
          </Row>

          <Row
            style={{
              borderBottom: "1px solid rgb(219, 219, 219)",
              marginBottom: "5px",
            }}
          >
            <Col sm={12}>
              <div className="orderHeader">
                <h6 style={{ fontSize: "24px" }} className="orderHeaderText">
                  Shop Profile
                </h6>
              </div>
            </Col>
            <Col>
              <span style={{ fontSize: "16px" }}>Profile Picture Enabled</span>
              <br />

              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {typeof modalData.shopImageLocation == "string" ? (
                  modalData.shopImageLocation == "removed" ? (
                    <>
                      <span style={{ color: "#89959b", fontSize: "14px" }}>
                        Status :{" "}
                      </span>
                      <Badge pill variant="warning">
                        Removed
                      </Badge>
                    </>
                  ) : (
                    <>
                      <span style={{ color: "#89959b", fontSize: "14px" }}>
                        Status :
                      </span>
                      <Badge pill variant="success">
                        Enabled
                      </Badge>
                    </>
                  )
                ) : (
                  <>
                    <span style={{ color: "#89959b", fontSize: "14px" }}>
                      Status :{" "}
                    </span>
                    <Badge pill variant="danger">
                      Disabled
                    </Badge>
                  </>
                )}
              </span>
            </Col>
            <Col>
              <span style={{ fontSize: "16px" }}>Google Location</span>
              <br />

              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {typeof modalData.pin == "undefined" ? (
                  <>
                    <span style={{ color: "#89959b", fontSize: "14px" }}>
                      Status :{" "}
                    </span>
                    <Badge pill variant="danger">
                      Disabled
                    </Badge>
                    <br />
                  </>
                ) : (
                  <>
                    <span style={{ color: "#89959b", fontSize: "14px" }}>
                      Status :{" "}
                    </span>
                    <Badge pill variant="success">
                      Enabled
                    </Badge>
                    <br />
                    <span style={{ color: "#89959b", fontSize: "14px" }}>
                      Lat :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {modalData.pin.location.lat}
                    </span>
                    <br />
                    <span style={{ color: "#89959b", fontSize: "14px" }}>
                      Long :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {modalData.pin.location.lon}
                    </span>
                    <br />
                  </>
                )}

                {/* {modalData.shopImageLocation !== "" ? "Enabled" : "Not Enabled"} */}
              </span>
            </Col>

            <Col>
              <span style={{ fontSize: "16px" }}>Loyalty Ratio</span>
              <br />

              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {typeof modalData.loyaltyRatio == "undefined" ? (
                  <>
                    <span style={{ color: "#89959b", fontSize: "14px" }}>
                      Status :{" "}
                    </span>
                    <Badge pill variant="danger">
                      Not Activated
                    </Badge>
                    <br />
                  </>
                ) : (
                  <>
                    <span style={{ color: "#89959b", fontSize: "14px" }}>
                      Status :{" "}
                    </span>
                    <Badge pill variant="success">
                      Activated
                    </Badge>
                    <br />
                    <span style={{ color: "#89959b", fontSize: "14px" }}>
                      Amount :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {" ₹ "} {modalData.loyaltyAmount}
                    </span>
                    <br />
                    <span style={{ color: "#89959b", fontSize: "14px" }}>
                      Points :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {modalData.loyaltyPoints}
                    </span>
                    <br />
                    <span style={{ color: "#89959b", fontSize: "14px" }}>
                      Ratio :{" "}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {modalData.loyaltyRatio} {" % "}
                    </span>
                    <br />
                  </>
                )}

                {/* {modalData.shopImageLocation !== "" ? "Enabled" : "Not Enabled"} */}
              </span>
            </Col>
          </Row>
          <Row
            style={{
              borderBottom: "1px solid rgb(219, 219, 219)",
              marginBottom: "5px",
            }}
          >
            <Col sm={12}>
              <div className="orderHeader">
                <h6 style={{ fontSize: "24px" }} className="orderHeaderText">
                  Packages
                </h6>
              </div>
            </Col>
            <Col>
              <span style={{ fontSize: "16px" }}>Billing</span>
              <br />
              <span style={{ color: "#89959b", fontSize: "14px" }}>
                Status :{" "}
              </span>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {modalData.billingPackage === "yes"
                  ? "Activated"
                  : "Not Activated"}
              </span>
              <br />
              <span style={{ color: "#89959b", fontSize: "14px" }}>
                Expiry Date :{" "}
              </span>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {modalData.billingPackage === "yes"
                  ? modalData.billingExpiryDate
                  : " - "}
              </span>
            </Col>
            <Col>
              <span style={{ fontSize: "16px" }}>Appointment</span>
              <br />
              <span style={{ color: "#89959b", fontSize: "14px" }}>
                Status :{" "}
              </span>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {modalData.appointmentPackage === "yes"
                  ? "Activated"
                  : "Not Activated"}
              </span>
              <br />
              <span style={{ color: "#89959b", fontSize: "14px" }}>
                Expiry Date :{" "}
              </span>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {modalData.appointmentPackage === "yes"
                  ? modalData.appointmentExpiryDate
                  : " - "}
              </span>
            </Col>
            <Col>
              <span style={{ fontSize: "16px" }}>Orders</span>
              <br />
              <span style={{ color: "#89959b", fontSize: "14px" }}>
                Status :{" "}
              </span>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {modalData.deliveryPackage === "yes"
                  ? "Activated"
                  : "Not Activated"}
              </span>
              <br />
              <span style={{ color: "#89959b", fontSize: "14px" }}>
                Expiry Date :{" "}
              </span>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {modalData.deliveryPackage === "yes"
                  ? modalData.deliveryExpiryDate
                  : " - "}
              </span>
            </Col>
            <Col>
              <span style={{ fontSize: "16px" }}>Loyalty</span>
              <br />
              <span style={{ color: "#89959b", fontSize: "14px" }}>
                Status :{" "}
              </span>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {modalData.loyaltyPackage === "yes"
                  ? "Activated"
                  : "Not Activated"}
              </span>
              <br />
              <span style={{ color: "#89959b", fontSize: "14px" }}>
                Expiry Date :{" "}
              </span>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                {modalData.loyaltyPackage === "yes"
                  ? modalData.loyaltyExpiryDate
                  : " - "}
              </span>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
