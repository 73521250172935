import React, { useEffect, useState } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { NotificationAPI } from "../handler/apiHandler";
import DeleteIcon from "@material-ui/icons/Delete";

export default function Notification({ classes, profile }) {
  const [notificationList, setNotificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getNotificationAPI();
  }, [profile]);

  const getNotificationAPI = async () => {
    await NotificationAPI({ shopId: profile.shopId }).then((data) => {
      if (data.status) {
        let sortNotification = data.data.notification.sort(
          (a, b) => b.date - a.date
        );
        setIsLoading(false);
        setNotificationList(sortNotification);
      }
    });
  };

  return (
    <>
      {isLoading ? null : (
        <>
          <div
            style={{ boxShadow: "none", overflow: "-moz-hidden-unscrollable" }}
            className="wrapTable"
          >
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.tabTableHeader}
                      key={"1"}
                      align={"1"}
                    >
                      Date
                    </TableCell>
                    <TableCell className={classes.tabTableHeader}>
                      Notification Type
                    </TableCell>
                    <TableCell className={classes.tabTableHeader}>
                      Message
                    </TableCell>
                    <TableCell className={classes.tabTableHeader}>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notificationList.map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        <TableCell className={classes.tabTableCell} key={1}>
                          {row.date}
                        </TableCell>
                        <TableCell className={classes.tabTableCell}>
                          {row.notificationType}
                        </TableCell>
                        <TableCell className={classes.tabTableCell}>
                          {row.message}
                        </TableCell>
                        <TableCell className={classes.tabTableCell}>
                          {row.status}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </>
  );
}
