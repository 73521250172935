import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import config from "../../config";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
export default class CustomerPincode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showArea: [],
      loadPage: false,
    };
  }
  componentDidMount = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        var mergePincodeAndArea = this.props.pincode.map((items) => {
          let count = data.Items.find(
            (elements) => items.pincode === elements.pincode
          );

          let appendObject = {};
          if (count !== undefined) {
            appendObject = Object.assign({}, count, items);
          } else {
            appendObject = {
              pincode: items.pincode,
              place: "Not Found",
              totalCustomers: items.totalCustomers,
            };
          }

          return appendObject;
        });

        this.setState({
          loadPage: true,
          showArea: mergePincodeAndArea,
        });
      });
  };
  render() {
    return (
      <>
        {this.state.loadPage && (
          <>
            <Typography
              variant="h2"
              style={{ textAlign: "center" }}
              gutterBottom
            >
              GHOSHAK CUSTOMER PINCODE
            </Typography>
            <Col className="division" md={{ span: 4, offset: 5 }}>
              <>
                <h4>TOTAL PINCODE ARE {this.state.showArea.length}</h4>
              </>
            </Col>
            <Col md={{ span: 8, offset: 2 }}>
              <>
                <div style={{ width: 100 + "%" }}>
                  <h3 style={{ textAlign: "center" }}> CUSTOMER PINCODE </h3>
                </div>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid",
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S NO</TableCell>
                      <TableCell align="center">PINCODE</TableCell>
                      <TableCell align="center">Total Customers</TableCell>
                      <TableCell align="center">PLACE</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.showArea.map((items, index) => (
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{items.pincode}</TableCell>
                        <TableCell align="center">
                          {items.totalCustomers}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor:
                              items.place === "Not Found" ? "red" : "",
                          }}
                          align="center"
                        >
                          {items.place}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            </Col>
          </>
        )}
      </>
    );
  }
}
