import React, { useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import S3 from "react-aws-s3";
import uuid from "uuid/dist/v4";
import config from "../../config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const OurClients = (props) => {
  const [websiteTemplate, setWebsiteTemplate] = useState();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState("");
  const [modal, setModal] = useState(false);
  const [header, setHeader] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setImageName] = useState("");
  const [id, setId] = useState("");

  const [active, setActive] = useState(true);
  const [templateId, setTemplateId] = useState("ourClients_001");
  const [templateName, setTemplateName] = useState("Rightside Header");

  const ref = useRef();
  const fetchDetails = async () => {
    await fetchUserDetails(props.shopId).then((data) => {
      var shopData = data[0];
      if (shopData.websiteTemplate) {
        setWebsiteTemplate(shopData.websiteTemplate);
        var websiteData = shopData.websiteTemplate;
        if (websiteData.ourClients) {
          setActive(
            websiteData.ourClients.active
              ? websiteData.ourClients.active
              : active
          );
          setTemplateId(
            websiteData.ourClients.templateId
              ? websiteData.ourClients.templateId
              : templateId
          );
          setTemplateName(
            websiteData.ourClients.templateName
              ? websiteData.ourClients.templateName
              : templateName
          );
          if (websiteData.ourClients.pageProps.cardData) {
            if (websiteData.ourClients.pageProps.cardData.length > 0) {
              setClients(websiteData.ourClients.pageProps.cardData);
            }
          }
        }
      }
    });
  };
  useEffect(() => {
    fetchDetails();
  }, [props]);

  const handleAdd = async () => {
    var dataObj = {};
    if (websiteTemplate) {
      dataObj = {
        ...websiteTemplate,
      };
      if (clients.length > 0) {
        dataObj = {
          ...dataObj,
          ourClients: {
            ...websiteTemplate.ourClients,
            active: active,
            templateId: templateId,
            templateName: templateName,
            pageProps: {
              cardData: [
                ...clients,
                {
                  imgName: imageName,
                  description: "",
                  imageUrl: imageUrl,
                  id: uuid(),
                  heading: header,
                  imageUri: imageUrl,
                },
              ],
            },
          },
        };
      } else {
        dataObj = {
          ...dataObj,
          ourClients: {
            active: active,
            templateId: templateId,
            templateName: templateName,
            pageProps: {
              cardData: [
                ...clients,
                {
                  id: uuid(),
                  heading: header,
                  description: "",
                  imageUrl: imageUrl,
                  imgName: imageName,
                  imageUri: imageUrl,
                },
              ],
            },
          },
        };
      }
    } else {
      dataObj = {
        colorTheme: "default",
        order: [
          "aboutUs",
          "about",
          "onlineProduct",
          "ourServices",
          "ourProjects",
          "ourBrands",
          "ourClients",
          "ourGallery",
          "clientTestimonials",
          "videoGallery",
          "socialMedia",
          "ourPartner",
          "ourTeam",
        ],
        ourClients: {
          pageProps: {
            cardData: [
              {
                imgName: imageName,
                description: "",
                imageUrl: imageUrl,
                id: uuid(),
                heading: header,
                imageUri: imageUrl,
              },
            ],
          },
          active: active,
          templateId: templateId,
          templateName: templateName,
        },
      };
    }
    await contentupload(dataObj, props.shopId).then(async (data) => {
      await fetchDetails();
      setImageName("");
      setImageUrl("");
      setHeader("");
      setModal(false);
    });
  };

  const handleEdit = (data) => {
    setHeader(data.heading);
    setImageUrl(data.imageUrl);
    setImageName(data.imageName);
    setId(data.id);
    setFrom("update");
    setModal(true);
  };

  const handleUpdate = async () => {
    var filteredClients = clients.filter((data) => data.id !== id);
    var dataObj = {
      ...websiteTemplate,
      ourClients: {
        ...websiteTemplate.ourClients,
        pageProps: {
          cardData: [
            ...filteredClients,
            {
              id: id,
              heading: header,
              description: "",
              imageUri: imageUrl,
              imageUrl: imageUrl,
              imgName: imageName,
            },
          ],
        },
      },
    };
    await contentupload(dataObj, props.shopId).then(async (data) => {
      setClients([]);
      setHeader("");
      setImageUrl("");
      setImageName("");
      setId("");
      setFrom("");
      setModal(false);
      await fetchDetails();
    });
  };

  const handleDelete = async (key) => {
    var filteredClients = clients.filter((data) => data.id !== key.id);
    var dataObj = {
      ...websiteTemplate,
      ourClients: {
        ...websiteTemplate.ourClients,
        pageProps: {
          cardData: [...filteredClients],
        },
      },
    };
    await contentupload(dataObj, props.shopId).then(async (data) => {
      setClients([]);
      await fetchDetails();
    });
  };

  const handleActiveTemplateChange = async (t, n, a) => {
    console.log("kdfnks sknckc", t, n, a);
    var updateObj = {
      ...websiteTemplate,
      ourClients: {
        ...websiteTemplate.ourClients,
        active: a,
        templateId: t,
        templateName: n,
        pageProps: {
          cardData: [...clients],
        },
      },
    };
    await contentupload(updateObj, props.shopId).then(async () => {
      fetchDetails();
    });
  };

  return (
    <div>
      <h4>Add or Edit Our Clients</h4>
      <div>
        {clients.length > 0 && (
          <>
            <div>
              <strong>
                <label>Show Our Clients</label>
              </strong>{" "}
              <FormControlLabel
                control={
                  <Switch
                    checked={active}
                    onChange={(e) => {
                      setActive(!active);
                    }}
                    color="primary"
                    value={active}
                  />
                }
              ></FormControlLabel>
            </div>
            <div>
              <strong>
                <label>Choose Template</label>
              </strong>
              <br />
              <select
                onChange={(e) => {
                  setTemplateId(e.target.value);
                  setTemplateName(e.target.name);
                }}
                value={templateId}
                style={{
                  width: "200px",
                }}
              >
                <option value="" disabled>
                  Select a template
                </option>
                <option value="ourClients_001" name="Rightside Header">
                  Rightside Header
                </option>
                <option value="ourClients_002" name="Compact">
                  Compact
                </option>
                <option value="ourClients_003" name="Magnified">
                  Magnified
                </option>
              </select>{" "}
            </div>
            <br />
            <Button
              onClick={() => {
                handleActiveTemplateChange(templateId, templateName, active);
              }}
            >
              Save
            </Button>
          </>
        )}
      </div>
      <br />

      <Button
        onClick={() => {
          setFrom("add");
          setModal(true);
        }}
      >
        Add Clients
      </Button>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {clients.map((data) => {
          return (
            <div
              className="ourServicesCard"
              key={data.id}
              style={{
                margin: "10px",
                width: "30%",
                boxShadow: "5px 5px 20px 1px #d4d4d4",
                borderRadius: "5px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <img
                    src={data.imageUrl}
                    width="100"
                    height="100"
                    alt="Service logo"
                  />
                </div>
                <div>
                  <div style={{ marginLeft: "5px" }}>
                    <strong>
                      <p>{data.heading}</p>
                    </strong>
                    <p>{data.description}</p>
                  </div>
                  <div style={{ margin: "5px" }}>
                    <Button
                      variant="outline-info"
                      onClick={() => handleEdit(data)}
                      size="sm"
                    >
                      Edit
                    </Button>{" "}
                    <Button
                      variant="outline-danger"
                      onClick={() => handleDelete(data)}
                      size="sm"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        show={modal}
        onHide={() => {
          setHeader("");
          setImageUrl("");
          setImageName("");
          setId("");
          setFrom("");
          setModal(!modal);
        }}
      >
        <Modal.Header>
          {from === "update" ? <h4>Update Client</h4> : <h4>Add Client</h4>}
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <div>
              <strong>
                <label>Enter Client Header: </label>
              </strong>
              <br />
              <textarea
                placeholder="Enter client header"
                onChange={(e) => setHeader(e.target.value)}
                value={header}
                rows="3"
                cols="40"
              />{" "}
            </div>

            <div>
              <strong>
                <label>Upload client image: </label> <br />
              </strong>
              <input
                ref={ref}
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={async (e) => {
                  setLoading(true);
                  console.log(e.target.files[0]);
                  var data = await handleImageUpload(e.target.files[0]);
                  var imageLink = `https://ghoshak-website-builder.s3-ap-south-1.amazonaws.com/${data.key}`;
                  setImageUrl(imageLink);
                  setImageName(data.key);
                  setLoading(false);
                }}
              />{" "}
            </div>
            <div>
              {imageUrl && (
                <div
                  style={{
                    margin: "10px",
                    width: "100%",
                    boxShadow: "5px 5px 20px 1px #d4d4d4",
                    borderRadius: "5px",
                  }}
                >
                  <img src={imageUrl} alt="Image" width="100" height="100" />
                  <Button
                    variant="outline-danger"
                    size="sm"
                    style={{ float: "right", margin: "10px" }}
                    onClick={() => {
                      setImageUrl("");
                      setImageName("");
                    }}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant={from === "update" ? "outline-info" : "outline-warning"}
            onClick={
              from === "update" ? () => handleUpdate() : () => handleAdd()
            }
          >
            {from === "update" ? <>update</> : <>Add</>}
          </Button>
          <Button
            onClick={() => {
              setHeader("");
              setImageUrl("");
              setImageName("");
              setId("");
              setFrom("");
              setModal(!modal);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const fetchUserDetails = async (shopId) => {
  if (shopId === "") return alert("Enter valid Shop Id");
  return await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
      method: "POST",
      body: JSON.stringify({
        userId10Digit: shopId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data[0].websiteTemplate);
      return data;
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("Something went wrong, please try again");
    });
};

const contentupload = async (obj, userId) => {
  await Promise.all([
    fetch(`${config.ApiGateway}/ownDomainContentUpload`, {
      method: "POST",
      body: JSON.stringify({
        obj: obj,
        userId: userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res]) => Promise.all([res.json()]))
    .then(async ([data]) => {
      console.log(data);
      alert("uploaded success");
    })
    .catch((err) => {
      console.log("updating A Record Error", err);
      alert("error while uploading data. Please try again");
    });
};

const handleImageUpload = async (image) => {
  const configSettings = {
    bucketName: "ghoshak-website-builder",
    region: "ap-south-1",
    accessKeyId: config.AccessKeyId,
    secretAccessKey: config.SecretAccessKey,
  };
  var fileName = uuid() + ".jpeg";
  var S3Client = new S3(configSettings);
  let data = await S3Client.uploadFile(image, fileName)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("S3 Error", err);
    });
  return data;
};

export default OurClients;
