// import dependency

import React, { useEffect, useState, useRef } from "react";
import MaterialTable from "material-table";
import Handler from "./handler/handler";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import config from "../config";
import CloseIcon from "@material-ui/icons/Close";
import Validation from "./handler/validation";
import { CreateOfferAPI, UpdateOfferAPI } from "./handler/apiHandler";
let initialState = {
  offerCategory: "",
  offerSubCategory: "",
  offerType: "offer",
  imageFile: [],
};
let initialDropdown = {
  category: [],
  operatingSystem: [],
  brand: [],
};
let initialImageDisplay = { selectedImage: null, fileName: null };
export default function ContentReview({ profile, crmUser }) {
  let fileUploadHandler = useRef(); // Create Ref for File input html
  const [findHandler] = Handler(); // Handler class
  let [validationHandler] = Validation();
  const [offer, setOffer] = useState([]); // assign empty object
  const [modalState, setModalState] = useState(false); // Modal State
  const [offerType, setOfferType] = useState("offer");
  const [isLoading, setIsLoading] = useState(true);
  const [tabKey, setTabKey] = useState("offer");
  const [imageUrl, setImageUrl] = useState(initialImageDisplay);
  const [editState, setEditState] = useState(false);
  const [state, setState] = useState(initialState);
  const [dropdown, setDropdown] = useState(initialDropdown);
  const sampleImage =
    "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/images/offers/default_offer_image.png";

  useEffect(() => {
    getOffers();
  }, [profile]);

  const getOffers = async () => {
    setIsLoading(true);
    let { shopType, userId } = profile;
    if (["Textile", "Mobile", "Supermarket"].includes(shopType)) {
      let getCategoryDropdown = await findHandler(
        "COLLECTION_CATEGORY",
        shopType
      );
      setDropdown(getCategoryDropdown);
    }
    await offersAPI();
  };
  const closeModal = () => {
    // close modal and reset to initial state
    setEditState(false);
    setModalState(false);
    setState(initialState);
    setImageUrl(initialImageDisplay);
  };

  const offersAPI = async () => {
    return await new Promise((resolve) => {
      if (Boolean(profile.userId)) {
        Promise.all([
          fetch(`${config.ApiGateway}/editoffer`, {
            method: "POST",
            body: JSON.stringify({
              shopId: profile.userId,
              offerType: tabKey,
            }),

            headers: {
              "Content-Type": "application/json",
            },
          }),
        ])
          .then(([res1]) => Promise.all([res1.json()]))
          .then(async ([data]) => {
            if (data.status) {
              if (data.payloadResponseExceededLimit) {
                try {
                  let uploadData = await (await fetch(data.data)).json();
                  setOffer(uploadData.offerRecords);
                  setIsLoading(false);
                  resolve();
                } catch (err) {
                  alert("Offer fetching api failed");
                }
              } else {
                resolve();
                setOffer(data.data.offerRecords);
                setIsLoading(false);
              }
            }
          })
          .catch((err) => console.log("error getting mac address", err));
      }
    });
  };
  const handleChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    // Triggers to calculate offer discount
    validateOfferDiscount();
  }, [state.offerNewPrice, state.offerOriginalPrice]);
  const validateOfferDiscount = () => {
    // calculates discount price
    if (
      parseFloat(state.offerOriginalPrice) <= parseFloat(state.offerNewPrice)
    ) {
      setState((prevState) => ({
        ...prevState,
        offerNewPrice: "",
      }));
      return alert("OFFER PRICE SHOULD BE LESS THAN ORIGINAL PRICE");
    }
    if (state.offerOriginalPrice !== "" && state.offerNewPrice !== "") {
      let originalPrice = parseFloat(state.offerOriginalPrice);
      let newPrice = parseFloat(state.offerNewPrice);

      let discount = ((originalPrice - newPrice) / originalPrice) * 100;
      discount = Math.round(discount);
      setState((prevState) => ({
        ...prevState,
        offerDiscount: discount,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        offerDiscount: 0,
      }));
    }
  };
  const createOffer = async (e) => {
    e.preventDefault();
    let input = state;
    input.shopId = profile.userId;
    input.shopPincode = profile.userPincode;
    input.shopType = profile.shopType;
    input.userId10Digit = profile.userId10Digit;
    input.reviewedBy = crmUser;
    let removedFields = await validationHandler("removeFields", state);
    await CreateOfferAPI(removedFields).then((data) => {
      if (data.status) {
        alert("offer created successfully");
        closeModal();
      } else {
        alert("offer created failed");
      }
    });
  };
  const updateOffer = async (e) => {
    // Update offer fields
    e.preventDefault();
    let input = state;
    let removedFields = await validationHandler("removeFields", state);
    await UpdateOfferAPI(removedFields).then((data) => {
      console.log(data, "updated data");
    });
  };

  const renderTable = () => {
    return (
      <>
        <div className="wrapTable">
          <MaterialTable
            title="Offers"
            actions={[
              {
                icon: "delete",
                tooltip: "Delete Offer",
                onClick: (event, rowData) => {
                  alert("offer deleted");
                },
              },
              {
                icon: "edit",
                tooltip: "edit Offer",
                onClick: (event, rowData) => {
                  let input = {};
                  setEditState(true);
                  Object.keys(rowData).forEach((elements) => {
                    input[elements] = rowData[elements];
                  });
                  setModalState(true);
                  setState(input);
                },
              },
            ]}
            columns={[
              {
                title: "Offer Imge",
                field: "offerImageLocation",
                filtering: false,
                render: (rowData) => (
                  <img
                    src={rowData.offerImageLocation}
                    alt={rowData.offerImageLocation}
                    width="100"
                    height="100"
                  ></img>
                ),
              },

              {
                title: "type",
                field: "offerType",
                filtering: false,
                render: (rowData) =>
                  rowData.offerType == "offer" ? "offer" : "collection",
              },
              {
                title: "Details",
                field: "offerDetails",
                filtering: false,
              },
              {
                title: "Created on ",
                field: "offerAddedDate",
                filtering: false,
              },
              {
                title: "Expiry on ",
                field: "offerEndDate",
                filtering: false,
              },
              {
                title: "Offer Status",
                field: "offerStatus",
                lookup: {
                  Live: "Live",
                  Rejected: "Rejected",
                  Deleted: "Deleted",
                },
              },

              {
                title: "Review",
                field: "review",
                lookup: { yes: "Reviewed", no: "Pending" },
                hidden: !(tabKey == "contentReview"),
              },

              {
                title: "Reviewed By",
                field: "reviewedBy",
                hidden: !(tabKey == "contentReview"),
                render: (rowData) =>
                  `${rowData.reviewedBy} ${rowData.reviewedOn}`,
              },
            ]}
            data={offer}
            options={{
              exportButton: false,
              pageSize: 30,
              pageSizeOptions: [5, 10, 20, 100],
              grouping: false,
              filtering: true,
            }}
          />
        </div>
      </>
    );
  };
  useEffect(() => {
    offersAPI();
  }, [tabKey]);

  return (
    <>
      <div className="vertical-space-10"></div>

      <div className="shopCardRow">
        <div className="shopCardHeader">
          <p>ContentReview</p>
        </div>
        <div className="customDateRangePickerContainer">
          <button
            onClick={() => setModalState(true)}
            id="success"
            className="actionButton"
          >
            Create Offer/Collection
          </button>
        </div>
      </div>

      {isLoading ? null : (
        <>
          <div className="shopCardRow">
            <div className="shopCardColumn">
              <div className="shopCardBox">
                <div className="ShopCardTabs">
                  <div className="shopCardColumn">
                    <Tabs
                      defaultActiveKey="website"
                      transition={false}
                      id="noanim-tab-example"
                      className="mb-3"
                      activeKey={tabKey}
                      onSelect={(k) => setTabKey(k)}
                    >
                      <Tab eventKey="contentReview" title="Content Review">
                        {renderTable()}
                      </Tab>
                      <Tab eventKey="offer" title="Offers">
                        {renderTable()}
                      </Tab>
                      <Tab eventKey="item" title="Collection">
                        {renderTable()}
                      </Tab>
                      <Tab
                        eventKey="offerDistribution"
                        title="Offer/Collection Distribution"
                      >
                        <h4>Loading</h4>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="modal-order-coupon"
            show={modalState}
            onHide={closeModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Create Offer/Collection</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <div className="fieldBox">
                  <>
                    <div style={{ flexBasis: "100%" }} className="fieldWrap">
                      <label>Offer Type</label>
                      <div key={`inline-radio`} className="mb-3">
                        <Form.Check
                          inline
                          label="Offer"
                          name="offerType"
                          onChange={(e) => handleChange("offerType", "offer")}
                          type="radio"
                          checked={state.offerType == "offer"}
                          id={`inline-radio-1`}
                        />
                        <Form.Check
                          inline
                          checked={state.offerType == "item"}
                          label="Collection"
                          name="offerType"
                          onChange={(e) => handleChange("offerType", "item")}
                          type="radio"
                          id={`inline-radio-2`}
                        />
                      </div>
                    </div>
                    <div id="twoCol" className="fieldWrap">
                      <label>Original Price</label>
                      <input
                        pattern="[0-9]{1,10}"
                        title="Enter only Numbers"
                        onChange={(e) => {
                          handleChange("offerOriginalPrice", e.target.value);
                        }}
                        className={"flexInputBox"}
                        placeholder="₹ 0.00"
                        value={state.offerOriginalPrice}
                      ></input>
                    </div>
                    <div id="twoCol" className="fieldWrap">
                      <label>Offer Price</label>
                      <input
                        pattern="[0-9]{1,10}"
                        onChange={(e) => {
                          handleChange("offerNewPrice", e.target.value);
                        }}
                        className={"flexInputBox"}
                        value={state.offerNewPrice}
                        placeholder="₹ 0.00"
                      ></input>
                    </div>
                    <div id="twoCol" className="fieldWrap">
                      <label> Start Date</label>
                      <input
                        type="date"
                        onChange={(e) =>
                          handleChange("offerStartDate", e.target.value)
                        }
                        className={"flexInputBox"}
                        value={state.offerStartDate}
                        min={new Date().toISOString().substring(0, 10)}
                      ></input>
                    </div>
                    <div id="twoCol" className="fieldWrap">
                      <label> Start Time</label>
                      <input
                        type="time"
                        onChange={(e) =>
                          handleChange("offerStartTime", e.target.value)
                        }
                        className={"flexInputBox"}
                        value={state.offerStartTime}
                      ></input>
                    </div>
                    <div id="twoCol" className="fieldWrap">
                      <label id={offerType == "offer" && "required"}>
                        End Date
                      </label>
                      <input
                        type="date"
                        onChange={(e) =>
                          handleChange("offerEndDate", e.target.value)
                        }
                        className={"flexInputBox"}
                        value={state.offerEndDate}
                        required={offerType == "offer"}
                        min={state.offerStartDate}
                      ></input>
                    </div>
                    <div id="twoCol" className="fieldWrap">
                      <label id={offerType == "offer" && "required"}>
                        End Time
                      </label>
                      <input
                        type="time"
                        onChange={(e) =>
                          handleChange("offerEndTime", e.target.value)
                        }
                        required={offerType == "offer"}
                        className={"flexInputBox"}
                        value={state.offerEndTime}
                      ></input>
                    </div>
                    <div style={{ flexBasis: "100%" }} className="fieldWrap">
                      <label id="required">Offer Details/Product Name</label>
                      <textarea
                        className={"flexInputBox"}
                        value={state.offerDetails}
                        onChange={(e) =>
                          handleChange("offerDetails", e.target.value)
                        }
                        required
                      ></textarea>
                    </div>
                    {state.offerType === "item" && (
                      <>
                        {profile.shopType == "Textile" && (
                          <>
                            <div id="twoCol" className="fieldWrap">
                              <label id="required">Category</label>
                              <input
                                className={"flexInputBox"}
                                value={state.offerCategory}
                                disabled={true}
                                onChange={(e) =>
                                  handleChange("offerCategory", e.target.value)
                                }
                              ></input>
                            </div>
                            <div id="twoCol" className="fieldWrap">
                              <label id="required">Sub Category</label>
                              <select
                                onChange={(e) => {
                                  let value = JSON.parse(e.target.value);

                                  //value ---> [0] - Offer category ,,,,, [1] ---> Sub category

                                  setState((prevState) => ({
                                    ...prevState,
                                    offerSubCategory: value[1],
                                    offerCategory: value[0],
                                  }));
                                }}
                                className={"flexInputBox"}
                                value={state.offerSubCategory}
                              >
                                {dropdown.category}
                              </select>
                            </div>
                          </>
                        )}
                        {profile.shopType == "Mobile" && (
                          <>
                            <div id="twoCol" className="fieldWrap">
                              <label id="required">Category</label>
                              <select
                                className={"flexInputBox"}
                                value={state.offerSubCategory}
                                onChange={(e) =>
                                  handleChange("offerCategory", e.target.value)
                                }
                                required
                              >
                                {dropdown.category}
                              </select>
                            </div>

                            <div id="twoCol" className="fieldWrap">
                              <label id="required">BRAND</label>
                              <select
                                onChange={(e) =>
                                  handleChange("mobileBrand", e.target.value)
                                }
                                required
                                className={"flexInputBox"}
                                value={state.mobileBrand}
                              >
                                {dropdown.brand}
                              </select>
                            </div>

                            <div id="twoCol" className="fieldWrap">
                              <label id="required"> Model</label>
                              <input
                                onChange={(e) =>
                                  handleChange("mobileModel", e.target.value)
                                }
                                className={"flexInputBox"}
                                value={state.mobileModel}
                              ></input>
                            </div>

                            <div id="twoCol" className="fieldWrap">
                              <label id="required"> Memory</label>
                              <input
                                onChange={(e) =>
                                  handleChange("mobileMemory", e.target.value)
                                }
                                required
                                className={"flexInputBox"}
                                value={state.mobileMemory}
                              ></input>
                            </div>

                            <div id="twoCol" className="fieldWrap">
                              <label> Dimension</label>
                              <input
                                className={"flexInputBox"}
                                value={state.mobileDimensions}
                                onChange={(e) =>
                                  handleChange(
                                    "mobileDimensions",
                                    e.target.value
                                  )
                                }
                                placeholder="15.7 * 0.9"
                              ></input>
                            </div>
                            <div id="twoCol" className="fieldWrap">
                              <label> Memory Expandable</label>
                              <input
                                className={"flexInputBox"}
                                value={state.mobileMemoryExtendable}
                                onChange={(e) =>
                                  handleChange(
                                    "mobileMemoryExtendable",
                                    e.target.value
                                  )
                                }
                              ></input>
                            </div>

                            <div id="twoCol" className="fieldWrap">
                              <label id="required"> RAM</label>
                              <input
                                required
                                className={"flexInputBox"}
                                value={state.mobileRAM}
                                onChange={(e) =>
                                  handleChange("mobileRAM", e.target.value)
                                }
                              ></input>
                            </div>

                            <div id="twoCol" className="fieldWrap">
                              <label id="required">Operating System</label>
                              <select
                                className={"flexInputBox"}
                                onChange={(e) =>
                                  handleChange("mobileOS", e.target.value)
                                }
                                required
                                value={state.mobileOS}
                              >
                                {dropdown.operatingSystem}
                              </select>
                            </div>

                            <div id="twoCol" className="fieldWrap">
                              <label> Front Camera Pixel</label>
                              <input
                                className={"flexInputBox"}
                                value={state.mobileFrontFacingCamera}
                                onChange={(e) =>
                                  handleChange(
                                    "mobileFrontFacingCamera",
                                    e.target.value
                                  )
                                }
                              ></input>
                            </div>

                            <div id="twoCol" className="fieldWrap">
                              <label>Rear Camera Pixel</label>
                              <input
                                className={"flexInputBox"}
                                value={state.mobileRearFacingCamera}
                                onChange={(e) =>
                                  handleChange(
                                    "mobileRearFacingCamera",
                                    e.target.value
                                  )
                                }
                              ></input>
                            </div>

                            <div id="twoCol" className="fieldWrap">
                              <label>Color</label>
                              <input
                                className={"flexInputBox"}
                                value={state.mobileColor}
                                onChange={(e) =>
                                  handleChange("mobileColor", e.target.value)
                                }
                              ></input>
                            </div>

                            <div id="twoCol" className="fieldWrap">
                              <label>Weight</label>
                              <input
                                className={"flexInputBox"}
                                value={state.mobileWeight}
                                placeholder={"In Gms"}
                                onChange={(e) =>
                                  handleChange("mobileWeight", e.target.value)
                                }
                              ></input>
                            </div>

                            <div id="twoCol" className="fieldWrap">
                              <label>Other Details</label>
                              <textarea
                                className={"flexInputBox"}
                                value={state.mobileOtherDetails}
                                onChange={(e) =>
                                  handleChange(
                                    "mobileOtherDetails",
                                    e.target.value
                                  )
                                }
                              ></textarea>
                            </div>

                            <div id="twoCol" className="fieldWrap">
                              <label>Service Details</label>
                              <textarea
                                className={"flexInputBox"}
                                value={state.mobileServiceDetails}
                                onChange={(e) =>
                                  handleChange(
                                    "mobileServiceDetails",
                                    e.target.value
                                  )
                                }
                              ></textarea>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {tabKey === "contentReview" && (
                      <>
                        <div
                          style={{ flexBasis: "100%" }}
                          className="fieldWrap"
                        >
                          <label>Approved Status</label>
                          <div key={`inline-radio`} className="mb-3">
                            <Form.Check
                              inline
                              label="Yes"
                              name="approvedStatus"
                              onChange={(e) => {
                                handleChange("offerStatus", "Live");
                                handleChange("approvedStatus", "yes");
                              }}
                              type="radio"
                              checked={state.approvedStatus == "yes"}
                              id={`inline-radio-1`}
                            />
                            <Form.Check
                              inline
                              checked={state.approvedStatus == "no"}
                              label="No"
                              name="approvedStatus"
                              onChange={(e) => {
                                handleChange("offerStatus", "Rejected");
                                handleChange("approvedStatus", "no");
                              }}
                              type="radio"
                              id={`inline-radio-2`}
                            />
                          </div>
                        </div>

                        <div id="twoCol" className="fieldWrap">
                          <label id="required">Offer Status</label>
                          <select
                            className={"flexInputBox"}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (value == "Live") {
                                handleChange("approvedStatus", "yes");
                              } else if (value == "Rejected") {
                                handleChange("approvedStatus", "no");
                              }
                              handleChange("offerStatus", value);
                            }}
                            required
                            value={state.offerStatus}
                          >
                            <option value="Live">Live</option>
                            <option value="Rejected">Rejected</option>
                          </select>
                        </div>
                        <div id="twoCol" className="fieldWrap">
                          <label id="required">Offer Image Quality</label>
                          <select
                            className={"flexInputBox"}
                            onChange={(e) =>
                              handleChange("imageQuality", e.target.value)
                            }
                            required
                            value={state.imageQuality}
                          >
                            <option>Select Image Quality</option>
                            <option value="good"> Good</option>
                            <option value="low">Low</option>
                            <option value="medium">Medium</option>{" "}
                          </select>
                        </div>

                        {(state.offerStatus == "Rejected" ||
                          state.approvedStatus == "no") && (
                          <>
                            <div id="twoCol" className="fieldWrap">
                              <label id="required">Rejected Reason</label>
                              <select
                                className={"flexInputBox"}
                                onChange={(e) =>
                                  handleChange(
                                    "reviewRejectedReason",
                                    e.target.value
                                  )
                                }
                                required
                                value={state.reviewRejectedReason}
                              >
                                <option>Select Rejected Reason</option>
                                <option value="QUALITY">Quality</option>
                                <option value="ADULT">Adult</option>
                                <option value="COPYRIGHT">Copyright</option>
                                <option value="POLITICAL">Political</option>
                                <option value="IRRELEVANT">Irrelevant</option>
                                <option value="PAYMENT ISSUE">
                                  Payment Issue
                                </option>
                                <option value="OTHERS">Others</option>
                                <option value="MANAGEMENT">Management</option>
                              </select>
                            </div>

                            <div id="twoCol" className="fieldWrap">
                              <label id="required">Comment</label>
                              <textarea
                                className={"flexInputBox"}
                                value={state.reviewComment}
                                onChange={(e) =>
                                  handleChange("reviewComment", e.target.value)
                                }
                                required
                              ></textarea>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {Boolean(imageUrl.selectedImage) ? (
                      <div
                        style={{ flexBasis: "100%", margin: "10px 0" }}
                        className="centerFlex"
                      >
                        <div className="flexBox1">
                          <img src={sampleImage}></img>
                        </div>
                        <div className="flexBox2">
                          <span>{imageUrl.fileName}</span>
                        </div>

                        <div className="flexBox1">
                          <CloseIcon
                            onClick={() => {
                              setImageUrl({
                                selectedImage: null,

                                fileName: "",
                              });
                              handleChange("imageFile", []);
                            }}
                            style={{ color: "#333" }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div id="fullWidth" className="fieldWrap">
                        <div
                          onClick={() => fileUploadHandler.current.click()}
                          className="uploadImageBox"
                        >
                          <span className="centerText">Upload Image</span>
                          <input
                            ref={fileUploadHandler}
                            onChange={async (e) => {
                              let imagePath = await findHandler(
                                "IMAGE_HANDLER",
                                e.target.files[0]
                              );

                              handleChange("imageFile", imagePath.base64);
                              setImageUrl({
                                selectedImage:
                                  imagePath.imageURI == "undefined"
                                    ? null
                                    : imagePath.imageURI,
                                fileName: imagePath.fileName,
                              });
                            }}
                            style={{ display: "none" }}
                            type="file"
                          ></input>
                        </div>
                      </div>
                    )}
                    <div className="flexBoxEnd">
                      <div className="updateButton">
                        <button
                          type="submit"
                          id="success"
                          onClick={() =>
                            editState ? updateOffer() : createOffer()
                          }
                          className="actionButton"
                        >
                          {editState ? "Update Offer" : "Create Offer"}
                        </button>
                      </div>
                      <div className="updateButton">
                        <button
                          onClick={() => {
                            closeModal();
                          }}
                          id="danger"
                          className="actionButton"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </>
                </div>
              </>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}
