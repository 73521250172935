import React, { Component } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import config from "../../config";
import Limit from "../../limit";
export default class MerchantCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponArray: []
    };
    this.getShop = this.getShop.bind(this);
    this.getMerchantDetails = this.getMerchantDetails.bind(this);
  }
  async componentDidMount() {
    await this.getShop();
  }
  getMerchantDetails(userid) {
    this.props.getMerchant(userid);
  }
  getShop() {
    var shopid = this.props.coupon[1].userId;

    var userDetails = [];
    var couponMerchantDetails = [];
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            constant_score: {
              filter: {
                bool: {
                  should: [
                    {
                      terms: {
                        userId: shopid
                      }
                    }
                  ],
                  must_not: {
                    bool: {
                      must: [{ term: { pincode: Limit.size.ghoshakInternal } }]
                    }
                  }
                }
              }
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        data.hits.hits.map(element => userDetails.push(element._source));

        userDetails.map(elements => {
          this.props.coupon.map(items => {
            if (items.userId === elements.userId) {
              couponMerchantDetails.push(Object.assign({}, items, elements));
            }
          });
        });

        this.setState({
          couponArray: couponMerchantDetails
        });
      })
      .catch(err => console.log(err, "function call failed"));
  }

  render() {
    return (
      <Row>
        <Col>
          <h3 style={{ textAlign: "center" }}>COUPONS</h3>
          <Table
            style={{
              width: 100 + "%",
              border: 0.5 + "px",
              borderColor: "#ced4da",
              borderStyle: "solid"
            }}
            size="medium"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">S NO</TableCell>
                <TableCell align="center">SHOP NAME</TableCell>
                <TableCell align="center">SHOP ID</TableCell>
                <TableCell align="center">PHONE NUMBER</TableCell>
                <TableCell align="center">PINCODE</TableCell>
                <TableCell align="center">COUPONS USED</TableCell>
                <TableCell align="center">COUPONS CONSUMED VALUE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.couponArray.map((item, index) => (
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item.shopName}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="link"
                      onClick={() => {
                        this.getMerchantDetails(item.userId10Digit);
                      }}
                    >
                      {item.userId10Digit}
                    </Button>
                  </TableCell>

                  <TableCell align="center">{item.userPhoneNumber}</TableCell>
                  <TableCell align="center">{item.userPincode}</TableCell>
                  <TableCell align="center">{item.couponsUsed}</TableCell>
                  <TableCell align="center">{item.consumedValue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Col>
      </Row>
    );
  }
}
