// Import Dependencies

import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Container, Col, Button, Row, Modal, Form } from "react-bootstrap";
import config from "../config";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
export default function Mac() {
  const [macList, setMacList] = useState([]);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const [filterBy, setFilterby] = useState("All");
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [getReferalId, setGetReferalId] = useState([]);
  const classes = useStyles();
  useEffect(() => {
    Promise.all([
      fetch(`${config.ApiGateway}/mac`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let sortByMacCount = data.sort((a, b) => b.count - a.count);
        sortByMacCount = sortByMacCount.filter(
          (elements) => elements.count >= 5 // Display user more than 5 logged in
        );
        setMacList(sortByMacCount);
      })
      .catch((err) => console.log("error getting mac address", err));
  }, []);
  const getRefferdBy = async (userArray) => {
    let refferedBy = userArray
      .filter((elements) => elements.referredBy != "None")
      .map((items) => items.referredBy.toLowerCase());
    //
    return await Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          _source: {
            includes: ["referralCode", "userName"],
          },
          query: {
            bool: {
              should: [
                {
                  terms: {
                    referralCode: refferedBy,
                  },
                },
              ],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let tempdata = data.hits.hits.map((element) => element._source);

        return tempdata;
      });
  };

  return (
    <Container>
      <div style={{ width: "100%", height: "40px" }}></div>
      <div className="orderHeader">
        <h2 className="orderHeaderText">Mac</h2>
      </div>

      <Row>
        <Col>
          <MaterialTable
            title={` User List`}
            columns={[
              { title: "MAC address", field: "macAddress" },
              { title: "Mac Id by Count", field: "count" },

              {
                title: "Total shops",
                field: "view",
                render: (rowData) => {
                  return (
                    rowData.users.filter(
                      (elements) => elements.userType == "Shop"
                    ).length || 0
                  );
                },
              },
              {
                title: "Total Customers",
                field: "view",
                render: (rowData) => {
                  return (
                    rowData.users.filter(
                      (elements) => elements.userType == "Customer"
                    ).length || 0
                  );
                },
              },

              {
                title: "Action",
                field: "users",
                render: (rowData) => (
                  <Button
                    onClick={async () => {
                      setModalShow(true);

                      let refferalCode = await getRefferdBy(rowData.users);

                      setGetReferalId(refferalCode);
                      setModalData(rowData.users);
                    }}
                  >
                    View Users
                  </Button>
                ),
              },
            ]}
            data={macList}
            options={{
              exportButton: true,
              pageSize: 50,
              pageSizeOptions: [5, 10, 20, 100, 300, 500, 1000, 2000],
              grouping: false,
              filtering: false,
            }}
          />
        </Col>
      </Row>

      <Modal className="viewCartDetails" show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={"6"}></Col>
            <Col style={{ textAlign: "right" }} sm={"6"}>
              <Form.Group>
                <Col>
                  <Form.Label>Filter by user type</Form.Label>
                </Col>{" "}
                <Col sm="6" style={{ float: "right" }}>
                  <Form.Control
                    onChange={(e) => setFilterby(e.target.value)}
                    value={filterBy}
                    as="Select"
                  >
                    <option value="All"> All</option>
                    <option value="Shop"> Shop</option>
                    <option value="Customer"> Customer</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row
            style={{
              borderBottom: "1px solid rgb(219, 219, 219)",
              marginBottom: "5px",
            }}
          >
            <Col>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Ghoshak Id</TableCell>
                    <TableCell align="right">User Name</TableCell>
                    <TableCell align="right">Type</TableCell>

                    <TableCell align="right">Shop Name</TableCell>
                    <TableCell align="right">Shop Type</TableCell>

                    <TableCell align="right">Phone Number</TableCell>
                    <TableCell align="right">joined on</TableCell>
                    <TableCell align="right">Reffered By</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modalData
                    .filter((items) =>
                      filterBy === items.userType
                        ? items.userType
                        : filterBy === "All"
                        ? items
                        : null
                    )
                    .map((row) => {
                      let refName = "";
                      let refferedName = getReferalId.find(
                        (items) =>
                          items.referralCode.toLowerCase() ===
                          row.referredBy.toLowerCase()
                      );

                      if (refferedName != undefined) {
                        refName = refferedName.userName;
                      } else {
                        refName = "none";
                      }

                      return (
                        <>
                          <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                              {row.userId10Digit}
                            </TableCell>
                            <TableCell align="right">{row.userName}</TableCell>
                            <TableCell align="right">{row.userType}</TableCell>
                            <TableCell align="right">{row.shopName}</TableCell>
                            <TableCell align="right">{row.shopType}</TableCell>
                            <TableCell align="right">
                              {row.userPhoneNumber}
                            </TableCell>
                            <TableCell align="right">
                              {row.userAddedDate}
                            </TableCell>
                            <TableCell align="right">{refName}</TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
