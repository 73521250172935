import React, { Component } from "react";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { Auth } from "aws-amplify";
import config from "../config";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class ExtendMembership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [{ id: undefined, customerId: undefined }],
      custdId: "",

      shopArray: [],
      expiryEndDate1: "",
      expiryEndDate2: "",
      userId10Digit: [],
      buttonDisable: false,
      showImportExcel: true,
      updateExpiryDate: "",
      membershipupdatedOn: new Date().toISOString().substring(0, 10),
      membershipupdatedBy: "",
    };
    this.fileHandler = this.fileHandler.bind(this);

    this.reset = this.state;
    this.handleDelete = this.handleDelete.bind(this);
    this.fetchMerchant = this.fetchMerchant.bind(this);
    this.onChange = this.onChange.bind(this);
    this.textAreaOnchange = this.textAreaOnchange.bind(this);

    this.updateMerchantExpiryDate = this.updateMerchantExpiryDate.bind(this);
  }
  fileHandler = (event) => {
    let fileObj = event.target.files[0];

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let response = resp.rows.map((elements) => {
          return elements[0].toString().trim();
        });
        this.setState({
          userId10Digit: response,
        });
      }
    });
  };
  textAreaOnchange(e) {
    this.multilineTextarea.style.height = "auto";

    this.multilineTextarea.style.height =
      this.multilineTextarea.scrollHeight + "px";
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async componentDidMount() {
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({
          membershipupdatedBy: user.attributes.phone_number,
        });
      })

      .catch((err) => console.log(err));
  }
  fetchMerchant(e) {
    e.preventDefault();

    Promise.all([
      fetch(`${config.ApiGateway}/getMembershipDates`, {
        method: "POST",
        body: JSON.stringify({
          userId10Digit: this.state.userId10Digit,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        if (data.length <= 0) {
          this.setState(this.reset);
          this.componentDidMount();
          return alert("No Shops Found");
        } else {
          this.setState({
            shopArray: data,
            showImportExcel: false,
          });
        }
      })
      .catch((err) => alert(err));
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  updateMerchantExpiryDate(e) {
    e.preventDefault();
    Promise.all([
      fetch(`${config.ApiGateway}/extendMembership`, {
        method: "POST",
        body: JSON.stringify({
          shopArray: this.state.shopArray,
          updateExpiryDate: this.state.updateExpiryDate,
          membershipupdatedBy: this.state.membershipupdatedBy,
          membershipupdatedOn: this.state.membershipupdatedOn,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert(data.response);

        this.setState(this.reset);
        this.componentDidMount();
      })
      .catch((err) => alert(err));
  }

  handleDelete(i) {
    this.setState({
      shopArray: this.state.shopArray.filter((tags, index) => index !== i),
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Container fluid>
        <>
          <div className="headingStyle">
            <h3>EXTEND MEMBERSHIP EXPIRY DATE</h3>
          </div>

          <Row>
            <Col xs={12}>
              <div
                style={{
                  backgroundColor: "#fafafa",

                  margin: 1 + "%",
                }}
              >
                <h3 style={{ textAlign: "center" }}> Add Shops</h3>
              </div>

              {this.state.showImportExcel ? (
                <>
                  <Form onSubmit={this.fetchMerchant}>
                    <Row
                      style={{ marginTop: 20 + "px", marginBottom: 20 + "px" }}
                    >
                      <Col xs={2}>
                        <Form.Label>Import Excel File</Form.Label>
                      </Col>
                      <Col xs={2}>
                        <input
                          type="file"
                          required
                          onChange={this.fileHandler.bind(this)}
                          style={{ padding: "10px" }}
                        />
                      </Col>
                      <Col>
                        <Button type="submit">Submit</Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              ) : (
                <>
                  <Form onSubmit={this.updateMerchantExpiryDate}>
                    <Row
                      style={{ marginTop: 20 + "px", marginBottom: 20 + "px" }}
                    >
                      <Col xs={2}>
                        <Form.Label>UPDATE EXPIRY DATE</Form.Label>
                      </Col>
                      <Col xs={2}>
                        <Form.Control
                          type="date"
                          name="updateExpiryDate"
                          onChange={this.onChange}
                          value={this.state.updateExpiryDate}
                          required
                        ></Form.Control>
                      </Col>

                      <Col>
                        <Button type="submit">UPDATE</Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
              {this.state.shopArray.length > 0 && (
                <>
                  <Table
                    style={{
                      width: 100 + "%",
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid",
                    }}
                    size="medium"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          S No
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          User ID
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Shop Name
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Name
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Phone Number
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Pincode
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          MemberShip ID
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Updated On
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Expiry
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.shopArray.map((item, index) => (
                        <>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              {index + 1}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {item.userId10Digit}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.shopName}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.userName}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.userPhoneNumber}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.userPincode}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.membershipId}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.membershipupdatedOn}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {item.membershipExpiry}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              <Button onClick={() => this.handleDelete(index)}>
                                <HighlightOffIcon color="secondary" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </Col>
          </Row>
        </>
      </Container>
    );
  }
}
ExtendMembership.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExtendMembership);
