module.exports = Object.freeze({
  SALOON: {
    key: ["Saloon", "Beauty Parlour"],
    fields: [
      "category",
      "subCategory",
      "productName",
      "productBrand",
      "duration",
      "productPrice",
      "productMrp",
      "productQty",
      "productType",
      "productImageArray"
    ],
  },

  KIRANA: {
    key: ["Supermarket", "Provisional Store"],
    fields: [
      "group",
      "productImageArray",
      "category",
      "subCategory",
      "unitOfMeasurment",
      "productMrp",
      "productName",
      "productBrand",
      "vegNonVeg",
      "productPrice",
      "productQty",
      "inventoryQty",
    ],
  },

  SWEETS_AND_SNACKS: {
    key: "Sweets and Snacks",
    fields: [
      "group",
      "category",
      "subCategory",
      "unitOfMeasurment",
      "productMrp",
      "productName",
      "productImageArray",
      "productBrand",
      "inventoryQty",
      "vegNonVeg",
      "productPrice",
      "productQty",
    ],
  },
  TEXTILE: {
    key: ["Textile"],
    fields: [
      "subCategory",
      "category",
      "productMrp",
      "productName",
      "productBrand",
      "productQty",
      "productType",
      "productPrice",
      "productImageArray"

    ],
  },
  RESTAURANT: {
    key: ["Restaurant", "Juice Shop", "Bakery"],
    fields: [
      "subCategory",
      "category",
      "productMrp",
      "productQty",
      "productName",
      "vegNonVeg",
      "productPrice",
      "vegNonVeg",
      "productImageArray"

    ],
  },
  MOBILE: {
    key: ["Mobile"],
    fields: [
      "subCategory",
      "category",
      "productName",
      "productBrand",
      "productPrice",
      "productMrp",
      "productQty",
      "productType",
      "inventoryQty",
      "productBrand",
      "productImageArray"

    ],
  },
  OTHERS: {
    key: [],
    fields: [
      "productName",
      "productBrand",
      "productPrice",
      "productMrp",
      "productQty",
      "productType",
      "productImageArray"

    ],
  },
});
