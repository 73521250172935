import React, { useEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Badge from "react-bootstrap/Badge";

import { saveAs } from "file-saver";
import CreateQrCode from "../../website/createQrCode";
import { pdf } from "@react-pdf/renderer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

let initialState = {
  websitePaymentId: "",
  websitePaymentStatus: "",
  websiteExpiryOn: "",
};
export default function Website({ data, isLoading }) {
  const [state, setState] = useState(initialState);
  const [modalShow, setModalShow] = useState(false); // Modal State
  const generateQrCode = async () => {
    // using callback function to get QR code
  };
  useEffect(() => {}, [data]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <>
      {isLoading ? (
        <>
          <div style={{ alignItems: "center" }} className="shopCardRow">
            <div className="shopCardColumn">
              {data.websiteStatus == "draft" ? (
                <>
                  <div id="col" className="centerFlex">
                    <button
                      onClick={() => setModalShow(true)}
                      id="success"
                      className="actionButton"
                    >
                      Make Website Live
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <ul className="horizontalList">
                    <li className="horizontalListItem">
                      <h6>URL</h6>

                      <span
                        onClick={() => window.open(data.websiteUrl, "_blank")}
                      >
                        {data.websiteUrl}
                      </span>
                    </li>
                    <li className="horizontalListItem">
                      <h6>QR Code</h6>
                      <button
                        onClick={async () => {
                          let blob = await pdf(
                            <CreateQrCode shopdetails={data} />
                          ).toBlob();
                          return saveAs(blob, data.shopName);
                        }}
                        className="infoButton"
                      >
                        Download
                      </button>
                    </li>

                    <li className="horizontalListItem">
                      <h6>Status</h6>

                      {data.websiteStatus == "live" ? (
                        <>
                          <Badge
                            style={{ color: "#fff" }}
                            pill
                            variant="success"
                          >
                            Live
                          </Badge>
                        </>
                      ) : data.websiteStatus == "expired" ? (
                        <>
                          <Badge
                            style={{ color: "#fff" }}
                            pill
                            variant="danger"
                          >
                            Expired
                          </Badge>
                        </>
                      ) : (
                        <>
                          <Badge
                            style={{ color: "#fff" }}
                            pill
                            variant="danger"
                          >
                            Invalid Status
                          </Badge>
                        </>
                      )}
                    </li>
                    <li id="rowDirection" className="horizontalListItem">
                      <div className="equalSpacingButton">
                        <EditIcon />
                      </div>
                      <div className="equalSpacingButton">
                        <DeleteIcon />
                      </div>
                    </li>
                  </ul>
                </>
              )}
            </div>
          </div>
        </>
      ) : null}

      <Modal
        className="modal-order-coupon"
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Website</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  Website Username
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    value={state.websiteUsername}
                    name="websiteUsername"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  Payment Id
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    value={state.websitePaymentId}
                    name="websitePaymentId"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  Payment Type
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    as="select"
                    value={state.websitePaymentStatus}
                    name="websitePaymentStatus"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="free">Free</option>
                    <option value="paid">Paid</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  Notes
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    as="textarea"
                    value={state.websiteNotes}
                    name="websiteNotes"
                    onChange={(e) => handleChange(e)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  Website Expiry Date
                </Form.Label>
                <Col sm={6}>
                  <Form.Control
                    type="date"
                    value={state.websiteExpiryOn}
                    name="websiteExpiryOn"
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Col sm={10}>
                  <Button style={{ float: "right" }} type="submit">
                    {/* {updateActivatedWeb ? "Update Website" : "Activate Website"} */}
                    Activate Website
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
