import React, { useEffect, useState, useContext } from "react";
import { Container, Col, Button, Row, Tabs, Tab } from "react-bootstrap";
import config from "../config";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PersonIcon from "@material-ui/icons/Person";
import TodayIcon from "@material-ui/icons/Today";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useLocation, useParams } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { store } from "../provider";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    fontSize: "12px",
  },
});

export default function DeliveryShopDetails() {
  const classes = useStyles();
  const [orderList, setOrderList] = useState([]);
  const [orderStatus, setOrderStatus] = useState({});
  const context = useContext(store);

  const [open, setOpen] = useState(false);
  const [activity, setActivity] = useState([]);
  const { shopId } = useParams();
  const [key, setKey] = useState("home");
  const [customerList, setCustomerList] = useState([]);
  const [todayOrder, setTodayOrder] = useState(0);
  useEffect(async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/trackShopOrders`, {
        method: "POST",
        body: JSON.stringify({
          shopId: shopId,
          dateRangeVal: "15",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setTodayOrder(data.todayOrders);
        setActivity(data.activityLog);
        setOrderStatus(data.orderStatus);
        setCustomerList(data.customers);
      })
      .catch((err) => alert(err));
  }, []);
  const shopDetails = context.state.currentDelivery;

  return (
    <>
      <Container>
        <div style={{ width: "100%", height: "100px" }}></div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "1.4rem",
          }}
        >
          <h2
            style={{
              textTransform: "capitalize",
              marginBottom: 0,
              fontSize: "2.4rem",
              fontWeight: "600",
              color: "#4f4e4e",
            }}
          >
            Delivery
          </h2>
        </div>

        <div style={{ marginBottom: "20px" }} className="flexCard">
          <Row>
            <Col sm={6}>
              <div className="imageContainer">
                <img
                  src={
                    "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/images/offers/default_offer_image.png"
                  }
                  className="imageBox"
                ></img>
              </div>
            </Col>
            <Col style={{ paddingTop: "27px" }} sm={6}>
              <h1 className="shopProfile">{shopDetails.shopName}</h1>
              <span className="shopProfile">{shopDetails.shopAddress}</span>
              <div className="vertical-space-5"></div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Merchant Name :{" "}
                </label>
                <p style={{ width: "40%" }}>{shopDetails.merchantName}</p>
              </div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Phone Number :{" "}
                </label>
                <p style={{ width: "40%" }}>{shopDetails.shopPhoneNumber}</p>
              </div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Shop Pincode :
                </label>
                <p style={{ width: "40%" }}>{shopDetails.userPincode}</p>
              </div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Shop Type :
                </label>
                <p style={{ width: "40%" }}>{shopDetails.shopType}</p>
              </div>
              <div className="shopLabeltext">
                <label style={{ color: "#89959b", width: "30%" }}>
                  Shop Id :
                </label>
                <p style={{ width: "40%" }}>
                  {shopDetails.shopIduserId10Digit}
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Col style={{ height: "340px" }} sm="6">
            <div className="flexCard">
              <div style={{ display: "inline-block", width: "100%" }}>
                <div className="orderHeader" style={{ textAlign: "center" }}>
                  <b>Customer Details</b>
                </div>
              </div>
              <div style={{ flex: 1, flexDirection: "column" }}>
                <div className="shopLabeltext">
                  <label
                    style={{
                      color: "#89959b",
                      width: "30%",
                      paddingLeft: "10px",
                    }}
                  >
                    Order Id :
                  </label>
                  <p style={{ width: "40%" }}>{shopDetails.orderId}</p>
                </div>
                <div className="shopLabeltext">
                  <label
                    style={{
                      color: "#89959b",
                      width: "30%",
                      paddingLeft: "10px",
                    }}
                  >
                    Billed Date:
                  </label>
                  <p style={{ width: "40%" }}>{shopDetails.billingDate}</p>
                </div>
                <div className="shopLabeltext">
                  <label
                    style={{
                      color: "#89959b",
                      width: "30%",
                      paddingLeft: "10px",
                    }}
                  >
                    Customer Name :
                  </label>
                  <p style={{ width: "40%" }}>{shopDetails.customerName}</p>
                </div>
                <div className="shopLabeltext">
                  <label
                    style={{
                      color: "#89959b",
                      width: "30%",
                      paddingLeft: "10px",
                    }}
                  >
                    Phone Number :
                  </label>
                  <p style={{ width: "40%" }}>
                    {shopDetails.customerPhoneNumber}
                  </p>
                </div>
                <div className="shopLabeltext">
                  <label
                    style={{
                      color: "#89959b",
                      width: "30%",
                      paddingLeft: "10px",
                    }}
                  >
                    Customer ID :
                  </label>
                  <p style={{ width: "40%" }}>{shopDetails.customerId}</p>
                </div>
                <div className="shopLabeltext">
                  <label
                    style={{
                      color: "#89959b",
                      width: "30%",
                      paddingLeft: "10px",
                    }}
                  >
                    Delivery Method :
                  </label>
                  <p style={{ width: "40%" }}>{shopDetails.deliveryMethod}</p>
                </div>

                <div className="shopLabeltext">
                  <label
                    style={{
                      color: "#89959b",
                      width: "30%",
                      paddingLeft: "10px",
                    }}
                  >
                    Delivery Address :
                  </label>
                  <p style={{ width: "40%" }}>
                    {Object.keys(shopDetails.deliveryAddress).map.length >
                      0 && (
                      <address>
                        {shopDetails.deliveryAddress.address}
                        {shopDetails.query}
                      </address>
                    )}
                  </p>
                </div>
                <div className="shopLabeltext">
                  <label
                    style={{
                      color: "#89959b",
                      width: "30%",
                      paddingLeft: "10px",
                    }}
                  >
                    Slot Time :
                  </label>
                  <p style={{ width: "40%" }}>
                    {Object.keys(shopDetails.slot).map.length > 0 ? (
                      <span>
                        {shopDetails.slot.startTime} -{" "}
                        {shopDetails.slot.endTime} ({shopDetails.slot.day})
                      </span>
                    ) : (
                      "-"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="6">
            <div className="flexCard">
              <div className="flexCardHeader">
                <label className="flexCardHeaderlabel">Cart Details</label>
              </div>
              <TableContainer
                style={{ height: "340px", overflow: "auto" }}
                component={Paper}
              >
                <Table
                  size="small"
                  className={classes.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>S No</TableCell>
                      <TableCell className={classes.tableCell}>
                        Product Name
                      </TableCell>
                      <TableCell className={classes.tableCell}>Price</TableCell>
                      <TableCell className={classes.tableCell}>
                        Inventory Qty
                      </TableCell>
                      <TableCell className={classes.tableCell}>MRP</TableCell>

                      <TableCell className={classes.tableCell}>
                        Order Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(shopDetails.cart).map(
                      ([key, value], index) => (
                        <TableRow key={value.orderId}>
                          <TableCell
                            className={classes.tableCell}
                            component="th"
                            scope="row"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            component="th"
                            scope="row"
                          >
                            {value.productName}
                            {value.unitOfMeasurment}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {value.productPrice}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {" "}
                            {value.inventoryQty}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {value.productMrp}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {" "}
                            {value.status}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: "30px", marginBottom: "100px" }}>
          <div style={{ width: "100%" }}>
            <Tabs
              id="controlled-tab-example"
              className="deliveryTab"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="home" title="Overview">
                <Row
                  style={{
                    float: "left",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "100%", display: "inline" }}>
                    <span className="subHeadertab">Dashboard</span>
                  </div>
                  <Col className="splitCol">
                    <div className="cardBodyRow">
                      <Row>
                        <Col className="cardBodyDelivery">
                          <p style={{ fontSize: "14px", display: "inline" }}>
                            Total Orders
                          </p>
                          <h3>{parseInt(orderStatus.complete)}</h3>
                        </Col>
                        <Col className="cardBodyDeliveryIcon">
                          <ShoppingCartIcon />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col className="splitCol">
                    <div className="cardBodyRow">
                      <Row>
                        <Col className="cardBodyDelivery">
                          <p style={{ fontSize: "14px", display: "inline" }}>
                            Total Customers
                          </p>
                          <h3>{customerList.length}</h3>
                        </Col>
                        <Col className="cardBodyDeliveryIcon">
                          <PersonIcon />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col className="splitCol">
                    <div className="cardBodyRow">
                      <Row>
                        <Col className="cardBodyDelivery">
                          <p style={{ fontSize: "14px", display: "inline" }}>
                            Today Orders
                          </p>
                          <h3>{todayOrder}</h3>
                        </Col>
                        <Col className="cardBodyDeliveryIcon">
                          <TodayIcon />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row
                  style={{
                    float: "left",
                    width: "100%",
                    marginTop: "20px",
                    display: "flex",
                  }}
                >
                  <div style={{ width: "100%", display: "inline" }}>
                    <span className="subHeadertab">Status</span>
                  </div>
                  <Col className="splitCol">
                    <div className="cardBodyRow">
                      <Row>
                        <Col className="cardBodyDelivery">
                          <p style={{ fontSize: "14px", display: "inline" }}>
                            Delivered
                          </p>
                          <h3>
                            <b>{orderStatus.complete || 0}</b>
                          </h3>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col className="splitCol">
                    <div className="cardBodyRow">
                      <Row>
                        <Col className="cardBodyDelivery">
                          <p style={{ fontSize: "14px", display: "inline" }}>
                            Order on Draft
                          </p>
                          <h3>
                            <b>{orderStatus.readyForDelivery || 0}</b>
                          </h3>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col className="splitCol">
                    <div className="cardBodyRow">
                      <Row>
                        <Col className="cardBodyDelivery">
                          <p style={{ fontSize: "14px", display: "inline" }}>
                            Rejected
                          </p>
                          <h3>
                            <b>{orderStatus.rejected || 0}</b>
                          </h3>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col className="splitCol">
                    <div className="cardBodyRow">
                      <Row>
                        <Col className="cardBodyDelivery">
                          <p style={{ fontSize: "14px", display: "inline" }}>
                            Ready for Pick Up
                          </p>
                          <h3>
                            <b>{orderStatus.readyForPickup || 0}</b>
                          </h3>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col className="splitCol">
                    <div className="cardBodyRow">
                      <Row>
                        <Col className="cardBodyDelivery">
                          <p style={{ fontSize: "14px", display: "inline" }}>
                            Delivery Accepted{" "}
                          </p>
                          <h3>
                            <b>{orderStatus.startPackaging || 0}</b>
                          </h3>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Tab>

              <Tab eventKey="profile" title="Customer">
                <Row
                  style={{
                    float: "left",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "100%", display: "inline" }}>
                    <span className="subHeadertab">Customer List</span>
                  </div>

                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>S No</TableCell>
                          <TableCell>Customer Name</TableCell>
                          <TableCell>Customer ID</TableCell>

                          <TableCell>Phone Number</TableCell>
                          <TableCell>Pincode</TableCell>
                          <TableCell>Total Orders</TableCell>

                          <TableCell>Amount Paid</TableCell>
                          <TableCell>Past Order</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {customerList.map((row, index) => (
                          <>
                            <TableRow key={row.orderId}>
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>

                              <TableCell> {row.customerName}</TableCell>
                              <TableCell> {row.customerId}</TableCell>
                              <TableCell>{row.customerPhoneNumber}</TableCell>
                              <TableCell> {row.customerPincode}</TableCell>
                              <TableCell> {row.visit}</TableCell>
                              <TableCell>
                                {"₹ "}
                                {row.totalAmount}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => setOpen(!open)}
                                >
                                  {open ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={8}
                              >
                                <Collapse
                                  in={open}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box margin={1}>
                                    <Typography
                                      variant="h6"
                                      gutterBottom
                                      component="div"
                                    >
                                      History
                                    </Typography>
                                    <Table size="small" aria-label="purchases">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Order Id</TableCell>
                                          <TableCell>Billed Date</TableCell>
                                          <TableCell>Delivery Method</TableCell>
                                          <TableCell>
                                            Delivery Address
                                          </TableCell>
                                          <TableCell>
                                            Amount Paid {" ₹ "}
                                          </TableCell>
                                          <TableCell>Total Products</TableCell>

                                          <TableCell colSpan={2}>
                                            Slot
                                          </TableCell>
                                          <TableCell>Order Status</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {row.purchaseDetails.map(
                                          (historyRow) => (
                                            <TableRow key={historyRow.orderId}>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {historyRow.orderId}
                                              </TableCell>
                                              <TableCell>
                                                {historyRow.billedDate}
                                              </TableCell>
                                              <TableCell>
                                                {historyRow.deliveryMethod}
                                              </TableCell>
                                              {Object.keys(
                                                historyRow.deliveryAddress
                                              ).length > 0 ? (
                                                <TableCell>
                                                  {
                                                    historyRow.deliveryAddress
                                                      .address
                                                  }
                                                  {
                                                    historyRow.deliveryAddress
                                                      .query
                                                  }
                                                </TableCell>
                                              ) : (
                                                <TableCell>{" - "}</TableCell>
                                              )}
                                              <TableCell>
                                                {" ₹ "} {historyRow.totalAmount}
                                              </TableCell>
                                              <TableCell>
                                                {
                                                  Object.keys(
                                                    historyRow.cartDetails
                                                  ).map.length
                                                }
                                              </TableCell>
                                              {Object.keys(historyRow.slot)
                                                .length > 0 ? (
                                                <TableCell colSpan={2}>
                                                  {historyRow.slot.startTime}
                                                  {" - "}
                                                  {historyRow.slot.endTime}
                                                  <b> {historyRow.slot.day}</b>
                                                </TableCell>
                                              ) : (
                                                <TableCell colSpan={2}>
                                                  {" - "}
                                                </TableCell>
                                              )}
                                              <TableCell>
                                                {historyRow.status}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Row>
              </Tab>
              <Tab eventKey="contact" title="Activity Logs">
                <Row
                  style={{
                    float: "left",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "100%", display: "inline" }}>
                    <span className="subHeadertab">Activity Logs</span>
                  </div>

                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>S No</TableCell>
                          <TableCell>Order ID</TableCell>
                          <TableCell>Billed Date</TableCell>
                          <TableCell>Customer Name</TableCell>
                          <TableCell>Customer ID</TableCell>

                          <TableCell>Phone Number</TableCell>
                          <TableCell>Pincode</TableCell>

                          <TableCell>Amount Paid</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {activity.map((row, index) => (
                          <>
                            <TableRow key={row.orderId}>
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell> {row.orderId}</TableCell>
                              <TableCell> {row.billingDate}</TableCell>
                              <TableCell> {row.customerName}</TableCell>
                              <TableCell> {row.customerId}</TableCell>
                              <TableCell>{row.customerPhoneNumber}</TableCell>
                              <TableCell> {row.customerPincode}</TableCell>

                              <TableCell>
                                {"₹ "}
                                {row.totalAmount}
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Row>
              </Tab>
            </Tabs>
          </div>
        </Row>
      </Container>
    </>
  );
}
