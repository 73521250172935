import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Typography } from "@material-ui/core";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import config from "../config";
export default class SetLoyaltyRatio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // initialize loyalty object
      loyalty: {
        loyaltyPoints: "",
        loyaltyAmount: "",
        loyaltyRatio: "",
        loyaltyupdatedBy: "",
        loyaltyupdatedOn: new Date().toISOString().substr(0, 10),
      },
      userName: "",
      userPhoneNumber: "",
      membershipArray: [],
      userPincode: "",
      shopName: "",
      shopAddress: "",
      PreviousloyaltyPoints: "",
      PreviousloyaltyAmount: "",
      PreviousloyaltyRatio: "",
      showData: true,
      loyaltyPackage: "",
      userId10Digit: "",
    };
    this.getShop = this.getShop.bind(this);
    this.setLoyaltyRatio = this.setLoyaltyRatio.bind(this);
    this.setLoyaltyUpdate = this.setLoyaltyUpdate.bind(this);
    this.setLoyaltyRatioOnchange = this.setLoyaltyRatioOnchange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.reset = this.state;
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((data) => {
        let user = { email: data.email, ...data.attributes };

        this.setState({
          ...this.state,
          loyalty: {
            ...this.state.loyalty,
            loyaltyupdatedBy: user.phone_number, // get current authenticated user
          },
        });
      })

      .catch((err) => console.log(err));
  }
  setLoyaltyUpdate(e) {
    e.preventDefault();
    fetch(`${config.ApiGateway}/updateloyaltyratio`, {
      method: "POST",
      body: JSON.stringify(this.state.loyalty),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        alert("loyalty ratio updated");
        this.setState(this.reset);
      })
      .catch((err) => console.log(err));
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  setLoyaltyRatioOnchange(e) {
    this.setState(
      {
        ...this.state,
        loyalty: {
          ...this.state.loyalty,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        this.setLoyaltyRatio(); // calls find loyalty ration function
      }
    );
  }
  setLoyaltyRatio() {
    if (
      this.state.loyalty.loyaltyPoints !== null &&
      this.state.loyalty.loyaltyAmount === ""
    ) {
      // Ignores if loyalty amount is empty || Null
      return alert("enter loyalty amount");
    }

    // If loyalty amount and points is valid
    if (
      parseFloat(this.state.loyalty.loyaltyPoints) != null &&
      parseFloat(this.state.loyalty.loyaltyAmount) != null
    ) {
      // to find ratio -- Amount / points

      let loyaltyratio =
        parseFloat(this.state.loyalty.loyaltyAmount) /
        parseFloat(this.state.loyalty.loyaltyPoints);

      this.setState({
        ...this.state,
        loyalty: {
          ...this.state.loyalty,
          loyaltyRatio: loyaltyratio,
        },
      });
    }
  }

  getShop(e) {
    // To get shop using shop Id
    e.preventDefault();
    fetch(
      `${config.ApiGateway}/getshop`,

      {
        method: "POST",

        body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        data.Items.forEach((elements) => {
          this.setState({
            ...this.state,

            loyalty: {
              ...this.state.loyalty,

              userId: elements.userId,
            },

            PreviousloyaltyPoints: elements.loyaltyPoints, // Default -  Null state
            PreviousloyaltyAmount: elements.loyaltyAmount,
            PreviousloyaltyRatio: elements.loyaltyRatio,
            // Past Loyalty Information
            loyaltyPackage: elements.loyaltyPackage,
            lastUpdatedby: elements.loyaltyupdatedBy,
            lastUpdatedOn: elements.loyaltyupdatedOn,
            // Shop Details
            userName: elements.userName,
            userPhoneNumber: elements.userPhoneNumber,
            shopId10Digit: elements.userId10Digit,
            userPincode: elements.userPincode,
            shopName: elements.shopName,
            shopAddress: elements.shopAddress,
            showData: false,
          });
        });
      })
      .catch((err) => {
        this.setState({
          message: "NO SHOPS FOUND",
        });
      });
  }

  render() {
    return (
      <Container fluid>
        {this.state.showData ? (
          <>
            <div
              style={{
                // backgroundColor: "#fafafa",
                width: 100 + "%",
                margin: 1 + "%",
              }}
            >
              <h3 style={{ textAlign: "center" }}>LOYALTY PACKAGE UPDATE</h3>
            </div>

            <Form onSubmit={this.getShop}>
              <Row>
                <Col md={{ span: 4, offset: 4 }}>
                  <Form.Label className="asterik">
                    ENTER YOUR SHOP ID
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="userId10Digit"
                    placeholder="SHOP ID"
                    onChange={this.onChange}
                    value={this.state.userId10Digit}
                    required
                  />

                  <Button
                    type="submit"
                    style={{
                      float: "right",
                      marginTop: 2 + "%",
                    }}
                  >
                    SUBMIT
                  </Button>

                  <h4 style={{ marginTop: 5 + "%" }}>{this.state.message}</h4>
                </Col>
              </Row>
            </Form>
          </>
        ) : (
          <Row>
            {this.state.loyaltyPackage === "yes" ? (
              <>
                <Col md={{ offset: 1 }}>
                  <Typography
                    variant="h3"
                    style={{ textAlign: "center" }}
                    gutterBottom
                  >
                    SHOP DETAILS
                  </Typography>
                  <Form onSubmit={this.setLoyaltyUpdate}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        SHOP NAME
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="shopName"
                          onChange={this.reviewOnchange}
                          value={this.state.shopName}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        MERCHANT NAME
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="userName"
                          onChange={this.reviewOnchange}
                          value={this.state.userName}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        SHOP ID
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="userId10Digit"
                          value={this.state.userId10Digit}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        PHONE NUMBER
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="userPhoneNumber"
                          value={this.state.userPhoneNumber}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        PINCODE
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="pincode"
                          onChange={this.reviewOnchange}
                          value={this.state.userPincode}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        SET LOYALTY AMOUNT
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="loyaltyAmount"
                          onChange={this.setLoyaltyRatioOnchange} // Onchange to find loyalty ratio
                          value={this.state.loyalty.loyaltyAmount}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        SET LOYALTY POINTS
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="loyaltyPoints"
                          onChange={this.setLoyaltyRatioOnchange} // Onchange to find loyalty ratio
                          value={this.state.loyalty.loyaltyPoints}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        LOYALTY RATIO
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="loyaltyRatio"
                          onChange={this.setLoyaltyRatio}
                          value={this.state.loyalty.loyaltyRatio}
                          disabled={true}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Button
                      style={{ float: "right", marginTop: 1 + "%" }}
                      type="submit"
                    >
                      UPDATE LOYALTY RATIO
                    </Button>
                  </Form>
                </Col>
                <Col>
                  <Typography
                    variant="h3"
                    style={{ textAlign: "center" }}
                    gutterBottom
                  >
                    CURRENT LOYALTY RATIO
                  </Typography>
                  <Table
                    style={{
                      width: 50 + "%",
                      marginLeft: 25 + "%",
                      marginRight: 25 + "%",

                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid",
                    }}
                    size="medium"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: "center" }}>
                          S NO
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          LOYALTY AMOUNT
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          LOYALTY POINT
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          LOYALTY RATIO
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ textAlign: "center" }}>1</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {this.state.PreviousloyaltyAmount}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {this.state.PreviousloyaltyPoints}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {this.state.PreviousloyaltyRatio}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Col>

                <Row style={{ width: 100 + "%", marginTop: 5 + "%" }}>
                  <Col md={{ offset: 1 }}>
                    <h3>LAST LOYALTY PACKAGE MODIFIED BY</h3>
                    <h5>{this.state.lastUpdatedby}</h5>
                  </Col>
                  <Col>
                    <h3>LAST LOYALTY PACKAGE MODIFIED ON</h3>
                    <h5>{this.state.lastUpdatedOn}</h5>
                  </Col>
                </Row>
              </>
            ) : (
              <h1 style={{ textAlign: "center" }}>
                LOYALTY PACKAGE NOT ACTIVATED
              </h1>
            )}
          </Row>
        )}
      </Container>
    );
  }
}
