import React, { Component } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from "@material-ui/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend,
  ResponsiveContainer
} from "recharts";
import { Link } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import config from "../../config";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide"
  },
  table: {
    minWidth: 340
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5
  }
});
class GenderRatio extends Component {
  constructor(props) {
    super(props);
    this.handleFunction = this.handleFunction.bind(this);
    this.state = {
      totalRatio: [],
      showData: false,
      showRatioList: [],
      page: 0,
      Customerpage: 0,
      rowsPerPage: 5,
      rowsPerPageCustomer: 10,
      customerDetails: [],
      showCustomerList: false,
      filterBy: "All",
      mergedArea: [],
      filterPincode: "All",
      exportData: [],
      headers: [],
      orderBy: false
    };
    this.handleFunction = this.handleFunction.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleCustomerPage = this.handleCustomerPage.bind(this);
    this.handleCustomerPagePerRow = this.handleCustomerPagePerRow.bind(this);
    this.handleFilterByCustomer = this.handleFilterByCustomer.bind(this);
    this.getCustomer = this.getCustomer.bind(this);
    this.onChange = this.onChange.bind(this);
    this.csvLink = React.createRef();
    this.sortFunction = this.sortFunction.bind(this);
  }
  sortFunction(orderBy) {
    var value = !orderBy;
    var sort = this.state.showRatioList.sort((a, b) =>
      this.state.orderBy
        ? b.totalUsers - a.totalUsers
        : a.totalUsers - b.totalUsers
    );
    this.setState({
      shopkeeperDetails: sort,
      orderBy: !this.state.orderBy
    });
  }
  handleFilterByCustomer(e) {
    let value = e.target.value;
    this.setState({
      filterBy: value,
      Customerpage: 0
    });
  }
  export() {
    let tempArr = [];
    this.state.customerDetails
      .filter(items =>
        this.state.filterBy !== "All"
          ? items.userGender === this.state.filterBy
          : items.userGender === "Male" || items.userGender === "Female"
      )
      .filter(headers =>
        this.state.filterPincode === "All"
          ? headers
          : headers.userPincode === this.state.filterPincode.slice(-6)
      )
      .map(elements => tempArr.push(elements));

    const headers = [
      { label: "Customer ID", key: "userId10Digit" },
      { label: "Pincode", key: "userPincode" },
      { label: "Name", key: "userName" },
      { label: "Phone Number", key: "userPhoneNumber" },
      { label: "Place", key: "place" },

      { label: "Gender", key: "userGender" }
    ];

    this.setState(
      {
        headers: headers,
        exportData: tempArr
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  onChange(e) {
    let value = e.target.value;
    this.setState(
      {
        filterPincode: e.target.value
      },
      () => {
        this.getCustomer(value.slice(-6));
      }
    );
  }
  getCustomer(pincodeArgs) {
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                { term: { userType: "customer" } },
                {
                  exists: {
                    field: "userGender"
                  }
                }
              ],

              filter: [
                {
                  term: {
                    userPincode: pincodeArgs
                  }
                }
              ]
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      }),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, data2]) => {
        let tempdata = data.hits.hits.map(element => element._source);

        var mergePincodeAndArea = tempdata.map(items => {
          let count = data2.Items.find(
            elements => items.userPincode === elements.pincode
          );
          let appendObject = {};
          appendObject.userName = items.userName;
          appendObject.userPhoneNumber = items.userPhoneNumber;
          appendObject.userAddress = items.userAddress;
          appendObject.userId10Digit = items.userId10Digit;
          appendObject.userGender = items.userGender;
          if (count !== undefined) {
            appendObject.userPincode = items.userPincode;
            appendObject.place = count.place;
          } else {
            appendObject.userPincode = items.userPincode;
            appendObject.place = "Not Found";
          }

          return appendObject;
        });
        this.setState({
          customerDetails: mergePincodeAndArea,
          showCustomerList: true
        });
      })
      .catch(err => console.log(err));
  }
  //   getCustomerDetails(userid) {
  //     this.props.getCustomer(userid);
  //   }
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleCustomerPage = (event, page) => {
    this.setState({ Customerpage: page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  handleCustomerPagePerRow = event => {
    this.setState({ rowsPerPageCustomer: event.target.value });
  };
  componentDidMount() {
    this.handleFunction(this.props.genderArr);
  }
  handleFunction(request) {
    let pincode = [...new Set(this.props.pincode)];

    Promise.all([
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      }),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, area]) => {
        var mergePincodeAndArea = request[0].map(items => {
          let count = data.Items.find(
            elements => items.pincode === elements.pincode
          );
          let appendObject = {};
          if (count !== undefined) {
            appendObject = Object.assign({}, count, items);
          } else {
            appendObject = Object.assign(
              {},
              { pincode: items.pincode, place: "Not Found" },
              items
            );
          }
          appendObject.totalUsers =
            parseInt(items.Male) + parseInt(items.Female);

          return appendObject;
        });

        mergePincodeAndArea = mergePincodeAndArea.sort(
          (a, b) => b.totalUsers - a.totalUsers
        );
        let showRatioList = mergePincodeAndArea;
        let top = mergePincodeAndArea.slice(0, 5);

        var mergePincodeAndArea = pincode.map(items => {
          let count = area.Items.find(elements => items === elements.pincode);
          let appendObject = {};
          count !== undefined
            ? (appendObject = Object.assign({}, count))
            : (appendObject = Object.assign(
                {},
                {
                  pincode: items,
                  place: "NOT FOUND"
                }
              ));

          return appendObject;
        });

        let mergedArea = mergePincodeAndArea.map((
          elements //Pincode dropdown
        ) => (
          <option key={elements.pincode}>
            {elements.place.toUpperCase()}
            {" - "}
            {elements.pincode}
          </option>
        ));

        this.setState({
          loadPage: true,
          showRatioList: showRatioList,
          totalRatio: request[1],
          top5Pincode: top,
          mergedArea: mergedArea
        });
      })
      .catch(err => console.log(err));
  }

  render() {
    const { classes } = this.props;
    const Gender = ["Male", "Female"];
    return (
      <>
        {this.state.loadPage && (
          <>
            <Row style={{ width: 100 + "%", justifyContent: "center" }}>
              <b style={{ fontSize: 32 }}>Gender Ratio</b>
            </Row>
            <Row style={{ width: 100 + "%", justifyContent: "center" }}>
              <Col xs={{ span: 5 }} className="division">
                <b>Male Users</b>
                <h3 style={{ textAlign: "center" }}>
                  {this.state.totalRatio[0].totalMale}
                </h3>
              </Col>
              <Col xs={{ span: 5, offset: 1 }} className="division">
                <b>
                  <span style={{ textAlign: "center" }}>Female Users </span>
                </b>
                <h3 style={{ textAlign: "center" }}>
                  {this.state.totalRatio[0].totalFemale}
                </h3>
              </Col>
            </Row>
            <Row style={{ width: 100 + "%" }}>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  margin: 1 + "%"
                }}
              >
                <h3 style={{ textAlign: "center" }}> Top 5 Pincode </h3>
              </div>
              <Col xs={{ span: 6 }}>
                <div style={{ width: 100 + "%" }}>
                  <h4 style={{ textAlign: "center" }}>Graph</h4>
                </div>
                <ResponsiveContainer aspect={2}>
                  <BarChart
                    width={600}
                    height={300}
                    data={this.state.top5Pincode}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />

                    <XAxis
                      tickLine={true}
                      xAxisId={0}
                      dy={0}
                      dx={-0}
                      label={{
                        value: "",
                        angle: 0,
                        position: "bottom"
                      }}
                      interval={0}
                      dataKey="pincode"
                      tickLine={false}
                      tick={{ fontSize: 9, angle: 0 }}
                    />

                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Male" fill="#8884d8">
                      <LabelList dataKey="Male" position="top" />
                    </Bar>
                    <Bar dataKey="Female" fill="red">
                      <LabelList dataKey="Female" position="top" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Col>
              <Col xs={{ span: 6 }}>
                {/* <Row >
                  <Col xs={{ span: 3, offset: 3 }}> */}
                <Button
                  style={{ float: "right", marginBottom: 3 + "%" }}
                  onClick={() => {
                    this.sortFunction(this.state.orderBy);
                  }}
                >
                  Sort
                </Button>
                {/* </Col>
                </Row> */}
                <Table
                  className="division"
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid"
                  }}
                  size="medium"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>S NO</TableCell>
                      <TableCell className={classes.tableCell}>
                        Pincode
                      </TableCell>
                      <TableCell className={classes.tableCell}>Place</TableCell>
                      <TableCell className={classes.tableCell}>Users</TableCell>
                      <TableCell className={classes.tableCell}>Male</TableCell>
                      <TableCell className={classes.tableCell}>
                        Female
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.showRatioList
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map((elements, index) => (
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            {index + 1}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.pincode}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.place}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.totalUsers}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.Male}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.Female}
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => this.getCustomer(elements.pincode)}
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={this.state.showRatioList.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Col>
            </Row>
            {this.state.showCustomerList && (
              <>
                {" "}
                <Row>
                  <Col
                    style={{ textAlign: "center" }}
                    className="division"
                    xs={{ span: 12 }}
                  >
                    <h3> Customers List</h3>
                    <Row>
                      <Col xs={{ span: 3 }}>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <strong> Filter By</strong>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            as="select"
                            name="filterBy"
                            value={this.state.filterBy}
                            onChange={this.handleFilterByCustomer}
                          >
                            <option>select gender </option>
                            <option value="Male">Male </option>
                            <option value="Female">Female </option>
                            <option value="All">All </option>
                          </Form.Control>
                        </InputGroup>
                      </Col>
                      {/* <Col xs={{ span: 4, offset: 1 }}>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <strong> Pincode</strong>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            as="select"
                            name="filterPincode"
                            value={this.state.filterPincode}
                            onChange={this.onChange}
                          >
                            <option value="">Select Pincode</option>

                            {this.state.mergedArea}
                          </Form.Control>
                        </InputGroup>
                      </Col> */}
                      <Col xs={{ span: 3, offset: 1 }}>
                        <Button
                          onClick={() => {
                            this.export();
                          }}
                        >
                          Download
                        </Button>
                        <CSVLink
                          enclosingCharacter={`'`}
                          data={this.state.exportData}
                          headers={this.state.headers}
                          ref={this.csvLink}
                          target="_blank"
                        />
                      </Col>
                    </Row>
                    <Table
                      style={{
                        width: 100 + "%",
                        border: 0.5 + "px",
                        borderColor: "#ced4da",
                        borderStyle: "solid"
                      }}
                      className={classes.table}
                      size="small"
                    >
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              S NO
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Customer ID
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Customer Name
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Phone Number
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Pincode
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Place
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              GENDER
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.customerDetails
                            .filter(items =>
                              this.state.filterBy !== "All"
                                ? items.userGender === this.state.filterBy
                                : items.userGender === "Male" ||
                                  items.userGender === "Female"
                            )
                            .filter(headers =>
                              this.state.filterPincode === "All"
                                ? headers
                                : headers.userPincode ===
                                  this.state.filterPincode.slice(-6)
                            )
                            .slice(
                              this.state.Customerpage *
                                this.state.rowsPerPageCustomer,
                              this.state.Customerpage *
                                this.state.rowsPerPageCustomer +
                                this.state.rowsPerPageCustomer
                            )
                            .map((elements, index) => (
                              <TableRow key={elements.userId10Digit}>
                                <TableCell className={classes.tableCell}>
                                  {index + 1}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Link
                                    to={{
                                      pathname: "/customer",
                                      search: `?userid=${elements.userId10Digit}`
                                    }}
                                  >
                                    {" "}
                                    {elements.userId10Digit}{" "}
                                  </Link>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {elements.userName}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {elements.userPhoneNumber}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {elements.userPincode}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {elements.place}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {elements.userGender}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </>
                    </Table>
                    <TablePagination
                      component="div"
                      count={this.state.customerDetails.length}
                      rowsPerPage={this.state.rowsPerPageCustomer}
                      page={this.state.Customerpage}
                      onChangePage={this.handleCustomerPage}
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      onChangeRowsPerPage={this.handleCustomerPagePerRow}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </>
    );
  }
}
GenderRatio.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GenderRatio);
