import { Row, Col, Container, Form, Button } from "react-bootstrap";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import React, { Component } from "react";
import config from "../config";
import { Link } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import { Auth } from "aws-amplify";
export default class CreateItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getShopdetailsToggle: true,
      userId10Digit: "",
      item: {
        offerStartDate: "",
        offerStartTime: "",
        offerEndDate: "",
        offerEndTime: "",
        offerDetails: "",

        offerOriginalPrice: "",
        offerNewPrice: "",
        offerDiscount: "",
        offerCategory: "",
        offerSubCategory: "",
        offerTag: "",
        offerSentStatus: "False",
        offerType: "item",
        shopPincode: "",
        shopId: "",
        shopType: "",
        offerAddedDate: new Date().toISOString().substring(0, 10),
        custumerAddedOfferToFav: {},
        imageFile: [],
        mobileBrand: "",
        mobileDimensions: "",
        mobileColor: "",
        mobileWeight: "",
        mobileModel: "",
        mobileFrontFacingCamera: "",

        mobileRearFacingCamera: "",
        mobileRAM: "",
        mobileOS: "",
        mobileOtherDetails: "",
        mobileServiceDetails: "",
        mobileMemory: "",
        mobileMemoryExtendable: "",
        reviewedBy: "",
        productSize: {},
      },
      productSize: {},
      buttonDisabled: false,
      imagePreviewUrl: [],
      itemArray: [],
      itemCategory: [],
      itemSubCategory: [],
      brandList: [],
      OsList: [],
      imageSize: "",
      remainingItem: "",
      membershipExpiry: "",
      currentMembershipType: "",
      userInformation: {},
      textileBrandList: [],
    };
    this.getShop = this.getShop.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.itemOnchange = this.itemOnchange.bind(this);
    this.handleimage = this.handleimage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.priceChange = this.priceChange.bind(this);
    this.discountCalculation = this.discountCalculation.bind(this);
    this.createoffersubmit = this.createoffersubmit.bind(this);
    this.getItemDropdown = this.getItemDropdown.bind(this);
    this.getCategoryDropdown = this.getCategoryDropdown.bind(this);
    this.reset = this.state;
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }
  handleCheckBox(value) {
    const size = value.value;
    if (Object.keys(this.state.item.productSize).includes(size)) {
      delete this.state.item.productSize[size];
      this.setState({
        item: { ...this.state.item, productSize: this.state.item.productSize },
      });
    } else {
      this.setState(() => ({
        item: {
          ...this.state.item,
          productSize: {
            ...this.state.item.productSize,
            [size]: {
              ...this.state.item.productSize[size],
              details: value.details,
            },
          },
        },
      }));
    }
  }
  componentDidUpdate() {
    window.onpopstate = (e) => {
      e.preventDefault();
      if (window.confirm("are you want to leave the page")) {
        window.location.assign("/");
      } else {
        window.location.assign("/createitem");
      }
    };
  }
  async componentDidMount() {
    try {
      const response = await fetch(`${config.json.URL}/imagesize.json`);
      const size = await response.json();
      this.setState({
        imageSize: size.BANNER,
      });
    } catch (error) {
      console.log(error);
    }
    await Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then((data) => {
      let user = { email: data.email, ...data.attributes };
      this.setState({
        item: {
          ...this.state.item,

          reviewedBy: user.phone_number,
        },
      });
    });
    try {
      const response = await fetch(`${config.json.URL}/fields.json`);
      const json = await response.json();

      this.setState({
        itemArray: json,
      });
    } catch (error) {
      console.log(error);
    }
  }
  getShop(e) {
    e.preventDefault();

    Promise.all([
      fetch(
        `${config.ApiGateway}/getCouponLimit`,

        {
          method: "POST",

          body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.setState(
          {
            ...this.state,
            item: {
              ...this.state.item,
              shopId: data["shopId"],
              shopPincode: data["userPincode"],
              shopType: data["shopType"],
            },
            userInformation: data,
            remainingItem: data["remainingItem"],
            currentMembershipType: data["membershipType"],
            membershipExpiry: data["membershipExpiry"],
          },
          () => {
            this.getCategoryDropdown(data["shopType"]);
          }
        );
      })
      .catch((err) => console.log(err));
  }
  getCategoryDropdown(shopCategory) {
    var category;
    var brandList = [];
    var OsList = [];
    let textileBrands = [];
    if (shopCategory === "Textile") {
      category = this.state.itemArray.category.map((item) => {
        return <option>{item.value}</option>;
      });

      textileBrands = this.state.itemArray.textileBrands.map((item) => {
        return <option>{item.value}</option>;
      });
    } else if (shopCategory === "Mobile") {
      this.state.itemArray.operatingSystem.forEach((elements) =>
        OsList.push(<option value={elements.value}>{elements.value}</option>)
      );
      category = ["mobile", "accessories"].map((item) => {
        return <option value={item}>{item}</option>;
      });

      this.state.itemArray.brand.forEach((elements) =>
        brandList.push(<option value={elements.value}>{elements.value}</option>)
      );
      this.setState({
        item: { ...this.state.item, offerCategory: "mobile" },
      });
    }

    this.setState({
      textileBrandList: textileBrands,
      itemCategory: category,

      OsList: OsList,
      brandList: brandList,

      getShopdetailsToggle: false,
    });
  }
  getItemDropdown(e) {
    this.setState({
      ...this.state,
      item: { ...this.state.item, offerCategory: e.target.value },
    });
    var tempCategory = e.target.value;
    var tempSubcategory = [];
    this.state.itemArray.subCategory.map((elements) => {
      if (tempCategory === elements.category) {
        tempSubcategory.push(<option>{elements.value}</option>);
      }
      return tempSubcategory;
    });
    this.setState({
      itemSubCategory: tempSubcategory,
    });
  }

  createoffersubmit(e) {
    e.preventDefault();

    if (this.state.item.shopType === "Mobile") {
      var numReg = /^\d+$/;
      if (this.state.item.mobileBrand == "Select a Brand")
        return alert("Please select a brand");
      if (this.state.item.mobileModel == "") return alert("Please Enter Model");
      if (this.state.item.offerCategory === "mobile") {
        if (this.state.item.mobileRAM == "") return alert("Please Enter RAM");
        if (this.state.item.mobileOS == "Select OS")
          return alert("Please select Operating System");
        if (this.state.item.mobileMemory == "")
          return alert("Please Enter Memory");
      }

      if (this.state.item.offerOriginalPrice == "")
        return alert("Please Enter Original Price");
      if (
        parseInt(this.state.item.offerOriginalPrice) <
        parseInt(this.state.item.offerNewPrice)
      )
        return alert("Please enter new price less than original price");
      if (numReg.test(this.state.item.offerOriginalPrice) == false)
        return alert("Please enter valid original price");
      if (
        this.state.item.offerNewPrice != "" &&
        numReg.test(this.state.item.offerNewPrice) == false
      )
        return alert("Please enter valid offer price");
    }

    Promise.all([
      fetch(
        `${config.ApiGateway}/createoffer`,

        {
          method: "POST",

          body: JSON.stringify(this.state.item),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert(data.status);
        this.setState(this.reset);
        this.componentDidMount();
      })
      .catch((err) => console.log(err));
  }

  removeImage() {
    this.setState({
      ...this.state,
      item: {
        ...this.state.item,
        imageFile: [],
      },
      imagePreviewUrl: [],
    });
  }
  handleimage(event) {
    this.setState({
      selectedFile: event.target.files[0],
    });

    let reader = new FileReader();
    let imageFormData = new FormData();
    imageFormData.append("photo", event.target.files[0]);

    console.log(
      event.target.files[0].size,
      Math.round(event.target.files[0].size / 1024) + " KB ",
      "get image size   --------------------"
    );

    Resizer.imageFileResizer(
      event.target.files[0], //is the file of the new image that can now be uploaded...
      350, // is the maxWidth of the  new image
      400, // is the maxHeight of the  new image
      "JPEG", // is the compressFormat of the  new image
      100, // is the quality of the  new image
      0, // is the rotatoion of the  new image
      (uri) => {
        this.setState({
          ...this.state,
          item: {
            ...this.state.item,

            imageFile: uri.replace(/^data:image\/\w+;base64,/, ""),
          },
        });
        this.setState({
          imagePreviewUrl: uri,
        });
      },
      "base64" // is the output type of the new image
    );

    // reader.onloadend = () => {
    //   this.setState({
    //     ...this.state,
    //     item: {
    //       ...this.state.item,

    //       imageFile: reader.result.replace(/^data:image\/\w+;base64,/, "")
    //     }
    //   });
    //   this.setState({
    //     imagePreviewUrl: reader.result
    //   });
    // };

    // reader.readAsDataURL(event.target.files[0]);
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  itemOnchange(e) {
    this.setState({
      ...this.state,
      item: {
        ...this.state.item,
        [e.target.name]: e.target.value,
      },
    });
  }
  priceChange(e) {
    this.setState(
      {
        ...this.state,
        item: {
          ...this.state.item,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        this.discountCalculation();
      }
    );
  }
  discountCalculation() {
    if (
      parseFloat(this.state.item.offerOriginalPrice) <=
      parseFloat(this.state.item.offerNewPrice)
    ) {
      this.setState({
        ...this.state,
        item: { ...this.state.item, offerNewPrice: "" },
      });
      return alert("OFFER PRICE SHOULD BE LESS THAN ORIGINAL PRICE");
    }
    if (
      this.state.item.offerOriginalPrice !== "" &&
      this.state.item.offerNewPrice !== ""
    ) {
      var originalPrice = parseFloat(this.state.item.offerOriginalPrice);
      var newPrice = parseFloat(this.state.item.offerNewPrice);
      var discount;

      discount = ((originalPrice - newPrice) / originalPrice) * 100;
      discount = Math.round(discount);
      this.setState({
        ...this.state,
        item: {
          ...this.state.item,
          offerDiscount: discount,
        },
      });
    } else {
      this.setState({
        ...this.state,
        item: {
          ...this.state.item,
          offerDiscount: 0,
        },
      });
    }
  }

  render() {
    return (
      <Container>
        <h3 style={{ textAlign: "center" }}>CREATE COLLECTION</h3>

        {/* <Row>
          <Col> */}
        {this.state.getShopdetailsToggle ? (
          <>
            <Form onSubmit={this.getShop}>
              <Col md={{ span: 8, offset: 1 }}>
                <Form.Group as={Row}>
                  <Form.Label className="asterik" column sm="4">
                    ENTER SHOP ID
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      onChange={this.handleChange}
                      name="userId10Digit"
                      pattern="[0-9]{8,10}"
                      value={this.state.userId10Digit}
                      required
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Button style={{ float: "right" }} type="submit">
                  GET SHOP
                </Button>
              </Col>
            </Form>
          </>
        ) : (
          <>
            {this.state.remainingItem <= 0 ? (
              <>
                <div className="listDetails">
                  <strong>REMAINING COLLECTION COUNT</strong>
                  <h5>{this.state.remainingoffer}</h5>
                </div>
                <div className="listDetails">
                  <strong>CURRENT MEMBERSHIP</strong>
                  <h5>{this.state.currentMembershipType}</h5>
                </div>
                <div className="listDetails">
                  <strong>MEMBERSHIP EXPIRY DATE</strong>
                  <h5>{this.state.membershipExpiry}</h5>
                </div>

                <div style={{ paddingLeft: 56 + "%" }}>
                  <Link to="/membership">
                    <Button>ACTIVATE MEMBERSHIP</Button>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <Row>
                  <Col md={{ span: 8 }}>
                    <Form onSubmit={this.createoffersubmit}>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          SHOP ID
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            type="text"
                            onChange={this.itemOnchange}
                            name="shopId"
                            disabled={true}
                            value={this.state.item.shopId}
                          ></Form.Control>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          USERNAME
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            type="text"
                            onChange={this.itemOnchange}
                            name="shopId"
                            disabled={true}
                            value={this.state.userInformation["userName"]}
                          ></Form.Control>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          PHONE NUMBER
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            type="text"
                            onChange={this.itemOnchange}
                            name="shopId"
                            disabled={true}
                            value={
                              this.state.userInformation["userPhoneNumber"]
                            }
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          SHOP NAME
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            type="text"
                            onChange={this.itemOnchange}
                            name="shopId"
                            disabled={true}
                            value={this.state.userInformation["shopName"]}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          SHOP ADDRESS
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            type="text"
                            onChange={this.itemOnchange}
                            name="shopId"
                            disabled={true}
                            value={this.state.userInformation["shopAddress"]}
                          ></Form.Control>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          SHOP TYPE
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            type="text"
                            onChange={this.itemOnchange}
                            name="shopType"
                            disabled={true}
                            value={this.state.item.shopType}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          SHOP PINCODE
                        </Form.Label>
                        <Col md={{ span: 9 }}>
                          <Form.Control
                            type="text"
                            onChange={this.itemOnchange}
                            name="shopPincode"
                            disabled={true}
                            value={this.state.item.shopPincode}
                          ></Form.Control>
                        </Col>
                      </Form.Group>

                      {this.state.item.shopType === "Textile" && (
                        <>
                          <Form.Group as={Row}>
                            <Form.Label className="asterik" column sm="3">
                              CATEGORY
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                as="select"
                                onChange={this.getItemDropdown}
                                name="offerCategory"
                                value={this.state.item.offerCategory}
                                required
                              >
                                <option value="">{"SELECT  CATEGORY"}</option>
                                {this.state.itemCategory}
                              </Form.Control>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label className="asterik" column sm="3">
                              SUB CATEGORY
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                as="select"
                                onChange={this.itemOnchange}
                                name="offerSubCategory"
                                value={this.state.item.offerSubCategory}
                                required
                              >
                                <option value="">
                                  {"SELECT SUB CATEGORY"}
                                </option>
                                {this.state.itemSubCategory}
                              </Form.Control>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              BRAND
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                as="select"
                                onChange={this.itemOnchange}
                                name="productBrand"
                                value={this.state.item.productBrand}
                              >
                                <option value="">{"Select Brand"}</option>

                                {this.state.textileBrandList}
                              </Form.Control>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              SIZE
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <div
                                key={`custom-inline-checkbox}`}
                                className="mb-3"
                              >
                                {this.state.itemArray.collectionSize.map(
                                  (elements) => {
                                    return (
                                      <>
                                        <Form.Check
                                          custom
                                          inline
                                          // checked={Object.keys(
                                          //   this.state.productSize
                                          // ).includes(elements.value)}
                                          label={`${elements.value}  ( ${elements.details} )`}
                                          onChange={() =>
                                            this.handleCheckBox(elements)
                                          }
                                          type={"checkbox"}
                                          id={`custom-inline-checkbox-${elements.value}`}
                                        />
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row}>
                            <Form.Label className="asterik" column sm="3">
                              TAG
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                type="text"
                                onChange={this.itemOnchange}
                                name="offerTag"
                                value={this.state.item.offerTag}
                                required
                              ></Form.Control>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label className="asterik" column sm="3">
                              PRODUCT NAME
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                as="textarea"
                                rows="6"
                                onChange={this.itemOnchange}
                                name="offerDetails"
                                value={this.state.item.offerDetails}
                                required
                              ></Form.Control>
                            </Col>
                          </Form.Group>
                        </>
                      )}

                      {this.state.item.shopType === "Mobile" && (
                        <>
                          <Form.Group as={Row}>
                            <Form.Label className="asterik" column sm="3">
                              CATEGORY
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                as="select"
                                onChange={this.itemOnchange}
                                name="offerCategory"
                                value={this.state.item.offerCategory}
                                required
                              >
                                {this.state.itemCategory}
                              </Form.Control>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label className="asterik" column sm="3">
                              Brand
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                as="select"
                                onChange={this.itemOnchange}
                                name="mobileBrand"
                                value={this.state.item.mobileBrand}
                                required
                              >
                                <option value="">{"Select Brand"}</option>
                                {this.state.brandList}
                              </Form.Control>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row}>
                            <Form.Label className="asterik" column sm="3">
                              Mobile Model
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                type="text"
                                onChange={this.itemOnchange}
                                name="mobileModel"
                                value={this.state.item.mobileModel}
                                required
                              ></Form.Control>
                            </Col>
                          </Form.Group>
                          {this.state.item.offerCategory === "mobile" && (
                            <>
                              <Form.Group as={Row}>
                                <Form.Label className="asterik" column sm="3">
                                  Memory
                                </Form.Label>
                                <Col md={{ span: 9 }}>
                                  <Form.Control
                                    type="text"
                                    onChange={this.itemOnchange}
                                    name="mobileMemory"
                                    value={this.state.item.mobileMemory}
                                    required
                                  ></Form.Control>
                                </Col>
                              </Form.Group>

                              <Form.Group as={Row}>
                                <Form.Label column sm="3">
                                  Memory Extendable
                                </Form.Label>
                                <Col md={{ span: 9 }}>
                                  <Form.Control
                                    type="text"
                                    onChange={this.itemOnchange}
                                    name="mobileMemoryExtendable"
                                    value={
                                      this.state.item.mobileMemoryExtendable
                                    }
                                  ></Form.Control>
                                </Col>
                              </Form.Group>

                              <Form.Group as={Row}>
                                <Form.Label column sm="3">
                                  Dimension
                                </Form.Label>
                                <Col md={{ span: 9 }}>
                                  <Form.Control
                                    type="text"
                                    onChange={this.itemOnchange}
                                    name="mobileDimensions"
                                    placeholder="15.7 * 0.9"
                                    value={this.state.item.mobileDimensions}
                                  ></Form.Control>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row}>
                                <Form.Label className="asterik" column sm="3">
                                  RAM
                                </Form.Label>
                                <Col md={{ span: 3 }}>
                                  <Form.Control
                                    type="text"
                                    onChange={this.itemOnchange}
                                    name="mobileRAM"
                                    value={this.state.item.mobileRAM}
                                    required
                                  ></Form.Control>
                                </Col>
                                <Form.Label className="asterik" column sm="3">
                                  OS
                                </Form.Label>
                                <Col md={{ span: 3 }}>
                                  <Form.Control
                                    as="select"
                                    onChange={this.itemOnchange}
                                    name="mobileOS"
                                    value={this.state.item.mobileOS}
                                    required
                                  >
                                    <option value="">{"Select OS"}</option>
                                    {this.state.OsList}
                                  </Form.Control>
                                </Col>
                              </Form.Group>

                              <Form.Group as={Row}>
                                <Form.Label column sm="3">
                                  Front Camera Pixel
                                </Form.Label>
                                <Col md={{ span: 3 }}>
                                  <Form.Control
                                    type="text"
                                    onChange={this.itemOnchange}
                                    pattern="[0-9]{1,10}"
                                    name="mobileFrontFacingCamera"
                                    value={
                                      this.state.item.mobileFrontFacingCamera
                                    }
                                    placeholder="Mega Pixel"
                                  ></Form.Control>
                                </Col>
                                <Form.Label column sm="3">
                                  Rear Camera pixel
                                </Form.Label>
                                <Col md={{ span: 3 }}>
                                  <Form.Control
                                    type="text"
                                    pattern="[0-9]{1,10}"
                                    onChange={this.itemOnchange}
                                    name="mobileRearFacingCamera"
                                    value={
                                      this.state.item.mobileRearFacingCamera
                                    }
                                    placeholder="Mega Pixel"
                                  ></Form.Control>
                                </Col>
                              </Form.Group>
                            </>
                          )}

                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              Mobile Color
                            </Form.Label>
                            <Col md={{ span: 3 }}>
                              <Form.Control
                                type="text"
                                onChange={this.itemOnchange}
                                name="mobileColor"
                                value={this.state.item.mobileColor}
                              ></Form.Control>
                            </Col>
                            <Form.Label column sm="3">
                              Mobile Weight
                            </Form.Label>
                            <Col md={{ span: 3 }}>
                              <Form.Control
                                type="text"
                                pattern="[0-9]{1,10}"
                                onChange={this.itemOnchange}
                                name="mobileWeight"
                                value={this.state.item.mobileWeight}
                                placeholder="In Gms"
                              ></Form.Control>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              Other Details
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                as="textarea"
                                rows="6"
                                onChange={this.itemOnchange}
                                name="mobileOtherDetails"
                                value={this.state.item.mobileOtherDetails}
                              ></Form.Control>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row}>
                            <Form.Label column sm="3">
                              Service Details
                            </Form.Label>
                            <Col md={{ span: 9 }}>
                              <Form.Control
                                as="textarea"
                                rows="6"
                                onChange={this.itemOnchange}
                                name="mobileServiceDetails"
                                value={this.state.item.mobileServiceDetails}
                              ></Form.Control>
                            </Col>
                          </Form.Group>
                        </>
                      )}

                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          ORIGINAL PRICE
                        </Form.Label>
                        <Col md={{ span: 3 }}>
                          <Form.Control
                            type="text"
                            onChange={this.priceChange}
                            pattern="[0-9]{1,10}"
                            name="offerOriginalPrice"
                            value={this.state.item.offerOriginalPrice}
                            placeholder="₹ 0.00"
                          ></Form.Control>
                        </Col>
                        <Form.Label column sm="3">
                          OFFER PRICE
                        </Form.Label>
                        <Col md={{ span: 3 }}>
                          <Form.Control
                            type="text"
                            pattern="[0-9]{1,10}"
                            onChange={this.priceChange}
                            name="offerNewPrice"
                            value={this.state.item.offerNewPrice}
                            placeholder="₹ 0.00"
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          OFFER START DATE
                        </Form.Label>
                        <Col md={{ span: 3 }}>
                          <Form.Control
                            type="date"
                            onChange={this.itemOnchange}
                            name="offerStartDate"
                            value={this.state.item.offerStartDate}
                            min={this.state.item.offerAddedDate}
                          ></Form.Control>
                        </Col>
                        <Form.Label column sm="3">
                          OFFER START TIME
                        </Form.Label>
                        <Col md={{ span: 3 }}>
                          <Form.Control
                            type="time"
                            onChange={this.itemOnchange}
                            name="offerStartTime"
                            value={this.state.item.offerStartTime}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          OFFER END DATE
                        </Form.Label>
                        <Col md={{ span: 3 }}>
                          <Form.Control
                            type="date"
                            onChange={this.itemOnchange}
                            name="offerEndDate"
                            value={this.state.item.offerEndDate}
                            min={
                              this.state.item.offerStartDate ||
                              this.state.item.offerAddedDate
                            }
                          ></Form.Control>
                        </Col>
                        <Form.Label column sm="3">
                          OFFER END TIME
                        </Form.Label>
                        <Col md={{ span: 3 }}>
                          <Form.Control
                            type="time"
                            onChange={this.itemOnchange}
                            name="offerEndTime"
                            value={this.state.item.offerEndTime}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label classname="asterik" column sm="3">
                          UPLOAD IMAGE
                        </Form.Label>
                        <Col>
                          <input
                            type="file"
                            onChange={this.handleimage}
                          ></input>
                        </Col>
                      </Form.Group>

                      <Button
                        disabled={this.state.buttonDisabled}
                        type="submit"
                        color="primary"
                      >
                        SUBMIT
                      </Button>
                    </Form>
                  </Col>

                  <Col>
                    {this.state.imagePreviewUrl.length !== 0 && (
                      <div>
                        <img
                          src={this.state.imagePreviewUrl}
                          alt={this.state.imagePreviewUrl}
                          width="300"
                          height="180"
                        ></img>
                        <Button
                          onClick={this.removeImage}
                          variant="outline-light"
                        >
                          <CancelOutlinedIcon
                            style={{
                              fontSize: 20,
                              backgroundColor: "red",
                              position: "absolute",
                            }}
                          />
                        </Button>
                      </div>
                    )}
                    <div
                      style={{
                        display: "inline",
                        width: 100 + "px",
                      }}
                    >
                      <strong>REMAINING OFFER COUNT</strong>
                      <h5>{this.state.remainingItem}</h5>
                    </div>

                    <strong>CURRENT MEMBERSHIP</strong>
                    <h5>{this.state.currentMembershipType}</h5>

                    <strong>MEMBERSHIP EXPIRY DATE</strong>
                    <h5>{this.state.membershipExpiry}</h5>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Container>
    );
  }
}
