import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Handler from "../handler/handler";
import CloseIcon from "@material-ui/icons/Close";
import { UpdateProfileAPI } from "../handler/apiHandler";
let initialState = {
  imageFile: [],
};
let initialImageDisplay = { selectedImage: null, fileName: null };
export default function ProfileDetails({ profile }) {
  let fileUploadHandler = useRef(); // Create Ref for File input html
  const [state, setState] = useState(initialState);
  const [dropdown, setDropdown] = useState([]);
  const [editProfileState, setEditProfileState] = useState(false);
  const [findHandler] = Handler(); // Handler class
  const [imageUrl, setImageUrl] = useState(initialImageDisplay);

  useEffect(() => {
    async function loadProfile() {
      let shopTypeOptions = await findHandler("GET_SHOPTYPE");
      await getProfileData();
      setDropdown(shopTypeOptions);
    }
    loadProfile();
  }, [profile]);

  const getProfileData = async () => {
    setState((prevState) => ({
      ...prevState,
      shopAddress: profile.shopAddress,
      shopName: profile.shopName,
      shopImageLocation:
        typeof profile.shopImageLocation == "string" &&
        profile.shopImageLocation != "removed"
          ? profile.shopImageLocation
          : process.env.REACT_APP_DEFAULT_IMAGE,
      shopType: profile.shopType,
      userPincode: profile.userPincode,
    }));
  };

  const handleChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const updateShopProfile = async () => {
    // To Update shop profile

    // validation

    let input = state;
    if (input.shopName === "") return alert("Enter Shop Name");

    if (input.shopAddress === "") return alert("Enter Shop Address");
    if (input.shopType === "") return alert("Enter Shop Type");
    if (input.userPincode === "") return alert("Enter Pincode");
    if (!(/^\d+$/.test(input.userPincode) && input.userPincode.length == 6))
      return alert("Enter valid pincode");
    input.userId = profile.userId;
    // update function handler
    await UpdateProfileAPI(input).then((data) => {
      if (data.status) {
        alert("profile updated");
        return resetAll();
      }
      alert("Profile update failed");
    });
  };

  const resetAll = () => {
    setState(initialState);
    setEditProfileState(false);
  };

  return (
    <>
      <div className="fieldBox">
        <>
          <p className="subHeadingBlock">Profile</p>

          <div className="fieldWrap">
            <label id={editProfileState && "required"}>Shop Name</label>
            <input
              className={editProfileState ? "flexInputBox" : "flexInputView"}
              value={state.shopName}
              onChange={(e) => {
                handleChange("shopName", e.target.value);
              }}
              required
            ></input>
          </div>
          <div className="fieldWrap">
            <label id={editProfileState && "required"}>Shop Type</label>
            <select
              className={editProfileState ? "flexInputBox" : "flexInputView"}
              value={state.shopType}
              onChange={(e) => {
                handleChange("shopType", e.target.value);
              }}
              required
            >
              {dropdown}
            </select>
          </div>
          <div className="fieldWrap">
            <label id={editProfileState && "required"}>Address</label>
            <input
              className={editProfileState ? "flexInputBox" : "flexInputView"}
              value={state.shopAddress}
              onChange={(e) => {
                handleChange("shopAddress", e.target.value);
              }}
              required
            ></input>
          </div>
          <div className="fieldWrap">
            <label id={editProfileState && "required"}>Pincode</label>
            <input
              className={editProfileState ? "flexInputBox" : "flexInputView"}
              value={state.userPincode}
              onChange={(e) => {
                handleChange("userPincode", e.target.value);
              }}
              pattern="[0-9]{6}"
              title="Enter valid pincode"
              required
            ></input>
          </div>

          {editProfileState && (
            <>
              {Boolean(imageUrl.selectedImage) ||
              Boolean(state.shopImageLocation) ? (
                <div
                  style={{ flexBasis: "100%", margin: "10px 0" }}
                  className="centerFlex"
                >
                  <div className="flexBox1">
                    <img
                      src={state.shopImageLocation || imageUrl.selectedImage}
                    ></img>
                  </div>
                  <div className="flexBox2">
                    <span>{imageUrl.fileName || state.shopImageLocation}</span>
                  </div>

                  <div className="flexBox1">
                    <CloseIcon
                      onClick={() => {
                        setImageUrl({
                          selectedImage: null,
                          fileName: "",
                        });
                        handleChange("imageFile", []);
                        handleChange("shopImageLocation", "");
                      }}
                      style={{ color: "#333" }}
                    />
                  </div>
                </div>
              ) : (
                <div id="fullWidth" className="fieldWrap">
                  <div
                    onClick={() => fileUploadHandler.current.click()}
                    className="uploadImageBox"
                  >
                    <span className="centerText">Upload Image</span>
                    <input
                      ref={fileUploadHandler}
                      onChange={async (e) => {
                        let imagePath = await findHandler(
                          "IMAGE_HANDLER",
                          e.target.files[0]
                        );

                        handleChange("imageFile", imagePath.base64);
                        setImageUrl({
                          selectedImage:
                            imagePath.imageURI == "undefined"
                              ? null
                              : imagePath.imageURI,
                          fileName: imagePath.fileName,
                        });
                      }}
                      style={{ display: "none" }}
                      type="file"
                    ></input>
                  </div>
                </div>
              )}
            </>
          )}
        </>
        <div className="flexBoxEnd">
          {editProfileState ? (
            <>
              <div className="updateButton">
                <button
                  onClick={() => updateShopProfile()}
                  type="submit"
                  id="success"
                  className="actionButton"
                >
                  Save
                </button>
              </div>
              <div className="updateButton">
                <button
                  type="button"
                  onClick={() => {
                    setEditProfileState(false);
                  }}
                  id="danger"
                  className="actionButton"
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="updateButton">
                <button
                  onClick={() => {
                    setEditProfileState(true);
                  }}
                  id="success"
                  className="actionButton"
                >
                  Edit
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
