import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import TotalRegistration from "./customerAnalysis/totalRegistration";
import CustomerPincode from "./customerAnalysis/customerPincode";
import TopCustomer from "./customerAnalysis/topCustomers";
import CustomerLikedOffers from "./customerAnalysis/customerLikedOffers";
import CustomerCoupons from "./customerAnalysis/customerCoupons";
import CustomerTransaction from "./customerAnalysis/customerTransaction";
import CustomerTable from "./customerAnalysis/customerTable";
import CustomerRefferals from "./customerAnalysis/customerRefferals";
import CustomerPincodeStats from "./customerAnalysis/customerPincodeStats";
import CustomerPincodeInformation from "./customerAnalysis/customerPincodeInformation";
import CustomerDetails from "./customerAnalysis/customerDetails";
import CustomerGhoshakCoins from "./customerAnalysis/customerGhoshakCoins";
import GenderRatio from "./customerAnalysis/genderRatio";
import CustomerAnniversary from "./customerAnalysis/customerAnniversary";
import CustomerEmail from "./customerAnalysis/customerEmail";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import config from "../config";
import CustomerDOB from "./customerAnalysis/customerDOB";
function getStepContent(
  item,
  args,
  argsArray,
  functioncall1,
  fetchCustomer,
  functioncall2,
  hideTable,
  pincode
) {
  switch (item) {
    case "totalRegistration":
      return <TotalRegistration args={args} />;
    case "customerPincode":
      return <CustomerPincode pincode={args} />;
    case "topCustomers":
      return <TopCustomer customer={argsArray} getCustomer={functioncall2} />;
    case "customerPincodeInformation":
      return (
        <CustomerPincodeInformation
          likedOffers={argsArray}
          renderTable={functioncall1}
        />
      );
    case "customerDetails":
      return <CustomerDetails user={args} userId={args} />;
    case "customerLikedOffers":
      return (
        <CustomerLikedOffers
          likedOffers={argsArray}
          renderTable={functioncall1}
        />
      );
    case "customerCoupons":
      return <CustomerCoupons coupons={argsArray} />;
    case "customerTransaction":
      return (
        <CustomerTransaction
          transactionDetails={argsArray}
          renderTable={functioncall1}
        />
      );
    case "customerTable":
      return (
        <CustomerTable
          details={argsArray}
          getCustomer={functioncall2}
          hideTable={hideTable}
        />
      );
    case "customerRefferals":
      return (
        <CustomerRefferals refer={argsArray} renderTable={functioncall1} />
      );
    case "customerPincodeStats":
      return <CustomerPincodeStats pincodeStats={argsArray} />;
    case "customerGhoshakCoins":
      return <CustomerGhoshakCoins customerDetails={fetchCustomer} />;
    case "genderRatio":
      return <GenderRatio genderArr={argsArray} pincode={pincode} />;
    case "DOB":
      return <CustomerDOB />;
    case "anniversary":
      return <CustomerAnniversary />;
    case "email":
      return <CustomerEmail />;
    default:
      return <></>;
  }
}

export default class CustomerIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classRender: "",
      args: "",
      argsArray: [],
      customerId: [],
      showPage: false,
      tempReferals: [],
      userdetails: [],
      store1: "",
      hideTable: [],
      genderRatioObj: {},
    };

    this.customertotalRegistration = this.customertotalRegistration.bind(this);
    this.customerPincode = this.customerPincode.bind(this);
    this.customerCoupons = this.customerCoupons.bind(this);
    this.topCustomer = this.topCustomer.bind(this);
    this.likedOffers = this.likedOffers.bind(this);
    this.customerTransaction = this.customerTransaction.bind(this);
    this.renderFunction = this.renderFunction.bind(this);
    this.customerRefferals = this.customerRefferals.bind(this);
    this.customerPincodeStats = this.customerPincodeStats.bind(this);
    this.customerDetails = this.customerDetails.bind(this);
    this.customerPincodeInformation = this.customerPincodeInformation.bind(
      this
    );
    this.customerGhoshakCoins = this.customerGhoshakCoins.bind(this);
    this.getCustomer = this.getCustomer.bind(this);
    this.genderRatioFn = this.genderRatioFn.bind(this);
    this.customerDobFn = this.customerDobFn.bind(this);
    this.customerAnniversaryFn = this.customerAnniversaryFn.bind(this);

    this.findCustomerNames = this.findCustomerNames.bind(this);
    this.findCustomerNamesByReferalCode = this.findCustomerNamesByReferalCode.bind(
      this
    );
    this.customerEmail = this.customerEmail.bind(this);

    this.pincodeCount = this.pincodeCount.bind(this);
  }

  getCustomer(userId) {
    this.setState({
      classRender: "customerDetails",
      args: userId,
    });
  }

  async componentDidMount() {
    let searchParams = new URLSearchParams(window.location.search);

    if (
      searchParams.get("userid") !== null &&
      searchParams.get("userid") !== undefined
    ) {
      await this.customerDetails(searchParams.get("userid"));
    }
    // await this.userDetails();

    this.setState({
      showPage: true,
    });
  }
  userDetails() {
    var tempdata = [];
    var tempCustomerid = [];
    var referals = [];
    var users = [];
    var userPincode = [];

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [{ match: { userType: "Customer" } }],
              must_not: {
                bool: {
                  must: [{ term: { userPincode: "111111" } }],
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map((element) => element._source);
        let genderRatioObj = {};
        tempdata.forEach((elements) => {
          if (
            elements.referredBy !== "None" &&
            elements.referredBy !== undefined
          ) {
            referals.push(elements.referredBy);
          }

          if (elements.userGender !== undefined) {
            if (genderRatioObj[elements.userPincode] === undefined) {
              genderRatioObj[elements.userPincode] = {};
            }
            if (
              genderRatioObj[elements.userPincode][elements.userGender] ===
              undefined
            ) {
              genderRatioObj[elements.userPincode][elements.userGender] = 1;
            } else {
              genderRatioObj[elements.userPincode][elements.userGender] += 1;
            }
            if (genderRatioObj[elements.userGender] === undefined) {
              genderRatioObj[elements.userGender] = 1;
            } else {
              genderRatioObj[elements.userGender] += 1;
            }
          }

          tempCustomerid.push(elements.userId);
          users.push(elements);
          userPincode.push(elements.userPincode);
        });
        console.log(genderRatioObj);
        return this.setState({
          customerId: tempCustomerid,
          // showPage: true,
          tempReferals: referals,
          userdetails: users,
          tempPincode: userPincode,
          genderRatioObj: genderRatioObj,
        });
      });
  }
  customerDobFn() {
    this.setState({
      classRender: "DOB",
    });
  }
  customerAnniversaryFn() {
    this.setState({
      classRender: "anniversary",
    });
  }
  customerEmail() {
    this.setState({
      classRender: "email",
    });
  }
  genderRatioFn() {
    let tempArr = [[], []];
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                { match: { userType: "Customer" } },
                {
                  exists: {
                    field: "userGender",
                  },
                },
              ],
              must_not: {
                bool: {
                  must: [{ term: { userPincode: "111111" } }],
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let tempdata = data.hits.hits.map((element) => element._source);

        let genderRatioObj = {};
        tempdata.forEach((elements) => {
          if (elements.userGender !== undefined) {
            if (genderRatioObj[elements.userPincode] === undefined) {
              genderRatioObj[elements.userPincode] = {};
            }
            if (
              genderRatioObj[elements.userPincode][elements.userGender] ===
              undefined
            ) {
              genderRatioObj[elements.userPincode][elements.userGender] = 1;
            } else {
              genderRatioObj[elements.userPincode][elements.userGender] += 1;
            }
            if (genderRatioObj[elements.userGender] === undefined) {
              genderRatioObj[elements.userGender] = 1;
            } else {
              genderRatioObj[elements.userGender] += 1;
            }
          }
        });

        Object.entries(genderRatioObj)
          .filter(
            ([header, values]) => header !== "Male" && header !== "Female"
          )
          .map(([key, value]) => {
            tempArr[0].push({
              pincode: key,
              Male: value["Male"] !== undefined ? value["Male"] : 0,
              Female: value["Female"] !== undefined ? value["Female"] : 0,
            });
          });
        tempArr[1].push({
          totalMale:
            genderRatioObj["Male"] !== undefined ? genderRatioObj["Male"] : 0,
          totalFemale:
            genderRatioObj["Female"] !== undefined
              ? genderRatioObj["Female"]
              : 0,
        });

        this.setState({
          classRender: "genderRatio",
          argsArray: tempArr,
        });
      })
      .catch((err) => console.log(err));
  }

  customerGhoshakCoins() {
    this.setState({
      classRender: "customerGhoshakCoins",
    });
  }
  customerDetails(tendigitUserId) {
    this.setState({
      classRender: "customerDetails",
      args: tendigitUserId,
    });
  }
  customerPincodeInformation() {
    this.setState({
      classRender: "customerPincodeInformation",
    });
  }

  renderFunction(items, hideTable) {
    this.setState({
      classRender: "customerTable",
      argsArray: items,
      hideTable: hideTable,
    });
  }
  customerPincodeStats = async () => {
    this.setState({
      classRender: "customerPincodeStats",
      argsArray: await this.pincodeCount(),
    });
  };

  customerRefferals = async () => {
    await Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=0`, {
        method: "POST",
        body: JSON.stringify({
          aggs: {
            refferedValueObj: {
              filter: {
                bool: {
                  must: [
                    {
                      term: {
                        userType: "customer",
                      },
                    },
                  ],
                  must_not: [
                    {
                      term: {
                        userPincode: "111111",
                      },
                    },
                    {
                      match: {
                        referredBy: "None",
                      },
                    },
                  ],
                },
              },
              aggs: {
                countReferals: {
                  terms: {
                    field: "referredBy",
                    size: 10000,
                    order: {
                      _term: "asc",
                    },
                  },
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        let refArr = data.aggregations.refferedValueObj.countReferals.buckets.map(
          (elements) => {
            return {
              refferalCode: elements.key,
              count: elements.doc_count,
            };
          }
        );

        let refId = refArr.map((elements) => elements.refferalCode);
        let getCustomers = await this.findCustomerNamesByReferalCode(refId);

        let mergeRefandCust = refArr.map((elements) => {
          let mergeCust = getCustomers.find(
            (items) =>
              items.referralCode.toLowerCase() ===
              elements.refferalCode.toLowerCase()
          );
          let appendObject = {};
          if (mergeCust !== undefined) {
            appendObject = Object.assign({}, mergeCust, elements);
          } else {
            appendObject = elements;
            console.log("non matching referal code" + elements);
          }

          return appendObject;
        });

        this.setState({
          classRender: "customerRefferals",
          argsArray: mergeRefandCust,
        });
      });
  };
  customerTransaction = async () => {
    let temparray = [];
    await Promise.all([
      fetch(`${config.Elasticsearch.URL}/points/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          _source: {
            includes: [
              "custId",
              "ghoshakPoints",
              "pointsType",
              "pointsAction",
              "points",
            ],
          },
          query: {
            bool: {
              must: [
                {
                  terms: {
                    pointsType: ["ghoshak", "loyalty"],
                  },
                },
                {
                  terms: {
                    pointsAction: ["redeemed", "added"],
                  },
                },
                {
                  exists: {
                    field: "custId",
                  },
                },
              ],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        let tempdata = data.hits.hits.map((element) => element._source);
        let custObj = {};
        tempdata.forEach((elements) => {
          if (custObj[elements.custId] === undefined) {
            custObj[elements.custId] = {
              ghoshakaddedPoints: 0,
              ghoshakredeemedPoints: 0,
              ghoshakaddedCount: 0,
              ghoshakredeemedCount: 0,
              loyaltyaddedPoints: 0,
              loyaltyredeemedPoints: 0,
              loyaltyaddedCount: 0,
              loyaltyredeemedCount: 0,
            };
          }

          if (elements.pointsType === "loyalty") {
            if (elements.pointsAction === "added") {
              custObj[elements.custId].loyaltyaddedPoints += parseFloat(
                elements.points
              );

              custObj[elements.custId].loyaltyaddedCount += 1;
            } else if (elements.pointsAction === "redeemed") {
              custObj[elements.custId].loyaltyredeemedPoints += parseFloat(
                elements.points
              );
              custObj[elements.custId].loyaltyredeemedCount += 1;
            }
          } else if (elements.pointsType === "ghoshak") {
            if (elements.pointsAction === "added") {
              custObj[elements.custId].ghoshakaddedPoints += parseFloat(
                elements.points
              );
              custObj[elements.custId].ghoshakaddedCount += 1;
            } else if (elements.pointsAction === "redeemed") {
              custObj[elements.custId].ghoshakredeemedPoints += parseFloat(
                elements.points
              );
              custObj[elements.custId].ghoshakredeemedCount += 1;
            }
          }
        });

        let custIds = await Object.entries(custObj).map(([key, value]) => key);
        let getCustomerNames = await this.findCustomerNames(custIds);

        let pointsArray = [{ ghoshak: [] }, { loyalty: [] }];

        Object.entries(custObj).forEach(([key, value], index) => {
          let mergeCust = getCustomerNames.find(
            (items) => items.userId === key
          );

          let appendObject = {};
          if (mergeCust !== undefined) {
            appendObject = Object.assign({}, value, mergeCust);
          } else {
            appendObject = value;
          }

          if (appendObject.userPincode !== "111111") {
            pointsArray[0].ghoshak.push({
              userId: key,
              userId10Digit: appendObject.userId10Digit,
              userName: appendObject.userName,
              userPhoneNumber: appendObject.userPhoneNumber,
              userPincode: appendObject.userPincode,
              userAddress: appendObject.userAddress,
              userId10Digit: appendObject.userId10Digit,
              ghoshakaddedPoints: appendObject.ghoshakaddedPoints,

              ghoshakredeemedPoints: appendObject.ghoshakredeemedPoints,
              ghoshakredeemedCount: appendObject.ghoshakredeemedCount,
              totalPoints:
                parseInt(appendObject.ghoshakaddedPoints) -
                parseInt(appendObject.ghoshakredeemedPoints),
              totalinteraction: appendObject.ghoshakredeemedCount,
            });
            pointsArray[1].loyalty.push({
              userId: key,
              userId10Digit: appendObject.userId10Digit,
              userName: appendObject.userName,
              userPhoneNumber: appendObject.userPhoneNumber,
              userPincode: appendObject.userPincode,
              userAddress: appendObject.userAddress,
              userId10Digit: appendObject.userId10Digit,
              loyaltyredeemedPoints: appendObject.loyaltyredeemedPoints,
              loyaltyredeemedCount: appendObject.loyaltyredeemedCount,
              totalPoints:
                parseInt(appendObject.loyaltyaddedCount) -
                parseInt(appendObject.loyaltyredeemedPoints),
              totalinteraction: appendObject.loyaltyredeemedCount,
            });
          }
        });

        temparray.push({
          ghoshak: pointsArray[0].ghoshak,
          loyalty: pointsArray[1].loyalty,
        });

        this.setState({
          classRender: "customerTransaction",
          argsArray: temparray,
        });
      })
      .catch((err) => console.log(err));
  };
  customerCoupons() {
    var tempdata = [];
    var couponGhoshak = [];
    var couponMerchant = [];
    var couponLoyalty = [];
    var couponArray = [];
    var ghoshakCouponPincode = [];
    var mechantCouponPincode = [];
    let couponPromo = [];
    let promoCouponPincode = [];
    var loyaltyCouponPincode = [];
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/coupons/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                {
                  match: {
                    couponCategory: "created",
                  },
                },
              ],
              must_not: [{ match: { pincode: "111111" } }],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map((element) => element._source);

        tempdata.forEach((elements) => {
          if (elements.couponType === "GHOSHAK") {
            couponGhoshak.push({
              couponid: elements.couponId,
              consumed:
                parseInt(elements.totalCouponCount) -
                parseInt(elements.remainingCouponCount),
              consumedvalue:
                (parseInt(elements.totalCouponCount) -
                  parseInt(elements.remainingCouponCount)) *
                parseInt(elements.maximumCouponAmount),
              pincode: elements.pincode,
            });
            ghoshakCouponPincode.push(elements.pincode);
          } else if (elements.couponType === "MERCHANT") {
            couponMerchant.push({
              couponid: elements.couponId,
              consumed:
                parseInt(elements.totalCouponCount) -
                parseInt(elements.remainingCouponCount),
              consumedvalue:
                (parseInt(elements.totalCouponCount) -
                  parseInt(elements.remainingCouponCount)) *
                parseInt(elements.maximumCouponAmount),
              pincode: elements.pincode,
            });
            mechantCouponPincode.push(elements.pincode);
          } else if (elements.couponType === "LOYALTY") {
            couponLoyalty.push({
              couponid: elements.couponId,
              consumed:
                parseInt(elements.totalCouponCount) -
                parseInt(elements.remainingCouponCount),
              consumedvalue:
                (parseInt(elements.totalCouponCount) -
                  parseInt(elements.remainingCouponCount)) *
                parseInt(elements.maximumCouponAmount),
              pincode: elements.pincode,
            });
            loyaltyCouponPincode.push(elements.pincode);
          } else if (elements.couponType === "PROMOCODE") {
            couponPromo.push({
              couponid: elements.couponId,
              consumed:
                parseInt(elements.promocodeCount) -
                parseInt(elements.promocodeRemainingCount),
              consumedvalue:
                (parseInt(elements.promocodeCount) -
                  parseInt(elements.promocodeRemainingCount)) *
                parseInt(elements.maximumCouponAmount),
              pincode: elements.pincode,
            });
            promoCouponPincode.push(elements.pincode);
          }
        });

        const ghoshakCouponShopPincode = [...new Set(ghoshakCouponPincode)];
        const merchantCouponShopPincode = [...new Set(mechantCouponPincode)];
        const loyaltyCouponShopPincode = [...new Set(loyaltyCouponPincode)];
        const promoCouponShopPincode = [...new Set(promoCouponPincode)];
        couponArray.push(
          couponGhoshak,
          ghoshakCouponShopPincode,
          couponMerchant,
          merchantCouponShopPincode,
          couponLoyalty,
          loyaltyCouponShopPincode,
          couponPromo,
          promoCouponShopPincode
        );

        this.setState({
          classRender: "customerCoupons",
          argsArray: couponArray,
        });
      });
  }

  customertotalRegistration() {
    this.setState({
      classRender: "totalRegistration",
    });
  }

  likedOffers() {
    var tempdata = [];
    var tempcustomerLikedOfferId = [];
    var customerLikedOffers = [];
    var count = 0;
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            term: { userType: "customer" },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map((element) => element._source);
        tempdata.forEach((elements) => {
          tempcustomerLikedOfferId = [];
          count = 0;
          for (var i in elements.favoriteOffers) {
            tempcustomerLikedOfferId.push(i);
            count++;
          }

          customerLikedOffers.push({
            userId: elements.userId,
            userId10Digit: elements.userId10Digit,
            totalAddedtoFavourites: count,
            userName: elements.userName,
            userPhoneNumber: elements.userPhoneNumber,
            userPincode: elements.userPincode,
            userType: elements.userType,
            offerId: tempcustomerLikedOfferId,
          });
        });

        this.setState({
          classRender: "customerLikedOffers",
          argsArray: customerLikedOffers,
        });
      });
  }
  customerPincode() {
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=0`, {
        method: "POST",
        body: JSON.stringify({
          aggs: {
            customerObject: {
              filter: {
                bool: {
                  must: [
                    {
                      match: {
                        userType: "customer",
                      },
                    },
                  ],
                },
              },
              aggs: {
                countPincodes: {
                  terms: {
                    field: "userPincode",
                    size: 10000,
                    order: {
                      _term: "asc",
                    },
                  },
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let pincode = data.aggregations.customerObject.countPincodes.buckets.map(
          (elements) => {
            return {
              pincode: elements.key,
              totalCustomers: elements.doc_count,
            };
          }
        );

        this.setState({
          classRender: "customerPincode",
          args: pincode,
        });
      });
  }
  topCustomer = async () => {
    await Promise.all([
      fetch(`${config.Elasticsearch.URL}/points/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                {
                  term: {
                    pointsAction: "redeemed",
                  },
                },

                {
                  exists: {
                    field: "custId",
                  },
                },
              ],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        let tempdata = data.hits.hits.map((element) => element._source);

        let custObj = {};
        tempdata.forEach((elements) => {
          if (custObj[elements.custId] === undefined) {
            custObj[elements.custId] = {
              redeemedPoints: 0,
            };
          }

          custObj[elements.custId].redeemedPoints += parseFloat(
            elements.points
          );
        });
        let sortByArr = [];

        Object.entries(custObj).forEach(([key, value]) => {
          sortByArr.push({
            customerid: key,
            points: value.redeemedPoints,
          });
        });
        sortByArr = sortByArr
          .sort((a, b) => parseInt(b.points) - parseInt(a.points))
          .slice(0, 10);

        this.setState({
          classRender: "topCustomers",
          argsArray: sortByArr,
        });
      })
      .catch((err) => console.log(err));
  };

  findCustomerNames = async (custIds) => {
    return await Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          _source: {
            includes: [
              "referralCode",
              "referredBy",
              "userAddedDate",
              "userId",
              "userId10Digit",
              "userPhoneNumber",
              "userPincode",
              "userName",
              "userAddress",
              "userType",
            ],
          },
          query: {
            bool: {
              should: [
                {
                  terms: {
                    userId: custIds,
                  },
                },
              ],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let tempdata = data.hits.hits.map((element) => element._source);

        return tempdata;
      });
  };

  findCustomerNamesByReferalCode = async (custIds) => {
    return await Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          _source: {
            includes: [
              "referralCode",
              "referredBy",
              "userAddedDate",
              "userId",
              "userId10Digit",
              "userPhoneNumber",
              "userPincode",
              "userName",
              "userAddress",
              "userType",
            ],
          },
          query: {
            bool: {
              should: [
                {
                  terms: {
                    referralCode: custIds,
                  },
                },
              ],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let tempdata = data.hits.hits.map((element) => element._source);

        return tempdata;
      });
  };
  pincodeCount = async () => {
    return await Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=0`, {
        method: "POST",
        body: JSON.stringify({
          aggs: {
            customerObject: {
              filter: {
                bool: {
                  must: [
                    {
                      match: {
                        userType: "customer",
                      },
                    },
                  ],
                },
              },
              aggs: {
                countPincodes: {
                  terms: {
                    field: "userPincode",
                    size: 10000,
                    order: {
                      _term: "asc",
                    },
                  },
                },
              },
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        let pincode = data.aggregations.customerObject.countPincodes.buckets.map(
          (elements) => {
            return {
              pincode: elements.key,
              totalCustomers: elements.doc_count,
            };
          }
        );

        return pincode;
      });
  };

  render() {
    return (
      <>
        {this.state.showPage && (
          <Container fluid>
            <Row>
              <Col md={{ span: 2 }}>
                <List
                  style={{ width: "100%", maxWidth: 360 }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      METRICS
                    </ListSubheader>
                  }
                >
                  <Divider />
                  <ListItem
                    button
                    onClick={() => {
                      this.customertotalRegistration();
                    }}
                  >
                    <ListItemText primary="TOTAL REGISTRATION" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.customerPincode();
                    }}
                  >
                    <ListItemText primary="PINCODE" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.topCustomer();
                    }}
                  >
                    <ListItemText primary="TOP CUSTOMER" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.likedOffers();
                    }}
                  >
                    <ListItemText primary="LIKED OFFERS" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.customerCoupons();
                    }}
                  >
                    <ListItemText primary="COUPONS" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.customerTransaction();
                    }}
                  >
                    <ListItemText primary="INTERACTION / TRANSACTION" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.customerRefferals();
                    }}
                  >
                    <ListItemText primary="REFERRALS" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.customerPincodeStats();
                    }}
                  >
                    <ListItemText primary="PINCODE STATS" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.customerPincodeInformation();
                    }}
                  >
                    <ListItemText primary="PINCODE INFORMATION" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.customerDetails();
                    }}
                  >
                    <ListItemText primary="CUSTOMER DETAILS" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.customerGhoshakCoins();
                    }}
                  >
                    <ListItemText primary="GHOSHAK COINS" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.genderRatioFn();
                    }}
                  >
                    <ListItemText primary="GENDER RATIO" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.customerDobFn();
                    }}
                  >
                    <ListItemText primary="DATE OF BIRTH" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.customerAnniversaryFn();
                    }}
                  >
                    <ListItemText primary="ANNIVERSARY" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.customerEmail();
                    }}
                  >
                    <ListItemText primary="EMAIL" />
                  </ListItem>
                </List>
              </Col>
              <Col md={{ span: 10 }}>
                {getStepContent(
                  this.state.classRender,
                  this.state.args,
                  this.state.argsArray,
                  this.renderFunction,
                  this.customerDetails,
                  this.getCustomer,
                  this.state.hideTable,
                  this.state.tempPincode
                )}
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  }
}
