import React, { Component } from "react";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { Auth } from "aws-amplify";
import config from "../config";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
    align: "center",
  },
});
class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [{ id: undefined, customerId: undefined }],
      custdId: "",
      updatedBy: "",
      notification: "",
      tempArray: [],
      buttonDisable: false,
    };
    this.fileHandler = this.fileHandler.bind(this);

    this.reset = this.state;
    this.handleDelete = this.handleDelete.bind(this);
    this.sendNotificationSubmit = this.sendNotificationSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.textAreaOnchange = this.textAreaOnchange.bind(this);
    this.addList = this.addList.bind(this);
  }
  fileHandler = (event) => {
    // Handle excel import
    let fileObj = event.target.files[0]; // Get

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      // Library to read excel
      if (err) {
        console.log(err);
      } else {
        let response = resp.rows.map((elements) => {
          // console.log(elements);
          return elements[0].toString().trim();
        });
        this.setState({
          tempArray: response,
        });
      }
    });
  };
  textAreaOnchange(e) {
    this.multilineTextarea.style.height = "auto";

    this.multilineTextarea.style.height =
      this.multilineTextarea.scrollHeight + "px";
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async componentDidMount() {
    // get current user
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({
          updatedBy: user.attributes.phone_number,
        });
      })

      .catch((err) => console.log(err));
  }
  sendNotificationSubmit(e) {
    // Firebase to send notification
    e.preventDefault();
    let customerId = this.state.tempArray.filter(
      (elements) =>
        elements !== null && elements !== "" && elements !== undefined
    );
    this.setState({
      buttonDisable: true,
    });
    Promise.all([
      fetch(
        `${config.ApiGateway}/sendNotificationToCustomer`,

        {
          method: "POST",
          body: JSON.stringify({
            claimed: customerId,
            userType: "Customer",
            notification: this.state.notification,
            updatedBy: this.state.updatedBy,
            notificationPostedOn: new Date().toISOString().substring(0, 10),
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert(JSON.stringify(data));
        this.setState(this.reset);
        this.componentDidMount();
      })
      .catch((err) => alert(err));
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  addList(e) {
    e.preventDefault();
    this.setState((prevState) => ({
      tempArray: [...this.state.tempArray, ...this.state.custdId.split(",")],
      custdId: "",
    }));
  }

  handleDelete(i) {
    // compare and remove object from array based on index 
    this.setState({
      tempArray: this.state.tempArray.filter((tag, index) => index !== i),
    });
  }

  render() {
    const { classes } = this.props;
    this.state.tempArray = this.state.tempArray.filter(
      (item) => item !== null && item !== "" && item !== undefined
    );

    this.state.tempArray.map((items) => console.log());

    return (
      <Container fluid>
        <>
          <div className="headingStyle">
            <h3>NOTIFICATION</h3>
          </div>

          <Form onSubmit={this.sendNotificationSubmit}>
            <Row>
              <Col xs={{ offset: 1, span: 3 }}>
                <Form.Label>ENTER MESSAGE</Form.Label>
              </Col>
              <Col xs={5}>
                <Form.Control
                  as="textarea"
                  rows="6"
                  name="notification"
                  value={this.state.notification}
                  onChange={this.onChange}
                  required
                ></Form.Control>
              </Col>
            </Row>
            <Button
              disabled={
                this.state.tempArray.length === 0 || this.state.buttonDisable
              }
              style={{
                position: "relative",
                marginLeft: 65 + "%",
                marginTop: 10 + "px",
              }}
              type="submit"
            >
              SEND NOTIFICATION
            </Button>
          </Form>

          <Row>
            <Col xs={12}>
              <div
                style={{
                  backgroundColor: "#fafafa",

                  margin: 1 + "%",
                }}
              >
                <h3 style={{ textAlign: "center" }}> ADD CUSTOMER</h3>
              </div>
              <Form onSubmit={this.addList}>
                <Row style={{ marginTop: 20 + "px", marginBottom: 20 + "px" }}>
                  <Col xs={2}>
                    <Form.Label>ENTER CUSTOMER USER ID</Form.Label>
                  </Col>
                  <Col xs={4}>
                    <Form.Control
                      className="dummy"
                      as="textarea"
                      name="custdId"
                      value={this.state.custdId}
                      onChange={this.textAreaOnchange}
                      ref={(ref) => (this.multilineTextarea = ref)}
                    ></Form.Control>
                  </Col>

                  <Col xs={2}>
                    <Button type="submit">ADD</Button>
                  </Col>
                  <Col>
                    <Form.Label>IMPORT EXCEL FILE</Form.Label>
                  </Col>
                  <Col>
                    <input
                      type="file"
                      onChange={this.fileHandler.bind(this)}
                      style={{ padding: "10px" }}
                    />
                  </Col>
                </Row>
              </Form>
              {this.state.tempArray.length > 0 && (
                <>
                  <Table
                    style={{
                      width: 50 + "%",
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid",
                    }}
                    size="medium"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          S NO
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          CUSTOMER ID
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          ACTION
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.tempArray
                        .filter(
                          (item) =>
                            item !== null && item !== "" && item !== undefined
                        )
                        .map((item, index) => (
                          <>
                            <TableRow>
                              <TableCell className={classes.tableCell}>
                                {index + 1}
                              </TableCell>
                              <TableCell
                                style={{
                                  backgroundColor:
                                    item.toString().length == "10" ? "" : "red",
                                }}
                                className={classes.tableCell}
                              >
                                {item}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                <Button
                                  onClick={() => this.handleDelete(index)}
                                >
                                  <HighlightOffIcon color="secondary" />
                                </Button>
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </Col>
          </Row>
        </>
      </Container>
    );
  }
}
Notification.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notification);
