import React, { useEffect, useState, useContext } from "react";
import UserRole from "./userRole";
import { Route, Redirect, useHistory } from "react-router-dom";
import { store } from "./provider";
import CustomAuth from "./wrap";
import { Auth } from "aws-amplify";
import Login from "./authentication/login";
const RouterPath = (props) => {
  const context = useContext(store);
  const [allRoutes, setAllRoutes] = useState([]);
  const [userRole, setUserRole] = useState("");
  const history = useHistory();
  useEffect(() => {
    async function getUserRole() {
      await Auth.currentAuthenticatedUser()
        .then(async (user) => {
          var info = user.signInUserSession.idToken.payload["cognito:groups"];
          setUserRole(info[0]);
          setAllRoutes(UserRole);
          await context.dispatch({
            type: "IS_LOGGEDIN",
            loggedIn: true,
          });
          await context.dispatch({
            type: "CURRENT_USER",
            data: user.attributes.email,
          });
        })
        .catch(async (err) => {
          setUserRole({ sessionLogin: "sales" });
          setAllRoutes([]);
          return await context.dispatch({
            type: "IS_LOGGEDIN",
            loggedIn: false,
          });
        });
    }

    getUserRole();
  }, []);
  return (
    <>
      {context.state.loggedIn ? (
        allRoutes.map((elements) => (
          <RouterProps
            authState={props.authState}
            key={elements.Component}
            path={elements.path}
            Component={elements.Component}
            Role={elements.roles}
            authorizedAccess={userRole}
          />
        ))
      ) : (
        <>
          <Login onStateChange={props.onStateChange} />
        </>
      )}
    </>
  );
};

const RouterProps = ({
  path,
  Component,
  Role,
  authorizedAccess,
  authState,
}) => {
  return (
    <Route
      path={path}
      exact
      render={(props) => {
        return Role.includes(authorizedAccess) ? (
          <Component authState={authState} {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/error", state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export default RouterPath;
