import React, { useEffect, useState } from "react";
import "../styles/licenseManagement.css";
import Modal from "react-modal";
import config from "../config";
import {
  FileOutlined,
  PieChartOutlined,
  UserOutlined,
  DesktopOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { CiEdit } from "react-icons/ci";
import { BiCheck } from "react-icons/bi";
import { RotatingLines } from "react-loader-spinner";
import { Switch } from "@material-ui/core";
import { Container, Col, Button, Row, Form } from "react-bootstrap";

const LicenseManagement = () => {
  const [idModal, setIdModal] = useState(false);
  const [tenDigitId, setTenDigitId] = useState("");
  const [alertNote, setAlertNote] = useState("");
  const [currentShop, setCurrentShop] = useState({});
  const [editId, setEditId] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState("");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "500px",
      height: "175px",
      borderRadius: "25px",
      overlay: {
        background: "#f6f6f6",
      },
    },
  };

  const getShopDetails = async () => {
    setLoading(true);
    try {
      let response = await fetch(`${config.ApiGateway}/ownDomainFetchContent`, {
        method: "POST",
        body: JSON.stringify({
          userId10Digit: String(tenDigitId),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      let received = await response.json();
      if (received[0].userId) {
        setCurrentShop(received[0]);
        setIdModal(false);
        setEditId(false);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);

      console.log("Error fethcing shops data", err);
    }
  };
  useEffect(() => {
    console.log("currentSHOP", currentShop);
  }, []);

  const verifyId = () => {
    //validating entered 10 digit id
    if (tenDigitId.length === 10 || tenDigitId.length === 9) {
      getShopDetails();
    } else {
      setAlertNote("Please enter a valid shop id.");
      return;
    }
  };

  const [collapsed, setCollapsed] = useState(false);
  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "ghoDeliveryPackageActive") {
      setCurrentShop((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
      return;
    }

    setCurrentShop((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const saveDetails = async (e) => {
    // update shop website status
    setSaving(true);
    let tempBody = {
      websiteUserName: currentShop?.websiteUserName.toLowerCase(),
      websiteExpiryOn: currentShop?.websiteExpiryOn,
      userId: currentShop?.userId,
      websitePaymentStatus: currentShop?.websitePaymentStatus,
      typename: "updateData",
      websitePaymentId: currentShop?.websitePaymentId,
      shopType: currentShop?.shopType,
    };
    if (currentShop.shopType === "Aggregated") {
      tempBody.ghoDeliveryPackageActive = currentShop.ghoDeliveryPackageActive;

      tempBody.ghoDeliveryPackageStartDate =
        currentShop.ghoDeliveryPackageStartDate;

      tempBody.ghoDeliveryPackageExpiryDate =
        currentShop.ghoDeliveryPackageExpiryDate;
    }
    await Promise.all([
      fetch(`${config.ApiGateway}/updateWebsiteStatus`, {
        method: "POST",
        body: JSON.stringify(tempBody),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data, "what happend");

        if (data.status == "failed") {
          setSaving(false);
          return alert("Error updating shop.");
        } else if (data.status == "sucess") {
          setSaving(false);

          return alert("Shop updated successfully");
        } else if (data.status == "update") {
          setSaving(false);

          return alert("Shop updated successfully");
        } else {
          setSaving(false);

          return alert("Error updating shop.");
        }
      })
      .catch((err) => console.log(err, "Error at making website live"));
  };

  useEffect(() => {
    setIdModal(true);
  }, []);

  return (
    <div className="lmroot">
      <div className="licenseOptions">
        <div className="idEnterSection">
          {editId && (
            <div className="inputDivId">
              <input
                type="number"
                className="shopIdInput"
                value={tenDigitId}
                placeholder="Shop Id"
                onChange={(e) => setTenDigitId(e.target.value)}
              />
              {loading ? (
                <RotatingLines
                  strokeColor="green"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible={true}
                />
              ) : (
                <BiCheck onClick={() => verifyId()} className="tick" />
              )}
              {/* <div className="idDone" onClick={() => verifyId()}>
                Done
              </div> */}
            </div>
          )}
          {!editId && (
            <div>
              <div className="display10DigitSec">
                <div className="display10Digit">Shop Id: {tenDigitId}</div>
                <CiEdit
                  className="editIconLm"
                  onClick={() => setEditId(true)}
                />
              </div>
            </div>
          )}
        </div>
        {!editId && currentShop.shopName && (
          <div className="shopDetailsSec">
            <img
              src={
                currentShop.shopImageLocation
                  ? currentShop.shopImageLocation
                  : currentShop.shopIconLocation
                  ? currentShop.shopIconLocation
                  : ""
              }
              className="shopImg"
            />
            <div className="shopName">{currentShop.shopName}</div>
          </div>
        )}
        {currentShop.userId && !editId && (
          <div className="optionsContainer">
            <div
              onClick={() => setSelectedOption("ghoDelivery")}
              className={
                selectedOption === "ghoDelivery" ? "selectedOption" : "option"
              }
            >
              Gho Delivery
            </div>
            <div
              onClick={() => setSelectedOption("pos")}
              className={selectedOption === "pos" ? "selectedOption" : "option"}
            >
              Retail Pos
            </div>
            <div
              onClick={() => setSelectedOption("onlineStore")}
              className={
                selectedOption === "onlineStore" ? "selectedOption" : "option"
              }
            >
              Online Store
            </div>
            <div
              onClick={() => setSelectedOption("website")}
              className={
                selectedOption === "website" ? "selectedOption" : "option"
              }
            >
              Website
            </div>
          </div>
        )}
      </div>
      <div className="mainSection">
        <div className="saveBtn" onClick={() => saveDetails()}>
          {saving ? " Saving" : "Save"}
          {saving && (
            <RotatingLines
              strokeColor="white"
              strokeWidth="5"
              animationDuration="0.75"
              width="25"
              visible={true}
            />
          )}
        </div>
        {selectedOption === "ghoDelivery" && currentShop.userId && (
          <div className="ghoDeliveryRoot">
            <div className="ghoDeliveryTitle">GHO-DELIVERY</div>
            <div className="ghoDeliveryInner">
              <div className="sectionIndi">
                <div className="indiName">Gho Delivery Package</div>
                <div>
                  <Switch
                    name="ghoDeliveryPackageActive"
                    checked={
                      currentShop.ghoDeliveryPackageActive
                        ? currentShop.ghoDeliveryPackageActive
                        : false
                    }
                    name="ghoDeliveryPackageActive"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="sectionIndi">
                <div className="indiName">Package Start Date</div>
                <div style={{ width: "300px" }}>
                  <Form.Control
                    type="date"
                    value={
                      currentShop.ghoDeliveryPackageStartDate
                        ? currentShop.ghoDeliveryPackageStartDate
                        : ""
                    }
                    name="ghoDeliveryPackageStartDate"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="sectionIndi">
                <div className="indiName">Package Expiry Date</div>
                <div style={{ width: "300px" }}>
                  <Form.Control
                    type="date"
                    value={
                      currentShop.ghoDeliveryPackageExpiryDate
                        ? currentShop.ghoDeliveryPackageExpiryDate
                        : ""
                    }
                    className="dateStyle"
                    name="ghoDeliveryPackageExpiryDate"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default LicenseManagement;

// const App = () => {

//   return (

//   );
// };
// export default App;
