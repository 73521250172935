import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Row, Button } from "react-bootstrap";
import config from "../../config";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide"
  },
  table: {
    minWidth: 340
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5
  }
});
class CustomerTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showdata: false,
      showTable: [],
      showusers: true
    };
  }

  async componentDidMount() {
    console.log(this.props.customerId.exsistingCustomer);

    Promise.all([
      await fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              should: [
                { terms: { userId: this.props.customerId.exsistingCustomer } }
              ]
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      }),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([users, data2]) => {
        let tempdata = users.hits.hits.map(element => element._source);
        var result = Object.entries(this.props.customerId.customers).map(
          ([id, value]) => {
            let findCustomer = tempdata.find(
              elements => elements.userId === id
            );
            if (findCustomer !== undefined) {
              let appendObject = {};
              appendObject.userName =
                findCustomer.userName !== undefined
                  ? findCustomer.userName
                  : "";
              appendObject.userPhoneNumber =
                findCustomer.userPhoneNumber !== undefined
                  ? findCustomer.userPhoneNumber
                  : "";
              appendObject.userPincode =
                findCustomer.userPincode !== undefined
                  ? findCustomer.userPincode
                  : "";

              appendObject.userAddedDate =
                findCustomer.userAddedDate !== undefined
                  ? findCustomer.userAddedDate
                  : "";
              appendObject.userId10Digit =
                findCustomer.userId10Digit !== undefined
                  ? findCustomer.userId10Digit
                  : "";

              appendObject.redeemed =
                value.redeemed !== undefined ? value.redeemed : 0;
              appendObject.added = value.added !== undefined ? value.added : 0;
              appendObject.visit = value.visit !== undefined ? value.visit : 0;
              return appendObject;
            }
          }
        );
        let loyaltyCustomers = result.filter(items => items !== undefined);
        var mergePincodeAndArea = loyaltyCustomers.map(items => {
          let count = data2.Items.find(
            elements => items.userPincode === elements.pincode
          );

          let appendObject = Object.assign({}, items, count);

          return appendObject;
        });

        this.setState({
          showTable: mergePincodeAndArea,
          showdata: true
        });
      })
      .catch(err => console.log(err));
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.showdata && (
          <>
            <Row>
              {this.state.showusers ? (
                <>
                  <div
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%"
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}> CUSTOMERS DETAILS </h3>
                  </div>
                  <Table
                    style={{
                      width: 100 + "%",
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid"
                    }}
                    className={classes.table}
                    size="small"
                  >
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            S NO
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            USER ID
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            ADDED
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            REDEEMED
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            CURRENT POINTS
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            PINCODE
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            PLACE
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            PHONE NUMBER
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            USERNAME
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            INTERACTION
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <>
                          {this.state.showTable.map((items, index) => (
                            <>
                              <TableRow key={items.couponId}>
                                <TableCell className={classes.tableCell}>
                                  {index + 1}
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  <Link
                                    to={{
                                      pathname: "/customer",
                                      search: `?userid=${items.userId10Digit}`
                                    }}
                                  >
                                    {items.userId10Digit}
                                  </Link>
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  {items.added}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.redeemed}
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  {parseFloat(items.added) -
                                    parseFloat(items.redeemed)}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.userPincode}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.place}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.userPhoneNumber}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.userName}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {items.visit}
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                        </>
                      </TableBody>
                    </>
                  </Table>
                </>
              ) : (
                <>
                  <h3>loading......</h3>
                </>
              )}
            </Row>
          </>
        )}
      </>
    );
  }
}
CustomerTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomerTable);
