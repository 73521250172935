import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import InputGroup from "react-bootstrap/InputGroup";
import { withStyles } from "@material-ui/core/styles";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import config from "../config";
import limit from "../limit";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class GhoshakDistributionByPincode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ghoshakCouponByPincodeArray: [],
      displayTable: false,
      couponDetails: [],
      startDate: limit.size.startDate,
      endDate: limit.size.endDate,
    };
    this.showCouponDetails = this.showCouponDetails.bind(this);
    this.renderTableRows = this.renderTableRows.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fetchDetails = this.fetchDetails.bind(this);
    this.export = this.export.bind(this);
    this.csvLink = React.createRef();
  }
  export() {
    const headers = [
      { label: "PINCODE", key: "pincode" },
      { label: "TOTAL COUPON", key: "values.totalCouponCount" },
      { label: "AREA", key: "place" },
      { label: "ALLOCATED COST", key: "values.allocatedCost" },
      { label: "CONSUMED COST", key: "values.consumedCost" },
      { label: "CREATED", key: "values.Added" },
      { label: "CLAIMED", key: "value.Claim" },
      { label: "REDEEMED", key: "value.Redeemed" },
      { label: "Live Coupons", key: "values.liveCouponDetails.length" },
      { label: "Expired Coupons", key: "values.expiredCouponDetails.length" },
    ];

    this.setState(
      {
        headers: headers,
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  renderTableRows() {
    let tableHeader = [];
    for (var i = 0; i < 6; i++) {
      tableHeader.push(
        <>
          <TableCell
            style={{
              textAlign: "center",
              borderLeft: 0.5 + "px",
              borderLeftColor: "#ced4da",
              borderLeftStyle: "solid",
            }}
          >
            LIVE
          </TableCell>
          <TableCell
            style={{
              textAlign: "center",
              borderRight: 0.5 + "px",
              borderRightColor: "#ced4da",
              borderRightStyle: "solid",
            }}
          >
            WILL EXPIRE
          </TableCell>
        </>
      );
    }
    return tableHeader;
  }
  showCouponDetails(couponDetails) {
    this.setState({
      couponDetails: couponDetails,
      displayTable: false,
    });
  }
  fetchDetails() {
    this.monthArray = [
      { id: "01", monthName: "JAN" },
      { id: "02", monthName: "FEB" },
      { id: "03", monthName: "MAR" },
      { id: "04", monthName: "APR" },
      { id: "05", monthName: "MAY" },
      { id: "06", monthName: "JUNE" },
      { id: "07", monthName: "JULY" },
      { id: "08", monthName: "AUG" },
      { id: "09", monthName: "SEPT" },
      { id: "10", monthName: "OCT" },
      { id: "11", monthName: "NOV" },
      { id: "12", monthName: "DEC" },
    ];

    let store_six_months = [];

    let currentDate = new Date().toISOString().substring(0, 10);
    let currentMonth = new Date().toISOString().substring(5, 7);
    let loop = new Date(currentDate);

    for (var i = 0; i < 6; i++) {
      let matchDate = loop.toISOString().substring(0, 10);
      store_six_months.push(matchDate.substring(0, 10));

      var newDate = loop.setMonth(loop.getMonth() + 1);
      loop = new Date(newDate);
    }

    Promise.all([
      fetch(
        `${config.ApiGateway}/couponDistributionByPincode`,

        {
          method: "POST",
          body: JSON.stringify({
            couponType: "GHOSHAK", // coupon status
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([data, data2]) => {
        let couponList = data.map((elements) => {
          let tempArray = [];
          for (var i = 0; i < store_six_months.length; i++) {
            let liveCouponTimeline = 0;
            let expireCouponTimeline = 0;
            let getMonthName = this.monthArray.find(
              (items) => store_six_months[i].substring(5, 7) === items.id
            );
            elements.values.allExpiryCoupons.forEach((items) => {
              if (
                store_six_months[i].substring(0, 7) === items.substring(0, 7)
              ) {
                liveCouponTimeline++;
                expireCouponTimeline++;
              } else if (
                new Date(store_six_months[i]).toISOString().substring(0, 10) <
                new Date(items).toISOString().substring(0, 10)
              ) {
                liveCouponTimeline++;
              }
            });
            tempArray.push({
              month: getMonthName.monthName,
              willExpire: expireCouponTimeline,
              currentlyAvailable: liveCouponTimeline,
            });
          }
          let appendobject = {};
          appendobject = Object.assign({}, elements);
          appendobject.couponTimeline = tempArray;
          return appendobject;
        });

        var mergePincodeAndArea = couponList.map((items) => {
          let count = data2.Items.find(
            (elements) => items.pincode === elements.pincode
          );
          let appendObject = {};
          if (count !== undefined) {
            appendObject = Object.assign({}, count, items);
          } else {
            appendObject = Object.assign(
              {},
              { pincode: items.pincode, place: "Not Found" },
              items
            );
          }
          return appendObject;
        });
        console.log(mergePincodeAndArea);
        return this.setState({
          ghoshakCouponByPincodeArray: mergePincodeAndArea,
          displayTable: true,
        });
      })
      .catch((err) => alert(err));
  }
  async componentDidMount() {
    await this.fetchDetails();
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              {this.state.displayTable ? (
                <>
                  <div
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%",
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}>
                      {" "}
                      GHOSHAK DISTRIBUTION{" "}
                    </h3>
                  </div>
                  <Row>
                    <Col>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <strong> DATE RANGE</strong>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name="startDate"
                          value={this.state.startDate}
                          onChange={this.onChange}
                          type="date"
                          required
                        ></Form.Control>
                        <Form.Control
                          name="endDate"
                          onChange={this.onChange}
                          value={this.state.endDate}
                          type="date"
                          min={this.state.startDate}
                          required
                        ></Form.Control>
                      </InputGroup>
                    </Col>

                    <Col>
                      <Button
                        onClick={() => {
                          this.fetchDetails();
                        }}
                      >
                        SEARCH
                      </Button>
                    </Col>
                    <Col xs={2}>
                      <Button
                        onClick={() => {
                          this.export();
                        }}
                      >
                        DOWNLOAD
                      </Button>
                      <CSVLink
                        enclosingCharacter={`'`}
                        data={this.state.ghoshakCouponByPincodeArray}
                        headers={this.state.headers}
                        ref={this.csvLink}
                        target="_blank"
                      />
                    </Col>
                  </Row>
                  <Table
                    style={{
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid",
                    }}
                    className={classes.table}
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={14}></TableCell>
                        <TableCell
                          align="center"
                          colSpan={14}
                          className={classes.tableCell}
                        >
                          COUPON AVAILABILITY
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          S NO
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          PINCODE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          PLACE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          TOTAL COUPONS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          ALLOCATED COST
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          CONSUMED COST
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          CREATED
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          CLAIMED COUPONS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          REDEEMED COUPONS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          LIVE COUPONS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          ACTIVE COUPON DETAILS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          EXPIRED COUPONS
                        </TableCell>
                        <TableCell colSpan={2} className={classes.tableCell}>
                          EXPIRED COUPONS DETAILS
                        </TableCell>
                        {this.state.ghoshakCouponByPincodeArray
                          .slice(-1)
                          .map((element, index) =>
                            element.couponTimeline.map((items) => (
                              <>
                                <TableCell
                                  colSpan={2}
                                  className={classes.tableCell}
                                  style={{
                                    textAlign: "center",
                                    borderLeft: 0.5 + "px",
                                    borderLeftColor: "#ced4da",
                                    borderLeftStyle: "solid",
                                  }}
                                >
                                  {items.month}
                                </TableCell>
                              </>
                            ))
                          )}
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={14}></TableCell>
                        {this.renderTableRows()}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.ghoshakCouponByPincodeArray.map(
                        (elements, index) => (
                          <TableRow key={elements.pincode}>
                            <TableCell className={classes.tableCell}>
                              {index + 1}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.pincode}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.place}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.values.totalCouponCount}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.values.allocatedCost}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.values.consumedCost}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.values.Added}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {elements.value !== undefined
                                ? elements.value.Claim
                                : 0}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.value !== undefined
                                ? elements.value.Redeemed
                                : 0}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {elements.values.active}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Button
                                onClick={() => {
                                  this.showCouponDetails(
                                    elements.values.liveCouponDetails
                                  );
                                }}
                              >
                                VIEW
                              </Button>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {elements.values.expired}
                            </TableCell>
                            <TableCell
                              colSpan={2}
                              className={classes.tableCell}
                            >
                              <Button
                                onClick={() => {
                                  this.showCouponDetails(
                                    elements.values.expiredCouponDetails
                                  );
                                }}
                              >
                                VIEW
                              </Button>
                            </TableCell>

                            {elements.couponTimeline.map((items) => (
                              <>
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                  style={{
                                    textAlign: "center",
                                    borderLeft: 0.5 + "px",
                                    borderLeftColor: "#ced4da",
                                    borderLeftStyle: "solid",
                                  }}
                                  key={items.currentlyAvailable}
                                >
                                  {items.currentlyAvailable}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={classes.tableCell}
                                  style={{
                                    textAlign: "center",
                                    borderRight: 0.5 + "px",
                                    borderRightColor: "#ced4da",
                                    borderRightStyle: "solid",
                                  }}
                                >
                                  {items.willExpire}
                                </TableCell>
                              </>
                            ))}
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </>
              ) : (
                <>
                  <div
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%",
                    }}
                  >
                    <h3 className={classes.tableCell}>
                      {" "}
                      GHOSHAK COUPON DETAILS
                    </h3>
                  </div>
                  <Table
                    style={{
                      border: 0.5 + "px",
                      borderColor: "#ced4da",
                      borderStyle: "solid",
                    }}
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          S NO
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          SHOP ID
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          SHOP NAME
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          USERNAME
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          PHONE NUMBER
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          SHOP TYPE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          SHOP ADDRESS
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          PINCODE
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPON DETAILS
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          TOTAL COUPON
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPONS USED
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          ALLOCATED COST
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          CONSUMED COST
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPON AMOUNT
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPON ADDED ON
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          EXPIRED ON
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          COUPON ADDED BY
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          VIEW REDEEMED
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.couponDetails.map((elements, index) => (
                        <TableRow key={elements.couponDetails}>
                          <TableCell className={classes.tableCell}>
                            {index + 1}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Link
                              to={{
                                pathname: "/merchant",
                                search: `?userid=${elements.userId10Digit}`,
                              }}
                            >
                              {elements.userId10Digit}
                            </Link>
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {elements.shopName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.userName}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.userPhoneNumber}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.shopType}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.shopAddress}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.userPincode}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.couponDetails}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {elements.totalCouponCount}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {parseInt(elements.totalCouponCount) -
                              parseInt(elements.remainingCouponCount)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {parseInt(elements.totalCouponCount) *
                              parseInt(elements.maximumCouponAmount)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {(parseInt(elements.totalCouponCount) -
                              parseInt(elements.remainingCouponCount)) *
                              parseInt(elements.maximumCouponAmount)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.maximumCouponAmount}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.addedDate}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.expiryDate}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {elements.couponAddedby}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Link
                              to={{
                                pathname: "/redeemedCustomers",
                                search: `?id=${elements.couponId}`,
                              }}
                            >
                              <Button>VIEW REDEEMED CUSTOMER</Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
GhoshakDistributionByPincode.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GhoshakDistributionByPincode);
