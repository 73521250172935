import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import Ghoshak from "./ghoshakLogoWhite.png";
import { store } from "../provider";

export default class Navigation extends Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }
  show = () => {
    this.setState({
      show: false,
      toggleState: false,
    });
  };

  render() {
    return (
      <header className="header">
        <div className="ghoshakLogoDiv">
          <img
            src={
              "https://ghoshak.com/wp-content/uploads/2023/11/cropped-logo-ghoshak1-removebg-preview-2.png"
            }
            alt={Ghoshak}
            width={160}
            height={50}
          ></img>
          <div className="welcomeText">Ghoshak-CRM</div>
        </div>
        {this.props.authState === "signedIn" && (
          <div className="logoutHomeDiv">
            <Link to="/" className="nav-link" href="/" className="homeText">
              Home
            </Link>
            <span className="separator">|</span>
            {/* <div className="logoutText">Logout</div> */}
            <div
              onClick={() => {
                Auth.signOut()
                  .then(async (data) => {
                    this.props.onStateChange("signedOut");
                    await this.context.dispatch({
                      type: "IS_LOGGEDIN",
                      loggedIn: false,
                    });
                  })
                  .catch((err) => console.log(err));
              }}
              className="logoutText"
            >
              Logout
            </div>
          </div>
        )}
        {/* <div className="container"> */}

        {/* <Navbar
            className="navigation"
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
          >
            <Navbar.Brand href="/">
              
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              {this.props.authState === "signedIn" && (
                <div className="navbar-nav-scroll">
                  <ul className="navbar-nav bd-navbar-nav flex-row">
                    <li className="nav-item">
c                        Home <span className="sr-only">(current)</span>
                      </Link>
                    </li>

                    <>
                      <li className="nav-item">
                        <Nav.Link
                          href="#"
                          onClick={() => {
                            Auth.signOut()
                              .then(async (data) => {
                                this.props.onStateChange("signedOut");
                                await this.context.dispatch({
                                  type: "IS_LOGGEDIN",
                                  loggedIn: false,
                                });
                              })
                              .catch((err) => console.log(err));
                          }}
                        >
                          Logout
                        </Nav.Link>
                      </li>
                    </>
                  </ul>
                </div>
              )}
            </Navbar.Collapse>
          </Navbar> */}
        {/* </div> */}
      </header>
    );
  }
}
