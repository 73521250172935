import MaterialTable from "material-table";
import React from "react";
import Button from "react-bootstrap/Button";
export default function DisplayTable({
  title,
  validKeys,
  data,
  openModal,
  isLoading,
}) {
  return (
    <>
      <MaterialTable
        title={title}
        columns={[
          {
            title: "Offer Imge",
            field: "offerImageLocation",
            render: (rowData) => (
              <img
                // src={JSON.parse(item.offerImageLocation)}
                src={rowData.offerImageLocation}
                alt={rowData.offerImageLocation}
                width="100"
                height="100"
              ></img>
            ),
            hidden: !validKeys.includes("offerImageLocation"),
          },
          {
            title: "Ghoshak Id",
            field: "userId10Digit",
            hidden: !validKeys.includes("userId10Digit"),
          },
          {
            title: "Shop Name",
            field: "shopName",
            hidden: !validKeys.includes("shopName"),
          },
          {
            title: "Name",
            field: "userName",
            hidden: !validKeys.includes("userName"),
          },
          {
            title: "Shop Type",
            field: "shopType",
            hidden: !validKeys.includes("shopType"),
          },
          {
            title: "Address",
            field: "shopAddress",
            hidden: !validKeys.includes("shopAddress"),
          },
          {
            title: "Pincode",
            field: "userPincode",
            hidden: !validKeys.includes("userPincode"),
          },
          {
            title: "Registered on",
            field: "userAddedDate",
            hidden: !validKeys.includes("userAddedDate"),
          },
          {
            title: "Loyalty Package",
            field: "loyaltyPackage",
            hidden: !validKeys.includes("loyaltyPackage"),
          },
          {
            title: "Loyalty Amount",
            field: "loyaltyAmount",
            hidden: !validKeys.includes("loyaltyAmount"),
          },
          {
            title: "Loyalty Points",
            field: "loyaltyPoints",
            hidden: !validKeys.includes("loyaltyPoints"),
          },
          {
            title: "Ratio",
            field: "loyaltyRatio",
            hidden: !validKeys.includes("loyaltyRatio"),
          },
          {
            title: "Customer",
            field: "customer",
            hidden: !validKeys.includes("customer"),
          },
          {
            title: "Total Offers",
            field: "offers",
            hidden: !validKeys.includes("offers"),
          },
          {
            title: "Offer Status",
            field: "status",
            hidden: !validKeys.includes("status"),
            render: (rowData) => (
              <ul style={{ listStyle: "none", display: "inline-block" }}>
                {Object.keys(rowData.status).map((items) => (
                  <li style={{ padding: 0, margin: 0 }}>
                    {items} : <b> {rowData.status[items]} </b>{" "}
                  </li>
                ))}
              </ul>
            ),
          },
          {
            title: "Type",
            field: "offerType",
            hidden: !validKeys.includes("offerType"),
          },
          {
            title: "Type",
            field: "offerDetails",
            hidden: !validKeys.includes("offerDetails"),
          },
          {
            title: "Review",
            field: "review",
            hidden: !validKeys.includes("review"),
          },
          {
            title: "Pincode",
            field: "shopPincode",
            hidden: !validKeys.includes("shopPincode"),
          },
          {
            title: "Status",
            field: "offerStatus",
            hidden: !validKeys.includes("offerStatus"),
          },
          {
            title: "Created On",
            field: "offerAddedDate",
            hidden: !validKeys.includes("offerAddedDate"),
          },
          {
            title: "Expiry Date",
            field: "offerEndDate",
            hidden: !validKeys.includes("offerEndDate"),
          },
          {
            title: "Reviewed on",
            field: "reviewedOn",
            hidden: !validKeys.includes("reviewedOn"),
          },
          {
            title: "Reviewed By",
            field: "reviewedBy",
            hidden: !validKeys.includes("reviewedBy"),
          },
          {
            title: "Promocode",
            field: "promocode",
            hidden: !validKeys.includes("promocode"),
          },
          {
            title: "Pincode",
            field: "pincode",
            hidden: !validKeys.includes("pincode"),
          },
          {
            title: "No of Coupon",
            field: "totalCouponCount",
            hidden: !validKeys.includes("totalCouponCount"),
            render: (rowData) =>
              parseInt(rowData.totalCouponCount) ||
              parseInt(rowData.promocodeCount) ||
              0,
          },
          {
            title: "Allocated Cost",
            field: "allocatedCost",
            hidden: !validKeys.includes("totalCouponCount"),
            render: (rowData) =>
              `₹ ${
                parseInt(rowData.maximumCouponAmount) *
                  parseInt(rowData.promocodeCount) ||
                parseInt(rowData.maximumCouponAmount) *
                  parseInt(rowData.totalCouponCount) ||
                0
              }`,
          },
          {
            title: "Consumed Cost",
            field: "consumedCost",
            hidden: !validKeys.includes("consumedCost"),
            render: (rowData) =>
              `₹ ${
                (parseInt(rowData.promocodeCount) -
                  parseInt(rowData.promocodeRemainingCount)) *
                  parseInt(rowData.maximumCouponAmount) ||
                (parseInt(rowData.totalCouponCount) -
                  parseInt(rowData.remainingCouponCount)) *
                  parseInt(rowData.maximumCouponAmount) ||
                0
              }`,
          },
          {
            title: "Details",
            field: "couponDetails",
            hidden: !validKeys.includes("couponDetails"),
          },

          {
            title: "Created on",
            field: "addedDate",
            hidden: !validKeys.includes("addedDate"),
          },

          {
            title: "Action",
            field: "action",
            hidden: !validKeys.includes("action"),
            render: (rowData) => (
              <Button onClick={() => openModal(rowData)}>View</Button>
            ),
          },
        ]}
        data={data}
        style={{ width: "100%" }}
        isLoading={isLoading}
        options={{
          exportButton: true,
          //   pageSize: 5,
          //   maxBodyHeight: 437,
          rowStyle: {
            fontSize: 12,
          },
          pageSizeOptions: [5, 10, 20, 100, 1000, 500],
          grouping: false,
          filtering: false,
        }}
      />
    </>
  );
}
