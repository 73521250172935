import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Tabs, Tab, Col } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import FacebookIcon from "@material-ui/icons/Facebook";
import PhoneIcon from "@material-ui/icons/Phone";
import InstagramIcon from "@material-ui/icons/Instagram";
import LanguageIcon from "@material-ui/icons/Language";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import {
  GetShopById,
  GetMembershipPackage,
  GetFields,
  UpdateProfile,
} from "./service";
import moment from "moment";
import Limitation from "./limitation";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  LabelList,
  Legend,
} from "recharts";
import CancelIcon from "@material-ui/icons/Cancel";
import { Link } from "react-router-dom";
export default function ShopDetails({ classes }) {
  // 6136995480
  //7101514418 -  textile
  //8282907746  - saloon
  // 3859310120  -  mobile
  // 7793187914  - super market

  // prod

  // 913253693

  const todayDate = new Date().toISOString().substring(0, 10);
  const [userId10Digit, setUserId10Digit] = useState("");
  const [errMessage, seterrMessage] = useState("");
  const [key, setKey] = useState("offer");
  const [editProfile, setEditProfile] = useState({
    imageFile: [],
    imagePreview: "",
  });
  const [validShop, setvalidShop] = useState(false);
  const [profile, setProfile] = useState({
    deliverySlot: {},
    typeOfService: [],
  });
  const [state, setState] = useState({ membership: [], segment: [] });
  const [membership, setMembership] = useState({}); // membership package
  const [bill, setBill] = useState({});
  const [delivery, setDelivery] = useState({ status: [] });
  const [appointment, setAppointment] = useState({ status: [] });
  const [loyalty, setLoyalty] = useState({});
  const [offer, setOffer] = useState({ status: {} });
  const [collection, setCollection] = useState({ status: {} });
  const [coupon, setCoupon] = useState({ status: {} });
  const [product, setProduct] = useState(0);
  const [website, setWebsite] = useState({});
  const [editProfileState, setEditProfileState] = useState(false);
  useEffect(() => getData(), []);
  const getData = async () => {
    let membershipPackage = await GetMembershipPackage(); // Get all Membership

    let fields = await GetFields(); // get all Json fields
    // get segment
    let segmentList = fields.shopTypes
      .slice(1)
      .map((items) => <option value={items.value}>{items.value}</option>);

    setState({
      membership: membershipPackage,
      segment: segmentList,
    });
  };
  // useEffect(() => {
  //   verifyShopById();
  // }, [state]);
  // const verifyShopById = async () => {
  const verifyShopById = async (e) => {
    e.preventDefault();
    // Verify shop is valid or not
    await getShopProfile();
  };

  const getShopProfile = async () => {
    await GetShopById(userId10Digit).then((data) => {
      if (data.status) {
        let shopProfile = data.profile;

        let findPackageName = state.membership.find(
          ({ membershipId }) => shopProfile.membershipId == membershipId
        );
        // Track Membership
        let trackMembership = {
          status: todayDate < shopProfile.membershipExpiry ? true : false,
          packageName:
            findPackageName !== undefined
              ? findPackageName.membershipType
              : "Not Defined",
          updatedOn: shopProfile.membershipupdatedOn,
          expiryDate:
            todayDate < shopProfile.membershipExpiry
              ? `${shopProfile.membershipExpiry} (${moment(todayDate).diff(
                  moment(shopProfile.membershipExpiry),
                  "days"
                )} days remaining)`
              : "Expired",
        };
        // Track bills
        let trackBills = {
          status: shopProfile.billingPackage == "yes",
          expiration:
            todayDate < shopProfile.billingExpiryDate
              ? `${shopProfile.billingExpiryDate} (${moment(todayDate).diff(
                  moment(shopProfile.billingExpiryDate),
                  "days"
                )} days remaining)`
              : "Expired",
          totalBills: data.bill.bills,
          sales: data.bill.totalAmount,
          averageSales:
            parseInt(data.bill.totalAmount) / parseInt(data.bill.bills),
        };

        // Track delivery

        let trackDelivery = {
          status: shopProfile.deliveryPackage == "yes",
          expiration:
            todayDate < shopProfile.deliveryExpiryDate
              ? `${shopProfile.deliveryExpiryDate} (${moment(todayDate).diff(
                  moment(shopProfile.deliveryExpiryDate),
                  "days"
                )} days remaining)`
              : shopProfile.deliveryExpiryDate,
          total: data.order.total,
          status: data.order.status,
        };

        // Track Appointment

        let trackAppointment = {
          status: shopProfile.appointmentPackage == "yes",
          expiration:
            todayDate < shopProfile.appointmentExpiryDate
              ? `${shopProfile.appointmentExpiryDate} (${moment(todayDate).diff(
                  moment(shopProfile.appointmentExpiryDate),
                  "days"
                )} days remaining)`
              : shopProfile.appointmentExpiryDate,
          total: data.appointment.total,
          status: data.appointment.status,
        };

        // track loyalty

        let trackLoyalty = {
          status: shopProfile.loyaltyExpiryDate == "yes",
          expiration:
            todayDate < shopProfile.loyaltyExpiryDate
              ? `${shopProfile.loyaltyExpiryDate} (${moment(todayDate).diff(
                  moment(shopProfile.loyaltyExpiryDate),
                  "days"
                )} days remaining)`
              : shopProfile.loyaltyExpiryDate,
          customer: data.loyalty.uniqueCustomer.length,
        };

        // Track website
        let trackWebsite = {
          status: shopProfile.websiteStatus,
          expiration:
            todayDate < shopProfile.websiteExpiryOn
              ? `${shopProfile.websiteExpiryOn} (${moment(todayDate).diff(
                  moment(shopProfile.websiteExpiryOn),
                  "days"
                )} days remaining)`
              : "Expired",
          url: shopProfile.websiteUrl,
        };

        setCoupon(data.coupon);
        setOffer(data.offer);
        setCollection(data.collection);
        setProfile((prevState) => ({ ...prevState, ...data.profile }));
        setMembership(trackMembership);
        setvalidShop(true);
        setBill(trackBills);
        setDelivery(trackDelivery);
        setAppointment(trackAppointment);
        setLoyalty(trackLoyalty);
        setProduct(data.product);
        setWebsite(trackWebsite);
        setEditProfile((prevState) => ({
          ...prevState,
          shopAddress: shopProfile.shopAddress,
          shopName: shopProfile.shopName,
          shopImageLocation:
            typeof shopProfile.shopImageLocation == "string" &&
            shopProfile.shopImageLocation != "removed"
              ? shopProfile.shopImageLocation
              : Limitation.defaultImage,
          shopType: shopProfile.shopType,
          userPincode: shopProfile.userPincode,
        }));

        // getGraphStats(shopProfile.userId);
      } else {
        seterrMessage("Invalid Shop");
        console.log("Failed due to", data);
      }
    });
  };

  const getGraphStats = async (shopId) => {
    // await getOfferStats();
  };

  const onChange = (name, value) => {
    setEditProfile((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleImage = (e) => {
    // handle profile image upload

    let reader = new FileReader();
    let imageFormData = new FormData();
    imageFormData.append("photo", e.target.files[0]);
    reader.onloadend = () => {
      setEditProfile((prevState) => ({
        ...prevState,
        imageFile: reader.result.replace(/^data:image\/\w+;base64,/, ""),
        imagePreview: reader.result,
      }));
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const updateShopProfile = async () => {
    //validation

    let input = editProfile;

    if (input.shopName === "") return alert("Enter Shop Name");

    if (input.shopAddress === "") return alert("Enter Shop Address");
    if (input.shopType === "") return alert("Enter Shop Type");
    if (input.userPincode === "") return alert("Enter Pincode");
    if (!(/^\d+$/.test(input.userPincode) && input.userPincode.length == 6))
      return alert("Enter valid pincode");

    delete input.imagePreview;
    delete input.shopImageLocation;
    input.userId = profile.userId;
    // Profile update
    await UpdateProfile(editProfile).then((data) => {
      // Reset after successful
      alert("Profile updated sucessfully");
      setEditProfile((prevState) => ({
        ...prevState,
        imageFile: [],
        imagePreview: "",
      }));
      getShopProfile();
      setEditProfileState(false);
    });
  };
  


  
  return (
    <>
      <div className="titleHeader">
        <h4>Shop Details</h4>
      </div>
      <div className="vertical-space-10"></div>
      <div className="flexRow">
        <Col className="dashboardColumnBox columnCenter" sm="8">
          <Form onSubmit={verifyShopById}>
            <Form.Row>
              <Col sm="4">
                <Form.Label>Enter Shop Id</Form.Label>
              </Col>
              <Col sm="6">
                <Form.Control
                  onChange={(e) => setUserId10Digit(e.target.value)}
                  value={userId10Digit}
                />
                <Form.Text
                  style={{
                    color: "red",
                    display: errMessage.length > 0 ? "" : "none",
                  }}
                >
                  {errMessage}
                </Form.Text>
              </Col>
              <Col sm="2">
                <Button type="submit">Search</Button>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </div>
      <div className="vertical-space-10"> </div>
      {validShop && (
        <>
          <div className="flexRow">
            <div className="labelWrapper">
              <h5>Profile </h5>
            </div>

            <div className="profileBox">
              <div className="profilePicture">
                <>
                  <div className="shopImage">
                    <div
                      style={{
                        display: editProfileState ? "initial" : "none",
                      }}
                      onClick={() =>
                        setEditProfile((prevState) => ({
                          ...prevState,
                          shopImageLocation: "",
                          imageFile: [],
                          imagePreview: "",
                        }))
                      }
                      className="removeIcon"
                    >
                      <CancelIcon />
                    </div>
                    <img
                      src={
                        editProfile.shopImageLocation ||
                        editProfile.imagePreview
                      }
                    ></img>
                    <div className="vertical-space-10"></div>
                    <input
                      style={{
                        display: editProfileState ? "initial" : "none",
                      }}
                      onChange={(e) => handleImage(e)}
                      type="file"
                    ></input>
                  </div>
                </>
              </div>
              <div className="profileContent">
                <div className="inputboxWrapper">
                  <div className="inputbox">
                    <label>Shop Name</label>
                    <input
                      disabled={!editProfileState}
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      value={editProfile.shopName}
                      name="shopName"
                    ></input>
                  </div>
                  <div className="inputbox">
                    <label>Shop Type</label>
                    <select
                      disabled={!editProfileState}
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      value={editProfile.shopType}
                      name="shopType"
                    >
                      {state.segment}
                    </select>
                  </div>
                  <div className="inputbox">
                    <label>Address</label>
                    <input
                      disabled={!editProfileState}
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      value={editProfile.shopAddress}
                      name="shopAddress"
                    ></input>
                  </div>
                </div>
                <div className="inputboxWrapper">
                  <div className="inputbox">
                    <label>Username</label>
                    <input readOnly value={profile.userName}></input>
                  </div>

                  <div className="inputbox">
                    <label>Pincode</label>
                    <input
                      disabled={!editProfileState}
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      value={editProfile.userPincode}
                      name="userPincode"
                      pattern="[0-9]{6}"
                    ></input>
                  </div>
                  <div className="inputbox">
                    <label>Ghoshak Id</label>
                    <input readOnly value={profile.userId10Digit}></input>
                  </div>
                </div>
                <div className="inputboxWrapper">
                  <div className="inputbox">
                    <label>Google Location :</label>
                    <span>
                      {typeof profile.pin == "undefined" ? (
                        <>
                          <Badge pill variant="danger">
                            Disabled
                          </Badge>
                          <br />
                        </>
                      ) : (
                        <>
                          <Badge pill variant="success">
                            Enabled
                          </Badge>

                          <span className="label">Lat :</span>
                          <span className="value">
                            {profile.pin.location.lat}
                          </span>

                          <span className="label">Long :</span>
                          <span className="value">
                            {profile.pin.location.lon}
                          </span>
                        </>
                      )}
                    </span>
                  </div>
                  <div className="inputbox">
                    <label>Total Employee</label>

                    <span className="value">
                      {profile.employee != undefined
                        ? Object.keys(profile.employee).length
                        : 0}
                    </span>
                  </div>
                </div>
              </div>
              <div id="subtitle" className="labelWrapper">
                <h5 id="subtitle">Shop Timings </h5>
              </div>
              {typeof profile.shopTimings == "undefined" ? (
                <p></p>
              ) : (
                <>
                  <ul className="package">
                    {Object.entries(profile.shopTimings).map(([key, value]) => {
                      return (
                        <>
                          <li style={{ flexFlow: "column wrap" }}>
                            <label>{key}</label>
                            <div className="value column">
                              {value.openTime} - {value.closeTime}
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </>
              )}

              <div id="subtitle" className="labelWrapper">
                <h5 id="subtitle">Payment </h5>
              </div>
              <ul className="package">
                <li>
                  <label>Method</label>
                  <div className="value">{profile.paymentMethod}</div>
                </li>
                <li>
                  <label>Payment Number</label>
                  <div className="value">{profile.paymentNumber}</div>
                </li>
              </ul>

              <div id="subtitle" className="labelWrapper">
                <h5 id="subtitle">Contact </h5>
              </div>
              <ul className="package">
                <li>
                  <label>
                    <PhoneIcon />
                  </label>
                  <div className="value">
                    {profile.userPhoneNumber.slice(-10)}
                  </div>
                </li>
                <li>
                  <label>
                    <FacebookIcon />
                  </label>
                  <div className="value">
                    {profile.fbLink != undefined ? (
                      <div
                        style={{ color: "#2c2ceb", fontWeight: "500" }}
                        onClick={() => {
                          window.open(
                            profile.fbLink.includes("https")
                              ? profile.fbLink
                              : `https://www.facebook.com/${profile.fbLink}`
                          );
                        }}
                      >
                        {profile.fbLink.includes("https")
                          ? profile.fbLink
                          : `https://www.facebook.com/${profile.fbLink}`}
                      </div>
                    ) : (
                      "NA"
                    )}
                  </div>
                </li>
                <li>
                  <label>
                    <InstagramIcon />
                  </label>
                  <div className="value">
                    {profile.instaLink != undefined ? (
                      <div
                        style={{ color: "#2c2ceb", fontWeight: "500" }}
                        onClick={() => {
                          window.open(
                            profile.instaLink.includes("https")
                              ? profile.instaLink
                              : `https://www.facebook.com/${profile.instaLink}`
                          );
                        }}
                      >
                        {profile.instaLink.includes("https")
                          ? profile.instaLink
                          : `https://www.facebook.com/${profile.instaLink}`}
                      </div>
                    ) : (
                      "NA"
                    )}
                  </div>
                </li>
              </ul>

              <div id="subtitle" className="labelWrapper">
                <h5 id="subtitle">Reference </h5>
              </div>
              <ul className="package">
                <li>
                  <label>Ref Id</label>
                  <div className="value">{profile.userId}</div>
                </li>
              </ul>

              <div
                style={{ flexBasis: "100%", justifyContent: "flex-end" }}
                className="inputboxWrapper"
              >
                {editProfileState ? (
                  <>
                    <Button
                      onClick={() => updateShopProfile()}
                      style={{ margin: "0px 5px" }}
                      variant="success"
                    >
                      Update
                    </Button>
                    <Button
                      onClick={() => {
                        setEditProfile({
                          shopAddress: profile.shopAddress,
                          shopName: profile.shopName,
                          shopImageLocation: profile.shopImageLocation,
                          shopType: profile.shopType,
                          userPincode: profile.userPincode,
                        });
                        setEditProfileState(false);
                      }}
                      style={{ margin: "0px 5px" }}
                      variant="danger"
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => setEditProfileState(true)}
                      style={{ margin: "0px 5px" }}
                      variant="secondary"
                    >
                      Edit
                    </Button>
                  </>
                )}
              </div>
            </div>

            <div className="labelWrapper">
              <h5>Membership </h5>
            </div>
            <div className="profileBox">
              <ul className="package">
                <li>
                  <label>Package Status</label>
                  <div className="value">
                    {membership.status ? (
                      <Badge pill variant="success">
                        Active
                      </Badge>
                    ) : (
                      <Badge pill variant="danger">
                        Expired
                      </Badge>
                    )}
                  </div>
                </li>
                <li>
                  <label>Package Name</label>
                  <div className="value">{membership.packageName}</div>
                </li>
                <li>
                  <label>Expiration</label>
                  <div className="value">{membership.expiryDate}</div>
                </li>
                <li>
                  <label>Last Action</label>
                  <div className="value">{membership.updatedOn}</div>
                </li>
              </ul>
            </div>

            <div className="labelWrapper">
              <h5>Billing </h5>
            </div>
            <div className="profileBox">
              <ul className="package">
                <li>
                  <label>Package Status</label>
                  <div className="value">
                    {bill.status ? (
                      <>
                        <Badge pill variant="success">
                          Active
                        </Badge>
                        <small>Expires on {bill.expiration}</small>
                      </>
                    ) : (
                      <Badge pill variant="danger">
                        Expired
                      </Badge>
                    )}
                  </div>
                </li>
                <li>
                  <label>Bills Generated</label>
                  <div className="value">{bill.totalBills}</div>
                </li>
                <li>
                  <label>Sales Amount</label>
                  <div className="value">₹ {bill.sales}</div>
                </li>
                <li>
                  <label>Avg Sales</label>
                  <div className="value">₹ {bill.averageSales}</div>
                </li>
              </ul>
            </div>

            <div className="labelWrapper">
              <h5>Products </h5>
              <div className="action">View All</div>
            </div>
            <div className="profileBox">
              <ul className="package">
                <li>
                  <label>Total Products/Service</label>
                  <div className="value">
                    {parseInt(product.live || 0) +
                      parseInt(product.undefined || 0)}
                  </div>
                </li>
              </ul>
            </div>

            <div className="labelWrapper">
              <h5>Website </h5>
              <div className="action">View All</div>
            </div>
            <div className="profileBox">
              <ul className="package">
                <li>
                  <label>Package Status</label>
                  <div className="value">
                    {website.status == "live" ? (
                      <>
                        <Badge pill variant="success">
                          Active
                        </Badge>
                        &nbsp;
                        <small>valid till {website.expiration}</small>
                      </>
                    ) : website.status == "draft" ? (
                      <Badge pill variant="warning">
                        Draft
                      </Badge>
                    ) : (
                      <Badge pill variant="danger">
                        Expired
                      </Badge>
                    )}
                  </div>
                </li>
                <li>
                  <label>Website Url</label>
                  <div className="value">
                    <a
                      style={{ display: "table-cell" }}
                      href={website.url}
                      target="_blank"
                    >
                      {website.url}
                    </a>
                  </div>
                </li>
              </ul>
            </div>

            <div className="labelWrapper">
              <h5>Delivery </h5>
              <Link
                to={{
                  pathname: `/shop/${profile.userId}`,
                }}
              >
                <div className="action">View All</div>
              </Link>
            </div>
            <div className="profileBox">
              <ul className="package">
                <li>
                  <label>Package Status</label>
                  <div className="value">
                    {delivery.status ? (
                      <>
                        <Badge pill variant="success">
                          Active
                        </Badge>
                        &nbsp;
                        <small>valid till {delivery.expiration}</small>
                      </>
                    ) : (
                      <Badge pill variant="danger">
                        Expired
                      </Badge>
                    )}
                  </div>
                </li>
                <li>
                  <label>Enabled Order</label>
                  <div className="value">
                    {profile.typeOfService.map((items) => (
                      <div className="value">
                        <CheckCircleOutlineIcon className="tickCircle" />
                        {items}
                      </div>
                    ))}
                  </div>
                </li>
                <li>
                  <label>Delivery Slot</label>
                  <div className="value">
                    {Object.entries(profile.deliverySlot || {}).map(
                      ([key, value]) => (
                        <Badge className="deliverySlot" pill variant="primary">
                          {`${value.startTime} - ${value.endTime}`}
                        </Badge>
                      )
                    )}
                  </div>
                </li>
              </ul>
            </div>
            <div className="vertical-space-10"></div>
            <div id="displayTable" className="flexColumn">
              <h6>Order Status</h6>
              <TableContainer className={classes.container}>
                <Table size="small" stickyHeader className={classes.table}>
                  <TableHead className={classes.tableHeadBody}>
                    <TableRow>
                      <TableCell className={classes.tableHead}>Type</TableCell>
                      <TableCell className={classes.tableHead}>
                        Order Placed
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Order Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {delivery.status.map((element) => (
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          {element.status}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {element.count}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          ₹ {element.amount}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div className="labelWrapper">
              <h5>Appointment </h5>
              {/* <Link
                to={{
                  pathname: `/shop/${profile.userId}`,
                }}
              ></Link> */}
              <div className="action">View All</div>
            </div>
            <div className="profileBox">
              <ul className="package">
                <li>
                  <label>Package Status</label>
                  <div className="value">
                    {appointment.status ? (
                      <>
                        <Badge pill variant="success">
                          Active
                        </Badge>
                        &nbsp;
                        <small>valid till {appointment.expiration}</small>
                      </>
                    ) : (
                      <Badge pill variant="danger">
                        Expired
                      </Badge>
                    )}
                  </div>
                </li>
              </ul>
            </div>
            <div className="vertical-space-10"></div>

            <div className="flexRow">
              <div id="pinBox" className="flexColumn">
                <h6>Order Status</h6>
                <TableContainer className={classes.container}>
                  <Table size="small" stickyHeader className={classes.table}>
                    <TableHead className={classes.tableHeadBody}>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          Type
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Order Placed
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Order Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appointment.status.map((element) => (
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            {element.status}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {element.count}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            ₹ {element.amount}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div id="pinBox" className="flexColumn">
                <ResponsiveContainer aspect={2}>
                  <BarChart
                    width={600}
                    height={300}
                    data={appointment.status}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="status"
                      tickLine={true}
                      xAxisId={0}
                      dy={0}
                      dx={-0}
                      label={{
                        value: "",
                        angle: 0,
                        position: "bottom",
                      }}
                      interval={0}
                      tickLine={false}
                      tick={{ fontSize: 9, angle: 0 }}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="count" fill="#8884d8" />
                    <LabelList dataKey="count " position="top" />
                    <Bar dataKey="amount" fill="#82ca9d" />
                    <LabelList dataKey="Sales" position="top" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="labelWrapper">
              <h5>Loyalty </h5>
            </div>
            <div className="profileBox">
              <ul className="package">
                <li>
                  <label>Package Status</label>
                  <div className="value">
                    {loyalty.status ? (
                      <>
                        <Badge pill variant="success">
                          Active
                        </Badge>
                        &nbsp;
                        <small>valid till {loyalty.expiration}</small>
                      </>
                    ) : (
                      <Badge pill variant="danger">
                        Expired
                      </Badge>
                    )}
                  </div>
                </li>
                <li>
                  <label>Points Configured</label>
                  <div className="value">
                    {parseFloat(profile.loyaltyRatio) > 0 ? (
                      <Badge pill variant="success">
                        configured
                      </Badge>
                    ) : (
                      <Badge pill variant="warning">
                        not configured
                      </Badge>
                    )}
                  </div>
                </li>
                <li>
                  <label>Ratio</label>
                  <div className="value">
                    {profile.loyaltyRatio}&nbsp;&nbsp;
                    {`(${profile.loyaltyAmount}/${profile.loyaltyRatio})`}
                  </div>
                </li>
                <li>
                  <label>Customers</label>
                  <div className="value">{loyalty.customer}</div>
                </li>
              </ul>
            </div>

            <div className="labelWrapper">
              <h5>Collection </h5>
              <div className="action">View All</div>
            </div>
            <div className="flexRow">
              <div id="pinBox" className="flexColumn">
                <h6>Collection Status</h6>
                <TableContainer className={classes.container}>
                  <Table size="small" stickyHeader className={classes.table}>
                    <TableHead className={classes.tableHeadBody}>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          Status
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          No. of offers
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(collection.status).map((element) => (
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            {element}
                          </TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row">
                            {collection.status[element]}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>

            <div className="labelWrapper">
              <h5>Offer </h5>
              <div className="action">View All</div>
            </div>
            <div className="flexRow">
              <div id="pinBox" className="flexColumn">
                <h6>Offer Status</h6>
                <TableContainer className={classes.container}>
                  <Table size="small" stickyHeader className={classes.table}>
                    <TableHead className={classes.tableHeadBody}>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          Status
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          No. of offers
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(offer.status).map((element) => (
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            {element}
                          </TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row">
                            {offer.status[element]}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>

            <div className="labelWrapper">
              <h5>Coupon </h5>
              <div className="action">View All</div>
            </div>
            <div className="flexRow">
              <div id="displayTable" className="flexColumn">
                <TableContainer className={classes.container}>
                  <Table size="small" stickyHeader className={classes.table}>
                    <TableHead className={classes.tableHeadBody}>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          Type
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Status
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Total Coupons
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Redeemed/claimed coupons
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Allocated Cost
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(coupon.status).map(([key, value]) => {
                        return (
                          <TableRow key={key}>
                            <TableCell className={classes.tableCell}>
                              {key}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <ul className="package">
                                {Object.keys(value.action).map((elements) => (
                                  <li>
                                    {elements} : {value.action[elements]}
                                  </li>
                                ))}
                              </ul>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {value.stats.totalCoupons}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {value.stats.consumed}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              ₹ {value.stats.allocatedCost}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
          <div className="vertical-space-10"></div>
        </>
      )}
    </>
  );
}
