// Import Dependencies

import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import config from "../config";

function Gamification(props) {
  const [gameClaims, setGameClaims] = useState([]);
  const [gameClaimsHistory, setGameClaimsHistory] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [reference, setReference] = useState({});

  const [shopName, setShopName] = useState("");
  const [shop10Digti, setShop10Digit] = useState("");

  useEffect(() => {
    fetchAllRequest();
  }, []);

  const fetchAllRequest = async () => {
    let response = await fetch(`${config.ApiGateway}/getGamificationHistory`, {
      method: "POST",
      body: JSON.stringify({
        shopId: props.location.state,
        type: "gameClaim",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());

    let temp = response.Items.sort(
      (a, b) => parseInt(b.createdAt) - parseInt(a.createdAt)
    );
    setShopName(temp[0]?.shopName);
    //            { title: "shop id", field: "userId10Digit" },
    setShop10Digit(temp[0]?.userId10Digit);

    setGameClaims(temp);
  };

  const getPreviousClaimDate = (date) => {
    let currentIndex = gameClaims.findIndex((item) => item.createdAt == date);

    getHistoryByClaim(
      gameClaims.length - currentIndex === 1
        ? 1616240000000
        : gameClaims[currentIndex + 1].createdAt,
      date
    );
  };
  const getHistoryByClaim = async (from, to) => {
    let response = await fetch(`${config.ApiGateway}/getGamificationHistory`, {
      method: "POST",
      body: JSON.stringify({
        shopId: props.location.state,
        type: "game",
        from,
        to,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
    // console.log(response);
    let sortedArray = response.Items.sort(
      (a, b) => parseInt(b.createdAt) - parseInt(a.createdAt)
    );
    console.log(sortedArray);
    setGameClaimsHistory(sortedArray);
    // setGameClaims(response.Items);
  };
  return (
    <>
      <Container fluid>
        <div className="orderHeader">
          <h2 className="orderHeaderText">
            Gamification Claims - {shopName} - {shop10Digti}{" "}
          </h2>
        </div>
        <MaterialTable
          actions={[
            (rowData) => ({
              icon: "details",
              tooltip: "more details",
              onClick: (event, rowData) => {
                getPreviousClaimDate(rowData.createdAt);
              },
            }),
          ]}
          columns={[
            { title: "date", field: "transactionDate" },
            { title: "claimed points", field: "points" },
            { title: "status", field: "status" },
            { title: "updated by", field: "updatedBy" },
            { title: "paid by", field: "paidBy" },
            { title: "paymentId", field: "paymentId" },
          ]}
          data={gameClaims}
          title="Claim History"
          options={{
            search: true,
          }}
        />
        {gameClaimsHistory && (
          <MaterialTable
            // actions={[
            //   (rowData) => ({
            //     icon: "details",
            //     tooltip: "more details",
            //     onClick: (event, rowData) => {
            //       console.log(rowData.reference);
            //       setReference(rowData.reference);
            //       setModalOpen(true);
            //     },
            //   }),
            // ]}
            columns={[
              { title: "date", field: "transactionDate" },
              { title: "Points", field: "points" },
              { title: "Description", field: "description" },
              {
                title: "Customer Phone",
                field: "reference.customerPhoneNumber",
              },
              { title: "Amount Paid", field: "reference.amountPaid" },
              { title: "Paid Date", field: "paidDate" },

              { title: "Bill open status", field: "reference.billOpened" },
            ]}
            data={gameClaimsHistory}
            title="Points History"
            options={{
              search: true,
              exportButton: true,
            }}
          />
        )}
      </Container>
      <Modal centered show={modalOpen} onHide={() => setModalOpen(false)}>
        <Modal.Header> details </Modal.Header>
        <Modal.Body>
          {Object.entries(reference).map((item) => {
            return (
              <p>
                {" "}
                {item[0]} - {item[1]}{" "}
              </p>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Gamification;
