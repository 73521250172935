import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import config from "../config";
export default class MembershipDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableArray: [],
      membershipResult: false
    };
  }

  async componentDidMount() {
    try {
      const response = await fetch(`${config.json.URL}/membershipLimits`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json"
        }
      });
      const json = await response.json();
      console.log(json);
      this.setState({ tableArray: json, membershipResult: true });
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    return (
      <>
        {this.state.membershipResult && (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S NO</TableCell>
                  <TableCell>MEMBERSHIP</TableCell>
                  <TableCell>MEMBERSHIP TYPE</TableCell>
                  <TableCell>NO OF ITEMS</TableCell>
                  <TableCell>NO OF OFFERS</TableCell>
                  <TableCell>NOTIFICATION PER DAY</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tableArray.Items.map((item, index) => (
                  <TableRow>
                    <>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.membershipId}</TableCell>

                      <>
                        <TableCell>{item.membershipType}</TableCell>
                        <TableCell>{item.noOfItems}</TableCell>
                        <TableCell>{item.noOfOffers}</TableCell>
                        <TableCell>{item.notificationPerDay}</TableCell>
                      </>
                    </>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </>
    );
  }
}
