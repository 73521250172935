import React, { useState, useEffect } from "react";
import config from "../config";
import { Container, Col, Button, Row } from "react-bootstrap";
import MaterialTable from "material-table";
export default function DistributionOfCollectionCategory() {
  const [days, setDays] = useState("30");
  const handleClick = (value) => {
    setDays(value);
  };
  const [man, setMan] = useState({
    column: [],
    data: [],
  });
  const [woman, setWoman] = useState({
    column: [],
    data: [],
  });
  const [boys, setBoys] = useState({
    column: [],
    data: [],
  });
  const [girls, setGirls] = useState({
    column: [],
    data: [],
  });
  useEffect(() => {
    getCollectionCategory();
  }, [days]);

  const getCollectionCategory = async () => {
    return await Promise.all([
      fetch(`${config.ApiGateway}/distributionPincodeByCollectionCategory`, {
        method: "POST",
        body: JSON.stringify({
          lastActivityDays: days,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        // Array Initialiation
        let menArray = [];
        let menObjKeys = [];
        let womenArray = [];
        let womanObjKeys = [];
        let girlsArray = [];
        let girlObjKeys = [];
        let boysArray = [];
        let boysObjKeys = [];
        // data structure pincode = {category : category list, count : category count}
        Object.entries(data).forEach(([key, value]) => {
          // store men category

          menArray.push(
            Object.assign(
              {},
              { pincode: key, count: value.count.Men || 0 },
              value.category.Men
            )
          );
          // store woman category
          womenArray.push(
            Object.assign(
              {},
              { pincode: key, count: value.count.Women || 0 },
              value.category.Women
            )
          );
          // store girls category

          girlsArray.push(
            Object.assign(
              {},
              { pincode: key, count: value.count.Girls || 0 },
              value.category.Girls || {}
            )
          );
          // store boys category
          boysArray.push(
            Object.assign(
              {},
              { pincode: key, count: value.count.Boys || 0 },
              value.category.Boys
            )
          );
        });

        // Extract men table header value

        [
          ...new Set(menArray.map(Object.keys).reduce((a, b) => a.concat(b))),
        ].map((i) => {
          menObjKeys.push({ title: i, field: i });
        });
        // Extract woman table header value

        [
          ...new Set(womenArray.map(Object.keys).reduce((a, b) => a.concat(b))),
        ].map((i) => {
          womanObjKeys.push({ title: i, field: i });
        });
        // Extract girls table header value

        [
          ...new Set(girlsArray.map(Object.keys).reduce((a, b) => a.concat(b))),
        ].map((i) => {
          girlObjKeys.push({ title: i, field: i });
        });

        // Extract boys table header value
        [
          ...new Set(boysArray.map(Object.keys).reduce((a, b) => a.concat(b))),
        ].map((i) => {
          boysObjKeys.push({ title: i, field: i });
        });

        setMan({
          data: menArray,
          column: menObjKeys,
        });
        setWoman({
          data: womenArray,
          column: womanObjKeys,
        });
        setBoys({
          data: boysArray,
          column: boysObjKeys,
        });
        setGirls({
          data: girlsArray,
          column: girlObjKeys,
        });
        return;
      })
      .catch((err) => console.log(err, "Error "));
  };

  return (
    <Container>
      <div style={{ width: "100%", height: "40px" }}></div>
      <div className="orderHeader">
        <h2 className="orderHeaderText">Collection category</h2>
      </div>
      <div className="flexCardHeader">
        <label className="flexCardHeaderlabel"></label>

        <nav class="site-nav">
          <ul className="flexCardUl">
            <li
              className={days === "15" ? "active" : ""}
              onClick={() => handleClick("15")}
            >
              <a href="#">15</a>
            </li>
            <li
              className={days === "30" ? "active" : ""}
              onClick={() => handleClick("30")}
            >
              <a href="#">30</a>
            </li>
            <li
              className={days === "90" ? "active" : ""}
              onClick={() => handleClick("90")}
            >
              <a href="#">90</a>
            </li>
            <li
              className={days === "182" ? "active" : ""}
              onClick={() => handleClick("182")}
            >
              <a href="#">182</a>
            </li>
            <li
              className={days === "365" ? "active" : ""}
              onClick={() => handleClick("365")}
            >
              <a href="#">365</a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="vertical-space-30"></div>

      <RenderTableData
        data={man.data}
        columns={man.column}
        title={"Men Collection"}
      />

      <RenderTableData
        data={woman.data}
        columns={woman.column}
        title={"Woman Collection"}
      />

      <RenderTableData
        data={boys.data}
        columns={boys.column}
        title={"Boys Collection"}
      />

      <RenderTableData
        data={girls.data}
        columns={girls.column}
        title={"Girls Collection"}
      />
    </Container>
  );
}
const RenderTableData = ({ columns, data, title }) => {
  //Dynamic table for all category (Men,boys,woman,girls)
  return (
    <>
      <div className="vertical-space-20"></div>
      <div className="orderHeader">
        <h2 className="orderHeaderText">{title}</h2>
      </div>
      <MaterialTable
        columns={columns}
        title={title}
        data={data}
        options={{
          exportButton: true,
          pageSize: 5,
          pageSizeOptions: [5, 10, 20, 100, 300, 500, 1000, 2000],
          grouping: false,
          filtering: false,
        }}
      />
      <div className="vertical-space-20"></div>
    </>
  );
};
