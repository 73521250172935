import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import MaterialTable from "material-table";
import config from "../../config";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    textAlign: "center",

    paddingLeft: 5,
  },
});
class SegmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [],
      showdata: false,
      showTable: [],
      title: "",
    };
    this.getMerchantDetails = this.getMerchantDetails.bind(this);
    this.csvLink = React.createRef();
    this.export = this.export.bind(this);
  }
  getMerchantDetails(userid) {
    this.props.getMerchant(userid);
  }
  export() {
    const headers = [
      { label: "PINCODE", key: "pincode" },
      { label: "SHOP NAME", key: "shopName" },
      { label: "SHOP TYPE", key: "shopType" },
      { label: "USER ID", key: "userId10Digit" },
      { label: "ADDRESS", key: "userAddress" },
      { label: "PHONE NUMBER", key: "userPhoneNumber" },
      { label: "NAME", key: "userName" },
      { label: "USER ADDED ON", key: "userAddedDate" },
    ];

    this.setState(
      {
        headers: headers,
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }
  async componentDidMount() {
    await this.getPIN();
  }

  getPIN() {
    Promise.all([
      fetch(`${config.ApiGateway}/getPlace`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        var mergePincodeAndArea = this.props.shops.map((items) => {
          let count = data.Items.find(
            (elements) => items.userPincode === elements.pincode
          );

          let appendObject = Object.assign({}, items, count);

          return appendObject;
        });

        return this.setState({
          showdata: true,
          showTable: mergePincodeAndArea,
          title: this.props.title,
        });
      })
      .catch((err) => alert("FUNCTION CALL FAILED"));
  }

  render() {
    if (this.state.title !== this.props.title) {
      this.getPIN();
    }
    const height = window.innerHeight;
    const { classes } = this.props;
    return (
      <>
        {this.state.showdata && (
          <>
            <div className="vertical-space-30"></div>
            <MaterialTable
              title={`${this.props.shops.length} - ${this.props.title} Shops`}
              columns={[
                {
                  title: "Shop Id",
                  field: "userId10Digit",
                  render: (rowData) => (
                    <td
                      onClick={() =>
                        this.getMerchantDetails(rowData.userId10Digit)
                      }
                    >
                      {rowData.userId10Digit}
                    </td>
                  ),
                },
                {
                  title: "Shop Name",
                  field: "shopName",
                },
                {
                  title: "Owner Name",
                  field: "userName",
                },

                {
                  title: "Shop Type",
                  field: "shopType",
                },
                {
                  title: "Address",
                  field: "shopAddress",
                },

                {
                  title: "Phone Number",
                  field: "userPhoneNumber",
                },
                {
                  title: "Pincode ",
                  field: "userPincode",
                },
                {
                  title: "Area ",
                  field: "place",
                },
                {
                  title: "Joined Ghoshak ",
                  field: "userAddedDate",
                },
              ]}
              data={this.state.showTable}
              options={{
                exportButton: true,
                pageSize: 30,
                headerStyle: { position: "sticky", top: 0 },
                maxBodyHeight: height - 48,
                pageSizeOptions: [5, 10, 20, 100, 500, 1000],
                grouping: false,
                filtering: false,
              }}
            />
          </>
        )}
      </>
    );
  }
}
SegmentDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SegmentDetails);
