import React, { Component } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import config from "../config";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
export default class DeleteCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPage: true,
      searchByPincodeOrId: "",
      couponCategory: "",
      couponDetails: [],

      userDetails: []
    };
    this.reset = this.state;
    this.onChange = this.onChange.bind(this);
    this.getCouponRecord = this.getCouponRecord.bind(this);
    this.deleteCouponFuction = this.deleteCouponFuction.bind(this);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  componentDidUpdate() {
    {
      this.state.showPage === false &&
        (window.onpopstate = e => {
          e.preventDefault();
          if (window.confirm("are you want to leave the page")) {
            window.location.assign("/");
          } else {
            window.location.assign("/deletecoupon");
          }
        });
    }
  }
  async deleteCouponFuction(couponId) {
    let couponDeletedBy = "";
    let couponDeletedOn = new Date().toISOString().slice(0, 10);
    await Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(data => {
        let user = { email: data.email, ...data.attributes };
        couponDeletedBy = user.phone_number;
      })

      .catch(err => console.log(err));

    Promise.all([
      await fetch(
        `${config.ApiGateway}/deletecoupon`,

        {
          method: "POST",

          body: JSON.stringify({
            couponId: couponId.couponId,
            couponCategory: "Deleted",
            couponDeletedBy: couponDeletedBy,
            couponDeletedOn: couponDeletedOn
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert("COUPON DELETED SUCCESSFULLY");
        this.setState(this.reset);
      })
      .catch(err => console.log(err));
  }

  getCouponRecord(e) {
    e.preventDefault();
    Promise.all([
      fetch(
        `${config.ApiGateway}/couponSearchByPincodeOrId`,

        {
          method: "POST",

          body: JSON.stringify({
            searchByPincodeOrId: this.state.searchByPincodeOrId,
            couponCategory: this.state.couponCategory
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.setState({
          couponDetails: data,
          showPage: false
        });
      })
      .catch(err => console.log(err));
  }

  render() {
    return (
      <Container fluid>
        {this.state.showPage ? (
          <>
            <div style={{ width: 100 + "%", marginBottom: 4 + "%" }}>
              <h1 style={{ textAlign: "center" }}>DELETE COUPON</h1>
            </div>
            <Row>
              <Col md={{ span: 5, offset: 4 }}>
                <Form onSubmit={this.getCouponRecord}>
                  <Row>
                    <Col md={{ span: 4 }}>
                      <Form.Label>USER ID</Form.Label>
                    </Col>
                    <Col md={{ span: 6 }}>
                      <Form.Control
                        name="searchByPincodeOrId"
                        value={this.state.searchByPincodeOrId}
                        onChange={this.onChange}
                        type="text"
                        required
                      ></Form.Control>
                    </Col>
                  </Row>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      COUPON STATUS
                    </Form.Label>

                    <Col>
                      <Form.Check
                        label="DELETED"
                        type="radio"
                        name="couponCategory"
                        onChange={this.onChange}
                        value="Deleted"
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        label="CREATED"
                        type="radio"
                        name="couponCategory"
                        onChange={this.onChange}
                        value="Created"
                      />
                    </Col>
                  </Form.Group>
                  <div style={{ float: "right", paddingRight: 17 + "%" }}>
                    <Button type="submit">SEARCH</Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <div style={{ width: 100 + "%" }}>
              <h1 style={{ textAlign: "center" }}>
                {this.state.couponCategory} List
              </h1>
            </div>
            <Col>
              <Table
                style={{
                  border: 0.5 + "px",
                  borderColor: "#ced4da",
                  borderStyle: "solid"
                }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S NO</TableCell>
                    <TableCell align="center">USER ID</TableCell>
                    <TableCell align="center">COUPON ID</TableCell>
                    <TableCell align="center">PINCODE</TableCell>

                    <TableCell align="center">EXPIRY DATE</TableCell>
                    <TableCell align="center">COUPON TYPE</TableCell>

                    <TableCell align="center">SHOPNAME</TableCell>
                    <TableCell align="center">ADDRESS</TableCell>
                    <TableCell align="center">PHONE NO</TableCell>
                    <TableCell align="center">COUPON COUNT</TableCell>
                    <TableCell align="center">REMAINING COUNT</TableCell>
                    <TableCell align="center">IMAGE</TableCell>
                    <TableCell align="center">DETAILS</TableCell>

                    {this.state.couponCategory === "Created" ? (
                      <>
                        <TableCell align="center"> CREATED BY</TableCell>
                        <TableCell align="center"> CREATED ON</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align="center"> DELETED BY</TableCell>
                        <TableCell align="center"> DELETED ON</TableCell>
                      </>
                    )}
                    <TableCell align="center">ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.couponDetails.map((item, index) => (
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        <Link
                          to={{
                            pathname: "/merchant",
                            search: `?userid=${item.userId10Digit}`
                          }}
                        >
                          {" "}
                          {item.userId10Digit}{" "}
                        </Link>
                      </TableCell>
                      <TableCell align="center">{item.couponId}</TableCell>
                      <TableCell align="center">{item.pincode}</TableCell>

                      <TableCell align="center">{item.expiryDate}</TableCell>

                      <TableCell align="center">{item.couponType}</TableCell>
                      <TableCell align="center">{item.shopName}</TableCell>
                      <TableCell align="center">{item.shopAddress}</TableCell>
                      <TableCell align="center">
                        {item.userPhoneNumber}
                      </TableCell>
                      <TableCell align="center">
                        {item.totalCouponCount}
                      </TableCell>
                      <TableCell align="center">
                        {item.remainingCouponCount}
                      </TableCell>
                      <TableCell align="center" key={item.imageLocation}>
                        <img
                          src={item.imageLocation}
                          alt={item.imageLocation}
                          width="200"
                          height="200"
                        ></img>
                      </TableCell>
                      <TableCell align="center">{item.couponDetails}</TableCell>

                      {this.state.couponCategory === "Created" ? (
                        <>
                          <TableCell align="center">
                            {item.couponAddedby}
                          </TableCell>
                          <TableCell align="center">{item.addedDate}</TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell align="center">
                            {item.couponDeletedBy}
                          </TableCell>
                          <TableCell align="center">
                            {item.couponDeletedOn}
                          </TableCell>
                        </>
                      )}

                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure want to delete a coupon"
                              )
                            ) {
                              this.deleteCouponFuction(item);
                            }
                          }}
                          disabled={
                            item.couponCategory === "Deleted" ? true : false
                          }
                        >
                          DELETE COUPON
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}
