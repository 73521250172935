import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import config from "../config";
export default function ActivateAppointment() {
  const [userId10Digit, setUserId10Digit] = useState("");
  const [showShopDetails, setShowShopDetails] = useState(false);
  const packageInitialState = {
    appointmentPackage: "",
    appointmentUpdatedOn: "",
    appointmentUpdatedBy: "",
    appointmentExpiryDate: "",
    appointmentTransactionId: "",
    userId: "",
  };
  const [state, setState] = useState(packageInitialState);
  const [shopInfo, setShopInfo] = useState({});
  const [message, setMessage] = useState("");
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((data) => {
        let user = { email: data.email, ...data.attributes };

        setState({ ...state, appointmentUpdatedBy: user.phone_number });
      })

      .catch((err) => console.log(err));
  }, []);

  const getShop = (e) => {
    e.preventDefault();

    fetch(
      `${config.ApiGateway}/getshop`,

      {
        method: "POST",

        body: JSON.stringify({ userId10Digit: userId10Digit }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        let userInfo = {};
        if (data.Items.length > 0) {
          data.Items.forEach((headers) => {
            if (headers.userType === "Shop") {
              userInfo = headers;
            }
          });

          setState({
            ...state,
            userId: userInfo["userId"],

            appointmentTransactionId: userInfo["appointmentTransactionId"],
            appointmentPackage: userInfo["appointmentPackage"],

            appointmentExpiryDate: userInfo["appointmentExpiryDate"],
          });
          setShopInfo(userInfo);
          setShowShopDetails(true);
        } else {
          setMessage(" No Shops Found");
        }
      })
      .catch((err) => {
        setMessage(" No Shops Found");
      });
  };

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const updatePackage = async (e) => {
    e.preventDefault();
    await fetch(`${config.ApiGateway}/activateAppointment`, {
      method: "POST",
      body: JSON.stringify(state),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        alert(data.status);
        setState({ ...packageInitialState });
        setUserId10Digit("");
        setShowShopDetails(false);
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Container>
        <div style={{ width: "100%", height: "100px" }}></div>

        <div className="pageTitleFlex">
          <h2 className="pageTitleText">Activate Appointment</h2>
        </div>

        {showShopDetails ? (
          <>
            <Row className="rowClass">
              <Col
                className="flexCard"
                style={{ display: "table-cell", padding: "16px" }}
                sm={5}
              >
                <div className="flexCardHeaderAlign">
                  <div className="orderHeader">
                    <b>Shop Info</b>
                  </div>
                </div>
                <div style={{ flex: 1, flexDirection: "column" }}>
                  <div className="shopLabeltext">
                    <label>Shop Name :</label>
                    <p>{shopInfo.shopName}</p>
                  </div>
                  <div className="shopLabeltext">
                    <label>Shop Address :</label>
                    <p>{shopInfo.shopAddress}</p>
                  </div>
                  <div className="shopLabeltext">
                    <label>Phone Number :</label>
                    <p>{shopInfo.userPhoneNumber}</p>
                  </div>
                  <div className="shopLabeltext">
                    <label>Owner Name :</label>
                    <p>{shopInfo.userName}</p>
                  </div>
                  <div className="shopLabeltext">
                    <label>Pincode:</label>
                    <p>{shopInfo.userPincode}</p>
                  </div>
                  <div className="shopLabeltext">
                    <label>User Area :</label>
                    <p>{shopInfo.userArea}</p>
                  </div>
                </div>
              </Col>

              <Col
                className="flexCard"
                style={{ display: "table-cell", padding: "16px" }}
                sm={5}
              >
                <div className="flexCardHeaderAlign">
                  <div className="orderHeader">
                    <b>Package Details</b>
                  </div>
                </div>
                <div style={{ flex: 1, flexDirection: "column" }}>
                  <Form onSubmit={updatePackage}>
                    <Form.Group as={Row}>
                      <Form.Label
                        style={{ color: "#89959b", paddingLeft: "5%" }}
                        column
                      >
                        Appointment Package
                      </Form.Label>

                      <Col xs={3}>
                        <Form.Check
                          type="radio"
                          required
                          name="appointmentPackage"
                          onChange={onChange}
                          label={"YES"}
                          value="yes"
                          checked={state.appointmentPackage === "yes"}
                        />
                      </Col>
                      <Col xs={3}>
                        <Form.Check
                          type="radio"
                          name="appointmentPackage"
                          onChange={onChange}
                          label={"NO"}
                          value="no"
                          checked={state.appointmentPackage === "no"}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label
                        style={{
                          color: "#89959b",
                          paddingLeft: "5%",
                          width: "40%",
                        }}
                        column
                      >
                        Transaction ID :
                      </Form.Label>

                      <Col style={{ marginRight: "9px", width: "40%" }}>
                        <Form.Control
                          name="appointmentTransactionId"
                          value={state.appointmentTransactionId}
                          required
                          onChange={onChange}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label
                        style={{
                          color: "#89959b",
                          paddingLeft: "5%",
                          width: "40%",
                        }}
                        column
                      >
                        Expiry Date :
                      </Form.Label>

                      <Col style={{ marginRight: "9px", width: "40%" }}>
                        <Form.Control
                          name="appointmentExpiryDate"
                          onChange={onChange}
                          value={state.appointmentExpiryDate}
                          type="date"
                          required
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Button
                      style={{ marginTop: "20px", float: "right" }}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Col>
                <h4>Package Updated By</h4>
                <p>{shopInfo.appointmentUpdatedBy}</p>
              </Col>

              <Col>
                <h4>Package Updated On</h4>
                <p>{shopInfo.appointmentUpdatedOn}</p>
              </Col>
            </Row>
          </>
        ) : (
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col style={{ marginTop: "40px" }} sm={6}>
              <Form onSubmit={getShop}>
                <Form.Label>Enter Shop ID</Form.Label>
                <Form.Control
                  name="userId10Digit"
                  value={userId10Digit}
                  onChange={(e) => setUserId10Digit(e.target.value)}
                ></Form.Control>
                <Button
                  style={{ marginTop: "20px", float: "right" }}
                  type="Submit"
                >
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
