import config from "../../config";
var ExcludePincode = "111111";
export const B2B = async () => {
  // Get all B2B Users
  return await Promise.all([
    fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        _source: {
          includes: [
            "userPincode",
            "userId",
            "userAddedDate",
            "userId10Digit",
            "shopName",
            "shopType",
            "customerPhoneNumberMap",
            "shopAddress",
          ],
        },
        query: {
          bool: {
            must: [{ match: { userType: "Shop" } }],
            must_not: {
              bool: {
                must: [{ term: { userPincode: ExcludePincode } }],
              },
            },
          },
        },
      }),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(([data]) => {
      let items = data.hits.hits.map((element) => element._source);
      return items;
    })
    .catch((err) => {
      console.log("err getting data", err);
    });
};
export const getSegment = async () => {
  // Get shop segment by count
  return await Promise.all([
    fetch(`${config.Elasticsearch.URL}/users/_search?size=0`, {
      method: "POST",
      body: JSON.stringify({
        aggs: {
          shopBucket: {
            filter: {
              bool: {
                must: [
                  {
                    match: {
                      userType: "Shop",
                    },
                  },
                  {
                    match: {
                      shopStatus: "Live",
                    },
                  },
                ],
                must_not: {
                  match: {
                    userPincode: ExcludePincode,
                  },
                },
              },
            },
            aggs: {
              segment: {
                terms: {
                  field: "shopType",
                  size: 10000,
                  order: {
                    _term: "asc",
                  },
                },
              },
            },
          },
        },
      }),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(([data]) => {
      return data.aggregations.shopBucket.segment.buckets.sort(
        (a, b) => b.doc_count - a.doc_count
      );
    })
    .catch((err) => {
      console.log("err getting data", err);
    });
};
export const getSegmentByShopType = async (shopType) => {
  // Get shop segment by shop type
  return await Promise.all([
    fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        query: {
          bool: {
            must: [{ match: { shopType: shopType } }], // query matched shop type
            must_not: {
              bool: {
                must: [{ term: { userPincode: ExcludePincode } }],
              },
            },
          },
        },
      }),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(([data]) => {
      return data.hits.hits.map((element) => element._source);
    })
    .catch((err) => {
      console.log("err getting data", err);
    });
};
export const GetOfferCreation = async (state) => {
  // Get offer
  return await Promise.all([
    fetch(`${config.Elasticsearch.URL}/offers/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              {
                match: {
                  offerType: state.offerType,
                },
              },

              {
                range: {
                  offerAddedDate: {
                    gte: state.startDate,
                    lte: state.endDate,
                    boost: 2.0,
                  },
                },
              },
            ],
            must_not: [{ term: { pincode: ExcludePincode } }],
          },
        },
      }),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(([data]) => {
      return data.hits.hits.map((element) => element._source);
    })
    .catch((err) => {
      console.log("err getting data", err);
    });
};

export const GetOfferCreationByShopId = async (state) => {
  // Get offer
  return await Promise.all([
    fetch(`${config.Elasticsearch.URL}/offers/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              {
                match: {
                  offerType: state.offerType,
                },
              },
              {
                match: {
                  shopId: "",
                },
              },
              {
                range: {
                  offerAddedDate: {
                    gte: state.startDate,
                    lte: state.endDate,
                    boost: 2.0,
                  },
                },
              },
            ],
            must_not: [{ term: { pincode: ExcludePincode } }],
          },
        },
      }),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(([data]) => {
      return data.hits.hits.map((element) => element._source);
    })
    .catch((err) => {
      console.log("err getting data", err);
    });
};

export const GetCouponByCouponType = async (state) => {
  // Get coupon

  return await Promise.all([
    fetch(`${config.Elasticsearch.URL}/coupons/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        query: {
          bool: {
            must: [
              {
                match: {
                  couponCategory: "Created",
                },
              },
              {
                match: {
                  couponType: state.couponType,
                },
              },
              {
                range: {
                  addedDate: {
                    gte: state.startDate,
                    lte: state.endDate,
                    boost: 2.0,
                  },
                },
              },
            ],
            must_not: [{ term: { pincode: ExcludePincode } }],
          },
        },
      }),

      headers: {
        "Content-Type": "application/json",
      },
    }),
    fetch(`${config.Elasticsearch.URL}/coupons/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        _source: {
          includes: [
            "paidStatus",
            "pincode",
            "couponStatus",
            "couponType",
            "shopId",
            "redeemedDate",
            "redeemedCouponId",
            "paidStatus",
          ],
        },
        query: {
          bool: {
            must: [
              {
                match: {
                  couponCategory: "Used",
                },
              },
              {
                match: {
                  couponType: state.couponType,
                },
              },
            ],
            should: [
              {
                range: {
                  redeemedDate: {
                    gte: state.startDate,
                    lte: state.endDate,
                    boost: 2.0,
                  },
                },
              },
              {
                range: {
                  claimed: {
                    gte: state.startDate,
                    lte: state.endDate,
                    boost: 2.0,
                  },
                },
              },
            ],
            must_not: [{ term: { pincode: ExcludePincode } }],
          },
        },
      }),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
    .then(([added, redeemed]) => {
      return {
        created: added.hits.hits.map((element) => element._source),
        used: redeemed.hits.hits.map((element) => element._source),
      };
    })
    .catch((err) => {
      console.log("err getting data", err);
    });
};
export const ActivatedLoyaltyShops = async () => {
  // Get all B2B Users
  return await Promise.all([
    fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        query: {
          constant_score: {
            filter: {
              bool: {
                must: [
                  { match: { userType: "Shop" } },
                  { match: { shopStatus: "Live" } },
                  {
                    exists: {
                      field: "loyaltyPoints",
                    },
                  },
                  {
                    exists: {
                      field: "loyaltyRatio",
                    },
                  },
                ],
              },
            },
          },
        },
      }),

      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(([data]) => {
      return data.hits.hits.map((element) => element._source);
    })
    .catch((err) => {
      console.log("err getting data", err);
    });
};

export const getLoyaltyCustomers = async () => {
  return await Promise.all([
    fetch(`${config.Elasticsearch.URL}/points/_search?size=10000`, {
      method: "POST",
      body: JSON.stringify({
        _source: {
          includes: [
            "pointsType",
            "shopId",
            "pointsAction",
            "pointsType",
            "custId",
          ],
        },
        query: {
          constant_score: {
            filter: {
              bool: {
                must: [
                  { match: { pointsAction: "added" } },
                  { match: { pointsType: "loyalty" } },
                  {
                    exists: {
                      field: "custId",
                    },
                  },
                  {
                    exists: {
                      field: "shopId",
                    },
                  },
                ],
              },
            },
          },
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(([data]) => {
      return data.hits.hits.map((element) => element._source);
    })
    .catch((err) => console.log("err", err));
};

export const GetShopById = async (userId10Digit) => {
  return await Promise.all([
    fetch(`${config.ApiGateway}/shopProfile`, {
      method: "POST",
      body: JSON.stringify({ userId10Digit }),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return data;
    })
    .catch((err) => console.log(err));
};

export const GetMembershipPackage = async () => {
  // get shop package
  return await Promise.all([
    fetch(`${config.ApiGateway}/getMembershipLimit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(([data]) => {
      return data;
    })
    .catch((err) => console.log(err));
};
export const GetFields = async () => {
  // get shop package
  return await Promise.all([fetch(`${config.json.URL}/fields.json`)])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return data;
    })
    .catch((err) => console.log(err));
};

export const UpdateProfile = async (editProfile) => {
  return await Promise.all([
    fetch(`${config.ApiGateway}/editProfile`, {
      method: "POST",
      body: JSON.stringify(editProfile),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(async ([data]) => {
      return data;
    })
    .catch((err) => console.log(err));
};
