import React, { Component } from "react";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import config from "../config";
import Limit from "../limit";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
export default class SalesReferal extends Component {
  constructor(props) {
    super(props);
    this.temparray = [];
    this.state = {
      salesLoginCount: [],
      toggle: true,
      showCompleteList: false,
      listOfShops: [],
      refferedName: "",
      searchEndDate: "",
      searchStartDate: "",
      referredByCode: "",
      temporary: []
    };
    this.getRefferal = this.getRefferal.bind(this);
    this.getSalesPersonInfo = this.getSalesPersonInfo.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fetchByDate = this.fetchByDate.bind(this);
    this.getCount = this.getCount.bind(this);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  componentDidUpdate() {
    {
      this.state.showResult === false &&
        (window.onpopstate = e => {
          e.preventDefault();
          if (window.confirm("are you sure want to leave the page?")) {
            window.location.assign("/");
          } else {
            window.location.assign("/salesrefferal");
          }
        });
    }
  }
  fetchByDate(e) {
    e.preventDefault();
    var getShopOwners = [];
    var tempdata = [];
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            constant_score: {
              filter: {
                bool: {
                  must: [
                    { match: { referredBy: this.state.referredByCode } },
                    {
                      range: {
                        userAddedDate: {
                          gte: this.state.searchStartDate,
                          lte: this.state.searchEndDate
                        }
                      }
                    }
                  ]
                }
              }
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map(element => element._source);

        tempdata.forEach(items => {
          getShopOwners.push({
            userId10Digit: items.userId10Digit,
            userPincode: items.userPincode,
            referredBy: items.referredBy,
            refferedName: this.state.refferedName,
            userName: items.userName,
            userType: items.userType,
            userPhoneNumber: items.userPhoneNumber,
            userAddedDate: items.userAddedDate,
            shopAddress: items.shopAddress,

            shopName: items.shopName
          });
        });

        this.setState({
          listOfShops: getShopOwners,
          toggle: false
        });
      })
      .catch(err => console.log(err));
  }
  async componentDidMount() {
    var userDetails = [];
    Promise.all([
      fetch(await `${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                { match: { userType: Limit.size.merchantUserType } },
                { term: { userPincode: Limit.size.ghoshakInternal } }
              ]
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        data.hits.hits.forEach(element => userDetails.push(element._source));
        var users = [];
        var refferalArray = [];
        userDetails.forEach(elements => {
          refferalArray.push(elements.referralCode);
          users.push({
            userName: elements.userName,
            userId10Digit: elements.userId10Digit,
            referralCode: elements.referralCode
          });
        });
        this.getRefferal(refferalArray, users);
      })
      .catch(err => console.log(err, "function call failed"));
  }

  getRefferal(referralcode, users) {
    var tempReferralcode = referralcode.map(elements => elements.toLowerCase());
    var tempdata = [];

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            constant_score: {
              filter: {
                bool: {
                  should: [{ terms: { referredBy: tempReferralcode } }]
                }
              }
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map(element => element._source);

        let logincount = {};
        tempdata.forEach(items => {
          if (logincount[items.referredBy] == undefined) {
            logincount[items.referredBy] = {
              referredBy: 1,
              userId: [items.userId]
            };
          } else {
            logincount[items.referredBy].referredBy += 1;
            logincount[items.referredBy].userId.push(items.userId);
          }
        });

        var count = [];
        var salesLoginCount = users.map(items => {
          count = Object.entries(logincount).find(
            ([elements, value]) => items.referralCode == elements
          );

          let appendObject = Object.assign({}, items);
          appendObject.totalcount =
            count !== undefined ? count[1].referredBy : 0;
          appendObject.reffereduserId =
            count !== undefined ? count[1].userId : [];
          return appendObject;
        });

        this.getCount(salesLoginCount);
      })
      .catch(err => console.log(err));
  }

  getCount(salesLoginCount) {
    this.temparray = [];

    salesLoginCount.map(async elements => {
      const response1 = await fetch(
        `${config.Elasticsearch.URL}/offers/_search?size=10000`,
        {
          method: "POST",
          body: JSON.stringify({
            query: {
              constant_score: {
                filter: {
                  bool: {
                    must: [
                      { terms: { shopId: elements.reffereduserId } },
                      { match: { offerType: "offer" } }
                    ],
                    must_not: [{ match: { offerStatus: "Deleted" } }]
                  }
                }
              }
            }
          }),

          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      const response2 = await fetch(
        `${config.Elasticsearch.URL}/offers/_search?size=10000`,
        {
          method: "POST",
          body: JSON.stringify({
            query: {
              constant_score: {
                filter: {
                  bool: {
                    must: [
                      { terms: { shopId: elements.reffereduserId } },
                      { match: { offerType: "item" } }
                    ],
                    must_not: [{ match: { offerStatus: "Deleted" } }]
                  }
                }
              }
            }
          }),

          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      const response3 = await fetch(
        `${config.Elasticsearch.URL}/coupons/_search?size=10000`,
        {
          method: "POST",
          body: JSON.stringify({
            query: {
              constant_score: {
                filter: {
                  bool: {
                    must: [
                      { terms: { shopId: elements.reffereduserId } },
                      { match: { couponCategory: "Created" } }
                    ]
                  }
                }
              }
            }
          }),

          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      const offer = await response1.json();
      const item = await response2.json();
      const coupon = await response3.json();

      //     .then(([offer, item, coupon]) => {
      var tempOffers = offer.hits.hits.map(element => element._source);
      var tempItem = item.hits.hits.map(element => element._source);
      var tempCoupon = coupon.hits.hits.map(element => element._source);

      let totalAccquisation = {};
      tempCoupon.forEach(element => {
        if (totalAccquisation[element.couponType] === undefined) {
          totalAccquisation[element.couponType] = 1;
        } else {
          totalAccquisation[element.couponType] += 1;
        }
      });
      totalAccquisation.totalOffers = tempOffers.length;
      totalAccquisation.totalCollection = tempItem.length;
      let ConvertObject = Object.assign({}, elements, totalAccquisation);
      this.temparray.push(ConvertObject);

      if (this.temparray.length === salesLoginCount.length) {
        this.setState({
          salesLoginCount: this.temparray,
          showCompleteList: true
        });
      }
    });
  }

  getSalesPersonInfo(salesPerson) {
    var getShopOwners = [];
    var tempdata = [];
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            constant_score: {
              filter: {
                bool: {
                  must: [{ match: { referredBy: salesPerson.referralCode } }]
                }
              }
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map(element => element._source);

        tempdata.forEach(items => {
          getShopOwners.push({
            userId10Digit: items.userId10Digit,
            userPincode: items.userPincode,
            referredBy: items.referredBy,
            refferedName: salesPerson.userName,
            userName: items.userName,
            userType: items.userType,
            userPhoneNumber: items.userPhoneNumber,
            userAddedDate: items.userAddedDate,
            shopAddress: items.shopAddress,
            shopName: items.shopName
          });
        });

        this.setState({
          listOfShops: getShopOwners,
          toggle: false,

          refferedName: salesPerson.userName,
          referredByCode: salesPerson.referralCode
        });
      })
      .catch(err => console.log(err));
  }
  render() {
    return (
      <Container fluid>
        {this.state.toggle ? (
          <>
            {this.state.showCompleteList && (
              <>
                <div style={{ textAlign: "center" }}>
                  <h1>SALES REFERRAL</h1>
                </div>
                <Row>
                  <Col md={{ span: 12 }}>
                    <Table
                      style={{
                        width: 100 + "%",
                        border: 0.5 + "px",
                        borderColor: "#ced4da",
                        borderStyle: "solid"
                      }}
                      size="medium"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ textAlign: "center" }}>
                            S NO
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            USERNAME
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            REFFERAL CODE
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            OFFERS CREATED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            COLLECTION CREATED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            GHOSHAK COUPON CREATED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            LOYALTY COUPON CREATED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            MERCHANT COUPON CREATED
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            TOTAL B2B LOGIN
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            ACTION
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.salesLoginCount.map((item, index) => (
                          <TableRow key={item.referralCode}>
                            <TableCell style={{ textAlign: "center" }}>
                              {index + 1}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.userName}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.referralCode}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.totalOffers}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.totalCollection}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.GHOSHAK}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.LOYALTY}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.MERCHANT}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {item.totalcount}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <Button
                                onClick={() => {
                                  this.getSalesPersonInfo(item);
                                }}
                              >
                                VIEW
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Col>
                </Row>
              </>
            )}
          </>
        ) : (
          <Row>
            <div
              style={{
                textAlign: "center",
                width: 100 + "%",
                marginTop: 2 + "%",
                marginBottom: 2 + "%"
              }}
            >
              <h2>
                TOTAL LOGIN DONE BY {this.state.refferedName.toUpperCase()}
              </h2>
            </div>
            <Col md={{ span: 8, offset: 2 }}>
              <Form onSubmit={this.fetchByDate}>
                <Form.Row>
                  <Col md={{ offset: 2 }}>
                    <Form.Label>FROM DATE</Form.Label>

                    <Form.Control
                      type="date"
                      name="searchStartDate"
                      onChange={this.onChange}
                      value={this.state.searchStartDate}
                      required
                    />
                  </Col>

                  <Col>
                    <Form.Label>TO DATE</Form.Label>
                    <Form.Control
                      type="date"
                      name="searchEndDate"
                      onChange={this.onChange}
                      min={this.state.searchStartDate}
                      value={this.state.searchEndDate}
                      required
                    />
                  </Col>
                  <Col>
                    <Button style={{ marginTop: 33 + "px" }} type="submit">
                      SEARCH
                    </Button>
                  </Col>
                </Form.Row>
              </Form>
              <div
                style={{
                  backgroundColor: "#fafafa",
                  width: 100 + "%",
                  margin: 1 + "%"
                }}
              >
                <h3 style={{ textAlign: "center" }}> SHOP DETAILS</h3>
              </div>
              <Table
                style={{
                  width: 100 + "%",
                  border: 0.5 + "px",
                  borderColor: "#ced4da",
                  borderStyle: "solid"
                }}
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ textAlign: "center" }}>S NO</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      USERNAME
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      SHOPNAME
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      PHONE NUMBER
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      LOGGED ON{" "}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      USER ID
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      SHOP PINCODE
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      REFFERED BY
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      REFFERED BY CODE
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      SHOP ADDRESS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.listOfShops.map((elements, index) => (
                    <TableRow key={elements.userPhoneNumber}>
                      <TableCell style={{ textAlign: "center" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {elements.userName}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {elements.shopName}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {elements.userPhoneNumber}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {elements.userAddedDate}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Link
                          to={{
                            pathname: "/merchant",
                            search: `?userid=${elements.userId10Digit}`
                          }}
                        >
                          {" "}
                          {elements.userId10Digit}
                        </Link>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {elements.userPincode}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {elements.refferedName}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {elements.referredBy}
                      </TableCell>

                      <TableCell style={{ textAlign: "center" }}>
                        {elements.shopAddress}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}
