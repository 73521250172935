// import dependencies

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import config from "../config";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";

export default function CreateFranchiseAccount() {
  let initialState = {
    userId10Digit: "",
  };
  const [linkedStatus, setLinkedStatus] = useState("");
  const linked = {
    linked: "This Shop already linked with different shop",
    invalid: "Invalid Shop",
  };
  const [shop, setShop] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [state, setState] = useState(initialState);
  const [isValidShop, setIsValidShop] = useState(false);
  const [profile, setProfile] = useState({});
  const validateUser = async (e) => {
    e.preventDefault();

    await Promise.all([
      fetch(`${config.ApiGateway}/shopProfileById`, {
        method: "POST",
        body: JSON.stringify({ userId10Digit: state.userId10Digit }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        if (data.status) {
          var shopProfile = {};
          if (data.payloadResponseExceededLimit) {
            try {
              let uploadData = await (await fetch(data.data)).json();
              setProfile(uploadData?.profile || {});

              shopProfile = uploadData?.profile || {};
            } catch (err) {
              return {
                status: false,
                data: [],
              };
            }
          } else {
            setProfile(data?.profile || {});
            shopProfile = data?.profile || {};
          }
          let { websiteUserRole } = shopProfile;
          if (websiteUserRole == "primary" || websiteUserRole == "secondary") {
            // Get Shop already linked
            // primary - franchise shop
            // secondary - franchise linked partner

            setLinkedStatus("linked");
          }
          // setTableLoading(false);

          setIsValidShop(true);
        } else {
          setLinkedStatus("invalid");
        }
      })
      .catch((err) => console.log(err));
  };
  const onChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    initialFetch();
  }, []);
  const initialFetch = async () => {
    // get all franchise shops
    setLinkedStatus("");
    setTableLoading(true);
    try {
      let data = await (
        await fetch(`${config.ApiGateway}/viewFranchise`, {
          method: "POST",
          body: JSON.stringify({}),
          headers: {
            "Content-Type": "application/json",
          },
        })
      ).json();

      if (data.status) {
        if (data.payloadResponseExceededLimit) {
          console.log("get data from bucket__");

          try {
            let uploadData = await (await fetch(data.data)).json();
            setTableLoading(false);
            setShop(uploadData || []);
          } catch (err) {
            setShop([]);
            alert("Fetch Failed");
          }
        } else {
          console.log("get data from DB__");
          setTableLoading(false);
          setShop(data.data || []);
        }

        setTableLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const createAccount = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/manageFranchise`, {
        method: "POST",
        body: JSON.stringify({
          userPhoneNumber: profile.userPhoneNumber,
          userId: profile.userId,
          typename: "create",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        setState({
          userId10Digit: "",
        });
        initialFetch();
        setIsValidShop(false);
        setProfile({});
      })
      .catch((err) => console.log(err));
  };

  const removeAccount = async (rowData) => {
    let input = {};
    const { userId, userPhoneNumber } = rowData;

    await Promise.all([
      fetch(`${config.ApiGateway}/manageFranchise`, {
        method: "POST",
        body: JSON.stringify({
          typename: "delete",
          userId,
          userPhoneNumber,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        if (data.errorType == "UserNotFoundException") {
          alert(data.errorMessage);
        }

        initialFetch();
      })
      .catch((err) => console.log("error getting data", err));
  };

  return (
    <>
      <Container>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Manage Franchise</h2>
        </div>
        <div className="vertical-space-40"></div>
        <Row>
          <Col className="dashboardColumnBox columnCenter" sm="12">
            <Form onSubmit={validateUser}>
              <Form.Row>
                <Col sm="4">
                  <Form.Label>Enter Shop Id</Form.Label>
                </Col>
                <Col sm="6">
                  <Form.Control
                    name="refCode"
                    onChange={(e) => {
                      onChange("userId10Digit", e.target.value);
                    }}
                    value={state.userId10Digit}
                  />
                  {linked[linkedStatus] && (
                    <Form.Text style={{ color: "red" }}>
                      {linked[linkedStatus]}
                    </Form.Text>
                  )}
                </Col>
                <Col sm="2">
                  <Button type="submit">Search</Button>
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </Row>
        <div className="vertical-space-40"></div>
        {isValidShop && (
          <>
            <Row>
              <Col className="dashboardColumnBox columnCenter" sm="12">
                <Row>
                  <Col sm={"6"}>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                      <Form.Label column sm="4">
                        Name
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control plaintext value={profile.userName} />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={"6"}>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                      <Form.Label column sm="4">
                        Phone number
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          plaintext
                          value={profile.userPhoneNumber}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={"6"}>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                      <Form.Label column sm="4">
                        Refferal Code
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control plaintext value={profile.referralCode} />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={"6"}>
                    <Form.Group as={Row} controlId="formPlaintextPassword">
                      <Form.Label column sm="4">
                        user id
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={profile.userId10Digit} plaintext />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <div id="confirmButtonBox" className="floatLeft">
                  <Button
                    onClick={() => createAccount()}
                    style={{ marginRight: "10px" }}
                  >
                    Confirm
                  </Button>
                  <Button
                    onClick={() => {
                      setProfile({});
                      setIsValidShop(false);
                    }}
                    variant="danger"
                  >
                    No
                  </Button>
                </div>
              </Col>
            </Row>
            <div className="vertical-space-40"></div>
          </>
        )}

        <Row>
          <Col className="columnCenter" sm="12">
            <MaterialTable
              title={`Franchise`}
              editable={{}}
              columns={[
                {
                  title: "Ghoshak Id",
                  field: "userId10Digit",
                },
                {
                  title: "Used Id",
                  field: "userId",
                  hidden: true,
                },
                { title: "Shop Name", field: "shopName" },
                { title: "Name", field: "userName" },
                { title: "Ph No", field: "userPhoneNumber" },
                { title: "Pincode", field: "userPincode" },

                {
                  title: "Login Link",
                  field: "loginLink",
                  render: (rowData) => {
                    return (
                      <>
                        <Link
                          to={`http://${window.location.host}/login?phone=${rowData.userPhoneNumber}&pass=pass1234`}
                        >
                          {`http://${window.location.host}/login?phone=${rowData.userPhoneNumber}&pass=pass1234`}
                        </Link>
                      </>
                    );
                  },
                },
                {
                  title: "Access",
                  field: "access",
                  render: (rowData) => (
                    <Button
                      onClick={() => removeAccount(rowData)}
                      variant="danger"
                    >
                      Remove Account
                    </Button>
                  ),
                },
              ]}
              data={shop}
              options={{
                exportButton: false,
              }}
              isLoading={tableLoading}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
