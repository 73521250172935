import React, { useEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Badge from "react-bootstrap/Badge";
import Validation from "../handler/validation";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import { loyaltyPointsAPI } from "../handler/apiHandler";
let initialState = {
  loyaltyPoints: "",
  loyaltyAmount: "",
  loyaltyRatio: "",
  loyaltyupdatedBy: "",
};
export default function Loyalty({ profile, crmUser }) {
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState(initialState);
  const [modalShow, setModalShow] = useState(false); // Modal State
  let [validationHandler] = Validation();

  useEffect(() => {
    setIsLoading(false);
  }, [profile, crmUser]);
  const handleChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const closeModal = () => {
    setState(initialState);
    setModalShow(false);
  };
  useEffect(() => {
    if (Boolean(state.loyaltyAmount) && Boolean(state.loyaltyPoints)) {
      let loyaltyRatio =
        parseFloat(state.loyaltyAmount) / parseFloat(state.loyaltyPoints);
      handleChange("loyaltyRatio", loyaltyRatio);
    }
  }, [state.loyaltyPoints, state.loyaltyAmount]);
  const updateLoyalty = async (e) => {
    e.preventDefault();
    let input = state;
    input.loyaltyupdatedBy = crmUser;
    input.userId = profile.userId;
    let validatedResults = await validationHandler("loyaltyPoints", input);
    if (validatedResults.status) {
      await loyaltyPointsAPI(validatedResults.uploadObject).then((data) => {
        closeModal();
      });
    } else {
      closeModal();
      alert("Failed to update");
    }
  };
  return (
    <>
      {isLoading ? (
        <>
          <div className="shopCardRow" id="fullHeight">
            <div className="centerFlex">
              <h3>Loading...</h3>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="shopCardRow">
            <div className="shopCardColumn">
              <>
                {profile.loyaltyPackage == "yes" ? (
                  <>
                    <ul className="horizontalList">
                      <li id="noBorder" className="horizontalListItem">
                        <h6>Points Configuration</h6>

                        <span>
                          {parseFloat(profile.loyaltyRatio) > 0 ? (
                            <Badge
                              style={{ color: "#fff" }}
                              pill
                              variant="success"
                            >
                              configured
                            </Badge>
                          ) : (
                            <Badge
                              style={{ color: "#fff" }}
                              pill
                              variant="warning"
                            >
                              not configured
                            </Badge>
                          )}
                        </span>
                      </li>
                      {parseFloat(profile.loyaltyRatio) > 0 && (
                        <>
                          <li id="noBorder" className="horizontalListItem">
                            <h6>Ratio</h6>

                            <span>
                              {parseFloat(profile.loyaltyRatio) > 0 ? (
                                <>{profile.loyaltyRatio}&nbsp;&nbsp;</>
                              ) : (
                                0
                              )}
                            </span>
                          </li>
                          <li id="noBorder" className="horizontalListItem">
                            <h6>Amount ₹</h6>

                            <span>₹&nbsp;{profile.loyaltyAmount}</span>
                          </li>
                          <li id="noBorder" className="horizontalListItem">
                            <h6>Points</h6>

                            <span>{profile.loyaltyPoints}</span>
                          </li>
                        </>
                      )}
                      <li
                        id="noBorder"
                        style={{ flex: "1 1 auto !important" }}
                        className="horizontalListItem"
                      >
                        <h6>Action</h6>
                        <span>
                          <button
                            className="editButton"
                            onClick={() => setModalShow(true)}
                          >
                            <EditTwoToneIcon />
                          </button>
                        </span>
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <div className="shopCardRow" id="fullHeight">
                      <div id="packageExpired" className="centerFlex">
                        <h3>Package Expired</h3>
                        <button id="success" className="actionButton">
                          Activate
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </>
      )}

      <Modal
        className="modal-order-coupon"
        show={modalShow}
        onHide={() => closeModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Configure Loyalty Points</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <form onSubmit={updateLoyalty}>
              <div className="fieldBox">
                <div id="twoCol" className="fieldWrap">
                  <label id="required">Amount</label>
                  <input
                    onChange={(e) =>
                      handleChange("loyaltyAmount", e.target.value)
                    }
                    pattern="^\d*(\.\d{0,2})?$"
                    title="numbers only allowed. if number is decimal only two digits allowed"
                    placeholder="₹0.00"
                    value={state.loyaltyAmount}
                    required
                    className={"flexInputBox"}
                  ></input>
                </div>
                <div id="twoCol" className="fieldWrap">
                  <label id="required">Points</label>
                  <input
                    pattern="^\d*(\.\d{0,2})?$"
                    title="numbers only allowed. if number is decimal only two digits allowed"
                    onChange={(e) =>
                      handleChange("loyaltyPoints", e.target.value)
                    }
                    value={state.loyaltyPoints}
                    required
                    className={"flexInputBox"}
                  ></input>
                </div>
                <div id="twoCol" className="fieldWrap">
                  <label id="required">Ratio</label>
                  <input
                    value={state.loyaltyRatio}
                    disabled
                    className={"flexInputBox"}
                  ></input>
                </div>

                <div className="flexBoxEnd">
                  <div className="updateButton">
                    <button type="submit" id="success" className="actionButton">
                      Update Points
                    </button>
                  </div>
                  <div className="updateButton">
                    <button
                      onClick={() => {
                        closeModal();
                      }}
                      id="danger"
                      className="actionButton"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
