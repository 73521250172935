import React, { useState, useContext, useEffect } from "react";
import { Container, Col, Button, Row, Badge } from "react-bootstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import { Link, useHistory } from "react-router-dom";
import config from "../config";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  LabelList,
  Legend,
} from "recharts";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
export default function OrderDashboard() {
  const classes = useStyles();
  const history = useHistory();
  const [subList, setSubList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [shopList, setShopList] = useState([]);
  const [pincodeList, setPincodeList] = useState([]);
  useEffect(() => {
    Promise.all([
      fetch(`${config.ApiGateway}/onlineDashboard`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        console.log(data);
        let categoryProduct = [];
        let subProduct = [];

        Object.entries(data.category).forEach(([key, value]) => {
          categoryProduct.push({
            categoryName: key,
            count: value,
          });
        });
        categoryProduct = categoryProduct.sort((a, b) => b.count - a.count); // sort category list
        Object.entries(data.subCategory).forEach(([key, value]) => {
          // for graph
          subProduct.push({
            categoryName: key,
            count: value,
          });
        });
        subProduct = subProduct.sort((a, b) => b.count - a.count); // sort by sub category product list
        setSubList(subProduct); // sub category product list
        setCategoryList(categoryProduct); // category list
        setPincodeList(data.pincode); // orders by pincode list
        setShopList(data.shop); // shop list
      })
      .catch((err) => alert(err));
  }, []);

  return (
    <>
      <Container fluid>
        <div style={{ width: "100%", height: "100px" }}></div>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Order Dashboard</h2>
        </div>

        <MaterialTable
          title="Orders"
          columns={[
            {
              title: "Shop Id",
              field: "shopIduserId10Digit",
              render: (rowData) => (
                <Link
                  to={{
                    pathname: "/merchant",
                    search: `?userid=${rowData.shopIduserId10Digit}`,
                  }}
                >
                  {rowData.shopIduserId10Digit}
                </Link>
              ),
            },
            { title: "Shop Name", field: "shopName" },

            {
              title: "total orders",
              field: "total",
              render: (rowData) => {
                const orders = parseInt(rowData.totalOrders) || 0;
                const rejected = parseInt(rowData.rejectedOrders) || 0;
                return <td>{orders + rejected}</td>;
              },
            },
            { title: "Total Products Live", field: "productsLive" },

            { title: "Orders Accepted", field: "totalOrders" },
            { title: "Orders Accepted Amt", field: "orderAmt" },
            { title: "total Orders Amt", field: "totalOrdersAmount" },
            { title: "Pincode", field: "shopPincode" },
            { title: "Area", field: "userArea" },
            { title: "Unique Customers", field: "uniqueCustomers" },

            {
              title: "New Customer Last 7 days",
              field: "newCustomersInLast7Days",
            },
            {
              title: "Total Pick-up orders",
              field: "delivery.Delivery",
            },
            {
              title: "Total Delivery Orders",
              field: "delivery.Pick-Up",
            },

            { title: "Order in last 7 Days", field: "lastWeekOrders" },
            { title: "Total Rejected Orders", field: "rejectedOrders" },
            {
              title: "Google Location",
              field: "location",
              render: (rowData) =>
                rowData.location == "true" ? (
                  <Badge pill variant="success">
                    Enabled
                  </Badge>
                ) : (
                  <Badge pill variant="danger">
                    Disabled
                  </Badge>
                ),
            },
            {
              title: "Delivery Service",
              field: "deliveryType",
              render: (rowData) =>
                rowData.deliveryType == "true" ? (
                  <Badge pill variant="success">
                    Enabled
                  </Badge>
                ) : (
                  <Badge pill variant="danger">
                    Disabled
                  </Badge>
                ),
            },
            {
              title: "Pick Up Delivery",
              field: "pickUpType",
              render: (rowData) =>
                rowData.pickUpType == "true" ? (
                  <Badge pill variant="success">
                    Enabled
                  </Badge>
                ) : (
                  <Badge pill variant="danger">
                    Disabled
                  </Badge>
                ),
            },
            {
              title: "Action",
              field: "view",
              render: (rowData) => (
                <Button
                  onClick={() => {
                    history.push(`/shop/${rowData.shopId}`);
                  }}
                >
                  View Shop
                </Button>
              ),
            },
          ]}
          data={shopList}
          options={{
            exportButton: true,
            pageSize: 10,
            pageSizeOptions: [5, 10, 20, 100],
            grouping: false,
            filtering: false,
          }}
        />

        <div style={{ width: "100%", height: "50px" }}></div>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Orders By Pincode</h2>
        </div>
        <Row className="flexCard">
          <MaterialTable
            title="Orders"
            columns={[
              { title: "Pincode", field: "pincode" },
              { title: "Place", field: "userArea" },
              { title: "Total Shops", field: "totalShops" },
              { title: "Total Orders", field: "totalOrders" },
              {
                title: "Order Cost  ₹",
                field: "orderAmt",

                render: (rowData) => (
                  <td>
                    {"₹ "}
                    {rowData.orderAmt}
                  </td>
                ),
              },
              { title: "Total Rejected Orders", field: "rejectedOrders" },
              {
                title: "Rejected Order Amount  ₹",
                field: "rejectedAmt",
                render: (rowData) => (
                  <td>
                    {"₹ "}
                    {rowData.rejectedAmt}
                  </td>
                ),
              },
              { title: "Unique Customers", field: "uniqueCustomers" },
            ]}
            data={pincodeList}
            options={{
              exportButton: true,
              pageSize: 5,
              pageSizeOptions: [5, 10, 20, 100],
              grouping: false,
              filtering: false,
            }}
          />
        </Row>
        <div style={{ width: "100%", height: "50px" }}></div>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Category</h2>
          <button
            onClick={() => {
              history.push(`/onlineDashboard/category`);
            }}
            className="orderDashboardViewallBtn"
          >
            View all
          </button>
        </div>
        <Row>
          <Col className="flexCard" sm={6}>
            <div style={{ marginTop: "40px" }}>
              <ResponsiveContainer aspect={1.6}>
                <BarChart
                  width={600}
                  height={300}
                  data={categoryList.slice(0, 10)}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="categoryName"
                    interval={0}
                    angle={-45}
                    textAnchor="end"
                    height={100}
                    tick={{ fontSize: 10 }}
                  />
                  <YAxis
                    label={{
                      value: "Count",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="count" fill="#8884d8">
                    <LabelList dataKey="count" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col sm={6}>
            <MaterialTable
              title="Category"
              columns={[
                { title: "Category", field: "categoryName" },
                { title: "Count", field: "count" },
              ]}
              data={categoryList}
              options={{
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 20, 100],
                grouping: false,
                filtering: false,
              }}
            />
          </Col>
        </Row>
        <div style={{ width: "100%", height: "50px" }}></div>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Sub Category</h2>
          <button
            onClick={() => {
              history.push(`/onlineDashboard/subCategory`);
            }}
            className="orderDashboardViewallBtn"
          >
            View all
          </button>
        </div>
        <Row className="justiy-content-between">
          <Col className="flexCard" sm={6}>
            <div style={{ marginTop: "40px" }}>
              <ResponsiveContainer aspect={1.6}>
                <BarChart
                  width={600}
                  height={300}
                  data={subList.slice(0, 10)}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="categoryName"
                    interval={0}
                    angle={-45}
                    textAnchor="end"
                    height={100}
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis
                    label={{
                      value: "Count",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="count" fill="#8884d8">
                    <LabelList dataKey="count" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col sm={6}>
            <MaterialTable
              title="Sub Category"
              columns={[
                { title: "Sub Category", field: "categoryName" },
                { title: "Count", field: "count" },
              ]}
              data={subList}
              options={{
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 20, 100],
                grouping: false,
                filtering: false,
              }}
            />
          </Col>
        </Row>
        <div style={{ width: "100%", height: "50px" }}></div>
      </Container>
    </>
  );
}
