import { Table, Dropdown, Typography, Space, Input, message } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";

import React, { useEffect, useState } from "react";
import "../styles/topNotifications_MC.css";
import ReactModal from "react-modal";
import { ColorRing } from "react-loader-spinner";
import uuid from "uuid/dist/v4";
import { groupBy, sumBy, isEqual } from "lodash";

const TopNotifications = ({ campaignData = {}, updateJson }) => {
  console.log("campaignData", campaignData);
  const [addNotificationModal, setAddNotificationModal] = useState(false);
  const [modified, setModified] = useState(false);
  const notificationObjInit = {
    product: "",
    message: "",
    link: "",
    title: "",
    route: "",
    id: "",
  };
  const [notificationObj, setNotificationObj] = useState({
    ...notificationObjInit,
  });
  const [messageApi, contextHolder] = message.useMessage();

  const feedbackToast = {
    success: function (message) {
      messageApi.open({
        type: "success",
        content: message,
      });
    },
    error: function (message) {
      messageApi.open({
        type: "error",
        content: message,
      });
    },
  };
  const [addingNotification, setAddingNotification] = useState(false);
  const { TextArea } = Input;

  const addNotificationStyles = {
    content: {
      width: "450px",
      height: "500px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      padding: "5px",
      marginRight: "-50%",
      borderRadius: "12px",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      background: "white",
    },
    overlay: {
      background: `rgba(0, 0, 0, 0.5)`,
    },
  };
  const tableHeaderStyle = {
    fontSize: "16px",
    color: "black",
    textAlign: "center",
  };
  const tableValueStyle = {
    fontSize: "16px",
    fontWeight: "500px",
    color: "black",
    textAlign: "center",
  };
  const ringLoader = () => {
    return (
      <ColorRing
        visible={true}
        height="20"
        width="20"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#f6666", "#f6666", "#f6666", "#f6666", "#f6666"]}
      />
    );
  };
  const items = [
    {
      key: "delete",
      label: "Delete",
    },
  ];
  const inputHandler = (e) => {
    let { name, value } = e.target;
    if (name === "product") {
      setNotificationObj({ ...notificationObj, route: "" });
    }
    setNotificationObj({ ...notificationObj, [name]: value });
  };
  const actionsHandler = (e, data) => {
    console.log("data", data);
    let key = e.key;
    if (key === "delete") {
      let ferd = campaignDetails.topNotifications.filter(
        (key) => key.id !== data.id
      );
      setCampaignDetails({ ...campaignDetails, topNotifications: [...ferd] });
      // setModified(true)
    }
  };
  const campaignDataColumns = [
    {
      title: <div style={tableHeaderStyle}>SNo</div>,
      key: "index",
      render: (text, record, index) => {
        return <div style={tableValueStyle}>{index + 1}</div>;
      },
    },
    {
      title: <div style={tableHeaderStyle}>Product</div>,
      key: "index",
      render: (_, { product }) => {
        return <div style={tableValueStyle}>{product}</div>;
      },
    },
    {
      title: <div style={tableHeaderStyle}>Title</div>,
      key: "index",
      render: (_, { title }) => {
        return <div style={tableValueStyle}>{title}</div>;
      },
    },
    {
      title: <div style={tableHeaderStyle}>Message</div>,
      key: "index",
      render: (_, { message }) => {
        return <div style={tableValueStyle}>{message}</div>;
      },
    },
    {
      title: <div style={tableHeaderStyle}>Link</div>,
      key: "index",
      render: (_, { link }) => {
        return <div style={tableValueStyle}>{link}</div>;
      },
    },
    {
      title: <div style={tableHeaderStyle}>Route</div>,
      key: "index",
      render: (_, { route }) => {
        return <div style={tableValueStyle}>{route || "N/A"}</div>;
      },
    },
    {
      title: <div style={tableHeaderStyle}>Actions</div>,
      key: "index",
      render: (data) => {
        return (
          <div style={tableValueStyle}>
            <Dropdown
              menu={{
                items: items,
                selectable: true,
                onClick: (e) => {
                  actionsHandler(e, data);
                },
                selectedKeys: [],
              }}
            >
              <Typography.Link>
                <Space>
                  <BsThreeDotsVertical
                    style={{ fontSize: "20px", color: "#f4700e" }}
                  />
                </Space>
              </Typography.Link>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const [existing, setExisting] = useState([]);
  const [campaignDetails, setCampaignDetails] = useState({});
  const [campaignDetailsBackUp, setCampaignDetailsBackUp] = useState({});

  useEffect(() => {
    if (Array.isArray(campaignData.topNotifications)) {
      let temp = campaignData.topNotifications.map((data) => data.product);
      console.log("temp", temp);
      setExisting([...temp]);
    }
    setCampaignDetails({ ...campaignData });
    setCampaignDetailsBackUp({ ...campaignData });
  }, [campaignData]);

  const updateNotificationObj = () => {
    if (!notificationObj.title)
      return feedbackToast.error("Please enter title");
    if (!notificationObj.message)
      return feedbackToast.error("Please enter message");

    setCampaignDetails({
      ...campaignDetails,
      topNotifications: [
        ...campaignDetails.topNotifications,
        { ...notificationObj, id: uuid() },
      ],
    });
    // setModified(true)
    setNotificationObj({ ...notificationObjInit });
    document.querySelector("#productSelect").value = "default";
  };
  const saveJson = () => {
    updateJson({ ...campaignDetails });
  };
  // const escapeSaver = () => {
  //   console.log(
  //     "lert_text",isEqual(campaignDetails, campaignDetailsBackUp)
  //   );
  //   if (!isEqual(campaignDetails, campaignDetailsBackUp)) {
  //     let alertText = "Your changes are not save, press ok to save.";
  //     if (window.confirm(alertText)) {
  //       saveJson();
  //     }
  //   }
  // };
  // useEffect(() => {
  //   return () => {
  //     escapeSaver();
  //   };
  // }, []);
  return (
    <div className="topNotifications_Main_Div">
      {contextHolder}
      <div className="topNotifications_Header">
        <div className="topNotifications_Title">Top Notifications</div>
        <div className="addAndSaveDiv">
          <div
            className="topNotifications_Add_Btn"
            onClick={() => setAddNotificationModal(true)}
          >
            Add Notification
          </div>
          <div
            className={
              isEqual(campaignDetails, campaignDetailsBackUp)
                ? "tn_Save_Btn_Disabled"
                : "tn_Save_Btn"
            }
            onClick={() => saveJson()}
          >
            Save
          </div>
        </div>
      </div>
      <div className="topNotifications_Body">
        <div className="existingNotification_Title">Existing notifications</div>
      </div>
      <div className="existingNotification_Div">
        <Table
          dataSource={campaignDetails.topNotifications}
          columns={campaignDataColumns}
        />
      </div>
      {/* <ReactModal >

      </ReactModal> */}
      <ReactModal
        isOpen={addNotificationModal}
        style={addNotificationStyles}
        onRequestClose={() => {
          setAddNotificationModal(false);
          setNotificationObj({ ...notificationObjInit });
          document.querySelector("#productSelect").value = "default";
        }}
      >
        <div className="addNotificationModal_Header">
          <div className="addNotificationModal_Title">Add Notification</div>
        </div>
        <div className="addNotificationModal_Body">
          <div className="selectNotifyTitleDiv">
            <div className="chooseImgCD">Product</div>
            <select
              className="selectOfProduct"
              onChange={(e) => {
                if (e.target.value === "default") {
                  setNotificationObj({
                    ...notificationObj,
                    product: "",
                  });
                } else {
                  setNotificationObj({
                    ...notificationObj,
                    product: e.target.value,
                  });
                }
              }}
              id="productSelect"
            >
              <option className="default">Select Product</option>
              <option
                value="ghoshakB2B"
                disabled={existing.includes("ghoshakB2B")}
              >
                Ghoshak B2B
              </option>
              <option value="pos" disabled={existing.includes("pos")}>
                POS
              </option>
              <option
                value="onlineStoreDashBoard"
                disabled={existing.includes("onlineStoreDashBoard")}
              >
                Onlinestore Dashboard
              </option>
              <option
                value="ghoDeliveryB2B"
                disabled={existing.includes("ghoDeliveryB2B")}
              >
                GhoDelivery B2B
              </option>
              <option
                value="ghoDeliveryDashBoard"
                disabled={existing.includes("ghoDeliveryDashBoard")}
              >
                GhoDelivery Dashboard
              </option>
            </select>
          </div>
          <div className="selectNotifyTitleDiv">
            <div className="chooseImgCD">Title</div>
            <Input
              showCount
              maxLength={30}
              value={notificationObj.title}
              onChange={(e) => inputHandler(e)}
              name="title"
            />
          </div>
          <div className="selectNotifyTitleDiv">
            <div className="chooseImgCD">Message</div>
            <TextArea
              showCount
              maxLength={100}
              value={notificationObj.message}
              onChange={(e) => inputHandler(e)}
              name="message"
              style={{ minHeight: "100px" }}
            />
          </div>

          <div className="selectNotifyTitleDiv">
            <div className="chooseImgCD">Redirect link</div>
            <Input
              // showCount
              // maxLength={30}
              // value={newNoti}
              // onChange={notifDataHandler}
              value={notificationObj.link}
              onChange={(e) => inputHandler(e)}
              name="link"
            />
          </div>
          {notificationObj.product &&
            notificationObj.product === "ghoshakB2B" && (
              <div className="selectNotifyTitleDiv">
                <div className="chooseImgCD">Route to</div>
                <Input
                  onChange={(e) => inputHandler(e)}
                  value={notificationObj.route}
                  name="route"
                />
              </div>
            )}

          <div className="add_Notif_Btn_Div">
            <div
              className={
                addingNotification
                  ? "add_Notif_Btn_Inner_Div_Disabled"
                  : "add_Notif_Btn_Inner_Div"
              }
              onClick={() => updateNotificationObj()}
            >
              <div className="send_Word">Add</div>
              {addingNotification && ringLoader()}
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default TopNotifications;
