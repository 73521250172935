import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";

export default function ProfileConfiguration({ classes, profile }) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getNotificationAPI();
  }, [profile]);

  const getNotificationAPI = async () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? null : (
        <>
          <div className="fieldBox">
            <>
              <div id="twoCol" className="fieldWrap">
                <label>Google Location</label>
                <div className="customDateRangePickerContainer">
                  <ul className="horizontalList">
                    {typeof profile.pin == "undefined" ? (
                      <>
                        <li id="noBorder" className="horizontalListItem">
                          <h6>Pin Enabled status</h6>
                          <span>
                            <Badge pill variant="danger">
                              Disabled
                            </Badge>
                          </span>
                        </li>
                      </>
                    ) : (
                      <>
                        <li id="noBorder" className="horizontalListItem">
                          <h6>Pin Enabled status</h6>
                          <Badge pill variant="danger">
                            Disabled
                          </Badge>
                        </li>
                        <li id="noBorder" className="horizontalListItem">
                          <h6>latitude </h6>
                          <span> {profile.pin.location.lat}</span>
                        </li>
                        <li id="noBorder" className="horizontalListItem">
                          <h6>longitude </h6>
                          <span> {profile.pin.location.lon}</span>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>

              <div id="twoCol" className="fieldWrap">
                <label>Delivery Slot Timing</label>
                <div className="customDateRangePickerContainer">
                  <ul className="horizontalList">
                    <li id="noBorder" className="horizontalListItem">
                      <h6>Timing </h6>
                      {Object.entries(profile.deliverySlot || {}).map(
                        ([key, value]) => (
                          <span>
                            <Badge
                              style={{ color: "#fff" }}
                              className="deliverySlot"
                              pill
                              variant="primary"
                            >
                              {`${value.startTime} - ${value.endTime}`}
                            </Badge>
                          </span>
                        )
                      )}
                    </li>
                  </ul>
                </div>
              </div>

              <div id="fullWidth" className="fieldWrap">
                <label>Shop Timing</label>
                {typeof profile.shopTimings == "undefined" ? (
                  <p>-</p>
                ) : (
                  <>
                    <div className="customDateRangePickerContainer">
                      <ul className="horizontalList">
                        {Object.entries(profile.shopTimings).map(
                          ([key, value]) => {
                            return (
                              <>
                                <li
                                  id="noBorder"
                                  style={{
                                    flex: "0 0 auto!important",
                                  }}
                                  className="horizontalListItem"
                                >
                                  <h6>{key}</h6>
                                  <span>
                                    {value.openTime} - {value.closeTime}
                                  </span>
                                </li>
                              </>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </>
          </div>
        </>
      )}
    </>
  );
}
