import { Row, Col, Form, InputGroup } from "react-bootstrap";
import React, { Component } from "react";
import {
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer
} from "recharts";
import config from "../../config";
import Limit from "../../limit";
import Dropdown from "../../dropDown";
import { Typography } from "@material-ui/core";
class CustomizedLabel extends Component {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={15} textAnchor="middle">
        {value}
      </text>
    );
  }
}
export default class TotalRegistration extends Component {
  constructor(props) {
    super(props);
    const year = new Date("2018-01-01").getFullYear();

    const dropdownYears = Array.from(
      new Array(20),
      (val, index) => index + year
    );
    this.state = {
      dateAdded: [],
      monthAnalysis: [],
      showData: false,
      user: "",
      currentYear: "",
      dates: [],
      startDate: "2019-01-01",
      endDate: Limit.size.endDate,
      years: dropdownYears,
      dropdownArray: [],
      userAddedDate: [],
      activity: "",
      orderDate: []
    };

    this.onChange = this.onChange.bind(this);
    this.selectYear = this.selectYear.bind(this);
    this.sortByDate = this.sortByDate.bind(this);
    this.month = [
      "",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
  }

  sortByDate() {
    var tempdata = [];
    // var response = [];
    // var totalUsers = [];
    let orderByDate = {};
    let arrangeDate = {};
    let customerAddedByDate = [];

    let dropdown = Dropdown.filter.activity.map(elements => {
      return Object.keys(elements).map(items => {
        return <option key={items}>{items}</option>;
      });
    });

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              must: [
                { match: { userType: Limit.size.merchantUserType } },
                {
                  range: {
                    userAddedDate: {
                      gte: this.state.startDate,
                      lte: this.state.endDate,
                      boost: 2.0
                    }
                  }
                }
              ],
              must_not: {
                bool: {
                  must: [{ term: { userPincode: Limit.size.ghoshakInternal } }]
                }
              }
            }
          }
        }),

        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map(element => element._source);

        tempdata.forEach(elements => {
          if (
            arrangeDate[elements.userAddedDate.substring(0, 4)] === undefined
          ) {
            arrangeDate[elements.userAddedDate.substring(0, 4)] = {};
          }
          if (
            arrangeDate[elements.userAddedDate.substring(0, 4)][
              parseInt(elements.userAddedDate.substring(5, 7))
            ] === undefined
          ) {
            arrangeDate[elements.userAddedDate.substring(0, 4)][
              parseInt(elements.userAddedDate.substring(5, 7))
            ] = 1;
          } else {
            arrangeDate[elements.userAddedDate.substring(0, 4)][
              parseInt(elements.userAddedDate.substring(5, 7))
            ] += 1;
          }
          if (
            this.state.activity !== "Year" &&
            this.state.activity !== "6 Months" &&
            this.state.activity !== ""
          ) {
            if (orderByDate[elements.userAddedDate] === undefined) {
              orderByDate[elements.userAddedDate] = 1;
            } else {
              orderByDate[elements.userAddedDate] += 1;
            }
          }
        });

        let orderBydateArray = [];
        // Object.entries(orderByDate).forEach(([element, key]) => {});
        if (
          this.state.activity !== "Year" &&
          this.state.activity !== "6 Months" &&
          this.state.activity !== ""
        ) {
          let start = new Date(this.state.startDate);
          let end = new Date(this.state.endDate);

          let loop = new Date(start);
          while (loop <= end) {
            let matchDate = loop.toISOString().substring(0, 10);
            let count = Object.entries(orderByDate).find(
              ([elements]) => elements === matchDate
            );

            count != undefined
              ? orderBydateArray.push({
                  date: count[0],
                  users: count[1]
                })
              : orderBydateArray.push({
                  date: matchDate,
                  users: 0
                });

            var newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
          }
        }

        Object.entries(arrangeDate).forEach(([element, value]) => {
          for (var key in value) {
            customerAddedByDate.push({
              year: element,
              month: this.month[parseInt(key)],
              users: value[key]
            });
          }
        });

        return this.setState({
          monthAnalysis: customerAddedByDate,
          user: tempdata,

          orderDate: orderBydateArray,
          showData: true,
          dropdownArray: dropdown
        });
      });
  }

  async componentDidMount() {
    await this.sortByDate();
  }

  onChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        this.selectYear(name, value);
      }
    );
  }
  selectYear(name, value) {
    var d = new Date();
    if (name === "year") {
      this.setState(
        {
          startDate: `${value}-01-01`,
          endDate: `${value}-12-31`,
          activity: ""
        },
        () => {
          this.sortByDate();
        }
      );
    } else {
      let getDay = Dropdown.filter.activity.map(elements => {
        return elements[value];
      });

      d.setDate(d.getDate() - getDay[0]);

      this.setState(
        {
          startDate: d.toISOString().substring(0, 10),
          year: "",
          endDate: new Date().toISOString().substring(0, 10)
        },
        () => {
          this.sortByDate();
        }
      );
    }
  }

  render() {
    // console.log(this.state.orderDate);
    return (
      <>
        <Typography variant="h2" style={{ textAlign: "center" }} gutterBottom>
          REGISTRATION
        </Typography>
        {this.state.showData && (
          <>
            <Row>
              <Col
                style={{ textAlign: "center" }}
                xs={{ span: 6, offset: 2 }}
                className="division"
              >
                <>
                  <strong> TOTAL REGISTRATION OF GHOSHAK MERCHANT</strong>
                  <h3 style={{ textAlign: "center" }}>
                    {" "}
                    {this.state.user.length}
                  </h3>
                </>
              </Col>
            </Row>

            <Row>
              <Col style={{ marginTop: 25 + "px" }} xs={{ span: 6, offset: 2 }}>
                <div className="cardDisplay">
                  <Form>
                    <Form.Row>
                      <Col>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <strong> FILTER BY</strong>
                            </InputGroup.Text>
                          </InputGroup.Prepend>

                          <Form.Control
                            as="select"
                            name="activity"
                            value={this.state.activity}
                            onChange={this.onChange}
                          >
                            <option>CHOOSE ACTIVITY</option>
                            {this.state.dropdownArray}
                          </Form.Control>
                        </InputGroup>
                      </Col>
                      <Col>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              <strong>YEAR</strong>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            as="select"
                            name="year"
                            onChange={this.onChange}
                          >
                            <option value="">SELECT YEAR</option>
                            {this.state.years.map((year, index) => {
                              return (
                                <option key={`year${index}`} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </InputGroup>
                      </Col>
                    </Form.Row>
                  </Form>
                  <div style={{ width: 100 + "%" }}>
                    <h6 style={{ textAlign: "center" }}>
                      merchant joined ghoshak{" "}
                      <b>
                        {" "}
                        {this.state.startDate}
                        {" - "}
                        {this.state.endDate}
                      </b>
                    </h6>
                  </div>

                  {this.state.activity !== "Year" &&
                  this.state.activity !== "6 Months" &&
                  this.state.activity !== "" ? (
                    <>
                      <ResponsiveContainer aspect={1.6}>
                        <BarChart
                          width={600}
                          height={300}
                          data={this.state.orderDate}
                          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />

                          <XAxis
                            dataKey="date"
                            interval={0}
                            angle={-45}
                            textAnchor="end"
                            height={100}
                          />
                          <YAxis
                            label={{
                              value: "Merchant",
                              angle: -90,
                              position: "insideLeft"
                            }}
                          />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="users" fill="#8884d8">
                            <LabelList dataKey="users" position="top" />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </>
                  ) : (
                    <>
                      <ResponsiveContainer aspect={1.6}>
                        <LineChart
                          width={500}
                          height={300}
                          data={this.state.monthAnalysis}

                          
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5
                          }}
                          className="recharts"
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            tickLine={true}
                            xAxisId={0}
                            dy={0}
                            dx={-0}
                            label={{
                              value: "",
                              angle: 0,
                              position: "bottom"
                            }}
                            interval={0}
                            dataKey="month"
                            tickLine={false}
                            tick={{ fontSize: 9, angle: 0 }}
                          />

                          <XAxis
                            xAxisId={1}
                            dy={-15}
                            dx={0}
                            label={{
                              value: "",
                              angle: 0,
                              position: "bottom"
                            }}
                            // interval={10}
                            dataKey="year"
                            tickLine={false}
                            tick={{ fontSize: 9, angle: 0 }}
                          />
                          <YAxis
                            label={{
                              value: "Merchant",
                              angle: -90,
                              position: "insideLeft"
                            }}
                          />
                          <Tooltip />
                          <Legend />
                          <Line
                            type="monotone"
                            dataKey="users"
                            stroke="#8884d8"
                            activeDot={{ r: 8 }}
                            label={<CustomizedLabel />}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}
