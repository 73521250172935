import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Tab,
  Tabs,
  Modal,
} from "react-bootstrap";
import config from "../config";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Auth } from "aws-amplify";
import ShopProfilecard from "../template/shopProfile";
import { ToWords } from "to-words";
import moment from "moment";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function PackageAndInvoice() {
  const classes = useStyles();
  const words = new ToWords();
  let initialAddBillObject = {
    description: "Appointment Booking",
    month: "",
    amount: "",
    startDate: new Date().toISOString().sub(0, 10),
    endDate: "",
  };
  let initialState = {
    packageStatus: "no",
    packageExpiryDate: "",
    packageUpdatedby: "NA",
    packageTransactionDetails: "",
    userId: "",
    key: "",
    notes: "",
  };
  let initialGenerateInvoice = {
    items: [],
    amount: "",
    totalBillAmount: 0,

    totalTaxCost: 0,
    paymentType: "Cash",

    transactionId: "NA",
    notes: "",
  };
  const [currentUser, setCurrentUser] = useState("");
  const [userId10Digit, setUserId10Digit] = useState("");
  const [shopObject, setShopObject] = useState({});
  const [state, setState] = useState(initialState);
  const [billHistory, setBillHistory] = useState([]);
  const [addBillDetails, setAddBillDetails] = useState(initialAddBillObject);
  const [modalShow, setModalShow] = useState(false);
  const [showShopDetails, setShowShopDetails] = useState(false);
  const [generateInvoiceObject, setgenerateInvoiceObject] = useState(
    initialGenerateInvoice
  );

  const [key, setKey] = useState("");
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const findShop = async (e) => {
    e.preventDefault();

    await getShopAndBills();
  };

  const getShopAndBills = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/getShopAndInvoice`, {
        method: "POST",
        body: JSON.stringify({
          userId10Digit: userId10Digit,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        if (typeof data == "object") {
          if (data.shop.Items.length > 0) {
            let shopProfile = data.shop.Items[0]; // Shop Details
            setShowShopDetails(true); // display shop details
            setShopObject(shopProfile); // update state of shop
            setBillHistory(data.bills); // Past history bills
          }
        }
      })
      .catch((err) => console.log("error in getting shop", err));
  };
  const updatePackage = async (e) => {
    // update package per shop
    e.preventDefault();
    let input = {};
    if (key === "appointment") {
      // setting variable
      input.appointmentPackage = state.packageStatus;
      input.appointmentExpiryDate = state.packageExpiryDate;
      input.appointmentTransactionId = state.packageTransactionDetails;
      input.notes = state.notes;
      input.appointmentUpdatedBy = state.packageUpdatedby;
      input.appointmentUpdatedOn = new Date().toISOString().substr(0, 10);
      input.noteName = "appointmentNotes";
    } else if (key === "billing") {
      input.billingPackage = state.packageStatus;
      input.billingExpiryDate = state.packageExpiryDate;
      input.billingTransactionid = state.packageTransactionDetails;
      input.notes = state.notes;
      input.billingUpdatedBy = state.packageUpdatedby;
      input.billingupdatedOn = new Date().toISOString().substr(0, 10);
      input.noteName = "billingNotes";
    } else if (key === "delivery") {
      input.deliveryPackage = state.packageStatus;
      input.deliveryExpiryDate = state.packageExpiryDate;
      input.deliveryTransactionId = state.packageTransactionDetails;
      input.notes = state.notes;
      input.noteName = "deliveryNotes";
      input.deliveryUpdatedBy = state.packageUpdatedby;
      input.deliveryUpdatedOn = new Date().toISOString().substr(0, 10);
    }

    input.userId = shopObject.userId; //mandatory field (shopId)

    await Promise.all([
      fetch(`${config.ApiGateway}/b2bPackageActivation`, {
        method: "POST",
        body: JSON.stringify(input),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        if (data.status == "success") {
          alert("Package Activated Successfully");
        } else {
          alert("Package Activation Failed");
        }
      })
      .catch((err) => console.log("error in getting shop", err));
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then((data) => {
      let user = { email: data.email, ...data.attributes };
      setCurrentUser(user.phone_number);
      setState((prevState) => ({
        ...prevState,
        packageUpdatedby: user.phone_number,
      }));
    });
  }, []);

  const getPackageType = async (packageType) => {
    await getPackageTemplate(packageType);
  };
  const getPackageTemplate = (packageType) => {
    switch (packageType) {
      case "billing": {
        return setState((prevState) => ({
          ...prevState,
          packageStatus: shopObject.billingPackage,
          packageExpiryDate: shopObject.billingExpiryDate,

          packageTransactionDetails: "",
          userId: shopObject.userId,
          key: packageType,
          notes: "",
        }));
      }
      case "appointment": {
        return setState((prevState) => ({
          ...prevState,
          packageStatus: shopObject.appointmentPackage,
          packageExpiryDate: shopObject.appointmentExpiryDate,

          packageTransactionDetails: "",
          userId: shopObject.userId,
          key: packageType,
          notes: "",
        }));
      }
      case "delivery": {
        return setState((prevState) => ({
          ...prevState,
          packageStatus: shopObject.deliveryPackage,
          packageExpiryDate: shopObject.deliveryExpiryDate,

          packageTransactionDetails: "",
          userId: shopObject.userId,
          key: packageType,
          notes: "",
        }));
      }
    }
  };
  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const renderForm = () => {
    return (
      <Row>
        <Col sm="12">
          <Form onSubmit={updatePackage}>
            <Form.Group as={Row}>
              <Form.Label className="packageFormLabel" column>
                <span style={{ textTransform: "capitalize" }}>{key}</span>{" "}
                Package
              </Form.Label>

              <Col xs={3}>
                <Form.Check
                  type="radio"
                  required
                  name="packageStatus"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  label={"YES"}
                  value="yes"
                  checked={state.packageStatus === "yes"}
                />
              </Col>
              <Col xs={3}>
                <Form.Check
                  type="radio"
                  name="packageStatus"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  label={"NO"}
                  value="no"
                  checked={state.packageStatus === "no"}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="packageFormLabel" column>
                Transaction ID :
              </Form.Label>

              <Col className="packageFormTextBox">
                <Form.Control
                  name="packageTransactionDetails"
                  value={state.packageTransactionDetails}
                  required
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label className="packageFormLabel" column>
                Expiry Date :
              </Form.Label>

              <Col className="packageFormTextBox">
                <Form.Control
                  name="packageExpiryDate"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  value={state.packageExpiryDate}
                  type="date"
                  required
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label className="packageFormLabel" column>
                Notes :
              </Form.Label>

              <Col className="packageFormTextBox">
                <Form.Control
                  as="textarea"
                  name="notes"
                  value={state.notes}
                  required
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Button className="packageFormButton" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    );
  };
  const removeItems = (removeRowIndex) => {
    const removeData = [...generateInvoiceObject.items];

    let splicedData = removeData.splice(removeRowIndex, 1);

    setgenerateInvoiceObject((prevState) => ({
      ...prevState,
      items: [...removeData],
      totalBillAmount:
        parseInt(prevState.totalBillAmount) - parseInt(splicedData[0].amount),
      totalTaxCost:
        parseInt(prevState.totalTaxCost) - parseInt(splicedData[0].tax),
    }));
  };

  const concatBills = (e) => {
    e.preventDefault();
    setgenerateInvoiceObject((prevState) => ({
      ...prevState,
      items: [
        ...prevState.items,
        {
          description: `Ghoshak ${addBillDetails.description} Subscription Charges - ${addBillDetails.month} months `,
          period: `period ( ${moment(new Date(addBillDetails.startDate)).format(
            "D MMMM YYYY"
          )} - ${moment(new Date(addBillDetails.endDate)).format(
            "D MMMM YYYY"
          )} ) `,

          month: addBillDetails.month,
          amount: addBillDetails.amount,
          packageType: addBillDetails.description,
          offerValidity: `${addBillDetails.month} months`,
          packageCost: (
            parseFloat(addBillDetails.amount) *
            (100 / 118)
          ).toFixed(2),
          tax: (
            parseFloat(addBillDetails.amount) -
            parseFloat(addBillDetails.amount) * (100 / 118)
          ).toFixed(2),
        },
      ],
      totalBillAmount:
        parseFloat(prevState.totalBillAmount) +
        parseFloat(addBillDetails.amount),

      totalTaxCost: (
        parseFloat(prevState.totalTaxCost) +
        (parseFloat(addBillDetails.amount) -
          parseFloat(addBillDetails.amount) * (100 / 118))
      ).toFixed(2),
    }));
    setAddBillDetails(initialAddBillObject);
  };
  const generateInvoice = async (e) => {
    // generate invoice 
    e.preventDefault();

    await Promise.all([
      fetch(`${config.ApiGateway}/invoiceGeneration`, {
        method: "POST",
        body: JSON.stringify({
          userId: shopObject.userId,
          amount: generateInvoiceObject.amount,
          items: generateInvoiceObject.items,
          totalBillAmount: generateInvoiceObject.totalBillAmount.toString(),
          totalTaxCost: (
            parseFloat(generateInvoiceObject.totalTaxCost) / 2
          ).toString(),
          paymentType: generateInvoiceObject.paymentType,
          currentDate: moment(new Date()).format("D MMMM YYYY"),
          transactionId: generateInvoiceObject.transactionId,
          notes: generateInvoiceObject.notes,
          amtInWords: words.convert(generateInvoiceObject.totalBillAmount, {
            currency: true,

            billedBy: currentUser,
          }),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        alert(data.status);
        await getShopAndBills();
        handleClose();
        setgenerateInvoiceObject(initialGenerateInvoice);
      })
      .catch((err) => console.log("error in getting shop", err));
  };

  const addBillOnChange = (name, value) => { 
    // dynamic onchange set state
    let valueEx = value;
    let nameEx = name;
    setAddBillDetails((prevState) => ({
      ...prevState,
      [nameEx]: valueEx,
    }));
  };

  return (
    <Container>
      <div style={{ width: "100%", height: "30px" }}></div>
      <div className="orderHeader">
        <h2 className="orderHeaderText">Package</h2>
      </div>

      <Row>
        <Col className="dashboardColumnBox columnCenter" sm="10">
          {/* {Enter Shop Id} */}
          <Form onSubmit={findShop}>
            <Form.Row>
              <Col sm="4">
                <Form.Label>Enter Shop Id</Form.Label>
              </Col>
              <Col sm="6">
                <Form.Control
                  onChange={(e) => setUserId10Digit(e.target.value)}
                  value={userId10Digit}
                />
              </Col>
              <Col sm="2">
                <Button type="submit">Search</Button>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      <div className="vertical-space-30"></div>
      {showShopDetails && (
        <>
          <Row>
            <Col className="columnCenter" sm="10">
              <ShopProfilecard shopInfo={shopObject} />
            </Col>
          </Row>

          <Row>
            <Col className="dashboardColumnBox columnCenter" sm="10">
              {/* React bootstsrap library - tabs */}
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                className="packagetab"
                onSelect={async (k) => {
                  setKey(k); // appointment,billing,delivery
                  await getPackageType(k);
                }}
              >
                <Tab eventKey="appointment" title="Appointment">
                  <div className="vertical-space-5"></div>
                  {renderForm()}
                </Tab>
                <Tab eventKey="billing" title="Billing">
                  <div className="vertical-space-5"></div>
                  {renderForm()}
                </Tab>
                <Tab eventKey="delivery" title="Delivery">
                  <div className="vertical-space-5"></div>
                  {renderForm()}
                </Tab>
              </Tabs>
            </Col>
          </Row>

          <div className="vertical-space-5"></div>
          <Row>
            <Col className="dashboardColumnBox columnCenter" sm={"10"}>
              <div className="vertical-space-5"></div>

              <Button onClick={() => setModalShow(true)}>Add Invoice</Button>
              <div className="vertical-space-5"></div>

              <MaterialTable
                columns={[
                  {
                    title: "invoice",
                    field: "invoiceNo",
                  },
                  {
                    title: "Shop Name",
                    field: "shopName",
                  },
                  {
                    title: "Shop Type",
                    field: "shopType",
                  },
                  {
                    title: "Billed on",
                    field: "billingDate",
                  },
                  {
                    title: "Amount",
                    field: "amountPaid",
                  },
                  {
                    title: "Description",
                    field: "items",
                    render: (rowData) => {
                      let renderText = rowData.items.map(
                        (elements) => `${elements.description}, `
                      );
                      return <p>{renderText}</p>;
                    },
                  },
                  {
                    title: "PDF Url",
                    field: "pdfURL",
                  },
                  {
                    title: "Billed By",
                    field: "billedBy",
                  },
                ]}
                data={billHistory.Items || []}
                options={{
                  search: true,

                  showTitle: false,

                  rowStyle: {
                    fontSize: 12,
                  },

                  paging: false,
                }}
              />
            </Col>
          </Row>

          <Row>
            <Modal
              className="viewCartDetails"
              show={modalShow}
              onHide={handleClose}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="wrapModalBody">
                  <Form style={{ width: "100%" }} onSubmit={concatBills}>
                    <Form.Row>
                      <Col sm="2">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="select"
                          required
                          value={addBillDetails.description}
                          onChange={async (e) => {
                            let value = e.target.value;

                            setAddBillDetails((prevState) => ({
                              ...prevState,
                              description: value,
                            }));
                          }}
                        >
                          <option value="Appointment Booking">
                            Appointment
                          </option>
                          <option value="Billing">Billing</option>
                          <option value="Online order">Delivery</option>
                          <option value="Loyalty">Loyalty</option>
                          <option value="Website">Website</option>
                        </Form.Control>
                      </Col>
                      <Col sm="2">
                        <Form.Label>Months</Form.Label>
                        <Form.Control
                          required
                          value={addBillDetails.month}
                          name="month"
                          onChange={(e) =>
                            addBillOnChange(e.target.name, e.target.value)
                          }
                        ></Form.Control>
                      </Col>
                      <Col sm="2">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                          required
                          value={addBillDetails.amount}
                          name="amount"
                          onChange={(e) =>
                            addBillOnChange(e.target.name, e.target.value)
                          }
                        ></Form.Control>
                      </Col>
                      <Col sm="2">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={addBillDetails.startDate}
                          name="startDate"
                          onChange={(e) =>
                            addBillOnChange(e.target.name, e.target.value)
                          }
                        ></Form.Control>
                      </Col>
                      <Col sm="2">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          required
                          type="date"
                          name="endDate"
                          value={addBillDetails.endDate}
                          onChange={(e) =>
                            addBillOnChange(e.target.name, e.target.value)
                          }
                        ></Form.Control>
                      </Col>
                      <Col className="description-add-button" sm="2">
                        <br />
                        <Button type="submit">Add</Button>
                      </Col>
                    </Form.Row>
                  </Form>
                  <div className="vertical-space-5"></div>
                  <Form style={{ width: "100%" }} onSubmit={generateInvoice}>
                    <Form.Row>
                      <Col sm="4">
                        <Form.Label className="packageFormLabel" column>
                          Payment Type:
                        </Form.Label>
                      </Col>
                      <Col sm="6" className="packageFormTextBox">
                        <Form.Control
                          as="Select"
                          value={generateInvoiceObject.paymentType}
                          required
                          onChange={(e) => {
                            let value = e.target.value;
                            setgenerateInvoiceObject((prevState) => ({
                              ...prevState,
                              paymentType: value,
                            }));
                          }}
                        >
                          <option value="Cash">Cash</option>
                          <option value="Card">Card</option>
                          <option value="Gpay">Gpay</option>
                          <option value="Paytm">Paytm</option>
                          <option value="UPI">UPI</option>
                          <option value="Others">Others</option>
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <div className="vertical-space-5"></div>
                    {generateInvoiceObject.paymentType !== "Cash" && (
                      <>
                        <Form.Row>
                          <Col sm="4">
                            <Form.Label className="packageFormLabel" column>
                              Transaction ID:
                            </Form.Label>
                          </Col>
                          <Col sm="6" className="packageFormTextBox">
                            <Form.Control
                              value={generateInvoiceObject.transactionId}
                              required
                              placeholder="If no id, please type as NA"
                              onChange={(e) => {
                                let value = e.target.value;
                                setgenerateInvoiceObject((prevState) => ({
                                  ...prevState,
                                  transactionId: value,
                                }));
                              }}
                            ></Form.Control>
                          </Col>
                        </Form.Row>
                        <div className="vertical-space-5"></div>
                        <Form.Row>
                          <Col sm="4">
                            <Form.Label className="packageFormLabel" column>
                              Notes:
                            </Form.Label>
                          </Col>
                          <Col sm="6" className="packageFormTextBox">
                            <Form.Control
                              as="textarea"
                              value={generateInvoiceObject.notes}
                              placeholder="Should not exceed more than 120 characters"
                              required
                              onChange={(e) => {
                                let value = e.target.value;
                                setgenerateInvoiceObject((prevState) => ({
                                  ...prevState,
                                  notes: value,
                                }));
                              }}
                            ></Form.Control>
                          </Col>
                        </Form.Row>
                      </>
                    )}
                    <Form.Group className="generate-invoice-button" as={Row}>
                      <Button
                        disabled={generateInvoiceObject.items.length <= 0}
                        type="submit"
                      >
                        Generate Invoice
                      </Button>
                    </Form.Group>
                  </Form>
                  <div className="vertical-space-5"></div>
                  {generateInvoiceObject.items.length > 0 && (
                    <>
                      <Row>
                        <TableContainer>
                          <Table
                            className={classes.table}
                            style={{ border: "1px dotted black" }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Index</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>
                                  Package Cost in INR (
                                  <b>
                                    <small>Without Tax</small>
                                  </b>
                                  )
                                </TableCell>
                                <TableCell>Tax in INR</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {generateInvoiceObject.items.map((row, index) => (
                                <TableRow key={row.description}>
                                  <TableCell component="th" scope="row">
                                    {index + 1}
                                  </TableCell>

                                  <TableCell>{row.description}</TableCell>
                                  <TableCell>{row.packageCost}</TableCell>
                                  <TableCell>{row.tax}</TableCell>
                                  <TableCell>
                                    <Button onClick={() => removeItems(index)}>
                                      Remove
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <div className="vertical-space-5"></div>
                        <div className="totalList">
                          <div className="rowFlex">
                            <div className="rowFlex-left">
                              <label className="rowFlex-label">SGST 9% :</label>
                            </div>

                            <div className="rowFlex-right">
                              <label className="totalListValue">
                                ₹{" "}
                                {parseFloat(
                                  generateInvoiceObject.totalTaxCost
                                ) / 2}
                              </label>
                            </div>
                          </div>
                          <div className="rowFlex">
                            <div className="rowFlex-left">
                              <label className="rowFlex-label">CGST 9% :</label>
                            </div>

                            <div className="rowFlex-right">
                              <label className="totalListValue">
                                ₹{" "}
                                {parseFloat(
                                  generateInvoiceObject.totalTaxCost
                                ) / 2}
                              </label>
                            </div>
                          </div>
                          <div className="rowFlex">
                            <div className="rowFlex-left">
                              <label className="rowFlex-label">
                                <b> Grand Total :</b>
                              </label>
                            </div>

                            <div className="rowFlex-right">
                              <label className="totalListValue">
                                ₹ {generateInvoiceObject.totalBillAmount}
                              </label>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </>
                  )}
                </div>
              </Modal.Body>
            </Modal>
          </Row>
        </>
      )}
    </Container>
  );
}
