import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Row, Col, Container, Button } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import config from "../config";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class PackageExpiration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadPage: false,
      packageObject: {},
      showUserList: false,
      userArray: [],
      rowsPerPageCustomer: 10,
      Customerpage: 0,
      exportData: [],
      headers: [],
      hideCell: true,
    };
    this.getMerchantDetails = this.getMerchantDetails.bind(this);
    this.handleCustomerPage = this.handleCustomerPage.bind(this);
    this.handleCustomerPagePerRow = this.handleCustomerPagePerRow.bind(this);
    this.csvLink = React.createRef();
  }
  handleCustomerPage = (event, page) => {
    this.setState({ Customerpage: page });
  };
  handleCustomerPagePerRow = (event) => {
    this.setState({ rowsPerPageCustomer: event.target.value });
  };

  export() {
    let tempArr = [];

    const headers = [
      { label: "Merchant ID", key: "userId10Digit" },
      { label: "Pincode", key: "userPincode" },
      { label: "Name", key: "userName" },
      { label: "Phone Number", key: "userPhoneNumber" },
      { label: "Place", key: "place" },

      { label: "Membership ID", key: "membershipId" },
      { label: "Package Name", key: "packageName" },

      { label: "Membership Upadted On", key: "membershipupdatedOn" },

      { label: "Membership Expiry", key: "membershipExpiry" },
      { label: "Loyalty updated ID", key: "loyaltyupdatedOn" },
      { label: "Loyalty Expiry On", key: "loyaltyExpiryDate" },
      { label: "Loyalty updated ID", key: "billingupdatedOn" },
      { label: "Loyalty Expiry On", key: "billingExpiryDate" },
    ];

    this.setState(
      {
        headers: headers,
      },
      () => {
        this.csvLink.current.link.click();
      }
    );
  }

  componentDidMount = async () => {
    await Promise.all([
      fetch(
        `${config.ApiGateway}/getPackageExpiration`,

        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        let expirationObject = {
          membershipExpired: [],
          loyaltyExpired: [],
          nxtMonthMembExp: [],
          thisMonthMembExp: [],
          todayMembExp: [],
          nxtMonthloyaltyExp: [],
          thisMonthloyaltyExp: [],
          todayloyaltyExp: [],
          nxtWeekMembExp: [],
          nxtWeekloyaltyExp: [],
          activeMembership: [],
          activeLoyalty: [],
          activeBilling: [],
          nxtWeekBillExp: [],
          nxtMonthBillExp: [],
          thisMonthBillExp: [],
          todayBillExp: [],
          BillExpired: [],
        };

        var CurrentDate = new Date();
        var time = new Date(CurrentDate.getTime());

        time.setMonth(CurrentDate.getMonth() + 1);
        time.setDate(0);

        let remaianingDays = [];

        do {
          remaianingDays.push(CurrentDate.toISOString().substring(0, 10));

          CurrentDate.setDate(CurrentDate.getDate() + 1);
        } while (
          time.toISOString().substring(0, 10) >=
          CurrentDate.toISOString().substring(0, 10)
        );

        let followingWeekDates = [];

        let date = new Date();
        let nextMonth = date;
        let todayDate = date.toISOString().substring(0, 10);
        let todayDateArray = [];
        todayDateArray.push(date.toISOString().substring(0, 10));
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        let nextMonthDate = [];
        nextMonthDate.push(nextMonth.toISOString().substring(0, 7));
        let i = 0;

        date = new Date();
        do {
          date.setDate(date.getDate() + 1);
          followingWeekDates.push(date.toISOString().substring(0, 10));
          i++;
        } while (i <= 7);

        data.userList.forEach((key) => {
          let tempUser = {};

          tempUser = key;

          let getPlace = data.getAreaName.Items.find(
            (headerPincode) => headerPincode.pincode == key.userPincode
          );

          if (getPlace != undefined) {
            tempUser.place = getPlace.place;
          } else {
            tempUser.place = "Not Found";
          }

          if (key.membershipId != undefined) {
            let getMembershipLimits = data.membershipDetails.Items.find(
              (header) => key.membershipId == header.membershipId
            );
            if (getMembershipLimits != undefined) {
              tempUser.packageName = getMembershipLimits.membershipType;
            } else {
              tempUser.packageName = "Not defined";
            }
          } else {
            tempUser.packageName = "Not defined";
          }

          if (todayDate <= key.membershipExpiry) {
            expirationObject.activeMembership.push(tempUser);

            if (nextMonthDate.includes(key.membershipExpiry.substring(0, 7))) {
              // filter next month membership expiry
              expirationObject.nxtMonthMembExp.push(tempUser);
            }

            if (todayDateArray.includes(key.membershipExpiry)) {
              // filter Today membership expiry
              expirationObject.todayMembExp.push(tempUser);
            }
            if (followingWeekDates.includes(key.membershipExpiry)) {
              // filter next week membership expiry
              expirationObject.nxtWeekMembExp.push(tempUser);
            }

            if (remaianingDays.includes(key.membershipExpiry)) {
              // filter next week membership expiry
              expirationObject.thisMonthMembExp.push(tempUser);
            }
          } else {
            expirationObject.membershipExpired.push(tempUser);
          }

          if (todayDate <= key.loyaltyExpiryDate) {
            expirationObject.activeLoyalty.push(tempUser);

            if (nextMonthDate.includes(key.loyaltyExpiryDate.substring(0, 7))) {
              // filter next month loyalty expiry
              expirationObject.nxtMonthloyaltyExp.push(tempUser);
            }

            if (todayDateArray.includes(key.loyaltyExpiryDate)) {
              // filter Today loyalty expiry
              expirationObject.todayloyaltyExp.push(tempUser);
            }
            if (followingWeekDates.includes(key.loyaltyExpiryDate)) {
              // filter next week loyalty expiry
              expirationObject.nxtWeekloyaltyExp.push(tempUser);
            }

            if (remaianingDays.includes(key.loyaltyExpiryDate)) {
              // filter next week loyalty expiry
              expirationObject.thisMonthloyaltyExp.push(tempUser);
            }
          } else {
            expirationObject.loyaltyExpired.push(tempUser);
          }

          if (key.billingPackage != undefined) {
            if (todayDate <= key.billingExpiryDate) {
              expirationObject.activeBilling.push(tempUser);

              if (
                nextMonthDate.includes(key.billingExpiryDate.substring(0, 7))
              ) {
                // filter next month Billing expiry
                expirationObject.nxtMonthBillExp.push(tempUser);
              }

              if (todayDateArray.includes(key.billingExpiryDate)) {
                // filter Today Billing expiry
                expirationObject.todayBillExp.push(tempUser);
              }
              if (followingWeekDates.includes(key.billingExpiryDate)) {
                // filter next week Billing expiry
                expirationObject.nxtWeekBillExp.push(tempUser);
              }

              if (remaianingDays.includes(key.billingExpiryDate)) {
                // filter next week Billing expiry
                expirationObject.thisMonthBillExp.push(tempUser);
              }
            } else {
              expirationObject.BillExpired.push(tempUser);
            }
          }
        });

        this.setState({
          loadPage: true,
          packageObject: expirationObject,
        });
      })
      .catch((err) => console.log(err));
  };
  getMerchantDetails(users, hidecell) {
    // to get Merchant list
    this.setState({
      showUserList: true,
      userArray: users,
      hideCell: hidecell, // true---> display membership   || false --- > display loyalty
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <Container fluid>
        <>
          <Row style={{ width: 100 + "%", justifyContent: "center" }}>
            <b style={{ fontSize: 32 }}>Package Expiration</b>
          </Row>

          {this.state.loadPage && (
            <>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col className="division" sm={{ span: 11 }}>
                  <div
                    className="headerName"
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%",
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}> Membership</h3>
                  </div>
                  <Row
                    style={{
                      marginBottom: 20 + "px",
                      marginTop: 20 + "px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Col className="verticalRightBorder" sm={{ span: 2 }}>
                      <label style={{ textAlign: "center" }}>
                        Membership Expired
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b>
                          {this.state.packageObject.membershipExpired.length}
                        </b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.membershipExpired,
                              "MERCHANT"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                    <Col className="verticalRightBorder" sm={{ span: 2 }}>
                      <label style={{ textAlign: "center" }}>
                        Next month Expiry
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b>
                          {" "}
                          {this.state.packageObject.nxtMonthMembExp.length}
                        </b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.nxtMonthMembExp,
                              "MERCHANT"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                    <Col className="verticalRightBorder" sm={{ span: 2 }}>
                      <label style={{ textAlign: "center" }}>
                        Today Expiry
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b> {this.state.packageObject.todayMembExp.length}</b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.todayMembExp,
                              "MERCHANT"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                    <Col className="verticalRightBorder" sm={{ span: 2 }}>
                      <label style={{ textAlign: "center" }}>
                        Expire by this month
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b>
                          {" "}
                          {this.state.packageObject.thisMonthMembExp.length}
                        </b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.thisMonthMembExp,
                              "MERCHANT"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                    <Col
                      className="verticalRightBorder"
                      style={{ borderRightColor: "transparent" }}
                      sm={{ span: 2 }}
                    >
                      <label style={{ textAlign: "center" }}>
                        upcoming week Expiration
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b> {this.state.packageObject.nxtWeekMembExp.length}</b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.nxtWeekMembExp,
                              "MERCHANT"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div className="rowClass">
                    <h5 style={{ textAlign: "center" }}>
                      {this.state.packageObject.activeMembership.length}{" "}
                      Merchants are in Membership package
                    </h5>
                    <Button
                      onClick={() =>
                        this.getMerchantDetails(
                          this.state.packageObject.activeMembership,
                          "MERCHANT"
                        )
                      }
                      size="sm"
                      variant="outline-dark"
                    >
                      View All
                    </Button>
                  </div>
                </Col>
                <Col className="division" sm={{ span: 11 }}>
                  <div
                    className="headerName"
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%",
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}> Loyalty</h3>
                  </div>
                  <Row
                    style={{
                      marginBottom: 20 + "px",
                      marginTop: 20 + "px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Col className="verticalRightBorder" sm={{ span: 2 }}>
                      <label style={{ textAlign: "center" }}>
                        Loyalty Expired
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b>{this.state.packageObject.loyaltyExpired.length}</b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.loyaltyExpired,
                              "LOYALTY"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                    <Col className="verticalRightBorder" sm={{ span: 2 }}>
                      <label style={{ textAlign: "center" }}>
                        Next month Expiry
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b>
                          {" "}
                          {this.state.packageObject.nxtMonthloyaltyExp.length}
                        </b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.nxtMonthloyaltyExp,
                              "LOYALTY"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                    <Col className="verticalRightBorder" sm={{ span: 2 }}>
                      <label style={{ textAlign: "center" }}>
                        Today Expiry
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b>
                          {" "}
                          {this.state.packageObject.todayloyaltyExp.length}
                        </b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.todayloyaltyExp,
                              "LOYALTY"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                    <Col className="verticalRightBorder" sm={{ span: 2 }}>
                      <label style={{ textAlign: "center" }}>
                        Expire by this month
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b>
                          {" "}
                          {this.state.packageObject.thisMonthloyaltyExp.length}
                        </b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.thisMonthloyaltyExp,
                              "LOYALTY"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                    <Col
                      className="verticalRightBorder"
                      style={{ borderRightColor: "transparent" }}
                      sm={{ span: 2 }}
                    >
                      <label style={{ textAlign: "center" }}>
                        upcoming week Expiration
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b>
                          {" "}
                          {this.state.packageObject.nxtWeekloyaltyExp.length}
                        </b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.nxtWeekloyaltyExp,
                              "LOYALTY"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div className="rowClass">
                    <h5 style={{ textAlign: "center" }}>
                      {this.state.packageObject.activeLoyalty.length} Merchants
                      are in Loyalty package
                    </h5>
                    <Button
                      onClick={() =>
                        this.getMerchantDetails(
                          this.state.packageObject.activeLoyalty,
                          "LOYALTY"
                        )
                      }
                      size="sm"
                      variant="outline-dark"
                    >
                      View All
                    </Button>
                  </div>
                </Col>
                <Col className="division" sm={{ span: 11 }}>
                  <div
                    className="headerName"
                    style={{
                      backgroundColor: "#fafafa",
                      width: 100 + "%",
                      margin: 1 + "%",
                    }}
                  >
                    <h3 style={{ textAlign: "center" }}> Billing</h3>
                  </div>
                  <Row
                    style={{
                      marginBottom: 20 + "px",
                      marginTop: 20 + "px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Col className="verticalRightBorder" sm={{ span: 2 }}>
                      <label style={{ textAlign: "center" }}>
                        Billing Expired
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b>{this.state.packageObject.BillExpired.length}</b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.BillExpired,
                              "BILLING"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                    <Col className="verticalRightBorder" sm={{ span: 2 }}>
                      <label style={{ textAlign: "center" }}>
                        Next month Expiry
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b>
                          {" "}
                          {this.state.packageObject.nxtMonthBillExp.length}
                        </b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.nxtMonthBillExp,
                              "BILLING"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                    <Col className="verticalRightBorder" sm={{ span: 2 }}>
                      <label style={{ textAlign: "center" }}>
                        Today Expiry
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b> {this.state.packageObject.todayBillExp.length}</b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.todayBillExp,
                              "BILLING"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                    <Col className="verticalRightBorder" sm={{ span: 2 }}>
                      <label style={{ textAlign: "center" }}>
                        Expire by this month
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b>
                          {" "}
                          {this.state.packageObject.thisMonthBillExp.length}
                        </b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.thisMonthBillExp,
                              "BILLING"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                    <Col
                      className="verticalRightBorder"
                      style={{ borderRightColor: "transparent" }}
                      sm={{ span: 2 }}
                    >
                      <label style={{ textAlign: "center" }}>
                        upcoming week Expiration
                      </label>
                      <h3 style={{ textAlign: "center" }}>
                        <b>
                          {" "}
                          {this.state.packageObject.nxtMonthBillExp.length}
                        </b>
                      </h3>
                      <div className="buttonCenter">
                        <Button
                          onClick={() =>
                            this.getMerchantDetails(
                              this.state.packageObject.nxtMonthBillExp,
                              "BILLING"
                            )
                          }
                          size="sm"
                          variant="outline-dark"
                        >
                          View
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div className="rowClass">
                    <h5 style={{ textAlign: "center" }}>
                      {this.state.packageObject.activeBilling.length} Merchants
                      are in Billing package
                    </h5>
                    <Button
                      onClick={() =>
                        this.getMerchantDetails(
                          this.state.packageObject.activeBilling,
                          "BILLING"
                        )
                      }
                      size="sm"
                      variant="outline-dark"
                    >
                      View All
                    </Button>
                  </div>
                </Col>
              </Row>
              {this.state.showUserList && (
                <>
                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col
                      style={{ textAlign: "center" }}
                      className="division"
                      sm={10}
                    >
                      <h3> Merchant List</h3>
                      <Row>
                        <Col
                          style={{
                            marginLeft: 90 + "%",
                            marginBottom: 20 + "px",
                          }}
                          sm={"auto"}
                        >
                          <Button
                            onClick={() => {
                              this.export();
                            }}
                          >
                            Download
                          </Button>
                          <CSVLink
                            enclosingCharacter={`'`}
                            data={this.state.userArray}
                            headers={this.state.headers}
                            ref={this.csvLink}
                            target="_blank"
                          />
                        </Col>
                      </Row>
                      <Table
                        style={{
                          width: 100 + "%",
                          border: 0.5 + "px",
                          borderColor: "#ced4da",
                          borderStyle: "solid",
                        }}
                        className={classes.table}
                        size="small"
                      >
                        <>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.tableCell}>
                                S NO
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                Merchant ID
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                Name
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                Phone Number
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                Shop Name
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                Shop Type
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                Pincode
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                Place
                              </TableCell>
                              {this.state.hideCell === "MERCHANT" && (
                                <>
                                  {" "}
                                  <TableCell className={classes.tableCell}>
                                    Membership ID
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    Package Name
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    updated on
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    Expiration
                                  </TableCell>
                                </>
                              )}
                              {this.state.hideCell === "LOYALTY" && (
                                <>
                                  {" "}
                                  <TableCell className={classes.tableCell}>
                                    updated on
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    Expiration
                                  </TableCell>
                                </>
                              )}
                              {this.state.hideCell === "BILLING" && (
                                <>
                                  {" "}
                                  <TableCell className={classes.tableCell}>
                                    updated on
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    Expiration
                                  </TableCell>
                                </>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.userArray
                              .slice(
                                this.state.Customerpage *
                                  this.state.rowsPerPageCustomer,
                                this.state.Customerpage *
                                  this.state.rowsPerPageCustomer +
                                  this.state.rowsPerPageCustomer
                              )

                              .map((elements, index) => (
                                <TableRow key={elements.userId10Digit}>
                                  <TableCell className={classes.tableCell}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    <Link
                                      to={{
                                        pathname: "/merchant",
                                        search: `?userid=${elements.userId10Digit}`,
                                      }}
                                    >
                                      {" "}
                                      {elements.userId10Digit}{" "}
                                    </Link>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {elements.userName}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {elements.userPhoneNumber}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {elements.shopName}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {elements.shopType}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {elements.userPincode}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {elements.place}
                                  </TableCell>
                                  {this.state.hideCell === "MERCHANT" && (
                                    <>
                                      {" "}
                                      <TableCell className={classes.tableCell}>
                                        {elements.membershipId}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {elements.packageName}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {elements.membershipupdatedOn}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {elements.membershipExpiry}
                                      </TableCell>
                                    </>
                                  )}
                                  {this.state.hideCell === "LOYALTY" && (
                                    <>
                                      {" "}
                                      <TableCell className={classes.tableCell}>
                                        {elements.loyaltyupdatedOn}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {elements.loyaltyExpiryDate}
                                      </TableCell>
                                    </>
                                  )}

                                  {this.state.hideCell === "BILLING" && (
                                    <>
                                      {" "}
                                      <TableCell className={classes.tableCell}>
                                        {elements.billingupdatedOn}
                                      </TableCell>
                                      <TableCell className={classes.tableCell}>
                                        {elements.billingExpiryDate}
                                      </TableCell>
                                    </>
                                  )}
                                </TableRow>
                              ))}
                          </TableBody>
                        </>
                      </Table>
                      <TablePagination
                        component="div"
                        count={this.state.userArray.length}
                        rowsPerPage={this.state.rowsPerPageCustomer}
                        page={this.state.Customerpage}
                        onChangePage={this.handleCustomerPage}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        onChangeRowsPerPage={this.handleCustomerPagePerRow}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </>
      </Container>
    );
  }
}
PackageExpiration.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PackageExpiration);
