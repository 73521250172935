import React, { Component } from "react";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Auth } from "aws-amplify";
import config from "../config";
import Resizer from "react-image-file-resizer";
import { Form, Row, Col, Button } from "react-bootstrap";
export default class AddGhoshakGift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ghoshakgift: {
        userId: "",
        userPincode: "",
        giftDetails: "",
        userName: "",
        userId10Digit: "",
        imageFile: [],
        giftAddeddate: new Date().toISOString().substr(0, 10),
        giftAddedBy: "",
      },
      imageSize: "",
      imagePreviewUrl: [],
      showUsers: [],
      searchPincode: "",
      userPhoneNumber: "",
      insertgiftFormState: false,
      showrecords: undefined,
    };
    this.addGhoshakGiftsubmit = this.addGhoshakGiftsubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleimage = this.handleimage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.pincodeOnchange = this.pincodeOnchange.bind(this);
    this.pincodeSearch = this.pincodeSearch.bind(this);
    this.viewUser = this.viewUser.bind(this);
    this.reset = this.state;
  }
  onChange(e) {
    this.setState({
      ...this.state,
      ghoshakgift: {
        ...this.state.ghoshakgift,
        [e.target.name]: e.target.value,
      },
    });
  }
  viewUser(userdetails) {
    this.setState({
      ...this.state,
      ghoshakgift: {
        ...this.state.ghoshakgift,
        userName: userdetails.userName,
        userId: userdetails.userId,
        userPincode: userdetails.userPincode,
        userId10Digit: userdetails.userId10Digit,
      },

      userPhoneNumber: userdetails.userPhoneNumber,
      insertgiftFormState: true,
    });
  }

  componentDidMount() {
    Promise.all([fetch(`${config.json.URL}/imagesize.json`)])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.setState({
          imageSize: data.GIFT,
        });
      })
      .catch((err) => console.log(err));

    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({
          ...this.state,
          ghoshakgift: {
            ...this.state.ghoshakgift,
            giftAddedBy: user.attributes.phone_number,
          }, //====>
        });
      })

      .catch((err) => console.log(err));
  }
  pincodeSearch(e) {
    e.preventDefault();
    Promise.all([
      fetch(`${config.ApiGateway}/pincodesearch`, {
        method: "POST",
        body: JSON.stringify({ searchPincode: this.state.searchPincode }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.setState({
          showUsers: data,
          showrecords: true,
        });
      })
      .catch((err) => console.log(err));
  }
  pincodeOnchange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  removeImage() {
    this.setState({
      ...this.state,
      ghoshakgift: {
        ...this.state.ghoshakgift,
        imageFile: [],
      },
      imagePreviewUrl: [],
    });
  }
  handleimage(event) {
    // if (event.target.files[0].size > parseInt(this.state.imageSize)) {
    //   alert("image file should be under 150kb ");
    //   return false;
    // }
    this.setState({
      selectedFile: event.target.files[0],
    });

    let reader = new FileReader();
    let imageFormData = new FormData();
    imageFormData.append("photo", event.target.files[0]);

    Resizer.imageFileResizer(
      event.target.files[0], //is the file of the new image that can now be uploaded...
      300, // is the maxWidth of the  new image
      400, // is the maxHeight of the  new image
      "WEBP", // is the compressFormat of the  new image
      100, // is the quality of the  new image
      0, // is the rotatoion of the  new image
      (uri) => {
        this.setState({
          ...this.state,
          ghoshakgift: {
            ...this.state.ghoshakgift,

            imageFile: uri.replace(/^data:image\/\w+;base64,/, ""),
          },
        });
        this.setState({
          imagePreviewUrl: uri,
        });
      },
      "base64" // is the output type of the new image
    );

    // reader.onloadend = () => {
    //   this.setState({
    //     ...this.state,
    //     ghoshakgift: {
    //       ...this.state.ghoshakgift,

    //       imageFile: reader.result.replace(/^data:image\/\w+;base64,/, "")
    //     }
    //   });
    //   this.setState({
    //     imagePreviewUrl: reader.result
    //   });
    // };

    // reader.readAsDataURL(event.target.files[0]);
  }
  addGhoshakGiftsubmit(e) {
    e.preventDefault();
    Promise.all([
      fetch(`${config.ApiGateway}/addghoshakgift`, {
        method: "POST",
        body: JSON.stringify(this.state.ghoshakgift),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        alert("gift added sucessfully");
        this.setState(this.reset);
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <>
        {this.state.insertgiftFormState ? (
          <Row>
            <div className="contentTableheader">
              <h3 style={{ textAlign: "center" }}>ADD GHOSHAK GIFT</h3>
            </div>
            <Col md={{ span: 4, offset: 2 }}>
              <Form onSubmit={this.addGhoshakGiftsubmit}>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      USER ID
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="userId"
                        onChange={this.onChange}
                        value={this.state.ghoshakgift.userId}
                        disabled={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      USER NAME
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="userName"
                        onChange={this.onChange}
                        value={this.state.ghoshakgift.userName}
                        disabled={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      PINCODE
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="userPincode"
                        onChange={this.onChange}
                        value={this.state.ghoshakgift.userPincode}
                        disabled={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      USER PHONE NUMBER
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="userId"
                        onChange={this.onChange}
                        value={this.state.userPhoneNumber}
                        disabled={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      10-DIGIT ID
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        name="pincode"
                        onChange={this.onChange}
                        value={this.state.ghoshakgift.userId10Digit}
                        disabled={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      GIFT DETAILS
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="textarea"
                        name="giftDetails"
                        onChange={this.onChange}
                        value={this.state.ghoshakgift.giftDetails}
                      ></Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label className="asterik" column sm="4">
                      UPLOAD IMAGE
                    </Form.Label>
                    <Col>
                      <input type="file" onChange={this.handleimage}></input>
                    </Col>
                  </Form.Group>

                  <Button type="submit" color="primary">
                    SUBMIT
                  </Button>
                </Col>
              </Form>
            </Col>
            <Col>
              <Col md={{ span: 4 }}>
                <Col>
                  <div>
                    <img
                      src={this.state.imagePreviewUrl}
                      alt={this.state.imagePreviewUrl}
                      width="300"
                      height="180"
                    ></img>
                    <Button onClick={this.removeImage} variant="outline-light">
                      <CancelOutlinedIcon style={{ fontSize: 20 }} />
                    </Button>
                  </div>
                </Col>
              </Col>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col md={{ span: 4, offset: 3 }} className="division">
                <h4 style={{ textAlign: "center" }}>ENTER PINCODE </h4>
                <Form onSubmit={this.pincodeSearch}>
                  <Form.Control
                    type="text"
                    name="searchPincode"
                    pattern="[0-9]{6}"
                    onChange={this.pincodeOnchange}
                  ></Form.Control>
                  <Button
                    style={{
                      width: 100 + "%",
                      height: 30 + "%",
                      marginTop: 2 + "%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    type="submit"
                  >
                    {" "}
                    SEARCH
                  </Button>
                </Form>
              </Col>
            </Row>
            {this.state.showrecords ? (
              <>
                <Row className="division">
                  <Col>
                    <div
                      style={{
                        backgroundColor: "#fafafa",
                        width: 100 + "%",
                        margin: 1 + "%",
                      }}
                    >
                      <Col>
                        <h3 style={{ textAlign: "center" }}>
                          CUSTOMERS IN {this.state.searchPincode}
                        </h3>
                      </Col>
                    </div>
                    <Table
                      style={{
                        width: 100 + "%",
                        border: 0.5 + "px",
                        borderColor: "#ced4da",
                        borderStyle: "solid",
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S NO</TableCell>
                          <TableCell align="center">USER ID</TableCell>
                          <TableCell align="center">USER NAME</TableCell>
                          <TableCell align="center">PINCODE</TableCell>
                          <TableCell align="center">USER ADDRESS</TableCell>
                          <TableCell align="center">USER TYPE</TableCell>
                          <TableCell align="center">
                            USER PHONE NUMBER
                          </TableCell>
                          <TableCell align="center">ACTION</TableCell>
                          {/* <TableCell align="center">USER ID</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.showUsers.map((items, index) => (
                          <TableRow key={items.userId}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{items.userId}</TableCell>
                            <TableCell align="center">
                              {items.userName}
                            </TableCell>
                            <TableCell align="center">
                              {items.userPincode}
                            </TableCell>
                            <TableCell align="center">
                              {items.userAddress}
                            </TableCell>
                            <TableCell align="center">
                              {items.userType}
                            </TableCell>
                            <TableCell align="center">
                              {items.userPhoneNumber}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                onClick={() => {
                                  this.viewUser(items);
                                }}
                              >
                                VIEW
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </>
    );
  }
}
