import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { Row, Col, Container, Form, Button } from "react-bootstrap";

import config from "../config";

export default class SalesTarget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sales: {
        pincode: "",
        startdate: "",
        enddate: ""
      },
      fetchSalesJson: [],
      showresult: true,
      salesResult: [],
      salesStatistics: {},
      displayTable: false
    };

    this.onChange = this.onChange.bind(this);
    this.getFetchTarget = this.getFetchTarget.bind(this);
    this.showDetails = this.showDetails.bind(this);
  }
  onChange(e) {
    this.setState({
      ...this.state,
      sales: {
        ...this.state.sales,
        [e.target.name]: e.target.value
      }
    });
  }
  showDetails() {
    this.setState({
      displayTable: true
    });
  }
  async componentDidMount() {
    try {
      const response = await fetch(`${config.json.URL}/salestarget.json`);
      const targetDetails = await response.json();
      console.log(targetDetails.sales[0]);
      this.setState({ fetchSalesJson: targetDetails.sales[0] });
    } catch (error) {
      console.log(error);
    }
  }
  async getFetchTarget(e) {
    e.preventDefault();

    Promise.all([
      await fetch(`${config.ApiGateway}/target`, {
        method: "POST",
        body: JSON.stringify(this.state.sales),
        headers: {
          "Content-Type": "application/json"
        }
      })
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        var salesStats = {
          totaldays: 0,
          todayleavedays: 0,
          totalworkeddays: 0,
          targetacheived: 0,
          targetnotacheived: 0
        };
        data.forEach(elements => {
          if (this.state.fetchSalesJson.leaveday === elements.day) {
            salesStats.todayleavedays += 1;
          } else {
            salesStats.totalworkeddays += 1;

            if (this.state.fetchSalesJson.loginperday > elements.count) {
              salesStats.targetnotacheived += 1;
            } else {
              salesStats.targetacheived += 1;
            }
          }
          salesStats.totaldays += 1;
        });

        this.setState({
          salesResult: data,
          salesStatistics: salesStats,
          showresult: false
        });
      })
      .catch(err => console.log(err));
  }
  render() {
    return (
      <Container fluid>
        <h3
          style={{
            textAlign: "center",
            marginTop: 1 + "%",
            marginBottom: 2 + "%"
          }}
        >
          SALES TARGET
        </h3>
        <Row>
          {this.state.showresult ? (
            <>
              <Col md={{ span: 4, offset: 4 }}>
                <Form onSubmit={this.getFetchTarget}>
                  <Form.Group as={Row}>
                    <Form.Label className="asterik" column sm="4">
                      PINCODE
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        onChange={this.onChange}
                        name="pincode"
                        value={this.state.sales.pincode}
                        required
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label className="asterik" column sm="4">
                      START DATE
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="date"
                        onChange={this.onChange}
                        name="startdate"
                        value={this.state.sales.startdate}
                        required
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label className="asterik" column sm="4">
                      END DATE
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="date"
                        onChange={this.onChange}
                        name="enddate"
                        value={this.state.sales.enddate}
                        required
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Button style={{ float: "right" }} type="submit">
                    SEARCH
                  </Button>
                </Form>
              </Col>
            </>
          ) : (
            <>
              {this.state.displayTable ? (
                <>
                  <Col md={{ span: 6, offset: 2 }}>
                    <Table
                      style={{
                        width: 100 + "%",
                        border: 0.5 + "px",
                        borderColor: "#ced4da",
                        borderStyle: "solid"
                      }}
                      size="medium"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S NO</TableCell>
                          <TableCell align="center">DATE</TableCell>
                          <TableCell align="center">DAY</TableCell>
                          <TableCell align="center">LOGIN PER DAY</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.salesResult.map((elements, index) => (
                          <TableRow>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">
                              {elements.date}
                            </TableCell>
                            <TableCell align="center">{elements.day}</TableCell>

                            {elements.day ===
                            this.state.fetchSalesJson.leaveday ? (
                              <TableCell
                                align="center"
                                style={{
                                  backgroundColor: this.state.fetchSalesJson
                                    .leavecolor
                                }}
                              >
                                {elements.count}
                              </TableCell>
                            ) : (
                              <TableCell
                                align="center"
                                style={{
                                  backgroundColor:
                                    this.state.fetchSalesJson.loginperday >
                                    elements.count
                                      ? this.state.fetchSalesJson.minimumcolor
                                      : this.state.fetchSalesJson.maximumcolor
                                }}
                              >
                                {elements.count}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Col>
                  <Col md={{ span: 2, offset: 1 }}>
                    <>
                      <ul>
                        <li style={{ listStyle: "none" }}>
                          <div className="input-color">
                            <div
                              className="color-box"
                              style={{
                                backgroundColor: this.state.fetchSalesJson
                                  .maximumcolor
                              }}
                            ></div>
                            <h6 style={{ paddingLeft: 20 + "px" }}>TARGET</h6>
                          </div>
                        </li>
                        <li style={{ listStyle: "none" }}>
                          <div className="input-color">
                            <div
                              className="color-box"
                              style={{
                                backgroundColor: this.state.fetchSalesJson
                                  .minimumcolor
                              }}
                            ></div>
                            <h6 style={{ paddingLeft: 20 + "px" }}>
                              BELOW TARGET
                            </h6>
                          </div>
                        </li>
                        <li style={{ listStyle: "none" }}>
                          <div className="input-color">
                            <div
                              className="color-box"
                              style={{
                                backgroundColor: this.state.fetchSalesJson
                                  .leavecolor
                              }}
                            ></div>
                            <h6 style={{ paddingLeft: 20 + "px" }}>HOLIDAY</h6>
                          </div>
                        </li>
                      </ul>
                    </>
                  </Col>
                </>
              ) : (
                <>
                  <Col md={{ span: 4, offset: 3 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                      }}
                    >
                      <strong>TOTAL WORKED DAYS</strong>
                      <h5>{this.state.salesStatistics["totalworkeddays"]}</h5>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                      }}
                    >
                      <strong>TOTAL NUMBER OF TARGET NOT ACHEIVED</strong>
                      <h5>{this.state.salesStatistics["targetnotacheived"]}</h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                      }}
                    >
                      <strong>TOTAL NUMBER OF TARGET ACHEIVED</strong>
                      <h5>{this.state.salesStatistics["targetacheived"]}</h5>
                    </div>
                    <Button
                      style={{ float: "right" }}
                      onClick={this.showDetails}
                    >
                      {" "}
                      VIEW{" "}
                    </Button>
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
      </Container>
    );
  }
}
