import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import config from "../config";
import "../deleivery/delivery.css";
const OrderStatus = () => {
  const [orderData, setOrderData] = useState([]);
  const fetchOnlineorder = async () => {
    try {
      var res = await fetch(`${config.ApiGateway}/viewOnlineOrders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          days: "30",
        }),
      });
      res = await res.json();
      console.log(res);
      setOrderData(res);
    } catch (err) {
      console.log("Error in fetching online orders", err);
      alert("Failed to fetch the data. Please try again");
    }
  };
  useEffect(() => {
    fetchOnlineorder();
  }, []);
  return (
    <Container>
      <div style={{ marginTop: "30px" }}>
        <MaterialTable
          data={orderData}
          columns={[
            {
              title: "Order Id",
              field: "orderId",
            },
            {
              title: "Ghoshak Id",
              field: "userId10Digit",
            },
            {
              title: "Shop Name",
              field: "shopName",
            },
            {
              title: "Shop Phone Number",
              field: "userPhoneNumber",
            },
            {
              title: "Order Date",
              field: "billingDate",
            },
            {
              title: "Delivery Method",
              field: "deliveryMethod",
            },
            {
              title: "Order Amount",
              field: "totalAmount",
            },
            {
              title: "Payment Id",
              field: "razorpayOrderId",
            },
            {
              title: "Customer Details",
              render: (rowData) => (
                <p>{`${rowData.customerName} 
                ${rowData.customerPhoneNumber} `}</p>
              ),
            },
            {
              title: "Delivery address",
              field: "deliveryAddress.address",
            },
            {
              title: "Order Status",
              field: "status",
            },
            {
              title: "Delivery person details",
              render: (rowData) => (
                <p>{`${
                  rowData.deliveryPersonDetails &&
                  rowData.deliveryPersonDetails.name
                } 
                ${
                  rowData.customerPhoneNumber &&
                  "+91" + rowData.deliveryPersonDetails.phoneNumber
                } `}</p>
              ),
            },
          ]}
        />
      </div>
    </Container>
  );
};

export default OrderStatus;
