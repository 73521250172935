import React from "react";
import { Row, Col } from "react-bootstrap";
import GetAppIcon from "@material-ui/icons/GetApp";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
const PartnerDownload = ({ shop, classes, download }) => {
  return (
    <>
      <div className="title">
        <h4>
          <span>Download</span>
        </h4>
      </div>
      <div className="profileCardContainer">
        <Row>
          <Col xs={"6"} sm={"6"}>
            <Row>
              <>
                <Col id="cardBox" className="colCenter" md="4" xs="3" sm="3">
                  <Row>
                    <Col className="icon" xs="3" md="4" sm={"2"}>
                      <GetAppIcon />
                    </Col>
                    <Col className="iconText" xs="3" md="8" sm={"10"}>
                      <span>Total Downloads</span>
                      <span>{shop.length}</span>
                    </Col>
                  </Row>
                </Col>
              </>
            </Row>
          </Col>
          <Col xs={"6"} sm={"6"}>
            <div className="tableCard">
              <div className="tableCardBody">
                <div className="tableCardTitle">
                  <div style={{ fontWeight: "400" }}>Segment</div>
                </div>
                <div className={classes.root}>
                  <TableContainer className={classes.container}>
                    <Table size="small" className={classes.table} stickyHeader>
                      <TableHead className={classes.tableHeadBody}>
                        <TableRow>
                          <TableCell className={classes.tableHead}>
                            Segment
                          </TableCell>
                          <TableCell className={classes.tableHead}>
                            Downloads
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody className={classes.tbody}>
                        {Object.keys(download).map((items) => (
                          <TableRow
                            className={classes.tableBodyRow}
                            key={items}
                          >
                            <TableCell
                              className={classes.tableCell}
                              component="th"
                              scope="row"
                            >
                              {items}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {download[items]}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PartnerDownload;
