import MaterialTable from "material-table";
import React, { useEffect } from "react";
import config from "../config";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
export const getAccountStatusDetails = (obj) => {
  const userAddedDayJsDate = dayjs(obj.userAddedDate);
  const billingPackageExpiryDayJsDate = dayjs(obj.billingExpiryDate);
  const websiteExpiryOnDayJsDate = dayjs(obj.websiteExpiryOn);
  const ghoDeliveryPackageExpiryDateDayJsDate = dayjs(
    obj.ghoDeliveryPackageExpiryDate
  );
  const customDomainExpiryOnDayJsDate = dayjs(obj.customDomainExpiryOn);
  const billingDateDiff = billingPackageExpiryDayJsDate.diff(
    userAddedDayJsDate,
    "days"
  );
  const websiteDateDiff = websiteExpiryOnDayJsDate.diff(
    userAddedDayJsDate,
    "days"
  );
  const ghoDeliveryDateDiff = ghoDeliveryPackageExpiryDateDayJsDate.diff(
    userAddedDayJsDate,
    "days"
  );
  const customDomainDateDiff = customDomainExpiryOnDayJsDate.diff(
    userAddedDayJsDate,
    "days"
  );

  const isWebsiteActive = dayjs().isBefore(websiteExpiryOnDayJsDate);
  const isCustomDomainActive = dayjs().isBefore(customDomainExpiryOnDayJsDate);
  const result = {};
  if (obj.billingPackage === "yes") {
    result["billing"] = {
      active: true,
      isTrial: billingDateDiff === 14 ? true : false,
      expiryDate: obj.billingExpiryDate,
    };
  } else {
    const value = {
      active: false,
      isTrial: billingDateDiff === 14 ? true : false,
      expiryDate: obj.billingExpiryDate,
    };
    if (billingDateDiff === 1 || billingDateDiff === 0) {
      value["notStarted"] = true;
    }
    result["billing"] = value;
  }
  if (obj.ghoDeliveryPackageActive === true) {
    result["ghoDelivery"] = {
      active: true,
      isTrial: ghoDeliveryDateDiff === 14 ? true : false,
      expiryDate: obj.ghoDeliveryPackageExpiryDate,
    };
  } else {
    const value = {
      active: false,
      isTrial: ghoDeliveryDateDiff === 14 ? true : false,
      expiryDate: obj.ghoDeliveryPackageExpiryDate,
    };
    if (
      obj.ghoDeliveryPackageExpiryDate === "NA" ||
      !obj.ghoDeliveryPackageExpiryDate
    ) {
      value["notStarted"] = true;
    }
    result["ghoDelivery"] = value;
  }
  console.log("websiteDateDiff1212", obj.shopName, { websiteDateDiff, obj });
  if (isWebsiteActive) {
    result["onlineStore"] = {
      active: true,
      isTrial: websiteDateDiff === 14 ? true : false,
      expiryDate: obj.websiteExpiryOn,
    };
  } else {
    const value = {
      active: false,
      isTrial: websiteDateDiff === 14 ? true : false,
      expiryDate: obj.websiteExpiryOn,
    };
    if (websiteDateDiff === 1 || websiteDateDiff === 0) {
      value["notStarted"] = true;
    }
    result["onlineStore"] = value;
  }
  if (isCustomDomainActive) {
    result["website"] = {
      active: true,
      isTrial: customDomainDateDiff === 14 ? true : false,
      expiryDate: obj.customDomainExpiryOn,
    };
  } else {
    const value = {
      active: false,
      isTrial: customDomainDateDiff === 14 ? true : false,
      expiryDate: obj.customDomainExpiryOn,
    };
    if (obj.customDomainExpiryOn === "NA" || !obj.customDomainExpiryOn) {
      value["notStarted"] = true;
    }
    result["website"] = value;
  }
  return result;
};

const Reseller = () => {
  const { resellerId } = useParams();
  const [userDetails, setUserDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getResellers = async (resellerId) => {
    try {
      const res = await fetch(`${config.ApiGateway}/getshop`, {
        method: "POST",
        body: JSON.stringify({
          type: "getAllUsersByReseller",
          resellerId: resellerId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      setUserDetails(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getResellers(resellerId);
    })(resellerId);
  }, [resellerId]);

  console.log("userDetails99", userDetails);

  return (
    <div>
      <div>
        <MaterialTable
          isLoading={loading}
          title=" List of User"
          columns={[
            {
              title: "Shop Name",
              field: "shopName",
            },
            {
              title: "Phone Number",
              field: "userPhoneNumber",
            },
            {
              title: "ShopId",
              field: "userId10Digit",
            },
            {
              title: "Date of Joining",
              field: "userAddedDate",
            },
            {
              title: "Enrolled Products",
              render: (rowData) => {
                const details = getAccountStatusDetails(rowData);
                console.log("details", details);
                return (
                  <>
                    {Object.keys(details)
                      .map((i) => {
                        return details[i]?.active || details[i]?.isTrial
                          ? i
                          : null;
                      })
                      .filter(Boolean)
                      .join(", ")}
                  </>
                );
              },
            },
          ]}
          data={userDetails}
          options={{
            exportButton: true,
            pageSize: 5,
            maxBodyHeight: 437,
            pageSizeOptions: [5, 10, 20, 100],
            grouping: false,
            filtering: false,
          }}
          style={{
            width: "90%",
            margin: "90px auto",
          }}
        />
      </div>
    </div>
  );
};

export default Reseller;
