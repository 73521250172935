import React, { Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import config from "../../config";
export default class CustomerPincodeInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showData: true,
      pincode: "",
      top10CustomerLikedOffers: [],
      totalUsers: [],
      top10CustomerLikedShops: [],
      top10CustomerBypoints: [],
      customersLikedOffers: [],
      customersAddedShops: [],
      top10CustomerRefferers: [],
      customerGhoshakCoupons: [],
      customerMerchantCoupons: [],
      totalreferers: [],
      totalCoupons: "",
      allUser: [],
      loyaltyTransaction: [],
      top10LoyaltyPoints: [],
    };
    this.pincodeSearch = this.pincodeSearch.bind(this);
    this.onChange = this.onChange.bind(this);
    this.viewTable = this.viewTable.bind(this);
    this.couponDetails = this.couponDetails.bind(this);
    this.getDetails = this.getDetails.bind(this);
  }
  getDetails(response) {
    var userId = response.map((items) => items.custId);
    var tempdata = [];
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              filter: [
                {
                  terms: {
                    userId: userId,
                  },
                },
              ],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map((element) => element._source);
        var arr = [];
        response.forEach((element) => {
          tempdata.forEach((items) => {
            if (element.custId === items.userId) {
              arr.push(Object.assign({}, element, items));
            }
          });
        });
        this.viewTable(arr, [
          "addedPts",
          "currentPts",
          "redeemedPts",
          "Interaction/Transaction",
        ]);
      })
      .catch((err) => console.log(err));
  }
  // componentDidMount() {
  //   this.pincodeSearch();
  // }

  viewTable(items, array) {
    this.props.renderTable(items, array);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  pincodeSearch(e) {
    e.preventDefault();
    var tempdata = [];
    var likedOffers = [];
    var userId = [];
    var likedOffersUsers = [[], [], [], [], []];
    var addedfavouriteShops = [[], [], [], [], []];
    var referral = [];
    Promise.all([
      fetch(`${config.Elasticsearch.URL}/users/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              filter: [
                {
                  match: {
                    userType: "customer",
                  },
                },
              ],
              must: [
                {
                  terms: {
                    userPincode: [this.state.pincode],
                  },
                },
              ],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        tempdata = data.hits.hits.map((element) => element._source);
        var favoriteShops = [];
        tempdata.forEach((elements) => {
          likedOffers.push({
            userId: elements.userId,
            userName: elements.userName,
            userPhoneNumber: elements.userPhoneNumber,
            userPincode: elements.userPincode,
            userAddress: elements.userAddress,
            userId10Digit: elements.userId10Digit,
            likedOffers: Object.keys(elements.favoriteOffers).length,
          });
          favoriteShops.push({
            userId: elements.userId,
            userName: elements.userName,
            userPhoneNumber: elements.userPhoneNumber,
            userId10Digit: elements.userId10Digit,
            userPincode: elements.userPincode,
            userAddress: elements.userAddress,
            favouriteShops: Object.keys(elements.favoriteShops).length,
          });
          userId.push(elements.userId);
          if (
            elements.referredBy !== "None" &&
            elements.referredBy !== undefined
          ) {
            referral.push(elements.referredBy);
          }
        });

        var top10LikedOffers = likedOffers
          .sort((a, b) => b.likedOffers - a.likedOffers)
          .slice(0, 10);
        var top10LikedShops = favoriteShops
          .sort((a, b) => b.favouriteShops - a.favouriteShops)
          .slice(0, 10);

        likedOffers.forEach((items) => {
          if (items.likedOffers === 0) {
            likedOffersUsers[0].push(items);
          } else if (items.likedOffers >= 1 && items.likedOffers <= 9) {
            likedOffersUsers[1].push(items);
          } else if (items.likedOffers >= 10 && items.likedOffers <= 49) {
            likedOffersUsers[2].push(items);
          } else if (items.likedOffers >= 50 && items.likedOffers <= 99) {
            likedOffersUsers[3].push(items);
          } else if (items.likedOffers >= 100) {
            likedOffersUsers[4].push(items);
          }
        });

        favoriteShops.forEach((items) => {
          if (items.favouriteShops === 0) {
            addedfavouriteShops[0].push(items);
          } else if (items.favouriteShops >= 1 && items.favouriteShops <= 9) {
            addedfavouriteShops[1].push(items);
          } else if (items.favouriteShops >= 10 && items.favouriteShops <= 49) {
            addedfavouriteShops[2].push(items);
          } else if (items.favouriteShops >= 50 && items.favouriteShops <= 99) {
            addedfavouriteShops[3].push(items);
          } else if (items.favouriteShops >= 100) {
            addedfavouriteShops[4].push(items);
          }
        });

        this.setState({
          totalUsers: tempdata.length,
          top10CustomerLikedOffers: top10LikedOffers,
          top10CustomerLikedShops: top10LikedShops,
          customersLikedOffers: likedOffersUsers,
          customersAddedShops: addedfavouriteShops,

          allUser: likedOffers,
        });

        this.couponDetails(userId, tempdata, referral);
      })
      .catch((err) => console.log(err));
  }
  couponDetails(userId, userDetails, referral) {
    var tempdata = [];
    var tempdata1 = [];
    var count = 0;
    var topReferrerals = [];
    var couponsConsumed = 0;
    var count1 = 0;
    var couponGhoshak = [];
    var couponMerchant = [];
    var tempdata2 = [];
    var loyalty = [];
    var ghoshak = [];
    var temparray = [];
    var loyaltyaddedPoints = 0;
    var loyaltyaddedCount = 0;
    var loyaltyredeemedPoints = 0;
    var loyaltyredeemedCount = 0;
    var ghoshakaddedPoints = 0;
    var ghoshakaddedCount = 0;
    var ghoshakredeemedPoints = 0;
    var ghoshakredeemedCount = 0;
    userDetails.forEach((elements) => {
      count = 0;
      referral.forEach((items) => {
        if (items === elements.referralCode) {
          count++;
        }
      });

      if (count > 0) {
        topReferrerals.push({
          userId: elements.userId,
          userName: elements.userName,
          userPhoneNumber: elements.userPhoneNumber,
          userPincode: elements.userPincode,
          userAddress: elements.userAddress,
          userId10Digit: elements.userId10Digit,
          count: count,
        });
      }
    });

    var top10Reffers = topReferrerals
      .sort((a, b) => b.count - a.count)
      .slice(0, 10);

    Promise.all([
      fetch(`${config.Elasticsearch.URL}/coupons/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              filter: [
                {
                  terms: {
                    custId: userId,
                  },
                },
              ],
              must: [
                {
                  terms: {
                    couponStatus: ["claim", "redeemed"],
                  },
                },
              ],
              must_not: [
                {
                  terms: {
                    points: [
                      "true",
                      "this.state.ghoshakpoints",
                      "ghoshaktest",
                      "ghoshakagain",
                    ],
                  },
                },
              ],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
      fetch(`${config.Elasticsearch.URL}/points/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              filter: [
                {
                  terms: {
                    custId: userId,
                  },
                },
              ],
              must: [
                {
                  terms: {
                    pointsAction: ["redeemed"],
                  },
                },
              ],
              must_not: [
                {
                  terms: {
                    points: [
                      "true",
                      "this.state.ghoshakpoints",
                      "ghoshaktest",
                      "ghoshakagain",
                    ],
                  },
                },
              ],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
      fetch(`${config.Elasticsearch.URL}/points/_search?size=10000`, {
        method: "POST",
        body: JSON.stringify({
          query: {
            bool: {
              filter: [
                {
                  terms: {
                    custId: userId,
                  },
                },
              ],
              must: [
                {
                  terms: {
                    pointsAction: ["redeemed", "added"],
                  },
                },
              ],
              must_not: [
                {
                  terms: {
                    points: [
                      "true",
                      "this.state.ghoshakpoints",
                      "ghoshaktest",
                      "ghoshakagain",
                    ],
                  },
                },
              ],
            },
          },
        }),

        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1, res2, res3]) =>
        Promise.all([res1.json(), res2.json(), res3.json()])
      )
      .then(([data, data2, data3]) => {
        data.hits.hits.map((element) => tempdata.push(element._source));
        data2.hits.hits.map((element) => tempdata1.push(element._source));
        data3.hits.hits.map((element) => tempdata2.push(element._source));
        var totalPoints = tempdata1.reduce(
          (totalPoints, { points, custId }) => {
            (
              totalPoints.find((el) => el.custId === custId) ||
              (totalPoints[totalPoints.length] = { custId, points: 0 })
            ).points += +parseFloat(points);

            return totalPoints;
          },
          []
        );

        userDetails.forEach((items) => {
          loyaltyaddedPoints = 0;
          loyaltyaddedCount = 0;
          loyaltyredeemedPoints = 0;
          loyaltyredeemedCount = 0;
          ghoshakaddedPoints = 0;
          ghoshakaddedCount = 0;
          ghoshakredeemedPoints = 0;
          ghoshakredeemedCount = 0;
          tempdata2.forEach((elements) => {
            if (elements.pointsType === "loyalty") {
              if (items.userId === elements.custId) {
                if (elements.pointsAction === "added") {
                  loyaltyaddedPoints += parseFloat(elements.points);
                  loyaltyaddedCount++;
                } else if (elements.pointsAction === "redeemed") {
                  loyaltyredeemedPoints += parseFloat(elements.points);
                  loyaltyredeemedCount++;
                }
              }
            } else if (elements.pointsType === "ghoshak") {
              if (items.userId === elements.custId) {
                if (elements.pointsAction === "added") {
                  ghoshakaddedPoints += parseFloat(elements.points);
                  ghoshakaddedCount++;
                } else if (elements.pointsAction === "redeemed") {
                  ghoshakredeemedPoints += parseFloat(elements.points);
                  ghoshakredeemedCount++;
                }
              }
            }
          });
          ghoshak.push({
            userId: items.userId,
            ghoshakaddedPoints: ghoshakaddedPoints,
            // ghoshakaddedCount: ghoshakaddedCount,
            ghoshakredeemedPoints: ghoshakredeemedPoints,
            ghoshakredeemedCount: ghoshakredeemedCount,
            // totalPoints: ghoshakaddedPoints - ghoshakredeemedPoints
            totalinteraction: ghoshakredeemedCount,
            userName: items.userName,
            userPhoneNumber: items.userPhoneNumber,
            userPincode: items.userPincode,
            userAddress: items.userAddress,
          });
          loyalty.push({
            userId: items.userId,
            loyaltyaddedPoints: loyaltyaddedPoints,
            // loyaltyaddedCount: loyaltyaddedCount,
            loyaltyredeemedPoints: loyaltyredeemedPoints,
            loyaltyredeemedCount: loyaltyredeemedCount,
            // totalPoints: loyaltyaddedCount - loyaltyredeemedPoints
            totalinteraction: loyaltyredeemedCount,
            userName: items.userName,
            userPhoneNumber: items.userPhoneNumber,
            userPincode: items.userPincode,
            userAddress: items.userAddress,
            userId10Digit: items.userId10Digit,
          });
        });
        var top10_loyaltytransaction = loyalty
          .sort((a, b) => b.totalinteraction - a.totalinteraction)
          .slice(0, 10);

        userDetails.forEach((items) => {
          count = 0;
          count1 = 0;
          tempdata.forEach((elements) => {
            if (elements.couponType === "GHOSHAK") {
              if (items.userId == elements.custId) {
                count++;
              }
            } else if (elements.couponType === "MERCHANT") {
              if (items.userId == elements.custId) {
                count1++;
              }
            }
          });
          couponGhoshak.push({
            userId: items.userId,
            userName: items.userName,
            userPhoneNumber: items.userPhoneNumber,
            userPincode: items.userPincode,
            userAddress: items.userAddress,
            consumed: count,
            userId10Digit: items.userId10Digit,
          });
          couponMerchant.push({
            userId: items.userId,
            userName: items.userName,
            userPhoneNumber: items.userPhoneNumber,
            userId10Digit: items.userId10Digit,
            userPincode: items.userPincode,
            userAddress: items.userAddress,
            consumed: count1,
          });
          couponsConsumed += count + count1;
        });

        this.setState({
          top10CustomerBypoints: totalPoints,
          top10CustomerRefferers: top10Reffers,
          customerGhoshakCoupons: couponGhoshak,
          customerMerchantCoupons: couponMerchant,
          totalreferers: topReferrerals,
          totalCoupons: couponsConsumed,
          // showData: true,
          loyaltyTransaction: loyalty,
          top10LoyaltyPoints: top10_loyaltytransaction,
          showData: false,
        });
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <>
        {this.state.showData && (
          <>
            <Row>
              <Col md={{ span: 4, offset: 3 }} className="division">
                <h4 style={{ textAlign: "center" }}>VIEW PINCODE STATS</h4>
                <Form onSubmit={this.pincodeSearch}>
                  <Form.Control
                    type="text"
                    name="pincode"
                    onChange={this.onChange}
                  ></Form.Control>
                  <Button
                    style={{
                      width: 100 + "%",
                      height: 30 + "%",
                      marginTop: 2 + "%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    type="submit"
                  >
                    {" "}
                    SEARCH
                  </Button>
                </Form>
              </Col>
            </Row>
          </>
        )}
        {!this.state.showData && (
          <>
            <Row style={{ width: 100 + "%" }}>
              <Col md={{ span: 3 }} className="division">
                <b>TOTAL CUSTOMERS</b>
                <h3 style={{ textAlign: "center" }}>{this.state.totalUsers}</h3>
              </Col>
              <Col md={{ span: 4, offset: 1 }} className="division">
                <b>
                  <span style={{ textAlign: "center" }}>
                    COUPONS CONSUMED (<small>ghoshak & merchant </small>)
                  </span>
                </b>

                <h3 style={{ textAlign: "center" }}>
                  {this.state.totalCoupons}
                </h3>
              </Col>
              <Col md={{ span: 3, offset: 1 }} className="division">
                <b>TOTAL REFERS</b>
                <h3 style={{ textAlign: "center" }}>
                  {this.state.totalreferers.length}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col className="division">
                <b>CUSTOMER USED GHOSHAK COUPONS</b>
                <h3 style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      this.viewTable(this.state.customerGhoshakCoupons, [
                        "couponsConsumed",
                      ]);
                    }}
                  >
                    VIEW
                  </Button>
                </h3>
              </Col>
            </Row>
            <Row>
              <Col className="division">
                <b>CUSTOMER USED MERCHANT COUPONS</b>
                <h3 style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      this.viewTable(this.state.customerMerchantCoupons, [
                        "couponsConsumed",
                      ]);
                    }}
                  >
                    VIEW
                  </Button>
                </h3>
              </Col>
            </Row>
            <Row>
              <Col className="division">
                <b>LOYALTY TRANSACTION & INTERACTION</b>
                <h3 style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      this.viewTable(this.state.loyaltyTransaction, [
                        "addedPts",
                        "currentPts",
                        "redeemedPts",
                        "Interaction/Transaction",
                      ]);
                    }}
                  >
                    VIEW
                  </Button>
                </h3>
                <h3 style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      this.viewTable(this.state.top10LoyaltyPoints, [
                        "addedPts",
                        "currentPts",
                        "redeemedPts",
                        "Interaction/Transaction",
                      ]);
                    }}
                  >
                    TOP 10 LOYALTY TRANSACTION/INTERACTION
                  </Button>
                </h3>
              </Col>
            </Row>
            <Row>
              <Col className="division">
                <b>TOP 10 CUSTOMERS ADDED OFFERS TO FAVOURITE</b>
                <h3 style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      this.viewTable(this.state.top10CustomerLikedOffers, [
                        "addedToFav",
                      ]);
                    }}
                  >
                    VIEW
                  </Button>
                </h3>
              </Col>
            </Row>
            <Row>
              <Col className="division">
                <b>TOP 10 CUSTOMERS ADDED SHOPS TO FAVOURITE</b>
                <h3 style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      this.viewTable(this.state.top10CustomerLikedShops, [
                        ["addedToFav"],
                      ]);
                    }}
                  >
                    VIEW
                  </Button>
                </h3>
              </Col>
            </Row>
            <Row>
              <Col className="division">
                <b>TOP 10 CUSTOMERS BY POINTS</b>
                <h3 style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      this.getDetails(this.state.top10CustomerBypoints);
                    }}
                  >
                    VIEW
                  </Button>
                </h3>
              </Col>
            </Row>
            <Row>
              <Col className="division">
                <b>TOP 10 CUSTOMERS REFERERS</b>
                <h3 style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      this.viewTable(this.state.top10CustomerRefferers, [
                        "referedCount",
                      ]);
                    }}
                  >
                    VIEW
                  </Button>
                </h3>
              </Col>
            </Row>
            <Row>
              <Col className="division">
                <b>All Customer</b>
                <h3 style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      this.viewTable(this.state.allUser, [""]);
                    }}
                  >
                    VIEW
                  </Button>
                </h3>
              </Col>
            </Row>
            <Row className="division">
              <Col>
                <div
                  style={{
                    backgroundColor: "#fafafa",
                    width: 100 + "%",
                    margin: 1 + "%",
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>
                    {" "}
                    OVERVIEW OF CUSTOMER LIKED OFFERS
                  </h3>
                </div>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid",
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" align="center">
                        S NO
                      </TableCell>
                      <TableCell align="center" align="center">
                        CONTENT
                      </TableCell>
                      <TableCell align="center" align="center">
                        TOTAL CUSTOMERS
                      </TableCell>
                      <TableCell align="center" align="center">
                        VIEW CUSTOMER DETAILS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">
                        CUSTOMERS LIKED ZERO OFFERS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.customersLikedOffers[0].length}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.customersLikedOffers[0],
                                [
                                  "COUPONS CONSUMED",
                                  "TOTAL TRANSACTION/INTERACTION",
                                  "REFERED COUNT",
                                  "REDEEMED POINTS",
                                  "currentPts",
                                  "addedPts",
                                ]
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">2</TableCell>
                      <TableCell align="center">
                        CUSTOMERS RANGE LIKED 1-9
                      </TableCell>
                      <TableCell align="center">
                        {this.state.customersLikedOffers[1].length}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.customersLikedOffers[1],
                                [
                                  "COUPONS CONSUMED",
                                  "TOTAL TRANSACTION/INTERACTION",
                                  "REFERED COUNT",
                                  "REDEEMED POINTS",
                                  "currentPts",
                                  "addedPts",
                                ]
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">3</TableCell>
                      <TableCell align="center">
                        CUSTOMERS LIKED OFFERS RANGE 10- 49
                      </TableCell>
                      <TableCell align="center">
                        {this.state.customersLikedOffers[2].length}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.customersLikedOffers[2],
                                [
                                  "COUPONS CONSUMED",
                                  "TOTAL TRANSACTION/INTERACTION",
                                  "REFERED COUNT",
                                  "REDEEMED POINTS",
                                  "currentPts",
                                  "addedPts",
                                ]
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">4</TableCell>
                      <TableCell align="center">
                        CUSTOMERS LIKED OFFERS RANGE 50- 99
                      </TableCell>
                      <TableCell align="center">
                        {this.state.customersLikedOffers[3].length}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.customersLikedOffers[3],
                                [
                                  "COUPONS CONSUMED",
                                  "TOTAL TRANSACTION/INTERACTION",
                                  "REFERED COUNT",
                                  "REDEEMED POINTS",
                                  "currentPts",
                                  "addedPts",
                                ]
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">5</TableCell>
                      <TableCell align="center">
                        CUSTOMERS LIKED MORE THAN 100 OFFERS
                      </TableCell>

                      <TableCell align="center">
                        {this.state.customersLikedOffers[4].length}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.customersLikedOffers[4],
                                [
                                  "COUPONS CONSUMED",
                                  "TOTAL TRANSACTION/INTERACTION",
                                  "REFERED COUNT",
                                  "REDEEMED POINTS",
                                  "currentPts",
                                  "addedPts",
                                ]
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Col>
            </Row>
            <Row className="division">
              <Col>
                <div
                  style={{
                    backgroundColor: "#fafafa",
                    width: 100 + "%",
                    margin: 1 + "%",
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>
                    {" "}
                    OVERVIEW OF CUSTOMER ADDED SHOPS TO FAVOURITE
                  </h3>
                </div>
                <Table
                  style={{
                    width: 100 + "%",
                    border: 0.5 + "px",
                    borderColor: "#ced4da",
                    borderStyle: "solid",
                  }}
                  size="medium"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" align="center">
                        S NO
                      </TableCell>
                      <TableCell align="center" align="center">
                        CONTENT
                      </TableCell>
                      <TableCell align="center" align="center">
                        TOTAL CUSTOMERS
                      </TableCell>
                      <TableCell align="center" align="center">
                        VIEW CUSTOMER DETAILS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">1</TableCell>
                      <TableCell align="center">
                        CUSTOMERS ADDED ZERO SHOPS
                      </TableCell>
                      <TableCell align="center">
                        {this.state.customersAddedShops[0].length}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.customersAddedShops[0],
                                [
                                  "COUPONS CONSUMED",
                                  "TOTAL TRANSACTION/INTERACTION",
                                  "REFERED COUNT",
                                  "REDEEMED POINTS",
                                  "currentPts",
                                  "addedPts",
                                ]
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">2</TableCell>
                      <TableCell align="center">
                        CUSTOMERS ADDED SHOPS RANGE 1-9
                      </TableCell>
                      <TableCell align="center">
                        {this.state.customersAddedShops[1].length}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.customersAddedShops[1],
                                [
                                  "COUPONS CONSUMED",
                                  "TOTAL TRANSACTION/INTERACTION",
                                  "REFERED COUNT",
                                  "REDEEMED POINTS",
                                  "currentPts",
                                  "addedPts",
                                ]
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">3</TableCell>
                      <TableCell align="center">
                        CUSTOMERS ADDED SHOPS RANGE 10- 49
                      </TableCell>
                      <TableCell align="center">
                        {this.state.customersAddedShops[2].length}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.customersAddedShops[2],
                                [
                                  "COUPONS CONSUMED",
                                  "TOTAL TRANSACTION/INTERACTION",
                                  "REFERED COUNT",
                                  "REDEEMED POINTS",
                                  "currentPts",
                                  "addedPts",
                                ]
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">4</TableCell>
                      <TableCell align="center">
                        CUSTOMERS ADDED SHOPS RANGE 50- 99
                      </TableCell>
                      <TableCell align="center">
                        {this.state.customersAddedShops[3].length}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.customersAddedShops[3],
                                [
                                  "COUPONS CONSUMED",
                                  "TOTAL TRANSACTION/INTERACTION",
                                  "REFERED COUNT",
                                  "REDEEMED POINTS",
                                  "currentPts",
                                  "addedPts",
                                ]
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">5</TableCell>
                      <TableCell align="center">
                        CUSTOMERS ADDED SHOPS MORE THAN 100
                      </TableCell>

                      <TableCell align="center">
                        {this.state.customersAddedShops[4].length}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Button
                            onClick={() => {
                              this.viewTable(
                                this.state.customersAddedShops[4],
                                [
                                  "COUPONS CONSUMED",
                                  "TOTAL TRANSACTION/INTERACTION",
                                  "REFERED COUNT",
                                  "REDEEMED POINTS",
                                  "currentPts",
                                  "addedPts",
                                ]
                              );
                            }}
                          >
                            View{" "}
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Col>
            </Row>
            )}
          </>
        )}
      </>
    );
  }
}
