import React, { Component } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { Auth } from "aws-amplify";
import MaterialTable from "material-table";
import config from "../config";
export default class GhoshakTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: {
        couponAddedby: "",
        couponStatus: "Advance",
        trasactionType: "cash",
        transactionId: "",
        amount: "",
        shopId: "",
        pincode: "",
        userId10Digit: "",
      },
      userDetails: {
        pincode: "",
        userId10Digit: "",
        shopName: "",
        shopAddress: "",
        userName: "",
        userPhoneNumber: "",
      },
      pastTransaction: [],
      userId10Digit: "",
      showTransaction: false,
      creditedAmt: 0,
      couponAmountUsed: 0,
      couponList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.getLastTransaction = this.getLastTransaction.bind(this);
    this.onChange = this.onChange.bind(this);
    this.addTransaction = this.addTransaction.bind(this);
    this.editTransaction = this.editTransaction.bind(this);
    this.getShop = this.getShop.bind(this);
  }
  editTransaction = (data) => {
    let editObject = {
      couponId: data.couponId,
      couponStatus: data.couponStatus,
      trasactionType: data.trasactionType,
      queryType: "edit",
      amount: data.amount,
    };
    Promise.all([
      fetch(`${config.ApiGateway}/ghoshakTransaction`, {
        method: "POST",

        body: JSON.stringify(editObject),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.getLastTransaction();
      })
      .catch((err) => console.log("error in adding transaction" + err));
  };
  getLastTransaction = async () => {
    await Promise.all([
      fetch(`${config.ApiGateway}/getGhoshakTransaction`, {
        method: "POST",

        body: JSON.stringify({ userId10Digit: this.state.userId10Digit }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        // console.log(data);
        let couponAmountUsed = 0;
        let orderPromoList = [];
        let amountCredited = 0;
        if (data.status === "success") {
          data.orderCoupons.map((elements) => {
            console.log(elements);
            if (
              elements.couponStatus == "Redeemed" &&
              elements.couponGivenBy == "Ghoshak"
            ) {
              couponAmountUsed += parseFloat(elements.discountAmount);
              console.log("elements.discountAmount" + elements.discountAmount);
            }

            if (
              elements.couponStatus === "Added" &&
              elements.couponGivenBy === "Ghoshak" &&
              elements.couponType === "ORDER-PROMO-CODE"
            ) {
              orderPromoList.push(elements);
            }
          });
          // console.log({ couponAmountUsed, orderPromoList, amountCredited });
          let cAmt = data.transactionList.filter(
            (items) => items.couponCategory === "Credited"
          );

          cAmt.forEach(
            (elements) => (amountCredited += parseFloat(elements.amount) || 0)
          );
          this.setState({
            couponList: orderPromoList,
            payment: {
              ...this.state.payment,
              shopId: data.userDetails.userId,
              pincode: data.userDetails.userPincode,
              userId10Digit: data.userDetails.userId10Digit,
            },
            userDetails: {
              ...this.state.userDetails,

              pincode: data.userDetails.userPincode,
              userId10Digit: data.userDetails.userId10Digit,
              shopName: data.userDetails.shopName,
              shopAddress: data.userDetails.shopAddress,
              userName: data.userDetails.userName,
              userPhoneNumber: data.userDetails.userPhoneNumber,
            },
            pastTransaction: data.transactionList.sort(
              (a, b) => new Date(b.addedDate) - new Date(a.addedDate)
            ),
            creditedAmt: amountCredited,
            couponAmountUsed: couponAmountUsed,
            showTransaction: true,
          });
        } else {
          alert("Entered Shop id Not found");
        }
      })
      .catch((err) => console.log("error in adding transaction" + err));
  };
  getShop = (e) => {
    e.preventDefault();
    this.getLastTransaction();
  };
  componentDidMount = async () => {
    this.mounted = true;

    await Auth.currentAuthenticatedUser()
      .then((user) => {
        if (this.mounted) {
          this.setState({
            payment: {
              ...this.state.payment,
              couponAddedby: user.attributes.phone_number,
            },
          });
        }
      })

      .catch((err) => console.log(err));
  };
  componentWillUnmount() {
    this.mounted = false;
  }

  handleChange = (e) => {
    //dynamic object
    this.setState({
      payment: { ...this.state.payment, [e.target.name]: e.target.value },
    });
  };
  addTransaction = (e) => {
    // Add new transaction
    e.preventDefault();
    Promise.all([
      fetch(`${config.ApiGateway}/ghoshakTransaction`, {
        method: "POST",

        body: JSON.stringify(this.state.payment),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([data]) => {
        this.setState({
          payment: {
            ...this.state.payment,

            couponStatus: "Advance",
            trasactionType: "cash",
            transactionId: "",
            amount: "",
          },
        });

        this.getLastTransaction();
      })
      .catch((err) => console.log("error in adding transaction" + err));
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    const {
      showTransaction,
      userDetails,
      creditedAmt,
      couponAmountUsed,
    } = this.state;

    return (
      <Container>
        <div style={{ width: "100%", height: "30px" }}></div>
        <div className="orderHeader">
          <h2 className="orderHeaderText">Ghoshak Transaction</h2>
        </div>
        <div style={{ width: "100%", height: "30px" }}></div>
        <Row className="rowClass">
          <Col
            className="flexCardHeader"
            style={{
              boxShadow: "0px 0px 13px 0px rgba(26, 52, 95, 0.08)",
            }}
            sm={8}
          >
            <Form onSubmit={this.getShop} style={{ width: "100%" }}>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="4">
                  Shop Id
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    name="userId10Digit"
                    pattern="[0-9]{1,}"
                    onChange={this.onChange}
                    value={this.state.userId10Digit}
                  ></Form.Control>
                </Col>
                <Col sm="2">
                  <Button type="submit">Submit</Button>
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Row>

        <div style={{ width: "100%", height: "30px" }}></div>
        {showTransaction && (
          <>
            <Row
              style={{ width: "100%", margin: "0 auto" }}
              className="rowClass"
            >
              <Col
                className="flexCard"
                style={{
                  display: "table-cell",
                  padding: "16px",
                  border: "1px solid #d8d8d8",
                  textAlign: "right",
                }}
                sm={11}
              >
                {" "}
                <div className="flexCardHeaderAlign">
                  <div className="orderHeader">
                    <b>Transaction Details</b>
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {parseFloat(this.state.amountCredited) -
                    parseFloat(this.state.couponAmountUsed) >
                  0 ? (
                    <>
                      <div className="shopLabeltext">
                        <label>Credited Amount:</label>
                        <p>
                          {" ₹ "} {creditedAmt}
                        </p>
                      </div>
                      <div className="shopLabeltext">
                        <label>Amount Used:</label>
                        <p>
                          {" ₹ "} {couponAmountUsed}
                        </p>
                      </div>
                      <div className="shopLabeltext">
                        <label>Balance:</label>
                        <p>
                          {" ₹ "}{" "}
                          {parseFloat(creditedAmt) -
                            parseFloat(couponAmountUsed)}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="shopLabeltext">
                        <label>Ghoshak Will Pay:</label>
                        <p>
                          {" ₹ "}{" "}
                          {parseFloat(creditedAmt) -
                            parseFloat(couponAmountUsed)}
                        </p>
                      </div>{" "}
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="rowClass">
              <Col
                className="flexCard"
                style={{ display: "table-cell", padding: "16px" }}
                sm={5}
              >
                <div className="flexCardHeaderAlign">
                  <div className="orderHeader">
                    <b>Shop Info</b>
                  </div>
                </div>
                <div style={{ flex: 1, flexDirection: "column" }}>
                  <div className="shopLabeltext">
                    <label>Shop Name :</label>
                    <p>{userDetails.shopName}</p>
                  </div>
                  <div className="shopLabeltext">
                    <label>Shop Address :</label>
                    <p>{userDetails.shopAddress}</p>
                  </div>
                  <div className="shopLabeltext">
                    <label>Phone Number :</label>
                    <p>{userDetails.userPhoneNumber}</p>
                  </div>
                  <div className="shopLabeltext">
                    <label>Owner Name :</label>
                    <p>{userDetails.userName}</p>
                  </div>
                  <div className="shopLabeltext">
                    <label>Pincode:</label>
                    <p>{userDetails.pincode}</p>
                  </div>
                  <div className="shopLabeltext">
                    <label>User Area :</label>
                    <p>{userDetails.userArea}</p>
                  </div>
                </div>
              </Col>

              <Col
                className="flexCard"
                style={{ display: "table-cell", padding: "16px" }}
                sm={5}
              >
                <div className="flexCardHeaderAlign">
                  <div className="orderHeader">
                    <b>Add Payment</b>
                  </div>
                </div>
                <Form onSubmit={this.addTransaction} style={{ width: "100%" }}>
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Form.Label column sm="4">
                      Payment Status
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={this.state.payment.couponStatus}
                        as="select"
                        name="couponStatus"
                        required
                        onChange={this.handleChange}
                      >
                        <option value="Balance">Balance Amount</option>
                        <option value="Advance">Advance</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Form.Label column sm="4">
                      Amount
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        name="amount"
                        placeholder="₹ INR"
                        pattern="[0-9]{1,}"
                        value={this.state.payment.amount}
                        onChange={this.handleChange}
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Form.Label column sm="4">
                      Transaction Type
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={this.state.payment.trasactionType}
                        as="select"
                        name="trasactionType"
                        required
                        onChange={this.handleChange}
                      >
                        <option value="cash">Cash</option>
                        <option value="gpay">G Pay</option>
                        <option value="paytm">Paytm</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextPassword">
                    <Form.Label column sm="4">
                      Transaction ID
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        name="transactionId"
                        value={this.state.payment.transactionId}
                        onChange={this.handleChange}
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Button
                    style={{ marginTop: "20px", float: "right" }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
            <div style={{ width: "100%", height: "30px" }}></div>
            <div className="orderHeader">
              <h2 className="orderHeaderText">Previous Transaction</h2>
            </div>
            <Row className="rowClass">
              <Col style={{ display: "table-cell", padding: "16px" }} sm={10}>
                <MaterialTable
                  title="Transaction "
                  columns={[
                    {
                      title: "Payment Status",
                      field: "couponStatus",
                      editComponent: (props) => (
                        <select
                          value={props.value}
                          onChange={(e) => props.onChange(e.target.value)}
                        >
                          <option value="Balance">Balance Amount</option>
                          <option value="Advance">Advance</option>{" "}
                        </select>
                      ),
                    },
                    {
                      title: "Amount",
                      field: "amount",
                    },

                    {
                      title: "Transaction Type",
                      field: "trasactionType",
                      editComponent: (props) => (
                        <select
                          value={props.value}
                          onChange={(e) => props.onChange(e.target.value)}
                        >
                          <option value="cash">Cash</option>
                          <option value="gpay">G Pay</option>
                          <option value="paytm">Paytm</option>{" "}
                        </select>
                      ),
                    },

                    {
                      title: "Added on",
                      field: "addedDate",
                      editable: "never",
                    },
                    {
                      title: "Updated By",
                      field: "couponAddedby",
                      editable: "never",
                    },
                  ]}
                  data={this.state.pastTransaction}
                  editable={{
                    // only group(b) rows would be deletable

                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          this.editTransaction(newData);

                          resolve();
                        }, 600);
                      }),
                  }}
                  options={{
                    exportButton: true,
                    pageSize: 5,
                    maxBodyHeight: 437,
                    pageSizeOptions: [5, 10, 20, 100],
                    grouping: false,
                    filtering: false,
                  }}
                />
              </Col>
            </Row>

            <div className="orderHeader">
              <h2 className="orderHeaderText">Ghoshak Transaction</h2>
            </div>
            <Row className="rowClass">
              <Col style={{ display: "table-cell", padding: "16px" }} sm={10}>
                <MaterialTable
                  title="Transaction "
                  columns={[
                    {
                      title: "Detail",
                      field: "discountAmount",
                      render: (rowData) => {
                        return `₹${rowData.discountAmount} off on online order`;
                      },
                    },
                    {
                      title: "Code",
                      field: "promocode",
                      render: (rowData) => {
                        return `${rowData.promocode}`;
                      },
                    },
                    {
                      title: " Coupons used",
                      field: "totalCouponCount",
                      render: (rowData) => {
                        return `${
                          rowData.totalCouponCount -
                          rowData.remainingCouponCount
                        }`;
                      },
                    },
                    {
                      title: " Coupons used",
                      field: "totalCouponCount",
                      render: (rowData) => {
                        return `${
                          parseInt(rowData.totalCouponCount) -
                          parseInt(rowData.remainingCouponCount)
                        }`;
                      },
                    },
                    {
                      title: "Amount",
                      field: "Amount",
                      render: (rowData) => {
                        return `${
                          parseInt(rowData.discountAmount) *
                          (parseInt(rowData.totalCouponCount) -
                            parseInt(rowData.remainingCouponCount))
                        }`;
                      },
                    },
                  ]}
                  data={this.state.couponList}
                  options={{
                    exportButton: true,
                    pageSize: 5,
                    maxBodyHeight: 437,
                    pageSizeOptions: [5, 10, 20, 100],
                    grouping: false,
                    filtering: false,
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        <div style={{ width: "100%", height: "30px" }}></div>
      </Container>
    );
  }
}
